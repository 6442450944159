@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-social-push-mode {
  .social-push-mode-input-wrap {
    margin-bottom: 14px;
  }

  .social-push-mode-input-title,
  .social-push-mode-input-label {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 900;
    line-height: 2.5em;
    color: $dmode-body-text;
    letter-spacing: 1px;
  }

  .social-push-mode-input-title {
    padding: 0 30px;
    margin: 0 0 5px;
  }

  .social-push-mode-input-label {
    padding: 0 25px;
  }

  .form-field label {
    width: 100%;
    padding: 0 3em;
    display: block;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 900;
    line-height: 2.5em;
    color: $dmode-body-text;
    letter-spacing: 1px;
    box-sizing: border-box;
  }
}
