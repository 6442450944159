@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-publisher-facebook-post-preview {
  display: block;
  padding: 12px;
  max-width: 474px;
  font-family: Helvetica, Arial, sans-serif;
  border: 1px solid #dddfe2;
  border-radius: 3px;
  background-color: #ffffff;

  .facebook-post-header,
  .facebook-post-text {
    a {
      color: #365899;
    }
  }

  .facebook-post-text {
    word-break: break-word;
    padding: 0 0 5px 1px;
  }

  .facebook-post-header {
    img {
      width: 40px;
      height: 40px;
      margin-right: 12px;
      border: solid 1px #eee;
    }

    a {
      color: #050505;
      font-size: 15px;
      font-weight: bold;
      padding-top: 2px;
      display: inline-block;
    }

    small {
      color: #65676b;
      position: relative;
      top: -2px;
      font-weight: normal;
      font-size: 13px;
    }
  }

  .facebook-post-body {
    font-size: 15px;
    font-weight: normal;
    line-height: 1.38;
    word-wrap: break-word;
    margin-top: 6px;
    color: #050505;
  }

  .facebook-post-footer {
    display: flex;
    justify-content: space-around;
    color: #616770;
    font-size: 13px;
    font-weight: bold;
    line-height: 14px;
    margin-top: 10px;
  }

  .facebook-post-footer-item {
    margin-right: 20px;
    padding: 4px 4px 4px 0;
  }

  .facebook-post-image,
  .facebook-post-video,
  .facebook-post-link-preview {
    margin-top: 6px;
    video {
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  .collage-container {
    .more-than-two {
    }
    .facebook-post-image {
      &:last-child {
        img {
          height: 250px;
        }
      }
    }

    &.collage-multiple {
      display: flex;
    }

    .facebook-post-image {
      position: relative;
      &.count-1 {
        img {
          height: 100%;
          width: 100%;
        }
      }
      img {
        height: 200px;
        object-fit: cover;
      }
      &.count-2 {
        img {
          width: 50%;
          border: 1px solid #fff;
        }
      }
      &.count-3 {
        img {
          width: 50%;
          height: 250px;
        }
        img {
          height: 250px;
          border: 1px solid #fff;
          &:first-child {
            width: 100%;
            height: 275px;
          }
        }
      }
      &.count-4 {
        img {
          width: 50%;
          border: 1px solid #fff;
        }
      }
      &.count-5,
      &.more-than-two:not(.count-4):not(.count-3) {
        display: flex;
        flex-wrap: wrap;
        img {
          width: 25%;
          flex-grow: 1;
          height: 165px;
          border: 1px solid #fff;

          &:first-child {
            width: 100%;
            height: 333px;
          }
        }
      }

      img {
        &.has-counter {
          filter: brightness(0.6);

          + .image-counter {
            color: #fff;
            font-size: 30px;
            position: absolute;
            bottom: 55px;
            right: 55px;
          }
        }
      }
    }
  }

  .facebook-post-link-preview,
  .facebook-post-link-preview:hover {
    text-decoration: none;
    color: #1d2129;
  }

  .facebook-post-link-preview {
    border: 1px solid;
    border-color: #e9ebee #e9ebee #d1d1d1;
    display: block;

    img {
      border: 0;
    }
  }

  .facebook-post-link-preview-footer {
    margin: 10px 12px;
    max-height: 100px;
    font-size: 12px;
  }

  .facebook-post-link-preview-title {
    font-family: Georgia, serif;
    letter-spacing: normal;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 5px;
    max-height: 110px;
    overflow: hidden;
    word-wrap: break-word;
  }

  .facebook-post-link-preview-description {
    line-height: 16px;
    max-height: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .facebook-post-link-preview-hostname {
    padding-top: 9px;
    font-size: 11px;
    line-height: 11px;
    text-transform: uppercase;
    color: #90949c;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .text-read-more {
    font-weight: 700;
    cursor: pointer;
  }
}
