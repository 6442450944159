.wave-dots-container {
  width: 15px;
  text-align: center;
}
.wave-dots-container .dot {
  display: inline-block;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  margin: 0;
  background-color: #fff;
  animation: wave 1.3s linear infinite;
}
.wave-dots-container .dot:nth-child(2) {
  animation-delay: -1.1s;
}
.wave-dots-container .dot:nth-child(3) {
  animation-delay: -0.9s;
}
@keyframes wave {
  0%, 60%, 100% {
    transform: initial;
  }
  30% {
    transform: translateY(-5px);
  }
}