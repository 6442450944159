ssi-widget-live-x-stream {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
ssi-widget-live-x-stream .dashboard-widget {
  position: relative;
  height: 100%;
  width: 100%;
}
@media (min-width: 600px) {
  ssi-widget-live-x-stream .dashboard-widget ssi-menu {
    display: none;
  }
  ssi-widget-live-x-stream .dashboard-widget .post-details.time-visibility {
    display: flex;
  }
  ssi-widget-live-x-stream .dashboard-widget .post-details.time-visibility.placed-below {
    display: none;
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding: 10px 0 0 10px;
  }
}
@media (min-width: 1201px) {
  ssi-widget-live-x-stream .dashboard-widget.view-1x2 .desktop-menu .menu-list-item:last-child, ssi-widget-live-x-stream .dashboard-widget.view-1x2 .desktop-menu .menu-list-item:nth-child(3) {
    display: none;
  }
  ssi-widget-live-x-stream .dashboard-widget.view-1x2 ssi-menu {
    display: block;
  }
  ssi-widget-live-x-stream .dashboard-widget.view-1x2 .post-details.time-visibility {
    display: none;
  }
  ssi-widget-live-x-stream .dashboard-widget.view-1x2 .post-details.time-visibility.placed-below {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding: 10px 0 0 10px;
  }
  ssi-widget-live-x-stream .dashboard-widget.view-2x2 ssi-menu {
    display: none;
  }
}
ssi-widget-live-x-stream .scroll-container {
  width: 100%;
  height: 100%;
}
ssi-widget-live-x-stream ssi-stream-post {
  display: block;
  padding: 10px;
}
@media (max-width: 599px) {
  ssi-widget-live-x-stream ssi-stream-post .desktop-menu .menu-list-item:last-child {
    display: none;
  }
  ssi-widget-live-x-stream ssi-stream-post .post-details.time-visibility {
    display: none;
  }
  ssi-widget-live-x-stream ssi-stream-post .post-details.time-visibility.placed-below {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding: 10px 0 0 10px;
  }
}
@media (max-width: 449px) {
  ssi-widget-live-x-stream ssi-stream-post .desktop-menu .menu-list-item:last-child, ssi-widget-live-x-stream ssi-stream-post .desktop-menu .menu-list-item:nth-child(3) {
    display: none;
  }
}