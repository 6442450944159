ssi-topic-report form {
  background-color: #fff;
  padding: 20px;
}
ssi-topic-report form .form-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}
ssi-topic-report form .form-row .form-field {
  display: flex;
  flex-direction: column;
  flex: 1 1 25%;
  white-space: nowrap;
  margin-right: 10px;
}
ssi-topic-report form .form-row .form-field > label {
  color: #43537f;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-left: 20px;
  margin-bottom: 15px;
}
ssi-topic-report form .form-row .form-field:last-child {
  margin-right: 0;
}
ssi-topic-report form .form-row .form-field.field-group {
  display: flex;
  flex-direction: row;
  margin-right: 10px;
}
ssi-topic-report form .form-row .form-field.wider {
  flex-basis: 40%;
}
ssi-topic-report form .form-row .input-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
ssi-topic-report form .form-row .input-wrapper > input {
  width: 100%;
  height: 46px;
  border: none;
  outline: none;
  border-radius: 100px;
  display: flex;
  align-items: center;
  color: #101525;
  font-size: 14px;
  font-weight: 900;
  background-color: #f8f9fd;
  padding: 10px 30px 10px 20px;
}
ssi-topic-report form .form-row .input-wrapper ssi-dropdown-select-2 {
  width: 100%;
}
ssi-topic-report .number-input {
  width: 100%;
  height: 43px;
  border: none;
  outline: none;
  border-radius: 100px;
  color: #101525;
  font-size: 14px;
  font-weight: 900;
  background-color: #f8f9fd;
  padding: 10px 30px 10px 20px;
}
ssi-topic-report .search-field input {
  margin-top: 25px;
  height: 43px;
  border: none;
  outline: none;
  border-radius: 100px;
  color: #101525;
  font-size: 14px;
  font-weight: 900;
  background-color: #f8f9fd;
  padding: 10px 30px 10px 20px;
}
ssi-topic-report .search-field input::placeholder {
  color: #838eab;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}
ssi-topic-report .input-range {
  display: flex;
}
ssi-topic-report .input-range input:not(:last-child) {
  margin-right: 10px;
}
ssi-topic-report ssi-dropdown-select-2 button {
  width: 100%;
}
ssi-topic-report .toggle-switch-wrapper .toggle-switch-2-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f0f3f9;
  padding: 10px 10px 10px 20px;
  border-radius: 50px;
}
ssi-topic-report .table-container {
  padding: 20px 20px 50px;
  background-color: #fff;
  border-radius: 10px;
}
ssi-topic-report .table-container .row-even {
  background-color: #f8f9fd;
}
ssi-topic-report .chatbot-name {
  display: flex;
  align-items: center;
  color: #43537f;
  font-size: 12px;
  font-weight: 900;
  min-width: 0;
  margin-right: 10px;
  padding: 0 5px 0 10px;
}
ssi-topic-report .chatbot-name span {
  display: block;
  max-width: 100%;
  padding: 10px 0;
  word-wrap: break-word;
}
ssi-topic-report .table-cell {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 0 5px 0 10px;
  border-bottom: 2px solid #e0e7f4;
}
ssi-topic-report .name-count-dropdown {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
ssi-topic-report .paginator-container {
  background-color: #fff;
  border-radius: 0 0 15px 15px;
  height: 80px;
  padding: 40px;
}