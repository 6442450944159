@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import './../../../../../angularjs/common/sass/variables';
@import './../../../../../angularjs/common/sass/mixins';
@import '../../../../common/styles/colors.scss';

$live-chat-offline-status-colour: #f39780;
$live-chat-online-status-colour: #91cac7;
$live-chat-online-text-colour: #49aee6;

ssi-inbox-dashboard {
  display: flex;
  background-color: #f8f9fd;
  padding: 12px;
  height: calc(100vh - 68px);

  .inbox-dashboard-inner {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 12px;

    .dashboard-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .inbox-loader {
    position: absolute;
    z-index: 4;
    margin-left: -47px;
    margin-top: 10px;
  }

  .inbox-clock {
    margin: 20px;
  }

  @include responsiveHeight($ssi-screen-height-sm) {
    .inbox-clock {
      display: none;
    }
  }

  h2 {
    color: #101525;
    font-size: 20px;
    font-weight: 900;
  }

  .social-status {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    label {
      margin-right: 10px;
      margin-top: 4px;
      color: $lmode-title-text;
      font-size: 14px;
      font-weight: 900;
    }

    .description {
      display: block;
      width: 100%;
      margin-top: 10px;
      color: $lmode-helper-text;
      font-size: 12px;
      font-weight: 900;
      text-align: center;
    }
  }

  .stats-and-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    .stat {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 16.5px;
      border-radius: 10px;
      background-color: #f2f4fc;
      i {
        color: $lmode-helper-text;
        margin-right: 5px;
      }
      span {
        color: #000000;
        font-size: 12px;
        font-weight: 900;
      }
    }

    .avatar {
      margin-left: 30px;
      margin-right: 30px;
      border-radius: 50%;
      width: 100px;
      height: 100px;
    }
  }

  .social-push-mode-dashboard {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: auto;

    .header-container {
      display: flex;
      width: 100%;
      text-align: center;
      flex-wrap: wrap;

      h1,
      h2 {
        width: 100%;
      }
    }

    ul,
    li {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    .total-stats-list {
      display: flex;
      i {
        align-items: center;
        background-color: #f6f8f9;
        border-radius: 50%;
        font-size: 2.5em;
        justify-content: center;
        padding: 20px;
      }
    }

    .single-stat {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      min-height: 160px;
      width: 130px;

      span {
        text-align: center;
        font-size: 0.8em;
        font-weight: 600;
        margin: 0;
        padding: 0;
        text-transform: capitalize;

        &.value {
          font-size: 1.6em;
          font-style: italic;
          font-weight: 300;
        }
      }
    }
  }

  .agents-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 130px);
    grid-gap: 20px;

    /* boring properties */
    list-style: none;
    padding: 20px;
    width: 100%;
    margin: 0 auto;

    > li {
      display: flex;
      justify-content: flex-start;
      padding: 0;
      flex-flow: column nowrap;
      margin: 20px 0;
      min-height: 160px;
      width: 130px;
    }
  }

  .agent-container {
    display: flex;
    align-items: center;
    img {
      border-radius: 50%;
      border-style: solid;
      border-width: 4px;
      height: 60px;
      width: 60px;
    }

    .agent-details {
      position: relative;
      display: flex;
      align-items: center;
      color: #187ab1;
      flex-flow: column nowrap;
      .name {
        font-weight: 700;
        text-transform: capitalize;
        text-align: center;
      }

      .resolutions {
        display: flex;
        margin-bottom: 5px;

        dt {
          font-weight: 400;
          margin: 0;
          padding: 0;
        }

        dd {
          padding-left: 5px;
        }
      }
    }

    .status-button {
      border: none;
      outline: revert;
      padding: 0;
      white-space: nowrap;
      display: flex;
      align-items: center;
      border-radius: 20px;
      justify-content: space-around;
      font-size: 1.1em;
      font-weight: 500;
      margin: 5px 0;
      padding: 2px 20px;
      text-transform: capitalize;
      width: 90px;

      &.disabled,
      &:disabled {
        background: transparent;
      }

      &:not(:disabled) {
        color: #fff;
      }
    }

    .status-icon {
      font-size: 0.85em;
      padding-left: 5px;
    }

    .dropdown-menu {
      border: none;
      border-radius: 20px;
      margin: 0;
      padding: 0;
      width: 100%;

      button {
        background: transparent;
        border: none;
        outline: none;
        appearance: none;
      }

      li {
        color: #43545d;
        padding: 10px;

        &:first-child {
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
        }
        &:last-child {
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
        }

        &:hover {
          background-color: $primary-active;
          color: #fff;
        }
      }
    }

    &.ONLINE {
      .agent-details {
        > button:not(:disabled) {
          background-color: $live-chat-online-status-colour;
          color: #fff;
        }
      }
      img {
        border-color: $live-chat-online-status-colour;
      }
    }

    &.BUSY {
      .agent-details {
        > button:not(:disabled) {
          background-color: #607987;
        }
      }
      img {
        border-color: #a6b7c0;
      }
    }

    &.OFFLINE {
      .agent-details {
        > button:not(:disabled) {
          background-color: $live-chat-offline-status-colour;
        }
      }
      img {
        border-color: $live-chat-offline-status-colour;
      }
    }
  }
}
