@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';
@import '~/styles/variables';

ssi-widget-inbox-conversations {
  height: 100%;
  width: 100%;

  .dashboard-widget {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 1px;

    ssi-inbox-query-result-list {
      &.live-chat-tally-visible {
        .inbox-query-result-list {
          height: calc(100% - $live-chat-tally-height);
        }
      }
    }
  }
}
