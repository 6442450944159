@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../../../angularjs/common/sass/variables';

.notes-modal-body {
  background-color: #f8f9fa;
  padding: 0;
}

// .notes-content{
//   position: relative;

//   textarea{
//     width:100%;
//     background: none;
//     display: block;
//     position: relative;
//     z-index: 2;
//   }
//   .notes-tag{
//     direction: ltr;
//     box-sizing: border-box;
//     width: 100%;
//     height: auto;
//     overflow: auto;
//     border-width: 1px;
//     border-style: solid;
//     padding: 2px 2px;
//     text-align: start;
//     text-transform: none;
//     text-indent: 0px;
//     text-decoration: none solid rgb(85, 85, 85);
//     letter-spacing: normal;
//     word-spacing: 0px;
//     tab-size: 8;
//     display: block;
//     overflow: hidden !important;
//     white-space: pre-wrap;
//     word-wrap: break-word;
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     background: transparent;
//     color: rgba(0, 0, 0, 0);
//     z-index: 2;
//     pointer-events: none;

//     a {
//       background: #43ade8;
//       border-radius: 4px;
//       color: #fff;
//       margin-left: -1px;
//       font-size: 14px;
//       vertical-align: text-bottom;
//     }
//   }
// }

// .notes-list{
//   min-height:200px;
//   margin-top:20px;
//   list-style:none;
//   padding:0;
//   li{
//     margin:0 0 10px 0;
//   }
// }
// .notes-list {

//   @note-padding: 5px;
//   @note-border-color: #c5d6e0;

//   .note.border-left .content {
//     border-left: solid @note-padding @note-border-color;
//     padding-right: 15px;
//   }

//   .note.border-right .content {
//     border-right: solid @note-padding @note-border-color;
//     padding-left: 15px;
//   }

//   .content {

//     font-size: 14px;
//     color: #7b8c96;
//     margin: @note-padding;
//     padding: @note-padding;
//     padding-left: 10px;

//     .disabled-links {

//       * {
//         padding-right: 0.25em;
//       }

//       a {
//         font-weight: normal;
//         color: #7b8c96;
//       }

//     }

//   }

//   & > ul {
//     display: flex;
//     flex-flow: column nowrap;
//     list-style: none;
//     margin: 0;
//     padding: 0;

//     * {
//       display: flex;
//     }

//     & > li {
//       flex-flow: column nowrap;
//       margin: 0;
//       padding: 0;

//       &:not(:first-child) {
//         border-top: solid 2px #EBF0F2;
//         margin-top: 20px;
//         padding-top: 20px;
//       }

//       .disabled-links {
//         display: inline;
//         flex-flow: row wrap;
//         font-size: 1.1em;
//         line-height: 1.6em;
//         margin: 0;

//         &.self-penned {
//           &:before {
//             color: @brand-primary;
//             content: 'You:';
//             font-style: italic;
//             font-weight: 400;
//             padding-right: 0.5em;
//           }
//         }

//         * {
//           display: inline;
//           padding: 0 0.2em;
//         }

//         a {
//           &:before {
//             content: '@';
//           }
//         }
//       }

//       & > small {
//         & > {
//           font-size: 0.85em;
//           font-style: italic;
//           font-weight: 600;
//         }

//         .delete-button {
//           align-items: center;
//           background: #E4A598;
//           border: none;
//           border-radius: 10px;
//           color: #fff;
//           justify-content: space-between;
//           padding: 1px 10px;

//           & > .button-icon {
//             font-size: 0.85em;
//             padding-right: 0.2em;
//           }

//           & > .button-text {
//             font-style: normal;
//             font-weight: 400;
//           }
//         }

//         & > .note-author {

//         }

//         & > .note-timestamp {
//           flex-grow: 1;
//           justify-content: flex-end;

//           &:before {
//             content: '|';
//             justify-content: center;
//             padding: 0 0.5em;
//           }
//         }
//       }
//     }
//   }

// }
