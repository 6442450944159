ssi-audit .audit-container {
  background-color: #fff;
  position: relative;
  min-height: 500px;
  border-radius: 15px;
}
ssi-audit .audit-container i.close-icon {
  padding: 14px;
  background-color: #f4f4fa;
  border-radius: 50%;
  color: #838eab;
  font-size: 7px;
  font-weight: 900;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
}
ssi-audit .audit-container i.close-icon:hover {
  cursor: pointer;
  color: #14bae3;
}
ssi-audit .audit-container .head {
  position: relative;
}
ssi-audit .audit-container .head .head-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 80px;
  padding: 25px 50px;
}
ssi-audit .audit-container .head .head-row.actions-row {
  position: absolute;
  min-height: 100px;
  background-color: #fff;
}
ssi-audit .audit-container .head h3 {
  color: #43537f;
  font-size: 20px;
  font-weight: 900;
  margin-right: 10px;
  margin-bottom: 0;
  user-select: none;
}
ssi-audit .audit-container .head .head-action {
  margin-left: 30px;
  cursor: pointer;
  user-select: none;
}
ssi-audit .audit-container .head .head-action i {
  color: #14bae3;
  font-size: 12px;
  font-weight: 900;
}
ssi-audit .audit-container .head .head-action i.filter-icon {
  font-size: 14px;
}
ssi-audit .audit-container .head .head-action span {
  margin-left: 5px;
  color: #43537f;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
}
ssi-audit .audit-container .body {
  padding: 25px 50px;
  height: 100%;
  height: 520px;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
ssi-audit .audit-container .body .load-more-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: auto;
}
ssi-audit .audit-container .body .load-more-btn-container .load-more-btn {
  border: none;
  outline: none;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  font-weight: 900;
  text-transform: capitalize;
  background-color: #14bae3;
  padding: 12px 20px;
  margin-top: 30px;
  margin-bottom: 30px;
}
ssi-audit .audit-container .body .load-more-btn-container .load-more-btn:hover {
  color: #fff;
}
ssi-audit .audit-container .body .load-more-btn-container .load-more-btn:disabled {
  background-color: #b4bbce;
}