@import '../../../angularjs/common/sass/include-media';
@import '~/styles/colors';

.form-grid {
  display: flex;
  flex-wrap: wrap;
  padding: 5rem 8rem;
  margin: 0 auto;

  &.align-left {
    margin: 0;
  }

  .form-submit-fixed {
    position: absolute;
    top: -16px;
    width: 190px;
    right: 0;
    text-align: center;
  }
  .form-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 3rem;
    @include media('<tablet') {
      display: block;
    }
  }

  .form-field {
    width: 100%;
    &:first-child:not(:only-child) {
      margin-right: 2rem;
    }
    &-label {
      display: block;
      padding: 0 20px;
      text-transform: uppercase;
      font-size: 10px;
      letter-spacing: 1px;
      margin-bottom: 1rem;
      color: $lmode-helper-text;
      font-weight: bold;
    }

    &.half {
      width: 50%;
      &:only-child {
        padding-right: 10px;
      }

      @include media('<tablet') {
        width: 100%;
        padding-right: 0 !important;
      }
    }

    @include media('<tablet') {
      margin-bottom: 10px;
    }
  }

  textarea {
    width: 100%;
    background-color: $lmgrey-background;
    border-radius: 10px;
    padding: 20px;
    border-color: #fff;
    resize: none;
    &:focus {
      border-color: $primary-active;
      outline-color: $primary-active;
    }
  }
}
