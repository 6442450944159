@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../angularjs/common/sass/variables';
@import '../../../../../angularjs/common/sass/mixins';
@import '~/styles/colors';

ssi-schedule-posts {
  display: block;

  .accordion-content {
    color: $lmode-body-text;
    text-align: left;

    &-title {
      font-size: 14px;
      font-weight: 900;
      text-align: left;
      color: $lmode-body-text;
      padding-left: 30px;
    }

    &-body {
      font-weight: 700;
      line-height: 20px;
      padding: 0 0 30px 30px;
      margin-bottom: 0;
    }

    &-action {
      display: flex;
      align-items: center;
      background-color: #f4f4fa;
      border-radius: 10px;
      border: 1px solid #f4f4fa;
      color: #838eab;
      height: 46px;
      padding: 0 14px;
      cursor: not-allowed;

      p {
        font-size: 14px;
        font-weight: 700;
        color: inherit;
        margin: 0 0 0 8px;
      }

      &-active {
        cursor: pointer;
        background-color: #e4faff;
        border-color: #14bae3;

        p {
          color: #101525;
        }

        i {
          color: #14bae3;
        }
      }
    }
  }

  .post-error {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #fff1ed;
    border-radius: 8px;
    padding: 10px;
    margin: 10px 0;
    width: 100%;

    i {
      color: #e34916;
      font-size: 14px;
      padding-right: 8px;
      flex-basis: 22px;
    }

    p {
      position: relative;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      text-align: left;
      color: $lmode-body-text;
      margin: 0;
      margin-right: auto;
    }

    .error-message {
      display: flex;
      align-items: baseline;
    }
  }

  .scheduling-posts-form {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin-bottom: 16px;
    padding-bottom: 16px;
    margin-top: -16px;
    border-bottom: 1px solid #c3c9d8;
  }

  .scheduling-posts-form-left {
    flex: 0 1 auto;
    margin-right: 10px;
    margin-top: 16px;
  }

  .scheduling-posts-form-right {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-top: 16px;
    flex: 1 1 auto;

    .send-right {
      margin: 0 0 0 auto;
    }

    &-inner {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: 16px;
    }
  }

  .scheduling-posts-label {
    color: $lmode-body-text;
    font-size: 10px;
    font-weight: 900;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-left: 30px;
    margin-bottom: 15px;
    display: block;
    max-width: 100%;
    height: 16px;
  }

  .scheduling-posts-date {
    font-family: 'Lato';
    border: 1px solid #c3c9d8;
    border-radius: 10px;
    padding: 14px;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    text-align: left;
    color: $lmode-title-text;
    appearance: none;
    height: 46px;
    background-color: #fff;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj4KICA8cGF0aCBkPSJNMTQ4IDI4OGgtNDBjLTYuNiAwLTEyLTUuNC0xMi0xMnYtNDBjMC02LjYgNS40LTEyIDEyLTEyaDQwYzYuNiAwIDEyIDUuNCAxMiAxMnY0MGMwIDYuNi01LjQgMTItMTIgMTJ6bTEwOC0xMnYtNDBjMC02LjYtNS40LTEyLTEyLTEyaC00MGMtNi42IDAtMTIgNS40LTEyIDEydjQwYzAgNi42IDUuNCAxMiAxMiAxMmg0MGM2LjYgMCAxMi01LjQgMTItMTJ6bTk2IDB2LTQwYzAtNi42LTUuNC0xMi0xMi0xMmgtNDBjLTYuNiAwLTEyIDUuNC0xMiAxMnY0MGMwIDYuNiA1LjQgMTIgMTIgMTJoNDBjNi42IDAgMTItNS40IDEyLTEyem0tOTYgOTZ2LTQwYzAtNi42LTUuNC0xMi0xMi0xMmgtNDBjLTYuNiAwLTEyIDUuNC0xMiAxMnY0MGMwIDYuNiA1LjQgMTIgMTIgMTJoNDBjNi42IDAgMTItNS40IDEyLTEyem0tOTYgMHYtNDBjMC02LjYtNS40LTEyLTEyLTEyaC00MGMtNi42IDAtMTIgNS40LTEyIDEydjQwYzAgNi42IDUuNCAxMiAxMiAxMmg0MGM2LjYgMCAxMi01LjQgMTItMTJ6bTE5MiAwdi00MGMwLTYuNi01LjQtMTItMTItMTJoLTQwYy02LjYgMC0xMiA1LjQtMTIgMTJ2NDBjMCA2LjYgNS40IDEyIDEyIDEyaDQwYzYuNiAwIDEyLTUuNCAxMi0xMnptOTYtMjYwdjM1MmMwIDI2LjUtMjEuNSA0OC00OCA0OEg0OGMtMjYuNSAwLTQ4LTIxLjUtNDgtNDhWMTEyYzAtMjYuNSAyMS41LTQ4IDQ4LTQ4aDQ4VjEyYzAtNi42IDUuNC0xMiAxMi0xMmg0MGM2LjYgMCAxMiA1LjQgMTIgMTJ2NTJoMTI4VjEyYzAtNi42IDUuNC0xMiAxMi0xMmg0MGM2LjYgMCAxMiA1LjQgMTIgMTJ2NTJoNDhjMjYuNSAwIDQ4IDIxLjUgNDggNDh6bS00OCAzNDZWMTYwSDQ4djI5OGMwIDMuMyAyLjcgNiA2IDZoMzQwYzMuMyAwIDYtMi43IDYtNnoiIGZpbGw9IiMxNGJhZTMiLz4KPC9zdmc+');
    background-repeat: no-repeat;
    background-position-x: 195px;
    background-position-y: 14px;
    background-size: 14px;
    padding: 16px 30px;
    width: 100%;

    &.active {
      font-size: 14px;
      font-weight: 900;
      line-height: 12px;
      color: $lmode-title-text;
    }

    &:focus-within {
      outline: none;
    }

    &::placeholder {
      color: #6a759a;
    }

    &:disabled {
      border-color: #c3c9d8;
      opacity: 1;
    }

    &:hover {
      cursor: pointer;
    }

    &::placeholder {
      color: #6a759a;
    }

    &:disabled {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIiBmaWxsPSIjQjRCQkNFIj4KICA8cGF0aCBkPSJNMTQ4IDI4OGgtNDBjLTYuNiAwLTEyLTUuNC0xMi0xMnYtNDBjMC02LjYgNS40LTEyIDEyLTEyaDQwYzYuNiAwIDEyIDUuNCAxMiAxMnY0MGMwIDYuNi01LjQgMTItMTIgMTJ6bTEwOC0xMnYtNDBjMC02LjYtNS40LTEyLTEyLTEyaC00MGMtNi42IDAtMTIgNS40LTEyIDEydjQwYzAgNi42IDUuNCAxMiAxMiAxMmg0MGM2LjYgMCAxMi01LjQgMTItMTJ6bTk2IDB2LTQwYzAtNi42LTUuNC0xMi0xMi0xMmgtNDBjLTYuNiAwLTEyIDUuNC0xMiAxMnY0MGMwIDYuNiA1LjQgMTIgMTIgMTJoNDBjNi42IDAgMTItNS40IDEyLTEyem0tOTYgOTZ2LTQwYzAtNi42LTUuNC0xMi0xMi0xMmgtNDBjLTYuNiAwLTEyIDUuNC0xMiAxMnY0MGMwIDYuNiA1LjQgMTIgMTIgMTJoNDBjNi42IDAgMTItNS40IDEyLTEyem0tOTYgMHYtNDBjMC02LjYtNS40LTEyLTEyLTEyaC00MGMtNi42IDAtMTIgNS40LTEyIDEydjQwYzAgNi42IDUuNCAxMiAxMiAxMmg0MGM2LjYgMCAxMi01LjQgMTItMTJ6bTE5MiAwdi00MGMwLTYuNi01LjQtMTItMTItMTJoLTQwYy02LjYgMC0xMiA1LjQtMTIgMTJ2NDBjMCA2LjYgNS40IDEyIDEyIDEyaDQwYzYuNiAwIDEyLTUuNCAxMi0xMnptOTYtMjYwdjM1MmMwIDI2LjUtMjEuNSA0OC00OCA0OEg0OGMtMjYuNSAwLTQ4LTIxLjUtNDgtNDhWMTEyYzAtMjYuNSAyMS41LTQ4IDQ4LTQ4aDQ4VjEyYzAtNi42IDUuNC0xMiAxMi0xMmg0MGM2LjYgMCAxMiA1LjQgMTIgMTJ2NTJoMTI4VjEyYzAtNi42IDUuNC0xMiAxMi0xMmg0MGM2LjYgMCAxMiA1LjQgMTIgMTJ2NTJoNDhjMjYuNSAwIDQ4IDIxLjUgNDggNDh6bS00OCAzNDZWMTYwSDQ4djI5OGMwIDMuMyAyLjcgNiA2IDZoMzQwYzMuMyAwIDYtMi43IDYtNnoiLz4KPC9zdmc+');
      color: #6a759a;
    }
  }

  ssi-time-picker {
    display: block;
    margin: 0 10px 0 0;
  }

  .scheduling-posts-delete {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f4f4fa;
    height: 46px;
    min-width: 46px;
    border-radius: 10px;
    border: 1px solid #f4f4fa;
    margin: 0;
    padding: 0 10px;
    cursor: not-allowed;

    i,
    p {
      color: #838eab;
      font-size: 14px;
    }

    p {
      margin: 0 0 0 8px;
      white-space: nowrap;
      font-weight: 900;
      text-rendering: geometricPrecision;
    }

    &-active {
      background-color: #fff1ed;
      border-color: #cc3300;
      cursor: pointer;

      i {
        color: #cc3300;
      }

      p {
        color: $lmode-body-text;
      }
    }
  }
}
