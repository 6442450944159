@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../angularjs/common/sass/variables';
@import '../../../../../../angularjs/common/sass/mixins';
@import '~/styles/colors';

ssi-advertising-targeting-builder {
  width: 100%;
  display: block;

  .targeting-options {
    display: flex;
    flex-wrap: wrap;
    min-height: 300px;

    &:before {
      content: '';
      width: 100%;
      order: 2;
    }

    li {
      $option-padding: 2px;
      background-color: $dgrey-background;
      color: #87959e;
      font-weight: normal;
      width: calc(16.6% - (#{$option-padding} * 2));
      text-align: center;
      padding: 40px 10px;
      margin: $option-padding;
      @include align-vertical(auto);
      position: relative;
      cursor: pointer;
      height: 120px;
      order: 3;

      &.compulsory {
        order: 1;
      }

      &.active {
        background-color: $brand-warning;
        color: #fff;
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.3;

        &.active {
          background-color: $brand-warning;
          color: #fff;
          opacity: 0.3;
        }
      }

      > span {
        cursor: pointer;
      }

      * {
        cursor: auto;
      }
    }
  }

  .advertising-text-muted {
    color: #a6b7c0;
  }

  $targeting-option-panel-width: 500px;

  .targeting-option-panel {
    width: $targeting-option-panel-width;
    border: solid 2px $brand-warning;
    border-radius: 15px;
    position: absolute;
    z-index: 10;
    background-color: #fff;
    left: calc(-#{$targeting-option-panel-width} / 2 + 50%);
    top: 70%;
  }

  .targeting-option-panel-inner {
    background-color: #f9f9f9;
    margin: 25px;
    border-radius: 15px;
    max-height: 250px;
    overflow-y: auto;
  }

  .targeting-option-panel-close {
    position: absolute;
    bottom: -17px;
    left: calc(50% - 50px);
  }
}

.ui-radio {
  position: relative;
  display: inline-block;
  min-width: 17px;
  min-height: 15px;

  input[type='radio'] {
    cursor: pointer;
    position: absolute;
    top: -8px;
    left: -8px;
    opacity: 0;
    outline: none;
    z-index: 3;
    width: 31px;
    height: 31px;
  }

  label {
    cursor: auto;
    position: relative;
    display: block;
    outline: none;
    transform: scale(1.3);

    &:before {
      content: '';
      position: absolute;
      background: #fff;
      border: 1px solid #d4d4d5;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      left: 0px;
    }

    &:after {
      content: '' !important;
      border: none;
      position: absolute;
      width: 15px;
      height: 15px;
      line-height: 15px;
      left: 0px;
      border-radius: 50%;
      transform: scale(0.46666667);
      background-color: transparent;
    }
  }

  .ui-radio-checked ~ label:after {
    background-color: #a6b7c0;
  }
}

.audience-table {
  margin-bottom: 75px;
}

.audience-table-row {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;

  > .audience-table-col {
    flex: 0 0 calc(50% - 63px);
    text-align: center;
    border-right: 2px solid #57616a;

    &:first-child {
      flex: 0 0 126px;
      width: 126px;
      text-align: left;
    }

    &:last-child {
      border-right-width: 0;
    }
  }
}

.audience-table-col p {
  margin: 0;
}

.audience-table-head {
  border-bottom: 2px solid #57616a;
}

.audience-table-head-main {
  color: #fff;
  padding: 8px 0 20px;
  font-weight: 500;
}

.audience-table-head-name {
  color: #87959e;
  padding: 8px 0 20px;
  font-size: 13px;
  font-weight: 700;
}

.audience-table-body {
  .audience-table-row {
    border-bottom: 2px solid #57616a;

    &:last-child {
      border-bottom-width: 0;
    }

    .audience-table-col {
      padding: 40px 0;

      &:first-child {
        padding: 40px 15px 40px 0;
      }
    }
  }

  .audience-option-label {
    display: inline-block;
    margin: 4px 1px;
    font-size: 100%;
  }
}
