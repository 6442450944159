ssi-inbox-crm-external-integration-person {
  display: flex;
  height: 100%;
  background-color: #f8f8f8;
  position: relative;
}
ssi-inbox-crm-external-integration-person .loading {
  width: 100%;
}
ssi-inbox-crm-external-integration-person iframe {
  width: 100%;
  height: 1px;
  min-height: 100%;
  border: 0;
}
ssi-inbox-crm-external-integration-person .person-details-container,
ssi-inbox-crm-external-integration-person .person-activities-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
ssi-inbox-crm-external-integration-person .person-activities-container {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  z-index: 2;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s;
  background-color: inherit;
}
ssi-inbox-crm-external-integration-person .person-activities-container.visible {
  max-height: 100%;
}
ssi-inbox-crm-external-integration-person .person-key-value-list,
ssi-inbox-crm-external-integration-person .person-activities {
  padding: 20px;
  font-size: 12px;
  flex: 1;
  overflow-y: auto;
}
ssi-inbox-crm-external-integration-person .person-key-value-list dt {
  text-transform: uppercase;
  color: #02acc1;
  margin-bottom: 3px;
}
ssi-inbox-crm-external-integration-person .person-key-value-list dd {
  color: #3a3d3e;
  margin: 0 0 20px;
}
ssi-inbox-crm-external-integration-person .person-key-value-list a {
  color: #3a3d3e;
  text-decoration: none;
}
ssi-inbox-crm-external-integration-person .section-footer {
  background-color: #4a4c4d;
  color: white;
  text-align: center;
  padding: 15px;
  border-right: solid 10px #02acc1;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: normal;
  cursor: pointer;
}
ssi-inbox-crm-external-integration-person .section-footer:hover {
  background-color: #3e3f40;
}
ssi-inbox-crm-external-integration-person .person-activity {
  padding: 8px 0;
}
ssi-inbox-crm-external-integration-person .person-activity:not(:last-child) {
  border-bottom: solid 1px #ced9df;
}
ssi-inbox-crm-external-integration-person .person-activity-header {
  font-weight: bold;
  margin-bottom: 5px;
  color: #596065;
}
ssi-inbox-crm-external-integration-person .person-activity-body {
  font-weight: normal;
  color: #778994;
}