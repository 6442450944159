ssi-top-post-view .top-post-view-container {
  position: relative;
  display: flex;
  padding: 40px 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.2);
  width: 100%;
}
ssi-top-post-view .top-post-view-container .view-post-link {
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #eff2fd;
  padding: 5px 25px;
}
ssi-top-post-view .top-post-view-container .view-post-link a {
  color: #43537f;
  font-size: 12px;
  font-weight: 900;
  line-height: 14px;
}
@media print {
  ssi-top-post-view .top-post-view-container .view-post-link a:after {
    content: none !important;
  }
}
ssi-top-post-view .top-post-view-container .left {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-right: 15px;
}
ssi-top-post-view .top-post-view-container .left img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
ssi-top-post-view .top-post-view-container .middle {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  margin-right: 15px;
}
ssi-top-post-view .top-post-view-container .middle .metadata {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
ssi-top-post-view .top-post-view-container .middle .metadata > * {
  margin-right: 10px;
}
ssi-top-post-view .top-post-view-container .middle .metadata a {
  color: #14bae3;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
}
@media print {
  ssi-top-post-view .top-post-view-container .middle .metadata a:after {
    content: none !important;
  }
}
ssi-top-post-view .top-post-view-container .middle .metadata .icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fd;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  color: #14bae3;
}
ssi-top-post-view .top-post-view-container .middle .metadata .timestamp {
  color: #43537f;
  font-size: 10px;
  font-weight: 900;
  line-height: 24px;
}
ssi-top-post-view .top-post-view-container .middle .content p {
  margin: 0;
  max-height: 45px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #101525;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
}
ssi-top-post-view .top-post-view-container .right {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-right: 15px;
}
ssi-top-post-view .top-post-view-container .right .gallery {
  position: relative;
}
ssi-top-post-view .top-post-view-container .right .gallery img,
ssi-top-post-view .top-post-view-container .right .gallery .video-icon-container {
  height: 80px;
  width: 120px;
  border-radius: 10px;
  box-shadow: 0 3px 5px 0 rgba(131, 142, 171, 0.35);
}
ssi-top-post-view .top-post-view-container .right .gallery img.stacked,
ssi-top-post-view .top-post-view-container .right .gallery .video-icon-container.stacked {
  position: absolute;
}
ssi-top-post-view .top-post-view-container .right .gallery .video-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #14bae3;
  background-color: #f8f9fd;
}
ssi-top-post-view .top-post-view-container .right .gallery .video-icon-container i {
  font-size: 60px;
}
ssi-top-post-view .top-post-view-container .right .gallery .media-items-count {
  position: absolute;
  right: -20px;
  top: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  color: #14bae3;
  font-size: 10px;
  font-style: italic;
  font-weight: 900;
  line-height: 12px;
  box-shadow: 0 5px 15px 0 rgba(116, 118, 132, 0.25);
}