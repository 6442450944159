@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-toggle-switch {
  .field-label {
    padding: 0 20px;
    text-transform: uppercase;
    margin-bottom: 1rem;
    color: $dmode-body-text;
    letter-spacing: 1px;
    font-size: 10px;
    font-weight: bold;
  }
  .toggle-switch-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: $lmgrey-background;
    padding: 10px 10px 10px 20px;
    border-radius: 50px;

    span {
      color: $lmode-body-text;
      font-weight: 900;
      padding-right: 10px;
      font-size: 14px;
    }

    &.active {
      background-color: #e4faff;
      span {
        color: $primary-active;
      }
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
    flex: 0 0 auto;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .slider {
        background-color: $primary-active;

        &:before {
          background-color: #0599bd;
          transform: translateX(16px);
        }
      }

      &:checked:focus + .slider {
        box-shadow: 0 0 1px $primary-active;
      }
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #d7dbe4;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: '';
      height: 16px;
      width: 16px;
      left: 4px;
      bottom: 4px;
      background-color: #fff;
      transition: 0.4s;
    }

    &.round {
      border-radius: 34px;
    }

    &:before {
      border-radius: 50%;
    }

    &.disabled {
      cursor: not-allowed;
    }
  }

  label {
    margin: 0;
  }
}
