@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-cl-date-time-picker {
  .cl-date-time-picker-container {
    .body {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      > * {
        margin: 10px;
        margin-bottom: 30px;
        flex: 0 0 260px;
      }
    }
  }
}
