@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../angularjs/common/sass/variables';

$unit: 2.5px;

ssi-live-chat-inline-notes {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  margin: ($unit * 4) 10%;

  * {
    display: flex;
  }

  // span {
  //   & > span {
  //     & + span {
  //       margin-left: ($unit * 1);
  //     }
  //   }
  // }

}
