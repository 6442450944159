@import "./src/apps/angularjs/common/sass/global-variables.scss";
.table-form-snippet {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  & > *:first-child {
    width: 25%;
    flex: 0 1 25%;
  }

  & > *:nth-child(2) {
    width: 48%;
    flex: 0 2 48%;
  }

  & > *:last-child {
    width: 25%;
    flex: 0 1 25%;
  }
}
