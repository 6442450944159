ssi-sentiment-text ssi-gauge-chart {
  position: relative;
  display: block;
}
ssi-sentiment-text mwl-gauge {
  display: block;
  margin-bottom: -50%;
  padding-bottom: 10%;
}
ssi-sentiment-text mwl-gauge .gauge {
  transform: perspective(1px) translateZ(0);
}
ssi-sentiment-text mwl-gauge .gauge .dial {
  stroke: rgb(238, 238, 238);
  stroke-width: 20;
  fill: rgba(0, 0, 0, 0);
}
ssi-sentiment-text mwl-gauge .gauge [class^=value-] {
  stroke-width: 20;
  fill: rgba(0, 0, 0, 0);
}
ssi-sentiment-text mwl-gauge .gauge .value-negative {
  stroke: rgb(217, 83, 79);
}
ssi-sentiment-text mwl-gauge .gauge .value-semiNegative {
  stroke: rgb(233, 134, 131);
}
ssi-sentiment-text mwl-gauge .gauge .value-neutral {
  stroke: rgb(167, 183, 185);
}
ssi-sentiment-text mwl-gauge .gauge .value-semiPositive {
  stroke: rgb(137, 208, 219);
}
ssi-sentiment-text mwl-gauge .gauge .value-positive {
  stroke: rgb(84, 189, 205);
}
ssi-sentiment-text mwl-gauge .gauge .value-text {
  fill: #596065;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 15px;
  transform: translate(0, -5px);
}
ssi-sentiment-text .mwl-gauge-label {
  position: absolute;
  bottom: calc(50% + 15px);
  left: 0;
  right: 0;
  color: #596065;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 15px;
  margin-top: -15px;
}
ssi-sentiment-text .gauge-meta {
  position: relative;
  z-index: 1;
}