ssi-toggle-switch .field-label {
  padding: 0 20px;
  text-transform: uppercase;
  margin-bottom: 1rem;
  color: #b4bbce;
  letter-spacing: 1px;
  font-size: 10px;
  font-weight: bold;
}
ssi-toggle-switch .toggle-switch-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f0f3f9;
  padding: 10px 10px 10px 20px;
  border-radius: 50px;
}
ssi-toggle-switch .toggle-switch-container span {
  color: #43537f;
  font-weight: 900;
  padding-right: 10px;
  font-size: 14px;
}
ssi-toggle-switch .toggle-switch-container.active {
  background-color: #e4faff;
}
ssi-toggle-switch .toggle-switch-container.active span {
  color: #14bae3;
}
ssi-toggle-switch .switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  flex: 0 0 auto;
}
ssi-toggle-switch .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
ssi-toggle-switch .switch input:checked + .slider {
  background-color: #14bae3;
}
ssi-toggle-switch .switch input:checked + .slider:before {
  background-color: #0599bd;
  transform: translateX(16px);
}
ssi-toggle-switch .switch input:checked:focus + .slider {
  box-shadow: 0 0 1px #14bae3;
}
ssi-toggle-switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d7dbe4;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
ssi-toggle-switch .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  transition: 0.4s;
}
ssi-toggle-switch .slider.round {
  border-radius: 34px;
}
ssi-toggle-switch .slider:before {
  border-radius: 50%;
}
ssi-toggle-switch .slider.disabled {
  cursor: not-allowed;
}
ssi-toggle-switch label {
  margin: 0;
}