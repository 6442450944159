ssi-custom-widget-field {
  font-size: 1rem;
}
ssi-custom-widget-field .cw-field {
  position: relative;
}
ssi-custom-widget-field .cw-field-trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #838eab;
  background-color: #f4f4fa;
  border: 0.1em solid #f4f4fa;
  border-radius: 10em;
  width: 100%;
  height: 3.6em;
  padding: 0 1.9em;
  margin: 0 0 1.4em;
  text-align: left;
}
ssi-custom-widget-field .cw-field-trigger.cw-field-trigger-pointer {
  cursor: auto;
  border-color: #f4f4fa;
}
ssi-custom-widget-field .cw-field-trigger ~ .cw-field-trigger + .cw-dropdown {
  top: 5em;
}
ssi-custom-widget-field .cw-field-trigger-active {
  border-color: #14bae3;
  background-color: #e7f8fc;
}
ssi-custom-widget-field .cw-field-trigger-active .cw-field-trigger-label {
  color: #101525;
  font-weight: 900;
}
ssi-custom-widget-field .cw-field-trigger-icon {
  color: #838eab;
  font-size: 1.2em;
}
ssi-custom-widget-field .cw-field-meta {
  margin-top: -0.4rem;
  border-color: #e7f8fc;
  background-color: #e7f8fc;
}
ssi-custom-widget-field .cw-field-meta .cw-field-trigger-icon {
  cursor: pointer;
  font-size: 1em;
}
ssi-custom-widget-field .cw-field-meta .cw-field-trigger-label {
  color: #101525;
  font-weight: 900;
}
ssi-custom-widget-field .cw-field-trigger-action-wrap {
  border-width: 0;
  background-color: transparent;
  padding: 0;
  margin: 0;
}
ssi-custom-widget-field .cw-field-trigger-action {
  color: #838eab;
  font-size: 1em;
  padding: 1em;
}
ssi-custom-widget-field .cw-field-trigger-action:active {
  color: #14bae3;
}
ssi-custom-widget-field .cw-field-trigger-label {
  flex: 1 0 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-rendering: geometricPrecision;
  font-size: 1.2em;
  margin: 0;
  flex: 1 1 auto;
  color: #838eab;
  font-weight: 700;
}
ssi-custom-widget-field .cw-dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  background-color: #fff;
  max-height: 35em;
  width: 37em;
  box-shadow: 0 0.5em 1em rgba(131, 142, 171, 0.35);
  z-index: 1;
  border-radius: 1.5em;
  padding: 3em 1.8em 4em 2em;
}
ssi-custom-widget-field .cw-dropdown-meta {
  padding: 4em 1.8em 3em 2em;
}
ssi-custom-widget-field .cw-dropdown-filter-wrap {
  margin: 0 1em 3em;
}
ssi-custom-widget-field .cw-dropdown-filter {
  width: 100%;
  font-size: 1.4em;
  border: 1px solid #c3c9d8;
  background-color: #f8f9fd;
  border-radius: 1rem;
  height: 4.6rem;
  padding: 0 1rem;
  text-indent: 2rem;
  color: #43537f;
  font-weight: 700;
  text-rendering: geometricPrecision;
}
ssi-custom-widget-field .cw-dropdown-filter::placeholder {
  color: #838eab;
}
ssi-custom-widget-field .cw-dropdown-list {
  overflow: auto;
  margin: 0;
  padding: 0;
}
ssi-custom-widget-field .cw-dropdown-list-item-check {
  position: absolute;
  display: none;
  top: 0;
  bottom: 0;
  margin: auto;
  font-size: 1.2em;
  line-height: 1.6rem;
  right: 1.2rem;
  height: 1.6rem;
  color: #fff;
  z-index: 2;
}
ssi-custom-widget-field .cw-dropdown-list-item,
ssi-custom-widget-field .cw-popup-dropdown-menu-item {
  position: relative;
  display: block;
}
ssi-custom-widget-field .cw-dropdown-list-item label,
ssi-custom-widget-field .cw-popup-dropdown-menu-item label {
  display: flex;
  align-items: center;
  color: #43537f;
  height: 3.6rem;
  border-radius: 1rem;
  padding: 0 5rem 0 2rem;
  color: #43537f;
  font-size: 1.4em;
  font-weight: 900;
  text-rendering: geometricPrecision;
  margin: 0 0 0.3em;
  cursor: pointer;
}
ssi-custom-widget-field .cw-dropdown-list-item label:hover, ssi-custom-widget-field .cw-dropdown-list-item label:focus,
ssi-custom-widget-field .cw-popup-dropdown-menu-item label:hover,
ssi-custom-widget-field .cw-popup-dropdown-menu-item label:focus {
  background-color: #f8f9fd;
}
ssi-custom-widget-field .cw-dropdown-list-item label:after,
ssi-custom-widget-field .cw-popup-dropdown-menu-item label:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 1rem;
  margin: auto;
  display: block;
  height: 1.6rem;
  width: 1.6rem;
  border: 0.1rem solid #838eab;
  background-color: #fff;
  border-radius: 0.2em;
}
ssi-custom-widget-field .cw-dropdown-list-item input,
ssi-custom-widget-field .cw-popup-dropdown-menu-item input {
  position: absolute;
  visibility: hidden;
}
ssi-custom-widget-field .cw-dropdown-list-item input:checked + label,
ssi-custom-widget-field .cw-popup-dropdown-menu-item input:checked + label {
  color: #14bae3;
}
ssi-custom-widget-field .cw-dropdown-list-item input:checked + label:after,
ssi-custom-widget-field .cw-popup-dropdown-menu-item input:checked + label:after {
  background-color: #14bae3;
  border-color: #14bae3;
}
ssi-custom-widget-field .cw-dropdown-list-item input:checked ~ .cw-dropdown-list-item-check,
ssi-custom-widget-field .cw-popup-dropdown-menu-item input:checked ~ .cw-dropdown-list-item-check {
  display: block;
}