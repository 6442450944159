@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-analytics ssi-survey ssi-report ssi-survey-responses {
  .survey-responses-container {
    .table-container {
      padding: 60px 30px 60px 30px;
      background-color: #fff;
      border-radius: 10px;

      .head-cell {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;
        padding: 0 5px 0 10px;
      }

      .body-cell {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;
        padding: 0 5px 0 10px;
        border-bottom: 2px solid #e0e7f4;

        &.row-even {
          background-color: $grey-background;
        }

        span {
          color: #43537f;
          font-size: 12px;
          font-weight: 900;
          margin: 9px 0;

          &.bold {
            color: #101525;
            font-size: 14px;
            font-weight: 900;
          }
        }
      }

      ssi-paginator {
        padding-top: 40px;
      }

      ssi-no-results {
        .no-results {
          padding: 100px;
        }
      }
    }
  }
}
