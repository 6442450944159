@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-message {
  font-size: 1rem;
  width: max(100%, 350px);
  max-width: 100%;

  .shared-post-container {
    padding: 5px 20px;
  }

  .bottom-line-container {
    .bottom-line {
      height: 2px;
      width: 100%;
      border-radius: 25px;
      background-color: $grey-background;
      margin-top: 10px;
    }
  }
}

$message-padding-gutter: 1.6em; // needs to be fixed with private message component
$message-border-radius: 1.2em; // needs to be fixed with private message component

.message-box-message-header {
  display: flex;
  flex-direction: column;
  margin: 0 calc($message-padding-gutter / -2);

  > * {
    margin-bottom: 0.9em;
    white-space: nowrap;
  }

  hr {
    margin: 0;
  }
}

.message-box-message-header-translate {
  align-self: flex-start;
}

.message-box-message-header-meta-wrap {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 0 0.6em;
}

.message-box-message-header-meta {
  color: $lmode-body-text;
  padding: 0;
  margin: 0;
  font-weight: 900;

  li {
    display: inline-block;
    vertical-align: middle;
    max-width: 25em;

    & > * {
      text-overflow: ellipsis;
      overflow: hidden;
      text-rendering: geometricPrecision;
    }

    &:last-child .message-box-message-header-meta-item:after {
      content: normal;
    }
  }

  p {
    margin-bottom: 0;
  }
}

.message-box-message-header-meta-title-wrap {
  line-height: 2em;
  margin: 0 0.4em 0 0;
  cursor: pointer;
}

.message-box-message-header-meta-title {
  color: $primary-active;
  font-size: 1.6em;
  font-weight: 700;
  line-height: inherit;

  &-big {
    font-size: 1.9em;
  }
}

.message-box-message-header-meta-item {
  color: inherit;
  font-size: 1em;
  font-weight: inherit;
  margin: 0;
  line-height: 2em;

  &:after {
    content: '|';
    margin: 0 0.6em;
  }
}

.message-box-message-header-meta-ad {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 6px;
  background-color: $grey-background;
  color: #43537f;
  border-radius: 4px;
  height: 20px;
  width: 65px;
  margin: 0 3px 0 0;

  span,
  i {
    color: inherit;
  }

  span {
    font-size: 8px;
    line-height: 0;
    text-transform: uppercase;
    font-weight: 900;
    text-rendering: geometricPrecision;
  }

  i {
    font-size: 10px;
  }
}

.message-box-message-header-meta-badge {
  color: inherit;
  font-size: 1em;
  font-weight: inherit;
  background-color: $grey-background;
  padding: 0.4em 0.6em;
  border-radius: 1em;
  margin: 0 0.6em 0 0;

  &.fa,
  &.ssi {
    color: $primary-active;
    font-weight: 700;
    border-radius: 50%;
    height: 2em;
    width: 2em;
    line-height: 2em;
    text-align: center;
    vertical-align: middle;
    padding: 0;
    margin: 0 0.3em 0 0;

    &.fa-youtube-play {
      color: #282828;
      height: auto;
      width: 100%;
      border-radius: 0;
      background: none;
      font-size: 23px;
      line-height: normal;
    }
  }

  .ssi {
    margin: 0 0.3em 0 0;
  }
}

.message-box-message-header-meta-verified {
  font-size: 1.2em;
  vertical-align: text-top;
  margin: 0 0.2rem 0 0;
}

p.message-box-message-header-meta-badge:empty {
  display: none;
}

.message-box-expandable-meta {
  position: relative;
  margin: 1.7em (-$message-padding-gutter) -1em 0;
  padding: 1.9em 0 1em 0;
  border-top: 0.2em solid $grey-background;

  &:last-child {
    border-bottom-left-radius: $message-border-radius;
    border-bottom-right-radius: $message-border-radius;
  }
}

// .message-box-expandable-meta-hide {
//   position: absolute;
//   background-color: #fff;
//   color: $primary-active;
//   height: 2em;
//   width: 2em;
//   border-radius: 50%;
//   text-align: center;
//   line-height: 1.9em;
//   vertical-align: middle;
//   cursor: pointer;

//   &:hover,
//   &:active {
//     color: darken($primary-active, 10%);
//   }
// }

.message-box-stats {
  display: inline-block;
  margin: 0 -0.8em;
  padding: 0;
  vertical-align: middle;
  vertical-align: bottom;

  > li {
    display: inline-block;
  }
}

.message-box-stat {
  margin: 0 0.8em 0;
  line-height: 1.5em;
}

.message-box-stat-icon {
  font-size: 1.4em;
  color: $lmode-body-text;
  font-weight: 500;
}

.message-box-stat-value {
  vertical-align: super;
  color: $lmode-body-text;
  font-weight: 900;
  font-size: 1em;
  white-space: nowrap;
  margin: 0 0 0 0.1em;
}

// retweet
.retweet-box {
  position: absolute;
  right: 0;
  margin: 0.6em 0 0;
  background-color: #fff;
  border-radius: 1.2em;
  width: 200px;
  box-shadow: 0 0 1.5em rgba(231, 232, 238, 0.65);
  padding: 2.6em 2em;
  z-index: 999999999;
}

.retweet-confirmation {
  text-align: center;
  background-color: #5b86f7;
  color: white;
  i {
    font-size: 2.8em;
    margin-bottom: 0.4em;
  }
  p {
    font-size: 1.2em;
    font-weight: 700;
    line-height: 1.8em;
    margin: 0;
  }
}

.retweet-box-close {
  position: absolute;
  right: 0.5em;
  top: 0.5em;
  display: flex;
  border-radius: 50%;
  height: 2em;
  width: 2em;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  color: white;
  cursor: pointer;

  .ssi {
    font-size: 0.8em;
  }
}

.retweet-box-actions {
  margin: 0;
  padding: 0;

  & > li {
    display: block;
  }
}

.retweet-box-action {
  color: $lmode-body-text;
  &-active {
    padding-bottom: 0.5em;

    & > p {
      color: $primary-active;
    }
  }

  p {
    width: 100%;
    display: inline-block;
    font-size: 1.2em;
    font-weight: 700;
    margin: 0;
    padding: 0.5em 0 0.7em;
    cursor: pointer;

    &:hover,
    &:focus {
      color: $primary-active;
    }
  }

  &:last-child {
    border-bottom-width: 0;

    p {
      padding: 0.5em 0;
    }
  }
  i {
    color: $lmode-helper-text;
  }
  .ssi-arrow-down-correct {
    margin-left: 1.2em;
    color: $primary-active;
    float: right;
    top: 7px;
    position: relative;
  }
}

.retweet-box-action-icon {
  font-size: 1.6em;
  position: relative;
  top: 2px;
  margin-right: 0.4em;
}

.retweet-box-sub-actions {
  margin: 0 -0.8em 0 0;
  padding: 0 0.3em 0.6em 0;
  max-height: 130px;
  overflow-y: auto;
  overflow-x: hidden;
  border-bottom: 2px solid $lmgrey-background;
}

.retweet-box-sub-action {
  display: block;
  margin: 0.2em 0;
  width: 100%;
  &-disabled {
    cursor: not-allowed;
  }
  &:hover {
    background-color: $grey-background;
    color: $primary-active;
    border-radius: 15px;
  }

  p {
    padding: 0.4em 0.8em 0.6em 0.4em;
    word-break: break-word;
    hyphens: auto;
  }

  .retweet-box-action-icon {
    font-size: 1.2em;
  }
}

.message-box-message-prompt-delete {
  background-color: #3e4764;

  ul {
    margin: 0;
    padding: 1.8em 3.3em;
  }

  li {
    display: inline-block;
    color: #f3f5fa;
    font-weight: 900;

    p {
      display: inline-block;
      margin: 0;
      font-size: 1em;
      font-weight: inherit;
      text-rendering: geometricPrecision;
      vertical-align: bottom;
    }

    &:after {
      content: '|';
      display: inline-block;
      margin: 0 0.6em;
      font-weight: 400;
    }

    &:last-child:after {
      content: normal;
    }
  }

  &-private {
    ul {
      padding: 1.8em 1.6em;
    }
  }
}

.message-box-message-prompt-delete-heading {
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.sentiment-and-tags {
  display: flex;
  margin: 1em 0 0.6em 0;
  flex-wrap: wrap;

  &.padding-bottom {
    padding-bottom: 10px;
  }

  .chip-item {
    display: flex;
    border-radius: 4px;
    margin-right: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    i {
      font-size: 14px;
      padding: 3px;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    span {
      display: flex;
      align-items: center;
      padding: 0 10px;
      color: $lmode-title-text;
      font-size: 12px;
      font-weight: 900;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .ssi-delete-close {
      margin-top: 3px;
      margin-right: 4px;
      font-size: 8px;
      cursor: pointer;
    }
    &.tag {
      background-color: #f8f9fd;
      border: 1px solid #c3c9d8;
    }
    &.view-more {
      text-decoration: underline;
      color: #14bae3;
      background: none;
      border: none;
      span {
        color: #14bae3;
      }
    }
  }
}
