ssi-notes {
  display: block;
}

.notes-component-wrapper {
  display: flex;
  padding: 25px;
}
.notes-component-wrapper .notes-list-wrapper {
  position: relative;
  flex: 0 1 55%;
  width: 50%;
}
.notes-component-wrapper .notes-list-wrapper.notes-list-wrapper-fade:after {
  content: "";
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  height: 90px;
  background: linear-gradient(transparent, #f9fafb);
  pointer-events: none;
}

.notes-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: 0 auto;
  max-width: 290px;
  text-align: center;
}
.notes-empty .notes-empty-icon {
  font-size: 70px;
  color: #a6b7c0;
  margin: 0 0 15px;
}
.notes-empty p {
  color: #a6b7c0;
  font-weight: 500;
}

.validate-post-notes-list {
  list-style: none;
  padding: 15px 0 85px 15px;
  margin: -15px 0 0 -15px;
  max-height: 430px;
  overflow-y: scroll;
  overflow-x: visible;
}
.validate-post-notes-list .note {
  min-height: 100px;
  padding: 15px 25px;
  margin: 0 10px 10px 0;
  background-color: #fff;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.05);
  border-left: 5px solid #79cfd3;
}
.validate-post-notes-list .note.note-own {
  border-left: 0;
  border-right: 5px solid #ebf2f5;
  text-align: right;
}
.validate-post-notes-list .note .note-header {
  font-weight: 700;
  font-size: 10px;
  color: #bfcbd1;
  padding: 0;
  margin: 0 0 6px;
}
.validate-post-notes-list .note .note-header li {
  display: inline-block;
  margin-right: 6px;
  line-height: 1;
  text-transform: uppercase;
}
.validate-post-notes-list .note .note-header li:after {
  padding-left: 6px;
  content: "|";
}
.validate-post-notes-list .note .note-header li:last-child {
  margin-right: 0;
}
.validate-post-notes-list .note .note-header li:last-child:after {
  content: normal;
}
.validate-post-notes-list .note .note-header .delete-button {
  padding: 0;
  border: 0;
  background-color: transparent;
}
.validate-post-notes-list .note .note-content {
  color: #a6b7c0;
  font-weight: 500;
}

.notes-wrapper {
  max-height: 500px;
  overflow-y: scroll;
}

.notes-input-wrapper {
  flex: 0 1 50%;
  width: 45%;
}

.notes-input {
  padding: 0 0 15px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.05);
  height: 100%;
  max-height: 320px;
  margin: 0 0 0 15px;
}

.notes-input-body {
  position: relative;
  height: calc(100% - 46px);
  min-height: 150px;
  max-height: 260px;
}
.notes-input-body textarea {
  padding: 35px 35px 0;
  margin: 0;
  height: 100% !important;
  width: 100%;
  background: none;
  display: block;
  position: relative;
  z-index: 2;
  resize: none;
  outline: none;
  border: 0;
  outline: 0;
}
.notes-input-body textarea::placeholder {
  color: #bfcbd1;
  font-style: italic;
  font-weight: 500;
}
.notes-input-body .notes-tag {
  direction: ltr;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  overflow: auto;
  border-width: 1px;
  border-style: solid;
  padding: 35px 35px 0;
  text-align: start;
  text-transform: none;
  text-indent: 0px;
  text-decoration: none solid rgb(85, 85, 85);
  letter-spacing: normal;
  word-spacing: 0px;
  tab-size: 8;
  display: block;
  overflow: hidden !important;
  white-space: pre-wrap;
  word-wrap: break-word;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  color: rgba(0, 0, 0, 0);
  z-index: 2;
  pointer-events: none;
}
.notes-input-body .notes-tag a {
  background: #43ade8;
  border-radius: 4px;
  color: #fff;
  margin-left: -1px;
  font-size: 14px;
  vertical-align: text-bottom;
}

.notes-input-footer {
  text-align: center;
  margin: 0 35px;
  padding-top: 15px;
  border-top: 2px solid #f9fafb;
}
.notes-input-footer .submit-note {
  background-color: #14bae3;
  border-radius: 20px;
  font-size: 12px;
  color: #fff;
  padding: 6px 20px;
  border: 0;
  min-width: 125px;
  font-weight: 700;
}
.notes-input-footer .submit-note.submit-note-disabled {
  background-color: #f9f9f9;
  color: #ced6db;
}
.notes-input-footer .submit-note.hover, .notes-input-footer .submit-note.focus {
  background-color: #14bae3;
}

.notes-component-wrapper-ad .notes-list-wrapper.notes-list-wrapper-fade:after {
  content: normal;
}
.notes-component-wrapper-ad .notes-empty .notes-empty-icon {
  color: #dee4e8;
}
.notes-component-wrapper-ad .notes-empty p {
  color: #ced7dc;
}
.notes-component-wrapper-ad .notes-input-body textarea::placeholder {
  color: #cbdae0;
}
.notes-component-wrapper-ad .notes-input {
  color: #fff;
}
.notes-component-wrapper-ad .validate-post-notes-list {
  padding: 15px 0 0 15px;
}
.notes-component-wrapper-ad .notes-input,
.notes-component-wrapper-ad .validate-post-notes-list .note {
  background-color: #3f454f;
}
.notes-component-wrapper-ad .notes-input-footer {
  border-color: #4e555e;
}
.notes-component-wrapper-ad .note-header {
  color: #cbdae0;
}