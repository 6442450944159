ssi-progress-bar {
  width: 100%;
}
ssi-progress-bar .progress-bar-placeholder {
  width: 100%;
  background-color: #f4f4fa !important;
  border-radius: 10px;
}
@media print {
  ssi-progress-bar .progress-bar-placeholder {
    -webkit-print-color-adjust: exact;
  }
}
ssi-progress-bar .progress-bar-placeholder.reversed {
  display: flex;
  justify-content: flex-end;
}
ssi-progress-bar .progress-bar-placeholder .progress {
  height: 100%;
  border-radius: inherit;
  transition: width 1s ease-in-out;
  -webkit-print-color-adjust: exact;
}
@media print {
  ssi-progress-bar .progress-bar-placeholder .progress {
    background-color: black !important;
  }
}