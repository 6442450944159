ssi-social-push-mode .social-push-mode-input-wrap {
  margin-bottom: 14px;
}
ssi-social-push-mode .social-push-mode-input-title,
ssi-social-push-mode .social-push-mode-input-label {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 900;
  line-height: 2.5em;
  color: #b4bbce;
  letter-spacing: 1px;
}
ssi-social-push-mode .social-push-mode-input-title {
  padding: 0 30px;
  margin: 0 0 5px;
}
ssi-social-push-mode .social-push-mode-input-label {
  padding: 0 25px;
}
ssi-social-push-mode .form-field label {
  width: 100%;
  padding: 0 3em;
  display: block;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 900;
  line-height: 2.5em;
  color: #b4bbce;
  letter-spacing: 1px;
  box-sizing: border-box;
}