ssi-list-select2 .list-select2-container .filter-container {
  margin-bottom: 15px;
  position: relative;
  width: 100%;
}
ssi-list-select2 .list-select2-container .filter-container i {
  position: absolute;
  color: #838eab;
  top: 12px;
  right: 12px;
  font-size: 14px;
  font-weight: 700;
}
ssi-list-select2 .list-select2-container .toggle-all-container {
  width: 100%;
  padding: 0 10px;
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
}
ssi-list-select2 .list-select2-container ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
ssi-list-select2 .list-select2-container ul li {
  margin: 5px 0;
}
ssi-list-select2 .list-select2-container ul li.selected > .main-content span {
  color: #14bae3;
}
ssi-list-select2 .list-select2-container ul li .main-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  color: #43537f;
  font-size: 14px;
  font-weight: 700;
  border-radius: 10px;
  height: 34px;
  white-space: nowrap;
  border: none;
}
ssi-list-select2 .list-select2-container ul li .main-content:hover {
  background-color: #f8f9fd;
}
ssi-list-select2 .list-select2-container ul li .main-content .icon-container {
  margin-right: 10px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
ssi-list-select2 .list-select2-container ul li .main-content .icon-container img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
ssi-list-select2 .list-select2-container ul li .main-content span {
  padding: 0 10px;
  border-radius: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
ssi-list-select2 .list-select2-container ul li .main-content .tick-container {
  position: relative;
  margin-right: 10px;
  margin-left: auto;
  height: 16px;
  width: 16px;
  border-radius: 2px;
  border: 1px solid #838eab;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
ssi-list-select2 .list-select2-container ul li .main-content .tick-container i {
  font-size: 10px;
  font-weight: 500;
  color: #fff;
}
ssi-list-select2 .list-select2-container ul li .main-content .tick-container.checked {
  border: 1px solid #14bae3;
  background-color: #14bae3;
}
ssi-list-select2 .list-select2-container ul li .main-content .tick-container.radio {
  height: 18px;
  width: 18px;
  border-radius: 50%;
}
ssi-list-select2 .list-select2-container ul li .main-content .tick-container.radio i {
  display: none;
}
ssi-list-select2 .list-select2-container ul li .main-content .tick-container.radio.checked {
  background-color: #fff;
}
ssi-list-select2 .list-select2-container ul li .main-content .tick-container.radio.checked i {
  padding: 1px;
  display: block;
  color: #14bae3;
  border-radius: 50%;
  background-color: #14bae3;
}
ssi-list-select2 .list-select2-container ul li .expanded-content-container {
  padding-left: 30px;
}
ssi-list-select2 .list-select2-container ul li .expanded-content-container .filter-container {
  margin-top: 10px;
}
ssi-list-select2 .list-select2-container ul li .expanded-content-container ul {
  padding-bottom: 20px;
}