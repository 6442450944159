@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../angularjs/common/sass/variables';
@import '~/styles/variables';

ssi-inbox-link-external-crm-modal {
  b {
    font-weight: normal;
  }

  p {
    font-size: 12px;
    max-width: 400px;
    margin: auto;
  }

  .modal-body {
    min-width: 800px !important;
    padding: 0 100px !important;
    padding-bottom: 100px !important;

    .row {
      padding-top: 50px;
    }
  }

  .scroll-y {
    max-height: 200px;
    text-align: left !important;
  }

  input.form-control {
    background-color: transparent;
    color: $modal-content-text-color;
    font-weight: normal;
    margin-bottom: 10px;
    width: $query-builder-item-inner-width;
    float: left;
  }

  form a {
    float: left;
    position: relative;
    top: 9px;
    left: 7px;
  }

  .loading {
    width: 400px;
    height: 400px;
  }

  ssi-inbox-crm-external-integration-person {
    background-color: white;
    height: 350px;
    text-align: left;
  }
}
