@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../../../angularjs/common/sass/variables';
@import '../../../../../../../../angularjs/common/sass/mixins';
@import '~/styles/colors';

$paused: $primary-active;
$live: #e1b83a;
$scheduled: #8e52cc;

.close-modal {
  position: fixed;
  right: 5px;
  z-index: 99;
  background-color: white;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  top: 5px;
  width: 40px;
  height: 40px;
  padding: 8px 11px;
}

.post-modal-wrapper {
  .modal-content {
    border-radius: 25px;
    background-color: #fff;
  }

  .close-modal {
    top: 8px;
    right: 8px;
    height: 50px;
    width: 50px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

    .close-modal {
      opacity: 1;
      color: $paused;
      font-size: 14px;
      box-shadow: none;
    }
  }
}

.post-modal {
  padding: 60px 25px 35px;

  .post {
    border-radius: 20px;
  }
}

.post-stats {
  margin: 0 15px;
  display: flex;
  align-items: center; /* Align Items Vertically */
  justify-content: space-between; /* Align Items Horizontally (with equal space in between each of them */
  overflow: hidden;

  &.section {
    margin-bottom: 25px;
    background-color: white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    padding: 20px 40px;
    border-radius: 20px;
  }

  .stat {
    display: flex;
    flex-wrap: wrap;
    margin: 0 5px;
    padding: 0 5px;
    text-align: center;

    &.disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }
}

.stat.stat-primary {
  .stat-score {
    font-size: 24px;
    color: $paused;
  }
}

.stat-score {
  flex: 1 1 100%;
  font-size: 20px;
  margin: 13px auto;
  color: #a6b7bf;
  font-style: italic;
  font-weight: 400;
}

.post-stats.post-stats-paused {
  .ad-status-color {
    color: $paused;
  }

  .stat {
    .stat-label,
    .label {
      color: $paused;
    }
  }

  & + .post-ads-info {
    .ad-status-color {
      color: $paused;
    }

    button {
      color: $paused;
    }
  }

  & ~ .post-ads-action {
    color: $paused;
  }
}

.post-stats.post-stats-live {
  .ad-status-color {
    color: $live;
  }

  .stat {
    .stat-label,
    .label {
      color: $live;
    }
  }

  & + .post-ads-info {
    .ad-status-color {
      color: $live;
    }

    button {
      color: $live;
    }
  }

  & ~ .post-ads-action {
    color: $live;
  }
}

.post-stats.post-stats-scheduled {
  .ad-status-color {
    color: $scheduled;
  }

  .stat {
    .stat-label,
    .label {
      color: $scheduled;
    }
  }

  & + .post-ads-info {
    .ad-status-color {
      color: $scheduled;
    }

    button {
      color: $scheduled;
    }
  }

  & ~ .post-ads-action {
    color: $scheduled;
  }
}

.post-wrap {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px;

  .post {
    flex: 0 0 510px;
    width: 510px;
    margin: 0;
  }

  .post-stats {
    display: flex;
    justify-content: space-around;
    flex: 0 0 420px;
  }

  &.post-wrap-expanded {
    .post {
      flex: 0 0 750px;
      width: 750px;
      padding-top: 60px;
      padding-bottom: 60px;
      padding-right: 220px;

      .post-gallery {
        max-height: 190px;
        width: 170px;
      }
    }

    .post-stats {
      flex: 0 0 200px;
      width: 200px;
    }
  }

  + .notes {
    margin: 0 -25px 35px;
    padding: 25px 0 0;
    background-color: #f9fafb;
  }
}

.post-action-expand {
  border-radius: 50%;
  position: absolute;
  margin-top: -12px;
  margin-left: -12px;
  height: 40px;
  width: 40px;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  z-index: 1;
  cursor: pointer;
  border: 0;
  padding: 0;
  overflow: hidden;

  .ssi {
    display: block;
    height: 40px;
    color: $paused;
    line-height: 40px;
    vertical-align: middle;
    text-align: center;
  }
}

.stat-label {
  flex: 1 1 100%;
  font-size: 12px;
  font-weight: 600;
  color: $primary-blue;
  outline: 0;
}

.note-trigger {
  z-index: 2;

  i {
    position: relative;
    margin: 0 0 15px;
    font-size: 43px;
    display: block;
    text-align: center;
    color: $primary-blue;
    background: -webkit-linear-gradient(#0d3bc4, #88dcf9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  button {
    padding: 6px 15px;
    border-width: 0;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    cursor: pointer;
  }

  .note-label {
    position: absolute;
    margin: -8px 0 0 15px;
    display: inline-block;
    border-radius: 50%;
    background-color: $primary-active;
    color: #fff;
    height: 20px;
    width: 20px;
    font-size: 11px;
    font-weight: 700;
    line-height: 20px;
  }
}

.note-trigger-active {
  i {
    font-size: 60px;
  }

  button {
    position: absolute;
    bottom: -35px;
    right: 13px;
    z-index: 3;
  }
}

.average-score-wrap {
  .stat-label {
    font-style: italic;
  }
}

.average-score {
  display: block;
  height: 60px;
  width: 60px;
  margin: 0 auto 8px;

  .average-score-progress {
    fill: transparent;
    stroke: #eff1f3;
    stroke-width: 5px;
  }

  .average-score-progressbar {
    fill: transparent;
    stroke-width: 8px;
  }

  .average-score-meta {
    font-size: 42px;
    fill: #a6b7bf;
    font-style: italic;
    font-weight: 400;
    text-anchor: middle;
  }
}

.post-ads {
  background-color: $dgrey-background;
  margin: 0 -10px -36px;
  padding-bottom: 30px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  .post-ads-nav {
    padding: 0;
    list-style: none;
    background-color: #4e555e;
    color: #707b84;

    & + .post-ads-info {
      padding-top: 30px;
    }
  }

  .post-stats {
    justify-content: space-around;
    padding: 40px 0 20px;
  }

  &.post-ads-scheduled {
    background-color: #2d2d35;

    .post-ads-nav {
      background-color: $dmode-background;
    }

    .post-ad {
      background-color: #2d2d35;
    }

    .post-ads-action button:not(:hover):not(:focus) {
      background-color: $dmode-background;
    }
  }
}

.post-ads-info {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin: 0 0 15px;
  font-weight: 600;

  div {
    display: flex;
    padding: 0 10px;
    margin: 1em 0;
    border-right: solid 2px #979a9f;

    &:last-child {
      border: none;
    }
  }
}

.post-ads-info-lowercase {
  text-transform: capitalize;
}

.post-ad {
  float: left;
  padding: 18px 40px 22px 40px;
  border-top: 4px solid #4e555e;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 10px;
  cursor: pointer;

  i {
    margin: 0 5px 0 0;
  }

  &.active {
    background-color: $dgrey-background;
    border-color: #fff;
    color: #fff;
  }

  &.post-ad-live {
    border-color: $live;
    color: $live;
  }

  &.post-ad-paused {
    border-color: $paused;
    color: $paused;
  }

  &.post-ad-scheduled {
    border-color: $scheduled;
    color: $scheduled;
  }
}

.post-ads-action {
  text-align: center;
  color: #fff;

  button {
    color: inherit;
    border: none;
    font-weight: 600;
    border-radius: 25px;
    background-color: #4e555e;
    margin: 0 5px;
    padding: 3px 18px 3px 15px;

    span {
      vertical-align: middle;
    }

    i {
      font-size: 12px;
      margin: 0 5px 0 0;
    }
  }
}

.ad-modal {
  width: 515px;
  background-color: #424348;
  border-radius: 20px;
  color: #fff;
  text-align: center;
  padding: 60px 15px 50px 15px;
}

.ad-modal-icon {
  font-size: 46px;
  margin-bottom: 16px;
}

.ad-modal-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}

.ad-modal-meta {
  width: 230px;
  margin: 0 auto;
  font-weight: 500;
}

.ad-modal-actions {
  margin-top: 40px;

  .btn {
    width: 175px;
    padding: 12px;
    border-radius: 20px;
    margin: 0 5px;
    border: 0;

    &.btn-default {
      background-color: #505158;
      color: #fff;
    }

    &.btn-primary {
      background-color: $paused;
      color: #fff;
    }
  }
}

.manage {
  display: flex;
  justify-content: center;
  button {
    padding: 10px 30px;
    border: 1px solid #f2eaea;
    &.disapprove {
      background-color: #f7eae7;
      color: #eaaaa3;
      margin-right: 10px;
    }
    &.approve {
      background-color: #a9d0d8;
      color: #fff;
      margin-left: 10px;
    }
  }
}

.overlay-confirmation {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
  border-radius: 25px;
  color: #fff;
  i {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 10px;
    &:hover {
      cursor: pointer;
    }
  }
  &.disapproved {
    background-color: rgba(255, 95, 88, 0.9);
  }
  &.approved {
    background-color: rgba(167, 207, 215, 0.9);
  }
}
