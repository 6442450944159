@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-clock {
  .clock {
    $clock-dimensions: 120px;
    $hour-hand-length: 25px;
    $hour-hand-width: 5px;
    $clock-hand-color: $lmode-title-text;
    $minute-hand-length: 35px;
    $minute-hand-width: 1px;
    margin-bottom: 60px;

    .clock-bg {
      margin: auto;
      width: $clock-dimensions;
      height: $clock-dimensions;
    }

    .clock-hour-hand,
    .clock-minute-hand {
      margin: auto;
      transform-origin: center bottom;
    }

    .clock-hour-hand {
      width: $hour-hand-width;
      height: $hour-hand-length;
      background-color: $clock-hand-color;
      margin-top: $hour-hand-length - 110px;
      border-radius: calc($hour-hand-width / 2);
    }

    .clock-minute-hand {
      width: $minute-hand-width;
      height: $minute-hand-length;
      background-color: $clock-hand-color;
      margin-top: -$minute-hand-length;
      border-radius: calc($minute-hand-width / 2);
    }
  }
}
