ssi-conversation-resolution-confirmation {
  align-items: center;
  background-color: white;
  color: #43537f;
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  justify-content: center;
  height: 100%;
  border-radius: 12px;
}
ssi-conversation-resolution-confirmation * {
  display: flex;
}
ssi-conversation-resolution-confirmation .confirmation-message {
  align-items: center;
  flex-flow: column nowrap;
  font-weight: 500;
  justify-content: center;
}
ssi-conversation-resolution-confirmation .confirmation-message .message-icon {
  font-size: 4em;
  color: #b4bbce;
  margin-bottom: 0.15em;
}
ssi-conversation-resolution-confirmation .confirmation-message .message-text {
  flex-flow: column nowrap;
  margin-bottom: 2em;
}
ssi-conversation-resolution-confirmation .confirmation-message .message-text h1 {
  font-size: 1.4em;
  font-weight: 800;
  justify-content: center;
  margin: 0;
}
ssi-conversation-resolution-confirmation .confirmation-message .message-text h2 {
  font-size: 1em;
  font-weight: 600;
  justify-content: center;
  color: #838eab;
}
ssi-conversation-resolution-confirmation .confirmation-message .dismiss-button {
  justify-content: center;
  background-color: #14bae3;
  min-width: 120px;
}