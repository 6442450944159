ssi-stat-graph {
  font-size: 1rem;
  /* Large Variation
   ========================================================================== */
  /* Large Thin Variation
  ========================================================================== */
}
ssi-stat-graph .stat-graph-container {
  display: flex;
  flex-direction: column;
  height: 36.4em;
  width: 36.4em;
  background-color: #fff;
  box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
  border-radius: 1.2em;
  padding: 4em 3em;
  margin: 1em;
}
ssi-stat-graph .stat-graph-header {
  display: flex;
  flex: 0 0 auto;
  margin: 0 1.2em 0.5em;
}
ssi-stat-graph .stat-graph-header-heading-container {
  flex: 1 1 auto;
}
ssi-stat-graph .stat-graph-header-heading {
  font-size: 2.8em;
  line-height: 1;
  color: #101525;
  font-weight: 900;
  margin: 0;
}
ssi-stat-graph .stat-graph-header-sub {
  font-size: 1em;
  line-height: 1;
  color: #838eab;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin: 0.8em 0 0;
  text-rendering: geometricPrecision;
}
ssi-stat-graph .stat-graph-header-meta {
  flex: 1 0 auto;
  margin: 0.1em -0.9em 0;
  text-align: right;
}
ssi-stat-graph .stat-graph-network {
  display: inline-block;
  margin: 0 0.9em;
}
ssi-stat-graph .stat-graph-network .fa,
ssi-stat-graph .stat-graph-network .ssi {
  color: #838eab;
  font-size: 1.8em;
}
ssi-stat-graph .stat-graph-header-break {
  flex: 0 0 auto;
  height: 0.4em;
  background-color: #d6dff7;
  border-radius: 1em;
  border-width: 0;
  margin: 2.5em 0.2em 3em;
}
ssi-stat-graph .stat-graph-content {
  display: flex;
  flex: 1 0 auto;
  margin: 0 0 0.4em;
}
ssi-stat-graph .stat-graph-chart-container {
  flex: 0 0 auto;
}
ssi-stat-graph .stat-graph-chart-container .highcharts-container {
  margin: -1em 0 0 -1em;
}
ssi-stat-graph .stat-graph-chart-key {
  flex: 1 0 auto;
  list-style: none;
  margin: 3em 0 0;
  padding: 0 0 0 1em;
}
ssi-stat-graph .stat-graph-chart-key li {
  display: flex;
  align-items: center;
  margin: 0 0 2em;
}
ssi-stat-graph .stat-graph-chart-legend {
  display: inline-block;
  width: 1.2em;
  height: 1.2em;
  border-radius: 50%;
  margin: 0 1.2em 0 0;
}
ssi-stat-graph .stat-graph-chart-name,
ssi-stat-graph .stat-graph-chart-amount {
  display: inline-block;
  font-size: 1.4em;
  color: #101525;
  font-weight: 900;
  text-rendering: geometricPrecision;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
}
ssi-stat-graph .stat-graph-chart-amount {
  position: relative;
  display: inline-block;
  width: 3.8rem;
  margin: 0 0.2em 0 0;
  padding: 0 0.2em 0 0;
  min-height: 1.4rem;
}
ssi-stat-graph .stat-graph-chart-amount:after {
  content: "|";
  position: absolute;
  right: 0;
  top: 0;
  margin: 0 auto 0 0;
}
ssi-stat-graph .stat-graph-chart-name {
  text-transform: capitalize;
  margin: 0 0 0 0.4em;
}
ssi-stat-graph .stat-graph-container-large {
  flex-direction: row;
  height: 35em;
  width: 55.6em;
  padding: 2.6em;
  justify-content: space-between;
}
ssi-stat-graph .stat-graph-container-large .stat-graph-large-left {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 4em;
}
ssi-stat-graph .stat-graph-container-large .stat-graph-competitor-container {
  margin-bottom: 3em;
}
ssi-stat-graph .stat-graph-container-large .stat-graph-competitor-container p {
  margin: 0;
}
ssi-stat-graph .stat-graph-container-large .stat-graph-competitor {
  display: flex;
  align-items: center;
  color: #838eab;
  font-size: 1em;
  letter-spacing: 0.1em;
  font-weight: 900;
  text-transform: uppercase;
  text-rendering: geometricPrecision;
}
ssi-stat-graph .stat-graph-container-large .stat-graph-competitor-colour {
  display: inline-block;
  height: 2em;
  width: 2em;
  border-radius: 50%;
  margin: 0 1em 0 0;
}
ssi-stat-graph .stat-graph-container-large .stat-graph-chart-container {
  margin-left: 7.2em;
}
ssi-stat-graph .stat-graph-container-large .stat-graph-large-left-footer {
  padding: 0;
  margin: 0;
  margin: 0.1em -0.9em 0;
}
ssi-stat-graph .stat-graph-container-large .stat-graph-large-right-header {
  display: flex;
}
ssi-stat-graph .stat-graph-container-large .stat-graph-large-right-header-title {
  margin: 5.4em 0 0;
  flex: 1 0 auto;
}
ssi-stat-graph .stat-graph-container-large .stat-graph-large-right-header-badge {
  flex: 0 0 auto;
}
ssi-stat-graph .stat-graph-container-large .stat-graph-badge {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #fbf3e2;
  color: #101525;
  height: 4.6em;
  width: 4.6em;
  border-radius: 50%;
  pointer-events: none;
}
ssi-stat-graph .stat-graph-container-large .stat-graph-badge p {
  margin: 0;
  font-size: 1.4em;
  font-weight: 900;
}
ssi-stat-graph .stat-graph-container-large .stat-graph-badge .stat-graph-badge-wreathe {
  position: absolute;
  margin: 2.4em 0 0;
  color: #f0b427;
}
ssi-stat-graph .stat-graph-container-large .stat-graph-badge .stat-graph-badge-wreathe path {
  transform: scale(1.15);
  transform-origin: center;
}
ssi-stat-graph .stat-graph-container-large .stat-graph-chart-key {
  padding-left: 0;
}
ssi-stat-graph .stat-graph-container-large-thin {
  width: 36.4em;
  height: 46em;
}
ssi-stat-graph .stat-graph-container-large-thin .stat-graph-large-left {
  justify-content: flex-start;
  margin-right: 1em;
}
ssi-stat-graph .stat-graph-container-large-thin .stat-graph-chart-container {
  margin-left: 0;
}
ssi-stat-graph .stat-graph-container-large-thin .stat-graph-competitor-container {
  margin: 0 0 4.4em;
}
ssi-stat-graph .stat-graph-container-large-thin .stat-graph-large-left-footer {
  margin: auto -0.9em 0;
}
ssi-stat-graph .stat-graph-container-large-thin .stat-graph-large-right {
  flex: 0 1 auto;
}
ssi-stat-graph .stat-graph-container-large-thin .stat-graph-large-right-header-title {
  margin: 14.5em 0 0;
}
ssi-stat-graph .stat-graph-container-large-thin highcharts-chart {
  padding: 10px;
}