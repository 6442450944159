@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';
@import '../../../../../angularjs/common/sass/variables';
@import '../../../../../angularjs/common/sass/mixins';
@import '../publisher.variables.scss';
@import '../publisher.mixins.scss';

$ssi-publisher-schedule-responsive-width: 885px;

ssi-publisher-secondary-view-nav {
  padding: $publisher-padding;

  .publisher-secondary-nav-web {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 20px;
    padding: 13px 25px;

    @media (max-width: $ssi-publisher-schedule-responsive-width) {
      display: none;
    }

    .cancel-btn {
      background-color: #f88c68;
      color: #fff;
      padding: 0 30px;
      font-weight: 900;
    }
  }

  .publisher-secondary-nav-mobile {
    @media (min-width: $ssi-publisher-schedule-responsive-width) {
      display: none;
    }

    .cancel-btn {
      background-color: #f88c68;
      color: #fff;
      width: 100%;
    }

    .edit-post-btn {
      position: relative;
      background-color: #fff;
      color: #43537f;
      width: 100%;
      margin-top: 10px;

      i {
        font-size: 12px;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 5px;
        transform: rotate(90deg);
      }
    }
  }
}
