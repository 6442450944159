@import "~@angular/cdk/overlay-prebuilt.css";
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/* declare a font face for JoyPixels */
@font-face {
  font-family: JoyPixels;
  src: url("https://www.orlo.app/assets/fonts/joypixels.woff") format("woff"), url("https://www.orlo.app/assets/fonts/joypixels.woff2") format("woff2");
}
/* declare a font face for JoyPixels SVG font (Firefox only) */
@font-face {
  font-family: JoyPixelsSVG;
  src: url("https://www.orlo.app/assets/fonts/joypixels-svg.woff") format("woff"), url("https://www.orlo.app/assets/fonts/joypixels-svg.woff2") format("woff2");
}
@font-face {
  font-family: JoyPixels;
  src: url("https://www.orlo.app/assets/fonts/lato.woff2") format("woff2");
  /* using the unicode-range attribute to limit the reach of the JoyPixels web font */
  unicode-range: U+0000-00A8, U+00AF-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: JoyPixelsSVG;
  src: url("https://www.orlo.app/assets/fonts/lato.woff2") format("woff2");
  /* using the unicode-range attribute to limit the reach of the JoyPixels web font */
  unicode-range: U+0000-00A8, U+00AF-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* OR font family applied to class matching *joypixels-font */
[class*=joypixels],
textarea,
input {
  font-family: JoyPixels;
}

/* apply SVG font when browser is Mozilla Firefox */
@-moz-document url-prefix() {
  [class*=joypixels],
textarea,
input {
    font-family: JoyPixelsSVG;
  }
}
h1 {
  margin-top: 0;
  font-size: 1.875em;
  font-weight: bold;
  line-height: 2.25em;
  font-family: "Lato", "Arial", sans-serif;
}
h1.light {
  color: #101525;
}
h1.dark {
  color: #d4d9e6;
}

h2 {
  margin-top: 0;
  font-size: 1.25em;
  font-weight: bold;
  line-height: 1.5em;
  font-family: "Lato", "Arial", sans-serif;
}
h2.light {
  color: #101525;
}
h2.dark {
  color: #d4d9e6;
}

h3 {
  margin-top: 0;
  font-size: 1.2em;
  font-weight: bold;
  line-height: 1.5em;
  font-family: "Lato", "Arial", sans-serif;
}
h3.light {
  color: #101525;
}
h3.dark {
  color: #d4d9e6;
}

h4 {
  margin-top: 0;
  font-size: 1em;
  font-weight: bold;
  line-height: 1.2em;
  font-family: "Lato", "Arial", sans-serif;
}
h4.light {
  color: #101525;
}
h4.dark {
  color: #d4d9e6;
}

p {
  font-family: "Lato", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.25em;
}
p.light {
  color: #43537f;
}
p.dark {
  color: #b4bbce;
}

.orlo-button {
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  color: #fff;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 14px;
  font-weight: bold;
}
.orlo-button:focus {
  outline: 0;
}
.orlo-button.primary {
  background-color: #14bae3;
  margin-right: 2.5%;
}
.orlo-button.secondary {
  background-color: #434552;
}
.orlo-button.text {
  background: transparent;
  text-transform: uppercase;
  color: #43537f;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 0.8em;
}
.orlo-button:disabled {
  background-color: #cfd3de;
}

.orlo-table {
  margin: 0;
  border-radius: 25px;
  background-color: #fff;
  border: none !important;
  color: #43537f;
}
.orlo-table.striped tr:nth-child(even) {
  background-color: #fff;
}
.orlo-table.striped tr:nth-child(odd) {
  background-color: #f0f3f9;
}
.orlo-table tr {
  border-top: 2px solid #f0f3f9;
}
.orlo-table tr:first-child {
  border-top: none;
}
.orlo-table tr.has-delete:hover {
  box-shadow: 0px 0px 10px 5px #f3f3f3;
  border-radius: 10px;
  border-top-color: transparent;
}
.orlo-table tr.has-delete:hover + tr {
  border-top-color: transparent;
}
.orlo-table tr.has-delete a {
  position: relative;
  margin-right: 30px;
  margin-left: 15px;
}
.orlo-table tr.has-delete a i,
.orlo-table tr.has-delete a .icon-wrap {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 14px;
  color: #d4d7e2;
  line-height: 0;
}
.orlo-table tr.has-delete a i:hover, .orlo-table tr.has-delete a i.visible,
.orlo-table tr.has-delete a .icon-wrap:hover,
.orlo-table tr.has-delete a .icon-wrap.visible {
  padding: 19px;
  border-radius: 50px;
  background-color: #faeeeb;
  color: #e29e8c;
  top: -8px;
  left: -8px;
}
.orlo-table th {
  padding: 0 25px 25px 25px !important;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 900;
  line-height: 2.5em;
  color: #838eab;
  letter-spacing: 1px;
  box-sizing: border-box;
  border: none !important;
}
.orlo-table td {
  padding: 15px !important;
  border: none !important;
  word-wrap: break-word;
}
.orlo-table td.small {
  font-size: 0.85em;
}
.orlo-table td.centered {
  text-align: center;
}
.orlo-table tbody td:first-child {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.9em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 300px;
}
.orlo-table thead tr {
  background-color: #fff !important;
  border: none;
}
.orlo-table thead th {
  padding: 1em 15px !important;
  background-color: #f0f3f9;
  color: #43537f;
}
.orlo-table thead th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.orlo-table thead th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.orlo-text-input {
  width: 100%;
  margin: 0 0 0.4em 0;
  padding: 0.6em 1.6em;
  border-radius: 50px;
  background-color: #f0f3f9;
  border: 3px solid #f0f3f9;
  font-size: 16px;
  font-weight: 900;
  box-sizing: border-box;
}
.orlo-text-input:focus {
  border-color: #14bae3;
  outline: 0;
}
.orlo-text-input::placeholder {
  color: #838eab;
  font-style: italic;
  font-weight: 400;
}
.orlo-text-input.invalid {
  border-color: #fff1ed;
  background-color: #fff1ed;
  color: #f88c68;
}
.orlo-text-input.invalid::placeholder {
  color: #f88c68;
}
.orlo-text-input.no-right-radius {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.orlo-text-input.no-left-radius {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.orlo-input-submit {
  text-align: center;
  white-space: nowrap;
  background-color: #caceda;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border: none;
  padding: 1.4rem 1.6rem;
  margin-bottom: 0.5em;
  color: #fff;
  font-weight: bold;
}
.orlo-input-submit:hover {
  cursor: pointer;
}
.orlo-input-submit.active {
  background-color: #14bae3;
}
.orlo-input-submit i {
  margin-right: 0.5rem;
}

.orlo-select {
  margin: 0 0 0.4em 0;
  position: relative;
}
.orlo-select i {
  position: absolute;
  top: 45px;
  right: 20px;
  font-size: 20px;
  color: #14bae3;
}
.orlo-select select {
  width: 100%;
  height: 46px;
  padding: 0.6em 1.6em;
  border-radius: 50px;
  background-color: #f0f3f9;
  border: 3px solid #f0f3f9;
  font-size: 16px;
  font-weight: 700;
  box-sizing: border-box;
  box-shadow: none;
  appearance: none;
  color: #838eab;
  cursor: pointer;
}
.orlo-select select:focus {
  border-color: #14bae3;
  outline: 0;
}
.orlo-select select::placeholder {
  color: #838eab;
  font-style: italic;
  font-weight: 400;
}
.orlo-select select::-ms-expand {
  display: none;
}
.orlo-select select.invalid {
  border-color: #fff1ed;
  background-color: #fff1ed;
  color: #f88c68;
}
.orlo-select select.invalid::placeholder {
  color: #f88c68;
}
.orlo-select select[disabled] + i {
  color: #ced3df;
}

.orlo-modal .modal-content {
  border-radius: 25px;
  background-color: white;
  border: none;
  margin: auto;
}
.orlo-modal-1000 .modal-dialog {
  width: 1000px;
  max-width: 98%;
  margin-top: -5%;
}
.orlo-modal-1200 .modal-dialog {
  width: 1200px;
  max-width: 98%;
  margin-top: -5%;
}
.orlo-modal.position-top-20 {
  top: 20px;
}
.orlo-modal.position-top-20 .modal-dialog {
  margin-top: 0;
}

.orlo-modal-backdrop {
  background-color: rgb(16, 21, 37);
  opacity: 0.7 !important;
}

.orlo-modal-dark .modal-content {
  border-radius: 25px;
  background-color: #35353f;
  border: none;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #eff2f8 #14bae3;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 6px;
}

.custom-scrollbar-2::-webkit-scrollbar {
  width: 4px;
}

.custom-scrollbar-2 {
  scrollbar-width: thin;
  scrollbar-color: #ecf0fb #14bae3;
}

.custom-scrollbar-2::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 1px;
}

.orlo-textarea {
  width: 100%;
}
.orlo-textarea label {
  width: 100%;
  padding: 0 3em;
  display: block;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 900;
  line-height: 2.5em;
  color: #43537f;
  letter-spacing: 1px;
  box-sizing: border-box;
  margin-bottom: 5px;
}
.orlo-textarea textarea {
  width: 100%;
  margin: 0 0 0.4em 0;
  padding: 1.6em;
  border-radius: 10px;
  background-color: #f8f9fd;
  border: 3px solid transparent;
  font-size: 16px;
  font-weight: 700;
  box-sizing: border-box;
  color: #101525;
}
.orlo-textarea textarea:focus {
  border-color: #14bae3;
  outline: 0;
}
.orlo-textarea textarea::placeholder {
  color: #838eab;
  font-style: italic;
  font-weight: 400;
}
.orlo-textarea textarea.invalid {
  border-color: #fff1ed;
  background-color: #fff1ed;
  color: #f88c68;
}
.orlo-textarea textarea.invalid::placeholder {
  color: #f88c68;
}

.orlo-tickbox {
  display: flex;
  align-items: center;
  position: relative;
}
.orlo-tickbox input[type=checkbox] {
  position: absolute;
  opacity: 0;
  margin: 0;
  cursor: pointer;
}
.orlo-tickbox label {
  width: 100%;
  padding: 0 1em;
  display: block;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 900;
  line-height: 2.5em;
  color: #43537f;
  letter-spacing: 1px;
  box-sizing: border-box;
  margin-bottom: 5px;
}
.orlo-tickbox::before {
  display: block;
  margin-top: -6px;
  background-color: #f0f3f9;
  content: "";
  border-radius: 50px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.orlo-tickbox i {
  display: none;
}
.orlo-tickbox.selected label {
  color: #14bae3;
}
.orlo-tickbox.selected::before {
  color: #14bae3;
  background-color: #d0f1f9;
}
.orlo-tickbox.selected i {
  display: block;
  position: absolute;
  left: 2px;
  top: 2px;
  bottom: 0;
  font-size: 18px;
  color: #14bae3;
}
.orlo-tickbox:first-child {
  margin-right: 30px;
}

.cdk-overlay-container {
  z-index: 1060;
}

cdk-overlay-pane {
  width: 100%;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #eff2f8 #14bae3;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #eff2f8;
  border-radius: 6px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #14bae3;
  border-radius: 6px;
  border: 3px solid #14bae3;
}

.a11y-label {
  position: absolute;
  text-indent: -99999px;
  color: #000;
}

@media print {
  body > button {
    display: none !important;
  }
}
.flex-grid {
  display: flex;
  flex-wrap: wrap;
}
.flex-grid .col {
  flex: 1;
}
.flex-grid .col.full {
  flex-basis: 100%;
}
.flex-grid .col.one-third {
  flex-basis: 33.33%;
}
.flex-grid .col.two-thirds {
  flex-basis: 66.66%;
}
.flex-grid .col.one-half {
  flex-basis: 50%;
}
.flex-grid.thirds {
  justify-content: space-between;
}
.flex-grid.thirds .col {
  width: 32%;
}

.z-2000 {
  z-index: 2000 !important;
}

.z-2001 {
  z-index: 2001 !important;
}

ssi-message-split-panel {
  position: absolute;
  font-size: 1rem;
  bottom: 60px;
  width: 100%;
}
ssi-message-split-panel:after {
  content: "";
  position: absolute;
  top: 65px;
  right: -1.5em;
  background-image: linear-gradient(to right, #a3b8ee 50%, transparent 50%);
  background-position: bottom;
  background-size: 4em 0.4em;
  background-repeat: repeat-x;
  display: block;
  width: 100%;
  height: 0.2em;
  z-index: 1;
}
ssi-message-split-panel .message-box-panel {
  background: #fff;
  color: #838eab;
  border-radius: 1.5em;
  box-shadow: 0 1.8em 3.5em rgba(131, 142, 171, 0.35);
  width: 30em;
  padding: 3em 3em 1.5em;
  margin: 0 0 3em;
  z-index: 2;
  cursor: auto;
}
ssi-message-split-panel .message-box-panel-body {
  line-height: 2em;
  margin: 0 0 2em;
}
ssi-message-split-panel .message-box-panel-body p {
  font-weight: 500;
  font-size: 1.4em;
  line-height: inherit;
  margin: 0;
}
ssi-message-split-panel .message-box-panel-actions {
  margin: 0;
  padding: 0;
  text-align: right;
}
ssi-message-split-panel .message-box-panel-action {
  display: inline-block;
  margin-left: 1em;
  letter-spacing: 0.1em;
}
ssi-message-split-panel .message-box-panel-action p {
  font-size: 1.2em;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: inherit;
  cursor: pointer;
}
ssi-message-split-panel .message-box-panel-action p:hover, ssi-message-split-panel .message-box-panel-action p:focus {
  text-decoration: underline;
}
ssi-message-split-panel .message-box-panel-action.confirm {
  color: #14bae3;
}
ssi-message-split-panel .message-box-panel-action.dismiss {
  color: #43537f;
}