@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-countdown {
  display: flex;
  align-items: center;
  width: max-content;
  margin-right: auto;
  padding: 8px;
  background-color: #faeeeb;
  color: $secondary-red;
  border-radius: 10px;
  border: 1px solid $secondary-red;

  i {
    font-size: 15px !important;
    margin-right: 5px;
  }

  span {
    overflow: hidden;
    font-size: 12px;
    font-weight: 700;
    margin-right: 5px;
  }
}
