ssi-advertising-targeting-builder {
  width: 100%;
  display: block;
}
ssi-advertising-targeting-builder .targeting-options {
  display: flex;
  flex-wrap: wrap;
  min-height: 300px;
}
ssi-advertising-targeting-builder .targeting-options:before {
  content: "";
  width: 100%;
  order: 2;
}
ssi-advertising-targeting-builder .targeting-options li {
  background-color: #454c56;
  color: #87959e;
  font-weight: normal;
  width: calc(16.6% - (2px * 2));
  text-align: center;
  padding: 40px 10px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  position: relative;
  cursor: pointer;
  height: 120px;
  order: 3;
}
ssi-advertising-targeting-builder .targeting-options li.compulsory {
  order: 1;
}
ssi-advertising-targeting-builder .targeting-options li.active {
  background-color: #e2b928;
  color: #fff;
}
ssi-advertising-targeting-builder .targeting-options li.disabled {
  pointer-events: none;
  opacity: 0.3;
}
ssi-advertising-targeting-builder .targeting-options li.disabled.active {
  background-color: #e2b928;
  color: #fff;
  opacity: 0.3;
}
ssi-advertising-targeting-builder .targeting-options li > span {
  cursor: pointer;
}
ssi-advertising-targeting-builder .targeting-options li * {
  cursor: auto;
}
ssi-advertising-targeting-builder .advertising-text-muted {
  color: #a6b7c0;
}
ssi-advertising-targeting-builder .targeting-option-panel {
  width: 500px;
  border: solid 2px #e2b928;
  border-radius: 15px;
  position: absolute;
  z-index: 10;
  background-color: #fff;
  left: calc(-500px / 2 + 50%);
  top: 70%;
}
ssi-advertising-targeting-builder .targeting-option-panel-inner {
  background-color: #f9f9f9;
  margin: 25px;
  border-radius: 15px;
  max-height: 250px;
  overflow-y: auto;
}
ssi-advertising-targeting-builder .targeting-option-panel-close {
  position: absolute;
  bottom: -17px;
  left: calc(50% - 50px);
}

.ui-radio {
  position: relative;
  display: inline-block;
  min-width: 17px;
  min-height: 15px;
}
.ui-radio input[type=radio] {
  cursor: pointer;
  position: absolute;
  top: -8px;
  left: -8px;
  opacity: 0;
  outline: none;
  z-index: 3;
  width: 31px;
  height: 31px;
}
.ui-radio label {
  cursor: auto;
  position: relative;
  display: block;
  outline: none;
  transform: scale(1.3);
}
.ui-radio label:before {
  content: "";
  position: absolute;
  background: #fff;
  border: 1px solid #d4d4d5;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  left: 0px;
}
.ui-radio label:after {
  content: "" !important;
  border: none;
  position: absolute;
  width: 15px;
  height: 15px;
  line-height: 15px;
  left: 0px;
  border-radius: 50%;
  transform: scale(0.46666667);
  background-color: transparent;
}
.ui-radio .ui-radio-checked ~ label:after {
  background-color: #a6b7c0;
}

.audience-table {
  margin-bottom: 75px;
}

.audience-table-row {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}
.audience-table-row > .audience-table-col {
  flex: 0 0 calc(50% - 63px);
  text-align: center;
  border-right: 2px solid #57616a;
}
.audience-table-row > .audience-table-col:first-child {
  flex: 0 0 126px;
  width: 126px;
  text-align: left;
}
.audience-table-row > .audience-table-col:last-child {
  border-right-width: 0;
}

.audience-table-col p {
  margin: 0;
}

.audience-table-head {
  border-bottom: 2px solid #57616a;
}

.audience-table-head-main {
  color: #fff;
  padding: 8px 0 20px;
  font-weight: 500;
}

.audience-table-head-name {
  color: #87959e;
  padding: 8px 0 20px;
  font-size: 13px;
  font-weight: 700;
}

.audience-table-body .audience-table-row {
  border-bottom: 2px solid #57616a;
}
.audience-table-body .audience-table-row:last-child {
  border-bottom-width: 0;
}
.audience-table-body .audience-table-row .audience-table-col {
  padding: 40px 0;
}
.audience-table-body .audience-table-row .audience-table-col:first-child {
  padding: 40px 15px 40px 0;
}
.audience-table-body .audience-option-label {
  display: inline-block;
  margin: 4px 1px;
  font-size: 100%;
}