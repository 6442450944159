ssi-sentiment-gauge {
  padding: 5px;
}
ssi-sentiment-gauge .sentiment-chart-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
ssi-sentiment-gauge .sentiment-chart-container .footer {
  position: absolute;
  height: 44px;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
}
ssi-sentiment-gauge .sentiment-chart-container .footer .left span {
  color: #101525;
  font-size: 26px;
  font-weight: 900;
}
ssi-sentiment-gauge .sentiment-chart-container .footer .right {
  display: flex;
  align-items: center;
}
ssi-sentiment-gauge .sentiment-chart-container .footer .right span {
  color: #101525;
  font-size: 10px;
  font-weight: 900;
  text-transform: uppercase;
  padding-top: 5px;
}
ssi-sentiment-gauge .sentiment-chart-container .footer .right i {
  font-size: 20px;
  margin-left: 8px;
  border-radius: 50%;
  padding: 5px;
}
ssi-sentiment-gauge .sentiment-chart-container .footer .right i.passive-new-icon {
  border-radius: 0;
  padding: 0;
  font-size: 32px;
}