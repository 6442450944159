ssi-inbox-activity-modal .inbox-activity-modal {
  position: relative;
  padding: 10px;
  height: 100%;
}
ssi-inbox-activity-modal .inbox-activity-modal .close-icon {
  position: fixed;
  z-index: 999999999;
  top: -8px;
  right: -8px;
  color: #838eab;
  font-size: 10px;
  background-color: #fff;
}
@media (min-width: 768px) {
  ssi-inbox-activity-modal .inbox-activity-modal .close-icon {
    top: -18px;
    right: -18px;
  }
}
ssi-inbox-activity-modal .inbox-activity-modal .close-icon:hover {
  opacity: 1;
  color: #14bae3;
}
ssi-inbox-activity-modal .inbox-activity-modal ssi-inbox-activity {
  height: 100%;
}