// @import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

$brand-primary: $ssi-brand-primary;
$brand-success: #acb638;
$brand-danger: #e36635;
$brand-royal: #a12378;
$brand-inverse: #707f8b;
$brand-warning: #e2b928;

$state-warning-text: $brand-warning;

$label-primary-bg: $brand-primary;
$btn-primary-bg: $brand-primary;
$panel-primary-heading-bg: $brand-primary;
$btn-primary-border: #2ca3e5;

$btn-success-bg: #acb638;
$btn-success-border: #9aa332;

$btn-warning-bg: #e2b928;
$btn-warning-border: #d4ac1d;

$body-bg: #f5f8fa;
$text-color: #596065;

$section-margin-width: 8px;

$bg-dark-primary: #353434;
$bg-dark-secondary: #3f3f49;

$bg-dark-primary-hover: #4d4f50;
$bg-dark-secondary-hover: lighten($bg-dark-secondary, 5%);

$bg-light-primary: #f8f8f8;
$bg-light-secondary: #eeeff3;

$text-color-muted: #c8d1d8;

$border-color-light: rgba(255, 255, 255, 0.24);

$page-heading-height: 0px;
$navbar-height: 68px;
$navbar-plus-page-heading-height: $page-heading-height + $navbar-height;

$modal-content-bg: #f4f5f5;
$modal-content-text-color: #5b6267;

$row-border-separator-color: rgba(255, 255, 255, 0.5);

$text-dark: #596065;
$text-light: #fff;

$font-family-sans-serif: 'Lato', sans-serif;
$font-family-serif: $font-family-sans-serif;
$font-family-base: $font-family-sans-serif;

$navbar-mobile-breakpoint: 1150px;

$box-shadow-default: 0 4px 32px -5px rgba(119, 124, 128, 0.56);

$box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
$box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
$box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
$box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
$box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);

// Inbox Variables
// Inbox -- Thread
$inbox-activity-background-color: #f8f9fd;
$activity-active-bg-color: #edf1f3;
$activity-separator-padding: 15px;
$activity-separator-width: 2px;
$activity-avatar-img-dimensions: 38px;
$activity-spacing-sides-width: $activity-avatar-img-dimensions +
  ($activity-separator-padding * 2) + $activity-separator-width;
$inbox-activity-thread-footer-content-height: 20px;
$inbox-activity-thread-footer-padding: 15px;
$inbox-activity-thread-footer-height: $inbox-activity-thread-footer-content-height +
  $inbox-activity-thread-footer-padding * 2;

// Inbox -- reply box
$reply-box-top-height: 35px;
$reply-box-textarea-vertical-margin: 8px;
$reply-box-padding: 10px;
$reply-actions-height: 40px;
$reply-box-top-height: 35px;
$reply-box-height-compressed: 70px;
$reply-box-height-active: 320px;
$reply-box-height-expanded: 600px;
$reply-box-height-hidden: 80px;
$reply-box-sticky-bottom-padding: 18px;
// End of Inbox Variables
