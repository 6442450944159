ssi-meta-post-options-select .meta-post-options-select-container ssi-dropdown-select-2 .dd-head {
  height: 36px;
}
ssi-meta-post-options-select .meta-post-options-select-container ssi-dropdown-select-2 .dd-head .button-icon {
  color: #43537f;
}
ssi-meta-post-options-select .meta-post-options-select-container .custom-head-button-label {
  color: #43537f;
}
ssi-meta-post-options-select .meta-post-options-select-container .custom-head-button-label i {
  margin-right: 5px;
  font-size: 14px;
}
ssi-meta-post-options-select .meta-post-options-select-container .custom-head-button-label span {
  font-size: 12px;
  font-weight: 900;
}