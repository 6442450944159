@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-report-build-from {
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 500px;
    padding: 80px 0;

    .form-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: 15px;

      &.extra-margin-top {
        margin-top: 50px;
      }

      .form-field {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        // &:nth-child(2) {
        //   margin-left: 20px;
        // }

        &.half {
          flex-basis: 48%;
        }

        > label {
          color: $lmode-body-text;
          font-size: 10px;
          font-weight: 900;
          letter-spacing: 1px;
          text-transform: uppercase;
          margin-left: 20px;
          margin-bottom: 15px;
        }

        .input-wrapper {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          &.select-all-accounts {
            justify-content: flex-end;
          }

          .inline-select-item {
            display: flex;
            align-items: center;
            color: $lmode-body-text;
            font-size: 12px;
            font-weight: 900;
            padding: 10px;
            border-radius: 10px;
            background-color: $grey-background;
            margin-right: 10px;
            cursor: pointer;
            user-select: none;

            i {
              margin-right: 10px;
            }

            &.selected {
              color: $primary-active;
              border: 1px solid $primary-active;
            }

            &:hover {
              color: #fff;
              background-color: $primary-active;
              box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.35);
            }
          }

          ssi-social-network-icon {
            i {
              font-size: 12px;
            }
          }

          .accounts-selector {
            width: 100%;
            button.dd-head {
              width: 100%;
            }
          }

          .select-accounts-placeholder {
            color: $lmode-helper-text;
            font-size: 14px;
            font-weight: 700;
          }

          .select-accounts-label {
            color: $lmode-title-text;
            font-size: 14px;
            font-weight: 900;
            display: flex;
            align-items: center;

            ssi-social-network-icon {
              margin-right: 8px;
            }
          }

          .date-picker-input {
            width: 100%;
            height: 46px;
            border: none;
            outline: none;
            border-radius: 100px;
            display: flex;
            align-items: center;
            color: $lmode-title-text;
            font-size: 14px;
            font-weight: 900;
            background-color: $grey-background;
            padding: 10px 30px 10px 20px;

            &::placeholder {
              color: $lmode-helper-text;
              font-size: 14px;
              font-weight: 700;
              line-height: 17px;
            }
          }

          .tag-selector {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .dd-container,
            button.dd-head {
              width: 100%;
              height: auto;
            }
            button.dd-head {
              border-radius: 12px;
            }

            .select-tags-placeholder {
              color: $lmode-helper-text;
              font-size: 14px;
              font-weight: 700;
            }

            .select-tag-labels {
              text-align: left;
              margin: 0 0 -10px -10px;
              color: #fff;

              span {
                padding: 6px 8px;
                font-size: 10px;
                margin: 0 4px 8px 0;
                background-color: $primary-active;
                border-radius: 100px;
                display: inline-flex;
                align-items: center;
                text-rendering: geometricPrecision;
                word-break: break-all;

                &:hover {
                  background-color: $secondary-red;
                }
              }

              .ssi {
                margin: 0 0 0 7px;
                font-size: 8px;
              }
            }
          }
        }
      }
    }

    .submit-button {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
}
