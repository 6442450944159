@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-analytics ssi-marketing ssi-report ssi-organic-reach {
  .organic-reach-container {
    .section-body {
      padding: 70px 30px 70px 30px;
      background-color: #fff;
      border-radius: 10px;
      position: relative;
      display: flex;

      @media print {
        display: block;
        page-break-inside: avoid;
      }

      ssi-highchart {
        width: 100%;
        height: 365px;

        highcharts-chart {
          .highcharts-container,
          .highcharts-root {
            overflow: visible !important;
          }

          .highcharts-axis-title {
            font-size: 10px;
            font-weight: 900;
            letter-spacing: 1px;
            text-transform: uppercase;
            white-space: nowrap;
          }

          .highcharts-axis-labels {
            text {
              font-weight: 900;
            }
          }

          .highcharts-xaxis-labels {
            transform: translate(0, 1em);
          }
        }
      }

      .total-reach-card-container {
        flex: 0 0 auto;
        // width: 260px;
        height: 280px;

        ssi-total-stat-card {
          .tsc {
            height: 100%;
          }
        }
      }
    }
  }
}
