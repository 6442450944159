ssi-dashboard-widget-modal {
  position: relative;
  font-size: 1rem;
}
ssi-dashboard-widget-modal .dwm-close {
  position: absolute;
  right: 2em;
  top: 2em;
  display: inline-block;
  border-radius: 50%;
  background-color: #f4f4fa;
  border-width: 0;
  height: 3.6em;
  width: 3.6em;
  outline-width: 0;
  z-index: 1;
}
ssi-dashboard-widget-modal .dwm-close .ssi {
  font-size: 1.2em;
  color: #838eab;
}
ssi-dashboard-widget-modal .dwm-close .ssi-addsmall {
  transform: rotate(45deg);
}
ssi-dashboard-widget-modal .dashboard-widget-modal {
  background-color: #fff;
  height: 100%;
  overflow: auto;
}
ssi-dashboard-widget-modal .dwm-container {
  display: flex;
  flex-wrap: wrap;
  padding: 2em 2em 107px;
  background-color: #fff;
  border-top-left-radius: 1.2em;
  border-top-right-radius: 1.2em;
}
@media (min-width: 768px) {
  ssi-dashboard-widget-modal .dwm-container {
    flex-wrap: nowrap;
    min-height: 100%;
  }
}
ssi-dashboard-widget-modal .dwm-aside {
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  margin-left: 1.4em;
  padding: 0;
}
@media (min-width: 768px) {
  ssi-dashboard-widget-modal .dwm-aside {
    flex: 0 0 24em;
    padding: 0 0 0 1.6em;
  }
}
ssi-dashboard-widget-modal .dwm-aside-header,
ssi-dashboard-widget-modal .dwm-body-header {
  display: flex;
  align-items: flex-end;
  height: 49px;
  margin-bottom: 4.5em;
  padding: 0 1.6em 0 0;
}
ssi-dashboard-widget-modal .dwm-aside-header h1,
ssi-dashboard-widget-modal .dwm-body-header h1 {
  font-size: 2em;
  font-weight: 900;
  color: #43537f;
  white-space: nowrap;
  margin: 0;
  line-height: 1;
  text-rendering: geometricPrecision;
}
ssi-dashboard-widget-modal .dwm-aside-header a,
ssi-dashboard-widget-modal .dwm-body-header a {
  display: block;
}
ssi-dashboard-widget-modal .dwm-aside-header a:hover, ssi-dashboard-widget-modal .dwm-aside-header a:focus,
ssi-dashboard-widget-modal .dwm-body-header a:hover,
ssi-dashboard-widget-modal .dwm-body-header a:focus {
  text-decoration: none;
}
ssi-dashboard-widget-modal .dwm-aside-header p,
ssi-dashboard-widget-modal .dwm-aside-header i,
ssi-dashboard-widget-modal .dwm-body-header p,
ssi-dashboard-widget-modal .dwm-body-header i {
  display: inline-block;
  color: #43537f;
  font-size: 1.4em;
  font-weight: 900;
  line-height: 1;
  margin: 0;
}
ssi-dashboard-widget-modal .dwm-aside-header i,
ssi-dashboard-widget-modal .dwm-body-header i {
  transform: rotate(90deg);
  font-size: 1em;
  margin: 0 8px 0 0;
  vertical-align: text-top;
}
ssi-dashboard-widget-modal .dwm-aside-body {
  flex: 1 0 auto;
  padding: 0 1.6em 2em 0;
}
@media (min-width: 768px) {
  ssi-dashboard-widget-modal .dwm-aside-body {
    border-right: 1px solid #d1dbf4;
  }
}
ssi-dashboard-widget-modal .dwm-aside-body h2 {
  color: #43537f;
  font-weight: 900;
  font-size: 1.6em;
  line-height: 19px;
  margin: 0 0 7px;
  text-rendering: geometricPrecision;
}
ssi-dashboard-widget-modal .dwm-aside-body p {
  color: #43537f;
  font-weight: 700;
  line-height: 20px;
  font-size: 1.4em;
  margin-bottom: 0;
  text-rendering: geometricPrecision;
}
ssi-dashboard-widget-modal .dwm-aside-meta {
  background-color: #e8f8fc;
  border-radius: 1em;
  padding: 1.4em;
  margin: 2em 0 0 -1.4em;
}
ssi-dashboard-widget-modal .dwm-aside-meta p {
  color: #058eaf;
  font-size: 1.4em;
  line-height: 20px;
}
ssi-dashboard-widget-modal .thumbnail-action {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.2em;
  width: 24em;
  height: 16em;
  margin: 0 0 1em;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='#14BAE3' stroke-width='3' stroke-dasharray='5%0c 10' stroke-dashoffset='100' stroke-linecap='square'/%3e%3c/svg%3e"), linear-gradient(0deg, #e8f9fd, #e8f9fd), linear-gradient(0deg, #f8f9fd, #f8f9fd);
}
ssi-dashboard-widget-modal .thumbnail-button {
  width: 46px;
  height: 46px;
  background-color: #14bae3;
  border-width: 0;
  border-radius: 50%;
  color: #fff;
  z-index: 1;
}
ssi-dashboard-widget-modal .item-thumb {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-radius: 1.2em;
  margin-bottom: 20px;
}
ssi-dashboard-widget-modal .item-thumb:before {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 138px;
  color: #c8f0f9;
}
ssi-dashboard-widget-modal .dwm-body {
  flex: 1 1 auto;
}
ssi-dashboard-widget-modal .dwm-body-content {
  width: 100%;
  display: flex;
}
ssi-dashboard-widget-modal .widget-config {
  flex: 1 1 auto;
}
ssi-dashboard-widget-modal .dwm-form {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 1em 1em 1em;
}
ssi-dashboard-widget-modal .dwm-form-half {
  flex: 1 0 100%;
}
@media (min-width: 768px) {
  ssi-dashboard-widget-modal .dwm-form-half {
    flex: 1 0 calc(50% - 16px);
    min-width: 30em;
    margin: 0 0.8em;
  }
}
ssi-dashboard-widget-modal .dwm-filters {
  height: 100%;
  flex: 0 0 540px;
  position: relative;
  padding: 0 1em 1em 1em;
  margin-left: auto;
}
ssi-dashboard-widget-modal .dwm-filters label {
  padding-left: 30px;
}
ssi-dashboard-widget-modal .dwm-body-row {
  padding: 0 3em;
  margin: 0 0 2em;
}
ssi-dashboard-widget-modal .dwm-label {
  display: block;
  text-transform: uppercase;
  font-size: 1em;
  font-weight: 900;
  letter-spacing: 0.1em;
  margin: 0 0 1.8em;
  color: #6a759a;
  text-rendering: geometricPrecision;
}
ssi-dashboard-widget-modal .dwm-body-row-input {
  position: relative;
  margin: 0 -3rem 0;
}
ssi-dashboard-widget-modal .dwm-input {
  font-size: 1.4em;
  width: 100%;
  border-width: 0;
  height: 4.6rem;
  border-radius: 1rem;
  color: #101525;
  font-weight: 700;
  text-rendering: geometricPrecision;
  text-indent: 3rem;
  padding: 0 3rem 0 0;
  background-color: #fff;
  border: 1px solid #c3c9d8;
}
ssi-dashboard-widget-modal .dwm-input:focus {
  outline: 1px solid #14bae3;
}
ssi-dashboard-widget-modal .dwm-input:disabled {
  opacity: 0.4;
}
ssi-dashboard-widget-modal .dwm-input::placeholder {
  color: #6a759a;
  text-rendering: geometricPrecision;
}
ssi-dashboard-widget-modal .dwm-body-row-input-meta {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  height: 1em;
  line-height: 1;
  right: 1.8em;
  color: #838eab;
  font-size: 1em;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-dashboard-widget-modal .dwm-body-row-input-meta-active {
  color: #14bae3;
}
ssi-dashboard-widget-modal .dwm-input-toggle {
  display: flex;
  align-items: center;
  font-size: 1.4em;
  width: 100%;
  border-width: 0;
  height: 4.6rem;
  border-radius: 1rem;
  color: #101525;
  font-weight: 700;
  text-rendering: geometricPrecision;
  text-indent: 3rem;
  padding: 0 1.3rem 0 0;
  background-color: #f8f9fd;
  border: 1px solid #c3c9d8;
}
ssi-dashboard-widget-modal .dwm-input-toggle.toggled {
  background-color: #e4faff;
  border: 1px solid #14bae3;
}
ssi-dashboard-widget-modal .dwm-input-toggle .toggle-switch-2-container {
  width: 100%;
}
ssi-dashboard-widget-modal .dwm-input-toggle .toggle-switch-2-label {
  letter-spacing: 0;
  color: #43537f;
}
ssi-dashboard-widget-modal .dwm-input-toggle .toggle-switch-2-label.active {
  color: #43537f;
}
ssi-dashboard-widget-modal .dwm-input-toggle .switch {
  font-size: 0.8em;
  outline: none;
}
ssi-dashboard-widget-modal .dwm-dropdown-selector {
  margin: 0 -3rem;
}
ssi-dashboard-widget-modal .dwm-dropdown-selector .dd-container {
  width: 100%;
}
ssi-dashboard-widget-modal .dwm-dropdown-selector .dd-container .dd-head {
  width: 100%;
  padding: 0 30px;
}
ssi-dashboard-widget-modal .dwm-dropdown-selector .dd-container .dd-body {
  width: 100%;
}
ssi-dashboard-widget-modal .dwm-dropdown-selector .dd-container .dd-body ul {
  max-height: 268px;
}
ssi-dashboard-widget-modal .dwm-footer {
  position: absolute;
  bottom: 1px;
  width: 100%;
  height: 107px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 2.6em 2.5em 1.7em;
  border-top: 1px solid #d1dbf4;
  max-height: 107px;
  background-color: #fff;
  border-bottom-left-radius: 1.2em;
  border-bottom-right-radius: 1.2em;
}
ssi-dashboard-widget-modal .dwm-footer-left {
  flex: 0 0 auto;
  padding: 0 0 0 0.5em;
}
ssi-dashboard-widget-modal .dwm-footer-right {
  flex: 0 1 auto;
  display: flex;
  align-items: center;
}
ssi-dashboard-widget-modal .dwm-footer-right .save-button {
  margin-left: 10px;
}