@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../common/styles/colors.scss';

ssi-outbox-search {
  .outbox-analytics-section {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .outbox-wrap {
    padding-bottom: 100px;
  }

  .form-check {
    display: flex;
    align-items: center;
    justify-content: end;

    .form-check-input {
      margin: 0;
      margin-left: 8px;
    }
  }

  .actions-panel {
    display: flex;
    align-items: center;
    // flex-wrap: wrap;
    padding: 10px;
    button {
      @media (max-width: 1200px) {
        span {
          display: none;
        }
        i {
          margin: 0;
        }
      }
    }
    & > * {
      margin-right: 10px;
    }
    .filters-button-container {
      margin-left: auto;
    }
    .g-count-badge-container {
      .g-count-badge {
        z-index: 1;
      }
    }
  }
}
