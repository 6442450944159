$primary-active: #14bae3;
$secondary-active: #434552;
$primary-red: #fff1ed;
$secondary-red: #f88c68;
$primary-blue: #43ade8;
$secondary-blue: #89a4ea;

//Backgrounds
$grey-background: #f8f9fd;
$lgrey-background: #f4f4fa;
$lmgrey-background: #f0f3f9;
$ldgrey-background: #e7ecf8;
$lblue-background: #d6dff7;
$dgrey-background: #454c56;

// Light mode
$lmode-title-text: #101525;
$lmode-body-text: #43537f;
$lmode-helper-text: #838eab;
$lmode-disabled: #cfd3de;
$lmode-border-color: #ecf0fb;

// Dark mode
$dmode-title-text: #d4d9e6;
$dmode-body-text: #b4bbce;
$dmode-helper-text: #727785;
$dmode-disabled: #444654;
$dmode-background: #35353f;
$dmode-accent: #25252d;
