@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-widget-default-config {
  .widget-default-config-container {
    .dwm-body-row {
      max-width: 700px;
    }
  }
}
