ssi-live-chat-message {
  flex-flow: column nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  padding: 35px 0 0 0;
  position: relative;
  width: 100%;
}
ssi-live-chat-message.agent ssi-live-chat-network-state, ssi-live-chat-message.robot ssi-live-chat-network-state {
  left: auto;
  right: -25px;
}
ssi-live-chat-message.visitor.selected .message-body {
  background-color: #d8e1e5;
  border-color: #fff;
}
ssi-live-chat-message.visitor.selected > .tags-icon {
  opacity: 1;
}
ssi-live-chat-message.visitor .message-body {
  background-color: #e3eaed;
}
ssi-live-chat-message.visitor ssi-live-chat-network-state {
  left: -25px;
}
ssi-live-chat-message.visitor .tags-icon {
  align-items: center;
  background: #e3eaed;
  border: none;
  border-radius: 50%;
  color: #49b0e8;
  font-size: 1.25em;
  font-weight: 400;
  height: 40px;
  justify-content: center;
  outline: none;
  padding: 10px;
  position: absolute;
  right: 0px;
  top: 35px;
  width: 40px;
  z-index: 1;
}
ssi-live-chat-message ssi-live-chat-network-state {
  height: 50px;
  left: 5px;
  position: absolute;
  top: 5px;
  width: 50px;
}
ssi-live-chat-message .message-body {
  background-color: #fff;
  border: solid 2.5px transparent;
  border-radius: 20px;
  cursor: pointer;
  flex-flow: column nowrap;
  min-height: 100px;
  padding: 20px 40px;
}
ssi-live-chat-message .message-body > .age {
  color: #beccd3;
  display: inline;
  font-size: 0.8em;
  font-style: italic;
  font-weight: 600;
  text-align: right;
}
ssi-live-chat-message .message-body > .text {
  display: inline;
  hyphens: auto;
  overflow-wrap: break-word;
  word-break: break-word;
  word-wrap: break-word;
}
ssi-live-chat-message .message-body > .text * {
  display: inline;
}
ssi-live-chat-message .message-body > .attachment img {
  max-height: 200px;
  max-width: 200px;
  object-fit: contain;
}
ssi-live-chat-message .message-body > p {
  margin: 0 0 2% 0;
  font-weight: 400;
}
ssi-live-chat-message .message-body > p.additional {
  color: #506975;
  font-size: 0.9em;
  font-style: italic;
}
ssi-live-chat-message .message-body > p.additional > * + * {
  border-left: solid 1px;
  margin-left: 5px;
  padding-left: 5px;
}
ssi-live-chat-message .message-body > p.additional .network-state {
  text-transform: capitalize;
}
ssi-live-chat-message .message-body > p.author {
  color: #125b84;
  flex-flow: column nowrap;
  font-size: 1.3em;
  white-space: nowrap;
}
ssi-live-chat-message .message-body > p.author > * {
  display: inline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
ssi-live-chat-message .message-footer {
  align-items: flex-end;
  flex-flow: row nowrap;
  font-size: 0.85em;
  justify-content: flex-end;
  list-style: none;
  margin: 10px 0 0 0;
  padding: 0;
  white-space: nowrap;
}
ssi-live-chat-message .message-footer > li {
  align-items: center;
  background-color: #e3eaed;
  border-radius: 10px;
  border: none;
  color: #43ade8;
  cursor: pointer;
  flex-shrink: 0;
  font-size: 0.85em;
  font-style: italic;
  margin: 0;
  padding: 2.5px 10px;
}
ssi-live-chat-message .message-footer > li.message-footer-notes .message-footer-notes-label {
  font-weight: 400;
  padding-left: 2.5px;
}
ssi-live-chat-message .message-footer > li.message-footer-notes .message-footer-notes-label .message-footer-notes-label-text {
  padding-right: 2.5px;
}
ssi-live-chat-message .translate {
  margin-bottom: 5px;
}