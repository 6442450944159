.advert-table-filters {
  position: relative;
  display: flex;
  justify-content: center;
  background-color: #383c46;
  font-weight: 500;
  font-size: 12px;
  padding: 20px;
  z-index: 2;
  white-space: nowrap;
}
.advert-table-filters > * {
  flex: 0 0 33%;
}
.advert-table-filters.advert-table-filters-two-col {
  justify-content: flex-start;
}
.advert-table-filters.advert-table-filters-three-col {
  justify-content: space-between;
}

.advert-table-filters-list {
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}
.advert-table-filters-list > li {
  position: relative;
  display: inline-block;
  padding: 0 10px;
  cursor: pointer;
}
.advert-table-filters-list > li:last-child {
  padding-right: 0;
}
.advert-table-filters-list > li:after {
  content: "";
  position: absolute;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  height: 11px;
  border-right: 1px solid #a8b9c2;
}
.advert-table-filters-list > li:last-child:after {
  content: normal;
}
.advert-table-filters-list > .active {
  color: #14bae3;
}
.advert-table-filters-list p {
  margin: 0;
}

.advert-table-filters-sort > span {
  display: inline-block;
}
.advert-table-filters-sort p {
  display: inline;
}

.advert-table-filters-sort-icon {
  font-size: 20px;
  color: #14bae3;
  margin-right: 8px;
  vertical-align: middle;
}

.advertising-highlight {
  color: #14bae3;
}

.advert-table-filters-soc {
  text-align: center;
}

.advert-table-filters-status {
  text-align: right;
}
.advert-table-filters-status .active.table-filter-status-item, .advert-table-filters-status .active.table-filter-status-item-live {
  color: #e2b928;
}
.advert-table-filters-status .active.table-filter-status-item-scheduled {
  color: #8e52cc;
}
.advert-table-filters-status .active.table-filter-status-item-paused {
  color: #14bae3;
}
.advert-table-filters-status .active.table-filter-status-item-completed {
  color: #14bae3;
}

.advert-sort-action {
  cursor: pointer;
}

.advert-sort-box {
  position: absolute;
  top: 54px;
  left: -10px;
  background-color: #393c46;
  border-radius: 20px;
  padding: 1em;
  width: 400px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  font-size: 14px;
}

.advert-sort-box-input {
  position: relative;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  background-color: #444954;
  margin-bottom: 20px;
}
.advert-sort-box-input input {
  width: 100%;
  height: 32px;
  background-color: #444954;
  padding: 0 15px;
  border-width: 0;
  outline: 0;
  font-weight: 500;
}
.advert-sort-box-input input::placeholder {
  color: inherit;
  opacity: 1 !important;
}
.advert-sort-box-input .ssi {
  position: absolute;
  pointer-events: none;
  right: 15px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #14bae3;
}

.advert-sort-box-options {
  margin: 0 0 30px;
  padding: 0;
  font-weight: 500;
}
.advert-sort-box-options + .advert-sort-box-options {
  margin: 0;
}

.advert-sort-box-option {
  position: relative;
  display: block;
  padding: 5px 20px;
  border-radius: 20px;
  cursor: pointer;
}
.advert-sort-box-option p {
  display: inline;
}
.advert-sort-box-option .ssi {
  position: absolute;
  display: none;
  right: 15px;
  height: 14px;
  margin: auto;
  top: 0;
  bottom: 0;
}

.advert-sort-box-options-italic {
  font-style: italic;
}

.advert-sort-box-option.active {
  background: #444954;
  color: #14bae3;
}
.advert-sort-box-option.active .ssi {
  display: block;
}

@media (min-width: 1200px) {
  .advert-table-filters {
    margin: 0 50px;
  }
}