ssi-menu-item {
  border: 1px solid #89a4ea;
  border-radius: 10px;
  padding: 12px;
  background-color: #fff;
}
ssi-menu-item:hover {
  cursor: pointer;
}
ssi-menu-item i {
  margin-right: 5px;
  color: #14bae3;
}
ssi-menu-item span {
  text-transform: none;
  font-size: 12px;
  color: #43537f;
  font-weight: 900;
  letter-spacing: 0;
}