ssi-ad-stats-report .advertising-analytics-report-container {
  width: 100%;
  height: 100%;
}
ssi-ad-stats-report .advertising-analytics-report-container .report-body {
  width: 100%;
  height: 100%;
  padding-top: 94px;
  padding-left: 268px;
  padding-right: 40px;
  min-height: calc(100vh - 68px);
}
ssi-ad-stats-report .advertising-analytics-report-container .report-body .section-delimiter {
  width: 100%;
  height: 2px;
  background-color: #fff;
  margin: 30px 0;
}
ssi-ad-stats-report .advertising-analytics-report-container .report-body .section-title {
  color: #101525;
  font-weight: 900;
  text-rendering: geometricPrecision;
  font-size: 20px;
  margin: 0 0 40px;
}
ssi-ad-stats-report .advertising-analytics-report-container .report-body-loading {
  width: 100%;
  height: 100%;
  height: calc(100vh - 68px);
  padding-top: 94px;
  padding-left: 228px;
}
ssi-ad-stats-report .advertising-analytics-report-container .selected-date-ranges {
  border-radius: 10px;
  background-color: #ebebf4;
  padding: 10px;
}
ssi-ad-stats-report .advertising-analytics-report-container .selected-date-ranges span {
  margin-right: 7px;
}
ssi-ad-stats-report .advertising-analytics-report-container .selected-date-ranges .current {
  color: #101525;
  font-size: 14px;
  font-weight: 900;
}
ssi-ad-stats-report .advertising-analytics-report-container .selected-date-ranges .previous {
  color: #101525;
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
}
ssi-ad-stats-report .advertising-analytics-report-container .facts-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 0 20px;
  margin: 0;
}
@media print {
  ssi-ad-stats-report .advertising-analytics-report-container .facts-container {
    display: block;
  }
  ssi-ad-stats-report .advertising-analytics-report-container .facts-container > * {
    display: inline-block;
    width: auto !important;
  }
}
ssi-ad-stats-report .advertising-analytics-report-container .facts-container > * {
  width: 100%;
}
ssi-ad-stats-report .advertising-analytics-report-container .facts-container > * .fact-overview {
  box-shadow: 0 0.5em 1em rgba(131, 142, 171, 0.35);
  border-radius: 12px;
  width: 100%;
  min-width: 170px;
  margin: 0 0 20px;
}