@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-checkbox-2 {
  .checkbox-2-container {
    position: relative;
    display: flex;
    align-items: center;

    &.reversed {
      justify-content: space-between;
      flex-direction: row-reverse;
      label {
        margin: 0;
        margin-right: 14px;
      }
    }

    &.square {
      .checkbox {
        height: 16px;
        width: 16px;
        border-radius: 2px;
      }

      i {
        top: 3px;
        left: 3px;
      }

      &.reversed {
        i {
          right: 3px;
          left: inherit;
        }
      }
    }

    input {
      visibility: hidden;

      &:disabled {
        cursor: not-allowed;
      }
    }

    .checkbox {
      position: absolute;
      height: 20px;
      width: 20px;
      background-color: #fff;
      border: 1px solid #838eab;
      border-radius: 50%;
      cursor: pointer;

      &.checked {
        background-color: $primary-active;
        border: none;
      }

      &.disabled {
        cursor: not-allowed;
      }
    }

    i {
      position: absolute;
      top: 3px;
      left: 5px;
      color: #fff;
      font-size: 10px;
      font-weight: 500;
      cursor: pointer;

      &.checked {
        // color: $primary-active;
      }

      &.disabled {
        cursor: not-allowed;
      }
    }

    label {
      margin: 0;
      margin-left: 10px;
      white-space: nowrap;
      color: $lmode-body-text;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 900;
      letter-spacing: 1px;
      cursor: pointer;

      &.checked {
        color: $primary-active;
      }

      &.disabled {
        cursor: not-allowed;
      }
    }
  }
}
