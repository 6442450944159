@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-social-push-mode-menu {
  .status-menu {
    width: 90px;
  }

  label {
    margin-right: 5px;
    margin-top: 3px;
    color: $lmode-helper-text;
    font-size: 14px;
    font-weight: 900;
  }

  ul {
    position: absolute;
    top: 0;
    height: 26px;
    width: 90px;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    list-style: none;
    padding: 0;
    border: 1px solid transparent;
    border-color: transparent;

    flex-direction: column;

    &.active {
      display: block;
      height: auto;
      border-left: 1px solid #cacaca;
      border-right: 1px solid #cacaca;
      border-bottom: 1px solid #cacaca;
    }

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 26px;
      padding: 0 10px;
      text-transform: capitalize;
      font-size: 12px;
      font-weight: 900;

      i .ssi {
        font-size: 10px;
        padding-top: 2px;
      }

      &:hover {
        color: $primary-active;
        cursor: pointer;
      }

      &.initial {
        color: #fff;
        font-weight: 900;
      }

      &.offline {
        background-color: $lmode-body-text;
      }

      &.online {
        background-color: $primary-active;
      }

      &.busy {
        background-color: #f4490f;
      }
    }
  }
}
