@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-engagement ssi-engagement-inbox-activity {
  font-size: 1rem;

  .inbox-activity-section {
    width: 100%;
  }

  .section-chart-wrap {
    position: relative;
    background-color: #fff;
    padding: 7em 4em 4em;
    margin: 0 0 2em;
    box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
    border-radius: 1.2em;
    width: 100%;

    .section-chart-context-menu-icon {
      display: inline-block;
      position: absolute;
      top: 2.6rem;
      right: 1.7rem;
      font-size: 2.6rem;
      color: $lmode-helper-text;
      // height: 2.6em;
      // width: 1.6em;
      // background-image: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMDMwMTA0IiB2aWV3Qm94PSIwIDAgMTkgMTkiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGNpcmNsZSBmaWxsPSIjODM4RUFCIiBjeD0iOS41IiBjeT0iMTYuNSIgcj0iMi41Ii8+PGNpcmNsZSBmaWxsPSIjODM4RUFCIiBjeD0iOS41IiBjeT0iOS41IiByPSIyLjUiLz48Y2lyY2xlIGZpbGw9IiM4MzhFQUIiIGN4PSI5LjUiIGN5PSIyLjUiIHI9IjIuNSIvPjwvc3ZnPg==');
      // background-size: auto 100%;
      // background-position: center;
      // background-repeat: no-repeat;
      pointer-events: none;

      @media print {
        display: none;
      }
    }

    highcharts-chart {
      padding: 0 2em 0 2em;
    }

    highcharts-chart,
    .highcharts-container,
    .highcharts-root {
      overflow: visible !important;
    }

    .highcharts-areaspline-series .highcharts-point {
      display: none;
    }

    .highcharts-axis-title {
      font-weight: 900;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }

    .highcharts-axis-labels {
      text {
        font-weight: 900;
      }
    }

    .highcharts-xaxis-labels {
      transform: translate(0, 1em);
    }

    .highcharts-grid-line {
      transform: translate(0, -20px);

      &:nth-child(2n) {
        display: none;
      }
    }

    //   &:first-child {
    //     stroke-width: 3em;
    //     transform: translate(0, -5%);
    //   }

    //   &:last-child {
    //     stroke-width: 2em;
    //     transform: translate(0, calc(-5% + 1em));
    //   }
    // }

    // .highcharts-button.highcharts-contextbutton text:after {
    //   content: '';
    //   background-image: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMDMwMTA0IiB2aWV3Qm94PSIwIDAgMTkgMTkiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGNpcmNsZSBmaWxsPSIjODM4RUFCIiBjeD0iOS41IiBjeT0iMTYuNSIgcj0iMi41Ii8+PGNpcmNsZSBmaWxsPSIjODM4RUFCIiBjeD0iOS41IiBjeT0iOS41IiByPSIyLjUiLz48Y2lyY2xlIGZpbGw9IiM4MzhFQUIiIGN4PSI5LjUiIGN5PSIyLjUiIHI9IjIuNSIvPjwvc3ZnPg==');

    // }

    // .highcharts-button-symbol {
    //   stroke: transparent;
    // }
  }

  // .section-chart-legend {
  //   display: flex;
  //   justify-content: center;
  //   margin: 5em 0 0;

  //   li {
  //     display: inline-block;
  //     margin: 0 2.8em;
  //   }

  //   p {
  //     display: inline-block;
  //     color: $lmode-body-text;
  //     font-size: 1em;
  //     letter-spacing: 0.1em;
  //     text-transform: uppercase;
  //     font-weight: 900;
  //     text-rendering: geometricPrecision;
  //     margin: 0;
  //   }
  // }

  // .section-chart-legend-key {
  //   display: inline-block;
  //   width: 2em;
  //   height: 0.4em;
  //   border-radius: 10em;
  //   margin: 0 0.7em 0 0;
  //   vertical-align: middle;
  // }

  .highcharts-legend-item {
    margin: 0 3em !important;

    span {
      display: inline-block;
      overflow: visible !important;

      &:before {
        content: '';
        position: absolute;
        top: 0.4em;
        left: -2.5em;
        display: inline-block;
        width: 1.8em;
        height: 0.4em;
        border-radius: 10em;
        -webkit-print-color-adjust: exact;
      }
    }

    &.highcharts-series-0 span:before {
      background-color: #425dec !important;
    }

    &.highcharts-series-1 span:before {
      background-color: $primary-active !important;
    }

    &.highcharts-series-2 span:before {
      background-color: #aebde3 !important;
    }
  }
}
