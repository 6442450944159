@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

body.modal-open {
  overflow: auto !important;
}

.notification-window {
  &.notification-window-no-backdrop {
    pointer-events: none;
  }

  // .modal-dialog {
  //   width: fit-content;
  //   min-width: 200px;
  //   max-width: 600px;
  // }

  animation: wiggle 4s linear infinite, delay-animation 2000ms linear infinite;

  @keyframes wiggle {
    0%,
    7% {
      transform: rotateX(0);
    }
    15% {
      transform: rotateX(-15deg);
    }
    20% {
      transform: rotateX(10deg);
    }
    25% {
      transform: rotateX(-10deg);
    }
    30% {
      transform: rotateX(6deg);
    }
    35% {
      transform: rotateX(-4deg);
    }
    40%,
    100% {
      transform: rotateX(0);
    }
  }
}

.notification-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.35);
  padding: 25px;
  pointer-events: all;
  background-color: #101525;

  i {
    margin-right: 10px;
    color: $lmode-helper-text;
  }

  p {
    color: #ffffff;
    font-weight: bold;
    margin-right: auto;
    margin-bottom: 0;
  }

  button {
    appearance: none;
    border: none;
    background: none;
    font-size: 10px;
    padding: 0;
    color: $lmode-helper-text;
    margin-left: 20px;
  }
}
