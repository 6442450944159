@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-diagnostics {
  display: block;
  margin-top: -69px;
  padding-top: 69px;
  background-color: #5dade0;
  height: 100vh;

  .text-red {
    color: #a94442;
  }
}
