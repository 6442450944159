@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../angularjs/common/sass/variables.scss';
@import '~/styles/colors';

$navbar-color: #80858e;
$navbar-background: $dmode-background;
$unit: 2.5px;

ssi-navbar {
  text-transform: capitalize;

  .navbar-inverse {
    background-color: $navbar-background;
    color: $navbar-color;
  }

  .navbar-brand {
    padding: 18px 15px;

    &:hover,
    &:focus {
      circle {
        stroke: #fff;
      }

      path {
        fill: #fff;
      }
    }

    &:active {
      circle {
        stroke: $primary-active;
      }

      path {
        fill: $primary-active;
      }
    }
  }

  .navbar {
    .nav {
      > li {
        > a,
        > button {
          color: inherit;
          padding: 23px 15px 25px;
        }
      }

      > .active,
      > .show {
        > a,
        > a:hover,
        > a:focus {
          background-color: $navbar-background;
          color: $primary-active;
        }
      }

      .compose-post {
        padding: 2px;
        a {
          border-radius: 10px;
          background-color: #40424e;
          color: $primary-active;
          font-weight: bold;
          font-size: 11px;
          padding: 0;
          margin: 16px;
          width: fit-content;
        }
        span {
          margin: 0 14px 0 2px;
          text-transform: uppercase;
        }
        i {
          &:first-child {
            padding: 10px 2px 10px 15px;
          }
          &.toggle-publisher {
            border-radius: 50px;
            background-color: #4b4c56;
            padding: 7px;
            margin: 3px;
          }
        }
      }

      .resource-guide-item {
        margin-left: 15px;
        width: 50px;
      }
    }

    .navbar-right {
      display: flex;
      align-items: center;
      > li {
        &:last-child {
          padding-right: 15px;
        }

        > a {
          padding: 23px 10px;
        }

        > button {
          background-color: transparent;
          border: none;
          outline: none;
          padding: 20px 10px;

          &:focus,
          &:hover {
            background-color: $navbar-background;
            color: #fff;
          }

          i {
            font-size: 24px;
          }

          &.navbar-live-chat-toggle {
            &.active {
              color: #49aee6 !important;
            }
          }
        }
      }
    }

    .navbar-right-action-icon {
      font-size: 20px;
    }
  }

  .navbar-right-notification {
    color: $primary-active;
  }

  .nav-item-icon {
    font-size: 14px;
    vertical-align: middle;
    padding-right: 5px;
  }

  .nav-item-label {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 11px;
    line-height: 14px;
    vertical-align: middle;
  }

  .nav-item-caret {
    position: absolute;
    right: -2px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 12px;
  }

  .navbar-header {
    height: navbar-height;
    line-height: auto;
    max-width: 100vw;

    .navbar-toggle {
      float: none;
      margin: 0;
      position: absolute;
      font-size: 1.5em;
      right: 6px;
      top: 6px;

      &.resource-guide-active {
        right: 66px;
      }
    }
  }

  .navbar-support {
    .label {
      border-radius: ($unit * 6);
      font-size: 1em;
      padding: $unit ($unit * 4);
      cursor: pointer;
      font-weight: normal;

      .fa {
        margin-right: $unit;
      }

      span {
        font-size: 0.85em;
      }
    }
    a {
      padding-right: 0;
      padding-left: 5px;
    }
  }

  @media (max-width: $navbar-mobile-breakpoint) {
    .navbar-toggle {
      display: block;
    }
    .navbar-header {
      float: none;
    }
    .navbar-collapse {
      padding: 0 15px !important;
      margin: 0 -15px !important;
      max-width: 100vw;
      &.collapse {
        display: none !important;
        height: auto !important;
        padding-bottom: 0;
        overflow: auto !important;
        .navbar-nav {
          float: none !important;
          margin: 7.5px -15px;
          > li {
            float: none;
            max-width: 95vw;
          }
          .dropdown-menu {
            position: static;
            float: none;
            width: auto;
            margin-top: 0;
            background-color: transparent;
            border: 0;
            -webkit-box-shadow: none;
            box-shadow: none;
          }
        }
        &.show {
          display: block !important;
        }
      }
    }
  }

  .workflows-selector {
    .nav-item-label {
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100px;
      display: inline-block;
      overflow: hidden;
    }
  }
}

@media only screen and (max-width: 1048px) {
  .navbar-nav {
    .show {
      .dropdown-menu {
        > li > a {
          color: #9d9d9d;
          font-size: 1.2em;
          &:hover {
            color: #fff;
            background-color: transparent;
          }
        }
        > .active {
          background-color: #090909 !important;
          a {
            color: #fff;
          }
        }
      }
    }
  }
  .navbar {
    .nav {
      li.compose-post {
        a {
          margin: 16px 0;
        }
      }
    }
  }
}
