.loader {
  width: 30px;
  height: 30px;
  border: 5px solid #adefff;
  border-top-color: #43ade8;
  border-radius: 50%;
  position: relative;
  animation: loader-rotate 1s linear infinite;
  top: 50%;
  margin: -28px auto 0;
}

@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}