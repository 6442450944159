:host {
  margin-bottom: 3px;
}

ssi-label-tag label {
  display: flex;
  align-self: center;
  align-items: center;
  background-color: #f8f9fd;
  color: #101525;
  border-radius: 15px;
  font-size: 12px;
  font-weight: 900;
  overflow: hidden;
  margin: 0;
  padding: 4px 6px 4px 12px;
}
ssi-label-tag label .remove-btn {
  margin-left: 8px;
  font-size: 7px;
  font-weight: 900;
  color: #838eab;
  border-radius: 50%;
  padding: 5px;
}
ssi-label-tag label .remove-btn:hover {
  cursor: pointer;
  background-color: #14bae3;
  color: #fff;
}