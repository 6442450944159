ssi-account-report .account-report-container {
  width: 100%;
  height: 100%;
}
ssi-account-report .account-report-container .report-body {
  width: 100%;
  height: 100%;
  padding-top: 94px;
  padding-left: 268px;
  padding-right: 40px;
  min-height: calc(100vh - 68px);
}
ssi-account-report .account-report-container .report-body .section-container:first-child {
  padding-top: 40px;
}
ssi-account-report .account-report-container .report-body .section-delimiter {
  width: 100%;
  height: 2px;
  background-color: #fff;
  margin: 30px 0;
}
ssi-account-report .account-report-container .report-body .section-title {
  color: #101525;
  font-weight: 900;
  text-rendering: geometricPrecision;
  font-size: 20px;
  margin: 0 0 40px;
}
ssi-account-report .account-report-container .report-body-loading {
  width: 100%;
  height: 100%;
  height: calc(100vh - 68px);
  padding-top: 94px;
  padding-left: 228px;
}
ssi-account-report .account-report-container .selected-date-ranges {
  border-radius: 10px;
  background-color: #ebebf4;
  padding: 10px;
}
ssi-account-report .account-report-container .selected-date-ranges span {
  margin-right: 7px;
}
ssi-account-report .account-report-container .selected-date-ranges .current {
  color: #101525;
  font-size: 14px;
  font-weight: 900;
}
ssi-account-report .account-report-container .selected-date-ranges .previous {
  color: #101525;
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
}
ssi-account-report .account-report-container .facts-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 0 20px;
  margin: 0;
}
@media print {
  ssi-account-report .account-report-container .facts-container {
    display: block;
  }
  ssi-account-report .account-report-container .facts-container > * {
    display: inline-block;
    width: auto !important;
  }
}
ssi-account-report .account-report-container .facts-container > * {
  width: 100%;
}
ssi-account-report .account-report-container .facts-container > * .fact-overview {
  box-shadow: 0 0.5em 1em rgba(131, 142, 171, 0.35);
  border-radius: 12px;
  width: 100%;
  min-width: 170px;
  margin: 0 0 20px;
}
ssi-account-report ssi-treetable {
  font-size: 1rem;
  overflow: visible;
}
ssi-account-report ssi-treetable .content {
  background-color: #fff;
  border-radius: 1.2em;
  box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
  padding: 3em 2.6em 4.6em;
  margin: 0 0 2em;
}
ssi-account-report ssi-treetable .content .content-inner .head .head-inner .row .cell .cell-inner-cover.active,
ssi-account-report ssi-treetable .content .content-inner .head .head-inner .row .cell {
  background-color: #fff;
  border: 0;
}
ssi-account-report ssi-treetable .content .content-inner .head .head-inner .row .cell .cell-inner .col-header > span {
  overflow: visible;
  white-space: normal;
  letter-spacing: 0.1em;
  color: #43537f;
  text-transform: uppercase;
  text-rendering: geometricPrecision;
}
ssi-account-report ssi-treetable .content .content-inner .head .head-inner .row .cell .cell-inner-cover,
ssi-account-report ssi-treetable .content .content-inner .head .head-inner .row .cell:hover {
  box-shadow: none;
}
ssi-account-report ssi-treetable .body {
  margin: 2em 0 0;
}
ssi-account-report ssi-treetable .body-inner .row {
  position: relative;
}
ssi-account-report ssi-treetable .body-inner .row:nth-child(2n) {
  background-color: #f8f9fd;
}
ssi-account-report ssi-treetable .body-inner .row:nth-child(2n):before, ssi-account-report ssi-treetable .body-inner .row:nth-child(2n):after {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 0.2em;
  border-radius: 10em;
  background-color: #e0e7f4;
  z-index: 1;
}
ssi-account-report ssi-treetable .body-inner .row:nth-child(2n):after {
  top: auto;
  bottom: 0;
}
ssi-account-report ssi-treetable .body-inner .cell {
  display: flex;
  align-items: center;
}
ssi-account-report ssi-treetable .body-inner .cell p {
  color: #101525;
  font-weight: 900;
  text-rendering: geometricPrecision;
  font-size: 1.4em;
  padding: 0 0.6em 0 1em;
  margin: 0;
}
ssi-account-report ssi-treetable .body-inner .body-cell-primary {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  text-rendering: geometricPrecision;
  width: 500px;
}
ssi-account-report ssi-treetable .body-inner .body-cell-primary i {
  display: inline-flex;
  align-items: center;
  color: #838eab;
  margin: 0 1em 0 0.7em;
}
ssi-account-report ssi-treetable .body-inner .body-cell-primary i:before {
  font-size: 1.2em;
}
ssi-account-report ssi-treetable .body-inner .body-cell-primary p {
  padding: 0;
  display: inline-block;
  color: #43537f;
  font-size: 1.2em;
  font-weight: 900;
  margin: 0;
}
ssi-account-report .account-list {
  margin: -0.5rem -0.5rem 0;
  padding: 0;
}
ssi-account-report .account-list li {
  display: inline-block;
  margin: 0.5rem;
}
ssi-account-report .body-cell-sentiment {
  width: 100%;
}
ssi-account-report .report-sentiment-bar {
  display: flex;
  background-color: black;
  height: 20px;
  width: 90%;
  margin: 0 auto;
  border-radius: 5px;
  overflow: hidden;
}
ssi-account-report .report-sentiment-bar > * {
  display: inline-block;
  height: 100%;
}
ssi-account-report .report-sentiment-bar-negative {
  background-color: #e36635;
}
ssi-account-report .report-sentiment-bar-netural {
  background-color: #43ade8;
}
ssi-account-report .report-sentiment-bar-positive {
  background-color: #acb638;
}
ssi-account-report .report-sentiment-bar-male {
  background-color: #43ade8;
}
ssi-account-report .report-sentiment-bar-female {
  background-color: #a94442;
}