@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

$ssi-message-shared-post-responsive-width: 885px;

ssi-message-share-preview {
  .shared-post-preview-container {
    .link-to-shared-post {
      text-decoration: none;
    }

    .shared-post-container {
      display: flex;
      flex-direction: column;
      max-width: 600px;
      background-color: #fff;
      padding: 20px;
      border-radius: 12px;

      .metadata {
        display: flex;
        align-items: center;
        @media (max-width: $ssi-message-shared-post-responsive-width) {
          max-width: 100vw;
          flex-direction: column;
        }

        > * {
          margin-right: 10px;
        }

        img {
          height: 25px;
          width: 25px;
          border-radius: 50%;
        }

        .name {
          color: $primary-active;
          font-size: 14px;
          font-weight: 700;
          line-height: 17px;
        }

        .icon-container {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $grey-background;
          border-radius: 50%;
          width: 20px;
          height: 20px;

          i {
            color: $primary-active;
            font-size: 12px;
          }
        }

        .username {
          border-radius: 100px;
          background-color: $grey-background;
          padding: 5px;
          span {
            color: $lmode-body-text;
            font-size: 10px;
            font-weight: 900;
            line-height: 12px;
          }
        }

        .timestamp {
          color: $lmode-body-text;
          font-size: 10px;
          font-weight: 900;
          line-height: 24px;
        }
      }

      .content {
        margin-top: 5px;

        p {
          margin: 0;
          max-height: 45px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;

          color: $lmode-title-text;
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;
        }
      }
    }

    .media-container {
      display: block;
      width: fit-content;
      background-color: #fff;
      padding: 13px;
      border-radius: 12px;
      margin-top: 5px;

      .gallery {
        position: relative;

        img,
        .video-icon-container {
          height: 60px;
          width: 80px;
          border-radius: 10px;
          box-shadow: 0 3px 5px 0 rgba(131, 142, 171, 0.35);

          &.stacked {
            position: absolute;
            // top: ngStyle
            // left: ngStyle
          }
        }

        .video-icon-container {
          display: flex;
          align-items: center;
          justify-content: center;
          color: $primary-active;
          background-color: $grey-background;

          i {
            font-size: 60px;
          }
        }

        .media-items-count {
          position: absolute;
          right: -20px;
          top: 45%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #fff;
          border-radius: 50%;
          width: 25px;
          height: 25px;
          color: $primary-active;
          font-size: 10px;
          font-style: italic;
          font-weight: 900;
          line-height: 12px;
          box-shadow: 0 5px 15px 0 rgba(116, 118, 132, 0.25);
        }
      }
    }
  }
}
