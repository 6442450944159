@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../../angularjs/common/sass/include-media';
@import '../../../../../../common/styles/colors.scss';
@import '../../../../../../../angularjs/common/sass/mixins';

$margin-between-boxes: 20px;
$border-radius: 12px;

ssi-add-widget {
  .add-dashboard-widget-content-container {
    font-size: 1rem;

    display: flex;
    flex-direction: column;
    position: relative;
    background: #fff;
    border-radius: $border-radius;
    height: 100%;
    padding: 40px 50px;

    i.close-icon {
      position: absolute;
      top: 20px;
      right: 20px;
      background-color: #f2f4fc;
      color: #838eab;
      border-radius: 50px;
      padding: 13px;
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: left;
      margin-bottom: 40px;

      .title {
        font-size: 2em;
        font-weight: 900;
        color: $lmode-body-text;
        white-space: nowrap;
        flex-basis: 20%;
        margin: 0;
      }

      .heading-right {
        flex-basis: 80%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .search-field {
      display: flex;
      align-items: center;
      text-align: left;
      margin-right: auto;

      i {
        color: $lmode-body-text;
        font-size: 1.2em;
        margin: 0 0.6rem 0 0;
      }
      input {
        color: $lmode-body-text;
        font-size: 1.4em;
        font-weight: 700;
        text-rendering: geometricPrecision;
        border-width: 0;
        outline: 0;
        padding: 0;
        &::placeholder {
          color: #838eab;
        }
      }
    }

    .body {
      overflow: auto;
    }

    .items-list-container {
      overflow: hidden;
    }

    .items-list {
      display: flex;
      flex-wrap: wrap;
      overflow: auto;
      height: 100%;
      align-content: flex-start;
    }

    .item-box {
      position: relative;
      width: 100%;
      margin: 0 20px 20px 0;
      border-radius: $border-radius;
      flex: 1 1 240px;

      .thumbnail-action {
        border-radius: $border-radius;
      }

      i {
        font-size: 1.2em;
      }

      .item-thumb {
        border: 1px solid #d1dbf4;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        width: 100%;
        height: 16em;
        background-color: $grey-background;
        border-radius: $border-radius;
        margin-bottom: 20px;

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 130px;
          color: #a3b8ee;
          opacity: 0.2;
        }

        &:hover {
          border-style: dashed;
          border-color: $primary-active;
        }
      }

      .add-widget-button {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 60px;
        .ssi {
          font-size: 0.7em;
        }
      }

      .title,
      .description {
        text-align: left;
        width: 100%;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: middle;
        text-align: left;
        margin-bottom: 0.5em;
        padding-left: 10px;
      }

      .title {
        color: $lmode-title-text;
        font-weight: 900;
        font-size: 1.6em;
      }

      .description {
        color: $lmode-body-text;
        font-weight: bold;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;
      }
    }

    @supports (display: grid) {
      .widget-list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(25em, 1fr));
        grid-gap: 2.1em;
        margin: 0;

        .widget-box {
          margin: 0;
        }

        @include media('>1800px') {
          grid-template-columns: repeat(auto-fill, minmax(30em, 1fr));
        }
      }
    }

    @include ie-only {
      .widget-list {
        display: flex;
        margin: 0 -1em 2.4em;

        .widget-box {
          margin: 0 1em;
        }
      }
    }
  }
}
