.table-form-snippet {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.table-form-snippet > *:first-child {
  width: 25%;
  flex: 0 1 25%;
}
.table-form-snippet > *:nth-child(2) {
  width: 48%;
  flex: 0 2 48%;
}
.table-form-snippet > *:last-child {
  width: 25%;
  flex: 0 1 25%;
}