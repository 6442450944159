ssi-insights-posts .posts-container {
  overflow: auto;
  background-color: #f4f4fa;
  height: calc(100vh - 199px);
  display: flex;
  flex-direction: column;
}
ssi-insights-posts .posts-container.filters-bar-visible {
  height: calc(100vh - 249px);
}
ssi-insights-posts .posts-container .posts-wrapper {
  flex: 1 0 auto;
  padding: 6px 12px;
  display: flex;
}
ssi-insights-posts .posts-container .posts-wrapper .column {
  flex: 1 1 calc(50% - 3px);
  margin: 6px;
}
ssi-insights-posts .posts-container .posts-wrapper .column .post-wrapper {
  margin-bottom: 10px;
}
ssi-insights-posts .posts-container .no-posts-wrapper {
  flex: 1 1 100%;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
ssi-insights-posts .posts-container .paginator-wrapper {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin: 60px 0;
}
ssi-insights-posts .posts-container .paginator-wrapper.hidden {
  display: none;
}