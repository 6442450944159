@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../angularjs/common/sass/variables';
@import '~/styles/colors';

.validate-posts-modal {
  .modal-content {
    border-radius: 25px;
    background-color: #424448;
    color: #fff;
    box-shadow: none;
    max-width: 515px;
    margin: 0 auto;
  }
}

.validate-posts-modal-body {
  text-align: center;
  padding: 50px;
  font-weight: 500;
}

.validate-posts-modal-icon {
  font-size: 60px;
  margin-bottom: 20px;
}

.validate-posts-modal-title {
  font-size: 18px;
}

.validate-posts-modal-button {
  border: 0;
  width: 170px;
  margin: 25px 5px 10px;
  padding: 10px;
  border-radius: 20px;

  &.btn-danger {
    background-color: #505258;
  }

  &.btn-primary {
    background-color: $primary-active;
  }
}
