ssi-campaign-box .campaign-select {
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  z-index: 1;
  cursor: pointer;
}
ssi-campaign-box .campaign-settings {
  width: 100%;
  position: absolute;
  top: 77px;
  bottom: 0;
  background-color: #dfe5e8;
  z-index: 1;
}
ssi-campaign-box .campaign-settings .campaign-action {
  top: -24px;
  position: relative;
}
ssi-campaign-box .campaign-container {
  display: flex;
  position: absolute;
  flex-direction: column;
  height: calc(100% - 25px);
  width: 100%;
}
ssi-campaign-box .campaign-container-ad .settings-trigger:hover, ssi-campaign-box .campaign-container-ad .settings-trigger:focus,
ssi-campaign-box .campaign-settings-ad .settings-trigger:hover,
ssi-campaign-box .campaign-settings-ad .settings-trigger:focus {
  background: #43537f;
}
ssi-campaign-box .campaign-container-ad .campaign-subtitle-icon.campaign-subtitle-icon-primary,
ssi-campaign-box .campaign-settings-ad .campaign-subtitle-icon.campaign-subtitle-icon-primary {
  color: #43537f;
}
ssi-campaign-box .campaign-section {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
}
ssi-campaign-box .campaign-section-light {
  background-color: #f9fafb;
}
ssi-campaign-box .campaign-header {
  position: relative;
  margin: 10px 15px;
}
ssi-campaign-box .campaign-header:before {
  content: "";
  position: absolute;
  display: block;
  border-bottom: 2px solid #f3f6f7;
  margin-top: 24px;
  width: 100%;
}
ssi-campaign-box .campaign-action {
  position: relative;
  display: inline-flex;
  border: solid 2px #f3f6f7;
  color: #43537f;
  font-weight: 700;
  font-size: 22px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: white;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
ssi-campaign-box .campaign-action.settings-trigger {
  cursor: pointer;
}
ssi-campaign-box .campaign-action.active {
  background-color: #14bae3;
  color: #fff;
}
ssi-campaign-box .settings-trigger {
  position: relative;
  z-index: 1;
}
ssi-campaign-box .settings-trigger:hover {
  color: white;
  background-color: #43ade8;
}
ssi-campaign-box .campaign-setting-button {
  width: 200px;
  border-radius: 20px;
  text-align: right;
}
ssi-campaign-box .campaign-children {
  padding: 1em;
}
ssi-campaign-box .campaign-children p {
  font-size: 40px;
}
ssi-campaign-box .campaign-info {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
ssi-campaign-box .campaign-title {
  color: #43537f;
  font-weight: 700;
  text-align: center;
  display: block;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
ssi-campaign-box .campaign-title-meta {
  display: inline-block;
  font-style: normal;
}
ssi-campaign-box .campaign-title-meta:before {
  content: "|";
  margin: 0 8px;
}
ssi-campaign-box .campaign-subtitle {
  font-size: 14px;
  color: #838eab;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
}
ssi-campaign-box .campaign-subtitle p {
  display: inline-block;
  margin: 0;
  color: #838eab;
  font-weight: 900;
}
ssi-campaign-box .campaign-subtitle p:after {
  content: "|";
  margin: 0 8px;
  vertical-align: top;
}
ssi-campaign-box .campaign-subtitle-icon {
  font-size: 28px;
  font-weight: 500;
  margin: 0 0 3px;
}
ssi-campaign-box .campaign-subtitle-icon-primary {
  color: #14bae3;
}
ssi-campaign-box .campaign-subtitle-icon-warning {
  color: #dfc045;
}
ssi-campaign-box .campaign-stats {
  margin: 0 auto;
  padding: 0;
}
ssi-campaign-box .campaign-stat.campaign-stat-clear {
  display: inline-block;
  background-color: transparent;
  color: #43537f;
  font-weight: 700;
  font-size: 12px;
  padding: 0;
  margin: 0;
}
ssi-campaign-box .campaign-stat.campaign-stat-clear:after {
  content: "|";
  margin: 0 8px;
}
ssi-campaign-box .campaign-stat.campaign-stat-clear:last-child:after {
  content: normal;
}
ssi-campaign-box .campaign-stat.campaign-stat-clear p {
  display: inline-block;
  margin: 0 5px 0 0;
}
ssi-campaign-box .campaign-editor {
  position: absolute;
  left: 20px;
  right: 20px;
  top: 50%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
  transform: translateY(-50%);
  z-index: 1;
}
ssi-campaign-box .campaign-editor select,
ssi-campaign-box .campaign-editor input {
  width: 100%;
  background-color: #f6f8f9;
  border: none;
  border-radius: 20px;
  padding: 6px 14px;
}
ssi-campaign-box .select-wrapper {
  width: 100%;
  display: inline-block;
  padding: 6px 16px 6px 6px;
  background-color: #f6f8f9;
  border-radius: 20px;
}

.tooltip-campaign {
  border-radius: 15px;
  padding: 0 10px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}
.tooltip-campaign * {
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
}
.tooltip-campaign .tooltip-body-title {
  font-weight: 600;
  font-size: 12px;
}
.tooltip-campaign .tooltip-body-contents ul {
  width: 100%;
  padding: 0;
  margin: 0;
  text-align: left;
}