ssi-influencers-list {
  display: block;
  font-size: 1rem;
  width: 100%;
}
ssi-influencers-list .influencers-list {
  margin: 0 0 1em;
  padding: 0 0 0 1.4em;
  overflow: auto;
}
ssi-influencers-list .influencers-list-labels {
  display: flex;
  width: 100%;
  margin: 0 0 1.5em;
  padding: 0;
}
ssi-influencers-list .influencers-list-label {
  flex: 0 0 13em;
  display: block;
}
ssi-influencers-list .influencers-list-label:first-child {
  flex: 1 0 auto;
  min-width: 35em;
  text-align: left;
}
ssi-influencers-list .influencers-list-label:last-child {
  flex: 0 0 14.4em;
}
ssi-influencers-list .influencers-list-label-center {
  text-align: center;
}
ssi-influencers-list .influencers-list-label-right {
  text-align: right;
}
ssi-influencers-list .influencers-list-label p {
  display: inline-block;
  color: #43537f;
  font-weight: 900;
  font-size: 1em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: 0;
  text-rendering: geometricPrecision;
}
ssi-influencers-list .influencers-list-label-action {
  display: inline-block;
  margin: 0 0.4rem 0 0;
  font-size: 2em;
  line-height: 1rem;
  vertical-align: sub;
  background: none;
  border: none;
  padding: 0;
  color: #838eab;
  cursor: pointer;
}
ssi-influencers-list .influencers-list-label-action-active {
  color: #14bae3;
}
ssi-influencers-list .influencers-list-data {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
}
ssi-influencers-list .influencers-list-data-item {
  display: flex;
  align-items: center;
  width: calc(100% + 1.4em);
  margin-left: -1.4em;
  padding: 0.7em 0 0.7em 1.4em;
  border-bottom: 0.1em solid #e2e2ee;
}
ssi-influencers-list .influencers-list-data-item:last-child {
  border-bottom-width: 0;
}
ssi-influencers-list .influencers-list-data-col {
  flex: 0 0 13em;
}
ssi-influencers-list .influencers-list-data-col:first-child {
  flex: 1 1 auto;
  min-width: 35em;
}
ssi-influencers-list .influencers-list-data-col:last-child {
  flex: 0 0 14.4em;
}
ssi-influencers-list .influencer-meta {
  overflow: hidden;
  margin: 0 0.4em 0 0;
}
ssi-influencers-list .influencer-account {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
ssi-influencers-list .influencer-badge {
  position: relative;
  display: inline-block;
  margin: 0.4em 0.8em 0 0;
  height: 2.1em;
  flex: 0 0 2.6em;
  width: 2.6em;
}
ssi-influencers-list .influencer-badge-meta {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  margin: auto;
  color: #101525;
  font-size: 1em;
  line-height: 1.4em;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-influencers-list .influencer-badge-wreathe-gold {
  color: #f0b427;
}
ssi-influencers-list .influencer-badge-wreathe-silver {
  color: #98a7d1;
}
ssi-influencers-list .influencer-badge-wreathe-bronze {
  color: #f88c68;
}
ssi-influencers-list .influencer-account-avatar {
  display: inline-flex;
  flex: 0 0 3.6em;
  height: 3.6em;
  width: 3.6em;
  background: grey;
  border-radius: 50%;
  margin: 0 1em 0 0;
  overflow: hidden;
  align-content: center;
}
ssi-influencers-list .influencer-account-avatar img {
  width: 100%;
  min-height: 100%;
}
ssi-influencers-list .influencer-account-details {
  display: flex;
  flex: 0 1 auto;
}
ssi-influencers-list .influencer-account-details-network {
  font-size: 1.2em;
  width: 1.2rem;
  margin: 0 1rem 0 0;
  flex: 0 1 auto;
}
ssi-influencers-list .influencer-account-name {
  color: #101525;
  font-size: 1.4em;
  font-weight: 900;
  text-underline-offset: 0.4rem;
  margin: 0 0.3em 0 0;
  text-rendering: geometricPrecision;
  white-space: nowrap;
}
ssi-influencers-list .influencer-account-alias {
  color: #000;
  font-size: 1.4em;
  font-weight: 400;
  margin: -0.1rem 0 0 0;
  white-space: nowrap;
}
ssi-influencers-list .influencer-followers p {
  font-size: 1.4em;
  color: #000;
  font-weight: 900;
  margin: 0;
  text-rendering: geometricPrecision;
}
ssi-influencers-list .influencer-button {
  background-color: #14bae3;
  border-width: 0;
  display: block;
  width: 100%;
  height: 2.6em;
  border-radius: 0.5em;
}
ssi-influencers-list .influencer-button span {
  font-size: 1.2em;
  font-weight: 900;
  color: #fff;
  text-rendering: geometricPrecision;
}
ssi-influencers-list .actionable {
  cursor: pointer;
}