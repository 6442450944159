@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

$status-highlight-live: #e2b928;
$status-highlight-scheduled: #8e52cc;
$status-highlight-paused: $primary-active;
$status-highlight-completed: $primary-active;

.advert-table-filters {
  position: relative;
  display: flex;
  justify-content: center;
  background-color: #383c46;
  font-weight: 500;
  font-size: 12px;
  padding: 20px;
  z-index: 2;
  white-space: nowrap;

  > * {
    flex: 0 0 33%;
  }

  &.advert-table-filters-two-col {
    justify-content: flex-start;
  }

  &.advert-table-filters-three-col {
    justify-content: space-between;
  }
}

.advert-table-filters-list {
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  vertical-align: middle;

  > li {
    position: relative;
    display: inline-block;
    padding: 0 10px;
    cursor: pointer;

    &:last-child {
      padding-right: 0;
    }

    &:after {
      content: '';
      position: absolute;
      right: 0;
      margin: auto;
      top: 0;
      bottom: 0;
      height: 11px;
      border-right: 1px solid #a8b9c2;
    }

    &:last-child:after {
      content: normal;
    }
  }

  > .active {
    color: $primary-active;
  }

  p {
    margin: 0;
  }
}

.advert-table-filters-sort {
  > span {
    display: inline-block;
  }

  p {
    display: inline;
  }
}

.advert-table-filters-sort-icon {
  font-size: 20px;
  color: $primary-active;
  margin-right: 8px;
  vertical-align: middle;
}

.advertising-highlight {
  color: $primary-active;
}

.advert-table-filters-soc {
  text-align: center;
}

.advert-table-filters-status {
  text-align: right;

  .active {
    &.table-filter-status-item,
    &.table-filter-status-item-live {
      color: $status-highlight-live;
    }

    &.table-filter-status-item-scheduled {
      color: $status-highlight-scheduled;
    }

    &.table-filter-status-item-paused {
      color: $status-highlight-paused;
    }

    &.table-filter-status-item-completed {
      color: $status-highlight-completed;
    }
  }
}

.advert-sort-action {
  cursor: pointer;
}

.advert-sort-box {
  position: absolute;
  top: 54px;
  left: -10px;
  background-color: #393c46;
  border-radius: 20px;
  padding: 1em;
  width: 400px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  font-size: 14px;
}

.advert-sort-box-input {
  position: relative;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  background-color: #444954;
  margin-bottom: 20px;

  input {
    width: 100%;
    height: 32px;
    background-color: #444954;
    padding: 0 15px;
    border-width: 0;
    outline: 0;
    font-weight: 500;

    &::placeholder {
      color: inherit;
      opacity: 1 !important;
    }
  }

  .ssi {
    position: absolute;
    pointer-events: none;
    right: 15px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    font-size: 18px;
    color: $primary-active;
  }
}

.advert-sort-box-options {
  margin: 0 0 30px;
  padding: 0;
  font-weight: 500;

  + .advert-sort-box-options {
    margin: 0;
  }
}

.advert-sort-box-option {
  position: relative;
  display: block;
  padding: 5px 20px;
  border-radius: 20px;
  cursor: pointer;

  p {
    display: inline;
  }

  .ssi {
    position: absolute;
    display: none;
    right: 15px;
    height: 14px;
    margin: auto;
    top: 0;
    bottom: 0;
  }
}

.advert-sort-box-options-italic {
  font-style: italic;
}

.advert-sort-box-option.active {
  background: #444954;
  color: $primary-active;

  .ssi {
    display: block;
  }
}

@media (min-width: 1200px) {
  .advert-table-filters {
    margin: 0 50px;
  }
}
