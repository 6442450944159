@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-inbox-language-selector {
  position: absolute;
  z-index: 5;
  padding: 25px 15px 32px 21px;
  background: #fff;
  border-radius: 25px;
  box-shadow: 0px 10px 15px rgba(231, 232, 238, 0.65);

  .language-selector-container {
    position: relative;
  }

  .language-selector-input {
    color: $lmode-body-text;
    font-size: 12px;
    line-height: 28px;
    font-weight: 700;
    width: 140px;
    text-indent: 6px;
    outline: 0;
    border: 0;
    padding: 0;
    border-bottom: 2px solid $primary-active;
    text-rendering: geometricPrecision;
    margin: 0 0 0 8px;

    &::placeholder {
      color: $lmode-helper-text;
    }
  }

  .language-selector-input-action {
    position: absolute;
    right: 0;
    top: 5px;
    color: $primary-active;
    font-size: 12px;
    font-weight: 900;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .language-selector-list {
    height: 175px;
    width: 194px;
    overflow-y: scroll;
    overflow-x: none;
    list-style: none;
    padding: 0 0 0 4px;
    margin: 18px 0 0;
  }

  .language-selector-list-item {
    margin: 2px 0;
    max-width: 100%;

    &.language-selector-list-item-active {
      .language-selector-list-item-label {
        color: $primary-active;
        background-color: $grey-background;
      }
    }
  }

  .language-selector-list-item-label {
    display: inline-block;
    font-size: 12px;
    line-height: 26px;
    font-weight: 900;
    color: $lmode-body-text;
    padding: 0 11px;
    border-radius: 100px;
    text-rendering: geometricPrecision;
    cursor: pointer;

    &:hover,
    &:focus {
      color: $primary-active;
    }

    .ssi {
      margin: 0 0 0 10px;
    }
  }
}
