@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-draft {
  font-size: 1rem;

  .draft-container {
    margin: 0 0 1em;
  }

  .draft {
    background-color: #fff;
    border-radius: 1em;
    border: 0.1em solid #c3c9d8;

    &.active-delete {
      border-color: $secondary-red;
    }
  }

  .draft-row {
    display: flex;
    padding: 0 2em 0 2.4em;
  }

  .draft-header {
    border-bottom: 0.1em solid;
    border-color: inherit;
    color: $lmode-body-text;
    min-height: 6.1em;
    flex-wrap: wrap;
  }

  .draft-title-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.6rem 0 -0.3em;
    & + .draft-title-icon {
      margin-left: -2rem;
    }
  }

  .draft-title {
    display: flex;
    align-items: center;
    flex: 1 0 auto;

    h2 {
      color: $lmode-title-text;
      font-size: 1.6em;
      margin: 0 0.4em 0 0;
      font-weight: 900;
      text-rendering: geometricPrecision;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .draft-title-pills {
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      background-color: #f4f4fa;
      border-radius: 10em;
      padding: 0.4em 0.6em;
      margin: 0 0.6em 0 0;

      & + li {
        margin-left: -0.6em;
      }

      p {
        color: $lmode-body-text;
        line-height: 1;
        font-size: 1.2em;
        font-weight: 900;
        text-rendering: geometricPrecision;
        margin: 0;
      }
    }
  }

  .draft-meta {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    border-color: inherit;
  }

  .draft-meta-list {
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      white-space: nowrap;

      &:not(:last-child):after {
        content: '|';
        display: inline-block;
        margin: 0 0.6em;
        font-size: 1.2rem;
        font-weight: 900;
        text-rendering: geometricPrecision;
      }
    }

    p {
      display: inline-block;
      font-size: 1.2em;
      font-weight: 900;
      text-rendering: geometricPrecision;
      margin: 0;
    }
  }

  .draft-meta-author {
    display: inline-flex;
    align-items: center;
    padding: 0.4em 0.6em;
    border: 1px solid;
    border-color: inherit;
    border-radius: 0.4em;
    margin: 0 0 0 1em;

    i {
      color: $lmode-helper-text;
      margin: 0 0.6rem 0 0;
      font-size: 1.1em;
    }

    p {
      color: $lmode-body-text;
      font-size: 1em;
      margin: 0;
      line-height: 1;
      font-weight: 900;
      text-rendering: geometricPrecision;
      text-transform: uppercase;
    }
  }

  .draft-body {
    padding-top: 0.8em;
    padding-bottom: 0.8em;
    display: flex;
    align-items: start;
    justify-content: space-between;

    p {
      color: $lmode-title-text;
      font-size: 1.4em;
      line-height: 2.4rem;
      margin: 0.5em 0 0.6em;
    }
  }

  .draft-gallery {
    position: relative;
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    margin: 1em 0 1em 5.7em;

    &-multiple {
      img:first-child {
        margin-top: -0.7em;
        margin-left: -0.3em;
      }
    }

    img,
    video {
      display: block;
      border-radius: 1em;
      overflow: hidden;
      background-color: #eee;
      height: 5.7em;
      object-fit: contain;

      & + img {
        position: absolute;
        top: 0.6em;
        left: 0.3em;
        box-shadow: 0 0.5em 1.5em rgba(116, 118, 132, 0.25);
      }
    }

    video {
      width: 17.3em;
      height: 10.7em;
    }
  }

  .draft-gallery-badge {
    background-color: #fff;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -1.3em;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 2em;
    width: 2em;
    color: $primary-active;
    font-weight: 900;
    text-rendering: geometricPrecision;
    font-style: italic;
    border-radius: 50%;
    box-shadow: 0 0.5em 1.5em rgba(116, 118, 132, 0.25);
  }

  .draft-footer {
    border-top: 0.1em solid;
    border-color: inherit;
    color: $lmode-body-text;
    padding-top: 1em;
    padding-bottom: 1em;
    min-height: 6em;
  }

  .draft-actions {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
  }

  .draft-actions-left {
    flex: 1 1 auto;
    margin: 0 0 0 -1.3em;
    padding: 0;

    .draft-actions-item {
      display: inline-flex;
      align-items: center;
      padding: 0.8em 0.3em;
      margin: 0 1em;
      cursor: pointer;

      i {
        font-size: 1.4em;
        margin: 0 0.8rem 0 0;
      }

      p {
        font-size: 1.4em;
        font-weight: 900;
        text-rendering: geometricPrecision;
        margin: 0;
      }
    }
  }

  ssi-tooltip-window {
    border-radius: 15px;
    z-index: 2;
    background-color: transparent;
  }

  .draft-actions-right {
    margin-bottom: 0;
    i {
      font-size: 14px;
      color: #e34916;
      display: flex;
      align-items: center;
      background-color: #fff1ed;
      border-radius: 50px;
      padding: 6px;
    }
  }

  .delete-draft-tooltip {
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;
    max-width: 310px;
    height: 100%;
    box-shadow: 0 1.8em 3.5em rgb(131 142 171 / 35%);

    .buttons-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
      button {
        text-transform: uppercase;
        padding: 0 5px;
        background-color: transparent;
        span {
          font-weight: 900;
          letter-spacing: 1px;
        }
      }
    }
    h5 {
      color: $lmode-helper-text;
      white-space: normal;
      text-transform: none;
      letter-spacing: 0;
      line-height: 20px;
      font-weight: bold;
    }
  }
}
