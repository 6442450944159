ssi-post-scheduler {
  font-size: 1rem;
  display: block;
  width: 100%;
}
ssi-post-scheduler .post-sheduler-container {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 0.5em 1em 0 rgba(131, 142, 171, 0.35);
  width: 34em;
  height: 46em;
  border-radius: 1.5em;
}
ssi-post-scheduler .post-scheduler-header {
  padding: 4em 4em 3em;
}
ssi-post-scheduler .post-scheduler-header h3 {
  margin: 0;
  font-size: 1em;
  line-height: 1;
  color: #43537f;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-rendering: geometricPrecision;
}
ssi-post-scheduler .post-scheduler-body {
  flex: 1 0 auto;
}
ssi-post-scheduler .publisher-schedule-date-picker-outer {
  padding: 0 34px;
  margin: 0 auto;
}
ssi-post-scheduler .publisher-schedule-date-picker-inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-height: 300px;
}
ssi-post-scheduler .publisher-schedule-date-picker-title-wrap {
  max-height: 28px;
  margin: 0 6px 8px;
}
ssi-post-scheduler .publisher-schedule-date-picker-title-nav button {
  border: 0;
  background-color: transparent;
}
ssi-post-scheduler .publisher-schedule-date-picker-title-nav button .point-right {
  transform: rotate(180deg);
}
ssi-post-scheduler .publisher-schedule-date-picker-title-nav i {
  font-size: 12px;
  color: #14bae3;
  line-height: 28px;
}
ssi-post-scheduler .publisher-schedule-date-picker-title {
  text-align: center;
}
ssi-post-scheduler .publisher-schedule-date-picker-title p {
  font-size: 14px;
  font-weight: 900;
  color: #43537f;
  margin: 0;
  line-height: 28px;
  text-rendering: geometricPrecision;
}
ssi-post-scheduler .cal-month-view {
  font-size: 12px;
  color: #43537f;
  display: block;
  margin-bottom: 27px;
  font-weight: 700;
  text-rendering: geometricPrecision;
}
ssi-post-scheduler .cal-month-view .cal-header {
  color: #43537f;
  margin-bottom: 7px;
  font-weight: inherit;
}
ssi-post-scheduler .cal-month-view .cal-header:hover, ssi-post-scheduler .cal-month-view .cal-header:focus {
  background-color: transparent;
}
ssi-post-scheduler .cal-month-view .cal-header .cal-cell:hover, ssi-post-scheduler .cal-month-view .cal-header .cal-cell:focus {
  background-color: transparent;
}
ssi-post-scheduler .cal-month-view .cal-days .cal-day-cell,
ssi-post-scheduler .cal-month-view .cal-days .cal-cell-top {
  background-color: transparent;
  border: 0;
  min-height: initial;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  ssi-post-scheduler .cal-month-view .cal-days .cal-day-cell,
ssi-post-scheduler .cal-month-view .cal-days .cal-cell-top {
    min-height: 35px;
  }
}
ssi-post-scheduler .cal-month-view .cal-days .cal-day-cell .cal-day-number,
ssi-post-scheduler .cal-month-view .cal-days .cal-day-number {
  display: block;
  float: none;
  text-align: center;
  margin: 5px auto;
  padding: 3px 0px;
  max-width: 26px;
  font-size: 12px;
  line-height: 18px;
  color: inherit;
  opacity: 1;
  font-weight: inherit;
  border-radius: 5px;
  border: 1px solid transparent;
  cursor: pointer;
  text-rendering: geometricPrecision;
}
ssi-post-scheduler .cal-month-view .cal-days .cal-cell {
  font-weight: 700;
  margin: 1px;
}
ssi-post-scheduler .cal-month-view .cal-days .cal-cell:first-child {
  margin-left: 0;
}
ssi-post-scheduler .cal-month-view .cal-days .cal-cell:last-child {
  margin-right: 0;
}
ssi-post-scheduler .cal-month-view .cal-days .cal-cell.cal-future:hover .cal-day-number, ssi-post-scheduler .cal-month-view .cal-days .cal-cell.cal-future:focus .cal-day-number {
  background-color: #eceef7;
}
ssi-post-scheduler .cal-month-view .cal-days .cal-cell.cal-today .cal-day-number {
  border: 1px solid #43537f;
  color: #43537f;
}
ssi-post-scheduler .cal-month-view .cal-days .cal-cell.cal-weekend .cal-day-number {
  color: #43537f;
}
ssi-post-scheduler .cal-month-view .cal-days .cal-cell.disabled .cal-day-number,
ssi-post-scheduler .cal-month-view .cal-days .cal-cell.cal-out-month .cal-day-number {
  opacity: 1;
  color: #b4bbce;
}
ssi-post-scheduler .cal-month-view .cal-days .cal-cell.disabled:hover .cal-day-number, ssi-post-scheduler .cal-month-view .cal-days .cal-cell.disabled:focus .cal-day-number,
ssi-post-scheduler .cal-month-view .cal-days .cal-cell.cal-out-month:hover .cal-day-number,
ssi-post-scheduler .cal-month-view .cal-days .cal-cell.cal-out-month:focus .cal-day-number {
  background-color: #f6f7fd;
}
ssi-post-scheduler .cal-month-view .cal-days .cal-cell.scheduled .cal-day-number {
  background-color: #8ed500;
  color: #fff;
}
ssi-post-scheduler .cal-month-view .cal-days .cal-cell.scheduled:hover .cal-day-number, ssi-post-scheduler .cal-month-view .cal-days .cal-cell.scheduled:focus .cal-day-number {
  background-color: #8ed500;
}
ssi-post-scheduler .cal-month-view .cal-days .cal-cell.active .cal-day-number {
  background-color: #14bae3;
  color: #fff;
}
ssi-post-scheduler .cal-month-view .cal-days .cal-cell.active:hover .cal-day-number, ssi-post-scheduler .cal-month-view .cal-days .cal-cell.active:focus .cal-day-number {
  background-color: #14bae3;
}
ssi-post-scheduler .cal-month-view .cal-days,
ssi-post-scheduler .cal-month-view .cal-days .cal-cell-row,
ssi-post-scheduler .cal-month-view .cal-days .cal-cell {
  border: 0;
}
ssi-post-scheduler .cal-month-view .cal-days:hover, ssi-post-scheduler .cal-month-view .cal-days:focus,
ssi-post-scheduler .cal-month-view .cal-days .cal-cell-row:hover,
ssi-post-scheduler .cal-month-view .cal-days .cal-cell-row:focus,
ssi-post-scheduler .cal-month-view .cal-days .cal-cell:hover,
ssi-post-scheduler .cal-month-view .cal-days .cal-cell:focus {
  background-color: transparent;
}
ssi-post-scheduler .select-schedule-time-btn {
  position: relative;
  margin-top: -6px;
}
ssi-post-scheduler .select-schedule-time-btn i {
  font-size: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 5px;
  transform: rotate(270deg);
}
ssi-post-scheduler .post-scheduler-footer {
  margin: auto 0 0 0;
  width: 100%;
  border-top: 1px solid #838eab;
  padding: 1.6em 2.6em;
  text-align: right;
}
ssi-post-scheduler .post-scheduler-button {
  margin: 0 0 0 3px;
}
ssi-post-scheduler .post-scheduler-button button {
  height: 46px;
  border-radius: 10px;
}
ssi-post-scheduler .post-scheduler-button button:disabled {
  color: #838eab !important;
  background-color: #f4f4fa !important;
}
ssi-post-scheduler .post-scheduler-button-secondary button {
  border: 1px solid #838eab;
}

.scheduled-posts-overlay {
  font-size: 1rem;
  display: block;
  width: 100%;
}
.scheduled-posts-overlay .scheduled-posts-overlay-container {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 0.5em 1em 0 rgba(131, 142, 171, 0.35);
  width: 36em;
  border-radius: 1em;
}
.scheduled-posts-overlay .scheduled-posts-overlay-header {
  display: flex;
  align-items: center;
  padding: 0 2em;
  min-height: 5.4em;
  border-bottom: 1px solid #c3c9d8;
  color: #43537f;
}
.scheduled-posts-overlay .scheduled-posts-overlay-header i {
  color: inherit;
  font-size: 1.4em;
}
.scheduled-posts-overlay .scheduled-posts-overlay-header p {
  color: inherit;
  font-size: 14px;
  font-weight: 900;
  text-rendering: geometricPrecision;
  margin: 0 0 0 0.6rem;
}
.scheduled-posts-overlay .scheduled-posts-overlay-body {
  padding: 2em 2em 0;
  color: #43537f;
}
.scheduled-posts-overlay .scheduled-posts-overlay-body .meta {
  display: flex;
  align-items: center;
  color: inherit;
  margin: 0 0 1em;
}
.scheduled-posts-overlay .scheduled-posts-overlay-body .meta h5 {
  font-size: 14px;
  color: inherit;
  font-weight: 900;
  margin: 0 4px 0 6px;
  padding: 0 5px 0 0;
  line-height: 1;
  border-right: 1px solid #43537f;
  text-rendering: geometricPrecision;
}
.scheduled-posts-overlay .scheduled-posts-overlay-body .meta span {
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  text-rendering: geometricPrecision;
}
.scheduled-posts-overlay .scheduled-posts-overlay-body p {
  font-size: 1.4em;
  height: 40px;
  margin: 0;
  font-weight: 700;
  color: inherit;
  line-height: 2rem;
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-rendering: geometricPrecision;
}
.scheduled-posts-overlay .scheduled-posts-overlay-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2em;
}
.scheduled-posts-overlay .scheduled-posts-overlay-footer-action {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.scheduled-posts-overlay .scheduled-posts-overlay-footer-action p {
  color: #43537f;
  font-size: 1.4em;
  font-weight: 900;
  margin: 0 0.9rem 0 0;
  text-rendering: geometricPrecision;
}
.scheduled-posts-overlay .rotate-90 {
  transform: rotate(270deg);
}
.scheduled-posts-overlay .fake-link {
  color: #14bae3;
  margin: 0;
  font-size: 1em;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-decoration: underline;
  text-underline-offset: 3px;
  font-weight: 900;
  text-rendering: geometricPrecision;
  cursor: pointer;
}

.tooltip-schedule {
  padding: 30px;
}
.tooltip-schedule .tooltip-schedule-title {
  display: block;
  color: #fff;
  font-size: 16px;
  margin: 0 0 4px;
  font-weight: 900;
}
.tooltip-schedule .tooltip-schedule-body {
  color: #b4bbce;
  font-weight: 700;
  margin: 0;
}