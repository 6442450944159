@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../../angularjs/common/sass/variables';
@import '../../../../insights/common/styles/variables.scss';
@import '~/styles/colors';

ssi-dashboard-navbar {
  font-size: 1rem;

  + .insights-page {
    min-height: calc(
      100vh - (#{$navbar-height} + #{$create-report-navbar-height})
    );
  }

  .insights-navbar {
    display: flex;
    height: $create-report-navbar-height;
    width: 100%;
    padding: 0 1.6em;
    background-color: #fff;
    border-bottom: 1px solid $lmode-helper-text;
  }

  .insights-navbar-column {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    // overflow-x: auto;

    &-right {
      justify-content: flex-end;
      overflow: visible;
    }
  }

  .insights-navbar-item {
    padding: 0 0.3em;

    .insights-navbar-button,
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $lgrey-background;
      color: $lmode-body-text;
      border-width: 0;
      border-radius: 1em;
      height: 3.6em;
      line-height: 3.6em;
      min-width: 3.6em;
      white-space: nowrap;

      &:hover,
      &:focus {
        background-color: $ldgrey-background;
      }

      &.delete-button {
        background-color: $primary-red;
        color: $secondary-red;
      }

      .ssi {
        font-size: 1.4em;
      }

      ssi-icon path {
        fill: $lmode-body-text;
      }
    }

    .insights-navbar-button-long {
      padding: 0 1.4em;

      & > *:first-child {
        display: inline-block;
        margin: 0 0.6rem 0 0;
      }

      span {
        color: $lmode-body-text;
        font-size: 1.4rem;
        font-weight: 900;
        text-rendering: geometricPrecision;
      }

      .ssi {
        font-size: 1rem;
      }
    }

    .border {
      border: 1px solid $lmode-helper-text;
      background-color: transparent;
      color: $primary-active;
    }
    ssi-dropdown-select-2 {
      z-index: 999;
      .dd-container {
        .dd-head {
          background-color: transparent;
          color: $lmode-title-text;
          font-size: 16px;

          .button-icon {
            color: $lmode-title-text;
          }
        }
      }
    }
  }

  .insights-navbar-name {
    padding: 0 1.9em 0 1.4em;

    &.insights-navbar-button {
      .ssi {
        font-size: 1.2rem;
        margin: 0 1rem 0 0;
      }
    }

    input {
      color: $lmode-title-text;
      font-weight: 900;
      text-rendering: geometricPrecision;
      font-size: 1.4em;
      margin: 0 0 0 0.3em;
      line-height: 1rem;
      border: none;
      background: none;
      appearance: none;
      outline: none;
      &::placeholder {
        color: $lmode-helper-text;
      }
    }
  }

  .insights-navbar-name {
    display: flex;
    align-items: center;
  }

  .insights-navbar-dropdown {
    .insights-navbar-button {
      &:focus,
      &:hover {
        background-color: $lgrey-background;
      }
    }

    .dropdown-menu {
      min-width: 24rem;
      padding: 2.4rem 1.9rem 2.3rem;
      margin: 0.7rem 0 0;
      right: 0;
      left: auto;
      border-radius: 1.2rem;
      border-width: 0;
      box-shadow: 0 1.8rem 4rem rgba(131, 142, 171, 0.35);
      overflow: hidden;

      li {
        position: relative;
        display: flex;
        align-items: center;
        height: 3.6rem;
        margin: 0.3rem 0 0.4rem;
        padding: 0.3rem 1rem 0.3rem 1.7rem;
        cursor: pointer;
        border-radius: 1rem;

        &.insights-navbar-item-break {
          &:hover,
          &:focus {
            cursor: auto;
            background-color: transparent;
          }
        }

        hr {
          position: absolute;
          left: -2.5rem;
          right: -2.5rem;
          border-top-width: 0;
          border-bottom: 0.1rem solid #c3c9d8;
        }

        .ssi {
          color: $lmode-helper-text;
          display: inline-block;
          margin: 0 1.4rem 0 0;

          &.insights-navbar-item-icon-red {
            color: $secondary-red;
          }
        }

        p {
          margin: 0;
          color: $lmode-body-text;
          font-weight: 900;
          text-rendering: geometricPrecision;
        }

        &:hover,
        &:focus {
          background-color: $grey-background;
        }
      }
    }
  }
}
