ssi-account-selector .ac-select-trigger {
  position: relative;
  width: 100%;
  padding-right: 2.5em;
  text-align: left;
}
ssi-account-selector .ac-select-trigger .caret {
  position: absolute;
  top: 50%;
  right: 1.2rem;
  margin-top: -0.2rem;
  vertical-align: middle;
}
ssi-account-selector .ac-select-trigger-title {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
ssi-account-selector .ac-dropdown {
  min-width: 100%;
}
ssi-account-selector .ac-filter-input {
  display: block;
  width: calc(100% - 0.8rem * 2);
  border-radius: 4px;
  margin: 0.4rem 0.8rem;
}
ssi-account-selector .ac-account-list {
  display: block;
  margin: 0;
  padding: 0;
  min-width: 100%;
}
ssi-account-selector .ac-item {
  display: block;
}
ssi-account-selector .ac-item-trigger {
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #262626;
  padding: 0.3rem 2rem;
  text-align: left;
  border-width: 0;
  background-color: transparent;
  white-space: nowrap;
  font-weight: 400;
  cursor: pointer;
}
ssi-account-selector .ac-item-trigger:not(ssi-account-selector .ac-item-trigger-selected):hover, ssi-account-selector .ac-item-trigger:not(ssi-account-selector .ac-item-trigger-selected):focus {
  background-color: #f5f5f5;
}
ssi-account-selector .ac-item-trigger-selected {
  background-color: #14bae3;
  color: #fff;
}
ssi-account-selector .ac-item-trigger-selected .ac-item-check-mark {
  display: block;
}
ssi-account-selector .ac-item-check-mark {
  display: none;
  margin: 0 0 0 0.8rem;
}