ssi-manage-webhooks .manage-webhooks {
  background-color: #f0f3f9;
  padding: 2rem 0;
}
ssi-manage-webhooks .container {
  position: relative;
  background-color: #fff;
  border-radius: 25px;
  padding: 1rem 3rem;
}
ssi-manage-webhooks .actions-menu {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 0.5rem 1rem 2rem 0;
}
ssi-manage-webhooks .actions-menu span {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 1.1rem;
}
ssi-manage-webhooks .destination {
  max-width: 300px;
  word-break: break-word;
}
ssi-manage-webhooks .orlo-table {
  width: 100%;
}
ssi-manage-webhooks .orlo-table tbody td:first-child {
  white-space: pre-wrap;
}
ssi-manage-webhooks .orlo-table tbody td.email {
  word-break: break-word;
}
ssi-manage-webhooks .orlo-table tr:first-child {
  border-top: 0;
}
ssi-manage-webhooks .orlo-table tr > td {
  padding: 1.5rem 1rem !important;
}
ssi-manage-webhooks .orlo-table tr > td > a {
  color: #838eab;
}
ssi-manage-webhooks .action-button {
  display: flex;
  align-items: center;
  padding: 1.3rem 2rem;
  font-weight: bold;
  color: #00b9e5;
  border-radius: 50px;
  border: none;
}
ssi-manage-webhooks .action-button.enabled {
  background-color: rgba(143, 202, 199, 0.25);
  color: #8fcac7;
}
ssi-manage-webhooks .action-button:disabled, ssi-manage-webhooks .action-button.disabled {
  background-color: #faeeeb;
  color: #e39e8a;
}
ssi-manage-webhooks .action-button.edit, ssi-manage-webhooks .action-button.delete {
  padding: 1.2rem;
  font-size: 2rem;
}
ssi-manage-webhooks .action-button.edit {
  background-color: #f0f3f9;
}
ssi-manage-webhooks .action-button span {
  margin-left: 0.7rem;
}
ssi-manage-webhooks .action-button.delete {
  background-color: #faeeeb;
}
ssi-manage-webhooks .action-button.delete i {
  color: #e29e8c;
}
ssi-manage-webhooks orlo-button button {
  display: flex;
  align-items: center;
  padding: 1rem 2rem !important;
}
ssi-manage-webhooks orlo-button span {
  font-weight: bold;
}
ssi-manage-webhooks orlo-button i {
  font-size: 2.5rem !important;
}