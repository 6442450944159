@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../angularjs/common/sass/variables';
@import '~/styles/colors';

$ssi-publisher-responsive-width: 885px;

ssi-dropdown-select,
ssi-dropdown-select-2 {
  position: relative;
  display: block;

  .btn:focus {
    outline: 0;
  }

  .btn {
    border-radius: 10px !important;
  }

  .dropdown-select-menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 375px; // todo - set this from the publisher as its hard coded to be the publisher text height
    padding-top: 10px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    &.width-extended {
      @media (max-width: $ssi-publisher-responsive-width) {
        width: 95vw;
      }
      @media (min-width: $ssi-publisher-responsive-width) {
        width: 50vw;
      }
    }
  }

  .dropdown-select-menu-items {
    flex: 1 1 auto;
    padding: 16px 36px;
  }

  .dropdown-select-menu-item {
    cursor: pointer;
    display: block;
    width: 100%;
    border-radius: 20px;
    padding: 8px 5px 8px 20px;
    margin: 3px 0;
    font-weight: normal;

    &:focus {
      outline: revert;
    }

    i {
      width: 20px;
      font-size: 14px;
    }
  }

  .dropdown-search-container {
    padding: 10px 60px 5px 60px;
    position: relative;

    .form-control {
      background-color: transparent;
      padding-right: 30px;
      box-shadow: none;
      height: 40px;
      font-weight: bold;
      font-size: 14px;
      border: 0;
      border-radius: 0;
      border-bottom: 2px solid #fff;

      &:focus {
        outline: none;
      }
    }

    .form-control::placeholder {
      opacity: 0.7;
      font-weight: 400;
    }

    i {
      position: absolute;
      top: 20px;
      right: 70px;
      font-size: 18px;
    }
  }

  .dropdown-toggle-all {
    text-align: right;
    padding: 0 60px;
    font-weight: 600;

    a {
      margin-left: 10px;

      &:focus {
        outline: revert;
      }
    }
  }

  @mixin dropdown-select-theme($bg-color, $text-color, $bg-color-active) {
    .dropdown-select-menu {
      background-color: $bg-color;
      color: $text-color;
    }

    .dropdown-select-menu-item.active,
    .dropdown-select-menu-item:hover {
      background-color: $bg-color-active;
    }

    .dropdown-search-container {
      .form-control,
      .form-control:focus {
        color: $text-color;
      }

      .form-control::placeholder {
        color: $text-color;
      }
    }

    .dropdown-toggle-all {
      a {
        color: $text-color;
      }
    }
  }

  .dropdown-select-container.primary {
    @include dropdown-select-theme($primary-active, #fff, #11acd3);
  }

  .dropdown-select-container.inverse {
    @include dropdown-select-theme($brand-inverse, #fff, #56606b);
  }
}
