ssi-publisher-youtube-post-preview {
  display: block;
  max-width: 500px;
  font-family: Roboto, Arial, sans-serif;
  font-weight: normal;
  background-color: #ffffff;
  padding: 30px;
}
ssi-publisher-youtube-post-preview video {
  width: 100%;
}
ssi-publisher-youtube-post-preview .youtube-post-header {
  padding: 20px 0 8px 0;
  border-bottom: solid 1px #eee;
}
ssi-publisher-youtube-post-preview .youtube-post-title {
  max-height: 4.8rem;
  font-weight: 400;
  color: rgb(17, 17, 17);
  font-size: 18px;
  margin: 0;
}
ssi-publisher-youtube-post-preview .youtube-post-views {
  color: rgba(17, 17, 17, 0.6);
  font-size: 1.6rem;
}
ssi-publisher-youtube-post-preview .youtube-post-body {
  padding-top: 16px;
}
ssi-publisher-youtube-post-preview .youtube-post-author-avatar img {
  width: 48px;
  height: 48px;
  margin-right: 16px;
}
ssi-publisher-youtube-post-preview .youtube-post-author-name a {
  color: #000;
  font-size: 1.4rem;
  font-weight: 500;
}
ssi-publisher-youtube-post-preview .youtube-post-author-name a:hover {
  text-decoration: none;
}
ssi-publisher-youtube-post-preview .youtube-post-time {
  font-size: 1.3rem;
  color: rgba(17, 17, 17, 0.6);
}
ssi-publisher-youtube-post-preview .youtube-post-description {
  margin-top: 12px;
  color: rgb(17, 17, 17);
  font-size: 14px;
}
ssi-publisher-youtube-post-preview .text-read-more {
  font-weight: 700;
  cursor: pointer;
}