@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-topic-report {
  form {
    background-color: #fff;
    padding: 20px;
    .form-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 20px;

      .form-field {
        display: flex;
        flex-direction: column;
        flex: 1 1 25%;
        white-space: nowrap;
        margin-right: 10px;

        > label {
          color: $lmode-body-text;
          font-size: 10px;
          font-weight: 900;
          letter-spacing: 1px;
          text-transform: uppercase;
          margin-left: 20px;
          margin-bottom: 15px;
        }

        &:last-child {
          margin-right: 0;
        }

        &.field-group {
          display: flex;
          flex-direction: row;
          margin-right: 10px;
        }

        &.wider {
          flex-basis: 40%;
        }
      }

      .input-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        > input {
          width: 100%;
          height: 46px;
          border: none;
          outline: none;
          border-radius: 100px;
          display: flex;
          align-items: center;
          color: $lmode-title-text;
          font-size: 14px;
          font-weight: 900;
          background-color: $grey-background;
          padding: 10px 30px 10px 20px;
        }

        ssi-dropdown-select-2 {
          width: 100%;
        }
      }
    }
  }

  .number-input {
    width: 100%;
    height: 43px;
    border: none;
    outline: none;
    border-radius: 100px;
    color: $lmode-title-text;
    font-size: 14px;
    font-weight: 900;
    background-color: $grey-background;
    padding: 10px 30px 10px 20px;
  }

  .search-field {
    input {
      margin-top: 25px;
      height: 43px;
      border: none;
      outline: none;
      border-radius: 100px;
      color: $lmode-title-text;
      font-size: 14px;
      font-weight: 900;
      background-color: $grey-background;
      padding: 10px 30px 10px 20px;
      &::placeholder {
        color: $lmode-helper-text;
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
      }
    }
  }

  .input-range {
    display: flex;
    input:not(:last-child) {
      margin-right: 10px;
    }
  }

  ssi-dropdown-select-2 {
    button {
      width: 100%;
    }
  }

  .toggle-switch-wrapper {
    .toggle-switch-2-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $lmgrey-background;
      padding: 10px 10px 10px 20px;
      border-radius: 50px;
    }
  }

  // table
  .table-container {
    padding: 20px 20px 50px;
    background-color: #fff;
    border-radius: 10px;

    .row-even {
      background-color: $grey-background;
    }
  }

  .chatbot-name {
    display: flex;
    align-items: center;
    color: $lmode-body-text;
    font-size: 12px;
    font-weight: 900;
    min-width: 0;
    margin-right: 10px;
    padding: 0 5px 0 10px;

    span {
      display: block;
      max-width: 100%;
      padding: 10px 0;
      word-wrap: break-word;
    }
  }

  .table-cell {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 0 5px 0 10px;
    border-bottom: 2px solid #e0e7f4;
  }

  .name-count-dropdown {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .paginator-container {
    background-color: #fff;
    border-radius: 0 0 15px 15px;
    height: 80px;
    padding: 40px;
  }
}
