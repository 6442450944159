ssi-publisher-schedule .publisher-secondary-view-right-body {
  height: 100%;
}
@media (max-width: 885px) {
  ssi-publisher-schedule .publisher-secondary-view-right-body {
    margin-bottom: 100px;
  }
}
ssi-publisher-schedule .publisher-secondary-view-right-body ssi-publisher-secondary-view-nav {
  margin: 35px 0 0;
}
@media (max-width: 885px) {
  ssi-publisher-schedule .publisher-secondary-view-right-body ssi-publisher-secondary-view-nav {
    margin: 0px;
  }
}
ssi-publisher-schedule .publisher-secondary-view-right-body-copy {
  margin: 0;
}
ssi-publisher-schedule .publisher-schedule-title {
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 900;
  color: #43537f;
}
ssi-publisher-schedule .publisher-schedule-body {
  color: #43537f;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin: 25px 45px;
}
@media (max-width: 885px) {
  ssi-publisher-schedule .publisher-schedule-body {
    margin: 10px;
  }
}
ssi-publisher-schedule .publisher-schedule-body b {
  font-weight: 900;
}
ssi-publisher-schedule .publisher-secondary-view-schedule-left {
  display: flex;
  flex: 1 1 auto;
  padding-top: 0;
  box-shadow: none;
  overflow: auto;
  height: 100%;
  flex-direction: column;
}
@media (max-width: 885px) {
  ssi-publisher-schedule .publisher-secondary-view-schedule-left {
    flex: 0 0 auto;
    height: auto;
  }
}
ssi-publisher-schedule .publisher-secondary-view-left-body {
  position: relative;
  align-items: flex-start;
  margin: 0 8px 8px 8px;
  padding: 50px 50px 0 30px !important;
  display: flex;
  background-color: #fff;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
@media (max-width: 885px) {
  ssi-publisher-schedule .publisher-secondary-view-left-body {
    flex-wrap: wrap;
    padding: 20px !important;
    overflow-y: scroll;
    justify-content: center;
  }
}
ssi-publisher-schedule .publisher-secondary-view-left-body .back-btn {
  position: absolute;
  top: 15px;
  left: 30px;
}
ssi-publisher-schedule .publisher-secondary-view-right {
  flex: 0 1 390px !important;
  width: 390px;
  padding: 0 10px;
}
@media (max-width: 885px) {
  ssi-publisher-schedule .publisher-secondary-view-right {
    width: auto;
  }
}
ssi-publisher-schedule .label-publisher-scheduled {
  background-color: #acb638;
}
ssi-publisher-schedule .label-publisher-disabled {
  background-color: #dadde3;
  color: #596065;
}
ssi-publisher-schedule .label {
  border-radius: 20px;
}
ssi-publisher-schedule .publisher-schedule-tip {
  margin-top: 25px;
  color: #14bae3;
}
ssi-publisher-schedule .publisher-schedule-tip span {
  font-size: 10px;
  line-height: 16px;
  font-weight: 900;
  letter-spacing: 1px;
  cursor: pointer;
}
ssi-publisher-schedule .publisher-schedule-tip i {
  font-size: 16px;
  vertical-align: middle;
}
ssi-publisher-schedule .publisher-schedule-list {
  position: relative;
  overflow-y: auto;
  padding: 0 10px;
  height: 220px;
}
@media (max-width: 885px) {
  ssi-publisher-schedule .publisher-schedule-list {
    height: 120px;
  }
}
ssi-publisher-schedule .publisher-schedule-list-item {
  position: relative;
  background-color: #fff;
  color: #43537f;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  padding: 12px 40px 12px 18px;
  margin-bottom: 8px;
  border-radius: 10px;
}
ssi-publisher-schedule .publisher-schedule-list-item i {
  font-size: 14px;
}
ssi-publisher-schedule .publisher-schedule-list-item:before {
  content: "";
  display: inline-block;
  background-color: #b2c614;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin: 0 5px -1px 0;
}
ssi-publisher-schedule .publisher-schedule-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 50%;
  color: #14bae3;
  font-weight: 700;
  font-size: 12px;
  margin: 0 0 20px;
  line-height: 30px;
  vertical-align: middle;
}
ssi-publisher-schedule .publisher-schedule-list-meta p {
  color: #838eab;
  line-height: 20px;
  font-size: 12px;
  font-weight: 700;
  margin: 0;
}
ssi-publisher-schedule .publisher-schedule-list-item-remove {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 6px;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  text-align: center;
  width: 30px;
  color: #f88c68;
  border-radius: 50%;
}
ssi-publisher-schedule .publisher-schedule-list-item-remove:hover, ssi-publisher-schedule .publisher-schedule-list-item-remove:focus {
  background-color: #fff1ed;
}
ssi-publisher-schedule .publisher-schedule-list-remaining {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
}
ssi-publisher-schedule .schedule-btn {
  flex: 0 0 auto;
  width: auto;
  padding: 16px 30px;
  font-size: 14px;
  font-weight: 900;
  line-height: 14px;
  border-radius: 40px;
  border-width: 0;
  text-rendering: geometricPrecision;
}
ssi-publisher-schedule .schedule-btn:focus {
  outline: revert;
}
ssi-publisher-schedule .schedule-btn i:first-child {
  margin-left: -15px;
  padding-right: 15px;
  font-size: 12px;
}
ssi-publisher-schedule .schedule-btn.schedule-btn-light,
ssi-publisher-schedule .schedule-btn .schedule-btn-light {
  color: #14bae3;
  background-color: #f8f9fd;
}
ssi-publisher-schedule .schedule-btn.schedule-btn-dark,
ssi-publisher-schedule .schedule-btn .schedule-btn-dark {
  background-color: #f88c68;
  color: #fff;
  font-weight: 900;
}
ssi-publisher-schedule .schedule-btn.schedule-btn-dark:focus, ssi-publisher-schedule .schedule-btn.schedule-btn-dark:hover,
ssi-publisher-schedule .schedule-btn .schedule-btn-dark:focus,
ssi-publisher-schedule .schedule-btn .schedule-btn-dark:hover {
  background-color: #f67246;
}
ssi-publisher-schedule .schedule-btn.schedule-btn-green,
ssi-publisher-schedule .schedule-btn .schedule-btn-green {
  background-color: #b2c614;
}
ssi-publisher-schedule .schedule-btn.schedule-btn-green:focus, ssi-publisher-schedule .schedule-btn.schedule-btn-green:hover,
ssi-publisher-schedule .schedule-btn .schedule-btn-green:focus,
ssi-publisher-schedule .schedule-btn .schedule-btn-green:hover {
  background-color: #98b700;
}