@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../angularjs/common/sass/mixins';
@import '~/styles/colors';

ssi-engagement-report {
  $headerHeight: 9.4em;
  $sidebarWidth: 22.8em;

  font-size: 1rem;

  ssi-report-header,
  ssi-report-sidebar {
    font-size: 1.4em;
  }

  .loading-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: $headerHeight 0 12em $sidebarWidth;
  }

  .selected-date-ranges {
    border-radius: 10px;
    background-color: #ebebf4;
    padding: 10px;

    span {
      margin-right: 7px;
    }

    .current {
      color: $lmode-title-text;
      font-size: 14px;
      font-weight: 900;
    }
    .previous {
      color: $lmode-title-text;
      font-size: 12px;
      font-style: italic;
      font-weight: 700;
    }
  }

  .report-body {
    position: relative;
    width: 100%;
    padding-top: $headerHeight + 4em;
    padding-left: $sidebarWidth + 4em;
    height: 100%;
    padding-right: 4em;
    min-height: calc(100vh - 6.8em);
    background-color: $lgrey-background;
  }

  .report-container {
    max-width: 192em;
    margin: auto;
  }

  .section-delimiter {
    width: 100%;
    border-bottom: 0.2em solid #fff;
    margin: 3em 0;
  }

  .section-title {
    color: $lmode-title-text;
    font-weight: 900;
    text-rendering: geometricPrecision;
    font-size: 2em;
    margin: 0 0 4rem;
  }

  .section-subtitle {
    color: $lmode-title-text;
    font-weight: 700;
    text-rendering: geometricPrecision;
    font-size: 1.6em;
    margin: 1rem 0 3rem;
  }

  .section-card {
    background-color: #fff;
    border-radius: 1.2em;
    padding: 2em;
    box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
    height: calc(100% - 2em);
    margin: 0 0 2em;
  }

  .section-grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1em 2.4em;

    &-item {
      flex: 1 0 0%;
      width: 100%;
      margin: 0 1em;
    }
  }

  @supports (display: grid) {
    .section-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(26.8em, 1fr));
      grid-gap: 0 2em;
      margin: 0;

      &-thirds {
        grid-template-columns: repeat(auto-fit, minmax(36.4em, 1fr));
      }

      &-halves {
        grid-template-columns: repeat(auto-fit, minmax(55.6em, 1fr));
      }

      .section-grid-item {
        margin: 0;
      }
    }
  }

  @include ie-only {
    .section-grid {
      display: flex;
      margin: 0 -1em 2.4em;

      .section-grid-item {
        margin: 0 1em;
      }
    }
  }

  .account-list {
    margin: -0.5em -0.5em 0;
    padding: 0;

    li {
      display: inline-block;
      margin: 0.5em;
    }
  }

  .time-range-list {
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    border-radius: 1.2em;
    box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
    padding: 1.8em 1.6em;

    @media print {
      display: block;
    }

    li {
      display: inline-flex;
      flex: 1 1 calc(50% - 2em);
      width: calc(50% - 2em);
      min-width: 30em;
      margin: 0.4em 1em;

      @media print {
        display: block;
        width: 100%;
      }

      &:nth-child(5n - 3) .time-range-list-progress span {
        background-color: $secondary-blue;
      }

      &:nth-child(5n - 2) .time-range-list-progress span {
        background-color: #f0b427;
      }

      &:nth-child(5n - 1) .time-range-list-progress span {
        background-color: $secondary-red;
      }

      &:nth-child(5n) .time-range-list-progress span {
        background-color: #ff3b0e;
      }
    }
  }

  .time-range-list-item {
    display: flex;
    align-items: center;
    padding: 0.5em;
    width: 100%;
    border: 0.1em solid $primary-active;
    border-radius: 0.5em;
    box-shadow: 0 0.5em 1em rgba(131, 142, 171, 0.25);
    page-break-inside: avoid;

    @media print {
      display: block;
      border: 1px solid black;
    }
  }

  .time-range-list-range {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 10.5em;
    height: 4.6em;
    background-color: $lgrey-background;
    border-radius: 0.5em;
    color: $lmode-body-text;
    font-weight: 900;
    text-rendering: geometricPrecision;

    span {
      font-size: 1.4em;
    }
  }

  .time-range-list-range-hyphen {
    display: inline-block;
    margin: 0 0.4em;
  }

  .time-range-list-progress {
    flex: 1 1 auto;
    background-color: $lgrey-background !important;
    height: 0.4em;
    border-radius: 10em;
    margin: 0 3.2em 0 1.5em;
    overflow: hidden;
    min-width: 2em;
    -webkit-print-color-adjust: exact;

    span {
      display: block;
      height: 100%;
      background-color: $primary-active;
      border-radius: 10em;
      -webkit-print-color-adjust: exact;

      @media print {
        background-color: black !important;
      }
    }
  }

  .time-range-list-amount {
    flex: 0 0 auto;
    color: $lmode-title-text;
    padding: 0 1.5em 0 0;

    p {
      font-size: 1.6em;
      margin: 0;
      font-weight: 900;
      text-rendering: geometricPrecision;

      &.time-range-list-amount-format-wrap {
        .time-range-list-amount-raw {
          display: none;
        }

        &:hover {
          .time-range-list-amount-formatted {
            display: none;
          }

          .time-range-list-amount-raw {
            display: block;
          }
        }
      }
    }
  }
}
