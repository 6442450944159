@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';
@import '../../../../../../../../angularjs/common/sass/include-media';

ssi-widget-live-x-stream {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .dashboard-widget {
    position: relative;
    height: 100%;
    width: 100%;

    @include media('>=600px') {
      ssi-menu {
        display: none;
      }
      .post-details {
        &.time-visibility {
          display: flex;
          &.placed-below {
            display: none;
            flex-direction: row-reverse;
            justify-content: flex-end;
            padding: 10px 0 0 10px;
          }
        }
      }
    }

    // only special-handling when responsiveWidth is >1200
    // since the grid component only assigns widget sizes when its that wide
    @include media('>1200px') {
      &.view-1x2 {
        .desktop-menu {
          .menu-list-item {
            &:last-child,
            &:nth-child(3) {
              display: none;
            }
          }
        }

        ssi-menu {
          display: block;
        }

        .post-details {
          &.time-visibility {
            display: none;
            &.placed-below {
              display: flex;
              flex-direction: row-reverse;
              justify-content: flex-end;
              padding: 10px 0 0 10px;
            }
          }
        }
      }

      &.view-2x2 {
        ssi-menu {
          display: none;
        }
      }
    }
  }

  .scroll-container {
    width: 100%;
    height: 100%;
  }

  ssi-stream-post {
    display: block;
    padding: 10px;

    @include media('<600px') {
      .desktop-menu {
        .menu-list-item {
          &:last-child {
            display: none;
          }
        }
      }
      .post-details {
        &.time-visibility {
          display: none;
          &.placed-below {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
            padding: 10px 0 0 10px;
          }
        }
      }
    }

    @include media('<450px') {
      .desktop-menu {
        .menu-list-item {
          &:last-child,
          &:nth-child(3) {
            display: none;
          }
        }
      }
    }
  }
}
