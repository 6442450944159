ssi-inbox-sidebar .wide-version,
ssi-inbox-sidebar .slim-version {
  height: 100%;
}
ssi-inbox-sidebar .wide-version {
  display: flex;
}
ssi-inbox-sidebar .slim-version {
  display: none;
}
ssi-inbox-sidebar .wide-version {
  width: 160px;
  min-width: 160px;
  flex: 1;
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
  display: flex;
  flex-direction: column;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-results-count {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0px;
  text-transform: uppercase;
  color: #596065;
  background-color: #f8f8f8;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-inner {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #3f3f49;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-inner a {
  font-size: 1em;
  font-weight: 600;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-inner a span {
  font-size: 1em;
  font-weight: 600;
}
ssi-inbox-sidebar .wide-version a {
  color: white;
}
ssi-inbox-sidebar .wide-version a:hover,
ssi-inbox-sidebar .wide-version a:focus {
  text-decoration: none;
  color: white;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item.border-top {
  border-top: solid 1px rgba(255, 255, 255, 0.24);
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item {
  padding: 14px 28px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item i {
  font-size: 12px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages {
  padding: 20px 10px;
  position: relative;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages.margin-bottom {
  margin-bottom: 10px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages-assigned {
  justify-content: flex-end;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages-filtered {
  border-right: solid 2px #545767;
  justify-content: flex-end;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages-total {
  border-bottom: solid 2px #545767;
  border-right: solid 2px #545767;
  justify-content: flex-start;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages-unread {
  border-bottom: solid 2px #545767;
  justify-content: flex-start;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages ul {
  display: flex;
  flex-flow: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages ul li {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 55px;
  padding: 0;
  width: 50%;
  color: #838eab;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages ul li.active .count {
  color: #14bae3;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages ul li.active .label {
  color: #fff;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages ul li .count {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 2px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages ul li .label {
  color: inherit;
  font-size: 1em;
  font-weight: 600;
  padding: 0;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages button {
  background: #545767;
  border: solid 6px #3f4142;
  border-radius: 50%;
  color: #14bae3;
  left: 58px;
  outline: none;
  padding: 6px;
  position: absolute;
  top: 47px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages button .refresh-icon {
  font-size: 2.4em;
}
ssi-inbox-sidebar .wide-version a.inbox-sidebar-item {
  background-color: #545767;
  border-radius: 28px;
  margin-left: 14px;
  margin-right: 14px;
  padding: 7px 14px;
}
ssi-inbox-sidebar .wide-version a.inbox-sidebar-item.inbox-sidebar-item-bg-primary {
  background-color: #14bae3;
}
ssi-inbox-sidebar .wide-version a.inbox-sidebar-item.inbox-sidebar-item-bg-primary:hover {
  background-color: #25c4ec;
}
ssi-inbox-sidebar .wide-version a.inbox-sidebar-item.inbox-sidebar-item-btn-list-filters {
  background-color: #14bae3;
  color: #fff;
}
ssi-inbox-sidebar .wide-version a.inbox-sidebar-item.inbox-sidebar-item-btn-list-filters-clear {
  background-color: #f88c68;
}
ssi-inbox-sidebar .wide-version a.inbox-sidebar-item.inbox-sidebar-item-conversations-button {
  background-color: #545767;
  box-shadow: #303030 0 0 16px 0px;
  color: #838eab;
}
ssi-inbox-sidebar .wide-version a.inbox-sidebar-item.inbox-sidebar-item-conversations-button:hover {
  color: #fff;
}
ssi-inbox-sidebar .wide-version a.inbox-sidebar-item.inbox-sidebar-item-conversations-button.active > .button-icon {
  color: #14bae3;
}
ssi-inbox-sidebar .wide-version a.inbox-sidebar-item.inbox-sidebar-item-conversations-button.active > .button-text {
  color: #fff;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-filters-list,
ssi-inbox-sidebar .wide-version .inbox-sidebar-active-preset {
  margin: 0 10px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-filters-list > ul,
ssi-inbox-sidebar .wide-version .inbox-sidebar-active-preset > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-filters-list > ul > li,
ssi-inbox-sidebar .wide-version .inbox-sidebar-active-preset > ul > li {
  align-items: center;
  background-color: #545767;
  border-radius: 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  list-style: none;
  margin: 10px 0;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-filters-list > ul > li .btn-edit,
ssi-inbox-sidebar .wide-version .inbox-sidebar-active-preset > ul > li .btn-edit {
  background-color: #14bae3;
  border-radius: 20px;
  padding: 5px;
  color: #fff;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-filters-list > ul > li .filter-label,
ssi-inbox-sidebar .wide-version .inbox-sidebar-filters-list > ul > li .preset-label,
ssi-inbox-sidebar .wide-version .inbox-sidebar-active-preset > ul > li .filter-label,
ssi-inbox-sidebar .wide-version .inbox-sidebar-active-preset > ul > li .preset-label {
  color: #fff;
  overflow: hidden;
  padding: 0 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 98px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-filters-list > ul > li .btn-remove,
ssi-inbox-sidebar .wide-version .inbox-sidebar-active-preset > ul > li .btn-remove {
  color: #f0d9d1;
  margin-right: 8px;
  padding: 5px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-filters-list > ul > li .btn-remove .ssi,
ssi-inbox-sidebar .wide-version .inbox-sidebar-filters-list > ul > li .btn-remove .fa,
ssi-inbox-sidebar .wide-version .inbox-sidebar-active-preset > ul > li .btn-remove .ssi,
ssi-inbox-sidebar .wide-version .inbox-sidebar-active-preset > ul > li .btn-remove .fa {
  font-size: 14px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-active-preset > ul > li {
  background-color: #14bae3;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-active-preset > ul > li .btn-remove {
  color: #fff;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-custom-presets-toggle {
  position: relative;
  top: 12px;
  z-index: 1;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list {
  background-color: #35353f;
  padding: 28px 0 20px 0;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list > ul {
  margin: 0 20px;
  padding: 0;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list > ul > li {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  padding: 0;
  font-size: 12px;
  color: #838eab;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list > ul > li.selected .btn-edit,
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list > ul > li.selected span {
  color: #fff;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list > ul > li.selected .preset-label .ssi,
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list > ul > li.selected .preset-label .fa {
  color: #14bae3;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list > ul > li .preset-label {
  align-items: center;
  display: flex;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list > ul > li .preset-label > span {
  overflow: hidden;
  padding-left: 5px;
  padding-right: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 96px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list > ul > li .preset-label .ssi,
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list > ul > li .preset-label .fa {
  margin-right: 2px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list > ul > li a {
  color: #838eab;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list > ul > li a i {
  color: #545767;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-presets-list {
  margin-top: 30px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-presets-list > ul {
  margin: 20px;
  padding: 0;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-presets-list > ul > li {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  padding: 0;
  color: #838eab;
  font-size: 12px;
  font-weight: 600;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-presets-list > ul > li.selected {
  color: #fff;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-presets-list > ul > li.selected .fa {
  color: #14bae3;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-presets-list > ul > li .ssi,
ssi-inbox-sidebar .wide-version .inbox-sidebar-presets-list > ul > li .fa {
  color: #545767;
  margin-right: 2px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-spacing-top {
  margin-top: 3px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-spacing-bottom {
  margin-bottom: 3px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bg-light {
  background-color: #545767;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bg-dark {
  background-color: #363434;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bg-primary {
  background-color: #14bae3;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bg-light-hover:hover {
  background-color: #5f6375;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bg-dark-hover:hover {
  background-color: #292727;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bg-primary-hover:hover {
  background-color: #25c4ec;
}
ssi-inbox-sidebar .wide-version a.inbox-sidebar-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-top {
  flex: 1;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-top .bulk-actions-menu {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  list-style: none;
  margin: 20px 0 0 0;
  padding: 0;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-top .bulk-actions-menu * {
  display: flex;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-top .bulk-actions-menu > li {
  flex-shrink: 0;
  flex-grow: 1;
  margin: 5px 0;
  padding: 0 10px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-top .bulk-actions-menu > li > * {
  flex-grow: 1;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-top .bulk-actions-menu > li > button, ssi-inbox-sidebar .wide-version .inbox-sidebar-top .bulk-actions-menu > li > dl > dt {
  align-items: center;
  border-radius: 20px;
  justify-content: space-between;
  color: #fff;
  font-weight: 600;
  padding: 5px 13px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-top .bulk-actions-menu > li > button {
  border: none;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-top .bulk-actions-menu > li > dl {
  flex-flow: column-reverse nowrap;
  flex-grow: 1;
  margin: 0;
  padding: 0;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-top .bulk-actions-menu > li > dl > dt {
  margin: 0;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-top .bulk-actions-menu > li > dl > dd {
  color: #fff;
  display: block;
  line-height: 1.8em;
  margin: 0;
  padding: 0 10px 12px 10px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-top .bulk-actions-menu > li > dl > dd > * {
  display: inline;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-icon-left {
  padding-left: 5px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-filter-remove {
  position: relative;
  top: -1px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-filters li:not(:last-child) {
  margin-bottom: 10px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-padding-sides-sm {
  padding-left: 10px;
  padding-right: 8px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-text {
  display: inline-block;
  width: calc(100% - 40px);
  word-wrap: break-word;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-active {
  background-color: #14bae3;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-deselect {
  background-color: #f88c68;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-deselect:hover {
  background-color: #f77950;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-deselect span {
  flex-grow: 1;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-assign {
  background-color: #f0b427;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-assign:hover {
  background-color: #edab10;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-sentiment {
  background-color: #d41d68;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-sentiment:hover {
  background-color: #be1a5d;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-tag {
  background-color: #668aea;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-tag:hover {
  background-color: #5079e7;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-spam {
  background-color: #545767;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-spam:hover {
  opacity: 0.8;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-tip dt {
  background-color: #545767;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-tip i {
  color: #14bae3;
}
ssi-inbox-sidebar .wide-version i.ssi-users-plus {
  font-size: 16px;
}
ssi-inbox-sidebar .wide-version i.ssi-sentiment-menu-icon {
  font-size: 13px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-add-filter.active {
  background-color: #14bae3;
  color: #fff;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-btn {
  display: block;
  background-color: #425dec !important;
  box-shadow: #303030 0 0 6px 0px;
}
ssi-inbox-sidebar .wide-version .social-push-btn {
  margin-bottom: 20px;
  background-color: #94aef5 !important;
}
ssi-inbox-sidebar .wide-version .social-push-btn.exit {
  background-color: #f88c68 !important;
}
ssi-inbox-sidebar .wide-version .social-push-btn.exit i {
  transform: rotate(90deg);
}
ssi-inbox-sidebar .slim-version {
  flex: 1;
  flex-direction: column;
  width: 75px;
  min-width: 75px;
  font-size: 9px;
  font-weight: normal;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0px;
  background-color: #f8f8f8;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #3f3f49;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  color: white;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-item.useful-things {
  margin-bottom: 10px;
}
@media only screen and (max-height: 820px) {
  ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-standard-tools .inbox-sidebar-slim-item,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-tools .inbox-sidebar-slim-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
  }
}
@media only screen and (max-height: 740px) {
  ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-standard-tools .inbox-sidebar-slim-item,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-tools .inbox-sidebar-slim-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-size: 16px;
  }
}
@media only screen and (max-height: 550px) {
  ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-standard-tools .inbox-sidebar-slim-item-icon:last-child,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-tools .inbox-sidebar-slim-item-icon:last-child {
    margin-bottom: 10px;
  }
  ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-standard-tools .inbox-sidebar-slim-item-icon.useful-things,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-tools .inbox-sidebar-slim-item-icon.useful-things {
    display: none;
  }
}
@media only screen and (max-height: 500px) {
  ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-top {
    flex: 0;
  }
  ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-standard-tools .inbox-sidebar-slim-item-icon,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-tools .inbox-sidebar-slim-item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
  }
  ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-standard-tools .inbox-sidebar-slim-item-icon:last-child,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-tools .inbox-sidebar-slim-item-icon:last-child {
    margin-bottom: 10px;
  }
  ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-standard-tools .inbox-sidebar-slim-item-icon.useful-things,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-tools .inbox-sidebar-slim-item-icon.useful-things {
    display: none;
  }
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-item-clickable {
  cursor: pointer;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages {
  margin: 20px 5px 5px 5px;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages button,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages dl,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages dt,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages dd,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages ul,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages li,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages span {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin: 0;
  padding: 0;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages span {
  text-transform: capitalize;
  font-size: 1em;
  font-weight: 600;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages ul {
  list-style: none;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages .refresh-button-container {
  padding: 5px;
  margin: 5px;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages .refresh-button {
  background-color: #545767;
  border: none;
  border-radius: 50%;
  color: #14bae3;
  font-size: 2.4em;
  font-style: normal;
  outline: none;
  padding: 5px;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages .inbox-sidebar-slim-messages-total {
  color: #fff;
  margin: 5px;
  padding: 5px;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages .inbox-sidebar-slim-messages-total .total-value {
  font-size: 1.6em;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages .statistics-container {
  margin-top: 5px;
  color: #838eab;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages .statistics-container .active .statistic-value {
  color: #14bae3;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages .statistics-container .active .statistic-label {
  color: #fff;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages .statistics-container .statistic {
  margin: 5px 0;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages .statistics-container .statistic-value {
  font-size: 1.6em;
}
@media only screen and (max-height: 700px) {
  ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages .statistics-container {
    display: none;
  }
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom {
  overflow-y: auto;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom ul > * {
  margin: 0;
  padding: 0;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom .inbox-sidebar-slim-bulk-tools > .inbox-sidebar-slim-item:hover {
  color: #fff;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom .inbox-sidebar-slim-bulk-tools > .inbox-sidebar-slim-item.bulk-tool-assign .slim-item-icon {
  background-color: #9f2977;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom .inbox-sidebar-slim-bulk-tools > .inbox-sidebar-slim-item.bulk-tool-count {
  flex-flow: column nowrap;
  height: auto;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom .inbox-sidebar-slim-bulk-tools > .inbox-sidebar-slim-item.bulk-tool-count .count-label {
  font-size: 1em;
  margin-top: 5px;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom .inbox-sidebar-slim-bulk-tools > .inbox-sidebar-slim-item.bulk-tool-count .count-value {
  align-items: center;
  color: #14bae3;
  display: flex;
  font-size: 1.6em;
  font-weight: 500;
  justify-content: center;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom .inbox-sidebar-slim-bulk-tools > .inbox-sidebar-slim-item.bulk-tool-deselect {
  font-size: 1.5em;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom .inbox-sidebar-slim-bulk-tools > .inbox-sidebar-slim-item.bulk-tool-sentiment .slim-item-icon {
  background-color: #8ed0d8;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom .inbox-sidebar-slim-bulk-tools > .inbox-sidebar-slim-item.bulk-tool-tag .slim-item-icon {
  background-color: #6e9bb6;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom .inbox-sidebar-slim-bulk-tools > .inbox-sidebar-slim-item .slim-item-icon, ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom .inbox-sidebar-slim-bulk-tools > .inbox-sidebar-slim-item.bulk-tool-count .count-value {
  background-color: #454748;
  border-radius: 50%;
  height: 48px;
  position: relative;
  width: 48px;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom .inbox-sidebar-slim-bulk-tools > .inbox-sidebar-slim-item .slim-item-icon:before, ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom .inbox-sidebar-slim-bulk-tools > .inbox-sidebar-slim-item.bulk-tool-count .count-value:before {
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 24px;
  left: 12px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 12px;
  width: 24px;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-top {
  flex: 1;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-inner,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item {
  width: 100%;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-inner {
  padding: 8px;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-icon {
  font-size: 20px;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-bg-light {
  background-color: #545767;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-clickable.inbox-sidebar-slim-item-bg-light:hover {
  background-color: #5f6375;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-bg-dark {
  background-color: #363434;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-clickable.inbox-sidebar-slim-item-bg-dark:hover {
  background-color: #292727;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-count .count {
  font-size: 13px;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-count .count.active {
  color: #43ade8;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-icon-count i {
  color: #14bae3;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-icon-count .count {
  font-size: 17px;
  font-style: italic;
  position: relative;
  left: -3px;
  bottom: -2px;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-icon-count.active i {
  color: white;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item.active {
  color: #14bae3;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-bulk-assign {
  color: #f0b427;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-clickable.inbox-sidebar-slim-item-bulk-assign:hover {
  color: #edab10;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-bulk-sentiment {
  color: #d41d68;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-clickable.inbox-sidebar-slim-item-bulk-sentiment:hover {
  color: #be1a5d;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-bulk-tag {
  color: #668aea;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-clickable.inbox-sidebar-slim-item-bulk-tag:hover {
  color: #5079e7;
}
ssi-inbox-sidebar .slim-version .social-push-btn.exit i {
  font-size: 14px;
  padding: 6px 7px;
  background-color: #f88c68 !important;
  border-radius: 50%;
  transform: rotate(90deg);
}
@media (min-width: 885px) {
  ssi-inbox-sidebar .wide-version {
    display: none;
  }
  ssi-inbox-sidebar .slim-version {
    display: flex;
  }
}
@media (min-width: 1350px) {
  ssi-inbox-sidebar .wide-version {
    display: flex;
  }
  ssi-inbox-sidebar .slim-version {
    display: none;
  }
}
@media (max-width: 885px) {
  ssi-inbox-sidebar .hidden-on-small-screens {
    display: none;
  }
}
ssi-inbox-sidebar .must-hide {
  display: none;
}
ssi-inbox-sidebar .must-show {
  display: flex;
}
@media (max-width: 885px) {
  ssi-inbox-sidebar .hidden-on-small-screens {
    display: none;
  }
}

ssi-inbox-useful-things.inbox-useful-things-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  z-index: 2;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s;
}
ssi-inbox-useful-things.expanded {
  max-height: 115px;
}
ssi-inbox-useful-things .inbox-sidebar-useful-things {
  margin: 0;
  padding: 0;
}
ssi-inbox-useful-things .inbox-sidebar-useful-things dt {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 28px;
  padding: 14px 28px;
}
ssi-inbox-useful-things .inbox-sidebar-useful-things dd ul {
  margin: 0;
  padding: 15px 0 0;
}
ssi-inbox-useful-things .inbox-sidebar-useful-things dd ul li {
  align-items: center;
  background-color: #545767;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 0 12px 10px 12px;
  padding: 6px 10px;
}
ssi-inbox-useful-things .inbox-sidebar-useful-things dd ul li.btn-export {
  background-color: #14bae3;
}
ssi-inbox-useful-things .inbox-sidebar-useful-things dd ul li.mark-all-actioned {
  background-color: #b2c614;
}
ssi-inbox-useful-things .inbox-sidebar-useful-things dd ul li.mark-all-unactioned {
  background-color: #545767;
}
ssi-inbox-useful-things .inbox-sidebar-useful-things dd ul li span {
  font-size: 12px;
}
ssi-inbox-useful-things .inbox-sidebar-useful-things dd ul li a {
  border-radius: 0;
  margin: 3px 0;
  padding: 10px 20px;
}
ssi-inbox-useful-things .inbox-sidebar-useful-things i.active {
  color: #14bae3;
}
ssi-inbox-useful-things .inbox-sidebar-useful-things .inbox-useful-things-collpase {
  max-height: 140px;
  transform: translateZ(0);
  transition: max-height 0.35s ease;
  overflow: hidden;
}
ssi-inbox-useful-things .inbox-sidebar-useful-things .inbox-useful-things-collpase.inbox-useful-things-collpased {
  max-height: 0;
}
ssi-inbox-useful-things .inbox-useful-things-popup .inbox-useful-things-body {
  background-color: #3f3f49;
  margin-right: auto;
  width: 230px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
ssi-inbox-useful-things .inbox-useful-things-popup .inbox-useful-things-title {
  text-transform: uppercase;
  text-align: right;
  font-weight: normal;
  flex: 1;
  font-size: 11px;
  margin-bottom: 4px;
}
ssi-inbox-useful-things .inbox-useful-things-popup .inbox-useful-things-title a {
  color: #fff;
  text-decoration: none;
}
ssi-inbox-useful-things .inbox-useful-things-popup .inbox-useful-things-title i {
  margin-left: 3px;
}
ssi-inbox-useful-things .inbox-useful-things-popup .inbox-useful-things-list a {
  display: flex;
  align-items: center;
  background-color: #545767;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 10px 12px;
  padding: 6px 10px;
  font-size: 12px;
  font-weight: 400;
}
ssi-inbox-useful-things .inbox-useful-things-popup .inbox-useful-things-list a:hover {
  background-color: #292727;
  text-decoration: none;
}
ssi-inbox-useful-things .inbox-useful-things-popup .inbox-useful-things-list i {
  color: #fff;
  font-size: 20px;
  font-size: 14px;
}
ssi-inbox-useful-things .inbox-useful-things-popup .inbox-useful-things-list .inbox-export a {
  background-color: #14bae3;
}
ssi-inbox-useful-things .inbox-useful-things-popup .inbox-useful-things-list .mark-all-actioned a {
  background-color: #b2c614;
}
ssi-inbox-useful-things .inbox-useful-things-popup .inbox-useful-things-list .mark-all-unactioned a {
  background-color: #545767;
}