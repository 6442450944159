@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../../angularjs/common/sass/variables';
@import '../../styles/variables';
@import '~/styles/colors';

ssi-global-filters {
  .global-filters {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &.compact {
      .filters-body {
        padding: 2em 1em 2em;
      }
    }

    ssi-tags-input {
      padding: 10px 10px;
    }

    .filters-body {
      flex: 1 1 auto;
      overflow-y: auto;
      overflow-x: hidden;
      width: 100%;
      height: 100%;
    }

    .filters-title {
      color: $lmode-body-text;
      padding: 0 0 2rem 4rem;
      font-size: 1.4em;
      margin: 0;
      line-height: 1;
      font-weight: 900;
      text-rendering: geometricPrecision;
      padding: 2em 0 1em 2em;
    }

    ul,
    li {
      list-style: none;
      margin: 0;
    }

    ul {
      width: 100%;
      padding: 0 40px;
    }

    li {
      border-bottom: 1px solid #c3c9d8;
      padding: 15px 10px;
      font-size: 14px;
      color: $lmode-body-text;
      font-weight: 900;

      i {
        color: $lmode-helper-text;

        &.leading-icon {
          font-size: 14px;
          margin-right: 15px;
          &.ssi-comment,
          &.ssi-image-small {
            font-size: 12px;
          }

          &.location {
            padding-right: 3px;
            font-size: 18px;
          }
        }
      }
    }

    h4 {
      text-transform: uppercase;
      color: $lmode-helper-text;
      letter-spacing: 1px;
      font-size: 10px;
      margin: 0 0 2em 0;
    }

    .filter-header {
      cursor: pointer;
      color: #43537f;
      .filter-header-right {
        margin-top: -8px;
        float: right;
        .ssi-arrow-down-new {
          margin-top: 10px;
          color: #43537f;

          &.flip {
            transform: rotate(180deg);
          }
        }
      }
      &.filter-active {
        span {
          width: 90px;
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .leading-icon {
          position: relative;
          top: -4px;
        }
      }
    }

    .filter-options {
      margin: 2em 0 0 2.2em;
    }

    .filter-options-label {
      text-transform: uppercase;
      font-weight: 900;
      font-size: 11px;
      letter-spacing: 1px;
    }

    .input-wrapper-half-label {
      margin: 0 10px 20px;
    }

    .filter-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 1em;

      .form-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-basis: 100%;
        margin-top: 15px;

        &.extra-margin-top {
          margin-top: 50px;
        }

        &.no-margin {
          margin: 0;
        }

        &.column {
          flex-direction: column;
          align-items: flex-start;
          .form-field {
            width: 100%;
            align-items: center;
            > label {
              width: 120px;
              margin-bottom: 0;
            }
          }
        }

        .form-field {
          position: relative;
          display: flex;
          // flex-direction: column;
          flex-basis: 100%;
          // &:nth-child(2) {
          //   margin-left: 20px;
          // }
          margin: 0 0 20px 0;

          ssi-checkbox-2 {
            width: 100%;
          }

          &.half {
            flex-basis: 48%;
          }

          &.column {
            flex-direction: column;
            align-items: flex-start;
          }

          > label {
            color: $lmode-body-text;
            font-size: 10px;
            font-weight: 900;
            letter-spacing: 1px;
            text-transform: uppercase;
            margin-left: 20px;
            margin-bottom: 15px;
          }

          label {
            &.compact {
              width: auto;
              margin: 0;
            }
          }

          .input-wrapper {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;

            label {
              padding: 0 0 15px 20px;
              font-size: 10px;
              font-weight: 900;
              line-height: 12px;
              letter-spacing: 0.1em;
              text-align: left;
              text-transform: uppercase;
            }

            &.select-all-accounts {
              justify-content: flex-end;
            }

            .inline-select-item {
              display: flex;
              align-items: center;
              color: $lmode-body-text;
              font-size: 12px;
              font-weight: 900;
              padding: 10px;
              border-radius: 10px;
              background-color: $grey-background;
              margin: 0 10px 10px 0;
              cursor: pointer;
              user-select: none;

              i {
                margin-right: 10px;
              }

              &.selected {
                color: $primary-active;
                border: 1px solid $primary-active;
              }

              &:hover {
                color: #fff;
                background-color: $primary-active;
                box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.35);
              }
            }

            ssi-social-network-icon {
              i {
                font-size: 12px;
              }
            }

            .accounts-selector {
              width: 100%;
              button.dd-head {
                width: 100%;
              }
            }

            .select-accounts-placeholder {
              color: $lmode-helper-text;
              font-size: 14px;
              font-weight: 700;
            }

            .select-accounts-label {
              color: $lmode-title-text;
              font-size: 14px;
              font-weight: 900;
              display: flex;
              align-items: center;

              ssi-social-network-icon {
                margin-right: 8px;
              }
            }

            .date-picker-input {
              width: 100%;
              height: 46px;
              border: none;
              outline: none;
              border-radius: 100px;
              display: flex;
              align-items: center;
              color: $lmode-title-text;
              font-size: 14px;
              font-weight: 900;
              background-color: $grey-background;
              padding: 10px 30px 10px 20px;
              appearance: none;

              &::placeholder {
                color: $lmode-helper-text;
                font-size: 14px;
                font-weight: 700;
                line-height: 17px;
              }

              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                appearance: none;
              }
            }

            .tag-selector {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;

              .dd-container,
              button.dd-head {
                width: 100%;
                height: auto;
              }
              button.dd-head {
                border-radius: 12px;
              }

              .select-tags-placeholder {
                color: $lmode-helper-text;
                font-size: 14px;
                font-weight: 700;
              }

              .select-tag-labels {
                text-align: left;
                margin: 0 0 -10px -10px;
                color: #fff;

                span {
                  padding: 6px 8px;
                  font-size: 10px;
                  margin: 0 4px 8px 0;
                  background-color: $primary-active;
                  border-radius: 100px;
                  display: inline-flex;
                  align-items: center;
                  text-rendering: geometricPrecision;
                  word-break: break-all;

                  &:hover {
                    background-color: $secondary-red;
                  }
                }

                .ssi {
                  margin: 0 0 0 7px;
                  font-size: 8px;
                }
              }
            }

            ssi-dropdown-select-2 {
              .dd-body {
                width: 100%;
              }
              .select-placeholder {
                color: $lmode-helper-text;
              }
              .select-label {
                color: #000;
              }
            }
          }
          input[type='number'] {
            border: 0.1em solid $lmode-border-color;
            padding-left: 5px;
            color: $lmode-title-text;
            font-style: normal;
            font-weight: 500;
            border-radius: 10px;
            padding: 0.6em 0.8em;

            &:focus {
              outline: none;
              border: 0.1em solid $primary-active;
            }

            &::placeholder {
              font-size: 12px;
              font-weight: 700;
              font-style: normal;
              color: $lmode-helper-text;
            }
          }
          input[type='number']::-webkit-inner-spin-button,
          input[type='number']::-webkit-outer-spin-button {
            opacity: 1;
          }
        }
      }
    }
    .filters-footer {
      flex: 0 0 auto;
      margin-top: auto;
      display: flex;
      justify-content: flex-end;
      padding: 20px 30px;
      border-top: 1px solid #c3c9d8;
      box-shadow: 0 18px 40px 0 rgba(131, 142, 171, 0.3490196078);

      button {
        margin-left: 10px;
        &.clear-btn {
          margin-right: auto;
        }
      }
    }
    .divider {
      width: 100%;
      margin: 1em 0 2em 0;
      border-bottom: 1px solid $lmode-helper-text;
    }
    .scroll-wrapper {
      padding: 5px 20px 0 0;
      width: 100%;
      max-height: 400px;
      overflow-y: scroll;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 10px;
        background-color: $lmode-border-color;
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: $primary-active;
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    }
    .cancel-filters {
      display: inline-block;
      margin-right: 5px;
      padding: 10px 12px;
      background-color: $primary-red;
      border-radius: 10px;
      font-weight: 700;
      font-size: 12px;
      i {
        color: $secondary-red;
        font-size: 8px;
        margin: 0 5px;
      }
    }
  }

  .apply-btn {
    background-color: #f0f3f9;
    // border: 1px solid $lmode-helper-text;
    color: $lmode-body-text;
    &.active {
      background-color: $primary-active;
      color: white;
      border: none;
    }
  }

  ssi-checkbox-2 {
    .checkbox-2-container {
      label {
        font-weight: 900;
        text-rendering: geometricPrecision;
        font-size: 14px;
        text-transform: none;
        letter-spacing: 0;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .checkbox {
        height: 16px;
        width: 16px;

        & ~ i {
          top: 5px;
        }

        &.checked {
          & ~ label {
            color: $primary-active;
          }

          & ~ i {
            color: #fff;
          }
        }
      }

      &.reversed i.checked {
        right: 3px;
      }

      .disabled {
        opacity: 0.4;
      }
    }
  }

  ssi-social-network-icon {
    position: absolute;
    margin-left: -20px;
    i {
      color: $lmode-helper-text !important;

      &.meta-icon {
        font-size: 10px;
        padding-bottom: 6px;
      }
      &.nextdoor-icon {
        font-size: 11px;
        padding-bottom: 6px;
      }
    }
  }

  .author-filters {
    flex: 0 0 auto;
    padding: 0.2em !important;
    label {
      width: 100%;
    }
  }

  .author-filter {
    font-size: 1.2em;
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 0 3rem 0 1.1rem;
    margin: 0 0.7rem 0.7rem 0 !important;
    flex: 0 1 auto;
    height: 34px;
    background-color: white;
    border: 0.1rem solid #c3c9d8;
    border-radius: 0.8rem;

    span {
      font-size: 1.2rem;
      line-height: 1.2rem;
      min-height: 1rem;
      color: $lmode-title-text;
      font-weight: 900;
      text-rendering: geometricPrecision;
      padding-right: 2em;
    }
  }

  .author-filter-action {
    position: absolute;
    display: block;
    right: 0.6rem;
    top: 0;
    bottom: 0;
    margin: auto;
    padding: 0 0.5rem;
    border-width: 0;
    background-color: transparent;
    line-height: 0;

    &:hover,
    &:focus {
      .ssi {
        color: $primary-active;
      }
    }

    .ssi {
      font-size: 0.8rem;
      color: $lmode-body-text;
    }
  }

  .author-input {
    label {
      width: 100% !important;
      margin-bottom: 15px !important;
    }
    input {
      border-radius: 1em;
      padding: 10px 30px 10px 20px;
      font-size: 14px;
      font-weight: 900;
      &:focus {
        outline: none;
      }
      &::placeholder {
        font-style: normal !important;
      }
    }
  }
}
