ssi-top-tags-list-box .top-tags-list-box-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
ssi-top-tags-list-box .top-tags-list-box-container.slim {
  padding: 0;
}
ssi-top-tags-list-box .top-tags-list-box-container.slim .body {
  padding: 0;
}
ssi-top-tags-list-box .top-tags-list-box-container .body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 40px;
}
ssi-top-tags-list-box .top-tags-list-box-container .body .tag-bar {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  word-break: break-all;
}
ssi-top-tags-list-box .top-tags-list-box-container .body .tag-bar.top {
  padding: 5px 20px 5px 5px;
  margin-bottom: 10px;
}
ssi-top-tags-list-box .top-tags-list-box-container .body .tag-bar.top .tag-count {
  font-size: 18px;
}
ssi-top-tags-list-box .top-tags-list-box-container .body .tag-bar ssi-medal-score {
  margin-right: 15px;
}
ssi-top-tags-list-box .top-tags-list-box-container .body .tag-bar .progress-bar-wrapper {
  width: 100%;
  margin-right: 15px;
}
ssi-top-tags-list-box .top-tags-list-box-container .body .tag-bar .progress-bar-wrapper span {
  color: #101525;
  font-size: 14px;
  font-weight: 900;
  line-height: 17px;
  word-break: break-all;
}
ssi-top-tags-list-box .top-tags-list-box-container .body .tag-bar .tag-count {
  color: #101525;
  font-size: 14px;
  font-weight: 900;
  line-height: 22px;
}
ssi-top-tags-list-box .top-tags-list-box-container .body-no-results {
  color: #838eab;
  font-size: 16px;
  font-weight: 900;
  padding: 0 10%;
  text-align: center;
}