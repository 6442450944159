@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-sentiment-list {
  font-size: 1rem;

  .sentiment-list {
    margin: 0 -0.6em;
    padding: 0;
    min-width: 20em;

    // Insights variant
    &.insights {
      .sentiment-list-item-1:hover,
      .sentiment-list-item-1.sentiment-list-item-active {
        background-color: #ff6e3d;
        color: #fff;
      }

      .sentiment-list-item-2:hover,
      .sentiment-list-item-2.sentiment-list-item-active {
        color: #fff;
        background-color: #ffa962;
      }

      .sentiment-list-item-3:hover,
      .sentiment-list-item-3.sentiment-list-item-active {
        color: #43537f;
        background-color: #d6dff7;
        box-shadow: 0 1em 1.5em 0 rgba(231, 232, 238, 0.65);
      }

      .sentiment-list-item-4:hover,
      .sentiment-list-item-4.sentiment-list-item-active {
        color: #749d00;
        background-color: #deec74;
      }

      .sentiment-list-item-5:hover,
      .sentiment-list-item-5.sentiment-list-item-active {
        background-color: #00c673;
        color: #fff;
      }
    }
  }

  .sentiment-list-item {
    display: inline-block;
    line-height: 2.8em;
    vertical-align: middle;
    text-align: center;
    background-color: $lmode-border-color;
    color: $lmode-helper-text;
    height: 2.6em;
    width: 2.6em;
    border-radius: 50%;
    margin: 0 0.6em;
    cursor: pointer;

    i {
      line-height: 1;
      font-size: 1.4em;
    }

    &:hover {
      color: rgba(255, 255, 255, 0.75);
      // box-shadow: 0 0.5em 0.5em 0 #e7e8ee;
    }

    // Inbox variant
    &.sentiment-list-item-1:hover,
    &.sentiment-list-item-1.sentiment-list-item-active {
      background-color: $secondary-red;
      color: #fff;
    }

    &.sentiment-list-item-2:hover,
    &.sentiment-list-item-2.sentiment-list-item-active {
      color: $secondary-red;
      background-color: $primary-red;
    }

    &.sentiment-list-item-3:hover,
    &.sentiment-list-item-3.sentiment-list-item-active {
      color: $lmode-helper-text;
      background-color: $lblue-background;
      box-shadow: 0 1em 1.5em 0 rgba(231, 232, 238, 0.65);
    }

    &.sentiment-list-item-4:hover,
    &.sentiment-list-item-4.sentiment-list-item-active {
      color: $primary-active;
      background-color: #e0f9ff;
    }

    &.sentiment-list-item-5:hover,
    &.sentiment-list-item-5.sentiment-list-item-active {
      background-color: #9fd700;
      color: #fff;
    }

    // &.sentiment-list-item-active {

    //     &:hover {
    //         background-color: #EEF0F6;
    //         color: $lmode-helper-text;
    //     }
    // }
  }
}
