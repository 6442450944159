@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../angularjs/common/sass/variables';
@import '../../../../../../angularjs/common/sass/mixins';
@import '~/styles/colors';

ssi-insights-reports-home {
  font-size: 1rem;

  .insights-page {
    position: relative;
    background-color: #fff;
    min-height: calc(100vh - #{$navbar-height});
    overflow-x: hidden;
  }

  .insights-container {
    max-width: 140em;
    padding: 0 2em;
    margin: 4em auto;
  }

  .insights-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto 2.7em;

    & > * {
      flex: 1 0 100%;
    }
  }

  .insights-column {
    flex: 1 0 auto;

    &-third {
      flex: 1 0 calc(100% / 3);
    }

    &-two-thirds {
      flex: 1 0 calc(100% / 3 * 2);
    }
  }

  .insights-reports-grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1em 2.4em;

    &-item {
      flex: 1 0 0%;
      width: 100%;
      margin: 0 1em;
    }
  }

  @supports (display: grid) {
    .insights-reports-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(25.7em, 1fr));
      grid-gap: 2.7em;
      margin: 0;

      .insights-reports-grid-item {
        margin: 0;
      }
    }
  }

  @include ie-only {
    .insights-reports-grid {
      display: flex;
      margin: 0 -1em 2.4em;

      .insights-reports-grid-item {
        margin: 0 1em;
      }
    }
  }

  .insights-reports-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 4em 0 3em;
    height: 45.6em;
    color: #43537f;
    text-align: center;
    border-radius: 1.2em;
    border: 0.1em solid #d1dbf4;
    background-color: #f8f9fd;

    h3,
    p {
      max-width: 21rem;
      text-rendering: geometricPrecision;
    }

    h3 {
      font-size: 2em;
      margin: 0 0 0.6rem;
      font-weight: 900;
    }

    p {
      line-height: 2rem;
      margin: 0 0 2rem;
      font-weight: 700;
    }

    button {
      background-color: #14bae3;
      border-radius: 1em;
      padding: 0.8em 1.9em;
      border-width: 0;
      color: #fff;

      .ssi {
        font-size: 1.2em;
        margin: 0 0.7rem 0 0;
        vertical-align: text-bottom;
      }

      span {
        font-size: 1.4em;
        font-weight: 900;
        text-rendering: geometricPrecision;
      }
    }
  }

  .insights-page-break {
    width: 200vw;
    margin: 4em -50vw;
    border-color: #d1dbf4;
  }

  .insights-reports-banner {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 4.6em 5.5em 5em;
    background-color: #101525;
    color: #fff;
    border-radius: 1.2em;

    &-content {
      flex: 1 1 auto;
      max-width: 61em;
    }

    &-hero {
      display: none;
      flex: 0 0 30.8em;
      width: 36.5em;
      height: 14.7em;
      margin: 0 0 -5em 1em;

      @media (min-width: 992px) {
        display: block;
      }
    }

    h2 {
      font-size: 2em;
      margin-bottom: 1.4rem;
    }

    p {
      line-height: 2rem;
      margin: 0;
      font-weight: 700;
      text-rendering: geometricPrecision;
    }
  }

  .insights-report-card {
    cursor: pointer;

    img {
      width: 100%;
      border-radius: 1.2em;
      margin: 0 0 2em;
    }

    &-meta {
      max-width: 97%;
      margin: 0 0 0 auto;

      p {
        color: #43537f;
        line-height: 2rem;
        font-weight: 700;
        text-rendering: geometricPrecision;
      }
    }

    &-title {
      color: #101525;
      font-size: 2em;
      font-weight: 900;
      text-rendering: geometricPrecision;
      margin: 0 0 0.6rem;
    }
  }

  .insights-reports-controls {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0.6em 0 2em;

    & > * {
      //flex: 0 0 100%;
      flex: 0 0 auto;
      margin: 0 0 1.6em;

      // @media (min-width: 768px) {
      //   flex: 1 0 0;
      // }
    }

    &-filter {
      .ssi {
        color: #43537f;
        font-size: 1.2em;
        margin: 0 0.6rem 0 0;
      }

      input {
        color: #43537f;
        font-size: 1.4em;
        font-weight: 700;
        text-rendering: geometricPrecision;
        border-width: 0;
        outline: 0;

        &::placeholder {
          color: #838eab;
        }
      }
    }

    &-type {
      text-align: center;

      ul {
        margin: 0 0 0.4em;
        padding: 0;
      }

      li {
        position: relative;
        display: inline-block;
        color: #43537f;
        font-size: 1em;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        font-weight: 900;
        text-rendering: geometricPrecision;
        padding: 0.3em 1em 0.8em;
        margin: 0 0.6em;
        cursor: pointer;

        &.insights-reports-controls-type-active:after {
          content: '';
          position: absolute;
          bottom: -0.4em;
          left: 0;
          right: 0;
          margin: auto;
          display: inline-block;
          height: 0.4em;
          width: 5em;
          border-radius: 10em;
          background-color: #14bae3;
        }
      }
    }

    &-sort {
      margin-top: 0.4em;
      text-align: right;
      color: #43537f;
      font-size: 1em;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      font-weight: 900;
      text-rendering: geometricPrecision;
      cursor: pointer;

      i {
        font-size: 1.4em;
      }
    }

    ssi-dropdown-select-2 {
      min-width: 160px;
    }
  }

  .insights-reports-tile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 0.1em solid #d1dbf4;
    background-color: #f8f9fd;
    border-radius: 1.2em;
    height: 18em;

    &-actionable {
      cursor: pointer;

      &:hover,
      &:focus {
        border-color: #14bae3;
      }
    }

    h3 {
      font-size: 1.4em;
      text-align: center;
      margin: 0.6rem 0 2.2rem;
      color: #43537f;
      font-weight: 900;
      text-rendering: geometricPrecision;
    }

    p {
      font-size: 1.4em;
      margin: -1.2em 0 2em;
      color: #43537f;
      font-weight: 700;
      text-rendering: geometricPrecision;
    }

    button {
      color: #43537f;
      background-color: #fff;
      border-width: 0;
      border-radius: 1rem;
      font-size: 1.4em;
      font-weight: 900;
      text-rendering: geometricPrecision;
      line-height: 1;
      padding: 1.1rem 2.1rem;

      &:hover,
      &:focus {
        background-color: $primary-active;
        color: #fff;
      }

      i {
        font-size: 1.2rem;
        margin: 0 0.5rem 0 0;
      }
    }

    .insights-reports-tile-add {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: -0.6rem 0 0;
      height: 5rem;
      width: 5rem;
      padding: 0;
      color: #14bae3;
      border-radius: 50%;

      i {
        font-size: 1.2rem;
        margin: 0;
        color: inherit;
      }
    }
  }

  ssi-paginator {
    margin: 4em 0 0;
  }

  ssi-no-results {
    font-size: 1rem;

    .ssi-no-results {
      font-size: 4rem;
    }

    .no-results {
      padding: 10rem;

      .title {
        text-rendering: geometricPrecision;
      }

      .desc {
        width: 22.5rem;
        color: #43537f;
        line-height: 2rem;
        text-rendering: geometricPrecision;
      }
    }
  }
}
