ssi-publisher-twitter-post-preview {
  display: block;
  padding: 20px 20px 11.6px 20px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #e1e8ed;
  max-width: 520px;
  font: normal normal 16px/1.4 Helvetica, Roboto, "Segoe UI", Calibri, sans-serif;
  color: #1c2022;
}
ssi-publisher-twitter-post-preview a {
  color: #1b95e0;
}
ssi-publisher-twitter-post-preview .twitter-post-header {
  color: #1c2022;
  line-height: 1.2;
}
ssi-publisher-twitter-post-preview .twitter-post-header img {
  height: 36px;
  width: 36px;
  margin-right: 8px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 0px 2px inset;
  border: 1px solid rgba(0, 0, 0, 0.04);
}
ssi-publisher-twitter-post-preview .twitter-post-header:hover,
ssi-publisher-twitter-post-preview .twitter-post-link-preview:hover {
  text-decoration: none;
}
ssi-publisher-twitter-post-preview .twitter-post-account-name {
  display: flex;
  font-weight: 700;
  color: #101525;
}
ssi-publisher-twitter-post-preview .twitter-post-account-name i {
  margin-left: auto;
  color: #687987;
}
ssi-publisher-twitter-post-preview .twitter-post-account-username {
  font-weight: normal;
  font-size: 14px;
  color: #657786;
  margin-left: 2px;
}
ssi-publisher-twitter-post-preview .twitter-post-body {
  padding-left: 45px;
}
ssi-publisher-twitter-post-preview .twitter-post-reply-to {
  line-height: 20px;
  font-size: 14px;
  padding-bottom: 8px;
  padding-top: 4px;
  color: #657786;
}
ssi-publisher-twitter-post-preview .twitter-post-reply-to a {
  color: #1b95e0;
}
ssi-publisher-twitter-post-preview .twitter-post-text {
  margin-bottom: 10px;
  white-space: pre-wrap;
  font-size: 15px;
}
ssi-publisher-twitter-post-preview .twitter-post-text span:not(.js-display-url) {
  display: none;
}
ssi-publisher-twitter-post-preview .twitter-post-link-preview {
  margin-top: 10.4px;
  font-size: 14px;
  border-radius: 10px;
  border: 1px solid rgb(225, 232, 237);
  display: block;
  color: #1c2022;
  margin-bottom: 0.32333em;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
ssi-publisher-twitter-post-preview .twitter-post-link-preview img {
  width: 100%;
  border-top-left-radius: 0.42857em;
  border-top-right-radius: 0.42857em;
}
ssi-publisher-twitter-post-preview .twitter-post-link-preview:hover {
  color: #1c2022;
  background: rgb(245, 248, 250);
  border-color: rgba(136, 153, 166, 0.5);
}
ssi-publisher-twitter-post-preview .twitter-post-link-preview-title {
  color: #232528;
  font-size: 1em;
  margin: 0 0 0.15em;
  font-weight: bold;
}
ssi-publisher-twitter-post-preview .twitter-post-link-preview-description {
  color: #6c7d8b;
  max-height: 2.6em;
  overflow: hidden;
  margin-top: 0.32333em;
}
ssi-publisher-twitter-post-preview .twitter-post-link-preview-hostname {
  text-transform: lowercase;
  color: rgb(136, 153, 166);
  max-height: 1.3em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 0.32333em;
}
ssi-publisher-twitter-post-preview .twitter-post-link-preview-footer {
  padding: 0.75em;
}
ssi-publisher-twitter-post-preview .twitter-post-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 425px;
  margin-top: 8px;
  padding-left: 45px;
  color: rgb(105, 120, 130);
}
ssi-publisher-twitter-post-preview .twitter-post-footer-item {
  margin-right: 14px;
  font-weight: 300;
}
ssi-publisher-twitter-post-preview .twitter-post-footer-item i.ssi-retweet-new {
  font-size: 20px;
}
ssi-publisher-twitter-post-preview .twitter-post-footer-item .twitter-analytics {
  position: relative;
  display: block;
  height: 14px;
  width: 1px;
  background-color: #687987;
}
ssi-publisher-twitter-post-preview .twitter-post-footer-item .twitter-analytics::before, ssi-publisher-twitter-post-preview .twitter-post-footer-item .twitter-analytics::after {
  position: absolute;
  width: 1px;
  content: "";
  background-color: #687987;
  bottom: 0;
}
ssi-publisher-twitter-post-preview .twitter-post-footer-item .twitter-analytics:before {
  right: 4px;
  height: 8px;
}
ssi-publisher-twitter-post-preview .twitter-post-footer-item .twitter-analytics:after {
  left: 4px;
  height: 11px;
}
ssi-publisher-twitter-post-preview .twitter-post-images,
ssi-publisher-twitter-post-preview .twitter-post-video {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}
ssi-publisher-twitter-post-preview .twitter-post-images.single-image img {
  width: 100%;
  border-radius: 5px;
}
ssi-publisher-twitter-post-preview .twitter-post-images.two-images {
  display: flex;
}
ssi-publisher-twitter-post-preview .twitter-post-images.two-images img {
  width: 100%;
}
ssi-publisher-twitter-post-preview .twitter-post-images.two-images .twitter-post-images-left {
  margin-right: 1px;
}
ssi-publisher-twitter-post-preview .twitter-post-images.two-images .twitter-post-images-left img {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
ssi-publisher-twitter-post-preview .twitter-post-images.two-images .twitter-post-images-right img {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
ssi-publisher-twitter-post-preview .twitter-post-images.three-images,
ssi-publisher-twitter-post-preview .twitter-post-images.four-images {
  display: flex;
}
ssi-publisher-twitter-post-preview .twitter-post-images.three-images img,
ssi-publisher-twitter-post-preview .twitter-post-images.four-images img {
  width: 100%;
}
ssi-publisher-twitter-post-preview .twitter-post-images.three-images .twitter-post-images-left,
ssi-publisher-twitter-post-preview .twitter-post-images.four-images .twitter-post-images-left {
  width: 66%;
  margin-right: 1px;
}
ssi-publisher-twitter-post-preview .twitter-post-images.three-images .twitter-post-images-left img,
ssi-publisher-twitter-post-preview .twitter-post-images.four-images .twitter-post-images-left img {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  height: 100%;
}
ssi-publisher-twitter-post-preview .twitter-post-images.three-images .twitter-post-images-right,
ssi-publisher-twitter-post-preview .twitter-post-images.four-images .twitter-post-images-right {
  flex: 1;
  display: flex;
  flex-direction: column;
}
ssi-publisher-twitter-post-preview .twitter-post-images.three-images .twitter-post-images-right img,
ssi-publisher-twitter-post-preview .twitter-post-images.four-images .twitter-post-images-right img {
  flex: 1;
}
ssi-publisher-twitter-post-preview .twitter-post-images.three-images .twitter-post-images-right img:first-child,
ssi-publisher-twitter-post-preview .twitter-post-images.four-images .twitter-post-images-right img:first-child {
  margin-bottom: 1px;
  border-top-right-radius: 5px;
}
ssi-publisher-twitter-post-preview .twitter-post-images.three-images .twitter-post-images-right img:nth-child(2),
ssi-publisher-twitter-post-preview .twitter-post-images.four-images .twitter-post-images-right img:nth-child(2) {
  border-bottom-right-radius: 5px;
}
ssi-publisher-twitter-post-preview .twitter-post-images.four-images .twitter-post-images-left {
  width: 75%;
}
ssi-publisher-twitter-post-preview .twitter-post-images.four-images .twitter-post-images-right img:nth-child(2) {
  border-bottom-right-radius: 0px;
  margin-bottom: 1px;
}
ssi-publisher-twitter-post-preview .twitter-post-images.four-images .twitter-post-images-right img:nth-child(3) {
  border-bottom-right-radius: 5px;
}
ssi-publisher-twitter-post-preview .twitter-post-video video {
  border-radius: 5px;
  width: 100%;
  height: 100%;
}