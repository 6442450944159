ssi-account-summary-card {
  font-size: 1rem;
}
ssi-account-summary-card .asc {
  display: inline-flex;
  background-color: #fff;
  box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
  border-radius: 1.2em;
  padding: 1.2em;
  width: 100%;
  min-width: 26.8em;
  margin: 0 0 2em;
}
@media print {
  ssi-account-summary-card .asc {
    border: 1px solid black;
  }
}
ssi-account-summary-card .asc-left {
  flex: 0 0 4.6em;
  padding-right: 1em;
}
ssi-account-summary-card .asc-thumb {
  height: 4.6em;
  width: 4.6em;
  overflow: hidden;
}
ssi-account-summary-card .asc-thumb i {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  color: #fff;
  line-height: 1;
  background-color: #89a4ea;
  font-size: 1.8em;
}
ssi-account-summary-card .asc-thumb i.ssi-sms:before {
  margin-top: 0.4em;
}
ssi-account-summary-card .asc-thumb i.fa-twitter, ssi-account-summary-card .asc-thumb i.ssi-x-logo {
  font-size: 2em;
  background-color: #1da1f2;
}
ssi-account-summary-card .asc-thumb i.fa-facebook {
  font-size: 1.7em;
  background-color: #1877f2;
}
ssi-account-summary-card .asc-thumb i.fa-linkedin {
  font-size: 1.8em;
  background-color: #2867b2;
}
ssi-account-summary-card .asc-thumb i.fa-youtube-play {
  font-size: 2em;
  background-color: #f00;
}
ssi-account-summary-card .asc-thumb i.fa-instagram {
  font-size: 2em;
  background: linear-gradient(180deg, #635fcc, #d43082 37%, #fec86b 100%);
}
ssi-account-summary-card .asc-thumb i.ssi-whatsapp {
  font-size: 1.7em;
  background-color: #56cd63;
}
ssi-account-summary-card .asc-right {
  flex: 1 0 auto;
  text-rendering: geometricPrecision;
}
ssi-account-summary-card .asc-title {
  font-weight: 900;
  margin: 0.7rem 0 0;
  font-size: 1.6em;
  color: #46547c;
}
ssi-account-summary-card .asc-meta {
  font-weight: 700;
  margin: 0.1em 0 1em;
  color: #838eab;
}
ssi-account-summary-card .asc-stats {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 2.6em 0.6em 0.5em 0;
}
ssi-account-summary-card .asc-stat {
  display: inline-block;
  margin: 0 2.2em 0 0;
}
ssi-account-summary-card .asc-stat:last-child {
  margin-right: 0;
}
ssi-account-summary-card .asc-stat:nth-child(2n) .asc-stat-label:before {
  background-color: #14bae3;
}
ssi-account-summary-card .asc-stat-data {
  color: #101525;
  font-size: 2em;
  margin: 0;
  font-weight: 900;
}
ssi-account-summary-card .asc-stat-label {
  color: #838eab;
  font-weight: 900;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 1em;
  margin: 0;
}
ssi-account-summary-card .asc-stat-label:before {
  content: "";
  display: block;
  width: 6em;
  height: 0.4em;
  border-radius: 10em;
  background-color: #89a4ea;
  margin: 0.6em 0 0.8em;
}