@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-publisher-twitter-post-preview {
  display: block;
  padding: 20px 20px 11.6px 20px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #e1e8ed;
  max-width: 520px;
  font: normal normal 16px/1.4 Helvetica, Roboto, 'Segoe UI', Calibri,
    sans-serif;
  color: #1c2022;

  a {
    color: #1b95e0;
  }

  .twitter-post-header {
    img {
      height: 36px;
      width: 36px;
      margin-right: 8px;
      box-shadow: rgba(0, 0, 0, 0.02) 0px 0px 2px inset;
      border: 1px solid rgba(0, 0, 0, 0.04);
    }
    color: #1c2022;
    line-height: 1.2;
  }

  .twitter-post-header:hover,
  .twitter-post-link-preview:hover {
    text-decoration: none;
  }

  .twitter-post-account-name {
    display: flex;
    font-weight: 700;
    color: $lmode-title-text;
    i {
      margin-left: auto;
      color: #687987;
    }
  }

  .twitter-post-account-username {
    font-weight: normal;
    font-size: 14px;
    color: #657786;
    margin-left: 2px;
  }

  .twitter-post-body {
    padding-left: 45px;
  }

  .twitter-post-reply-to {
    line-height: 20px;
    font-size: 14px;
    padding-bottom: 8px;
    padding-top: 4px;
    color: #657786;

    a {
      color: #1b95e0;
    }
  }

  .twitter-post-text {
    margin-bottom: 10px;
    white-space: pre-wrap;
    font-size: 15px;

    span:not(.js-display-url) {
      display: none;
    }
  }

  .twitter-post-link-preview {
    margin-top: 10.4px;
    font-size: 14px;
    border-radius: 10px;
    border: 1px solid rgb(225, 232, 237);
    display: block;
    color: #1c2022;
    margin-bottom: 0.32333em;
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;

    img {
      width: 100%;
      border-top-left-radius: 0.42857em;
      border-top-right-radius: 0.42857em;
    }
  }

  .twitter-post-link-preview:hover {
    color: #1c2022;
    background: rgb(245, 248, 250);
    border-color: rgba(136, 153, 166, 0.5);
  }

  .twitter-post-link-preview-title {
    color: #232528;
    font-size: 1em;
    margin: 0 0 0.15em;
    font-weight: bold;
  }

  .twitter-post-link-preview-description {
    color: #6c7d8b;
    max-height: 2.6em;
    overflow: hidden;
    margin-top: 0.32333em;
  }

  .twitter-post-link-preview-hostname {
    text-transform: lowercase;
    color: rgb(136, 153, 166);
    max-height: 1.3em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 0.32333em;
  }

  .twitter-post-link-preview-footer {
    padding: 0.75em;
  }

  .twitter-post-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 425px;
    margin-top: 8px;
    padding-left: 45px;
    color: rgb(105, 120, 130);
  }

  .twitter-post-footer-item {
    margin-right: 14px;
    font-weight: 300;
    i.ssi-retweet-new {
      font-size: 20px;
    }
    .twitter-analytics {
      position: relative;
      display: block;
      height: 14px;
      width: 1px;
      background-color: #687987;

      &::before,
      &::after {
        position: absolute;
        width: 1px;
        content: '';
        background-color: #687987;
        bottom: 0;
      }
      &:before {
        right: 4px;
        height: 8px;
      }
      &:after {
        left: 4px;
        height: 11px;
      }
    }
  }

  .twitter-post-images,
  .twitter-post-video {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
  }

  .twitter-post-images.single-image {
    img {
      width: 100%;
      border-radius: 5px;
    }
  }

  .twitter-post-images.two-images {
    display: flex;

    img {
      width: 100%;
    }

    .twitter-post-images-left {
      margin-right: 1px;
    }

    .twitter-post-images-left img {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    .twitter-post-images-right img {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  .twitter-post-images.three-images,
  .twitter-post-images.four-images {
    display: flex;

    img {
      width: 100%;
    }

    .twitter-post-images-left {
      width: 66%;
      margin-right: 1px;

      img {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        height: 100%;
      }
    }

    .twitter-post-images-right {
      flex: 1;
      display: flex;
      flex-direction: column;

      img {
        flex: 1;
      }

      img:first-child {
        margin-bottom: 1px;
        border-top-right-radius: 5px;
      }

      img:nth-child(2) {
        border-bottom-right-radius: 5px;
      }
    }
  }

  .twitter-post-images.four-images {
    .twitter-post-images-left {
      width: 75%;
    }
    .twitter-post-images-right {
      img:nth-child(2) {
        border-bottom-right-radius: 0px;
        margin-bottom: 1px;
      }
      img:nth-child(3) {
        border-bottom-right-radius: 5px;
      }
    }
  }

  .twitter-post-video {
    video {
      border-radius: 5px;
      width: 100%;
      height: 100%;
    }
  }
}
