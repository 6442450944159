@import "./src/apps/angularjs/common/sass/global-variables.scss";

$unit: 2.5px;

$columnWidth: ($unit * 52);
$numberOfColumns: 5;

ssi-live-chat-team {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;

  * {
    display: flex;
  }

  & > ul {
    align-items: flex-start;
    flex-flow: row wrap;
    justify-content: flex-start;
    list-style: none;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    width: $columnWidth * $numberOfColumns;

    & > li {
      flex-flow: column nowrap;
      justify-content: flex-start;
      margin: 20px 0;
      min-height: ($unit * 64);
      padding: 0;
      width: $columnWidth;
    }
  }
}
