@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-theme-detector-create-edit {
  .theme-detector-create-edit-container {
    .section-head {
      padding: 0;
      margin-left: 20px;
      .title {
        display: flex;
        align-items: center;
        // i {
        //   font-size: 20px;
        //   color: #838eab;
        //   margin-right: 10px;
        // }
        h2 {
          color: #43537f;
          font-size: 20px;
          font-weight: 900;
          line-height: 40px;
          margin: 0;
        }
      }
    }

    .section-body {
      display: flex;
      align-items: center;
      justify-content: space-between;

      form.create-edit-form {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;

        .form-field-group {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 274px;
          margin-top: 40px;
          margin-left: 40px;

          &.double-length {
            width: 590px;
          }

          .form-field {
            display: flex;
            flex-direction: column;
            flex-basis: 100%;
            // &:nth-child(2) {
            //   margin-left: 20px;
            // }

            &.half {
              flex-basis: 48%;
            }

            > label {
              color: $lmode-body-text;
              font-size: 10px;
              font-weight: 900;
              letter-spacing: 1px;
              text-transform: uppercase;
              margin-left: 20px;
              margin-bottom: 15px;
              min-height: 14px;
            }

            .input-wrapper {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;

              ssi-dropdown-select-2 {
                width: 100%;
                button.dd-head {
                  width: 100%;
                  border-radius: 10px;
                }
              }

              .select-placeholder {
                color: $lmode-helper-text;
                font-size: 14px;
                font-weight: 700;
              }

              .select-label {
                color: $lmode-title-text;
                font-size: 14px;
                font-weight: 900;
                display: flex;
                align-items: center;
                max-width: 200px;
                overflow: hidden;

                span {
                  white-space: nowrap;
                }

                ssi-social-network-icon {
                  margin-right: 8px;
                }
              }

              .text-input {
                width: 100%;
                height: 46px;
                border: none;
                outline: none;
                border-radius: 10px;
                display: flex;
                align-items: center;
                color: #43537f;
                font-family: Lato, sans-serif;
                font-size: 14px;
                font-weight: 900;
                background-color: $grey-background;
                padding: 10px 30px 10px 20px;

                &::placeholder {
                  color: $lmode-helper-text;
                  font-size: 13px;
                  font-weight: 700;
                }
              }
            }
          }
        }

        @media print {
          .submit-button {
            display: none !important;
          }
        }
      }
    }

    .section-foot {
      display: flex;
      align-items: center;
      padding-top: 40px;

      .buttons {
        width: 100%;
        margin-left: 40px;
        margin-right: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .delete-button {
          margin-right: auto;
          i {
            font-weight: 500;
          }
        }

        .cancel-button {
        }

        .submit-button {
          margin-left: 10px;
        }
      }
    }
  }
}
