.post-box-container {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #b4bbce;
  border-radius: 10px;
  overflow: hidden;
  margin: 1px;
  cursor: pointer;
  width: 100%;
}
.post-box-container.post-box-container-modal-inner .content .text-content {
  padding-right: 75px;
}
.post-box-container .top-info {
  display: flex;
  align-items: center;
  height: 35px;
  font-weight: bold;
  font-size: 12px;
  padding: 2px 7px;
  color: #101525;
}
.post-box-container .content {
  position: relative;
  min-height: 60px;
  padding: 7px 4px;
  border-top: 1px solid #b4bbce;
  border-bottom: 1px solid #b4bbce;
  max-height: 160px;
}
.post-box-container .content .network-name {
  display: flex;
  align-items: center;
}
.post-box-container .content .network-name h5 {
  margin: 0 0 5px 4px;
  font-weight: 900;
  font-size: 12px;
  color: #101525;
}
.post-box-container .content .text-content {
  padding: 3px 5px;
}
.post-box-container .content .post-video {
  width: 100%;
  padding-bottom: 60%;
  background-color: #e7ecfc;
  border-radius: 5px;
  position: relative;
}
.post-box-container .content .post-video i {
  position: absolute;
  font-size: 2em;
  margin: 0 auto;
  width: 25px;
  display: block;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #14bae3;
}
.post-box-container .content .text {
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: #101525;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.post-box-container .media-preview {
  margin: 7px 0;
}
.post-box-container .media-preview img {
  height: 82px;
  width: 100%;
  border-radius: 5px;
  object-fit: cover;
}
.post-box-container .top-info,
.post-box-container .bottom-actions {
  background-color: #f8f9fd;
}
.post-box-container .bottom-actions {
  width: 100%;
  background-color: #f8f9fd;
  height: 36px;
  overflow: hidden;
}
.post-box-container .bottom-actions .bottom-actions-wrap {
  position: relative;
  display: block;
  height: 100%;
  min-height: 36px;
  width: 100%;
  font-size: 0;
  padding: 0 2px;
}
.post-box-container .bottom-actions .bottom-actions-wrap-icons, .post-box-container .bottom-actions .bottom-actions-wrap-labels {
  position: absolute;
  display: flex;
  height: 26px;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: -1;
}
.post-box-container .bottom-actions .bottom-actions-wrap-icons-show, .post-box-container .bottom-actions .bottom-actions-wrap-labels-show {
  z-index: 1;
}
.post-box-container .bottom-actions a,
.post-box-container .bottom-actions button {
  display: inline-block;
  appearance: none;
  border: none;
  background: none;
  border-radius: 5px;
  white-space: nowrap;
  line-height: 12px;
  font-size: 12px;
  font-weight: 900;
  padding: 7px;
  margin: 0 2px;
}
.post-box-container .bottom-actions a span,
.post-box-container .bottom-actions button span {
  line-height: 1;
}
.post-box-container .bottom-actions a:hover, .post-box-container .bottom-actions a:focus,
.post-box-container .bottom-actions button:hover,
.post-box-container .bottom-actions button:focus {
  text-decoration: none;
}
.post-box-container .bottom-actions .live-post {
  color: #fff;
  background-color: #b2c614;
}
.post-box-container .bottom-actions .approval-post {
  color: #fff;
  background-color: #75ddca;
}
.post-box-container .bottom-actions .scheduled-post {
  color: #fff;
  background-color: #8da4e5;
}
.post-box-container .bottom-actions .failed-post {
  color: #fff;
  background-color: #e34916;
}
.post-box-container .bottom-actions .deleted-post {
  color: #e34916;
  background-color: #fff1ed;
}
.post-box-container .bottom-actions .thread-post {
  color: #fff;
  background-color: #14bae3;
}
.post-box-container .bottom-actions .create-thread-post {
  color: #fff;
  background-color: #14bae3;
}
.post-box-container .bottom-actions .draft-post {
  color: #fff;
  background-color: #838eab;
}
.post-box-container.failed-post {
  border-color: #e34916;
}
.post-box-container.failed-post .top-info,
.post-box-container.failed-post .bottom-actions {
  background-color: #f9dbd1;
}
.post-box-container.failed-post .content {
  border-color: #e34916;
  background-color: #fcece7;
}
.post-box-container.deleted-post {
  border-color: #e34916;
}
.post-box-container.deleted-post .content {
  border-color: #e34916;
}
.post-box-container ssi-context-menu .context-menu-container {
  border-radius: 10px;
}
.post-box-container .post-box-gallery-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 43px;
  margin: auto;
  right: 4px;
  display: flex;
  align-items: center;
  flex: 0 0 auto;
}
.post-box-container .post-box-gallery-prev img {
  display: block;
  border-radius: 5px;
  overflow: hidden;
  background-color: #eee;
  width: auto;
  width: 60px;
  height: 43px;
  object-fit: cover;
  cursor: pointer;
}
.post-box-container .post-box-gallery-prev img + img {
  position: absolute;
  top: 6px;
  left: 3px;
  box-shadow: 0 5px 15px rgba(116, 118, 132, 0.25);
}
.post-box-container .post-box-gallery-prev-multiple {
  margin-right: 4px;
  padding: 6px 0 0;
}
.post-box-container .post-box-gallery-prev-multiple img {
  z-index: 2;
}
.post-box-container .post-box-gallery-prev-multiple img + img {
  z-index: 1;
  margin-left: -8px;
}
.post-box-container .post-box-gallery-badge-prev {
  background-color: #fff;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -6px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 18px;
  width: 18px;
  font-size: 10px;
  font-weight: 900;
  text-rendering: geometricPrecision;
  border-radius: 50%;
  box-shadow: 0 5px 15px rgba(116, 118, 132, 0.25);
  z-index: 3;
}

.post-box-scroll {
  visibility: hidden;
  position: absolute;
  top: calc(-68px - 100px);
}

.day-view {
  position: relative;
  border: 1px solid #838eab;
  border-radius: 5px;
  width: fit-content;
  cursor: pointer;
  max-width: 100%;
}
.day-view ssi-social-network-icon {
  height: 26px;
}
.day-view .content {
  display: flex;
  align-items: center;
  padding: 5px;
}
.day-view .content i {
  margin-left: 5px;
}
.day-view .time-info {
  margin-left: 7px;
  font-size: 10px;
  font-weight: 900;
  color: #101525;
  line-height: 10px;
}
.day-view .text {
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 7px;
  font-weight: bold;
  font-size: 12px;
  color: #101525;
}
.day-view.live-post {
  border-color: #b2c614;
  background-color: rgba(178, 198, 20, 0.3);
}
.day-view.approval-post {
  border-color: #75ddca;
  background-color: rgba(117, 221, 202, 0.3);
}
.day-view.scheduled-post {
  border-color: #8da4e5;
  background-color: rgba(141, 164, 229, 0.3);
}
.day-view.failed-post {
  background-color: rgba(227, 73, 22, 0.3);
}
.day-view.deleted-post {
  border-color: #e34916;
  background-color: rgba(227, 73, 22, 0.3);
}
.day-view.thread-post {
  background-color: #14bae3;
}
.day-view.create-thread-post {
  background-color: #14bae3;
}
.day-view.draft-post {
  border-color: #b4bbce;
  background-color: rgba(180, 187, 206, 0.3);
}

.day-view-mobile .content {
  display: flex;
  justify-content: space-between;
}

.month-view ssi-social-network-icon {
  height: 20px;
}
.month-view .content {
  display: flex;
  align-items: center;
  border: 1px solid #838eab;
  border-radius: 5px;
  width: fit-content;
  padding: 3px 4px 3px 2px;
}
.month-view .content.failed-post {
  border-color: #e34916;
  background-color: rgba(227, 73, 22, 0.3);
}
.month-view .post-counter {
  margin-left: 3px;
  font-weight: bold;
  font-size: 12px;
  color: #101525;
  padding: 0 2px;
}

.post-box-preview-window {
  background-color: #fff;
  border: 1px solid rgba(131, 142, 171, 0.35);
  border-radius: 15px;
}