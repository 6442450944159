ssi-triggers-range-selector nouislider {
  display: block;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
ssi-triggers-range-selector *:focus {
  outline: none;
}
ssi-triggers-range-selector .noUi-horizontal {
  height: 10px;
  border: 0;
  background-color: #f0f2f3;
}
ssi-triggers-range-selector .noUi-horizontal .noUi-tooltip {
  border: 0;
  background: none;
  bottom: 10%;
  color: white;
  font-size: 11px;
  font-style: italic;
  font-weight: normal;
}
ssi-triggers-range-selector .noUi-horizontal .noUi-handle {
  border-radius: 20px;
  width: 28px;
  border: 0;
  background-color: #14bae3;
  box-shadow: none;
  top: -8px;
}
ssi-triggers-range-selector .noUi-connect {
  background-color: #d2e2eb;
  box-shadow: none;
}
ssi-triggers-range-selector .noUi-handle:after,
ssi-triggers-range-selector .noUi-handle:before {
  content: normal;
}