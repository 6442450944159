ssi-live-chat-message-tags {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.175);
  display: flex;
  position: absolute;
  right: 12px;
  top: 64px;
}
ssi-live-chat-message-tags > * {
  display: flex;
}
ssi-live-chat-message-tags > .container {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  max-height: 40vh;
  min-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px;
  width: 200px;
}
ssi-live-chat-message-tags > .container .tags-filter {
  border: none;
  border-bottom: solid 1px #b8ccd9;
  color: #b8ccd9;
  flex-grow: 0;
  flex-shrink: 0;
  font-style: italic;
  margin: 10px 10px;
  outline: none;
  text-transform: lowercase;
}
ssi-live-chat-message-tags > .container .tags-filter::placeholder {
  color: #b8ccd9;
}
ssi-live-chat-message-tags > .container ul {
  flex-flow: column nowrap;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  padding: 0;
}
ssi-live-chat-message-tags > .container ul.active-tags > li {
  background-color: #b8ccd9;
  color: #fff;
  margin: 2.5px 2.5px 2.5px 0;
  padding: 5px 10px;
}
ssi-live-chat-message-tags > .container ul.active-tags > li .text {
  margin-right: 5px;
}
ssi-live-chat-message-tags > .container ul > li {
  align-items: center;
  border-radius: 10px;
  color: #b8ccd9;
  cursor: pointer;
  flex-shrink: 0;
  font-style: italic;
  font-weight: 400;
  justify-content: space-between;
  padding: 5px 10px;
}
ssi-live-chat-message-tags > .container ul > li.selected, ssi-live-chat-message-tags > .container ul > li:hover {
  background-color: #b8ccd9;
  color: #fff;
}