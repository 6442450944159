ssi-cl-button .cl-button-container .body {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
ssi-cl-button .cl-button-container .body > * {
  margin: 10px;
  margin-bottom: 30px;
  flex: 0 0 260px;
}
ssi-cl-button .cl-button-container .body > * a {
  font-size: 10px;
}
ssi-cl-button .cl-button-container .body > * p {
  font-size: 10px;
  margin: 0;
}
ssi-cl-button .cl-button-container .body > * p:last-of-type {
  margin-bottom: 20px;
}