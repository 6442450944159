@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '../../../../../angularjs/common/sass/include-media';
@import '../../../../common/styles/colors.scss';

$calendar-header-height: 94px;
$calendar-container-padding: 34px;
$calendar-body-margin: 19px;
$mobile-time-label-width: 4.6rem;
$time-label-width: 5.3rem;
$day-row-color: #fff;
$day-alt-row-color: #f9f9fc;

@mixin add-icon($size, $left) {
  font-size: 1.2em;
  display: block;
  position: absolute;
  margin: auto;
  left: $left;
  top: 0;
  bottom: 0;
  color: #14bae3;
  text-align: center;
  vertical-align: middle;
  line-height: calc($size - 0.2rem);
  border: 0.1rem solid #14bae3;
  background-color: #fff;
  border-radius: 50%;
  height: $size;
  width: $size;
  display: inline-block;
  z-index: 2;
  cursor: pointer;

  &:after {
    content: '\f1db';
    font-family: Socialsignin;
    color: inherit;
  }
}

@mixin add-icon-bottom-right($size, $bottom) {
  font-size: 0.857em;
  display: block;
  position: absolute;
  margin: auto;
  bottom: $bottom;
  right: 5px;
  color: #14bae3;
  text-align: center;
  vertical-align: middle;
  line-height: calc($size - 0.2rem);
  border: 0.1rem solid #14bae3;
  background-color: #fff;
  border-radius: 50%;
  height: $size;
  width: $size;
  display: inline-block;
  z-index: 2;
  cursor: pointer;

  &:after {
    content: '\f1db';
    font-family: Socialsignin;
    color: inherit;
  }
}

ssi-content-calendar {
  overflow: hidden;
  width: 100%;

  .content-calendar-container {
    width: 100vw;
    padding: $calendar-container-padding calc($calendar-body-margin + 15px) 0;
    background-color: #f4f4fa;
    overflow-y: auto;
    overflow-x: hidden;

    &.month-view {
      height: calc(100vh - 68px);
    }

    &.content-calendar-container-mobile {
      padding: 0;
      background-color: #fff;

      .content-calendar-header {
        padding: $calendar-container-padding 0 0;
      }

      .post-box-scroll {
        top: calc(-#{$navbar-height} - 85px);
      }
    }

    .cal-hour-segment {
      position: relative;

      .empty-cell {
        display: none;
        // cursor: pointer;
      }

      .hidden-events-btn-container {
        position: absolute;
        bottom: 1px;
        left: 1px;
        padding: 5px;

        .show-more-btn {
          color: #14bae3;
          font-weight: 900;
          background-color: transparent;
          border: none;
        }

        &.mobile {
          left: 45px;
          .show-more-btn {
            font-size: 12px;
            i {
              margin-left: 3px;
              font-size: 12px;
            }
          }
        }
      }

      // &:hover {
      //   .empty-cell {
      //     position: absolute;
      //     display: flex;
      //     align-self: center;
      //     justify-content: center;
      //   }
      // }
    }
  }

  .calendar-scroll-top {
    visibility: hidden;
    position: absolute;
    top: calc(-#{$navbar-height} - 100px);
  }

  .content-calendar-header {
    display: block;
    height: $calendar-header-height;
    position: fixed;
    width: 100%;
    left: 0;
    padding: $calendar-container-padding $calendar-container-padding 0;
    margin: -$calendar-container-padding 0 0;
    z-index: 999;
    background-color: #f4f4fa;
  }

  .campaigns-and-events-box {
    display: flex;
    width: 100%;
    text-align: left;
    flex-wrap: wrap;
    padding: 1px;

    .single-item {
      display: flex;
      align-items: center;
      color: $lmode-body-text;
      border: 1px solid #f4bf41;
      border-radius: 5px;
      padding: 4px 7px;
      background-color: #fef9f0;
      width: 100%;
      margin-top: 1px;

      &.spans-next-day {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &.spans-previous-day {
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      span {
        font-size: 12px;
        font-weight: 900;
        opacity: 1;
        max-width: 160px;

        overflow: hidden;
        display: inline-block;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;

        // handling different screens for event/campaign names to fix and not to break the table
        @include media('<1500px') {
          max-width: 120px;
        }
        @include media('<portal') {
          max-width: 95px;
        }
        @include media('<desktop') {
          max-width: 68px;
        }
        @include media('<850px') {
          max-width: 58px;
        }
      }
      i {
        color: #f4bf41;
        margin-right: 8px;
      }

      &.custom-event {
        border-color: #3463f2;
        background-color: #eaeffe;
        i {
          color: #3463f2;
        }
      }
    }

    > button {
      color: $primary-active;
      font-weight: 900;
      background-color: transparent;
      border: none;
      margin: 5px 0;

      i {
        font-size: 14px;
        margin-left: 3px;
      }

      &.close-collapse {
        i {
          transform: rotate(180deg);
          margin-right: 5px;
        }
      }
    }

    &.above-table {
      .single-item {
        width: auto;
        margin: 0 0 5px 5px;
      }
    }

    @media (max-width: 768px) {
      padding-top: 20px;
    }
  }

  .content-calendar-header-scroll-margin {
    margin: calc(
        #{$calendar-header-height} - #{$calendar-container-padding} + 1px
      )
      0 0; // +1 to prevent rendering issue
  }

  ssi-calendar-day-view {
    font-size: 1rem;
    display: block;

    &.calendar-day-view-mobile {
      margin: 1.1em auto;
      width: calc(100vw - 2em);

      .cal-week-view {
        border-width: 0;

        .cal-time-events {
          border-width: 0;
        }

        .cal-hour:not(:last-child) .cal-hour-segment {
          border-bottom-color: #838eab;
        }

        .cal-hour {
          &:last-child .cal-hour-segment {
            &:hover:before {
              content: '';
              position: absolute;
              bottom: 0;
              right: 0;
              display: block;
              width: calc(100% - $mobile-time-label-width);
              border-bottom: 0.1em dashed #14bae3;
            }
          }

          &:nth-child(3) .cal-hour-segment {
            &:hover:before {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              display: block;
              width: calc(100% - $mobile-time-label-width);
              border-bottom: 0.1em dashed #14bae3;
            }
          }
        }

        .cal-hour-segment {
          &:hover {
            background-color: rgba(20, 186, 227, 0.1) !important;
            border-right: 0.1em dashed #14bae3;
            // cursor: pointer;

            .cal-time .cell-add-icon-left {
              @include add-icon-bottom-right(3.6rem, 3px);
            }

            .cal-time {
              border-right: 0.1em dashed #14bae3;
            }
          }
        }

        .cal-time {
          width: $mobile-time-label-width;
          line-height: 4.2em;
          padding: 0;
          height: 100%;
          background-color: #fff;
          text-align: left;
          color: #43537f;
          font-size: 1em;
          font-weight: 900;
          text-rendering: geometricPrecision;
        }
      }

      .cal-day-view {
        .cal-current-time-marker {
          margin-left: $mobile-time-label-width;
          width: calc(100% - $mobile-time-label-width);
        }

        .cal-events-container {
          margin-left: $mobile-time-label-width;
        }
      }

      .cal-day-view,
      .cal-hour,
      .cal-day-view:nth-child(odd),
      .cal-hour:nth-child(odd) {
        background-color: #fff;
      }

      .cal-time-date {
        display: none;
        // display: inline-block;
        // text-align: center;
        // color: #101525;
        // text-transform: uppercase;
        // font-weight: 900;
        // text-rendering: geometricPrecision;
        // margin-top: 0.2rem;

        // span {
        //   font-size: 1.6em;
        //   display: block;
        //   margin: 0.4rem 0 0;
        // }

        // & ~ * {
        //   display: none;
        // }
      }

      .post-box-container {
        width: 300px;
        max-width: 100%;

        .text-content {
          padding-right: 75px;
        }
      }
    }

    &.calendar-day-view {
      margin: 1em 0;
      border: 0.1em solid #b4bbce;
      overflow: hidden;
      border-radius: 1em;

      .cal-week-view {
        border-width: 0;

        .cal-time-events {
          border-width: 0;
        }

        .cal-hour {
          background-color: $day-row-color;

          .cal-time {
            background-color: $day-row-color;
          }

          &:nth-child(2n + 1) {
            background-color: $day-alt-row-color;

            .cal-time {
              background-color: $day-alt-row-color;
            }
          }

          .cal-hour-segment,
          .cal-hour-segment:last-child {
            border-bottom-color: #838eab;

            &:hover {
              background-color: #e7f8fc !important;
              // cursor: pointer;

              .cell-add-icon-left {
                @include add-icon(2.6rem, 0.4rem);
              }

              .cal-time {
                position: relative;
                z-index: 3;
                border-right: 0.1em solid #14bae3;
                border-bottom: 0.1em;

                .cell-add-icon-left {
                  @include add-icon(2.6rem, calc($time-label-width + 0.3rem));
                }
              }
            }
          }
        }

        .cal-time {
          display: flex;
          align-items: center;
          width: $time-label-width;
          padding: 0 0 0 1.1em;
          height: 100%;
          text-align: left;
          color: #43537f;
          font-size: 1em;
          font-weight: 900;
          text-rendering: geometricPrecision;
        }
      }

      .cal-day-view {
        .cal-current-time-marker {
          margin-left: $mobile-time-label-width;
          width: calc(100% - $mobile-time-label-width);
        }

        .cal-events-container {
          margin-left: calc($time-label-width + 3.6rem);
        }
      }
    }

    ssi-calendar-week-view {
      .cal-hour-segment {
        &:after {
          display: none !important;
        }
      }
    }
  }

  ssi-calendar-week-view {
    .cal-week-view {
      width: 100%;

      .cal-hour-odd {
        background-color: #fff;
      }

      .cal-day-headers {
        margin: 0;
        height: auto;
        border-color: #b4bbce;

        .cal-header {
          position: relative;
          padding: 0;

          .week-head-cell-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 10px;

            span {
              color: #838eab;
              font-size: 10px;
              font-weight: 900;
              text-transform: uppercase;
              opacity: 1;
            }

            b {
              color: #43537f;
              font-size: 20px;
              font-weight: 600;

              &.today {
                color: #fff;
                z-index: 1;
                &::after {
                  content: '';
                  position: absolute;
                  top: 0;
                  right: 5px;
                  height: 30px;
                  width: 35px;
                  background-color: $primary-active;
                  border-bottom-left-radius: 5px;
                  border-bottom-right-radius: 5px;
                  transition: all 0.3s ease-in-out 0s;
                  z-index: -1;
                }
              }
            }
          }

          .week-head-cell-bottom {
            display: flex;
            align-items: center;
            justify-content: start;
            flex-wrap: wrap;

            .posts-count-chip {
              margin-left: 3px;
              margin-bottom: 2px;

              span {
                padding: 1px 4px;
                border: 1px solid #b4bbce;
                border-radius: 2px;
                opacity: 1;
                font-size: 10px;
                font-weight: 900;
                color: #838eab;
              }

              &.has-posts {
                span {
                  background-color: #14bae3;
                  border: none;
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }

    .cal-time-events {
      border-color: #b4bbce !important;

      .cal-time-label-column {
        .cal-hour {
          ssi-calendar-week-view-hour-segment {
            .cal-hour-start {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 10px;
              font-weight: 900;
              color: #43537f;
              border-bottom-color: #b4bbce;

              .cal-time {
                padding: 0;
              }
            }
          }
        }
      }

      .cal-day-columns {
        .cal-day-column {
          border-left-color: #b4bbce;
          .cal-hour {
            ssi-calendar-week-view-hour-segment {
              .cal-hour-segment {
                border-bottom-color: #b4bbce;

                &:hover {
                  outline: 1px solid #14bae3;
                  outline-offset: -1px;
                  background-color: rgba(20, 186, 227, 0.1);

                  .cell-add-icon {
                    @include add-icon-bottom-right(3.6rem, 3px);
                  }
                }
              }
            }
          }

          .cal-hour-odd {
            background-color: #fff;
          }
        }
      }
    }
  }

  ssi-calendar-month-view {
    .cal-month-view {
      border-radius: 10px;

      .cal-days {
        border: 1px solid #b4bbce;
        border-radius: 10px;

        .cal-cell-row {
          border-bottom: 1px solid #b4bbce;

          &.cal-cell-row-last {
            border-bottom: none;
          }

          ssi-calendar-month-cell {
            min-height: 120px;

            &.cal-cell {
              background-color: #fff;

              &.cal-out-month {
                background-color: #f8f9fd;
              }

              &:hover {
                background-color: #fff;
              }
            }

            &.cal-day-cell {
              &.cal-today {
                .day-cell-inner-wrapper {
                  border: 1px solid #14bae3;
                }
              }
              &:not(:last-child) {
                border-right: 1px solid #b4bbce;
              }
              &:first-child {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
              }
              &:last-child {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
              }
            }

            .day-cell-inner-wrapper {
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: stretch;
              position: relative;
              // cursor: pointer;

              &:hover {
                outline: 1px solid #14bae3;
                outline-offset: -1px;
                background-color: rgba(20, 186, 227, 0.1);

                .cell-add-icon {
                  @include add-icon-bottom-right(3.6rem, 5px);
                }
              }

              .cell-top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 8px;

                .day-name-wrapper {
                  .day-name {
                    color: #838eab;
                    font-size: 10px;
                    font-weight: 900;
                    text-transform: uppercase;
                  }
                }

                .day-number-wrapper {
                  &.today {
                    background-color: #14bae3;
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                    padding: 0 5px;
                    .day-number {
                      color: #fff;
                    }
                  }
                  &.out-of-month {
                    .day-number {
                      color: #b4bbce;
                    }
                  }

                  .day-number {
                    color: #43537f;
                    font-size: 20px;
                    font-weight: 600;
                    opacity: 1;
                  }
                }
              }

              .cal-events {
                // align-items: flex-start;
                padding: 5px 10px 5px 20px;
                margin: 0;

                .post-chip {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border: 1px solid #838eab;
                  border-radius: 5px;
                  width: 46px;
                  height: 26px;
                  margin-right: 2px;
                  margin-bottom: 2px;

                  .post-count {
                    margin-left: 4px;
                    color: #101525;
                    font-size: 12px;
                    font-weight: 700;
                  }
                }
              }

              .cell-bottom {
                display: flex;
                align-items: center;
                justify-content: start;

                .posts-count-chip {
                  margin-left: 3px;

                  span {
                    padding: 1px 4px;
                    border: 1px solid #b4bbce;
                    border-radius: 2px;
                    opacity: 1;
                    font-size: 10px;
                    font-weight: 900;
                    color: #838eab;
                  }

                  &.has-posts {
                    span {
                      background-color: #14bae3;
                      border: none;
                      color: #fff;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .cal-header {
        .cal-cell {
          background-color: #f4f4fa;
        }
      }
    }
  }

  ssi-context-menu {
    .context-menu-container {
      // border: 1px solid #B4BBCE;
      border-radius: 10px;
      box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.35);

      .posts-preview-menu {
        background-color: #fff;
        border: 1px solid rgba(131, 142, 171, 0.35);
        border-radius: 10px;
        padding: 2px 2px 2px 2px;
        width: 300px;

        ssi-post-box {
          margin-bottom: 2px;
        }
      }
    }
  }

  .cell-add-icon,
  .cell-add-icon-left {
    display: none;
  }
}
