@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-engagement ssi-average-response-time {
  font-size: 1rem;

  .engagement-times-section {
    ssi-highchart {
      width: 100%;
      height: 34em;

      highcharts-chart {
        .highcharts-container,
        .highcharts-root {
          overflow: visible !important;
        }

        .highcharts-axis-title {
          font-size: 1em;
          font-weight: 900;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          white-space: nowrap;
        }

        .highcharts-axis-labels {
          text {
            font-weight: 900;
          }
        }

        .highcharts-xaxis-labels {
          transform: translate(0, 1em);
        }
      }
    }
  }

  .section-chart-wrap {
    padding: 3em 3em 6em 3em;
    background-color: #fff;
    border-radius: 1.2em;
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
    margin: 0 0 2em;

    @media print {
      page-break-inside: avoid;
    }
  }

  .top-row {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 3em;

    .best-hours-container {
      display: flex;
      border-radius: 0.5em;
      border: 0.1em solid $secondary-red;
      padding: 2em;

      .left {
        margin-right: 8em;

        span {
          color: $lmode-helper-text;
          font-size: 1em;
          font-weight: 900;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          text-rendering: geometricPrecision;
        }
      }

      .middle {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        span {
          color: $lmode-helper-text;
          font-size: 1.4em;
          font-weight: 700;
          text-rendering: geometricPrecision;
        }

        .hours {
          span {
            color: $lmode-title-text;
            font-size: 2em;
            font-weight: 900;
          }
        }
      }

      .right {
        display: flex;
        align-items: center;
        margin-left: 2em;

        i {
          font-size: 3.2em;
          color: #ffe8df;
        }
      }
    }
  }

  .chart-legend {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    margin-top: 3em;

    .legend-item {
      display: flex;
      align-items: center;
      margin-right: 6em;

      &:first-child {
        margin-right: 3em;
      }

      span {
        color: $lmode-body-text;
        font-size: 1em;
        font-weight: 900;
        letter-spacing: 0.1em;
      }

      .circle {
        height: 1.8em;
        width: 1.8em;
        border-radius: 50%;
        background-color: #000;
        margin-right: 1em;
        -webkit-print-color-adjust: exact;
      }

      &.great {
        .circle {
          background-color: $secondary-red !important;
        }
      }
      &.good {
        .circle {
          background-color: #ffbfa9 !important;
        }
      }
      &.okay {
        .circle {
          background-color: #fee9e2 !important;
        }
      }
      &.not-great {
        .circle {
          background-color: #b2f0ff !important;
        }
      }
      &.not-good {
        .circle {
          background-color: #2bc1e6 !important;
        }
      }
    }
  }

  .art-tile {
    border-radius: 1.2em;
    background-color: #fff;
    box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
    padding: 2.6em 2.6em 2.4em 3em;
    margin: 0 0 2em;
    color: $lmode-body-text;

    h2,
    h3,
    p {
      text-rendering: geometricPrecision;
    }

    &-large {
      padding: 4.5em 9em 5em 7em;
    }

    &-banner {
      position: relative;

      & > div {
        padding-right: 35em;
      }

      h2 {
        font-size: 2em;
        font-weight: 900;
        margin: 0 0 0.8em;
      }

      p {
        font-weight: 700;
        line-height: 1.4em;
        margin: 0 0 0.2em;
      }

      img {
        position: absolute;
        bottom: 0;
        right: 8.7em;
        width: 31.5em;
        max-height: 17.7em;
      }
    }

    &-copy {
      position: relative;
      padding: 2.6em 8em 2.6em 3em;

      h3 {
        color: $lmode-title-text;
        font-size: 1.4em;
        margin: 0 0 0.4em;
        font-weight: 900;
      }

      p {
        color: $lmode-helper-text;
        margin: 0;
        line-height: 1.4em;
        font-weight: 700;
      }

      &-icon {
        position: absolute;
        top: 2.6em;
        right: 2.6em;
        height: 3.6em;
        width: 3.6em;
        background-color: $lgrey-background;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        i {
          font-size: 1.8em;
          color: $lmode-body-text;
        }

        &:hover,
        &:focus {
          background-color: $primary-active;
          cursor: pointer;

          i {
            color: #fff;
          }
        }
      }
    }
  }
}
