@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../../../angularjs/common/sass/variables';
@import '~/styles/colors';

ssi-create-edit-report {
  $profile-grid-gutter: 5px;
  $header-height: 94px;

  .content-wrapper {
    padding: 60px 0 0;
    max-width: 1294px !important;

    .form-group {
      padding: 0;
    }
  }

  .heading-container {
    position: fixed;
    top: $navbar-height;
    width: 100%;
    height: $header-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $lgrey-background;
    padding: 24px 45px;
    box-shadow: 0 5px 10px rgba(131, 142, 171, 0.25);
    z-index: 1;

    orlo-button {
      margin: 0 0 0 20px;
      height: 46px;

      .ssi-line-arrow-small-down {
        transform: rotate(90deg);
        margin-right: 5px;
      }

      button {
        height: inherit;
      }
    }

    h1 {
      line-height: 1;
      margin: 10px 0;
      font-weight: 900;
      text-rendering: geometricPrecision;
    }
  }

  .content-form {
    padding: 60px 70px 90px;
    margin: $header-height 15px 0;
    background-color: $lgrey-background;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    position: relative;
  }

  .delete {
    position: absolute;
    right: 2em;
    top: 2em;
    background-color: white;
    padding: 1em;
    border-radius: 100%;
    color: $lmode-helper-text;
    line-height: 0;
    cursor: pointer;
  }

  orlo-text-input {
    label {
      margin: 8px 0;
      padding: 0;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $lmode-body-text;
      font-size: 10px;
      font-weight: 900;
      text-rendering: geometricPrecision;
    }

    input {
      height: 46px;
      color: $lmode-title-text !important;
      background-color: #fff !important;
      font-size: 14px !important;
      text-rendering: geometricPrecision;
      color: $lmode-helper-text;

      &:not(:focus) {
        border-color: #fff !important;
      }

      &.invalid {
        border-color: $secondary-red !important;
      }

      &::placeholder {
        color: $lmode-helper-text;
        font-weight: 700 !important;
      }

      &:focus {
        font-weight: 600 !important;
      }
    }

    span {
      text-align: left !important;
    }
  }

  .input-container {
    position: relative;

    &:first-child {
      margin-right: 30px !important;
      flex: 1 1 40% !important;
    }

    &:last-child {
      flex: 1 1 50% !important;
    }
  }

  .input-counter {
    position: absolute;
    bottom: 0;
    right: 8px;
    color: $lmode-body-text;
    font-size: 10px;
    font-weight: 900;
    text-rendering: geometricPrecision;

    &.error {
      color: $secondary-red;
    }
  }

  .form-section {
    margin: 0 0 60px;
  }

  .section-title {
    margin: 18px 0 18px 28px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $lmode-body-text;
    font-size: 10px;
    font-weight: 900;
    text-rendering: geometricPrecision;
  }

  .profile-grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 (-$profile-grid-gutter);
  }

  .profile-grid-item {
    flex: 0 1 25%;
    width: 25%;
  }

  .profile {
    display: flex;
    flex: 1 0 auto;
    background-color: #fff;
    align-items: center;
    width: calc(100% - #{$profile-grid-gutter} * 2);
    border-radius: 100px;
    padding: 3px;
    border: 2px solid #fff;
    margin: 0 $profile-grid-gutter 12px;
    color: $lmode-body-text;

    &.focus {
      background-color: #213ed9;
      border-color: #213ed9;
      .profile-avatar {
        background: #425dec !important;
        .ssi-target {
          color: white;
          opacity: 1;
        }
      }
    }

    &-included {
      border-color: $primary-active;
      background-color: $primary-active;

      .profile-meta {
        color: #fff;
        pointer-events: none;
      }

      .profile-action {
        background-color: #fff;
        transform: rotate(45deg);
      }
    }

    &-selected {
      border-color: $primary-active;

      .profile-action {
        background-color: $primary-active;
        color: #fff;
        font-size: 14px;
        cursor: auto;
      }
    }

    &-empty {
      text-align: center;

      p {
        width: 100%;
        margin: 0 10% 0 0;
        color: $lmode-helper-text;
        font-size: 10px;
        font-weight: 900;
        letter-spacing: 1px;
        text-transform: uppercase;
        text-rendering: geometricPrecision;
      }
    }
  }

  .profile-meta {
    flex: 1 1 auto;
    margin: 0 14px;
    overflow: hidden;

    p {
      margin: 0;
      font-weight: 900;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-rendering: geometricPrecision;
    }

    ul {
      margin: 0;
      padding: 0;
    }

    li {
      display: inline-block;
      margin: 0 10px 0 0;
      font-size: 12px;
    }
  }

  .profile-avatar,
  .profile-action {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 46px;
    width: 46px;
    height: 46px;
    background-color: $lgrey-background;
    border-radius: 50%;
  }

  .profile-avatar {
    background-position: 50%;
    cursor: pointer;
    .ssi {
      color: $lmode-helper-text;
      font-size: 18px;
      line-height: 1;
    }
    .ssi-target {
      opacity: 0;
    }
  }

  .profile-action {
    color: $primary-active;
    background-color: $lgrey-background;
    font-size: 12px;
    cursor: pointer;
  }

  .limit-info {
    width: 100%;
    margin: 15px $profile-grid-gutter -30px ($profile-grid-gutter + 10px);

    h4 {
      max-width: 400px;
      color: $lmode-body-text;
      font-size: 14px;
      line-height: 20px;
      font-weight: 700;
      text-rendering: geometricPrecision;
    }
  }
}
