@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-analytics ssi-marketing ssi-report {
  .marketing-analytics-report-container {
    width: 100%;
    height: 100%;

    $headerHeight: 94px;
    $sidebarWidth: 228px;

    .report-body {
      width: 100%;
      height: 100%;
      padding-top: $headerHeight + 40px;
      padding-left: $sidebarWidth + 40px;
      padding-right: 40px;
      min-height: calc(100vh - 68px);

      .account-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .account-list-item {
          display: flex;
          align-items: center;
          background-color: #ebebf4;
          border-radius: 10px;
          padding: 10px;
          margin: 5px;
          i {
            color: $lmode-helper-text;
          }
          span {
            color: $lmode-body-text;
            font-size: 12px;
            font-weight: 900;
            line-height: 14px;
            margin-left: 10px;
          }
        }
      }

      .section-delimiter {
        width: 100%;
        height: 2px;
        background-color: #fff;
        margin-top: 40px;
        margin-bottom: 40px;
      }

      .section-head,
      .section-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
        h2,
        h3 {
          color: $lmode-title-text;
          font-size: 20px;
          font-weight: 900;
        }
      }
    }

    .report-body-loading {
      width: 100%;
      height: 100%;
      height: calc(100vh - 68px);
      padding-top: $headerHeight;
      padding-left: $sidebarWidth;
    }

    .selected-date-ranges {
      border-radius: 10px;
      background-color: #ebebf4;
      padding: 10px;

      span {
        margin-right: 7px;
      }

      .current {
        color: $lmode-title-text;
        font-size: 14px;
        font-weight: 900;
      }
      .previous {
        color: $lmode-title-text;
        font-size: 12px;
        font-style: italic;
        font-weight: 700;
      }
    }
  }
}
