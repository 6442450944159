@keyframes blink-animation {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 0.4;
  }
  66% {
    opacity: 0.8;
  }
}
ssi-live-chat-conversation-indicators {
  display: flex;
  flex-flow: column nowrap;
  flex-shrink: 0;
}
ssi-live-chat-conversation-indicators * {
  display: flex;
}
ssi-live-chat-conversation-indicators > ul {
  flex-shrink: 0;
  list-style: none;
  margin: 40px 20px 0 20px;
  padding: 0;
}
ssi-live-chat-conversation-indicators > ul > li {
  flex-shrink: 0;
  margin: 0;
  position: relative;
}
ssi-live-chat-conversation-indicators > ul > li .indicator.agent-typing .indicator-inner {
  background-color: #fff;
}
ssi-live-chat-conversation-indicators > ul > li .indicator.agent-typing .indicator-inner .indicator-blobs {
  color: #a6b7c0;
  font-size: 2.5em;
  height: auto;
  margin-top: -20px;
}
ssi-live-chat-conversation-indicators > ul > li .indicator.agent-typing .indicator-inner .indicator-text {
  font-style: italic;
}
ssi-live-chat-conversation-indicators > ul > li .indicator.agent-viewing .indicator-inner {
  background-color: #fff;
}
ssi-live-chat-conversation-indicators > ul > li .indicator.visitor-typing .indicator-inner {
  height: 50px;
}
ssi-live-chat-conversation-indicators > ul > li .indicator.visitor-typing .indicator-inner .indicator-blobs {
  font-size: 5em;
  margin: -35px auto 0 auto;
}
ssi-live-chat-conversation-indicators > ul > li .indicator .indicator-inner {
  align-items: center;
  background-color: #d8e1e5;
  border-radius: 40px;
  color: #fff;
  flex-flow: row nowrap;
  flex-grow: 0;
  flex-shrink: 1;
  height: 30px;
  overflow: hidden;
  padding: 5px;
}
ssi-live-chat-conversation-indicators > ul > li .indicator .indicator-inner .indicator-avatar {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}
ssi-live-chat-conversation-indicators > ul > li .indicator .indicator-inner .indicator-blobs {
  flex-grow: 0;
  font-size: 1.5em;
  justify-content: center;
  margin-top: -20px;
  margin-left: 10px;
}
ssi-live-chat-conversation-indicators > ul > li .indicator .indicator-inner .indicator-blobs span {
  animation: blink-animation 1s infinite;
  padding: 0 2.5px;
}
ssi-live-chat-conversation-indicators > ul > li .indicator .indicator-inner .indicator-icon {
  color: #a6b7c0;
  display: flex;
  font-size: 1.25em;
  font-weight: 600;
  margin-left: 10px;
}
ssi-live-chat-conversation-indicators > ul > li .indicator .indicator-inner .indicator-text {
  display: inline;
  font-size: 0.9em;
  font-weight: 600;
  margin-left: 10px;
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
ssi-live-chat-conversation-indicators > ul > li .indicator .indicator-inner .indicator-text * {
  display: inline;
  font-weight: inherit;
}
ssi-live-chat-conversation-indicators .indicator-inner {
  align-items: center;
  background-color: #fff;
  border-radius: 60px;
  font-size: 0.8em;
  font-weight: 400;
  margin: 0 5px;
  min-width: 200px;
  padding: 5px;
}
ssi-live-chat-conversation-indicators .indicator-inner.agent .indicator-icon i {
  color: #d2dbdf;
}
ssi-live-chat-conversation-indicators .indicator-inner.agent .indicator-text {
  color: #1dafec;
}
ssi-live-chat-conversation-indicators .indicator-inner.visitor {
  background: linear-gradient(to right, #D2DBDF 0%, #D8E1E5 100%);
}
ssi-live-chat-conversation-indicators .indicator-inner .indicator-icon {
  flex-grow: 1;
  justify-content: center;
}
ssi-live-chat-conversation-indicators .indicator-inner .indicator-icon i {
  color: #fff;
  font-size: 2em;
  font-weight: 600;
}
ssi-live-chat-conversation-indicators .indicator-inner .indicator-text {
  align-items: center;
  color: #43ade8;
}
ssi-live-chat-conversation-indicators .indicator-inner .indicator-text .indicator-text-state {
  margin: 0 10px 0 2.5px;
}
ssi-live-chat-conversation-indicators .indicator-inner ssi-live-chat-avatar {
  height: 35px;
  width: 35px;
}
ssi-live-chat-conversation-indicators .multiple-agents-container {
  background-color: #fff;
  border-radius: 25px;
  height: 150px;
  left: 5px;
  overflow: none;
  padding: 25px;
  position: absolute;
  top: -155px;
  width: 200px;
}
ssi-live-chat-conversation-indicators .multiple-agents-container .multiple-agents {
  flex-flow: column nowrap;
  flex-grow: 1;
  list-style: none;
  margin: 0;
  overflow-x: none;
  overflow-y: auto;
  padding: 0;
}
ssi-live-chat-conversation-indicators .multiple-agents-container .multiple-agents.typing .indicator-blobs {
  color: #a6b7c0;
  font-size: 2.5em;
  height: auto;
  margin-top: -27.5px;
}
ssi-live-chat-conversation-indicators .multiple-agents-container .multiple-agents.typing .indicator-blobs span {
  animation: blink-animation 1s infinite;
  padding: 0 2.5px;
}
ssi-live-chat-conversation-indicators .multiple-agents-container .multiple-agents.viewing .agent-status-icon {
  color: #43ade8;
  font-weight: 400;
}
ssi-live-chat-conversation-indicators .multiple-agents-container .multiple-agents > li {
  align-items: center;
  flex-basis: 25px;
  flex-flow: row nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}
ssi-live-chat-conversation-indicators .multiple-agents-container .multiple-agents > li .agent-avatar {
  border-radius: 50%;
  height: 20px;
  width: 20px;
}
ssi-live-chat-conversation-indicators .multiple-agents-container .multiple-agents > li .agent-name {
  color: #a6b7c0;
  display: inline;
  flex-grow: 1;
  font-size: 0.75em;
  font-weight: 600;
  overflow: hidden;
  padding-left: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
}