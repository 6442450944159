@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';
@import './../../../../angularjs/common/sass/variables.scss';
@import './../../../../angularjs/common/sass/mixins.scss';

ssi-post-scheduler {
  font-size: 1rem;
  display: block;
  width: 100%;

  .post-sheduler-container {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0 0.5em 1em 0 rgba(131, 142, 171, 0.35);
    width: 34em;
    height: 46em;
    border-radius: 1.5em;
  }

  .post-scheduler-header {
    padding: 4em 4em 3em;

    h3 {
      margin: 0;
      font-size: 1em;
      line-height: 1;
      color: #43537f;
      font-weight: 900;
      letter-spacing: 1px;
      text-transform: uppercase;
      text-rendering: geometricPrecision;
    }
  }

  .post-scheduler-body {
    flex: 1 0 auto;
  }

  $publisher-schedule-calendar-color: #8b8e9d;
  $brand: $primary-active;
  $calendar-title-height: 28px;
  $calendar-font-color: $lmode-body-text;

  .publisher-schedule-date-picker-outer {
    padding: 0 34px;
    margin: 0 auto;
  }

  .publisher-schedule-date-picker-inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    max-height: 300px;
  }

  .publisher-schedule-date-picker-title-wrap {
    max-height: $calendar-title-height;
    margin: 0 6px 8px;
  }

  .publisher-schedule-date-picker-title-nav {
    button {
      border: 0;
      background-color: transparent;

      .point-right {
        transform: rotate(180deg);
      }
    }

    i {
      font-size: 12px;
      color: $brand;
      line-height: $calendar-title-height;
    }
  }

  .publisher-schedule-date-picker-title {
    text-align: center;

    p {
      font-size: 14px;
      font-weight: 900;
      color: #43537f;
      margin: 0;
      line-height: $calendar-title-height;
      text-rendering: geometricPrecision;
    }
  }

  .cal-month-view {
    font-size: 12px;
    color: #43537f;
    display: block;
    margin-bottom: 27px;
    font-weight: 700;
    text-rendering: geometricPrecision;

    .cal-header {
      color: #43537f;
      margin-bottom: 7px;
      font-weight: inherit;

      &:hover,
      &:focus {
        background-color: transparent;
      }

      .cal-cell {
        &:hover,
        &:focus {
          background-color: transparent;
        }
      }
    }

    .cal-days {
      .cal-day-cell,
      .cal-cell-top {
        background-color: transparent;
        border: 0;
        min-height: initial;

        @include ie-only {
          min-height: 35px;
        }
      }

      .cal-day-cell .cal-day-number,
      .cal-day-number {
        display: block;
        float: none;
        text-align: center;
        margin: 5px auto;
        padding: 3px 0px;
        max-width: 26px;
        font-size: 12px;
        line-height: 18px;
        color: inherit;
        opacity: 1;
        font-weight: inherit;
        border-radius: 5px;
        border: 1px solid transparent;
        cursor: pointer;

        text-rendering: geometricPrecision;
      }

      @mixin cal-cell-bg(
        $bg-color,
        $bg-color-hover,
        $text-color: $publisher-schedule-calendar-color
      ) {
        & {
          background-color: transparent;
        }
        &:hover {
          background-color: transparent;
        }
        .cal-day-number {
          color: $text-color !important;
        }
      }

      .cal-cell {
        font-weight: 700;
        margin: 1px;
      }

      .cal-cell:first-child {
        margin-left: 0;
      }

      .cal-cell:last-child {
        margin-right: 0;
      }

      .cal-cell.cal-future {
        &:hover,
        &:focus {
          .cal-day-number {
            background-color: #eceef7;
          }
        }
      }

      .cal-cell.cal-today {
        .cal-day-number {
          border: 1px solid $calendar-font-color;
          color: #43537f;
        }
      }

      .cal-cell.cal-weekend {
        .cal-day-number {
          color: #43537f;
        }
      }

      .cal-cell.disabled,
      .cal-cell.cal-out-month {
        .cal-day-number {
          opacity: 1;
          color: #b4bbce;
        }

        &:hover,
        &:focus {
          .cal-day-number {
            background-color: #f6f7fd;
          }
        }
      }

      .cal-cell.scheduled {
        .cal-day-number {
          background-color: #8ed500;
          color: #fff;
        }

        &:hover,
        &:focus {
          .cal-day-number {
            background-color: #8ed500;
          }
        }
      }

      .cal-cell.active {
        .cal-day-number {
          background-color: $brand;
          color: #fff;
        }

        &:hover,
        &:focus {
          .cal-day-number {
            background-color: $brand;
          }
        }
      }

      &,
      .cal-cell-row,
      .cal-cell {
        border: 0;

        &:hover,
        &:focus {
          background-color: transparent;
        }
      }
    }
  }

  .select-schedule-time-btn {
    position: relative;
    margin-top: -6px; // hack to show proper button height - date picker otherwise eats the height

    i {
      font-size: 10px;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 5px;
      transform: rotate(270deg);
    }
  }

  .post-scheduler-footer {
    margin: auto 0 0 0;
    width: 100%;
    border-top: 1px solid #838eab;
    padding: 1.6em 2.6em;
    text-align: right;
  }

  .post-scheduler-button {
    margin: 0 0 0 3px;

    button {
      height: 46px;
      border-radius: 10px;

      &:disabled {
        color: #838eab !important; // required to override element style
        background-color: #f4f4fa !important; // required to override element style
      }
    }

    &-secondary button {
      border: 1px solid $lmode-helper-text;
    }
  }
}

.scheduled-posts-overlay {
  font-size: 1rem;
  display: block;
  width: 100%;

  .scheduled-posts-overlay-container {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0 0.5em 1em 0 rgba(131, 142, 171, 0.35);
    width: 36em;
    border-radius: 1em;
  }

  .scheduled-posts-overlay-header {
    display: flex;
    align-items: center;
    padding: 0 2em;
    min-height: 5.4em;
    border-bottom: 1px solid #c3c9d8;
    color: #43537f;

    i {
      color: inherit;
      font-size: 1.4em;
    }

    p {
      color: inherit;
      font-size: 14px;
      font-weight: 900;
      text-rendering: geometricPrecision;
      margin: 0 0 0 0.6rem;
    }
  }

  .scheduled-posts-overlay-body {
    padding: 2em 2em 0;
    color: #43537f;

    .meta {
      display: flex;
      align-items: center;
      color: inherit;
      margin: 0 0 1em;

      h5 {
        font-size: 14px;
        color: inherit;
        font-weight: 900;
        margin: 0 4px 0 6px;
        padding: 0 5px 0 0;
        line-height: 1;
        border-right: 1px solid #43537f;
        text-rendering: geometricPrecision;
      }

      span {
        font-size: 14px;
        font-weight: 700;
        line-height: 1;
        text-rendering: geometricPrecision;
      }
    }

    p {
      font-size: 1.4em;
      height: 40px;
      margin: 0;
      font-weight: 700;
      color: inherit;
      line-height: 2rem;
      display: block;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-rendering: geometricPrecision;
    }
  }

  .scheduled-posts-overlay-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2em;
  }

  .scheduled-posts-overlay-footer-action {
    display: flex;
    align-items: center;
    cursor: pointer;

    p {
      color: #43537f;
      font-size: 1.4em;
      font-weight: 900;
      margin: 0 0.9rem 0 0;
      text-rendering: geometricPrecision;
    }
  }

  .rotate-90 {
    transform: rotate(270deg);
  }

  .fake-link {
    color: #14bae3;
    margin: 0;
    font-size: 1em;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-decoration: underline;
    text-underline-offset: 3px;
    font-weight: 900;
    text-rendering: geometricPrecision;
    cursor: pointer;
  }
}

.tooltip-schedule {
  padding: 30px;

  .tooltip-schedule-title {
    display: block;
    color: #fff;
    font-size: 16px;
    margin: 0 0 4px;
    font-weight: 900;
  }

  .tooltip-schedule-body {
    color: $dmode-body-text;
    font-weight: 700;
    margin: 0;
  }
}
