.orlo-crm-person {
  width: 600px;
  padding: 0 1em 1em 1em;
  text-align: center;
}
.orlo-crm-person .header {
  width: 400px;
  margin: 0 auto;
}
.orlo-crm-person .header img {
  width: 12%;
  height: 12%;
  margin: 1.6em 0;
}
.orlo-crm-person .header button {
  margin-top: 1.6em;
}
.orlo-crm-person .header h2 {
  color: #000;
  font-size: 1.4em;
  font-weight: 600;
  margin: 0 0 1.2em 0;
}
.orlo-crm-person .info {
  margin: 1em 0 2em 0;
  color: #8c8f9d;
  font-weight: 500;
}
.orlo-crm-person .flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.orlo-crm-person .crm-person {
  margin: 0 2em 2em 2em;
  text-align: center;
}
.orlo-crm-person .crm-person ul {
  padding: 0;
  list-style: none;
}
.orlo-crm-person .crm-person ul li {
  float: left;
  background-color: #e7ebf6;
  border-radius: 25px;
  padding: 4px 8px;
  color: #5471d7;
  margin: 0 0.4em;
  font-weight: 700;
}
.orlo-crm-person .crm-person ul li i {
  margin-right: 0.4em;
}
.orlo-crm-person .btn {
  background-color: #5471d7;
  margin: 1em 5px;
  -webkit-appearance: none;
  border: none;
  border-radius: 20px;
  padding: 1em 2em;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
}
.orlo-crm-person .btn.btn-cancel {
  background: none;
  text-transform: uppercase;
  color: #8c8f9d;
  text-decoration: underline;
  font-size: 0.8em;
}