@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

.reauth-accounts {
  color: $lmode-helper-text;
  h2 {
    color: $lmode-helper-text;
  }
  h3 {
    margin-bottom: 3rem;
  }
  .container {
    position: relative;
    border-radius: 25px;
    padding: 1rem 3rem;
  }

  .rounded-container {
    width: 100%;
    padding: 3rem;
    background-color: #fff;
    border-radius: 25px;
    margin-top: 1.5rem;

    &.extra-padding {
      padding-left: 4rem;
    }

    &.no-accounts {
      text-align: center;
      padding: 10rem;
      i {
        color: #eff3fa;
        font-size: 9rem;
      }

      h3 {
        margin-bottom: 0;
        font-size: 20px;
      }
    }
  }

  .account-name {
    text-transform: uppercase;
  }

  .reauth-action {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
  }

  .reauth-list {
    list-style: none;
    padding: 0 0 0 1rem;

    li {
      &:not(:last-child) {
        border-bottom: 4px solid $lmgrey-background;
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
      }
    }
  }

  img {
    margin-right: 1rem;
  }

  .added-by {
    font-style: italic;
  }
}
