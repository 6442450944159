ssi-crm-person-notes {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  width: 100%;
}
ssi-crm-person-notes .close-button {
  width: 80%;
  max-width: 200px;
  position: relative;
}
ssi-crm-person-notes .close-button i {
  font-size: 10px;
  font-weight: 900;
  position: absolute;
  right: 10px;
}
ssi-crm-person-notes form {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
ssi-crm-person-notes form .save-button {
  width: 80%;
  min-height: 30px;
  max-width: 200px;
  margin-top: 10px;
}