@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-login {
  .form-group {
    margin-bottom: 20px;
  }

  .form-group-authentication {
    margin: 45px 0 12px;
  }

  .btn-google {
    flex: 1 0 175px;
    background-color: #fff;
    border-color: #fff;
    position: relative;
    text-indent: -9px;

    img {
      width: 18px;
      height: 18px;
      vertical-align: bottom;
      margin-right: 11px;
    }
  }

  .login-field {
    .form-control-password {
      display: block;

      input,
      .form-control {
        padding-right: 60px;

        &[type='password'] {
          letter-spacing: 8px;
        }
      }

      &.form-control-password-empty {
        .show-hide {
          display: none;
        }
      }

      .show-hide {
        top: 19px;
        right: 30px;
        height: 22px;
        font-size: 24px;

        &.ssi-eyeclosed {
          font-size: 12px;
          right: 31px;
        }
      }
    }
  }

  .form-meta {
    text-align: right;
    margin: 6px 0 0;
    padding: 0 15px;

    a {
      color: inherit;
      font-size: 12px;
      line-height: 14px;
      font-weight: 700;
      font-style: normal;
    }
  }

  .form-spacer {
    margin-bottom: 37px;
  }
}
