@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../angularjs/common/sass/variables';
@import '../../../../../angularjs/common/sass/mixins';
@import '~/styles/colors';

ssi-dashboard ssi-view {
  .dashboard-view-container {
    width: 100%;
    width: 100%;
    position: relative;
    background-color: $lgrey-background;
    overflow-x: hidden;
  }
}
