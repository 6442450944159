ssi-message {
  font-size: 1rem;
  width: max(100%, 350px);
  max-width: 100%;
}
ssi-message .shared-post-container {
  padding: 5px 20px;
}
ssi-message .bottom-line-container .bottom-line {
  height: 2px;
  width: 100%;
  border-radius: 25px;
  background-color: #f8f9fd;
  margin-top: 10px;
}

.message-box-message-header {
  display: flex;
  flex-direction: column;
  margin: 0 -0.8em;
}
.message-box-message-header > * {
  margin-bottom: 0.9em;
  white-space: nowrap;
}
.message-box-message-header hr {
  margin: 0;
}

.message-box-message-header-translate {
  align-self: flex-start;
}

.message-box-message-header-meta-wrap {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 0 0.6em;
}

.message-box-message-header-meta {
  color: #43537f;
  padding: 0;
  margin: 0;
  font-weight: 900;
}
.message-box-message-header-meta li {
  display: inline-block;
  vertical-align: middle;
  max-width: 25em;
}
.message-box-message-header-meta li > * {
  text-overflow: ellipsis;
  overflow: hidden;
  text-rendering: geometricPrecision;
}
.message-box-message-header-meta li:last-child .message-box-message-header-meta-item:after {
  content: normal;
}
.message-box-message-header-meta p {
  margin-bottom: 0;
}

.message-box-message-header-meta-title-wrap {
  line-height: 2em;
  margin: 0 0.4em 0 0;
  cursor: pointer;
}

.message-box-message-header-meta-title {
  color: #14bae3;
  font-size: 1.6em;
  font-weight: 700;
  line-height: inherit;
}
.message-box-message-header-meta-title-big {
  font-size: 1.9em;
}

.message-box-message-header-meta-item {
  color: inherit;
  font-size: 1em;
  font-weight: inherit;
  margin: 0;
  line-height: 2em;
}
.message-box-message-header-meta-item:after {
  content: "|";
  margin: 0 0.6em;
}

.message-box-message-header-meta-ad {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 6px;
  background-color: #f8f9fd;
  color: #43537f;
  border-radius: 4px;
  height: 20px;
  width: 65px;
  margin: 0 3px 0 0;
}
.message-box-message-header-meta-ad span,
.message-box-message-header-meta-ad i {
  color: inherit;
}
.message-box-message-header-meta-ad span {
  font-size: 8px;
  line-height: 0;
  text-transform: uppercase;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
.message-box-message-header-meta-ad i {
  font-size: 10px;
}

.message-box-message-header-meta-badge {
  color: inherit;
  font-size: 1em;
  font-weight: inherit;
  background-color: #f8f9fd;
  padding: 0.4em 0.6em;
  border-radius: 1em;
  margin: 0 0.6em 0 0;
}
.message-box-message-header-meta-badge.fa, .message-box-message-header-meta-badge.ssi {
  color: #14bae3;
  font-weight: 700;
  border-radius: 50%;
  height: 2em;
  width: 2em;
  line-height: 2em;
  text-align: center;
  vertical-align: middle;
  padding: 0;
  margin: 0 0.3em 0 0;
}
.message-box-message-header-meta-badge.fa.fa-youtube-play, .message-box-message-header-meta-badge.ssi.fa-youtube-play {
  color: #282828;
  height: auto;
  width: 100%;
  border-radius: 0;
  background: none;
  font-size: 23px;
  line-height: normal;
}
.message-box-message-header-meta-badge .ssi {
  margin: 0 0.3em 0 0;
}

.message-box-message-header-meta-verified {
  font-size: 1.2em;
  vertical-align: text-top;
  margin: 0 0.2rem 0 0;
}

p.message-box-message-header-meta-badge:empty {
  display: none;
}

.message-box-expandable-meta {
  position: relative;
  margin: 1.7em -1.6em -1em 0;
  padding: 1.9em 0 1em 0;
  border-top: 0.2em solid #f8f9fd;
}
.message-box-expandable-meta:last-child {
  border-bottom-left-radius: 1.2em;
  border-bottom-right-radius: 1.2em;
}

.message-box-stats {
  display: inline-block;
  margin: 0 -0.8em;
  padding: 0;
  vertical-align: middle;
  vertical-align: bottom;
}
.message-box-stats > li {
  display: inline-block;
}

.message-box-stat {
  margin: 0 0.8em 0;
  line-height: 1.5em;
}

.message-box-stat-icon {
  font-size: 1.4em;
  color: #43537f;
  font-weight: 500;
}

.message-box-stat-value {
  vertical-align: super;
  color: #43537f;
  font-weight: 900;
  font-size: 1em;
  white-space: nowrap;
  margin: 0 0 0 0.1em;
}

.retweet-box {
  position: absolute;
  right: 0;
  margin: 0.6em 0 0;
  background-color: #fff;
  border-radius: 1.2em;
  width: 200px;
  box-shadow: 0 0 1.5em rgba(231, 232, 238, 0.65);
  padding: 2.6em 2em;
  z-index: 999999999;
}

.retweet-confirmation {
  text-align: center;
  background-color: #5b86f7;
  color: white;
}
.retweet-confirmation i {
  font-size: 2.8em;
  margin-bottom: 0.4em;
}
.retweet-confirmation p {
  font-size: 1.2em;
  font-weight: 700;
  line-height: 1.8em;
  margin: 0;
}

.retweet-box-close {
  position: absolute;
  right: 0.5em;
  top: 0.5em;
  display: flex;
  border-radius: 50%;
  height: 2em;
  width: 2em;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  color: white;
  cursor: pointer;
}
.retweet-box-close .ssi {
  font-size: 0.8em;
}

.retweet-box-actions {
  margin: 0;
  padding: 0;
}
.retweet-box-actions > li {
  display: block;
}

.retweet-box-action {
  color: #43537f;
}
.retweet-box-action-active {
  padding-bottom: 0.5em;
}
.retweet-box-action-active > p {
  color: #14bae3;
}
.retweet-box-action p {
  width: 100%;
  display: inline-block;
  font-size: 1.2em;
  font-weight: 700;
  margin: 0;
  padding: 0.5em 0 0.7em;
  cursor: pointer;
}
.retweet-box-action p:hover, .retweet-box-action p:focus {
  color: #14bae3;
}
.retweet-box-action:last-child {
  border-bottom-width: 0;
}
.retweet-box-action:last-child p {
  padding: 0.5em 0;
}
.retweet-box-action i {
  color: #838eab;
}
.retweet-box-action .ssi-arrow-down-correct {
  margin-left: 1.2em;
  color: #14bae3;
  float: right;
  top: 7px;
  position: relative;
}

.retweet-box-action-icon {
  font-size: 1.6em;
  position: relative;
  top: 2px;
  margin-right: 0.4em;
}

.retweet-box-sub-actions {
  margin: 0 -0.8em 0 0;
  padding: 0 0.3em 0.6em 0;
  max-height: 130px;
  overflow-y: auto;
  overflow-x: hidden;
  border-bottom: 2px solid #f0f3f9;
}

.retweet-box-sub-action {
  display: block;
  margin: 0.2em 0;
  width: 100%;
}
.retweet-box-sub-action-disabled {
  cursor: not-allowed;
}
.retweet-box-sub-action:hover {
  background-color: #f8f9fd;
  color: #14bae3;
  border-radius: 15px;
}
.retweet-box-sub-action p {
  padding: 0.4em 0.8em 0.6em 0.4em;
  word-break: break-word;
  hyphens: auto;
}
.retweet-box-sub-action .retweet-box-action-icon {
  font-size: 1.2em;
}

.message-box-message-prompt-delete {
  background-color: #3e4764;
}
.message-box-message-prompt-delete ul {
  margin: 0;
  padding: 1.8em 3.3em;
}
.message-box-message-prompt-delete li {
  display: inline-block;
  color: #f3f5fa;
  font-weight: 900;
}
.message-box-message-prompt-delete li p {
  display: inline-block;
  margin: 0;
  font-size: 1em;
  font-weight: inherit;
  text-rendering: geometricPrecision;
  vertical-align: bottom;
}
.message-box-message-prompt-delete li:after {
  content: "|";
  display: inline-block;
  margin: 0 0.6em;
  font-weight: 400;
}
.message-box-message-prompt-delete li:last-child:after {
  content: normal;
}
.message-box-message-prompt-delete-private ul {
  padding: 1.8em 1.6em;
}

.message-box-message-prompt-delete-heading {
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.sentiment-and-tags {
  display: flex;
  margin: 1em 0 0.6em 0;
  flex-wrap: wrap;
}
.sentiment-and-tags.padding-bottom {
  padding-bottom: 10px;
}
.sentiment-and-tags .chip-item {
  display: flex;
  border-radius: 4px;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}
.sentiment-and-tags .chip-item i {
  font-size: 14px;
  padding: 3px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.sentiment-and-tags .chip-item span {
  display: flex;
  align-items: center;
  padding: 0 10px;
  color: #101525;
  font-size: 12px;
  font-weight: 900;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.sentiment-and-tags .chip-item .ssi-delete-close {
  margin-top: 3px;
  margin-right: 4px;
  font-size: 8px;
  cursor: pointer;
}
.sentiment-and-tags .chip-item.tag {
  background-color: #f8f9fd;
  border: 1px solid #c3c9d8;
}
.sentiment-and-tags .chip-item.view-more {
  text-decoration: underline;
  color: #14bae3;
  background: none;
  border: none;
}
.sentiment-and-tags .chip-item.view-more span {
  color: #14bae3;
}