ssi-engagement ssi-user-performance,
ssi-engagement ssi-tags-table,
ssi-engagement ssi-team-performance,
ssi-engagement ssi-response-time-sla {
  font-size: 1rem;
}
ssi-engagement ssi-user-performance ssi-treetable,
ssi-engagement ssi-tags-table ssi-treetable,
ssi-engagement ssi-team-performance ssi-treetable,
ssi-engagement ssi-response-time-sla ssi-treetable {
  overflow: visible;
}
ssi-engagement ssi-user-performance ssi-treetable .content,
ssi-engagement ssi-tags-table ssi-treetable .content,
ssi-engagement ssi-team-performance ssi-treetable .content,
ssi-engagement ssi-response-time-sla ssi-treetable .content {
  background-color: #fff;
  border-radius: 1.2em;
  box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
  padding: 3em 2.6em 4.6em;
  margin: 0 0 2em;
}
ssi-engagement ssi-user-performance ssi-treetable .content .content-inner .head .head-inner .row .cell .cell-inner-cover.active,
ssi-engagement ssi-user-performance ssi-treetable .content .content-inner .head .head-inner .row .cell,
ssi-engagement ssi-tags-table ssi-treetable .content .content-inner .head .head-inner .row .cell .cell-inner-cover.active,
ssi-engagement ssi-tags-table ssi-treetable .content .content-inner .head .head-inner .row .cell,
ssi-engagement ssi-team-performance ssi-treetable .content .content-inner .head .head-inner .row .cell .cell-inner-cover.active,
ssi-engagement ssi-team-performance ssi-treetable .content .content-inner .head .head-inner .row .cell,
ssi-engagement ssi-response-time-sla ssi-treetable .content .content-inner .head .head-inner .row .cell .cell-inner-cover.active,
ssi-engagement ssi-response-time-sla ssi-treetable .content .content-inner .head .head-inner .row .cell {
  background-color: #fff;
  border: 0;
}
ssi-engagement ssi-user-performance ssi-treetable .content .content-inner .head .head-inner .row .cell .cell-inner .col-header > span,
ssi-engagement ssi-tags-table ssi-treetable .content .content-inner .head .head-inner .row .cell .cell-inner .col-header > span,
ssi-engagement ssi-team-performance ssi-treetable .content .content-inner .head .head-inner .row .cell .cell-inner .col-header > span,
ssi-engagement ssi-response-time-sla ssi-treetable .content .content-inner .head .head-inner .row .cell .cell-inner .col-header > span {
  overflow: visible;
  white-space: normal;
  letter-spacing: 0.1em;
  color: #43537f;
  text-transform: uppercase;
  text-rendering: geometricPrecision;
}
ssi-engagement ssi-user-performance ssi-treetable .content .content-inner .head .head-inner .row .cell .cell-inner-cover,
ssi-engagement ssi-user-performance ssi-treetable .content .content-inner .head .head-inner .row .cell:hover,
ssi-engagement ssi-tags-table ssi-treetable .content .content-inner .head .head-inner .row .cell .cell-inner-cover,
ssi-engagement ssi-tags-table ssi-treetable .content .content-inner .head .head-inner .row .cell:hover,
ssi-engagement ssi-team-performance ssi-treetable .content .content-inner .head .head-inner .row .cell .cell-inner-cover,
ssi-engagement ssi-team-performance ssi-treetable .content .content-inner .head .head-inner .row .cell:hover,
ssi-engagement ssi-response-time-sla ssi-treetable .content .content-inner .head .head-inner .row .cell .cell-inner-cover,
ssi-engagement ssi-response-time-sla ssi-treetable .content .content-inner .head .head-inner .row .cell:hover {
  box-shadow: none;
}
ssi-engagement ssi-user-performance ssi-treetable .body,
ssi-engagement ssi-tags-table ssi-treetable .body,
ssi-engagement ssi-team-performance ssi-treetable .body,
ssi-engagement ssi-response-time-sla ssi-treetable .body {
  margin: 2em 0 0;
}
ssi-engagement ssi-user-performance ssi-treetable .body-inner .row,
ssi-engagement ssi-tags-table ssi-treetable .body-inner .row,
ssi-engagement ssi-team-performance ssi-treetable .body-inner .row,
ssi-engagement ssi-response-time-sla ssi-treetable .body-inner .row {
  position: relative;
}
ssi-engagement ssi-user-performance ssi-treetable .body-inner .row:nth-child(2n),
ssi-engagement ssi-tags-table ssi-treetable .body-inner .row:nth-child(2n),
ssi-engagement ssi-team-performance ssi-treetable .body-inner .row:nth-child(2n),
ssi-engagement ssi-response-time-sla ssi-treetable .body-inner .row:nth-child(2n) {
  background-color: #f8f9fd;
}
ssi-engagement ssi-user-performance ssi-treetable .body-inner .row:nth-child(2n):before, ssi-engagement ssi-user-performance ssi-treetable .body-inner .row:nth-child(2n):after,
ssi-engagement ssi-tags-table ssi-treetable .body-inner .row:nth-child(2n):before,
ssi-engagement ssi-tags-table ssi-treetable .body-inner .row:nth-child(2n):after,
ssi-engagement ssi-team-performance ssi-treetable .body-inner .row:nth-child(2n):before,
ssi-engagement ssi-team-performance ssi-treetable .body-inner .row:nth-child(2n):after,
ssi-engagement ssi-response-time-sla ssi-treetable .body-inner .row:nth-child(2n):before,
ssi-engagement ssi-response-time-sla ssi-treetable .body-inner .row:nth-child(2n):after {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 0.2em;
  border-radius: 10em;
  background-color: #e0e7f4;
  z-index: 1;
}
ssi-engagement ssi-user-performance ssi-treetable .body-inner .row:nth-child(2n):after,
ssi-engagement ssi-tags-table ssi-treetable .body-inner .row:nth-child(2n):after,
ssi-engagement ssi-team-performance ssi-treetable .body-inner .row:nth-child(2n):after,
ssi-engagement ssi-response-time-sla ssi-treetable .body-inner .row:nth-child(2n):after {
  top: auto;
  bottom: 0;
}
ssi-engagement ssi-user-performance ssi-treetable .body-inner .cell,
ssi-engagement ssi-tags-table ssi-treetable .body-inner .cell,
ssi-engagement ssi-team-performance ssi-treetable .body-inner .cell,
ssi-engagement ssi-response-time-sla ssi-treetable .body-inner .cell {
  display: flex;
  align-items: center;
}
ssi-engagement ssi-user-performance ssi-treetable .body-inner .cell p,
ssi-engagement ssi-tags-table ssi-treetable .body-inner .cell p,
ssi-engagement ssi-team-performance ssi-treetable .body-inner .cell p,
ssi-engagement ssi-response-time-sla ssi-treetable .body-inner .cell p {
  color: #101525;
  font-weight: 900;
  text-rendering: geometricPrecision;
  font-size: 1.4em;
  padding: 0 0.6em 0 1em;
  margin: 0;
}
ssi-engagement ssi-user-performance ssi-treetable .body-inner .body-cell-primary,
ssi-engagement ssi-tags-table ssi-treetable .body-inner .body-cell-primary,
ssi-engagement ssi-team-performance ssi-treetable .body-inner .body-cell-primary,
ssi-engagement ssi-response-time-sla ssi-treetable .body-inner .body-cell-primary {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  text-rendering: geometricPrecision;
  width: 500px;
}
ssi-engagement ssi-user-performance ssi-treetable .body-inner .body-cell-primary i,
ssi-engagement ssi-tags-table ssi-treetable .body-inner .body-cell-primary i,
ssi-engagement ssi-team-performance ssi-treetable .body-inner .body-cell-primary i,
ssi-engagement ssi-response-time-sla ssi-treetable .body-inner .body-cell-primary i {
  display: inline-flex;
  align-items: center;
  color: #838eab;
  margin: 0 1em 0 0.7em;
}
ssi-engagement ssi-user-performance ssi-treetable .body-inner .body-cell-primary i:before,
ssi-engagement ssi-tags-table ssi-treetable .body-inner .body-cell-primary i:before,
ssi-engagement ssi-team-performance ssi-treetable .body-inner .body-cell-primary i:before,
ssi-engagement ssi-response-time-sla ssi-treetable .body-inner .body-cell-primary i:before {
  font-size: 1.2em;
}
ssi-engagement ssi-user-performance ssi-treetable .body-inner .body-cell-primary p,
ssi-engagement ssi-tags-table ssi-treetable .body-inner .body-cell-primary p,
ssi-engagement ssi-team-performance ssi-treetable .body-inner .body-cell-primary p,
ssi-engagement ssi-response-time-sla ssi-treetable .body-inner .body-cell-primary p {
  padding: 0;
  display: inline-block;
  color: #43537f;
  font-size: 1.2em;
  font-weight: 900;
  margin: 0;
}
ssi-engagement ssi-user-performance .user-performance-filters label,
ssi-engagement ssi-tags-table .user-performance-filters label,
ssi-engagement ssi-team-performance .user-performance-filters label,
ssi-engagement ssi-response-time-sla .user-performance-filters label {
  letter-spacing: 0.1em;
  display: block;
  color: #43537f;
  font-weight: 900;
  text-transform: uppercase;
  text-rendering: geometricPrecision;
}
ssi-engagement ssi-user-performance .user-performance-filters select,
ssi-engagement ssi-tags-table .user-performance-filters select,
ssi-engagement ssi-team-performance .user-performance-filters select,
ssi-engagement ssi-response-time-sla .user-performance-filters select {
  font-size: 1.4em;
  padding: 0.6em 1em;
  margin: 0 0 1em;
  border-radius: 1.2rem;
  border-width: 0;
}