ssi-analytics ssi-disapprovals ssi-report ssi-breakdown .breakdown-container .table-container {
  padding: 30px 30px 60px 30px;
  background-color: #fff;
  border-radius: 10px;
}
ssi-analytics ssi-disapprovals ssi-report ssi-breakdown .breakdown-container .table-container .options-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}
ssi-analytics ssi-disapprovals ssi-report ssi-breakdown .breakdown-container .table-container .head-cell {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 0 5px 0 10px;
}
ssi-analytics ssi-disapprovals ssi-report ssi-breakdown .breakdown-container .table-container .body-cell {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 0 5px 0 10px;
  border-bottom: 2px solid #e0e7f4;
}
ssi-analytics ssi-disapprovals ssi-report ssi-breakdown .breakdown-container .table-container .body-cell.row-even {
  background-color: #f8f9fd;
}
ssi-analytics ssi-disapprovals ssi-report ssi-breakdown .breakdown-container .table-container .body-cell.account {
  color: #43537f;
  font-size: 12px;
  font-weight: 900;
  line-height: 14px;
}
ssi-analytics ssi-disapprovals ssi-report ssi-breakdown .breakdown-container .table-container .body-cell.account span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 10px;
}
@media print {
  ssi-analytics ssi-disapprovals ssi-report ssi-breakdown .breakdown-container .table-container .body-cell.account span {
    white-space: normal;
  }
}
ssi-analytics ssi-disapprovals ssi-report ssi-breakdown .breakdown-container .table-container .body-cell.stat {
  color: #101525;
  font-size: 14px;
  font-weight: 900;
}
@media print {
  ssi-analytics ssi-disapprovals ssi-report ssi-breakdown .breakdown-container .table-container .body-cell.stat {
    display: flex;
    flex-wrap: wrap;
  }
  ssi-analytics ssi-disapprovals ssi-report ssi-breakdown .breakdown-container .table-container .body-cell.stat ssi-stat-change {
    margin-left: 0;
  }
}
ssi-analytics ssi-disapprovals ssi-report ssi-breakdown .breakdown-container .table-container .body-cell.stat ssi-stat-change {
  margin-left: 10px;
}