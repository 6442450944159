@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-automated-reports {
  .automated-reports-container {
    width: 100%;
    height: calc(100vh - 186px);
    padding: 0 40px;
    background-color: #f4f4fa;

    .page-head {
      margin-top: 20px;
      background-image: linear-gradient(to right, #f26235, #1877f2);
      height: 200px;
      display: flex;
      background-color: #fff;
      border-radius: 12px;
      overflow: hidden;
      .text {
        padding: 40px 20px 40px 50px;
      }
      h2 {
        font-weight: 900;
        font-size: 20px;
        color: #fff;
      }
      p {
        font-weight: bold;
        color: #fff;
        line-height: 20px;
      }
      img {
        height: 200%;
        padding-right: 20px;
        padding-top: 20px;
      }
    }

    .page-body {
      margin-top: 20px;

      .tabs {
        display: flex;
        > div {
          position: relative;
          background-color: $grey-background;
          padding: 25px 50px;
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
          margin-right: 10px;
          letter-spacing: 1px;
          color: $lmode-body-text;
          font-size: 10px;
          text-transform: uppercase;
          font-weight: 900;
          box-shadow: 5px 0 10px -5px rgba(131, 142, 171, 0.1);

          &:hover {
            cursor: pointer;
          }
          &::before {
            content: '';
            transform: scaleX(0);
            visibility: hidden;
            position: absolute;
            bottom: 7px;
            height: 2px;
            width: 30%;
            left: 35%;
            background-color: #b2c614;
            transition: all 0.3s ease-in-out 0s;
          }
          &.active,
          &:hover {
            &:before {
              transform: scaleX(1);
              visibility: visible;
            }
            background-color: #fff;
          }
          &.insights {
            &::before {
              background-color: #f0b427;
            }
          }
        }
      }

      .table-container {
        padding: 20px;
        background-color: #fff;
        border-radius: 0 15px 15px 15px;
        // box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.25);

        ssi-treetable {
          .content {
            .head {
              .row {
                .cell {
                  height: 50px;
                  background-color: #fff;
                  text-transform: uppercase;
                  font-weight: 900;
                  font-size: 10px;
                  color: $lmode-body-text;
                  padding-left: 30px;
                  border-bottom: 2px solid #e0e7f4;
                }
              }
            }
          }
        }

        .table-cell {
          display: flex;
          align-items: center;
          height: 100%;
          width: 100%;

          &.body-cell {
            border-bottom: 2px solid #e0e7f4;
            padding-left: 30px;
            &.gray-bg {
              background-color: #f8f9fd;
            }

            &.text-cell {
              font-size: 14px;
              color: $lmode-title-text;
              font-weight: 700;

              span.status-dot {
                flex: 0 0 auto;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                margin-right: 10px;
                background-color: #b0c600;
                &.paused {
                  background-color: #b4bbce;
                }
              }

              span.text {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                @media print {
                  white-space: normal;
                }

                &.bold {
                  font-weight: 900;
                  // color: #43537f;
                }
                &.paused {
                  color: $lmode-helper-text;
                }
                &.capitalize {
                  text-transform: capitalize;
                }
              }
            }

            &.actions-cell {
              display: flex;
              justify-content: flex-end;
              button {
                margin-right: 5px;
                padding: 0 14px;
                i {
                  margin: 0;
                  font-weight: 500;
                }

                &.view-btn {
                  i {
                    margin-right: 5px;
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }

        .paginator-container {
          background-color: #fff;
          border-radius: 0 0 15px 15px;
          height: 80px;
          padding: 40px;
        }

        .filter-container {
          // width: 100%;
          width: 220px;
          // padding: 0 10px 10px 10px;
          position: relative;

          input.form-control.form-control-minimal.filter {
            // border-bottom: 2px solid $lmode-border-color;
            padding-left: 0;
            padding-right: 20px;
            color: $lmode-title-text;
            font-style: normal;
            font-size: 14px;
            font-weight: 700;
            border: 0;
            // text-transform: capitalize;

            &::placeholder {
              font-size: 14px;
              font-weight: 700;
              font-style: normal;
              color: $lmode-helper-text;
            }
          }

          i {
            position: absolute;
            color: $lmode-helper-text;
            top: 10px;
            right: 10px;
            font-size: 14px;
            font-weight: 700;

            &.active {
              color: $primary-active;
            }
          }
        }
      }
    }
  }
}
