@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../angularjs/common/sass/include-media';
@import '~/styles/colors';

$margin-between-boxes: 20px;
$box-radius: 12px;
$box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.35);
$box-bg-color: #fff;

ssi-link-clicks-section {
  .total-guidance-container {
    display: flex;
    margin: $margin-between-boxes 0;

    @media print {
      display: block;
    }
  }
  .total-clicks {
    margin-right: 20px;
  }
  .guidance-text {
    flex: 1 1 60%;
    display: inline-flex;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 5px 20px 0 rgba(131, 142, 171, 0.2);

    @media print {
      margin: 10px 0 0;
      border: 1px solid black;
    }

    .text {
      padding: 40px 20px 40px 50px;
      @include media('>portal') {
        padding-right: 80px;
      }
    }
    h2 {
      font-weight: 900;
      font-size: 20px;
      color: $lmode-body-text;
    }
    p {
      font-weight: bold;
      color: $lmode-body-text;
      line-height: 20px;
    }
    img {
      width: auto;
      height: 160px;
      margin-left: auto;
      margin-right: 40px;
      align-self: flex-end;
      @include media('<desktop') {
        display: none;
      }
    }
  }

  .global-link-clicks {
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    border-radius: $box-radius;
    box-shadow: $box-shadow;
    padding: 30px;

    @media print {
      display: block !important;
      page-break-inside: avoid;
    }

    .map-container {
      flex: 1 1 calc(70% - #{$margin-between-boxes});

      @media print {
        display: block;
        page-break-inside: avoid;
      }

      ssi-map {
        .agm-map-container-inner {
          border-radius: $box-radius;
        }
      }

      h4 {
        i {
          margin-left: 5px;
        }
      }
    }
    .locations-list-container {
      flex: 1 1 calc(30% - #{$margin-between-boxes});
      border-radius: $box-radius;
      box-shadow: $box-shadow;
      margin-left: $margin-between-boxes;
      padding: 40px 40px 20px 40px;
      background-color: #fff;

      @media print {
        page-break-inside: avoid;
        border: 1px solid black !important;
      }

      @include media('<portal') {
        flex-basis: 100%;
        margin: $margin-between-boxes 0 0 0;
      }

      &.no-results {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h4 {
          font-size: 20px;
          font-weight: 900;
          color: $lmode-body-text;
        }
        i {
          font-size: 50px;
          color: $lmode-helper-text;
          margin-bottom: 20px;
        }
        p {
          font-weight: bold;
          color: $lmode-helper-text;
          line-height: 20px;
        }
      }

      &.full-width {
        flex-basis: 100%;
        margin: 20px 0 0;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      margin-top: 30px;
    }

    li {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      span {
        min-width: 130px;
        margin: 0;
        font-weight: 900;
        color: $lmode-helper-text;
        & + span {
          min-width: 30px;
          color: $lmode-title-text;
        }
      }
    }
  }

  highcharts-chart {
    border-radius: $box-radius;
    box-shadow: $box-shadow;

    @media print {
      page-break-inside: avoid;
    }
  }
}
