ssi-message-share-preview .shared-post-preview-container .link-to-shared-post {
  text-decoration: none;
}
ssi-message-share-preview .shared-post-preview-container .shared-post-container {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
}
ssi-message-share-preview .shared-post-preview-container .shared-post-container .metadata {
  display: flex;
  align-items: center;
}
@media (max-width: 885px) {
  ssi-message-share-preview .shared-post-preview-container .shared-post-container .metadata {
    max-width: 100vw;
    flex-direction: column;
  }
}
ssi-message-share-preview .shared-post-preview-container .shared-post-container .metadata > * {
  margin-right: 10px;
}
ssi-message-share-preview .shared-post-preview-container .shared-post-container .metadata img {
  height: 25px;
  width: 25px;
  border-radius: 50%;
}
ssi-message-share-preview .shared-post-preview-container .shared-post-container .metadata .name {
  color: #14bae3;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}
ssi-message-share-preview .shared-post-preview-container .shared-post-container .metadata .icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fd;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
ssi-message-share-preview .shared-post-preview-container .shared-post-container .metadata .icon-container i {
  color: #14bae3;
  font-size: 12px;
}
ssi-message-share-preview .shared-post-preview-container .shared-post-container .metadata .username {
  border-radius: 100px;
  background-color: #f8f9fd;
  padding: 5px;
}
ssi-message-share-preview .shared-post-preview-container .shared-post-container .metadata .username span {
  color: #43537f;
  font-size: 10px;
  font-weight: 900;
  line-height: 12px;
}
ssi-message-share-preview .shared-post-preview-container .shared-post-container .metadata .timestamp {
  color: #43537f;
  font-size: 10px;
  font-weight: 900;
  line-height: 24px;
}
ssi-message-share-preview .shared-post-preview-container .shared-post-container .content {
  margin-top: 5px;
}
ssi-message-share-preview .shared-post-preview-container .shared-post-container .content p {
  margin: 0;
  max-height: 45px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #101525;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}
ssi-message-share-preview .shared-post-preview-container .media-container {
  display: block;
  width: fit-content;
  background-color: #fff;
  padding: 13px;
  border-radius: 12px;
  margin-top: 5px;
}
ssi-message-share-preview .shared-post-preview-container .media-container .gallery {
  position: relative;
}
ssi-message-share-preview .shared-post-preview-container .media-container .gallery img,
ssi-message-share-preview .shared-post-preview-container .media-container .gallery .video-icon-container {
  height: 60px;
  width: 80px;
  border-radius: 10px;
  box-shadow: 0 3px 5px 0 rgba(131, 142, 171, 0.35);
}
ssi-message-share-preview .shared-post-preview-container .media-container .gallery img.stacked,
ssi-message-share-preview .shared-post-preview-container .media-container .gallery .video-icon-container.stacked {
  position: absolute;
}
ssi-message-share-preview .shared-post-preview-container .media-container .gallery .video-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #14bae3;
  background-color: #f8f9fd;
}
ssi-message-share-preview .shared-post-preview-container .media-container .gallery .video-icon-container i {
  font-size: 60px;
}
ssi-message-share-preview .shared-post-preview-container .media-container .gallery .media-items-count {
  position: absolute;
  right: -20px;
  top: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  color: #14bae3;
  font-size: 10px;
  font-style: italic;
  font-weight: 900;
  line-height: 12px;
  box-shadow: 0 5px 15px 0 rgba(116, 118, 132, 0.25);
}