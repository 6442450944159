ssi-live-chat-assign-chat {
  background-color: #edf1f3;
  display: flex;
  flex-direction: column;
  min-height: 0;
  height: 100%;
}
ssi-live-chat-assign-chat > .live-chat-assign-chat-header {
  align-items: center;
  background-color: #d8e1e5;
  color: white;
  display: flex;
  flex-flow: row nowrap;
  flex-shrink: 0;
  font-size: 12px;
  height: 72px;
  justify-content: space-between;
  list-style: none;
  padding: 10px;
}
ssi-live-chat-assign-chat > .live-chat-assign-chat-header * {
  align-items: center;
  display: flex;
}
ssi-live-chat-assign-chat > .live-chat-assign-chat-header > li {
  color: #fff;
  font-weight: 400;
  margin: 0 10px;
  padding: 10px;
}
ssi-live-chat-assign-chat > .live-chat-assign-chat-header > li > button {
  align-items: baseline;
  background-color: #fff;
  border: none;
  border-radius: 24px;
  color: #a6b7c0;
  font-style: italic;
  justify-content: space-between;
  padding: 8px 12px;
}
ssi-live-chat-assign-chat > .live-chat-assign-chat-header > li > button > .button-icon {
  margin-right: 8px;
}
ssi-live-chat-assign-chat > .live-chat-assign-chat-header > li > button > .button-text {
  font-weight: 600;
}
ssi-live-chat-assign-chat > .live-chat-assign-chat-header > li.live-chat-assign-chat-header-title i {
  font-size: 40px;
}
ssi-live-chat-assign-chat > .live-chat-assign-chat-header > li.live-chat-assign-chat-header-title span {
  font-size: 18px;
}