@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../../angularjs/common/sass/include-media';

$margin-between-boxes: 2em;
$box-radius: 12px;
$box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.25);
$box-bg-color: #fff;

ssi-engagement ssi-inbox-sentiment {
  font-size: 1rem;

  .inbox-sentiment-container {
    display: flex;
    flex-wrap: wrap;

    @media print {
      display: block;
    }
  }

  .sentiment-boxes {
    width: calc(50% - #{calc($margin-between-boxes / 2)});
    display: flex;
    flex-wrap: wrap;
    gap: $margin-between-boxes;
    margin-left: calc($margin-between-boxes / 2);

    @media print {
      display: block;
    }

    @include media('<portal') {
      width: 100%;
      margin-left: 0;
      margin-top: $margin-between-boxes;
    }
  }

  .inbox-sentiment-pie-chart {
    height: 40em;
    width: calc(50% - #{$margin-between-boxes});
    margin-right: calc($margin-between-boxes / 2);
    border-radius: 12px;
    background-color: $box-bg-color;
    box-shadow: $box-shadow;
    page-break-inside: avoid;

    @media print {
      border: 1px solid black;
    }

    @include media('<portal') {
      width: 100%;
      margin-right: 0;
    }

    // for our innerHTML in highchart legend
    .highchart-legend-inline {
      display: inline-block;
      min-width: 3.5em;
      + span {
        margin-left: 0.5em;
      }
    }
  }

  ssi-fact-overview,
  ssi-sentiment-card {
    display: flex;
    flex: 1 1 calc(33.33% - #{$margin-between-boxes});
    height: 19em;

    @include media('<desktop') {
      flex: 1 1 calc(50% - #{$margin-between-boxes});
    }
    @include media('<tablet') {
      flex-basis: 100%;
    }
  }
}
