@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

$validate-post-notes-responsive-width: 885px;

.post-box-side {
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  padding-top: 20px;
  text-align: center;
  background: #fff;
  height: 100%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  @media (max-width: $validate-post-notes-responsive-width) {
    position: relative;
    width: 100vw;
    height: 60px;
  }
}

.post-box-side-activator {
  color: $primary-active;
  font-size: 18px;
  vertical-align: middle;
}

.post-box-side-activator-badge {
  color: $primary-active;
  font-size: 10px;
  vertical-align: top;
}
