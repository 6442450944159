@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-progress-bar {
  $borderRadius: 10px;
  width: 100%;

  .progress-bar-placeholder {
    // height: ngStyle
    width: 100%;
    background-color: $lgrey-background !important;
    border-radius: $borderRadius;

    @media print {
      -webkit-print-color-adjust: exact;
    }

    &.reversed {
      display: flex;
      justify-content: flex-end;
    }

    .progress {
      // width: ngStyle
      // background-color: ngStyle
      height: 100%;
      border-radius: inherit;
      transition: width 1s ease-in-out;
      -webkit-print-color-adjust: exact;

      @media print {
        background-color: black !important;
      }
    }
  }
}
