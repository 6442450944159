@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';
@import '../../../../angularjs/common/sass/include-media';

ssi-tags-input-2 {
  font-size: 1rem;
  width: 100%;

  $tags-input-width: 470px;
  $dropdown-wrap-width: 115px;
  $tags-height: 46px;

  .tags-input {
    display: flex;
    width: 100%;
  }

  .search-submit {
    display: inline-flex;
    background-color: $primary-active;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    white-space: nowrap;

    button {
      font-weight: 700;
      font-size: 14px;
      color: $lmode-helper-text;
      background-color: $lgrey-background;
      border: none;
      border-left: 1px solid #c3c9d8;
      padding: 10px;
      appearance: none;
      outline: none;

      i {
        margin-right: 5px;
      }
      span {
        @include media('<large-phone') {
          display: none;
        }
      }
    }
  }

  .tags-input-input-wrap {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    border: 0.1em solid #c3c9d8;
    overflow: hidden;
    margin-left: -0.1em;
    border-radius: 1em;
    background-color: #fff;
    padding: 0;
    width: auto;

    &-focused {
      outline: 1px solid $primary-active;
      z-index: 2;
      border-color: $primary-active;

      .tags-input-input-container-icon {
        color: $primary-active;
      }

      .search-submit {
        background-color: $primary-active;

        button {
          color: #fff;
          background-color: $primary-active;
          border-color: $primary-active;
        }
      }
    }
  }

  .tags-input-input-container {
    display: flex;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    padding-left: 2rem;

    &-noPadding {
      padding-left: 0;
    }
  }

  .tags-input-tags {
    flex: 0 0 auto;
    margin: 0;
    padding: 0.5em;

    &-noPadding {
      padding: 0;
    }
  }

  .tags-input-tag {
    font-size: 1.2em;
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 0 3rem 0 1.1rem;
    margin: 0 0.2rem 0 0;
    flex: 0 1 auto;
    height: 36px;
    background-color: #fbfbfe;
    border: 0.1rem solid #c3c9d8;
    border-radius: 0.8rem;

    span {
      font-size: 1.2rem;
      line-height: 1.2rem;
      min-height: 1rem;
      color: $lmode-title-text;
      font-weight: 900;
      text-rendering: geometricPrecision;
    }
  }

  .tags-input-tag-action {
    position: absolute;
    display: block;
    right: 0.6rem;
    top: 0;
    bottom: 0;
    margin: auto;
    padding: 0 0.5rem;
    border-width: 0;
    background-color: transparent;
    line-height: 0;

    &:hover,
    &:focus {
      .ssi {
        color: $primary-active;
      }
    }

    .ssi {
      font-size: 0.8rem;
      color: $lmode-body-text;
    }
  }

  .tags-input-input {
    flex: 1 1 auto;
    height: $tags-height;
    color: $lmode-title-text;
    font-weight: 700;
    font-size: 1.4em;
    text-rendering: geometricPrecision;
    border-width: 0;
    min-width: 22rem;
    outline: 0;
    text-indent: 0.7rem;

    &:disabled {
      background-color: #fff;
      color: transparent;

      &::placeholder {
        color: transparent;
      }
    }

    &::placeholder {
      color: #6a759a;
    }

    @include media('<large-phone') {
      min-width: initial;
    }
  }
}
