@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../angularjs/common/sass/variables';
@import '~/styles/colors';

$live-chat-statistics-default-colour: $lmode-body-text;

$unit: 2.5px;

ssi-live-chat-team-dashboard {
  height: calc(100vh - #{$navbar-height});
  margin: 0 auto;
  color: $live-chat-statistics-default-colour;
  max-width: ($unit * 384);
  overflow-x: hidden;
  overflow-y: auto;
  padding: ($unit * 8) ($unit * 8);

  & > header {
    align-self: center;
    margin-bottom: ($unit * 8);
    text-align: center;

    h1 {
      font-weight: 300;
      justify-content: center;
      margin: ($unit * 2) 0;
      text-transform: capitalize;
    }

    h2 {
      font-size: 1em;
      font-weight: 600;
      justify-content: center;
      margin: ($unit * 2) 0;
      text-transform: capitalize;
    }
  }
}
