ssi-schedule-posts {
  display: block;
}
ssi-schedule-posts .accordion-content {
  color: #43537f;
  text-align: left;
}
ssi-schedule-posts .accordion-content-title {
  font-size: 14px;
  font-weight: 900;
  text-align: left;
  color: #43537f;
  padding-left: 30px;
}
ssi-schedule-posts .accordion-content-body {
  font-weight: 700;
  line-height: 20px;
  padding: 0 0 30px 30px;
  margin-bottom: 0;
}
ssi-schedule-posts .accordion-content-action {
  display: flex;
  align-items: center;
  background-color: #f4f4fa;
  border-radius: 10px;
  border: 1px solid #f4f4fa;
  color: #838eab;
  height: 46px;
  padding: 0 14px;
  cursor: not-allowed;
}
ssi-schedule-posts .accordion-content-action p {
  font-size: 14px;
  font-weight: 700;
  color: inherit;
  margin: 0 0 0 8px;
}
ssi-schedule-posts .accordion-content-action-active {
  cursor: pointer;
  background-color: #e4faff;
  border-color: #14bae3;
}
ssi-schedule-posts .accordion-content-action-active p {
  color: #101525;
}
ssi-schedule-posts .accordion-content-action-active i {
  color: #14bae3;
}
ssi-schedule-posts .post-error {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #fff1ed;
  border-radius: 8px;
  padding: 10px;
  margin: 10px 0;
  width: 100%;
}
ssi-schedule-posts .post-error i {
  color: #e34916;
  font-size: 14px;
  padding-right: 8px;
  flex-basis: 22px;
}
ssi-schedule-posts .post-error p {
  position: relative;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #43537f;
  margin: 0;
  margin-right: auto;
}
ssi-schedule-posts .post-error .error-message {
  display: flex;
  align-items: baseline;
}
ssi-schedule-posts .scheduling-posts-form {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-bottom: 16px;
  padding-bottom: 16px;
  margin-top: -16px;
  border-bottom: 1px solid #c3c9d8;
}
ssi-schedule-posts .scheduling-posts-form-left {
  flex: 0 1 auto;
  margin-right: 10px;
  margin-top: 16px;
}
ssi-schedule-posts .scheduling-posts-form-right {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-top: 16px;
  flex: 1 1 auto;
}
ssi-schedule-posts .scheduling-posts-form-right .send-right {
  margin: 0 0 0 auto;
}
ssi-schedule-posts .scheduling-posts-form-right-inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 16px;
}
ssi-schedule-posts .scheduling-posts-label {
  color: #43537f;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-left: 30px;
  margin-bottom: 15px;
  display: block;
  max-width: 100%;
  height: 16px;
}
ssi-schedule-posts .scheduling-posts-date {
  font-family: "Lato";
  border: 1px solid #c3c9d8;
  border-radius: 10px;
  padding: 14px;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  color: #101525;
  appearance: none;
  height: 46px;
  background-color: #fff;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj4KICA8cGF0aCBkPSJNMTQ4IDI4OGgtNDBjLTYuNiAwLTEyLTUuNC0xMi0xMnYtNDBjMC02LjYgNS40LTEyIDEyLTEyaDQwYzYuNiAwIDEyIDUuNCAxMiAxMnY0MGMwIDYuNi01LjQgMTItMTIgMTJ6bTEwOC0xMnYtNDBjMC02LjYtNS40LTEyLTEyLTEyaC00MGMtNi42IDAtMTIgNS40LTEyIDEydjQwYzAgNi42IDUuNCAxMiAxMiAxMmg0MGM2LjYgMCAxMi01LjQgMTItMTJ6bTk2IDB2LTQwYzAtNi42LTUuNC0xMi0xMi0xMmgtNDBjLTYuNiAwLTEyIDUuNC0xMiAxMnY0MGMwIDYuNiA1LjQgMTIgMTIgMTJoNDBjNi42IDAgMTItNS40IDEyLTEyem0tOTYgOTZ2LTQwYzAtNi42LTUuNC0xMi0xMi0xMmgtNDBjLTYuNiAwLTEyIDUuNC0xMiAxMnY0MGMwIDYuNiA1LjQgMTIgMTIgMTJoNDBjNi42IDAgMTItNS40IDEyLTEyem0tOTYgMHYtNDBjMC02LjYtNS40LTEyLTEyLTEyaC00MGMtNi42IDAtMTIgNS40LTEyIDEydjQwYzAgNi42IDUuNCAxMiAxMiAxMmg0MGM2LjYgMCAxMi01LjQgMTItMTJ6bTE5MiAwdi00MGMwLTYuNi01LjQtMTItMTItMTJoLTQwYy02LjYgMC0xMiA1LjQtMTIgMTJ2NDBjMCA2LjYgNS40IDEyIDEyIDEyaDQwYzYuNiAwIDEyLTUuNCAxMi0xMnptOTYtMjYwdjM1MmMwIDI2LjUtMjEuNSA0OC00OCA0OEg0OGMtMjYuNSAwLTQ4LTIxLjUtNDgtNDhWMTEyYzAtMjYuNSAyMS41LTQ4IDQ4LTQ4aDQ4VjEyYzAtNi42IDUuNC0xMiAxMi0xMmg0MGM2LjYgMCAxMiA1LjQgMTIgMTJ2NTJoMTI4VjEyYzAtNi42IDUuNC0xMiAxMi0xMmg0MGM2LjYgMCAxMiA1LjQgMTIgMTJ2NTJoNDhjMjYuNSAwIDQ4IDIxLjUgNDggNDh6bS00OCAzNDZWMTYwSDQ4djI5OGMwIDMuMyAyLjcgNiA2IDZoMzQwYzMuMyAwIDYtMi43IDYtNnoiIGZpbGw9IiMxNGJhZTMiLz4KPC9zdmc+");
  background-repeat: no-repeat;
  background-position-x: 195px;
  background-position-y: 14px;
  background-size: 14px;
  padding: 16px 30px;
  width: 100%;
}
ssi-schedule-posts .scheduling-posts-date.active {
  font-size: 14px;
  font-weight: 900;
  line-height: 12px;
  color: #101525;
}
ssi-schedule-posts .scheduling-posts-date:focus-within {
  outline: none;
}
ssi-schedule-posts .scheduling-posts-date::placeholder {
  color: #6a759a;
}
ssi-schedule-posts .scheduling-posts-date:disabled {
  border-color: #c3c9d8;
  opacity: 1;
}
ssi-schedule-posts .scheduling-posts-date:hover {
  cursor: pointer;
}
ssi-schedule-posts .scheduling-posts-date::placeholder {
  color: #6a759a;
}
ssi-schedule-posts .scheduling-posts-date:disabled {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIiBmaWxsPSIjQjRCQkNFIj4KICA8cGF0aCBkPSJNMTQ4IDI4OGgtNDBjLTYuNiAwLTEyLTUuNC0xMi0xMnYtNDBjMC02LjYgNS40LTEyIDEyLTEyaDQwYzYuNiAwIDEyIDUuNCAxMiAxMnY0MGMwIDYuNi01LjQgMTItMTIgMTJ6bTEwOC0xMnYtNDBjMC02LjYtNS40LTEyLTEyLTEyaC00MGMtNi42IDAtMTIgNS40LTEyIDEydjQwYzAgNi42IDUuNCAxMiAxMiAxMmg0MGM2LjYgMCAxMi01LjQgMTItMTJ6bTk2IDB2LTQwYzAtNi42LTUuNC0xMi0xMi0xMmgtNDBjLTYuNiAwLTEyIDUuNC0xMiAxMnY0MGMwIDYuNiA1LjQgMTIgMTIgMTJoNDBjNi42IDAgMTItNS40IDEyLTEyem0tOTYgOTZ2LTQwYzAtNi42LTUuNC0xMi0xMi0xMmgtNDBjLTYuNiAwLTEyIDUuNC0xMiAxMnY0MGMwIDYuNiA1LjQgMTIgMTIgMTJoNDBjNi42IDAgMTItNS40IDEyLTEyem0tOTYgMHYtNDBjMC02LjYtNS40LTEyLTEyLTEyaC00MGMtNi42IDAtMTIgNS40LTEyIDEydjQwYzAgNi42IDUuNCAxMiAxMiAxMmg0MGM2LjYgMCAxMi01LjQgMTItMTJ6bTE5MiAwdi00MGMwLTYuNi01LjQtMTItMTItMTJoLTQwYy02LjYgMC0xMiA1LjQtMTIgMTJ2NDBjMCA2LjYgNS40IDEyIDEyIDEyaDQwYzYuNiAwIDEyLTUuNCAxMi0xMnptOTYtMjYwdjM1MmMwIDI2LjUtMjEuNSA0OC00OCA0OEg0OGMtMjYuNSAwLTQ4LTIxLjUtNDgtNDhWMTEyYzAtMjYuNSAyMS41LTQ4IDQ4LTQ4aDQ4VjEyYzAtNi42IDUuNC0xMiAxMi0xMmg0MGM2LjYgMCAxMiA1LjQgMTIgMTJ2NTJoMTI4VjEyYzAtNi42IDUuNC0xMiAxMi0xMmg0MGM2LjYgMCAxMiA1LjQgMTIgMTJ2NTJoNDhjMjYuNSAwIDQ4IDIxLjUgNDggNDh6bS00OCAzNDZWMTYwSDQ4djI5OGMwIDMuMyAyLjcgNiA2IDZoMzQwYzMuMyAwIDYtMi43IDYtNnoiLz4KPC9zdmc+");
  color: #6a759a;
}
ssi-schedule-posts ssi-time-picker {
  display: block;
  margin: 0 10px 0 0;
}
ssi-schedule-posts .scheduling-posts-delete {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f4fa;
  height: 46px;
  min-width: 46px;
  border-radius: 10px;
  border: 1px solid #f4f4fa;
  margin: 0;
  padding: 0 10px;
  cursor: not-allowed;
}
ssi-schedule-posts .scheduling-posts-delete i,
ssi-schedule-posts .scheduling-posts-delete p {
  color: #838eab;
  font-size: 14px;
}
ssi-schedule-posts .scheduling-posts-delete p {
  margin: 0 0 0 8px;
  white-space: nowrap;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-schedule-posts .scheduling-posts-delete-active {
  background-color: #fff1ed;
  border-color: #cc3300;
  cursor: pointer;
}
ssi-schedule-posts .scheduling-posts-delete-active i {
  color: #cc3300;
}
ssi-schedule-posts .scheduling-posts-delete-active p {
  color: #43537f;
}