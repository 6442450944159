ssi-live-chat-navigation {
  background: linear-gradient(to right, rgb(237, 241, 243) -1%, rgb(237, 241, 243) 97%, rgb(225, 229, 231) 100%) #EDF1F3;
  display: flex;
  flex-basis: 72px;
  height: 72px;
  min-height: 72px;
  /* stylelint-disable */
  /* stylelint-enable */
}
ssi-live-chat-navigation * {
  display: flex;
}
ssi-live-chat-navigation > ul {
  flex-flow: row nowrap;
  flex-grow: 1;
  list-style: none;
  margin: 0;
  padding: 0;
  transform: none !important;
}
ssi-live-chat-navigation > ul > li {
  border: solid 2.5px #fff;
  border-radius: 30px;
  flex-grow: 1;
  flex-shrink: 0;
  margin: 15px 10px;
}
ssi-live-chat-navigation > ul > li.exit {
  background-color: #D8DFE5;
  position: relative;
}
ssi-live-chat-navigation > ul > li.exit .icon {
  left: 10px;
  position: absolute;
}
ssi-live-chat-navigation > ul > li.exit .text {
  margin-left: 10px;
}
ssi-live-chat-navigation > ul > li.date-range .icon, ssi-live-chat-navigation > ul > li.sorting .icon {
  margin-left: 10px;
}
ssi-live-chat-navigation > ul > li.date-range.show, ssi-live-chat-navigation > ul > li.sorting.show {
  display: flex !important;
}
ssi-live-chat-navigation > ul > li.date-range .text, ssi-live-chat-navigation > ul > li.sorting .text {
  font-style: italic;
}
ssi-live-chat-navigation > ul > li .dropdown-menu {
  border: none;
  border-radius: 20px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  transform: none !important;
  width: 100%;
  z-index: 9;
}
ssi-live-chat-navigation > ul > li .dropdown-menu > li {
  padding: 10px;
}
ssi-live-chat-navigation > ul > li button,
ssi-live-chat-navigation > ul > li select {
  align-items: center;
  background-color: transparent;
  border: none;
  color: #4f626c;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 0.9em;
  font-weight: 600;
  justify-content: center;
  padding: 5px 10px;
  position: inherit;
}
ssi-live-chat-navigation > ul > li button[disabled],
ssi-live-chat-navigation > ul > li select[disabled] {
  cursor: not-allowed;
  opacity: 0.65;
}
ssi-live-chat-navigation > ul > li button:focus,
ssi-live-chat-navigation > ul > li select:focus {
  outline: revert !important;
}
ssi-live-chat-navigation > ul > li button.show,
ssi-live-chat-navigation > ul > li select.show {
  display: flex !important;
}
ssi-live-chat-navigation > ul > li button > *,
ssi-live-chat-navigation > ul > li select > * {
  display: inline;
}
ssi-live-chat-navigation > ul > li .icon {
  font-size: 1em;
  font-weight: 300;
}
@media only screen and (max-width: 1350px) {
  ssi-live-chat-navigation > ul > li .icon {
    font-size: 0.85em;
  }
  ssi-live-chat-navigation > ul > li button {
    font-size: 0.75em;
  }
}