@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-custom-widget-field {
  font-size: 1rem;

  .cw-field {
    position: relative;
  }

  .cw-field-trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $lmode-helper-text;
    background-color: $lgrey-background;
    border: 0.1em solid $lgrey-background;
    border-radius: 10em;
    width: 100%;
    height: 3.6em;
    padding: 0 1.9em;
    margin: 0 0 1.4em;
    text-align: left;

    &.cw-field-trigger-pointer {
      cursor: auto;
      border-color: $lgrey-background;
    }

    & ~ .cw-field-trigger + .cw-dropdown {
      top: 5em;
    }
  }

  .cw-field-trigger-active {
    border-color: $primary-active;
    background-color: #e7f8fc;

    .cw-field-trigger-label {
      color: $lmode-title-text;
      font-weight: 900;
    }
  }

  .cw-field-trigger-icon {
    color: $lmode-helper-text;
    font-size: 1.2em;
  }

  .cw-field-meta {
    margin-top: -0.4rem;
    border-color: #e7f8fc;
    background-color: #e7f8fc;

    .cw-field-trigger-icon {
      cursor: pointer;
      font-size: 1em;
    }

    .cw-field-trigger-label {
      color: $lmode-title-text;
      font-weight: 900;
    }
  }

  .cw-field-trigger-action-wrap {
    border-width: 0;
    background-color: transparent;
    padding: 0;
    margin: 0;
  }

  .cw-field-trigger-action {
    color: $lmode-helper-text;
    font-size: 1em;
    padding: 1em;

    &:active {
      color: $primary-active;
    }
  }

  .cw-field-trigger-label {
    flex: 1 0 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-rendering: geometricPrecision;
    font-size: 1.2em;
    margin: 0;
    flex: 1 1 auto;
    color: $lmode-helper-text;
    font-weight: 700;
  }

  .cw-dropdown {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    background-color: #fff;
    max-height: 35em;
    width: 37em;
    box-shadow: 0 0.5em 1em rgba(131, 142, 171, 0.35);
    z-index: 1;
    border-radius: 1.5em;
    padding: 3em 1.8em 4em 2em;

    &-meta {
      padding: 4em 1.8em 3em 2em;
    }
  }

  .cw-dropdown-filter-wrap {
    margin: 0 1em 3em;
  }

  .cw-dropdown-filter {
    width: 100%;
    font-size: 1.4em;
    border: 1px solid #c3c9d8;
    background-color: $grey-background;
    border-radius: 1rem;
    height: 4.6rem;
    padding: 0 1rem;
    text-indent: 2rem;
    color: $lmode-body-text;
    font-weight: 700;
    text-rendering: geometricPrecision;

    &::placeholder {
      color: $lmode-helper-text;
    }
  }

  .cw-dropdown-list {
    overflow: auto;
    margin: 0;
    padding: 0;
  }

  .cw-dropdown-list-item-check {
    position: absolute;
    display: none;
    top: 0;
    bottom: 0;
    margin: auto;
    font-size: 1.2em;
    line-height: 1.6rem;
    right: 1.2rem;
    height: 1.6rem;
    color: #fff;
    z-index: 2;
  }

  .cw-dropdown-list-item,
  .cw-popup-dropdown-menu-item {
    position: relative;
    display: block;

    label {
      display: flex;
      align-items: center;
      color: $lmode-body-text;
      height: 3.6rem;
      border-radius: 1rem;
      padding: 0 5rem 0 2rem;
      color: $lmode-body-text;
      font-size: 1.4em;
      font-weight: 900;
      text-rendering: geometricPrecision;
      margin: 0 0 0.3em;
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: $grey-background;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 1rem;
        margin: auto;
        display: block;
        height: 1.6rem;
        width: 1.6rem;
        border: 0.1rem solid $lmode-helper-text;
        background-color: #fff;
        border-radius: 0.2em;
      }
    }

    input {
      position: absolute;
      visibility: hidden;

      &:checked + label {
        color: $primary-active;

        &:after {
          background-color: $primary-active;
          border-color: $primary-active;
        }
      }

      &:checked ~ .cw-dropdown-list-item-check {
        display: block;
      }
    }
  }
}
