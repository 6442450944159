ssi-live-chat-network-state {
  align-items: center;
  color: #a6b7c0;
  display: flex;
  flex-flow: column nowrap;
  flex-shrink: 0;
  font-size: 0.9em;
  position: relative;
}
ssi-live-chat-network-state * {
  display: flex;
}
ssi-live-chat-network-state button {
  background: transparent;
  border: none;
  outline: revert;
  padding: 0;
  white-space: nowrap;
}
ssi-live-chat-network-state button:focus {
  outline: revert !important;
}
ssi-live-chat-network-state button[disabled] {
  cursor: not-allowed;
  opacity: 0.65;
}
ssi-live-chat-network-state ssi-live-chat-avatar {
  cursor: pointer;
  height: 60px;
  width: 60px;
}
ssi-live-chat-network-state ssi-live-chat-avatar.away .inner > img, ssi-live-chat-network-state ssi-live-chat-avatar.busy .inner > img {
  border-color: #a6b7c0;
}
ssi-live-chat-network-state ssi-live-chat-avatar.away .inner > .statistic, ssi-live-chat-network-state ssi-live-chat-avatar.busy .inner > .statistic {
  background-color: #a6b7c0;
}
ssi-live-chat-network-state ssi-live-chat-avatar.offline .inner > img {
  border-color: #f39780;
}
ssi-live-chat-network-state ssi-live-chat-avatar.offline .inner > .statistic {
  background-color: #f39780;
}
ssi-live-chat-network-state ssi-live-chat-avatar.online .inner > img {
  border-color: #91cac7;
}
ssi-live-chat-network-state ssi-live-chat-avatar.online .inner > .statistic {
  background-color: #91cac7;
}
ssi-live-chat-network-state ssi-live-chat-avatar .inner > img {
  border-style: solid;
  border-width: 4px;
}
ssi-live-chat-network-state ssi-live-chat-avatar .inner > .statistic {
  position: absolute;
  border-radius: 50%;
  color: #fff;
  padding: 6px 8px;
  font-style: italic;
  font-weight: 400;
  top: -6px;
  right: -6px;
  justify-content: center;
}
ssi-live-chat-network-state > div {
  align-items: center;
  color: #187ab1;
  flex-flow: column nowrap;
}
ssi-live-chat-network-state > div > .dropdown {
  position: inherit;
  width: 90px;
}
ssi-live-chat-network-state > div > .dropdown.show > * {
  display: inline;
}
ssi-live-chat-network-state > div > .options {
  display: none;
}
ssi-live-chat-network-state > div > button.status {
  color: #fff;
}
ssi-live-chat-network-state > div > .status {
  align-items: center;
  border-radius: 20px;
  font-weight: 500;
  justify-content: space-around;
  padding: 2.5px 12.5px;
}
ssi-live-chat-network-state > div > .status.away, ssi-live-chat-network-state > div > .status.busy {
  background-color: #607987;
}
ssi-live-chat-network-state > div > .status.offline {
  background-color: #f39780;
  font-style: normal;
}
ssi-live-chat-network-state > div > .status.online {
  background-color: #91cac7;
}
ssi-live-chat-network-state > div > .status.unstyled {
  background-color: transparent !important;
  margin: 0 !important;
}
ssi-live-chat-network-state > div > .status .status-icon {
  font-size: 0.85em;
  padding-left: 5px;
}
ssi-live-chat-network-state > div > dl {
  margin: 0;
  padding: 0;
}
ssi-live-chat-network-state > div > dl dt,
ssi-live-chat-network-state > div > dl dd {
  font-weight: 400;
  margin: 0;
  padding: 0;
}
ssi-live-chat-network-state > div > dl dd {
  padding-left: 5px;
}
ssi-live-chat-network-state > div .dropdown-menu {
  border: none;
  border-radius: 20px;
  margin: 0;
  padding: 0;
  width: 100%;
}
ssi-live-chat-network-state > div .dropdown-menu > li {
  color: #43545d;
  padding: 10px;
}
ssi-live-chat-network-state > div .dropdown-menu > li:first-child {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
ssi-live-chat-network-state > div .dropdown-menu > li:last-child {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
ssi-live-chat-network-state > div .dropdown-menu > li:hover {
  background-color: #14bae3;
  color: #fff;
}
ssi-live-chat-network-state > div .dropdown-menu > li > button span {
  font-style: italic;
}
ssi-live-chat-network-state > div .dropdown-menu > li > button strong {
  font-weight: bold;
  padding-left: 2.5px;
}
ssi-live-chat-network-state > div > .name {
  font-weight: 700;
  text-transform: capitalize;
  text-align: center;
}
ssi-live-chat-network-state > div > .resolutions {
  margin-bottom: 5px;
}
ssi-live-chat-network-state > div > .status {
  font-size: 1.1em;
  font-weight: 500;
  margin: 5px 0;
  padding: 2px 20px;
  text-transform: capitalize;
}
ssi-live-chat-network-state > div > .status.offline {
  font-style: italic;
}
ssi-live-chat-network-state > div > .status.online {
  font-weight: 400;
}
ssi-live-chat-network-state .social-status {
  position: relative;
  display: flex;
}
ssi-live-chat-network-state .social-status .status-menu {
  width: 90px;
}
ssi-live-chat-network-state .social-status label {
  margin-right: 5px;
  margin-top: 3px;
  color: #43537f;
  font-size: 14px;
  font-weight: 900;
}
ssi-live-chat-network-state .social-status ul {
  position: absolute;
  top: 0;
  z-index: 2;
  height: 25px;
  width: 90px;
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  list-style: none;
  padding: 0;
  color: #596065;
  flex-direction: column;
}
ssi-live-chat-network-state .social-status ul.active {
  display: block;
  height: auto;
  border-left: 1px solid #cacaca;
  border-right: 1px solid #cacaca;
  border-bottom: 1px solid #cacaca;
}
ssi-live-chat-network-state .social-status ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 26px;
  height: 26px;
  padding: 0 10px;
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 900;
}
ssi-live-chat-network-state .social-status ul li i .ssi {
  font-size: 10px;
  padding-top: 2px;
}
ssi-live-chat-network-state .social-status ul li:hover {
  color: #14bae3;
  cursor: pointer;
}
ssi-live-chat-network-state .social-status ul li.initial {
  color: #fff;
  font-weight: bold;
  line-height: 25px;
  flex: 1 0 25px;
}
ssi-live-chat-network-state .social-status ul li.offline {
  background-color: #5c698d;
}
ssi-live-chat-network-state .social-status ul li.online {
  background-color: #277e8e;
}
ssi-live-chat-network-state .social-status ul li.busy {
  background-color: #be3609;
}