ssi-activity-link-preview * {
  display: flex;
}
ssi-activity-link-preview > .link-trigger {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 12px rgba(0, 0, 0, 0.175);
  cursor: pointer;
  display: flex;
  flex-flow: column nowrap;
  flex-shrink: 0;
  flex-grow: 0;
  margin-top: 10px;
  padding: 20px;
  text-decoration: none;
}
ssi-activity-link-preview > .link-trigger span.link-description {
  color: #596065;
  margin-bottom: 20px;
}
ssi-activity-link-preview > .link-trigger span.link-title {
  color: #43ade8;
  font-weight: 600;
}
ssi-activity-link-preview > .link-trigger span.spinner {
  align-self: center;
  color: #94a1a7;
  font-size: 60px;
  margin: 40px;
}
ssi-activity-link-preview > .link-trigger img.link-preview {
  max-height: 30vh;
  max-width: 30vw;
}