@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-message-actions-inline {
  font-size: 1rem;

  .message-box-buttons {
    display: flex;
    justify-content: flex-start;
    padding: 0;
    // margin: 0 -1.9em 0.2em 1.6em;
    margin: 0 -0.3em 0.2em !important;

    .message-box-message-action {
      background-color: transparent;
      margin: 0.4em 0.3em -0.2em;
      height: 2em;
      width: 2em;

      &-wide {
        width: auto;
      }

      p {
        margin: 0;
        cursor: auto;
      }

      &-tall {
        height: auto;
      }

      .message-box-message-action-icon {
        font-size: 1.4em;

        &:not(.message-box-message-action-icon-custom-colour) {
          color: #43537f;
        }

        &.ssi {
          &.ssi-fb-liked {
            color: #15bae3;
          }

          &.ssi-liked {
            color: #d10800;
          }

          &.ssi-hide-image {
            font-size: 1.6em;
          }

          &.ssi-inbox-spam {
            &.active {
              color: #ffa962;
            }
          }
        }
      }

      .message-box-message-action-button {
        display: inline-block;
        border-radius: 10px;
        border-width: 0;
        font-size: 1.2em;
        font-weight: 700;
        color: #fff;
        height: 100%;
        padding: 0 8px;

        &-primary {
          background-color: $primary-active;
        }

        &-warning {
          background-color: $secondary-red;
        }
      }
    }

    .message-box-message-action-info {
      display: flex;
      align-items: center;
      border-radius: 5px;
      overflow: hidden;
      background-color: #5096aa;
      color: #fff;
      height: 26px;

      i {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 26px;
        font-size: 14px;
        width: 26px;
        height: 26px;
        color: inherit;
        background-color: #5fa5ba;
      }

      span {
        display: inline-block;
        padding: 0 9px;
        color: inherit;
        font-size: 12px;
        font-weight: 900;
        text-rendering: geometricPrecision;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .message-box-message-action-meta {
    display: block;
    width: 0.5em;
    font-size: 0.8em;
    line-height: 0.8em;
  }

  &.message-box-buttons-selfpenned {
    .message-box-message-action-icon {
      background-color: #ecf0fd;
    }
  }
}
