@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-alert {
  display: flex;
  padding: 10px 15px;
  background-color: $primary-red;
  color: $lmode-body-text;
  font-weight: bold;
  font-size: 12px;
  border: none;
  border-radius: 10px;
  &.warning {
    background-color: $primary-red;
  }

  .message-icon {
    margin: 2px 10px 0 0;
  }
}
