$live-chat-conversation-tree-gradient: linear-gradient(
  to bottom,
  rgba(216,225,229,1) 0%,
  rgba(237,241,243,1) 3%,
  rgba(237,241,243,1) 100%
);
$live-chat-list-item-background-colour: #EDF1F3;
$live-chat-list-item-gradient-start-colour: rgba(237,241,243,1);
$live-chat-list-item-gradient-end-colour: rgba(225,229,231,1);
$live-chat-list-item-gradient: linear-gradient(
  to right,
  $live-chat-list-item-gradient-start-colour -1%,
  $live-chat-list-item-gradient-start-colour 97%,
  $live-chat-list-item-gradient-end-colour 100%
);

$live-chat-typing-indicator-gradient: linear-gradient(
  to right,
  #D2DBDF 0%,
  #D8E1E5 100%
);

$live-chat-navigation-background-colour: #EDF1F3;
$live-chat-navigation-button-background-colour: #43ADE8;
$live-chat-navigation-disabled-button-background-color: #A6B7C0;
$live-chat-passive-text-colour: #A4B5BE;
