@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-pesdk-modal {
  .modal-popup-pesdk {
    .modal-body {
      min-width: 95vw;
      height: 576px;
      padding: 0;
    }
  }

  @media (min-width: 768px) {
    .modal-popup-pesdk {
      .modal-body {
        min-width: 70vw;
      }
    }
  }
}

ssi-photoeditorsdk-desktopui {
  .show {
    display: flex !important; // this is fix/owerwrite for
  }
  .photoeditorsdk-editor-saving {
    position: relative;

    &:after {
      content: 'saving...';
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      background-color: rgba(0, 0, 0, 0.6);
      color: #fff;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      vertical-align: middle;
      line-height: 576px;
      z-index: 1;
      cursor: wait;
    }
  }
}
