@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../angularjs/common/sass/variables';
@import '~/styles/colors';

$unit: 2.5px;

$link-colour: #596065;
$link-title-colour: $primary-blue;
$spinner-colour: #94a1a7;

ssi-activity-link-preview {
  * {
    display: flex;
  }

  & > .link-trigger {
    background-color: #fff;
    border-radius: ($unit * 4);
    box-shadow: 0 1px 12px rgba(0, 0, 0, 0.175);
    cursor: pointer;
    display: flex;
    flex-flow: column nowrap;
    flex-shrink: 0;
    flex-grow: 0;
    margin-top: ($unit * 4);
    padding: ($unit * 8);
    text-decoration: none;

    span {
      &.link-description {
        color: $link-colour;
        margin-bottom: ($unit * 8);
      }

      &.link-title {
        color: $link-title-colour;
        font-weight: 600;
      }

      &.spinner {
        align-self: center;
        color: $spinner-colour;
        font-size: ($unit * 24);
        margin: ($unit * 16);
      }
    }

    img.link-preview {
      max-height: 30vh;
      max-width: 30vw;
    }
  }
}
