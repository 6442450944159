ssi-network-inbound-comments {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 5px 20px 0 rgba(131, 142, 171, 0.2);
  padding: 40px;
}
ssi-network-inbound-comments h5 {
  color: #101525;
  font-size: 16px;
  font-weight: 700;
}
ssi-network-inbound-comments .network-info {
  display: flex;
  align-items: center;
  height: 40px;
  font-size: 14px;
  font-weight: 900;
}
ssi-network-inbound-comments .network-info .name {
  color: #838eab;
  width: 100px;
}
ssi-network-inbound-comments .network-info .comments-count {
  color: #101525;
  width: 50px;
}