@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/variables';
@import '~/styles/colors';
@import '../../../../../angularjs/common/sass/include-media';

$header-height: 94px;

ssi-twitter-threads {
  font-size: 1rem;

  .twitter-threads-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: $header-height;
    background-color: #fff;
    letter-spacing: 0.1em;
    padding: 3em 4em;
    box-shadow: 0 0.5em 1em rgba(131, 142, 171, 0.2);

    h1 {
      text-transform: uppercase;
      color: $lmode-title-text;
      font-size: 1.4em;
      margin: 0;
      letter-spacing: inherit;
      font-weight: 900;
      text-rendering: geometricPrecision;
    }
  }

  .twitter-threads-header-button {
    display: flex;
    align-items: center;
    padding: 0 1em;
    background-color: #ebebf4;
    border-radius: 1em;
    margin: 0 0.5em 0 0;

    p {
      color: $lmode-title-text;
      font-size: 1.4em;
      letter-spacing: 0;
      font-weight: 900;
      text-rendering: geometricPrecision;
      margin: 0;
    }
  }

  .twitter-threads-body {
    position: relative;
    padding: 9em 0 5em;
    background-color: $lgrey-background;
    min-height: calc(100vh - #{$navbar-height + $header-height});
  }

  .twitter-thread-back {
    position: absolute;
    top: 2em;
    left: 4em;
  }

  .twitter-thread-container {
    max-width: 100%;
    width: 102em;
    padding: 0 4.4em;
    margin: 0 auto;

    @include media('<tablet') {
      padding-right: 1em;
    }
  }

  .twitter-thread-container-offset {
    margin-left: -3.2em;
  }

  .th-row-counter {
    position: relative;
    flex: 0 0 6.4em;

    div {
      position: relative;
      display: inline-flex;
      background-color: #fff;
      align-items: center;
      justify-content: center;
      height: 4.6em;
      width: 4.6em;
      border-radius: 50%;
      box-shadow: 0 0.5em 1em rgba(131, 142, 171, 0.2);

      &:after {
        content: '';
        position: absolute;
        right: -1.6em;
        background-color: #a3b8ee;
        border-radius: 10em;
        height: 0.2em;
        width: 1.4em;

        @include media('<tablet') {
          display: none;
        }
      }
    }

    p {
      color: $lmode-body-text;
      font-size: 1.2em;
      font-weight: 900;
      text-rendering: geometricPrecision;
      margin: 0;
    }

    @include media('<tablet') {
      &:before {
        content: '';
        position: absolute;
        display: block;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAABkCAYAAAChBZEkAAAAP0lEQVR4AWNYvOO9/qIdb6+DMIjNAGG8+w/CIDYDhIPAg0JgVAAjojCj0r3ABwUPkAC5aWxUYDSNjaax0TQGAJm4gdn7+tTDAAAAAElFTkSuQmCC);
        background-size: 100% 100%;
        bottom: 4.9em;
        left: 2.2em;
        width: 0.2em;
        height: 3.3em;
      }
      &:after {
        content: '';
        position: absolute;
        display: block;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAABkCAYAAAChBZEkAAAAP0lEQVR4AWNYvOO9/qIdb6+DMIjNAGG8+w/CIDYDhIPAg0JgVAAjojCj0r3ABwUPkAC5aWxUYDSNjaax0TQGAJm4gdn7+tTDAAAAAElFTkSuQmCC);
        background-size: 100% 100%;
        top: 4.9em;
        left: 2.2em;
        width: 0.2em;
        height: 3.3em;
      }
    }
  }

  .th-row-item {
    flex: 1 1 auto;

    @include media('<tablet') {
      width: 100%;
      margin-top: 4em;
    }
  }

  .twitter-thread-tweet {
    position: relative;
    display: flex;
    margin: 0 0 6.6em;

    &:after {
      content: '';
      position: absolute;
      bottom: -6.2em;
      left: 11.6em;
      display: block;
      width: 0.2em;
      height: 5.8em;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAABkCAYAAAChBZEkAAAAP0lEQVR4AWNYvOO9/qIdb6+DMIjNAGG8+w/CIDYDhIPAg0JgVAAjojCj0r3ABwUPkAC5aWxUYDSNjaax0TQGAJm4gdn7+tTDAAAAAElFTkSuQmCC');
      background-size: 100% 100%;

      @include media('<tablet') {
        content: none;
      }
    }

    &::before {
      @include media('<tablet') {
        content: none;
      }
    }

    @include media('<tablet') {
      flex-wrap: wrap;
      margin-bottom: 4em;
    }

    &:first-child {
      .th-row-counter {
        &::before {
          content: none;
        }
      }
    }
  }

  .twitter-thread-add {
    background-color: #fff;
    padding: 5.7em 0;
    border-radius: 1em;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='#A3B8EE' stroke-width='4' stroke-dasharray='10%2c 18' stroke-dashoffset='-2' stroke-linecap='square'/%3e%3c/svg%3e");
    text-align: center;
    margin: 0 0 4em 6.4em;

    orlo-button button {
      padding: 14px 26px;

      // .ssi {
      //   display: inline-flex;
      //   align-items: center;
      //   justify-items: center;
      //   font-size: 6px;
      //   border-radius: 50%;
      //   border: 2px solid #fff;
      //   height: 14px;
      //   width: 14px;
      // }
    }
  }

  .action-button-wrap {
    position: relative;

    &-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      cursor: not-allowed;
    }
  }
}
