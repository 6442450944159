@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';
@import './../../../../angularjs/common/sass/variables.scss';
@import './../../../../angularjs/common/sass/mixins.scss';

$calendar-color: #8b8e9d;
$brand: $primary-active;
$calendar-title-height: 28px;
$calendar-font-color: $lmode-body-text;

ssi-date-time-picker {
  label {
    color: $lmode-body-text;
    font-size: 10px;
    font-weight: 900;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-left: 30px;
    margin-bottom: 15px;
    display: inline-block;
    max-width: 100%;
  }

  input,
  select {
    font-family: 'Lato';
    border: 1px solid #c3c9d8;
    border-radius: 10px;
    padding: 14px;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    text-align: left;
    color: #6a759a;
    appearance: none;
    height: 46px;

    &.active {
      font-size: 14px;
      font-weight: 900;
      color: $lmode-title-text;
    }

    &:focus-within {
      outline: none;
    }

    &::placeholder {
      color: #6a759a;
    }

    &:disabled {
      border-color: #c3c9d8;
      opacity: 1;
      color: #6a759a;
    }
  }

  input {
    background-color: #fff;
    background-size: 14px;
    padding: 16px 30px;

    &:hover {
      cursor: pointer;
    }

    &::placeholder {
      color: #6a759a;
    }

    &:disabled {
      color: #6a759a;
    }
  }

  select {
    cursor: pointer;
    background-color: #fff;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMzAuMDIxIDMwLjAyMSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzAuMDIxIDMwLjAyMTsiIHhtbDpzcGFjZT0icHJlc2VydmUiIGZpbGw9IiMxNEJBRTMiPgo8Zz4KCTxwYXRoIGQ9Ik0yOC42MTEsMTMuMzg1bC0xMS4wMTEsOS4zNTJjLTAuNzQ1LDAuNjMzLTEuNjY3LDAuOTQ5LTIuNTg5LDAuOTQ5Yy0wLjkyMSwwLTEuODQyLTAuMzE2LTIuNTg5LTAuOTQ5TDEuNDExLDEzLjM4NSAgIGMtMS42ODQtMS40My0xLjg5LTMuOTU0LTAuNDYtNS42MzhjMS40MzEtMS42ODQsMy45NTUtMS44OSw1LjYzOS0wLjQ1OWw4LjQyMSw3LjE1MWw4LjQyLTcuMTUxICAgYzEuNjg2LTEuNDMsNC4yMDktMS4yMjQsNS42MzksMC40NTlDMzAuNSw5LjQzMSwzMC4yOTQsMTEuOTU1LDI4LjYxMSwxMy4zODV6Ii8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+');
    background-repeat: no-repeat;
    background-position-x: 65px;
    background-position-y: 17px;
    background-size: 12px;
    padding: 15px 44px 16px 30px;

    &:disabled {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMzAuMDIxIDMwLjAyMSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzAuMDIxIDMwLjAyMTsiIHhtbDpzcGFjZT0icHJlc2VydmUiIGZpbGw9IiNCNEJCQ0UiPgo8Zz4KCTxwYXRoIGQ9Ik0yOC42MTEsMTMuMzg1bC0xMS4wMTEsOS4zNTJjLTAuNzQ1LDAuNjMzLTEuNjY3LDAuOTQ5LTIuNTg5LDAuOTQ5Yy0wLjkyMSwwLTEuODQyLTAuMzE2LTIuNTg5LTAuOTQ5TDEuNDExLDEzLjM4NSAgIGMtMS42ODQtMS40My0xLjg5LTMuOTU0LTAuNDYtNS42MzhjMS40MzEtMS42ODQsMy45NTUtMS44OSw1LjYzOS0wLjQ1OWw4LjQyMSw3LjE1MWw4LjQyLTcuMTUxICAgYzEuNjg2LTEuNDMsNC4yMDktMS4yMjQsNS42MzksMC40NTlDMzAuNSw5LjQzMSwzMC4yOTQsMTEuOTU1LDI4LjYxMSwxMy4zODV6Ii8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+');
    }
  }

  .meridian-toggle {
    display: flex;
    margin-left: 5px;
    border-radius: 10px;

    button {
      appearance: none;
      outline: none;
      font-size: 14px;
      font-weight: 700;
      line-height: 14px;
      text-align: left;
      padding: 15px 13px;
      color: #6a759a;
      border: 1px solid #c3c9d8;
      background-color: #fff;

      &:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-right: none;
      }
      &:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border-left: none;
      }

      &.active {
        background-color: $primary-active;
        color: #fff;

        &:disabled {
          background-color: $dmode-body-text;
        }
      }

      &:not(.active) {
        cursor: pointer;
      }

      &:disabled {
        cursor: not-allowed;
      }
    }
  }

  .date-container,
  .time-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    justify-items: flex-start;
  }

  .date-container {
    position: relative;
    width: 100%;

    .calendar-icon {
      font-size: 16px;
      color: $primary-active;
      position: absolute;
      bottom: 15px;
      right: 15px;
    }
  }

  .time-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    .select-container {
      display: flex;
      align-items: center;
    }

    .semicolon {
      font-size: 14px;
      font-weight: 700;
      line-height: 14px;
      text-align: center;
      margin: 0 3px;
      color: $lmode-helper-text;
    }
  }
}

.date-time-picker-wrap {
  display: flex;

  > div {
    &:not(:only-child) {
      margin-right: 7px;
    }
  }
}

.date-picker-header {
  padding: 40px 0 30px 0;

  h3 {
    margin: 0;
    font-size: 10px;
    line-height: 1;
    color: $lmode-body-text;
    font-weight: 900;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-rendering: geometricPrecision;
  }
}

.date-picker-outer {
  padding: 0 34px 20px 34px;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid rgba(131, 142, 171, 0.35);
  border-radius: 15px;
  width: 340px;
}

.date-picker-inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-height: 300px;

  .cal-month-view {
    font-size: 12px;
    color: #43537f;
    display: block;
    margin-bottom: 27px;
    font-weight: 700;
    text-rendering: geometricPrecision;

    .cal-header {
      color: #43537f;
      margin-bottom: 7px;
      font-weight: inherit;

      &:hover,
      &:focus {
        background-color: transparent;
      }

      .cal-cell {
        &:hover,
        &:focus {
          background-color: transparent;
        }
      }
    }

    .cal-days {
      border: none;

      .cal-day-cell,
      .cal-cell-top {
        background-color: transparent;
        border: 0;
        min-height: initial;

        @include ie-only {
          min-height: 35px;
        }
      }

      .cal-day-cell .cal-day-number,
      .cal-day-number {
        display: block;
        float: none;
        text-align: center;
        margin: 5px auto;
        padding: 3px 0px;
        max-width: 26px;
        font-size: 12px;
        line-height: 18px;
        color: inherit;
        opacity: 1;
        font-weight: inherit;
        border-radius: 5px;
        border: 1px solid transparent;
        cursor: pointer;

        text-rendering: geometricPrecision;
      }

      @mixin cal-cell-bg(
        $bg-color,
        $bg-color-hover,
        $text-color: $publisher-schedule-calendar-color
      ) {
        & {
          background-color: transparent;
        }
        &:hover {
          background-color: transparent;
        }
        .cal-day-number {
          color: $text-color !important;
        }
      }

      .cal-cell {
        font-weight: 700;
        margin: 1px;
      }

      .cal-cell:first-child {
        margin-left: 0;
      }

      .cal-cell:last-child {
        margin-right: 0;
      }

      .cal-cell.cal-future {
        &:hover,
        &:focus {
          .cal-day-number {
            background-color: #eceef7;
          }
        }
      }

      .cal-cell.cal-today {
        .cal-day-number {
          // color: #fff;
          // background-color: $primary-active;
          border: 1px solid #43537f;
          color: #43537f;
        }
      }

      .cal-cell.cal-weekend {
        .cal-day-number {
          color: #43537f;
        }
      }

      .cal-cell.disabled,
      .cal-cell.cal-out-month {
        .cal-day-number {
          opacity: 1;
          color: #b4bbce;
        }

        &:hover,
        &:focus {
          .cal-day-number {
            background-color: #f6f7fd;
          }
        }
      }

      .cal-cell.scheduled {
        .cal-day-number {
          background-color: #8ed500;
          color: #fff;
        }

        &:hover,
        &:focus {
          .cal-day-number {
            background-color: #8ed500;
          }
        }
      }

      .cal-cell.active {
        .cal-day-number {
          background-color: $brand;
          color: #fff;
        }

        &:hover,
        &:focus {
          .cal-day-number {
            background-color: $brand;
          }
        }
      }

      &.cal-cell {
        border: 0;

        &:hover,
        &:focus {
          background-color: transparent;
        }
      }

      .cal-cell-row {
        border: none;

        &:hover {
          background-color: transparent;
        }

        .cal-cell {
          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
}

.date-picker-title-wrap {
  max-height: $calendar-title-height;
  margin: 0 6px 8px;
}

.date-picker-title-nav {
  button {
    border: 0;
    background-color: transparent;

    .point-right {
      transform: rotate(180deg);
    }
  }

  i {
    font-size: 12px;
    color: $brand;
    line-height: $calendar-title-height;
  }
}

.date-picker-title {
  text-align: center;

  p {
    font-size: 14px;
    font-weight: 900;
    color: #43537f;
    margin: 0;
    line-height: $calendar-title-height;
    text-rendering: geometricPrecision;
  }
}
