ssi-outbox-post-new-notes {
  font-size: 1rem;
}
ssi-outbox-post-new-notes .opnn-input {
  background-color: #f8f9fd;
  border-radius: 1.2em;
  height: 100%;
}
ssi-outbox-post-new-notes .opnn-input-wrap {
  position: relative;
  height: 12.9em;
}
ssi-outbox-post-new-notes .opnn-input-textbox {
  display: block;
  font-size: 1.4em;
  padding: 2rem 3rem 0;
  margin: 0;
  height: 100%;
  width: 100%;
  background: none;
  display: block;
  position: relative;
  z-index: 2;
  resize: none;
  outline: none;
  border: 0;
  outline: 0;
  color: #101525;
  border-radius: 1.2rem;
}
ssi-outbox-post-new-notes .opnn-input-textbox::placeholder {
  font-size: 1.4rem;
  color: #838eab;
  font-weight: 600;
  text-rendering: geometricPrecision;
}
ssi-outbox-post-new-notes .opnn-tag {
  font-size: 1.4em;
  direction: ltr;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  overflow: auto;
  border-width: 1px;
  border-style: solid;
  padding: 2rem 3rem 0;
  text-align: start;
  text-transform: none;
  text-indent: 0px;
  text-decoration: none solid rgb(85, 85, 85);
  letter-spacing: normal;
  word-spacing: 0px;
  tab-size: 8;
  display: block;
  overflow: hidden !important;
  white-space: pre-wrap;
  word-wrap: break-word;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  color: rgba(0, 0, 0, 0);
  z-index: 2;
  pointer-events: none;
}
ssi-outbox-post-new-notes .opnn-tag a {
  font-size: 1.4rem;
  background: #43ade8;
  border-radius: 0.4rem;
  color: #fff;
  vertical-align: text-bottom;
}
ssi-outbox-post-new-notes .opnn-input-footer {
  padding: 0 1.4em 1.5em;
  text-align: right;
}
ssi-outbox-post-new-notes .opnn-input-action {
  font-size: 1em;
  background-color: transparent;
  border-width: 0;
  color: #43537f;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  font-weight: 900;
  margin: 0 0 0 1rem;
  text-rendering: geometricPrecision;
}
ssi-outbox-post-new-notes .opnn-input-action:hover {
  color: #14bae3;
}
ssi-outbox-post-new-notes .opnn-input-action:disabled {
  color: #838eab;
}
ssi-outbox-post-new-notes .opnn-list {
  margin: 3em 0 0;
  padding: 0;
}
ssi-outbox-post-new-notes .opnn-note {
  display: block;
  padding: 0 3em 1em;
  margin: 0 0 2em;
  border-bottom: 0.1em solid #c3c9d8;
}
ssi-outbox-post-new-notes .opnn-note:last-child {
  margin: 0;
}
ssi-outbox-post-new-notes .opnn-note-body {
  margin: 0 0 1.8em;
}
ssi-outbox-post-new-notes .opnn-note-content {
  font-size: 1.4em;
  line-height: 1.6em;
  color: #101525;
  margin: 0;
  word-break: break-word;
}
ssi-outbox-post-new-notes .delete-button {
  display: inline-block;
  background-color: transparent;
  border-width: 0;
  padding: 0;
}
ssi-outbox-post-new-notes .delete-button i {
  font-size: 14px;
  color: #e34916;
  display: flex;
  align-items: center;
  background-color: #fff1ed;
  border-radius: 50px;
  padding: 6px;
}
ssi-outbox-post-new-notes .opnn-note-meta {
  display: inline-block;
  padding: 0;
  margin: 0 0 0 1em;
}
ssi-outbox-post-new-notes .opnn-note-meta li {
  display: inline-block;
  white-space: nowrap;
}
ssi-outbox-post-new-notes .opnn-note-meta li:not(:last-child):after {
  content: "|";
  display: inline-block;
  margin: 0 0.6em;
  font-size: 1.2rem;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-outbox-post-new-notes .opnn-note-meta p {
  display: inline-block;
  font-size: 1.2em;
  font-weight: 900;
  text-rendering: geometricPrecision;
  margin: 0;
}
ssi-outbox-post-new-notes .delete-note-tooltip {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  max-width: 310px;
  height: 100%;
  box-shadow: 0 1.8em 3.5em rgba(131, 142, 171, 0.35);
}
ssi-outbox-post-new-notes .delete-note-tooltip .buttons-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
ssi-outbox-post-new-notes .delete-note-tooltip .buttons-container button {
  text-transform: uppercase;
  padding: 0 5px;
  background-color: transparent;
}
ssi-outbox-post-new-notes .delete-note-tooltip .buttons-container button span {
  font-weight: 900;
  letter-spacing: 1px;
}
ssi-outbox-post-new-notes .delete-note-tooltip h5 {
  color: #838eab;
  white-space: normal;
  text-transform: none;
  letter-spacing: 0;
  line-height: 20px;
  font-weight: bold;
}