ssi-outbox-post-new {
  font-size: 1rem;
}
ssi-outbox-post-new .opn-container {
  margin: 0 0 1.5em;
}
ssi-outbox-post-new .opn-wrap {
  background-color: #fff;
  border-radius: 1em;
  border: 0.1em solid #c3c9d8;
}
ssi-outbox-post-new .opn-wrap-deleted {
  border-color: #f88c68;
}
ssi-outbox-post-new .opn-wrap-failed {
  border-color: #e34916;
  background-color: #fcece7;
}
ssi-outbox-post-new .opn-wrap-failed .opn-actions-right i {
  background-color: #fff;
}
ssi-outbox-post-new .opn-row {
  display: flex;
  padding: 1em 2.4em 1em 2.4em;
}
ssi-outbox-post-new .opn-header {
  border-bottom: 0.1em solid;
  border-color: inherit;
  color: #43537f;
  min-height: 6.1em;
  flex-wrap: wrap;
}
ssi-outbox-post-new .opn-title-icon {
  display: inline-flex;
  flex: 0 0 2.6rem;
  align-items: center;
  justify-content: center;
  margin: 0.4rem 0.6rem 0.4rem -0.4rem;
  color: #fff;
  background-color: #1da1f2;
  border-radius: 50%;
  font-size: 1.2em;
  height: 2.6rem;
  width: 2.6rem;
}
ssi-outbox-post-new .opn-title {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  flex-wrap: wrap;
}
ssi-outbox-post-new .opn-title h2 {
  color: #101525;
  font-size: 1.6em;
  margin: 0.4rem 0.6rem 0.4rem 0;
  font-weight: 900;
  text-rendering: geometricPrecision;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
ssi-outbox-post-new .opn-title-pills {
  padding: 0;
  margin: 0.4em 0;
}
ssi-outbox-post-new .opn-title-pills li {
  display: inline-flex;
  align-items: center;
  background-color: #f4f4fa;
  border-radius: 10em;
  padding: 0.4em 0.6em;
  margin: 0 0.6em 0 0;
}
ssi-outbox-post-new .opn-title-pills li + li {
  margin-left: -0.6em;
}
ssi-outbox-post-new .opn-title-pills li i {
  font-size: 1em;
  margin: 0 0.6rem 0 0;
}
ssi-outbox-post-new .opn-title-pills li p {
  color: #43537f;
  line-height: 1;
  font-size: 1.2em;
  font-weight: 900;
  text-rendering: geometricPrecision;
  margin: 0;
  white-space: nowrap;
}
ssi-outbox-post-new li.opn-title-pill-warning {
  background-color: #fff1ed;
}
ssi-outbox-post-new li.opn-title-pill-warning i {
  color: #e34916;
}
ssi-outbox-post-new .opn-meta {
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  border-color: inherit;
  flex-wrap: wrap;
  margin: 0 -0.4em;
}
ssi-outbox-post-new .opn-meta-list {
  margin: 0.4em 0.6em 0.4em 0;
  padding: 0;
}
ssi-outbox-post-new .opn-meta-list li {
  display: inline-block;
  white-space: nowrap;
}
ssi-outbox-post-new .opn-meta-list li:not(:last-child):after {
  content: "|";
  display: inline-block;
  margin: 0 0.6em;
  font-size: 1.2rem;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-outbox-post-new .opn-meta-list p {
  display: inline-block;
  font-size: 1.2em;
  font-weight: 900;
  text-rendering: geometricPrecision;
  margin: 0;
}
ssi-outbox-post-new .opn-meta-date {
  display: inline-flex;
  align-items: center;
  padding: 0.4em 0.6em;
  background-color: rgba(141, 164, 229, 0.1);
  border: 1px solid #8da4e5;
  border-radius: 0.4em;
  margin: 0.4em 0 0.4em 0.4em;
  height: 2em;
}
ssi-outbox-post-new .opn-meta-date i {
  color: #43537f;
  margin: 0 0.6rem 0 0;
  font-size: 1.1em;
}
ssi-outbox-post-new .opn-meta-date p {
  color: #43537f;
  font-size: 1.2em;
  margin: 0;
  line-height: 1;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-outbox-post-new .opn-meta-date ul {
  display: inline-block;
  margin: 0;
  padding: 0;
}
ssi-outbox-post-new .opn-meta-date li {
  display: inline-flex;
  white-space: nowrap;
  align-items: center;
}
ssi-outbox-post-new .opn-meta-date li:not(:last-child):after {
  content: "|";
  display: inline-block;
  margin: 0 0.6em;
  font-size: 1.2rem;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-outbox-post-new .opn-meta-date-failed {
  border-color: #e34916;
  background-color: transparent;
}
ssi-outbox-post-new .opn-meta-date-failed i {
  color: #e34916;
}
ssi-outbox-post-new .opn-meta-author {
  display: inline-flex;
  align-items: center;
  padding: 0.4em 0.6em;
  border: 0.1em solid;
  border-color: inherit;
  border-radius: 0.4em;
  margin: 0.4em 0 0.4em 0.4em;
  height: 2em;
}
ssi-outbox-post-new .opn-meta-author i {
  color: #838eab;
  margin: 0 0.6rem 0 0;
  font-size: 1.1em;
}
ssi-outbox-post-new .opn-meta-author p {
  color: #43537f;
  font-size: 1em;
  margin: 0;
  line-height: 1;
  font-weight: 900;
  text-rendering: geometricPrecision;
  text-transform: uppercase;
}
ssi-outbox-post-new .opn-body {
  padding-top: 0.8em;
  padding-bottom: 0.8em;
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  ssi-outbox-post-new .opn-body {
    flex-wrap: nowrap;
  }
}
ssi-outbox-post-new .opn-body p {
  color: #101525;
  font-size: 1.4em;
  line-height: 2.4rem;
  margin: 0.5em 0 0.6em;
}
ssi-outbox-post-new .opn-body .post-video {
  width: 108px;
  padding-bottom: 68%;
  background-color: #e7ecfc;
  border-radius: 5px;
  position: relative;
}
ssi-outbox-post-new .opn-body .post-video i {
  position: absolute;
  font-size: 4em;
  margin: 0 auto;
  width: 40px;
  display: block;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
ssi-outbox-post-new .opn-gallery {
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  margin: 0 auto 0.4em;
}
@media (min-width: 768px) {
  ssi-outbox-post-new .opn-gallery {
    margin: -0.5em -1.7em -0.5em 5.7em;
  }
}
ssi-outbox-post-new .opn-gallery img {
  display: block;
  border-radius: 1em;
  overflow: hidden;
  background-color: #eee;
  width: auto;
  width: 10.8em;
  height: 7.4em;
  object-fit: cover;
  cursor: pointer;
}
ssi-outbox-post-new .opn-gallery img + img {
  position: absolute;
  top: 0.6em;
  left: 0.3em;
  box-shadow: 0 0.5em 1.5em rgba(116, 118, 132, 0.25);
}
ssi-outbox-post-new .opn-gallery-multiple {
  margin-right: -0.4em;
  padding: 0.8em 0 0;
}
ssi-outbox-post-new .opn-gallery-multiple img {
  z-index: 2;
}
ssi-outbox-post-new .opn-gallery-multiple img + img {
  z-index: 1;
  margin-top: -0.5em;
  margin-left: -1em;
}
ssi-outbox-post-new .opn-gallery-badge {
  background-color: #fff;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -1.3em;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 2.4em;
  width: 2.4em;
  color: #14bae3;
  font-weight: 900;
  text-rendering: geometricPrecision;
  font-style: italic;
  border-radius: 50%;
  box-shadow: 0 0.5em 1.5em rgba(116, 118, 132, 0.25);
  z-index: 3;
}
ssi-outbox-post-new .opn-footer {
  border-top: 0.1em solid;
  border-color: inherit;
  color: #43537f;
  padding-top: 1em;
  padding-bottom: 1em;
  min-height: 6em;
}
ssi-outbox-post-new .opn-actions {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}
ssi-outbox-post-new .opn-actions-left {
  flex: 1 1 auto;
  margin: 0 0 0 -1.3em;
  padding: 0;
  display: flex;
  align-items: center;
}
ssi-outbox-post-new .opn-actions-left .opn-actions-item {
  display: inline-flex;
  align-items: center;
  margin: 0 1em;
  cursor: pointer;
}
ssi-outbox-post-new .opn-actions-left .opn-actions-item a,
ssi-outbox-post-new .opn-actions-left .opn-actions-item button {
  display: flex;
  color: inherit;
  padding: 0.8em 0.3em;
  outline: none;
  background: transparent;
  border: 0.1em solid transparent;
  text-align: left;
}
ssi-outbox-post-new .opn-actions-left .opn-actions-item a:hover, ssi-outbox-post-new .opn-actions-left .opn-actions-item a:focus,
ssi-outbox-post-new .opn-actions-left .opn-actions-item button:hover,
ssi-outbox-post-new .opn-actions-left .opn-actions-item button:focus {
  text-decoration: none;
}
ssi-outbox-post-new .opn-actions-left .opn-actions-item a small,
ssi-outbox-post-new .opn-actions-left .opn-actions-item button small {
  position: absolute;
  z-index: 5;
  font-size: 10px;
  top: 1px;
  left: 15px;
  font-weight: bold;
}
ssi-outbox-post-new .opn-actions-left .opn-actions-item i {
  font-size: 1.4em;
  margin: 0 0.8rem 0 0;
}
ssi-outbox-post-new .opn-actions-left .opn-actions-item i.tag {
  font-size: 1.6em;
}
ssi-outbox-post-new .opn-actions-left .opn-actions-item i.ssi-first-comment {
  font-size: 1.8em;
}
ssi-outbox-post-new .opn-actions-left .opn-actions-item p {
  font-size: 1.4em;
  font-weight: 900;
  text-rendering: geometricPrecision;
  margin: 0;
}
ssi-outbox-post-new ssi-tooltip-window {
  border-radius: 15px;
  z-index: 2;
  background-color: transparent;
}
ssi-outbox-post-new .opn-actions-right {
  margin-bottom: 0;
}
ssi-outbox-post-new .opn-actions-right i {
  font-size: 14px;
  color: #e34916;
  display: flex;
  align-items: center;
  background-color: #fff1ed;
  border-radius: 50px;
  padding: 6px;
}
ssi-outbox-post-new .delete-opn-tooltip {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  max-width: 310px;
  height: 100%;
  box-shadow: 0 1.8em 3.5em rgba(131, 142, 171, 0.35);
}
ssi-outbox-post-new .delete-opn-tooltip .buttons-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
ssi-outbox-post-new .delete-opn-tooltip .buttons-container button {
  text-transform: uppercase;
  padding: 0 5px;
  background-color: transparent;
}
ssi-outbox-post-new .delete-opn-tooltip .buttons-container button span {
  font-weight: 900;
  letter-spacing: 1px;
}
ssi-outbox-post-new .delete-opn-tooltip h5 {
  color: #838eab;
  white-space: normal;
  text-transform: none;
  letter-spacing: 0;
  line-height: 20px;
  font-weight: bold;
}

.post-actions-right {
  margin-bottom: 0;
}
.post-actions-right i {
  font-size: 14px;
  color: #e34916;
  display: flex;
  align-items: center;
  background-color: #fff1ed;
  border-radius: 50px;
  padding: 6px;
}

.delete-post-tooltip {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  max-width: 310px;
  height: 100%;
  box-shadow: 0 1.8em 3.5em rgba(131, 142, 171, 0.35);
}
.delete-post-tooltip .buttons-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.delete-post-tooltip .buttons-container button {
  text-transform: uppercase;
  padding: 0 5px;
  background-color: transparent;
}
.delete-post-tooltip .buttons-container button span {
  font-weight: 900;
  letter-spacing: 1px;
}
.delete-post-tooltip h5 {
  color: #838eab;
  white-space: normal;
  text-transform: none;
  letter-spacing: 0;
  line-height: 20px;
  font-weight: bold;
}