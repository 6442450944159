ssi-validate-reply-box-content .post-box-content-body {
  display: flex;
  padding: 0;
}
ssi-validate-reply-box-content .post-box-content-status {
  flex: 0 0 auto;
  width: 60px;
}
ssi-validate-reply-box-content .post-box-content-status .img-circle {
  max-width: 42px;
  cursor: pointer;
}
ssi-validate-reply-box-content .post-box-content-status .img-circle:nth-child(n+2) {
  margin-top: -10px;
}
ssi-validate-reply-box-content .post-box-selected {
  background-color: #494f5b;
  color: #14bae3;
  border-radius: 50%;
  height: 42px;
  width: 42px;
  text-align: center;
  line-height: 46px;
  vertical-align: middle;
  cursor: pointer;
}
ssi-validate-reply-box-content .post-box-selected .post-box-selected-icon {
  font-size: 22px;
}
ssi-validate-reply-box-content .post-box-selected .img-circle {
  display: none;
}
ssi-validate-reply-box-content .post-box-content {
  flex: 1 1 auto;
}
ssi-validate-reply-box-content .post-box-content-meta {
  font-size: 12px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}
ssi-validate-reply-box-content .post-box-content-meta a,
ssi-validate-reply-box-content .post-box-content-meta p {
  white-space: pre-wrap;
  font-weight: 500;
  line-height: 1.8;
  color: #101525;
  font-weight: 700;
}
ssi-validate-reply-box-content .post-box-content-meta span,
ssi-validate-reply-box-content .post-box-content-meta p {
  color: #101525;
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 0;
  display: inline-block;
  white-space: nowrap;
}
ssi-validate-reply-box-content .post-box-content-meta > a {
  color: #14bae3;
  font-weight: 900;
}
ssi-validate-reply-box-content .post-box-content-meta i {
  font-size: 10px;
  color: #14bae3;
}
ssi-validate-reply-box-content .post-box-content-meta i.network-icon {
  font-size: 10px;
  color: initial;
}
ssi-validate-reply-box-content .post-box-content-divide {
  padding: 0 5px;
}