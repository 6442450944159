@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import './../../../../angularjs/common/sass/variables';
@import './../../../../angularjs/common/sass/mixins';
@import '~/styles/colors';

ssi-outbox-post-menu {
  display: block;

  .outbox-menu {
    .outbox-menu-trigger {
      width: 26px;
      height: 40px;
      box-shadow: none;
      border-radius: 0;
      border: 0;

      i {
        font-size: 24px;
      }
    }

    .outbox-menu-items {
      display: none;
    }

    .outbox-menu-items {
      background-color: #ffffff;
      padding: 10px;
      min-width: 35px;
      border-radius: 20px;
      text-align: center;
      box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.2);

      a {
        padding: 0 10px;

        i {
          color: $lmode-helper-text;
        }
      }

      a:hover {
        text-decoration: none;
      }
    }
  }

  .outbox-menu:not(.no-hover):hover {
    .outbox-menu-trigger {
      display: none;
    }
    .outbox-menu-items {
      display: flex;
      align-items: center;

      .text-danger {
        i {
          color: $secondary-red;
        }
      }
    }
  }
}
