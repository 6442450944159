@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-ktd-grid {
  display: block;
  position: relative;
  width: 100%;

  ssi-ktd-grid-item {
    &.ktd-grid-item-dragging {
      z-index: 1000;
    }

    &.no-transitions {
      transition: none !important;
    }
  }

  .ktd-grid-item-placeholder {
    position: absolute;
    background-color: rgba(20, 186, 227, 0.1);
    border: 2px dashed #14bae3;
    border-radius: 10px;
    padding: 10px;
    // opacity: 0.2;
    z-index: 0;
    transition-property: transform;
    transition: all 150ms ease;
  }
}
