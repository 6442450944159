ssi-manage-accounts .add-accounts-panels {
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -10px;
  margin-right: -10px;
}
ssi-manage-accounts .add-accounts-panels .panel {
  flex-basis: calc(25% - (10px * 2));
  margin: 0 10px;
  margin-bottom: 20px;
}
ssi-manage-accounts .add-accounts-panels h1 {
  color: #555;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1em;
  margin: 2em 0;
  letter-spacing: 1px;
}
ssi-manage-accounts .add-accounts-panels img {
  margin: 4em 0;
  min-height: 58px;
}