@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../../angularjs/common/sass/include-media';
@import '~/styles/colors';

$margin-between-boxes: 20px;
$box-radius: 12px;
$box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.35);
$box-bg-color: #fff;

ssi-campaign ssi-top-posts-section {
  .section-content {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 30px 40px;
    background-color: #fff;
    border-radius: $box-radius;
  }
}
