.boost-confirmed {
  text-align: center;
  margin: 4em 0;
}
.boost-confirmed h3 {
  margin-bottom: 1em;
}
.boost-confirmed i {
  display: block;
  font-size: 4em;
  margin-bottom: 20px;
}