@import "./src/apps/angularjs/common/sass/global-variables.scss";

$unit: 2.5px;

$ssi-live-chat-message-tags-background-color: #fff;
$ssi-live-chat-message-tags-foreground-color: #b8ccd9;

ssi-live-chat-message-tags {
  background: $ssi-live-chat-message-tags-background-color;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0,0,0,.175);
  display: flex;
  position: absolute;
  right: 12px;
  top: 64px;

  & > * {
    display: flex;
  }

  & > .container {
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    flex-shrink: 0;
    max-height: calc(40vh);
    min-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 10px;
    width: 200px;
  
    .tags-filter {
      border: none;
      border-bottom: solid 1px $ssi-live-chat-message-tags-foreground-color;
      color: $ssi-live-chat-message-tags-foreground-color;
      flex-grow: 0;
      flex-shrink: 0;
      font-style: italic;
      margin: ($unit * 4) ($unit * 4);
      outline: none;
      text-transform: lowercase;
  
      &::placeholder {
        color: $ssi-live-chat-message-tags-foreground-color;
      }
    }
  
    ul {
      flex-flow: column nowrap;
      flex-grow: 0;
      flex-shrink: 0;
      margin: 0;
      padding: 0;

      &.active-tags {
        & > li {
          background-color: $ssi-live-chat-message-tags-foreground-color;
          
          color: $ssi-live-chat-message-tags-background-color;
          margin: $unit $unit $unit 0;
          padding: ($unit * 2) ($unit * 4);

          .text {
            margin-right: $unit * 2;
          }
        }
      }

      & > li {
        align-items: center;
        border-radius: $unit * 4;
        color: $ssi-live-chat-message-tags-foreground-color;
        cursor: pointer;
        flex-shrink: 0;
        font-style: italic;
        font-weight: 400;
        justify-content: space-between;
        padding: ($unit * 2) ($unit * 4);

        &.selected,
        &:hover {
          background-color: $ssi-live-chat-message-tags-foreground-color;
          color: $ssi-live-chat-message-tags-background-color;
        }
      } 
    }
  }
}
