ssi-inbox-priority-indicator .inbox-priority-container .inbox-priority-dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
ssi-inbox-priority-indicator .inbox-priority-container .inbox-priority-badge {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
ssi-inbox-priority-indicator .inbox-priority-container .inbox-priority-badge.S {
  height: 20px;
  width: 20px;
}
ssi-inbox-priority-indicator .inbox-priority-container .inbox-priority-badge.M {
  height: 26px;
  width: 26px;
}
ssi-inbox-priority-indicator .inbox-priority-container .inbox-priority-badge span {
  color: #fff;
  font-size: 10px;
  font-weight: 900;
}
ssi-inbox-priority-indicator .inbox-priority-container .inbox-priority-text {
  height: 36px;
  border-radius: 10px;
  background-color: rgba(137, 164, 234, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
}
ssi-inbox-priority-indicator .inbox-priority-container .inbox-priority-text span {
  color: #43537f;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-left: 5px;
}
ssi-inbox-priority-indicator .inbox-priority-container .P1 {
  background-color: #f4490f;
}
ssi-inbox-priority-indicator .inbox-priority-container .P2 {
  background-color: #f0b427;
}
ssi-inbox-priority-indicator .inbox-priority-container .P3 {
  background-color: #425dec;
}
ssi-inbox-priority-indicator .inbox-priority-container .P4 {
  background-color: #89a4ea;
}
ssi-inbox-priority-indicator .inbox-priority-container .P5 {
  background-color: #12aca4;
}
ssi-inbox-priority-indicator .inbox-priority-container.single-value {
  background: #fff;
  border-radius: 10px;
  padding: 8px;
}