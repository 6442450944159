@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-engagement-build {
  .engagement-analytics-build-container {
    display: flex;
    flex-direction: column;

    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100px;
      padding: 0 60px;
      background-color: $lgrey-background;
      box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.25);
      z-index: 1;

      h3 {
        font-size: 14px;
        color: $lmode-title-text;
        font-weight: 900;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
      h2 {
        color: $lmode-title-text;
        font-weight: 900;
        font-size: 20px;
      }
    }

    .body {
      flex-basis: calc(100vh - 168px);
      min-height: calc(100vh - 168px);
      padding: 0 20px 20px 20px;
      background-color: $lgrey-background;
      overflow: auto;

      .body-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        background-color: #fff;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
      }
    }
  }
}
