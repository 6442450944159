ssi-widget-profile-search {
  height: 100%;
  width: 100%;
}
ssi-widget-profile-search .dashboard-widget {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
@media (min-width: 600px) {
  ssi-widget-profile-search .dashboard-widget ssi-menu {
    display: none;
  }
  ssi-widget-profile-search .dashboard-widget .post-details.time-visibility {
    display: flex;
  }
  ssi-widget-profile-search .dashboard-widget .post-details.time-visibility.placed-below {
    display: none;
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding: 10px 0 0 10px;
  }
}
@media (min-width: 1201px) {
  ssi-widget-profile-search .dashboard-widget.view-1x2 .desktop-menu .menu-list-item:last-child, ssi-widget-profile-search .dashboard-widget.view-1x2 .desktop-menu .menu-list-item:nth-child(3) {
    display: none;
  }
  ssi-widget-profile-search .dashboard-widget.view-1x2 ssi-menu {
    display: block;
  }
  ssi-widget-profile-search .dashboard-widget.view-1x2 .post-details.time-visibility {
    display: none;
  }
  ssi-widget-profile-search .dashboard-widget.view-1x2 .post-details.time-visibility.placed-below {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding: 10px 0 0 10px;
  }
  ssi-widget-profile-search .dashboard-widget.view-2x2 ssi-menu {
    display: none;
  }
}
ssi-widget-profile-search .dashboard-widget .article-grid {
  height: 100%;
  overflow: auto;
  margin-top: 7px;
}
ssi-widget-profile-search .dashboard-widget .form-field {
  padding: 10px 20px;
  font-weight: 900;
}
ssi-widget-profile-search .dashboard-widget .form-field .label-clear-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
}
ssi-widget-profile-search .dashboard-widget .form-field label.field-title {
  font-size: 10px;
  line-height: 12px;
  padding-left: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #43537f;
  font-weight: 900;
}
ssi-widget-profile-search .dashboard-widget .form-field:last-child {
  padding-top: 0;
}
ssi-widget-profile-search .dashboard-widget ssi-dropdown-select-2 {
  width: 100%;
}
ssi-widget-profile-search .dashboard-widget ssi-dropdown-select-2 .dd-head {
  width: 100%;
}
ssi-widget-profile-search .dashboard-widget ssi-dropdown-select-2 .dd-body {
  width: 100%;
}
ssi-widget-profile-search .dashboard-widget ssi-dropdown-select-2 .select-accounts-label {
  display: flex;
  align-items: center;
}
ssi-widget-profile-search .dashboard-widget ssi-dropdown-select-2 .select-accounts-label span {
  padding-left: 7px;
}
ssi-widget-profile-search .dashboard-widget .message-info {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-align: center;
  padding-top: 20px;
  margin-top: 20px;
}
ssi-widget-profile-search .dashboard-widget .message-info.view-1x1, ssi-widget-profile-search .dashboard-widget .message-info.view-2x1, ssi-widget-profile-search .dashboard-widget .message-info.view-3x1, ssi-widget-profile-search .dashboard-widget .message-info.view-4x1 {
  justify-content: start;
  padding-top: 10px;
}
ssi-widget-profile-search .dashboard-widget .message-info i {
  color: #b4bbce;
  font-size: 37.5px;
}
ssi-widget-profile-search .dashboard-widget .message-info h5 {
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
  color: #43537f;
}
ssi-widget-profile-search .dashboard-widget .message-info p {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  max-width: 222px;
  color: #43537f;
}
ssi-widget-profile-search .dashboard-widget .message-info p.view-1x1, ssi-widget-profile-search .dashboard-widget .message-info p.view-2x1, ssi-widget-profile-search .dashboard-widget .message-info p.view-3x1, ssi-widget-profile-search .dashboard-widget .message-info p.view-4x1 {
  max-width: 312px;
}
ssi-widget-profile-search .dashboard-widget .message-info ssi-spinner {
  margin-bottom: 10px;
}
ssi-widget-profile-search .form-container {
  padding-top: 20px;
}
ssi-widget-profile-search .form-container.box-shadowed {
  box-shadow: 0px 9px 7px 0px #c3c9d8;
}
ssi-widget-profile-search .input-with-submit {
  display: flex;
  width: 100%;
}
ssi-widget-profile-search .input-with-submit input {
  border: 1px solid #c3c9d8;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 10px;
  outline: none;
  width: 100%;
  padding-left: 20px;
}
ssi-widget-profile-search .input-with-submit input::placeholder {
  color: #6a759a;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
}
ssi-widget-profile-search .input-with-submit .search-submit {
  display: inline-flex;
  background-color: #14bae3;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  white-space: nowrap;
}
ssi-widget-profile-search .input-with-submit .search-submit i {
  margin-right: 5px;
}
ssi-widget-profile-search .input-with-submit .search-submit button {
  font-weight: 700;
  color: #838eab;
  background-color: #f4f4fa;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid #c3c9d8;
  border-left: none;
  padding: 10px;
  appearance: none;
  outline: none;
}
ssi-widget-profile-search .input-with-submit.active input {
  border-color: #14bae3;
  color: #101525;
  font-size: 14px;
  font-weight: 900;
}
ssi-widget-profile-search .input-with-submit.active button {
  color: #fff;
  background-color: #14bae3;
  border-color: #14bae3;
}
ssi-widget-profile-search .input-with-submit .tags-input-tags {
  flex: 0 0 auto;
  margin: 0;
  padding: 0.2em;
}
ssi-widget-profile-search .input-with-submit .tags-input-tag {
  font-size: 1.2em;
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0 3rem 0 1.1rem;
  margin: 0 0.2rem 0 0;
  flex: 0 1 auto;
  height: 40px;
  background-color: #f4f4fa;
  border: 0.1rem solid #c3c9d8;
  border-radius: 0.8rem;
}
ssi-widget-profile-search .input-with-submit .tags-input-tag span {
  font-size: 1.2rem;
  line-height: 1.2rem;
  min-height: 1rem;
  color: #101525;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-widget-profile-search .input-with-submit .tags-input-tag-action {
  position: absolute;
  display: block;
  right: 0.6rem;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 0 0.5rem;
  border-width: 0;
  background-color: transparent;
  line-height: 0;
}
ssi-widget-profile-search .profiles-list {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  height: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(215px, 1fr));
  grid-gap: 15px;
  margin: 0;
}
ssi-widget-profile-search .load-more-button {
  margin-top: auto;
  margin-bottom: 10px;
  appearance: none;
  border: none;
  padding: 0;
  color: #fff;
  border-radius: 10px;
  background-color: #14bae3;
  padding: 10px;
  font-size: 14px;
  font-weight: 900;
  line-height: 14px;
  grid-column: 1/-1;
  max-width: fit-content;
  justify-self: center;
  align-self: center;
}
ssi-widget-profile-search .load-more-button i {
  color: #fff;
  margin-right: 5px;
}
ssi-widget-profile-search .profile-container {
  flex: 1 1 215px;
  height: 280px;
  border: 1px solid #c3c9d8;
  border-radius: 10px;
  padding: 20px;
}
ssi-widget-profile-search .profile-container .profile-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
ssi-widget-profile-search .profile-container .profile-box img {
  border: 4px solid #e8ecf9;
  border-radius: 50%;
  height: 62px;
  width: 62px;
}
ssi-widget-profile-search .profile-container .profile-box .name {
  display: block;
  color: #14bae3;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 900;
  line-height: 16.8px;
  text-align: center;
  margin-top: 5px;
}
ssi-widget-profile-search .profile-container .profile-box .username,
ssi-widget-profile-search .profile-container .profile-box .description,
ssi-widget-profile-search .profile-container .profile-box .location {
  font-size: 12px;
  line-height: 14.4px;
  font-weight: 700;
  margin-top: 5px;
  text-align: center;
}
ssi-widget-profile-search .profile-container .profile-box .description {
  color: #43537f;
  line-height: 18px;
  margin: 5px 0;
  max-height: 70px;
  overflow: auto;
}
ssi-widget-profile-search .profile-container .profile-box .location {
  display: block;
  font-style: italic;
  line-height: 12px;
  margin: 10px 0 5px 0;
}
ssi-widget-profile-search .profile-container .profile-box .location i {
  display: block;
  font-size: 20px;
  color: #43537f;
  margin-bottom: 5px;
}
ssi-widget-profile-search .profile-container .profile-box button {
  margin-top: auto;
  appearance: none;
  border: none;
  padding: 0;
  color: #fff;
  border-radius: 10px;
  background-color: #14bae3;
  padding: 10px;
  font-size: 14px;
  font-weight: 900;
  line-height: 14px;
}
ssi-widget-profile-search .profile-container .profile-box button i {
  color: #fff;
  margin-right: 5px;
}
ssi-widget-profile-search ssi-stream-post {
  display: block;
  padding: 10px;
}
@media (max-width: 599px) {
  ssi-widget-profile-search ssi-stream-post .desktop-menu .menu-list-item:last-child {
    display: none;
  }
  ssi-widget-profile-search ssi-stream-post .post-details.time-visibility {
    display: none;
  }
  ssi-widget-profile-search ssi-stream-post .post-details.time-visibility.placed-below {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding: 10px 0 0 10px;
  }
}
@media (max-width: 449px) {
  ssi-widget-profile-search ssi-stream-post .desktop-menu .menu-list-item:last-child, ssi-widget-profile-search ssi-stream-post .desktop-menu .menu-list-item:nth-child(3) {
    display: none;
  }
}
ssi-widget-profile-search ssi-stream-post .repost-menu .menu-list {
  max-height: 230px;
  overflow: auto;
}
ssi-widget-profile-search .scrollable-content {
  display: flex;
  flex-direction: column;
}