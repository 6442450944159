@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

.my-details {
  background-color: $lmgrey-background;
  padding: 2em 4em;
  .container {
    position: relative;
    background-color: #fff;
    border-radius: 25px;
    padding: 6em 0;
  }
  .form {
    width: 300px;
    margin: 0 auto;
    .form-submit {
      position: absolute;
      top: -16px;
      width: 190px;
      right: 0;
      text-align: center;
    }
  }
  .avatar {
    text-align: center;
    margin: 2em 0;
    i {
      margin: 0 0 0.4em 0;
      font-size: 40px;
      color: $lmode-helper-text;
    }
    .avatar-label {
      margin: 0 0 2em 0;
      padding: 0 3em;
      display: block;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 900;
      line-height: 2.5em;
      color: $lmode-helper-text;
      letter-spacing: 1px;
    }
    .avatar-image {
      position: relative;
      display: inline-block;
      margin: 0 0 2em 0;
      img {
        width: 150px;
        border-radius: 50%;
      }
    }
    .avatar-delete {
      position: absolute;
      width: 40px;
      height: 40px;
      top: 0;
      right: 0;
      padding: 0;
      border-radius: 100%;
      border: 3px solid #fff;
      background-color: $primary-red;
      i {
        margin: 0;
        font-size: 20px;
        color: $secondary-red;
      }
    }
    button {
      margin: 0;
    }
  }

  orlo-text-input input:disabled {
    opacity: 0.6;
  }
}
