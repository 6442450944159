ssi-conversation-hold .conversation-hold-container {
  display: inline-block;
}
ssi-conversation-hold .conversation-hold-container .hold-btn {
  white-space: nowrap;
}
ssi-conversation-hold .conversation-hold-container .hold-btn i {
  font-weight: 400;
}
ssi-conversation-hold .conversation-hold-container i.hold-icon-standalone {
  font-weight: 400;
}