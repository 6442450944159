@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import "../../../../../../library/sass/live-chat/colours.scss";
@import "../../../../../angularjs/common/sass/mixins";
@import "../../../../../angularjs/common/sass/variables";

$component-height: 72px;
$unit: 2.5px;
$passive-text-colour: #A4B5BE;

ssi-live-chat-push-mode-agent-encouragement {
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  
  .encouragement {
    align-items: center;
    flex-flow: column nowrap;
    flex-shrink: 0;
    text-align: center;

    .encouragement-icon {
      color: $live-chat-passive-text-colour;
      font-size: 15vh;
    }

    .encouragement-message {
      color: $live-chat-passive-text-colour;
      font-size: 1.4em;
      font-weight: 600;

      & > * {
        padding: 0 $unit;
      }
    }
  }

}
