@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-account-summary-card {
  font-size: 1rem;

  .asc {
    display: inline-flex;
    background-color: #fff;
    box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
    border-radius: 1.2em;
    padding: 1.2em;
    width: 100%;
    min-width: 26.8em;
    margin: 0 0 2em;

    @media print {
      border: 1px solid black;
    }
  }

  .asc-left {
    flex: 0 0 4.6em;
    padding-right: 1em;
  }

  .asc-thumb {
    height: 4.6em;
    width: 4.6em;
    overflow: hidden;

    i {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      color: #fff;
      line-height: 1;
      background-color: $secondary-blue;
      font-size: 1.8em;

      &.ssi-sms:before {
        margin-top: 0.4em;
      }

      &.fa-twitter,
      &.ssi-x-logo {
        font-size: 2em;
        background-color: #1da1f2;
      }

      &.fa-facebook {
        font-size: 1.7em;
        background-color: #1877f2;
      }

      &.fa-linkedin {
        font-size: 1.8em;
        background-color: #2867b2;
      }

      &.fa-youtube-play {
        font-size: 2em;
        background-color: #f00;
      }

      &.fa-instagram {
        font-size: 2em;
        background: linear-gradient(180deg, #635fcc, #d43082 37%, #fec86b 100%);
      }

      &.ssi-whatsapp {
        font-size: 1.7em;
        background-color: #56cd63;
      }
    }
  }

  .asc-right {
    flex: 1 0 auto;
    text-rendering: geometricPrecision;
  }

  .asc-title {
    font-weight: 900;
    margin: 0.7rem 0 0;
    font-size: 1.6em;
    color: #46547c;
  }

  .asc-meta {
    font-weight: 700;
    margin: 0.1em 0 1em;
    color: $lmode-helper-text;
  }

  .asc-stats {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 2.6em 0.6em 0.5em 0;
  }

  .asc-stat {
    display: inline-block;
    margin: 0 2.2em 0 0;

    &:last-child {
      margin-right: 0;
    }

    &:nth-child(2n) {
      .asc-stat-label:before {
        background-color: $primary-active;
      }
    }
  }

  .asc-stat-data {
    color: $lmode-title-text;
    font-size: 2em;
    margin: 0;
    font-weight: 900;
  }

  .asc-stat-label {
    color: $lmode-helper-text;
    font-weight: 900;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-size: 1em;
    margin: 0;

    &:before {
      content: '';
      display: block;
      width: 6em;
      height: 0.4em;
      border-radius: 10em;
      background-color: $secondary-blue;
      margin: 0.6em 0 0.8em;
    }
  }
}
