@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../angularjs/common/sass/variables';
@import '../../../../../angularjs/common/sass/mixins';
@import '../publisher.variables.scss';
@import '../publisher.mixins.scss';
@import '~/styles/colors';

$publisher-schedule-left-bg: #596065;
$brand: $primary-active;
$ssi-publisher-schedule-responsive-width: 885px;
$ssi-publisher-schedule-mobile-width: 768px;

ssi-publisher-schedule {
  .publisher-secondary-view-right-body {
    height: 100%;

    @media (max-width: $ssi-publisher-schedule-responsive-width) {
      margin-bottom: 100px;
    }

    ssi-publisher-secondary-view-nav {
      margin: 35px 0 0;
      @media (max-width: $ssi-publisher-schedule-responsive-width) {
        margin: 0px;
      }
    }
  }

  .publisher-secondary-view-right-body-copy {
    margin: 0;
  }

  .publisher-schedule-title {
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 900;
    color: $lmode-body-text;
  }

  .publisher-schedule-body {
    color: $lmode-body-text;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin: 25px 45px;
    @media (max-width: $ssi-publisher-schedule-responsive-width) {
      margin: 10px;
    }
    b {
      font-weight: 900;
    }
  }

  .publisher-secondary-view-schedule-left {
    display: flex;
    flex: 1 1 auto;
    padding-top: 0;
    box-shadow: none;
    overflow: auto;
    height: 100%;
    flex-direction: column;

    @media (max-width: $ssi-publisher-schedule-responsive-width) {
      flex: 0 0 auto;
      height: auto;
    }
  }

  .publisher-secondary-view-left-body {
    position: relative;
    align-items: flex-start;
    margin: 0 8px 8px 8px;
    padding: 50px 50px 0 30px !important;
    display: flex;
    background-color: #fff;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    @media (max-width: $ssi-publisher-schedule-responsive-width) {
      flex-wrap: wrap;
      padding: 20px !important;
      overflow-y: scroll;
      // max-height: 51vh;
      justify-content: center;
    }

    .back-btn {
      position: absolute;
      top: 15px;
      left: 30px;
    }
  }

  .publisher-secondary-view-right {
    flex: 0 1 390px !important;
    width: 390px;
    @media (max-width: $ssi-publisher-schedule-responsive-width) {
      width: auto;
    }
    padding: 0 10px;
  }

  .label-publisher-scheduled {
    background-color: $publisher-schedule-day-scheduled-color;
  }

  .label-publisher-disabled {
    background-color: $publisher-schedule-day-disabled-color;
    color: $publisher-schedule-left-bg;
  }

  .label {
    border-radius: 20px;
  }

  .publisher-schedule-tip {
    margin-top: 25px;
    color: $brand;

    span {
      font-size: 10px;
      line-height: 16px;
      font-weight: 900;
      letter-spacing: 1px;
      cursor: pointer;
    }

    i {
      font-size: 16px;
      vertical-align: middle;
    }
  }

  .publisher-schedule-list {
    position: relative;
    overflow-y: auto;
    padding: 0 10px;
    height: 220px;
    @media (max-width: $ssi-publisher-schedule-responsive-width) {
      height: 120px;
    }
  }

  .publisher-schedule-list-item {
    position: relative;
    background-color: #fff;
    color: $lmode-body-text;
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    padding: 12px 40px 12px 18px;
    margin-bottom: 8px;
    border-radius: 10px;

    i {
      font-size: 14px;
    }

    &:before {
      // position: absolute;
      content: '';
      display: inline-block;
      background-color: #b2c614;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      margin: 0 5px -1px 0;

      // width: 10px;
      // height: 100%;
      // top: 0;
      // left: 0;
      // border-top-left-radius: 10px;
      // border-bottom-left-radius: 10px;
    }
  }

  .publisher-schedule-icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 50%;
    color: $primary-active;
    font-weight: 700;
    font-size: 12px;
    margin: 0 0 20px;
    line-height: 30px;
    vertical-align: middle;
  }

  .publisher-schedule-list-meta p {
    color: $lmode-helper-text;
    line-height: 20px;
    font-size: 12px;
    font-weight: 700;
    margin: 0;
  }

  .publisher-schedule-list-item-remove {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 6px;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    vertical-align: middle;
    text-align: center;
    width: 30px;
    color: $secondary-red;
    border-radius: 50%;

    &:hover,
    &:focus {
      background-color: $primary-red;
    }
  }

  .publisher-schedule-list-remaining {
    @include align-vertical(60px);
  }

  .schedule-btn {
    flex: 0 0 auto;
    width: auto;
    padding: 16px 30px;
    font-size: 14px;
    font-weight: 900;
    line-height: 14px;
    border-radius: 40px;
    border-width: 0;
    text-rendering: geometricPrecision;

    &:focus {
      outline: revert;
    }

    i:first-child {
      margin-left: -15px;
      padding-right: 15px;
      font-size: 12px;
    }

    &.schedule-btn-light,
    .schedule-btn-light {
      color: $brand;
      background-color: $grey-background;
    }

    &.schedule-btn-dark,
    .schedule-btn-dark {
      background-color: $secondary-red;
      color: #fff;
      font-weight: 900;

      &:focus,
      &:hover {
        background-color: #f67246;
      }
    }

    &.schedule-btn-green,
    .schedule-btn-green {
      background-color: #b2c614;

      &:focus,
      &:hover {
        background-color: #98b700;
      }
    }
  }
}
