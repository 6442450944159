ssi-query-builder-condition {
  font-size: 1rem;
}
ssi-query-builder-condition .query-container-builder-row {
  display: flex;
  margin: 0;
}
ssi-query-builder-condition .query-container-builder-row-single .query-container-builder-row-tree-label {
  position: absolute;
  top: 1em;
  right: 4.8em;
}
ssi-query-builder-condition .query-container-builder-row-single .query-container-builder-row-tree-guides {
  position: absolute;
  width: 4em;
  top: 2.3em;
  right: 0.8em;
  border: 0.1em solid #c3c9d8;
}
ssi-query-builder-condition .query-container-builder-row-parent .query-container-builder-row-tree-label, ssi-query-builder-condition .query-container-builder-row-parent-child .query-container-builder-row-tree-label, ssi-query-builder-condition .query-container-builder-row-child .query-container-builder-row-tree-label {
  position: absolute;
  bottom: 0;
  right: 4.8em;
}
ssi-query-builder-condition .query-container-builder-row-parent .query-container-builder-row-tree-guides, ssi-query-builder-condition .query-container-builder-row-parent-child .query-container-builder-row-tree-guides, ssi-query-builder-condition .query-container-builder-row-child .query-container-builder-row-tree-guides {
  position: absolute;
  width: 6.4em;
  top: 2.3em;
  right: 0.8em;
  border: 0.1em solid #c3c9d8;
}
ssi-query-builder-condition .query-container-builder-row-parent .query-container-builder-row-tree-guides:before, ssi-query-builder-condition .query-container-builder-row-parent-child .query-container-builder-row-tree-guides:before, ssi-query-builder-condition .query-container-builder-row-child .query-container-builder-row-tree-guides:before {
  content: "";
  position: absolute;
  left: -0.1em;
  border: 0.1em solid #c3c9d8;
  height: 2.4em;
}
ssi-query-builder-condition .query-container-builder-row-parent-child .query-container-builder-row-tree-guides:after {
  content: "";
  position: absolute;
  left: -0.1em;
  border: 0.1em solid #c3c9d8;
  height: 2.4em;
  top: -2.4em;
}
ssi-query-builder-condition .query-container-builder-row-child .query-container-builder-row-tree-label {
  display: none;
}
ssi-query-builder-condition .query-container-builder-row-child .query-container-builder-row-tree-guides:before {
  top: -2.4em;
}
ssi-query-builder-condition .query-container-builder-row-condition-select select.involved,
ssi-query-builder-condition .query-container-builder-row-condition-select select .has {
  border-color: #12aca4;
  outline-color: #12aca4;
  background-color: #ecf5f5;
}
ssi-query-builder-condition .query-container-builder-row-condition-select select.not-involved,
ssi-query-builder-condition .query-container-builder-row-condition-select select .has-not {
  border-color: #f88c68;
  outline-color: #f88c68;
  background-color: #fdf6f4;
}
ssi-query-builder-condition .query-container-builder-row-condition-select select.from {
  border-color: #c3c9d8;
  outline-color: #c3c9d8;
  background-color: #f4f4fa;
}
ssi-query-builder-condition .query-container-builder-row-tree {
  position: relative;
  width: 11em;
  flex: 0 0 11em;
}
ssi-query-builder-condition .query-container-builder-row-tree-label {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #838eab;
  font-weight: 900;
  text-rendering: geometricPrecision;
  text-transform: uppercase;
  background-color: #fff;
  width: 4.2em;
  height: 2.6em;
  z-index: 1;
  letter-spacing: 0.1em;
}
ssi-query-builder-condition .query-container-builder-row-tree-label span {
  font-size: 1.2em;
  pointer-events: none;
}
ssi-query-builder-condition .query-container-builder-row-tree-label-is-root {
  width: 5.6em;
  height: 4em;
  margin: -0.7em -0.7em 0;
  background-color: #425dec;
  color: #fff;
  border-radius: 0.6em;
  outline: 0.8em solid #f8f9fd;
  cursor: pointer;
}
ssi-query-builder-condition .query-container-builder-row-condition {
  display: flex;
  flex: 1 1 auto;
}
ssi-query-builder-condition .query-container-builder-row-condition > * {
  margin: 0 0.6em 0 0;
}
ssi-query-builder-condition .query-container-builder-row-condition > *:last-child {
  margin: 0;
}
ssi-query-builder-condition .query-container-builder-row-condition-text {
  flex: 1 0 auto;
  border: 0.2rem solid #c3c9d8;
  background-color: #fff;
  color: #101525;
  font-weight: 900;
  text-rendering: geometricPrecision;
  padding: 0 2rem;
  height: 4.6rem;
  line-height: 1;
  border-radius: 1rem;
  font-size: 1.4em;
  width: 10rem;
}
ssi-query-builder-condition .query-container-builder-row-condition-delete {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 4.6em;
  width: 4.6em;
  background-color: #f88c68;
  border-width: 0;
  border-radius: 1em;
}
ssi-query-builder-condition .query-container-builder-row-condition-delete .ssi {
  font-size: 2em;
  color: #fff;
}