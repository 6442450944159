ssi-widget-monitoring-stream-config {
  height: 100%;
  width: 100%;
}
ssi-widget-monitoring-stream-config .widget-monitoring-stream-config-container .dwc-form {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 1em 1em 1em;
}
ssi-widget-monitoring-stream-config .widget-monitoring-stream-config-container .dwc-form .dwc-form-half {
  flex: 1 0 100%;
}
@media (min-width: 768px) {
  ssi-widget-monitoring-stream-config .widget-monitoring-stream-config-container .dwc-form .dwc-form-half {
    flex: 1 0 calc(50% - 16px);
    min-width: 30em;
    margin: 0 0.8em;
  }
}
ssi-widget-monitoring-stream-config .widget-monitoring-stream-config-container .dwc-form .dwc-body-row {
  padding: 0 3em;
  margin: 0 0 2em;
}
ssi-widget-monitoring-stream-config .widget-monitoring-stream-config-container .dwc-form .dwc-label {
  display: block;
  text-transform: uppercase;
  font-size: 1em;
  font-weight: 900;
  letter-spacing: 0.1em;
  margin: 0 0 1.8em;
  color: #43537f;
  text-rendering: geometricPrecision;
}
ssi-widget-monitoring-stream-config .widget-monitoring-stream-config-container .dwc-form .dwc-body-row-input {
  position: relative;
  margin: 0 -3rem 0;
}
ssi-widget-monitoring-stream-config .widget-monitoring-stream-config-container .dwc-form .dwc-input {
  font-size: 1.4em;
  width: 100%;
  border-width: 0;
  height: 4.6rem;
  border-radius: 1rem;
  color: #101525;
  font-weight: 700;
  text-rendering: geometricPrecision;
  text-indent: 3rem;
  padding: 0 3rem 0 0;
  background-color: #fff;
  outline: 1px solid #c3c9d8;
}
ssi-widget-monitoring-stream-config .widget-monitoring-stream-config-container .dwc-form .dwc-input:focus {
  outline: 1px solid #14bae3;
}
ssi-widget-monitoring-stream-config .widget-monitoring-stream-config-container .dwc-form .dwc-input:disabled {
  opacity: 0.4;
}
ssi-widget-monitoring-stream-config .widget-monitoring-stream-config-container .dwc-form .dwc-input::placeholder {
  color: #6a759a;
  text-rendering: geometricPrecision;
}
ssi-widget-monitoring-stream-config .widget-monitoring-stream-config-container .dwc-form .dwc-body-row-input-meta {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  height: 1em;
  line-height: 1;
  right: 1.8em;
  color: #838eab;
  font-size: 1em;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-widget-monitoring-stream-config .widget-monitoring-stream-config-container .dwc-form .dwc-body-row-input-meta-active {
  color: #14bae3;
}
ssi-widget-monitoring-stream-config .widget-monitoring-stream-config-container .dwc-form .dwc-dropdown-selector {
  margin: 0 -3rem;
}
ssi-widget-monitoring-stream-config .widget-monitoring-stream-config-container .dwc-form .dwc-dropdown-selector .dd-container,
ssi-widget-monitoring-stream-config .widget-monitoring-stream-config-container .dwc-form .dwc-dropdown-selector .dd-head {
  width: 100%;
}
ssi-widget-monitoring-stream-config .widget-monitoring-stream-config-container .dwc-form .dwc-dropdown-selector .dd-head {
  border: 1px solid #c3c9d8;
  background: #fff;
}
ssi-widget-monitoring-stream-config .widget-monitoring-stream-config-container .dwc-form .dwc-dropdown-selector .dd-container .dd-body {
  width: 100%;
}
ssi-widget-monitoring-stream-config .widget-monitoring-stream-config-container .dwc-form .dwc-dropdown-selector .dd-container .dd-body ul {
  max-height: 268px;
}
ssi-widget-monitoring-stream-config .widget-monitoring-stream-config-container .dwc-form .dwc-dropdown-selector .dd-container .dd-body ul li {
  font-size: 14px;
  min-height: 36px;
  padding: 3px 10px 3px 10px;
  border-radius: 10px;
  margin: 5px 0;
}
ssi-widget-monitoring-stream-config .widget-monitoring-stream-config-container .dwc-form .dwc-dropdown-selector .dd-container .dd-head.variant-analytics .button-icon {
  color: #14bae3;
}
ssi-widget-monitoring-stream-config .widget-monitoring-stream-config-container .dwc-form .dwc-dropdown-selector .dd-container button {
  border-radius: 1rem;
  text-indent: 10px;
  height: 4.6rem;
}
ssi-widget-monitoring-stream-config .widget-monitoring-stream-config-container .dwc-form .dwc-dropdown-placeholder {
  color: #838eab;
  font-size: 14px;
  font-weight: 700;
}
ssi-widget-monitoring-stream-config ssi-global-filters .global-filters .filters-title {
  padding-top: 0;
  padding-left: 0;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  color: #43537f;
  text-rendering: geometricPrecision;
}
ssi-widget-monitoring-stream-config ssi-global-filters .global-filters.compact .filters-body {
  padding: 0;
}
ssi-widget-monitoring-stream-config ssi-global-filters .global-filters ul {
  padding: 0;
}
ssi-widget-monitoring-stream-config ssi-global-filters .global-filters ssi-tags-input {
  padding: 0 0 10px;
}
ssi-widget-monitoring-stream-config ssi-global-filters .global-filters .filters-footer {
  display: none;
}