.modal-popup {
  text-align: center;
}
.modal-popup .tag-selector {
  margin-top: 1em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-popup .tag-selector .dd-container,
.modal-popup .tag-selector button.dd-head {
  width: 100%;
  height: auto;
}
.modal-popup .tag-selector button.dd-head {
  border-radius: 12px;
  background-color: white;
}
.modal-popup .tag-selector .select-tags-placeholder {
  color: #838eab;
  font-size: 14px;
  font-weight: 700;
}
.modal-popup .tag-selector .select-tag-labels {
  text-align: left;
  margin: 0 0 -10px -10px;
  color: #fff;
}
.modal-popup .tag-selector .select-tag-labels span {
  padding: 6px 8px;
  font-size: 10px;
  margin: 0 4px 8px 0;
  background-color: #14bae3;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  text-rendering: geometricPrecision;
  word-break: break-all;
}
.modal-popup .tag-selector .select-tag-labels span:hover {
  background-color: #f88c68;
}
.modal-popup .tag-selector .select-tag-labels .ssi {
  margin: 0 0 0 7px;
  font-size: 8px;
}
.modal-popup .modal-header {
  border-bottom: 0;
}
.modal-popup .modal-title {
  font-size: 26px;
  padding-bottom: 10px;
}
.modal-popup .modal-body {
  width: 80%;
  min-width: 500px;
  margin: auto;
  padding-bottom: 70px;
}
@media (max-width: 885px) {
  .modal-popup .modal-body {
    width: auto;
    min-width: initial;
  }
}
.modal-popup .modal-action-buttons {
  margin-top: 40px;
  text-transform: uppercase;
}
.modal-popup .modal-action-buttons .btn {
  width: 150px;
}
.modal-popup .modal-action-buttons .btn ~ .btn {
  margin-left: 5px;
}
.modal-popup .modal-link {
  padding-top: 20px;
  font-size: 11px;
  font-weight: normal;
  text-transform: uppercase;
}
.modal-popup .btn {
  text-transform: uppercase;
  font-size: 11px;
}
.modal-popup .checkbox {
  text-transform: uppercase;
}
.modal-popup .checkbox label {
  font-size: 13px;
  padding-left: 10px;
}

.modal-style-1 .modal-content {
  background-color: #fff;
  max-width: 480px;
  border-width: 0;
  border-radius: 15px;
  box-shadow: 0, 0, 18px rgba(131, 142, 171, 0.35);
}
.modal-style-1 .modal-header {
  display: none;
}
.modal-style-1 .modal-popup .modal-body {
  width: 100%;
  min-width: auto;
  padding: 60px 35px;
}
.modal-style-1 .modal-title {
  color: #43537f;
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
  padding: 0;
  margin: 0 0 15px;
}
.modal-style-1 .modal-title .ssi {
  display: block;
  margin: 0 0 20px;
  font-size: 30px;
  color: #b4bbce;
}
.modal-style-1 .modal-meta {
  color: #838eab;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
}
.modal-style-1 .modal-action-buttons {
  margin: 40px 0 0;
}
.modal-style-1 .modal-action-buttons .btn {
  min-width: 165px;
  padding: 14px 25px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  border-width: 0;
  border-radius: 50px;
  text-transform: none;
}
.modal-style-1 .modal-action-buttons .btn-primary {
  background-color: #14bae3;
}
.modal-style-1 .modal-action-buttons .btn-primary:hover, .modal-style-1 .modal-action-buttons .btn-primary:focus {
  background-color: #11acd3;
}