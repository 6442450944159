ssi-analytics ssi-marketing ssi-report ssi-top-times-to-post .top-times-to-post-container .section-head h2 .ssi.ssi-help {
  margin: 0 0 0 3px;
  font-size: 14px;
  vertical-align: text-top;
}
ssi-analytics ssi-marketing ssi-report ssi-top-times-to-post .top-times-to-post-container .section-body {
  padding: 30px 30px 60px 30px;
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
}
@media print {
  ssi-analytics ssi-marketing ssi-report ssi-top-times-to-post .top-times-to-post-container .section-body {
    page-break-inside: avoid;
  }
}
@media print {
  ssi-analytics ssi-marketing ssi-report ssi-top-times-to-post .top-times-to-post-container .section-body {
    page-break-inside: avoid;
  }
}
ssi-analytics ssi-marketing ssi-report ssi-top-times-to-post .top-times-to-post-container .section-body .top-row {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 30px;
}
ssi-analytics ssi-marketing ssi-report ssi-top-times-to-post .top-times-to-post-container .section-body .top-row .best-hours-container {
  display: flex;
  border-radius: 5px;
  border: 1px solid #d41d68;
  padding: 20px;
}
ssi-analytics ssi-marketing ssi-report ssi-top-times-to-post .top-times-to-post-container .section-body .top-row .best-hours-container .left {
  margin-right: 80px;
}
ssi-analytics ssi-marketing ssi-report ssi-top-times-to-post .top-times-to-post-container .section-body .top-row .best-hours-container .left span {
  color: #838eab;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
}
ssi-analytics ssi-marketing ssi-report ssi-top-times-to-post .top-times-to-post-container .section-body .top-row .best-hours-container .middle {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
ssi-analytics ssi-marketing ssi-report ssi-top-times-to-post .top-times-to-post-container .section-body .top-row .best-hours-container .middle span {
  color: #838eab;
  font-size: 14px;
  font-weight: 700;
}
ssi-analytics ssi-marketing ssi-report ssi-top-times-to-post .top-times-to-post-container .section-body .top-row .best-hours-container .middle .hours span {
  color: #101525;
  font-size: 20px;
  font-weight: 900;
}
ssi-analytics ssi-marketing ssi-report ssi-top-times-to-post .top-times-to-post-container .section-body .top-row .best-hours-container .right {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
ssi-analytics ssi-marketing ssi-report ssi-top-times-to-post .top-times-to-post-container .section-body .top-row .best-hours-container .right i {
  font-size: 30px;
  color: #ffeee9;
}
ssi-analytics ssi-marketing ssi-report ssi-top-times-to-post .top-times-to-post-container .section-body ssi-highchart {
  width: 100%;
  height: 340px;
}
ssi-analytics ssi-marketing ssi-report ssi-top-times-to-post .top-times-to-post-container .section-body ssi-highchart highcharts-chart .highcharts-container,
ssi-analytics ssi-marketing ssi-report ssi-top-times-to-post .top-times-to-post-container .section-body ssi-highchart highcharts-chart .highcharts-root {
  overflow: visible !important;
}
ssi-analytics ssi-marketing ssi-report ssi-top-times-to-post .top-times-to-post-container .section-body ssi-highchart highcharts-chart .highcharts-axis-title {
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: nowrap;
}
ssi-analytics ssi-marketing ssi-report ssi-top-times-to-post .top-times-to-post-container .section-body ssi-highchart highcharts-chart .highcharts-axis-labels text {
  font-weight: 900;
}
ssi-analytics ssi-marketing ssi-report ssi-top-times-to-post .top-times-to-post-container .section-body ssi-highchart highcharts-chart .highcharts-xaxis-labels {
  transform: translate(0, 1em);
}
ssi-analytics ssi-marketing ssi-report ssi-top-times-to-post .top-times-to-post-container .section-body .chart-legend {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  margin-top: 40px;
}
ssi-analytics ssi-marketing ssi-report ssi-top-times-to-post .top-times-to-post-container .section-body .chart-legend .legend-item {
  display: flex;
  align-items: center;
  margin-right: 60px;
}
ssi-analytics ssi-marketing ssi-report ssi-top-times-to-post .top-times-to-post-container .section-body .chart-legend .legend-item:first-child {
  margin-right: 30px;
}
ssi-analytics ssi-marketing ssi-report ssi-top-times-to-post .top-times-to-post-container .section-body .chart-legend .legend-item span {
  color: #43537f;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 0.4px;
}
ssi-analytics ssi-marketing ssi-report ssi-top-times-to-post .top-times-to-post-container .section-body .chart-legend .legend-item .circle {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: #d41d68;
  margin-right: 10px;
  -webkit-print-color-adjust: exact;
}
ssi-analytics ssi-marketing ssi-report ssi-top-times-to-post .top-times-to-post-container .section-body .chart-legend .legend-item.great .circle {
  background-color: #d41d68 !important;
}
ssi-analytics ssi-marketing ssi-report ssi-top-times-to-post .top-times-to-post-container .section-body .chart-legend .legend-item.good .circle {
  background-color: #f40064 !important;
}
ssi-analytics ssi-marketing ssi-report ssi-top-times-to-post .top-times-to-post-container .section-body .chart-legend .legend-item.okay .circle {
  background-color: #ff5da0 !important;
}
ssi-analytics ssi-marketing ssi-report ssi-top-times-to-post .top-times-to-post-container .section-body .chart-legend .legend-item.not-great .circle {
  background-color: #fbc5db !important;
}
ssi-analytics ssi-marketing ssi-report ssi-top-times-to-post .top-times-to-post-container .section-body .chart-legend .legend-item.not-good .circle {
  background-color: #ffeee9 !important;
}