@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-widget-marketing-posts {
  height: 100%;
  width: 100%;

  .dashboard-widget {
    position: relative;
    height: 100%;
    width: 100%;

    .form-field {
      padding: 10px;
      border-bottom: 1px solid #c3c9d8;
      font-weight: 900;

      .label-clear-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 5px;
      }

      label.field-title {
        font-size: 10px;
        line-height: 12px;
        padding-left: 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $lmode-body-text;
        font-weight: 900;
      }

      span.right {
        flex: 1 1 auto;
        padding-right: 20px;
        font-size: 14px;
        color: $primary-active;
        white-space: nowrap;

        button.last-month-only-btn {
          border: 0;
          background-color: #ffffff;
          text-decoration: underline;
        }

        .italic {
          font-style: italic;
          font-size: 15px;
        }
      }
    }

    ssi-dropdown-select-2 {
      width: 100%;

      ssi-social-network-icon {
        margin-right: -8px;
      }

      .dd-head {
        width: 100%;
      }
      .dd-body {
        width: 100%;
      }

      .select-accounts-label {
        display: flex;
        align-items: center;

        span {
          padding-left: 12px;
          color: #101525;
          font-weight: 900;
        }
      }
    }

    .message-info {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      text-align: center;

      i {
        color: #b4bbce;
        font-size: 37.5px;
      }

      h5 {
        font-size: 20px;
        font-weight: 900;
        line-height: 24px;
        color: $lmode-body-text;
      }

      p {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        max-width: 222px;
        color: $lmode-body-text;
      }
    }

    ssi-outbox-post-list {
      .panel {
        margin: 5px 10px 20px 10px;
      }
    }
  }
}
