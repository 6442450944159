@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../angularjs/common/sass/variables';
@import '../../../../../../angularjs/common/sass/mixins';
@import '../../publisher.variables.scss';
@import '../../publisher.mixins.scss';
@import '~/styles/colors';

$ssi-publisher-schedule-dp-responsive-width: 885px;

ssi-publisher-schedule-date-picker {
  position: relative;
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  flex-direction: column;

  @media (max-width: $ssi-publisher-schedule-dp-responsive-width) {
    flex: auto;
    flex-wrap: wrap;
  }

  $publisher-schedule-calendar-color: #8b8e9d;
  $brand: $primary-active;
  $calendar-title-height: 28px;
  $calendar-font-color: $lmode-body-text;

  .publisher-schedule-date-picker-outer {
    width: 290px;
    padding: 0 6px;
    margin: 10px auto 0;
  }

  .publisher-schedule-date-picker-inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    max-height: 300px;
  }

  .publisher-schedule-date-picker-title-wrap {
    max-height: $calendar-title-height;
    margin: 0 6px 8px;
  }

  .publisher-schedule-date-picker-title-nav {
    button {
      border: 0;
      background-color: transparent;

      &focus {
        outline: revert;
      }
    }
    i {
      font-size: 12px;
      color: $brand;
      line-height: $calendar-title-height;
    }
  }

  .publisher-schedule-date-picker-title {
    text-align: center;

    p {
      font-size: 12px;
      font-weight: 900;
      color: $calendar-font-color;
      margin: 0;
      line-height: $calendar-title-height;
      text-rendering: geometricPrecision;
    }
  }

  .cal-month-view {
    font-size: 12px;
    color: darken($calendar-font-color, 30%);
    display: block;
    margin-top: 10px;
    margin-bottom: 15px;
    font-weight: 700;
    text-rendering: geometricPrecision;

    .cal-header {
      margin-bottom: 7px;
      font-weight: inherit;

      &:hover,
      &:focus {
        background-color: transparent;
      }

      .cal-cell {
        &:hover,
        &:focus {
          background-color: transparent;
        }
      }
    }

    .cal-days {
      .cal-day-cell,
      .cal-cell-top {
        background-color: transparent;
        border: 0;
        min-height: initial;

        @include ie-only {
          min-height: 35px;
        }
      }

      .cal-day-cell .cal-day-number,
      .cal-day-number {
        display: block;
        float: none;
        text-align: center;
        margin: 0px -2px;
        padding: 5px 0px;
        font-size: 12px;
        line-height: 18px;
        color: inherit;
        opacity: 1;
        font-weight: inherit;
        border-radius: 50%;
        border: 1px solid transparent;
        cursor: pointer;

        text-rendering: geometricPrecision;
      }

      @mixin cal-cell-bg(
        $bg-color,
        $bg-color-hover,
        $text-color: $publisher-schedule-calendar-color
      ) {
        & {
          background-color: transparent;
        }
        &:hover {
          background-color: transparent;
        }
        .cal-day-number {
          color: $text-color !important;
        }
      }

      .cal-cell {
        font-weight: 700;
        margin: 1px;
      }

      .cal-cell:first-child {
        margin-left: 0;
      }

      .cal-cell:last-child {
        margin-right: 0;
      }

      .cal-cell.cal-future {
        &:hover,
        &:focus {
          .cal-day-number {
            background-color: #eceef7;
          }
        }
      }

      .cal-cell.cal-today {
        .cal-day-number {
          border: 1px solid $calendar-font-color;
          color: darken($calendar-font-color, 30%);
        }
      }

      .cal-cell.cal-weekend {
        .cal-day-number {
          color: darken($calendar-font-color, 30%);
        }
      }

      .cal-cell.disabled,
      .cal-cell.cal-out-month {
        .cal-day-number {
          opacity: 1;
          color: darken(#a5acc4, 30%);
        }

        &:hover,
        &:focus {
          .cal-day-number {
            background-color: #f6f7fd;
          }
        }
      }

      .cal-cell.scheduled {
        .cal-day-number {
          background-color: #a4be13;
          color: #fff;
        }

        &:hover,
        &:focus {
          .cal-day-number {
            background-color: #a4be13;
          }
        }
      }

      .cal-cell.active {
        .cal-day-number {
          background-color: $brand;
          color: #fff;
        }

        &:hover,
        &:focus {
          .cal-day-number {
            background-color: $brand;
          }
        }
      }

      &,
      .cal-cell-row,
      .cal-cell {
        border: 0;

        &:hover,
        &:focus {
          background-color: transparent;
        }
      }
    }
  }

  .select-schedule-time-btn {
    position: relative;
    margin-top: -6px; // hack to show proper button height - date picker otherwise eats the height

    i {
      font-size: 10px;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 5px;
      transform: rotate(270deg);
    }
  }

  .jump-to-today-btn {
    margin-bottom: 10px;
    margin-right: 8px;
    width: 278px;
    font-size: 12px;
  }
}
