@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-campaign-modal {
  .header {
    width: 400px;
    text-align: center;
    margin: 0 auto 2em auto;
    img {
      width: 12%;
      height: 12%;
      margin: 1.6em 0;
    }
    button {
      margin-top: 1.6em;
    }
    h2 {
      color: #5471d7;
      font-size: 1.4em;
      font-weight: 600;
      margin: 0 0 1.2em 0;
    }
    p {
      margin: 1em 0;
      font-size: 12px;
      color: #a6b7c1;
      font-weight: 500;
    }
  }
  .exit {
    position: absolute;
    right: 15px;
    top: 15px;
    background-color: #eff2f8;
    border-radius: 100%;
    cursor: pointer;
    i {
      display: inline-block;
      padding: 10px;
      color: $lmode-helper-text;
    }
  }
  .dropdown-select-menu {
    position: relative !important;
    border-radius: 25px;
  }
  .dropdown-select-container {
    margin-bottom: 1em;

    .dropdown-select-menu-item {
      visibility: visible;
      background-color: transparent;
      border-width: 0;
      text-align: left;
    }

    button {
      visibility: hidden;
    }
  }
  .campaigns {
    padding: 2em 4em;
    position: relative;
  }
  input {
    display: none;
  }
  .footer {
    text-align: center;
    * {
      margin: 1em auto;
      display: block;
    }
  }
  .publisher-landing-dropdown-item-indent {
    margin-left: 20px;
    display: inline-block;

    i {
      width: 18px;
    }
  }
}
