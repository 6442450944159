ssi-outbox-post-menu {
  display: block;
}
ssi-outbox-post-menu .outbox-menu .outbox-menu-trigger {
  width: 26px;
  height: 40px;
  box-shadow: none;
  border-radius: 0;
  border: 0;
}
ssi-outbox-post-menu .outbox-menu .outbox-menu-trigger i {
  font-size: 24px;
}
ssi-outbox-post-menu .outbox-menu .outbox-menu-items {
  display: none;
}
ssi-outbox-post-menu .outbox-menu .outbox-menu-items {
  background-color: #ffffff;
  padding: 10px;
  min-width: 35px;
  border-radius: 20px;
  text-align: center;
  box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.2);
}
ssi-outbox-post-menu .outbox-menu .outbox-menu-items a {
  padding: 0 10px;
}
ssi-outbox-post-menu .outbox-menu .outbox-menu-items a i {
  color: #838eab;
}
ssi-outbox-post-menu .outbox-menu .outbox-menu-items a:hover {
  text-decoration: none;
}
ssi-outbox-post-menu .outbox-menu:not(.no-hover):hover .outbox-menu-trigger {
  display: none;
}
ssi-outbox-post-menu .outbox-menu:not(.no-hover):hover .outbox-menu-items {
  display: flex;
  align-items: center;
}
ssi-outbox-post-menu .outbox-menu:not(.no-hover):hover .outbox-menu-items .text-danger i {
  color: #f88c68;
}