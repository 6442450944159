ssi-insights ssi-reports ssi-view-report .global-filters-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 500px;
  height: calc(100% - 68px);
  margin-top: 68px;
  background-color: #fff;
  box-shadow: 0 18px 40px 0 rgba(131, 142, 171, 0.35);
  z-index: 3;
}
ssi-insights ssi-reports ssi-view-report .view-report-container {
  width: 100%;
}
ssi-insights ssi-reports ssi-view-report .view-report-container .header {
  height: 60px;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0 40px;
}
ssi-insights ssi-reports ssi-view-report .view-report-container .header h3 {
  padding: 0;
  margin: 0;
  color: #101525;
  font-size: 16px;
  font-weight: 900;
  line-height: 14px;
}
ssi-insights ssi-reports ssi-view-report .view-report-container .actions-bar {
  height: 60px;
  background-color: #f4f4fa;
  border-top: 1px solid #838eab;
  border-bottom: 1px solid #838eab;
  display: flex;
  align-items: center;
}
ssi-insights ssi-reports ssi-view-report .view-report-container .content-wrapper {
  height: 100%;
  background-color: #f4f4fa;
}