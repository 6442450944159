@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-network-inbound-comments {
  display: flex;
  flex-direction: column;

  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 5px 20px 0 rgba(131, 142, 171, 0.2);
  padding: 40px;

  h5 {
    color: $lmode-title-text;
    font-size: 16px;
    font-weight: 700;
  }

  .network-info {
    display: flex;
    align-items: center;
    height: 40px;

    font-size: 14px;
    font-weight: 900;

    .name {
      color: $lmode-helper-text;
      width: 100px;
    }

    .comments-count {
      color: $lmode-title-text;
      width: 50px;
    }

    .bar {
    }
  }
}
