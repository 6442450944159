@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../angularjs/common/sass/variables';
@import '../../../../../angularjs/common/sass/mixins';
@import '~/styles/colors';

ssi-campaigns-select-create {
  .campaign-dd-and-button-container {
    display: flex;
    align-items: center;
    width: 100%;

    ssi-dropdown-select-2 {
      width: 100%;

      .dd-head {
        width: 100%;
        padding: 0 35px 0 5px;
      }

      .dd-body {
        min-width: 250px;
        width: auto;
        > ul {
          max-height: 465px !important;
        }
      }
    }

    &.no-selected-options {
      ssi-dropdown-select-2 {
        .dd-head {
          padding: 0 30px 0 30px;
        }
      }
    }

    .create-campaign-button {
      height: 46px;
      border: 1px solid #c3c9d8;
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      &:disabled {
        background-color: #c3c9d8;
      }
    }

    .select-placeholder {
      font-size: 14px;
      font-weight: 700;
      color: #6a759a;
      text-wrap: nowrap;
    }

    .selected-options-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .chips-container {
        display: flex;
        align-items: center;
        overflow: hidden;

        .chip {
          height: 36px;
          background-color: #f4f4fa;
          border-radius: 8px;
          padding: 0 10px;
          white-space: nowrap;
          margin-right: 5px;

          display: flex;
          align-items: center;

          span {
            font-size: 14px;
            color: $lmode-title-text;
            font-weight: 900;
            margin: 0;
            margin-left: 10px;
          }

          i {
            font-size: 8px;
            color: $lmode-body-text;
            font-weight: 500;
            margin-left: 10px;
            cursor: pointer;

            &:first-child {
              font-size: 12px;
              color: $lmode-helper-text;
            }
          }
        }
      }
    }

    .chevron.expand-campaign-icon {
      font-size: 12px;
      font-weight: 900;
      color: $lmode-title-text;

      transform: rotate(-90deg);

      &.down {
        transform: rotate(0deg);
      }
    }

    ssi-list-select2 {
      .filter-container {
        padding-left: 32px;
      }
    }

    &.can-manage-campaigns {
      ssi-dropdown-select-2 {
        width: 100%;

        .dd-head {
          width: 100%;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }
}
