@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '../../../../angularjs/common/sass/variables.scss';

ssi-landing {
  display: block;

  .landing {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 17px 15px;
    background-color: #fff;

    &.layout-login {
      .landing-wrap {
        .logo-sm,
        .landing-background-forgot {
          display: none;
        }
      }
    }

    &.layout-forgotPassword {
      .landing-wrap {
        .logo,
        .landing-background-login,
        .landing-container-col-illustration {
          display: none;
        }
      }
    }

    .landing-wrap {
      position: relative;
      height: 100%;
      display: flex;
      background-color: #f5f7fb;
      border-radius: 10px;
      overflow: hidden;
    }

    .landing-background-login {
      display: none;
      position: absolute;
      bottom: 20px;
      left: 0;
      max-height: 85%;
      max-width: 45%;

      img {
        height: 100%;
        width: 100%;
      }
    }

    .landing-background-forgot {
      display: none;
      align-items: flex-end;
      position: absolute;
      top: 0;
      left: -80px;
      right: 0;
      margin: auto;
      max-height: 40%;
      width: 45%;

      img {
        width: 100%;
        min-width: 600px;
        height: auto;
      }
    }

    .landing-container {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      padding: 0 10%;
      justify-content: space-around;
      margin: 0 auto;
      overflow: auto;
      z-index: 1;
      color: #101525;
    }

    .landing-container-col {
      max-width: 415px;
      width: 100%;
    }

    .landing-container-col-illustration {
      display: none;
    }

    .landing-illustration {
      text-align: center;

      img {
        margin: -70px -8px 0 0;
        width: 100%;
        max-height: 283px;
      }
    }

    .landing-container-inner {
      form {
        margin: 0 3px;
      }
    }

    .logo {
      margin: 0 30px 80px;

      img {
        height: 50px;
      }
    }

    .logo-sm {
      position: absolute;
      top: 35px;
      left: 40px;

      img {
        height: 30px;
      }
    }

    .login-terms-footer {
      font-size: 10px;
      margin-top: 16px;
      font-weight: 700;
      padding: 0 15px;

      p {
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
      }

      a {
        color: inherit;
        text-decoration: underline;
      }
    }

    .login-block {
      display: block;
    }

    .login-buttons,
    .forget-buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .forgot-message {
      position: relative;
      display: flex;
      align-items: center;
      background-color: rgba(17, 177, 219, 0.15);
      padding: 20px;
      border-radius: 15px;
      max-width: 380px;
      margin: 0 auto 45px;
      overflow: hidden;

      .forgot-message-image {
        flex: 1 0 40px;
        height: 40px;
        width: 40px;
        fill: #0ca2ca;
      }

      .forgot-message-body {
        margin: 0 0 0 15px;
        font-size: 14px;
        line-height: 24px;
        font-weight: 700;
        color: #0ca2ca;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .btn {
      width: 201px;
      border-radius: 100px;
      padding: 19px 0;
      line-height: 18px;
      vertical-align: middle;
      outline: none !important;
      font-weight: 700;

      &:hover,
      &:focus {
        box-shadow: 0 5px 10px rgba(148, 155, 191, 0.15);
      }

      &:active {
        box-shadow: 0 3px 10px rgba(148, 155, 191, 0.15);
      }
    }

    .btn-secondary {
      background-color: #3463f2;
      border-color: #3463f2;
      color: #fff;
    }

    .btn-primary {
      background-color: #f26235;
      border-color: #f26235;
      color: #fff;
    }

    .btn-success {
      background-color: #11b1db;
      border: none;

      &:hover,
      &:focus {
        background-color: #11b1db !important;
        box-shadow: 0 5px 10px rgba(47, 153, 181, 0.25);
      }

      &:active {
        background-color: #11b1db !important;
        box-shadow: 0 3px 10px rgba(47, 153, 181, 0.25);
      }

      &.disabled,
      &.btn[disabled] {
        opacity: 1;
        box-shadow: none;
      }
    }

    .big-header {
      font-weight: 100;
      font-size: 40px;
      margin-bottom: 50px;
    }

    .btn + .btn {
      margin-left: 13px;
    }

    .login-field,
    .forgot-field {
      label {
        display: block;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.63px;
        text-transform: uppercase;
        font-weight: 900;
        margin: 0 30px 12px;
      }

      input,
      .form-control {
        color: inherit;
        border-radius: 100px;
        background-color: #fff;
        border: 3px solid #fff;
        height: auto;
        font-weight: 700;
        font-size: 16px;
        box-shadow: none;
        outline: 0;
        padding: 16px 7px 14px 27px;

        &::placeholder {
          color: #949bbf;
          opacity: 0.55;
          letter-spacing: normal;
        }

        &:focus,
        &:active {
          border-color: #11b1db;
          box-shadow: none;
        }
      }
    }

    .chat-toggle-trigger {
      position: static;
      left: 25px;
      bottom: 35px;
      cursor: pointer;
      font-weight: 800;
      margin: 30px 20px 0 20px;
      font-size: 12px;

      strong {
        color: #27bae1;
        font-weight: 800;
      }
    }
  }

  @media (max-width: 480px) {
    .login-buttons,
    .forget-buttons {
      flex-wrap: wrap;

      .btn {
        margin-bottom: 10px;
        flex: 1 1 100%;

        & + .btn {
          margin: 0;
        }
      }
    }
  }

  @media (min-width: 992px) {
    .landing {
      .landing-background-login {
        display: block;
      }

      .landing-background-forgot {
        display: flex;
      }

      .landing-container-col-illustration {
        display: block;
      }

      .landing-container-col-form {
        margin-right: -40px;
      }

      .chat-toggle-trigger {
        position: absolute;
      }
    }
  }

  @media (min-width: 1600px) {
    .landing {
      .landing-container-col-illustration {
        max-width: none;
      }

      .landing-illustration {
        img {
          margin: -90px auto 0 auto;
          width: auto;
          max-width: calc(100% - 15px);
          max-height: 493px;
        }
      }
    }
  }
}
