@import "./src/apps/angularjs/common/sass/global-variables.scss";
$unit: 2.5px;

ssi-live-chat-conversation-sidebar {
  background-color: #f9f9f9;
  display: flex;
  flex-basis: 200px;
  flex-flow: column nowrap;
  height: 100%;
  padding: 30px 20px;
  position: absolute;
  right: 0;
  transition: width 0.25s;
  width: 200px;
  z-index: 7;

  &.is-fully-visible {
    li.origin {
      & > dl {
        & > dd {
          white-space: normal !important;
        }
      }
    }
  }

  &:hover {
    box-shadow: -3px 0 10px rgba(0, 0, 0, 0.175);
    width: 480px;
  }

  * > * {
    display: flex;
  }

  ssi-live-chat-avatar {
    align-self: center;
    border-radius: 50%;
    height: 80px;
    width: 80px;

    .inner {
      img {
        border: solid 3px #fff;
      }

      .verified {
        font-size: 15px;
        padding: 7.5px;
        top: 0;
      }
    }
  }

  .primary-details {
    align-items: center;
    flex-flow: column nowrap;
    flex-shrink: 0;
    font-size: 1em;
    font-style: italic;
    font-weight: 400;
    justify-content: center;
    margin: ($unit * 6) 0;

    .network-state {
      text-transform: capitalize;
    }
  }

  .verified {
    color: darken(#49b0e8, 30%);
  }

  .toggle {
    background: transparent;
    border: none;
    color: #a6b7c0;
    position: fixed;
    right: 20px;

    &:focus {
      outline: revert;
    }
  }

  .inner {
    flex-flow: column nowrap;
    overflow-x: hidden;
    overflow-y: auto;

    dl.details {
      flex-flow: column nowrap;
      flex-shrink: 0;

      &.expanded {
        & > dd {
          display: flex;
        }
      }

      & > dd {
        display: none;
        flex-shrink: 0;
        padding: 20px;

        ul {
          color: darken(#a6b7c0, 30%);
          flex-flow: column nowrap;
          flex-grow: 1;
          flex-shrink: 1;
          font-size: 0.8em;
          list-style: none;
          margin: 0;
          overflow: hidden;
          padding: 0;

          & > li {
            display: inline;
            flex-shrink: 0;
            margin: 0 0 ($unit * 2) 0;
            overflow: hidden;
            padding: ($unit * 2) 0 0 0;

            & + li {
              border-top: solid 2px #fff;
            }

            & > dl {
              flex-flow: column nowrap;
              margin: 0;
              padding: 0;

              & > dt {
                font-style: italic;
                font-weight: 400;
              }

              & > dd {
                display: inline;
                font-weight: 600;
                hyphens: auto;
                overflow-wrap: break-word;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                word-break: break-word;
                word-wrap: break-word;
              }
            }
          }
        }
      }

      & > dt {
        background: #fff;
        border: none;
        border-radius: 20px;
        box-shadow: 0 3px 12px rgba(0, 0, 0, 0.04);
        color: darken(#a8b9c0, 30%);
        cursor: pointer;
        flex-grow: 1;
        flex-shrink: 0;
        font-size: 0.85em;
        font-weight: 600;
        justify-content: center;
        padding: 3px 20px;
        &.block {
          background: #fff1ed;
          border: 1px solid #f88c68;
        }
      }
    }
  }
  ssi-map {
    agm-map {
      width: 100%;
      .gm-bundled-control {
        display: none;
      }
    }
  }
}
