ssi-live-chat-note-form {
  color: #a6b7c0;
  flex-flow: column nowrap;
  flex-shrink: 0;
  font-size: 0.85em;
  margin: 0 20px;
}
ssi-live-chat-note-form form {
  flex-flow: column nowrap;
}
ssi-live-chat-note-form form .form-inner {
  background-color: #fff;
  border-radius: 20px;
  border-top-right-radius: 0;
  flex-flow: column nowrap;
  font-size: 0.85em;
  margin-top: 10px;
  padding: 20px 20px 10px 20px;
}
ssi-live-chat-note-form form .form-inner textarea,
ssi-live-chat-note-form form .form-inner textarea:focus {
  box-shadow: none;
  border: none;
  display: block;
  resize: none;
  width: 100%;
}
ssi-live-chat-note-form form .form-inner .author {
  font-style: italic;
  font-weight: 400;
  justify-content: flex-end;
}
ssi-live-chat-note-form form .form-inner .author .name {
  margin-left: 2.5px;
}
ssi-live-chat-note-form .form-footer > li button,
ssi-live-chat-note-form .footer-information > li button,
ssi-live-chat-note-form .footer-options > li button {
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 20px;
  font-weight: 500;
  justify-content: center;
  padding: 5px 10px;
}
ssi-live-chat-note-form .form-footer > li button.save-button,
ssi-live-chat-note-form .footer-information > li button.save-button,
ssi-live-chat-note-form .footer-options > li button.save-button {
  background-color: #14bae3;
  border: none;
  border-radius: 20px;
  color: #fff;
  display: flex;
  font-size: 1em;
  font-style: italic;
  font-weight: 500;
  justify-content: center;
  margin: 20px 0;
  padding: 3px 20px;
}
ssi-live-chat-note-form ul {
  flex-flow: row nowrap;
  list-style: none;
  margin: 0;
  padding: 0;
}
ssi-live-chat-note-form ul > li {
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}
ssi-live-chat-note-form .form-footer {
  justify-content: flex-end;
}
ssi-live-chat-note-form .form-footer .footer-information {
  color: #c9d8e0;
  flex-shrink: 0;
  font-style: italic;
  justify-content: flex-end;
}
ssi-live-chat-note-form .footer-options.expanded {
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-top: 20px;
}
ssi-live-chat-note-form .footer-options.expanded button {
  font-style: italic;
  font-size: 0.85em;
}