ssi-outbox-search .outbox-analytics-section {
  display: flex;
  flex-direction: column;
  width: 100%;
}
ssi-outbox-search .outbox-wrap {
  padding-bottom: 100px;
}
ssi-outbox-search .form-check {
  display: flex;
  align-items: center;
  justify-content: end;
}
ssi-outbox-search .form-check .form-check-input {
  margin: 0;
  margin-left: 8px;
}
ssi-outbox-search .actions-panel {
  display: flex;
  align-items: center;
  padding: 10px;
}
@media (max-width: 1200px) {
  ssi-outbox-search .actions-panel button span {
    display: none;
  }
  ssi-outbox-search .actions-panel button i {
    margin: 0;
  }
}
ssi-outbox-search .actions-panel > * {
  margin-right: 10px;
}
ssi-outbox-search .actions-panel .filters-button-container {
  margin-left: auto;
}
ssi-outbox-search .actions-panel .g-count-badge-container .g-count-badge {
  z-index: 1;
}