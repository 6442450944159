@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-top-tags-list-box {
  .top-tags-list-box-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.slim {
      padding: 0;
      .body {
        padding: 0;
      }
    }

    .body {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px 40px;

      .tag-bar {
        width: 100%;
        display: flex;
        align-items: center;
        // justify-content: center;
        padding: 10px 20px;
        word-break: break-all;

        &.top {
          padding: 5px 20px 5px 5px;
          margin-bottom: 10px;

          .tag-count {
            font-size: 18px;
          }
        }

        ssi-medal-score {
          margin-right: 15px;
        }

        .progress-bar-wrapper {
          width: 100%;
          margin-right: 15px;

          span {
            color: $lmode-title-text;
            font-size: 14px;
            font-weight: 900;
            line-height: 17px;
            word-break: break-all;
          }
        }

        .tag-count {
          color: $lmode-title-text;
          font-size: 14px;
          font-weight: 900;
          line-height: 22px;
        }
      }
    }

    .body-no-results {
      color: $lmode-helper-text;
      font-size: 16px;
      font-weight: 900;
      padding: 0 10%;
      text-align: center;
    }
  }
}
