@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-list-select2 {
  .list-select2-container {
    .filter-container {
      margin-bottom: 15px;
      position: relative;
      width: 100%;

      i {
        position: absolute;
        color: $lmode-helper-text;
        top: 12px;
        right: 12px;
        font-size: 14px;
        font-weight: 700;
      }
    }

    .toggle-all-container {
      width: 100%;
      padding: 0 10px;
      margin-bottom: 15px;

      display: flex;
      justify-content: flex-end;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin: 5px 0;

        &.selected {
          //   background-color: $grey-background;

          > .main-content {
            span {
              color: $primary-active;
            }
          }
        }

        .main-content {
          // margin: 5px;
          // width: 94%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          cursor: pointer;
          color: $lmode-body-text;
          font-size: 14px;
          font-weight: 700;
          border-radius: 10px;
          height: 34px;
          white-space: nowrap;
          border: none;

          &:hover {
            background-color: $grey-background;
          }

          .icon-container {
            // margin-left: 2px;
            margin-right: 10px;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }

          span {
            padding: 0 10px;
            border-radius: 15px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          .tick-container {
            position: relative;
            margin-right: 10px;
            margin-left: auto;
            height: 16px;
            width: 16px;
            border-radius: 2px;
            border: 1px solid #838eab;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
              font-size: 10px;
              font-weight: 500;
              color: #fff;
            }

            &.checked {
              border: 1px solid $primary-active;
              background-color: $primary-active;
            }

            &.radio {
              height: 18px;
              width: 18px;
              border-radius: 50%;

              i {
                display: none;
              }

              &.checked {
                background-color: #fff;

                i {
                  // just make a circle of it xD
                  padding: 1px;
                  display: block;
                  color: $primary-active;
                  border-radius: 50%;
                  background-color: $primary-active;
                }
              }
            }
          }
        }

        .expanded-content-container {
          padding-left: 30px;

          .filter-container {
            margin-top: 10px;
          }

          ul {
            // margin: 0;
            padding-bottom: 20px;
          }
        }
      }
    }
  }
}
