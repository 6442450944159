@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-menu-item {
  border: 1px solid $secondary-blue;
  border-radius: 10px;
  padding: 12px;
  background-color: #fff;
  &:hover {
    cursor: pointer;
  }

  i {
    margin-right: 5px;
    color: $primary-active;
  }

  span {
    text-transform: none;
    font-size: 12px;
    color: $lmode-body-text;
    font-weight: 900;
    letter-spacing: 0;
  }
}
