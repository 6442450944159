ssi-notes-textarea {
  width: 100%;
}
ssi-notes-textarea .mention-menu .mention-active a.mention-item {
  background-color: transparent;
}
ssi-notes-textarea .notes-textarea-container {
  position: relative;
  height: calc(100% - 46px);
  min-height: 150px;
  max-height: 260px;
}
ssi-notes-textarea .notes-textarea-container textarea {
  resize: none;
  outline: none;
  border: 1px solid #b4bbce;
  border-radius: 5px;
  z-index: 2;
  position: relative;
  height: 100%;
  width: 100%;
  background: none;
  display: block;
  margin: 0;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #101525;
}
ssi-notes-textarea .notes-textarea-container textarea.borderless {
  border: 0;
}
ssi-notes-textarea .notes-textarea-container textarea::placeholder {
  font-style: normal;
  font-size: 12px;
  font-weight: 700;
  color: #838eab;
}
ssi-notes-textarea .notes-textarea-container .notes-tag {
  direction: ltr;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  overflow: auto;
  border-width: 1px;
  border-style: solid;
  padding: 10px;
  text-align: start;
  text-transform: none;
  text-indent: 0px;
  text-decoration: none solid rgb(85, 85, 85);
  letter-spacing: normal;
  word-spacing: 0px;
  tab-size: 8;
  display: block;
  overflow: hidden !important;
  white-space: pre-wrap;
  word-wrap: break-word;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  color: rgba(0, 0, 0, 0);
  z-index: 2;
  pointer-events: none;
}
ssi-notes-textarea .notes-textarea-container .notes-tag a {
  background: #43ade8;
  border-radius: 4px;
  color: #fff;
  margin-left: -1px;
  font-size: 14px;
  vertical-align: text-bottom;
}