@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../common/styles/colors.scss';
@import '../../../../../../angularjs/common/sass/include-media';

ssi-content-calendar-nav {
  width: 100%;

  .cc-nav-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 20px;
    height: 40px;

    @include media('<tablet') {
      display: none;
    }
  }

  .date-info-wrapper {
    position: relative;

    .ssi {
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      font-size: 10px;
      height: 10px;
      right: 15px;
    }
  }

  .date-info {
    color: $lmode-body-text;
    font-size: 18px;
    font-weight: bold;
    margin-left: 10px;
    white-space: nowrap;
    padding-right: 27px !important;
    max-width: 160px;
  }

  .left,
  .right {
    display: flex;
    align-items: center;
  }

  .cc-nav-button {
    border: 1px solid $dmode-body-text;
    border-radius: 10px;
    padding: 12px 15px;
    font-size: 14px;
    font-weight: bold;
    color: $lmode-body-text;
    line-height: 14px;
    background: #fff;
    white-space: nowrap;

    &.filters-button {
      display: flex;
      align-items: center;
      cursor: pointer;

      i {
        font-size: 12px;

        & + span {
          margin-left: 5px;
        }
      }

      &.active {
        border: 1px solid $primary-active;
        background-color: rgba(20, 186, 227, 0.1);
      }

      span {
        font-weight: 900;
      }
    }

    i {
      color: $lmode-body-text;
    }
  }

  .left {
    button {
      font-weight: 900;
      color: $lmode-body-text;
    }
  }

  .right {
    .text {
      color: $lmode-body-text;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
      margin-right: 10px;
    }

    .campaign {
      padding: 11px;
      i {
        font-size: 16px;
      }
      &.active {
        background-color: $primary-active;
        i {
          color: #fff;
        }
      }
    }

    .calendar-event {
      padding: 10px;
      i {
        font-size: 18px;
      }
    }
  }

  .buttons {
    display: flex;
    button {
      display: flex;
      align-items: center;
      &:first-child {
        width: 40px;
        i {
          transform: rotate(90deg);
        }
      }
      &:last-child {
        width: 40px;
        i {
          transform: rotate(270deg);
        }
      }
    }
    i {
      font-size: 10px;
    }
  }

  .tabs {
    border: 1px solid $dmode-body-text;
    border-radius: 10px;
    padding: 1px;
    white-space: nowrap;
    background-color: #fff;

    button {
      border: none;
      border-radius: 8px;
      padding: 11px 15px;
      color: $lmode-helper-text;
      margin-left: 0;
      &.active {
        background-color: $primary-active;
        color: #fff;
      }
    }
  }

  button,
  div {
    &:not(:first-child) {
      margin-left: 4px;
    }
  }

  .dropdown-menu-wrap {
    width: 190px;
    margin: 0 0 10px;

    &.dropdown-menu {
      padding: 0;
      font-size: 1rem;
      background-color: transparent;
      border-width: 0;
      box-shadow: none;
      border-radius: 0;

      &.dropdown-menu-right {
        transform: translate(calc(50% - 20px), 43px) !important;
      }
    }
  }

  // mobile view

  .cc-nav-container-mobile {
    background-color: $lgrey-background;
    display: none;
    @include media('<tablet') {
      padding: 10px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    button {
      padding: 12px;
      i {
        font-size: 12px;
      }
    }

    .date-info {
      display: flex;
      align-items: center;
      color: $lmode-body-text;
      background: #fff;
      font-size: 14px;
      font-weight: 700;
      line-height: 14px;
      border: 1px solid $dmode-body-text;
      border-radius: 10px;
      padding: 12px 15px;
      white-space: nowrap;
      cursor: pointer;
      margin-left: 0;

      i {
        font-size: 10px;
        margin-left: 7px;
      }
    }
  }
}
