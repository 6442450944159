ssi-inbox-crm-sidebar {
  position: absolute;
  right: 0;
  z-index: 99999999;
  height: 100%;
  width: 220px;
  font-size: 12px;
  background-color: #f8f9fd;
  padding: 15px 5px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container {
  padding: 0;
  background-color: #fff;
  border-radius: 12px;
  height: 100%;
  overflow-y: auto;
  color: #a6b7c0;
  display: flex;
  flex-direction: column;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-img {
  align-self: center;
  border-radius: 50%;
  margin: 10px 0;
  padding-left: 0;
  padding-right: 0;
  width: 80px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-collapse {
  bottom: auto;
  display: flex;
  justify-content: flex-end;
  left: auto;
  padding: 10px 10px 0;
  position: relative;
  right: 0;
  top: 0;
  width: auto;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-collapse button {
  background-color: transparent;
  border: none;
  color: #838eab;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-collapse button:hover {
  color: #97abb5;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-sentiment {
  position: absolute;
  bottom: 20px;
  right: 55px;
  padding: 15px;
  border-radius: 50%;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-profile-notes {
  display: flex;
  flex-flow: column nowrap;
  flex-shrink: 0;
  margin: 10px 10px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-profile-notes button {
  border: none;
  outline: none;
  border-radius: 20px;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  color: #fff;
  font-size: 12px;
  font-weight: 900;
  background-color: #668aea;
  padding: 6px;
  margin-top: 30px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-profile-notes > ul {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-profile-notes > ul * {
  display: flex;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-profile-notes .profile-note {
  display: flex;
  flex-flow: column nowrap;
  margin: 0;
  padding: 0;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-profile-notes .profile-note .profile-note-meta {
  margin: 10px 0;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-profile-notes .profile-note .profile-note-meta span {
  font-size: 10px;
  color: #43537f;
  font-weight: 900;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-profile-notes .profile-note .profile-note-content {
  display: inline;
  color: #43537f;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 20px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-profile-notes .profile-note .profile-note-content * {
  display: inline;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .verified-wrap {
  position: relative;
  width: 80px;
  align-self: center;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .verified {
  display: block;
  position: absolute;
  top: 9px;
  right: -1px;
  color: #1da1f2;
  font-size: 20px;
  z-index: 1;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .verified:after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 70%;
  width: 70%;
  background-color: #fff;
  border-radius: 50%;
  z-index: -1;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-stats {
  padding-left: 20px;
  padding-right: 20px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-stats-profile {
  display: flex;
  margin: 10px 0;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-stats-profile button {
  background-color: #fff;
  border: none;
  border-radius: 24px;
  display: flex;
  flex-grow: 1;
  font-weight: bold;
  padding: 3px 18px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-stats .stats {
  display: flex;
  flex-direction: column;
  font-weight: 900;
  font-size: 10px;
  list-style: none;
  margin: 20px 0;
  padding: 0;
  color: #838eab;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-stats .stats .stat-name {
  text-transform: uppercase;
  letter-spacing: 1px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-stats .stats .stat-value {
  color: #101525;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-stats .stats > li {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 6px 0;
  padding: 0;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-stats i {
  margin-right: 3px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-stats .list-unstyled > li {
  margin-bottom: 10px;
  line-height: 30px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-stats .btn {
  font-weight: normal;
  border-color: #a6b7c0;
  position: relative;
  top: 8px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-stats .btn:hover {
  border-color: #98b8c9;
  background-color: #98b8c9;
  color: white;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-stats ssi-crm-profile-sentiment-bar {
  display: block;
  margin-top: 20px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-stats .btn-group {
  width: 100%;
  display: flex;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-stats .btn-group > .view-profile-btn {
  flex: 1;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-stats .btn-group > .dropdown-menu {
  margin-top: 10px;
  text-transform: none;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .progress {
  margin-top: 16px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-notes-header {
  background-color: #6599af;
  border-right: solid 15px #30728d;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-notes-header:hover {
  background-color: #568da5;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-notes-header,
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-header {
  color: white;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  cursor: pointer;
  transition: height 0.5s ease;
  flex: 1;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-notes-header .inner,
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-header .inner {
  padding: 20px 25px;
  width: 100%;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-notes-header .total,
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-header .total {
  font-size: 18px;
  position: relative;
  top: 2px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-notes-header .total:after,
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-header .total:after {
  content: "|";
  margin: 0 3px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-notes-header i,
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-header i {
  float: right;
  font-size: 18px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-person-header {
  background-color: #5a5c5d;
  color: white;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-person-header .inner {
  align-items: center;
  display: flex;
  flex: 1;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-person-header-left {
  flex: 1;
  margin-left: 20px;
  font-size: 12px;
  text-transform: uppercase;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-person-header-left button {
  align-items: center;
  background: transparent;
  border: none;
  color: white;
  display: flex;
  justify-content: space-between;
  min-width: 48px;
  padding: 0;
  text-transform: uppercase;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-unlink-external-integration-person,
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-expand-external-integration-person {
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  float: left;
  margin-right: 8px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-unlink-external-integration-person {
  color: #585958;
  background-color: #e6c032;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-unlink-external-integration-person:hover {
  background-color: #cca619;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-expand-external-integration-person {
  color: white;
  background-color: #353535;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-expand-external-integration-person:hover {
  background-color: #1c1c1c;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-profile-unavailable {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-profile-unavailable i {
  color: #b4bbce;
  font-size: 40px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-profile-unavailable h5 {
  color: #43537f;
  font-size: 14px;
  font-weight: 900;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-profile-unavailable div {
  color: #838eab;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  padding: 0 10px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-profile-unavailable button {
  margin-top: 30px;
  border: none;
  outline: none;
  border-radius: 20px;
  display: flex;
  width: 100%;
  justify-content: center;
  color: #fff;
  font-size: 12px;
  font-weight: 900;
  background-color: #668aea;
  padding: 6px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-bottom {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-bottom .modals {
  display: flex;
  flex-flow: column nowrap;
  flex-shrink: 0;
  list-style: none;
  margin-top: 20px;
  margin-bottom: 0;
  margin-left: 10px;
  margin-right: 10px;
  padding: 0;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-bottom .modals li {
  display: flex;
  flex-shrink: 0;
  margin: 3px 0;
  padding: 0;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-bottom .modals li button {
  border: none;
  outline: none;
  border-radius: 20px;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  color: #fff;
  font-size: 12px;
  font-weight: 900;
  background-color: #668aea;
  padding: 6px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-notes-active .inbox-crm-sidebar-notes-header {
  height: 72px;
  background-color: #568da5;
  border: 0;
  flex: none;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-notes-active .inbox-crm-sidebar-notes-header:hover {
  background-color: #6599af;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-notes-active .inbox-crm-sidebar-tags,
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-notes-active .inbox-crm-sidebar-external-integration-header {
  display: none;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-person-active .inbox-crm-sidebar-notes-header,
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-person-active .inbox-crm-sidebar-external-integration-header {
  height: 0;
  margin: 0;
  flex: none;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-person-active .inbox-crm-sidebar-notes-header .inner,
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-person-active .inbox-crm-sidebar-external-integration-header .inner {
  display: none;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-person-active .inbox-crm-sidebar-tags {
  display: none;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-person-active .inbox-crm-sidebar-external-integration-person-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-header {
  font-size: 16px;
  background-color: #a7a8a9;
  margin-bottom: 8px;
  border-right: solid 15px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-header:hover {
  background-color: #8d8f90;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-header.active {
  background-color: #5a5c5d;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-header.active:hover {
  background-color: #414243;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-header:nth-of-type(2) {
  border-color: #951c79;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-header:nth-of-type(3) {
  border-color: #2dafc3;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-header:nth-of-type(4) {
  border-color: #e28a68;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-tags {
  margin-left: 10px;
  margin-right: 10px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-tags .inbox-crm-sidebar-tags-icon {
  position: absolute;
  top: 10px;
  left: 25px;
  font-size: 16px;
  color: #a6b7c0;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-tags input {
  background-color: transparent;
  font-style: italic;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-tags input::placeholder {
  color: #c9d8e0;
  font-weight: 400;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .plugins h4 {
  font-size: 14px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .plugins li {
  margin: 5px 0;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .plugins button {
  width: 100%;
  background: #fff;
  border: none;
  border-radius: 20px;
  display: flex;
  flex-grow: 1;
  font-size: 1em;
  font-weight: 600;
  padding: 3px 20px;
  margin: 0 0 10px 0;
}

ssi-inbox-activity {
  display: flex;
  height: calc(100vh - 68px);
  position: relative;
  transition: all 0.2s ease-out;
  /* stylelint-disable */
  /* stylelint-enable */
}
ssi-inbox-activity .close-conversation-button {
  float: none;
  border: none;
  margin: 0;
  background: #333;
  color: #fff;
  margin: 0;
  height: 40px;
}
@media only screen and (min-width: 885px) {
  ssi-inbox-activity .close-conversation-button {
    display: none;
  }
}
ssi-inbox-activity.pinned-crm {
  width: calc(100% - 280px);
}
ssi-inbox-activity .activity-body {
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
}
ssi-inbox-activity .inbox-activity-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-right: 220px;
  min-height: 0;
}
ssi-inbox-activity .inbox-activity-left.crm-hidden {
  margin-right: 0;
}
@media only screen and (max-width: 1350px) {
  ssi-inbox-activity .inbox-activity-left {
    width: 100%;
    margin-right: 0;
  }
}
ssi-inbox-activity .inbox-activity-body {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  min-height: 0;
  height: 100%;
}
ssi-inbox-activity .inbox-activity-thread-container {
  position: relative;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 0;
  height: 100%;
}

ssi-inbox-crm-sidebar {
  position: absolute;
  right: 0;
  z-index: 99999999;
  height: 100%;
  width: 220px;
  font-size: 12px;
  background-color: #f8f9fd;
  padding: 15px 5px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container {
  padding: 0;
  background-color: #fff;
  border-radius: 12px;
  height: 100%;
  overflow-y: auto;
  color: #a6b7c0;
  display: flex;
  flex-direction: column;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-img {
  align-self: center;
  border-radius: 50%;
  margin: 10px 0;
  padding-left: 0;
  padding-right: 0;
  width: 80px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-collapse {
  bottom: auto;
  display: flex;
  justify-content: flex-end;
  left: auto;
  padding: 10px 10px 0;
  position: relative;
  right: 0;
  top: 0;
  width: auto;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-collapse button {
  background-color: transparent;
  border: none;
  color: #838eab;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-collapse button:hover {
  color: #97abb5;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-sentiment {
  position: absolute;
  bottom: 20px;
  right: 55px;
  padding: 15px;
  border-radius: 50%;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-profile-notes {
  display: flex;
  flex-flow: column nowrap;
  flex-shrink: 0;
  margin: 10px 10px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-profile-notes button {
  border: none;
  outline: none;
  border-radius: 20px;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  color: #fff;
  font-size: 12px;
  font-weight: 900;
  background-color: #668aea;
  padding: 6px;
  margin-top: 30px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-profile-notes > ul {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-profile-notes > ul * {
  display: flex;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-profile-notes .profile-note {
  display: flex;
  flex-flow: column nowrap;
  margin: 0;
  padding: 0;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-profile-notes .profile-note .profile-note-meta {
  margin: 10px 0;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-profile-notes .profile-note .profile-note-meta span {
  font-size: 10px;
  color: #43537f;
  font-weight: 900;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-profile-notes .profile-note .profile-note-content {
  display: inline;
  color: #43537f;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 20px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-profile-notes .profile-note .profile-note-content * {
  display: inline;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .verified-wrap {
  position: relative;
  width: 80px;
  align-self: center;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .verified {
  display: block;
  position: absolute;
  top: 9px;
  right: -1px;
  color: #1da1f2;
  font-size: 20px;
  z-index: 1;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .verified:after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 70%;
  width: 70%;
  background-color: #fff;
  border-radius: 50%;
  z-index: -1;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-stats {
  padding-left: 20px;
  padding-right: 20px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-stats-profile {
  display: flex;
  margin: 10px 0;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-stats-profile button {
  background-color: #fff;
  border: none;
  border-radius: 24px;
  display: flex;
  flex-grow: 1;
  font-weight: bold;
  padding: 3px 18px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-stats .stats {
  display: flex;
  flex-direction: column;
  font-weight: 900;
  font-size: 10px;
  list-style: none;
  margin: 20px 0;
  padding: 0;
  color: #838eab;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-stats .stats .stat-name {
  text-transform: uppercase;
  letter-spacing: 1px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-stats .stats .stat-value {
  color: #101525;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-stats .stats > li {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 6px 0;
  padding: 0;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-stats i {
  margin-right: 3px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-stats .list-unstyled > li {
  margin-bottom: 10px;
  line-height: 30px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-stats .btn {
  font-weight: normal;
  border-color: #a6b7c0;
  position: relative;
  top: 8px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-stats .btn:hover {
  border-color: #98b8c9;
  background-color: #98b8c9;
  color: white;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-stats ssi-crm-profile-sentiment-bar {
  display: block;
  margin-top: 20px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-stats .btn-group {
  width: 100%;
  display: flex;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-stats .btn-group > .view-profile-btn {
  flex: 1;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-stats .btn-group > .dropdown-menu {
  margin-top: 10px;
  text-transform: none;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .progress {
  margin-top: 16px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-notes-header {
  background-color: #6599af;
  border-right: solid 15px #30728d;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-notes-header:hover {
  background-color: #568da5;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-notes-header,
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-header {
  color: white;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  cursor: pointer;
  transition: height 0.5s ease;
  flex: 1;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-notes-header .inner,
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-header .inner {
  padding: 20px 25px;
  width: 100%;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-notes-header .total,
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-header .total {
  font-size: 18px;
  position: relative;
  top: 2px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-notes-header .total:after,
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-header .total:after {
  content: "|";
  margin: 0 3px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-notes-header i,
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-header i {
  float: right;
  font-size: 18px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-person-header {
  background-color: #5a5c5d;
  color: white;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-person-header .inner {
  align-items: center;
  display: flex;
  flex: 1;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-person-header-left {
  flex: 1;
  margin-left: 20px;
  font-size: 12px;
  text-transform: uppercase;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-person-header-left button {
  align-items: center;
  background: transparent;
  border: none;
  color: white;
  display: flex;
  justify-content: space-between;
  min-width: 48px;
  padding: 0;
  text-transform: uppercase;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-unlink-external-integration-person,
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-expand-external-integration-person {
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  float: left;
  margin-right: 8px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-unlink-external-integration-person {
  color: #585958;
  background-color: #e6c032;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-unlink-external-integration-person:hover {
  background-color: #cca619;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-expand-external-integration-person {
  color: white;
  background-color: #353535;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-expand-external-integration-person:hover {
  background-color: #1c1c1c;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-profile-unavailable {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-profile-unavailable i {
  color: #b4bbce;
  font-size: 40px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-profile-unavailable h5 {
  color: #43537f;
  font-size: 14px;
  font-weight: 900;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-profile-unavailable div {
  color: #838eab;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  padding: 0 10px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-profile-unavailable button {
  margin-top: 30px;
  border: none;
  outline: none;
  border-radius: 20px;
  display: flex;
  width: 100%;
  justify-content: center;
  color: #fff;
  font-size: 12px;
  font-weight: 900;
  background-color: #668aea;
  padding: 6px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-bottom {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-bottom .modals {
  display: flex;
  flex-flow: column nowrap;
  flex-shrink: 0;
  list-style: none;
  margin-top: 20px;
  margin-bottom: 0;
  margin-left: 10px;
  margin-right: 10px;
  padding: 0;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-bottom .modals li {
  display: flex;
  flex-shrink: 0;
  margin: 3px 0;
  padding: 0;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-bottom .modals li button {
  border: none;
  outline: none;
  border-radius: 20px;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  color: #fff;
  font-size: 12px;
  font-weight: 900;
  background-color: #668aea;
  padding: 6px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-notes-active .inbox-crm-sidebar-notes-header {
  height: 72px;
  background-color: #568da5;
  border: 0;
  flex: none;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-notes-active .inbox-crm-sidebar-notes-header:hover {
  background-color: #6599af;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-notes-active .inbox-crm-sidebar-tags,
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-notes-active .inbox-crm-sidebar-external-integration-header {
  display: none;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-person-active .inbox-crm-sidebar-notes-header,
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-person-active .inbox-crm-sidebar-external-integration-header {
  height: 0;
  margin: 0;
  flex: none;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-person-active .inbox-crm-sidebar-notes-header .inner,
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-person-active .inbox-crm-sidebar-external-integration-header .inner {
  display: none;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-person-active .inbox-crm-sidebar-tags {
  display: none;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-person-active .inbox-crm-sidebar-external-integration-person-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-header {
  font-size: 16px;
  background-color: #a7a8a9;
  margin-bottom: 8px;
  border-right: solid 15px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-header:hover {
  background-color: #8d8f90;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-header.active {
  background-color: #5a5c5d;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-header.active:hover {
  background-color: #414243;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-header:nth-of-type(2) {
  border-color: #951c79;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-header:nth-of-type(3) {
  border-color: #2dafc3;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-external-integration-header:nth-of-type(4) {
  border-color: #e28a68;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-tags {
  margin-left: 10px;
  margin-right: 10px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-tags .inbox-crm-sidebar-tags-icon {
  position: absolute;
  top: 10px;
  left: 25px;
  font-size: 16px;
  color: #a6b7c0;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-tags input {
  background-color: transparent;
  font-style: italic;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .inbox-crm-sidebar-tags input::placeholder {
  color: #c9d8e0;
  font-weight: 400;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .plugins h4 {
  font-size: 14px;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .plugins li {
  margin: 5px 0;
}
ssi-inbox-crm-sidebar .inbox-crm-sidebar-container .plugins button {
  width: 100%;
  background: #fff;
  border: none;
  border-radius: 20px;
  display: flex;
  flex-grow: 1;
  font-size: 1em;
  font-weight: 600;
  padding: 3px 20px;
  margin: 0 0 10px 0;
}

ssi-inbox-activity-crm {
  color: #596065;
  display: flex;
  flex-direction: column;
  min-height: 0;
  height: 100%;
}
ssi-inbox-activity-crm .inbox-activity-header-crm {
  background-color: #f9f9f9;
  height: 100px;
  padding: 20px !important;
}
ssi-inbox-activity-crm .inbox-activity-header-crm img {
  height: 80px;
  border-radius: 50%;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  margin-left: 20px;
}
ssi-inbox-activity-crm .inbox-activity-header-crm button {
  font-size: 11px;
  font-weight: normal;
}
ssi-inbox-activity-crm .inbox-activity-header-crm .verified-wrap {
  position: relative;
  display: inline-block;
}
ssi-inbox-activity-crm .inbox-activity-header-crm .verified {
  display: block;
  position: absolute;
  top: -1px;
  right: -1px;
  color: #1da1f2;
  font-size: 20px;
  z-index: 1;
}
ssi-inbox-activity-crm .inbox-activity-header-crm .verified:after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 70%;
  width: 70%;
  background-color: #fff;
  border-radius: 50%;
  z-index: -1;
}
ssi-inbox-activity-crm .inbox-activity-header-crm-inner {
  width: 100%;
  text-align: right;
}
ssi-inbox-activity-crm .inbox-activity-crm-body {
  flex: 1;
  overflow-y: auto;
}
ssi-inbox-activity-crm label {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  margin: 20px 10px;
}
ssi-inbox-activity-crm .inbox-activity-crm-body-inner {
  padding: 10px 25px 25px;
}
ssi-inbox-activity-crm .inbox-activity-crm-section {
  background-color: white;
  padding: 25px;
}
ssi-inbox-activity-crm .inbox-activity-crm-section-statistics {
  display: flex;
}
ssi-inbox-activity-crm .inbox-activity-crm-section-stat {
  flex: 1;
  text-transform: uppercase;
  text-align: center;
  font-size: 11px;
  font-weight: normal;
  position: relative;
  top: 5px;
}
ssi-inbox-activity-crm .inbox-activity-crm-section-stat .sentiment-progressbar {
  margin-top: 0;
}
ssi-inbox-activity-crm .inbox-activity-crm-section-stat-value {
  margin-left: 5px;
}
ssi-inbox-activity-crm .progress {
  position: relative;
  top: 2px;
}
ssi-inbox-activity-crm ssi-crm-profile-sentiment-bar {
  display: block;
  padding: 0 10px;
}
ssi-inbox-activity-crm .inbox-activity-crm-integrations-container {
  display: flex;
}
ssi-inbox-activity-crm .inbox-activity-crm-integration {
  background-color: #a7a8a9;
  color: white;
  flex-basis: 33%;
  margin-right: 8px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 160px;
  text-transform: uppercase;
  border-bottom: solid 15px;
  font-weight: normal;
}
ssi-inbox-activity-crm .inbox-activity-crm-integration:nth-of-type(1) {
  border-color: #951c79;
}
ssi-inbox-activity-crm .inbox-activity-crm-integration:nth-of-type(2) {
  border-color: #2dafc3;
}
ssi-inbox-activity-crm .inbox-activity-crm-integration:nth-of-type(3) {
  border-color: #e28a68;
}
ssi-inbox-activity-crm .inbox-activity-crm-integration:hover {
  background-color: #8d8f90;
}
ssi-inbox-activity-crm .inbox-activity-crm-integration.active {
  background-color: #5a5c5d;
}
ssi-inbox-activity-crm .inbox-activity-crm-integration.active:hover {
  background-color: #414243;
}