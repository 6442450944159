ssi-live-chat {
  display: flex;
  flex-flow: column nowrap;
  flex-shrink: 0;
  flex-grow: 0;
}
ssi-live-chat * {
  display: flex;
}
ssi-live-chat.expanded {
  flex-grow: 1;
}