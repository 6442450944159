@import "./src/apps/angularjs/common/sass/global-variables.scss";
.angular-editor-toolbar {
  #underline-textEditor,
  #strikeThrough-textEditor,
  #subscript-textEditor,
  #superscript-textEditor,
  .angular-editor-toolbar-set:nth-child(5),
  .angular-editor-toolbar-set:nth-child(7),
  .angular-editor-toolbar-set:nth-child(8),
  .angular-editor-toolbar-set:nth-child(9),
  .angular-editor-toolbar-set:nth-child(10),
  .angular-editor-toolbar-set:nth-child(11),
  .angular-editor-toolbar-set:nth-child(12) {
    display: none !important;
  }

  i.fa:before {
    font-size: 16px;
    line-height: 20px;
  }
}

.angular-editor-textarea {
  max-height: 300px !important;

  h2,
  h3,
  h4,
  h5 {
    margin-top: 20px;
  }
}
