@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-top-tags {
  .top-tags {
    background-color: white;
    border-radius: 12px;
    padding: 2em;
  }
  .tag-podiums {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 1em;
    .tag-bar {
      flex: 1 1 33.33%;
      margin-right: 2em;
      &:last-child {
        margin-right: 0;
      }
      &:nth-child(1) {
        ssi-medal-score {
          .medal-score {
            background-color: #fbf3e2;
          }
          .ssi-social-score {
            color: #f0b427 !important;
          }
        }
      }
      &:nth-child(2) {
        ssi-medal-score {
          .medal-score {
            background-color: $lgrey-background;
          }
          .ssi-social-score {
            color: $dmode-body-text !important;
          }
        }
      }
      &:nth-child(3) {
        ssi-medal-score {
          .medal-score {
            background-color: $primary-red;
          }
          .ssi-social-score {
            color: $secondary-red !important;
          }
        }
      }
    }
  }
  .tag-list {
    display: flex;
    flex-flow: row wrap;
    // max-height: 420px;
    .tag-bar {
      width: 25%;
      flex: 1 1 25%;
    }
  }
  .tag-bar {
    width: 100%;
    display: flex;
    align-items: center;
    // justify-content: center;
    padding: 10px 20px;

    &.top {
      padding: 5px 20px 5px 5px;
      margin-bottom: 10px;

      .tag-count {
        font-size: 18px;
      }
    }

    ssi-medal-score {
      margin-right: 15px;
    }

    .progress-bar-wrapper {
      width: 100%;
      margin-right: 15px;

      span {
        color: $lmode-title-text;
        font-size: 14px;
        font-weight: 900;
        line-height: 17px;
        word-break: break-all;
      }
      .percent {
        color: $lmode-helper-text;
        font-weight: 700;
        margin-left: 5px;
      }
    }

    .tag-count {
      color: $lmode-title-text;
      font-size: 14px;
      font-weight: 900;
      line-height: 22px;
    }

    .tag-position {
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
      color: $lmode-helper-text;
      margin-right: 8px;
    }
  }
}
