@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import "../../../../../angularjs/common/sass/mixins";
@import "../../../../../angularjs/common/sass/variables";
@import "../../../../../../library/sass/live-chat/colours";

$component-height: 72px;
$live-chat-navigation-background-colour: #EDF1F3;
$live-chat-navigation-foreground-colour: #9DAEB7;
$live-chat-navigation-item-exit-background-colour: #D8DFE5;
$unit: 2.5px;

ssi-live-chat-navigation {
  background: $live-chat-list-item-gradient $live-chat-list-item-background-colour;
  display: flex;
  flex-basis: $component-height;
  height: $component-height;
  min-height: $component-height;

  * {
    display: flex;
  }

  & > ul {
    flex-flow: row nowrap;
    flex-grow: 1;
    list-style: none;
    margin: 0;
    padding: 0;
    transform: none !important;

    & > li {
      border: solid ($unit * 1) #fff;
      border-radius: ($unit * 12);
      flex-grow: 1;
      flex-shrink: 0;
      margin: ($unit * 6) ($unit * 4);

      &.exit {
        background-color: $live-chat-navigation-item-exit-background-colour;
        position: relative;

        .icon {
          left: ($unit * 4);
          position: absolute;
        }

        .text {
          margin-left: ($unit * 4);
        }
      }

      &.date-range,
      &.sorting {
        .icon {
          margin-left: ($unit * 4);
        }
      }

      &.date-range,
      &.sorting {
        &.show {
          display: flex !important;
        }

        .text {
          font-style: italic;
        }
      }

      .dropdown-menu {
        border: none;
        border-radius: ($unit * 8);
        margin: 0;
        overflow: hidden;
        padding: 0;
        transform: none !important;
        width: 100%;
        z-index: 9;

        & > li {
          padding: ($unit * 4);
        }
      }

      button,
      select {
        align-items: center;
        background-color: transparent;
        border: none;
        color: darken($live-chat-navigation-foreground-colour, 30%);
        display: flex;
        flex-grow: 1;
        flex-shrink: 0;
        font-size: 0.9em;
        font-weight: 600;
        justify-content: center;
        padding: ($unit * 2) ($unit * 4);
        position: inherit;

        &[disabled] {
          cursor: not-allowed;
          opacity: 0.65;
        }

        &:focus {
          outline: revert !important;
        }

        &.show {
          display: flex !important;
        }

        & > * {
          display: inline;
        }
      }

      .icon {
        font-size: 1em;
        font-weight: 300;
      }
    }
  }

  /* stylelint-disable */
  @include responsiveWidth($ssi-screen-width-sm) {
    & > ul {
      & > li {
        .icon {
          font-size: 0.85em;
        }

        button {
          font-size: 0.75em;
        }
      }
    }
  };
  /* stylelint-enable */

}
