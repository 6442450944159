@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../angularjs/common/sass/variables';
@import '../../../../../../angularjs/common/sass/mixins';
@import '~/styles/colors';

ssi-insights ssi-reports ssi-edit {
  font-size: 1rem;
  * {
    box-sizing: border-box;
  }

  $appHeaderHeight: 68px;
  $actionBarHeight: 40px;

  .insights-reports-edit-container {
    width: 100%;
    position: relative;
    // background-color: #f8f9fd;
    background-color: #f4f4fa;
    min-height: calc(100vh - #{$navbar-height});
    overflow-x: hidden;

    .cool-class-name {
      .actions-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 70px;
        padding: 20px 20px 10px 20px;

        .left {
          ssi-dropdown-select-2 {
            button.dd-head {
              background-color: #fff;

              .head-button-label {
                > span {
                  color: #14bae3;
                }
              }
            }
          }
        }

        .right {
          display: flex;
          align-items: center;

          button {
            border: none;
            outline: none;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            color: #43537f;
            font-size: 14px;
            font-weight: 900;
            background-color: #e2e2ee;
            padding: 10px 20px;
            margin-left: 10px;

            &:hover {
              color: #14bae3;
            }

            &.save-btn {
              background-color: $primary-active;
              color: #fff;

              &:not([disabled]):hover {
                color: #fff;
              }
            }

            &:disabled {
              background-color: $dmode-body-text;
            }

            &.cancel-btn {
              background-color: #f4f4fa;
              border: 1px solid #838eab;
            }
          }
        }
      }
    }
  }
}
