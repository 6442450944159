ssi-instagram-story-stats {
  font-size: 1rem;
}
ssi-instagram-story-stats .section-head {
  margin-bottom: 20px;
}
ssi-instagram-story-stats .section-title {
  color: #101525;
  font-size: 20px;
  font-weight: 900;
  margin: 0;
}
ssi-instagram-story-stats .facts-summary-container .section-body {
  width: 100%;
}
ssi-instagram-story-stats .facts-summary-container .section-body .facts-row {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(26.8em, 1fr));
  grid-gap: 0 2em;
  margin: 0;
}
@media print {
  ssi-instagram-story-stats .facts-summary-container .section-body .facts-row {
    display: block;
  }
  ssi-instagram-story-stats .facts-summary-container .section-body .facts-row > * {
    display: inline-block;
    width: auto !important;
  }
}
ssi-instagram-story-stats .facts-summary-container .section-body .facts-row > * {
  width: 100%;
  padding: 7px;
}
ssi-instagram-story-stats .facts-summary-container .section-body .facts-row > *:first-child {
  margin-left: 0;
}
ssi-instagram-story-stats ssi-fact-overview {
  min-width: 260px;
}
ssi-instagram-story-stats ssi-fact-overview .fact-overview {
  box-shadow: 0 0.5em 1em rgba(131, 142, 171, 0.35);
  margin-bottom: 10px;
}