@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-office-hours {
  table {
    &.table {
      tbody {
        tr {
          td {
            vertical-align: middle;
          }
        }
      }
    }
  }

  .network-icon {
    margin-right: 5px;
  }
}
