@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../angularjs/common/sass/variables';
@import '../../../../../angularjs/common/sass/mixins';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';
@import '../publisher.variables.scss';
@import '../publisher.mixins.scss';
@import '~/styles/colors';
@import '../../../../../angularjs/common/sass/include-media';

$ssi-publisher-responsive-width: 885px;

ssi-publisher-landing {
  $publisher-textarea-zindex: 2;

  display: flex;
  height: 100%;

  @media (max-width: $ssi-publisher-responsive-width) {
    flex-direction: column;
    align-items: center;
  }

  ssi-dropdown-select {
    z-index: $publisher-textarea-zindex + 2;

    &.dd-active {
      z-index: $publisher-textarea-zindex + 3;
    }
  }

  .publisher-landing-left,
  .publisher-landing-right {
    display: flex;
    flex-direction: column;
    @media (max-width: $ssi-publisher-responsive-width) {
      align-items: center;
    }
    border-radius: 15px;
  }

  .publisher-landing-left {
    z-index: 1 !important;
    background-color: transparent;
    padding: 0;
    @media (max-width: $ssi-publisher-responsive-width) {
      margin: 10px 0px;
      width: 100vw;
    }
    @media (min-width: $ssi-publisher-responsive-width) {
      margin: 40px 10px 20px 20px;
      flex: 1 1 52%;
    }
  }

  .publisher-landing-right {
    @media (max-width: $ssi-publisher-responsive-width) {
      width: 100vw;
    }
    @media (min-width: $ssi-publisher-responsive-width) {
      margin: 40px 20px 20px 10px;
      flex: 1 1 48%;
    }
  }

  .publisher-textarea-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #fff;
    z-index: 0;
    line-height: 19px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    @media (max-width: $ssi-publisher-responsive-width) {
      width: 95vw;
    }
    @mixin publisher-link-tag($bg-color) {
      background-color: $bg-color;

      &.publisher-link-tag-hover {
        background-color: darken($bg-color, 1%);
      }
    }

    .publisher-link-tag-primary {
      color: $primary-active;
      text-decoration: underline;

      &.publisher-link-tag-hover {
        @include publisher-link-tag($lmgrey-background);
        text-shadow: 0 0 transparent !important;
      }
    }

    .publisher-link-tag-warning {
      color: $primary-active;
      text-decoration: underline;

      &.publisher-link-tag-hover {
        @include publisher-link-tag($lmgrey-background);
        text-shadow: 0 0 transparent !important;
      }
    }

    .publisher-textarea-footer {
      display: flex;
      background-color: #fff;
      padding: 20px;
      z-index: 5;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;

      &.mobile-device {
        padding: 10px;
      }

      &.publisher-textarea-footer-addl-if-mobile {
        padding: 10px;
        border-top: 1px solid #c6cfe4;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
      }
    }

    .publisher-textarea-footer-left {
      flex: 1;
      .btn {
        border-radius: 10px;
        border: 0;
        line-height: 24px;
        margin-right: 3px;
        height: 42px;
        min-width: 42px;
        padding: 0;

        &:focus {
          outline: revert;
        }

        &.btn-primary {
          background-color: $primary-active;
        }

        &.dropdown-toggle {
          padding: 0 9px;

          .ssi {
            font-size: 14px;
          }

          .dropdown-toggle-meta {
            font-size: 11px;
            font-weight: 900;
            margin: 0 8px 0 0;
            text-rendering: geometricPrecision;
          }

          .caret {
            border-top: 5px dashed;
            border-top: 5px solid;
            border-right: 5px solid transparent;
            border-left: 5px solid transparent;
            margin: 0 2px 0 0;
          }

          & + .dropdown-menu {
            min-width: 230px;
            padding: 20px 15px;
            border-radius: 10px;
            box-shadow: 0 0 18px rgba(131, 142, 171, 0.35);

            .dropdown-item {
              width: 100%;
              overflow: hidden;
              word-wrap: none;
              text-overflow: ellipsis;
              color: $lmode-body-text;
              font-size: 14px;
              line-height: 36px;
              font-weight: 900;
              height: 36px;
              padding: 0 20px;
              border-radius: 200px;
              margin: 0 0 4px;
              text-rendering: geometricPrecision;

              &.active {
                background-color: $primary-active !important;
                color: #fff;
              }

              &:last-child {
                margin: 0;
              }
            }
          }
        }

        i {
          font-size: 16px;
          line-height: 30px;

          &.ssi-first-comment {
            font-size: 20px;
          }
        }
      }

      .btn-left-action {
        background-color: $lmgrey-background;
        color: $primary-active;
        font-size: 12px;

        &-active {
          background-color: $primary-active;
          color: #fff;
        }
      }

      .btn-split-post {
        background-color: $lmgrey-background;
        color: $primary-active;
        font-size: 12px;
        font-weight: 900;
        border-radius: 10px;
        width: auto;

        .ssi {
          margin: 0 6px 0 0;
        }

        &.btn-split-post-action {
          padding: 0 16px;
        }
      }

      [ngbDropdown].show {
        display: inline-block !important;
      }

      .split-post-account-select-menu {
        overflow-y: auto;
        max-height: 230px;
      }
    }
    .publisher-textarea-footer-right {
      @include align-vertical(auto);
      justify-content: flex-end;
      margin-top: 10px;
      $publisher-textarea-footer-icon-color: #c0cad2;
      .publisher-textarea-character-count {
        font-weight: 700;
        font-style: italic;
        margin-right: 5px;
        font-size: 14px;
        color: darken($lmode-helper-text, 10%);
        &.active {
          color: $primary-active;
        }
        &.invalid {
          color: $brand-danger;
        }
      }
      .publisher-textarea-footer-right-icon {
        color: $publisher-textarea-footer-icon-color;
        opacity: 1 !important; // @todo: set fire to this code and bury it at a crossroads.

        &:focus {
          outline: revert;
        }
      }
      .label {
        background-color: $publisher-textarea-footer-icon-color;
        line-height: 18px;
        border-radius: 15px;
        margin-top: 2px;
        font-style: italic;
        font-weight: 300;
        i {
          font-size: 15px;
          position: relative;
          top: 2px;
        }
      }
      .label.active,
      .label:hover {
        background-color: $primary-active;
      }
      .label,
      .publisher-textarea-footer-right-icon {
        margin: 0 5px;
      }
      .publisher-textarea-footer-right-icon {
        font-size: 20px;
        color: $lmode-helper-text;
        &:hover {
          color: $primary-active;
          text-decoration: none;
        }
      }
      .add-more {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $lmode-border-color;
        color: $primary-active;
        border-radius: 15px;
        padding: 5px 7px;
        font-size: 11px;
        font-weight: bold;
        i {
          font-size: 15px;
          margin-left: 4px;
          &:first-child {
            font-size: 11px;
            font-weight: bold;
            margin-right: 5px;
          }
        }
      }

      .ai-writing-tool-context-menu {
        position: absolute;
        background-color: #fff;
        bottom: 15px;
        right: 15px;
        display: block;
        font-size: 1em;
        margin: 0;
        padding: 1.8em 0;
        border-radius: 1.5em;
        box-shadow: 0 1.8em 4em rgb(131 142 171 / 35%);
        border-width: 0;
        min-width: 18em;

        .menu-item {
          display: flex;
          align-items: center;
          height: 3.6rem;
          padding: 0 2em 0 1rem;
          border-radius: 1rem;
          margin: 0.2rem 2rem;
          cursor: pointer;

          i {
            color: $lmode-body-text;
            font-size: 1.4rem;
            margin: 0 1.5rem 0 0;
            color: $lmode-helper-text;
          }

          span {
            font-size: 1.4rem;
            font-weight: 900;
            text-rendering: geometricPrecision;
            color: $lmode-body-text;
          }

          &:hover {
            background-color: $grey-background;
          }
        }

        hr {
          border-top: 1px solid #c3c9d8;
        }

        a:hover {
          text-decoration: none;
        }

        ssi-icon {
          margin-right: 1.5rem;
        }
      }

      .ai-writing-tool-icon {
        font-size: 18px;
        margin-left: 5px;
        background-color: #f2f4fc;
        border-radius: 10px;
        padding: 10px;
      }
    }
    .no-accounts-wrap {
      display: none;
    }
  }
  .publisher-textarea-container.disabled {
    textarea,
    .publisher-textarea-footer {
      background-color: $publisher-action-disabled-bg;
    }
    textarea {
      text-align: center;
      padding-top: 120px;
    }
    .no-accounts-wrap {
      display: block;
      position: absolute;
      z-index: 2;
      top: 35%;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
      color: $dmode-body-text;

      p {
        color: darken($dmode-body-text, 25%);
        font-weight: 700;
        margin: 10px 0 20px;
      }

      .ssi {
        display: block;
        font-size: 40px;
        color: inherit;
      }
      @media (max-width: $ssi-publisher-responsive-width) {
        padding: 15px;
      }
    }
  }
  .text-highlight-element {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100% !important;
    @include ie-only {
      width: 100% !important;
      height: 201px !important;
    }
  }
  .text-input-highlight-container {
    .text-highlight-element {
      pointer-events: none;
      background: transparent;
      z-index: 2;

      .text-highlight-tag {
        text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
      }
    }
  }
  .publisher-text-form-control {
    width: 100%;
    height: 100%;
    flex: 1;
    background-color: transparent;
    z-index: $publisher-textarea-zindex;
    padding-top: 12px;
    position: relative;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    resize: none;
    border: 0;
    padding: 38px 36px;
    outline: none;
    line-height: 19px;
  }
  .publisher-text-form-control,
  .publisher-video-title-form-control {
    font-weight: normal;
    color: $publisher-input-color;

    @media (max-width: $ssi-publisher-responsive-width) {
      width: 95vw;
    }
  }
  .publisher-text-form-control::placeholder,
  .publisher-video-title-form-control::placeholder {
    color: $publisher-input-placeholder-color;
    font-weight: bold;
  }
  .publisher-video-title-form-control,
  .publisher-video-title-form-control:focus {
    border-bottom: solid 3px #eef0f2;
  }
  .publisher-btns {
    @media (max-width: $ssi-publisher-responsive-width) {
      flex-direction: column;
      width: 95vw;
    }
    margin-bottom: $section-margin-width;
    z-index: 1;
    .btn {
      border-radius: 20px;
      padding: 13px;
      &:focus {
        outline: revert;
      }
      @media (max-width: $ssi-publisher-responsive-width) {
        padding: 10px 15px 10px 25px;
        text-align: left;
      }
      font-weight: 700;
      i.pull-right {
        position: relative;
        top: 3px;
      }

      span {
        i.fa-youtube-play {
          font-size: 23px;
          vertical-align: bottom;
        }
      }
    }
    .dropdown-select-menu {
      border-radius: 20px;
      background-color: $primary-active;
    }

    .accounts-dd {
      @media (min-width: $ssi-publisher-responsive-width) {
        margin-right: calc(calc($section-margin-width));
      }
      .dropdown-select-menu-item {
        background-color: $primary-active;
        border: 0;
        &:hover {
          background-color: #11acd3;
        }
      }
    }
    .campaigns-dd {
      @media (min-width: $ssi-publisher-responsive-width) {
        margin-left: calc(calc($section-margin-width));
      }
      .dropdown-select-menu-item {
        background-color: #57576a;
        border: 0;
      }
    }

    .tags-select-container {
      position: relative;

      .tags-selector-btn {
        width: 48px;
        height: 48px;
        background-color: #707f8b;
        margin-left: calc($section-margin-width);
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .ssi-tags {
          color: #fff;
          font-size: 28px;
          font-weight: 500;
        }

        &:hover {
          background-color: #59656f;
        }

        span {
          display: none;
          @include media('<#{$ssi-publisher-responsive-width}') {
            display: block;
            color: #fff;
            font-weight: 700;
          }
        }

        @include media('<#{$ssi-publisher-responsive-width}') {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding-left: 25px;
          margin: 0;
          height: 42px;
        }
      }

      .selected-tags-count {
        position: absolute;
        top: -4px;
        right: -4px;
        width: 18px;
        height: 18px;
        border: 1px solid #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #707f8b;

        span {
          font-size: 10px;
          color: #fff;
          font-weight: 700;
        }
      }

      .no-tags-state {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        padding: 0 10px;
        // height: 160px;

        i {
          margin-top: 20px;
          color: #b4bbce;
          font-size: 30px;
        }

        .title {
          margin-top: 15px;
          color: $lmode-body-text;
          font-size: 16px;
          font-weight: 900;
        }

        .desc {
          margin-bottom: 20px;
          color: $lmode-body-text;
          font-size: 14px;
          font-weight: 700;
          padding: 0 24px;
          text-align: center;
        }

        button {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 20px;
          width: 100%;
          i {
            margin: 0;
            margin-left: 10px;
            font-size: 12px;
            color: #fff;
          }
          span {
            white-space: nowrap;
          }
        }
      }
    }

    .publisher-close-compose {
      display: none;
      @include media('<#{$ssi-publisher-responsive-width}') {
        display: flex;
      }

      .publisher-close-btn {
        width: 100%;
        height: 42px;
        text-align: center;
        border-radius: 10px;
        margin: 2px;
      }
    }
  }
  .publisher-textarea-container-badge {
    position: absolute;
    background-color: #b2c614;
    padding: 4px 9px;
    top: 10px;
    right: 15px;
    z-index: 3;
    font-size: 10px;
    line-height: 12px;
    color: #fff;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 900;
    text-rendering: geometricPrecision;
    border-radius: 100px;
  }

  $publisher-tooltip-window-bg-dark: $lmode-title-text;
  $publisher-tooltip-window-bg-light: #303449;
  $publisher-tooltip-window-font-light: $lmode-helper-text;
  $publisher-tooltip-window-font-size: 12px;
  $publisher-tooltip-window-item-margin: 5px;
  $publisher-link-config-prefix-item-height: 30px;
  .publisher-tooltip-window {
    width: 670px;
    background-color: #242a3e;
    padding: 20px;
    border-radius: 10px;
    max-width: none;
    z-index: 99999999;
    @media (max-width: $ssi-publisher-responsive-width) {
      max-width: 99vw;
    }
    // @include ie-only {
    // width: 610px;
    // }
    &,
    .form-control,
    .input-group-addon,
    .btn {
      font-size: $publisher-tooltip-window-font-size;
    }
    .btn {
      font-weight: 700;
      min-width: 100px;
    }
    .form-control-wrap {
      display: flex;
      align-items: center;
      background-color: $publisher-tooltip-window-bg-dark;
    }
    .form-control {
      background-color: $publisher-tooltip-window-bg-dark;
      font-weight: normal;
      border-radius: 3px;
      color: #fff;
    }
    .form-control::placeholder {
      color: $dmode-body-text;
      font-weight: 700;
      text-rendering: geometricPrecision;
    }
    .btn-primary {
      background-color: $primary-active;
      border-color: $primary-active;
      &:disabled {
        background-color: $publisher-tooltip-window-bg-light;

        border-color: $publisher-tooltip-window-bg-light;
      }
    }
    .input-group-addon,
    input.form-control,
    .btn {
      height: $publisher-link-config-prefix-item-height;
    }
    .text-danger {
      color: $brand-danger;
    }
  }
  .info-wrap {
    border-right: 2px solid $publisher-tooltip-window-bg-dark;
    border-top: 2px solid $publisher-tooltip-window-bg-dark;
    border-bottom: 2px solid $publisher-tooltip-window-bg-dark;
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    height: 30px;
    transition: border-color 0.15s ease-in-out;
  }
  .info {
    display: inline-block;
    height: 16px;
    width: 16px;
    line-height: 16px;
    vertical-align: middle;
    text-align: center;
    font-size: 12px;
    color: $lmode-helper-text;
    border-radius: 100%;
    &.info-light {
      &:hover {
        background-color: #303449;
      }
    }
    &.info-dark {
      &:hover {
        background-color: #242a3e;
      }
    }
  }
  .publisher-link-config-tooltip-wrap {
    width: 100%;
    @media (max-width: $ssi-publisher-responsive-width) {
      max-width: 100vw;
    }
  }

  .publisher-link-config-tooltip-top {
    display: flex;
    justify-content: space-between;
    margin: 0 0 20px;
    @media (max-width: $ssi-publisher-responsive-width) {
      flex-wrap: wrap;
    }
  }
  .publisher-link-config-togglers {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .publisher-link-config-toggler {
    display: inline-flex;
    align-items: center;
    color: $publisher-tooltip-window-font-light;
    margin: 0 12px 0 0;
    cursor: pointer;
    i {
      color: $publisher-tooltip-window-font-light;
      font-size: 20px;
      line-height: 1;
    }
    &.active {
      i {
        color: $primary-active;
      }
    }
    &.disabled {
      cursor: not-allowed;
    }
  }
  .publisher-link-config-prefix {
    text-rendering: geometricPrecision;
    @include align-vertical(100%);
    .input-group {
      width: 360px;
      @media (max-width: $ssi-publisher-responsive-width) {
        max-width: 100vw;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .form-control-wrap .info {
        margin-right: 6px;
      }
      .form-control {
        border-left: 2px solid $publisher-tooltip-window-bg-dark;
        border-top: 2px solid $publisher-tooltip-window-bg-dark;
        border-bottom: 2px solid $publisher-tooltip-window-bg-dark;
        &:focus {
          border-left: 2px solid $primary-active;
          border-top: 2px solid $primary-active;
          border-bottom: 2px solid $primary-active;
          & + .info-wrap {
            border-right: 2px solid $primary-active;
            border-top: 2px solid $primary-active;

            border-bottom: 2px solid $primary-active;
          }
        }
      }
    }
    .input-group-addon {
      background-color: $publisher-tooltip-window-bg-light;
      color: #fff;
      border: 0;
      font-weight: 700;
      line-height: 14px;
      .info {
        margin-right: -6px;
      }
    }
  }
  .publisher-link-config-prefix-alert {
    display: flex;
    align-items: center;
    background-color: $primary-red;
    border-radius: 10px;
    padding: 0 15px;
    max-width: 465px;
    height: 38px;
    a {
      line-height: 10px;
    }
    p {
      display: inline-block;
      font-weight: 700;
      font-size: 10px;
      line-height: 10px;
      color: $lmode-body-text;
      margin: -5px 0 0;
    }
    .ssi {
      color: $secondary-red;
      margin: 0 10px 0 0;
      line-height: 10px;
    }
  }
  @mixin publisher-link-config-prefix-alert-color($text-color) {
    &,
    a {
      color: $text-color;
    }
  }
  .publisher-link-config-prefix-alert.text-danger {
    @include publisher-link-config-prefix-alert-color($brand-danger);
  }

  .publisher-link-config-prefix-alert.text-primary {
    @include publisher-link-config-prefix-alert-color($primary-active);
  }

  .publisher-link-config-tooltip-link-preview-visible {
    .publisher-link-config-togglers {
      height: auto;
      flex-direction: row;
      @include align-vertical(100%);
    }
    .publisher-link-config-toggler {
      margin-bottom: 0;
      height: $publisher-link-config-prefix-item-height;
      span {
        display: none;
      }
    }
    .publisher-link-config-prefix {
      margin-left: 0;
    }
  }
  .publisher-link-config-tooltip-bottom {
    display: flex;
    width: 100%;
    @media (max-width: $ssi-publisher-responsive-width) {
      flex-direction: column;
    }
    .trigger-linkedin-image {
      width: 50%;
      max-width: 150px;
      margin-top: 10px;
      align-self: center;
    }
    .loading {
      flex: 1 0 auto;
    }
  }
  .publisher-link-config-tooltip-bottom-form {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
    min-height: 155px;
    .form-control {
      padding: 14px;
      border-radius: 5px;
      line-height: 20px;
    }
  }
  .publisher-link-config-tooltip-bottom-form-title {
    border: 2px solid $publisher-tooltip-window-bg-dark;
    &.form-control {
      padding: 0px 12px;
    }
    &:focus {
      border: 2px solid $primary-active;
    }
  }
  .publisher-link-config-tooltip-bottom-form-title-disabled {
    cursor: not-allowed;
    white-space: normal;
    &.form-control {
      min-height: 30px;
      height: auto;
      line-height: 18px;
      padding: 6px 14px;
      background-color: $publisher-tooltip-window-bg-light;
    }
  }
  .publisher-link-config-tooltip-bottom-form-textarea {
    flex: 1 0 auto;
    margin: 4px 0 0;
    overflow-x: hidden;
    overflow-y: auto;
    cursor: not-allowed;
    white-space: normal;

    &.form-control {
      background-color: $publisher-tooltip-window-bg-light;
    }
  }
  $publisher-linkedin-image-tooltip-height: 150px;
  $publisher-linkedin-image-tooltip-width: 400px;
  .publisher-linkedin-image-tooltip {
    width: $publisher-linkedin-image-tooltip-width;
    height: $publisher-linkedin-image-tooltip-height;
  }
  .publisher-landing-dropdown-item-indent {
    margin-left: 20px;
    display: inline-block;
    i {
      width: 18px;
    }
  }
  .publisher-landing-dropdown-item-indent.underline {
    padding-bottom: 3px;
    border-bottom: solid 1px #8b939a;
  }
  .override-zindex {
    z-index: 4;
  }

  .zero-zindex {
    z-index: 0;
  }
  .media-nav-wrap {
    flex: 0 0 auto;
    height: 155px;
    width: 315px;
    margin: 0 0 0 4px;
    border-radius: 5px;
    overflow: hidden;
    @media (max-width: $ssi-publisher-responsive-width) {
      width: 88vw;
    }
    .media-nav-img {
      flex: 1 0 auto;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      height: 100%;
    }
  }
  // to be shared on two child components:
  .publisher-close-btn {
    border: 0;
    padding: 13px 25px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    background-color: $secondary-red;
    color: #fff;
    border-radius: 25px;
    font-weight: bold;
    margin: 0 0 8px auto;
  }
  .account-option {
    display: flex;
    justify-content: space-between;
    .account-option-left {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 14px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      i {
        margin-right: 5px;
        &.fa-youtube-play {
          font-size: 22px;
        }
      }
    }
    .account-option-right {
      flex: 0 0 25%;
      display: flex;
      justify-content: space-between;
      div {
        &:last-of-type {
          min-width: 20px;
          margin-left: 5px;
        }
      }
      .account-type {
        display: flex;
        align-items: center;
        min-width: 80px;
        padding: 2px 10px;
        font-size: 10px;
        letter-spacing: 1px;
        border-radius: 12px;
        background-color: #0c9bbf;
        i {
          font-size: 10px;
          width: 14px;
        }
      }
    }
  }
  .publisher-mention-click-context-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 160px;
    padding: 8px;
    border-radius: 10px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);

    button {
      border: 0;
      outline: 0;
      padding: 8px 10px;
      border-radius: 20px;
      background: #ffffff;
      font-weight: 900;
      color: $lmode-body-text;
      cursor: pointer;
      &:hover {
        color: $primary-active;
        background-color: $grey-background;
      }
    }

    .vertical-line {
      height: 20px;
      width: 2px;
      background-color: rgba(180, 187, 206, 0.5);
      margin: 0 5px;
    }

    a {
      padding: 5px 10px;
      border-radius: 50%;
      color: $lmode-body-text;
      cursor: pointer;
      &:hover {
        color: $primary-active;
        background-color: $grey-background;
      }
    }
  }
  .post-info {
    border: 2px solid $lmode-helper-text;
    border-radius: 100%;
    display: inline-block;
    margin-left: 10px;
    i {
      padding: 5px;
    }
  }

  .pt-a11y-label {
    position: absolute;
    text-indent: -9999px;
  }

  .video-visibility {
    display: inline-block;
    flex: 0 1 0;
    position: relative;

    .ssi {
      position: absolute;
      pointer-events: none;
      color: #838eab;
      height: 1em;
      top: 0;
      bottom: 0;
      font-size: 1.2em;
      right: 2rem;
      margin: auto;
    }

    select {
      height: 42px;
      background-color: #f4f4fa;
      border: none;
      color: $lmode-body-text;
      font-weight: 900;
      text-rendering: geometricPrecision;
      padding: 8px 14px;
      border-radius: 1rem;
      vertical-align: middle;
      appearance: none;
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }

    &:not(:first-child) {
      select {
        width: 160px;
      }
    }
  }

  ssi-menu {
    .menu-container .menu-body {
      width: 370px;
      height: 330px;
      border-radius: 15px;
      box-shadow: 0 5px 15px rgba(116, 118, 132, 0.25);
      overflow: hidden;
    }

    hr {
      margin: 10px 0;
    }

    i {
      &.active {
        color: $primary-active;
      }
    }

    .heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 60px 10px 60px;

      span {
        color: $primary-active;
        font-weight: 900;
        font-size: 14px;
      }

      i {
        color: $primary-active;
        font-size: 10px;
        margin-left: 5px;
        margin-right: auto;
      }
    }
  }

  .tags-input-input-container {
    padding: 10px 15px 5px 40px;

    label {
      width: 100%;
      position: relative;
    }

    i {
      font-size: 0.6em;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 1.3rem;
      right: 1.2rem;
      color: $primary-active;
      cursor: pointer;
    }

    input {
      width: 100%;
      border: 1px solid $primary-active;
      border-radius: 10px;
      padding: 8px 30px 8px 20px;
      color: $lmode-title-text;
      background-color: $grey-background;
      font-size: 14px;
      &::placeholder {
        color: $lmode-helper-text;
      }
      &:focus,
      &:active,
      &:focus-visible {
        border-color: $primary-active;
        outline: none;
      }

      &[type='text'] {
        color: $lmode-title-text;
      }
    }
  }
  ul,
  li {
    list-style: none;
    margin: 0;
  }

  ul {
    overflow: auto;
    height: 190px;
    padding: 10px 60px;

    p {
      color: $lmode-helper-text;
    }
  }

  li {
    position: relative;
    color: $lmode-body-text;
    margin-bottom: 15px;
    font-weight: 900;
    font-size: 14px;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    span {
      max-width: 230px;
      overflow: hidden;
      display: inline-block;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .select-icon {
      display: none;
      color: $primary-active;
      position: absolute;
      right: 0;
      font-weight: 900;
    }

    &.selected {
      color: $primary-active;

      .select-icon {
        display: inline-flex;
      }
    }
    &:hover {
      cursor: pointer;
      color: $primary-active;
    }
  }

  @include media('<#{$ssi-publisher-responsive-width}') {
    ssi-dropdown-select,
    ssi-dropdown-select-2 {
      margin: 2px;
    }
  }
}
