@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-rounded-box-check {
  max-width: fit-content;

  .rounded-box-check-container {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 900;
    padding: 12px;
    background-color: #f4f4fa;
    color: #6a759a;
    border-radius: 8px;
    cursor: pointer;
    user-select: none;
    max-width: fit-content;
    white-space: nowrap;
    border: 1px solid transparent;
    i {
      font-size: 12px;
    }

    &.bordered-initial {
      border-color: #c3c9d8;
    }

    &.selected {
      color: $lmode-body-text;
      border: 1px solid $primary-active;
      background-color: #e4faff;

      i {
        color: $primary-active;
      }
    }
  }

  i {
    margin-right: 10px;
  }
}
