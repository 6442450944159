@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

$controls-height: 61px;
$controls-line-height: 60px;
$blue: $primary-active;
$chart-responsive-width: 885px;

ssi-highchart-controls {
  display: flex;
  justify-content: space-between;
  margin: -50px 0 10px;
  @media (max-width: $chart-responsive-width) {
    flex-direction: column;
  }
}

.highchart-controls-filter {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: $controls-height;
  vertical-align: middle;
  padding: 0 0 0 30px;
}

.highchart-controls-filter-select {
  appearance: none;
  padding: 0 30px 0 0;
  border: none;
  background-color: transparent;
  outline: 0;
  font-weight: 700;
  color: $blue;
  cursor: pointer;

  &::-ms-expand {
    display: none;
  }
}

.highchart-controls-filter-select-icon {
  position: absolute;
  color: $blue;
  font-size: 16px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 16px;
  right: 5px;
  pointer-events: none;
}

.highchart-controls-actions {
  position: relative;
  display: inline-block;
  padding: 0;
  height: $controls-height;
  line-height: $controls-line-height;
  vertical-align: middle;
  text-align: right;
  white-space: nowrap;
}

.highchart-controls-action {
  display: inline-block;
  padding: 0 14px;
  font-size: 12px;
  border-left: 1px solid rgb(204, 214, 235);
  font-weight: 500;
  line-height: 1;
  color: $lmode-body-text;
  font-weight: 700;
  cursor: pointer;

  &:first-child {
    border-left-width: 0;
  }
}

.highchart-controls-action-icon {
  margin-left: 6px;
}

.highchart-controls-date-wrap {
  position: relative;
  text-align: right;
  margin-top: 10px;
  height: 51px;
  padding-left: 0;
  white-space: nowrap;
}

.highchart-controls-date-picker {
  position: relative;
  display: inline-block;
  max-width: 320px;
  margin-bottom: 0;
  background-color: #f9f9f9;
  border-radius: 20px;
  min-width: 180px;
  margin-left: 5px;

  input {
    border-width: 0;
    padding: 12px 20px;
    height: auto;
    box-shadow: none;
    border-radius: 20px;
    color: #a6b7c0;
    font-size: 12px;
    font-weight: 500;
    background-color: transparent;
  }

  .ssi {
    position: absolute;
    pointer-events: none;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 18px;
    font-size: 16px;
    color: $blue;
  }
}

.highchart-controls-date-picker .form-control {
  background-color: transparent;
}

.highchart-controls-date-action {
  display: block;
}

.highchart-controls-action-active {
  color: $blue;
}

.highchart-controls-date-picker input.ng-untouched {
  font-style: italic;
}

.highchart-controls-date-toggle {
  border-radius: 50%;
  display: inline-block;
  margin: 0 15px 0 8px;
  height: 41px;
  width: 41px;
  background-color: #f3f6f7;
  text-align: center;
  cursor: pointer;
}

.highchart-controls-date-toggle-icon {
  color: #a6b7c0;
  line-height: 41px;
}

.flatpickr-calendar {
  padding: 0 30px;
  min-width: 410px;
  border-radius: 15px;
  border-width: 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

  &:before,
  &:after {
    content: normal;
  }
}

.flatpickr-months {
  padding: 35px 0 10px;
  color: $blue;

  .flatpickr-month {
    height: auto;
    width: 100%;
    background-color: #f9f9f9;
    border-radius: 15px;
    padding: 8px;
  }

  .flatpickr-prev-month,
  .flatpickr-next-month {
    display: flex;
    align-items: center;
    margin: 35px 30px 0;
    height: 33px;
    font-size: 0;

    &:before {
      font: normal normal normal 18px/1 Socialsignin;
      text-rendering: auto;
      font-smoothing: antialiased;
    }
  }

  .flatpickr-prev-month:before {
    content: '\f0b3';
  }

  .flatpickr-next-month:before {
    content: '\f0b4';
  }
}

.flatpickr-current-month {
  position: static;
  font-size: 14px;
  height: auto;
  padding-top: 0;

  span.cur-month,
  .numInputWrapper input {
    color: $blue;
    font-weight: 600;
  }
}

.flatpickr-innerContainer {
  display: flex;
  justify-content: center;
  padding-bottom: 35px;
}

.flatpickr-rContainer {
  background-color: #f9f9f9;
  border-radius: 15px;
  padding: 20px 15px;
  width: 100%;
}

span.flatpickr-weekday {
  font-weight: 600;
  color: #a6b7c0;
  font-size: 13px;
}

.flatpickr-day {
  font-weight: 300;
  color: #a6b7c0;
  font-size: 15px;
}

.flatpickr-day.selected {
  background-color: $primary-active;
}

.flatpickr-day.disabled,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay {
  color: #a6b7c0;
  opacity: 0.4;
}
