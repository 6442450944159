ssi-stream-post .insights-post-container {
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #c3c9d8;
}
ssi-stream-post .insights-post-container.post-selected {
  border: 1px solid #14bae3;
}
ssi-stream-post .insights-post-container.active {
  background-color: #f3fcfe;
  border: 1px solid #14bae3;
}
ssi-stream-post .post-details {
  display: flex;
  align-items: center;
}
ssi-stream-post .post-head {
  height: 60px;
  padding: 0 20px;
  margin: 0;
  border-bottom: 1px solid #c3c9d8;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
ssi-stream-post .post-details.left > * {
  margin-right: 5px;
}
ssi-stream-post .post-details.left ssi-checkbox-2 {
  margin-right: 10px;
}
ssi-stream-post .post-details.left ssi-social-network-icon {
  margin-right: -5px;
}
ssi-stream-post .post-details.left ssi-social-network-icon .social-network-icon-container {
  height: 26px;
}
ssi-stream-post .post-details.left .author-avatar {
  height: 26px;
  width: 26px;
  border-radius: 50%;
}
ssi-stream-post .post-details.left a.author-name {
  color: #14bae3;
  font-size: 16px;
  font-weight: 700;
}
ssi-stream-post .post-details.left a.author-name.inactive {
  color: #101525;
  text-decoration: none;
}
ssi-stream-post .post-details.left .author-username {
  color: #101525;
  font-size: 12px;
  font-weight: 900;
  padding: 1px 5px;
  border-radius: 100px;
  background-color: #f4f4fa;
}
ssi-stream-post .post-details.left .rating {
  display: flex;
  align-items: center;
  margin-left: 7px;
}
ssi-stream-post .post-details.left .rating i {
  margin-left: 3px;
  font-size: 12px;
  color: #b4bedb;
}
ssi-stream-post .post-details.left .rating i.active {
  color: #14bae3;
}
ssi-stream-post .post-details.time-visibility > * {
  margin-left: 10px;
}
ssi-stream-post .post-details.time-visibility .timestamp {
  color: #101525;
  font-size: 12px;
  font-weight: 900;
}
ssi-stream-post .post-details.time-visibility .visibility {
  border-radius: 4px;
  border: 1px solid #c3c9d8;
  display: flex;
  align-items: center;
  padding: 2px 5px;
}
ssi-stream-post .post-details.time-visibility .visibility i {
  color: #838eab;
  font-size: 10px;
  margin-right: 7px;
}
ssi-stream-post .post-details.time-visibility .visibility span {
  color: #101525;
  font-size: 10px;
  font-weight: 900;
  text-transform: uppercase;
}
ssi-stream-post .post-details.time-visibility i.close-modal-icon {
  padding: 14px;
  background-color: #f4f4fa;
  border-radius: 50%;
  color: #838eab;
  font-size: 8px;
  margin-left: 15px;
}
ssi-stream-post .post-details.time-visibility i.close-modal-icon:hover {
  cursor: pointer;
}
ssi-stream-post .post-details.time-visibility.thin {
  display: none;
}
ssi-stream-post .post-body {
  min-height: 80px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  max-height: 400px;
  overflow: auto;
}
ssi-stream-post .post-body .left {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
ssi-stream-post .post-body .left .content {
  margin: 0 15px 15px 0;
  text-align: left;
}
ssi-stream-post .post-body .left .content span,
ssi-stream-post .post-body .left .content p {
  color: #101525;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
}
ssi-stream-post .post-body .left .content span.read-more-link {
  cursor: pointer;
  color: #14bae3;
  font-weight: 900;
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1px;
}
ssi-stream-post .post-body .left .content span.read-more-link:hover {
  text-decoration: underline;
}
ssi-stream-post .post-body .left ssi-message-link-preview {
  margin: 0 15px 15px 0;
}
ssi-stream-post .post-body .left ssi-message-link-preview .message-link-preview {
  border-radius: 10px;
  background-color: #f8f9fd;
  border: 1px solid #e7edfd;
}
ssi-stream-post .post-body .left .sentiment-and-emotions {
  display: flex;
  margin-top: auto;
  margin-right: 15px;
}
ssi-stream-post .post-body .left .sentiment-and-emotions.padding-bottom {
  padding-bottom: 10px;
}
ssi-stream-post .post-body .left .sentiment-and-emotions .chip-item {
  display: flex;
  border-radius: 4px;
  margin-right: 5px;
}
ssi-stream-post .post-body .left .sentiment-and-emotions .chip-item i {
  font-size: 14px;
  padding: 3px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
ssi-stream-post .post-body .left .sentiment-and-emotions .chip-item span {
  display: flex;
  align-items: center;
  padding: 0 10px;
  color: #101525;
  font-size: 8px;
  font-weight: 900;
  text-transform: uppercase;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
ssi-stream-post .post-body .right {
  flex: 0 0 auto;
}
ssi-stream-post .post-body .right .media-gallery {
  position: relative;
  margin-right: 15px;
}
ssi-stream-post .post-body .right .media-gallery img,
ssi-stream-post .post-body .right .media-gallery .video-icon-container {
  height: 80px;
  width: 120px;
  border-radius: 10px;
  box-shadow: 0 3px 5px 0 rgba(131, 142, 171, 0.35);
}
ssi-stream-post .post-body .right .media-gallery img.stacked,
ssi-stream-post .post-body .right .media-gallery .video-icon-container.stacked {
  position: absolute;
}
ssi-stream-post .post-body .right .media-gallery .video-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #14bae3;
  background-color: #f8f9fd;
}
ssi-stream-post .post-body .right .media-gallery .video-icon-container i {
  font-size: 60px;
}
ssi-stream-post .post-body .right .media-gallery .play-icon {
  position: absolute;
  font-size: 30px;
  color: #fff;
}
ssi-stream-post .post-body .right .media-gallery .media-items-count {
  position: absolute;
  right: -20px;
  top: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  color: #14bae3;
  font-size: 10px;
  font-style: italic;
  font-weight: 900;
  line-height: 12px;
  box-shadow: 0 5px 15px 0 rgba(116, 118, 132, 0.25);
}
ssi-stream-post .post-foot {
  height: 60px;
  padding: 0 10px 0 5px;
  border-top: 1px solid #c3c9d8;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
ssi-stream-post .post-foot .left .stats {
  display: flex;
}
ssi-stream-post .post-foot .left .stats .stat {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  min-width: 0;
}
ssi-stream-post .post-foot .left .stats .stat .stat-value {
  color: #101525;
  font-size: 12px;
  font-weight: 900;
}
ssi-stream-post .post-foot .left .stats .stat .stat-label {
  color: #101525;
  font-size: 12px;
  font-weight: 900;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
ssi-stream-post .post-foot .right > * {
  margin-left: 10px;
}
ssi-stream-post .post-foot .right .tags {
  border-radius: 100px;
  background-color: #f4f4fa;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
ssi-stream-post .post-foot .right .tags span {
  color: #101525;
  font-size: 12px;
  font-weight: 900;
  margin: 0 7px;
}
ssi-stream-post .post-foot .right .tags i {
  padding-top: 2px;
}
ssi-stream-post .post-foot .right .tags i.edit-icon {
  color: #43537f;
  font-size: 10px;
}
ssi-stream-post .post-foot .right .actions-menu-container {
  position: relative;
}
ssi-stream-post .post-foot .right .actions-menu-container .menu-body {
  width: 220px;
}
ssi-stream-post .post-foot .right .actions-menu-container ssi-menu .menu-trigger-icon {
  font-size: 14px;
}
ssi-stream-post .post-foot .right .actions-menu-container ssi-menu .sentiment i {
  width: 14px;
}
ssi-stream-post .menu-list {
  display: block;
  margin: 0;
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0 25px 56px rgba(131, 142, 171, 0.35);
  border-width: 0;
  min-width: 224px;
  list-style: none;
}
ssi-stream-post .menu-list .menu-list-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  font-size: 14px;
  line-height: 14px;
  text-align: left;
  margin: 5px;
  max-width: 100%;
  color: #43537f;
  font-weight: 900;
  border-radius: 12px;
  min-height: 30px;
  border: none;
  display: flex;
  height: 45px;
  padding: 0 15px 0 15px;
  margin: 3px 0;
  list-style: none;
  cursor: pointer;
}
ssi-stream-post .menu-list .menu-list-item i {
  font-size: 16px;
  color: #838eab;
}
ssi-stream-post .menu-list .menu-list-item i:first-child {
  margin-right: 10px;
}
ssi-stream-post .menu-list .menu-list-item i:last-child {
  font-size: 14px;
  margin-left: 7px;
}
ssi-stream-post .menu-list .menu-list-item i.ssi-retweet-new, ssi-stream-post .menu-list .menu-list-item i.ssi-push-to-inbox-correct {
  font-size: 18px;
}
ssi-stream-post .menu-list .menu-list-item i.ssi-arrow-down-new {
  font-size: 10px;
}
ssi-stream-post .menu-list .menu-list-item:hover {
  background-color: #f8f9fd;
}
ssi-stream-post .menu-list .menu-list-item:hover i.ssi-twitter-preview-like:hover {
  color: #d10000;
}
ssi-stream-post .menu-list .menu-list-item span {
  white-space: nowrap;
}
ssi-stream-post .menu-list.desktop-menu {
  margin: 0;
  padding: 0;
  display: flex;
  box-shadow: none;
  overflow: hidden;
}
ssi-stream-post .menu-list.desktop-menu i {
  font-size: 14px;
  color: #43537f;
}
ssi-stream-post .menu-list.desktop-menu i:first-child {
  margin-right: 7px;
}
ssi-stream-post .menu-list.desktop-menu i.ssi-arrow-down-new {
  margin: 0 3px 0 5px;
}
ssi-stream-post .menu-list.desktop-menu i:last-child {
  margin-left: 7px;
}
ssi-stream-post .repost-menu {
  position: absolute;
  bottom: 10px;
  left: 170px;
  background-color: #fff;
}
ssi-stream-post .repost-menu hr {
  margin: 10px 0;
}
ssi-stream-post .repost-menu .menu-list {
  max-height: 300px;
}
ssi-stream-post .repost-menu .menu-list-item {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  margin: 0;
  padding: 10px 0 0 0;
  flex-wrap: wrap;
}
ssi-stream-post .repost-menu .menu-list-item:hover {
  background: none;
}
ssi-stream-post .repost-menu .menu-list-item .toggle-expand-label {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
ssi-stream-post .repost-menu .menu-list-item .toggle-expand-label span {
  margin-right: auto;
}
ssi-stream-post .repost-menu .menu-list-item .toggle-expand-icon {
  color: #14bae3;
}
ssi-stream-post .repost-menu .menu-list-item {
  justify-content: space-between;
  height: auto;
}
ssi-stream-post .repost-menu .menu-list-item .inner-list {
  margin: 10px 0 0 15px;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 0 0 0 0;
}
ssi-stream-post .repost-menu .menu-list-item .inner-list li {
  font-size: 14px;
  font-weight: 900;
  line-height: 40px;
  text-align: left;
  padding: 0 10px;
}
ssi-stream-post .repost-menu .menu-list-item .inner-list li:hover {
  background-color: #f8f9fd;
  border-radius: 12px;
}
ssi-stream-post .post-foot i {
  font-weight: 700;
}
ssi-stream-post .post-foot i.ssi-retweet-new {
  font-size: 16px;
}
ssi-stream-post .post-foot i.ssi-push-to-inbox-correct {
  font-size: 16px;
}
ssi-stream-post .post-foot i.liked {
  color: #d10000;
}