@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

$unit: 2.5px;

$ssi-outbox-typeahead-background-color: #b8ccd9;
$ssi-outbox-typeahead-choice-background-color: #eee;
$ssi-outbox-typeahead-choice-foreground-color: $primary-blue;
$ssi-outbox-typeahead-highlight-background-color: $primary-blue;
$ssi-outbox-typeahead-highlight-foreground-color: #fff;
$ssi-outbox-typeahead-suggestion-foreground-color: #999;
$ssi-outbox-typeahead-suggestion-icon-color: #666;

ssi-outbox-typeahead {
  display: flex;

  .input-hidden {
    display: none;
  }

  & > * {
    display: flex;
  }

  .disabled,
  [disabled] {
    cursor: not-allowed !important;
    opacity: 0.65 !important;
  }

  & > .outbox-typeahead-container {
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    flex-shrink: 1;
    position: relative;

    .choices-container {
      background-color: #fff;
      overflow: hidden;

      input {
        border: none;
        flex-grow: 1;
        flex-shrink: 0;
        margin: 0;
        padding: ($unit * 4);
        width: 100%;
      }
    }

    .suggestions-filter {
      border: none;
      outline: none;

      &::placeholder {
        color: $ssi-outbox-typeahead-background-color;
      }
    }

    ul {
      background-color: #fff;
      flex-flow: column nowrap;
      flex-grow: 0;
      flex-shrink: 0;
      height: 50vh;
      left: ($unit * 2);
      margin: 0;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 0;
      position: absolute;
      top: calc(100% + #{$unit});
      width: 20vw;

      &.choices {
        display: flex;
        flex-flow: row wrap;
        flex-grow: 0;
        flex-shrink: 0;
        height: auto;
        left: auto;
        position: relative;
        top: auto;

        & > .selected-option {
          background-color: $ssi-outbox-typeahead-choice-background-color;
          border-radius: ($unit * 6);
          color: $ssi-outbox-typeahead-choice-foreground-color;
          display: inline;
          flex-grow: 0;
          flex-shrink: 1;
          font-size: 0.9em;
          font-style: italic;
          margin: ($unit * 2);
          overflow: hidden;
          padding: ($unit * 2) ($unit * 10) ($unit * 2) ($unit * 4);
          position: relative;
          text-overflow: ellipsis;
          white-space: nowrap;

          &:after {
            color: $ssi-outbox-typeahead-suggestion-icon-color;
            content: 'X';
            font-style: normal;
            position: absolute;
            right: ($unit * 4);
          }

          &:hover {
            box-shadow: 0 calc($unit * 1) calc($unit * 2) calc($unit / 2)
              #d4d1d1;
            &.disabled {
              box-shadow: none;
            }
          }

          &.disabled {
            &:after {
              display: none !important;
            }
          }
        }
      }

      &.suggestions {
        width: 100%;
        z-index: 999;

        & > li {
          color: $ssi-outbox-typeahead-suggestion-foreground-color;
          margin: $unit ($unit * 2) $unit 0;
          padding: ($unit * 2) ($unit * 4);

          &.active {
            background-color: $ssi-outbox-typeahead-highlight-background-color;
            color: $ssi-outbox-typeahead-highlight-foreground-color;
          }

          &.selected,
          &:hover {
            background-color: $ssi-outbox-typeahead-highlight-background-color;
            color: $ssi-outbox-typeahead-highlight-foreground-color;
          }
        }
      }

      & > li {
        align-items: center;
        color: $ssi-outbox-typeahead-suggestion-foreground-color;
        cursor: pointer;
        flex-shrink: 0;
        font-weight: 400;
        justify-content: space-between;
        padding: ($unit * 2) ($unit * 4);
      }
    }
  }
}
