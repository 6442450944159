ssi-compose-area {
  display: block;
  margin-bottom: 10px;
  height: auto;
}
ssi-compose-area .composer-textarea-container {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #fff;
  z-index: 2;
  line-height: 19px;
  border: 1px solid #c3c9d8;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
ssi-compose-area .composer-textarea-container .composer-text-form-control {
  width: 100%;
  height: 100%;
  min-height: 250px;
  flex: 1;
  background-color: transparent;
  z-index: 2;
  position: relative;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  resize: none;
  border: 0;
  padding: 38px 36px;
  outline: none;
  line-height: 19px;
  color: #101525;
  font-size: 14px;
  font-weight: 700;
}
ssi-compose-area .composer-textarea-container .composer-text-form-control::placeholder {
  color: #6a759a;
}
ssi-compose-area .composer-textarea-container .no-accounts-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  padding: 80px;
  text-align: center;
  color: #43537f;
}
ssi-compose-area .composer-textarea-container .no-accounts-container i {
  font-size: 37px;
  color: #b4bbce;
  margin-bottom: 15px;
}
ssi-compose-area .composer-textarea-container .no-accounts-container h4 {
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
  text-align: center;
}
ssi-compose-area .composer-textarea-container .no-accounts-container p {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  padding: 0 20px;
  max-width: 250px;
}
ssi-compose-area .composer-textarea-container.disabled {
  background-color: #f0f3f9;
  border: none;
}
ssi-compose-area .composer-textarea-container .text-highlight-element {
  pointer-events: none;
  background: transparent;
  z-index: 2;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100% !important;
}
ssi-compose-area .composer-textarea-container .text-highlight-tag {
  text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
  color: #14bae3;
  text-decoration: underline;
}
ssi-compose-area .composer-textarea-container.active {
  border-color: #14bae3;
}
ssi-compose-area .composer-textarea-footer {
  z-index: 5;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 5px;
}
ssi-compose-area .composer-textarea-footer .composer-textarea-footer-right-icon {
  display: flex;
  align-items: center;
}
ssi-compose-area .composer-textarea-footer img {
  width: auto;
  height: 16px;
}
ssi-compose-area .composer-textarea-footer i {
  font-size: 16px;
  color: #6a759a;
}
ssi-compose-area .composer-textarea-footer .attachment-type-list li i {
  font-size: 14px;
}
ssi-compose-area .composer-textarea-footer .attachment-type-list li i.video-icon {
  font-size: 16px;
}
ssi-compose-area .composer-textarea-footer .location-menu .menu-body {
  position: absolute;
  width: 300px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(116, 118, 132, 0.25);
  overflow: hidden;
  padding: 15px;
  max-height: 320px;
  overflow-y: scroll;
}
ssi-compose-area .composer-textarea-footer .location-menu .menu-body ul,
ssi-compose-area .composer-textarea-footer .location-menu .menu-body li {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 900;
  line-height: 40px;
  text-align: left;
  color: #43537f;
}
ssi-compose-area .composer-textarea-footer .location-menu .menu-body ul i,
ssi-compose-area .composer-textarea-footer .location-menu .menu-body li i {
  font-size: 14px;
  color: #43537f;
  margin-right: 7px;
}
ssi-compose-area .composer-textarea-footer .location-menu .menu-body ul i.video-icon,
ssi-compose-area .composer-textarea-footer .location-menu .menu-body li i.video-icon {
  font-size: 16px;
}
ssi-compose-area .composer-textarea-footer .location-menu .menu-body ul {
  display: flex;
  flex-direction: column;
  align-items: center;
}
ssi-compose-area .composer-textarea-footer .location-menu .menu-body li {
  padding: 2px 15px;
  border-radius: 15px;
}
ssi-compose-area .composer-textarea-footer .location-menu .menu-body li:hover {
  cursor: pointer;
  background-color: #f8f9fd;
}
ssi-compose-area .composer-textarea-footer .location-menu .location-search-container {
  position: relative;
  width: 100%;
  border: 1px solid #c3c9d8;
  border-radius: 10px;
}
ssi-compose-area .composer-textarea-footer .location-menu .location-search-container input {
  width: 100%;
  border: 0;
  border-radius: 10px;
  outline: 0;
  padding: 8px 20px;
  color: #101525;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  font-family: "Lato", "Arial", sans-serif;
}
ssi-compose-area .composer-textarea-footer .location-menu .location-search-container input::placeholder {
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  color: #6a759a;
}
ssi-compose-area .composer-textarea-footer .location-menu .location-search-container i {
  position: absolute;
  color: #6a759a;
  top: 13px;
  right: 12px;
  font-size: 12px;
  font-weight: 700;
}
ssi-compose-area .composer-textarea-footer .location-menu .location-search-container i.active {
  color: #14bae3;
}
ssi-compose-area .composer-textarea-footer .location-menu .location-search-container hr {
  margin: 0 -50px;
  background-color: #c3c9d8;
  height: 1px;
}
ssi-compose-area .composer-textarea-footer .location-menu .location-search-container .location-selected {
  position: absolute;
}
ssi-compose-area .composer-textarea-footer .location-menu .location-default {
  padding: 40px 60px;
  text-align: center;
  min-height: 200px;
}
ssi-compose-area .composer-textarea-footer .location-menu .location-default i {
  font-size: 30px;
  margin-bottom: 10px;
}
ssi-compose-area .composer-textarea-footer .location-menu .location-default h3,
ssi-compose-area .composer-textarea-footer .location-menu .location-default p {
  color: #43537f;
  font-weight: 700;
}
ssi-compose-area .composer-textarea-footer .location-menu .location-tags {
  min-height: 200px;
}
ssi-compose-area .composer-textarea-footer .location-menu .location-tags p {
  padding: 60px;
  text-align: center;
}
ssi-compose-area .composer-textarea-footer .location-menu .location-tags span {
  display: inline-block;
  max-width: 215px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
ssi-compose-area .composer-textarea-footer .location-menu .location-tags .select-circle {
  margin: 10px 0 0 10px;
  padding: 2px;
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 1px solid #838eab;
  border-radius: 100%;
  position: relative;
}
ssi-compose-area .composer-textarea-footer .location-menu .location-tags .select-circle.selected {
  border-color: #14bae3;
}
ssi-compose-area .composer-textarea-footer .location-menu .location-tags .select-circle.selected::after {
  position: absolute;
  content: "";
  margin: 1px;
  width: 10px;
  height: 10px;
  background-color: #14bae3;
  border-radius: 100%;
}
ssi-compose-area .composer-textarea-footer .location-menu .location-tags ul li {
  display: flex;
  width: 100%;
  padding-top: 8px;
  cursor: pointer;
}
ssi-compose-area .composer-textarea-footer .location-menu .location-tags ul li.selected {
  color: #14bae3;
}
ssi-compose-area .composer-textarea-footer .location-menu ssi-spinner {
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 60%;
}
ssi-compose-area .composer-video-title-container {
  background-color: #fff;
  font-family: "Lato", sans-serif;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #c3c9d8;
  border-left: 1px solid #c3c9d8;
  border-right: 1px solid #c3c9d8;
}
ssi-compose-area .composer-video-title-container span {
  display: flex;
  align-items: center;
  border: 0;
  padding: 0.4em 0.3em 0.4em 1.5em;
  color: #6a759a;
  background-color: #ffffff;
  z-index: 2;
}
ssi-compose-area .composer-video-title-container span i {
  font-size: 22px;
}
ssi-compose-area .composer-video-title-container input {
  border: 0;
  font-family: Lato, sans-serif;
  font-weight: 700;
  color: #101525;
  z-index: 1;
}
ssi-compose-area .composer-video-title-container input:focus {
  outline: none;
}
ssi-compose-area .composer-video-title-container:focus-within {
  border: 1px solid #14bae3;
}
ssi-compose-area .composer-video-title-form-control {
  font-weight: normal;
}
ssi-compose-area .composer-video-title-form-control::placeholder {
  color: #6a759a;
}
ssi-compose-area ssi-emoji-form-control-trigger {
  display: flex;
  align-items: center;
}
ssi-compose-area .post-controls {
  display: flex;
  z-index: 2;
  align-items: center;
  padding: 5px;
  height: 46px;
  border: 1px solid #c3c9d8;
  background-color: #fff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom: none;
}
ssi-compose-area .post-controls .network-post-controls-select {
  margin-right: 5px;
}
ssi-compose-area .post-controls .toggle-label {
  height: 36px;
  padding: 0 8px 0 12px;
  margin-left: auto;
}
ssi-compose-area .post-controls .toggle-label p {
  font-size: 12px;
}
ssi-compose-area .post-controls ssi-checkbox .checkbox-container label {
  padding: 8px 20px;
}
ssi-compose-area .post-controls ssi-checkbox .checkbox-container label .leading-icon {
  font-size: 12px;
}
ssi-compose-area .post-controls ssi-checkbox .checkbox-container label .check-icon {
  top: 12px;
}
ssi-compose-area .post-controls ssi-checkbox .checkbox-container:not(.active) {
  background-color: #fff;
}
ssi-compose-area .post-controls.active {
  border-color: #14bae3;
}
ssi-compose-area .post-control-item {
  padding: 6px;
}
ssi-compose-area .post-control-item .menu-head .menu-trigger-icon {
  font-size: 16px;
  padding: 0;
}
ssi-compose-area .post-control-item .location-selected {
  padding: 6px 10px;
  background-color: #f4f4fa;
  border-radius: 8px;
}
ssi-compose-area .post-control-item .location-selected span {
  font-weight: 900;
  color: #101525;
  padding: 0 10px 0 9px;
}
ssi-compose-area .post-control-item .location-selected i {
  color: #43537f;
}
ssi-compose-area .post-control-item .location-selected .ssi-delete-close {
  position: relative;
  top: -2px;
  font-size: 8px;
  cursor: pointer;
}
ssi-compose-area .post-controls-reply {
  margin-right: 4px;
}
ssi-compose-area .post-controls-reply ssi-dropdown-select-2 .dd-head {
  height: 36px;
  line-height: 36px;
  padding: 0 14px;
}
ssi-compose-area .post-controls-reply ssi-dropdown-select-2 .dd-head .button-icon {
  color: #43537f;
  right: 14px;
}
ssi-compose-area .post-controls-reply ssi-dropdown-select-2 .dd-body {
  max-width: 300px;
}
ssi-compose-area .post-controls-reply ssi-dropdown-select-2 .dd-body .body-header-container {
  padding: 0;
  text-rendering: geometricPrecision;
}
ssi-compose-area .post-controls-reply ssi-dropdown-select-2 .dd-body ul {
  margin-top: 20px;
}
ssi-compose-area .post-controls-reply ssi-dropdown-select-2 .dd-body ul li {
  text-rendering: geometricPrecision;
}
ssi-compose-area .post-controls-reply .custom-head-button-label {
  color: #43537f;
}
ssi-compose-area .post-controls-reply .custom-head-button-label i {
  margin-right: 5px;
  font-size: 14px;
}
ssi-compose-area .post-controls-reply .custom-head-button-label span {
  font-size: 12px;
  font-weight: 900;
}
ssi-compose-area .post-controls-reply .menu-header {
  padding: 20px 20px 20px 0;
  width: 280px;
  color: #43537f;
  font-size: 14px;
}
ssi-compose-area .post-controls-reply .menu-header strong {
  font-weight: 900;
}
ssi-compose-area .post-controls-reply .menu-header strong i {
  margin-right: 5px;
}
ssi-compose-area .post-controls-reply .menu-header div {
  font-weight: 700;
  margin-top: 3px;
}
ssi-compose-area .account-controls {
  display: flex;
  align-items: center;
  padding: 5px;
  border-top: 1px solid #c3c9d8;
}
ssi-compose-area .account-controls .acc-control-button {
  display: flex;
  align-items: center;
  outline: none;
  appearance: none;
  border: 1px solid #c3c9d8;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  margin-right: 5px;
}
ssi-compose-area .account-controls .acc-control-button i {
  color: #14bae3;
}
ssi-compose-area .account-controls .acc-control-button span {
  margin-left: 5px;
  color: #43537f;
  font-size: 12px;
  font-weight: 900;
  line-height: 12px;
  text-align: left;
}
ssi-compose-area .account-controls .acc-control-button.network-select {
  padding: 9px;
}
ssi-compose-area .account-controls .acc-control-button.active {
  background-color: rgba(20, 186, 227, 0.1);
  border-color: #14bae3;
}
ssi-compose-area .account-controls .acc-control-button.post-errored {
  background-color: #ff4d11;
  border-color: #ff4d11;
}
ssi-compose-area .account-controls .acc-control-button.post-errored i {
  color: #fff;
}
ssi-compose-area .account-controls span .acc-control-button {
  padding: 9px;
}
ssi-compose-area .account-controls.active {
  border-color: #14bae3;
}
ssi-compose-area .account-controls .network-accounts-list {
  max-height: 280px;
  overflow-y: auto;
}
ssi-compose-area .composer-textarea-character-count {
  color: #14bae3;
  font-size: 12px;
  font-weight: 900;
  line-height: 14.4px;
}
ssi-compose-area .composer-mention-click-context-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 160px;
  padding: 8px;
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}
ssi-compose-area .composer-mention-click-context-menu button {
  border: 0;
  outline: 0;
  padding: 8px 10px;
  border-radius: 20px;
  background: #ffffff;
  font-weight: 900;
  color: #43537f;
  cursor: pointer;
}
ssi-compose-area .composer-mention-click-context-menu button:hover {
  color: #14bae3;
  background-color: #f8f9fd;
}
ssi-compose-area .composer-mention-click-context-menu .vertical-line {
  height: 20px;
  width: 2px;
  background-color: rgba(180, 187, 206, 0.5);
  margin: 0 5px;
}
ssi-compose-area .composer-mention-click-context-menu a {
  padding: 5px 10px;
  border-radius: 50%;
  color: #43537f;
  cursor: pointer;
}
ssi-compose-area .composer-mention-click-context-menu a:hover {
  color: #14bae3;
  background-color: #f8f9fd;
}
ssi-compose-area .ai-writing-tool-context-menu {
  position: absolute;
  background-color: #fff;
  bottom: -1.4em;
  right: 0;
  display: block;
  font-size: 1em;
  margin: 0 -0.4em 0 0;
  padding: 1.8em 0;
  border-radius: 1.5em;
  box-shadow: 0 1.8em 4em rgba(131, 142, 171, 0.35);
  border-width: 0;
  min-width: 18em;
}
ssi-compose-area .ai-writing-tool-context-menu .menu-item {
  display: flex;
  align-items: center;
  height: 3.6rem;
  padding: 0 2em 0 1rem;
  border-radius: 1rem;
  margin: 0.2rem 2rem;
  cursor: pointer;
}
ssi-compose-area .ai-writing-tool-context-menu .menu-item i {
  color: #43537f;
  font-size: 1.4rem;
  margin: 0 1.5rem 0 0;
  color: #838eab;
}
ssi-compose-area .ai-writing-tool-context-menu .menu-item span {
  font-size: 1.4rem;
  font-weight: 900;
  text-rendering: geometricPrecision;
  color: #43537f;
}
ssi-compose-area .ai-writing-tool-context-menu .menu-item:hover {
  background-color: #f8f9fd;
}
ssi-compose-area .ai-writing-tool-context-menu hr {
  border-top: 1px solid #c3c9d8;
}
ssi-compose-area .ai-writing-tool-context-menu a:hover {
  text-decoration: none;
}
ssi-compose-area .ai-writing-tool-context-menu ssi-icon {
  margin-right: 1.5rem;
}