@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../library/sass/live-chat/colours.scss';
@import '../../../../../angularjs/common/sass/variables';
@import '~/styles/colors';

$assigned-conversation: $brand-success;
$assigned-conversation-hover: #719239;
$unassigned-conversation: $primary-active;
$unassigned-conversation-hover: #2788bf;

$component-height: 72px;
$unit: 2.5px;

$navbar-height: 68px;
$tree-height: calc(100vh - (#{$navbar-height} + (#{$component-height} * 2)));

$tree-item-height: ($unit * 36);

ssi-live-chat-conversation-tree {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 0;
  height: $tree-height;
  overflow-x: hidden;
  overflow-y: auto;

  * {
    display: flex;
  }

  button {
    &[disabled] {
      cursor: not-allowed !important;
      opacity: 0.65 !important;
    }
  }

  .not-loaded {
    align-items: center;
    background: $live-chat-list-item-gradient
      $live-chat-list-item-background-colour;
    flex-grow: 1;
    justify-content: center;

    .spinner {
      font-size: 2.5em;
    }
  }

  & > ul {
    flex-flow: column nowrap;
    flex-grow: 0;
    list-style: none;
    margin: 0;
    padding: 0;

    & > li {
      align-items: center;
      background: $live-chat-list-item-gradient
        $live-chat-list-item-background-colour;
      flex-basis: $tree-item-height;
      flex-grow: 0;
      margin: 0;
      min-height: ($unit * 36);
      padding: 0 ($unit * 8);
      position: relative;

      & > ssi-live-chat-network-state {
        & + {
          .disabled {
            margin-left: ($unit * 8) !important;
          }
        }
      }

      &.disabled {
        cursor: not-allowed !important;
        opacity: 0.65 !important;

        .details {
          cursor: not-allowed !important;
        }
      }

      &.assigned {
        .assignment {
          background-color: $assigned-conversation;

          &:hover {
            background-color: $assigned-conversation-hover;
          }
        }
      }

      &.unassigned {
        .assignment {
          background-color: $unassigned-conversation;

          &:hover {
            background-color: $unassigned-conversation-hover;
          }
        }
      }

      .age {
        color: darken($primary-blue, 30%);
        font-size: 0.7em;
        font-style: italic;
        font-weight: 400;
        position: absolute;
        right: ($unit * 6);
        top: ($unit * 12);
        white-space: nowrap;
        width: ($unit * 24);
      }

      .assignment {
        background-color: transparent;
        border: none;
        cursor: pointer;
        flex-shrink: 0;
        height: ($unit * 36);
        margin: 0 ($unit * 8);
        padding: 0;
        width: ($unit * 6);
      }

      .assignment-status {
        color: darken($primary-blue, 30%);
        font-size: 0.8em;
        font-style: italic;
        font-weight: 600;
        text-transform: capitalize;

        & > .assignee {
          &:before {
            content: '|';
            margin: 0 ($unit * 2);
          }
        }
      }

      .application-name {
        color: darken($primary-blue, 30%);
        font-size: 0.8em;
        font-style: italic;
        font-weight: 600;
        text-transform: capitalize;
      }

      ssi-live-chat-avatar img {
        height: ($unit * 14);
        width: ($unit * 14);
      }

      ssi-live-chat-network-state {
        & + .details {
          padding-left: ($unit * 8);
        }
      }

      .details {
        cursor: pointer;
        flex-flow: column nowrap;
        flex-grow: 1;
        overflow: hidden;
        padding: ($unit * 8) 0;

        .name {
          font-weight: 600;
        }

        .name,
        .preview {
          display: inline;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
