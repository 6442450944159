ssi-add-widget .add-dashboard-widget-content-container {
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #fff;
  border-radius: 12px;
  height: 100%;
  padding: 40px 50px;
}
ssi-add-widget .add-dashboard-widget-content-container i.close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #f2f4fc;
  color: #838eab;
  border-radius: 50px;
  padding: 13px;
}
ssi-add-widget .add-dashboard-widget-content-container i.close-icon:hover {
  cursor: pointer;
  opacity: 0.8;
}
ssi-add-widget .add-dashboard-widget-content-container .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  margin-bottom: 40px;
}
ssi-add-widget .add-dashboard-widget-content-container .head .title {
  font-size: 2em;
  font-weight: 900;
  color: #43537f;
  white-space: nowrap;
  flex-basis: 20%;
  margin: 0;
}
ssi-add-widget .add-dashboard-widget-content-container .head .heading-right {
  flex-basis: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
ssi-add-widget .add-dashboard-widget-content-container .search-field {
  display: flex;
  align-items: center;
  text-align: left;
  margin-right: auto;
}
ssi-add-widget .add-dashboard-widget-content-container .search-field i {
  color: #43537f;
  font-size: 1.2em;
  margin: 0 0.6rem 0 0;
}
ssi-add-widget .add-dashboard-widget-content-container .search-field input {
  color: #43537f;
  font-size: 1.4em;
  font-weight: 700;
  text-rendering: geometricPrecision;
  border-width: 0;
  outline: 0;
  padding: 0;
}
ssi-add-widget .add-dashboard-widget-content-container .search-field input::placeholder {
  color: #838eab;
}
ssi-add-widget .add-dashboard-widget-content-container .body {
  overflow: auto;
}
ssi-add-widget .add-dashboard-widget-content-container .items-list-container {
  overflow: hidden;
}
ssi-add-widget .add-dashboard-widget-content-container .items-list {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  height: 100%;
  align-content: flex-start;
}
ssi-add-widget .add-dashboard-widget-content-container .item-box {
  position: relative;
  width: 100%;
  margin: 0 20px 20px 0;
  border-radius: 12px;
  flex: 1 1 240px;
}
ssi-add-widget .add-dashboard-widget-content-container .item-box .thumbnail-action {
  border-radius: 12px;
}
ssi-add-widget .add-dashboard-widget-content-container .item-box i {
  font-size: 1.2em;
}
ssi-add-widget .add-dashboard-widget-content-container .item-box .item-thumb {
  border: 1px solid #d1dbf4;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 16em;
  background-color: #f8f9fd;
  border-radius: 12px;
  margin-bottom: 20px;
}
ssi-add-widget .add-dashboard-widget-content-container .item-box .item-thumb:before {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 130px;
  color: #a3b8ee;
  opacity: 0.2;
}
ssi-add-widget .add-dashboard-widget-content-container .item-box .item-thumb:hover {
  border-style: dashed;
  border-color: #14bae3;
}
ssi-add-widget .add-dashboard-widget-content-container .item-box .add-widget-button {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 60px;
}
ssi-add-widget .add-dashboard-widget-content-container .item-box .add-widget-button .ssi {
  font-size: 0.7em;
}
ssi-add-widget .add-dashboard-widget-content-container .item-box .title,
ssi-add-widget .add-dashboard-widget-content-container .item-box .description {
  text-align: left;
  width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  text-align: left;
  margin-bottom: 0.5em;
  padding-left: 10px;
}
ssi-add-widget .add-dashboard-widget-content-container .item-box .title {
  color: #101525;
  font-weight: 900;
  font-size: 1.6em;
}
ssi-add-widget .add-dashboard-widget-content-container .item-box .description {
  color: #43537f;
  font-weight: bold;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
}
@supports (display: grid) {
  ssi-add-widget .add-dashboard-widget-content-container .widget-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25em, 1fr));
    grid-gap: 2.1em;
    margin: 0;
  }
  ssi-add-widget .add-dashboard-widget-content-container .widget-list .widget-box {
    margin: 0;
  }
  @media (min-width: 1801px) {
    ssi-add-widget .add-dashboard-widget-content-container .widget-list {
      grid-template-columns: repeat(auto-fill, minmax(30em, 1fr));
    }
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  ssi-add-widget .add-dashboard-widget-content-container .widget-list {
    display: flex;
    margin: 0 -1em 2.4em;
  }
  ssi-add-widget .add-dashboard-widget-content-container .widget-list .widget-box {
    margin: 0 1em;
  }
}