@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import "../../../../../../library/sass/live-chat/colours.scss";
@import "../../../../../angularjs/common/sass/variables.scss";

$offset: 200px;
$unit: 2.5px;

ssi-live-chat-offline-agent-notice {
  align-items: center;
  flex-flow: column nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  height: calc(100vh - #{$offset});
  justify-content: center;
  overflow: hidden;

  .encouragement {
    align-items: center;
    flex-flow: column nowrap;
    // flex-grow: 1;
    flex-shrink: 0;
    margin: 0 12% 2% 12%;
    text-align: center;

    .encouragement-icon {
      color: $live-chat-passive-text-colour;
      font-size: 15vh;
    }

    .encouragement-message {
      color: $live-chat-passive-text-colour;
      font-size: 1.4em;
      font-weight: 600;

      & > * {
        padding: 0 $unit;
      }
    }
  }

  .available-unassigned-chats,
  .unavailable-unassigned-chats {
    align-items: center;
    flex-flow: column nowrap;
    height: 100%;
    justify-content: center;
  }

  .unavailable-unassigned-chats {
    button,
    button:hover {
      background-color: #F3C874;
      color: $text-light;
    }
  }
}