ssi-advertising ui-view {
  display: flex;
  flex: 1 1 auto;
  background-color: #f6f8f9;
  margin-left: -15px;
  margin-right: -15px;
}
ssi-advertising ui-view > * {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
}
@media (max-width: 885px) {
  ssi-advertising ui-view > * {
    max-width: 100vw;
  }
}

.btn-style-2 {
  padding: 0;
  width: 170px;
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  border-radius: 20px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  border-width: 0;
}
.btn-style-2 .ssi {
  margin-right: 10px;
}

.btn-style-2.btn-primary {
  background-color: #14bae3;
}