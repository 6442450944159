@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../angularjs/common/sass/variables';
@import '../../../../angularjs/common/sass/mixins';

.export-conversation {
  background-color: #3a3f49;
  color: white;
  padding: 30px 60px !important;
  border-radius: 25px;

  &.confirmation {
    text-align: center;
  }

  p {
    margin: 0 0 1em 0;
  }

  input[type='password'],
  input[type='text'] {
    display: block;
    width: 300px;
    background: none;
    border: none;
    border-bottom: 2px solid #737481;
    padding: 5px;
  }
  label {
    margin: 0 0 0 10px;
    color: #27bae1;
  }
  h4 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .export-message {
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
    display: block;
    float: right;
    &.invalid {
      color: #737481;
    }
    &.valid {
      color: #27bae1;
    }
  }
  .export-buttons {
    padding: 10px 0;
    text-align: center;
    button {
      margin: 0 6px;
      border-radius: 16px;
      border: none;
    }
    .export-cancel {
      background-color: #4c525e;
      color: white;
    }
  }
  .export-disclaimer {
    color: #737481;
    font-size: 12px;
    padding: 0 0 0 10px;
    border-left: 2px solid #27bae1;
    line-height: 1.5em;
    font-weight: 500;
  }
  .export-code-input {
    position: relative;
    display: inline-block;
    margin: 0 0 10px 0;
  }
  .export-input {
    margin: 0 0 10px 0;
  }
  .export-code-confirmation {
    border: 2px solid;
    display: inline-block;
    margin: 0 0 10px 0;
    padding: 5px;
    border-radius: 18px;
    border-color: #27bae1;
    font-size: 20px;
    padding: 2px 0;
    min-width: 104px;
  }
  .export-fireworks {
    font-size: 46px;
    margin-bottom: 16px;
  }
  .show-hide {
    position: absolute;
    right: 15px;
    top: 3px;
    font-size: 24px;
    line-height: 22px;
    vertical-align: middle;
    color: #27bae1;
    cursor: pointer;
    &.ssi-eyeclosed {
      font-size: 12px;
      line-height: 30px;
    }
  }
}
