@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-enable-social-chatbots {
  h1 {
    font-weight: 900;
    color: $lmode-helper-text;
    font-size: 1.25em;
  }
  p {
    color: $lmode-helper-text;
  }
  .enable-social-chatbots {
    background-color: $grey-background;
    padding: 2em 4em;
  }
  .container {
    position: relative;
    // background-color: #FFF;
    border-radius: 25px;
    padding: 2em 0;
  }
  .segment {
    background: #fff;
    border: 2.5em solid #fff;
    border-radius: 25px;
    margin-bottom: 2em;
  }
  .ssi-loading {
    margin-top: 10px;
    float: right;
  }
  .account-list {
    padding: 0 0 2em 0;
    list-style: none;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 6px;
      border-bottom: 2px solid $lmgrey-background;
      button {
        float: right;
      }
    }
    .account-name {
      padding: 2em 0;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 900;
      line-height: 2.5em;
      color: $lmode-helper-text;
      letter-spacing: 1px;
      display: inline-block;
      i {
        margin-right: 1em;
        color: $primary-blue;
      }
    }
  }

  .orlo-select {
    display: inline-block;
    select {
      padding: 0.6em 2.6em 0.6em 1.6em;
    }
    i {
      top: 15px;
    }
  }

  .return-nav {
    position: absolute;
    top: -16px;
    width: 150px;
    left: 35px;
  }
}
