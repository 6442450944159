@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

.report-header {
  position: fixed;
  display: flex;
  width: 100%;
  height: 94px;
  background-color: $lgrey-background;
  padding: 2em 45px 2em 228px;
  color: $lmode-title-text;
  box-shadow: 0px 0px 10px 5px rgba(131, 142, 171, 0.2);
  z-index: 98;
  h1 {
    margin: 0 0 0 40px;
    font-size: 1em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 900;
    line-height: 2.6em;
  }
  h2 {
    margin: 0;
    font-size: 1.4em;
    font-weight: 900;
    line-height: 1.8em;
  }
  .report-name {
    margin-left: auto;
    display: flex;
  }

  .keyword-search-input {
    display: inline-block;
    align-self: flex-start;
    background-color: white;
    border-radius: 10px;
    padding: 0.6em 0.8em;
    margin-left: 0.6em;
    border: 1px solid #c3c9d8;
    cursor: pointer;
    white-space: nowrap;

    input {
      color: $lmode-title-text;
      height: 38px;
      margin: -11px 9px -11px 0;
      min-width: 170px;
      font-weight: 700;
      border-width: 0;
      outline-width: 0;
      background-color: transparent;

      &::placeholder {
        color: #6a759a;
      }
    }

    i {
      font-weight: 700;
      color: $lmode-body-text;
    }

    &:hover {
      border-color: $primary-active;

      i {
        border-color: $primary-active;
      }
    }
  }

  .report-date-range {
    display: inline-block;
    border-radius: 10px;
    background-color: #ebebf4;
    align-self: flex-start;
    font-weight: 700;
    padding: 0.7em 0.4em;
    margin-right: 1em;
  }
  .report-date-selector {
    .control-selector {
      background-color: white;
      border-radius: 10px;
      padding: 0.6em 0.8em;
      margin-left: 0.6em;
      color: $lmode-body-text;
      border: 1px solid #c3c9d8;
      cursor: pointer;
      &:hover {
        border-color: $primary-active;
      }
    }
    ssi-highchart-controls {
      margin: 0;
      .highchart-controls-actions,
      .highchart-controls-date-wrap {
        margin: 0;
        line-height: inherit;
        .highchart-controls-action,
        .highchart-controls-date-picker {
          padding: 1em 0.6em;
          margin-left: 0.6em;
          background-color: white;
          border: none;
          color: $lmode-body-text;
          border-radius: 10px;
          font-weight: 700;
          border: 1px solid white;
          &:hover {
            border-color: $primary-active;
          }
          &.highchart-controls-action-active {
            border-color: $primary-active;
            box-shadow: 0px 5px 10px 0px rgba(131, 142, 171, 0.35);
          }
        }
        .highchart-controls-date-toggle {
          i {
            background-color: white;
            border-radius: 10px;
            padding: 0.72em 1em;
            color: $lmode-body-text;
            line-height: inherit;
          }
        }
        .highchart-controls-date-picker {
          padding: 0;
          max-width: auto;
          min-width: auto;
          input {
            max-width: 120px;
            padding: 0.72em 0.6em;
            font-style: normal;
            color: $lmode-body-text;
            font-weight: 700;
            border-radius: 10px;
            ::placeholder {
              color: $lmode-body-text;
            }
            &:focus {
              border-radius: 10px;
            }
          }
          i {
            font-size: 0.8em;
            margin: 1.25em 0;
            font-weight: 900;
            right: 7px;
          }
        }
      }
    }
  }
}
