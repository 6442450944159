@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../angularjs/common/sass/variables';
@import '../../../../angularjs/common/sass/mixins';
@import '~/styles/variables';

.orlo-crm-person {
  width: 600px;
  padding: 0 1em 1em 1em;
  text-align: center;
  .header {
    width: 400px;
    margin: 0 auto;
    img {
      width: 12%;
      height: 12%;
      margin: 1.6em 0;
    }
    button {
      margin-top: 1.6em;
    }
    h2 {
      color: #000;
      font-size: 1.4em;
      font-weight: 600;
      margin: 0 0 1.2em 0;
    }
  }
  .info {
    margin: 1em 0 2em 0;
    color: #8c8f9d;
    font-weight: 500;
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .crm-person {
    margin: 0 2em 2em 2em;
    text-align: center;
    ul {
      padding: 0;
      list-style: none;
      li {
        float: left;
        background-color: #e7ebf6;
        border-radius: 25px;
        padding: 4px 8px;
        color: #5471d7;
        margin: 0 0.4em;
        font-weight: 700;
        i {
          margin-right: 0.4em;
        }
      }
    }
  }
  .btn {
    background-color: #5471d7;
    margin: 1em 5px;
    -webkit-appearance: none;
    border: none;
    border-radius: 20px;
    padding: 1em 2em;
    font-weight: 700;
    color: #fff;
    cursor: pointer;
    &.btn-cancel {
      background: none;
      text-transform: uppercase;
      color: #8c8f9d;
      text-decoration: underline;
      font-size: 0.8em;
    }
  }
}
