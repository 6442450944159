@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-marketing {
  ssi-drafts-library {
    margin: -35px -15px 0;
  }
}

ssi-drafts-library {
  display: flex;
  font-size: 1rem;

  ssi-alerts {
    display: block;
    font-size: 1.4em;
    margin: 2em 0 -2em;
  }

  .drafts-library-wrap {
    display: flex;
    flex-direction: column;
    flex: 1 0 100%;
    background-color: $lmgrey-background;
  }

  .drafts-library-header {
    display: flex;
    flex: 0 0 9.4em;
    align-items: center;
    justify-content: space-between;
    padding: 0 4em;
    height: 9.4em;
    background-color: #fff;
    box-shadow: 0 0.5em 1em rgba(131, 142, 171, 0.2);

    h1 {
      margin: 0;
      color: $lmode-title-text;
      text-transform: uppercase;
      font-weight: 900;
      font-size: 1.4em;
      letter-spacing: 0.1rem;
      text-rendering: geometricPrecision;
    }

    .create-draft-btn {
      border: none;
      outline: none;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      color: #fff;
      font-size: 12px;
      font-weight: 900;
      background-color: $primary-active;
      padding: 10px 20px;
      margin-left: 10px;

      &:hover {
        color: #fff;
      }

      &:disabled {
        background-color: $dmode-body-text;
      }

      display: flex;
      align-items: center;
      i {
        font-weight: 900;
        font-size: 10px;
        margin-right: 10px;
      }
    }
  }

  .drafts-library-container {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    margin: 0 auto;
    max-width: 136em;
    width: calc(100% - 8em);
  }

  .drafts-library-nav {
    margin: 4.2em 0 1em;
    text-align: center;
  }

  .drafts-library-nav-item {
    position: relative;
    display: inline-block;
    font-size: 1em;
    color: $lmode-body-text;
    font-weight: 900;
    text-rendering: geometricPrecision;
    letter-spacing: 0.1em;
    line-height: 1;
    text-transform: uppercase;
    margin: 0 2em;
    height: 2.2em;
    cursor: pointer;

    &::selection {
      background-color: transparent;
    }

    &:after {
      content: '';
      position: absolute;
      display: block;
      height: 0.4em;
      border-radius: 10em;
      width: 5em;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }

    &-active {
      &:after {
        background-color: #14bae3;
      }
    }
  }

  .drafts-library-body {
    border-radius: 1em;
    background-color: #fff;
    margin: 3em 0 0;
    padding: 3em;
    flex: 1 0 auto;
  }

  .drafts-library-actions {
    height: 2em;
    margin: 1.7em 0 1.6em;
  }

  .drafts-library-list-empty {
    text-align: center;
    margin: 11em auto 2em;
    max-width: 17.5em;

    h2 {
      font-size: 2em;
      margin: 0 0 0.6rem;
      color: $lmode-body-text;
      font-weight: 900;
      text-rendering: geometricPrecision;
    }

    p {
      font-size: 1.4em;
      line-height: 2rem;
      color: $lmode-body-text;
      font-weight: 700;
      text-rendering: geometricPrecision;
      margin: 0 0 2rem;
    }

    button {
      margin: 0 auto;
      font-size: 1.4em;
      display: inline-block;
      background-color: $primary-active;
      border-radius: 0.8em;
      color: #fff;
      height: 3.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 2rem;
      border-width: 0;
      font-weight: 900;
      text-rendering: geometricPrecision;
    }
  }

  ssi-paginator {
    margin: 4em 0 1em;
  }
}
