ssi-inbox-link-external-crm-modal b {
  font-weight: normal;
}
ssi-inbox-link-external-crm-modal p {
  font-size: 12px;
  max-width: 400px;
  margin: auto;
}
ssi-inbox-link-external-crm-modal .modal-body {
  min-width: 800px !important;
  padding: 0 100px !important;
  padding-bottom: 100px !important;
}
ssi-inbox-link-external-crm-modal .modal-body .row {
  padding-top: 50px;
}
ssi-inbox-link-external-crm-modal .scroll-y {
  max-height: 200px;
  text-align: left !important;
}
ssi-inbox-link-external-crm-modal input.form-control {
  background-color: transparent;
  color: #5b6267;
  font-weight: normal;
  margin-bottom: 10px;
  width: 75%;
  float: left;
}
ssi-inbox-link-external-crm-modal form a {
  float: left;
  position: relative;
  top: 9px;
  left: 7px;
}
ssi-inbox-link-external-crm-modal .loading {
  width: 400px;
  height: 400px;
}
ssi-inbox-link-external-crm-modal ssi-inbox-crm-external-integration-person {
  background-color: white;
  height: 350px;
  text-align: left;
}