ssi-dropdown-menu {
  font-size: 1rem;
}
ssi-dropdown-menu .orlo-dropdown-menu {
  max-width: 100%;
  background-color: #fff;
  box-shadow: 0 0.5em 1em rgba(131, 142, 171, 0.35);
  border-radius: 1.5em;
  padding: 2.7em 1.6em 2.1em;
}
ssi-dropdown-menu .orlo-dropdown-menu-list {
  margin: 0;
  padding: 0;
}
ssi-dropdown-menu .orlo-dropdown-menu-item {
  display: block;
  width: 100%;
  margin: 0 0 0.4em;
}
ssi-dropdown-menu .orlo-dropdown-menu-item:last-child {
  margin: 0;
}
ssi-dropdown-menu .orlo-dropdown-menu-link {
  display: flex;
  align-items: center;
  width: 100%;
  height: 3.6em;
  border-width: 0;
  background-color: transparent;
  border-radius: 1em;
  padding: 0 0 0 1.9em;
  margin: 0 -1.8em 0 0;
}
ssi-dropdown-menu .orlo-dropdown-menu-link:hover, ssi-dropdown-menu .orlo-dropdown-menu-link:focus {
  background-color: #f8f9fd;
}
ssi-dropdown-menu .orlo-dropdown-menu-icon {
  display: block;
  font-size: 1.4em;
  line-height: 1;
  color: #838eab;
  width: 1.4rem;
}
ssi-dropdown-menu .orlo-dropdown-menu-label {
  font-size: 1.4em;
  margin: 0 0 0 1rem;
  color: #43537f;
  font-weight: 900;
  text-rendering: geometricPrecision;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}