@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-youtube-post-options-select {
  .youtube-post-options-select-container {
    ssi-dropdown-select-2 {
      .dd-head {
        height: 36px;

        .button-icon {
          color: #43537f;
        }
      }

      .dd-body {
        ul {
          li {
            i {
              &.ssi-unlisted {
                font-size: 16px;
              }
            }
          }
        }
      }
    }

    .custom-head-button-label {
      color: #43537f;
      i {
        margin-right: 5px;
        font-size: 14px;
      }
      span {
        font-size: 12px;
        font-weight: 900;
      }
    }

    .menu-header {
      padding: 20px 0;
      width: 280px;
      color: #43537f;
      font-size: 14px;

      strong {
        font-weight: 900;
        i {
          margin-right: 5px;
        }
      }

      div {
        font-weight: 700;
        margin-top: 3px;
      }
    }
  }
}
