@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-notes-modal {
  .notes-modal-container {
    background-color: #fff;
    border-radius: 15px;

    .head {
      height: 68px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      //   border-bottom: 1px solid #c3c9d8;

      h3 {
        color: #43537f;
        font-size: 20px;
        font-weight: 900;
        line-height: 20px;
        margin: 0;
        padding: 0;
      }

      i.close-icon {
        padding: 14px;
        background-color: #f4f4fa;
        border-radius: 50%;
        color: #838eab;
        font-size: 7px;
        font-weight: 900;
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 1;
        &:hover {
          cursor: pointer;
          color: #14bae3;
        }
      }
    }

    .note-create {
      padding: 20px 15px;

      .add-note-wrapper {
        border-radius: 10px;
        background-color: #f8f9fd;
        padding: 10px 20px;
      }
    }

    .note-list {
      padding: 0 20px 20px 20px;
      max-height: 340px;
      overflow: auto;

      .note-list-item {
        border-bottom: 1px solid #c3c9d8;
        padding: 20px 10px 10px 10px;
      }
    }
  }
}
