ssi-query-builder-step {
  font-size: 1rem;
}
ssi-query-builder-step .stream-builder-query {
  margin: 0 0.5em;
}
ssi-query-builder-step .query-guide-container {
  background-color: #ecf5f5;
  border: 2px solid #cceae8;
  padding: 0.8em;
  margin: 0 0 0.8em;
  border-radius: 1.2em;
  color: #43537f;
}
ssi-query-builder-step .query-guide-label {
  display: inline-block;
  background-color: #cceae8;
  border-radius: 0.4em;
  color: #12aca4;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin: 0 0 0.8em;
  padding: 0.8em 1em 0.7em;
}
ssi-query-builder-step .query-guide-label p {
  font-size: 1.2em;
  line-height: 1;
  font-weight: 900;
  text-rendering: geometricPrecision;
  margin: 0;
}
ssi-query-builder-step .query-guide-sentence {
  color: #43537f;
  font-weight: 700;
  text-rendering: geometricPrecision;
  margin: 0.3em 0.6em 1em;
}
ssi-query-builder-step .query-guide-sentence i {
  font-weight: 900;
}
ssi-query-builder-step .query-actionbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 1.2em;
  padding: 0.8em 1em;
  background-color: #f4f4fa;
  margin: 0 0 3em;
}
ssi-query-builder-step .query-actionbar-container-column {
  flex: 1 0 0;
}
ssi-query-builder-step .query-actionbar-container-column-center {
  text-align: center;
}
ssi-query-builder-step .query-actionbar-container-column-right {
  text-align: right;
}
ssi-query-builder-step .query-actionbar-container-column orlo-button {
  margin: 0 0.8em 0 0;
}
ssi-query-builder-step .query-actionbar-button button {
  padding: 9px 10px 10px;
  font-size: 12px;
  border-radius: 10px;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-query-builder-step .query-actionbar-button button .ssi {
  margin: 0 2px 0 0;
}
ssi-query-builder-step .query-actionbar-button button .ssi-addsmall {
  font-size: 10px;
}
ssi-query-builder-step .query-actionbar-button button .ssi-pro-tip {
  font-size: 16px;
  vertical-align: middle;
}
ssi-query-builder-step .query-actionbar-button-border button {
  padding: 7px 10px 8px;
  border: 2px solid #cceae8;
  box-sizing: border-box;
}
ssi-query-builder-step .actionbar-toggle {
  display: inline-flex;
  height: 4em;
  width: 11em;
}
ssi-query-builder-step .actionbar-toggle-item {
  flex: 1 0 0;
  height: 100%;
}
ssi-query-builder-step .actionbar-toggle-item:first-child label {
  border-left: 0.2em solid #c3c9d8;
  border-top-left-radius: 1.2em;
  border-bottom-left-radius: 1.2em;
}
ssi-query-builder-step .actionbar-toggle-item:last-child label {
  border-right: 0.2em solid #c3c9d8;
  border-top-right-radius: 1.2em;
  border-bottom-right-radius: 1.2em;
}
ssi-query-builder-step .actionbar-toggle-item label {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  height: 100%;
  background-color: #fff;
  border-top: 0.2em solid #c3c9d8;
  border-bottom: 0.2em solid #c3c9d8;
  color: #838eab;
  font-weight: 900;
  text-rendering: geometricPrecision;
  cursor: pointer;
}
ssi-query-builder-step .actionbar-toggle-item label span {
  font-size: 1.6em;
}
ssi-query-builder-step .actionbar-toggle-item input {
  position: absolute;
  z-index: -1;
  opacity: 0 !important;
}
ssi-query-builder-step .actionbar-toggle-item input:checked + label {
  background-color: black;
  color: #fff;
}
ssi-query-builder-step .actionbar-toggle-item-blue input:checked + label {
  background-color: #425dec;
  border-color: #425dec;
}
ssi-query-builder-step .actionbar-toggle-item-yellow input:checked + label {
  background-color: #f0b427;
  border-color: #f0b427;
}
ssi-query-builder-step .query-container-no-query {
  text-align: center;
  margin: 10em auto 3em;
  width: 36em;
  max-width: 100%;
}
ssi-query-builder-step .query-container-no-query .ssi {
  color: #b4bbce;
  font-size: 5em;
}
ssi-query-builder-step .query-container-no-query h2 {
  font-size: 2em;
  color: #43537f;
  font-weight: 900;
  text-rendering: geometricPrecision;
  margin: 0.5em 0 0.3em;
}
ssi-query-builder-step .query-container-no-query p {
  color: #838eab;
  font-weight: 700;
  line-height: 1.5em;
  text-rendering: geometricPrecision;
}
ssi-query-builder-step .query-container-builder {
  width: 73em;
  max-width: 100%;
  margin: 0 auto 3em;
}
ssi-query-builder-step ssi-query-builder-condition + .query-container-builder-group {
  margin-top: 3.5em;
}
ssi-query-builder-step ssi-query-builder-condition + .query-container-builder-group:before {
  content: "";
  position: absolute;
  top: -3.9em;
  left: 4.8em;
  height: 2.2em;
  border: 0.1em solid #c3c9d8;
}
ssi-query-builder-step ssi-query-builder-condition.parent-child + .query-container-builder-row .query-container-builder-row-tree:before,
ssi-query-builder-step ssi-query-builder-condition.parent + .query-container-builder-row .query-container-builder-row-tree:before {
  content: "";
  position: absolute;
  display: inline-block;
  border: 0.1em solid #c3c9d8;
  height: calc(100% + 0.7em);
  left: 3.8em;
  top: -0.7em;
}
ssi-query-builder-step .query-container-builder-row {
  display: flex;
  align-items: flex-start;
  margin: 0;
}
ssi-query-builder-step .query-container-builder-row-tree {
  width: 11em;
  flex: 0 0 11em;
  height: 7em;
}
ssi-query-builder-step .query-container-builder-group {
  position: relative;
  border: 0.2em solid #89a4ea;
  margin: 1em -1.2em 2.5em;
  padding: 1em 1em 1.4em;
  border-radius: 1em;
  background-color: #f8f9fd;
}
ssi-query-builder-step .query-container-builder-group .query-container-builder-row {
  height: 5.4em;
}
ssi-query-builder-step .query-container-builder-group .query-container-builder-row-single .query-container-builder-row-tree-label {
  top: 1em;
}
ssi-query-builder-step .query-container-builder-group .query-container-builder-row-parent .query-container-builder-row-tree-label, ssi-query-builder-step .query-container-builder-group .query-container-builder-row-parent-child .query-container-builder-row-tree-label, ssi-query-builder-step .query-container-builder-group .query-container-builder-row-child .query-container-builder-row-tree-label {
  top: 1em;
  bottom: auto;
}
ssi-query-builder-step .query-container-builder-group .query-container-builder-row-parent-child .query-container-builder-row-tree-guides:after {
  height: 3em;
  top: -3em;
}
ssi-query-builder-step .query-container-builder-group .query-container-builder-row-child .query-container-builder-row-tree-label {
  display: inline-flex;
}
ssi-query-builder-step .query-container-builder-group .query-container-builder-row-child .query-container-builder-row-tree-guides:before {
  top: -3em;
}
ssi-query-builder-step .query-container-builder-group .query-container-builder-row-tree-label {
  background-color: #f8f9fd;
}
ssi-query-builder-step .query-container-builder-group .query-container-builder-row-tree {
  position: relative;
  width: 11em;
  flex: 0 0 11em;
}
ssi-query-builder-step .query-container-builder-group-join {
  position: relative;
  color: #838eab;
  font-weight: 900;
  text-rendering: geometricPrecision;
  text-transform: uppercase;
  z-index: 1;
  letter-spacing: 0.1em;
  margin: -2.5em 0 0;
  height: 5em;
}
ssi-query-builder-step .query-container-builder-group-join .query-container-builder-group-join-label {
  position: absolute;
  width: 4.2em;
  text-align: center;
  top: 1.3em;
  left: 1.2em;
  font-size: 1.2em;
  pointer-events: none;
}
ssi-query-builder-step .query-container-builder-group-join .query-container-builder-group-join-guides:before, ssi-query-builder-step .query-container-builder-group-join .query-container-builder-group-join-guides:after {
  content: "";
  position: absolute;
  left: 3.8em;
  display: inline-block;
  border: 0.1em solid #c3c9d8;
  height: 1.2em;
}
ssi-query-builder-step .query-container-builder-group-join .query-container-builder-group-join-guides:before {
  top: 0;
}
ssi-query-builder-step .query-container-builder-group-join .query-container-builder-group-join-guides:after {
  bottom: 0;
}
ssi-query-builder-step .query-container-builder-row-condition-select-wrap {
  display: flex;
  justify-content: flex-end;
  margin: 0 0 1em;
}
ssi-query-builder-step .query-container-builder-row-condition-select {
  display: inline-block;
  flex: 0 1 0;
  position: relative;
}
ssi-query-builder-step .query-container-builder-row-condition-select .ssi {
  position: absolute;
  pointer-events: none;
  color: #838eab;
  height: 1em;
  top: 0;
  bottom: 0;
  font-size: 1.2em;
  right: 2rem;
  margin: auto;
}
ssi-query-builder-step .query-container-builder-row-condition-select select {
  background-color: #f4f4fa;
  border: 0.2rem solid #c3c9d8;
  color: #43537f;
  font-weight: 900;
  text-rendering: geometricPrecision;
  font-size: 1.4em;
  padding: 1.1rem 4rem 1.2rem 2rem;
  border-radius: 1rem;
  appearance: none;
}
ssi-query-builder-step .query-container-builder-row-condition-select:not(:first-child) select {
  width: 160px;
}
ssi-query-builder-step .query-container-builder-group-add {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 1em;
  border: 0.2em solid #c3c9d8;
  background-color: #fff;
  height: 4.6em;
  width: 4.6em;
}
ssi-query-builder-step .query-container-builder-group-add .ssi {
  font-size: 1.2em;
  color: #43537f;
}
ssi-query-builder-step .query-container-builder-group-delete {
  position: absolute;
  bottom: 1.2em;
  right: 2em;
  color: #43537f;
  padding: 0.5em;
  cursor: pointer;
}
ssi-query-builder-step .query-container-builder-group-delete p {
  display: inline-block;
  margin: 0;
  font-size: 1.2em;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-query-builder-step .query-container-builder-group-delete .ssi {
  vertical-align: text-top;
  margin: 0 0.2em 0 0;
}

.query-builder-pro-tip-modal {
  background-color: rgba(53, 53, 63, 0.85);
}
.query-builder-pro-tip-modal .modal-dialog {
  width: calc(100% - 15px);
  max-width: 1025px;
}
.query-builder-pro-tip-modal .modal-content {
  padding: 20px;
  box-shadow: 0 5px 20px rgba(131, 142, 171, 0.2);
  border-radius: 12px;
  background-color: #fff;
  color: #43537f;
}
.query-builder-pro-tip-modal .modal-close-button {
  display: inline-block;
  border-radius: 50%;
  border-width: 0;
  background-color: #f4f4fa;
  height: 36px;
  width: 36px;
  color: #14bae3;
  line-height: 0;
  float: right;
}
.query-builder-pro-tip-modal .modal-close-button .ssi {
  font-size: 10px;
  transform: rotate(45deg);
}
.query-builder-pro-tip-modal .pro-modal-header {
  margin: 0 60px 35px 50px;
}
.query-builder-pro-tip-modal .pro-modal-header h2 {
  font-size: 20px;
  margin: 30px 0 14px;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
.query-builder-pro-tip-modal .pro-modal-header p {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-rendering: geometricPrecision;
}
.query-builder-pro-tip-modal .pro-modal-header a {
  font-weight: 900;
  color: #14bae3;
  text-decoration: underline;
}
.query-builder-pro-tip-modal .pro-modal-body {
  background-color: #f8f9fd;
  border-radius: 10px;
  padding: 30px 30px 0;
}
.query-builder-pro-tip-modal .pro-modal-body h3 {
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 900;
  text-rendering: geometricPrecision;
  margin: 7px 20px 13px;
}
.query-builder-pro-tip-modal .pro-modal-body img {
  display: none;
  flex: 1 0 234px;
  align-self: flex-end;
  height: 303px;
  width: 234px;
  margin: -35px -15px 0 80px;
  object-fit: cover;
  object-position: top;
}
@media (min-width: 768px) {
  .query-builder-pro-tip-modal .pro-modal-body img {
    display: block;
  }
}
.query-builder-pro-tip-modal .pro-modal-body-content {
  display: flex;
}
.query-builder-pro-tip-modal .pro-modal-body-legend {
  display: flex;
  flex-wrap: wrap;
}
.query-builder-pro-tip-modal .pro-modal-body-legend dt {
  flex: 0 1 97px;
  width: 97px;
  text-align: right;
  padding: 0 20px 0 0;
}
.query-builder-pro-tip-modal .pro-modal-body-legend dt .pro-modal-body-key {
  margin: 5px 0 0;
}
.query-builder-pro-tip-modal .pro-modal-body-legend dd {
  flex: 1 1 calc(100% - 97px);
  width: calc(100% - 97px);
  color: #43537f;
  font-weight: 700;
  text-rendering: geometricPrecision;
  line-height: 20px;
  margin: 0 0 20px;
}
.query-builder-pro-tip-modal .pro-modal-body-legend dd strong {
  font-weight: 900;
}
.query-builder-pro-tip-modal .pro-modal-body-legend dd:after {
  content: "";
  display: block;
  flex-basis: 100%;
  height: 0;
}
.query-builder-pro-tip-modal .pro-modal-body-key {
  display: inline-block;
  min-width: 50px;
  text-align: center;
  padding: 6px 9px 7px;
  border-radius: 4px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  font-size: 12px;
  text-rendering: geometricPrecision;
  font-weight: 900;
  background-color: #eee;
}
.query-builder-pro-tip-modal .pro-modal-body-key-blue {
  background-color: #425dec;
}
.query-builder-pro-tip-modal .pro-modal-body-key-yellow {
  background-color: #f0b427;
}
.query-builder-pro-tip-modal .pro-modal-body-key-orange {
  background-color: #f88c68;
}
.query-builder-pro-tip-modal .pro-modal-body-key-white {
  background-color: #fff;
  color: #43537f;
}