ssi-account-pill {
  font-size: 1rem;
}
ssi-account-pill .account-pill {
  display: inline-flex;
  align-items: center;
  padding: 1.1em 1em;
  background-color: #ebebf4;
  border-radius: 1em;
}
ssi-account-pill .account-pill-icon {
  color: #838eab;
  margin-right: 0.7em;
}
ssi-account-pill .account-pill-icon:before {
  font-size: 1.4em;
}
ssi-account-pill .account-pill-label {
  color: #43537f;
  font-size: 1.2em;
  font-weight: 900;
  text-rendering: geometricPrecision;
  margin: 0;
}