.manage-ifttt-applet-service .manage-ifttt-applet-service-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 20px;
  width: 200px;
  height: 170px;
  margin: 0 3px;
  box-shadow: 0 0 20px rgba(150, 150, 150, 0.1);
  transition: box-shadow 500ms ease;
}
.manage-ifttt-applet-service .manage-ifttt-applet-service-item:hover, .manage-ifttt-applet-service .manage-ifttt-applet-service-item:focus {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
.manage-ifttt-applet-service .manage-ifttt-applet-service-item:first-child {
  color: #1faece;
}
.manage-ifttt-applet-service .manage-ifttt-applet-service-item:nth-child(2) {
  color: #6c80d7;
}
.manage-ifttt-applet-service .manage-ifttt-applet-service-item:nth-child(3) {
  color: #e5b457;
}
.manage-ifttt-applet-service .manage-ifttt-applet-service-item h4 {
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 20px;
}
.manage-ifttt-applet-service .manage-ifttt-applet-service-item img {
  width: 50px;
  height: 50px;
}
.manage-ifttt-applet-service .manage-ifttt-applet-service-item:hover {
  cursor: pointer;
}
.manage-ifttt-applet-service .manage-ifttt-applet-trigger-icon {
  font-size: 23px;
}