.sentiment-progressbar {
  display: flex;
  height: 20px;
  margin-bottom: 50px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 10px;
}
.sentiment-progressbar.sentiment-progressbar-thin {
  height: 12px;
  margin-top: 16px;
  margin-bottom: 20px;
}
.sentiment-progressbar.sentiment-progressbar-thin.sentiment-bar-spacing-before {
  margin-top: 2px;
}
.sentiment-progressbar.sentiment-bar-spacing-before {
  margin-top: 2px;
}

.sentiment-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #d6dff7;
  transition: width 0.6s ease;
}
.sentiment-bar.sentiment-bar-positive {
  background-color: #14bae3;
}
.sentiment-bar.sentiment-bar-negative {
  background-color: #f88c68;
}