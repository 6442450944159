@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../angularjs/common/sass/variables';

.report-sidebar {
  position: fixed;
  left: 0;
  height: calc(100% - #{$navbar-height});
  overflow: auto;
  width: 228px;
  background-color: #292935;
  padding: 0 0 0 2em;
  z-index: 99;
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      padding: 0.6em 1em;
      margin: 0 0 0.6em 0;
      color: white;
      font-weight: 700;
      border-bottom-left-radius: 100px;
      border-top-left-radius: 100px;
      cursor: pointer;
      i {
        margin-right: 0.4em;
      }
    }
  }
  hr {
    margin-right: 2em;
    border-top: 2px solid white;
  }
  .nav-list {
    margin-bottom: 2em;
  }
  .report-icon {
    display: flex;
    align-items: center;
    justify-content: left;
    height: 94px;
    i {
      font-size: 1.6em;
      color: white;
      margin-left: 0.6em;
    }
  }
}
