@import "./src/apps/angularjs/common/sass/global-variables.scss";
.boost-confirmed{
  text-align: center;
  margin: 4em 0;

  h3 {
    margin-bottom: 1em;
  }

  i {
    display: block;
    font-size: 4em;
    margin-bottom: 20px;
  }
}