ssi-plugin {
  display: block;
  width: 280px;
  height: 100%;
  position: fixed;
  top: 0;
  right: -280px;
  z-index: 9999;
}
ssi-plugin iframe {
  border: none;
}

.plugin-background {
  visibility: hidden;
  position: absolute;
  width: 0;
  height: 0;
}

.plugin-container, .plugin, .plugin-window {
  position: relative;
  height: 100%;
  width: 100%;
}

.plugin {
  position: absolute;
  right: 280px;
}

.plugin-container {
  right: 0;
  top: 0px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-out;
}
.plugin-container.open {
  right: 280px;
}
.plugin-container.pinned {
  box-shadow: none;
  top: 68px;
}

.plugin-close {
  top: 22px;
  left: 30px;
}

.plugin-settings {
  top: 22px;
  left: 75px;
}

.plugin-button {
  position: absolute;
  padding: 12px;
  z-index: 10000;
  border: none;
  border-radius: 100%;
  background-color: #5B7CEE;
  line-height: 0;
  color: #fff;
}