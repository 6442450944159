@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../angularjs/common/sass/variables';
@import '../../../../angularjs/common/sass/mixins';
@import '~/styles/colors';

@mixin media-nav-arrow-size($dimensions, $arrow-offset) {
  width: $dimensions;
  @include align-vertical($dimensions);
  top: calc(50% - ((#{$dimensions} + #{$section-margin-width}) / 2));

  &.left {
    left: -$arrow-offset;
  }

  &.right {
    right: -$arrow-offset;
  }
}

ssi-media-nav {
  display: flex;
  position: relative;
  height: 100%;
  overflow: hidden;

  .media-nav-img {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 180px;
  }

  img {
    object-fit: cover;
    width: 100%;
  }

  .media-nav-arrow {
    color: $primary-active;
    background-color: white;
    position: absolute;
    border-radius: 50%;
    font-size: 35px;
    border: 0;
    box-shadow: $box-shadow-default;
    z-index: 1;
    @include media-nav-arrow-size(30px, 8px);
  }

  .media-nav-arrow:disabled {
    color: lighten(#000, 33.5%);
    cursor: not-allowed;
  }

  .media-nav-arrow:not(:disabled):hover {
    color: shade($primary-active, 15%);
    cursor: pointer;
  }

  video {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .nav-btn-dark {
    .media-nav-arrow {
      background-color: #2e3031;
    }
  }

  &.nav-btn-sm {
    .media-nav-arrow {
      @include media-nav-arrow-size(30px, 5px);
      font-size: 28px;
    }
  }

  &.redesign {
    height: auto;
    .media-nav-arrow {
      border-radius: 5px;
      height: 26px;
      width: 26px;
      background-color: #fff;

      &.left {
        top: calc(50% - 20px);
        left: 5px;
      }
      &.right {
        top: calc(50% - 20px);
        right: 5px;
      }
    }
  }
}
