@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../../angularjs/common/sass/include-media';

ssi-analytics ssi-marketing ssi-report ssi-brand-sentiment {
  font-size: 1rem;

  .brand-sentiment-container {
    .section-head {
      h2 .ssi.ssi-help {
        margin: 0 0 0 3px;
        font-size: 14px;
        vertical-align: text-top;
      }
    }

    .section-body {
      display: flex;
      flex-direction: column;

      @media print {
        display: block;
      }

      $margin-between-boxes: 2em;
      $box-radius: 12px;
      $box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.25);
      $box-bg-color: #fff;

      .sentiments {
        display: flex;
        flex-wrap: wrap;

        @media print {
          display: block;
        }

        .pie-chart-container {
          height: 40em;
          width: calc(50% - #{$margin-between-boxes});
          margin-right: calc($margin-between-boxes / 2);
          border-radius: 12px;
          background-color: $box-bg-color;
          box-shadow: $box-shadow;

          @media print {
            height: auto;
            border: 1px solid black;
            page-break-inside: avoid;
            max-width: 100%;
          }

          @include media('<portal') {
            width: 100%;
            margin-right: 0;
          }

          // for our innerHTML in highchart legend
          .highchart-legend-inline {
            display: inline-block;
            min-width: 3.5em;
            + span {
              margin-left: 0.5em;
            }
          }
        }

        .cards-container {
          width: 50%;
          display: flex;
          flex-wrap: wrap;
          gap: $margin-between-boxes;
          margin-left: calc($margin-between-boxes / 2);

          @media print {
            display: block;
          }

          @include media('<portal') {
            width: 100%;
            margin-left: 0;
            margin-top: $margin-between-boxes;
          }

          ssi-fact-overview,
          ssi-sentiment-card {
            display: flex;
            flex: 1 1 calc(33.33% - #{$margin-between-boxes});
            height: 19em;

            @include media('<desktop') {
              flex: 1 1 calc(50% - #{$margin-between-boxes});
            }
            @include media('<tablet') {
              flex-basis: 100%;
            }
          }
        }
      }

      .network-inbound-comments {
        flex: 1 1 auto;
        margin-top: $margin-between-boxes;
      }
    }
  }
}
