@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../angularjs/common/sass/mixins';

.manage-ifttt-applet-service {

  .manage-ifttt-applet-service-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 20px;
    width: 200px;
    height: 170px;
    margin: 0 3px;
    box-shadow: 0 0 20px rgba(150, 150, 150, 0.1);
    transition: box-shadow 500ms ease;

    &:hover,
    &:focus {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    }

    &:first-child {
      color: #1faece;
    }

    &:nth-child(2) {
      color: #6c80d7;
    }

    &:nth-child(3) {
      color: #e5b457;
    }

    h4 {
      font-size: 16px;
      font-weight: 500;
      margin: 0 0 20px;
    }

    $manage-ifttt-applet-service-item-img-dimensions: 50px;

    img {
      width: $manage-ifttt-applet-service-item-img-dimensions;
      height: $manage-ifttt-applet-service-item-img-dimensions;
    }
  }

  .manage-ifttt-applet-service-item:hover {
    cursor: pointer;
  }

  .manage-ifttt-applet-trigger-icon {
    font-size: 23px;
  }

}
