@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-advertising-preset-list {
  flex: 1 1 auto;
  padding: 0 15px;
}

.preset-wrap {
  flex: 1 0 auto;
  background-color: $dgrey-background;
  margin: 0 -15px;
  padding: 0 15px;
  min-height: 420px;
}

.presets-none {
  max-width: 400px;
  margin: 50px auto 100px;
  color: #e9f9ff;
  text-align: center;
}

.presets-none-icon {
  font-size: 68px;
  margin: 0 0 30px;
}

.presets-none-title {
  font-size: 18px;
  margin: 0 0 20px;
  font-weight: 500;
  color: #b3c5ce;
}

.presets-none-meta {
  color: #78848d;
  margin: 0 0 40px;
  font-weight: 500;
}

.presets {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px 0;
  padding: 3px 0 120px;
}

.preset {
  margin: 5px;
  height: 75px;
  background-color: #505760;
  flex: 0 0 calc(33.333% - 10px);
  width: calc(33.333% - 10px);
  padding: 15px;
}

.preset-create {
  background-color: #383c46;
  color: $primary-active;

  .btn-preset {
    background-color: #383c46;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }
}

.preset-title {
  position: relative;
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  width: calc(100% - 115px);
  height: 45px;
  line-height: 45px;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 0 0 25px;
  margin: 0;
}

.preset-title-icon {
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
  bottom: 0;
  color: $primary-active;
  font-size: 14px;
  line-height: 12px;
  height: 14px;
}

.btn-preset {
  border-radius: 50%;
  background-color: #4c535c;
  border-width: 0;
  height: 50px;
  width: 50px;
  float: right;
  margin-left: 5px;
  vertical-align: middle;
  line-height: 50px;
  padding: 0;
}

.preset-count {
  display: block;
  margin: 0;
  text-align: center;
  padding: 0 0 80px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
  color: #727e87;
}

.top.tooltip-preset {
  display: block;
  text-align: center;
  background: #383c46;
  width: 320px;
  min-height: 320px;
  border-radius: 20px;
  padding: 30px 25px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  font-weight: 500;

  .tooltip-arrow {
    border-width: 15px 40px 0 0;
  }
}

.top.tooltip-preset[data-popper-placement^='top'] {
  margin-bottom: -10px !important;

  .tooltip-arrow {
    margin-left: 15px;
    border-color: #383c46 transparent transparent transparent;
  }
}

.top.tooltip-preset[data-popper-placement^='bottom'] {
  margin-top: -10px !important;

  .tooltip-arrow {
    top: -15px;
    border-width: 0 40px 15px 0;
    border-color: transparent transparent #383c46 transparent;
  }
}

.tooltip-preset-icon {
  display: block;
  font-size: 26px;
  color: $primary-active;
  margin: 0 0 10px 0;
}

.tooltip-preset-title {
  font-size: 12px;
  margin-bottom: 30px;
}

.tooltip-preset-meta {
  font-size: 9px;
  color: $primary-active;
  font-style: italic;
}

.tooltip-preset-tags-wrap {
  position: relative;
  text-align: left;
  margin: 0 0 10px;
}

.tooltip-preset-label {
  position: absolute;
  width: 30px;

  .ssi {
    font-size: 20px;
  }
}

.tooltip-preset-tags {
  position: relative;
  display: block;
  font-size: 12px;
  line-height: 20px;
  margin: 0;
  padding-left: 30px;
  list-style: none;

  p {
    display: inline;
  }
}

.tooltip-preset-tag {
  display: inline;
  color: #fff;
}

@media (min-width: 1200px) {
  .presets {
    margin: 0 45px;
  }
}
