@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-highcharts {
  .dark {
    .highcharts-axis-labels {
      text {
        fill: #ccd6eb !important;
        font-size: 9px !important;
      }
    }

    .highcharts-contextbutton {
      .highcharts-button-box {
        fill: none !important;
      }

      .highcharts-button-symbol {
        stroke: #ccd6eb !important;
      }
    }
  }
}
