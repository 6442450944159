@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

$popup-responsive-width: 885px;

.modal-popup {
  text-align: center;

  .tag-selector {
    margin-top: 1em;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .dd-container,
    button.dd-head {
      width: 100%;
      height: auto;
    }
    button.dd-head {
      border-radius: 12px;
      background-color: white;
    }

    .select-tags-placeholder {
      color: $lmode-helper-text;
      font-size: 14px;
      font-weight: 700;
    }

    .select-tag-labels {
      text-align: left;
      margin: 0 0 -10px -10px;
      color: #fff;

      span {
        padding: 6px 8px;
        font-size: 10px;
        margin: 0 4px 8px 0;
        background-color: $primary-active;
        border-radius: 100px;
        display: inline-flex;
        align-items: center;
        text-rendering: geometricPrecision;
        word-break: break-all;

        &:hover {
          background-color: $secondary-red;
        }
      }

      .ssi {
        margin: 0 0 0 7px;
        font-size: 8px;
      }
    }
  }

  .modal-header {
    border-bottom: 0;
  }

  .modal-title {
    font-size: 26px;
    padding-bottom: 10px;
  }

  .modal-body {
    width: 80%;
    min-width: 500px;
    @media (max-width: $popup-responsive-width) {
      width: auto;
      min-width: initial;
    }

    margin: auto;
    padding-bottom: 70px;
  }

  .modal-action-buttons {
    margin-top: 40px;
    text-transform: uppercase;

    .btn {
      width: 150px;

      & ~ .btn {
        margin-left: 5px;
      }
    }
  }

  .modal-link {
    padding-top: 20px;
    font-size: 11px;
    font-weight: normal;
    text-transform: uppercase;
  }

  .btn {
    text-transform: uppercase;
    font-size: 11px;
  }

  .checkbox {
    text-transform: uppercase;

    label {
      font-size: 13px;
      padding-left: 10px;
    }
  }
}

.modal-style-1 {
  .modal-content {
    background-color: #fff;
    max-width: 480px;
    border-width: 0;
    border-radius: 15px;
    box-shadow: 0, 0, 18px rgba(131, 142, 171, 0.35);
  }

  .modal-header {
    display: none;
  }

  .modal-popup {
    .modal-body {
      width: 100%;
      min-width: auto;
      padding: 60px 35px;
    }
  }

  .modal-title {
    color: $lmode-body-text;
    font-size: 20px;
    font-weight: 900;
    line-height: 24px;
    padding: 0;
    margin: 0 0 15px;

    .ssi {
      display: block;
      margin: 0 0 20px;
      font-size: 30px;
      color: $dmode-body-text;
    }
  }

  .modal-meta {
    color: $lmode-helper-text;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
  }

  .modal-action-buttons {
    margin: 40px 0 0;

    .btn {
      min-width: 165px;
      padding: 14px 25px;
      color: #fff;
      font-size: 14px;
      font-weight: 700;
      border-width: 0;
      border-radius: 50px;
      text-transform: none;
    }

    .btn-primary {
      background-color: $primary-active;

      &:hover,
      &:focus {
        background-color: #11acd3;
      }
    }
  }
}
