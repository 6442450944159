ssi-live-chat-team-statistics {
  align-items: center;
  color: #43537f;
  display: flex;
  flex-flow: column nowrap;
}
ssi-live-chat-team-statistics * {
  display: flex;
}
ssi-live-chat-team-statistics button {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
}
ssi-live-chat-team-statistics button:focus {
  outline: revert;
}
ssi-live-chat-team-statistics button:hover .icon {
  background-color: #d8dfe5;
  cursor: pointer;
}
ssi-live-chat-team-statistics button:hover .icon i {
  color: #fff;
}
ssi-live-chat-team-statistics > ul {
  align-items: flex-start;
  flex-flow: row wrap;
  justify-content: flex-start;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  width: 650px;
}
ssi-live-chat-team-statistics > ul > li {
  align-items: flex-start;
  justify-content: center;
  margin: 20px 0;
  min-height: 130px;
  padding: 0 5px;
  width: 130px;
}
ssi-live-chat-team-statistics > ul > li dl {
  align-items: center;
  flex-flow: column-reverse nowrap;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}
ssi-live-chat-team-statistics > ul > li dl > dt {
  font-size: 0.8em;
  font-weight: 600;
  margin: 0;
  padding: 0;
  text-transform: capitalize;
}
ssi-live-chat-team-statistics > ul > li dl > dd {
  font-weight: 400;
  margin: 0;
  padding: 0;
}
ssi-live-chat-team-statistics > ul > li dl > dd.icon {
  align-items: center;
  background-color: #f6f8f9;
  border-radius: 50%;
  font-size: 2.5em;
  justify-content: center;
  padding: 20px;
}
ssi-live-chat-team-statistics > ul > li dl > dd.icon > i {
  color: #43ade8;
}
ssi-live-chat-team-statistics > ul > li dl > dd.value {
  font-size: 1.6em;
  font-style: italic;
  font-weight: 300;
}