ssi-view-profile-modal mwl-gauge {
  display: block;
  margin-bottom: 20px;
}
ssi-view-profile-modal mwl-gauge .gauge {
  transform: perspective(1px) translateZ(0);
  max-width: 140px;
}
ssi-view-profile-modal mwl-gauge .gauge .dial {
  stroke: rgb(238, 238, 238);
  stroke-width: 20;
  fill: rgba(0, 0, 0, 0);
}
ssi-view-profile-modal mwl-gauge .gauge .value {
  stroke-width: 20;
  fill: rgba(0, 0, 0, 0);
}
ssi-view-profile-modal mwl-gauge .gauge .value-text {
  fill: #596065;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 15px;
  transform: translate(0, -5px);
}
ssi-view-profile-modal .mwl-gauge-label {
  position: absolute;
  bottom: 50%;
  left: 0;
  right: 0;
  color: #596065;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 0;
}
ssi-view-profile-modal .mwl-gauge-label.mwl-gauge-label-large {
  font-size: 30px;
}
ssi-view-profile-modal ssi-gauge-chart {
  position: relative;
  display: block;
}
ssi-view-profile-modal .gauge-chart-small {
  margin-top: 20px;
}
ssi-view-profile-modal .gauge-chart-small mwl-gauge .gauge {
  max-width: 90px;
}
ssi-view-profile-modal .gauge-chart-green mwl-gauge .gauge .value {
  stroke: #5fb65e;
}
ssi-view-profile-modal .gauge-chart-green .mwl-gauge-label {
  color: #5fb65e;
}
ssi-view-profile-modal .gauge-chart-orange mwl-gauge .gauge .value {
  stroke: #edab55;
}
ssi-view-profile-modal .gauge-chart-orange .mwl-gauge-label {
  color: #edab55;
}
ssi-view-profile-modal .gauge-chart-blue mwl-gauge .gauge .value {
  stroke: #3979b3;
}
ssi-view-profile-modal .gauge-chart-blue .mwl-gauge-label {
  color: #3979b3;
}
ssi-view-profile-modal .verified-wrap {
  position: relative;
}
ssi-view-profile-modal .verified {
  display: block;
  position: absolute;
  top: -1px;
  right: -1px;
  color: #1DA1F2;
  font-size: 20px;
  z-index: 1;
}
ssi-view-profile-modal .verified:after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 70%;
  width: 70%;
  background-color: #fff;
  border-radius: 50%;
  z-index: -1;
}