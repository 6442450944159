.gif-search-modal .modal-content {
  background-color: #fff;
}

ssi-gif-search-modal .gif-search-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #fff;
  padding: 6px 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
ssi-gif-search-modal .gifs-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height: 400px;
  overflow: auto;
  overflow-x: hidden;
  background-color: #fff;
  margin: 0 7px;
}
ssi-gif-search-modal .gif-box {
  position: relative;
  cursor: pointer;
}
ssi-gif-search-modal .gif-box label {
  position: absolute;
  color: #fff;
  font-weight: 900;
  bottom: 0;
  left: 5px;
  text-transform: capitalize;
}
ssi-gif-search-modal .gif-box.category {
  width: 50%;
  height: 150px;
  margin: 3px;
  width: calc(50% - 6px);
}
ssi-gif-search-modal .gif-box.category:nth-child(odd) {
  margin-left: 0;
  width: calc(50% - 3px);
}
ssi-gif-search-modal .gif-box.category:nth-child(even) {
  margin-right: 0;
  width: calc(50% - 3px);
}
ssi-gif-search-modal .gif-box.category img {
  width: 100%;
  height: 150px;
}
ssi-gif-search-modal .search-input-container {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-right: 30px;
}
ssi-gif-search-modal .search-input-container input {
  background-color: #f8f9fd;
  outline: none;
  border: none;
  color: #838eab;
  border: 1px solid #c3c9d8;
  border-radius: 10px;
  padding: 12px 24px;
  margin: 12px 0px;
  width: 100%;
  font-family: "Lato", "Arial", sans-serif;
  font-weight: bold;
}
ssi-gif-search-modal .search-input-container input::placeholder {
  color: #838eab;
  font-weight: bold;
}
ssi-gif-search-modal .search-input-container input.active {
  color: #101525;
  border: 1px solid #14bae3;
}
ssi-gif-search-modal .search-input-container .cancel-search {
  min-width: 160px;
  appearance: none;
  box-shadow: none;
  border: none;
  background-color: #fff;
  border-radius: 10px;
  border-color: #838eab;
  display: flex;
  align-items: center;
  font-weight: 900;
  cursor: pointer;
  color: #43537f;
}
ssi-gif-search-modal .search-input-container .cancel-search i {
  transform: rotate(270deg);
  margin-right: 7px;
}
ssi-gif-search-modal .search-input-container .cancel-search.visible {
  visibility: visible;
}
ssi-gif-search-modal i.close-icon {
  padding: 12px;
  background-color: #ecf0fb;
  border-radius: 50px;
  color: #838eab;
  font-size: 10px;
}
ssi-gif-search-modal i.close-icon:hover {
  cursor: pointer;
}
ssi-gif-search-modal .giphy-gif {
  cursor: pointer;
}
ssi-gif-search-modal > img {
  margin-left: auto;
  display: flex;
  padding: 7px 0;
}