@import 'variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

.btn-inverse {
  @include button-variant(#fff, $brand-inverse, rgba(0, 0, 0, 0.2));
}

.btn-royal {
  @include button-variant(white, $brand-royal, rgba(0, 0, 0, 0.2));
}

.btn-outline {
  background-color: transparent;
}

.btn-outline.btn-primary {
  color: $brand-primary;
}

.btn-outline.btn-inverse {
  color: $gray;
}

.btn-outline.btn-primary:hover,
.btn-outline.btn-inverse:hover {
  color: #fff;
}

.btn-square {
  border-radius: 0;
}

.btn-rounded {
  border-radius: 20px;
}

.btn-padding-sides {
  $btn-padding: 30px;
  padding-left: $btn-padding;
  padding-right: $btn-padding;
}
