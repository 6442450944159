ssi-publisher-linkedin-post-preview {
  display: block;
  max-width: 552px;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 3px rgba(0, 0, 0, 0.2);
  font-family: Source Sans Pro, Helvetica, Arial, sans-serif, Hiragino Kaku Gothic Pro, Meiryo, Hiragino Sans GB W3, Noto Naskh Arabic, Droid Arabic Naskh, Geeza Pro, Simplified Arabic, Noto Sans Thai, Thonburi, Dokchampa, Droid Sans Thai, Droid Sans Fallback, -apple-system, ".SFNSDisplay-Regular", Heiti SC, Microsoft Yahei, Segoe UI;
}
ssi-publisher-linkedin-post-preview .linkedin-post-header {
  padding: 12px 16px 0;
  margin-bottom: 8px;
}
ssi-publisher-linkedin-post-preview .linkedin-post-header img {
  width: 48px;
  height: 48px;
  border-radius: 6px;
  border: 4px solid transparent;
  margin-right: 8px;
}
ssi-publisher-linkedin-post-preview .linkedin-post-account-name {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 20px;
}
ssi-publisher-linkedin-post-preview .linkedin-post-date-time {
  font-weight: 400;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.55);
  font-size: 13px;
}
ssi-publisher-linkedin-post-preview .linkedin-post-account-name,
ssi-publisher-linkedin-post-preview .linkedin-post-date-time {
  position: relative;
  top: 5px;
}
ssi-publisher-linkedin-post-preview .linkedin-post-body img {
  width: 100%;
  padding: 0 16px;
}
ssi-publisher-linkedin-post-preview .linkedin-post-body .joypixels {
  width: auto;
  padding: 0;
}
ssi-publisher-linkedin-post-preview .linkedin-post-text {
  word-wrap: break-word;
  font-weight: 400;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.7);
  font-size: 100%;
  margin: 0 16px;
  overflow: hidden;
}
ssi-publisher-linkedin-post-preview .linkedin-post-text a,
ssi-publisher-linkedin-post-preview .linkedin-post-text a:hover {
  color: #0084bf;
  font-weight: 700;
}
ssi-publisher-linkedin-post-preview .linkedin-post-image,
ssi-publisher-linkedin-post-preview .linkedin-post-video {
  margin-top: 6px;
}
ssi-publisher-linkedin-post-preview .linkedin-post-image img,
ssi-publisher-linkedin-post-preview .linkedin-post-image video,
ssi-publisher-linkedin-post-preview .linkedin-post-video img,
ssi-publisher-linkedin-post-preview .linkedin-post-video video {
  width: 100%;
}
ssi-publisher-linkedin-post-preview .linkedin-post-link-preview {
  display: block;
  margin-top: 8px;
  margin-left: 16px;
  margin-right: 16px;
  border-radius: 2px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}
ssi-publisher-linkedin-post-preview .linkedin-post-link-preview:hover {
  text-decoration: none;
}
ssi-publisher-linkedin-post-preview .linkedin-post-link-preview-img {
  background-position: center;
  background-size: cover;
  height: 270px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
ssi-publisher-linkedin-post-preview .linkedin-post-link-preview-footer {
  padding: 8px 0;
  border-top: 1px solid #e6e9ec;
}
ssi-publisher-linkedin-post-preview .linkedin-post-link-preview-title {
  overflow: hidden;
  max-height: 40px;
  margin: 0 12px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  font-size: 15px;
}
ssi-publisher-linkedin-post-preview .linkedin-post-link-preview-hostname {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 16px;
  margin: 0 12px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.55);
}
ssi-publisher-linkedin-post-preview .linkedin-post-footer {
  margin: 16px;
  border-top: 1px solid #e6e9ec;
  padding-top: 8px;
  height: 40px;
}
ssi-publisher-linkedin-post-preview .linkedin-post-footer-item {
  padding: 0 8px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.55);
  font-size: 15px;
}
ssi-publisher-linkedin-post-preview .linkedin-post-footer-item:first-child {
  padding-left: 0;
}
ssi-publisher-linkedin-post-preview .text-read-more {
  font-weight: 700;
  cursor: pointer;
}