@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-sentiment-amend-box {
  font-size: 1rem;

  .message-box-actions-menu {
    position: relative;
    display: inline-block;
    background: #fff;
    padding: 3em 2.5em 3.5em;
    border-radius: 1em;
    box-shadow: 0 0.5em 1em 0 rgba(131, 142, 171, 0.35);
    margin: -3em 0;

    &.message-box-actions-menu-selfpenned {
      background-color: #ecf0fd;
    }
  }

  .message-box-actions-menu-item-body {
    padding: 2em 0 0;
  }

  .menu-item-icon {
    color: $lmode-helper-text;
    margin-right: 0.8em;
    vertical-align: top;
    line-height: 2.4em;

    &:before {
      font-size: 1.4em;
    }

    &-warning {
      color: #e9856f;
    }
  }

  .message-box-actions-menu-item-button {
    padding: 0.2em 1.3em 0.1em 0.8em;
    margin: 0 -0.8em;
    border-radius: 10em;
    cursor: pointer;

    .menu-item-title {
      display: inline;
      color: $lmode-body-text;
      font-size: 1.4em;
      line-height: 2em;
      font-weight: 900;
      vertical-align: top;
      margin: 0;

      &:hover {
        color: $primary-active;
      }
    }

    &:hover {
      background: $grey-background;
    }
  }
}
