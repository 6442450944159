ssi-add-custom-provider-modal .form-row {
  display: flex;
}
ssi-add-custom-provider-modal .form-group {
  width: 100%;
}
ssi-add-custom-provider-modal .info-message {
  margin-top: 20px;
}
ssi-add-custom-provider-modal ul {
  margin: 10px 0;
  padding-left: 0;
  font-size: 17px;
}
ssi-add-custom-provider-modal ul li {
  margin-left: 20px;
  margin-top: 10px;
}
ssi-add-custom-provider-modal ul li span:hover {
  cursor: pointer;
  font-weight: bold;
}
ssi-add-custom-provider-modal .success-message,
ssi-add-custom-provider-modal .error-message {
  margin-top: 10px;
  text-align: center;
  width: 100%;
}
ssi-add-custom-provider-modal .success-message {
  color: green;
}
ssi-add-custom-provider-modal .error-message {
  color: red;
}