@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-widget-monitoring-stream-config {
  height: 100%;
  width: 100%;

  .widget-monitoring-stream-config-container {
    .dwc-form {
      display: flex;
      flex: 1 1 auto;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 1em 1em 1em;

      .dwc-form-half {
        flex: 1 0 100%;

        @media (min-width: 768px) {
          flex: 1 0 calc(50% - 16px); // 16 is col gutter
          min-width: 30em;
          margin: 0 0.8em;
        }
      }

      .dwc-body-row {
        padding: 0 3em;
        margin: 0 0 2em;
      }

      .dwc-label {
        display: block;
        text-transform: uppercase;
        font-size: 1em;
        font-weight: 900;
        letter-spacing: 0.1em;
        margin: 0 0 1.8em;
        color: #43537f;
        text-rendering: geometricPrecision;
      }

      .dwc-body-row-input {
        position: relative;
        margin: 0 -3rem 0;
      }

      .dwc-input {
        font-size: 1.4em;
        width: 100%;
        border-width: 0;
        height: 4.6rem;
        border-radius: 1rem;
        color: #101525;
        font-weight: 700;
        text-rendering: geometricPrecision;
        text-indent: 3rem;
        padding: 0 3rem 0 0;
        background-color: #fff;
        outline: 1px solid #c3c9d8;

        &:focus {
          outline: 1px solid $primary-active;
        }

        &:disabled {
          opacity: 0.4;
        }

        &::placeholder {
          color: #6a759a;
          text-rendering: geometricPrecision;
        }
      }

      .dwc-body-row-input-meta {
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        height: 1em;
        line-height: 1;
        right: 1.8em;
        color: $lmode-helper-text;
        font-size: 1em;
        font-weight: 900;
        text-rendering: geometricPrecision;

        &-active {
          color: $primary-active;
        }
      }

      .dwc-dropdown-selector {
        margin: 0 -3rem;

        .dd-container,
        .dd-head {
          width: 100%;
        }

        .dd-head {
          border: 1px solid #c3c9d8;
          background: #fff;
        }

        .dd-container {
          .dd-body {
            width: 100%;

            ul {
              max-height: 268px;

              li {
                font-size: 14px;
                min-height: 36px;
                padding: 3px 10px 3px 10px;
                border-radius: 10px;
                margin: 5px 0;
              }
            }
          }

          .dd-head.variant-analytics .button-icon {
            color: $primary-active;
          }

          button {
            border-radius: 1rem;
            text-indent: 10px;
            height: 4.6rem;
          }
        }
      }

      .dwc-dropdown-placeholder {
        color: #838eab;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }

  ssi-global-filters {
    .global-filters .filters-title {
      padding-top: 0;
      padding-left: 0;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 900;
      letter-spacing: 1px;
      color: #43537f;
      text-rendering: geometricPrecision;
    }

    .global-filters.compact .filters-body {
      padding: 0;
    }

    .global-filters {
      ul {
        padding: 0;
      }

      ssi-tags-input {
        padding: 0 0 10px;
      }

      .filters-footer {
        display: none;
      }
    }
  }
}
