ssi-inbox-priority-select .inbox-priority-select-container {
  display: flex;
  align-items: center;
  padding: 0 8px;
  height: 36px;
  border-radius: 10px;
  background-color: #fff;
}
ssi-inbox-priority-select .inbox-priority-select-container > * {
  padding: 0 3px;
  cursor: pointer;
}
ssi-inbox-priority-select .inbox-priority-select-container.single-value {
  background: #fff;
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
}
ssi-inbox-priority-select ul .inbox-priority-dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 10px;
}
ssi-inbox-priority-select ul > span {
  display: block;
  margin-left: 12px;
  font-size: 1.4rem;
  font-weight: 900;
  text-rendering: geometricPrecision;
  color: #43537f;
  margin-bottom: 10px;
}
ssi-inbox-priority-select ul > span i {
  margin-right: 8px;
}
ssi-inbox-priority-select ul li {
  display: flex;
  justify-content: flex-start;
}
ssi-inbox-priority-select ul li .tick {
  display: none;
}
ssi-inbox-priority-select ul li.active {
  background-color: #f8f9fd;
}
ssi-inbox-priority-select ul li.active i.tick {
  display: block;
  color: #14bae3;
  margin-left: auto;
}
ssi-inbox-priority-select .inbox-priority-container .inbox-priority-badge {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
ssi-inbox-priority-select .inbox-priority-container .inbox-priority-badge span {
  color: #fff;
  font-size: 10px;
  font-weight: 900;
}
ssi-inbox-priority-select .P1 {
  background-color: #f4490f;
}
ssi-inbox-priority-select .P2 {
  background-color: #f0b427;
}
ssi-inbox-priority-select .P3 {
  background-color: #425dec;
}
ssi-inbox-priority-select .P4 {
  background-color: #89a4ea;
}
ssi-inbox-priority-select .P5 {
  background-color: #12aca4;
}