@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../../angularjs/common/sass/variables';
@import '../../styles/variables';
@import '~/styles/colors';

ssi-sentiment-gauge {
  padding: 5px;

  .sentiment-chart-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;

    .footer {
      position: absolute;
      height: 44px;
      width: 100%;
      bottom: 0;
      left: 0;
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 5px;

      .left {
        span {
          color: #101525;
          font-size: 26px;
          font-weight: 900;
        }
      }

      .right {
        display: flex;
        align-items: center;

        span {
          color: #101525;
          font-size: 10px;
          font-weight: 900;
          text-transform: uppercase;
          padding-top: 5px;
        }

        i {
          font-size: 20px;
          margin-left: 8px;
          border-radius: 50%;
          padding: 5px;

          &.passive-new-icon {
            border-radius: 0;
            padding: 0;
            font-size: 32px;
          }
        }
      }
    }
  }
}
