.reply-box {
  position: relative;
  background-color: #fff;
  border-radius: 12px;
  padding: 10px;
  border: 2px solid transparent;
  display: flex;
  height: 70px;
  max-height: calc(
    100vh - 86px
  );
  transition: height 200ms ease, border 100ms ease 200ms;
}
.reply-box .single-reply-warning {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff1ed;
  border-radius: 10px;
  border: 1px solid #f88c68;
}
.reply-box .single-reply-warning i {
  color: #f88c68;
  margin-right: 7px;
}
.reply-box .single-reply-warning p {
  color: #43537f;
  font-size: 12px;
  margin: 0;
}
.reply-box.unfinished {
  border: 2px solid #14bae3;
  box-shadow: 0 5px 10px rgba(131, 142, 171, 0.35);
}
.reply-box.inactive {
  box-sizing: border-box;
}
.reply-box.inactive:after {
  content: "";
  display: block;
  position: absolute;
  height: calc(100% + 4px);
  width: calc(100% + 4px);
  top: -2px;
  left: -2px;
  border: 1px solid #a3b8ee;
  pointer-events: none;
  border-radius: 12px;
}
.reply-box.invalid {
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  align-items: center;
  border-radius: 0;
  border-top: 2px solid #eff1f4;
  height: 80px;
}
.reply-box.invalid .session-invalid-actions {
  display: flex;
  align-items: center;
}
.reply-box.invalid .session-invalid-actions button {
  margin: 0 5px;
  background-color: #fff;
  color: #14bae3;
}
.reply-box.invalid .session-invalid-message {
  display: flex;
  align-items: center;
  padding: 8px 13px;
  background-color: #35353f;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  border-radius: 10px;
}
.reply-box.invalid .session-invalid-message i {
  color: #838eab;
  font-size: 18px;
  margin-right: 10px;
}
.reply-box.invalid .validity-message a {
  color: #14bae3;
  text-decoration: underline;
}
.reply-box button {
  border: none;
  border-radius: 50px;
  background-color: #f3f5fa;
  color: #14bae3;
}
.reply-box .resolved {
  font-size: 12px;
  color: #838eab;
  font-weight: 700;
}
.reply-box .resolved i {
  padding-right: 0.5em;
}
.reply-box .reply-box-above {
  position: absolute;
  left: 0;
  top: -45px;
  display: flex;
  width: 100%;
  pointer-events: none;
}
.reply-box .reply-box-above > * {
  pointer-events: auto;
  margin-right: 0.8rem;
}
.reply-box .reply-box-above .viewing {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cd5cdf;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: default;
  position: relative;
}
.reply-box .reply-box-above .viewing-conversation {
  background-color: #516cff;
}
.reply-box .reply-box-above .viewing i {
  font-size: 20px;
  color: #fff;
}
.reply-box .reply-box-above .viewing .count-badge {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: #9a03b3;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -2px;
  right: -2px;
}
.reply-box .reply-box-above .viewing .count-badge span {
  color: #fff;
  font-size: 10px;
  font-weight: 900;
}
.reply-box .reply-box-above .typing {
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 22px;
  background-color: #6377ad;
  cursor: default;
}
.reply-box .reply-box-above .typing .avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-left: 2px;
  margin-right: 1rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.reply-box .reply-box-above .typing .avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.reply-box .reply-box-above .typing .avatar i {
  font-size: 16px;
  color: #838eab;
}
.reply-box .reply-box-above .typing .text {
  margin-right: 0.5rem;
}
.reply-box .reply-box-above .typing .text span {
  color: #fff;
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
}
.reply-box .reply-box-above .typing .dots {
  height: 20px;
  margin-right: 1.5rem;
}
.reply-box .reply-box-above .countdown.end-right {
  margin-left: auto;
  margin-right: 2rem;
}
.reply-box .collision-tooltip-content {
  padding: 2.5rem;
}
.reply-box .collision-tooltip-content .title {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
  margin-right: 50px;
}
.reply-box .collision-tooltip-content .content {
  color: #b4bbce;
  font-size: 14px;
  font-weight: 500;
}
.reply-box .collision-tooltip-content .content.alone {
  font-weight: 900;
}
.reply-box .collision-tooltip-content .icon-container {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 26px;
  width: 26px;
  border-radius: 50%;
  background-color: rgba(138, 149, 176, 0.17);
  display: flex;
  align-items: center;
  justify-content: center;
}
.reply-box .collision-tooltip-content .icon-container i {
  color: #8a95b0;
  font-size: 16px;
}
.reply-box .session-tooltip-content {
  padding: 2.5rem;
}
.reply-box .session-tooltip-content .title {
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 20px;
}
.reply-box .session-tooltip-content .content {
  color: #b4bbce;
  font-size: 14px;
  font-weight: 500;
}
.reply-box .session-tooltip-content .icon-container {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 26px;
  width: 26px;
  border-radius: 50%;
  background-color: rgba(138, 149, 176, 0.17);
  display: flex;
  align-items: center;
  justify-content: center;
}
.reply-box .session-tooltip-content .icon-container i {
  color: #8a95b0;
  font-size: 16px;
}
.reply-box .textarea-emoji-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}
.reply-box .textarea-emoji-container .popover {
  border-radius: 25px;
}
.reply-box .textarea-emoji-container .popover input {
  border-radius: 15px;
}
.reply-box.active {
  flex-direction: column;
  height: 320px;
  box-shadow: 0 5px 10px rgba(131, 142, 171, 0.35);
}
.reply-box.active .textarea-emoji-container {
  flex-direction: column;
}
.reply-box.active textarea,
.reply-box.active .prediction-container {
  padding: 0 25px;
  margin-top: 20px;
  line-height: 24px;
}
.reply-box.active textarea {
  transition: height 0.3s;
}
.reply-box.active textarea::placeholder {
  text-indent: 3px;
}
.reply-box.active .prediction-container .prediction:after {
  margin-top: 0;
}
.reply-box.expanded {
  height: 600px;
  box-shadow: 0 5px 10px rgba(131, 142, 171, 0.35);
}
.reply-box.expanded textarea {
  height: 100%;
}
.reply-box textarea {
  color: #101525;
  background-color: transparent;
  caret-color: #14bae3;
  border: 0 !important;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  resize: none;
  z-index: 1;
  flex: 1 1 auto;
}
.reply-box textarea::placeholder {
  color: #838eab;
  font-weight: 700;
  text-rendering: geometricPrecision;
}
.reply-box .prediction-container {
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: #838eab;
  z-index: 0;
}
.reply-box .prediction-container .prediction:after {
  position: absolute;
  content: "TAB";
  margin-top: 3px;
  margin-left: 7px;
  border-radius: 5px;
  color: #14bae3;
  border-radius: 5px;
  background-color: #f3f5fa;
  height: 24px;
  padding: 2px;
  width: 40px;
  font-size: 10px;
  text-align: center;
  letter-spacing: 1px;
  font-weight: 600;
  line-height: 20px;
}
.reply-box textarea,
.reply-box .prediction-container {
  margin: 8px 0;
  padding: 0 20px;
  line-height: 30px;
  font-size: 14px;
  font-weight: 500;
  height: 30px;
  white-space: pre-wrap;
  word-spacing: 0;
  word-wrap: break-word;
}
.reply-box .reply-top {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  margin: 5px 10px 25px;
}
.reply-box .reply-top.reply-top-hidden {
  visibility: hidden;
}
.reply-box .reply-top.reply-top-desktop {
  flex-wrap: nowrap;
  margin: 15px 25px;
}
.reply-box .reply-top.reply-top-desktop .reply-top-left {
  flex: 0 1 auto;
  justify-content: flex-start;
  margin-bottom: 0;
}
.reply-box .reply-top.reply-top-desktop .reply-top-left .reply-from ssi-dropdown-select-2 {
  flex: 0 1 auto;
}
.reply-box .reply-top.reply-top-desktop .reply-top-right {
  flex: 0 1 auto;
  justify-content: flex-start;
}
.reply-box .reply-top.reply-top-desktop .reply-top-right .reply-to {
  flex: 0 1 auto;
  margin-left: 15px;
}
.reply-box .reply-top.reply-top-desktop .reply-top-right .reply-to ssi-dropdown-select-2 {
  flex: 0 1 auto;
}
.reply-box .reply-top.reply-top-desktop .reply-top-right .expand-icon-container,
.reply-box .reply-top.reply-top-desktop .reply-top-right .keyboard-shortcuts-container {
  display: block;
  margin-left: 15px;
}
.reply-box .reply-top .reply-top-left {
  flex: 1 0 100%;
  display: flex;
  margin-bottom: 10px;
}
.reply-box .reply-top .reply-top-left .reply-from {
  flex: 1 0 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 900;
  color: #838eab;
}
.reply-box .reply-top .reply-top-left .reply-from > span {
  margin-right: 5px;
}
.reply-box .reply-top .reply-top-left .reply-from ssi-dropdown-select-2 {
  flex: 1 0 auto;
  max-width: 350px;
}
.reply-box .reply-top .reply-top-left .reply-from ssi-dropdown-select-2 button.dd-head {
  width: 100%;
}
.reply-box .reply-top .reply-top-right {
  flex: 1 0 100%;
  display: flex;
  align-items: center;
}
.reply-box .reply-top .reply-top-right .reply-to {
  flex: 1 0 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 900;
  color: #838eab;
}
.reply-box .reply-top .reply-top-right .reply-to > span {
  margin-right: 5px;
}
.reply-box .reply-top .reply-top-right .reply-to ssi-dropdown-select-2 {
  flex: 1 0 auto;
  max-width: 350px;
}
.reply-box .reply-top .reply-top-right .reply-to ssi-dropdown-select-2 button.dd-head {
  width: 100%;
}
.reply-box .reply-top .reply-top-right .translate-reply {
  background-color: #f8f9fd;
  outline: none;
  height: 30px;
  padding: 6px 10px;
  font-weight: 900;
  font-size: 12px;
}
.reply-box .reply-top .reply-top-right .translate-reply:disabled, .reply-box .reply-top .reply-top-right .translate-reply[disabled] {
  color: #838eab;
}
.reply-box .reply-top .reply-top-right .translate-reply span {
  margin: 0 3px;
}
.reply-box .reply-top .reply-top-right .keyboard-shortcuts-container {
  display: none;
  position: relative;
}
.reply-box .reply-top .reply-top-right .keyboard-shortcuts-container .keyboard-icon {
  font-size: 18px;
  color: #838eab;
}
.reply-box .reply-top .reply-top-right .keyboard-shortcuts-container .keyboard-icon:hover {
  cursor: pointer;
}
.reply-box .reply-top .reply-top-right .keyboard-shortcuts-container .keyboard-icon.keyboard-icon-absolute {
  position: absolute;
  top: 0;
  left: 0;
}
.reply-box .reply-top .reply-top-right .keyboard-shortcuts-container .keyboard-shortcuts-list {
  border-radius: 15px;
  border: 0;
  padding-bottom: 20px;
  bottom: auto;
}
.reply-box .reply-top .reply-top-right .keyboard-shortcuts-container .keyboard-shortcuts-list .shortcuts-heading {
  margin: 10px 10px 10px 0;
  padding: 5px 10px 10px 0;
  text-align: right;
  color: #838eab;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.reply-box .reply-top .reply-top-right .keyboard-shortcuts-container .keyboard-shortcuts-list li:hover {
  background: none;
  cursor: auto;
}
.reply-box .reply-top .reply-top-right .keyboard-shortcuts-container .keyboard-shortcuts-list .dropdown-list-item {
  display: flex;
  justify-content: space-between;
  color: #43537f;
  font-size: 12px;
  font-weight: bold;
}
.reply-box .reply-top .reply-top-right .keyboard-shortcuts-container .keyboard-shortcuts-list .dropdown-list-item .character-key {
  color: #838eab;
  font-size: 11px;
}
.reply-box .reply-top .reply-top-right .keyboard-shortcuts-container .keyboard-shortcuts-list .dropdown-list-item .character-key:hover {
  cursor: auto;
}
.reply-box .reply-top .reply-top-right .keyboard-shortcuts-container .character-key {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  background-color: #eef0f6;
  font-size: 14px;
  padding: 1px;
  border-radius: 5px;
  width: 23px;
  height: 23px;
  margin-left: 20px;
}
.reply-box .reply-top .reply-top-right .keyboard-shortcuts-container .character-key:hover {
  cursor: pointer;
}
.reply-box .reply-top .reply-top-right .expand-icon-container {
  display: none;
  height: 18px;
}
.reply-box .reply-top .reply-top-right .expand-icon-container ssi-icon {
  font-size: 18px;
  color: #838eab;
}
.reply-box .reply-top .reply-top-right .expand-icon-container ssi-icon:hover {
  cursor: pointer;
}
.reply-box .attachments-bar {
  display: flex;
  margin: 0 15px;
}
.reply-box .reply-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 1 auto;
  flex-wrap: wrap;
}
.reply-box .reply-actions .actions-right {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  margin: 5px 0 0 auto;
  flex-flow: row nowrap;
  list-style: none;
}
.reply-box .reply-actions .actions-right li,
.reply-box .reply-actions .actions-right a {
  margin-left: 10px;
}
.reply-box .reply-actions .actions-right li.send-reply,
.reply-box .reply-actions .actions-right a.send-reply {
  background-color: #eef0f6;
  border-radius: 50%;
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
}
@media (max-width: 885px) {
  .reply-box .reply-actions .actions-right li.send-reply,
.reply-box .reply-actions .actions-right a.send-reply {
    width: 50px;
    height: 50px;
  }
}
.reply-box .reply-actions .actions-right li.send-reply i,
.reply-box .reply-actions .actions-right a.send-reply i {
  color: #838eab;
}
.reply-box .reply-actions .actions-right li.send-reply button,
.reply-box .reply-actions .actions-right a.send-reply button {
  font-size: 20px;
}
@media (max-width: 885px) {
  .reply-box .reply-actions .actions-right li.send-reply button,
.reply-box .reply-actions .actions-right a.send-reply button {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
  }
}
.reply-box .reply-actions .actions-right li.active,
.reply-box .reply-actions .actions-right a.active {
  color: #14bae3;
  font-weight: bold;
}
.reply-box .reply-actions .actions-right .media-icon {
  width: 16px;
  height: auto;
  font-size: 16px;
}
.reply-box .reply-actions .actions-right {
  padding: 0;
}
.reply-box .reply-actions .actions-right .chars-remaining {
  color: #14bae3;
  font-size: 12px;
  font-weight: 900;
}
.reply-box .reply-actions .actions-right .chars-remaining.limit-exceeded {
  color: #f88c68;
}
.reply-box .reply-actions .actions-right button {
  border-radius: 50px;
  background-color: #14bae3;
  color: #fff;
  height: 40px;
  width: 40px;
}
.reply-box .reply-actions .actions-right button:disabled, .reply-box .reply-actions .actions-right button[disabled] {
  background-color: #eef0f6;
  color: #838eab;
}
.reply-box .reply-actions .actions-right i {
  font-size: 20px;
  color: #838eab;
}
.reply-box .reply-actions .actions-right i.has-attachments {
  color: #14bae3;
  position: relative;
}
.reply-box .reply-actions .actions-right i .attachments-count {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #14bae3;
  color: #fff;
  font-size: 10px;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
}
.reply-box .reply-actions .actions-right i:hover {
  color: #14bae3;
  cursor: pointer;
}
.reply-box .reply-actions .actions-left {
  display: flex;
  align-items: center;
}
.reply-box .reply-actions .actions-left > :not(:last-child) {
  margin-right: 10px;
}
.reply-box .reply-actions .actions-left button {
  background-color: #f2f4fc;
  color: #14bae3;
}
.reply-box .reply-actions .actions-left button.dm-reply-link-added-button i {
  font-size: 10px;
  margin-right: 0;
  margin-left: 6px;
}
.reply-box .reply-actions .actions-left .tags-input {
  position: relative;
  margin-left: 15px;
  font-size: 1rem;
}
.reply-box .reply-actions .actions-left .tags-input input {
  border: 0;
  border-bottom: 1px solid #b4bbce;
  color: #b4bbce;
  font-size: 12px;
  padding: 5px;
}
.reply-box .reply-actions .actions-left .tags-input .overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  z-index: 40;
  overflow-y: auto;
  backface-visibility: hidden;
  pointer-events: auto;
}
.reply-box .reply-actions .actions-left .tags-input .tags-menu {
  position: absolute;
  left: -15px;
  bottom: -5px;
  min-height: 160px;
  z-index: 50;
  display: inline-block;
  background: #fff;
  padding: 2.5em;
  border-radius: 2.5em;
  box-shadow: 0 0.1em 1.5em 0 rgba(231, 232, 238, 0.65);
  padding: 2.5em;
  margin: 0;
}
.reply-box.thin-border {
  border: 1px solid #c3c9d8;
  box-shadow: none;
}
.reply-box.no-border {
  border: none;
  box-shadow: none;
}
.reply-box .save-edit-reply {
  border-top: 1px solid #838eab;
  padding: 10px 10px 0;
  margin: 10px -10px 0 -10px;
  z-index: 2;
}
.reply-box .save-edit-reply .buttons-container {
  display: flex;
  justify-content: flex-end;
}
.reply-box .save-edit-reply button {
  width: fit-content;
  background-color: #f3f4fc;
  color: #838eab;
  font-size: 14px;
  font-weight: 900;
  padding: 12px 20px;
  border-radius: 10px;
}
.reply-box .save-edit-reply button:first-child {
  background-color: #fff;
  color: rgb(67, 83, 127);
  border: 1px solid #838eab;
  margin-right: 5px;
}
.reply-box .save-edit-reply button:last-child {
  color: #fff;
  background-color: #14bae3;
}
.reply-box .save-edit-reply button:last-child[disabled] {
  color: #838eab;
  background-color: #f4f4fa;
  cursor: not-allowed;
}