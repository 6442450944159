@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-engagement ssi-team-performance {
  font-size: 1rem;

  .section-tip-wrap {
    margin: 0 0 2em;
  }

  .section-tip {
    display: inline-flex;
    background-color: #fff;
    border-radius: 1.2em;
    padding: 1em;
    box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);

    &-icon {
      display: flex;
      flex: 0 0 3.6em;
      align-items: center;
      justify-content: center;
      height: 3.6em;
      width: 3.6em;
      background-color: $lgrey-background;
      border-radius: 50%;
      color: $primary-active;
      border: 0;
      outline: 0;

      &:active {
        background-color: darken($lgrey-background, 5%);
      }

      &:hover + p {
        display: inline-flex !important;
      }

      i {
        height: 1.4rem;
        font-size: 1.8em;
      }
    }

    p {
      display: none;
      align-items: center;
      margin: 0 0.8em;
      color: $lmode-body-text;
      font-weight: 700;
      text-rendering: geometricPrecision;
    }
  }
}
