@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-analytics {
  .network-filter {
    padding: 0;
    li {
      position: relative;
      letter-spacing: 1px;
      margin: 0 2em 0 0;
      display: inline-block;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 700;
      color: $lmode-body-text;
      cursor: pointer;
      &::before {
        content: '';
        transform: scaleX(0);
        visibility: hidden;
        position: absolute;
        top: 22px;
        height: 2px;
        width: 80%;
        left: 10%;
        background-color: $primary-active;
        transition: all 0.3s ease-in-out 0s;
      }
      &.active,
      &:hover {
        &:before {
          transform: scaleX(1);
          visibility: visible;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .section-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      color: $lmode-title-text;
      font-size: 20px;
      font-weight: 900;
      margin: 0;
    }

    .ssi.ssi-help {
      margin: 0 0 0 3px;
      font-size: 14px;
      vertical-align: text-top;
    }
  }

  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: auto;
    background-color: #fff;
    // background-color: $lgrey-background;
    img {
      width: 200px;
      height: 200px;
    }
    // i {
    //   font-size: 50px;
    //   color: $dmode-body-text;
    //   margin-bottom: 20px;
    // }
    h3 {
      font-size: 20px;
      font-weight: 900;
      color: $lmode-body-text;
    }

    p {
      font-weight: bold;
      color: $lmode-helper-text;
    }
  }

  @media print {
    ssi-report-sidebar {
      display: none;
    }

    ssi-report-header .report-header {
      position: relative;
      padding: 0;

      h1 {
        margin: 0;
      }

      .report-date-selector {
        display: none;
      }
    }

    .report-body {
      width: 100% !important;
      padding: 0 !important;
    }

    .section-tip-wrap {
      display: none;
    }
  }
}
