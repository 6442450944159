.angular-editor-toolbar #underline-textEditor,
.angular-editor-toolbar #strikeThrough-textEditor,
.angular-editor-toolbar #subscript-textEditor,
.angular-editor-toolbar #superscript-textEditor,
.angular-editor-toolbar .angular-editor-toolbar-set:nth-child(5),
.angular-editor-toolbar .angular-editor-toolbar-set:nth-child(7),
.angular-editor-toolbar .angular-editor-toolbar-set:nth-child(8),
.angular-editor-toolbar .angular-editor-toolbar-set:nth-child(9),
.angular-editor-toolbar .angular-editor-toolbar-set:nth-child(10),
.angular-editor-toolbar .angular-editor-toolbar-set:nth-child(11),
.angular-editor-toolbar .angular-editor-toolbar-set:nth-child(12) {
  display: none !important;
}
.angular-editor-toolbar i.fa:before {
  font-size: 16px;
  line-height: 20px;
}

.angular-editor-textarea {
  max-height: 300px !important;
}
.angular-editor-textarea h2,
.angular-editor-textarea h3,
.angular-editor-textarea h4,
.angular-editor-textarea h5 {
  margin-top: 20px;
}