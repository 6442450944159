@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../angularjs/common/sass/mixins';
@import '~/styles/colors';

$avatar-size: 4.6em;
$message-avatar-gap: 1.4em;
$message-width: 41em;
$dash-border: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAABQBAMAAADMwMmsAAAAFVBMVEVHcEzAzvKJpeq+zu+Jo+rBz/KJpOolcxqSAAAABXRSTlMA1+ovYfU0u0QAAAAlSURBVAjXY3BSYVBLAiEgAxcwFGYQDWQIDSUNAbUANeICA2QvAC2bG6Gl/VMHAAAAAElFTkSuQmCC';

ssi-inline-note,
ssi-inline-add-note {
  font-size: 1rem;
  display: block;
  width: 100%;

  .inline-note-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin: 0.4em 0 0;
    width: 100%;

    &-public {
      .inline-note {
        margin: 0 0 0 0.4em;
      }
    }
  }

  .inline-note-left {
    position: relative;
    display: inline-flex;
    flex: 0 0 $avatar-size;
    flex-direction: column;
  }

  .inline-notes-avatar-wrap {
    display: flex;
    flex: 0 0 $avatar-size;
    align-items: center;
    justify-content: center;
    position: relative;
    height: $avatar-size;
    width: $avatar-size;
    background-color: $secondary-blue;
    border-radius: 50%;

    &.inline-notes-avatar-wrap-pointer {
      cursor: pointer;
    }
  }

  .inline-notes-avatar-icon {
    font-size: 2em;
    color: #fff;
  }

  .inline-notes-avatar-badge {
    position: absolute;
    top: -0.1em;
    right: -0.3em;
    background-color: #668aea;
    height: 1.6em;
    width: 1.6em;
    color: #fff;
    font-size: 1em;
    box-shadow: 0 1em 3.5em rgba(231, 232, 238, 0.65);
    border-radius: 1.6em;
    text-align: center;
    font-weight: 900;
    text-rendering: geometricPrecision;
  }

  .inline-notes-avatar-guide {
    position: absolute;
    top: $avatar-size + 0.4em;
    left: 0;
    right: 0;
    margin: auto;
    display: block;
    height: calc(100% - #{$avatar-size + 0.4em});
    width: 0.2em;
    background-image: url($dash-border);
    background-size: 0.2em 4em;
    background-repeat: repeat;
  }

  .inline-note-right {
    display: flex;
    align-items: flex-start;
    flex: 1 0 auto;
    max-width: 100%;
  }

  .inline-note-wrap {
    position: relative;
    display: inline-flex;
    flex: 0 1 auto;
    flex-direction: column;
    width: 100%;
    max-width: $message-width;
    margin: 0 0 0.6em;
  }

  .inline-note {
    display: flex;
    flex: 0 1 auto;
    flex-wrap: wrap;
    flex-flow: column;
    overflow: hidden;
    margin: 0 0 0 $message-avatar-gap;
    background-color: #668aea;
    border: 0.2em solid #668aea;
    border-radius: 1em;
  }

  .inline-add-note {
    overflow: visible;
    border: 0;

    ssi-add-note {
      background: #fff;
      border: 0.2em solid #668aea;
      border-radius: 1em;

      .add-note-input-wrap {
        margin: 0;
      }

      .add-note-input-label,
      .notes-tag {
        height: 11rem;
        padding: 1.9rem 1.6rem 0 2.1rem;
        margin: 0;
        line-height: 2.4rem;
        font-size: 1.4em;
      }

      .notes-tag {
        width: auto;
        height: 11rem - 1.9rem;
        padding: 0;
        margin: 1.9rem 1.6rem 0 2.1rem;
        line-height: 2.2rem;
        font-size: 1.4em;
      }

      .add-note-input {
        font-size: inherit;
        line-height: inherit;
        color: $lmode-title-text;

        &::placeholder {
          color: $lmode-helper-text;
        }
      }

      .add-note-actions {
        margin: 1.3em 1.4em 1.3em 0;
      }
    }
  }

  .inline-note-body {
    padding: 1.9em 1.6em 0.8em 2.1em;
    line-height: 2.4em;

    a {
      color: #fff;
      text-decoration: underline;
    }

    p {
      font-size: 1.4em;
      line-height: inherit;
      color: #fff;
      font-weight: 700;
      text-rendering: geometricPrecision;
      word-break: break-word;
      hyphens: auto;

      @include ie-only {
        word-break: break-all;
      }
    }
  }

  .inline-note-footer {
    background-color: #e7edfd;
    padding: 0.8em 1.1em 1.1em 1.9em;
  }

  .inline-note-footer-add {
    display: flex;
    align-items: center;
    float: right;
    color: $lmode-body-text;
    cursor: pointer;

    p {
      display: inline-block;
      font-size: 1.2em;
      font-weight: 900;
      margin: 0;
      text-rendering: geometricPrecision;
    }
  }

  .inline-note-footer-delete {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    color: $lmode-body-text;
    height: 2em;
    width: 1.3em;
    cursor: pointer;
    transition: all 100ms ease;
    margin: 0 1em 0 0;

    p {
      display: none;
      margin: 0;
      color: #fff;
      font-size: 1.2em;
      font-weight: 900;
      white-space: nowrap;
      text-rendering: geometricPrecision;
    }

    .ssi {
      font-size: 1.2em;
    }

    &:hover,
    &:focus {
      color: #fff;
      background-color: $secondary-red;
      border-radius: 10em;
      width: 9.6em;
      padding: 0 0.8em;
      margin: 0 1em 0 -0.8em;

      p {
        display: inline-block;
      }

      & ~ .inline-note-footer-add p {
        display: none;
      }
    }
  }

  .inline-note-footer-meta {
    display: inline-block;
    color: $lmode-body-text;
    padding: 0;
    margin: 0;

    li {
      display: inline-flex;
      align-items: center;
      height: 2em;

      &:after {
        content: '|';
        font-weight: 900;
        display: inline-block;
        margin: 0 0.3em;
      }

      &:last-child:after {
        content: normal;
      }
    }

    p {
      display: inline-block;
      font-size: 1em;
      font-weight: 900;
      margin: 0;
      text-rendering: geometricPrecision;
      overflow-x: hidden;
    }

    .inline-note-footer-concat {
      p {
        max-width: 13em;

        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .inline-note-footer-add-icon {
    display: inline-flex;
    flex: 0 0 auto;
    height: 2em;
    width: 2em;
    margin: 0 0 0 0.5em;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 50%;

    .ssi {
      font-size: 0.8em;
    }
  }

  .inline-note-container-inverse {
    flex-direction: row-reverse;

    .inline-note-right {
      justify-content: flex-end;
    }

    .inline-note {
      margin: 0 1.4em 0 0;
    }
  }
}
