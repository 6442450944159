@charset "UTF-8";
.modal-chat-bot .panel-heading p {
  margin: 0;
}
.modal-chat-bot .list-group-item li {
  list-style-type: square;
}
.modal-chat-bot .list-group-item-subheading {
  margin: -10px -15px 15px -15px;
  height: 38px;
  padding: 10px 15px 10px 20px;
  border-bottom: 1px solid #ddd;
  background-color: #f9f9f9;
}
.modal-chat-bot .list-group-item-subheading:before {
  content: "•";
  display: inline-block;
  margin: 0 10px 0 0;
}
.modal-chat-bot .form-field {
  margin: 10px 0;
}
.modal-chat-bot .form-field select {
  width: 100%;
  padding: 6px 12px;
  height: 34px;
  border: 1px solid #ccc;
  border-radius: 4px;
}