@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../angularjs/common/sass/variables';
@import '../../../../../angularjs/common/sass/mixins';
@import '~/styles/colors';

ssi-post-errors {
  display: block;

  ul,
  li {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .post-error {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #fff1ed;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;

    i {
      color: #e34916;
      font-size: 14px;
      padding-right: 8px;
      flex-basis: 22px;
    }
    p {
      position: relative;
      flex-basis: 95%;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      text-align: left;
      color: $lmode-body-text;
      margin: 0;
      margin-right: auto;

      span,
      a {
        font-weight: 900;

        span {
          font-weight: 700;
        }
      }
    }

    .error-message {
      display: flex;
      align-items: baseline;
    }

    a {
      display: block;
      font-weight: 900;
      font-size: 14px;
      padding-left: 22px;
      white-space: nowrap;
      text-align: right;
      text-decoration: underline;
      color: $lmode-body-text;
    }
  }

  .duplicates-text-account-list {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: $lmode-body-text;
    padding-left: 22px;

    i {
      color: initial;
    }
  }
}
