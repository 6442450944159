ssi-top-tags .top-tags {
  background-color: white;
  border-radius: 12px;
  padding: 2em;
}
ssi-top-tags .tag-podiums {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 1em;
}
ssi-top-tags .tag-podiums .tag-bar {
  flex: 1 1 33.33%;
  margin-right: 2em;
}
ssi-top-tags .tag-podiums .tag-bar:last-child {
  margin-right: 0;
}
ssi-top-tags .tag-podiums .tag-bar:nth-child(1) ssi-medal-score .medal-score {
  background-color: #fbf3e2;
}
ssi-top-tags .tag-podiums .tag-bar:nth-child(1) ssi-medal-score .ssi-social-score {
  color: #f0b427 !important;
}
ssi-top-tags .tag-podiums .tag-bar:nth-child(2) ssi-medal-score .medal-score {
  background-color: #f4f4fa;
}
ssi-top-tags .tag-podiums .tag-bar:nth-child(2) ssi-medal-score .ssi-social-score {
  color: #b4bbce !important;
}
ssi-top-tags .tag-podiums .tag-bar:nth-child(3) ssi-medal-score .medal-score {
  background-color: #fff1ed;
}
ssi-top-tags .tag-podiums .tag-bar:nth-child(3) ssi-medal-score .ssi-social-score {
  color: #f88c68 !important;
}
ssi-top-tags .tag-list {
  display: flex;
  flex-flow: row wrap;
}
ssi-top-tags .tag-list .tag-bar {
  width: 25%;
  flex: 1 1 25%;
}
ssi-top-tags .tag-bar {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 20px;
}
ssi-top-tags .tag-bar.top {
  padding: 5px 20px 5px 5px;
  margin-bottom: 10px;
}
ssi-top-tags .tag-bar.top .tag-count {
  font-size: 18px;
}
ssi-top-tags .tag-bar ssi-medal-score {
  margin-right: 15px;
}
ssi-top-tags .tag-bar .progress-bar-wrapper {
  width: 100%;
  margin-right: 15px;
}
ssi-top-tags .tag-bar .progress-bar-wrapper span {
  color: #101525;
  font-size: 14px;
  font-weight: 900;
  line-height: 17px;
  word-break: break-all;
}
ssi-top-tags .tag-bar .progress-bar-wrapper .percent {
  color: #838eab;
  font-weight: 700;
  margin-left: 5px;
}
ssi-top-tags .tag-bar .tag-count {
  color: #101525;
  font-size: 14px;
  font-weight: 900;
  line-height: 22px;
}
ssi-top-tags .tag-bar .tag-position {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  color: #838eab;
  margin-right: 8px;
}