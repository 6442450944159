@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

$live-chat-statistics-default-colour: $lmode-body-text;

$unit: 2.5px;

$columnWidth: ($unit * 52);
$numberOfColumns: 5;

ssi-live-chat-team-statistics {
  align-items: center;
  color: $live-chat-statistics-default-colour;
  display: flex;
  flex-flow: column nowrap;

  * {
    display: flex;
  }

  button {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;

    &:focus {
      outline: revert;
    }

    &:hover {
      .icon {
        background-color: #d8dfe5;
        cursor: pointer;

        i {
          color: #fff;
        }
      }
    }
  }

  & > ul {
    align-items: flex-start;
    flex-flow: row wrap;
    justify-content: flex-start;
    list-style: none;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    width: $columnWidth * $numberOfColumns;

    & > li {
      align-items: flex-start;
      justify-content: center;
      margin: ($unit * 8) 0;
      min-height: ($unit * 52);
      padding: 0 ($unit * 2);
      width: $columnWidth;

      dl {
        align-items: center;
        flex-flow: column-reverse nowrap;
        justify-content: space-between;
        margin: 0;
        padding: 0;

        & > dt {
          font-size: 0.8em;
          font-weight: 600;
          margin: 0;
          padding: 0;
          text-transform: capitalize;
        }

        & > dd {
          font-weight: 400;
          margin: 0;
          padding: 0;

          &.icon {
            align-items: center;
            background-color: #f6f8f9;
            border-radius: 50%;
            font-size: 2.5em;
            justify-content: center;
            padding: ($unit * 8);

            & > i {
              color: $primary-blue;
            }
          }

          &.value {
            font-size: 1.6em;
            font-style: italic;
            font-weight: 300;
          }
        }
      }
    }
  }
}
