ssi-widget-chart-line {
  font-size: 1rem;
}
ssi-widget-chart-line .insights-widget {
  position: relative;
  height: 100%;
  width: 100%;
}
ssi-widget-chart-line .insights-chart-line {
  display: flex;
  align-items: center;
  justify-content: center;
}
ssi-widget-chart-line .insights-chart-line-wrapper {
  width: calc(100% - 6em);
  height: 89%;
}
ssi-widget-chart-line .no-results {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
}
ssi-widget-chart-line .no-results i {
  font-size: 40px;
  color: #b4bbce;
  margin-bottom: 20px;
}
ssi-widget-chart-line .no-results h4 {
  color: #43537f;
  font-weight: 900;
  font-size: 20px;
}
ssi-widget-chart-line .no-results p {
  color: #43537f;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}