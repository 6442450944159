@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../angularjs/common/sass/variables';
@import '../../../../../angularjs/common/sass/mixins';
@import '../../../../common/styles/colors.scss';

ssi-monitoring-streams {
  font-size: 1rem;

  $headerHeight: 9.4em;

  .monitoring-page {
    position: relative;
    background-color: #f8f9fd;
    min-height: calc(100vh - #{$navbar-height});
    overflow-x: hidden;

    &.monitoring-page-reporting {
      background-color: #f0f7f7;
    }
  }

  .monitoring-header {
    position: fixed;
    z-index: 99;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 3em;
    background-color: #fff;
    box-shadow: 0 0.5em 1em rgba(131, 142, 171, 0.2);
    height: $headerHeight;
    overflow: hidden;

    button {
      border-radius: 10px;
    }
  }

  .monitoring-header-left {
    padding: 0 1em;
    flex: 1 0 auto;
  }

  .monitoring-header-title {
    font-weight: 900;
    text-rendering: geometricPrecision;
    text-transform: uppercase;
    font-size: 1.4em;
    line-height: 1;
    letter-spacing: 0.1rem;
    color: #101525;
    margin: 0;
  }

  // orlo-button.monitoring-header-right-button {
  //   display: inline-block;
  //   height: 3.6em;

  //   button {
  //     font-size: 12px;
  //     line-height: 1;
  //     padding: 11px 8px 10px 11px;
  //     border-radius: 10px;
  //   }

  //   .leading-icon {
  //     font-size: 10px;
  //     line-height: 12px;
  //     margin: 0 3px 0 0;

  //     & + span {
  //       vertical-align: middle;
  //     }
  //   }
  // }

  .monitoring-body {
    padding-top: $headerHeight;
  }

  .monitoring-container {
    max-width: 140em;
    padding: 0 2em;
    margin: 4em auto;
  }

  .monitoring-message {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 1.2em;
    padding: 2em;
    margin: 2em auto;
    color: $lmode-body-text;

    @media (min-width: 768px) {
      padding: 4em 7em;
    }

    h2 {
      font-size: 2em;
      margin: 0 0 0.6em;
      font-weight: 900;
      text-rendering: geometricPrecision;
    }

    p {
      line-height: 2rem;
      font-weight: 700;
      margin: 0;
      text-rendering: geometricPrecision;
    }
  }

  .monitoring-message-left {
    flex: 0 1 60em;
    max-width: 60em;
    margin: 0.6em 0;
  }

  .monitoring-message-right {
    align-self: flex-end;

    img {
      display: none;
      width: 31em;
      height: 17.5em;
      margin: 0 0 -3em 2em;
      object-fit: cover;
      object-position: 0 0;

      @media (min-width: 768px) {
        display: block;
      }

      @media (min-width: 992px) {
        margin: -1.5em 5em -4em 2em;
      }
    }
  }

  .monitoring-actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 5em 0 2.6em;
    width: 100%;

    & > * {
      flex: 0 1 auto;
      margin: 0 0 1.6em;
    }

    .monitoring-actions-center {
      flex: 1 0 0;
      text-align: center;
    }

    .monitoring-actions-right {
      text-align: right;
    }
  }

  .monitoring-actions-search {
    .ssi {
      color: $lmode-body-text;
      font-size: 1.2em;
      margin: 0 0.6rem 0 0;
    }

    input {
      color: $lmode-body-text;
      font-size: 1.4em;
      font-weight: 700;
      text-rendering: geometricPrecision;
      border-width: 0;
      outline: 0;
      background-color: transparent;
      min-width: 190px;

      &::placeholder {
        color: $lmode-helper-text;
      }
    }
  }

  .monitoring-actions-filter {
    position: relative;
    display: inline-block;
    color: $lmode-body-text;
    font-size: 1em;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 900;
    text-rendering: geometricPrecision;
    padding: 0.3em 1em 0.6em;
    margin: 0 0.6em;
    cursor: pointer;

    &.monitoring-actions-filter-active:after {
      content: '';
      position: absolute;
      bottom: -0.4em;
      left: 0;
      right: 0;
      margin: auto;
      display: inline-block;
      height: 0.4em;
      width: 5em;
      border-radius: 10em;
      background-color: #12aca4;
    }
  }

  .monitoring-actions-sort {
    margin-top: 0.4em;
    text-align: right;
    color: $lmode-body-text;
    font-size: 1em;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 900;
    text-rendering: geometricPrecision;
    cursor: pointer;

    i {
      font-size: 1.4em;
      color: $lmode-helper-text;
    }
  }

  ssi-dropdown-select-2 {
    display: inline-block;
    min-width: 160px;
  }

  .monitoring-streams-grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1em 2.4em;

    &-item {
      flex: 1 0 0%;
      width: 100%;
      margin: 0 1em;
    }
  }

  @supports (display: grid) {
    .monitoring-streams-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(25.6em, 1fr));
      grid-gap: 2em;
      margin: 0;

      .monitoring-streams-grid-item {
        margin: 0;
      }
    }
  }

  @include ie-only {
    .monitoring-streams-grid {
      display: flex;
      margin: 0 -1em 2.4em;

      .monitoring-streams-grid-item {
        margin: 0 1em;
      }
    }
  }

  .insights-streams-tile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 0.1em solid #12aca4;
    background-color: #fff;
    border-radius: 1.2em;
    height: 36em;

    &-actionable {
      cursor: pointer;

      &:hover,
      &:focus {
        border-color: #14bae3;
      }
    }

    h3 {
      font-size: 1.4em;
      margin: 0.6rem 0 2rem;
      color: $lmode-body-text;
      font-weight: 900;
      text-rendering: geometricPrecision;
    }

    p {
      font-size: 1em;
      margin: -1.2em 0 2.6em;
      color: $lmode-body-text;
      font-weight: 900;
      text-rendering: geometricPrecision;
    }

    button {
      color: #fff;
      background-color: #12aca4;
      border-width: 0;
      border-radius: 1rem;
      font-size: 1.4em;
      font-weight: 900;
      text-rendering: geometricPrecision;
      line-height: 1;
      padding: 1.1rem 2.1rem;

      i {
        font-size: 1.2rem;
        margin: 0 0.5rem 0 0;
      }
    }

    .insights-streams-tile-add {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 1rem;
      height: 5rem;
      width: 5rem;
      padding: 0;
      color: #fff;
      border-radius: 50%;

      i {
        font-size: 1.2rem;
        margin: 0;
        color: inherit;
      }
    }

    &.insights-streams-tile-inbox {
      border-color: #89a4ea;

      button {
        background-color: #14bae3;
      }
    }
  }

  .monitoring-streams-grid-item-reporting {
    .stream-card {
      border-color: #12aca4;

      &:hover {
        background-color: #e7f4f4;
      }
    }
  }

  .monitoring-new-enhanced-body {
    border: 0.1em solid #12aca4;
    color: $lmode-body-text;
    background-color: #fff;
    border-radius: 1.2em;
    text-align: center;
    padding: 12em;

    h2 {
      font-size: 2em;
      font-weight: 900;
      text-rendering: geometricPrecision;
      margin: 0;
      line-height: 2;
    }

    p {
      font-size: 1.4em;
      font-weight: 700;
      text-rendering: geometricPrecision;
      margin: 0;
    }

    orlo-button.monitoring-new-enhanced-body-action {
      display: inline-block;
      margin: 24px 0 0;
      height: 3.6em;

      button {
        font-size: 14px;
        line-height: 1;
        padding: 9px 11px 9px 14px;
        border-radius: 10px;
      }

      .leading-icon {
        font-size: 12px;
        line-height: 12px;
        margin: 0 3px 0 0;

        & + span {
          vertical-align: middle;
        }
      }
    }
  }

  .monitoring-new-twitter-body {
    margin: 7.2em 0 2em;
    text-align: center;
    min-width: 25.6em;

    img {
      height: 10em;
      margin: 0 0 1.4em;
      pointer-events: none;
    }

    h2 {
      font-size: 2em;
      color: $lmode-body-text;
      font-weight: 900;
      text-rendering: geometricPrecision;
      margin: 0;
      line-height: 2;
    }

    p {
      font-size: 1.4em;
      color: $lmode-helper-text;
      font-weight: 700;
      text-rendering: geometricPrecision;
      margin: 0;
    }
  }

  .monitoring-new-twitter-action {
    display: inline-flex;
    margin: 2em 0 0;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #14bae3;
    color: #fff;
    height: 4.6em;
    width: 4.6em;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: darken(#14bae3, 3%);
    }
  }

  ssi-paginator {
    font-size: 1.4em;

    .flx-paginator {
      margin: 4em 0 0;

      button.navigate-btn,
      button.page-link,
      button.page-link.current {
        background-color: transparent;
      }
    }
  }

  .listening-container {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 1.2em;
    padding: 2em;
    margin: 2em auto;
    min-height: 300px;

    &.blank-view {
      justify-content: center;
      padding: 10em 2em;
    }

    .confirm-action-tooltip-window {
      border-radius: 12px;

      .tooltip-actions {
        width: 400px;
      }
    }
  }

  .table-container {
    width: 100%;
    position: relative;

    > button {
      position: absolute;
      top: 0;
      right: 0;
      appearance: none;
      outline: none;
      text-decoration: underline;
      border: none;
      font-weight: 900;
      font-size: 10px;
      color: $lmode-body-text;
      background-color: transparent;
      text-transform: uppercase;
      letter-spacing: 1px;

      i {
        margin-left: 7px;
      }
    }

    .cell {
      background-color: transparent !important;
    }

    .table-cell {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 5px 0 10px;
      color: $lmode-body-text;
      font-size: 12px;
      font-weight: 900;
      line-height: 14px;
      border-top: 2px solid #e0e7f4;

      &.row-odd {
        background-color: #f1f7f7;
      }

      &.light {
        font-weight: 700;
      }
    }

    .actions-cell {
      justify-content: flex-end;

      > button {
        border: 1px solid $lmode-helper-text;
        padding: 10px;
        border-radius: 10px;
        outline: none;
        appearance: none;

        &:first-child {
          margin-right: 5px;
          color: $lmode-helper-text;
          background-color: #fff;
          border: 1px solid $lmode-helper-text;
        }

        &:last-child {
          color: $lmode-body-text;
          background-color: #fff1ed;
          border: 1px solid #e34916;

          i {
            color: #e34916;
          }
        }
      }
    }
  }

  .hashtag-container {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 1.2em;
    padding: 2em;
    margin: 2em auto;
    min-height: 300px;

    &.blank-view {
      justify-content: center;
      padding: 10em 2em;
    }

    .call-to-action span i {
      background-color: #0d827c;
      font-size: 14px;
    }
  }

  .call-to-action {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 220px;

    > i {
      font-size: 48px;
      color: $dmode-body-text;
      margin-bottom: 10px;
    }

    h2 {
      color: $lmode-body-text;
      font-weight: 900;
      font-size: 20px;
    }

    p {
      font-size: 14px;
      font-weight: bold;
      color: $lmode-body-text;
      text-align: center;
    }

    span {
      cursor: pointer;
      margin-top: 30px;

      i {
        color: #fff;
        padding: 18px;
        border-radius: 50%;
        background-color: #4eaaa4;
      }
    }

    a {
      color: #fff;
      font-weight: 900;
      font-size: 14px;
      background-color: $primary-active;
      padding: 8px 20px;
      border-radius: 10px;
      margin-top: 20px;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
