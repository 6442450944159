@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-advertising-objectives {
  flex: 1 1 auto;
  padding: 0 15px;
}

.advert-table-objectives-body-wrap {
  flex: 1 1 auto;
  width: auto;
  margin: 0 -15px;
  padding: 0 15px;
  background-color: $dgrey-background;
  overflow: hidden;
}

.advert-table-objectives-body-wrap.active {
  background-color: #eff2f4;
}

.advert-table-head-objectives {
  display: flex;
  padding: 0 20px 0 20px;
  margin: 0;
  background-color: #40454f;
  justify-content: flex-end;

  .advert-table-head-label {
    border-bottom-width: 5px;
    border-top-width: 5px;
    border-left-width: 0;
    border-right-width: 0;
    border-color: transparent;
    border-style: solid;
    cursor: pointer;
  }
}

.advert-table-head-objectives.active {
  .advert-table-head-label.active {
    border-bottom-color: #f2ca27;
  }
}

.advert-table-col-objectives {
  display: inline-flex;
  flex: 1 0 9%;
  max-width: 9%;
  padding: 35px 0;
  align-items: center;
  justify-content: center;

  &:first-child {
    flex: 1 0 12%;
    max-width: 12%;
    min-width: 185px;
    justify-content: center;
    margin: 0 auto 0 0;
  }
}

.objectives {
  display: flex;
  flex-wrap: wrap;
  padding: 50px 0 40px;
  margin: 0 -5px;
  list-style: none;
}

.objective-wrap {
  display: inline-block;
  flex: 0 0 calc(50% - 10px);
  width: calc(50% - 10px);
  margin: 0 5px 10px;
}

.objectives-none {
  max-width: 280px;
  margin: 50px auto 100px;
  color: #b3c5ce;
  text-align: center;
}

.objectives-none-icon {
  font-size: 68px;
  margin: 0 0 30px;
}

.objectives-none-title {
  font-size: 18px;
  margin: 0 0 20px;
  font-weight: 500;
}

.objectives-none-meta {
  margin: 0 0 40px;
  font-weight: 500;
}

.objectives-none-meta-list {
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    padding: 0 10px;
    color: $primary-active;
    border-right: 1px solid #b3c5ce;
    line-height: 14px;
    vertical-align: middle;
    font-weight: 500;

    &:last-child {
      border-right-width: 0;
    }
  }
}

@media (min-width: 1200px) {
  .advert-table-objectives-body {
    margin: 0 50px;
  }
}
