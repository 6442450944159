ssi-analytics ssi-disapprovals ssi-build .marketing-analytics-build-container {
  display: flex;
  flex-direction: column;
}
ssi-analytics ssi-disapprovals ssi-build .marketing-analytics-build-container .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  padding: 0 60px;
  background-color: #f4f4fa;
  box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.25);
  z-index: 1;
}
ssi-analytics ssi-disapprovals ssi-build .marketing-analytics-build-container .head h3 {
  font-size: 14px;
  color: #101525;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
}
ssi-analytics ssi-disapprovals ssi-build .marketing-analytics-build-container .head h2 {
  color: #101525;
  font-weight: 900;
  font-size: 20px;
}
ssi-analytics ssi-disapprovals ssi-build .marketing-analytics-build-container .body {
  flex-basis: calc(100vh - 168px);
  height: calc(100vh - 168px);
  padding: 0 20px 20px 20px;
  background-color: #f4f4fa;
  overflow: auto;
}
ssi-analytics ssi-disapprovals ssi-build .marketing-analytics-build-container .body .body-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #fff;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}