@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

.company-lockdown {
  background-color: d;
  padding: 2em 4em;
  .container {
    position: relative;
    background-color: $dmode-background;
    border-radius: 25px;
    padding: 0 0 6em 0;
  }
  .form {
    text-align: center;
    .text-input {
      margin: 0 auto 1em auto;
    }
    label {
      margin-bottom: 1em;
      color: $dmode-body-text;
    }
  }

  .header {
    padding: 3em 4em;
    margin-bottom: 5em;
    background-color: $dmode-accent;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    color: $dmode-body-text;
    h2 {
      text-transform: uppercase;
      color: #fff;
      letter-spacing: 1px;
    }
  }
  .info-list {
    margin: 0 4em;
    p {
      color: $secondary-red;
      text-transform: uppercase;
    }
    ul {
      list-style: none;
      padding: 0;
      li {
        color: #fff;
        margin-bottom: 1em;
      }
    }
    span {
      margin-left: 1em;
    }
  }
  .unlock {
    color: $secondary-red;
    text-align: center;
  }
}
