@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

$preview-height: 10em;
$preview-body-vertical-margin: 1em;

ssi-message-link-preview {
  font-size: 1rem;

  .message-link-preview {
    display: flex;
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
    background-color: #fcfdfe;
    overflow: hidden;

    &.message-link-preview-self-penned {
      background-color: #d1dcf9;
    }
  }

  .message-link-preview-thumb {
    flex: 0 0 $preview-height;
    width: $preview-height;
    height: $preview-height;
    overflow: hidden;
    min-width: 5em;
    background-color: $lmode-helper-text;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .message-link-preview-body {
    max-height: $preview-height - $preview-body-vertical-margin;
    margin: $preview-body-vertical-margin 2em;
    align-self: center;
    overflow: auto;
    max-width: 30em;
  }

  .message-link-preview-title {
    display: block;
    display: -webkit-box;
    font-weight: 900;
    text-decoration: underline;
    color: $lmode-title-text;
    font-size: 1.2em;
    line-height: 1.6em;
    margin-bottom: 0.4em;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    text-rendering: geometricPrecision;
    overflow: hidden;
  }

  .message-link-preview-subtitle {
    display: inline-block;
    display: -webkit-box;
    color: $lmode-title-text;
    font-weight: 600;
    font-size: 1.2em;
    text-rendering: geometricPrecision;
    margin: 0;
    -webkit-line-clamp: 1;
    overflow: hidden;
    -webkit-box-orient: vertical;
    max-height: 1.4rem;
  }
}
