ssi-campaigns-select-create .campaign-dd-and-button-container {
  display: flex;
  align-items: center;
  width: 100%;
}
ssi-campaigns-select-create .campaign-dd-and-button-container ssi-dropdown-select-2 {
  width: 100%;
}
ssi-campaigns-select-create .campaign-dd-and-button-container ssi-dropdown-select-2 .dd-head {
  width: 100%;
  padding: 0 35px 0 5px;
}
ssi-campaigns-select-create .campaign-dd-and-button-container ssi-dropdown-select-2 .dd-body {
  min-width: 250px;
  width: auto;
}
ssi-campaigns-select-create .campaign-dd-and-button-container ssi-dropdown-select-2 .dd-body > ul {
  max-height: 465px !important;
}
ssi-campaigns-select-create .campaign-dd-and-button-container.no-selected-options ssi-dropdown-select-2 .dd-head {
  padding: 0 30px 0 30px;
}
ssi-campaigns-select-create .campaign-dd-and-button-container .create-campaign-button {
  height: 46px;
  border: 1px solid #c3c9d8;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
ssi-campaigns-select-create .campaign-dd-and-button-container .create-campaign-button:disabled {
  background-color: #c3c9d8;
}
ssi-campaigns-select-create .campaign-dd-and-button-container .select-placeholder {
  font-size: 14px;
  font-weight: 700;
  color: #6a759a;
  text-wrap: nowrap;
}
ssi-campaigns-select-create .campaign-dd-and-button-container .selected-options-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
ssi-campaigns-select-create .campaign-dd-and-button-container .selected-options-container .chips-container {
  display: flex;
  align-items: center;
  overflow: hidden;
}
ssi-campaigns-select-create .campaign-dd-and-button-container .selected-options-container .chips-container .chip {
  height: 36px;
  background-color: #f4f4fa;
  border-radius: 8px;
  padding: 0 10px;
  white-space: nowrap;
  margin-right: 5px;
  display: flex;
  align-items: center;
}
ssi-campaigns-select-create .campaign-dd-and-button-container .selected-options-container .chips-container .chip span {
  font-size: 14px;
  color: #101525;
  font-weight: 900;
  margin: 0;
  margin-left: 10px;
}
ssi-campaigns-select-create .campaign-dd-and-button-container .selected-options-container .chips-container .chip i {
  font-size: 8px;
  color: #43537f;
  font-weight: 500;
  margin-left: 10px;
  cursor: pointer;
}
ssi-campaigns-select-create .campaign-dd-and-button-container .selected-options-container .chips-container .chip i:first-child {
  font-size: 12px;
  color: #838eab;
}
ssi-campaigns-select-create .campaign-dd-and-button-container .chevron.expand-campaign-icon {
  font-size: 12px;
  font-weight: 900;
  color: #101525;
  transform: rotate(-90deg);
}
ssi-campaigns-select-create .campaign-dd-and-button-container .chevron.expand-campaign-icon.down {
  transform: rotate(0deg);
}
ssi-campaigns-select-create .campaign-dd-and-button-container ssi-list-select2 .filter-container {
  padding-left: 32px;
}
ssi-campaigns-select-create .campaign-dd-and-button-container.can-manage-campaigns ssi-dropdown-select-2 {
  width: 100%;
}
ssi-campaigns-select-create .campaign-dd-and-button-container.can-manage-campaigns ssi-dropdown-select-2 .dd-head {
  width: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}