ssi-paginator {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
ssi-paginator * {
  box-sizing: border-box;
}
ssi-paginator .flx-paginator {
  flex: 0 0 auto;
  margin: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
ssi-paginator .flx-paginator .dots {
  opacity: 0.5;
  user-select: none;
  font-size: 14px;
}
ssi-paginator .flx-paginator button {
  flex: 0 0 auto;
  height: 1.8rem;
  min-width: 1.8rem;
  background: transparent;
  border: 0;
  cursor: pointer;
}
ssi-paginator .flx-paginator button i {
  color: #40337f;
}
ssi-paginator .flx-paginator button.page-link {
  display: flex;
  align-items: center;
  font-weight: 900;
  font-size: 14px;
  color: #43537f;
  margin: 0 6px;
  position: relative;
}
ssi-paginator .flx-paginator button.page-link::before {
  content: "";
  transform: scaleX(0);
  visibility: hidden;
  position: absolute;
  top: 22px;
  height: 3px;
  width: 98%;
  left: 1%;
  background-color: #14bae3;
  border-radius: 10px;
  transition: all 0.3s ease-in-out 0s;
}
ssi-paginator .flx-paginator button.page-link.active {
  font-weight: 900;
  color: #14bae3;
}
ssi-paginator .flx-paginator button.page-link.active:before, ssi-paginator .flx-paginator button.page-link:hover:before {
  transform: scaleX(1);
  visibility: visible;
}
ssi-paginator .flx-paginator button.page-link:disabled {
  cursor: default;
}
ssi-paginator .flx-paginator button.navigate-btn {
  display: flex;
  align-items: center;
  color: #14bae3;
  text-align: center;
  border: none;
  border-radius: 3px;
  margin: 0;
}
ssi-paginator .flx-paginator button.navigate-btn:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
ssi-paginator .flx-paginator button.navigate-btn.left {
  margin-right: 1em;
}
ssi-paginator .flx-paginator button.navigate-btn.right {
  margin-left: 1rem;
}
ssi-paginator .flx-paginator button.navigate-btn i {
  color: #14bae3;
  font-weight: 900;
  font-size: 12px;
}
ssi-paginator .flx-paginator-settings {
  flex: 0 0 auto;
  margin: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
ssi-paginator .flx-paginator-settings select {
  height: 1.8rem;
  border: 1px solid #40337f;
  border-radius: 3px;
  margin-right: 1rem;
  background: transparent;
  cursor: pointer;
}
ssi-paginator .flx-paginator-settings .span {
  flex: 0 0 auto;
  height: 1.8rem;
  margin: 0 0.2rem 0 0.2rem;
  border: 0;
}