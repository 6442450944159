ssi-publisher-schedule-date-picker {
  position: relative;
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  flex-direction: column;
}
@media (max-width: 885px) {
  ssi-publisher-schedule-date-picker {
    flex: auto;
    flex-wrap: wrap;
  }
}
ssi-publisher-schedule-date-picker .publisher-schedule-date-picker-outer {
  width: 290px;
  padding: 0 6px;
  margin: 10px auto 0;
}
ssi-publisher-schedule-date-picker .publisher-schedule-date-picker-inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-height: 300px;
}
ssi-publisher-schedule-date-picker .publisher-schedule-date-picker-title-wrap {
  max-height: 28px;
  margin: 0 6px 8px;
}
ssi-publisher-schedule-date-picker .publisher-schedule-date-picker-title-nav button {
  border: 0;
  background-color: transparent;
}
ssi-publisher-schedule-date-picker .publisher-schedule-date-picker-title-nav buttonfocus {
  outline: revert;
}
ssi-publisher-schedule-date-picker .publisher-schedule-date-picker-title-nav i {
  font-size: 12px;
  color: #14bae3;
  line-height: 28px;
}
ssi-publisher-schedule-date-picker .publisher-schedule-date-picker-title {
  text-align: center;
}
ssi-publisher-schedule-date-picker .publisher-schedule-date-picker-title p {
  font-size: 12px;
  font-weight: 900;
  color: #43537f;
  margin: 0;
  line-height: 28px;
  text-rendering: geometricPrecision;
}
ssi-publisher-schedule-date-picker .cal-month-view {
  font-size: 12px;
  color: #0e121b;
  display: block;
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 700;
  text-rendering: geometricPrecision;
}
ssi-publisher-schedule-date-picker .cal-month-view .cal-header {
  margin-bottom: 7px;
  font-weight: inherit;
}
ssi-publisher-schedule-date-picker .cal-month-view .cal-header:hover, ssi-publisher-schedule-date-picker .cal-month-view .cal-header:focus {
  background-color: transparent;
}
ssi-publisher-schedule-date-picker .cal-month-view .cal-header .cal-cell:hover, ssi-publisher-schedule-date-picker .cal-month-view .cal-header .cal-cell:focus {
  background-color: transparent;
}
ssi-publisher-schedule-date-picker .cal-month-view .cal-days .cal-day-cell,
ssi-publisher-schedule-date-picker .cal-month-view .cal-days .cal-cell-top {
  background-color: transparent;
  border: 0;
  min-height: initial;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  ssi-publisher-schedule-date-picker .cal-month-view .cal-days .cal-day-cell,
ssi-publisher-schedule-date-picker .cal-month-view .cal-days .cal-cell-top {
    min-height: 35px;
  }
}
ssi-publisher-schedule-date-picker .cal-month-view .cal-days .cal-day-cell .cal-day-number,
ssi-publisher-schedule-date-picker .cal-month-view .cal-days .cal-day-number {
  display: block;
  float: none;
  text-align: center;
  margin: 0px -2px;
  padding: 5px 0px;
  font-size: 12px;
  line-height: 18px;
  color: inherit;
  opacity: 1;
  font-weight: inherit;
  border-radius: 50%;
  border: 1px solid transparent;
  cursor: pointer;
  text-rendering: geometricPrecision;
}
ssi-publisher-schedule-date-picker .cal-month-view .cal-days .cal-cell {
  font-weight: 700;
  margin: 1px;
}
ssi-publisher-schedule-date-picker .cal-month-view .cal-days .cal-cell:first-child {
  margin-left: 0;
}
ssi-publisher-schedule-date-picker .cal-month-view .cal-days .cal-cell:last-child {
  margin-right: 0;
}
ssi-publisher-schedule-date-picker .cal-month-view .cal-days .cal-cell.cal-future:hover .cal-day-number, ssi-publisher-schedule-date-picker .cal-month-view .cal-days .cal-cell.cal-future:focus .cal-day-number {
  background-color: #eceef7;
}
ssi-publisher-schedule-date-picker .cal-month-view .cal-days .cal-cell.cal-today .cal-day-number {
  border: 1px solid #43537f;
  color: #0e121b;
}
ssi-publisher-schedule-date-picker .cal-month-view .cal-days .cal-cell.cal-weekend .cal-day-number {
  color: #0e121b;
}
ssi-publisher-schedule-date-picker .cal-month-view .cal-days .cal-cell.disabled .cal-day-number,
ssi-publisher-schedule-date-picker .cal-month-view .cal-days .cal-cell.cal-out-month .cal-day-number {
  opacity: 1;
  color: #525c7e;
}
ssi-publisher-schedule-date-picker .cal-month-view .cal-days .cal-cell.disabled:hover .cal-day-number, ssi-publisher-schedule-date-picker .cal-month-view .cal-days .cal-cell.disabled:focus .cal-day-number,
ssi-publisher-schedule-date-picker .cal-month-view .cal-days .cal-cell.cal-out-month:hover .cal-day-number,
ssi-publisher-schedule-date-picker .cal-month-view .cal-days .cal-cell.cal-out-month:focus .cal-day-number {
  background-color: #f6f7fd;
}
ssi-publisher-schedule-date-picker .cal-month-view .cal-days .cal-cell.scheduled .cal-day-number {
  background-color: #a4be13;
  color: #fff;
}
ssi-publisher-schedule-date-picker .cal-month-view .cal-days .cal-cell.scheduled:hover .cal-day-number, ssi-publisher-schedule-date-picker .cal-month-view .cal-days .cal-cell.scheduled:focus .cal-day-number {
  background-color: #a4be13;
}
ssi-publisher-schedule-date-picker .cal-month-view .cal-days .cal-cell.active .cal-day-number {
  background-color: #14bae3;
  color: #fff;
}
ssi-publisher-schedule-date-picker .cal-month-view .cal-days .cal-cell.active:hover .cal-day-number, ssi-publisher-schedule-date-picker .cal-month-view .cal-days .cal-cell.active:focus .cal-day-number {
  background-color: #14bae3;
}
ssi-publisher-schedule-date-picker .cal-month-view .cal-days,
ssi-publisher-schedule-date-picker .cal-month-view .cal-days .cal-cell-row,
ssi-publisher-schedule-date-picker .cal-month-view .cal-days .cal-cell {
  border: 0;
}
ssi-publisher-schedule-date-picker .cal-month-view .cal-days:hover, ssi-publisher-schedule-date-picker .cal-month-view .cal-days:focus,
ssi-publisher-schedule-date-picker .cal-month-view .cal-days .cal-cell-row:hover,
ssi-publisher-schedule-date-picker .cal-month-view .cal-days .cal-cell-row:focus,
ssi-publisher-schedule-date-picker .cal-month-view .cal-days .cal-cell:hover,
ssi-publisher-schedule-date-picker .cal-month-view .cal-days .cal-cell:focus {
  background-color: transparent;
}
ssi-publisher-schedule-date-picker .select-schedule-time-btn {
  position: relative;
  margin-top: -6px;
}
ssi-publisher-schedule-date-picker .select-schedule-time-btn i {
  font-size: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 5px;
  transform: rotate(270deg);
}
ssi-publisher-schedule-date-picker .jump-to-today-btn {
  margin-bottom: 10px;
  margin-right: 8px;
  width: 278px;
  font-size: 12px;
}