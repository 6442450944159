ssi-theme-detectors {
  background-color: #f4f4fa;
}
ssi-theme-detectors .theme-detectors-container {
  padding: 60px 20px;
  max-width: 1400px;
  margin: auto;
}
ssi-theme-detectors .theme-detectors-container .section-container {
  padding: 30px 30px 60px;
  background-color: #fff;
  border-radius: 10px;
}
ssi-theme-detectors .theme-detectors-container .section-delimiter {
  width: 100%;
  height: 2px;
  background-color: #fff;
  margin-top: 40px;
  margin-bottom: 40px;
}