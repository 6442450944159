@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';
@import '../../../../../angularjs/common/sass/variables';
@import '../../../../../angularjs/common/sass/mixins';
@import '../publisher.variables.scss';
@import '../publisher.mixins.scss';
@import '~/styles/colors';

$ssi-publisher-schedule-responsive-width: 885px;

ssi-publisher-schedule-action-buttons {
  .publisher-action-buttons-container {
    @media (min-width: $ssi-publisher-schedule-responsive-width) {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;

      .action-button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $primary-active;
        color: #fff;
        border-radius: 50%;
        height: 46px;
        width: 46px;
        margin-right: 25px;
        border: 0;
        @include publisher-text();

        &:last-child {
          margin-right: 0;
        }

        &:focus {
          outline: revert;
        }

        &:hover,
        &:focus {
          color: #fff;
        }

        &.disabled {
          background-color: transparent;
          color: #b4bbce;
          cursor: not-allowed;

          &:hover,
          &:focus {
            background-color: $lmgrey-background;
          }

          i {
            font-size: 28px;
            color: inherit;
          }
        }

        i {
          font-size: 28px;
          color: inherit;

          &.ssi-send-icon {
            font-size: 24px;
          }
        }

        &:disabled,
        &:disabled:hover {
          background-color: $publisher-action-disabled-bg;
          border-color: $publisher-action-disabled-bg;
          color: $publisher-action-disabled-color;
        }
      }
    }

    @media (max-width: $ssi-publisher-schedule-responsive-width) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      margin-top: 20px;

      .action-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-basis: 50%;
        height: 154px;
        border-radius: 20px;
        border: 0;
        background: #14bae3;
        color: #fff;

        i {
          font-size: 28px;
          margin-bottom: 10px;
        }
        span {
          font-size: 14px;
          font-weight: 900;
        }

        &.preview-btn {
          margin-right: 10px;
          margin-left: 5px;
          background: #f3fcfe;
          border: 1px solid #14bae3;
          color: #14bae3;

          &:disabled,
          &.disabled {
            color: #b4bbce;
            background-color: #f7f8fc;
            border: 0;
          }
        }

        &.publish-btn {
          margin-right: 5px;

          &:disabled,
          &.disabled {
            color: #b4bbce;
            background-color: #f7f8fc;
            border: 0;
          }
        }
      }
    }
  }
}
