@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-campaign-box {
  $campaign-icon-dimensions: 50px;

  .campaign-select {
    position: absolute;
    width: 100%;
    top: 0;
    height: 100%;
    z-index: 1;
    cursor: pointer;
  }

  .campaign-settings {
    width: 100%;
    position: absolute;
    top: 77px;
    bottom: 0;
    background-color: #dfe5e8;
    z-index: 1;

    .campaign-action {
      top: -24px;
      position: relative;
    }
  }

  .campaign-container {
    display: flex;
    position: absolute;
    flex-direction: column;
    height: calc(100% - 25px);
    width: 100%;
  }

  .campaign-container-ad,
  .campaign-settings-ad {
    .settings-trigger {
      &:hover,
      &:focus {
        background: $lmode-body-text;
      }
    }

    .campaign-subtitle-icon.campaign-subtitle-icon-primary {
      color: $lmode-body-text;
    }
  }

  .campaign-section {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: center;
  }

  .campaign-section-light {
    background-color: #f9fafb;
  }

  .campaign-header {
    position: relative;
    margin: 10px 15px;

    &:before {
      content: '';
      position: absolute;
      display: block;
      border-bottom: 2px solid #f3f6f7;
      margin-top: 24px;
      width: 100%;
    }
  }

  .campaign-action {
    position: relative;
    display: inline-flex;
    border: solid 2px #f3f6f7;
    color: $lmode-body-text;
    font-weight: 700;
    font-size: 22px;
    border-radius: 50%;
    width: $campaign-icon-dimensions;
    height: $campaign-icon-dimensions;
    background-color: white;
    justify-content: center;
    align-items: center;
    z-index: 2;

    &.settings-trigger {
      cursor: pointer;
    }

    &.active {
      background-color: $primary-active;
      color: #fff;
    }
  }

  .settings-trigger {
    position: relative;
    z-index: 1;

    &:hover {
      color: white;
      background-color: $primary-blue;
    }
  }

  .campaign-setting-button {
    width: 200px;
    border-radius: 20px;
    text-align: right;
  }

  .campaign-children {
    padding: 1em;
    p {
      font-size: 40px;
    }
  }

  .campaign-info {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .campaign-title {
    color: $lmode-body-text;
    font-weight: 700;
    text-align: center;
    display: block;
    position: relative;
    z-index: 1;
    cursor: pointer;
  }

  .campaign-title-meta {
    display: inline-block;
    font-style: normal;

    &:before {
      content: '|';
      margin: 0 8px;
    }
  }

  .campaign-subtitle {
    font-size: 14px;
    color: $lmode-helper-text;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 1px;

    p {
      display: inline-block;
      margin: 0;
      color: $lmode-helper-text;
      font-weight: 900;

      &:after {
        content: '|';
        margin: 0 8px;
        vertical-align: top;
      }
    }
  }

  .campaign-subtitle-icon {
    font-size: 28px;
    font-weight: 500;
    margin: 0 0 3px;
  }

  .campaign-subtitle-icon-primary {
    color: $primary-active;
  }

  .campaign-subtitle-icon-warning {
    color: #dfc045;
  }

  .campaign-stats {
    margin: 0 auto;
    padding: 0;
  }

  .campaign-stat {
    &.campaign-stat-clear {
      display: inline-block;
      background-color: transparent;
      color: $lmode-body-text;
      font-weight: 700;
      font-size: 12px;
      padding: 0;
      margin: 0;

      &:after {
        content: '|';
        margin: 0 8px;
      }

      &:last-child:after {
        content: normal;
      }

      p {
        display: inline-block;
        margin: 0 5px 0 0;
      }
    }
  }

  .campaign-editor {
    position: absolute;
    left: 20px;
    right: 20px;
    top: 50%;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
    transform: (translateY(-50%));
    z-index: 1;

    select,
    input {
      width: 100%;
      background-color: #f6f8f9;
      border: none;
      border-radius: 20px;
      padding: 6px 14px;
    }
  }

  .select-wrapper {
    width: 100%;
    display: inline-block;
    padding: 6px 16px 6px 6px;
    background-color: #f6f8f9;
    border-radius: 20px;
  }
}

.tooltip-campaign {
  border-radius: 15px;
  padding: 0 10px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;

  * {
    font-size: inherit;
    font-weight: inherit;
    font-style: inherit;
  }

  .tooltip-body-title {
    font-weight: 600;
    font-size: 12px;
  }

  .tooltip-body-contents ul {
    width: 100%;
    padding: 0;
    margin: 0;
    text-align: left;
  }
}
