@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-sentiment-card {
  display: flex;
  justify-content: space-between;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.25);
  padding: 2em;
  min-width: 12em;
  page-break-inside: avoid;

  @media print {
    margin: 10px 0 0;
    border: 1px solid black;
  }

  ssi-big-number {
    &.message-count {
      font-size: 2.8em;
      color: $lmode-title-text;
      font-weight: 900;
    }
  }
  span {
    display: block;
    text-transform: lowercase;
    font-size: 1.4em;
    font-weight: 900;
    line-height: 0;
    color: $lmode-title-text;
  }

  ssi-stat-change {
    display: flex;
  }

  .sentiment-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .upper {
      margin-bottom: 3em;
    }
  }

  .sentiment-right {
    i {
      font-size: 2.4em;
      border-radius: 50%;
      padding: 0.25em;
    }
  }
}
