@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';
@import '../../../../../../angularjs/common/sass/include-media';
@import '../../../../../../angularjs/common/sass/mixins.scss';

ssi-analytics ssi-disapprovals ssi-report {
  .disapprovals-analytics-report-container {
    width: 100%;
    height: 100%;

    $headerHeight: 94px;
    $sidebarWidth: 228px;

    .report-body {
      width: 100%;
      height: 100%;
      padding-top: $headerHeight + 40px;
      padding-left: $sidebarWidth + 40px;
      padding-right: 40px;
      min-height: calc(100vh - 68px);

      .account-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .account-list-item {
          display: flex;
          align-items: center;
          background-color: #ebebf4;
          border-radius: 10px;
          padding: 10px;
          margin: 5px;
          i {
            color: $lmode-helper-text;
          }
          span {
            color: $lmode-body-text;
            font-size: 12px;
            font-weight: 900;
            line-height: 14px;
            margin-left: 10px;
          }
        }
      }

      .section-delimiter {
        width: 100%;
        height: 2px;
        background-color: #fff;
        margin-top: 40px;
        margin-bottom: 40px;
      }

      .section-head,
      .section-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
        h2,
        h3 {
          color: $lmode-title-text;
          font-size: 20px;
          font-weight: 900;
        }
      }
    }

    .report-body-loading {
      width: 100%;
      height: 100%;
      height: calc(100vh - 68px);
      padding-top: $headerHeight;
      padding-left: $sidebarWidth;
    }

    .selected-date-ranges {
      border-radius: 10px;
      background-color: #ebebf4;
      padding: 10px;

      span {
        margin-right: 7px;
      }

      .current {
        color: $lmode-title-text;
        font-size: 14px;
        font-weight: 900;
      }
      .previous {
        color: $lmode-title-text;
        font-size: 12px;
        font-style: italic;
        font-weight: 700;
      }
    }

    .section-title {
      color: #101525;
      font-weight: 900;
      text-rendering: geometricPrecision;
      font-size: 20px;
      margin: 0 0 40px;
    }
  }

  .facts-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 0 20px;
    margin: 0;

    @media print {
      display: block;

      & > * {
        display: inline-block;
        width: auto !important;
      }
    }

    > * {
      width: 100%;

      .fact-overview {
        border-radius: 12px;
        padding: 12px;
        width: 100%;
        min-width: 170px;
        margin: 0 0 20px;
      }
    }
  }

  .summaries-per-account-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 0 20px;
  }

  article {
    font-size: 1rem;

    &.social-network {
      display: inline-flex;
      background-color: #fff;
      box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
      border-radius: 1.2em;
      padding: 1.2em;
      width: 100%;
      min-width: 170px;
      margin: 0 0 2em;

      @media print {
        border: 1px solid black;
      }
    }

    .social-network-left {
      flex: 0 0 4.6em;
      padding-right: 1em;
    }

    .social-network-thumb {
      height: 4.6em;
      width: 4.6em;
      overflow: hidden;

      i {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        color: #fff;
        line-height: 1;
        background-color: $secondary-blue;
        font-size: 1.8em;

        &.ssi-sms:before {
          margin-top: 0.4em;
        }

        &.fa-twitter,
        &.ssi-x-logo {
          font-size: 2em;
          background-color: #1da1f2;
        }

        &.fa-facebook {
          font-size: 1.7em;
          background-color: #1877f2;
        }

        &.fa-linkedin {
          font-size: 1.8em;
          background-color: #2867b2;
        }

        &.fa-youtube-play {
          font-size: 2em;
          background-color: #f00;
        }

        &.fa-instagram {
          font-size: 2em;
          background: linear-gradient(
            180deg,
            #635fcc,
            #d43082 37%,
            #fec86b 100%
          );
        }

        &.ssi-whatsapp {
          font-size: 1.7em;
          background-color: #56cd63;
        }
      }
    }

    .social-network-right {
      flex: 1 0 auto;
      text-rendering: geometricPrecision;
    }

    .social-network-title {
      font-weight: 900;
      margin: 0.7rem 0 0;
      font-size: 1.6em;
      color: #46547c;
    }

    .social-network-meta {
      font-weight: 700;
      margin: 0.1em 0 1em;
      color: $lmode-helper-text;
    }

    .social-network-stats {
      display: flex;
      justify-content: flex-end;
      padding: 0;
      margin: 2.6em 0.6em 0.5em 0;
    }

    .social-network-stat {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      margin: 0 2.2em 0 0;

      &:last-child {
        margin-right: 0;
      }

      &:nth-child(2n) {
        .social-network-stat-label:before {
          background-color: $primary-active;
        }
      }
    }

    .social-network-stat-data {
      color: $lmode-title-text;
      font-size: 2em;
      margin: 0;
      font-weight: 900;
    }

    .social-network-stat-label {
      color: $lmode-helper-text;
      font-weight: 900;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      font-size: 1em;
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      &:before {
        right: 0;
        content: '';
        display: block;
        width: 6em;
        height: 0.4em;
        border-radius: 10em;
        background-color: #f88c68;
        margin: 0.6em 0 0.8em;
      }
    }
  }
}
