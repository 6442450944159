@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-component-library {
  .component-library-container {
    padding: 20px;
    background-color: #f0f3f9;
    width: 100%;
    // height: calc(100vh - 68px);

    .section {
      width: 100%;
      margin-bottom: 40px;
      border-top: 1px solid #d8d8d8;
      padding-top: 10px;
    }
  }
}
