ssi-no-results .no-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 140px;
}
ssi-no-results .no-results i {
  font-size: 50px;
  color: #b4bbce;
  margin-bottom: 30px;
}
ssi-no-results .no-results ssi-spinner {
  margin-bottom: 30px;
}
ssi-no-results .no-results .title {
  color: #43537f;
  font-size: 20px;
  font-weight: 900;
}
ssi-no-results .no-results .desc {
  color: #838eab;
  font-size: 14px;
  font-weight: 700;
  max-width: 280px;
}
ssi-no-results .no-results.size-sm {
  padding: 20px;
}
ssi-no-results .no-results.size-sm i {
  font-size: 30px;
  margin-bottom: 15px;
}
ssi-no-results .no-results.size-sm ssi-spinner {
  margin-bottom: 15px;
}
ssi-no-results .no-results.size-sm .title {
  font-size: 14px;
}
ssi-no-results .no-results.size-sm .desc {
  font-size: 12px;
  max-width: 200px;
}