ssi-marketing-index {
  display: block;
  background-color: #f9f9f9;
  margin-left: -15px;
  margin-right: -15px;
}
ssi-marketing-index .campaigns-stats {
  background-color: #fff;
  padding: 10px;
  overflow: hidden;
}
ssi-marketing-index .campaigns-stats .highcharts-credits,
ssi-marketing-index .campaigns-stats .highcharts-legend {
  display: none;
}
ssi-marketing-index .campaigns-stats .highcharts-container,
ssi-marketing-index .campaigns-stats .highcharts-container svg {
  width: 100% !important;
}
ssi-marketing-index .campaigns-stats.campaigns-stats-ad .graph-filters .stat-number-label.active,
ssi-marketing-index .campaigns-stats.campaigns-stats-ad .graph-filters a.active {
  background-color: #14bae3;
}
ssi-marketing-index .campaigns-stats.campaigns-stats-ad .highchart-controls-action-active {
  color: #14bae3;
}
ssi-marketing-index .campaigns-stats.campaigns-stats-ad .highcharts-graph {
  stroke: #14bae3 !important;
}
ssi-marketing-index .campaigns-stats .highcharts-contextbutton .highcharts-button-symbol {
  stroke: #14bae3 !important;
}
ssi-marketing-index .campaign-stats-row {
  flex-wrap: wrap;
  align-items: flex-start;
}
@media (min-width: 885px) {
  ssi-marketing-index .campaign-stats-row {
    display: flex;
  }
}
ssi-marketing-index .campaign-stats-col {
  flex: 0 1 100%;
  width: 100%;
}
@media (min-width: 885px) {
  ssi-marketing-index .campaign-stats-col {
    margin: 0 30px;
  }
}
ssi-marketing-index .campaign-stats-col-chart {
  order: 2;
  padding: 20px 0;
}
ssi-marketing-index .campaign-stats-col-chart ssi-highchart {
  display: block;
  min-height: 520px;
  padding: 60px 35px 45px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}
ssi-marketing-index .campaign-stats-col-chart ssi-highchart-controls {
  margin: -110px 30px 80px;
}
ssi-marketing-index .campaign-stats-col-filters {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  order: 1;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
@media (max-width: 885px) {
  ssi-marketing-index .campaign-stats-col-filters {
    max-width: 100vw;
  }
}
ssi-marketing-index .graph-filters {
  margin: 12px 15px;
  position: relative;
  text-align: center;
}
@media (min-width: 885px) {
  ssi-marketing-index .graph-filters {
    max-width: 570px;
  }
}
ssi-marketing-index .graph-filters ul {
  display: inline-flex;
  justify-content: space-between;
  padding: 18px 20px;
  border-radius: 40px;
  margin: 0;
  background-color: #fafafc;
}
@media (max-width: 885px) {
  ssi-marketing-index .graph-filters ul {
    flex-wrap: wrap;
    text-align: center;
    max-width: 100vw;
  }
}
ssi-marketing-index .graph-filters li {
  display: block;
  list-style-type: none;
  font-size: 12px;
  text-align: center;
  min-width: 60px;
  color: #101525;
}
@media (min-width: 885px) {
  ssi-marketing-index .graph-filters li {
    padding: 0 20px;
  }
}
ssi-marketing-index .graph-filters .stat-number-label,
ssi-marketing-index .graph-filters a {
  display: inline-block;
  color: #838eab;
  border-radius: 25px;
  text-decoration: none;
  white-space: nowrap;
  font-weight: 700;
}
ssi-marketing-index .graph-filters .stat-number-label.active,
ssi-marketing-index .graph-filters a.active {
  padding: 2px 10px;
  color: white;
  background-color: #14bae3;
  border: 2px solid #92d7e8;
}
ssi-marketing-index .graph-filters .graph-filter-title {
  color: #838eab;
  font-size: 11px;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin: 0 0 15px;
}
ssi-marketing-index .graph-filters .graph-filter-title p {
  display: inline-block;
  margin: 0;
  font-weight: 900;
}
ssi-marketing-index .graph-filters .graph-filter-title p:before {
  content: "|";
  margin: 0 10px;
}
ssi-marketing-index .graph-filters .graph-filter-title .floating-label {
  font-size: 8px;
  line-height: 8px;
  padding: 2px;
  background-color: #14bae3;
  color: #fff;
  display: inline-block;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  vertical-align: super;
  cursor: pointer;
  text-align: center;
  letter-spacing: 0;
}
ssi-marketing-index .stat-number {
  display: block;
  font-size: 18px;
  color: #101525;
  font-weight: 900;
  padding: 0;
  text-transform: lowercase;
}
ssi-marketing-index .campaigns-list {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 885px) {
  ssi-marketing-index .campaigns-list {
    padding: 80px 120px;
  }
}
@media (max-width: 885px) {
  ssi-marketing-index .campaigns-list {
    margin-top: 80px;
    max-width: 100vw;
    display: block;
  }
}
ssi-marketing-index .campaign {
  position: relative;
  flex-basis: calc(33% - (5px * 2));
  background-color: white;
  box-shadow: 0 0 10px rgba(60, 60, 60, 0.1);
  padding: 25px 0;
  margin: 5px;
  text-align: center;
  min-height: 430px;
}
@media (max-width: 885px) {
  ssi-marketing-index .campaign {
    width: 100vw;
  }
}
ssi-marketing-index .campaign-message {
  padding: 100px 0 400px;
  font-size: 22px;
  text-align: center;
}
ssi-marketing-index .campaign-stat {
  background-color: #f6f8f9;
  padding: 5px;
  color: #101525;
  font-weight: normal;
  margin: 0 3px;
  font-size: 13px;
  border-radius: 10px;
}
ssi-marketing-index .campaign-stats-hide {
  visibility: hidden;
}
ssi-marketing-index .campaigns-completed-filter {
  text-align: center;
  margin: -20px 0;
  width: 100%;
}
ssi-marketing-index .campaigns-completed-filter .campaigns-select-wrapper {
  position: relative;
  display: inline-block;
  padding: 0;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0, 0, 15px rgba(0, 0, 0, 0.1);
  border: 2px solid #f9f9f9;
}
ssi-marketing-index .campaigns-completed-filter .campaigns-select-wrapper select {
  appearance: none;
  background-color: #fff;
  border: none;
  color: #43537f;
  font-weight: 700;
  padding: 10px 38px 10px 20px;
}
ssi-marketing-index .campaigns-completed-filter .campaigns-select-wrapper select::-ms-expand {
  display: none;
}
ssi-marketing-index .campaigns-completed-filter .campaigns-select-wrapper.campaigns-select-wrapper-ad select {
  background-color: #43537f;
  color: #fff;
}
ssi-marketing-index .campaigns-completed-filter .campaigns-select-wrapper.campaigns-select-wrapper-ad .campaigns-select-icon {
  color: #fff;
}
ssi-marketing-index .campaigns-anchor {
  position: relative;
  width: 100%;
  height: 34px;
}
ssi-marketing-index .campaigns-select-container {
  width: 100%;
}
ssi-marketing-index .campaigns-select-container.fixed {
  position: fixed;
  padding: 35px 0;
  background: linear-gradient(#f9f9f9 80px, transparent);
  top: 68px;
  z-index: 9;
}
ssi-marketing-index .campaigns-select-icon {
  position: absolute;
  color: #14bae3;
  font-size: 16px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 16px;
  right: 18px;
  pointer-events: none;
}
ssi-marketing-index .campaign-add {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f9fafb;
}
ssi-marketing-index .campaign-add-icon {
  display: block;
  color: #b4bbce;
  font-size: 112px;
  line-height: 112px;
}
ssi-marketing-index .campaign-add-btn {
  display: inline-block;
  background-color: #fff;
  border: 2px solid #f3f6f7;
  color: #43537f;
  font-weight: 700;
  width: auto;
  margin: 10px auto;
  padding: 10px 20px;
}
ssi-marketing-index .campaign-add-btn:hover, ssi-marketing-index .campaign-add-btn:active {
  background-color: #fff;
  border-color: #e6eaed;
  color: #101525;
}
ssi-marketing-index .campaign-add-btn p {
  display: inline;
  color: #43537f;
  font-weight: 700;
}
ssi-marketing-index .campaign-add-btn .ssi {
  color: #14bae3;
  margin-right: 5px;
}
ssi-marketing-index .campaign-add-box-wrap {
  position: absolute;
  width: 100%;
  margin-top: -34px;
  z-index: 3;
}
ssi-marketing-index .campaign-bottom {
  margin-top: 70px;
  padding: 0 0 100px;
  color: #43537f;
  font-size: 20px;
  font-weight: 900;
  text-align: center;
}
ssi-marketing-index .campaign-bottom p {
  color: #43537f;
  font-size: 20px;
  font-weight: 900;
}
ssi-marketing-index .campaign-bottom-icon {
  font-size: 70px;
  color: #b4bbce;
  margin: 0 0 15px;
}
@media (max-width: 885px) {
  ssi-marketing-index .campaign-stats-row {
    max-width: 100vw;
  }
}
@media (min-width: 1366px) {
  ssi-marketing-index .campaign-stats-row {
    flex-wrap: nowrap;
  }
  ssi-marketing-index .campaign-stats-col {
    flex: 0 1 50%;
    width: 50%;
  }
  ssi-marketing-index .campaign-stats-col-chart {
    order: 1;
  }
  ssi-marketing-index .campaign-stats-col-chart ssi-highchart {
    min-height: 400px;
    padding: 0;
    box-shadow: none;
  }
  ssi-marketing-index .campaign-stats-col-chart ssi-highchart-controls {
    margin: -50px 0 10px;
  }
  ssi-marketing-index .campaign-stats-col-filters {
    order: 2;
    flex-direction: column;
    padding: 20px 0 40px 0;
  }
  ssi-marketing-index .graph-filters {
    text-align: right;
  }
  ssi-marketing-index .graph-filters li,
ssi-marketing-index .graph-filters .graph-filter-title {
    text-align: right;
  }
}