.modal-body .back-button {
  display: none !important;
}
@media (max-width: 480px) {
  .modal-body ssi-inbox-activity {
    height: calc(85vh - 68px);
  }
}

ssi-action-pad {
  background-color: #f8f9fd;
}
ssi-action-pad .action-pad-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 17px;
  margin: 10px 10px 3px;
  border-radius: 12px;
  background-color: #e7edfd;
  height: 70px;
}
ssi-action-pad .action-pad-container.action-pad-container-hidden > * {
  display: none;
}
ssi-action-pad .action-pad-container .action-trigger-view-profile {
  display: none;
}
ssi-action-pad .action-pad-container .action-trigger-view-profile.unavailable {
  cursor: not-allowed;
}
ssi-action-pad .action-pad-container .action-trigger-toggle-profile {
  display: block;
}
@media (max-width: 1350px) {
  ssi-action-pad .action-pad-container .action-trigger-view-profile {
    display: block;
  }
  ssi-action-pad .action-pad-container .action-trigger-toggle-profile {
    display: none;
  }
}
ssi-action-pad .action-pad-container.silenced {
  background-color: #35353f;
}
ssi-action-pad .action-pad-container.silenced .assignment > button,
ssi-action-pad .action-pad-container.silenced .action-pad-actions-assign > button {
  background-color: #494d5e;
}
ssi-action-pad .action-pad-container.silenced .assignment > button > span, ssi-action-pad .action-pad-container.silenced .assignment > button > i.ssi,
ssi-action-pad .action-pad-container.silenced .action-pad-actions-assign > button > span,
ssi-action-pad .action-pad-container.silenced .action-pad-actions-assign > button > i.ssi {
  color: #fff;
}
ssi-action-pad .action-pad-container.silenced .action-pad-button,
ssi-action-pad .action-pad-container.silenced .inbox-priority-select-container {
  background-color: #494d5e;
}
ssi-action-pad .action-pad-container.silenced .action-pad-button i,
ssi-action-pad .action-pad-container.silenced .inbox-priority-select-container i {
  color: #fff;
}
ssi-action-pad .action-pad-container.silenced .action-triggers {
  background-color: #494d5e;
}
ssi-action-pad .action-pad-container.silenced .action-triggers i {
  color: #fff;
}
ssi-action-pad .action-pad-container.silenced orlo-button button {
  background-color: #494d5e !important;
}
ssi-action-pad .action-pad-container.silenced orlo-button.unsilence {
  margin-left: 5px;
}
ssi-action-pad .action-pad-container.silenced orlo-button.unsilence i {
  color: #f88c68;
}
ssi-action-pad .action-pad-container.silenced .assignment-action {
  background-color: #494d5e;
}
ssi-action-pad .unsilence {
  margin-left: 6px;
}
ssi-action-pad .unsilence button {
  border-radius: 10px;
  white-space: nowrap;
}
ssi-action-pad .action-pad-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-width: 0;
  border-radius: 10px;
  height: 36px;
  min-width: 36px;
}
ssi-action-pad .action-pad-button i {
  font-size: 18px;
  color: #838eab;
}
ssi-action-pad .action-pad-button i.ssi-more-actions {
  font-size: 14px;
}
ssi-action-pad .back-button {
  font-size: 14px;
  padding: 11px;
  color: #43537f;
  border: 1px solid #668aea;
  border-radius: 10px;
  background-color: #fff;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-action-pad .back-button.action-pad-button i {
  color: #838eab;
  font-size: 13px;
}
ssi-action-pad .back-button.action-pad-button span {
  margin-left: 8px;
}
ssi-action-pad .back-button .ssi-line-arrow-small-down {
  transform: rotate(90deg);
}
ssi-action-pad .ap-menu-button {
  margin: 0 0 0 6px;
}
ssi-action-pad .action-pad-container-left {
  display: flex;
  height: 36px;
}
ssi-action-pad .action-pad-container-left > button:not(:last-child), ssi-action-pad .action-pad-container-left > orlo-button:not(:last-child) {
  margin-right: 6px;
}
ssi-action-pad .assignment-action {
  background-color: #668aea;
  color: #fff;
  display: flex;
  padding: 11px;
  border: none;
  border-radius: 10px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 900;
  outline: 0;
  text-rendering: geometricPrecision;
  cursor: pointer;
}
ssi-action-pad .assignment-action .ssi {
  font-size: 14px;
  margin: 0;
}
ssi-action-pad .assignment-action > span {
  margin: 0 0 0 9px;
}
ssi-action-pad .assignment-action .assignment-action-meta-wrap {
  height: 36px;
  white-space: nowrap;
  margin: -13px -18px;
  padding: 13px 18px;
}
ssi-action-pad .assignment-action .assignment-action-meta {
  display: inline-block;
  max-width: 65px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}
ssi-action-pad .assignment-action .assignment-action-meta + .assignment-action-meta:before {
  content: "|";
  margin: 0 2px 0 0;
}
ssi-action-pad .assignment-action .assignment-action-meta-timestamp {
  max-width: none;
}
ssi-action-pad .action-pad-container-right {
  display: flex;
  align-items: center;
  position: relative;
}
ssi-action-pad .action-pad-container-right:last-child {
  margin-right: 0;
}
ssi-action-pad .action-pad-actions-assign button {
  padding: 11px;
  font-size: 12px;
  min-width: 90px;
  line-height: 13px;
  border-radius: 10px;
  white-space: nowrap;
  margin-left: 6px;
}
ssi-action-pad .action-pad-actions-assign button .ssi {
  color: #838eab;
  font-size: 14px;
  color: #838eab;
}
ssi-action-pad .action-pad-actions-assign button .ssi + span {
  display: inline-block;
  line-height: 14px;
  vertical-align: text-top;
  font-weight: 900;
  text-rendering: geometricPrecision;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #43537f;
}
ssi-action-pad .ap-menu-dropdown {
  background-color: #fff;
  min-width: 231px;
  border-radius: 15px;
  box-shadow: 0px 5px 10px rgba(131, 142, 171, 0.35);
  margin: -36px 0 0;
  border-width: 0;
  padding: 28px 10px 28px 36px;
}
ssi-action-pad .ap-menu-dropdown > li {
  display: block;
  padding: 10px 0;
  font-size: 14px;
  line-height: 15px;
  margin: 0 0 4px 0;
  cursor: pointer;
}
ssi-action-pad .ap-menu-dropdown > li:hover .ap-menu-label,
ssi-action-pad .ap-menu-dropdown > li:hover .on-hold-label,
ssi-action-pad .ap-menu-dropdown > li:hover .resolve-label {
  text-decoration: underline;
}
ssi-action-pad .ap-menu-dropdown > li .ap-menu-label,
ssi-action-pad .ap-menu-dropdown > li .on-hold-label,
ssi-action-pad .ap-menu-dropdown > li .resolve-label {
  margin-left: 10px;
  color: #43537f;
  font-weight: 900;
  text-rendering: geometricPrecision;
  font-size: inherit;
}
ssi-action-pad .ap-menu-dropdown > li > i {
  color: #838eab;
}
ssi-action-pad .action-triggers {
  position: relative;
  padding: 8px 2px 8px 20px;
  border-radius: 10px;
  background-color: #fff;
  margin: 0 0 0 6px;
  display: flex;
  align-items: center;
}
ssi-action-pad .action-triggers .action-trigger {
  color: #838eab;
  margin-right: 18px;
  cursor: pointer;
}
ssi-action-pad .action-triggers .action-trigger-active {
  color: #14bae3;
}
ssi-action-pad .action-triggers .action-trigger:hover {
  color: #14bae3;
}
ssi-action-pad .action-triggers .action-trigger.ssi-resolve-convo {
  font-size: 16px;
  top: 2px;
  position: relative;
}
ssi-action-pad .action-triggers .action-trigger.no-pointer {
  cursor: default;
}
ssi-action-pad .action-triggers ssi-conversation-hold {
  display: flex;
  align-items: center;
}
ssi-action-pad .action-triggers ssi-conversation-hold .dd-container {
  display: flex;
  align-items: center;
}
ssi-action-pad .action-triggers-dropdown {
  top: 45px;
  left: 50%;
  transform: translateX(-50%);
}
ssi-action-pad .action-trigger-view-profile,
ssi-action-pad .action-trigger-toggle-profile {
  font-size: 20px;
  color: #838eab;
  margin-left: 6px;
  cursor: pointer;
}
ssi-action-pad .tags-select-container {
  position: relative;
}
ssi-action-pad .tags-select-container .selected-tags-count {
  position: absolute;
  top: -10px;
  left: 10px;
}
ssi-action-pad .tags-select-container .selected-tags-count span {
  font-size: 10px;
  color: #43537f;
  font-weight: 900;
}
ssi-action-pad .tags-select-container .no-tags-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 0 10px;
}
ssi-action-pad .tags-select-container .no-tags-state i {
  margin-top: 20px;
  color: #b4bbce;
  font-size: 30px;
}
ssi-action-pad .tags-select-container .no-tags-state .title {
  margin-top: 15px;
  color: #43537f;
  font-size: 16px;
  font-weight: 900;
}
ssi-action-pad .tags-select-container .no-tags-state .desc {
  margin-bottom: 20px;
  color: #43537f;
  font-size: 14px;
  font-weight: 700;
  padding: 0 24px;
  text-align: center;
}
ssi-action-pad .tags-select-container .no-tags-state button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  width: 100%;
}
ssi-action-pad .tags-select-container .no-tags-state button i {
  margin: 0;
  margin-left: 10px;
  font-size: 12px;
  color: #fff;
}
ssi-action-pad .tags-select-container .no-tags-state button span {
  white-space: nowrap;
}
ssi-action-pad .tags-select-container .dd-body {
  max-width: 200px;
}
ssi-action-pad .ssi-socialprofile {
  text-indent: 12%;
}