ssi-campaign-list .campaigns-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f4f4fa;
  box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.25);
}
ssi-campaign-list .campaigns-list-header h1 {
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #101525;
}
ssi-campaign-list .heading-info-container {
  display: flex;
  margin: 20px 20px 0 20px;
}
ssi-campaign-list .heading-info-container .page-description {
  flex: 1 1 60%;
  display: flex;
  background-color: #fff;
  border-radius: 12px;
}
ssi-campaign-list .heading-info-container .page-description .text {
  padding: 40px 20px 40px 50px;
}
ssi-campaign-list .heading-info-container .page-description h2 {
  font-weight: 900;
  font-size: 20px;
  color: #43537f;
}
ssi-campaign-list .heading-info-container .page-description p {
  font-weight: bold;
  color: #43537f;
  line-height: 20px;
}
ssi-campaign-list .heading-info-container img {
  width: auto;
  height: 144px;
  align-self: flex-end;
}
@media (max-width: 767px) {
  ssi-campaign-list .heading-info-container {
    flex-wrap: wrap;
  }
}
ssi-campaign-list .edit-create-campaign {
  padding: 20px;
  border-radius: 15px;
  background-color: #fff;
  margin: 20px;
}
ssi-campaign-list .edit-create-campaign form {
  display: flex;
  flex-wrap: wrap;
}
ssi-campaign-list .edit-create-campaign form input {
  background-color: #f8f9fd;
  border-color: #f8f9fd;
  color: #101525;
}
ssi-campaign-list .edit-create-campaign form ssi-dropdown-select-2 button.dd-head.variant-analytics {
  color: #101525;
  font-size: 14px;
  width: 100%;
  height: 46px;
}
ssi-campaign-list .edit-create-campaign form ssi-dropdown-select-2 .dd-body {
  width: 100%;
}
ssi-campaign-list .edit-create-campaign .form-field {
  flex: 1 1 calc(20% - 2 * 10px);
  margin-right: 10px;
}
@media (max-width: 1023px) {
  ssi-campaign-list .edit-create-campaign .form-field {
    flex-basis: calc(50% - 10px);
  }
}
@media (max-width: 767px) {
  ssi-campaign-list .edit-create-campaign .form-field {
    flex-basis: 100%;
  }
}
ssi-campaign-list .edit-create-campaign .form-field.name {
  flex-basis: 30%;
}
ssi-campaign-list .edit-create-campaign .date-picker-input {
  width: 100%;
  height: 46px;
  border: none;
  outline: none;
  border-radius: 100px;
  display: flex;
  align-items: center;
  color: #101525;
  font-size: 14px;
  font-weight: 900;
  background-color: #f8f9fd;
  padding: 10px 30px;
}
ssi-campaign-list .edit-create-campaign .date-picker-input::placeholder {
  color: #838eab;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}
ssi-campaign-list .edit-create-campaign label {
  width: 100%;
  padding: 0 3em;
  display: block;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 900;
  line-height: 2.5em;
  color: #43537f;
  letter-spacing: 1px;
  box-sizing: border-box;
}
ssi-campaign-list .edit-create-campaign .form-actions-container {
  display: flex;
  align-items: flex-end;
  margin: 0 0 10px 30px;
}
ssi-campaign-list .edit-create-campaign .form-actions-container .form-action {
  display: flex;
  margin-left: 5px;
}
@media (max-width: 1023px) {
  ssi-campaign-list .edit-create-campaign .form-actions-container {
    width: 100%;
    justify-content: center;
    margin-top: 20px;
  }
}
ssi-campaign-list .campaigns-overview-container {
  flex: 1 1 40%;
  display: flex;
}
ssi-campaign-list .campaigns-overview-container .campaigns-stats-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  margin-left: 20px;
  flex: 1 1 50%;
}
ssi-campaign-list .campaigns-overview-container .campaigns-stats-card .upper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
ssi-campaign-list .campaigns-overview-container .campaigns-stats-card .upper i {
  font-size: 20px;
  padding: 13px;
  border-radius: 50%;
}
ssi-campaign-list .campaigns-overview-container .campaigns-stats-card .upper i.live {
  color: #b0c600;
  background-color: #f0f4d0;
}
ssi-campaign-list .campaigns-overview-container .campaigns-stats-card .upper i.completed {
  color: #f0b427;
  background-color: #fbf3e2;
}
ssi-campaign-list .campaigns-overview-container .campaigns-stats-card span {
  font-size: 35px;
  color: #101525;
  font-weight: bold;
}
ssi-campaign-list .campaigns-overview-container .campaigns-stats-card h4 {
  font-size: 10px;
  font-weight: bold;
  color: #838eab;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 5px;
  margin-right: 5px;
}
@media (max-width: 767px) {
  ssi-campaign-list .campaigns-overview-container .campaigns-stats-card:first-child {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  ssi-campaign-list .campaigns-overview-container {
    margin-top: 20px;
  }
}
ssi-campaign-list .tabs-container {
  padding: 20px;
}
ssi-campaign-list .tabs-container .tabs {
  display: flex;
}
ssi-campaign-list .tabs-container .tabs > div {
  position: relative;
  background-color: #f8f9fd;
  padding: 25px 50px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  margin-right: 10px;
  letter-spacing: 1px;
  color: #43537f;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 900;
  box-shadow: 5px 0 10px -5px rgba(131, 142, 171, 0.1);
}
ssi-campaign-list .tabs-container .tabs > div:hover {
  cursor: pointer;
}
ssi-campaign-list .tabs-container .tabs > div::before {
  content: "";
  transform: scaleX(0);
  visibility: hidden;
  position: absolute;
  bottom: 7px;
  height: 2px;
  width: 30%;
  left: 35%;
  background-color: #b2c614;
  transition: all 0.3s ease-in-out 0s;
}
ssi-campaign-list .tabs-container .tabs > div.active, ssi-campaign-list .tabs-container .tabs > div:hover {
  background-color: #fff;
}
ssi-campaign-list .tabs-container .tabs > div.active:before, ssi-campaign-list .tabs-container .tabs > div:hover:before {
  transform: scaleX(1);
  visibility: visible;
}
ssi-campaign-list .tabs-container .tabs > div.completed::before {
  background-color: #f0b427;
}
ssi-campaign-list .tabs-container .table-container {
  padding: 20px;
  background-color: #fff;
  border-radius: 0 15px 15px 15px;
}
ssi-campaign-list .tabs-container .table-container ssi-treetable .content .head .row .cell {
  height: 50px;
  background-color: #fff;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 10px;
  color: #43537f;
  padding-left: 30px;
  border-bottom: 2px solid #e0e7f4;
}
ssi-campaign-list .tabs-container .table-container .table-cell {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  border-bottom: 2px solid #e0e7f4;
  padding-left: 30px;
}
ssi-campaign-list .tabs-container .table-container .table-head-cell {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}
ssi-campaign-list .tabs-container .table-container .campaign-name {
  display: flex;
  align-items: center;
  color: #43537f;
  font-size: 12px;
  font-weight: 900;
  min-width: 0;
}
ssi-campaign-list .tabs-container .table-container .campaign-name small {
  color: #e0e7f4;
}
ssi-campaign-list .tabs-container .table-container .campaign-name i {
  margin-right: 10px;
  color: #43537f;
}
ssi-campaign-list .tabs-container .table-container .campaign-name span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
ssi-campaign-list .tabs-container .table-container .actions-cell {
  display: flex;
  justify-content: flex-end;
}
ssi-campaign-list .tabs-container .table-container .actions-cell button span {
  white-space: nowrap;
}
ssi-campaign-list .tabs-container .table-container .date-cell .date-value {
  color: #101525;
  font-weight: 900;
}
ssi-campaign-list .tabs-container .table-container .date-cell .no-date {
  font-weight: bold;
  color: #43537f;
  font-style: italic;
}
ssi-campaign-list .tabs-container .table-container .paginator-container {
  background-color: #fff;
  border-radius: 0 0 15px 15px;
  height: 80px;
  padding: 40px;
}
ssi-campaign-list .tabs-container .table-container .parent {
  background-color: #f8f9fd;
}
ssi-campaign-list .tabs-container .table-container .filter-container {
  width: 205px;
  padding: 0 10px 10px 10px;
  position: relative;
}
ssi-campaign-list .tabs-container .table-container .filter-container.extend-width {
  width: 250px;
}
ssi-campaign-list .tabs-container .table-container .filter-container input.form-control.form-control-minimal.filter {
  padding-left: 5px;
  padding-right: 20px;
  color: #101525;
  font-style: normal;
  font-size: 14px;
  font-weight: 700;
  border: 0;
  text-transform: capitalize;
}
ssi-campaign-list .tabs-container .table-container .filter-container input.form-control.form-control-minimal.filter::placeholder {
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  color: #838eab;
}
ssi-campaign-list .tabs-container .table-container .filter-container i {
  position: absolute;
  color: #43537f;
  top: 10px;
  right: 10px;
  font-size: 14px;
  font-weight: 700;
}
ssi-campaign-list .tabs-container .table-container .filter-container i.active {
  color: #14bae3;
}
ssi-campaign-list orlo-button {
  margin-right: 10px;
}
ssi-campaign-list orlo-button button {
  display: flex;
  align-items: center;
}
ssi-campaign-list orlo-button button i {
  margin-right: 5px;
  font-size: 12px !important;
}
ssi-campaign-list .confirm-action-tooltip-window {
  border-radius: 12px;
}