ssi-live-chat-message-note {
  background-color: #7d90a0;
  border-radius: 20px;
  border-top-right-radius: 0;
  color: #fff;
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  font-size: 0.85em;
  margin: 10px 0;
  padding: 20px 20px 10px 20px;
}
ssi-live-chat-message-note * {
  display: flex;
}
ssi-live-chat-message-note span > span + span {
  margin-left: 2.5px;
}
ssi-live-chat-message-note .footer-information > li button,
ssi-live-chat-message-note .footer-options > li button {
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 20px;
  font-weight: 500;
  justify-content: center;
  padding: 5px 10px;
}
ssi-live-chat-message-note .footer-information > li button.add-button,
ssi-live-chat-message-note .footer-options > li button.add-button {
  background-color: #fff;
  color: #14bae3;
}
ssi-live-chat-message-note .footer-information > li button.add-button:hover, ssi-live-chat-message-note .footer-information > li button.add-button.active,
ssi-live-chat-message-note .footer-options > li button.add-button:hover,
ssi-live-chat-message-note .footer-options > li button.add-button.active {
  background-color: #14bae3;
  color: #fff;
}
ssi-live-chat-message-note .footer-information > li button.delete-button,
ssi-live-chat-message-note .footer-options > li button.delete-button {
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 10px;
  color: #e4a598;
  font-style: italic;
  font-weight: 600;
  justify-content: space-between;
  padding: 0;
}
ssi-live-chat-message-note .footer-information > li button.delete-button > .button-icon,
ssi-live-chat-message-note .footer-options > li button.delete-button > .button-icon {
  font-size: 0.85em;
  padding-right: 0.2em;
}
ssi-live-chat-message-note .footer-information > li button.view-more-button,
ssi-live-chat-message-note .footer-options > li button.view-more-button {
  background-color: #14bae3;
  color: #fff;
}
ssi-live-chat-message-note .footer-information > li button.view-more-button:hover, ssi-live-chat-message-note .footer-information > li button.view-more-button.active,
ssi-live-chat-message-note .footer-options > li button.view-more-button:hover,
ssi-live-chat-message-note .footer-options > li button.view-more-button.active {
  background-color: #fff;
  color: #14bae3;
}
ssi-live-chat-message-note .footer-information > li button > .button-icon,
ssi-live-chat-message-note .footer-options > li button > .button-icon {
  padding-right: 5px;
}
ssi-live-chat-message-note ul {
  flex-flow: row nowrap;
  list-style: none;
  margin: 0;
  padding: 0;
}
ssi-live-chat-message-note ul > li {
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}
ssi-live-chat-message-note p {
  display: inline;
  flex-flow: row wrap;
  font-size: 1.1em;
  line-height: 1.6em;
  margin: 0 0 10px 0;
}
ssi-live-chat-message-note p.self-penned:before {
  color: #14bae3;
  content: "You:";
  font-style: italic;
  font-weight: 400;
  padding-right: 0.5em;
}
ssi-live-chat-message-note p * {
  display: inline;
  padding: 0 0.2em;
}
ssi-live-chat-message-note p a {
  color: #fff;
  font-style: italic;
  font-weight: 400;
}
ssi-live-chat-message-note p a:before {
  content: "@";
}
ssi-live-chat-message-note .notes-preview,
ssi-live-chat-message-note .form-inner {
  border-radius: 20px;
  border-top-right-radius: 0;
  flex-flow: column nowrap;
  padding: 20px 20px 10px 20px;
}
ssi-live-chat-message-note .footer {
  align-items: center;
  font-size: 0.85em;
  font-style: italic;
  justify-content: space-between;
}
ssi-live-chat-message-note .footer .footer-options {
  margin: 0;
  padding: 0;
}
ssi-live-chat-message-note .footer .footer-options > li:not(:first-child) {
  margin-left: 5px;
}
ssi-live-chat-message-note .footer .footer-information {
  flex-grow: 1;
  font-style: italic;
  font-weight: 500;
  justify-content: flex-end;
}
ssi-live-chat-message-note .footer .footer-information > li:first-child:after {
  content: "|";
  padding: 0 5px;
}
ssi-live-chat-message-note .footer .footer-information .information-icon {
  color: #edbb38;
  font-size: 2em;
  margin-left: 5px;
}
ssi-live-chat-message-note .you {
  color: #43ade8;
  font-style: italic;
  font-weight: 600;
  text-transform: capitalize;
}