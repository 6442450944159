@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-message-footer {
  font-size: 1rem;

  .message-box-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1em 0 0;
  }

  .message-box-footer-actions {
    margin: 0;
    min-height: 1em;
    padding: 0 0 0 2em;

    & > li {
      display: inline-block;
    }

    & ~ ssi-message-actions-inline {
      margin-bottom: -1em;
    }
  }

  .message-box-footer-action {
    position: relative;
    color: $primary-active;
    margin: 0 0.6em 0 0;

    & > p,
    & > span,
    & > i {
      margin: 0;
      text-transform: uppercase;
      font-size: 1em;
      font-weight: 900;
      letter-spacing: 0.1em;
      text-rendering: geometricPrecision;
      cursor: pointer;
    }

    &:hover,
    &:active {
      color: darken($primary-active, 10%);
    }

    &-inaction {
      & > p:not(.mouse-pointer),
      & > span:not(.mouse-pointer),
      & > i:not(.mouse-pointer) {
        cursor: auto;
      }

      &:hover,
      &:active {
        color: $primary-active;
      }
    }

    &.message-box-footer-action-button {
      display: inline-flex;
      align-items: center;
      background-color: #668aea;
      padding: 0.5em 1em;
      border-radius: 2em;
      color: #fff;
      letter-spacing: 0;
      text-transform: none;
      cursor: pointer;

      & > p,
      & > span,
      & > i {
        letter-spacing: inherit;
        text-transform: inherit;

        &::selection {
          background-color: transparent;
        }
      }

      & > p {
        font-size: 1.2em;
        margin: 0 0.4em 0 0;
      }

      & > i {
        font-size: 0.7em;
        font-weight: 900;
      }
    }
  }

  .message-box-footer-message-actions {
    align-self: flex-end;
    margin-bottom: -1em;
  }
}
