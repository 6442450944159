@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../angularjs/common/sass/include-media';
@import '../../../../../common/styles/colors.scss';

ssi-description-step {
  .page-description {
    flex: 1 1 60%;
    display: flex;
    background-color: $grey-background;
    border-radius: 12px;
    .text {
      padding: 40px 20px 40px 50px;
    }
    h2 {
      font-weight: 900;
      font-size: 20px;
      color: $lmode-body-text;
    }
    p {
      font-weight: bold;
      color: $lmode-body-text;
      line-height: 20px;
    }

    img {
      width: auto;
      height: 144px;
      margin-right: 40px;
      align-self: flex-end;
    }
  }

  .stream-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px;
  }

  .input-container {
    position: relative;
    margin-left: 10px;

    input {
      color: $lmode-title-text;
      &::placeholder {
        font-style: normal;
        font-size: 14px;
      }
    }

    label {
      text-transform: uppercase;
      color: $lmode-body-text;
      font-weight: 900;
      margin: 10px 0 10px 30px;
      letter-spacing: 1px;
    }

    .input-counter {
      position: absolute;
      top: 90px;
      right: 8px;
      color: #838eab;
      font-size: 10px;
      font-weight: 900;
      text-rendering: geometricPrecision;

      &.has-value {
        color: $primary-active;
      }
      &.error {
        color: $secondary-red;
      }
    }

    &:first-child {
      margin-left: 0;
      width: calc(35% - 20px);
    }
    &:last-child {
      width: calc(65% - 20px);
    }
  }

  .description-step {
    .input-container {
      @include media('<tablet') {
        width: 100%;
        margin: 0;
      }
    }
  }
}
