ssi-live-chat-avatar {
  display: flex;
  flex-shrink: 0;
  height: 50px;
  width: 50px;
}
ssi-live-chat-avatar * {
  display: flex;
}
ssi-live-chat-avatar .inner {
  height: inherit;
  position: relative;
  width: inherit;
}
ssi-live-chat-avatar .inner img {
  border-radius: 50%;
  height: inherit;
  width: inherit;
}
ssi-live-chat-avatar .inner .spinner {
  align-items: center;
  height: inherit;
  justify-content: center;
  width: inherit;
}
ssi-live-chat-avatar .inner .verified {
  background-color: #D8DFE5;
  border-radius: 50%;
  color: #5B6267;
  font-size: 10px;
  font-style: italic;
  font-weight: 600;
  justify-content: center;
  padding: 5px;
  position: absolute;
  right: 0;
  top: -5px;
}