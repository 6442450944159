@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../angularjs/common/sass/variables';
@import '../../../../../angularjs/common/sass/mixins';
@import '~/styles/colors';

ssi-targeting-options {
  .accordion-content {
    display: block;
    height: 100%;

    .toggle-label p {
      width: 150px;
      text-align: left;
    }
  }

  ssi-facebook-options,
  ssi-linkedin-options {
    .targeting-options-panel {
      font-family: Lato, sans-serif;

      .network-information {
        display: flex;

        .accordion-content-title {
          padding-left: 10px;
        }

        .accordion-content-body {
          padding: 0 0 2px 10px;
        }
      }
    }

    .targeting-form {
      &.preview-toggled {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 16px;
      }

      label {
        font-weight: 900;
        font-size: 10px;
        text-transform: uppercase;
        line-height: 12px;
        color: #43537f;
        padding: 26px 0 16px 30px;
        letter-spacing: 1px;
        margin-bottom: 0;
      }

      .dropdown-menu-container {
        ssi-dropdown-select-2 {
          width: 100%;

          .dd-head {
            width: 100%;
            padding: 0 35px 0 25px;
          }

          .dd-body {
            width: 100%;
            ul {
              max-height: 400px !important;
            }
          }

          .select-placeholder {
            font-size: 14px;
            font-weight: 700;
            color: #6a759a;
            text-wrap: nowrap;
          }
        }

        .select-range ssi-dropdown-select-2:first-child button {
          margin-right: 0;
        }

        .select-range ssi-dropdown-select-2:nth-child(odd) button {
          border-radius: 10px 0 0 10px;
          border-right: none;
        }

        .select-range ssi-dropdown-select-2:nth-child(even) button {
          border-radius: 0 10px 10px 0;
        }
      }

      .orlo-typeahead {
        .outbox-typeahead-container {
          width: 100%;
          height: 92px;
          border: 1px solid #c3c9d8;
          border-radius: 10px;
          background-color: #ffffff;

          &:focus-within {
            border-color: #14bae3;
          }

          &:hover {
            border-color: #14bae3;
          }

          ul.suggestions {
            position: absolute;
            z-index: 1000;
            min-width: 260px;
            padding: 20px 10px 20px 20px;
            border-radius: 15px;
            -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
            background-color: #fff;
            overflow: hidden;
            height: auto;
            left: 0;

            & > li {
              max-width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: start;
              -ms-flex-pack: start;
              justify-content: flex-start;
              cursor: pointer;
              color: #43537f;
              font-size: 14px;
              font-weight: 900;
              border-radius: 10px;
              min-height: 30px;
              border: none;

              &:hover {
                background-color: #f8f9fd;
              }

              &.active {
                background-color: #f8f9fd;
              }
            }
          }

          .choices-container {
            border-radius: 10px;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: flex-start;
            overflow-y: auto;

            li:not(:first-child) {
              padding-left: 0;
            }

            li {
              padding-left: 20px;
            }

            li > input {
              font-style: normal;
              font-family: Lato, sans-serif;
              font-weight: 700;
              font-size: 14px;
              color: #101525;

              &.suggestions-filter {
                &::placeholder {
                  &:first-letter {
                    text-transform: uppercase;
                  }
                  color: #6a759a;
                  opacity: 1;
                }
              }
            }
          }

          ul.choices {
            width: 100%;

            .selected-option {
              font-weight: 900;
              font-style: normal;
              font-size: 12px;
              line-height: 12px;
              align-content: center;
              border-radius: 8px;
              color: #101525;
              background-color: #f4f4fa;
              padding-top: 10px;
              padding-bottom: 10px;
              padding-left: 10px;
            }

            .selected-option:after {
              font-size: 8px;
              color: #43537f;
            }
          }
        }
      }
    }
  }

  ssi-nextdoor-options {
    .targeting-options-panel {
      font-family: Lato, serif;

      .network-information {
        display: flex;

        .accordion-content-title {
          padding-left: 10px;
        }

        .accordion-content-body {
          padding: 0 0 2px 10px;
        }
      }

      .targeting-options-form {
        .nextdoor-boundary-select {
          label {
            font-weight: 900;
            font-size: 10px;
            text-transform: uppercase;
            line-height: 12px;
            color: #43537f;
            padding: 26px 0 16px 30px;
            margin-bottom: 0;
          }

          ssi-dropdown-select-2 {
            width: 100%;

            .dd-head {
              width: 100%;
              padding: 0 35px 0 5px;

              &:focus {
                border-color: #14bae3;
              }

              &:hover {
                border-color: #14bae3;
              }
            }

            .dd-body {
              width: 100%;
              ul {
                max-height: 400px !important;
              }
            }
          }

          &.no-selected-options {
            width: 100%;
            ssi-dropdown-select-2 {
              .dd-head {
                padding: 0 30px 0 20px;
              }
            }
          }

          .select-placeholder {
            font-size: 14px;
            font-weight: 700;
            color: #6a759a;
            text-wrap: nowrap;
            padding-left: 25px;
          }

          .selected-options-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .chips-container {
              display: flex;
              align-items: center;
              overflow: hidden;

              .chip {
                height: 36px;
                background-color: #f4f4fa;
                border-radius: 8px;
                padding: 0 10px;
                white-space: nowrap;
                margin-right: 5px;

                display: flex;
                align-items: center;

                span {
                  font-size: 14px;
                  color: #101525;
                  font-weight: 900;
                  margin: 0;
                  margin-left: 10px;
                }

                i {
                  font-size: 8px;
                  color: #43537f;
                  font-weight: 500;
                  margin-left: 10px;
                  cursor: pointer;
                }
              }
            }
          }

          .menu-header {
            height: 46px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            span {
              margin-right: auto;
            }

            .collapse-icon {
              color: $primary-active;
              font-weight: 900;
              font-size: 12px;
              margin-left: 5px;
              margin-right: 8px;
              transform: rotate(180deg);
              cursor: pointer;
            }

            .no-boundaries-selected-label {
              color: #6a759a;
              font-weight: 700;
              font-style: italic;
            }
          }
        }
      }
    }
  }
}
