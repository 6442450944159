ssi-targeting-options .accordion-content {
  display: block;
  height: 100%;
}
ssi-targeting-options .accordion-content .toggle-label p {
  width: 150px;
  text-align: left;
}
ssi-targeting-options ssi-facebook-options .targeting-options-panel,
ssi-targeting-options ssi-linkedin-options .targeting-options-panel {
  font-family: Lato, sans-serif;
}
ssi-targeting-options ssi-facebook-options .targeting-options-panel .network-information,
ssi-targeting-options ssi-linkedin-options .targeting-options-panel .network-information {
  display: flex;
}
ssi-targeting-options ssi-facebook-options .targeting-options-panel .network-information .accordion-content-title,
ssi-targeting-options ssi-linkedin-options .targeting-options-panel .network-information .accordion-content-title {
  padding-left: 10px;
}
ssi-targeting-options ssi-facebook-options .targeting-options-panel .network-information .accordion-content-body,
ssi-targeting-options ssi-linkedin-options .targeting-options-panel .network-information .accordion-content-body {
  padding: 0 0 2px 10px;
}
ssi-targeting-options ssi-facebook-options .targeting-form.preview-toggled,
ssi-targeting-options ssi-linkedin-options .targeting-form.preview-toggled {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
}
ssi-targeting-options ssi-facebook-options .targeting-form label,
ssi-targeting-options ssi-linkedin-options .targeting-form label {
  font-weight: 900;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 12px;
  color: #43537f;
  padding: 26px 0 16px 30px;
  letter-spacing: 1px;
  margin-bottom: 0;
}
ssi-targeting-options ssi-facebook-options .targeting-form .dropdown-menu-container ssi-dropdown-select-2,
ssi-targeting-options ssi-linkedin-options .targeting-form .dropdown-menu-container ssi-dropdown-select-2 {
  width: 100%;
}
ssi-targeting-options ssi-facebook-options .targeting-form .dropdown-menu-container ssi-dropdown-select-2 .dd-head,
ssi-targeting-options ssi-linkedin-options .targeting-form .dropdown-menu-container ssi-dropdown-select-2 .dd-head {
  width: 100%;
  padding: 0 35px 0 25px;
}
ssi-targeting-options ssi-facebook-options .targeting-form .dropdown-menu-container ssi-dropdown-select-2 .dd-body,
ssi-targeting-options ssi-linkedin-options .targeting-form .dropdown-menu-container ssi-dropdown-select-2 .dd-body {
  width: 100%;
}
ssi-targeting-options ssi-facebook-options .targeting-form .dropdown-menu-container ssi-dropdown-select-2 .dd-body ul,
ssi-targeting-options ssi-linkedin-options .targeting-form .dropdown-menu-container ssi-dropdown-select-2 .dd-body ul {
  max-height: 400px !important;
}
ssi-targeting-options ssi-facebook-options .targeting-form .dropdown-menu-container ssi-dropdown-select-2 .select-placeholder,
ssi-targeting-options ssi-linkedin-options .targeting-form .dropdown-menu-container ssi-dropdown-select-2 .select-placeholder {
  font-size: 14px;
  font-weight: 700;
  color: #6a759a;
  text-wrap: nowrap;
}
ssi-targeting-options ssi-facebook-options .targeting-form .dropdown-menu-container .select-range ssi-dropdown-select-2:first-child button,
ssi-targeting-options ssi-linkedin-options .targeting-form .dropdown-menu-container .select-range ssi-dropdown-select-2:first-child button {
  margin-right: 0;
}
ssi-targeting-options ssi-facebook-options .targeting-form .dropdown-menu-container .select-range ssi-dropdown-select-2:nth-child(odd) button,
ssi-targeting-options ssi-linkedin-options .targeting-form .dropdown-menu-container .select-range ssi-dropdown-select-2:nth-child(odd) button {
  border-radius: 10px 0 0 10px;
  border-right: none;
}
ssi-targeting-options ssi-facebook-options .targeting-form .dropdown-menu-container .select-range ssi-dropdown-select-2:nth-child(even) button,
ssi-targeting-options ssi-linkedin-options .targeting-form .dropdown-menu-container .select-range ssi-dropdown-select-2:nth-child(even) button {
  border-radius: 0 10px 10px 0;
}
ssi-targeting-options ssi-facebook-options .targeting-form .orlo-typeahead .outbox-typeahead-container,
ssi-targeting-options ssi-linkedin-options .targeting-form .orlo-typeahead .outbox-typeahead-container {
  width: 100%;
  height: 92px;
  border: 1px solid #c3c9d8;
  border-radius: 10px;
  background-color: #ffffff;
}
ssi-targeting-options ssi-facebook-options .targeting-form .orlo-typeahead .outbox-typeahead-container:focus-within,
ssi-targeting-options ssi-linkedin-options .targeting-form .orlo-typeahead .outbox-typeahead-container:focus-within {
  border-color: #14bae3;
}
ssi-targeting-options ssi-facebook-options .targeting-form .orlo-typeahead .outbox-typeahead-container:hover,
ssi-targeting-options ssi-linkedin-options .targeting-form .orlo-typeahead .outbox-typeahead-container:hover {
  border-color: #14bae3;
}
ssi-targeting-options ssi-facebook-options .targeting-form .orlo-typeahead .outbox-typeahead-container ul.suggestions,
ssi-targeting-options ssi-linkedin-options .targeting-form .orlo-typeahead .outbox-typeahead-container ul.suggestions {
  position: absolute;
  z-index: 1000;
  min-width: 260px;
  padding: 20px 10px 20px 20px;
  border-radius: 15px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-color: #fff;
  overflow: hidden;
  height: auto;
  left: 0;
}
ssi-targeting-options ssi-facebook-options .targeting-form .orlo-typeahead .outbox-typeahead-container ul.suggestions > li,
ssi-targeting-options ssi-linkedin-options .targeting-form .orlo-typeahead .outbox-typeahead-container ul.suggestions > li {
  max-width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  cursor: pointer;
  color: #43537f;
  font-size: 14px;
  font-weight: 900;
  border-radius: 10px;
  min-height: 30px;
  border: none;
}
ssi-targeting-options ssi-facebook-options .targeting-form .orlo-typeahead .outbox-typeahead-container ul.suggestions > li:hover,
ssi-targeting-options ssi-linkedin-options .targeting-form .orlo-typeahead .outbox-typeahead-container ul.suggestions > li:hover {
  background-color: #f8f9fd;
}
ssi-targeting-options ssi-facebook-options .targeting-form .orlo-typeahead .outbox-typeahead-container ul.suggestions > li.active,
ssi-targeting-options ssi-linkedin-options .targeting-form .orlo-typeahead .outbox-typeahead-container ul.suggestions > li.active {
  background-color: #f8f9fd;
}
ssi-targeting-options ssi-facebook-options .targeting-form .orlo-typeahead .outbox-typeahead-container .choices-container,
ssi-targeting-options ssi-linkedin-options .targeting-form .orlo-typeahead .outbox-typeahead-container .choices-container {
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: auto;
}
ssi-targeting-options ssi-facebook-options .targeting-form .orlo-typeahead .outbox-typeahead-container .choices-container li:not(:first-child),
ssi-targeting-options ssi-linkedin-options .targeting-form .orlo-typeahead .outbox-typeahead-container .choices-container li:not(:first-child) {
  padding-left: 0;
}
ssi-targeting-options ssi-facebook-options .targeting-form .orlo-typeahead .outbox-typeahead-container .choices-container li,
ssi-targeting-options ssi-linkedin-options .targeting-form .orlo-typeahead .outbox-typeahead-container .choices-container li {
  padding-left: 20px;
}
ssi-targeting-options ssi-facebook-options .targeting-form .orlo-typeahead .outbox-typeahead-container .choices-container li > input,
ssi-targeting-options ssi-linkedin-options .targeting-form .orlo-typeahead .outbox-typeahead-container .choices-container li > input {
  font-style: normal;
  font-family: Lato, sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #101525;
}
ssi-targeting-options ssi-facebook-options .targeting-form .orlo-typeahead .outbox-typeahead-container .choices-container li > input.suggestions-filter::placeholder,
ssi-targeting-options ssi-linkedin-options .targeting-form .orlo-typeahead .outbox-typeahead-container .choices-container li > input.suggestions-filter::placeholder {
  color: #6a759a;
  opacity: 1;
}
ssi-targeting-options ssi-facebook-options .targeting-form .orlo-typeahead .outbox-typeahead-container .choices-container li > input.suggestions-filter::placeholder:first-letter,
ssi-targeting-options ssi-linkedin-options .targeting-form .orlo-typeahead .outbox-typeahead-container .choices-container li > input.suggestions-filter::placeholder:first-letter {
  text-transform: uppercase;
}
ssi-targeting-options ssi-facebook-options .targeting-form .orlo-typeahead .outbox-typeahead-container ul.choices,
ssi-targeting-options ssi-linkedin-options .targeting-form .orlo-typeahead .outbox-typeahead-container ul.choices {
  width: 100%;
}
ssi-targeting-options ssi-facebook-options .targeting-form .orlo-typeahead .outbox-typeahead-container ul.choices .selected-option,
ssi-targeting-options ssi-linkedin-options .targeting-form .orlo-typeahead .outbox-typeahead-container ul.choices .selected-option {
  font-weight: 900;
  font-style: normal;
  font-size: 12px;
  line-height: 12px;
  align-content: center;
  border-radius: 8px;
  color: #101525;
  background-color: #f4f4fa;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}
ssi-targeting-options ssi-facebook-options .targeting-form .orlo-typeahead .outbox-typeahead-container ul.choices .selected-option:after,
ssi-targeting-options ssi-linkedin-options .targeting-form .orlo-typeahead .outbox-typeahead-container ul.choices .selected-option:after {
  font-size: 8px;
  color: #43537f;
}
ssi-targeting-options ssi-nextdoor-options .targeting-options-panel {
  font-family: Lato, serif;
}
ssi-targeting-options ssi-nextdoor-options .targeting-options-panel .network-information {
  display: flex;
}
ssi-targeting-options ssi-nextdoor-options .targeting-options-panel .network-information .accordion-content-title {
  padding-left: 10px;
}
ssi-targeting-options ssi-nextdoor-options .targeting-options-panel .network-information .accordion-content-body {
  padding: 0 0 2px 10px;
}
ssi-targeting-options ssi-nextdoor-options .targeting-options-panel .targeting-options-form .nextdoor-boundary-select label {
  font-weight: 900;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 12px;
  color: #43537f;
  padding: 26px 0 16px 30px;
  margin-bottom: 0;
}
ssi-targeting-options ssi-nextdoor-options .targeting-options-panel .targeting-options-form .nextdoor-boundary-select ssi-dropdown-select-2 {
  width: 100%;
}
ssi-targeting-options ssi-nextdoor-options .targeting-options-panel .targeting-options-form .nextdoor-boundary-select ssi-dropdown-select-2 .dd-head {
  width: 100%;
  padding: 0 35px 0 5px;
}
ssi-targeting-options ssi-nextdoor-options .targeting-options-panel .targeting-options-form .nextdoor-boundary-select ssi-dropdown-select-2 .dd-head:focus {
  border-color: #14bae3;
}
ssi-targeting-options ssi-nextdoor-options .targeting-options-panel .targeting-options-form .nextdoor-boundary-select ssi-dropdown-select-2 .dd-head:hover {
  border-color: #14bae3;
}
ssi-targeting-options ssi-nextdoor-options .targeting-options-panel .targeting-options-form .nextdoor-boundary-select ssi-dropdown-select-2 .dd-body {
  width: 100%;
}
ssi-targeting-options ssi-nextdoor-options .targeting-options-panel .targeting-options-form .nextdoor-boundary-select ssi-dropdown-select-2 .dd-body ul {
  max-height: 400px !important;
}
ssi-targeting-options ssi-nextdoor-options .targeting-options-panel .targeting-options-form .nextdoor-boundary-select.no-selected-options {
  width: 100%;
}
ssi-targeting-options ssi-nextdoor-options .targeting-options-panel .targeting-options-form .nextdoor-boundary-select.no-selected-options ssi-dropdown-select-2 .dd-head {
  padding: 0 30px 0 20px;
}
ssi-targeting-options ssi-nextdoor-options .targeting-options-panel .targeting-options-form .nextdoor-boundary-select .select-placeholder {
  font-size: 14px;
  font-weight: 700;
  color: #6a759a;
  text-wrap: nowrap;
  padding-left: 25px;
}
ssi-targeting-options ssi-nextdoor-options .targeting-options-panel .targeting-options-form .nextdoor-boundary-select .selected-options-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
ssi-targeting-options ssi-nextdoor-options .targeting-options-panel .targeting-options-form .nextdoor-boundary-select .selected-options-container .chips-container {
  display: flex;
  align-items: center;
  overflow: hidden;
}
ssi-targeting-options ssi-nextdoor-options .targeting-options-panel .targeting-options-form .nextdoor-boundary-select .selected-options-container .chips-container .chip {
  height: 36px;
  background-color: #f4f4fa;
  border-radius: 8px;
  padding: 0 10px;
  white-space: nowrap;
  margin-right: 5px;
  display: flex;
  align-items: center;
}
ssi-targeting-options ssi-nextdoor-options .targeting-options-panel .targeting-options-form .nextdoor-boundary-select .selected-options-container .chips-container .chip span {
  font-size: 14px;
  color: #101525;
  font-weight: 900;
  margin: 0;
  margin-left: 10px;
}
ssi-targeting-options ssi-nextdoor-options .targeting-options-panel .targeting-options-form .nextdoor-boundary-select .selected-options-container .chips-container .chip i {
  font-size: 8px;
  color: #43537f;
  font-weight: 500;
  margin-left: 10px;
  cursor: pointer;
}
ssi-targeting-options ssi-nextdoor-options .targeting-options-panel .targeting-options-form .nextdoor-boundary-select .menu-header {
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
ssi-targeting-options ssi-nextdoor-options .targeting-options-panel .targeting-options-form .nextdoor-boundary-select .menu-header span {
  margin-right: auto;
}
ssi-targeting-options ssi-nextdoor-options .targeting-options-panel .targeting-options-form .nextdoor-boundary-select .menu-header .collapse-icon {
  color: #14bae3;
  font-weight: 900;
  font-size: 12px;
  margin-left: 5px;
  margin-right: 8px;
  transform: rotate(180deg);
  cursor: pointer;
}
ssi-targeting-options ssi-nextdoor-options .targeting-options-panel .targeting-options-form .nextdoor-boundary-select .menu-header .no-boundaries-selected-label {
  color: #6a759a;
  font-weight: 700;
  font-style: italic;
}