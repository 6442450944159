ssi-analytics ssi-marketing ssi-report ssi-organic-reach .organic-reach-container .section-body {
  padding: 70px 30px 70px 30px;
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  display: flex;
}
@media print {
  ssi-analytics ssi-marketing ssi-report ssi-organic-reach .organic-reach-container .section-body {
    display: block;
    page-break-inside: avoid;
  }
}
ssi-analytics ssi-marketing ssi-report ssi-organic-reach .organic-reach-container .section-body ssi-highchart {
  width: 100%;
  height: 365px;
}
ssi-analytics ssi-marketing ssi-report ssi-organic-reach .organic-reach-container .section-body ssi-highchart highcharts-chart .highcharts-container,
ssi-analytics ssi-marketing ssi-report ssi-organic-reach .organic-reach-container .section-body ssi-highchart highcharts-chart .highcharts-root {
  overflow: visible !important;
}
ssi-analytics ssi-marketing ssi-report ssi-organic-reach .organic-reach-container .section-body ssi-highchart highcharts-chart .highcharts-axis-title {
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: nowrap;
}
ssi-analytics ssi-marketing ssi-report ssi-organic-reach .organic-reach-container .section-body ssi-highchart highcharts-chart .highcharts-axis-labels text {
  font-weight: 900;
}
ssi-analytics ssi-marketing ssi-report ssi-organic-reach .organic-reach-container .section-body ssi-highchart highcharts-chart .highcharts-xaxis-labels {
  transform: translate(0, 1em);
}
ssi-analytics ssi-marketing ssi-report ssi-organic-reach .organic-reach-container .section-body .total-reach-card-container {
  flex: 0 0 auto;
  height: 280px;
}
ssi-analytics ssi-marketing ssi-report ssi-organic-reach .organic-reach-container .section-body .total-reach-card-container ssi-total-stat-card .tsc {
  height: 100%;
}