@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-widget-monitoring-stream {
  height: 100%;
  width: 100%;

  .posts-container {
    height: 100%;
    width: 100%;
    background-color: #fff;

    .posts-wrapper {
      height: 100%;
      width: 100%;
      overflow-y: auto;

      //   &.two-columns {
      //     .scrollable-content {
      //       .post-wrapper {
      //         flex-basis: calc(50% - 20px);
      //       }
      //     }
      //   }

      .scrollable-content {
        // element added by virtual scroll directive
        display: flex;
        flex-wrap: wrap;

        .post-wrapper {
          flex-basis: 100%;
          margin: 10px 10px 0 10px;

          ssi-insights-post {
            .insights-post-container {
              .post-body {
                height: 140px;
              }
            }
          }
        }
      }
    }

    .no-posts-wrapper {
      padding: 6px 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
}
