@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-engagement ssi-response-times {
  font-size: 1rem;

  .section-chart-wrap {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 2em;
    box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
    border-radius: 1.2em;
    width: 100%;

    @media print {
      display: block;
      page-break-inside: avoid;
    }

    ssi-highchart {
      flex: 1 0 auto;
      padding: 5.2em 3em 5.2em 4em;

      .highcharts-axis-title {
        font-size: 10px;
        font-weight: 900;
        letter-spacing: 1px;
        text-transform: uppercase !important;
        white-space: nowrap;
      }

      .highcharts-axis-labels {
        text {
          font-weight: 900;
        }
      }

      .highcharts-data-label text {
        font-family: 'Lato', 'Arial', sans-serif;
        font-weight: 900 !important;
        font-size: 12px !important;
      }
    }

    .section-chart-meta {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex: 0 1 30em;
      margin: 0;
      padding: 0;

      &-item {
        display: flex;
        flex: 1 1 auto;
        height: 7.6em;
        width: 30em;
        border-radius: 1.2em;
        box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
        margin: 1em 0;
        padding: 1.2em 1.2em 2em;

        @media print {
          border: 1px solid black;
          margin: 5px;
        }
      }
    }
  }

  .section-chart-meta-icon {
    display: inline-block;
    height: 3.6em;
    width: 3.6em;
    overflow: hidden;
    vertical-align: super;

    i {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      color: #fff;
      line-height: 1;
      background-color: $secondary-blue;

      &.fa-twitter,
      &.ssi-x-logo {
        font-size: 1.3em;
        background-color: #1da1f2;
      }

      &.fa-facebook {
        font-size: 1.3em;
        background-color: #1877f2;
      }

      &.fa-linkedin {
        font-size: 1.3em;
        background-color: #2867b2;
      }

      &.fa-youtube-play {
        font-size: 1.3em;
        background-color: #f00;
      }

      &.fa-instagram {
        font-size: 1.3em;
        background: linear-gradient(180deg, #635fcc, #d43082 37%, #fec86b 100%);
      }

      &.ssi-whatsapp {
        font-size: 1.2em;
        background-color: #56cd63;
      }
    }
  }

  .section-chart-meta-data {
    display: inline-block;
    margin: 0 0 0 0.9em;
    padding: 0.4em 0 0;

    p {
      font-weight: 700;
      text-rendering: geometricPrecision;
      color: $lmode-helper-text;
      line-height: 1.4em;
      margin: 0;

      &:first-child {
        font-size: 1.6em;
        font-weight: 900;
        color: #44537f;
      }
    }
  }

  .section-chart-meta-data-diff {
    display: inline-block;
    align-self: center;
    margin-left: auto;
    font-weight: 900;
    text-rendering: geometricPrecision;
    color: $secondary-red;

    &-less {
      color: #12aca4;

      .ssi-line-arrow-small-up {
        transform: rotate(180deg);
      }
    }

    &-none {
      display: inline-block;
      font-weight: 900;
      letter-spacing: 0.1em;
      color: $lmode-helper-text;
      text-transform: uppercase;
      font-size: 1em;
      margin: 1em 0 0;

      & + i {
        display: none;
      }
    }

    &-icon {
      font-size: 1em;
    }

    &-stat {
      display: inline-block;
      font-weight: inherit;
      margin: 0;
      font-size: 1.4em;
    }
  }
}
