@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../angularjs/common/sass/mixins';
@import '../../../../../angularjs/common/sass/variables';
@import '../../../../../../library/sass/live-chat/colours.scss';
@import '~/styles/colors';

$offline-colour: $lmode-body-text;
$online-colour: $primary-active;
$unit: 2.5px;

ssi-live-chat-conversation-statistics {
  display: flex;
  flex-basis: 72px;
  flex-grow: 1;
  height: 72px;

  * {
    display: flex;
  }

  & > div {
    flex-grow: 1;

    .chat-statistics {
      flex-grow: 1;
      list-style: none;
      margin: 0;
      padding: 0;

      .widget-label {
        display: none;
      }

      .unassigned,
      .assigned,
      .ongoing {
        .icon span.quantity-hide {
          display: none !important; // Live chat tally specificity needs flattening/refactor
        }
      }

      li {
        align-items: center;
        margin: 0;
        padding: 0;

        &.disabled {
          cursor: not-allowed;
          opacity: 0.65;
        }

        &.empty {
          color: $offline-colour;
          .icon {
            &:after {
              background-color: $dmode-body-text;
            }
          }
        }
      }
    }

    &.offline {
      .chat-statistics {
        span {
          color: $offline-colour;
        }
      }
    }

    &.online {
      .chat-statistics {
        color: $online-colour;

        .icon:after {
          background-color: $online-colour;
        }
      }
    }
  }
}
