ssi-publisher-mentions-autocomplete-menu .mentions-list-container {
  display: block;
  border-radius: 15px;
  box-shadow: 0px 5px 10px 0px rgba(131, 142, 171, 0.35);
  padding: 20px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  max-height: 215px;
  min-width: 310px;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  overflow: auto;
  scrollbar-color: #14bae3 #14bae3;
}
ssi-publisher-mentions-autocomplete-menu .mentions-list-container li {
  margin-bottom: 7px;
  padding: 0 10px;
}
ssi-publisher-mentions-autocomplete-menu .mentions-list-container li span {
  font-size: 14px;
  line-height: 40px;
  color: #43537f;
  font-weight: 900;
}
ssi-publisher-mentions-autocomplete-menu .mentions-list-container li span.username {
  font-weight: 700;
}
ssi-publisher-mentions-autocomplete-menu .mentions-list-container li:hover, ssi-publisher-mentions-autocomplete-menu .mentions-list-container li.active {
  background-color: #f8f9fd;
  border-radius: 10px;
}
ssi-publisher-mentions-autocomplete-menu .mentions-list-container li a {
  text-decoration: none;
}
ssi-publisher-mentions-autocomplete-menu .mentions-list-container img {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}