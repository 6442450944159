ssi-engagement ssi-engagement-times {
  font-size: 1rem;
}
ssi-engagement ssi-engagement-times .engagement-times-section ssi-highchart {
  width: 100%;
  height: 34em;
}
ssi-engagement ssi-engagement-times .engagement-times-section ssi-highchart highcharts-chart .highcharts-container,
ssi-engagement ssi-engagement-times .engagement-times-section ssi-highchart highcharts-chart .highcharts-root {
  overflow: visible !important;
}
ssi-engagement ssi-engagement-times .engagement-times-section ssi-highchart highcharts-chart .highcharts-axis-title {
  font-size: 1em;
  font-weight: 900;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  white-space: nowrap;
}
ssi-engagement ssi-engagement-times .engagement-times-section ssi-highchart highcharts-chart .highcharts-axis-labels text {
  font-weight: 900;
}
ssi-engagement ssi-engagement-times .engagement-times-section ssi-highchart highcharts-chart .highcharts-xaxis-labels {
  transform: translate(0, 1em);
}
ssi-engagement ssi-engagement-times .section-chart-wrap {
  padding: 3em 3em 6em 3em;
  background-color: #fff;
  border-radius: 1.2em;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
}
ssi-engagement ssi-engagement-times .top-row {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3em;
}
ssi-engagement ssi-engagement-times .top-row .best-hours-container {
  display: flex;
  flex: 0 0 auto;
  border-radius: 0.5em;
  border: 0.1em solid #425dec;
  padding: 2em;
}
ssi-engagement ssi-engagement-times .top-row .best-hours-container .left {
  margin-right: 8em;
}
ssi-engagement ssi-engagement-times .top-row .best-hours-container .left span {
  color: #838eab;
  font-size: 1em;
  font-weight: 900;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-rendering: geometricPrecision;
}
ssi-engagement ssi-engagement-times .top-row .best-hours-container .middle {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
ssi-engagement ssi-engagement-times .top-row .best-hours-container .middle span {
  color: #838eab;
  font-size: 1.4em;
  font-weight: 700;
  text-rendering: geometricPrecision;
}
ssi-engagement ssi-engagement-times .top-row .best-hours-container .middle .hours span {
  color: #101525;
  font-size: 2em;
  font-weight: 900;
}
ssi-engagement ssi-engagement-times .top-row .best-hours-container .right {
  display: flex;
  align-items: center;
  margin-left: 2em;
}
ssi-engagement ssi-engagement-times .top-row .best-hours-container .right i {
  font-size: 3.2em;
  color: #b2f0ff;
}
ssi-engagement ssi-engagement-times .chart-legend {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  margin-top: 4em;
}
ssi-engagement ssi-engagement-times .chart-legend .legend-item {
  display: flex;
  align-items: center;
  margin-right: 6em;
}
ssi-engagement ssi-engagement-times .chart-legend .legend-item:first-child {
  margin-right: 3em;
}
ssi-engagement ssi-engagement-times .chart-legend .legend-item span {
  color: #43537f;
  font-size: 1em;
  font-weight: 900;
  letter-spacing: 0.1em;
}
ssi-engagement ssi-engagement-times .chart-legend .legend-item .circle {
  height: 1.8em;
  width: 1.8em;
  border-radius: 50%;
  background-color: #000;
  margin-right: 1em;
  -webkit-print-color-adjust: exact;
}
ssi-engagement ssi-engagement-times .chart-legend .legend-item.great .circle {
  background-color: #425dec !important;
}
ssi-engagement ssi-engagement-times .chart-legend .legend-item.good .circle {
  background-color: #14bae3 !important;
}
ssi-engagement ssi-engagement-times .chart-legend .legend-item.okay .circle {
  background-color: #72d6ee !important;
}
ssi-engagement ssi-engagement-times .chart-legend .legend-item.not-great .circle {
  background-color: #b2f0ff !important;
}
ssi-engagement ssi-engagement-times .chart-legend .legend-item.not-good .circle {
  background-color: #e7f7f6 !important;
}
ssi-engagement ssi-engagement-times .section-tip-wrap {
  position: absolute;
  max-width: 80%;
  right: 0;
  top: 0.5em;
}
ssi-engagement ssi-engagement-times .section-tip {
  display: inline-flex;
  flex-direction: row-reverse;
  background-color: #fff;
  border-radius: 1.2em;
  padding: 1em;
  box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
}
ssi-engagement ssi-engagement-times .section-tip-icon {
  display: flex;
  flex: 0 0 3.6em;
  align-items: center;
  justify-content: center;
  height: 3.6em;
  width: 3.6em;
  background-color: #f4f4fa;
  border-radius: 50%;
  color: #14bae3;
  border: 0;
  outline: 0;
}
ssi-engagement ssi-engagement-times .section-tip-icon:active {
  background-color: #e2e2f2;
}
ssi-engagement ssi-engagement-times .section-tip-icon:hover + p {
  display: inline-flex !important;
}
ssi-engagement ssi-engagement-times .section-tip-icon i {
  height: 1.4rem;
  font-size: 1.8em;
}
ssi-engagement ssi-engagement-times .section-tip p {
  display: none;
  align-items: center;
  margin: 0 0.8em;
  color: #43537f;
  font-weight: 700;
  text-rendering: geometricPrecision;
}