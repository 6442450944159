@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

.filters-text-filter {
  background-color: transparent;
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom: 1px solid #6e7579;
  font-style: italic;
  font-weight: 500;
  height: 26px;
  padding: 10px;
  box-shadow: none;
  font-size: 12px;
  width: 100%;
  outline: none;
  margin-bottom: 8px;

  &:focus {
    border-bottom: 1px solid $primary-active;
  }
}

.validation-filters-wrap {
  max-width: 330px;
  margin: 0 auto;
}

.validation-filters-head {
  display: flex;
  justify-content: space-between;
}

.validation-filters-subtitle {
  font-size: 12px;
  font-style: italic;
  padding-left: 10px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 20px;
}

.validation-filters-actions {
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    color: $primary-active;
    font-style: italic;
    font-size: 11px;
    font-weight: 600;
    cursor: pointer;

    &:after {
      content: '|';
      padding: 0 10px;
      color: #6e7579;
      cursor: default;
    }

    &:last-child:after {
      content: normal;
    }
  }
}

.validation-filters-scroll {
  max-height: calc(100vh - 325px);
  overflow-y: auto;
}

.validation-filters {
  display: block;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.validation-filter {
  display: block;
  margin-bottom: 8px;
  border-radius: 20px;
  background-color: #464d56;
  padding: 3px 12px;
  text-align: left;
  cursor: pointer;
}

.validation-filter-status {
  color: #6e7579;
  float: right;
  margin-top: 3px;
}

.validation-filter.validation-filter-active {
  background-color: $primary-active;
}

.validation-filter-disabled {
  pointer-events: none;
  opacity: 0.4;
}

.validation-filter-active .validation-filter-status {
  color: #fff;
}

.validation-filter-input {
  display: none;

  &:checked ~ .validation-filter {
    background: $primary-active;

    .validation-filter-status {
      color: #fff;
    }
  }
}

.validation-filter-input-icon {
  float: right;
  margin: 3px 5px 0 0;
}

.filter-actions {
  margin-top: 35px;
}

.apply-filter-button {
  background-color: #464d56;
  border: none;
  min-height: 24px;
  width: 110px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 600;
  color: #868a8f;
  padding: 12px;
  margin: 0 5px;
  outline: 0;
}

.apply-filter-button-primary {
  background-color: $primary-active;
  color: #fff;
}

.reset-list-style {
  padding-left: 0;
  margin-bottom: 0;

  li {
    display: block;
  }
}

.validation-filters-title {
  color: #fff;
  font-size: 16px;
  margin-bottom: 50px;
  font-weight: 500;
}
