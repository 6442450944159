@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-cl-dropdown-select-c {
  .cl-dropdown-select-c-container {
    ssi-dropdown-select-2 {
      // width: 100%;

      ssi-social-network-icon {
        margin-right: -8px;
      }

      .dd-head {
        // width: 100%;
      }

      .dd-body {
        // width: 100%;
        ul {
          max-height: 400px !important;
        }
      }

      .select-placeholder {
        font-size: 14px;
        font-weight: 700;
        color: #6a759a;
      }

      .select-accounts-label {
        display: flex;
        align-items: center;

        span {
          padding-left: 12px;
          color: #101525;
          font-weight: 900;
        }
      }
    }
  }
}
