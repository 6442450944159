@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../angularjs/common/sass/variables';
@import '../../../../angularjs/common/sass/mixins';
@import '../../../../angularjs/common/sass/variables.scss';

ssi-inline-notes {
  font-size: 1rem;

  .footer {
    display: block;
    text-align: right;
    width: 100%;
    max-width: 45.6em;
    margin: 0.2em 0 0.6em;
  }

  .footer-inverse {
    margin: 0.2em 0 0.6em auto;
    text-align: left;
  }

  .footer-button button {
    font-size: 1em !important;
  }

  .footer-button button span {
    font-size: 1.2em !important;
    font-weight: 900 !important;
    text-rendering: geometricPrecision;
  }
}
