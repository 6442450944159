ssi-checkbox .checkbox-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f0f3f9;
  border: 1px solid #c3c9d8;
  border-radius: 10px;
}
ssi-checkbox .checkbox-container.active {
  background-color: #e4faff;
  border-color: #14bae3;
}
ssi-checkbox .checkbox-container label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  white-space: nowrap;
  color: #43537f;
  font-weight: 900;
  padding: 12px 20px;
}
ssi-checkbox .checkbox-container label:hover {
  cursor: pointer;
}
ssi-checkbox .checkbox-container label span {
  margin-right: 15px;
}
ssi-checkbox .checkbox-container .leading-icon {
  margin-right: 5px;
  color: #43537f;
  font-size: 16px;
}
ssi-checkbox .checkbox-container .check-icon {
  position: absolute;
  display: none;
  top: 16px;
  right: 22px;
  color: #fff;
  font-size: 12px;
}
ssi-checkbox .checkbox-container input {
  margin: 0;
  margin-left: auto;
  appearance: none;
  outline: none !important;
  border: 1px solid #d7dbe4;
  background-color: #fff;
  border-radius: 2px;
  padding: 7px;
}
ssi-checkbox .checkbox-container input:checked {
  border-color: #14bae3;
  background-color: #14bae3;
}
ssi-checkbox .checkbox-container input:checked + .check-icon {
  display: block;
}