ssi-social-network-select .social-network-select {
  padding: 0;
}
ssi-social-network-select .social-network-select li {
  position: relative;
  letter-spacing: 1px;
  margin: 0 2em 0 0;
  display: inline-block;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 900;
  color: #43537f;
  cursor: pointer;
}
ssi-social-network-select .social-network-select li::before {
  content: "";
  transform: scaleX(0);
  visibility: hidden;
  position: absolute;
  top: 22px;
  height: 2px;
  width: 80%;
  left: 10%;
  background-color: #14bae3;
  transition: all 0.3s ease-in-out 0s;
}
ssi-social-network-select .social-network-select li.active:before, ssi-social-network-select .social-network-select li:hover:before {
  transform: scaleX(1);
  visibility: visible;
}
ssi-social-network-select .social-network-select li:last-child {
  margin-right: 0;
}