@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../angularjs/common/sass/variables.scss';
@import '~/styles/colors';

$unit: 2.5px;

ssi-live-chat-note-form {
  color: #a6b7c0;
  flex-flow: column nowrap;
  flex-shrink: 0;
  font-size: 0.85em;
  margin: 0 ($unit * 8);

  form {
    flex-flow: column nowrap;

    .form-inner {
      background-color: #fff;
      border-radius: ($unit * 8);
      border-top-right-radius: 0;
      flex-flow: column nowrap;
      font-size: 0.85em;
      margin-top: 10px;
      padding: ($unit * 8) ($unit * 8) ($unit * 4) ($unit * 8);

      textarea,
      textarea:focus {
        box-shadow: none;
        border: none;
        display: block;
        resize: none;
        width: 100%;
      }

      .author {
        font-style: italic;
        font-weight: 400;
        justify-content: flex-end;

        .name {
          margin-left: ($unit * 1);
        }
      }
    }
  }

  .form-footer,
  .footer-information,
  .footer-options {
    & > li {
      button {
        align-items: center;
        background-color: transparent;
        border: none;
        border-radius: ($unit * 8);
        font-weight: 500;
        justify-content: center;
        padding: ($unit * 2) ($unit * 4);

        &.save-button {
          background-color: $primary-active;
          border: none;
          border-radius: 20px;
          color: $text-light;
          display: flex;
          font-size: 1em;
          font-style: italic;
          font-weight: 500;
          justify-content: center;
          margin: 20px 0;
          padding: 3px 20px;
        }
      }
    }
  }

  ul {
    flex-flow: row nowrap;
    list-style: none;
    margin: 0;
    padding: 0;

    & > li {
      align-items: center;
      flex-shrink: 0;
      justify-content: space-between;
      margin: 0;
      padding: 0;
    }
  }

  .form-footer {
    justify-content: flex-end;

    .footer-information {
      color: #c9d8e0;
      flex-shrink: 0;
      font-style: italic;
      justify-content: flex-end;
    }
  }

  .footer-options.expanded {
    justify-content: flex-end;
    margin-bottom: ($unit * 4);
    margin-top: ($unit * 8);

    button {
      font-style: italic;
      font-size: 0.85em;
    }
  }
}
