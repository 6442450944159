ssi-tags-select-create .tags-select-create-container ssi-dropdown-select-2 {
  width: 100%;
}
ssi-tags-select-create .tags-select-create-container ssi-dropdown-select-2 .dd-head {
  width: 100%;
  padding: 0 35px 0 5px;
}
ssi-tags-select-create .tags-select-create-container ssi-dropdown-select-2 .dd-body {
  width: 100%;
}
ssi-tags-select-create .tags-select-create-container ssi-dropdown-select-2 .dd-body ul {
  max-height: 400px !important;
}
ssi-tags-select-create .tags-select-create-container.no-selected-options {
  width: 100%;
}
ssi-tags-select-create .tags-select-create-container.no-selected-options ssi-dropdown-select-2 .dd-head {
  padding: 0 30px 0 30px;
}
ssi-tags-select-create .tags-select-create-container .select-placeholder {
  font-size: 14px;
  font-weight: 700;
  color: #6a759a;
  text-wrap: nowrap;
}
ssi-tags-select-create .tags-select-create-container .selected-options-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
ssi-tags-select-create .tags-select-create-container .selected-options-container .chips-container {
  display: flex;
  align-items: center;
  overflow: hidden;
}
ssi-tags-select-create .tags-select-create-container .selected-options-container .chips-container .chip {
  height: 36px;
  background-color: #f4f4fa;
  border-radius: 8px;
  padding: 0 10px;
  white-space: nowrap;
  margin-right: 5px;
  display: flex;
  align-items: center;
}
ssi-tags-select-create .tags-select-create-container .selected-options-container .chips-container .chip span {
  font-size: 14px;
  color: #101525;
  font-weight: 900;
  margin: 0;
  margin-left: 10px;
}
ssi-tags-select-create .tags-select-create-container .selected-options-container .chips-container .chip i {
  font-size: 8px;
  color: #43537f;
  font-weight: 500;
  margin-left: 10px;
  cursor: pointer;
}
ssi-tags-select-create .tags-select-create-container .selected-options-container .chips-container .chip i:first-child {
  font-size: 19px;
  color: #838eab;
}
ssi-tags-select-create .tags-select-create-container .empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding-right: 10px;
}
ssi-tags-select-create .tags-select-create-container .empty-state i {
  margin-top: 20px;
  color: #b4bbce;
  font-size: 30px;
}
ssi-tags-select-create .tags-select-create-container .empty-state .title {
  margin-top: 15px;
  color: #43537f;
  font-size: 16px;
  font-weight: 900;
}
ssi-tags-select-create .tags-select-create-container .empty-state .desc {
  margin-bottom: 20px;
  color: #43537f;
  font-size: 14px;
  font-weight: 700;
  padding: 0 24px;
  text-align: center;
}
ssi-tags-select-create .tags-select-create-container .empty-state button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  width: calc(100% + 20px);
}
ssi-tags-select-create .tags-select-create-container .empty-state button i {
  margin: 0 -8px 0 12px;
  font-size: 12px;
  font-weight: 900;
  color: #fff;
}
ssi-tags-select-create .tags-select-create-container .empty-state button span {
  white-space: nowrap;
}