ssi-live-chat-navigation {
  background: linear-gradient(to right, rgb(237, 241, 243) -1%, rgb(237, 241, 243) 97%, rgb(225, 229, 231) 100%) #EDF1F3;
  display: flex;
  flex-basis: 72px;
  height: 72px;
  min-height: 72px;
  /* stylelint-disable */
  /* stylelint-enable */
}
ssi-live-chat-navigation * {
  display: flex;
}
ssi-live-chat-navigation > ul {
  flex-flow: row nowrap;
  flex-grow: 1;
  list-style: none;
  margin: 0;
  padding: 0;
  transform: none !important;
}
ssi-live-chat-navigation > ul > li {
  border: solid 2.5px #fff;
  border-radius: 30px;
  flex-grow: 1;
  flex-shrink: 0;
  margin: 15px 10px;
}
ssi-live-chat-navigation > ul > li.exit {
  background-color: #D8DFE5;
  position: relative;
}
ssi-live-chat-navigation > ul > li.exit .icon {
  left: 10px;
  position: absolute;
}
ssi-live-chat-navigation > ul > li.exit .text {
  margin-left: 10px;
}
ssi-live-chat-navigation > ul > li.date-range .icon, ssi-live-chat-navigation > ul > li.sorting .icon {
  margin-left: 10px;
}
ssi-live-chat-navigation > ul > li.date-range.show, ssi-live-chat-navigation > ul > li.sorting.show {
  display: flex !important;
}
ssi-live-chat-navigation > ul > li.date-range .text, ssi-live-chat-navigation > ul > li.sorting .text {
  font-style: italic;
}
ssi-live-chat-navigation > ul > li .dropdown-menu {
  border: none;
  border-radius: 20px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  transform: none !important;
  width: 100%;
  z-index: 9;
}
ssi-live-chat-navigation > ul > li .dropdown-menu > li {
  padding: 10px;
}
ssi-live-chat-navigation > ul > li button,
ssi-live-chat-navigation > ul > li select {
  align-items: center;
  background-color: transparent;
  border: none;
  color: #4f626c;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 0.9em;
  font-weight: 600;
  justify-content: center;
  padding: 5px 10px;
  position: inherit;
}
ssi-live-chat-navigation > ul > li button[disabled],
ssi-live-chat-navigation > ul > li select[disabled] {
  cursor: not-allowed;
  opacity: 0.65;
}
ssi-live-chat-navigation > ul > li button:focus,
ssi-live-chat-navigation > ul > li select:focus {
  outline: revert !important;
}
ssi-live-chat-navigation > ul > li button.show,
ssi-live-chat-navigation > ul > li select.show {
  display: flex !important;
}
ssi-live-chat-navigation > ul > li button > *,
ssi-live-chat-navigation > ul > li select > * {
  display: inline;
}
ssi-live-chat-navigation > ul > li .icon {
  font-size: 1em;
  font-weight: 300;
}
@media only screen and (max-width: 1350px) {
  ssi-live-chat-navigation > ul > li .icon {
    font-size: 0.85em;
  }
  ssi-live-chat-navigation > ul > li button {
    font-size: 0.75em;
  }
}

ssi-live-chat-next-chat {
  background: linear-gradient(to right, rgb(237, 241, 243) -1%, rgb(237, 241, 243) 97%, rgb(225, 229, 231) 100%) #EDF1F3;
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  /* stylelint-disable */
  /* stylelint-enable */
}
ssi-live-chat-next-chat * {
  display: flex;
}
ssi-live-chat-next-chat.expanded {
  flex-grow: 1;
}
ssi-live-chat-next-chat .browser-offline {
  align-items: flex-start;
  background-color: #faeeeb;
  border: solid 2.5px #e09e90;
  color: #e09e90;
  flex-flow: column;
  flex-grow: 1;
  justify-content: center;
  line-height: 25px;
  left: 0;
  padding: 5px;
  position: absolute;
  top: 0;
  width: 100%;
}
ssi-live-chat-next-chat .no-chats-visible {
  justify-content: center;
}
ssi-live-chat-next-chat .no-chats-visible > div {
  align-items: center;
  flex-flow: column nowrap;
}
ssi-live-chat-next-chat > div {
  align-items: center;
  flex-grow: 1;
}
ssi-live-chat-next-chat > div.statistics-container {
  align-items: center;
  border-bottom: solid 2.5px #fff;
  flex-basis: 72px;
  flex-flow: row nowrap;
  flex-grow: 0;
  height: 72px;
  width: 100%;
}
ssi-live-chat-next-chat > div.statistics-container ssi-live-chat-conversation-statistics {
  flex-grow: 1;
}
ssi-live-chat-next-chat > div button.btn {
  background-color: #43ADE8;
  border: solid 2.5px #fff;
  border-radius: 20px;
  color: #fff;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 0.85em;
  font-weight: 500;
  justify-content: center;
  margin: 10px 20px;
  min-width: 140px;
  outline: revert;
  padding: 10px 20px;
}
ssi-live-chat-next-chat > div button.btn.next-chat-button[disabled], ssi-live-chat-next-chat > div button.btn.next-chat-button[disabled]:hover {
  background-color: #607987;
  color: #fff;
}
ssi-live-chat-next-chat > div button.btn.next-chat-button > span {
  align-items: center;
  display: inline-flex;
}
ssi-live-chat-next-chat > div button.btn.next-chat-button > span i {
  margin-right: 5px;
}
ssi-live-chat-next-chat > div button.btn:focus {
  outline: revert;
}
ssi-live-chat-next-chat > div button.btn:hover {
  background-color: #fff;
  color: #43ade8;
}
ssi-live-chat-next-chat > div ssi-live-chat-conversation-statistics .chat-statistics {
  justify-content: space-around;
  list-style: none;
  margin: 0;
  padding: 0;
}
ssi-live-chat-next-chat > div ssi-live-chat-conversation-statistics .chat-statistics li {
  cursor: pointer;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-live-chat-next-chat > div ssi-live-chat-conversation-statistics .chat-statistics li .icon {
  align-items: center;
  font-size: 14px;
  margin: 0 5px;
}
ssi-live-chat-next-chat > div ssi-live-chat-conversation-statistics .chat-statistics li .icon i {
  font-size: inherit;
}
ssi-live-chat-next-chat > div ssi-live-chat-conversation-statistics .chat-statistics li .icon span {
  margin: 0 5px;
}
ssi-live-chat-next-chat > div .encouragement {
  align-items: center;
  flex-flow: column nowrap;
  flex-shrink: 0;
  margin: 0 12% 2% 12%;
  text-align: center;
}
ssi-live-chat-next-chat > div .encouragement .encouragement-icon {
  color: #A4B5BE;
  font-size: 15vh;
}
ssi-live-chat-next-chat > div .encouragement .encouragement-message {
  color: #A4B5BE;
  font-size: 1.4em;
  font-weight: 600;
}
@media only screen and (max-width: 1350px) {
  ssi-live-chat-next-chat > div button.btn {
    font-size: 0.7em;
  }
}