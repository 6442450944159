@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-analytics ssi-marketing ssi-report ssi-follower-count {
  .follower-count-container {
    .section-body {
      display: flex;
      flex-direction: column;

      @media print {
        display: block;
      }

      .card {
        background-color: #fff;
        border-radius: 12px;
        box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.35);
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 160px;

        &:not(:last-child) {
          margin-right: 20px;
        }

        .half {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      .followers-by-gender {
        display: flex;
        flex-wrap: wrap;

        .card {
          padding: 40px;
          .half {
            &.top {
              .title {
                color: $lmode-helper-text;
                font-size: 10px;
                font-weight: 900;
                letter-spacing: 1px;
                line-height: 12px;
                text-transform: uppercase;
              }
              .percentage {
                color: $lmode-title-text;
                font-size: 28px;
                font-weight: 900;
                i {
                  color: $lmode-helper-text;
                  font-size: 32px;
                  margin-left: 10px;
                }
              }
            }
          }
        }
      }

      .followers-by-network {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;

        @media print {
          display: block;
        }

        .card {
          padding: 15px;

          @media print {
            page-break-inside: avoid;
            display: inline-block;
            width: auto;
          }

          .half {
            &.top {
              .network-info {
                display: flex;
                .network-icon {
                  margin-right: 10px;
                }
                .network-name {
                  color: $lmode-body-text;
                  font-size: 16px;
                  font-weight: 900;
                }
                .accounts-count {
                  color: $lmode-helper-text;
                  font-size: 14px;
                  font-weight: 500;
                }
              }
            }
            &.bottom {
              padding-left: 10px;

              .followers-current-count {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                margin-left: auto;
                > * {
                  &:not(:first-child) {
                    margin-top: 5px;
                  }
                }

                .count {
                  color: $lmode-title-text;
                  font-size: 20px;
                  font-weight: 900;
                }
                .break-line {
                  height: 4px;
                  width: 60px;
                  border-radius: 100px;
                  background-color: $lblue-background;
                }
                > span {
                  color: $lmode-helper-text;
                  font-size: 10px;
                  font-weight: 900;
                  letter-spacing: 1px;
                  text-transform: uppercase;
                }
              }
            }
          }
        }
      }
    }
  }
}
