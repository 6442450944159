ssi-sentiment-amend-box {
  font-size: 1rem;
}
ssi-sentiment-amend-box .message-box-actions-menu {
  position: relative;
  display: inline-block;
  background: #fff;
  padding: 3em 2.5em 3.5em;
  border-radius: 1em;
  box-shadow: 0 0.5em 1em 0 rgba(131, 142, 171, 0.35);
  margin: -3em 0;
}
ssi-sentiment-amend-box .message-box-actions-menu.message-box-actions-menu-selfpenned {
  background-color: #ecf0fd;
}
ssi-sentiment-amend-box .message-box-actions-menu-item-body {
  padding: 2em 0 0;
}
ssi-sentiment-amend-box .menu-item-icon {
  color: #838eab;
  margin-right: 0.8em;
  vertical-align: top;
  line-height: 2.4em;
}
ssi-sentiment-amend-box .menu-item-icon:before {
  font-size: 1.4em;
}
ssi-sentiment-amend-box .menu-item-icon-warning {
  color: #e9856f;
}
ssi-sentiment-amend-box .message-box-actions-menu-item-button {
  padding: 0.2em 1.3em 0.1em 0.8em;
  margin: 0 -0.8em;
  border-radius: 10em;
  cursor: pointer;
}
ssi-sentiment-amend-box .message-box-actions-menu-item-button .menu-item-title {
  display: inline;
  color: #43537f;
  font-size: 1.4em;
  line-height: 2em;
  font-weight: 900;
  vertical-align: top;
  margin: 0;
}
ssi-sentiment-amend-box .message-box-actions-menu-item-button .menu-item-title:hover {
  color: #14bae3;
}
ssi-sentiment-amend-box .message-box-actions-menu-item-button:hover {
  background: #f8f9fd;
}