ssi-emoji-picker .emoji-container {
  padding: 22px 18px 0;
}
ssi-emoji-picker p {
  font-size: 10px;
  letter-spacing: 1px;
  font-weight: 900;
  text-rendering: geometricPrecision;
  text-transform: uppercase;
}
ssi-emoji-picker .emoji-input {
  position: relative;
  color: #43537f;
  border-bottom: 1px solid #c3c9d8;
  margin: 0 0 18px;
}
ssi-emoji-picker .emoji-input .form-control {
  background-color: transparent;
  font-size: 14px;
  padding: 0 0 8px;
  font-weight: 700;
  color: inherit;
}
ssi-emoji-picker .emoji-input .form-control::placeholder {
  color: #6a759a;
}
ssi-emoji-picker .emoji-input .ssi {
  position: absolute;
  right: 0;
  top: 4px;
  font-size: 12px;
  color: #43537f;
  pointer-events: none;
}
ssi-emoji-picker .emoji-categories,
ssi-emoji-picker .emoji-list,
ssi-emoji-picker .freq-emoji-list {
  display: flex;
  flex-wrap: wrap;
}
ssi-emoji-picker .freq-emoji-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -6px 10px -4px;
}
ssi-emoji-picker .emoji-list {
  max-height: 149px;
  min-height: 149px;
  overflow-y: auto;
  overflow-x: hidden;
  align-content: flex-start;
  margin: 0 -20px 0 -4px;
}
ssi-emoji-picker .emojis-row {
  display: flex;
  align-items: center;
  justify-content: start;
}
ssi-emoji-picker .emoji-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
ssi-emoji-picker .emoji-icon {
  cursor: pointer;
  text-align: center;
  margin: 0 !important;
}
ssi-emoji-picker .emoji-icon .joypixels-24-people {
  margin: 0 2.4px;
}
ssi-emoji-picker .emoji-icon:hover {
  text-decoration: none;
}
ssi-emoji-picker .emoji-categories {
  justify-content: space-between;
  border-top: 1px solid #c3c9d8;
  padding: 10px 18px 12px;
  margin: 0 -20px 0 -18px;
}
ssi-emoji-picker .emoji-categories a {
  cursor: pointer;
}
ssi-emoji-picker .emoji-categories a i {
  color: #43537f;
  font-size: 14px !important;
}
ssi-emoji-picker .emoji-categories a:hover {
  text-decoration: none;
  color: #3c4b56;
}
ssi-emoji-picker .emoji-categories a.active,
ssi-emoji-picker .emoji-categories a.active:hover {
  color: #14bae3;
}
ssi-emoji-picker .emoji-categories a.active i,
ssi-emoji-picker .emoji-categories a.active:hover i {
  color: #14bae3 !important;
}