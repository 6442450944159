ssi-icon,
svg-icon {
  display: inline-block;
  height: auto;
  width: auto;
}

ssi-icon {
  font-size: 1rem;
  line-height: 0;
}

svg-icon {
  fill: currentColor;
}