@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-live-chat-widgets {
  h1 {
    font-weight: 900;
    color: $lmode-helper-text;
    font-size: 1.25em;
  }
  .live-chat-widgets {
    background-color: $grey-background;
    padding: 2em 4em;
  }
  .container {
    position: relative;
    // background-color: #FFF;
    border-radius: 25px;
    padding: 2em 0;
  }
  .segment {
    background: #fff;
    border: 2.5em solid #fff;
    border-radius: 25px;
    margin-bottom: 2em;
  }
  .push-mode-status {
    position: relative;
    color: $lmode-body-text;
    font-weight: 700;
    padding: 0.8em 1.2em 0.8em 44px;
    background-color: $lmgrey-background;
    display: inline-block;
    border-radius: 50px;
    cursor: pointer;
    &:before {
      content: '';
      width: 20px;
      height: 20px;
      position: absolute;
      left: 15px;
      top: 10px;
      border-radius: 100%;
      background-color: red;
    }
    &.on {
      &:before {
        background-color: green;
      }
    }
    &.loading-service {
      &:before {
        background-color: transparent;
      }
    }
  }
  .widget-list {
    padding: 0 2em 2em 2em;
    list-style: none;
    li {
      margin-bottom: 2em;
      border-bottom: 4px solid $lmgrey-background;
      button {
        float: right;
      }
    }
    .widget-name {
      padding: 2em 0;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 900;
      line-height: 2.5em;
      color: $lmode-helper-text;
      letter-spacing: 1px;
      display: inline-block;
    }
  }

  .add-new-widget {
    padding: 0 2em 1em 0;
    button {
      float: right;
    }
  }

  .return-nav {
    position: absolute;
    top: -16px;
    width: 150px;
    left: 35px;
  }
}
