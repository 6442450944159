.my-details {
  background-color: #f0f3f9;
  padding: 2em 4em;
}
.my-details .container {
  position: relative;
  background-color: #fff;
  border-radius: 25px;
  padding: 6em 0;
}
.my-details .form {
  width: 300px;
  margin: 0 auto;
}
.my-details .form .form-submit {
  position: absolute;
  top: -16px;
  width: 190px;
  right: 0;
  text-align: center;
}
.my-details .avatar {
  text-align: center;
  margin: 2em 0;
}
.my-details .avatar i {
  margin: 0 0 0.4em 0;
  font-size: 40px;
  color: #838eab;
}
.my-details .avatar .avatar-label {
  margin: 0 0 2em 0;
  padding: 0 3em;
  display: block;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 900;
  line-height: 2.5em;
  color: #838eab;
  letter-spacing: 1px;
}
.my-details .avatar .avatar-image {
  position: relative;
  display: inline-block;
  margin: 0 0 2em 0;
}
.my-details .avatar .avatar-image img {
  width: 150px;
  border-radius: 50%;
}
.my-details .avatar .avatar-delete {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 0;
  right: 0;
  padding: 0;
  border-radius: 100%;
  border: 3px solid #fff;
  background-color: #fff1ed;
}
.my-details .avatar .avatar-delete i {
  margin: 0;
  font-size: 20px;
  color: #f88c68;
}
.my-details .avatar button {
  margin: 0;
}
.my-details orlo-text-input input:disabled {
  opacity: 0.6;
}