ssi-inline-notes {
  font-size: 1rem;
}
ssi-inline-notes .footer {
  display: block;
  text-align: right;
  width: 100%;
  max-width: 45.6em;
  margin: 0.2em 0 0.6em;
}
ssi-inline-notes .footer-inverse {
  margin: 0.2em 0 0.6em auto;
  text-align: left;
}
ssi-inline-notes .footer-button button {
  font-size: 1em !important;
}
ssi-inline-notes .footer-button button span {
  font-size: 1.2em !important;
  font-weight: 900 !important;
  text-rendering: geometricPrecision;
}