@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../angularjs/common/sass/variables';
@import '../../../../angularjs/common/sass/mixins';
@import '~/styles/colors';
@import '~/styles/variables';

ssi-inbox-search-results.conversation-detail {
  .list-select-option {
    & > a {
      background-color: transparent;
    }
  }
}

.list-select {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  list-style: none;
  margin: 0;
  padding: 0;

  .list-select-option {
    align-items: center;
    display: flex;
    flex-grow: 1;
    margin-bottom: $section-margin-width - 2; // not sure why this is needed

    a {
      background-color: $bg-dark-secondary;
      display: inline-block;
      padding: 4px $query-builder-padding-sm;
      font-size: 11px;
      color: #fff;
      font-weight: normal;
      flex: 1;
    }

    a:hover {
      text-decoration: none;
      background-color: $bg-dark-secondary-hover;
    }

    .list-select-option-icon-outer {
      margin-left: 8px;
      font-size: 13px;
      color: #c9d8e0;
      @include align-vertical(auto);
    }

    .list-select-option-icon-inner {
      float: right;
    }

    .icon-selected {
      color: $primary-active;
    }
  }

  .list-select-option.active {
    a {
      background-color: $primary-active;
    }

    a:hover {
      background-color: lighten($primary-active, 5%);
    }
  }

  * > * {
    display: flex;
  }
}

ssi-list-select.options-white {
  .list-select-option a {
    background-color: white !important;
    color: lighten(#000, 33.5%) !important;
  }
}

ssi-list-select.options-light {
  .list-select-option a {
    background-color: #f8f8f8 !important;
    color: $primary-active !important;
  }
}

.list-select.query-builder-width,
ssi-list-select.query-builder-width {
  .list-select-option a {
    flex-basis: $query-builder-item-inner-width;
    flex-grow: 0;
    flex-shrink: 0;
  }
}

ssi-list-select.options-light,
ssi-list-select.options-white {
  .list-select-option.active a,
  .list-select-option a:hover {
    background-color: $primary-active !important;
    color: white !important;
  }
}

ssi-list-select.options-lg {
  .list-select-option a {
    font-size: 15px;
  }

  .list-select-option-icon-outer i {
    font-size: 18px;
  }
}

ssi-list-select.option-active-success {
  .list-select-option.active a,
  .list-select-option a:hover {
    background-color: $brand-success !important;
    color: white !important;
  }

  .icon-selected {
    color: $brand-success !important;
  }
}

ssi-list-select.option-active-royal {
  .list-select-option.active a,
  .list-select-option a:hover {
    background-color: $brand-royal !important;
    color: white !important;
  }

  .icon-selected {
    color: $brand-royal !important;
  }
}

ssi-list-select.option-circles-hidden {
  .list-select-option-icon-outer {
    display: none !important;
  }
}
