ssi-sentiment-list {
  font-size: 1rem;
}
ssi-sentiment-list .sentiment-list {
  margin: 0 -0.6em;
  padding: 0;
  min-width: 20em;
}
ssi-sentiment-list .sentiment-list.insights .sentiment-list-item-1:hover,
ssi-sentiment-list .sentiment-list.insights .sentiment-list-item-1.sentiment-list-item-active {
  background-color: #ff6e3d;
  color: #fff;
}
ssi-sentiment-list .sentiment-list.insights .sentiment-list-item-2:hover,
ssi-sentiment-list .sentiment-list.insights .sentiment-list-item-2.sentiment-list-item-active {
  color: #fff;
  background-color: #ffa962;
}
ssi-sentiment-list .sentiment-list.insights .sentiment-list-item-3:hover,
ssi-sentiment-list .sentiment-list.insights .sentiment-list-item-3.sentiment-list-item-active {
  color: #43537f;
  background-color: #d6dff7;
  box-shadow: 0 1em 1.5em 0 rgba(231, 232, 238, 0.65);
}
ssi-sentiment-list .sentiment-list.insights .sentiment-list-item-4:hover,
ssi-sentiment-list .sentiment-list.insights .sentiment-list-item-4.sentiment-list-item-active {
  color: #749d00;
  background-color: #deec74;
}
ssi-sentiment-list .sentiment-list.insights .sentiment-list-item-5:hover,
ssi-sentiment-list .sentiment-list.insights .sentiment-list-item-5.sentiment-list-item-active {
  background-color: #00c673;
  color: #fff;
}
ssi-sentiment-list .sentiment-list-item {
  display: inline-block;
  line-height: 2.8em;
  vertical-align: middle;
  text-align: center;
  background-color: #ecf0fb;
  color: #838eab;
  height: 2.6em;
  width: 2.6em;
  border-radius: 50%;
  margin: 0 0.6em;
  cursor: pointer;
}
ssi-sentiment-list .sentiment-list-item i {
  line-height: 1;
  font-size: 1.4em;
}
ssi-sentiment-list .sentiment-list-item:hover {
  color: rgba(255, 255, 255, 0.75);
}
ssi-sentiment-list .sentiment-list-item.sentiment-list-item-1:hover, ssi-sentiment-list .sentiment-list-item.sentiment-list-item-1.sentiment-list-item-active {
  background-color: #f88c68;
  color: #fff;
}
ssi-sentiment-list .sentiment-list-item.sentiment-list-item-2:hover, ssi-sentiment-list .sentiment-list-item.sentiment-list-item-2.sentiment-list-item-active {
  color: #f88c68;
  background-color: #fff1ed;
}
ssi-sentiment-list .sentiment-list-item.sentiment-list-item-3:hover, ssi-sentiment-list .sentiment-list-item.sentiment-list-item-3.sentiment-list-item-active {
  color: #838eab;
  background-color: #d6dff7;
  box-shadow: 0 1em 1.5em 0 rgba(231, 232, 238, 0.65);
}
ssi-sentiment-list .sentiment-list-item.sentiment-list-item-4:hover, ssi-sentiment-list .sentiment-list-item.sentiment-list-item-4.sentiment-list-item-active {
  color: #14bae3;
  background-color: #e0f9ff;
}
ssi-sentiment-list .sentiment-list-item.sentiment-list-item-5:hover, ssi-sentiment-list .sentiment-list-item.sentiment-list-item-5.sentiment-list-item-active {
  background-color: #9fd700;
  color: #fff;
}