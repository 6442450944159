@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';
@import '../../../../angularjs/common/sass/variables';
@import '../../../../angularjs/common/sass/mixins';

$inbox-query-result-list-item-avatar-width: 70px;
$inbox-query-result-list-item-avatar-width-sm: 48px;

ssi-inbox-pinned-activities {
  margin-bottom: -20px;
  z-index: 1;
}

.unpin-button {
  z-index: 9999;
}

.pinned-activities-container {
  display: flex;
  flex: 1;
  background-color: #f6f7f8;
  color: $dmode-body-text;
  margin: 0;
  padding: 15px 15px 5px;
  list-style: none;

  li {
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 160px;
    max-width: 30%;
    flex: 1;
    padding: 5px 10px;
    border-radius: 12px;
    background-color: #fff;

    &.active {
      background-color: #e7edfd;
    }
    &:last-child {
      margin-right: 0;
    }

    img {
      margin-right: 0.5rem;
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }

    span {
      font-size: 10px;
      font-weight: 900;
      color: $lmode-body-text;
    }

    .author-info {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-right: 0.2rem;
      flex: 1;

      &:hover {
        cursor: pointer;
      }
    }

    button {
      border: 0;
      border-radius: 50%;
      color: #fff;
      background-color: #668aea;
      padding: 6px;
      font-size: 7px;
      height: 20px;
      width: 20px;
    }
  }

  .additional-trigger {
    position: relative;

    > i {
      z-index: 10;
      color: $lmode-helper-text;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .additional-activities {
    position: absolute;
    z-index: 5;
    top: 0;
    right: 0;
    margin: 0;
    padding: 30px 10px 10px 10px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 0px 10px 0px rgba(202, 202, 202, 0.75);

    li {
      margin-right: 0;
      max-width: 100%;
      width: 100%;
      &:hover {
        color: $primary-active;
      }

      .author-info {
        margin-right: 3rem;
      }
    }
  }
}

.pinned-activities-tooltip-content {
  padding: 2.5rem;
  width: 260px;
  font-weight: 900;

  .main-info {
    display: flex;

    span {
      margin-right: 0.4rem;
      color: #fff;
      font-size: 10px;

      &.author-name {
        display: inline-block;
        max-width: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .message-preview {
    margin-top: 1rem;
    color: $dmode-body-text;
    font-size: 14px;
    word-wrap: break-word;
  }
}
