@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import './../../../../angularjs/common/sass/variables';
@import './../../../../angularjs/common/sass/mixins';
@import '~/styles/colors';

ssi-outbox-post {
  .panel {
    box-shadow: $box-shadow-default;
    border-radius: 0;
    border-color: #eee;
  }

  .panel-body {
    padding: 5px;
    position: relative;

    &.panel-body-failed:not(.bg-success) {
      background-color: #fdf3ef;
    }
  }

  .media-left {
    padding: 10px;
  }

  .media-body {
    padding-top: 10px;
  }

  .media-content > * {
    white-space: pre-wrap;
  }

  .media-right {
    padding-right: 5px;

    a,
    img {
      width: 90px;
      height: 90px;
      box-shadow: $box-shadow-default;
      border-radius: 50%;
    }

    a {
      display: inline-block;
      text-align: center;
      @include align-vertical(90px);
    }

    a:hover {
      text-decoration: none;
    }

    img {
      object-fit: cover;
    }
  }

  .has-bottom-stats {
    .media-right {
      padding-right: 12px;
      padding-top: 12px;
    }
  }

  .media-heading {
    margin-bottom: 12px;
  }

  .media-heading a {
    padding-right: 5px;
    i {
      color: #000;
      &.fa-youtube-play {
        color: #282828;
        font-size: 23px;
      }
    }
  }

  .media-heading small {
    font-size: 12px;
    color: $text-dark;

    .fa-stack-2x.approved {
      color: #acb638;
    }
    .fa-stack-2x.rejected {
      color: #e36635;
    }
  }

  .media-sub-heading {
    margin-top: 3px;
  }

  .children-separated *:not(:last-child):after {
    content: ' | ';
    padding: 0 5px;
  }

  .children-separated :last-child {
    padding-right: 5px;
  }

  .outbox-post-btns {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .btn {
      margin-right: 3px;
    }

    a:hover {
      text-decoration: none;
    }

    .outbox-post-stat {
      margin-right: 7px;
    }
  }

  .outbox-menu {
    position: absolute;
    right: $section-margin-width;
    top: $section-margin-width;
    z-index: 1;

    .outbox-menu-items {
      display: none;
    }
  }

  .outbox-menu-items {
    background-color: #ffffff;
    padding: 7px;
    min-width: 35px;
    border-radius: 20px;
    text-align: center;
    box-shadow: $box-shadow-default;

    .fa-ellipsis-v {
      padding: 0 7px;
      cursor: default;
      font-size: 17px;
    }

    a {
      padding: 0 7px;
    }

    a:hover {
      text-decoration: none;
    }

    i.ssi-first-comment {
      font-size: 20px;
    }
  }

  .outbox-menu:not(.no-hover):hover {
    .outbox-menu-trigger {
      display: none;
    }
    .outbox-menu-items {
      display: inline-block;

      .text-danger {
        color: $secondary-red;
      }
    }
  }

  .outbox-menu-trigger {
    width: 33px;
    @include align-vertical(33px);
    box-shadow: $box-shadow-default;
    border-radius: 50%;
  }

  .no-media .media-body {
    padding-right: 10px;
  }

  .media-nav-arrow.left {
    left: -10px !important;
  }

  .media-nav-arrow.right {
    right: -10px !important;
  }

  .outbox-post-stats-container {
    margin-left: 62px;
    padding-bottom: $section-margin-width;
  }

  .outbox-post-advert-icon {
    position: relative;
    left: 3px;
    top: -15px;
  }

  .unvalidated {
    color: #526bb4;
    margin-left: 5px;
  }

  .outbox-post-failed-chip {
    display: inline-flex;
    font-size: 10px;
    background-color: #fadcd2;
    border-radius: 0.4em;
    padding: 0.6em;
    white-space: nowrap;
    margin: 0.2em;

    i.ssi {
      font-size: 1em;
      color: #e34916;
      padding: 0 0.5em;
    }
  }

  .outbox-post-failed-chip-label {
    font-size: 1em;
    color: #101525;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 900;
    padding: 0 0.3em;
  }

  .twitter-thread-badge {
    display: inline-block;
    background-color: $grey-background;
    border-radius: 10em;
    padding: 2px 6px 3px;

    p {
      display: inline-block;
      margin: 0;
      color: $primary-active;
      font-weight: 900;
      text-rendering: geometricPrecision;
      font-size: 12px;
    }
  }

  .twitter-thread-badge-icon {
    border-radius: 50%;
    display: inline-block;
    margin: 0 3px 0 0;
    background-color: $primary-active;
    height: 11px;
    width: 11px;

    i {
      font-size: 8px;
      color: #fff;
      margin: -3px 0 0 2px;
      vertical-align: middle;
    }
  }

  // outbox-post and outbox-post-stats styles
  .outbox-post-stat {
    font-size: 10px;
    font-weight: normal;
    display: inline-block;
    position: relative;
    top: 2px;
    margin-right: 25px;
    color: #bbc2c8;

    i {
      font-size: 14px;
    }

    .outbox-post-stat-count {
      position: relative;
      top: -4px;
    }
  }

  a.outbox-post-stat {
    color: #bbced8;
  }

  a.outbox-post-stat:hover {
    text-decoration: none;
  }

  .tags-container {
    position: relative;
    display: inline-block;

    .no-tags-state {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      padding: 0 10px;
      // height: 160px;

      i {
        margin-top: 20px;
        color: #b4bbce;
        font-size: 30px;
      }

      .title {
        margin-top: 15px;
        color: $lmode-body-text;
        font-size: 16px;
        font-weight: 900;
      }

      .desc {
        margin-bottom: 20px;
        color: $lmode-body-text;
        font-size: 14px;
        font-weight: 700;
        padding: 0 24px;
        text-align: center;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        width: 100%;
        i {
          margin: 0;
          margin-left: 10px;
          font-size: 12px;
          color: #fff;
        }
        span {
          white-space: nowrap;
        }
      }
    }
  }
}

.ad-budget-tooltip {
  padding: 3px 8px;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
  font-weight: normal;
  font-size: 11px;
}

.pub-confirm-modal {
  font-size: 1rem;
  color: $lmode-body-text;
  margin-top: 20%;
  border-radius: 1.5em;
  background-color: #fff;
}

.pub-confirm-modal-header {
  display: flex;
  align-items: center;
  border-bottom: 0.1em solid #c3c9d8;
  padding: 2em 2em 2.1em;

  h2 {
    margin: 0.2em 0 0;
    padding: 0;
    line-height: 1;
    font-size: 2em;
    font-weight: 900;
    text-rendering: geometricPrecision;
  }

  &-left {
    flex: 1 1 auto;
    padding: 0 1em;
  }

  &-right {
    flex: 0 0 auto;
  }
}

.pub-confirm-modal-close {
  display: inline-block;
  border-radius: 50%;
  background-color: $lgrey-background;
  border-width: 0;
  height: 3.6em;
  width: 3.6em;
  outline-width: 0;

  .ssi {
    font-size: 1.2em;
    color: $lmode-helper-text;
  }

  .ssi-addsmall {
    transform: rotate(45deg);
  }
}

.pub-confirm-modal-body {
  min-height: 200px;
  padding: 2.9em 2em 2.4em;

  p {
    color: $lmode-helper-text;
    font-size: 1.4em;
    font-weight: 700;
    text-rendering: geometricPrecision;
    line-height: 2rem;
    padding: 0 1rem;
    margin: 0 0 3rem;
  }

  & > *:last-child {
    margin: 0;
  }
}

.pub-confirm-modal-body-row {
  padding: 0 3em;
  margin: 0 0 2em;
}

.pub-confirm-modal-footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 2.6em 2.5em 1.7em;
  border-top: 0.1em solid #c3c9d8;

  orlo-button {
    display: inline-block;
    margin: 0 0 1em;
    white-space: nowrap;

    & + orlo-button {
      margin-left: 0.8em;
    }
  }

  &-left {
    flex: 0 0 auto;
    padding: 0 0 0 0.5em;
  }

  &-right {
    flex: 0 1 auto;
  }
}

.pub-confirm-modal-button {
  button {
    height: 49px;
    border-radius: 10px;
  }

  &-secondary button {
    border: 0.1em solid $lmode-helper-text;
  }

  &-link button {
    text-transform: uppercase;
    font-weight: 900;
    text-rendering: geometricPrecision;
    font-size: 14px;
    letter-spacing: 1px;
    text-decoration: underline;
    text-underline-offset: 3px;
    padding-left: 0;
    padding-right: 0;
  }
}
