@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../common/styles/colors.scss';
@import '../../../../../angularjs/common/sass/variables';

$monitoring-green: #0d827c;
$monitoring-green-accent: #ecf5f5;
$stream-container-margin: 2em;

ssi-stream-builder {
  font-size: 1rem;

  .stream-builder-container {
    padding: 4em;
    margin: $stream-container-margin;
    background-color: white;
    border-radius: 15px;
    min-height: calc(
      100vh - (#{$navbar-height} + #{$stream-container-margin} * 2)
    );
  }

  i {
    &.left {
      transform: rotate(-90deg);
      margin-right: 0.5em;
    }
    &.right {
      transform: rotate(90deg);
      margin-left: 0.5em;
    }
  }

  .stream-builder-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2em;
    color: $lmode-helper-text;

    & > * {
      flex: 1 0 0;
    }

    .stream-builder-nav-action,
    a {
      color: $lmode-body-text;
      font-size: 1.2em;
      font-weight: 900;
      &:hover {
        text-decoration: none;
        color: $monitoring-green;
      }
    }

    .stream-builder-nav-action {
      margin: 0 0 0 2rem;
      cursor: pointer;

      span {
        font-size: 14px;
      }

      i {
        font-size: 12px;
        vertical-align: text-top;
        margin: 0 7px 0 0;
      }
    }

    .nav-list {
      display: flex;
      width: 465px;
      margin: 0 20px;
      flex: 0 0 465px;

      & > * {
        flex: 1 0 auto;
      }

      i {
        font-size: 0.9em;
        font-weight: 700;
        margin-left: 0.8em;
      }

      h3 {
        font-size: 14px;
        font-weight: 900;
      }
    }
    .line {
      width: 88px;
      height: 2px;
      margin: 1em;
      background-color: #b4bbce;

      &.active:not(.completed),
      &.final {
        position: relative;
        background: linear-gradient(87.08deg, #12aca4 0%, #b4bbce 100%);

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: -2px;
          right: 85px;
          background: #12aca4;
          border-radius: 50%;
          height: 6px;
          width: 6px;
        }
      }

      &.final {
        &::before {
          right: 0;
        }
      }
      &.completed {
        background: #12aca4;
        margin-left: 0.5em;
      }
    }
    .active {
      color: $monitoring-green;
      border-color: $monitoring-green;
    }

    .next,
    .back {
      white-space: nowrap;

      a {
        font-size: 14px;
      }

      &.disabled {
        orlo-button button {
          opacity: 0.6;
        }

        a {
          cursor: not-allowed;
          opacity: 0.6;

          &:hover,
          &:focus {
            color: $lmode-body-text;
          }
        }
      }
    }
    .next {
      text-align: right;
    }
    button.next {
      background-color: transparent;
      border-width: 0;
    }
    orlo-button {
      button {
        margin-top: -9px;
      }
    }
  }
}
