ssi-assign-message .content-container {
  background-color: #fff;
}
ssi-assign-message .heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 1 10%;
  padding: 40px 40px 20px 40px;
}
ssi-assign-message .heading h3 {
  color: #43537f;
  margin: 0 auto 0 10px;
  font-weight: 900;
  font-size: 19px;
}
ssi-assign-message .heading i:first-child {
  color: #838eab;
  font-size: 22px;
}
ssi-assign-message .heading i.close-icon {
  padding: 12px;
  background-color: #ecf0fb;
  border-radius: 50px;
  color: #838eab;
  font-size: 12px;
}
ssi-assign-message .heading i.close-icon:hover {
  cursor: pointer;
}
ssi-assign-message .search-field {
  display: flex;
  margin: 20px 60px 0 50px;
  padding-bottom: 5px;
  border-bottom: 2px #ecf0fb solid;
  color: #838eab;
}
ssi-assign-message .search-field.active {
  border-color: #14bae3;
}
ssi-assign-message .search-field input {
  height: 30px;
  border: 0;
  font-size: 16px;
  color: #101525;
  box-shadow: none;
  background-color: transparent;
  width: 100%;
  outline: none;
  font-weight: 500;
}
ssi-assign-message .search-field input::placeholder {
  color: #838eab;
  font-weight: bold;
  font-size: 14px;
}
ssi-assign-message .search-field i {
  font-size: 20px;
}
ssi-assign-message hr {
  border: 1px solid #ecf0fb;
  margin: 0;
}
ssi-assign-message .buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 30px 20px 30px;
  flex: 0 1 10%;
  overflow: hidden;
  border-top: 2px solid #ecf0fb;
}
ssi-assign-message .buttons .assign-to-me {
  margin-right: auto;
  background: none;
  border: 0;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 0px rgba(202, 202, 202, 0.75);
  padding: 5px 15px;
  font-weight: bold;
  color: #43537f;
  outline: none;
}
ssi-assign-message .buttons .assign-to-me i {
  margin-right: 10px;
}
ssi-assign-message .buttons .assign-to-me:hover {
  color: #14bae3;
}
ssi-assign-message .buttons .assign-to-me:disabled {
  color: #43537f;
  cursor: not-allowed;
}
ssi-assign-message .buttons orlo-button span {
  font-size: 12px !important;
  letter-spacing: 1px;
}
ssi-assign-message .buttons orlo-button button {
  padding: 10px 10px;
}
ssi-assign-message .content {
  display: flex;
  flex-direction: column;
  margin: 20px 30px 20px 40px;
  min-height: 300px;
  max-height: 300px;
  overflow: auto;
  scrollbar-color: #14bae3;
}
ssi-assign-message .content::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  background-color: #ecf0fb;
  border-radius: 5px;
}
ssi-assign-message .content::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #14bae3;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
ssi-assign-message .content ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
ssi-assign-message .content ul li {
  display: flex;
  align-items: baseline;
  color: #43537f;
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
  margin-bottom: 5px;
}
ssi-assign-message .content ul li i {
  color: #838eab;
}
ssi-assign-message .content ul li .tick {
  display: none;
}
ssi-assign-message .content ul li:hover, ssi-assign-message .content ul li.selected {
  color: #14bae3;
  background-color: #f8f9fd;
  border-radius: 25px;
  margin-right: 30px;
  cursor: default;
}
ssi-assign-message .content ul li:hover.selected i.tick, ssi-assign-message .content ul li.selected.selected i.tick {
  position: relative;
  display: inline-block;
  margin-left: auto;
}
ssi-assign-message .content ul li:hover.selected i.tick:after, ssi-assign-message .content ul li.selected.selected i.tick:after {
  display: block;
  content: "";
  border-radius: 50%;
  background-color: gray;
}
ssi-assign-message .content ul li .more-info {
  color: #8a95b0;
  display: inline-flex;
  align-items: center;
  margin: 0 5px 0 10px;
  font-size: 11px;
  font-weight: normal;
  text-transform: uppercase;
}
ssi-assign-message .content ul li .more-info svg {
  margin: 0 5px;
}
ssi-assign-message .content i {
  margin-right: 10px;
}
ssi-assign-message .content hr {
  margin: 15px 30px 15px 45px;
}
ssi-assign-message .assignment-type {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 20px 50px;
  margin: 0;
}
ssi-assign-message .assignment-type li {
  display: flex;
  align-items: center;
  position: relative;
}
ssi-assign-message .assignment-type li::before {
  display: block;
  background-color: #f0f3f9;
  content: "";
  border-radius: 50px;
  width: 16px;
  height: 16px;
}
ssi-assign-message .assignment-type li i {
  display: none;
}
ssi-assign-message .assignment-type li.selected label {
  color: #14bae3;
}
ssi-assign-message .assignment-type li.selected::before {
  color: #14bae3;
  background-color: #d0f1f9;
}
ssi-assign-message .assignment-type li.selected i {
  display: block;
  position: absolute;
  left: 2px;
  bottom: 0;
  top: 0;
  margin: auto;
  height: 16px;
  font-size: 18px;
  color: #14bae3;
}
ssi-assign-message .assignment-type li:first-child {
  margin-right: 30px;
}
ssi-assign-message .assignment-type input {
  margin: 0;
  -webkit-appearance: none;
}
ssi-assign-message .assignment-type label {
  color: #43537f;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 1px;
  font-weight: bold;
  margin-left: 5px;
  margin: 0 0 0 5px;
}
ssi-assign-message .assign-no-results {
  margin: auto;
}
ssi-assign-message .assign-no-results i {
  color: #b4bbce;
  font-size: 40px;
  margin-bottom: 30px;
}
ssi-assign-message .assign-no-results h3 {
  color: #838eab;
  font-size: 14px;
  font-weight: bold;
}
ssi-assign-message .assign-no-results h6 {
  color: #838eab;
  margin: 10px 10%;
}