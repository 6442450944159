@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-social-network-select {
  .social-network-select {
    padding: 0;
    li {
      position: relative;
      letter-spacing: 1px;
      margin: 0 2em 0 0;
      display: inline-block;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 900;
      color: $lmode-body-text;
      cursor: pointer;
      &::before {
        content: '';
        transform: scaleX(0);
        visibility: hidden;
        position: absolute;
        top: 22px;
        height: 2px;
        width: 80%;
        left: 10%;
        background-color: $primary-active;
        transition: all 0.3s ease-in-out 0s;
      }
      &.active,
      &:hover {
        &:before {
          transform: scaleX(1);
          visibility: visible;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
