@import "./src/apps/angularjs/common/sass/global-variables.scss";
// @todo DRY it
$header-height: 72px * 2;
$unit: 2.5px;

ssi-live-chat-notes {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  min-height: 0;
  
  * {
    display: flex;
  }

  button {
    align-items: center;
    background: #fff;
    border: none;
    border-radius: ($unit * 12);
    color: #A6B7C0;
    cursor: pointer;
    font-weight: 500;
    padding: ($unit * 4) ($unit * 8);

    & > .button-icon {
      padding-right: 0.5em;
    }

    & > .button-text {
      font-style: italic;
    }
  }

  .live-chat-notes-header {
    align-items: center;
    background-color: rgb(216, 225, 229);
    box-sizing: border-box;
    color: #fff;
    flex-shrink: 0;
    height: 72px;
    justify-content: space-between;
    line-height: ($unit * 8);
    list-style: none;
    margin: 0;
    padding: 0 ($unit * 8);

    & > li {
      align-items: center;
      margin: 0;
      padding: 0;

      &.title {
        & > .title-icon {
          font-size: 2.8em;
          padding-left: ($unit * 2);
        }

        & > .title-text {
          font-size: 1.4em;
          font-weight: 500;
        }
      }
    }
  }

  .live-chat-notes-content {
    align-self: flex-start;
    color: #A6B7C0;
    flex-flow: column nowrap;
    flex-grow: 0;
    flex-shrink: 0;
    height: calc(100vh -  #{$header-height});
    overflow-y: auto;
    padding: ($unit * 16) 10%;
    width: 100%;

    ssi-live-chat-message {
      .has-tags {
        display: none;
      }

      .message-footer {
        display: none;
      }
    }

    .delete-button {
      align-items: center;
      background: transparent;
      border: none;
      border-radius: ($unit * 4);
      color: #E4A598;
      font-style: italic;
      font-weight: 400;
      justify-content: space-between;

      & > .button-icon {
        font-size: 0.85em;
        padding-right: 0.2em;
      }
    }

    .message-notes {
      flex-flow: column nowrap;
      margin: 0;
      padding: 0;

      & > li {
        flex-shrink: 0;
        margin: 0;
        padding: 0;

        ssi-live-chat-message-note {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
}
