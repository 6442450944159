ssi-live-chat-widgets h1 {
  font-weight: 900;
  color: #838eab;
  font-size: 1.25em;
}
ssi-live-chat-widgets .live-chat-widgets {
  background-color: #f8f9fd;
  padding: 2em 4em;
}
ssi-live-chat-widgets .container {
  position: relative;
  border-radius: 25px;
  padding: 2em 0;
}
ssi-live-chat-widgets .segment {
  background: #fff;
  border: 2.5em solid #fff;
  border-radius: 25px;
  margin-bottom: 2em;
}
ssi-live-chat-widgets .push-mode-status {
  position: relative;
  color: #43537f;
  font-weight: 700;
  padding: 0.8em 1.2em 0.8em 44px;
  background-color: #f0f3f9;
  display: inline-block;
  border-radius: 50px;
  cursor: pointer;
}
ssi-live-chat-widgets .push-mode-status:before {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  left: 15px;
  top: 10px;
  border-radius: 100%;
  background-color: red;
}
ssi-live-chat-widgets .push-mode-status.on:before {
  background-color: green;
}
ssi-live-chat-widgets .push-mode-status.loading-service:before {
  background-color: transparent;
}
ssi-live-chat-widgets .widget-list {
  padding: 0 2em 2em 2em;
  list-style: none;
}
ssi-live-chat-widgets .widget-list li {
  margin-bottom: 2em;
  border-bottom: 4px solid #f0f3f9;
}
ssi-live-chat-widgets .widget-list li button {
  float: right;
}
ssi-live-chat-widgets .widget-list .widget-name {
  padding: 2em 0;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 900;
  line-height: 2.5em;
  color: #838eab;
  letter-spacing: 1px;
  display: inline-block;
}
ssi-live-chat-widgets .add-new-widget {
  padding: 0 2em 1em 0;
}
ssi-live-chat-widgets .add-new-widget button {
  float: right;
}
ssi-live-chat-widgets .return-nav {
  position: absolute;
  top: -16px;
  width: 150px;
  left: 35px;
}