ssi-live-chat-conversation-tree {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 0;
  height: calc(100vh - (68px + (72px * 2)));
  overflow-x: hidden;
  overflow-y: auto;
}
ssi-live-chat-conversation-tree * {
  display: flex;
}
ssi-live-chat-conversation-tree button[disabled] {
  cursor: not-allowed !important;
  opacity: 0.65 !important;
}
ssi-live-chat-conversation-tree .not-loaded {
  align-items: center;
  background: linear-gradient(to right, rgb(237, 241, 243) -1%, rgb(237, 241, 243) 97%, rgb(225, 229, 231) 100%) #EDF1F3;
  flex-grow: 1;
  justify-content: center;
}
ssi-live-chat-conversation-tree .not-loaded .spinner {
  font-size: 2.5em;
}
ssi-live-chat-conversation-tree > ul {
  flex-flow: column nowrap;
  flex-grow: 0;
  list-style: none;
  margin: 0;
  padding: 0;
}
ssi-live-chat-conversation-tree > ul > li {
  align-items: center;
  background: linear-gradient(to right, rgb(237, 241, 243) -1%, rgb(237, 241, 243) 97%, rgb(225, 229, 231) 100%) #EDF1F3;
  flex-basis: 90px;
  flex-grow: 0;
  margin: 0;
  min-height: 90px;
  padding: 0 20px;
  position: relative;
}
ssi-live-chat-conversation-tree > ul > li > ssi-live-chat-network-state + .disabled {
  margin-left: 20px !important;
}
ssi-live-chat-conversation-tree > ul > li.disabled {
  cursor: not-allowed !important;
  opacity: 0.65 !important;
}
ssi-live-chat-conversation-tree > ul > li.disabled .details {
  cursor: not-allowed !important;
}
ssi-live-chat-conversation-tree > ul > li.assigned .assignment {
  background-color: #acb638;
}
ssi-live-chat-conversation-tree > ul > li.assigned .assignment:hover {
  background-color: #719239;
}
ssi-live-chat-conversation-tree > ul > li.unassigned .assignment {
  background-color: #14bae3;
}
ssi-live-chat-conversation-tree > ul > li.unassigned .assignment:hover {
  background-color: #2788bf;
}
ssi-live-chat-conversation-tree > ul > li .age {
  color: #105982;
  font-size: 0.7em;
  font-style: italic;
  font-weight: 400;
  position: absolute;
  right: 15px;
  top: 30px;
  white-space: nowrap;
  width: 60px;
}
ssi-live-chat-conversation-tree > ul > li .assignment {
  background-color: transparent;
  border: none;
  cursor: pointer;
  flex-shrink: 0;
  height: 90px;
  margin: 0 20px;
  padding: 0;
  width: 15px;
}
ssi-live-chat-conversation-tree > ul > li .assignment-status {
  color: #105982;
  font-size: 0.8em;
  font-style: italic;
  font-weight: 600;
  text-transform: capitalize;
}
ssi-live-chat-conversation-tree > ul > li .assignment-status > .assignee:before {
  content: "|";
  margin: 0 5px;
}
ssi-live-chat-conversation-tree > ul > li .application-name {
  color: #105982;
  font-size: 0.8em;
  font-style: italic;
  font-weight: 600;
  text-transform: capitalize;
}
ssi-live-chat-conversation-tree > ul > li ssi-live-chat-avatar img {
  height: 35px;
  width: 35px;
}
ssi-live-chat-conversation-tree > ul > li ssi-live-chat-network-state + .details {
  padding-left: 20px;
}
ssi-live-chat-conversation-tree > ul > li .details {
  cursor: pointer;
  flex-flow: column nowrap;
  flex-grow: 1;
  overflow: hidden;
  padding: 20px 0;
}
ssi-live-chat-conversation-tree > ul > li .details .name {
  font-weight: 600;
}
ssi-live-chat-conversation-tree > ul > li .details .name,
ssi-live-chat-conversation-tree > ul > li .details .preview {
  display: inline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}