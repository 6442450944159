ssi-analytics ssi-survey ssi-report ssi-survey-responses .survey-responses-container .table-container {
  padding: 60px 30px 60px 30px;
  background-color: #fff;
  border-radius: 10px;
}
ssi-analytics ssi-survey ssi-report ssi-survey-responses .survey-responses-container .table-container .head-cell {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 0 5px 0 10px;
}
ssi-analytics ssi-survey ssi-report ssi-survey-responses .survey-responses-container .table-container .body-cell {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 0 5px 0 10px;
  border-bottom: 2px solid #e0e7f4;
}
ssi-analytics ssi-survey ssi-report ssi-survey-responses .survey-responses-container .table-container .body-cell.row-even {
  background-color: #f8f9fd;
}
ssi-analytics ssi-survey ssi-report ssi-survey-responses .survey-responses-container .table-container .body-cell span {
  color: #43537f;
  font-size: 12px;
  font-weight: 900;
  margin: 9px 0;
}
ssi-analytics ssi-survey ssi-report ssi-survey-responses .survey-responses-container .table-container .body-cell span.bold {
  color: #101525;
  font-size: 14px;
  font-weight: 900;
}
ssi-analytics ssi-survey ssi-report ssi-survey-responses .survey-responses-container .table-container ssi-paginator {
  padding-top: 40px;
}
ssi-analytics ssi-survey ssi-report ssi-survey-responses .survey-responses-container .table-container ssi-no-results .no-results {
  padding: 100px;
}