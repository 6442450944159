@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../angularjs/common/sass/variables';
@import '../../../../../angularjs/common/sass/mixins';

$unit: 2.5px;

ssi-inbox-activity-thread {
  $inbox-activity-silence-message-height: 38px;
  $inbox-activity-thread-indent-width: 50px;
  $inbox-activity-border: solid 2px #e7ebee;

  background-color: $inbox-activity-background-color;
  display: flex;
  flex-direction: column;
  min-height: 0;
  height: 100%;

  .inbox-split-conversation-dialog {
    background: #d8e1e5;
    border-top: dashed 2px #fff;
    border-bottom: dashed 2px #fff;
    display: flex;
    justify-content: center;
    padding: 20px;

    * {
      display: flex;
    }

    & > ul {
      flex-flow: row nowrap;
      list-style: none;
      margin: 0;
      padding: 0;

      & > li {
        align-items: center;
        background-color: #fff;
        border-radius: 20px;
        color: #fff;
        cursor: pointer;
        font-size: 0.85em;
        font-style: italic;
        font-weight: 500;
        margin: 0 3px;
        padding: 3px 12px;

        &.inbox-split-conversation-confirm {
          background-color: $brand-primary;
        }

        &.inbox-split-conversation-undo {
          background-color: #7d90a0;
        }
      }
    }
  }

  .scroll-container {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;

    &.scroll-container-reply {
      padding-bottom: 85px;
      margin-bottom: $reply-box-height-active + $reply-box-sticky-bottom-padding;

      &.scroll-container-reply-compressed {
        padding-bottom: 55px;
        margin-bottom: $reply-box-height-compressed +
          $reply-box-sticky-bottom-padding;
      }

      &.scroll-container-reply-expanded {
        padding-bottom: 55px;
        margin-bottom: $reply-box-height-expanded +
          $reply-box-sticky-bottom-padding;
      }

      &.scroll-container-reply-hidden {
        padding-bottom: 55px;
        margin-bottom: $reply-box-height-hidden +
          $reply-box-sticky-bottom-padding;
      }

      &.scroll-container-reply-none {
        margin-bottom: 0;
        padding-bottom: 55px + $reply-box-height-compressed +
          $reply-box-sticky-bottom-padding;
      }

      &:after {
        @include ie-only {
          content: '';
          display: block;
          height: 85px;
        }
      }

      &.scroll-container-reply-compressed:after,
      &.scroll-container-reply-expanded:after,
      &.scroll-container-reply-hidden:after {
        @include ie-only {
          content: '';
          display: block;
          height: 55px;
        }
      }

      &.scroll-container-reply-none:after {
        @include ie-only {
          content: '';
          display: block;
          height: 55px + $reply-box-height-compressed +
            $reply-box-sticky-bottom-padding;
        }
      }
    }

    & > *:first-child {
      margin-top: 16px;
    }

    .conversation-on-hold-indicator-container {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 60px;

      .line {
        flex: 1 1 auto;
        height: 2px;
        border-radius: 10px;
        background-color: #e7edfd;
      }

      .conversation-on-hold-indicator {
        flex: 0 0 auto;
        border-radius: 10px;
        background-color: #e7edfd;
        height: 36px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        color: #101525;
        font-size: 12px;
        font-weight: 900;
        i {
          margin-right: 10px;
        }
      }
    }
  }

  .inbox-activity-thread-expander.border-top {
    border-top: $inbox-activity-border;
    padding-top: 5px;
  }

  .hide-activity-top {
    .activity-top {
      display: none;
    }
  }

  .inbox-activity-thread-children {
    margin-left: $inbox-activity-thread-indent-width;

    .activity-reply {
      padding-left: 0;
    }

    .activity-body,
    .inbox-activity-thread-expander,
    .activity-reply {
      //border-left: solid @activity-separator-width @activity-separator-color;
    }
  }

  .inbox-activity-thread-indent-0 {
    .inbox-activity-thread-children {
      margin-left: 0;
    }
  }

  .inbox-activity-thread-history,
  .inbox-activity-thread-newer-activities {
    &.expanded {
      .thread-expand-icon {
        position: absolute;
        top: -50px;
      }
    }
  }

  .inbox-activity-thread-history.expanded + .inbox-activity-reply-container {
    .thread-expand-icon {
      position: absolute;
      top: -50px;
    }
  }

  .inbox-activity-thread-indent-1 {
    // margin-left: $inbox-activity-thread-indent-width;
    margin-left: 0;

    .inbox-activity-reply-container,
    .inbox-activity-thread-container {
      ssi-activity {
        // margin-left: $inbox-activity-thread-indent-width * 3;
        margin-top: 0;
      }
    }
  }

  .inbox-activity-thread-indent-2 {
    margin-left: $inbox-activity-thread-indent-width;

    .inbox-activity-thread-children {
      margin-left: 0;
    }
  }

  .thread-expand-icon {
    color: #7d90a0;
    font-size: 1.6em;
    margin-left: $inbox-activity-thread-indent-width * 2.6;
    margin-top: $unit * 4;
  }

  .inbox-activity-thread-expander {
    display: flex;
    margin: 10px 0 0 14px;
    padding: 0;

    a {
      font-weight: normal;
      font-size: 12px;

      span {
        display: inline-block;
        background: #fff;
        border-radius: 100px;
        color: #14bae3;
        font-weight: 900;
        font-size: 12px;
        line-height: 14px;
        padding: 13px 24px;
        min-width: 120px;
        text-rendering: geometricPrecision;
      }
    }

    a:hover {
      text-decoration: none;
    }
  }

  .inbox-activity-thread-expander.warning {
    i {
      color: $brand-warning;
    }
  }

  .inbox-activity-thread-expander.royal {
    i {
      color: $brand-royal;
    }
  }

  .inbox-activity-thread-expander.info {
    margin-left: -28px;
    i {
      color: #5bc0de;
    }
  }

  @keyframes flash-animation {
    from {
      background: $activity-active-bg-color;
    }
    to {
      background: inherit;
    }
  }

  .inbox-activity-flash-on-entry {
    display: block;
    animation-name: flash-animation;
    animation-duration: 2s;
  }

  .inbox-activity-thread-footer {
    height: $inbox-activity-thread-footer-height;
    overflow: hidden;
    background-color: #edf1f3;
    padding: $inbox-activity-thread-footer-padding;
    z-index: 4;

    @include box-shadow-top();

    .btn {
      border: 0;
    }

    & > ul {
      display: flex;
      flex-flow: row nowrap;
      font-size: 12px;
      list-style: none;
      margin: 0;
      padding: 0;

      & > li {
        padding: 0 10px 0 0;
        & > button {
          background-color: #fff;
          border: none;
          border-radius: 10px;
          color: $brand-primary;
          font-weight: 400;
        }
      }
    }
  }
}
