ssi-tags-table {
  font-size: 1rem;
}
ssi-tags-table .body-cell-stat {
  font-size: 14px !important;
  color: #000 !important;
}
ssi-tags-table .body-cell-label {
  font-size: 12px !important;
  text-indent: 6px;
  word-break: break-all;
  max-height: 45px;
  overflow: hidden;
}