@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../angularjs/common/sass/variables';

$placeholder-colour: #d8e1e5;
$border-colour: #f6f7f8;

$primary-colour: #1dafec;
$secondary-colour: #7d90a0;
$tertiary-colour: #fff;
$unit: 2.5px;

ssi-live-chat-conversation-response {
  align-self: flex-end;
  display: flex;
  flex-flow: column nowrap;
  margin-right: 10%;
  min-height: (68 * $unit);
  width: 70%;

  * {
    display: flex;
  }

  .hidden {
    display: none;
  }

  & > form {
    flex-flow: column nowrap;

    &.hidden {
      display: none;
    }

    & > div {
      background-color: #fff;
      border-radius: 20px;
      flex-flow: column nowrap;
      justify-content: space-between;
      margin: ($unit * 4) 0 ($unit * 8) 0;
      min-height: 120px;
      padding: 10px 20px;
      position: relative;

      input,
      textarea {
        border: none;
        outline: none;

        &[disabled],
        &.disabled {
          cursor: not-allowed;
          opacity: 0.65;
        }

        &::placeholder {
          color: $placeholder-colour;
          font-style: italic;
          font-weight: 500;
        }
      }

      & > .additional {
        align-items: center;
        flex-flow: row nowrap;
        justify-content: flex-end;
        list-style: none;
        margin: 80px 0 0 0;
        padding: 0;

        & > li {
          margin: 0;
          padding: 0;

          .options {
            align-items: center;
            list-style: none;
            margin: 0;
            padding: 0;

            li {
              color: $placeholder-colour;
              font-size: 1.5em;
              margin: 0;
              padding: 0 3px;

              &.characters-remaining {
                font-size: 0.8em;
                font-weight: 600;
              }

              &.upload-attachment {
                input[type='file'] {
                  display: none;
                }

                label {
                  cursor: pointer;
                  margin: 0;
                  padding: 0;

                  &.disabled {
                    cursor: not-allowed;
                    opacity: 0.65;
                  }
                }
              }

              &.info,
              &:hover {
                color: $primary-colour;

                &.disabled {
                  color: $placeholder-colour;
                }
              }

              button {
                background-color: transparent;
                border: none;
                outline: none;
                padding: 0;
              }

              ssi-emoji-picker {
                display: block;
              }
            }
          }
        }
      }

      textarea {
        background-color: transparent;
        padding: 0;
        position: relative;
        resize: none;
        z-index: 1;
      }

      textarea,
      .prediction-container {
        height: $navbar-height;
        position: absolute;
        width: 90%;
      }

      .prediction-container {
        color: #9cf;
        white-space: pre-wrap;
        word-spacing: 0;
        word-wrap: break-word;
        z-index: 0;
      }
    }

    & > .buttons {
      align-items: center;
      list-style: none;
      margin: 0 30px 30px 30px;
      padding: 0;

      & > li {
        margin: 0 5px;
        padding: 0;

        & > button {
          align-items: center;
          background-color: transparent;
          border: none;
          border-radius: 20px;
          font-size: 0.8em;
          font-weight: 400;
          padding: 3px 20px;

          i {
            margin-right: $unit * 2;
          }

          &[disabled] {
            cursor: not-allowed;
            opacity: 0.65;
          }

          &.note {
            background-color: $tertiary-colour;
            color: $primary-colour;

            i {
              font-size: 1.5em;
              padding-left: 5px;
            }
          }

          &.reply {
            background-color: $primary-colour;
            color: $tertiary-colour;
          }

          &.resolve {
            background-color: $secondary-colour;
            color: $tertiary-colour;
          }
        }
      }
    }
  }
}
