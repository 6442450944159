@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';
@import '~/styles/variables';

$offline-colour: #a6b7c0;
$online-colour: $primary-blue;

.conversation-detail {
  ssi-live-chat-tally-tile {
    > div {
      background-color: #717f8b;
    }
  }
}

ssi-live-chat-tally-tile {
  container: tally / inline-size;
  align-items: center;
  flex-grow: 0;
  justify-content: center;

  & > div {
    height: $live-chat-tally-height;
    box-shadow: 0px 5px 5px 0px rgba(131, 142, 171, 0.2);
    z-index: 2;
    align-items: center;
    background-color: #fff;
    flex-flow: row nowrap;
    flex-grow: 1;
    justify-content: center;
    padding: 10px 20px;

    .avatar {
      height: 50px;
      width: 50px;
      margin-left: 18px;

      img {
        border-style: solid;
        border-width: 4px;
        border-radius: 50%;
        height: 100%;
        width: 100%;
      }
    }

    ssi-live-chat-network-state {
      .dropdown-menu {
        top: 16px !important;
        left: 32px !important;
      }
    }

    &.offline {
      img {
        border-color: $offline-colour;
      }
    }

    &.online {
      img {
        border-color: $online-colour;
      }
    }
  }

  @container tally (min-width: 480px) {
    .chat-statistics p.widget-label {
      display: block;
    }
  }

  .social-status label {
    color: #43537f;
    line-height: 26px;
    font-size: 10px;
    letter-spacing: 1px;
    font-weight: 900;
    text-transform: uppercase;
    text-rendering: geometricPrecision;
  }

  .status-menu {
    width: 83px;

    ul {
      position: fixed;
      top: auto;
      margin-top: 3px;
      border-radius: 5px;

      li {
        height: 26px;
        border-radius: 5px;

        &.busy {
          background-color: #f4490f;
        }
      }
    }
  }

  .chat-statistics {
    justify-content: flex-end;
    align-items: center;

    .widget-label {
      display: none;
      margin: 0 0 0 4px;
      font-weight: 900;
      color: #43537f;
      font-size: 12px;
      text-rendering: geometricPrecision;
    }

    .icon {
      font-size: 12px;

      i {
        display: block;
        margin: 0 4px 0 0;
        font-size: 16px;
        color: #43537f;
      }

      span {
        font-size: 12px;
        color: #43537f;
        font-style: normal;
        font-weight: 900;
        text-rendering: geometricPrecision;
      }

      &:after {
        content: normal;
      }
    }

    li {
      height: 26px;

      &:not(.unassigned) {
        margin-right: 14px;
        padding-right: 14px;
        border-right: 1px solid #b4bbce;
      }
    }

    .statistic {
      display: none;
    }

    .unassigned {
      order: 3;

      .icon {
        &:not(.unhighlight) {
          position: relative;
          height: 26px;
          width: 26px;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='26' height='26' fill='none' xmlns:v='https://vecta.io/nano'%3E%3Cpath d='M0 5a5 5 0 0 1 5-5h16a5 5 0 0 1 5 5v16a5 5 0 0 1-5 5H5a5 5 0 0 1-5-5V5z' fill='url(%23A)'/%3E%3Cpath d='M16.291 7.856c0-.367.109-.726.313-1.031s.494-.543.833-.683.712-.177 1.072-.106.691.248.95.508.436.59.508.95.035.733-.106 1.072-.378.629-.684.833-.664.313-1.031.313a1.86 1.86 0 0 1-1.856-1.856zm3.205 3.473v3.248a3.37 3.37 0 0 1-.986 2.381 3.37 3.37 0 0 1-2.381.986H15.11c-.104 0-.208.024-.301.071s-.175.114-.238.198l-1.013 1.344a.96.96 0 0 1-.81.443.96.96 0 0 1-.81-.443l-1.012-1.343a.75.75 0 0 0-.54-.27H9.374c-.895 0-1.753-.355-2.386-.988S6 15.465 6 14.57V9.88c0-.895.355-1.753.988-2.386s1.491-.988 2.386-.988h5.3a.67.67 0 0 1 .288.066c.09.043.169.106.232.183s.108.168.131.265.025.198.005.295a2.94 2.94 0 0 0 .019 1.186 2.85 2.85 0 0 0 2.152 2.152 2.94 2.94 0 0 0 1.186.019.67.67 0 0 1 .56.136.67.67 0 0 1 .249.52zm-8.772 1.251c0-.133-.039-.264-.114-.375s-.179-.197-.303-.249-.259-.064-.39-.038-.251.09-.346.185-.159.215-.185.345-.013.267.038.39.138.229.249.303.241.114.375.114c.179-.001.35-.072.477-.198s.198-.298.198-.476zm2.699 0c0-.133-.04-.264-.114-.375s-.18-.197-.303-.249-.259-.064-.39-.038-.251.09-.346.185-.159.215-.185.345-.013.267.038.39.138.229.249.303.241.114.375.114c.179-.001.35-.072.476-.198s.198-.298.198-.476zm2.699 0c0-.133-.04-.264-.114-.375s-.18-.197-.303-.249-.259-.064-.39-.038-.251.09-.345.185-.159.215-.185.345-.013.267.038.39.138.229.249.303.241.114.375.114c.179-.001.35-.072.476-.198s.198-.298.198-.476z' fill='%23fff'/%3E%3Cdefs%3E%3ClinearGradient id='A' x1='0' y1='0' x2='27.541' y2='4.99' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='.04' stop-color='%231877f2'/%3E%3Cstop offset='1' stop-color='%230ec9f7'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");

          i {
            display: none;
          }
        }
      }
    }

    .assigned {
      order: 1;
    }

    .ongoing {
      order: 2;

      .icon {
        &:not(.unhighlight) {
          position: relative;
          height: 26px;
          width: 26px;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='26' height='26' fill='none' xmlns:v='https://vecta.io/nano'%3E%3Cpath d='M0 5a5 5 0 0 1 5-5h16a5 5 0 0 1 5 5v16a5 5 0 0 1-5 5H5a5 5 0 0 1-5-5V5z' fill='url(%23A)'/%3E%3Cg fill='%23fff'%3E%3Cpath d='M11.976 20l-2.042-2.042h-.946c-.792-.002-1.551-.317-2.111-.877S6.002 15.762 6 14.97v-3.589c.002-.792.317-1.551.877-2.111s1.319-.875 2.111-.877h5.976c.793 0 1.554.315 2.115.875s.877 1.32.879 2.113v3.589c-.002.793-.318 1.553-.879 2.113s-1.322.875-2.115.875h-.946L11.976 20zM8.988 9.589a1.8 1.8 0 0 0-1.798 1.791v3.589c.002.476.192.932.528 1.269s.793.527 1.269.528h1.441l1.547 1.541 1.547-1.541h1.441c.476-.002.933-.192 1.269-.528s.527-.793.528-1.269v-3.589a1.8 1.8 0 0 0-1.798-1.791H8.988z'/%3E%3Cpath d='M17.357 15.565v-1.19a1.8 1.8 0 0 0 .69-.14 1.81 1.81 0 0 0 .584-.393c.167-.168.299-.367.389-.587a1.8 1.8 0 0 0 .135-.691v-3.57c-.002-.476-.192-.933-.528-1.269s-.793-.527-1.269-.528h-5.976c-.476.002-.933.192-1.269.528s-.527.793-.528 1.269H8.387a2.99 2.99 0 0 1 .877-2.117C9.826 6.316 10.587 6 11.381 6h5.976c.793.002 1.553.318 2.113.879s.875 1.322.875 2.115v3.57c.001.393-.076.783-.226 1.146s-.37.694-.647.973a2.98 2.98 0 0 1-.97.651 3 3 0 0 1-1.145.23zm-8.369-2.988h1.196v1.197H8.988v-1.197zm2.393 0h1.196v1.197h-1.196v-1.197zm2.386 0h1.196v1.197h-1.196v-1.197z'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='A' x1='0' y1='0' x2='27.541' y2='4.99' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='.04' stop-color='%231877f2'/%3E%3Cstop offset='1' stop-color='%230ec9f7'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");

          i {
            display: none;
          }
        }
      }
    }

    .ongoing,
    .unassigned {
      .icon span {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8px;
        line-height: 8px;
        color: #fff;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background-color: #10c3f7;
        border: 1px solid #fff;
        top: -12px;
        right: -14px;
      }
    }
  }

  .not-enabled {
    align-items: center;
    background-color: #f6f7f8;
    height: 72px;
    justify-content: center;

    i {
      font-size: 2.5em;
    }
  }
}
