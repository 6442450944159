ssi-tags-input-2 {
  font-size: 1rem;
  width: 100%;
}
ssi-tags-input-2 .tags-input {
  display: flex;
  width: 100%;
}
ssi-tags-input-2 .search-submit {
  display: inline-flex;
  background-color: #14bae3;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  white-space: nowrap;
}
ssi-tags-input-2 .search-submit button {
  font-weight: 700;
  font-size: 14px;
  color: #838eab;
  background-color: #f4f4fa;
  border: none;
  border-left: 1px solid #c3c9d8;
  padding: 10px;
  appearance: none;
  outline: none;
}
ssi-tags-input-2 .search-submit button i {
  margin-right: 5px;
}
@media (max-width: 479px) {
  ssi-tags-input-2 .search-submit button span {
    display: none;
  }
}
ssi-tags-input-2 .tags-input-input-wrap {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  border: 0.1em solid #c3c9d8;
  overflow: hidden;
  margin-left: -0.1em;
  border-radius: 1em;
  background-color: #fff;
  padding: 0;
  width: auto;
}
ssi-tags-input-2 .tags-input-input-wrap-focused {
  outline: 1px solid #14bae3;
  z-index: 2;
  border-color: #14bae3;
}
ssi-tags-input-2 .tags-input-input-wrap-focused .tags-input-input-container-icon {
  color: #14bae3;
}
ssi-tags-input-2 .tags-input-input-wrap-focused .search-submit {
  background-color: #14bae3;
}
ssi-tags-input-2 .tags-input-input-wrap-focused .search-submit button {
  color: #fff;
  background-color: #14bae3;
  border-color: #14bae3;
}
ssi-tags-input-2 .tags-input-input-container {
  display: flex;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  padding-left: 2rem;
}
ssi-tags-input-2 .tags-input-input-container-noPadding {
  padding-left: 0;
}
ssi-tags-input-2 .tags-input-tags {
  flex: 0 0 auto;
  margin: 0;
  padding: 0.5em;
}
ssi-tags-input-2 .tags-input-tags-noPadding {
  padding: 0;
}
ssi-tags-input-2 .tags-input-tag {
  font-size: 1.2em;
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0 3rem 0 1.1rem;
  margin: 0 0.2rem 0 0;
  flex: 0 1 auto;
  height: 36px;
  background-color: #fbfbfe;
  border: 0.1rem solid #c3c9d8;
  border-radius: 0.8rem;
}
ssi-tags-input-2 .tags-input-tag span {
  font-size: 1.2rem;
  line-height: 1.2rem;
  min-height: 1rem;
  color: #101525;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-tags-input-2 .tags-input-tag-action {
  position: absolute;
  display: block;
  right: 0.6rem;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 0 0.5rem;
  border-width: 0;
  background-color: transparent;
  line-height: 0;
}
ssi-tags-input-2 .tags-input-tag-action:hover .ssi, ssi-tags-input-2 .tags-input-tag-action:focus .ssi {
  color: #14bae3;
}
ssi-tags-input-2 .tags-input-tag-action .ssi {
  font-size: 0.8rem;
  color: #43537f;
}
ssi-tags-input-2 .tags-input-input {
  flex: 1 1 auto;
  height: 46px;
  color: #101525;
  font-weight: 700;
  font-size: 1.4em;
  text-rendering: geometricPrecision;
  border-width: 0;
  min-width: 22rem;
  outline: 0;
  text-indent: 0.7rem;
}
ssi-tags-input-2 .tags-input-input:disabled {
  background-color: #fff;
  color: transparent;
}
ssi-tags-input-2 .tags-input-input:disabled::placeholder {
  color: transparent;
}
ssi-tags-input-2 .tags-input-input::placeholder {
  color: #6a759a;
}
@media (max-width: 479px) {
  ssi-tags-input-2 .tags-input-input {
    min-width: initial;
  }
}