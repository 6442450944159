@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-widget-influencers-list {
  font-size: 1rem;

  .influencers-list-wrap {
    position: relative;
    display: flex;
    height: inherit;
    overflow: auto;
    padding: 2.2em 1.4em 0;

    ssi-influencers-list {
      align-self: center;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}
