@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../angularjs/common/sass/variables';
@import '../../../../../../angularjs/common/sass/mixins';

ssi-inbox-crm-external-integration-person {
  display: flex;
  height: 100%;
  background-color: #f8f8f8;
  position: relative;

  .loading {
    width: 100%;
  }

  iframe {
    width: 100%;
    height: 1px;
    min-height: 100%;
    border: 0;
  }

  .person-details-container,
  .person-activities-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  .person-activities-container {
    @include slide-in-overlay();
    background-color: inherit;
  }

  .person-activities-container.visible {
    max-height: 100%;
  }

  .person-key-value-list,
  .person-activities {
    padding: 20px;
    font-size: 12px;
    flex: 1;
    overflow-y: auto;
  }

  .person-key-value-list {
    dt {
      text-transform: uppercase;
      color: #02acc1;
      margin-bottom: 3px;
    }

    dd {
      color: #3a3d3e;
      margin: 0 0 20px;
    }

    a {
      color: #3a3d3e;
      text-decoration: none;
    }
  }

  $comments-header-bg: #4a4c4d;

  .section-footer {
    background-color: $comments-header-bg;
    color: white;
    text-align: center;
    padding: 15px;
    border-right: solid 10px #02acc1;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: normal;
    cursor: pointer;
  }

  .section-footer:hover {
    background-color: darken($comments-header-bg, 5%);
  }

  .person-activity {
    padding: $section-margin-width 0;
  }

  .person-activity:not(:last-child) {
    border-bottom: solid 1px #ced9df;
  }

  .person-activity-header {
    font-weight: bold;
    margin-bottom: 5px;
    color: #596065;
  }

  .person-activity-body {
    font-weight: normal;
    color: #778994;
  }
}
