@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';
@import '../../styles/variables';

ssi-menu {
  .menu-container {
    position: relative;
    display: inline-block;

    .menu-head {
      cursor: pointer;

      .menu-trigger-icon {
        font-size: 14px;
        padding: 8px;
        border-radius: 50%;

        &:hover {
          background-color: #f4f4fa;
        }
      }
    }

    .menu-body {
      position: absolute;
      z-index: 999;
      background-color: #fff;
      border-radius: 10px;
    }
  }
}
