@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

.gif-search-modal {
  .modal-content {
    background-color: #fff;
  }
}

ssi-gif-search-modal {
  .gif-search-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #fff;
    padding: 6px 15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .gifs-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    height: 400px;
    overflow: auto;
    overflow-x: hidden;
    background-color: #fff;
    margin: 0 7px;
  }

  .gif-box {
    position: relative;
    cursor: pointer;

    label {
      position: absolute;
      color: #fff;
      font-weight: 900;
      bottom: 0;
      left: 5px;
      text-transform: capitalize;
    }

    &.category {
      width: 50%;
      height: 150px;
      margin: 3px;
      width: calc(50% - 6px);
      &:nth-child(odd) {
        margin-left: 0;
        width: calc(50% - 3px);
      }
      &:nth-child(even) {
        margin-right: 0;
        width: calc(50% - 3px);
      }

      img {
        width: 100%;
        height: 150px;
      }
    }
  }

  .search-input-container {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-right: 30px;

    input {
      background-color: #f8f9fd;
      outline: none;
      border: none;
      color: $lmode-helper-text;
      border: 1px solid #c3c9d8;
      border-radius: 10px;
      padding: 12px 24px;
      margin: 12px 0px;
      width: 100%;
      font-family: 'Lato', 'Arial', sans-serif;
      font-weight: bold;
      &::placeholder {
        color: $lmode-helper-text;
        font-weight: bold;
      }

      &.active {
        color: $lmode-title-text;
        border: 1px solid $primary-active;
      }
    }

    .cancel-search {
      min-width: 160px;
      appearance: none;
      box-shadow: none;
      border: none;
      background-color: #fff;
      border-radius: 10px;
      border-color: $lmode-helper-text;
      display: flex;
      align-items: center;
      font-weight: 900;
      cursor: pointer;
      color: $lmode-body-text;

      i {
        transform: rotate(270deg);
        margin-right: 7px;
      }

      &.visible {
        visibility: visible;
      }
    }
  }

  i.close-icon {
    padding: 12px;
    background-color: $lmode-border-color;
    border-radius: 50px;
    color: $lmode-helper-text;
    font-size: 10px;
    &:hover {
      cursor: pointer;
    }
  }

  // @giphy/js-components element style:
  .giphy-gif {
    cursor: pointer;
  }

  > img {
    margin-left: auto;
    display: flex;
    padding: 7px 0;
  }
}
