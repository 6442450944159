ssi-countdown {
  display: flex;
  align-items: center;
  width: max-content;
  margin-right: auto;
  padding: 8px;
  background-color: #faeeeb;
  color: #f88c68;
  border-radius: 10px;
  border: 1px solid #f88c68;
}
ssi-countdown i {
  font-size: 15px !important;
  margin-right: 5px;
}
ssi-countdown span {
  overflow: hidden;
  font-size: 12px;
  font-weight: 700;
  margin-right: 5px;
}