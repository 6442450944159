@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-cl-accordion {
  .cl-accordion-container {
    .body {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      > * {
        margin: 10px;
        margin-bottom: 30px;
        flex: 0 0 calc(50% - 20px);
        width: calc(50% - 20px);

        a {
          font-size: 10px;
        }
      }
    }
  }

  .toggle-label {
    display: flex;
    height: 100%;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #c3c9d8;
    padding: 0 8px 0 25px;

    p {
      color: #43537f;
      margin: 0;
      font-weight: 900;
      text-rendering: geometricPrecision;
    }
  }
}
