@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../library/sass/live-chat/colours.scss';
@import '~/styles/colors';

$unit: 2.5px;

$agent-message-background-colour: #fff;
$list-item-avatar-horizontal-offset: $unit * 10;
$list-item-margin: $unit * 8;
$list-item-avatar-horizontal-margin: $list-item-margin +
  $list-item-avatar-horizontal-offset;
$list-item-avatar-size: $unit * 20;
$message-author-name-colour: #49aee6;
$message-secondary-text-colour: #beccd3;
$visitor-message-background-colour: #e3eaed;
$visitor-message-border-colour: #fff;
$visitor-selected-message-background-colour: #d8e1e5;

ssi-live-chat-message {
  flex-flow: column nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  padding: ($unit * 14) 0 0 0;
  position: relative;
  width: 100%;

  &.agent,
  &.robot {
    ssi-live-chat-network-state {
      left: auto;
      right: -$list-item-avatar-horizontal-offset;
    }
  }

  &.visitor {
    &.selected {
      .message-body {
        background-color: $visitor-selected-message-background-colour;
        border-color: $visitor-message-border-colour;
      }

      & > .tags-icon {
        opacity: 1;
      }
    }

    .message-body {
      background-color: $visitor-message-background-colour;
    }

    ssi-live-chat-network-state {
      left: -$list-item-avatar-horizontal-offset;
    }

    .tags-icon {
      align-items: center;
      background: #e3eaed;
      border: none;
      border-radius: 50%;
      color: #49b0e8;
      font-size: 1.25em;
      font-weight: 400;
      height: ($unit * 16);
      justify-content: center;
      outline: none;
      padding: 10px;
      position: absolute;
      right: 0px;
      top: ($unit * 14);
      width: ($unit * 16);
      z-index: 1;
    }
  }

  ssi-live-chat-network-state {
    height: $list-item-avatar-size;
    left: ($unit * 2);
    position: absolute;
    // top: -($unit * 10);
    top: ($unit * 2);
    width: $list-item-avatar-size;
  }

  .message-body {
    background-color: $agent-message-background-colour;
    border: solid ($unit * 1) transparent;
    border-radius: ($unit * 8);
    cursor: pointer;
    flex-flow: column nowrap;
    min-height: ($unit * 40);
    padding: ($unit * 8) ($unit * 16);

    & > .age {
      color: $message-secondary-text-colour;
      display: inline;
      font-size: 0.8em;
      font-style: italic;
      font-weight: 600;
      text-align: right;
    }

    & > .text {
      display: inline;
      hyphens: auto;
      overflow-wrap: break-word;
      word-break: break-word;
      word-wrap: break-word;

      * {
        display: inline;
      }
    }

    & > .attachment {
      img {
        max-height: ($unit * 80);
        max-width: ($unit * 80);
        object-fit: contain;
      }
    }

    & > p {
      margin: 0 0 2% 0;
      font-weight: 400;

      &.additional {
        color: darken($message-secondary-text-colour, 40%);
        font-size: 0.9em;
        font-style: italic;

        & > * {
          & + * {
            border-left: solid 1px;
            margin-left: $unit * 2;
            padding-left: $unit * 2;
          }
        }

        .network-state {
          text-transform: capitalize;
        }
      }

      &.author {
        color: darken($message-author-name-colour, 30%);
        flex-flow: column nowrap;
        font-size: 1.3em;
        white-space: nowrap;

        & > * {
          display: inline;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  .message-footer {
    align-items: flex-end;
    flex-flow: row nowrap;
    font-size: 0.85em;
    justify-content: flex-end;
    list-style: none;
    margin: ($unit * 4) 0 0 0;
    padding: 0;
    white-space: nowrap;

    & > li {
      align-items: center;
      background-color: #e3eaed;
      border-radius: ($unit * 4);
      border: none;
      color: $primary-blue;
      cursor: pointer;
      flex-shrink: 0;
      font-size: 0.85em;
      font-style: italic;
      margin: 0;
      padding: ($unit * 1) ($unit * 4);

      &.message-footer-notes {
        .message-footer-notes-label {
          font-weight: 400;
          padding-left: ($unit * 1);

          .message-footer-notes-label-text {
            padding-right: ($unit * 1);
          }
        }
      }
    }
  }

  .translate {
    margin-bottom: $unit * 2;
  }
}
