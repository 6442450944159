@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-account-pill {
  font-size: 1rem;

  .account-pill {
    display: inline-flex;
    align-items: center;
    padding: 1.1em 1em;
    background-color: #ebebf4;
    border-radius: 1em;

    &-icon {
      color: $lmode-helper-text;
      margin-right: 0.7em;

      &:before {
        font-size: 1.4em;
      }
    }

    &-label {
      color: $lmode-body-text;
      font-size: 1.2em;
      font-weight: 900;
      text-rendering: geometricPrecision;
      margin: 0;
    }
  }
}
