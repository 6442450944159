ssi-engagement ssi-inbox-sentiment {
  font-size: 1rem;
}
ssi-engagement ssi-inbox-sentiment .inbox-sentiment-container {
  display: flex;
  flex-wrap: wrap;
}
@media print {
  ssi-engagement ssi-inbox-sentiment .inbox-sentiment-container {
    display: block;
  }
}
ssi-engagement ssi-inbox-sentiment .sentiment-boxes {
  width: calc(50% - 1em);
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
  margin-left: 1em;
}
@media print {
  ssi-engagement ssi-inbox-sentiment .sentiment-boxes {
    display: block;
  }
}
@media (max-width: 1199px) {
  ssi-engagement ssi-inbox-sentiment .sentiment-boxes {
    width: 100%;
    margin-left: 0;
    margin-top: 2em;
  }
}
ssi-engagement ssi-inbox-sentiment .inbox-sentiment-pie-chart {
  height: 40em;
  width: calc(50% - 2em);
  margin-right: 1em;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.25);
  page-break-inside: avoid;
}
@media print {
  ssi-engagement ssi-inbox-sentiment .inbox-sentiment-pie-chart {
    border: 1px solid black;
  }
}
@media (max-width: 1199px) {
  ssi-engagement ssi-inbox-sentiment .inbox-sentiment-pie-chart {
    width: 100%;
    margin-right: 0;
  }
}
ssi-engagement ssi-inbox-sentiment .inbox-sentiment-pie-chart .highchart-legend-inline {
  display: inline-block;
  min-width: 3.5em;
}
ssi-engagement ssi-inbox-sentiment .inbox-sentiment-pie-chart .highchart-legend-inline + span {
  margin-left: 0.5em;
}
ssi-engagement ssi-inbox-sentiment ssi-fact-overview,
ssi-engagement ssi-inbox-sentiment ssi-sentiment-card {
  display: flex;
  flex: 1 1 calc(33.33% - 2em);
  height: 19em;
}
@media (max-width: 1023px) {
  ssi-engagement ssi-inbox-sentiment ssi-fact-overview,
ssi-engagement ssi-inbox-sentiment ssi-sentiment-card {
    flex: 1 1 calc(50% - 2em);
  }
}
@media (max-width: 767px) {
  ssi-engagement ssi-inbox-sentiment ssi-fact-overview,
ssi-engagement ssi-inbox-sentiment ssi-sentiment-card {
    flex-basis: 100%;
  }
}