@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-triggers-range-selector {
  nouislider {
    display: block;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  *:focus {
    outline: none;
  }

  .noUi-horizontal {
    height: 10px;
    border: 0;
    background-color: #f0f2f3;
  }

  .noUi-horizontal .noUi-tooltip {
    border: 0;
    background: none;
    bottom: 10%;
    color: white;
    font-size: 11px;
    font-style: italic;
    font-weight: normal;
  }

  .noUi-horizontal .noUi-handle {
    border-radius: 20px;
    width: 28px;
    border: 0;
    background-color: $primary-active;
    box-shadow: none;
    top: -8px;
  }

  .noUi-connect {
    background-color: #d2e2eb;
    box-shadow: none;
  }

  .noUi-handle:after,
  .noUi-handle:before {
    content: normal;
  }
}
