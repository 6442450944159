@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

$header-height: 60px;

ssi-custom-widget {
  font-size: 1rem;
  display: block;
  height: 100%;

  .cw-container {
    display: flex;
    min-height: 100%;
    background-color: $lgrey-background;
    border-radius: 1.2em;
  }

  .cw-side {
    position: relative;
    width: 30em;
    flex: 0 0 30em;
    border-right: 0.1em solid $lmode-helper-text;
    min-height: 100%;
    color: $lmode-body-text;
    z-index: 3;
  }

  .cw-side-tabs {
    display: flex;
    height: $header-height;
    border-top-left-radius: 1.2em;
    overflow: hidden;
  }

  .cw-filters {
    text-align: left;
    background-color: #fff;
    height: calc(100% - #{$header-height});
    border-bottom-left-radius: 1.2em;
    opacity: 0;
    visibility: hidden;
    display: none;
    &.active {
      opacity: 1;
      visibility: visible;
      display: block;
    }
    ssi-global-filters {
      .global-filters {
        ul {
          padding: 0 10px;
        }
      }
    }
  }

  .cw-side-tab {
    position: relative;
    display: inline-flex;
    flex: 1 0 0;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    letter-spacing: 0.1em;
    border: 0 solid $lmode-helper-text;
    border-bottom-width: 0.1em;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    // outline: 0;
    cursor: pointer;

    p {
      font-size: 1em;
      margin: 0;
      line-height: 1;
      color: $lmode-helper-text;
      text-transform: uppercase;
      font-weight: 900;
      text-rendering: geometricPrecision;
    }

    &.cw-side-tab-active {
      background-color: #fff;
      border-top-width: 0.1em;
      border-left-width: 0.1em;
      border-right-width: 0.1em;
      border-bottom-width: 0;
      cursor: auto;

      &:first-child {
        border-left-width: 0;
        border-top-left-radius: 0;
      }

      &:last-child {
        border-right-width: 0;
        border-top-right-radius: 0;
      }

      p {
        color: $lmode-body-text;
        margin: -0.2em 0 0;

        &:after {
          content: '';
          position: absolute;
          margin: auto;
          bottom: 0.4em;
          left: 0;
          right: 0;
          display: block;
          width: 5em;
          height: 0.2em;
          background-color: $primary-active;
        }
      }
    }
  }

  .cw-side-form {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    height: calc(100% - #{$header-height});
    padding: 4em 3em 2em;
    text-align: left;
    border-bottom-left-radius: 1.2em;
    opacity: 0;
    visibility: hidden;
    display: none;
    &.active {
      opacity: 1;
      visibility: visible;
      display: flex;
    }
  }

  .cw-side-form-section {
    position: relative;
    margin: 0 0 3.5em;

    &-action {
      border-top: 1px solid $lmode-helper-text;
      text-align: center;
      margin: 0 -3em 3.5em;
      padding: 2em 1em 0;
    }

    &-stretch {
      flex: 1 1 auto;
      margin-right: -2em;
      padding-right: 2em;
    }

    &:last-child {
      margin-bottom: 0;
      border-bottom-left-radius: 1.2em;
    }
  }

  .cw-side-form-heading {
    font-size: 2em;
    padding: 0 0 2rem;
    margin: 0;
    line-height: 1;
    font-weight: 900;
    text-rendering: geometricPrecision;
  }

  .cw-side-form-subheading {
    color: $lmode-body-text;
    font-size: 1em;
    letter-spacing: 0.1rem;
    padding: 0.8rem 0 1.6rem;
    margin: 0;
    text-transform: uppercase;
    font-weight: 900;
    text-rendering: geometricPrecision;
  }

  .cw-grouped-radio {
    margin: 0 -0.6em 0 0;
    padding: 0;
    z-index: 1;
  }

  .cw-grouped-radio-field {
    position: relative;
    display: inline-block;
    height: 5em;
    width: 5em;
    margin: 0 0.6em 0.6em 0;

    input {
      position: absolute;
      z-index: 0;

      &:checked + label {
        background-color: $primary-active;
        color: #fff;
        z-index: 1;
      }

      &:focus-visible + label {
        outline: -webkit-focus-ring-color auto 1px;
        outline-color: -webkit-focus-ring-color;
        outline-style: auto;
        outline-width: 1px;
      }
    }

    label {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $lmode-body-text;
      height: 100%;
      width: 100%;
      background-color: $lgrey-background;
      border-radius: 1em;
      cursor: pointer;

      i {
        font-size: 2em;
        line-height: 1;
      }
    }
  }

  .cw-side-form-confirm {
    position: absolute;
    top: 3.5em;
    left: 0;
    background-color: #fff;
    padding: 3em 3em 2.7em;
    width: 30em;
    color: $lmode-body-text;
    border-radius: 1em;
    box-shadow: 0 0.5em 1em rgba(131, 142, 171, 0.35);
    z-index: 2;

    p {
      font-weight: 700;
      font-size: 1.4em;
      line-height: 2rem;
      margin: 0 0 1.3rem;
      text-rendering: geometricPrecision;
    }
  }

  .cw-side-form-confirm-btn {
    font-size: 1.4em;
    color: inherit;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    font-weight: 900;
    z-index: 9;
    background-color: transparent;
    border-width: 0;
    text-rendering: geometricPrecision;

    &-primary {
      color: $primary-active;
    }
  }

  .cw-content {
    flex: 1 1 auto;
  }

  .cw-header {
    display: flex;
    height: $header-height;
    background-color: #fff;
    border-bottom: 0.1em solid $lmode-helper-text;
    padding: 1.2em;
    border-top-right-radius: 1.2em;
  }

  .cw-header-form {
    display: flex;
    align-items: center;
    flex: 1 0 auto;
  }

  .cw-header-form-input {
    flex: 0 1 auto;
    display: flex;
    align-items: center;

    input {
      flex: 1 1 auto;
      height: 100%;
      border-width: 0;
      font-size: 1.4em;
      color: $lmode-title-text;
      font-weight: 900;
      text-rendering: geometricPrecision;
      outline: 0;
      margin: 0 0 0.2rem;

      &::placeholder {
        color: $lmode-helper-text;
        font-weight: 700;
      }
    }

    &.cw-header-form-input-name {
      min-width: 25em;
    }

    &.cw-header-form-input-desc {
      flex: 1 0 auto;
    }
  }

  .cw-header-form-input-label {
    margin: 0;

    i {
      font-size: 1.2em;
      color: $lmode-body-text;
      padding: 0 1.2rem;
    }
  }

  .cw-header-actions {
    flex: 0 0 auto;
    padding: 0 0.8em;
  }

  .cw-header-action-button,
  .cw-side-action-button {
    display: inline-block;
    font-size: 1.4em;
    border: 0.1rem solid $lmode-helper-text;
    color: $lmode-body-text;
    height: 3.6rem;
    padding: 0 1.5em;
    white-space: nowrap;
    border-radius: 1rem;
    font-weight: 900;
    text-rendering: geometricPrecision;
    background-color: #fff;
    margin: 0 0 0 0.2em;

    &.cw-header-action-button-primary,
    &.cw-side-action-button-primary {
      background-color: $primary-active;
      color: #fff;
      border-color: $primary-active;
    }

    &[disabled] {
      background-color: $lgrey-background;
      border-color: $lgrey-background;
      color: $lmode-helper-text;
      cursor: not-allowed;
    }
  }

  .cw-body {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: calc(100% - #{$header-height});
    margin: 0.9em 1em;
  }

  .cw-chart-empty {
    width: 19em;
    align-self: center;
    margin: 4.7em 0 0;

    .ssi {
      font-size: 4.4em;
      line-height: 1;
      color: $dmode-body-text;
      margin: 0 0 2.7rem;
    }

    p {
      color: $lmode-body-text;
      font-size: 1.4em;
      line-height: 2rem;
      font-weight: 700;
      text-rendering: geometricPrecision;
      margin: 0;
    }

    .cw-chart-empty-title {
      font-size: 2em;
      font-weight: 900;
      margin: 0 0 1rem;
    }
  }

  .cw-chart-container {
    height: 100%;
    width: 100%;
  }

  .cw-chart-wrap {
    background-color: #fff;
    border: 0.1em solid $lmode-helper-text;
    border-radius: 1em;
    padding: 3em 1em;
  }

  .cw-chart-loading {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background-color: $lgrey-background;
    height: calc(100% - 2em);
    margin-top: -0.1em;
    width: 100%;
    border-bottom-left-radius: 1.2em;

    @keyframes spin {
      from {
        transform: rotate3d(0, 0, 0, 0deg);
      }
      to {
        transform: rotate3d(0, 0, 1, 360deg);
      }
    }

    img {
      height: 4em;
      width: 4em;
      margin: 0 0 3em;
      animation-name: spin;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    p {
      color: $lmode-body-text;
      font-size: 1.4em;
      line-height: 2rem;
      font-weight: 700;
      text-rendering: geometricPrecision;
      margin: 0;
    }
  }
}
