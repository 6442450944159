ssi-engagement-report {
  font-size: 1rem;
}
ssi-engagement-report ssi-report-header,
ssi-engagement-report ssi-report-sidebar {
  font-size: 1.4em;
}
ssi-engagement-report .loading-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 9.4em 0 12em 22.8em;
}
ssi-engagement-report .selected-date-ranges {
  border-radius: 10px;
  background-color: #ebebf4;
  padding: 10px;
}
ssi-engagement-report .selected-date-ranges span {
  margin-right: 7px;
}
ssi-engagement-report .selected-date-ranges .current {
  color: #101525;
  font-size: 14px;
  font-weight: 900;
}
ssi-engagement-report .selected-date-ranges .previous {
  color: #101525;
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
}
ssi-engagement-report .report-body {
  position: relative;
  width: 100%;
  padding-top: 13.4em;
  padding-left: 26.8em;
  height: 100%;
  padding-right: 4em;
  min-height: calc(100vh - 6.8em);
  background-color: #f4f4fa;
}
ssi-engagement-report .report-container {
  max-width: 192em;
  margin: auto;
}
ssi-engagement-report .section-delimiter {
  width: 100%;
  border-bottom: 0.2em solid #fff;
  margin: 3em 0;
}
ssi-engagement-report .section-title {
  color: #101525;
  font-weight: 900;
  text-rendering: geometricPrecision;
  font-size: 2em;
  margin: 0 0 4rem;
}
ssi-engagement-report .section-subtitle {
  color: #101525;
  font-weight: 700;
  text-rendering: geometricPrecision;
  font-size: 1.6em;
  margin: 1rem 0 3rem;
}
ssi-engagement-report .section-card {
  background-color: #fff;
  border-radius: 1.2em;
  padding: 2em;
  box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
  height: calc(100% - 2em);
  margin: 0 0 2em;
}
ssi-engagement-report .section-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1em 2.4em;
}
ssi-engagement-report .section-grid-item {
  flex: 1 0 0%;
  width: 100%;
  margin: 0 1em;
}
@supports (display: grid) {
  ssi-engagement-report .section-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(26.8em, 1fr));
    grid-gap: 0 2em;
    margin: 0;
  }
  ssi-engagement-report .section-grid-thirds {
    grid-template-columns: repeat(auto-fit, minmax(36.4em, 1fr));
  }
  ssi-engagement-report .section-grid-halves {
    grid-template-columns: repeat(auto-fit, minmax(55.6em, 1fr));
  }
  ssi-engagement-report .section-grid .section-grid-item {
    margin: 0;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  ssi-engagement-report .section-grid {
    display: flex;
    margin: 0 -1em 2.4em;
  }
  ssi-engagement-report .section-grid .section-grid-item {
    margin: 0 1em;
  }
}
ssi-engagement-report .account-list {
  margin: -0.5em -0.5em 0;
  padding: 0;
}
ssi-engagement-report .account-list li {
  display: inline-block;
  margin: 0.5em;
}
ssi-engagement-report .time-range-list {
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 1.2em;
  box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
  padding: 1.8em 1.6em;
}
@media print {
  ssi-engagement-report .time-range-list {
    display: block;
  }
}
ssi-engagement-report .time-range-list li {
  display: inline-flex;
  flex: 1 1 calc(50% - 2em);
  width: calc(50% - 2em);
  min-width: 30em;
  margin: 0.4em 1em;
}
@media print {
  ssi-engagement-report .time-range-list li {
    display: block;
    width: 100%;
  }
}
ssi-engagement-report .time-range-list li:nth-child(5n-3) .time-range-list-progress span {
  background-color: #89a4ea;
}
ssi-engagement-report .time-range-list li:nth-child(5n-2) .time-range-list-progress span {
  background-color: #f0b427;
}
ssi-engagement-report .time-range-list li:nth-child(5n-1) .time-range-list-progress span {
  background-color: #f88c68;
}
ssi-engagement-report .time-range-list li:nth-child(5n) .time-range-list-progress span {
  background-color: #ff3b0e;
}
ssi-engagement-report .time-range-list-item {
  display: flex;
  align-items: center;
  padding: 0.5em;
  width: 100%;
  border: 0.1em solid #14bae3;
  border-radius: 0.5em;
  box-shadow: 0 0.5em 1em rgba(131, 142, 171, 0.25);
  page-break-inside: avoid;
}
@media print {
  ssi-engagement-report .time-range-list-item {
    display: block;
    border: 1px solid black;
  }
}
ssi-engagement-report .time-range-list-range {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 10.5em;
  height: 4.6em;
  background-color: #f4f4fa;
  border-radius: 0.5em;
  color: #43537f;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-engagement-report .time-range-list-range span {
  font-size: 1.4em;
}
ssi-engagement-report .time-range-list-range-hyphen {
  display: inline-block;
  margin: 0 0.4em;
}
ssi-engagement-report .time-range-list-progress {
  flex: 1 1 auto;
  background-color: #f4f4fa !important;
  height: 0.4em;
  border-radius: 10em;
  margin: 0 3.2em 0 1.5em;
  overflow: hidden;
  min-width: 2em;
  -webkit-print-color-adjust: exact;
}
ssi-engagement-report .time-range-list-progress span {
  display: block;
  height: 100%;
  background-color: #14bae3;
  border-radius: 10em;
  -webkit-print-color-adjust: exact;
}
@media print {
  ssi-engagement-report .time-range-list-progress span {
    background-color: black !important;
  }
}
ssi-engagement-report .time-range-list-amount {
  flex: 0 0 auto;
  color: #101525;
  padding: 0 1.5em 0 0;
}
ssi-engagement-report .time-range-list-amount p {
  font-size: 1.6em;
  margin: 0;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-engagement-report .time-range-list-amount p.time-range-list-amount-format-wrap .time-range-list-amount-raw {
  display: none;
}
ssi-engagement-report .time-range-list-amount p.time-range-list-amount-format-wrap:hover .time-range-list-amount-formatted {
  display: none;
}
ssi-engagement-report .time-range-list-amount p.time-range-list-amount-format-wrap:hover .time-range-list-amount-raw {
  display: block;
}