ssi-publisher-targeting {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
ssi-publisher-targeting .publisher-targeting-header {
  padding: 20px 20px 15px 20px;
  align-items: center;
}
ssi-publisher-targeting .publisher-targeting-header .btn {
  margin-left: 3px;
  width: 140px;
}
ssi-publisher-targeting .publisher-targeting-body {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  box-shadow: inset 0 -17px 14px -14px #eceff6, inset 0 17px 14px -14px #eceff6;
  padding: 10px 30px;
  margin: 0 3px;
}
ssi-publisher-targeting .publisher-targeting-body label {
  font-size: 10px;
  letter-spacing: 1px;
  margin-top: 10px;
  margin-left: 15px;
  color: #43537f;
  font-weight: 900;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-transform: uppercase;
}
ssi-publisher-targeting .publisher-targeting-body ssi-outbox-typeahead .choices-container .choices {
  background: #f0f3f9;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
}
ssi-publisher-targeting .publisher-targeting-body ssi-outbox-typeahead .choices-container .choices li {
  color: #fff;
  background-color: #14bae3;
  font-style: normal;
  max-width: 140px;
}
ssi-publisher-targeting .publisher-targeting-body ssi-outbox-typeahead .choices-container .choices li:after {
  color: #fff;
}
ssi-publisher-targeting .publisher-targeting-body ssi-outbox-typeahead .choices-container input {
  background: #f0f3f9;
  border: none;
  border-radius: 20px;
  font-style: normal;
  padding: 10px 15px;
}
ssi-publisher-targeting .publisher-targeting-body ssi-outbox-typeahead .choices-container input::placeholder {
  color: #838eab;
  font-weight: bold;
}
ssi-publisher-targeting .publisher-targeting-body ssi-outbox-typeahead .suggestions {
  box-shadow: -1px 0px 7px 2px rgba(131, 142, 171, 0.35);
  border-radius: 20px;
  max-height: 160px;
}
ssi-publisher-targeting .publisher-targeting-body ssi-outbox-typeahead .suggestions-filter {
  text-transform: none;
}
ssi-publisher-targeting .publisher-targeting-body ssi-outbox-typeahead.has-value .suggestions-filter {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
ssi-publisher-targeting .publisher-targeting-body .row {
  margin-top: 10px;
}
ssi-publisher-targeting .publisher-targeting-footer {
  display: flex;
  align-items: center;
  padding: 20px 30px;
}
ssi-publisher-targeting .publisher-targeting-footer button {
  width: 240px;
  background-color: #14bae3;
  border-radius: 20px;
  padding: 8px 12px;
  color: #fff;
  border: none;
  font-weight: normal;
}
ssi-publisher-targeting .publisher-targeting-footer span {
  display: flex;
  align-items: center;
  color: #14bae3;
  font-weight: 900;
  font-size: 11px;
  letter-spacing: 1px;
}
ssi-publisher-targeting .publisher-targeting-footer i {
  margin-right: 5px;
  font-size: 16px;
}
ssi-publisher-targeting .publisher-targeting-footer button,
ssi-publisher-targeting .publisher-targeting-footer span {
  margin-right: auto;
}
ssi-publisher-targeting .tab-nav {
  position: relative;
  text-transform: uppercase;
  text-decoration: none;
  color: #515b77;
  margin-left: 20px;
  font-weight: 900;
  font-size: 10px;
  letter-spacing: 1px;
}
ssi-publisher-targeting .tab-nav:focus {
  outline: revert;
}
ssi-publisher-targeting .tab-nav:before {
  visibility: hidden;
  transform: scaleX(0);
  visibility: visible;
  content: "";
  position: absolute;
  top: 22px;
  height: 2px;
  width: 80%;
  left: 7%;
  background-color: #14bae3;
  transition: all 0.3s ease-in-out 0s;
}
ssi-publisher-targeting .tab-nav.active, ssi-publisher-targeting .tab-nav:hover {
  position: relative;
}
ssi-publisher-targeting .tab-nav.active:before, ssi-publisher-targeting .tab-nav:hover:before {
  visibility: visible;
  transform: scaleX(1);
}
ssi-publisher-targeting .tab-nav.exit {
  background: none;
  border: none;
  color: #14bae3;
  font-style: normal;
}
ssi-publisher-targeting .tab-nav.exit:before {
  visibility: hidden;
}
ssi-publisher-targeting ssi-dropdown-select-2 .variant-analytics {
  width: 100%;
}
ssi-publisher-targeting ssi-dropdown-select-2 .button-icon {
  top: 14px;
}
ssi-publisher-targeting ssi-dropdown-select-2 .dd-body {
  width: 100% !important;
}
ssi-publisher-targeting ssi-dropdown-select-2 ul li {
  position: relative;
}
ssi-publisher-targeting ssi-dropdown-select-2 ul li .tick-container i {
  top: 3px;
}
ssi-publisher-targeting .boundary-chips {
  text-align: left;
  margin: 0 0 -10px -10px;
  color: #fff;
  margin: 0;
  padding: 1em 0 0 10px;
}
ssi-publisher-targeting .boundary-chips li {
  float: left;
  position: relative;
}
ssi-publisher-targeting .boundary-chips span {
  padding: 6px 26px 6px 8px;
  font-size: 10px;
  margin: 0 4px 8px 0;
  background-color: #14bae3;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  text-rendering: geometricPrecision;
  word-break: break-all;
  font-weight: 900;
}
ssi-publisher-targeting .boundary-chips .ssi {
  position: absolute;
  width: 8px;
  height: 8px;
  font-size: 8px;
  right: 12px;
  top: 9px;
  cursor: pointer;
}

.dropdown-menu-container {
  display: flex;
  flex-flow: column nowrap;
}
.dropdown-menu-container .dropdown-menu {
  border-radius: 20px;
  box-shadow: 0 0.1em 1.5em 0 rgba(193, 193, 193, 0.65);
  border: 1px solid #f9f9f9;
}
.dropdown-menu-container .dropdown-menu button {
  background-color: #fff;
  border-radius: 20px;
}
.dropdown-menu-container .dropdown-menu button:hover {
  color: #14bae3;
  background: #f6f7f8;
}
.dropdown-menu-container .dropdown-menu-trigger {
  background: #f0f3f9;
  border: none;
  border-radius: 20px;
  color: #515b77;
  font-weight: bold;
  display: flex;
  max-width: 100% !important;
  padding: 10px 15px;
  position: relative;
  text-align: left;
}
.dropdown-menu-container .dropdown-menu-trigger .trigger-icon {
  color: #43ade8;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  position: absolute;
  right: 10px;
  top: 12.5px;
}
.dropdown-menu-container .dropdown-menu-trigger:focus, .dropdown-menu-container .dropdown-menu-trigger:active {
  border-bottom: 0 !important;
}
.dropdown-menu-container .dropdown-menu-trigger:focus {
  outline: revert;
}
.dropdown-menu-container .dropdown-menu-trigger.has-value > span {
  color: black;
  font-style: normal;
  font-weight: 900;
}
.dropdown-menu-container .dropdown-menu-trigger > span, .dropdown-menu-container .dropdown-menu-trigger > ul > li {
  font-family: "Lato", sans-serif !important;
  letter-spacing: normal !important;
}
.dropdown-menu-container .dropdown-menu-trigger > ul {
  left: 25px !important;
  max-height: 200px !important;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: -50px !important;
  transform: none !important;
  z-index: 1030 !important;
}
.dropdown-menu-container .dropdown-menu-trigger > ul > li {
  border: none;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
}
.dropdown-menu-container .dropdown-menu-trigger > ul > li > button {
  border: none;
  display: flex;
  font-size: 0.9em;
  font-style: normal;
  height: 30px !important;
  margin: 0;
  padding: 5px 20px !important;
  width: 100%;
  color: #5e6270;
}
.dropdown-menu-container .select-range {
  display: flex;
  justify-content: space-between;
}
.dropdown-menu-container .select-range button {
  width: 100%;
  color: #5e6270;
}
.dropdown-menu-container .select-range button:first-child {
  margin-right: 5px;
}
.dropdown-menu-container .select-range button:focus {
  outline: revert;
}
.dropdown-menu-container label {
  margin-bottom: 10px;
}