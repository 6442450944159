ssi-component-library .component-library-container {
  padding: 20px;
  background-color: #f0f3f9;
  width: 100%;
}
ssi-component-library .component-library-container .section {
  width: 100%;
  margin-bottom: 40px;
  border-top: 1px solid #d8d8d8;
  padding-top: 10px;
}