ssi-link-clicks-section .total-guidance-container {
  display: flex;
  margin: 20px 0;
}
@media print {
  ssi-link-clicks-section .total-guidance-container {
    display: block;
  }
}
ssi-link-clicks-section .total-clicks {
  margin-right: 20px;
}
ssi-link-clicks-section .guidance-text {
  flex: 1 1 60%;
  display: inline-flex;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 5px 20px 0 rgba(131, 142, 171, 0.2);
}
@media print {
  ssi-link-clicks-section .guidance-text {
    margin: 10px 0 0;
    border: 1px solid black;
  }
}
ssi-link-clicks-section .guidance-text .text {
  padding: 40px 20px 40px 50px;
}
@media (min-width: 1201px) {
  ssi-link-clicks-section .guidance-text .text {
    padding-right: 80px;
  }
}
ssi-link-clicks-section .guidance-text h2 {
  font-weight: 900;
  font-size: 20px;
  color: #43537f;
}
ssi-link-clicks-section .guidance-text p {
  font-weight: bold;
  color: #43537f;
  line-height: 20px;
}
ssi-link-clicks-section .guidance-text img {
  width: auto;
  height: 160px;
  margin-left: auto;
  margin-right: 40px;
  align-self: flex-end;
}
@media (max-width: 1023px) {
  ssi-link-clicks-section .guidance-text img {
    display: none;
  }
}
ssi-link-clicks-section .global-link-clicks {
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.35);
  padding: 30px;
}
@media print {
  ssi-link-clicks-section .global-link-clicks {
    display: block !important;
    page-break-inside: avoid;
  }
}
ssi-link-clicks-section .global-link-clicks .map-container {
  flex: 1 1 calc(70% - 20px);
}
@media print {
  ssi-link-clicks-section .global-link-clicks .map-container {
    display: block;
    page-break-inside: avoid;
  }
}
ssi-link-clicks-section .global-link-clicks .map-container ssi-map .agm-map-container-inner {
  border-radius: 12px;
}
ssi-link-clicks-section .global-link-clicks .map-container h4 i {
  margin-left: 5px;
}
ssi-link-clicks-section .global-link-clicks .locations-list-container {
  flex: 1 1 calc(30% - 20px);
  border-radius: 12px;
  box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.35);
  margin-left: 20px;
  padding: 40px 40px 20px 40px;
  background-color: #fff;
}
@media print {
  ssi-link-clicks-section .global-link-clicks .locations-list-container {
    page-break-inside: avoid;
    border: 1px solid black !important;
  }
}
@media (max-width: 1199px) {
  ssi-link-clicks-section .global-link-clicks .locations-list-container {
    flex-basis: 100%;
    margin: 20px 0 0 0;
  }
}
ssi-link-clicks-section .global-link-clicks .locations-list-container.no-results {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
ssi-link-clicks-section .global-link-clicks .locations-list-container.no-results h4 {
  font-size: 20px;
  font-weight: 900;
  color: #43537f;
}
ssi-link-clicks-section .global-link-clicks .locations-list-container.no-results i {
  font-size: 50px;
  color: #838eab;
  margin-bottom: 20px;
}
ssi-link-clicks-section .global-link-clicks .locations-list-container.no-results p {
  font-weight: bold;
  color: #838eab;
  line-height: 20px;
}
ssi-link-clicks-section .global-link-clicks .locations-list-container.full-width {
  flex-basis: 100%;
  margin: 20px 0 0;
}
ssi-link-clicks-section .global-link-clicks ul {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 30px;
}
ssi-link-clicks-section .global-link-clicks li {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
ssi-link-clicks-section .global-link-clicks li span {
  min-width: 130px;
  margin: 0;
  font-weight: 900;
  color: #838eab;
}
ssi-link-clicks-section .global-link-clicks li span + span {
  min-width: 30px;
  color: #101525;
}
ssi-link-clicks-section highcharts-chart {
  border-radius: 12px;
  box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.35);
}
@media print {
  ssi-link-clicks-section highcharts-chart {
    page-break-inside: avoid;
  }
}