@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../library/sass/live-chat/colours.scss';
@import '~/styles/colors';

$unit: 2.5px;
$avatar-size: ($unit * 14);
$indicator-avatar-size: $unit * 16;
$indicator-width: ($unit * 80); // 200
$multiple-agents-item-height: $unit * 10; // 25

@keyframes blink-animation {
  0% {
    opacity: 0;
  }

  33% {
    opacity: 0.4;
  }

  66% {
    opacity: 0.8;
  }
}

ssi-live-chat-conversation-indicators {
  display: flex;
  flex-flow: column nowrap;
  flex-shrink: 0;

  * {
    display: flex;
  }

  & > ul {
    flex-shrink: 0;
    list-style: none;
    margin: ($unit * 16) ($unit * 8) 0 ($unit * 8);
    padding: 0;

    & > li {
      flex-shrink: 0;
      margin: 0;
      position: relative;

      .indicator {
        &.agent-typing {
          .indicator-inner {
            background-color: #fff;

            .indicator-blobs {
              color: #a6b7c0;
              font-size: 2.5em;
              height: auto;
              margin-top: -($unit * 8);
            }

            .indicator-text {
              font-style: italic;
            }
          }
        }

        &.agent-viewing {
          .indicator-inner {
            background-color: #fff;
          }
        }

        &.visitor-typing {
          .indicator-inner {
            height: $unit * 20;

            .indicator-blobs {
              font-size: 5em;
              margin: -($unit * 14) auto 0 auto;
            }
          }
        }

        .indicator-inner {
          align-items: center;
          background-color: #d8e1e5;
          border-radius: 40px;
          color: #fff;
          flex-flow: row nowrap;
          flex-grow: 0;
          flex-shrink: 1;
          height: $unit * 12;
          overflow: hidden;
          padding: $unit * 2;

          .indicator-avatar {
            border-radius: 50%;
            height: $indicator-avatar-size;
            width: $indicator-avatar-size;
          }

          .indicator-blobs {
            flex-grow: 0;
            font-size: 1.5em;

            justify-content: center;
            margin-top: -$unit * 8;
            margin-left: $unit * 4;

            span {
              animation: blink-animation 1s infinite;
              padding: 0 $unit;
            }
          }

          .indicator-icon {
            color: #a6b7c0;
            display: flex;
            font-size: 1.25em;
            font-weight: 600;
            margin-left: $unit * 4;
          }

          .indicator-text {
            display: inline;
            font-size: 0.9em;
            font-weight: 600;
            margin-left: $unit * 4;
            margin-right: $unit * 4;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            * {
              display: inline;
              font-weight: inherit;
            }
          }
        }
      }
    }
  }

  .indicator-inner {
    align-items: center;
    background-color: #fff;
    border-radius: ($unit * 24);
    font-size: 0.8em;
    font-weight: 400;
    margin: 0 ($unit * 2);
    min-width: ($unit * 80);
    padding: ($unit * 2);

    &.agent {
      .indicator-icon {
        i {
          color: #d2dbdf;
        }
      }

      .indicator-text {
        color: #1dafec;
      }
    }

    &.visitor {
      background: $live-chat-typing-indicator-gradient;
    }

    .indicator-icon {
      i {
        color: #fff;
        font-size: 2em;
        font-weight: 600;
      }

      flex-grow: 1;
      justify-content: center;
    }

    .indicator-text {
      align-items: center;
      color: $primary-blue;

      .indicator-text-state {
        margin: 0 ($unit * 4) 0 ($unit);
      }
    }

    ssi-live-chat-avatar {
      height: $avatar-size;
      width: $avatar-size;
    }
  }

  $multiple-agents-container-padding: $unit * 10;
  $multiple-agents-container-height: ($multiple-agents-item-height * 4) +
    ($multiple-agents-container-padding * 2);

  .multiple-agents-container {
    background-color: #fff;
    border-radius: $multiple-agents-container-padding;
    height: $multiple-agents-container-height;
    left: $unit * 2;
    overflow: none;
    padding: $multiple-agents-container-padding;
    position: absolute;
    top: -($multiple-agents-container-height + ($unit * 2));
    width: $indicator-width;

    .multiple-agents {
      flex-flow: column nowrap;
      flex-grow: 1;
      list-style: none;
      margin: 0;
      overflow-x: none;
      overflow-y: auto;
      padding: 0;

      &.typing {
        .indicator-blobs {
          color: #a6b7c0;
          font-size: 2.5em;
          height: auto;
          margin-top: -($unit * 11);

          span {
            animation: blink-animation 1s infinite;
            padding: 0 $unit;
          }
        }
      }

      &.viewing {
        .agent-status-icon {
          color: $primary-blue;
          font-weight: 400;
        }
      }

      & > li {
        align-items: center;
        flex-basis: $multiple-agents-item-height;
        flex-flow: row nowrap;
        flex-grow: 1;
        flex-shrink: 0;
        justify-content: space-between;
        margin: 0;
        padding: 0;

        .agent-avatar {
          border-radius: 50%;
          height: ($unit * 8);
          width: ($unit * 8);
        }

        .agent-name {
          color: #a6b7c0;
          display: inline;
          flex-grow: 1;
          font-size: 0.75em;
          font-weight: 600;
          overflow: hidden;
          padding-left: ($unit * 4);
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
