@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';
@import '../../../../../../../angularjs/common/sass/include-media';

ssi-multi-select-chips {
  font-size: 1rem;
  width: 100%;
  position: relative;

  .multi-select-chips {
    display: block;
  }

  .multi-select-chips-trigger {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 4.6em;
    width: 100%;
    border: 0.1em solid $lmode-helper-text;
    background-color: #fff;
    border-radius: 1em;
    padding: 0.4em;

    &[aria-expanded='true'] {
      visibility: hidden;
    }

    &.multi-line-chips {
      padding-bottom: 0;

      .multi-select-chips-trigger-chip {
        margin-bottom: 0.4em;
      }
    }
  }

  .multi-select-chips-trigger-text {
    color: $lmode-helper-text;
    font-size: 1.4em;
    font-weight: 700;
    min-height: 2.6rem;
    line-height: 2.6rem;
    text-rendering: geometricPrecision;
    margin: 0.2rem 0 0.2rem 2rem;
    text-align: left;

    @include media('<large-phone') {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 200px;
    }
  }

  .multi-select-chips-trigger-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2em;
    position: absolute;
    right: 1.8rem;
    color: $primary-active;
  }

  .multi-select-chips-trigger-chip {
    display: inline-flex;
    flex: 0 0 auto;
    align-items: center;
    background-color: #f4f4fa;
    border-radius: 0.5em;
    margin: 0 0.4em 0 0;
    padding: 0.8em;

    button {
      background-color: transparent;
      border-width: 0;
      padding: 0 0.5rem;
      margin: 0 0.2rem 0 0;
      font-size: 0.8em;

      &:hover i,
      &:focus i {
        color: $primary-active;
      }

      i {
        color: $lmode-helper-text;
      }
    }
  }

  .multi-select-chips-trigger-chip-icon {
    background-color: #12aca4;
    border-radius: 50%;
    display: inline-block;
    height: 2em;
    width: 2em;

    i {
      color: #fff;
      font-size: 1.2rem;
      line-height: 2rem;
    }
  }

  .multi-select-chips-trigger-chip-label {
    font-size: 1.4em;
    color: $lmode-title-text;
    font-weight: 900;
    text-rendering: geometricPrecision;
    margin: 0 0.5rem 0 0.8rem;
  }

  .multi-select-chips-dropdown {
    position: absolute;
    width: 100%;
    top: 46px;
    z-index: 10;
    background-color: #fff;
    padding: 3rem 3rem 4rem;
    margin: 0;
    border-radius: 1.5rem;
    box-shadow: 0 1.8rem 4rem rgba(131, 142, 171, 0.35);
    border-width: 0;

    .chips-filter-input {
      position: relative;
    }
  }

  .multi-select-chips-dropdown-input {
    width: 100%;
    background-color: #fff;
    border: none;
    border-bottom: 1px solid $primary-active;
    color: $lmode-body-text;
    font-size: 1.4rem;
    padding: 1.4rem 0.5rem;
    outline: 0;
    font-weight: 700;
    text-rendering: geometricPrecision;
    line-height: 1.4rem;
    margin: 0 0 2rem;

    &::placeholder {
      color: #6a759a;
    }

    & + i {
      position: absolute;
      top: 17px;
      right: 10px;
      font-size: 12px;
      color: $primary-active;
    }
  }

  .multi-select-chips-dropdown-list {
    margin: 0;
    padding: 0;
    max-height: 22.8rem;
    overflow: auto;
  }

  .multi-select-chips-dropdown-actions {
    margin: 0 0 1rem 0;
  }

  .multi-select-chips-dropdown-action {
    border-width: 0;
    background-color: transparent;
    font-size: 1.4rem;
    text-transform: capitalize;
    font-weight: 900;
    text-rendering: geometricPrecision;
    color: $lmode-body-text;
  }

  .multi-select-chips-dropdown-list-item {
    display: block;
    width: 100%;
  }

  .multi-select-chips-dropdown-list-action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: #fff;
    border-radius: 0.5rem;
    height: 4rem;
    border-width: 0;
    text-align: left;
    padding: 0 0.6rem;

    &:hover,
    &:focus {
      background-color: #f8f9fd;
    }
  }

  .multi-select-chips-dropdown-list-action-label {
    font-size: 1.4rem;
    color: $lmode-body-text;
    font-weight: 900;
    text-rendering: geometricPrecision;
  }

  .multi-select-chips-dropdown-list-action-state {
    display: inline-block;
    border: 1px solid $lmode-helper-text;
    border-radius: 0.2rem;
    height: 1.6rem;
    width: 1.6rem;

    i {
      display: block;
      margin: 0.1rem 0 0 0.1rem;
      font-size: 1.2rem;
      color: #fff;
    }
  }

  .multi-select-chips-dropdown-list-action-checked {
    .multi-select-chips-dropdown-list-action-label {
      color: $primary-active;
    }

    .multi-select-chips-dropdown-list-action-state {
      background-color: $primary-active;
      border-color: $primary-active;
    }
  }
}
