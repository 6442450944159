ssi-attachments .composer-attachments {
  margin-top: 20px;
}
ssi-attachments .composer-attachments h3 {
  font-size: 10px;
  font-weight: 900;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-align: left;
  color: #43537f;
  text-transform: uppercase;
  padding: 0 0 10px 20px;
}
ssi-attachments .composer-attachments .post-attachments-container {
  display: flex;
  margin-top: 10px;
  border-radius: 15px;
  padding: 10px;
  background-color: #f8f9fd;
  border: 1px solid #c3c9d8;
}
ssi-attachments .composer-attachments .post-attachment {
  width: 200px;
  height: 200px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  overflow: hidden;
  border-radius: 10px;
  border: 2px dashed #c3c9d8;
  background-color: #d9dfe9;
}
ssi-attachments .composer-attachments .post-attachment img {
  max-height: 100%;
  max-width: 100%;
  cursor: pointer;
}
ssi-attachments .composer-attachments .post-attachment video {
  max-height: 100%;
  max-width: 100%;
}
ssi-attachments .composer-attachments .post-attachment.add-new {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}
ssi-attachments .composer-attachments .post-attachment.upload-progress {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: white;
  border: 2px dashed #14bae3;
}
ssi-attachments .composer-attachments .post-attachment.hovered {
  border: 2px dashed #14bae3;
}
ssi-attachments .composer-attachments .post-attachment.hidden {
  display: none;
}
ssi-attachments .composer-attachments .post-attachment-type {
  position: absolute;
  top: 0px;
  left: 12px;
  font-size: 24px;
  color: white;
}
ssi-attachments .composer-attachments .post-attachment-upload-progress-type {
  font-size: 24px;
  color: #838eab;
}
ssi-attachments .composer-attachments .post-attachment-cancel {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #43537f;
  font-size: 10px;
}
ssi-attachments .composer-attachments .post-attachment-name {
  color: #43537f;
  font-size: 12px;
  font-weight: 900;
}
ssi-attachments .composer-attachments .post-attachment-size {
  color: #43537f;
  font-size: 12px;
  font-weight: 700;
}
ssi-attachments .composer-attachments .post-attachment-progress-bar {
  margin-top: 20px;
  width: 100%;
}
ssi-attachments .composer-attachments .post-attachment-progress-spin {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
ssi-attachments .composer-attachments .post-attachment-progress-spin .spinner {
  color: #14bae3;
}
ssi-attachments .composer-attachments .post-attachment-progress-spin .resizing-video-text {
  margin-top: 5px;
  color: #838eab;
  font-size: 14px;
  font-weight: 700;
  padding: 0 20px;
  text-align: center;
}
ssi-attachments .composer-attachments .post-attachment-progress-text {
  color: #43537f;
  font-size: 12px;
  font-weight: 900;
}
ssi-attachments .composer-attachments .post-attachment-options-menu {
  position: absolute;
  top: 0px;
  right: 0px;
}
ssi-attachments .composer-attachments .post-attachment-options-menu .post-attachment-options-menu-trigger {
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 10px;
  cursor: pointer;
}
ssi-attachments .composer-attachments .post-attachment-options-menu .post-attachment-options-menu-trigger i {
  font-size: 17px;
}
ssi-attachments .composer-attachments .post-attachment-options-menu .post-attachment-options-menu-trigger:hover {
  background-color: #e4faff;
  border: 1px solid #14bae3;
}
ssi-attachments .composer-attachments .post-attachment-options-menu .post-attachment-options-list .remove-icon {
  color: #ff4d11;
}
ssi-attachments .composer-attachments .post-attachment-action {
  position: absolute;
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 10px;
  cursor: pointer;
}
ssi-attachments .composer-attachments .post-attachment-action i {
  font-size: 17px;
}
ssi-attachments .composer-attachments .post-attachment-action.bottom-left {
  bottom: 0px;
  left: 0px;
}
ssi-attachments .composer-attachments .post-attachment-action.top-left {
  top: 0px;
  left: 0px;
}
ssi-attachments .composer-attachments .post-attachment-action.top-right {
  top: 0px;
  right: 0px;
}
ssi-attachments .composer-attachments .post-attachment-action.action-remove i {
  color: #ff4d11;
}
ssi-attachments .composer-attachments .post-attachment-action.action-remove:hover {
  background-color: #ffede7;
  border: 1px solid #ff4d11;
}
ssi-attachments .composer-attachments .post-attachment-action.action-edit:hover {
  background-color: #e4faff;
  border: 1px solid #14bae3;
}