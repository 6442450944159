ssi-content-calendar-nav {
  width: 100%;
}
ssi-content-calendar-nav .cc-nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 20px;
  height: 40px;
}
@media (max-width: 767px) {
  ssi-content-calendar-nav .cc-nav-container {
    display: none;
  }
}
ssi-content-calendar-nav .date-info-wrapper {
  position: relative;
}
ssi-content-calendar-nav .date-info-wrapper .ssi {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  font-size: 10px;
  height: 10px;
  right: 15px;
}
ssi-content-calendar-nav .date-info {
  color: #43537f;
  font-size: 18px;
  font-weight: bold;
  margin-left: 10px;
  white-space: nowrap;
  padding-right: 27px !important;
  max-width: 160px;
}
ssi-content-calendar-nav .left,
ssi-content-calendar-nav .right {
  display: flex;
  align-items: center;
}
ssi-content-calendar-nav .cc-nav-button {
  border: 1px solid #b4bbce;
  border-radius: 10px;
  padding: 12px 15px;
  font-size: 14px;
  font-weight: bold;
  color: #43537f;
  line-height: 14px;
  background: #fff;
  white-space: nowrap;
}
ssi-content-calendar-nav .cc-nav-button.filters-button {
  display: flex;
  align-items: center;
  cursor: pointer;
}
ssi-content-calendar-nav .cc-nav-button.filters-button i {
  font-size: 12px;
}
ssi-content-calendar-nav .cc-nav-button.filters-button i + span {
  margin-left: 5px;
}
ssi-content-calendar-nav .cc-nav-button.filters-button.active {
  border: 1px solid #14bae3;
  background-color: rgba(20, 186, 227, 0.1);
}
ssi-content-calendar-nav .cc-nav-button.filters-button span {
  font-weight: 900;
}
ssi-content-calendar-nav .cc-nav-button i {
  color: #43537f;
}
ssi-content-calendar-nav .left button {
  font-weight: 900;
  color: #43537f;
}
ssi-content-calendar-nav .right .text {
  color: #43537f;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  margin-right: 10px;
}
ssi-content-calendar-nav .right .campaign {
  padding: 11px;
}
ssi-content-calendar-nav .right .campaign i {
  font-size: 16px;
}
ssi-content-calendar-nav .right .campaign.active {
  background-color: #14bae3;
}
ssi-content-calendar-nav .right .campaign.active i {
  color: #fff;
}
ssi-content-calendar-nav .right .calendar-event {
  padding: 10px;
}
ssi-content-calendar-nav .right .calendar-event i {
  font-size: 18px;
}
ssi-content-calendar-nav .buttons {
  display: flex;
}
ssi-content-calendar-nav .buttons button {
  display: flex;
  align-items: center;
}
ssi-content-calendar-nav .buttons button:first-child {
  width: 40px;
}
ssi-content-calendar-nav .buttons button:first-child i {
  transform: rotate(90deg);
}
ssi-content-calendar-nav .buttons button:last-child {
  width: 40px;
}
ssi-content-calendar-nav .buttons button:last-child i {
  transform: rotate(270deg);
}
ssi-content-calendar-nav .buttons i {
  font-size: 10px;
}
ssi-content-calendar-nav .tabs {
  border: 1px solid #b4bbce;
  border-radius: 10px;
  padding: 1px;
  white-space: nowrap;
  background-color: #fff;
}
ssi-content-calendar-nav .tabs button {
  border: none;
  border-radius: 8px;
  padding: 11px 15px;
  color: #838eab;
  margin-left: 0;
}
ssi-content-calendar-nav .tabs button.active {
  background-color: #14bae3;
  color: #fff;
}
ssi-content-calendar-nav button:not(:first-child),
ssi-content-calendar-nav div:not(:first-child) {
  margin-left: 4px;
}
ssi-content-calendar-nav .dropdown-menu-wrap {
  width: 190px;
  margin: 0 0 10px;
}
ssi-content-calendar-nav .dropdown-menu-wrap.dropdown-menu {
  padding: 0;
  font-size: 1rem;
  background-color: transparent;
  border-width: 0;
  box-shadow: none;
  border-radius: 0;
}
ssi-content-calendar-nav .dropdown-menu-wrap.dropdown-menu.dropdown-menu-right {
  transform: translate(calc(50% - 20px), 43px) !important;
}
ssi-content-calendar-nav .cc-nav-container-mobile {
  background-color: #f4f4fa;
  display: none;
}
@media (max-width: 767px) {
  ssi-content-calendar-nav .cc-nav-container-mobile {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
ssi-content-calendar-nav .cc-nav-container-mobile button {
  padding: 12px;
}
ssi-content-calendar-nav .cc-nav-container-mobile button i {
  font-size: 12px;
}
ssi-content-calendar-nav .cc-nav-container-mobile .date-info {
  display: flex;
  align-items: center;
  color: #43537f;
  background: #fff;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  border: 1px solid #b4bbce;
  border-radius: 10px;
  padding: 12px 15px;
  white-space: nowrap;
  cursor: pointer;
  margin-left: 0;
}
ssi-content-calendar-nav .cc-nav-container-mobile .date-info i {
  font-size: 10px;
  margin-left: 7px;
}