ssi-widget-theme-cloud {
  display: flex;
  justify-content: center;
  font-size: 1rem;
  overflow: hidden;
  padding: 20px;
}
ssi-widget-theme-cloud .insights-widget {
  overflow: auto;
  height: 100%;
}
ssi-widget-theme-cloud .theme {
  display: inline-flex;
  margin-bottom: 7px;
  margin-right: 7px;
}
ssi-widget-theme-cloud .theme span {
  font-size: 12px;
  font-weight: 900;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  padding: 10px;
}
ssi-widget-theme-cloud .theme span.positive {
  background-color: rgba(0, 198, 115, 0.2);
  color: #008a50;
}
ssi-widget-theme-cloud .theme span.negative {
  color: #ff4d11;
  background-color: rgba(255, 77, 17, 0.2);
}
ssi-widget-theme-cloud .theme.large span {
  font-size: 20px;
  line-height: 20px;
}
ssi-widget-theme-cloud .theme:hover {
  cursor: pointer;
}
ssi-widget-theme-cloud .no-results-state {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}
ssi-widget-theme-cloud .no-results-state i {
  color: #b4bbce;
  font-size: 40px;
}
ssi-widget-theme-cloud .no-results-state h4 {
  margin-top: 10px;
  color: #43537f;
  font-weight: 900;
  font-size: 20px;
}
ssi-widget-theme-cloud .no-results-state p {
  color: #43537f;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}