@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

@import '../../../common/styles/colors.scss';

$mainHeaderHeight: 68px;

ssi-filters {
  .cc-filters-container {
    height: 100%;
    width: 100%;
    max-width: 540px;
    background-color: #fff;

    &.standalone {
      position: fixed;
      top: 0;
      right: 0;
      height: calc(100% - $mainHeaderHeight);
      z-index: 999;
      margin-top: $mainHeaderHeight;
      box-shadow: 0 18px 40px 0 rgba(131, 142, 171, 0.35);
    }

    .filters-wrapper {
      position: relative;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;

      .filters-section {
        flex: 1 1 auto;
        width: 100%;
        height: 100%;

        &.standalone {
          overflow-y: auto;
          overflow-x: hidden;
          padding: 30px 40px 40px 40px;
        }

        h3 {
          font-size: 20px;
          font-weight: 900;
          color: $lmode-body-text;
        }

        .input-search-container {
          position: relative;
          width: 100%;
          display: flex;
          align-items: center;

          .pro-tip-btn {
            width: 46px;
            height: 46px;
            margin-left: 5px;
            background-color: #fff;
            border: 1px solid $primary-active;
            i {
              color: $primary-active;
              margin: 0;
              font-size: 16px;
              font-weight: normal;
            }
          }

          i.loupe-icon {
            position: absolute;
            color: $lmode-helper-text;
            top: 15px;
            right: 18px;
            font-size: 14px;
            font-weight: 700;

            &.pro-tip-btn-visible {
              right: 65px;
            }
          }

          .search-chip {
            position: absolute;
            top: 3px;
            left: 3px;
            height: 40px;
            background-color: #f4f4fa;
            border: 1px solid #c3c9d8;
            border-radius: 8px;
            padding: 0 10px;

            display: flex;
            align-items: center;
            justify-content: center;

            span {
              font-size: 12px;
              color: #101525;
              font-weight: 900;
            }

            i {
              font-size: 8px;
              color: #43537f;
              font-weight: 500;
              margin-left: 10px;
              cursor: pointer;
            }
          }
        }

        .accordion {
          .accordion-item {
            border-bottom: 1px solid #c3c9d8;

            .filter-head {
              height: 50px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              cursor: pointer;

              .left {
                i {
                  margin-right: 20px;
                  font-weight: 500;
                  color: $lmode-helper-text;

                  &.filter-head-icon {
                    margin-left: 7px;
                    color: #6a759a;
                    font-size: 12px;
                    &.ssi-priority {
                      font-size: 10px;
                    }
                  }
                }

                .title {
                  font-size: 14px;
                  font-weight: 900;
                  color: $lmode-body-text;
                }
              }

              .right {
                .clear-filter-btn {
                  display: inline-block;
                  margin-right: 5px;
                  border: 0;

                  i {
                    color: #f88c68;
                    font-size: 8px;
                    margin-right: 5px;
                  }
                }

                i {
                  margin-right: 20px;
                  font-weight: 500;
                  color: $lmode-helper-text;

                  &.chevron {
                    // margin-left: auto;
                    font-size: 12px;
                    font-weight: 900;
                    color: $lmode-title-text;

                    transform: rotate(0deg);

                    &.up {
                      transform: rotate(180deg);
                    }
                  }
                }
              }
            }

            .filter-body {
              min-height: 100px;
              padding-bottom: 20px;
              //   padding-left: 5px;
              padding-right: 5px;

              .chevron.expand-campaign-icon {
                font-size: 12px;
                font-weight: 900;
                color: $lmode-title-text;

                transform: rotate(-90deg);

                &.down {
                  transform: rotate(0deg);
                }
              }

              ssi-list-select2 {
                .filter-container {
                  padding-left: 32px;
                }
              }

              ssi-dropdown-select-2 {
                .dd-body {
                  min-width: auto !important;
                  ul {
                    max-height: 200px !important;
                  }
                }
              }

              .tags-include-exclude-select-filter-container {
                width: 100%;
                padding: 10px 20px;

                > label {
                  color: $lmode-body-text;
                  font-size: 10px;
                  font-weight: 900;
                  letter-spacing: 1px;
                  text-transform: uppercase;
                  margin-top: 20px;
                  margin-left: 20px;
                  margin-bottom: 10px;
                  padding: 0;

                  &:first-child {
                    margin-top: 0;
                  }
                }
              }

              .date-range-filter-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                padding: 10px 20px;
                position: relative;

                &.extra-margin-top {
                  margin-top: 50px;
                }

                .form-field {
                  display: flex;
                  flex-direction: column;
                  flex-basis: 100%;

                  &.half {
                    flex-basis: 48%;
                  }

                  > label {
                    color: $lmode-body-text;
                    font-size: 10px;
                    font-weight: 900;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    margin-left: 20px;
                    margin-bottom: 10px;
                    padding: 0;
                  }

                  .input-wrapper {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .date-picker-input {
                      width: 100%;
                      height: 46px;
                      border: none;
                      outline: none;
                      border-radius: 100px;
                      display: flex;
                      align-items: center;
                      color: $lmode-title-text;
                      font-size: 14px;
                      font-weight: 900;
                      background-color: $grey-background;
                      padding: 10px 30px 10px 20px;

                      &::placeholder {
                        color: $lmode-helper-text;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 17px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .foot-section {
        flex: 0 0 auto;
        margin-top: auto;
        width: 100%;
        height: 70px;
        border-top: 1px solid #c3c9d8;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 40px;
        box-shadow: 0 18px 40px 0 #838eab59;

        button {
          margin-left: 10px;

          &.restore-btn {
            margin-right: auto;
          }
        }
      }
    }
  }
}
