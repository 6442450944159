ssi-composer .minimised-view {
  position: fixed;
  bottom: 0;
  right: 20px;
  z-index: 2000;
  border: 1px solid #14bae3;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: none;
  background-color: #fff;
  padding: 10px;
  width: 240px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #43537f;
  box-shadow: 0 5px 10px 0 rgba(16, 21, 37, 0.35);
}
ssi-composer .minimised-view:hover {
  cursor: pointer;
}
ssi-composer .minimised-view span {
  color: #101525;
  font-weight: 900;
}
ssi-composer .composer-inner-container {
  position: fixed;
  height: calc(100vh - 68px);
  width: 100vw;
  background: #fff;
  z-index: 1000;
  transition: all 0.3s ease-in-out;
  transform: translateY(0);
}
ssi-composer .composer-inner-container .composer-heading,
ssi-composer .composer-inner-container .composer-footer {
  padding: 15px 20px 11px;
}
ssi-composer .composer-inner-container .composer-heading {
  display: flex;
  justify-content: space-between;
  height: 77px;
  width: 100%;
  box-shadow: 0px 5px 10px 0 rgba(131, 142, 171, 0.2);
  border-bottom: 1px solid #c3c9d8;
}
ssi-composer .composer-inner-container .composer-heading .layout-control-actions {
  display: flex;
}
ssi-composer .composer-inner-container .composer-heading .layout-control-actions .layout-action {
  border: 1px solid #838eab;
  background-color: #fff;
  width: 46px;
  height: 46px;
  color: #43537f;
  margin-right: 5px;
}
ssi-composer .composer-inner-container .composer-heading .layout-control-actions .layout-action i {
  font-size: 20px;
}
ssi-composer .composer-inner-container .composer-heading .layout-control-actions .layout-action i.split-icon {
  font-size: 24px;
}
ssi-composer .composer-inner-container .composer-heading .layout-control-actions .layout-action.g-btn-wide {
  width: auto;
}
ssi-composer .composer-inner-container .composer-heading .layout-control-actions .layout-action:disabled {
  color: #838eab;
  background-color: #f4f4fa;
  border-color: #f4f4fa;
}
ssi-composer .composer-inner-container .composer-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px -5px 10px 0 rgba(131, 142, 171, 0.2);
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #c3c9d8;
}
@media (max-width: 479px) {
  ssi-composer .composer-inner-container .composer-footer {
    flex-wrap: wrap;
  }
  ssi-composer .composer-inner-container .composer-footer ssi-checkbox {
    width: 100%;
  }
}
@media (max-width: 479px) {
  ssi-composer .composer-inner-container .composer-footer button {
    width: 100%;
    margin-top: 10px;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}
ssi-composer .composer-inner-container .composer-footer .toggle-switch-2-container {
  border: none;
  background-color: inherit;
}
ssi-composer .composer-inner-container .composer-content {
  background-color: #f8f9fd;
  height: calc(100% - 154px + 1px);
  overflow: auto;
  display: flex;
}
@media (max-width: 767px) {
  ssi-composer .composer-inner-container .composer-content {
    flex-wrap: wrap;
  }
}
ssi-composer .composer-inner-container .image-icon,
ssi-composer .composer-inner-container .video-icon {
  color: #43537f;
}
ssi-composer .composer-inner-container .post-controls-container,
ssi-composer .composer-inner-container .post-preview-container {
  background-color: #f0f3f9;
  border: 1px solid #c3c9d8;
  flex: 1 1 50%;
  padding: 20px;
}
ssi-composer .composer-inner-container .post-controls-container {
  border-top: none;
  overflow: auto;
}
ssi-composer .composer-inner-container .post-preview-container {
  margin: 15px;
  border-radius: 12px;
}
ssi-composer .composer-inner-container.fixedRight {
  width: 50vw;
  left: initial;
  right: 0;
  box-shadow: -5px 0 7px 1px rgba(131, 142, 171, 0.2);
}
ssi-composer .composer-inner-container.fixedRight .composer-content {
  flex-wrap: wrap;
}
ssi-composer .composer-inner-container.fixedRight .composer-footer {
  max-height: 77px;
  width: 50vw;
}
ssi-composer .composer-inner-container.fixedRight .post-controls-container {
  border-right: none;
  border-left: none;
}
ssi-composer .composer-inner-container.fixedLeft {
  width: 50vw;
  left: 0;
  box-shadow: 5px 0 7px 1px rgba(131, 142, 171, 0.2);
}
ssi-composer .composer-inner-container.fixedLeft .composer-footer {
  width: 50vw;
}
ssi-composer .composer-inner-container.from-left {
  left: 0;
}
ssi-composer .composer-inner-container.full {
  width: 100vw;
  right: 0;
}
ssi-composer .composer-inner-container.full .composer-footer {
  width: 100vw;
}
ssi-composer .composer-inner-container.minimised {
  width: 0;
  right: 0;
  transform: translateY(100%);
}
ssi-composer .targeting-options-posts .cl-accordion-item {
  overflow: visible;
  will-change: initial;
}
ssi-composer .targeting-options-posts .multiple-targetable-account .accordion-body {
  padding: 1em;
}
ssi-composer .targeting-options-posts .multiple-targetable-account .accordion-body .multiple-accounts .targeting-options-item:first-child.accordion-content .cl-accordion-item {
  border-top: 1px solid #c3c9d8 !important;
  border-left: 1px solid #c3c9d8;
  border-right: 1px solid #c3c9d8;
  border-radius: 10px 10px 0 0 !important;
}
ssi-composer .targeting-options-posts .multiple-targetable-account .accordion-body .multiple-accounts .targeting-options-item:last-child.accordion-content .cl-accordion-item {
  border: 1px solid #c3c9d8 !important;
  border-radius: 0 0 10px 10px !important;
}
ssi-composer .targeting-options-posts .multiple-targetable-account .accordion-body .multiple-accounts .targeting-options-item.accordion-content .cl-accordion {
  margin-bottom: 0;
}
ssi-composer .targeting-options-posts .multiple-targetable-account .accordion-body .multiple-accounts .targeting-options-item.accordion-content .cl-accordion .cl-accordion-item {
  border-left: 1px solid #c3c9d8;
  border-right: 1px solid #c3c9d8;
  border-bottom: 0;
  border-radius: 0;
}
ssi-composer .accordion-content {
  color: #43537f;
  text-align: left;
}
ssi-composer .accordion-content-title {
  font-size: 14px;
  font-weight: 900;
  text-align: left;
  color: #43537f;
  padding-left: 30px;
}
ssi-composer .accordion-content-body {
  font-weight: 700;
  line-height: 20px;
  padding: 0 0 30px 30px;
  margin-bottom: 0;
}
ssi-composer .accordion-content-action {
  display: flex;
  align-items: center;
  background-color: #f4f4fa;
  border-radius: 10px;
  border: 1px solid #f4f4fa;
  color: #838eab;
  height: 46px;
  padding: 0 14px;
  cursor: not-allowed;
}
ssi-composer .accordion-content-action p {
  font-size: 14px;
  font-weight: 700;
  color: inherit;
  margin: 0 0 0 8px;
}
ssi-composer .accordion-content-action-active {
  cursor: pointer;
  background-color: #e4faff;
  border-color: #14bae3;
}
ssi-composer .accordion-content-action-active p {
  color: #43537f;
}
ssi-composer .accordion-content-action-active i {
  color: #14bae3;
}
ssi-composer .tags-campaigns {
  margin-top: 30px;
}
ssi-composer .tags-campaigns .cl-accordion-item {
  overflow: visible;
  will-change: initial;
}
ssi-composer .tags-campaigns-container .accordion-content-body {
  padding-bottom: 10px;
}
ssi-composer .tags-campaigns-container:last-child {
  margin-top: 30px;
}
ssi-composer .selector-draft-container {
  display: flex;
  margin-bottom: 10px;
}
ssi-composer .selector-draft-container > * {
  flex: 1;
  width: 50%;
}
ssi-composer .selector-draft-container .draft-title-input {
  padding-left: 10px;
}
ssi-composer .selector-draft-container .draft-title-input label {
  display: block;
  font-size: 10px;
  font-weight: 900;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-align: left;
  color: #43537f;
  text-transform: uppercase;
  padding: 0 0 10px 20px;
}
ssi-composer .selector-draft-container .draft-title-input input {
  appearance: none;
  border: 1px solid #c3c9d8;
  border-radius: 10px;
  padding: 12px;
  width: 100%;
  font-weight: 900;
  font-family: Lato, sans-serif;
}
ssi-composer .selector-draft-container .draft-title-input input::placeholder {
  color: #6a759a;
  font-weight: 700;
}
ssi-composer .selector-draft-container .draft-title-input input:focus {
  outline: none;
}
ssi-composer .selector-draft-container .draft-title-input input:focus-within {
  border: 1px solid #14bae3;
}
ssi-composer .toggle-label {
  display: flex;
  height: 100%;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #c3c9d8;
  padding: 0 8px 0 25px;
}
ssi-composer .toggle-label p {
  color: #43537f;
  margin: 0;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-composer .toggle-label.toggled {
  border: 1px solid #14bae3;
  background-color: #e4faff;
}
ssi-composer .toggle-label.toggled p {
  padding-right: 7px;
}
ssi-composer .post-errors .cl-accordion-item {
  border-color: #e34916;
}
ssi-composer .post-errors .accordion-button {
  border-color: #e34916;
  background-color: #fff1ed;
}
ssi-composer .post-errors .accordion-button .accordion-button-icon {
  color: #cc3300;
}

.composer-footer-left {
  display: flex;
  flex-wrap: wrap;
}
.composer-footer-left > * {
  margin: 0 8px 4px 0;
}
.composer-footer-left ssi-checkbox .checkbox-container {
  background-color: #fff;
  border-color: #c3c9d8;
}
.composer-footer-left ssi-checkbox .checkbox-container input {
  border-color: #838eab;
}
.composer-footer-left ssi-checkbox .checkbox-container input:checked {
  border-color: #14bae3;
}

.composer-footer-right {
  margin: 0 0 4px;
}

.composer-footer-label {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  background-color: #f0f3f9;
  border-width: 0;
  border-radius: 10px;
  height: 46px;
  color: #43537f;
}
.composer-footer-label p {
  margin: 0;
  font-weight: 900;
  text-rendering: geometricPrecision;
  white-space: nowrap;
}
.composer-footer-label i {
  font-size: 16px;
}
.composer-footer-label i:first-child {
  margin: 0 6px 0 0;
}
.composer-footer-label i:last-child {
  margin: 0 0 0 6px;
}

.tooltip-link-highlight-content {
  display: flex;
  align-items: center;
}
.tooltip-link-highlight-content p {
  padding-right: 15px;
  font-weight: 900;
  text-rendering: geometricPrecision;
  line-height: 20px;
  margin: 0 15px 0 0;
  border-right: 2px solid #d9dde6;
  white-space: nowrap;
  cursor: pointer;
}
.tooltip-link-highlight-content i {
  display: inline-block;
  color: #43537f;
  font-weight: 900;
  cursor: pointer;
}

.ap-menu-dropdown {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 5px 10px rgba(131, 142, 171, 0.35);
  border-width: 0;
  padding: 18px 10px;
}
.ap-menu-dropdown > li {
  display: block;
  padding: 10px 10px 10px 20px;
  line-height: 15px;
  margin: 0 0 4px 0;
  cursor: pointer;
}
.ap-menu-dropdown > li:hover {
  background-color: #f0f3f9;
  border-radius: 15px;
}
.ap-menu-dropdown > li .ap-menu-label {
  margin-left: 10px;
  color: #43537f;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
.ap-menu-dropdown > li > i {
  color: #838eab;
}
.ap-menu-dropdown > li .ssi-small-delete {
  color: #ff4d10;
}