@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-manage-webhooks {
  .manage-webhooks {
    background-color: $lmgrey-background;
    padding: 2rem 0;
  }
  .container {
    position: relative;
    background-color: #fff;
    border-radius: 25px;
    padding: 1rem 3rem;
  }

  .actions-menu {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 0.5rem 1rem 2rem 0;
    span {
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 1px;
      font-size: 1.1rem;
    }
  }

  .destination {
    max-width: 300px;
    word-break: break-word;
  }

  .orlo-table {
    width: 100%;
    tbody {
      td {
        &:first-child {
          white-space: pre-wrap;
        }

        &.email {
          word-break: break-word;
        }
      }
    }
    tr {
      &:first-child {
        border-top: 0;
      }

      > td {
        padding: 1.5rem 1rem !important;
        > a {
          color: $lmode-helper-text;
        }
      }
    }
  }

  .action-button {
    display: flex;
    align-items: center;
    padding: 1.3rem 2rem;
    font-weight: bold;
    color: #00b9e5;
    border-radius: 50px;
    border: none;
    &.enabled {
      background-color: rgba(143, 202, 199, 0.25);
      color: #8fcac7;
    }
    &:disabled,
    &.disabled {
      background-color: #faeeeb;
      color: #e39e8a;
    }

    &.edit,
    &.delete {
      padding: 1.2rem;
      font-size: 2rem;
    }

    &.edit {
      background-color: $lmgrey-background;
    }

    span {
      margin-left: 0.7rem;
    }

    &.delete {
      background-color: #faeeeb;
      i {
        color: #e29e8c;
      }
    }
  }

  orlo-button {
    button {
      display: flex;
      align-items: center;
      padding: 1rem 2rem !important;
    }
    span {
      font-weight: bold;
    }
    i {
      font-size: 2.5rem !important;
    }
  }
}
