.manage-ifttt-applet-name .accounts-label {
  background-color: #e7eff3;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: normal;
}
.manage-ifttt-applet-name .accounts-label .accounts-amount {
  margin-right: 30px;
}
.manage-ifttt-applet-name .accounts-label:hover {
  text-decoration: none;
}