ssi-activity-snippets-dropdown {
  font-size: 1rem;
}
ssi-activity-snippets-dropdown .as-dropdown-container {
  position: absolute;
  z-index: 7;
  padding: 1.5em 2em 1.5em 1em;
  background: #fff;
  border-radius: 1.2em;
  box-shadow: 0 0.4em 3.2em -0.5em rgba(119, 124, 128, 0.56);
}
ssi-activity-snippets-dropdown .as-label {
  font-size: 1.4em;
  padding: 0 1.5rem;
  font-style: italic;
  color: #838eab;
  font-weight: normal;
}
ssi-activity-snippets-dropdown .as-label::after {
  content: "/";
  display: block;
  position: absolute;
  top: 4.5rem;
}
ssi-activity-snippets-dropdown .as-filter-input {
  font-size: 1.4em;
  display: flex;
  margin: 0 2.5rem 1rem 1.5rem;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  border: none;
  border-bottom: 0.1rem solid #ecf0fb;
  width: 90%;
}
ssi-activity-snippets-dropdown .as-filter-input:focus {
  outline: none;
}
ssi-activity-snippets-dropdown .as-list {
  align-items: flex-start;
  background-color: #fff;
  border-radius: 1em;
  display: block;
  flex-flow: column nowrap;
  flex: 1 0 auto;
  height: 9em;
  width: 35em;
  justify-content: flex-start;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0 0.5em 0 0;
}
ssi-activity-snippets-dropdown .as-list-item {
  display: flex;
  align-items: center;
  border-radius: 10em;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: flex-start;
  margin: 0.5em 0.5em;
  padding: 0.5em 0.5em;
}
ssi-activity-snippets-dropdown .as-list-item .tick {
  display: none;
}
ssi-activity-snippets-dropdown .as-list-item button {
  font-size: 1.2em;
  color: #43537f;
  font-weight: bold;
}
ssi-activity-snippets-dropdown .as-list-item:hover, ssi-activity-snippets-dropdown .as-list-item.selected {
  background-color: #f8f9fd;
}
ssi-activity-snippets-dropdown .as-list-item:hover button, ssi-activity-snippets-dropdown .as-list-item.selected button {
  color: #14bae3;
}
ssi-activity-snippets-dropdown .as-list-item a,
ssi-activity-snippets-dropdown .as-list-item button {
  align-items: center;
  background: transparent;
  border: none;
  flex: 1 1 auto;
  justify-content: space-between;
  padding: 0;
  text-decoration: none;
  display: inline-block;
  width: 100%;
  max-width: 14rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}
ssi-activity-snippets-dropdown .administer {
  margin-top: 1.5em;
}
ssi-activity-snippets-dropdown .administer a {
  font-size: 1em;
  color: #14bae3;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.1em;
}