@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../../../angularjs/common/sass/variables';

ssi-triggers-sentiment-selector {

  $sentiment-option-default-bg: #dae0e4;

  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px;

  .sentiment-option {
    background-color: $sentiment-option-default-bg;
    padding: 10px;
    border-radius: 30px;
    color: white;
    margin: 5px;
  }

  .sentiment-option.active {
    box-shadow: $box-shadow-1;
  }

  .sentiment-option:hover {
    background-color: darken($sentiment-option-default-bg, 5%);
  }

}
