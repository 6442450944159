@import "./src/apps/angularjs/common/sass/global-variables.scss";
// https://github.com/MatejKustec/SpinThatShit/blob/master/examples/style.css

@import '~/styles/colors';

.loader {
  width: 30px;
  height: 30px;
  border: 5px solid #adefff;
  border-top-color: $primary-blue;
  border-radius: 50%;
  position: relative;
  animation: loader-rotate 1s linear infinite;
  top: 50%;
  margin: -28px auto 0;
}

@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
