@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

$input-filter-gutter: 0.8rem;

ssi-account-selector {
  // font-size: 1rem;

  .ac-select-trigger {
    position: relative;
    width: 100%;
    padding-right: 2.5em;
    text-align: left;

    .caret {
      position: absolute;
      top: 50%;
      right: 1.2rem;
      margin-top: -0.2rem;
      vertical-align: middle;
    }
  }

  .ac-select-trigger-title {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  .ac-dropdown {
    min-width: 100%;
  }

  .ac-filter-input {
    display: block;
    width: calc(100% - #{$input-filter-gutter} * 2);
    border-radius: 4px;
    margin: 0.4rem $input-filter-gutter;
  }

  .ac-account-list {
    display: block;
    margin: 0;
    padding: 0;
    min-width: 100%;
  }

  .ac-item {
    display: block;
  }

  .ac-item-trigger {
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #262626;
    padding: 0.3rem 2rem;
    text-align: left;
    border-width: 0;
    background-color: transparent;
    white-space: nowrap;
    font-weight: 400;
    cursor: pointer;

    &:not(&-selected) {
      &:hover,
      &:focus {
        background-color: #f5f5f5;
      }
    }

    &-selected {
      background-color: $primary-active;
      color: #fff;

      .ac-item-check-mark {
        display: block;
      }
    }
  }

  .ac-item-check-mark {
    display: none;
    margin: 0 0 0 0.8rem;
  }
}
