@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
ssi-widget-trustometer-gauge .sentiment-chart-container {
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
}
ssi-widget-trustometer-gauge .sentiment-chart-container .gauge-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
}
ssi-widget-trustometer-gauge .sentiment-chart-container .gauge-container .gauge-background {
  width: 100%;
  height: auto;
}
ssi-widget-trustometer-gauge .sentiment-chart-container .gauge-container svg,
ssi-widget-trustometer-gauge .sentiment-chart-container .gauge-container .score-container {
  max-width: 300px;
  width: 100%;
}
ssi-widget-trustometer-gauge .sentiment-chart-container .score-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  padding: 3px 7px;
  margin-top: 10px;
  height: 48px;
}
ssi-widget-trustometer-gauge .sentiment-chart-container .score-container .score {
  color: #101525;
  font-size: 30px;
  font-weight: 900;
  margin-right: auto;
}
ssi-widget-trustometer-gauge .sentiment-chart-container .score-container .trustometer-label {
  text-transform: uppercase;
  text-align: right;
  font-size: 10px;
  line-height: 10px;
  font-weight: 900;
  margin-right: 5px;
}
ssi-widget-trustometer-gauge .sentiment-chart-container .score-container i {
  font-size: 30px;
  color: #f05230;
}
ssi-widget-trustometer-gauge .sentiment-chart-container .score-container.no-data {
  padding: 3px 45px;
}
ssi-widget-trustometer-gauge .sentiment-chart-container .score-container.no-data .trustometer-label {
  text-align: center;
  line-height: 12px;
}
ssi-widget-trustometer-gauge .themes-container {
  padding: 0 15px 15px 15px;
}
ssi-widget-trustometer-gauge .themes-container .themes-group h5 {
  color: #838eab;
  font-size: 10px;
  font-weight: 900;
  text-transform: uppercase;
}
ssi-widget-trustometer-gauge .themes-container .themes-group:last-child {
  border-top: 1px solid #838eab;
  margin-top: 10px;
}
ssi-widget-trustometer-gauge .themes-container .themes-list {
  display: flex;
  flex-wrap: wrap;
}
ssi-widget-trustometer-gauge .themes-container .themes-list span {
  color: #101525;
  font-size: 12px;
  font-weight: 900;
  padding: 5px;
  border-radius: 5px;
  margin-right: 2px;
  margin-bottom: 2px;
  background: #0dcdc2;
  white-space: nowrap;
  cursor: pointer;
}
ssi-widget-trustometer-gauge .themes-container .themes-list.distrusting .theme {
  color: #fff;
  background: #f05230;
}
ssi-widget-trustometer-gauge .themes-container .themes-list.distrusting .loading-themes-loader span {
  background-image: linear-gradient(to right, #f05230 8%, #dddddd 18%, #f05230 33%);
}
ssi-widget-trustometer-gauge .themes-container .themes-list p {
  color: #43537f;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
}
ssi-widget-trustometer-gauge .themes-container .loading-themes-loader {
  height: 70px;
  width: 100%;
}
ssi-widget-trustometer-gauge .themes-container .loading-themes-loader span {
  opacity: 0.1;
  width: 130px;
  height: 26px;
  display: inline-flex;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #0dcdc2 8%, #dddddd 18%, #0dcdc2 33%);
  background-size: 800px 104px;
  position: relative;
}
ssi-widget-trustometer-gauge .themes-container .loading-themes-loader span:nth-child(1) {
  width: 50px;
}
ssi-widget-trustometer-gauge .themes-container .loading-themes-loader span:nth-child(2) {
  width: 120px;
}
ssi-widget-trustometer-gauge .themes-container .loading-themes-loader span:nth-child(3) {
  width: 60px;
}
ssi-widget-trustometer-gauge .themes-container .loading-themes-loader span:nth-child(4) {
  width: 100px;
}
ssi-widget-trustometer-gauge .themes-container .loading-themes-loader span:nth-child(5) {
  width: 90px;
}
ssi-widget-trustometer-gauge .themes-container .no-themes-results {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
}
ssi-widget-trustometer-gauge .themes-container .no-themes-results i {
  font-size: 40px;
  color: #b4bbce;
  margin-bottom: 20px;
}
ssi-widget-trustometer-gauge .themes-container .no-themes-results h4 {
  color: #43537f;
  font-weight: 900;
  font-size: 20px;
}
ssi-widget-trustometer-gauge .themes-container .no-themes-results p {
  color: #43537f;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}