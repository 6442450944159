@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-manage-accounts {
  .add-accounts-panels {
    $panel-margin: 10px;

    flex-wrap: wrap;
    justify-content: center;
    margin-left: -$panel-margin;
    margin-right: -$panel-margin;

    .panel {
      flex-basis: calc(25% - (#{$panel-margin} * 2));
      margin: 0 $panel-margin;
      margin-bottom: $panel-margin * 2;
    }
    h1 {
      color: #555;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 1em;
      margin: 2em 0;
      letter-spacing: 1px;
    }
    img {
      margin: 4em 0;
      min-height: 58px;
    }
  }
}
