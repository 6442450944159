.report-sidebar {
  position: fixed;
  left: 0;
  height: calc(100% - 68px);
  overflow: auto;
  width: 228px;
  background-color: #292935;
  padding: 0 0 0 2em;
  z-index: 99;
}
.report-sidebar ul {
  margin: 0;
  padding: 0;
}
.report-sidebar ul li {
  list-style: none;
  padding: 0.6em 1em;
  margin: 0 0 0.6em 0;
  color: white;
  font-weight: 700;
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
  cursor: pointer;
}
.report-sidebar ul li i {
  margin-right: 0.4em;
}
.report-sidebar hr {
  margin-right: 2em;
  border-top: 2px solid white;
}
.report-sidebar .nav-list {
  margin-bottom: 2em;
}
.report-sidebar .report-icon {
  display: flex;
  align-items: center;
  justify-content: left;
  height: 94px;
}
.report-sidebar .report-icon i {
  font-size: 1.6em;
  color: white;
  margin-left: 0.6em;
}