ssi-top-post-stats .top-post-stats-container {
  display: flex;
}
ssi-top-post-stats .top-post-stats-container .top-post-interaction {
  flex: 1;
  text-align: center;
  font-weight: 900;
  color: #101525;
  position: relative;
}
ssi-top-post-stats .top-post-stats-container .top-post-interaction #rank-0 div {
  background-color: #fbf3e2;
}
ssi-top-post-stats .top-post-stats-container .top-post-interaction #rank-0 i {
  color: #f0b427;
}
ssi-top-post-stats .top-post-stats-container .top-post-interaction #rank-1 div {
  background-color: #eff2fd;
}
ssi-top-post-stats .top-post-stats-container .top-post-interaction #rank-1 i {
  color: #b4bbce;
}
ssi-top-post-stats .top-post-stats-container .top-post-interaction #rank-2 div {
  background-color: #fff1ed;
}
ssi-top-post-stats .top-post-stats-container .top-post-interaction #rank-2 i {
  color: #f88c68;
}
ssi-top-post-stats .top-post-stats-container .top-post-interaction .rank {
  position: relative;
  margin: 0 0 2em 0;
}
ssi-top-post-stats .top-post-stats-container .top-post-interaction .rank div {
  margin: 0 auto;
  width: 46px;
  height: 46px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}
ssi-top-post-stats .top-post-stats-container .top-post-interaction .rank i {
  position: absolute;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 4em;
  color: #eaedf4;
}
ssi-top-post-stats .top-post-stats-container .top-post-interaction .number {
  display: block;
  font-size: 2em;
  position: relative;
  z-index: 2;
  line-height: 1em;
}
ssi-top-post-stats .top-post-stats-container .top-post-interaction .subtitle {
  text-transform: uppercase;
  font-size: 0.7em;
  position: relative;
  z-index: 2;
  color: #838eab;
  letter-spacing: 1px;
  font-weight: 700;
}
ssi-top-post-stats .top-post-stats-container .top-post-score {
  flex: 2;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.8em;
  padding: 0 0 0 2em;
}
ssi-top-post-stats .top-post-stats-container .top-post-score ul {
  list-style: none;
  padding: 0;
}
ssi-top-post-stats .top-post-stats-container .top-post-score ul li {
  margin: 0 0 1em 0;
  color: #838eab;
}
ssi-top-post-stats .top-post-stats-container .top-post-score i {
  margin-right: 0.6em;
  font-size: 1.4em;
}
ssi-top-post-stats .top-post-stats-container .top-post-score span {
  letter-spacing: 1px;
  font-size: 0.9em;
  font-weight: 700;
}
ssi-top-post-stats .top-post-stats-container .top-post-score ssi-big-number {
  width: 55px;
  display: inline-block;
  margin-right: 0.3em;
  font-size: 1.4em;
  color: #101525;
  font-weight: 900;
}