@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';
@import '../../../../angularjs/common/sass/include-media';

ssi-outbox-message {
  font-size: 1rem;

  .outbox-message {
    position: relative;
    border-radius: 1em;
    background-color: #fff;
    overflow: hidden;
    padding: 4.2em 3em 3em;
  }

  .outbox-message-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3em;
  }

  .outbox-message-tab {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #eff2fd;
    border-top-left-radius: 1em;
    border-bottom-right-radius: 1em;
    height: inherit;
    min-width: 11.3em;

    &:hover {
      text-decoration: none;
    }

    p {
      color: $lmode-body-text;
      font-weight: 900;
      text-rendering: geometricPrecision;
      padding: 0 1em;
      margin: 0;
      font-size: 1.2em;
      text-transform: lowercase;

      &:first-letter {
        text-transform: uppercase;
      }
    }
  }

  .outbox-message-body {
    display: flex;
  }

  .outbox-message-thumb {
    flex: 0 0 6.9em;
    padding: 1.5em 0 1em;

    img {
      display: inline-block;
      background-color: #fff;
      border-radius: 50%;
      height: 4.6em;
      width: 4.6em;
      box-shadow: 0 0.5em 1em rgba(131, 142, 171, 0.25);
    }

    @include media('<tablet') {
      display: none;
    }
  }

  .outbox-message-thumb-mobile {
    display: none;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0.5em 1em rgba(131, 142, 171, 0.25);
    margin-right: 0.5em;

    @include media('<tablet') {
      display: inline-block;
      height: 2em;
      width: 2em;
    }
  }

  .outbox-message-content {
    flex: 1 1 auto;
  }

  .outbox-message-content-meta {
    ul {
      margin: 0 -0.5em;
      padding: 0;
    }

    li {
      display: inline-block;
      margin: 0 0.5em 0.5em;

      @include media('<tablet') {
        &:first-child {
          display: block;
        }
      }
    }

    p {
      display: inline-block;
      margin-bottom: 0;
      color: $lmode-body-text;
      font-size: 1.2em;
      line-height: 1;
      font-weight: 900;
      text-rendering: geometricPrecision;
    }

    i {
      display: inline-block;
      color: $lmode-body-text;
      margin: 0 0.2em 0 0;
      line-height: 1;
      vertical-align: text-top;
    }
  }

  .outbox-message-content-meta-primary {
    p {
      color: $primary-active;
      font-size: 1.6em;
      font-weight: 700;
      vertical-align: bottom;
      margin-bottom: 0.1em;
    }

    .outbox-message-content-meta-primary-icon {
      display: inline-block;
      height: 2em;
      width: 2em;
      line-height: 2em;
      text-align: center;
      background-color: $grey-background;
      border-radius: 50%;
      margin: 0 0 0.1em 0.2em;

      i {
        color: $primary-active;
        font-size: 1.4em;
        width: inherit;
      }
    }
  }

  .outbox-message-content-meta-pill {
    background-color: $grey-background;
    border-radius: 10em;
    padding: 0.4em 0.6em;
  }

  .outbox-message-content-meta-split {
    p:last-child::before {
      content: '|';
      margin: 0 0.5em;
    }
  }

  .outbox-message-content-body {
    margin: 0.5em 0 2em;
    line-height: 2.4em;

    p {
      color: $lmode-title-text;
      font-weight: 700;
      text-rendering: geometricPrecision;
      line-height: inherit;
      margin: 0;
    }
  }

  .outbox-message-content-stats {
    display: flex;
    align-items: center;

    ul {
      padding: 0;
      margin: 0 0.5em;

      @include media('<tablet') {
        width: 100%;
        margin-top: 1em;
      }
    }

    li {
      display: inline-block;
      padding: 0 1.5em;
      margin: 0.5em 0;

      @include media('<tablet') {
        &:first-child {
          padding-left: 0;
        }
      }
    }

    i {
      display: inline-block;
      color: $lmode-helper-text;
      margin: 0 0.3em 0 0;
      font-size: 1.2em;
      line-height: 1.2rem;
      vertical-align: text-bottom;

      &.ssi-total-engagement,
      &.ssi-eye {
        font-size: 1.4em;
      }

      &.ssi-reach,
      &.ssi-correct-retweet {
        font-size: 1.6em;
      }
    }

    p {
      display: inline-block;
      color: $lmode-title-text;
      font-size: 1.2em;
      line-height: 1;
      margin: 0;
      font-weight: 900;
      text-rendering: geometricPrecision;
    }

    @include media('<tablet') {
      flex-wrap: wrap;
    }
  }

  .outbox-message-content-label {
    display: inline-block;
    background-color: #ccc;
    border-radius: 0.4em;
    padding: 0.3em 1em;

    &-live {
      background-color: #b2c614;
    }

    &-scheduled {
      background-color: $secondary-blue;
    }

    &-approval {
      background-color: #91d4e1;
    }

    p {
      color: #fff;
      font-weight: 900;
      font-size: 1em;
      letter-spacing: 0.1em;
      margin: 0;
      text-rendering: geometricPrecision;
      text-transform: uppercase;
    }
  }

  .outbox-message-gallery {
    text-align: right;
    flex: 0 0 15.6em;
    padding-right: 1.4em;

    @include media('<tablet') {
      display: none;
    }
  }

  .outbox-message-gallery-mobile {
    display: none;
    @include media('<tablet') {
      display: block;
      padding-bottom: 2em;
    }
  }

  .outbox-message-gallery-wrap {
    display: inline-block;
    height: 7.2em;
    width: 10.4em;

    img {
      border-radius: 1em;
      box-shadow: 0 0.5em 0.5em rgba(131, 142, 171, 0.35);
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .outbox-message-gallery-wrap-multiple {
    position: relative;

    img:first-child {
      position: absolute;
      left: -0.7em;
    }

    img:nth-child(2) {
      position: absolute;
      top: 0.8em;
      left: 0;
    }
  }

  .outbox-message-gallery-wrap-multiple-badge {
    position: absolute;
    top: 0.7em;
    bottom: 0;
    right: -1.3em;
    margin: auto;
    display: inline-block;
    background-color: #fff;
    color: $primary-active;
    font-size: 1em;
    font-weight: 900;
    text-rendering: geometricPrecision;
    height: 2.6em;
    width: 2.6em;
    border-radius: 50%;
    box-shadow: 0 0.5em 1.5em rgba(116, 118, 132, 0.25);
    text-align: center;
    line-height: 2.6em;
    z-index: 1;
  }
}
