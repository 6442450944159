@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-toggle-switch-2 {
  font-size: 1rem;

  .toggle-switch-2-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .toggle-switch-2-label {
    color: $lmode-body-text;
    font-size: 1em;
    font-weight: 900;
    letter-spacing: 0.1rem;
    padding-left: 1.4rem;
    padding-right: 2rem;

    &.active {
      color: $primary-active;
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 3.4em;
    height: 2em;
    flex: 0 0 auto;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:focus + span {
        outline: auto -webkit-focus-ring-color;
        transition: outline 0s;
      }

      &:checked + .slider {
        background-color: $primary-active;

        &:before {
          background-color: #fff;
          transform: translateX(1.6em);
        }
      }

      &:checked:focus + .slider {
        box-shadow: 0 0 0.1em $primary-active;
      }

      &:focus {
        outline: none;

        + .slider {
          outline: none;
        }
      }
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $dmode-body-text;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: '';
      height: 1.2em;
      width: 1.2em;
      left: 0.3em;
      bottom: 0.4em;
      background-color: #fff;
      transition: 0.4s;
    }

    &:focus-visible {
      outline: none;
    }

    &.round {
      border-radius: 10em;
    }

    &:before {
      border-radius: 50%;
    }

    &.disabled {
      cursor: not-allowed;
    }
  }

  label {
    margin: 0;
  }

  .ts2-a11y-label {
    position: absolute;
    text-indent: -9999px;
  }

  &.wrapped {
    .toggle-switch-2-container {
      display: flex;
      align-items: center;
      font-size: 14px;
      width: 100%;
      border-width: 0;
      height: 46px;
      color: #101525;
      font-weight: 700;
      text-rendering: geometricPrecision;
      padding: 0 12px 0 0;
      background-color: $grey-background;
      border: 1px solid $lmode-helper-text;
      border-radius: 10px;
    }

    &.toggled {
      .toggle-switch-2-container {
        background-color: #e4faff;
        border: 1px solid $lmode-helper-text;
      }
    }

    .toggle-switch-2-container {
      width: 100%;
    }

    .toggle-switch-2-label {
      letter-spacing: 0;
      color: $lmode-body-text;
      &.active {
        color: $lmode-body-text;
      }
    }

    input {
      &:checked + .slider {
        &:before {
          background-color: #fff;
          transform: translateX(1.6em);
        }
      }
    }
  }
}
