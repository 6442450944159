.marketing-add-campaign {
  position: absolute;
  width: 345px;
  margin: auto;
  left: -50%;
  right: -50%;
  display: flex;
  flex-direction: column;
  background-color: white;
  top: 45px;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
  z-index: 99;
}
.marketing-add-campaign select, .marketing-add-campaign input {
  width: 100%;
  background-color: #f6f8f9;
  border: none;
  border-radius: 20px;
  padding: 6px 14px;
}

.select-wrapper {
  width: 100%;
  display: inline-block;
  padding: 6px 16px 6px 6px;
  background-color: #f6f8f9;
  border-radius: 20px;
}

.campaign-calendar-control {
  position: relative;
}
.campaign-calendar-control .flatpickr-calendar {
  top: auto !important;
  left: 0 !important;
  right: 0 !important;
  margin: auto;
}