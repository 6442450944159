ssi-treetable {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: visible;
  width: 100%;
  height: 100%;
}
ssi-treetable * {
  box-sizing: border-box;
}
ssi-treetable .options {
  flex: 0 0 auto;
  position: relative;
}
ssi-treetable .content {
  flex: 1 0 auto;
  overflow: visible;
  display: flex;
  flex-direction: column;
}
ssi-treetable .content.scrollable-horizontally {
  overflow-x: auto;
}
ssi-treetable .content .content-inner {
  flex: 1 0 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  overflow: visible;
}
ssi-treetable .content .content-inner .head {
  flex: 0 0 40px;
  display: flex;
  flex-direction: column;
}
ssi-treetable .content .content-inner .head .head-inner .row {
  display: flex;
  flex-direction: row;
  margin: 0;
}
ssi-treetable .content .content-inner .head .head-inner .row .cell {
  flex: 0 0 auto;
  height: 40px;
  position: relative;
  overflow: visible;
  background-color: #f0f3f9;
  padding: 0 5px 0 10px;
}
ssi-treetable .content .content-inner .head .head-inner .row .cell:hover {
  box-shadow: inset 1px 0px 0px 0px #dcdcdc, inset -1px 0px 0px 0px #dcdcdc;
}
ssi-treetable .content .content-inner .head .head-inner .row .cell.dragged {
  cursor: move;
  cursor: grab;
}
ssi-treetable .content .content-inner .head .head-inner .row .cell.cloned {
  position: fixed;
  opacity: 0.8;
  z-index: 12;
  cursor: move;
  cursor: grab;
}
ssi-treetable .content .content-inner .head .head-inner .row .cell .cell-inner-cover {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #e9ecf5;
  box-shadow: inset 1px 1px 0px 0px #adadad;
  z-index: 11;
}
ssi-treetable .content .content-inner .head .head-inner .row .cell .cell-inner-cover.active {
  display: block;
}
ssi-treetable .content .content-inner .head .head-inner .row .cell .cell-inner {
  display: flex;
  align-items: center;
  font-size: 13px;
  height: inherit;
}
ssi-treetable .content .content-inner .head .head-inner .row .cell .cell-inner .col-resizer {
  position: absolute;
  width: 0.3rem;
  height: 100%;
  cursor: col-resize;
  top: 0;
}
ssi-treetable .content .content-inner .head .head-inner .row .cell .cell-inner .col-resizer.left {
  left: 0;
}
ssi-treetable .content .content-inner .head .head-inner .row .cell .cell-inner .col-resizer.right {
  right: 0;
}
ssi-treetable .content .content-inner .head .head-inner .row .cell .cell-inner .col-header {
  height: inherit;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  max-width: calc(100% - 20px);
  cursor: default;
}
ssi-treetable .content .content-inner .head .head-inner .row .cell .cell-inner .col-header:hover {
  cursor: move;
  cursor: grab;
}
ssi-treetable .content .content-inner .head .head-inner .row .cell .cell-inner .col-header > span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-transform: uppercase;
  color: #43537f;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
}
ssi-treetable .content .content-inner .head .head-inner .row .cell .cell-inner .col-sorter {
  position: relative;
  display: inline-flex;
  cursor: pointer;
  width: 20px;
  height: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
ssi-treetable .content .content-inner .head .head-inner .row .cell .cell-inner .col-sorter .triangle {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}
ssi-treetable .content .content-inner .head .head-inner .row .cell .cell-inner .col-sorter .triangle.up {
  border-bottom: 8px solid #43537f;
}
ssi-treetable .content .content-inner .head .head-inner .row .cell .cell-inner .col-sorter .triangle.up.active {
  border-bottom: 8px solid #14bae3;
  opacity: 1;
}
ssi-treetable .content .content-inner .head .head-inner .row .cell .cell-inner .col-sorter .triangle.down {
  border-top: 8px solid #43537f;
}
ssi-treetable .content .content-inner .head .head-inner .row .cell .cell-inner .col-sorter .triangle.down.active {
  border-top: 8px solid #14bae3;
  opacity: 1;
}
ssi-treetable .content .content-inner .body {
  flex: 1 1 auto;
}
@media print {
  ssi-treetable .content .content-inner .body {
    max-height: none !important;
  }
}
ssi-treetable .content .content-inner .body.in-focus {
  overflow-y: auto;
}
@media print {
  ssi-treetable .content .content-inner .body .body-inner {
    min-width: 100%;
    max-width: 100%;
  }
}
ssi-treetable .content .content-inner .body .body-inner .row {
  display: flex;
  flex-direction: row;
  margin: 0;
}
ssi-treetable .content .content-inner .body .body-inner .row.expanded .cell {
  background-color: #f5f7fe;
}
ssi-treetable .content .content-inner .body .body-inner .row:hover .cell {
  background-color: #f6f8fb;
}
ssi-treetable .content .content-inner .body .body-inner .row .cell {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  height: inherit;
  position: relative;
  overflow: visible;
}
ssi-treetable .content .content-inner .body .body-inner .row .cell .cell-inner {
  height: inherit;
  display: flex;
  align-items: center;
  font-size: 12px;
}
ssi-treetable .content .content-inner .body .body-inner .row .cell .cell-inner .flx-css-icon-container {
  margin-right: 1rem;
}
ssi-treetable .content .content-inner .body .body-inner .row .cell .cell-inner > span.field-value {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #43537f;
  font-size: 10px;
  font-weight: 900;
}
ssi-treetable .content .content-inner .body .body-no-results-template-container {
  position: relative;
}
ssi-treetable .paginator-container {
  flex: 0 0 auto;
  position: relative;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
ssi-treetable .foot {
  flex: 0 0 auto;
  position: relative;
}

.flx-no-select {
  user-select: none;
}

.flx-clickable {
  cursor: pointer;
}

.flx-css-icon-container {
  position: relative;
  height: 1.3rem;
  min-height: 1.3rem;
  width: 1.3rem;
  min-width: 1.3rem;
  background-color: transparent;
}

.flx-chevron {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.6;
}
.flx-chevron.flx-chevron-circle {
  border-radius: 50%;
  border: 1px solid #000000;
}
.flx-chevron:before {
  height: 0.5em;
  width: 0.5em;
  border-width: 0.25em 0.25em 0 0;
  border-style: solid;
  content: "";
  display: inline-block;
  position: relative;
  top: 0.4em;
  left: 0.4em;
  vertical-align: top;
  transform: rotate(45deg);
  transition-property: transform;
  transition-duration: 0.2s;
}
.flx-chevron.flx-chevron-down:before {
  transform: rotate(135deg);
}