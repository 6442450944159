@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-facebook-share-modal {
  $form-control-border-radius: 5px;
  $form-control-icon-color: #d2d8df;

  display: block;
  background-color: #373c3f;
  padding: 40px;
  border-radius: 4px;
  width: 550px;

  // shitty hack, todo - replace with flexbox
  .btn-inverse {
    margin-top: 35px;
  }

  ssi-publisher-landing {
    flex-direction: column;

    .publisher-textarea-container {
      border-radius: 5px;
      z-index: auto;

      textarea {
        min-height: 160px;
        padding: 20px;
      }

      .publisher-textarea-footer {
        padding: 10px 20px;
      }

      .no-accounts-icon {
        display: none;
      }
    }
  }

  textarea.form-control.form-control-minimal {
    resize: none;
    border-top-left-radius: $form-control-border-radius;
    border-top-right-radius: $form-control-border-radius;
    border-bottom: 0;
  }

  input.form-control.form-control-minimal {
    border-radius: $form-control-border-radius;
    background-color: white;
  }

  .form-control.form-control-minimal {
    font-size: 14px;
    padding: 15px;
  }

  .quote-retweet-modal-form-control-footer {
    background-color: #fff;
    border-bottom-left-radius: $form-control-border-radius;
    border-bottom-right-radius: $form-control-border-radius;
    padding: 10px 15px;
    text-align: right;
    font-size: 14px;

    i {
      font-size: 20px;
      color: $form-control-icon-color;
      position: relative;
      top: 2px;
      left: -3px;
    }

    i:hover {
      color: darken($form-control-icon-color, 10%);
    }
  }

  .quote-retweet-footer-btns {
    .btn {
      margin-left: 3px;
      margin-top: 3px;
    }
  }

  .quote-retweet-footer-date-picker {
    .btn {
      margin-left: 3px;
    }
  }
}
