@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

.post-box-content-body {
  display: flex;
  padding: 0;
}

.post-box-content-status {
  flex: 0 0 auto;
  width: 60px;

  .img-circle {
    max-width: 42px;
    cursor: pointer;

    &:nth-child(n + 2) {
      margin-top: -10px;
    }
  }
}

.post-box-selected {
  background-color: #494f5b;
  color: $primary-active;
  border-radius: 50%;
  height: 42px;
  width: 42px;
  text-align: center;
  line-height: 46px;
  vertical-align: middle;
  cursor: pointer;

  .post-box-selected-icon {
    font-size: 22px;
  }

  .img-circle {
    display: none;
  }
}

.post-box-content {
  flex: 1 1 auto;

  p {
    white-space: pre-wrap;
    font-weight: 500;
    line-height: 1.8;
    color: $lmode-title-text;
    font-weight: 700;
  }
}

.post-box-content-meta {
  font-size: 12px;
  margin-bottom: 5px;

  p {
    color: $lmode-title-text;
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 0;
    display: inline-block;
    white-space: pre-wrap;
  }

  span {
    color: $lmode-title-text;
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 0;
    display: inline-block;
    white-space: nowrap;
  }

  > a {
    color: $primary-active;
    font-weight: 900;
  }

  i {
    font-size: 10px;
    color: $primary-active;
  }
}

.post-box-content-divide {
  padding: 0 5px;
}
