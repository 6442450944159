ssi-live-chat-conversation-response {
  align-self: flex-end;
  display: flex;
  flex-flow: column nowrap;
  margin-right: 10%;
  min-height: 170px;
  width: 70%;
}
ssi-live-chat-conversation-response * {
  display: flex;
}
ssi-live-chat-conversation-response .hidden {
  display: none;
}
ssi-live-chat-conversation-response > form {
  flex-flow: column nowrap;
}
ssi-live-chat-conversation-response > form.hidden {
  display: none;
}
ssi-live-chat-conversation-response > form > div {
  background-color: #fff;
  border-radius: 20px;
  flex-flow: column nowrap;
  justify-content: space-between;
  margin: 10px 0 20px 0;
  min-height: 120px;
  padding: 10px 20px;
  position: relative;
}
ssi-live-chat-conversation-response > form > div input,
ssi-live-chat-conversation-response > form > div textarea {
  border: none;
  outline: none;
}
ssi-live-chat-conversation-response > form > div input[disabled], ssi-live-chat-conversation-response > form > div input.disabled,
ssi-live-chat-conversation-response > form > div textarea[disabled],
ssi-live-chat-conversation-response > form > div textarea.disabled {
  cursor: not-allowed;
  opacity: 0.65;
}
ssi-live-chat-conversation-response > form > div input::placeholder,
ssi-live-chat-conversation-response > form > div textarea::placeholder {
  color: #d8e1e5;
  font-style: italic;
  font-weight: 500;
}
ssi-live-chat-conversation-response > form > div > .additional {
  align-items: center;
  flex-flow: row nowrap;
  justify-content: flex-end;
  list-style: none;
  margin: 80px 0 0 0;
  padding: 0;
}
ssi-live-chat-conversation-response > form > div > .additional > li {
  margin: 0;
  padding: 0;
}
ssi-live-chat-conversation-response > form > div > .additional > li .options {
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}
ssi-live-chat-conversation-response > form > div > .additional > li .options li {
  color: #d8e1e5;
  font-size: 1.5em;
  margin: 0;
  padding: 0 3px;
}
ssi-live-chat-conversation-response > form > div > .additional > li .options li.characters-remaining {
  font-size: 0.8em;
  font-weight: 600;
}
ssi-live-chat-conversation-response > form > div > .additional > li .options li.upload-attachment input[type=file] {
  display: none;
}
ssi-live-chat-conversation-response > form > div > .additional > li .options li.upload-attachment label {
  cursor: pointer;
  margin: 0;
  padding: 0;
}
ssi-live-chat-conversation-response > form > div > .additional > li .options li.upload-attachment label.disabled {
  cursor: not-allowed;
  opacity: 0.65;
}
ssi-live-chat-conversation-response > form > div > .additional > li .options li.info, ssi-live-chat-conversation-response > form > div > .additional > li .options li:hover {
  color: #1dafec;
}
ssi-live-chat-conversation-response > form > div > .additional > li .options li.info.disabled, ssi-live-chat-conversation-response > form > div > .additional > li .options li:hover.disabled {
  color: #d8e1e5;
}
ssi-live-chat-conversation-response > form > div > .additional > li .options li button {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
}
ssi-live-chat-conversation-response > form > div > .additional > li .options li ssi-emoji-picker {
  display: block;
}
ssi-live-chat-conversation-response > form > div textarea {
  background-color: transparent;
  padding: 0;
  position: relative;
  resize: none;
  z-index: 1;
}
ssi-live-chat-conversation-response > form > div textarea,
ssi-live-chat-conversation-response > form > div .prediction-container {
  height: 68px;
  position: absolute;
  width: 90%;
}
ssi-live-chat-conversation-response > form > div .prediction-container {
  color: #9cf;
  white-space: pre-wrap;
  word-spacing: 0;
  word-wrap: break-word;
  z-index: 0;
}
ssi-live-chat-conversation-response > form > .buttons {
  align-items: center;
  list-style: none;
  margin: 0 30px 30px 30px;
  padding: 0;
}
ssi-live-chat-conversation-response > form > .buttons > li {
  margin: 0 5px;
  padding: 0;
}
ssi-live-chat-conversation-response > form > .buttons > li > button {
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 20px;
  font-size: 0.8em;
  font-weight: 400;
  padding: 3px 20px;
}
ssi-live-chat-conversation-response > form > .buttons > li > button i {
  margin-right: 5px;
}
ssi-live-chat-conversation-response > form > .buttons > li > button[disabled] {
  cursor: not-allowed;
  opacity: 0.65;
}
ssi-live-chat-conversation-response > form > .buttons > li > button.note {
  background-color: #fff;
  color: #1dafec;
}
ssi-live-chat-conversation-response > form > .buttons > li > button.note i {
  font-size: 1.5em;
  padding-left: 5px;
}
ssi-live-chat-conversation-response > form > .buttons > li > button.reply {
  background-color: #1dafec;
  color: #fff;
}
ssi-live-chat-conversation-response > form > .buttons > li > button.resolve {
  background-color: #7d90a0;
  color: #fff;
}