@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-tags-table {
  font-size: 1rem;

  .body-cell-stat {
    font-size: 14px !important;
    color: #000 !important;
  }

  // .body-cell-stat-wrap {
  //   justify-content: center !important;
  // }

  .body-cell-label {
    font-size: 12px !important;
    text-indent: 6px;
    word-break: break-all;
    max-height: 45px;
    overflow: hidden;
  }
}
