@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../../angularjs/common/sass/include-media';
@import '~/styles/colors';

$margin-between-boxes: 20px;
$box-radius: 12px;
$box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.35);
$box-bg-color: #fff;

ssi-topic-feedback {
  font-size: 1rem;
}
