@import "./src/apps/angularjs/common/sass/global-variables.scss";
.action-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > * {
    margin-left: 5px;
  }
}

.panel-file,
.panel-folder {
  .panel-heading {
    height: 80px;
  }
}

.panel-file {
  .panel-body {
    height: 140px;
  }
}

.panel-folder {
  .panel-body {
    height: 182px;
  }
}

.panel-file.panel-primary .panel-heading a {
  color: white;
}

.create-folder-menu-container {
  padding: 10px;
  width: 300px;
}
