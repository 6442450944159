ssi-analytics ssi-marketing ssi-report ssi-brand-sentiment {
  font-size: 1rem;
}
ssi-analytics ssi-marketing ssi-report ssi-brand-sentiment .brand-sentiment-container .section-head h2 .ssi.ssi-help {
  margin: 0 0 0 3px;
  font-size: 14px;
  vertical-align: text-top;
}
ssi-analytics ssi-marketing ssi-report ssi-brand-sentiment .brand-sentiment-container .section-body {
  display: flex;
  flex-direction: column;
}
@media print {
  ssi-analytics ssi-marketing ssi-report ssi-brand-sentiment .brand-sentiment-container .section-body {
    display: block;
  }
}
ssi-analytics ssi-marketing ssi-report ssi-brand-sentiment .brand-sentiment-container .section-body .sentiments {
  display: flex;
  flex-wrap: wrap;
}
@media print {
  ssi-analytics ssi-marketing ssi-report ssi-brand-sentiment .brand-sentiment-container .section-body .sentiments {
    display: block;
  }
}
ssi-analytics ssi-marketing ssi-report ssi-brand-sentiment .brand-sentiment-container .section-body .sentiments .pie-chart-container {
  height: 40em;
  width: calc(50% - 2em);
  margin-right: 1em;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.25);
}
@media print {
  ssi-analytics ssi-marketing ssi-report ssi-brand-sentiment .brand-sentiment-container .section-body .sentiments .pie-chart-container {
    height: auto;
    border: 1px solid black;
    page-break-inside: avoid;
    max-width: 100%;
  }
}
@media (max-width: 1199px) {
  ssi-analytics ssi-marketing ssi-report ssi-brand-sentiment .brand-sentiment-container .section-body .sentiments .pie-chart-container {
    width: 100%;
    margin-right: 0;
  }
}
ssi-analytics ssi-marketing ssi-report ssi-brand-sentiment .brand-sentiment-container .section-body .sentiments .pie-chart-container .highchart-legend-inline {
  display: inline-block;
  min-width: 3.5em;
}
ssi-analytics ssi-marketing ssi-report ssi-brand-sentiment .brand-sentiment-container .section-body .sentiments .pie-chart-container .highchart-legend-inline + span {
  margin-left: 0.5em;
}
ssi-analytics ssi-marketing ssi-report ssi-brand-sentiment .brand-sentiment-container .section-body .sentiments .cards-container {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
  margin-left: 1em;
}
@media print {
  ssi-analytics ssi-marketing ssi-report ssi-brand-sentiment .brand-sentiment-container .section-body .sentiments .cards-container {
    display: block;
  }
}
@media (max-width: 1199px) {
  ssi-analytics ssi-marketing ssi-report ssi-brand-sentiment .brand-sentiment-container .section-body .sentiments .cards-container {
    width: 100%;
    margin-left: 0;
    margin-top: 2em;
  }
}
ssi-analytics ssi-marketing ssi-report ssi-brand-sentiment .brand-sentiment-container .section-body .sentiments .cards-container ssi-fact-overview,
ssi-analytics ssi-marketing ssi-report ssi-brand-sentiment .brand-sentiment-container .section-body .sentiments .cards-container ssi-sentiment-card {
  display: flex;
  flex: 1 1 calc(33.33% - 2em);
  height: 19em;
}
@media (max-width: 1023px) {
  ssi-analytics ssi-marketing ssi-report ssi-brand-sentiment .brand-sentiment-container .section-body .sentiments .cards-container ssi-fact-overview,
ssi-analytics ssi-marketing ssi-report ssi-brand-sentiment .brand-sentiment-container .section-body .sentiments .cards-container ssi-sentiment-card {
    flex: 1 1 calc(50% - 2em);
  }
}
@media (max-width: 767px) {
  ssi-analytics ssi-marketing ssi-report ssi-brand-sentiment .brand-sentiment-container .section-body .sentiments .cards-container ssi-fact-overview,
ssi-analytics ssi-marketing ssi-report ssi-brand-sentiment .brand-sentiment-container .section-body .sentiments .cards-container ssi-sentiment-card {
    flex-basis: 100%;
  }
}
ssi-analytics ssi-marketing ssi-report ssi-brand-sentiment .brand-sentiment-container .section-body .network-inbound-comments {
  flex: 1 1 auto;
  margin-top: 2em;
}