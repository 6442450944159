ssi-enlarge-image-modal .modal-header .ssi-arrow-down-new {
  display: none;
}
ssi-enlarge-image-modal .opn-modal-gallery {
  color: red;
}
ssi-enlarge-image-modal .opn-modal-gallery .modal-header {
  padding: 20px;
  border-bottom: 1px solid #c3c9d8;
}
ssi-enlarge-image-modal .opn-modal-gallery .modal-header .ssi-arrow-down-new {
  display: block;
}
ssi-enlarge-image-modal .opn-modal-gallery .modal-header .fa-angle-left,
ssi-enlarge-image-modal .opn-modal-gallery .modal-header .fa-angle-right {
  display: none;
}
ssi-enlarge-image-modal .opn-modal-gallery .btn-default {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  background-color: #f4f4fa;
  color: #838eab;
  border-width: 0;
  border-radius: 50%;
}
ssi-enlarge-image-modal .opn-modal-gallery .btn-default i {
  font-size: 16px;
  line-height: 1;
}
ssi-enlarge-image-modal .opn-modal-gallery .btn-default i.arrow-down-new-left {
  transform: rotate(90deg);
  font-size: 12px;
}
ssi-enlarge-image-modal .opn-modal-gallery .btn-default i.arrow-down-new-right {
  transform: rotate(270deg);
  font-size: 12px;
}
ssi-enlarge-image-modal .opn-modal-gallery img {
  border-radius: 6px;
}
ssi-enlarge-image-modal .opn-modal-gallery .eim-zoom,
ssi-enlarge-image-modal .opn-modal-gallery .eim-count {
  display: none;
}
ssi-enlarge-image-modal .eim-alt-text {
  margin: 0;
}
ssi-enlarge-image-modal .eim-alt-text p {
  font-size: 14px;
  color: #101525;
  font-weight: 700;
  line-height: 1.4;
  margin: 20px 20px 10px;
}
ssi-enlarge-image-modal .eim-alt-text p:empty {
  margin: 0;
}