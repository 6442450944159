@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

.sentiment-progressbar {
  display: flex;
  height: 20px;
  margin-bottom: 50px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 10px;
  // box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);

  &.sentiment-progressbar-thin {
    height: 12px;
    margin-top: 16px;
    margin-bottom: 20px;

    &.sentiment-bar-spacing-before {
      margin-top: 2px;
    }
  }

  &.sentiment-bar-spacing-before {
    margin-top: 2px;
  }
}

.sentiment-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: $lblue-background;
  // box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  transition: width 0.6s ease;

  &.sentiment-bar-positive {
    background-color: $primary-active;
  }

  &.sentiment-bar-negative {
    background-color: $secondary-red;
  }
}
