ssi-live-chat-conversation-dashboard {
  background-color: #edf1f3;
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  flex-shrink: 0;
}
ssi-live-chat-conversation-dashboard * {
  display: flex;
}
ssi-live-chat-conversation-dashboard > .inner {
  flex-flow: row nowrap;
  flex-grow: 1;
  /* stylelint-disable */
  /* stylelint-enable */
}
ssi-live-chat-conversation-dashboard > .inner button {
  min-height: 14px;
  min-width: 14px;
  align-items: center;
  background-color: transparent;
  justify-content: center;
  border: none;
  padding: 0;
}
ssi-live-chat-conversation-dashboard > .inner button[disabled] {
  cursor: not-allowed;
  opacity: 0.65;
}
ssi-live-chat-conversation-dashboard > .inner button.unavailable {
  cursor: inherit;
}
ssi-live-chat-conversation-dashboard > .inner > div {
  flex-flow: column nowrap;
  flex-grow: 1;
}
ssi-live-chat-conversation-dashboard > .inner > div.has-sidebar {
  margin-right: 200px;
}
ssi-live-chat-conversation-dashboard > .inner > div.container-when-loading .loading {
  margin: 50px 0;
}
ssi-live-chat-conversation-dashboard > .inner > div > .conversation-content {
  flex-flow: column nowrap;
  flex-grow: 0;
  flex-shrink: 1;
  height: calc(100vh - (68px + 72px + 52.5px));
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
ssi-live-chat-conversation-dashboard > .inner > div > .conversation-content > :first-child {
  padding-top: 20px;
}
ssi-live-chat-conversation-dashboard > .inner > div > .conversation-footer {
  background-color: #edf1f3;
  box-shadow: 0 -3px 10px rgba(0, 0, 0, 0.175);
  height: 52.5px;
  flex-shrink: 0;
  padding: 15px;
  z-index: 3;
}
ssi-live-chat-conversation-dashboard > .inner > div > .conversation-footer ul {
  flex-shrink: 0;
  list-style: none;
  margin: 0;
  padding: 0;
}
ssi-live-chat-conversation-dashboard > .inner > div > .conversation-footer ul > li {
  flex-shrink: 0;
  margin: 0;
  padding: 0;
}
ssi-live-chat-conversation-dashboard > .inner > div > .conversation-footer ul > li > button {
  align-items: center;
  background-color: #fff;
  border: none;
  border-radius: 10px;
  color: #43ade8;
  flex-shrink: 0;
  font-weight: 400;
  justify-content: space-between;
  padding: 2.5px 5px;
}
ssi-live-chat-conversation-dashboard > .inner > div > .conversation-footer ul > li > button:focus {
  outline: revert;
}
ssi-live-chat-conversation-dashboard > .inner > div > .conversation-footer ul > li > button i {
  margin-right: 5px;
}
ssi-live-chat-conversation-dashboard > .inner > div > .conversation-footer ul > li > button span {
  font-size: 0.85em;
  padding: 0 1.25px;
}
ssi-live-chat-conversation-dashboard > .inner > div > .conversation-navigation {
  align-items: center;
  background-color: #d8e1e5;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.175);
  display: flex;
  flex-flow: row nowrap;
  height: 72px;
  justify-content: space-between;
  min-height: 72px;
  padding: 0 20px;
  position: relative;
  z-index: 3;
}
ssi-live-chat-conversation-dashboard > .inner > div > .conversation-navigation > ul {
  align-items: center;
  list-style: none;
  margin: 20px 0;
  padding: 0;
}
ssi-live-chat-conversation-dashboard > .inner > div > .conversation-navigation > ul.agent {
  align-items: flex-start;
}
ssi-live-chat-conversation-dashboard > .inner > div > .conversation-navigation > ul.agent > li + li {
  margin-left: 20px;
}
ssi-live-chat-conversation-dashboard > .inner > div > .conversation-navigation > ul.agent > li.close-conversation {
  margin-top: 5px;
}
ssi-live-chat-conversation-dashboard > .inner > div > .conversation-navigation > ul.agent > li.network-state {
  position: relative;
}
ssi-live-chat-conversation-dashboard > .inner > div > .conversation-navigation > ul.agent > li.network-state .icon-background {
  font-size: 3em;
  left: 3px;
  position: absolute;
  top: -2px;
  z-index: 3;
}
ssi-live-chat-conversation-dashboard > .inner > div > .conversation-navigation > ul.agent > li.network-state .icon-foreground {
  background-color: #84c3c0;
  border: solid 2px #fff;
  border-radius: 20px;
  font-size: 1.4em;
  font-weight: 600;
  left: 7px;
  padding: 5px;
  position: absolute;
  top: 3px;
  z-index: 3;
}
ssi-live-chat-conversation-dashboard > .inner > div > .conversation-navigation > ul.agent > li i {
  color: #fff;
  font-size: 2.6em;
  font-weight: 500;
}
ssi-live-chat-conversation-dashboard > .inner > div > .conversation-navigation > ul.conversation {
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}
ssi-live-chat-conversation-dashboard > .inner > div > .conversation-navigation > ul.conversation > li {
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  color: #a6b7c0;
  font-size: 1em;
  height: 42px;
  margin: 0;
  padding: 10px;
  position: relative;
}
ssi-live-chat-conversation-dashboard > .inner > div > .conversation-navigation > ul.conversation > li.assign-message {
  margin-right: 10px;
}
ssi-live-chat-conversation-dashboard > .inner > div > .conversation-navigation > ul.conversation > li.assign-message button .button-icon {
  font-size: 1.5em;
  font-weight: 400;
}
ssi-live-chat-conversation-dashboard > .inner > div > .conversation-navigation > ul.conversation > li.assign-message button:focus {
  outline: revert;
}
ssi-live-chat-conversation-dashboard > .inner > div > .conversation-navigation > ul.conversation > li.assign-message button .button-text {
  font-size: 0.85em;
  font-style: italic;
  font-weight: 500;
  margin-left: 10px;
}
ssi-live-chat-conversation-dashboard > .inner > div > .conversation-navigation > ul.conversation > li.translate-messages {
  margin-right: 10px;
}
ssi-live-chat-conversation-dashboard > .inner > div > .conversation-navigation > ul.conversation > li.translate-messages.toggled {
  background-color: #1dafec;
}
ssi-live-chat-conversation-dashboard > .inner > div > .conversation-navigation > ul.conversation > li.translate-messages.toggled .translate {
  color: white;
}
ssi-live-chat-conversation-dashboard > .inner > div > .conversation-navigation > ul.conversation > li.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
ssi-live-chat-conversation-dashboard > .inner > div > .conversation-navigation > ul.conversation > li.options > ul {
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
}
ssi-live-chat-conversation-dashboard > .inner > div > .conversation-navigation > ul.conversation > li.options > ul > li {
  margin: 0 5px;
  padding: 0;
}
ssi-live-chat-conversation-dashboard > .inner > div > .conversation-navigation > ul.conversation > li.profile-toggle, ssi-live-chat-conversation-dashboard > .inner > div > .conversation-navigation > ul.conversation > li.sidebar-toggle {
  background-color: transparent;
  margin-left: 20px;
  padding-right: 0;
}
ssi-live-chat-conversation-dashboard > .inner > div > .conversation-navigation > ul.conversation > li.profile-toggle i, ssi-live-chat-conversation-dashboard > .inner > div > .conversation-navigation > ul.conversation > li.sidebar-toggle i {
  color: #fff;
  font-size: 2.6em;
  font-weight: 500;
}
ssi-live-chat-conversation-dashboard > .inner > div > .conversation-navigation > ul > li {
  margin: 0;
  padding: 0;
}
ssi-live-chat-conversation-dashboard > .inner .emblem {
  background-color: #d8e1e5;
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 0.75em;
  font-style: italic;
  font-weight: 400;
  position: absolute;
  right: -12px;
  top: -10px;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  border: solid 1px white;
}
ssi-live-chat-conversation-dashboard > .inner .profile-toggle {
  display: none;
}
@media only screen and (max-width: 1350px) {
  ssi-live-chat-conversation-dashboard > .inner .has-sidebar {
    margin-right: 0 !important;
  }
  ssi-live-chat-conversation-dashboard > .inner .profile-toggle {
    display: flex;
  }
}