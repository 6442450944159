@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-sentiment-text {
  ssi-gauge-chart {
    position: relative;
    display: block;
  }

  mwl-gauge {
    display: block;
    margin-bottom: -50%;
    padding-bottom: 10%;

    .gauge {
      transform: perspective(1px) translateZ(0);

      .dial {
        stroke: rgb(238, 238, 238);
        stroke-width: 20;
        fill: rgba(0, 0, 0, 0);
      }

      [class^='value-'] {
        stroke-width: 20;
        fill: rgba(0, 0, 0, 0);
      }

      .value-negative {
        stroke: rgb(217, 83, 79);
      }

      .value-semiNegative {
        stroke: rgb(233, 134, 131);
      }

      .value-neutral {
        stroke: rgb(167, 183, 185);
      }

      .value-semiPositive {
        stroke: rgb(137, 208, 219);
      }

      .value-positive {
        stroke: rgb(84, 189, 205);
      }

      .value-text {
        fill: #596065;
        font-family: sans-serif;
        font-weight: bold;
        font-size: 15px;
        transform: translate(0, -5px);
      }
    }
  }

  .mwl-gauge-label {
    position: absolute;
    bottom: calc(50% + 15px);
    left: 0;
    right: 0;
    color: #596065;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 20px;
    line-height: 15px;
    margin-top: -15px;
  }

  .gauge-meta {
    position: relative;
    z-index: 1;
  }
}
