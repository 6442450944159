@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-content-generator-feed {
  font-size: 1rem;

  .cgf-list {
    & > li {
      display: flex;
      align-items: center;
      margin-bottom: 0.5em;
    }

    ssi-checkbox-2 {
      .checkbox-2-container i {
        top: 0;
        bottom: 0;
        margin: auto;
        height: 10px;
      }

      label {
        white-space: normal;
      }
    }
  }
}
