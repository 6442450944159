@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-top-post-stats {
  .top-post-stats-container {
    display: flex;
    // padding:0 2em 0 0;
    .top-post-interaction {
      flex: 1;
      text-align: center;
      font-weight: 900;
      color: $lmode-title-text;
      position: relative;
      #rank-0 {
        div {
          background-color: #fbf3e2;
        }
        i {
          color: #f0b427;
        }
      }
      #rank-1 {
        div {
          background-color: #eff2fd;
        }
        i {
          color: $dmode-body-text;
        }
      }
      #rank-2 {
        div {
          background-color: $primary-red;
        }
        i {
          color: $secondary-red;
        }
      }
      .rank {
        position: relative;
        margin: 0 0 2em 0;
        div {
          margin: 0 auto;
          width: 46px;
          height: 46px;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-content: center;
          flex-direction: column;
        }
        i {
          position: absolute;
          transform: translate(-50%, -50%);
          text-align: center;
          font-size: 4em;
          color: #eaedf4;
        }
      }
      .number {
        display: block;
        font-size: 2em;
        position: relative;
        z-index: 2;
        line-height: 1em;
      }
      .subtitle {
        text-transform: uppercase;
        font-size: 0.7em;
        position: relative;
        z-index: 2;
        color: $lmode-helper-text;
        letter-spacing: 1px;
        font-weight: 700;
      }
    }
    .top-post-score {
      flex: 2;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 0.8em;
      padding: 0 0 0 2em;
      ul {
        list-style: none;
        padding: 0;
        li {
          margin: 0 0 1em 0;
          color: $lmode-helper-text;
        }
      }
      i {
        margin-right: 0.6em;
        font-size: 1.4em;
      }
      span {
        letter-spacing: 1px;
        font-size: 0.9em;
        font-weight: 700;
      }
      ssi-big-number {
        width: 55px;
        display: inline-block;
        margin-right: 0.3em;
        font-size: 1.4em;
        color: $lmode-title-text;
        font-weight: 900;
      }
    }
  }
}
