ssi-widget-influencers-list {
  font-size: 1rem;
}
ssi-widget-influencers-list .influencers-list-wrap {
  position: relative;
  display: flex;
  height: inherit;
  overflow: auto;
  padding: 2.2em 1.4em 0;
}
ssi-widget-influencers-list .influencers-list-wrap ssi-influencers-list {
  align-self: center;
  margin-top: auto;
  margin-bottom: auto;
}