ssi-outbox-post-list .scroll-y {
  border: 1px solid #eee;
}

@media (max-width: 885px) {
  ssi-outbox-post-list .outbox-post-list-responsive {
    height: 95vh !important;
  }
}
ssi-outbox-post-list .panel {
  box-shadow: 0 4px 32px -5px rgba(119, 124, 128, 0.56);
  border-radius: 0;
  border-color: #eee;
}
ssi-outbox-post-list .panel-body {
  padding: 5px;
  position: relative;
}
ssi-outbox-post-list .media-left {
  padding: 10px;
}
ssi-outbox-post-list .media-body {
  padding-top: 10px;
}
ssi-outbox-post-list .media-content > * {
  white-space: pre-wrap;
}
ssi-outbox-post-list .media-right {
  padding-right: 5px;
}
ssi-outbox-post-list .media-right a,
ssi-outbox-post-list .media-right img {
  width: 90px;
  height: 90px;
  box-shadow: 0 4px 32px -5px rgba(119, 124, 128, 0.56);
  border-radius: 50%;
}
ssi-outbox-post-list .media-right a {
  display: inline-block;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
}
ssi-outbox-post-list .media-right a:hover {
  text-decoration: none;
}
ssi-outbox-post-list .media-right img {
  object-fit: cover;
}
ssi-outbox-post-list .has-bottom-stats .media-right {
  padding-right: 12px;
  padding-top: 12px;
}
ssi-outbox-post-list .media-heading {
  margin-bottom: 12px;
}
ssi-outbox-post-list .media-heading a {
  padding-right: 5px;
}
ssi-outbox-post-list .media-heading small {
  font-size: 12px;
  color: #596065;
}
ssi-outbox-post-list .media-sub-heading {
  margin-top: 3px;
}
ssi-outbox-post-list .children-separated *:not(:last-child):after {
  content: " | ";
  padding: 0 5px;
}
ssi-outbox-post-list .children-separated :last-child {
  padding-right: 5px;
}
ssi-outbox-post-list .outbox-post-btns {
  margin-bottom: 10px;
}
ssi-outbox-post-list .outbox-post-btns .btn {
  margin-right: 3px;
}
ssi-outbox-post-list .outbox-post-btns a:hover {
  text-decoration: none;
}
ssi-outbox-post-list .outbox-post-btns .outbox-post-stat {
  margin-right: 7px;
}
ssi-outbox-post-list .outbox-menu {
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 1;
}
ssi-outbox-post-list .outbox-menu .outbox-menu-items {
  display: none;
}
ssi-outbox-post-list .outbox-menu-items {
  background-color: #ffffff;
  padding: 7px;
  min-width: 35px;
  border-radius: 20px;
  text-align: center;
  box-shadow: 0 4px 32px -5px rgba(119, 124, 128, 0.56);
}
ssi-outbox-post-list .outbox-menu-items a {
  padding: 0 7px;
}
ssi-outbox-post-list .outbox-menu-items a:hover {
  text-decoration: none;
}
ssi-outbox-post-list .outbox-menu:not(.no-hover):hover .outbox-menu-trigger {
  display: none;
}
ssi-outbox-post-list .outbox-menu:not(.no-hover):hover .outbox-menu-items {
  display: inline-block;
}
ssi-outbox-post-list .outbox-menu-trigger {
  width: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 33px;
  box-shadow: 0 4px 32px -5px rgba(119, 124, 128, 0.56);
  border-radius: 50%;
}
ssi-outbox-post-list .no-media .media-body {
  padding-right: 10px;
}
ssi-outbox-post-list .media-nav-arrow.left {
  left: -10px !important;
}
ssi-outbox-post-list .media-nav-arrow.right {
  right: -10px !important;
}
ssi-outbox-post-list .outbox-post-stats-container {
  margin-left: 62px;
  padding-bottom: 8px;
}
ssi-outbox-post-list .outbox-post-advert-icon {
  position: relative;
  left: 3px;
  top: -15px;
}
ssi-outbox-post-list .unvalidated {
  color: #526bb4;
  margin-left: 5px;
}
ssi-outbox-post-list .outbox-post-stat {
  font-size: 10px;
  font-weight: normal;
  display: inline-block;
  position: relative;
  top: 2px;
  margin-right: 25px;
  color: #bbc2c8;
}
ssi-outbox-post-list .outbox-post-stat i {
  font-size: 14px;
}
ssi-outbox-post-list .outbox-post-stat .outbox-post-stat-count {
  position: relative;
  top: -4px;
}
ssi-outbox-post-list a.outbox-post-stat {
  color: #bbced8;
}
ssi-outbox-post-list a.outbox-post-stat:hover {
  text-decoration: none;
}

.ad-budget-tooltip {
  padding: 3px 8px;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
  font-weight: normal;
  font-size: 11px;
}