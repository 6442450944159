@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-meta-post-options-select {
  .meta-post-options-select-container {
    ssi-dropdown-select-2 {
      .dd-head {
        height: 36px;

        .button-icon {
          color: #43537f;
        }
      }
    }

    .custom-head-button-label {
      color: #43537f;
      i {
        margin-right: 5px;
        font-size: 14px;
      }
      span {
        font-size: 12px;
        font-weight: 900;
      }
    }
  }
}
