@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../../angularjs/common/sass/mixins';
@import '../../../../../../../angularjs/common/sass/variables';

.manage-ifttt-applet-accounts {

  .manage-ifttt-applet-stage-body {
    text-align: left;
  }

  .scroll-vertical {
    max-height: 200px;
  }

}
