ssi-cl-dropdown-select-h .cl-dropdown-select-h-container ssi-dropdown-select-2 .dd-head {
  height: 36px;
}
ssi-cl-dropdown-select-h .cl-dropdown-select-h-container ssi-dropdown-select-2 .dd-head .button-icon {
  color: #43537f;
}
ssi-cl-dropdown-select-h .cl-dropdown-select-h-container .custom-head-button-label {
  color: #43537f;
}
ssi-cl-dropdown-select-h .cl-dropdown-select-h-container .custom-head-button-label i {
  margin-right: 5px;
  font-size: 14px;
}
ssi-cl-dropdown-select-h .cl-dropdown-select-h-container .custom-head-button-label span {
  font-size: 12px;
  font-weight: 900;
}
ssi-cl-dropdown-select-h .cl-dropdown-select-h-container .menu-header {
  padding: 20px 0;
  width: 280px;
  color: #43537f;
  font-size: 14px;
}
ssi-cl-dropdown-select-h .cl-dropdown-select-h-container .menu-header strong {
  font-weight: 900;
}
ssi-cl-dropdown-select-h .cl-dropdown-select-h-container .menu-header strong i {
  margin-right: 5px;
}
ssi-cl-dropdown-select-h .cl-dropdown-select-h-container .menu-header div {
  font-weight: 700;
  margin-top: 3px;
}