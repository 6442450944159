@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../angularjs/common/sass/variables';

ssi-advertising-not-purchased {
    position: relative;
    margin: 40px 0 0;
    padding: 100px 0;
    text-align: center;
    color: #8a98a1;
    background-image: url('./img/ads_splash_background.jpg');
    background-size: cover;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #3a3f49;
        opacity: 0.9;
    }
}

.not-purchased-wrap {
    position: relative;
    z-index: 1;
}

.not-purchased-title,
.not-purchased-meta,
.not-purchased-cta {
    max-width: 700px;
    font-weight: 500;
    margin: 0 auto;
}

.not-purchased-title {
    font-size: 22px;
    margin: 0 auto 25px;
    color: #c9d8e0;
}

.not-purchased-meta {
    line-height: 2;
    margin-bottom: 50px;
}

.not-purchased-cta {
    font-size: 18px;
    color: #c9d8e0;
    line-height: 1.6;
    margin-bottom: 30px;
}

.not-purchased-cards {
    display: flex;
    margin: 120px auto;
    max-width: 1300px;
    min-width: 900px;
    padding: 0;
} 

.not-purchased-card {
    display: inline-block;
    flex: 1 0 33%;
    padding: 60px 80px;
    max-width: 445px;
    border-right: 1px solid rgba(168,185,194,0.5);

    &:last-child {
        border-right-width: 0;
    }

    .ssi {
        font-size: 72px;
        color: #c9d8e0;
    }

    .not-purchased-card-title {
        color: #c9d8e0;
        font-size: 16px;
        font-weight: 700;
    }

    p {
        font-weight: 500;
        line-height: 1.8;
    }
}