@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-publisher-instagram-post-preview {
  display: block;
  background-color: #fff;
  max-width: 500px;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  font-family: -apple-system, system-ui, 'Segoe UI', Roboto, Helvetica, Arial,
    sans-serif;
  font-weight: normal;

  .instagram-post-header {
    height: 60px;
    padding: 16px;

    img {
      width: 30px;
      height: 30px;
      border: solid 1px #fafafa;
    }

    .instagram-post-author-username {
      line-height: 26px;
      padding-left: 10px;
    }
  }

  .instagram-post-author-username {
    color: #262626;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .instagram-post-author-username:hover {
    text-decoration: none;
    color: #262626;
  }

  .instagram-post-images {
    position: relative;
    display: flex;
    overflow: hidden;
  }

  .instagram-post-images-image {
    flex: 1 0 498px;

    img {
      height: 100%;
      background-size: cover;
    }
  }

  .instagram-post-images-action {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    opacity: 0.6;
    background-color: #fff;
    height: 26px;
    width: 26px;
    color: #666;
    border-radius: 50%;
    border-width: 0;
    font-size: 26px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;

    &:first-child {
      left: 8px;
    }

    &:last-child {
      right: 8px;
    }
  }

  .instagram-post-image img,
  .instagram-post-images img,
  .instagram-post-video video {
    width: 100%;
  }

  .instagram-post-body {
    padding: 0 16px;
  }

  .instagram-post-actions i {
    font-size: 24px;
    color: #003569;
    padding: 8px;
    padding-left: 0;
  }

  .instagram-post-actions i:nth-child(2) {
    position: relative;
    top: -2px;
  }

  .instagram-post-text {
    color: #262626;
    font-size: 14px;

    a {
      color: #003569;
    }

    a:hover {
      text-decoration: none;
    }
  }

  .instagram-post-date-time {
    font-size: 10px;
    letter-spacing: 0.2px;
    line-height: 18px;
    color: #999;
    text-transform: uppercase;
    margin-bottom: 8px;
    margin-top: 4px;
  }

  .text-read-more {
    font-weight: 700;
    cursor: pointer;
  }
}
