@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../angularjs/common/sass/include-media';
@import '../../../../../../angularjs/common/sass/mixins.scss';
@import '~/styles/colors';

ssi-video-metrics {
  font-size: 1rem;

  .section-head {
    margin-bottom: 20px;
  }

  .section-title {
    color: $lmode-title-text;
    font-size: 20px;
    font-weight: 900;
    margin: 0;
  }

  .facts-summary-container {
    .section-body {
      width: 100%;

      .facts-row {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(26.8em, 1fr));
        grid-gap: 0 2em;
        margin: 0;

        @media print {
          display: block;

          & > * {
            display: inline-block;
            width: auto !important;
          }
        }

        > * {
          width: 100%;
          padding: 7px;
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }

  ssi-fact-overview {
    min-width: 260px;
    .fact-overview {
      box-shadow: 0 0.5em 1em rgba(131, 142, 171, 0.35);
      margin-bottom: 10px;
    }
  }
}
