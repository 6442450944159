@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../angularjs/common/sass/mixins';
@import '../../../../../angularjs/common/sass/variables';
@import '~/styles/colors';

$component-height: 72px;
$unit: 2.5px;
$footer-height: $unit * 21;

ssi-live-chat-conversation-dashboard {
  background-color: #edf1f3;
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  flex-shrink: 0;

  * {
    display: flex;
  }

  & > .inner {
    flex-flow: row nowrap;
    flex-grow: 1;

    button {
      min-height: 14px;
      min-width: 14px;
      align-items: center;
      background-color: transparent;
      justify-content: center;
      border: none;
      padding: 0;

      &[disabled] {
        cursor: not-allowed;
        opacity: 0.65;
      }

      &.unavailable {
        cursor: inherit;
      }
    }

    & > div {
      flex-flow: column nowrap;
      flex-grow: 1;

      &.has-sidebar {
        margin-right: 200px;
      }

      &.container-when-loading {
        .loading {
          margin: ($unit * 20) 0;
        }
      }

      & > .conversation-content {
        flex-flow: column nowrap;
        flex-grow: 0;
        flex-shrink: 1;
        height: calc(
          100vh - (#{$navbar-height} + #{$component-height} + #{$footer-height})
        );
        margin: 0;
        overflow-x: hidden;
        overflow-y: auto;

        & > :first-child {
          padding-top: ($unit * 8);
        }
      }

      & > .conversation-footer {
        background-color: #edf1f3;
        box-shadow: 0 -3px 10px rgba(0, 0, 0, 0.175);
        height: $footer-height;
        flex-shrink: 0;
        padding: 15px;
        z-index: 3;

        ul {
          flex-shrink: 0;
          list-style: none;
          margin: 0;
          padding: 0;

          & > li {
            flex-shrink: 0;
            margin: 0;
            padding: 0;

            & > button {
              align-items: center;
              background-color: #fff;
              border: none;
              border-radius: 10px;
              color: $primary-blue;
              flex-shrink: 0;
              font-weight: 400;
              justify-content: space-between;
              padding: ($unit) ($unit * 2);

              &:focus {
                outline: revert;
              }

              i {
                margin-right: $unit * 2;
              }

              span {
                font-size: 0.85em;
                padding: 0 calc($unit / 2);
              }
            }
          }
        }
      }

      & > .conversation-navigation {
        align-items: center;
        background-color: #d8e1e5;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.175);
        display: flex;
        flex-flow: row nowrap;
        height: $component-height;
        justify-content: space-between;
        min-height: $component-height;
        padding: 0 ($unit * 8);
        position: relative;
        z-index: 3;

        & > ul {
          align-items: center;
          list-style: none;
          margin: ($unit * 8) 0;
          padding: 0;

          &.agent {
            align-items: flex-start;

            & > li {
              & + li {
                margin-left: ($unit * 8);
              }

              &.close-conversation {
                margin-top: ($unit * 2);
              }

              &.network-state {
                position: relative;

                .icon-background {
                  font-size: 3em;
                  left: 3px;
                  position: absolute;
                  top: -2px;
                  z-index: 3;
                }

                .icon-foreground {
                  background-color: #84c3c0;
                  border: solid 2px #fff;
                  border-radius: ($unit * 8);
                  font-size: 1.4em;
                  font-weight: 600;
                  left: 7px;
                  padding: 5px;
                  position: absolute;
                  top: 3px;
                  z-index: 3;
                }
              }

              i {
                color: #fff;
                font-size: 2.6em;
                font-weight: 500;
              }
            }
          }

          &.conversation {
            align-items: center;
            list-style: none;
            margin: 0;
            padding: 0;

            & > li {
              align-items: center;
              background-color: #fff;
              border-radius: ($unit * 8);
              color: #a6b7c0;
              font-size: 1em;
              height: 42px;
              margin: 0;
              padding: ($unit * 4);
              position: relative;

              &.assign-message {
                margin-right: ($unit * 4);

                button {
                  .button-icon {
                    font-size: 1.5em;
                    font-weight: 400;
                  }

                  &:focus {
                    outline: revert;
                  }

                  .button-text {
                    font-size: 0.85em;
                    font-style: italic;
                    font-weight: 500;
                    margin-left: ($unit * 4);
                  }
                }
              }

              &.translate-messages {
                margin-right: ($unit * 4);
                &.toggled {
                  background-color: #1dafec;
                  .translate {
                    color: white;
                  }
                }
              }

              &.disabled {
                cursor: not-allowed;
                opacity: 0.5;
              }

              &.options {
                > ul {
                  justify-content: space-between;
                  list-style: none;
                  margin: 0;
                  padding: 0;

                  & > li {
                    margin: 0 ($unit * 2);
                    padding: 0;
                  }
                }
              }

              &.profile-toggle,
              &.sidebar-toggle {
                background-color: transparent;
                margin-left: 20px;
                padding-right: 0;

                i {
                  color: #fff;
                  font-size: 2.6em;
                  font-weight: 500;
                }
              }
            }
          }

          & > li {
            margin: 0;
            padding: 0;
          }
        }
      }
    }

    .emblem {
      background-color: #d8e1e5;
      border-radius: 50%;
      color: #fff;
      display: flex;
      font-size: 0.75em;
      font-style: italic;
      font-weight: 400;
      position: absolute;
      right: -12px;
      top: -10px;

      align-items: center;
      justify-content: center;
      height: 25px;
      width: 25px;
      border: solid 1px white;
    }

    .profile-toggle {
      display: none;
    }

    /* stylelint-disable */
    @include responsiveWidth($ssi-screen-width-sm) {
      .has-sidebar {
        margin-right: 0 !important;
      }

      .profile-toggle {
        display: flex;
      }
    }
    /* stylelint-enable */
  }
}
