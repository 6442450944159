@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-checkbox {
  .checkbox-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $lmgrey-background;
    border: 1px solid #c3c9d8;
    border-radius: 10px;

    &.active {
      background-color: #e4faff;
      border-color: $primary-active;
    }

    label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: 0;
      white-space: nowrap;
      color: $lmode-body-text;
      font-weight: 900;
      padding: 12px 20px;

      &:hover {
        cursor: pointer;
      }

      span {
        margin-right: 15px;
      }
    }

    .leading-icon {
      margin-right: 5px;
      color: $lmode-body-text;
      font-size: 16px;
    }

    .check-icon {
      position: absolute;
      display: none;
      top: 16px;
      right: 22px;
      color: #fff;
      font-size: 12px;
    }
    input {
      margin: 0;
      margin-left: auto;
      appearance: none;
      outline: none !important;
      border: 1px solid #d7dbe4;
      background-color: #fff;
      border-radius: 2px;
      padding: 7px;

      &:checked {
        border-color: $primary-active;
        background-color: $primary-active;
        & + .check-icon {
          display: block;
        }
      }
    }
  }
}
