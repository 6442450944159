ssi-alert {
  display: flex;
  padding: 10px 15px;
  background-color: #fff1ed;
  color: #43537f;
  font-weight: bold;
  font-size: 12px;
  border: none;
  border-radius: 10px;
}
ssi-alert.warning {
  background-color: #fff1ed;
}
ssi-alert .message-icon {
  margin: 2px 10px 0 0;
}