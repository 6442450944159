@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-emoji-picker {
  $emoji-category-color: #bfcad2;

  .emoji-container {
    padding: 22px 18px 0;
  }

  p {
    font-size: 10px;
    letter-spacing: 1px;
    font-weight: 900;
    text-rendering: geometricPrecision;
    text-transform: uppercase;
  }

  .emoji-input {
    position: relative;
    color: #43537f;
    border-bottom: 1px solid #c3c9d8;
    margin: 0 0 18px;

    .form-control {
      background-color: transparent;
      font-size: 14px;
      padding: 0 0 8px;
      font-weight: 700;
      color: inherit;

      &::placeholder {
        color: #6a759a;
      }
    }

    .ssi {
      position: absolute;
      right: 0;
      top: 4px;
      font-size: 12px;
      color: #43537f;
      pointer-events: none;
    }
  }

  .emoji-categories,
  .emoji-list,
  .freq-emoji-list {
    display: flex;
    flex-wrap: wrap;
  }

  .freq-emoji-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -6px 10px -4px;
  }

  .emoji-list {
    max-height: 149px;
    min-height: 149px;
    overflow-y: auto;
    overflow-x: hidden;
    align-content: flex-start;
    margin: 0 -20px 0 -4px;
  }

  .emojis-row {
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .emoji-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .emoji-icon {
    cursor: pointer;
    text-align: center;
    margin: 0 !important;

    .joypixels-24-people {
      margin: 0 2.4px;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .emoji-categories {
    justify-content: space-between;
    border-top: 1px solid #c3c9d8;
    padding: 10px 18px 12px;
    margin: 0 -20px 0 -18px;

    a {
      cursor: pointer;

      i {
        color: #43537f;
        font-size: 14px !important;
      }
    }

    a:hover {
      text-decoration: none;
      color: darken($emoji-category-color, 50%);
    }

    a.active,
    a.active:hover {
      color: $primary-active;
      i {
        color: $primary-active !important;
      }
    }
  }
}
