@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-total-stat-card {
  font-size: 1rem;

  .tsc {
    position: relative;
    display: inline-flex;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0 0.5em 1em rgba(131, 142, 171, 0.35);
    border-radius: 1.2em;
    width: 100%;
    min-width: 26.8em;
    margin: 0 0 2em;

    @media print {
      border: 1px solid black;
    }
  }

  .tsc-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 26.8em;
    text-align: center;
  }

  .tsc-icon-wrap {
    display: flex;
    align-items: flex-end;
    height: 6em;
    margin: 0 auto 1.5em;

    &.tsc-icon-wrap-growth-label {
      margin: -2em auto 1.5em;
    }

    &.tsc-icon-wrap-background {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 5.6em;
      width: 5.6em;
      line-height: 1;
      border-radius: 50%;
      margin-bottom: 0.9em;
    }
  }

  .tsc-icon {
    font-size: 3.6em;
    color: $lmode-helper-text;
  }

  .tsc-label {
    letter-spacing: 0.1em;
    line-height: 1.2em;

    p {
      color: $lmode-helper-text;
      font-size: 1em;
      font-weight: 900;
      margin: 0 0 0.3em;
      text-transform: uppercase;
      text-rendering: geometricPrecision;
    }
  }

  .tsc-value {
    &:before {
      content: '';
      display: block;
      margin: 0.7em auto;
      width: 4em;
      background-color: $lblue-background;
      height: 0.4em;
      border-radius: 10em;
    }

    p {
      color: $lmode-title-text;
      font-weight: 900;
      margin: 0;
      font-size: 2em;
      text-rendering: geometricPrecision;
    }
  }

  .tsc-growth-label-wrap {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15em;
    height: 3.6em;
    bottom: 0;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
    background-color: $primary-red;
    color: $secondary-red;

    &.tsc-growth-label-positive {
      background-color: #eaf9f8;
      color: #12aca4;
    }

    p,
    i {
      display: inline-block;
      margin: 0 0.3em 0 0;
      text-rendering: geometricPrecision;

      &:last-child {
        margin: 0;
      }
    }
  }

  .tsc-growth-label-icon {
    font-size: 1.2em;
  }

  .tsc-growth-label {
    font-size: 1.4em;
    font-weight: 900;
  }
}
