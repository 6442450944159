ssi-chatbot-report ssi-report-sidebar .report-sidebar {
  width: 228px;
}
ssi-chatbot-report ssi-report-sidebar .ssi-line-arrow-small-down {
  transform: rotate(90deg);
}
ssi-chatbot-report .report-body {
  display: flex;
  flex-direction: column;
  background-color: #f4f4fa;
  width: 100%;
  height: 100%;
  padding-top: 134px;
  padding-left: 268px;
  padding-right: 40px;
  min-height: calc(100vh - 68px);
}
ssi-chatbot-report .report-body.loading {
  background-color: #fff;
}
ssi-chatbot-report .report-body-loading {
  width: 100%;
  height: 100%;
  height: calc(100vh - 68px);
  padding-top: 94px;
  padding-left: 228px;
}
ssi-chatbot-report hr {
  background-color: #fff;
  height: 2px;
  border: none;
  margin: 30px 0;
}
ssi-chatbot-report .section-content {
  margin: 25px 0;
}
ssi-chatbot-report .section-heading {
  position: relative;
}
ssi-chatbot-report h4 {
  color: #101525;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}
ssi-chatbot-report .summaries-per-account-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
ssi-chatbot-report .summaries-per-account-container ssi-account-summary-card {
  flex: 1 1 calc(50% - 20px);
}
ssi-chatbot-report .summaries-per-account-container ssi-account-summary-card .asc {
  margin-bottom: 0;
}
ssi-chatbot-report .summaries-per-account-container ssi-account-summary-card .asc-stats {
  justify-content: flex-end;
}
ssi-chatbot-report .summaries-per-account-container ssi-account-summary-card .asc-stat-label::before {
  background-color: #14bae3;
}
@media (min-width: 1025px) {
  ssi-chatbot-report .summaries-per-account-container ssi-account-summary-card {
    flex: 0 1 calc(15% - 20px);
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  ssi-chatbot-report .summaries-per-account-container ssi-account-summary-card {
    margin: 10px;
  }
}
ssi-chatbot-report .facts-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  overflow-x: auto;
}
ssi-chatbot-report .facts-container > * {
  width: 100%;
  padding: 7px;
  margin-left: 10px;
}
ssi-chatbot-report .facts-container > *:first-child {
  margin-left: 0;
}
@media (max-width: 1299px) {
  ssi-chatbot-report .facts-container > * .fact-overview {
    width: 172px !important;
  }
}
ssi-chatbot-report .table-container ssi-treetable .row-even {
  background-color: #f8f9fd;
}
ssi-chatbot-report .table-container ssi-treetable .content {
  background-color: #fff;
  border-radius: 1.2em;
  padding: 3em 2.6em 4.6em;
  border-radius: 0 15px 0 0;
  box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
}
ssi-chatbot-report .table-container ssi-treetable .body-inner .row {
  position: relative;
}
ssi-chatbot-report .table-container ssi-treetable .body-inner .row:nth-child(2n) {
  background-color: #f8f9fd;
}
ssi-chatbot-report .table-container ssi-treetable .body-inner .row:nth-child(2n):before, ssi-chatbot-report .table-container ssi-treetable .body-inner .row:nth-child(2n):after {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 0.2em;
  border-radius: 10em;
  background-color: #e0e7f4;
  z-index: 1;
}
ssi-chatbot-report .table-container ssi-treetable .body-inner .row:nth-child(2n):after {
  top: auto;
  bottom: 0;
}
ssi-chatbot-report .table-container ssi-treetable .body-inner .cell {
  display: flex;
  align-items: center;
}
ssi-chatbot-report .table-container ssi-treetable .body-inner .cell p {
  color: #101525;
  font-weight: 900;
  text-rendering: geometricPrecision;
  font-size: 1.4em;
  padding: 0 0.6em 0 1em;
  margin: 0;
}
ssi-chatbot-report .table-container ssi-treetable .body-inner .body-cell-primary {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  text-rendering: geometricPrecision;
  width: 500px;
}
ssi-chatbot-report .table-container ssi-treetable .body-inner .body-cell-primary i {
  display: inline-flex;
  align-items: center;
  color: #838eab;
  margin: 0 1em 0 0.7em;
}
ssi-chatbot-report .table-container ssi-treetable .body-inner .body-cell-primary i:before {
  font-size: 1.2em;
}
ssi-chatbot-report .table-container ssi-treetable .body-inner .body-cell-primary p {
  padding: 0;
  display: inline-block;
  color: #43537f;
  font-size: 1.2em;
  font-weight: 900;
  margin: 0;
}
ssi-chatbot-report .table-container ssi-treetable .paginator-container {
  background-color: #fff;
  border-radius: 0 0 15px 15px;
  height: 80px;
  padding: 15px;
}
ssi-chatbot-report .table-container ssi-treetable ssi-paginator {
  display: flex;
  justify-content: center;
  font-size: 1.4em;
}
ssi-chatbot-report .table-container ssi-treetable ssi-paginator button.page-link {
  display: flex;
  align-items: center;
  font-weight: 900;
  color: #43537f;
  background-color: transparent;
  margin: 0 10px;
}
ssi-chatbot-report .table-container ssi-treetable ssi-paginator button.page-link.current {
  font-weight: 900;
  color: #14bae3;
  background-color: transparent;
  opacity: 1;
  position: relative;
}
ssi-chatbot-report .table-container ssi-treetable ssi-paginator button.page-link.current::before {
  content: "";
  position: absolute;
  top: 23px;
  left: -2px;
  border-radius: 15px;
  display: block;
  height: 4px;
  width: 26px;
  background-color: #14bae3;
}
ssi-chatbot-report .table-container ssi-treetable ssi-paginator button.navigate-btn {
  display: flex;
  border: none;
  background-color: transparent;
  color: #14bae3;
}
ssi-chatbot-report .table-container ssi-treetable ssi-paginator .chevron {
  opacity: 1;
}
ssi-chatbot-report .table-container .order-number-cell {
  color: #101525;
  font-size: 14px;
  font-weight: 900;
  padding-left: 15px;
}
ssi-chatbot-report .section-tip-wrap {
  position: absolute;
  max-width: 80%;
  right: 0;
  top: -15px;
}
ssi-chatbot-report .section-tip {
  display: inline-flex;
  flex-direction: row-reverse;
  background-color: #fff;
  border-radius: 1.2em;
  padding: 1em;
  box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
}
ssi-chatbot-report .section-tip-icon {
  display: flex;
  flex: 0 0 3.6em;
  align-items: center;
  justify-content: center;
  height: 3.6em;
  width: 3.6em;
  background-color: #f4f4fa;
  border-radius: 50%;
  color: #14bae3;
  border: 0;
  outline: 0;
}
ssi-chatbot-report .section-tip-icon:active {
  background-color: #e2e2f2;
}
ssi-chatbot-report .section-tip-icon:hover + p {
  display: inline-flex !important;
}
ssi-chatbot-report .section-tip-icon i {
  height: 1.4rem;
  font-size: 1.8em;
}
ssi-chatbot-report .section-tip p {
  display: none;
  align-items: center;
  margin: 0 0.8em;
  color: #43537f;
  font-weight: 700;
  text-rendering: geometricPrecision;
}