@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../common/styles/colors.scss';

ssi-social-network-icon {
  .social-network-icon-container {
    display: flex;
    align-items: center;

    i {
      // color: $lmode-body-text;
    }
  }
}
