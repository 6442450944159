ssi-campaign-report ssi-report-sidebar .report-sidebar {
  width: 228px;
}
ssi-campaign-report ssi-report-sidebar .ssi-line-arrow-small-down {
  transform: rotate(90deg);
}
ssi-campaign-report .report-body {
  display: flex;
  flex-direction: column;
  background-color: #f4f4fa;
  width: 100%;
  height: 100%;
  padding-top: 134px;
  padding-left: 268px;
  padding-right: 40px;
  min-height: calc(100vh - 68px);
}
@media print {
  ssi-campaign-report .report-body {
    display: block;
  }
}
ssi-campaign-report .report-body.loading {
  background-color: #fff;
}
ssi-campaign-report hr {
  background-color: #fff;
  height: 2px;
  border: none;
  margin: 30px 0;
}
ssi-campaign-report .section-content {
  margin: 25px 0;
}
ssi-campaign-report h4 {
  color: #101525;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}
ssi-campaign-report .summaries-per-account-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
@media print {
  ssi-campaign-report .summaries-per-account-container {
    display: block;
    width: 100%;
  }
}
ssi-campaign-report .summaries-per-account-container ssi-account-summary-card {
  flex: 1 1 calc(50% - 20px);
}
ssi-campaign-report .summaries-per-account-container ssi-account-summary-card .asc {
  margin-bottom: 0;
}
ssi-campaign-report .summaries-per-account-container ssi-account-summary-card .asc-stats {
  justify-content: flex-end;
}
ssi-campaign-report .summaries-per-account-container ssi-account-summary-card .asc-stat-label::before {
  background-color: #14bae3;
}
@media (min-width: 1025px) {
  ssi-campaign-report .summaries-per-account-container ssi-account-summary-card {
    flex: 0 1 calc(15% - 20px);
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  ssi-campaign-report .summaries-per-account-container ssi-account-summary-card {
    margin: 10px;
  }
}
ssi-campaign-report .facts-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  overflow-x: auto;
}
@media print {
  ssi-campaign-report .facts-container {
    display: block;
  }
  ssi-campaign-report .facts-container > * {
    display: inline-block;
    width: auto !important;
  }
}
ssi-campaign-report .facts-container > * {
  width: 100%;
  padding: 7px;
  margin-left: 10px;
}
ssi-campaign-report .facts-container > *:first-child {
  margin-left: 0;
}
@media (max-width: 1299px) {
  ssi-campaign-report .facts-container > * .fact-overview {
    width: 172px !important;
  }
}