ssi-live-chat-offline-agent-notice {
  align-items: center;
  flex-flow: column nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  height: calc(100vh - 200px);
  justify-content: center;
  overflow: hidden;
}
ssi-live-chat-offline-agent-notice .encouragement {
  align-items: center;
  flex-flow: column nowrap;
  flex-shrink: 0;
  margin: 0 12% 2% 12%;
  text-align: center;
}
ssi-live-chat-offline-agent-notice .encouragement .encouragement-icon {
  color: #A4B5BE;
  font-size: 15vh;
}
ssi-live-chat-offline-agent-notice .encouragement .encouragement-message {
  color: #A4B5BE;
  font-size: 1.4em;
  font-weight: 600;
}
ssi-live-chat-offline-agent-notice .encouragement .encouragement-message > * {
  padding: 0 2.5px;
}
ssi-live-chat-offline-agent-notice .available-unassigned-chats,
ssi-live-chat-offline-agent-notice .unavailable-unassigned-chats {
  align-items: center;
  flex-flow: column nowrap;
  height: 100%;
  justify-content: center;
}
ssi-live-chat-offline-agent-notice .unavailable-unassigned-chats button,
ssi-live-chat-offline-agent-notice .unavailable-unassigned-chats button:hover {
  background-color: #F3C874;
  color: #fff;
}