@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';
@import '../../../../angularjs/common/sass/variables';
@import '../../../../angularjs/common/sass/mixins';
@import './publisher.variables.scss';
@import './publisher.mixins.scss';
@import '~/styles/colors';

$ssi-publisher-responsive-width: 885px;

ssi-publisher {
  color: $publisher-font-color;

  h1,
  h2,
  h3 {
    color: $publisher-font-color;
  }

  .publisher-height-spacer {
    height: $publisher-height;
  }

  .publisher-body-container {
    @media (max-width: $ssi-publisher-responsive-width) {
      width: 100vw;
      height: 100vh;
      overflow: auto !important;
      background-color: #f0f3f9;
    }
    @media (min-width: $ssi-publisher-responsive-width) {
      height: $publisher-height;
      width: 100%;
    }

    overflow: visible !important;
    position: fixed;
    top: $navbar-height;
    z-index: 1030 - 1; // so that top navbar dropdowns work
  }

  .publisher-body {
    background-color: $publisher-bg;

    @media (max-width: $ssi-publisher-responsive-width) {
      margin: 0;
    }

    @media (min-width: $ssi-publisher-responsive-width) {
      margin: 0 $publisher-sides-margin;
      box-shadow: $publisher-box-shadow;
    }

    height: 100%;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
  }

  // secondary views are used for the schedule / album views
  .publisher-secondary-view {
    display: flex;
    height: 100%;
    @media (max-width: $ssi-publisher-responsive-width) {
      flex-direction: column;
    }
  }

  .publisher-secondary-view-left {
    width: $publisher-secondary-view-left-width;
    padding-top: $publisher-secondary-view-margin-top;
    box-shadow: $publisher-box-shadow;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .publisher-secondary-view-left-body {
    padding: 0 $publisher-padding;
    flex: 1;
  }

  .publisher-secondary-view-right {
    flex: 1;
  }

  .publisher-btn-light {
    @include button-variant($primary-active, #fff, #fff);
  }

  .publisher-btn-gray {
    @include button-variant(#8d919f, #e1e3ea, #e1e3ea);
  }

  .publisher-text {
    @include publisher-text();
  }
}
