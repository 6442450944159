ssi-engagement-summary .engagement-summary-container .section-body {
  width: 100%;
}
ssi-engagement-summary .engagement-summary-container .section-body .facts-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  overflow-x: auto;
}
@media print {
  ssi-engagement-summary .engagement-summary-container .section-body .facts-row {
    display: block;
  }
  ssi-engagement-summary .engagement-summary-container .section-body .facts-row > * {
    display: inline-block;
    width: auto !important;
  }
}
ssi-engagement-summary .engagement-summary-container .section-body .facts-row > * {
  width: 100%;
  padding: 7px;
  margin-left: 10px;
}
ssi-engagement-summary .engagement-summary-container .section-body .facts-row > *:first-child {
  margin-left: 0;
}
ssi-engagement-summary ssi-fact-overview .fact-overview {
  box-shadow: 0 0.5em 1em rgba(131, 142, 171, 0.35);
  margin-bottom: 10px;
}