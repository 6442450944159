@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../angularjs/common/sass/variables';
@import '~/styles/colors';

$validate-post-box-responsive-width: 885px;

ssi-validate-posts-box {
  ssi-campaign-box {
    ul {
      list-style: none;
      padding: 0;

      li {
        margin: 0 0 1em 0;
      }
    }

    button {
      border-radius: 10px;

      i {
        float: left;
        padding: 3px;
      }
    }

    .settings-trigger {
      position: relative;
      z-index: 99;

      &:hover {
        color: white;
        background-color: $primary-blue;
      }
    }

    .campaign-settings {
      width: 100%;
      position: absolute;
      top: 77px;
      bottom: 0;
      background-color: #dfe5e8;

      .campaign-icon {
        top: -24px;
        position: relative;
      }
    }

    .campaign-setting-button {
      width: 200px;
      border-radius: 20px;
      text-align: right;
    }

    .campaign-children {
      padding: 1em;
      p {
        font-size: 40px;
      }
    }

    .campaign-select {
      position: absolute;
      width: 100%;
      top: 0;
      height: 100%;
      cursor: pointer;
    }

    .campaign-editor {
      position: absolute;
      left: 20px;
      right: 20px;
      top: 50%;
      display: flex;
      flex-direction: column;
      background-color: white;
      border-radius: 20px;
      padding: 20px;
      box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
      transform: (translateY(-50%));
      z-index: 99;

      select,
      input {
        width: 100%;
        background-color: #f6f8f9;
        border: none;
        border-radius: 20px;
        padding: 6px 14px;
      }
    }

    .select-wrapper {
      width: 100%;
      display: inline-block;
      padding: 6px 16px 6px 6px;
      background-color: #f6f8f9;
      border-radius: 20px;
    }
  }

  .post-box {
    position: relative;
    border-width: 0;
    padding-right: 50px;
    box-shadow: 0px 5px 10px 0px rgba(131, 142, 171, 0.2);
    overflow: hidden;
    margin-bottom: 20px;
    border-radius: 0;
    @media (max-width: $validate-post-box-responsive-width) {
      padding-right: 0px;
    }
  }

  .post-box-targeting-open {
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
  }

  .post-box-wrap {
    position: relative;
    padding: 20px 130px 20px 25px;
  }

  .post-box-header {
    margin-bottom: 10px;

    .btn {
      margin: 0 5px 5px 0;
    }
  }

  .post-box-panel-body {
    padding: 0;
  }

  .post-box-footer {
    padding: 15px 25px;
    border-radius: 0;
    background-color: #ebf0f2;
    border-top: 0;

    &.post-box-footer-selected {
      background-color: #494f5b;
    }
  }

  .post-box-validation {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99;
    opacity: 0.7;
    color: white;
    text-align: center;
    font-size: 24px;
    padding: 80px 0;

    a {
      font-size: 16px;
    }

    &.approved {
      background-color: cadetblue;
    }
    &.disapproved {
      background-color: coral;
    }
    &.boosted {
      background-color: black;
    }
  }
}
