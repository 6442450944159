ssi-engagement ssi-team-performance {
  font-size: 1rem;
}
ssi-engagement ssi-team-performance .section-tip-wrap {
  margin: 0 0 2em;
}
ssi-engagement ssi-team-performance .section-tip {
  display: inline-flex;
  background-color: #fff;
  border-radius: 1.2em;
  padding: 1em;
  box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
}
ssi-engagement ssi-team-performance .section-tip-icon {
  display: flex;
  flex: 0 0 3.6em;
  align-items: center;
  justify-content: center;
  height: 3.6em;
  width: 3.6em;
  background-color: #f4f4fa;
  border-radius: 50%;
  color: #14bae3;
  border: 0;
  outline: 0;
}
ssi-engagement ssi-team-performance .section-tip-icon:active {
  background-color: #e2e2f2;
}
ssi-engagement ssi-team-performance .section-tip-icon:hover + p {
  display: inline-flex !important;
}
ssi-engagement ssi-team-performance .section-tip-icon i {
  height: 1.4rem;
  font-size: 1.8em;
}
ssi-engagement ssi-team-performance .section-tip p {
  display: none;
  align-items: center;
  margin: 0 0.8em;
  color: #43537f;
  font-weight: 700;
  text-rendering: geometricPrecision;
}