ssi-stat-change .stat-change {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 900;
}
ssi-stat-change .stat-change i {
  margin-right: 3px;
  font-size: 10px;
}
ssi-stat-change .stat-change.hidden {
  display: block !important;
  visibility: hidden;
}
ssi-stat-change .stat-change.positive {
  color: #12aca4;
}
ssi-stat-change .stat-change.negative {
  color: #f88c68;
}
ssi-stat-change .stat-change.no-change {
  color: #838eab;
}
ssi-stat-change .stat-change.no-change i {
  font-size: 8px;
}
ssi-stat-change .stat-change.boxed {
  padding: 7px 10px;
  border-radius: 5px;
  background-color: #cffdfa;
  font-size: 20px;
}
ssi-stat-change .stat-change.boxed i {
  font-size: 14px;
}
ssi-stat-change .stat-change.boxed.positive {
  background-color: #eaf9f8;
}
ssi-stat-change .stat-change.boxed.negative {
  background-color: #fff1ed;
}
ssi-stat-change .stat-change.boxed.no-change {
  background-color: #eff2fd;
}
ssi-stat-change .stat-change.boxed.no-change i {
  font-size: 10px;
}
ssi-stat-change .stat-change.boxed.bottom {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}