ssi-live-chat-conversation-sidebar {
  background-color: #f9f9f9;
  display: flex;
  flex-basis: 200px;
  flex-flow: column nowrap;
  height: 100%;
  padding: 30px 20px;
  position: absolute;
  right: 0;
  transition: width 0.25s;
  width: 200px;
  z-index: 7;
}
ssi-live-chat-conversation-sidebar.is-fully-visible li.origin > dl > dd {
  white-space: normal !important;
}
ssi-live-chat-conversation-sidebar:hover {
  box-shadow: -3px 0 10px rgba(0, 0, 0, 0.175);
  width: 480px;
}
ssi-live-chat-conversation-sidebar * > * {
  display: flex;
}
ssi-live-chat-conversation-sidebar ssi-live-chat-avatar {
  align-self: center;
  border-radius: 50%;
  height: 80px;
  width: 80px;
}
ssi-live-chat-conversation-sidebar ssi-live-chat-avatar .inner img {
  border: solid 3px #fff;
}
ssi-live-chat-conversation-sidebar ssi-live-chat-avatar .inner .verified {
  font-size: 15px;
  padding: 7.5px;
  top: 0;
}
ssi-live-chat-conversation-sidebar .primary-details {
  align-items: center;
  flex-flow: column nowrap;
  flex-shrink: 0;
  font-size: 1em;
  font-style: italic;
  font-weight: 400;
  justify-content: center;
  margin: 15px 0;
}
ssi-live-chat-conversation-sidebar .primary-details .network-state {
  text-transform: capitalize;
}
ssi-live-chat-conversation-sidebar .verified {
  color: #115d87;
}
ssi-live-chat-conversation-sidebar .toggle {
  background: transparent;
  border: none;
  color: #a6b7c0;
  position: fixed;
  right: 20px;
}
ssi-live-chat-conversation-sidebar .toggle:focus {
  outline: revert;
}
ssi-live-chat-conversation-sidebar .inner {
  flex-flow: column nowrap;
  overflow-x: hidden;
  overflow-y: auto;
}
ssi-live-chat-conversation-sidebar .inner dl.details {
  flex-flow: column nowrap;
  flex-shrink: 0;
}
ssi-live-chat-conversation-sidebar .inner dl.details.expanded > dd {
  display: flex;
}
ssi-live-chat-conversation-sidebar .inner dl.details > dd {
  display: none;
  flex-shrink: 0;
  padding: 20px;
}
ssi-live-chat-conversation-sidebar .inner dl.details > dd ul {
  color: #556c78;
  flex-flow: column nowrap;
  flex-grow: 1;
  flex-shrink: 1;
  font-size: 0.8em;
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
}
ssi-live-chat-conversation-sidebar .inner dl.details > dd ul > li {
  display: inline;
  flex-shrink: 0;
  margin: 0 0 5px 0;
  overflow: hidden;
  padding: 5px 0 0 0;
}
ssi-live-chat-conversation-sidebar .inner dl.details > dd ul > li + li {
  border-top: solid 2px #fff;
}
ssi-live-chat-conversation-sidebar .inner dl.details > dd ul > li > dl {
  flex-flow: column nowrap;
  margin: 0;
  padding: 0;
}
ssi-live-chat-conversation-sidebar .inner dl.details > dd ul > li > dl > dt {
  font-style: italic;
  font-weight: 400;
}
ssi-live-chat-conversation-sidebar .inner dl.details > dd ul > li > dl > dd {
  display: inline;
  font-weight: 600;
  hyphens: auto;
  overflow-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-word;
  word-wrap: break-word;
}
ssi-live-chat-conversation-sidebar .inner dl.details > dt {
  background: #fff;
  border: none;
  border-radius: 20px;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.04);
  color: #576e78;
  cursor: pointer;
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 0.85em;
  font-weight: 600;
  justify-content: center;
  padding: 3px 20px;
}
ssi-live-chat-conversation-sidebar .inner dl.details > dt.block {
  background: #fff1ed;
  border: 1px solid #f88c68;
}
ssi-live-chat-conversation-sidebar ssi-map agm-map {
  width: 100%;
}
ssi-live-chat-conversation-sidebar ssi-map agm-map .gm-bundled-control {
  display: none;
}