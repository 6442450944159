@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../../angularjs/common/sass/include-media';
@import '~/styles/colors';

$margin-between-fields: 10px;

ssi-campaign-list {
  .campaigns-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: $lgrey-background;
    box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.25);
    h1 {
      font-size: 14px;
      font-weight: 900;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $lmode-title-text;
    }
  }

  .heading-info-container {
    display: flex;
    margin: 20px 20px 0 20px;
    .page-description {
      flex: 1 1 60%;
      display: flex;
      background-color: #fff;
      border-radius: 12px;
      .text {
        padding: 40px 20px 40px 50px;
      }
      h2 {
        font-weight: 900;
        font-size: 20px;
        color: $lmode-body-text;
      }
      p {
        font-weight: bold;
        color: $lmode-body-text;
        line-height: 20px;
      }
    }
    img {
      width: auto;
      height: 144px;
      align-self: flex-end;
    }

    @include media('<tablet') {
      flex-wrap: wrap;
    }
  }

  .edit-create-campaign {
    padding: 20px;
    border-radius: 15px;
    background-color: #fff;
    margin: 20px;

    form {
      display: flex;
      flex-wrap: wrap;

      input {
        background-color: $grey-background;
        border-color: $grey-background;
        color: $lmode-title-text;
      }
      ssi-dropdown-select-2 {
        button.dd-head.variant-analytics {
          color: $lmode-title-text;
          font-size: 14px;
          width: 100%;
          height: 46px;
        }
        .dd-body {
          width: 100%;
        }
      }
    }

    .form-field {
      flex: 1 1 calc(20% - 2 * #{$margin-between-fields});
      margin-right: $margin-between-fields;
      @include media('<desktop') {
        flex-basis: calc(50% - #{$margin-between-fields});
      }
      @include media('<tablet') {
        flex-basis: 100%;
      }
      &.name {
        flex-basis: 30%;
      }
    }

    .date-picker-input {
      width: 100%;
      height: 46px;
      border: none;
      outline: none;
      border-radius: 100px;
      display: flex;
      align-items: center;
      color: $lmode-title-text;
      font-size: 14px;
      font-weight: 900;
      background-color: $grey-background;
      padding: 10px 30px;

      &::placeholder {
        color: $lmode-helper-text;
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
      }
    }

    label {
      width: 100%;
      padding: 0 3em;
      display: block;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 900;
      line-height: 2.5em;
      color: $lmode-body-text;
      letter-spacing: 1px;
      box-sizing: border-box;
    }

    .form-actions-container {
      display: flex;
      align-items: flex-end;
      margin: 0 0 10px 30px;
      .form-action {
        display: flex;
        margin-left: 5px;
      }

      @include media('<desktop') {
        width: 100%;
        justify-content: center;
        margin-top: 2 * $margin-between-fields;
      }
    }
  }

  .campaigns-overview-container {
    flex: 1 1 40%;
    display: flex;
    .campaigns-stats-card {
      background-color: #fff;
      padding: 20px;
      border-radius: 12px;
      margin-left: 20px;
      flex: 1 1 50%;
      .upper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        i {
          font-size: 20px;
          padding: 13px;
          border-radius: 50%;
          &.live {
            color: #b0c600;
            background-color: #f0f4d0;
          }
          &.completed {
            color: #f0b427;
            background-color: #fbf3e2;
          }
        }
      }
      span {
        font-size: 35px;
        color: $lmode-title-text;
        font-weight: bold;
      }
      h4 {
        font-size: 10px;
        font-weight: bold;
        color: $lmode-helper-text;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin-top: 5px;
        margin-right: 5px;
      }

      @include media('<tablet') {
        &:first-child {
          margin-left: 0;
        }
      }
    }

    @include media('<tablet') {
      margin-top: 20px;
    }
  }

  .tabs-container {
    padding: 20px;

    .tabs {
      display: flex;
      > div {
        position: relative;
        background-color: $grey-background;
        padding: 25px 50px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        margin-right: 10px;
        letter-spacing: 1px;
        color: $lmode-body-text;
        font-size: 10px;
        text-transform: uppercase;
        font-weight: 900;
        box-shadow: 5px 0 10px -5px rgba(131, 142, 171, 0.1);

        &:hover {
          cursor: pointer;
        }
        &::before {
          content: '';
          transform: scaleX(0);
          visibility: hidden;
          position: absolute;
          bottom: 7px;
          height: 2px;
          width: 30%;
          left: 35%;
          background-color: #b2c614;
          transition: all 0.3s ease-in-out 0s;
        }
        &.active,
        &:hover {
          &:before {
            transform: scaleX(1);
            visibility: visible;
          }
          background-color: #fff;
        }
        &.completed {
          &::before {
            background-color: #f0b427;
          }
        }
      }
    }

    .table-container {
      padding: 20px;
      background-color: #fff;
      border-radius: 0 15px 15px 15px;
      // box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.25);

      ssi-treetable {
        .content {
          .head {
            .row {
              .cell {
                height: 50px;
                background-color: #fff;
                text-transform: uppercase;
                font-weight: 900;
                font-size: 10px;
                color: $lmode-body-text;
                padding-left: 30px;
                border-bottom: 2px solid #e0e7f4;
              }
            }
          }
        }
      }

      .table-cell {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        border-bottom: 2px solid #e0e7f4;
        padding-left: 30px;
      }

      .table-head-cell {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
      }

      .campaign-name {
        display: flex;
        align-items: center;
        color: $lmode-body-text;
        font-size: 12px;
        font-weight: 900;
        min-width: 0;
        small {
          color: #e0e7f4;
        }
        i {
          margin-right: 10px;
          color: $lmode-body-text;
        }
        span {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      .actions-cell {
        display: flex;
        justify-content: flex-end;
        button {
          span {
            white-space: nowrap;
          }
        }
      }

      .date-cell {
        .date-value {
          color: $lmode-title-text;
          font-weight: 900;
        }
        .no-date {
          font-weight: bold;
          color: $lmode-body-text;
          font-style: italic;
        }
      }

      .paginator-container {
        background-color: #fff;
        border-radius: 0 0 15px 15px;
        height: 80px;
        padding: 40px;
      }

      .parent {
        background-color: $grey-background;
      }

      .filter-container {
        // width: 100%;
        width: 205px;
        padding: 0 10px 10px 10px;
        position: relative;

        &.extend-width {
          width: 250px;
        }

        input.form-control.form-control-minimal.filter {
          // border-bottom: 2px solid $lmode-border-color;
          padding-left: 5px;
          padding-right: 20px;
          color: $lmode-title-text;
          font-style: normal;
          font-size: 14px;
          font-weight: 700;
          border: 0;
          text-transform: capitalize;

          &::placeholder {
            font-size: 14px;
            font-weight: 700;
            font-style: normal;
            color: $lmode-helper-text;
          }
        }

        i {
          position: absolute;
          color: $lmode-body-text;
          top: 10px;
          right: 10px;
          font-size: 14px;
          font-weight: 700;

          &.active {
            color: $primary-active;
          }
        }
      }
    }
  }

  orlo-button {
    margin-right: 10px;
    button {
      display: flex;
      align-items: center;
      i {
        margin-right: 5px;
        font-size: 12px !important;
      }
    }
  }

  .confirm-action-tooltip-window {
    border-radius: 12px;
  }
}
