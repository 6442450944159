ssi-stream-card {
  font-size: 1rem;
}
ssi-stream-card .stream-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2.8em 1.5em 4.5em;
  text-align: center;
  height: 36em;
  margin: 0 auto;
  border: 0.1em solid #89a4ea;
  border-radius: 1.2em;
  background-color: #fff;
  color: #43537f;
  overflow: hidden;
}
ssi-stream-card .stream-card-header p {
  font-size: 1em;
  color: #838eab;
  font-weight: 900;
  text-rendering: geometricPrecision;
  margin: 0;
}
ssi-stream-card .stream-card-header-action {
  display: inline-block;
  height: 3.6em;
  width: 3.6em;
  border-radius: 50%;
  margin: 2.4em 0 0;
  border-width: 0;
  background-color: #f8f9fd;
}
ssi-stream-card .stream-card-header-action:hover, ssi-stream-card .stream-card-header-action:focus {
  background-color: #eceffa;
}
ssi-stream-card .stream-card-header-action:active {
  background-color: #e0e5f6;
}
ssi-stream-card .stream-card-header-action i {
  color: #838eab;
  font-size: 1.4em;
}
ssi-stream-card .stream-card-body {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: space-between;
  color: #43537f;
  margin: 0.8em 0 0;
}
ssi-stream-card .stream-card-body h3 {
  font-size: 1.4em;
  font-weight: 900;
  text-rendering: geometricPrecision;
  margin: 0 0 0.4em;
}
ssi-stream-card .stream-card-body p {
  font-size: 1em;
  line-height: 1.4em;
  font-weight: 700;
  text-rendering: geometricPrecision;
  margin: 0;
}
ssi-stream-card .stream-card-body .print-query-comparison {
  display: inline-block;
  width: 100%;
  line-height: 2em;
  background-color: #fff;
  z-index: 2;
}
ssi-stream-card .stream-card-body .print-query-comparison:before, ssi-stream-card .stream-card-body .print-query-comparison:after {
  content: "";
  z-index: 1;
  display: inline-block;
  vertical-align: middle;
  width: 6em;
  border-bottom: 0.1em solid #43537f;
  opacity: 0.6;
}
ssi-stream-card .stream-card-body .print-query-comparison:before {
  left: 0;
}
ssi-stream-card .stream-card-body .print-query-comparison:after {
  right: 0;
}
ssi-stream-card .stream-card-body-content {
  height: 10em;
  overflow: hidden;
  padding-bottom: 1em;
}
ssi-stream-card .stream-card-body-content p {
  word-break: break-all;
}
ssi-stream-card .stream-sources {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 0 3em;
  padding: 0;
  height: 3em;
}
ssi-stream-card .stream-sources-break {
  flex: 1 0 auto;
  list-style: none;
}
ssi-stream-card .stream-source {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3em;
  width: 3em;
  border-radius: 50%;
  margin: 0 -0.3em;
}
ssi-stream-card .stream-source i {
  font-size: 1.6em;
  color: #fff;
}
ssi-stream-card .stream-source-more {
  width: 100%;
  padding: 0.3em 0 0;
  cursor: default;
}
ssi-stream-card .stream-source-more p {
  display: inline-block;
}
ssi-stream-card orlo-button.stream-card-footer-action button {
  padding: 7px 22px 10px;
}
ssi-stream-card orlo-button.stream-card-footer-action .leading-icon {
  font-size: 0.8em;
}
ssi-stream-card orlo-button.stream-card-footer-action .leading-icon + span {
  margin: 0 0 0 5px;
  vertical-align: middle;
}