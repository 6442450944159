@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-outbox-post {
  .outbox-post-container {
    position: relative;
    display: flex;
    align-items: center;
    height: 160px;
    padding: 0 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.2);
    width: 100%;

    .view-post-link {
      position: absolute;
      top: 0;
      left: 0;
      border-top-left-radius: 10px;
      border-bottom-right-radius: 10px;
      background-color: #eff2fd;
      padding: 5px 25px;

      a {
        color: $lmode-body-text;
        font-size: 12px;
        font-weight: 900;
        line-height: 14px;

        @media print {
          &:after {
            content: none !important;
          }
        }
      }
    }

    .left {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      margin-right: 15px;

      img {
        height: 50px;
        width: 50px;
        border-radius: 50%;
      }
    }

    .middle {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      margin-right: 15px;

      .metadata {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        > * {
          margin-right: 10px;
        }

        a {
          color: $primary-active;
          font-size: 16px;
          font-weight: 700;
          line-height: 19px;

          @media print {
            &:after {
              content: none !important;
            }
          }
        }

        .icon-container {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $grey-background;
          border-radius: 50%;
          width: 20px;
          height: 20px;

          i {
            color: $primary-active;
            font-size: 12px;
          }
        }

        .timestamp {
          color: $lmode-body-text;
          font-size: 10px;
          font-weight: 900;
          line-height: 24px;
        }
      }

      .content {
        p {
          margin: 0;
          max-height: 45px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;

          color: $lmode-title-text;
          font-size: 14px;
          font-weight: 700;
          line-height: 24px;
        }
      }
    }

    .right {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      margin-right: 45px;

      .gallery {
        position: relative;

        img,
        .video-icon-container {
          height: 80px;
          width: 120px;
          border-radius: 10px;
          box-shadow: 0 3px 5px 0 rgba(131, 142, 171, 0.35);

          &.stacked {
            position: absolute;
            // top: ngStyle
            // left: ngStyle
          }
        }

        .video-icon-container {
          display: flex;
          align-items: center;
          justify-content: center;
          color: $primary-active;
          background-color: $grey-background;

          i {
            font-size: 60px;
          }
        }

        .media-items-count {
          position: absolute;
          right: -20px;
          top: 45%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #fff;
          border-radius: 50%;
          width: 25px;
          height: 25px;
          color: $primary-active;
          font-size: 10px;
          font-style: italic;
          font-weight: 900;
          line-height: 12px;
          box-shadow: 0 5px 15px 0 rgba(116, 118, 132, 0.25);
        }
      }
    }

    ssi-outbox-post-menu {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 1;
    }
  }
}
