@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../angularjs/common/sass/mixins';
@import '../../../../../../angularjs/common/sass/variables';
@import '../../publisher.variables.scss';
@import '~/styles/colors';

$publisher-targeting-nav-item-dimensions: 90px;
$publisher-targeting-nav-item-bg: #e1e3ea;
$publisher-targeting-nav-item-text: #9195a3;
$publisher-targeting-nav-item-active-bg: #fff;
$publisher-targeting-nav-item-active-text: $primary-active;
$unit: 2.5px;

ssi-publisher-targeting {
  display: flex;
  flex-direction: column;
  background-color: #fff;

  .publisher-targeting-header {
    padding: 20px 20px 15px 20px;
    align-items: center;
    .btn {
      margin-left: 3px;
      width: 140px;
    }
  }

  .publisher-targeting-body {
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1;
    box-shadow: inset 0 -17px 14px -14px #eceff6,
      inset 0 17px 14px -14px #eceff6;
    padding: 10px 30px;
    margin: 0 3px;

    label {
      font-size: 10px;
      letter-spacing: 1px;
      margin-top: 10px;
      margin-left: 15px;
      color: $lmode-body-text;
      font-weight: 900;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-transform: uppercase;
    }

    ssi-outbox-typeahead {
      .choices-container {
        .choices {
          background: $lmgrey-background;
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
          width: 100%;
          li {
            color: #fff;
            background-color: $primary-active;
            font-style: normal;
            max-width: 140px;
            &:after {
              color: #fff;
            }
          }
        }

        input {
          background: $lmgrey-background;
          border: none;
          border-radius: 20px;
          font-style: normal;
          padding: 10px 15px;
          &::placeholder {
            color: $publisher-input-placeholder-color;
            font-weight: bold;
          }
        }
      }

      .suggestions {
        box-shadow: -1px 0px 7px 2px rgba(131, 142, 171, 0.35);
        border-radius: 20px;
        max-height: 160px;
      }

      .suggestions-filter {
        text-transform: none;
      }

      &.has-value {
        .suggestions-filter {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
      }
    }

    .row {
      margin-top: 10px;
    }
  }

  .publisher-targeting-footer {
    display: flex;
    align-items: center;
    padding: 20px 30px;

    button {
      width: 240px;
      background-color: $primary-active;
      border-radius: 20px;
      padding: 8px 12px;
      color: #fff;
      border: none;
      font-weight: normal;
    }
    span {
      display: flex;
      align-items: center;
      color: $primary-active;
      font-weight: 900;
      font-size: 11px;
      letter-spacing: 1px;
    }
    i {
      margin-right: 5px;
      font-size: 16px;
    }

    button,
    span {
      margin-right: auto;
    }
  }

  .tab-nav {
    position: relative;
    text-transform: uppercase;
    text-decoration: none;
    color: darken($publisher-input-placeholder-color, 20%);
    margin-left: 20px;
    font-weight: 900;
    font-size: 10px;
    letter-spacing: 1px;

    &:focus {
      outline: revert;
    }

    &:before {
      visibility: hidden;
      transform: scaleX(0);
      visibility: visible;
      content: '';
      position: absolute;
      top: 22px;
      height: 2px;
      width: 80%;
      left: 7%;
      background-color: $primary-active;
      transition: all 0.3s ease-in-out 0s;
    }
    &.active,
    &:hover {
      position: relative;
      &:before {
        visibility: visible;
        transform: scaleX(1);
      }
    }

    &.exit {
      background: none;
      border: none;
      color: $primary-active;
      font-style: normal;

      &:before {
        visibility: hidden;
      }
    }
  }

  ssi-dropdown-select-2 {
    .variant-analytics {
      width: 100%;
    }
    .button-icon {
      top: 14px;
    }
    .dd-body {
      width: 100% !important;
    }

    ul {
      li {
        position: relative;
        .tick-container {
          i {
            top: 3px;
          }
        }
      }
    }
  }

  .boundary-chips {
    text-align: left;
    margin: 0 0 -10px -10px;
    color: #fff;
    margin: 0;
    padding: 1em 0 0 10px;
    li {
      float: left;
      position: relative;
    }

    span {
      padding: 6px 26px 6px 8px;
      font-size: 10px;
      margin: 0 4px 8px 0;
      background-color: $primary-active;
      border-radius: 100px;
      display: inline-flex;
      align-items: center;
      text-rendering: geometricPrecision;
      word-break: break-all;
      font-weight: 900;
    }

    .ssi {
      position: absolute;
      width: 8px;
      height: 8px;
      font-size: 8px;
      right: 12px;
      top: 9px;
      cursor: pointer;
    }
  }
}

$targeting-inactive-color: $lmode-helper-text;
$targeting-active-color: $lmode-title-text;

.dropdown-menu-container {
  display: flex;
  flex-flow: column nowrap;

  .dropdown-menu {
    border-radius: 20px;
    box-shadow: 0 0.1em 1.5em 0 rgba(193, 193, 193, 0.65);
    border: 1px solid #f9f9f9;

    button {
      background-color: #fff;
      border-radius: 20px;

      &:hover {
        color: $primary-active;
        background: #f6f7f8;
      }
    }
  }

  .dropdown-menu-trigger {
    background: $lmgrey-background;
    border: none;
    border-radius: 20px;
    color: darken($publisher-input-placeholder-color, 20%);
    font-weight: bold;
    display: flex;
    max-width: 100% !important;
    padding: 10px 15px;
    position: relative;
    text-align: left;

    .trigger-icon {
      color: $primary-blue;
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      position: absolute;
      right: ($unit * 4);
      top: ($unit * 5);
    }

    &:focus,
    &:active {
      border-bottom: 0 !important;
    }

    &:focus {
      outline: revert;
    }

    &.has-value {
      & > span {
        color: darken($targeting-active-color, 20%);
        font-style: normal;
        font-weight: 900;
      }
    }

    & > span,
    & > ul > li {
      font-family: $font-family-sans-serif !important;
      letter-spacing: normal !important;
    }

    & > ul {
      left: ($unit * 10) !important;
      max-height: 200px !important;
      overflow-x: hidden;
      overflow-y: auto;
      position: absolute;
      top: -($unit * 20) !important;
      transform: none !important;
      z-index: 1030 !important;

      & > li {
        border: none;
        justify-content: flex-start;
        margin: 0;
        padding: 0;

        & > button {
          border: none;
          display: flex;
          font-size: 0.9em;
          font-style: normal;
          height: ($unit * 12) !important;
          margin: 0;
          padding: ($unit * 2) ($unit * 8) !important;
          width: 100%;
          color: darken($publisher-targeting-nav-item-text, 20%);
        }
      }
    }
  }

  .select-range {
    display: flex;
    justify-content: space-between;

    button {
      width: 100%;
      color: darken($publisher-targeting-nav-item-text, 20%);

      &:first-child {
        margin-right: 5px;
      }

      &:focus {
        outline: revert;
      }
    }
  }

  label {
    margin-bottom: 10px;
  }
}
