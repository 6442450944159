@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../../angularjs/common/sass/variables';
@import '~/styles/colors';

.dissaprove-posts {
  p {
    margin: 0 0 1em 0;
  }
  textarea {
    display: block;
    width: 100%;
    height: 200px;
    margin: 0 0 20px;
    border-radius: 12px;
    border: none;
    padding: 1em;
    font-weight: 500;
  }
  label {
    margin: 0 0 1em 0;
  }

  .tag-selector {
    margin-top: 1em;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 2em 0;

    .dd-container,
    button.dd-head {
      width: 100%;
      height: auto;
    }
    button.dd-head {
      border-radius: 12px;
      background-color: white;
    }

    .select-tags-placeholder {
      color: $lmode-helper-text;
      font-size: 14px;
      font-weight: 700;
    }

    .select-tag-labels {
      text-align: left;
      margin: 0 0 -10px -10px;
      color: #fff;

      span {
        padding: 6px 8px;
        font-size: 10px;
        margin: 0 4px 8px 0;
        background-color: $primary-active;
        border-radius: 100px;
        display: inline-flex;
        align-items: center;
        text-rendering: geometricPrecision;
        word-break: break-all;

        &:hover {
          background-color: $secondary-red;
        }
      }

      .ssi {
        margin: 0 0 0 7px;
        font-size: 8px;
      }
    }
  }

  .buttons-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
