@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../angularjs/common/sass/variables';
@import '~/styles/colors';

$validate-post-box-responsive-width: 885px;

ssi-validate-reply-box {
  .post-box {
    position: relative;
    border-width: 0;
    // padding-right: 50px;
    box-shadow: 0px 5px 10px 0px rgba(131, 142, 171, 0.2);
    overflow: hidden;
    margin-bottom: 20px;
    border-radius: 0;
    @media (max-width: $validate-post-box-responsive-width) {
      padding-right: 0px;
    }
  }

  .post-box-targeting-open {
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
  }

  .post-box-wrap {
    position: relative;
    padding: 20px 20px 20px 25px;
  }

  .post-box-header {
    margin-bottom: 10px;

    .btn {
      margin: 0 5px 5px 0;
    }
  }

  .post-box-panel-body {
    padding: 0;
  }

  .post-box-footer {
    padding: 15px 25px;
    border-radius: 0;
    background-color: #ebf0f2;
    border-top: 0;

    &.post-box-footer-selected {
      background-color: #494f5b;
    }
  }

  .post-box-validation {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99;
    opacity: 0.7;
    color: white;
    text-align: center;
    font-size: 24px;
    padding: 80px 0;

    a {
      font-size: 16px;
    }

    &.approved {
      background-color: cadetblue;
    }
    &.disapproved {
      background-color: coral;
    }
    &.boosted {
      background-color: black;
    }
  }
}
