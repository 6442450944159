@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../angularjs/common/sass/variables';
@import '../../../../../angularjs/common/sass/mixins';
@import '~/styles/colors';

ssi-publisher-social-network-preview-modal {
  $publisher-social-network-preview-modal-color: #a3b5c0;

  .modal-close-icon {
    display: flex;
    align-self: flex-end;
    justify-content: flex-end;
    color: $publisher-social-network-preview-modal-color;
    position: relative;
    background-color: #eff2f8;
    padding: 8px;
    border-radius: 50px;
    border: 0;
    z-index: 1;

    i {
      color: $lmode-helper-text;
    }
  }

  .modal-header {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 10px 10px 30px 40px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .modal-body {
    padding: 20px 10px;
    text-align: center;
    background-color: #fff;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow: inset 0 -17px 14px -14px rgba(131, 142, 171, 0.35),
      inset 0 17px 14px -14px rgba(131, 142, 171, 0.35);
  }

  .heading-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 30px;
  }

  .content-left {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    h3,
    h5 {
      width: 100%;
      margin: 5px 0;
    }
    h3 {
      color: $lmode-body-text;
      font-weight: 900;
      font-size: 20px;
    }
    h5 {
      color: darken($lmode-helper-text, 20%);
      font-weight: 700;
    }
  }

  .dropdown {
    .dropdown-toggle {
      display: flex;
      align-items: center;
      width: 100%;
      background-color: #eff2f8;
      border-radius: 10px;
      border: none;
      padding: 12px;
      color: $primary-active;
      font-size: 12px;
      font-weight: bold;
      white-space: nowrap;
      span {
        width: 100%;
        margin: 0 2px;
      }
    }
    .dropdown-menu {
      border-radius: 10px;
      padding: 10px;
      a {
        color: $lmode-body-text;
        background: none;
        font-weight: bold;
        border-radius: 10px;
        margin-bottom: 5px;
        &:hover {
          background-color: $grey-background;
        }
        &.active {
          color: #fff;
        }
      }
    }
  }

  ul {
    display: flex;
    margin: 0;
    padding: 0;
  }

  .tab-nav {
    list-style: none;
    position: relative;
    height: 30px;
    text-transform: uppercase;
    text-decoration: none;
    margin: 0 25px 0 0;
    font-weight: 900;
    font-size: 10px;
    letter-spacing: 1px;
    color: $lmode-helper-text;

    &:focus {
      outline: revert;
    }

    &:before {
      visibility: hidden;
      transform: scaleX(0);
      visibility: visible;
      content: '';
      position: absolute;
      top: 22px;
      height: 4px;
      width: 100%;
      background-color: $secondary-blue;
      transition: all 0.3s ease-in-out 0s;
    }
    &.active,
    &:hover {
      position: relative;
      color: $lmode-title-text;
      cursor: pointer;
      &:before {
        visibility: visible;
        transform: scaleX(1);
      }
    }
  }

  .social-network-preview-header {
    color: $publisher-social-network-preview-modal-color;
    margin-bottom: 30px;
  }

  .social-network-preview-body {
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    text-align: left;
  }

  .generic-message {
    color: $lmode-helper-text;
    text-align: center;
    margin: 50px 0;
    i {
      font-size: 30px;
      margin-bottom: 5px;
    }
  }

  .social-network-preview-nav,
  .social-network-preview-footer {
    width: 100%;
  }

  .social-network-preview-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .social-network-preview-footer :first-child {
    margin-right: calc(calc($section-margin-width / 2));
  }

  .social-network-preview-footer :nth-child(2) {
    margin-left: calc(calc($section-margin-width / 2));
  }

  .social-network-preview-window {
    margin: 20px 0;
  }
}
