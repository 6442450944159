@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../angularjs/common/sass/include-media';
@import '../../../../../../angularjs/common/sass/mixins.scss';
@import '~/styles/colors';

$margin-between-boxes: 20px;
$headerHeight: 94px;
$sidebarWidth: 228px;

ssi-chatbot-report {
  ssi-report-sidebar {
    .report-sidebar {
      width: $sidebarWidth;
    }

    .ssi-line-arrow-small-down {
      transform: rotate(90deg);
    }
  }

  .report-body {
    display: flex;
    flex-direction: column;
    background-color: $lgrey-background;
    width: 100%;
    height: 100%;
    padding-top: 134px;
    padding-left: 268px;
    padding-right: 40px;
    min-height: calc(100vh - 68px);
    &.loading {
      background-color: #fff;
    }
  }

  .report-body-loading {
    width: 100%;
    height: 100%;
    height: calc(100vh - 68px);
    padding-top: $headerHeight;
    padding-left: $sidebarWidth;
  }

  hr {
    background-color: #fff;
    height: 2px;
    border: none;
    margin: 30px 0;
  }

  .section-content {
    margin: 25px 0;
  }

  .section-heading {
    position: relative;
  }

  h4 {
    color: $lmode-title-text;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .summaries-per-account-container {
    display: flex;
    flex-wrap: wrap;
    gap: $margin-between-boxes;

    ssi-account-summary-card {
      flex: 1 1 calc(50% - #{$margin-between-boxes});
      .asc {
        margin-bottom: 0;
      }
      .asc-stats {
        justify-content: flex-end;
      }

      .asc-stat-label {
        &::before {
          background-color: $primary-active;
        }
      }

      @include media('>desktop') {
        flex: 0 1 calc(15% - #{$margin-between-boxes});
      }
      @include ie-only {
        margin: calc($margin-between-boxes / 2);
      }
    }
  }

  .facts-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    overflow-x: auto;
    > * {
      width: 100%;
      padding: 7px;
      margin-left: 10px;
      &:first-child {
        margin-left: 0;
      }
      .fact-overview {
        @include media('<1300px') {
          width: 172px !important;
        }
      }
    }
  }

  // table styles
  .table-container {
    ssi-treetable {
      .row-even {
        background-color: $grey-background;
      }

      .content {
        background-color: #fff;
        border-radius: 1.2em;
        padding: 3em 2.6em 4.6em;
        border-radius: 0 15px 0 0;
        box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
      }

      .body-inner {
        .row {
          position: relative;

          &:nth-child(2n) {
            background-color: $grey-background;

            &:before,
            &:after {
              content: '';
              position: absolute;
              top: 0;
              width: 100%;
              height: 0.2em;
              border-radius: 10em;
              background-color: #e0e7f4;
              z-index: 1;
            }

            &:after {
              top: auto;
              bottom: 0;
            }
          }
        }

        .cell {
          display: flex;
          align-items: center;

          p {
            color: $lmode-title-text;
            font-weight: 900;
            text-rendering: geometricPrecision;
            font-size: 1.4em;
            padding: 0 0.6em 0 1em;
            margin: 0;
          }
        }

        .body-cell-primary {
          display: flex;
          justify-content: flex-start;
          align-items: stretch;
          text-rendering: geometricPrecision;
          width: 500px;

          i {
            display: inline-flex;
            align-items: center;
            color: $lmode-helper-text;
            margin: 0 1em 0 0.7em;

            &:before {
              font-size: 1.2em;
            }
          }

          p {
            padding: 0;
            display: inline-block;
            color: $lmode-body-text;
            font-size: 1.2em;
            font-weight: 900;
            margin: 0;
          }
        }
      }

      .paginator-container {
        background-color: #fff;
        border-radius: 0 0 15px 15px;
        height: 80px;
        padding: 15px;
      }

      ssi-paginator {
        display: flex;
        justify-content: center;
        font-size: 1.4em;

        button {
          &.page-link {
            display: flex;
            align-items: center;
            font-weight: 900;
            color: $lmode-body-text;
            background-color: transparent;
            margin: 0 10px;
            &.current {
              font-weight: 900;
              color: $primary-active;
              background-color: transparent;
              opacity: 1;
              position: relative;
              &::before {
                content: '';
                position: absolute;
                top: 23px;
                left: -2px;
                border-radius: 15px;
                display: block;
                height: 4px;
                width: 26px;
                background-color: $primary-active;
              }
            }
          }
          &.navigate-btn {
            display: flex;
            border: none;
            background-color: transparent;
            color: $primary-active;
          }
        }

        .chevron {
          opacity: 1;
        }
      }
    }

    .order-number-cell {
      color: $lmode-title-text;
      font-size: 14px;
      font-weight: 900;
      padding-left: 15px;
    }
  }

  .section-tip-wrap {
    position: absolute;
    max-width: 80%;
    right: 0;
    top: -15px;
  }

  .section-tip {
    display: inline-flex;
    flex-direction: row-reverse;
    background-color: #fff;
    border-radius: 1.2em;
    padding: 1em;
    box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);

    &-icon {
      display: flex;
      flex: 0 0 3.6em;
      align-items: center;
      justify-content: center;
      height: 3.6em;
      width: 3.6em;
      background-color: $lgrey-background;
      border-radius: 50%;
      color: $primary-active;
      border: 0;
      outline: 0;

      &:active {
        background-color: darken($lgrey-background, 5%);
      }

      &:hover + p {
        display: inline-flex !important;
      }

      i {
        height: 1.4rem;
        font-size: 1.8em;
      }
    }

    p {
      display: none;
      align-items: center;
      margin: 0 0.8em;
      color: $lmode-body-text;
      font-weight: 700;
      text-rendering: geometricPrecision;
    }
  }
}
