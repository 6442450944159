ssi-live-chat-notes {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  min-height: 0;
}
ssi-live-chat-notes * {
  display: flex;
}
ssi-live-chat-notes button {
  align-items: center;
  background: #fff;
  border: none;
  border-radius: 30px;
  color: #A6B7C0;
  cursor: pointer;
  font-weight: 500;
  padding: 10px 20px;
}
ssi-live-chat-notes button > .button-icon {
  padding-right: 0.5em;
}
ssi-live-chat-notes button > .button-text {
  font-style: italic;
}
ssi-live-chat-notes .live-chat-notes-header {
  align-items: center;
  background-color: rgb(216, 225, 229);
  box-sizing: border-box;
  color: #fff;
  flex-shrink: 0;
  height: 72px;
  justify-content: space-between;
  line-height: 20px;
  list-style: none;
  margin: 0;
  padding: 0 20px;
}
ssi-live-chat-notes .live-chat-notes-header > li {
  align-items: center;
  margin: 0;
  padding: 0;
}
ssi-live-chat-notes .live-chat-notes-header > li.title > .title-icon {
  font-size: 2.8em;
  padding-left: 5px;
}
ssi-live-chat-notes .live-chat-notes-header > li.title > .title-text {
  font-size: 1.4em;
  font-weight: 500;
}
ssi-live-chat-notes .live-chat-notes-content {
  align-self: flex-start;
  color: #A6B7C0;
  flex-flow: column nowrap;
  flex-grow: 0;
  flex-shrink: 0;
  height: calc(100vh - 144px);
  overflow-y: auto;
  padding: 40px 10%;
  width: 100%;
}
ssi-live-chat-notes .live-chat-notes-content ssi-live-chat-message .has-tags {
  display: none;
}
ssi-live-chat-notes .live-chat-notes-content ssi-live-chat-message .message-footer {
  display: none;
}
ssi-live-chat-notes .live-chat-notes-content .delete-button {
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 10px;
  color: #E4A598;
  font-style: italic;
  font-weight: 400;
  justify-content: space-between;
}
ssi-live-chat-notes .live-chat-notes-content .delete-button > .button-icon {
  font-size: 0.85em;
  padding-right: 0.2em;
}
ssi-live-chat-notes .live-chat-notes-content .message-notes {
  flex-flow: column nowrap;
  margin: 0;
  padding: 0;
}
ssi-live-chat-notes .live-chat-notes-content .message-notes > li {
  flex-shrink: 0;
  margin: 0;
  padding: 0;
}
ssi-live-chat-notes .live-chat-notes-content .message-notes > li ssi-live-chat-message-note {
  margin-left: 0;
  margin-right: 0;
}