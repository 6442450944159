@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-stat-change {
  .stat-change {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 900;

    i {
      margin-right: 3px;
      font-size: 10px;
    }

    &.hidden {
      display: block !important;
      visibility: hidden;
    }

    &.positive {
      color: #12aca4;
    }
    &.negative {
      color: $secondary-red;
    }
    &.no-change {
      color: $lmode-helper-text;
      i {
        font-size: 8px;
      }
    }

    &.boxed {
      padding: 7px 10px;
      border-radius: 5px;
      background-color: #cffdfa;
      font-size: 20px;

      i {
        font-size: 14px;
      }

      &.positive {
        background-color: #eaf9f8;
      }
      &.negative {
        background-color: $primary-red;
      }
      &.no-change {
        background-color: #eff2fd;
        i {
          font-size: 10px;
        }
      }

      //positioning
      &.bottom {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}
