ssi-inbox-language-selector {
  position: absolute;
  z-index: 5;
  padding: 25px 15px 32px 21px;
  background: #fff;
  border-radius: 25px;
  box-shadow: 0px 10px 15px rgba(231, 232, 238, 0.65);
}
ssi-inbox-language-selector .language-selector-container {
  position: relative;
}
ssi-inbox-language-selector .language-selector-input {
  color: #43537f;
  font-size: 12px;
  line-height: 28px;
  font-weight: 700;
  width: 140px;
  text-indent: 6px;
  outline: 0;
  border: 0;
  padding: 0;
  border-bottom: 2px solid #14bae3;
  text-rendering: geometricPrecision;
  margin: 0 0 0 8px;
}
ssi-inbox-language-selector .language-selector-input::placeholder {
  color: #838eab;
}
ssi-inbox-language-selector .language-selector-input-action {
  position: absolute;
  right: 0;
  top: 5px;
  color: #14bae3;
  font-size: 12px;
  font-weight: 900;
  cursor: pointer;
}
ssi-inbox-language-selector .language-selector-input-action:hover {
  text-decoration: underline;
}
ssi-inbox-language-selector .language-selector-list {
  height: 175px;
  width: 194px;
  overflow-y: scroll;
  overflow-x: none;
  list-style: none;
  padding: 0 0 0 4px;
  margin: 18px 0 0;
}
ssi-inbox-language-selector .language-selector-list-item {
  margin: 2px 0;
  max-width: 100%;
}
ssi-inbox-language-selector .language-selector-list-item.language-selector-list-item-active .language-selector-list-item-label {
  color: #14bae3;
  background-color: #f8f9fd;
}
ssi-inbox-language-selector .language-selector-list-item-label {
  display: inline-block;
  font-size: 12px;
  line-height: 26px;
  font-weight: 900;
  color: #43537f;
  padding: 0 11px;
  border-radius: 100px;
  text-rendering: geometricPrecision;
  cursor: pointer;
}
ssi-inbox-language-selector .language-selector-list-item-label:hover, ssi-inbox-language-selector .language-selector-list-item-label:focus {
  color: #14bae3;
}
ssi-inbox-language-selector .language-selector-list-item-label .ssi {
  margin: 0 0 0 10px;
}