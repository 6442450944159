ssi-landing {
  display: block;
}
ssi-landing .landing {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 17px 15px;
  background-color: #fff;
}
ssi-landing .landing.layout-login .landing-wrap .logo-sm,
ssi-landing .landing.layout-login .landing-wrap .landing-background-forgot {
  display: none;
}
ssi-landing .landing.layout-forgotPassword .landing-wrap .logo,
ssi-landing .landing.layout-forgotPassword .landing-wrap .landing-background-login,
ssi-landing .landing.layout-forgotPassword .landing-wrap .landing-container-col-illustration {
  display: none;
}
ssi-landing .landing .landing-wrap {
  position: relative;
  height: 100%;
  display: flex;
  background-color: #f5f7fb;
  border-radius: 10px;
  overflow: hidden;
}
ssi-landing .landing .landing-background-login {
  display: none;
  position: absolute;
  bottom: 20px;
  left: 0;
  max-height: 85%;
  max-width: 45%;
}
ssi-landing .landing .landing-background-login img {
  height: 100%;
  width: 100%;
}
ssi-landing .landing .landing-background-forgot {
  display: none;
  align-items: flex-end;
  position: absolute;
  top: 0;
  left: -80px;
  right: 0;
  margin: auto;
  max-height: 40%;
  width: 45%;
}
ssi-landing .landing .landing-background-forgot img {
  width: 100%;
  min-width: 600px;
  height: auto;
}
ssi-landing .landing .landing-container {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0 10%;
  justify-content: space-around;
  margin: 0 auto;
  overflow: auto;
  z-index: 1;
  color: #101525;
}
ssi-landing .landing .landing-container-col {
  max-width: 415px;
  width: 100%;
}
ssi-landing .landing .landing-container-col-illustration {
  display: none;
}
ssi-landing .landing .landing-illustration {
  text-align: center;
}
ssi-landing .landing .landing-illustration img {
  margin: -70px -8px 0 0;
  width: 100%;
  max-height: 283px;
}
ssi-landing .landing .landing-container-inner form {
  margin: 0 3px;
}
ssi-landing .landing .logo {
  margin: 0 30px 80px;
}
ssi-landing .landing .logo img {
  height: 50px;
}
ssi-landing .landing .logo-sm {
  position: absolute;
  top: 35px;
  left: 40px;
}
ssi-landing .landing .logo-sm img {
  height: 30px;
}
ssi-landing .landing .login-terms-footer {
  font-size: 10px;
  margin-top: 16px;
  font-weight: 700;
  padding: 0 15px;
}
ssi-landing .landing .login-terms-footer p {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}
ssi-landing .landing .login-terms-footer a {
  color: inherit;
  text-decoration: underline;
}
ssi-landing .landing .login-block {
  display: block;
}
ssi-landing .landing .login-buttons,
ssi-landing .landing .forget-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
ssi-landing .landing .forgot-message {
  position: relative;
  display: flex;
  align-items: center;
  background-color: rgba(17, 177, 219, 0.15);
  padding: 20px;
  border-radius: 15px;
  max-width: 380px;
  margin: 0 auto 45px;
  overflow: hidden;
}
ssi-landing .landing .forgot-message .forgot-message-image {
  flex: 1 0 40px;
  height: 40px;
  width: 40px;
  fill: #0ca2ca;
}
ssi-landing .landing .forgot-message .forgot-message-body {
  margin: 0 0 0 15px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  color: #0ca2ca;
  text-overflow: ellipsis;
  overflow: hidden;
}
ssi-landing .landing .btn {
  width: 201px;
  border-radius: 100px;
  padding: 19px 0;
  line-height: 18px;
  vertical-align: middle;
  outline: none !important;
  font-weight: 700;
}
ssi-landing .landing .btn:hover, ssi-landing .landing .btn:focus {
  box-shadow: 0 5px 10px rgba(148, 155, 191, 0.15);
}
ssi-landing .landing .btn:active {
  box-shadow: 0 3px 10px rgba(148, 155, 191, 0.15);
}
ssi-landing .landing .btn-secondary {
  background-color: #3463f2;
  border-color: #3463f2;
  color: #fff;
}
ssi-landing .landing .btn-primary {
  background-color: #f26235;
  border-color: #f26235;
  color: #fff;
}
ssi-landing .landing .btn-success {
  background-color: #11b1db;
  border: none;
}
ssi-landing .landing .btn-success:hover, ssi-landing .landing .btn-success:focus {
  background-color: #11b1db !important;
  box-shadow: 0 5px 10px rgba(47, 153, 181, 0.25);
}
ssi-landing .landing .btn-success:active {
  background-color: #11b1db !important;
  box-shadow: 0 3px 10px rgba(47, 153, 181, 0.25);
}
ssi-landing .landing .btn-success.disabled, ssi-landing .landing .btn-success.btn[disabled] {
  opacity: 1;
  box-shadow: none;
}
ssi-landing .landing .big-header {
  font-weight: 100;
  font-size: 40px;
  margin-bottom: 50px;
}
ssi-landing .landing .btn + .btn {
  margin-left: 13px;
}
ssi-landing .landing .login-field label,
ssi-landing .landing .forgot-field label {
  display: block;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.63px;
  text-transform: uppercase;
  font-weight: 900;
  margin: 0 30px 12px;
}
ssi-landing .landing .login-field input,
ssi-landing .landing .login-field .form-control,
ssi-landing .landing .forgot-field input,
ssi-landing .landing .forgot-field .form-control {
  color: inherit;
  border-radius: 100px;
  background-color: #fff;
  border: 3px solid #fff;
  height: auto;
  font-weight: 700;
  font-size: 16px;
  box-shadow: none;
  outline: 0;
  padding: 16px 7px 14px 27px;
}
ssi-landing .landing .login-field input::placeholder,
ssi-landing .landing .login-field .form-control::placeholder,
ssi-landing .landing .forgot-field input::placeholder,
ssi-landing .landing .forgot-field .form-control::placeholder {
  color: #949bbf;
  opacity: 0.55;
  letter-spacing: normal;
}
ssi-landing .landing .login-field input:focus, ssi-landing .landing .login-field input:active,
ssi-landing .landing .login-field .form-control:focus,
ssi-landing .landing .login-field .form-control:active,
ssi-landing .landing .forgot-field input:focus,
ssi-landing .landing .forgot-field input:active,
ssi-landing .landing .forgot-field .form-control:focus,
ssi-landing .landing .forgot-field .form-control:active {
  border-color: #11b1db;
  box-shadow: none;
}
ssi-landing .landing .chat-toggle-trigger {
  position: static;
  left: 25px;
  bottom: 35px;
  cursor: pointer;
  font-weight: 800;
  margin: 30px 20px 0 20px;
  font-size: 12px;
}
ssi-landing .landing .chat-toggle-trigger strong {
  color: #27bae1;
  font-weight: 800;
}
@media (max-width: 480px) {
  ssi-landing .login-buttons,
ssi-landing .forget-buttons {
    flex-wrap: wrap;
  }
  ssi-landing .login-buttons .btn,
ssi-landing .forget-buttons .btn {
    margin-bottom: 10px;
    flex: 1 1 100%;
  }
  ssi-landing .login-buttons .btn + .btn,
ssi-landing .forget-buttons .btn + .btn {
    margin: 0;
  }
}
@media (min-width: 992px) {
  ssi-landing .landing .landing-background-login {
    display: block;
  }
  ssi-landing .landing .landing-background-forgot {
    display: flex;
  }
  ssi-landing .landing .landing-container-col-illustration {
    display: block;
  }
  ssi-landing .landing .landing-container-col-form {
    margin-right: -40px;
  }
  ssi-landing .landing .chat-toggle-trigger {
    position: absolute;
  }
}
@media (min-width: 1600px) {
  ssi-landing .landing .landing-container-col-illustration {
    max-width: none;
  }
  ssi-landing .landing .landing-illustration img {
    margin: -90px auto 0 auto;
    width: auto;
    max-width: calc(100% - 15px);
    max-height: 493px;
  }
}