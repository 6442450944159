@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../angularjs/common/sass/include-media';
@import '../../../../../../angularjs/common/sass/mixins.scss';
@import '~/styles/colors';

$margin-between-boxes: 20px;
$sidebarWidth: 228px;

ssi-campaign-report {
  ssi-report-sidebar {
    .report-sidebar {
      width: $sidebarWidth;
    }

    .ssi-line-arrow-small-down {
      transform: rotate(90deg);
    }
  }

  .report-body {
    display: flex;
    flex-direction: column;
    background-color: $lgrey-background;
    width: 100%;
    height: 100%;
    padding-top: 134px;
    padding-left: 268px;
    padding-right: 40px;
    min-height: calc(100vh - 68px);

    @media print {
      display: block;
    }

    &.loading {
      background-color: #fff;
    }
  }

  hr {
    background-color: #fff;
    height: 2px;
    border: none;
    margin: 30px 0;
  }

  .section-content {
    margin: 25px 0;
  }

  h4 {
    color: $lmode-title-text;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .summaries-per-account-container {
    display: flex;
    flex-wrap: wrap;
    gap: $margin-between-boxes;

    @media print {
      display: block;
      width: 100%;
    }

    ssi-account-summary-card {
      flex: 1 1 calc(50% - #{$margin-between-boxes});
      .asc {
        margin-bottom: 0;
      }
      .asc-stats {
        justify-content: flex-end;
      }

      .asc-stat-label {
        &::before {
          background-color: $primary-active;
        }
      }

      @include media('>desktop') {
        flex: 0 1 calc(15% - #{$margin-between-boxes});
      }
      @include ie-only {
        margin: calc($margin-between-boxes / 2);
      }
    }
  }

  .facts-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    overflow-x: auto;

    @media print {
      display: block;

      & > * {
        display: inline-block;
        width: auto !important;
      }
    }

    > * {
      width: 100%;
      padding: 7px;
      margin-left: 10px;
      &:first-child {
        margin-left: 0;
      }
      .fact-overview {
        @include media('<1300px') {
          width: 172px !important;
        }
      }
    }
  }
}
