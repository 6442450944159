@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-notes {
  width: 100%;

  .notes-list-cont {
    list-style: none;
    padding: 0;
    margin: 0;
    height: 500px;

    .note {
      min-height: 100px;
      padding: 12px;
      margin: 0;
      margin-bottom: 10px;
      background-color: #fff;
      border-radius: 12px;
      box-shadow: 0 4px 8px 0 rgba(131, 142, 171, 0.25);

      // &.note-own {
      //   border-left: 0;
      //   border-right: 5px solid #ebf2f5;
      //   text-align: right;
      // }

      .note-header {
        font-size: 10px;
        font-weight: 900;
        color: $lmode-body-text;
        margin: 8px 0;

        .delete-button {
          padding: 0;
          border: 0;
          background-color: transparent;
        }
      }

      .note-content {
        font-size: 12px;
        font-weight: 700;
        color: $lmode-body-text;
      }
    }
  }
}
