ssi-automated-reports .automated-reports-container {
  width: 100%;
  height: calc(100vh - 186px);
  padding: 0 40px;
  background-color: #f4f4fa;
}
ssi-automated-reports .automated-reports-container .page-head {
  margin-top: 20px;
  background-image: linear-gradient(to right, #f26235, #1877f2);
  height: 200px;
  display: flex;
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
}
ssi-automated-reports .automated-reports-container .page-head .text {
  padding: 40px 20px 40px 50px;
}
ssi-automated-reports .automated-reports-container .page-head h2 {
  font-weight: 900;
  font-size: 20px;
  color: #fff;
}
ssi-automated-reports .automated-reports-container .page-head p {
  font-weight: bold;
  color: #fff;
  line-height: 20px;
}
ssi-automated-reports .automated-reports-container .page-head img {
  height: 200%;
  padding-right: 20px;
  padding-top: 20px;
}
ssi-automated-reports .automated-reports-container .page-body {
  margin-top: 20px;
}
ssi-automated-reports .automated-reports-container .page-body .tabs {
  display: flex;
}
ssi-automated-reports .automated-reports-container .page-body .tabs > div {
  position: relative;
  background-color: #f8f9fd;
  padding: 25px 50px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  margin-right: 10px;
  letter-spacing: 1px;
  color: #43537f;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 900;
  box-shadow: 5px 0 10px -5px rgba(131, 142, 171, 0.1);
}
ssi-automated-reports .automated-reports-container .page-body .tabs > div:hover {
  cursor: pointer;
}
ssi-automated-reports .automated-reports-container .page-body .tabs > div::before {
  content: "";
  transform: scaleX(0);
  visibility: hidden;
  position: absolute;
  bottom: 7px;
  height: 2px;
  width: 30%;
  left: 35%;
  background-color: #b2c614;
  transition: all 0.3s ease-in-out 0s;
}
ssi-automated-reports .automated-reports-container .page-body .tabs > div.active, ssi-automated-reports .automated-reports-container .page-body .tabs > div:hover {
  background-color: #fff;
}
ssi-automated-reports .automated-reports-container .page-body .tabs > div.active:before, ssi-automated-reports .automated-reports-container .page-body .tabs > div:hover:before {
  transform: scaleX(1);
  visibility: visible;
}
ssi-automated-reports .automated-reports-container .page-body .tabs > div.insights::before {
  background-color: #f0b427;
}
ssi-automated-reports .automated-reports-container .page-body .table-container {
  padding: 20px;
  background-color: #fff;
  border-radius: 0 15px 15px 15px;
}
ssi-automated-reports .automated-reports-container .page-body .table-container ssi-treetable .content .head .row .cell {
  height: 50px;
  background-color: #fff;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 10px;
  color: #43537f;
  padding-left: 30px;
  border-bottom: 2px solid #e0e7f4;
}
ssi-automated-reports .automated-reports-container .page-body .table-container .table-cell {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}
ssi-automated-reports .automated-reports-container .page-body .table-container .table-cell.body-cell {
  border-bottom: 2px solid #e0e7f4;
  padding-left: 30px;
}
ssi-automated-reports .automated-reports-container .page-body .table-container .table-cell.body-cell.gray-bg {
  background-color: #f8f9fd;
}
ssi-automated-reports .automated-reports-container .page-body .table-container .table-cell.body-cell.text-cell {
  font-size: 14px;
  color: #101525;
  font-weight: 700;
}
ssi-automated-reports .automated-reports-container .page-body .table-container .table-cell.body-cell.text-cell span.status-dot {
  flex: 0 0 auto;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: #b0c600;
}
ssi-automated-reports .automated-reports-container .page-body .table-container .table-cell.body-cell.text-cell span.status-dot.paused {
  background-color: #b4bbce;
}
ssi-automated-reports .automated-reports-container .page-body .table-container .table-cell.body-cell.text-cell span.text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media print {
  ssi-automated-reports .automated-reports-container .page-body .table-container .table-cell.body-cell.text-cell span.text {
    white-space: normal;
  }
}
ssi-automated-reports .automated-reports-container .page-body .table-container .table-cell.body-cell.text-cell span.text.bold {
  font-weight: 900;
}
ssi-automated-reports .automated-reports-container .page-body .table-container .table-cell.body-cell.text-cell span.text.paused {
  color: #838eab;
}
ssi-automated-reports .automated-reports-container .page-body .table-container .table-cell.body-cell.text-cell span.text.capitalize {
  text-transform: capitalize;
}
ssi-automated-reports .automated-reports-container .page-body .table-container .table-cell.body-cell.actions-cell {
  display: flex;
  justify-content: flex-end;
}
ssi-automated-reports .automated-reports-container .page-body .table-container .table-cell.body-cell.actions-cell button {
  margin-right: 5px;
  padding: 0 14px;
}
ssi-automated-reports .automated-reports-container .page-body .table-container .table-cell.body-cell.actions-cell button i {
  margin: 0;
  font-weight: 500;
}
ssi-automated-reports .automated-reports-container .page-body .table-container .table-cell.body-cell.actions-cell button.view-btn i {
  margin-right: 5px;
  font-size: 12px;
}
ssi-automated-reports .automated-reports-container .page-body .table-container .paginator-container {
  background-color: #fff;
  border-radius: 0 0 15px 15px;
  height: 80px;
  padding: 40px;
}
ssi-automated-reports .automated-reports-container .page-body .table-container .filter-container {
  width: 220px;
  position: relative;
}
ssi-automated-reports .automated-reports-container .page-body .table-container .filter-container input.form-control.form-control-minimal.filter {
  padding-left: 0;
  padding-right: 20px;
  color: #101525;
  font-style: normal;
  font-size: 14px;
  font-weight: 700;
  border: 0;
}
ssi-automated-reports .automated-reports-container .page-body .table-container .filter-container input.form-control.form-control-minimal.filter::placeholder {
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  color: #838eab;
}
ssi-automated-reports .automated-reports-container .page-body .table-container .filter-container i {
  position: absolute;
  color: #838eab;
  top: 10px;
  right: 10px;
  font-size: 14px;
  font-weight: 700;
}
ssi-automated-reports .automated-reports-container .page-body .table-container .filter-container i.active {
  color: #14bae3;
}