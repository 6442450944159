@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../../angularjs/common/sass/variables';
@import '../../../../../../../angularjs/common/sass/mixins';
@import '~/styles/colors';

$ifttt-config-columns-nav-arrow-width: 50px;
$ifttt-config-column-base-width: 230px;
$ifttt-config-name-height: 30px;
$ifttt-service-and-accounts-color-primary: #a0bdce;
$ifttt-service-and-accounts-color-secondary: lighten(
  $ifttt-service-and-accounts-color-primary,
  10%
);
$ifttt-triggers-color-primary: $brand-royal;
$ifttt-triggers-color-secondary: #c7569e;
$ifttt-actions-color-primary: $brand-success;
$ifttt-actions-color-secondary: #d8ddb0;
$ifttt-bg-light: #f8f8f8;
$ifttt-text-muted: #c9d2d8;

.manage-ifttt-applet-triggers-and-actions {
  .manage-ifttt-applet-triggers-and-actions-top {
    width: 100%;
    margin: auto;
    padding-top: 85px;
  }

  .manage-ifttt-applet-triggers-and-actions-bottom {
    @include align-vertical();
  }

  .applet-name-container {
    background-color: $ifttt-bg-light;
    padding: 8px 10px;
    border-radius: 7px;
    text-align: center;
    color: $primary-active;
    margin-bottom: $section-margin-width;
    text-transform: uppercase;
    font-size: 11px;
    height: $ifttt-config-name-height;

    .applet-name {
      font-weight: bold;
      font-style: italic;
    }

    i {
      float: right;
      margin-top: 2px;
    }
  }

  .applet-name-container:hover {
    color: darken(darken(#428bca, 6.5%), 15%);
    cursor: pointer;
  }

  .manage-ifttt-applet-triggers-and-actions-top-inner {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .applet-config-columns-container {
    display: flex;
    flex-direction: column;
  }

  .applet-config-columns {
    display: flex;
  }

  .applet-config-column:not(:last-child) {
    margin-right: $section-margin-width;
  }

  .applet-config-columns-nav-arrow {
    width: $ifttt-config-columns-nav-arrow-width;
    text-align: center;
    color: $primary-active;
    font-size: 55px;
    @include align-vertical(auto);
    margin-top: $ifttt-config-name-height;

    i:hover {
      cursor: pointer;
      color: darken(darken(#428bca, 6.5%), 15%);
    }
  }

  .applet-config-column-body {
    text-align: center;
    @include align-vertical(200px);
    background-color: $ifttt-bg-light;
    padding: 0 15px;

    i {
      font-size: 40px;
      margin: 20px 5px;
      color: $ifttt-text-muted;
    }

    .applet-config-column-body-title,
    .applet-config-column-body-sub-title {
      font-style: italic;
      color: $ifttt-text-muted;
      font-weight: normal;
    }

    .applet-config-column-body-sub-title {
      font-size: 12px;
    }
  }

  .applet-config-column-header,
  .applet-config-column-footer {
    border-radius: 5px;
    padding: 8px 10px;
    text-align: center;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: normal;
    cursor: pointer;
    width: $ifttt-config-column-base-width;
    margin: auto;

    i {
      float: right;
      margin-top: 3px;
    }
  }

  .applet-config-column-header {
    color: white;
    margin-bottom: $section-margin-width;
  }

  .applet-config-column-footer {
    font-style: italic;
    color: white;
    margin-top: $section-margin-width;
  }

  @mixin applet-config-column($column-color-primary, $column-color-secondary) {
    .applet-config-column-header,
    .applet-config-column-footer.active {
      background-color: $column-color-primary;
    }

    .applet-config-column-header:hover,
    .applet-config-column-footer.active:hover {
      background-color: darken($column-color-primary, 5%);
    }

    .applet-config-column-body {
      border-color: $column-color-secondary;

      i {
        color: $column-color-primary;
      }
    }

    .ingredient-label {
      color: $column-color-primary;
    }
  }

  .applet-config-column.service-and-accounts {
    @include applet-config-column(
      $ifttt-service-and-accounts-color-primary,
      $ifttt-service-and-accounts-color-secondary
    );
  }

  .applet-config-column.triggers {
    @include applet-config-column(
      $ifttt-triggers-color-primary,
      $ifttt-triggers-color-secondary
    );
  }

  .applet-config-column.actions {
    @include applet-config-column(
      $ifttt-actions-color-primary,
      $ifttt-actions-color-secondary
    );
  }

  .applet-config-column:not(.has-items) {
    .applet-config-column-body {
      i {
        color: #bac6cd;
      }
    }
  }

  .applet-config-column.active {
    .applet-config-column-header {
      pointer-events: none;
    }

    .applet-config-column-body {
      box-shadow: $box-shadow-1;

      .applet-config-column-body-title,
      .applet-config-column-body-sub-title {
        color: $primary-active;
      }
    }

    .applet-config-column-footer.active {
      background-color: $primary-active;
    }

    .applet-config-column-footer.active:hover {
      background-color: shade($primary-active, 5%);
    }
  }

  .applet-config-column.has-items.active {
    .applet-config-column-body {
      border-width: 3px;
      border-style: solid;
    }
  }

  .applet-config-column.future {
    .applet-config-column-body i {
      color: $ifttt-text-muted;
    }

    .applet-config-column-body:hover {
      cursor: pointer;
      i {
        color: shade($ifttt-text-muted, 5%);
      }
    }
  }

  .applet-config-column-footer.disabled {
    background-color: $ifttt-bg-light;
    color: $ifttt-text-muted;
    pointer-events: none;
  }

  .manage-ifttt-applet-triggers-and-actions-bottom-title {
    font-size: 26px;
  }

  .manage-ifttt-applet-triggers-and-actions-bottom-sub-title {
    font-weight: normal;
  }

  .manage-ifttt-applet-triggers-and-actions-bottom-inner {
    margin: 20px;
    width: 100%;
  }

  .manage-ifttt-applet-cards {
    display: flex;
    flex-wrap: wrap;
  }

  ssi-trigger-or-action-card {
    flex-basis: calc(20% - #{$section-margin-width});
    margin-right: $section-margin-width;
    margin-bottom: $section-margin-width;

    .btn-primary[disabled] {
      background-color: #c4cfd5;
      border-color: #c4cfd5;
    }

    .card-form-elements-columns {
      display: flex;
      height: 100%;
      min-height: 0;
    }

    .card-form-elements {
      display: flex;
      padding: 0 30px;
      flex-direction: column;
      flex: 1;
      min-height: 0;
      width: 100%;
    }

    .card-form-element {
      flex: 1;
      display: flex;
      flex-direction: column;
      position: relative; // required for the textarea caret positioning to work
      min-height: 0;

      &.flex-basis {
        flex-basis: 50px;
      }
    }

    .scroll-vertical {
      flex: 1;
    }

    .ingredient-label {
      background-color: $ifttt-actions-color-primary;
      border-radius: 8px;
      display: inline-block;
      padding: 0 4px;
      margin-bottom: 1px;
    }
  }

  $card-form-element-input-bg-color: #f0f2f3;

  .card-form-element-input {
    background-color: $card-form-element-input-bg-color;
    border-radius: 0;
    border: 0;
    resize: none;
    box-shadow: none;
    text-align: left;
  }

  .card-form-element-input::placeholder {
    color: #c9d8e0;
  }

  textarea.card-form-element-input {
    flex: 1;
  }

  .card-form-element-input:focus {
    box-shadow: none;
    border: 0;
  }

  textarea[ssiTextInputElement].card-form-element-input {
    background: none;
  }

  .text-input-highlight-container {
    .text-highlight-element {
      background-color: $card-form-element-input-bg-color;
    }

    .text-highlight-element-tag {
      background-color: #cedae1;
    }
  }

  .ingredients-popover {
    border: 0;
    height: 343px;
    width: 300px;
    max-width: initial;
    border-radius: 0;
    padding: 20px;

    .popover-title {
      display: none;
    }

    h4 {
      text-align: center;
      color: #cedae1;
      font-size: 15px;
      font-weight: normal;
      margin-bottom: 20px;
    }

    .scroll-vertical {
      max-height: 230px;
    }
  }

  .popover.ingredients-popover::before {
    content: initial;
  }

  .popover.ingredients-popover::after {
    border: 0;
  }

  .exact-matches-checkbox-wrap {
    display: flex;
    align-items: center;
    // justify-content: flex-end;
    padding: 5px 0 20px 0;

    a {
      color: $lmode-body-text;
      &:hover {
        color: $primary-active;
      }

      i {
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
}
