@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-note {
  .note-container {
    p {
      color: #101525;
      font-size: 14px;
      font-weight: 700;
    }

    .note-meta-and-actions {
      span {
        color: #43537f;
        font-size: 12px;
        font-weight: 900;
        margin-right: 5px;

        &.delete-icon-container {
          padding: 2px;
          background-color: #fff1ed;
          border-radius: 50%;
          margin-right: 10px;

          &:hover {
            cursor: pointer;
          }

          i {
            font-size: 10px;
            font-weight: 900;
            color: #e34916;
          }
        }
      }
    }
  }
}
