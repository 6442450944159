@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-engagement ssi-engagement-times {
  font-size: 1rem;

  .engagement-times-section {
    ssi-highchart {
      width: 100%;
      height: 34em;

      highcharts-chart {
        .highcharts-container,
        .highcharts-root {
          overflow: visible !important;
        }

        .highcharts-axis-title {
          font-size: 1em;
          font-weight: 900;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          white-space: nowrap;
        }

        .highcharts-axis-labels {
          text {
            font-weight: 900;
          }
        }

        .highcharts-xaxis-labels {
          transform: translate(0, 1em);
        }
      }
    }
  }

  .section-chart-wrap {
    padding: 3em 3em 6em 3em;
    background-color: #fff;
    border-radius: 1.2em;
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
  }

  .top-row {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3em;

    .best-hours-container {
      display: flex;
      flex: 0 0 auto;
      border-radius: 0.5em;
      border: 0.1em solid #425dec;
      padding: 2em;

      .left {
        margin-right: 8em;

        span {
          color: $lmode-helper-text;
          font-size: 1em;
          font-weight: 900;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          text-rendering: geometricPrecision;
        }
      }

      .middle {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        span {
          color: $lmode-helper-text;
          font-size: 1.4em;
          font-weight: 700;
          text-rendering: geometricPrecision;
        }

        .hours {
          span {
            color: $lmode-title-text;
            font-size: 2em;
            font-weight: 900;
          }
        }
      }

      .right {
        display: flex;
        align-items: center;
        margin-left: 2em;

        i {
          font-size: 3.2em;
          color: #b2f0ff;
        }
      }
    }
  }

  .chart-legend {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    margin-top: 4em;

    .legend-item {
      display: flex;
      align-items: center;
      margin-right: 6em;

      &:first-child {
        margin-right: 3em;
      }

      span {
        color: $lmode-body-text;
        font-size: 1em;
        font-weight: 900;
        letter-spacing: 0.1em;
      }

      .circle {
        height: 1.8em;
        width: 1.8em;
        border-radius: 50%;
        background-color: #000;
        margin-right: 1em;
        -webkit-print-color-adjust: exact;
      }

      &.great {
        .circle {
          background-color: #425dec !important;
        }
      }
      &.good {
        .circle {
          background-color: $primary-active !important;
        }
      }
      &.okay {
        .circle {
          background-color: #72d6ee !important;
        }
      }
      &.not-great {
        .circle {
          background-color: #b2f0ff !important;
        }
      }
      &.not-good {
        .circle {
          background-color: #e7f7f6 !important;
        }
      }
    }
  }

  .section-tip-wrap {
    position: absolute;
    max-width: 80%;
    right: 0;
    top: 0.5em;
    // margin: 0 0 2em 2em;
  }

  .section-tip {
    display: inline-flex;
    flex-direction: row-reverse;
    background-color: #fff;
    border-radius: 1.2em;
    padding: 1em;
    box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);

    &-icon {
      display: flex;
      flex: 0 0 3.6em;
      align-items: center;
      justify-content: center;
      height: 3.6em;
      width: 3.6em;
      background-color: $lgrey-background;
      border-radius: 50%;
      color: $primary-active;
      border: 0;
      outline: 0;

      &:active {
        background-color: darken($lgrey-background, 5%);
      }

      &:hover + p {
        display: inline-flex !important;
      }

      i {
        height: 1.4rem;
        font-size: 1.8em;
      }
    }

    p {
      display: none;
      align-items: center;
      margin: 0 0.8em;
      color: $lmode-body-text;
      font-weight: 700;
      text-rendering: geometricPrecision;
    }
  }
}
