@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../angularjs/common/sass/variables';
@import '../../../../angularjs/common/sass/mixins';
@import '~/styles/colors';
@import '../../../../angularjs/common/sass/include-media';

$ssi-publisher-responsive-width: 885px;
$footerHeight: 77px;
$headerHeight: 77px;

ssi-composer {
  .minimised-view {
    position: fixed;
    bottom: 0;
    right: 20px;
    z-index: 2000;
    border: 1px solid $primary-active;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: none;
    background-color: #fff;
    padding: 10px;
    width: 240px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $lmode-body-text;
    box-shadow: 0 5px 10px 0 rgba(16, 21, 37, 0.35);

    &:hover {
      cursor: pointer;
    }

    span {
      color: $lmode-title-text;
      font-weight: 900;
    }
  }
  .composer-inner-container {
    position: fixed;
    height: calc(100vh - #{$navbar-height});
    width: 100vw;
    background: #fff;
    z-index: 1000;
    transition: all 0.3s ease-in-out;
    transform: translateY(0);

    .composer-heading,
    .composer-footer {
      padding: 15px 20px 11px;
    }

    .composer-heading {
      display: flex;
      justify-content: space-between;
      height: $headerHeight;
      width: 100%;
      box-shadow: 0px 5px 10px 0 rgba(131, 142, 171, 0.2);
      border-bottom: 1px solid #c3c9d8;

      .layout-control-actions {
        display: flex;

        .layout-action {
          border: 1px solid $lmode-helper-text;
          background-color: #fff;
          width: 46px;
          height: 46px;
          color: $lmode-body-text;
          margin-right: 5px;

          i {
            font-size: 20px;

            &.split-icon {
              font-size: 24px;
            }
          }

          &.g-btn-wide {
            width: auto;
          }

          &:disabled {
            color: #838eab;
            background-color: #f4f4fa;
            border-color: #f4f4fa;
          }
        }
      }
    }

    .composer-footer {
      position: fixed;
      bottom: 0;
      width: 100%;
      background-color: #fff;
      box-shadow: 0px -5px 10px 0 rgba(131, 142, 171, 0.2);
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #c3c9d8;

      @include media('<large-phone') {
        flex-wrap: wrap;

        ssi-checkbox {
          width: 100%;
        }
      }

      button {
        @include media('<large-phone') {
          width: 100%;
          margin-top: 10px;
          flex-direction: row-reverse;
          justify-content: space-between;
        }
      }

      .toggle-switch-2-container {
        border: none;
        background-color: inherit;
      }
    }

    .composer-content {
      background-color: $grey-background;
      height: calc(
        100% - ($footerHeight + $headerHeight) + 1px
      ); // 1px subpixel rendering bug
      overflow: auto;
      display: flex;

      @include media('<tablet') {
        flex-wrap: wrap;
      }
    }

    .image-icon,
    .video-icon {
      color: #43537f;
    }

    .post-controls-container,
    .post-preview-container {
      background-color: $lmgrey-background;
      border: 1px solid #c3c9d8;
      flex: 1 1 50%;
      padding: 20px;
    }

    .post-controls-container {
      border-top: none;
      overflow: auto;
    }

    .post-preview-container {
      margin: 15px;
      border-radius: 12px;
    }

    &.fixedRight {
      width: 50vw;
      left: initial;
      right: 0;
      box-shadow: -5px 0 7px 1px rgba(131, 142, 171, 0.2);

      .composer-content {
        flex-wrap: wrap;
      }

      .composer-footer {
        max-height: $footerHeight;
        width: 50vw;
      }

      .post-controls-container {
        border-right: none;
        border-left: none;
      }
    }

    &.fixedLeft {
      width: 50vw;
      left: 0;
      box-shadow: 5px 0 7px 1px rgba(131, 142, 171, 0.2);

      .composer-footer {
        width: 50vw;
      }
    }

    &.from-left {
      left: 0;
    }

    &.full {
      width: 100vw;
      right: 0;
      .composer-footer {
        width: 100vw;
      }
    }

    &.minimised {
      width: 0;
      right: 0;
      transform: translateY(100%);
    }
  }

  .targeting-options-posts {
    .cl-accordion-item {
      overflow: visible;
      will-change: initial;
    }

    .multiple-targetable-account {
      .accordion-body {
        padding: 1em;

        .multiple-accounts {
          .targeting-options-item:first-child {
            &.accordion-content {
              .cl-accordion-item {
                border-top: 1px solid #c3c9d8 !important;
                border-left: 1px solid #c3c9d8;
                border-right: 1px solid #c3c9d8;
                border-radius: 10px 10px 0 0 !important;
              }
            }
          }

          .targeting-options-item:last-child {
            &.accordion-content {
              .cl-accordion-item {
                border: 1px solid #c3c9d8 !important;
                border-radius: 0 0 10px 10px !important;
              }
            }
          }

          .targeting-options-item.accordion-content {
            .cl-accordion {
              margin-bottom: 0;

              .cl-accordion-item {
                border-left: 1px solid #c3c9d8;
                border-right: 1px solid #c3c9d8;
                border-bottom: 0;
                border-radius: 0;
              }
            }
          }
        }
      }
    }
  }

  .accordion-content {
    color: $lmode-body-text;
    text-align: left;

    &-title {
      font-size: 14px;
      font-weight: 900;
      text-align: left;
      color: $lmode-body-text;
      padding-left: 30px;
    }

    &-body {
      font-weight: 700;
      line-height: 20px;
      padding: 0 0 30px 30px;
      margin-bottom: 0;
    }

    &-action {
      display: flex;
      align-items: center;
      background-color: #f4f4fa;
      border-radius: 10px;
      border: 1px solid #f4f4fa;
      color: #838eab;
      height: 46px;
      padding: 0 14px;
      cursor: not-allowed;

      p {
        font-size: 14px;
        font-weight: 700;
        color: inherit;
        margin: 0 0 0 8px;
      }

      &-active {
        cursor: pointer;
        background-color: #e4faff;
        border-color: #14bae3;

        p {
          color: $lmode-body-text;
        }

        i {
          color: #14bae3;
        }
      }
    }
  }

  .tags-campaigns {
    margin-top: 30px;

    .cl-accordion-item {
      overflow: visible;
      will-change: initial;
    }
  }

  .tags-campaigns-container {
    .accordion-content-body {
      padding-bottom: 10px;
    }
    &:last-child {
      margin-top: 30px;
    }
  }

  .selector-draft-container {
    display: flex;
    margin-bottom: 10px;
    > * {
      flex: 1;
      width: 50%;
    }

    .draft-title-input {
      padding-left: 10px;

      label {
        display: block;
        font-size: 10px;
        font-weight: 900;
        line-height: 12px;
        letter-spacing: 0.1em;
        text-align: left;
        color: $lmode-body-text;
        text-transform: uppercase;
        padding: 0 0 10px 20px;
      }

      input {
        appearance: none;
        border: 1px solid #c3c9d8;
        border-radius: 10px;
        padding: 12px;
        width: 100%;
        font-weight: 900;
        font-family: Lato, sans-serif;

        &::placeholder {
          color: #6a759a;
          font-weight: 700;
        }
      }

      input:focus {
        outline: none;
      }

      input:focus-within {
        border: 1px solid #14bae3;
      }
    }
  }

  .toggle-label {
    display: flex;
    height: 100%;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #c3c9d8;
    padding: 0 8px 0 25px;

    p {
      color: $lmode-body-text;
      margin: 0;
      font-weight: 900;
      text-rendering: geometricPrecision;
    }

    &.toggled {
      border: 1px solid $primary-active;
      background-color: #e4faff;

      p {
        padding-right: 7px;
      }
    }
  }

  .post-errors {
    .cl-accordion-item {
      border-color: #e34916;
    }
    .accordion-button {
      border-color: #e34916;
      background-color: #fff1ed;
      .accordion-button-icon {
        color: #cc3300;
      }
    }
  }
}

.composer-footer-left {
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin: 0 8px 4px 0;
  }

  ssi-checkbox .checkbox-container {
    background-color: #fff;
    border-color: #c3c9d8;

    input {
      border-color: #838eab;

      &:checked {
        border-color: #14bae3;
      }
    }
  }
}

.composer-footer-right {
  margin: 0 0 4px;
}

.composer-footer-label {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  background-color: #f0f3f9;
  border-width: 0;
  border-radius: 10px;
  height: 46px;
  color: #43537f;

  p {
    margin: 0;
    font-weight: 900;
    text-rendering: geometricPrecision;
    white-space: nowrap;
  }

  i {
    font-size: 16px;

    &:first-child {
      margin: 0 6px 0 0;
    }

    &:last-child {
      margin: 0 0 0 6px;
    }
  }
}

.tooltip-link-highlight-content {
  display: flex;
  align-items: center;

  p {
    padding-right: 15px;
    font-weight: 900;
    text-rendering: geometricPrecision;
    line-height: 20px;
    margin: 0 15px 0 0;
    border-right: 2px solid #d9dde6;
    white-space: nowrap;
    cursor: pointer;
  }

  i {
    display: inline-block;
    color: #43537f;
    font-weight: 900;
    cursor: pointer;
  }
}

.ap-menu-dropdown {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 5px 10px rgba(131, 142, 171, 0.35);
  border-width: 0;
  padding: 18px 10px;

  & > li {
    display: block;
    padding: 10px 10px 10px 20px;
    line-height: 15px;
    margin: 0 0 4px 0;
    cursor: pointer;

    &:hover {
      background-color: #f0f3f9;
      border-radius: 15px;
    }

    .ap-menu-label {
      margin-left: 10px;
      color: #43537f;
      font-weight: 900;
      text-rendering: geometricPrecision;
    }

    & > i {
      color: #838eab;
    }

    .ssi-small-delete {
      color: #ff4d10;
    }
  }
}
