@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../../angularjs/common/sass/variables';

.manage-ifttt-applet-name {

  .accounts-label {

    background-color: #e7eff3;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: normal;

    .accounts-amount {
      margin-right: 30px;
    }

  }

  .accounts-label:hover {
    text-decoration: none;
  }

}
