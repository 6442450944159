@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '../../../../../angularjs/common/sass/mixins';
@import '~/styles/colors';

$tooltip-bg-color-primary: #3f4142;
$tooltip-bg-color-secondary: #2e3031;
$tooltip-text-color: #fff;
$tooltip-arrow-dimensions: 10px;
$tooltip-icon-color: #3787b3;
$tooltip-max-width: 350px;
$dark-tooltip-background: $dmode-background;

@mixin tooltip-top($arrow-color) {
  .tooltip-arrow {
    top: 100%;
    // margin-left: $tooltip-arrow-dimensions / 2;
    border-width: $tooltip-arrow-dimensions $tooltip-arrow-dimensions 3px 2px;
    border-color: $arrow-color transparent transparent;
  }
}

@mixin tooltip-bottom($arrow-color) {
  .tooltip-arrow {
    top: -$tooltip-arrow-dimensions;
    // margin-left: $tooltip-arrow-dimensions / 2;
    border-width: $tooltip-arrow-dimensions 2px 3px $tooltip-arrow-dimensions;
    border-color: transparent transparent transparent $arrow-color;
  }
}

ssi-tooltip-window[data-popper-placement^='top'] {
  @include tooltip-top($tooltip-bg-color-primary);
}

ssi-tooltip-window[data-popper-placement^='bottom'] {
  @include tooltip-bottom($tooltip-bg-color-primary);
}

ssi-tooltip-window.tooltip-bg-secondary {
  background-color: $tooltip-bg-color-secondary;
}

ssi-tooltip-window[data-popper-placement^='top'].tooltip-bg-secondary {
  @include tooltip-top($tooltip-bg-color-secondary);
}

ssi-tooltip-window[data-popper-placement^='bottom'].tooltip-bg-secondary {
  @include tooltip-bottom($tooltip-bg-color-secondary);
}

ssi-tooltip-window {
  background-color: $tooltip-bg-color-primary;
  color: $tooltip-text-color;
  max-width: $tooltip-max-width;
  display: flex;
  position: absolute;
  border-radius: 3px;
  z-index: $zindex-tooltip;

  .tooltip-icon {
    color: $tooltip-icon-color;
    font-size: 30px;
    background-color: $tooltip-bg-color-secondary;
    align-items: center;
    justify-content: center;
    height: auto;
    border-radius: 3px;
    padding: 10px;
    display: flex;
    flex: 0 1 auto;

    & + .tooltip-body {
      flex: 1;
      width: auto;
    }
  }

  .tooltip-icon.tooltip-icon-padding {
    padding: 20px;
    font-size: 45px;
  }

  .tooltip-body {
    flex: 0 1 auto;
    width: 100%;
    padding: 10px;
    font-style: italic;
    display: flex;
    flex-direction: column;
  }

  .tooltip-body-title {
    font-size: 16px;
    margin-bottom: 5px;
    display: flex;
  }

  .tooltip-body-contents {
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    flex: 0 1 auto;
    width: 100%;
    @include align-vertical();
  }

  .tooltip-arrow {
    content: '';
    position: absolute;
    border-style: solid;
  }

  .trailing-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 16px;
    padding: 5px;
    border-radius: 50%;
    color: #838eab;
    background-color: rgba(138, 149, 176, 0.17);
  }
}

@mixin tooltip-arrow-style {
  $arrow-size: 10px;

  &.top:not([data-popper-placement='bottom']),
  &.bottom[data-popper-placement='top'] {
    margin-bottom: $arrow-size;

    .tooltip-arrow {
      top: 100%;
      height: $arrow-size;
      width: $arrow-size;
      border: none;

      &:before {
        content: '';
        display: block;
        margin-left: calc($arrow-size / 4);
        top: 100%;
        border-style: solid;
        border-width: $arrow-size $arrow-size 0 0;
        border-color: $lmode-title-text transparent transparent transparent;
        transform: skew(-6deg);
      }
    }
  }

  &.bottom:not([data-popper-placement='top']),
  &.top[data-popper-placement='bottom'] {
    margin-top: $arrow-size;
    margin-left: calc($arrow-size / 4);

    .tooltip-arrow {
      top: -$arrow-size;
      height: $arrow-size;
      width: $arrow-size;
      border: none;

      &:before {
        content: '';
        display: block;
        margin-left: calc($arrow-size / 4);
        top: -$arrow-size;
        border-style: solid;
        border-width: $arrow-size 0 0 $arrow-size;
        border-color: transparent transparent transparent $lmode-title-text;
        transform: skew(6deg);
      }
    }
  }
}

.tooltip-chunky {
  background: $lmode-title-text;
  min-width: 50px;
  max-width: 335px;
  border-radius: 25px;
  filter: drop-shadow(0 5px 10px rgba(16, 21, 37, 0.35));

  @include tooltip-arrow-style;

  .tooltip-body {
    padding: 9px 15px;
    transform: skew(0);
    word-break: break-word;
  }

  ul {
    padding-left: 0;
  }

  li {
    list-style: none;
  }

  .tooltip-body-contents {
    color: #fff;
    line-height: 20px;
    font-weight: 700;
    font-style: normal;
  }
}

.tooltip-chunky-basic {
  background: $lmode-title-text;
  min-width: 150px;
  border-radius: 15px;
  filter: drop-shadow(0 5px 10px rgba(16, 21, 37, 0.35));

  @include tooltip-arrow-style;
}

.tooltip-chunky-big {
  background: $lmode-title-text;
  width: 335px;
  border-radius: 15px;
  filter: drop-shadow(0 5px 10px rgba(16, 21, 37, 0.35));

  .tooltip-body-title {
    font-size: 18px;
    color: #fff;
    font-weight: 900;
    line-height: 22px;
    font-style: normal;
  }

  @include tooltip-arrow-style;

  .tooltip-body {
    padding: 25px;
    transform: skew(0);
  }

  .tooltip-body-contents {
    color: #fff;
    line-height: 20px;
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    white-space: normal;
  }
}

.tooltip-chunky-big-white {
  @extend .tooltip-chunky-big;

  .tooltip-body-contents {
    color: #fff;
  }
}

.tooltip-link-highlight {
  display: flex;
  background: #fff;
  color: #43537f;
  min-height: 46px;
  max-width: 155px;
  border-radius: 10px;
  padding: 0 20px;
  box-shadow: 0px 5px 10px 0px #838eab59;
}

// dark variant - new design, with 'rounded' 15px radius
.dark {
  background-color: $dark-tooltip-background;

  .tooltip-body {
    background-color: transparent;
    padding: 25px;
    transform: none;
    font-style: normal;
    line-height: 20px;

    .tooltip-body-contents {
      color: #fff;
      font-size: 14px;
      font-weight: normal;
    }
  }
  .tooltip-icon {
    color: $lmode-helper-text;
    padding: 25px 3px 35px 20px;
    background-color: transparent;
    font-size: 19px;

    & + .tooltip-body {
      padding: 25px 25px 25px 10px;
    }
  }

  &.rounded {
    border-radius: 15px;
  }

  &.grey {
    background-color: $grey-background;
    color: #8a95b0;
    border-radius: 20px;
    box-shadow: 0 3px 12px 3px rgba(231, 232, 238, 0.65);
    padding: 10px;

    .tooltip-body {
      border-radius: 15px;
    }
    .tooltip-icon {
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }
  }
}

// used in insights module
.dark-new {
  width: 300px;
  background-color: #101525;
  border-radius: 15px;

  .tooltip-body {
    background-color: transparent;
    padding: 25px !important;
    transform: none;
    font-style: normal;
    line-height: 20px;
    text-align: left;

    .tooltip-body-contents {
      color: #fff;
      font-size: 14px;
      font-weight: normal;
    }

    .tooltip-body-title {
      font-size: 20px;
      font-weight: 900;
      margin-bottom: 10px;
      text-align: left;
    }
  }
  .tooltip-icon {
    position: absolute;
    right: 12px;
    top: 12px;
    font-size: 14px !important;
    color: #838eab;
    padding: 5px !important;
    background-color: rgba(138, 149, 176, 0.17);
    border-radius: 50%;

    & + .tooltip-body {
      padding: 25px 25px 25px 10px;
    }
  }

  &.rounded {
    border-radius: 15px;
  }

  @include tooltip-arrow-style;

  .tooltip-arrow {
    border-color: #101525 transparent transparent !important;
  }
}

@mixin tooltip-top($arrow-color) {
  margin-bottom: $tooltip-arrow-dimensions;

  .tooltip-arrow {
    top: 100%;
    border-width: $tooltip-arrow-dimensions $tooltip-arrow-dimensions 0
      $tooltip-arrow-dimensions;
    border-color: $arrow-color transparent transparent transparent;
  }
}

@mixin tooltip-bottom($arrow-color) {
  margin-top: $tooltip-arrow-dimensions;

  .tooltip-arrow {
    top: -$tooltip-arrow-dimensions;
    border-width: 0 $tooltip-arrow-dimensions $tooltip-arrow-dimensions
      $tooltip-arrow-dimensions;
    border-color: transparent transparent $arrow-color transparent;
  }
}

ssi-tooltip-window.top {
  @include tooltip-top($tooltip-bg-color-primary);
}

ssi-tooltip-window.bottom {
  @include tooltip-bottom($tooltip-bg-color-primary);
}

ssi-tooltip-window.tooltip-bg-secondary {
  background-color: $tooltip-bg-color-secondary;
}

ssi-tooltip-window.top.tooltip-bg-secondary {
  @include tooltip-top($tooltip-bg-color-secondary);
}

ssi-tooltip-window.bottom.tooltip-bg-secondary {
  @include tooltip-bottom($tooltip-bg-color-secondary);
}
