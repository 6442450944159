@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

.post-box-gallery {
  position: absolute;
  right: 0;
  top: 20px;
  bottom: 15px;
  width: 130px;
  margin-right: -15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.post-box-gallery-badge {
  position: absolute;
  line-height: 42px;
  text-align: center;
  font-size: 12px;
  color: $primary-active;
  display: block;
  width: 42px;
  height: 42px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  z-index: 1;
  font-weight: 500;
}

.post-box-gallery-frame {
  display: block;
  border: 4px solid #fff;
  height: 100%;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  max-height: 150px;
  min-width: 100%;
  width: 100%;
}

.post-box-gallery-frame-stack {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 15px;
  box-shadow: none;
}
