ssi-content-generator-feed {
  font-size: 1rem;
}
ssi-content-generator-feed .cgf-list > li {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
}
ssi-content-generator-feed .cgf-list ssi-checkbox-2 .checkbox-2-container i {
  top: 0;
  bottom: 0;
  margin: auto;
  height: 10px;
}
ssi-content-generator-feed .cgf-list ssi-checkbox-2 label {
  white-space: normal;
}