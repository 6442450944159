ssi-detected-theme-results {
  font-size: 1rem;
}
ssi-detected-theme-results .detected-theme-results-container {
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 12px;
  height: 100%;
}
ssi-detected-theme-results .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: 20px;
  border-bottom: 1px solid #c3c9d8;
}
ssi-detected-theme-results .heading .title {
  font-size: 2em;
  font-weight: 900;
  color: #43537f;
  white-space: nowrap;
  flex-basis: 20%;
  margin-bottom: 0;
}
ssi-detected-theme-results .body {
  display: flex;
  height: 100%;
  background-color: #fff;
  overflow: hidden;
}
ssi-detected-theme-results .body .posts-area {
  overflow: auto;
  background-color: #fff;
  width: 100%;
}
ssi-detected-theme-results .body .posts-area ssi-insights-posts .posts-container {
  height: 100%;
  background-color: inherit;
}
ssi-detected-theme-results .body .posts-area.dotted {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='#F8F9FD' rx='12' ry='12' stroke='#c3c9d8' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 12px;
  box-shadow: inset 0 18px 40px 0 rgba(131, 142, 171, 0.1);
}
ssi-detected-theme-results .body .paginator-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin: 20px 0 60px 0;
}
ssi-detected-theme-results .message-info {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-align: center;
}
ssi-detected-theme-results .message-info i {
  color: #b4bbce;
  font-size: 4em;
}
ssi-detected-theme-results .message-info h5 {
  font-size: 2em;
  font-weight: 900;
  color: #43537f;
}
ssi-detected-theme-results .message-info p {
  font-size: 1.4em;
  max-width: 220px;
  font-weight: bold;
  color: #43537f;
}