@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

.login-activity {
  .login-table {
    position: relative;
    padding: 2em;
    border-radius: 25px;
    background-color: #fff;
  }
}
