@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

$campaign-stats-responsive-width: 885px;

ssi-marketing-index {
  $campaign-title-color: $lmode-title-text;
  $index-bg-color: #f6f8f9;
  $light-blue: #59d7df;
  $orange: #eac95c;

  display: block;
  background-color: #f9f9f9;
  margin-left: -15px;
  margin-right: -15px;

  .campaigns-stats {
    background-color: #fff;
    padding: 10px;
    overflow: hidden;

    .highcharts-credits,
    .highcharts-legend {
      display: none;
    }

    .highcharts-container,
    .highcharts-container svg {
      width: 100% !important;
    }

    &.campaigns-stats-ad {
      .graph-filters {
        .stat-number-label,
        a {
          &.active {
            background-color: $primary-active;
          }
        }
      }
      .highchart-controls-action-active {
        color: $primary-active;
      }

      .highcharts-graph {
        stroke: $primary-active !important;
      }
    }

    .highcharts-contextbutton .highcharts-button-symbol {
      stroke: $primary-active !important;
    }
  }

  .campaign-stats-row {
    @media (min-width: $campaign-stats-responsive-width) {
      display: flex;
    }
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .campaign-stats-col {
    flex: 0 1 100%;
    width: 100%;
    @media (min-width: $campaign-stats-responsive-width) {
      margin: 0 30px;
    }
  }

  .campaign-stats-col-chart {
    order: 2;
    padding: 20px 0;

    ssi-highchart {
      display: block;
      min-height: 520px;
      padding: 60px 35px 45px;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    }

    ssi-highchart-controls {
      margin: -110px 30px 80px;
    }
  }

  .campaign-stats-col-filters {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    order: 1;
    flex-wrap: wrap;
    margin-bottom: 10px;
    @media (max-width: $campaign-stats-responsive-width) {
      max-width: 100vw;
    }
  }

  .graph-filters {
    margin: 12px 15px;
    position: relative;

    @media (min-width: $campaign-stats-responsive-width) {
      max-width: 570px;
    }

    text-align: center;

    ul {
      display: inline-flex;
      justify-content: space-between;

      @media (max-width: $campaign-stats-responsive-width) {
        flex-wrap: wrap;
        text-align: center;
        max-width: 100vw;
      }

      padding: 18px 20px;
      border-radius: 40px;
      margin: 0;
      background-color: #fafafc;
    }

    li {
      @media (min-width: $campaign-stats-responsive-width) {
        padding: 0 20px;
      }
      display: block;
      list-style-type: none;
      font-size: 12px;
      text-align: center;
      min-width: 60px;
      color: $campaign-title-color;
    }

    .stat-number-label,
    a {
      display: inline-block;
      color: $lmode-helper-text;
      border-radius: 25px;
      text-decoration: none;
      white-space: nowrap;
      font-weight: 700;

      &.active {
        padding: 2px 10px;
        color: white;
        background-color: $primary-active;
        border: 2px solid #92d7e8;
      }
    }

    .graph-filter-title {
      color: $lmode-helper-text;
      font-size: 11px;
      font-weight: 900;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      margin: 0 0 15px;

      p {
        display: inline-block;
        margin: 0;
        font-weight: 900;

        &:before {
          content: '|';
          margin: 0 10px;
        }
      }

      .floating-label {
        font-size: 8px;
        line-height: 8px;
        padding: 2px;
        background-color: $primary-active;
        color: #fff;
        display: inline-block;
        border-radius: 50%;
        height: 12px;
        width: 12px;
        vertical-align: super;
        cursor: pointer;
        text-align: center;
        letter-spacing: 0;
      }
    }
  }

  .stat-number {
    display: block;
    font-size: 18px;
    color: $lmode-title-text;
    font-weight: 900;
    padding: 0;
    text-transform: lowercase;
  }

  .campaigns-list {
    display: flex;
    flex-wrap: wrap;

    @media (min-width: $campaign-stats-responsive-width) {
      padding: 80px 120px;
    }

    @media (max-width: $campaign-stats-responsive-width) {
      margin-top: 80px;
      max-width: 100vw;
      display: block;
    }
  }

  .campaign {
    position: relative;
    $campaign-margin: 5px;
    flex-basis: calc(33% - (#{$campaign-margin} * 2));
    background-color: white;
    box-shadow: 0 0 10px rgba(60, 60, 60, 0.1);
    padding: 25px 0;
    margin: $campaign-margin;
    text-align: center;
    min-height: 430px;
    @media (max-width: $campaign-stats-responsive-width) {
      width: 100vw;
    }
  }

  .campaign-message {
    padding: 100px 0 400px;
    font-size: 22px;
    text-align: center;
  }

  .campaign-stat {
    background-color: $index-bg-color;
    padding: 5px;
    color: $campaign-title-color;
    font-weight: normal;
    margin: 0 3px;
    font-size: 13px;
    border-radius: 10px;
  }

  .campaign-stats-hide {
    visibility: hidden;
  }

  .campaigns-completed-filter {
    text-align: center;
    margin: -20px 0;
    width: 100%;

    .campaigns-select-wrapper {
      position: relative;
      display: inline-block;
      padding: 0;
      background-color: #fff;
      border-radius: 20px;
      box-shadow: 0, 0, 15px rgba(0, 0, 0, 0.1);
      border: 2px solid #f9f9f9;

      select {
        appearance: none;
        background-color: #fff;
        border: none;
        color: $lmode-body-text;
        font-weight: 700;
        padding: 10px 38px 10px 20px;

        &::-ms-expand {
          display: none;
        }
      }

      &.campaigns-select-wrapper-ad {
        select {
          background-color: $lmode-body-text;
          color: #fff;
        }

        .campaigns-select-icon {
          color: #fff;
        }
      }
    }
  }

  .campaigns-anchor {
    position: relative;
    width: 100%;
    height: 34px;
  }

  .campaigns-select-container {
    width: 100%;
  }

  .campaigns-select-container.fixed {
    position: fixed;
    padding: 35px 0;
    background: linear-gradient(#f9f9f9 80px, transparent);
    top: 68px;
    z-index: 9;
  }

  .campaigns-select-icon {
    position: absolute;
    color: $primary-active;
    font-size: 16px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 16px;
    right: 18px;
    pointer-events: none;
  }

  .campaign-add {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #f9fafb;
  }

  .campaign-add-icon {
    display: block;
    color: $dmode-body-text;
    font-size: 112px;
    line-height: 112px;
  }

  .campaign-add-btn {
    display: inline-block;
    background-color: #fff;
    border: 2px solid #f3f6f7;
    color: $lmode-body-text;
    font-weight: 700;
    width: auto;
    margin: 10px auto;
    padding: 10px 20px;

    &:hover,
    &:active {
      background-color: #fff;
      border-color: #e6eaed;
      color: $campaign-title-color;
    }

    p {
      display: inline;
      color: $lmode-body-text;
      font-weight: 700;
    }

    .ssi {
      color: $primary-active;
      margin-right: 5px;
    }
  }

  .campaign-add-box-wrap {
    position: absolute;
    width: 100%;
    margin-top: -34px;
    z-index: 3;
  }

  .campaign-bottom {
    margin-top: 70px;
    padding: 0 0 100px;
    color: $lmode-body-text;
    font-size: 20px;
    font-weight: 900;
    text-align: center;
    p {
      color: $lmode-body-text;
      font-size: 20px;
      font-weight: 900;
    }
  }

  .campaign-bottom-icon {
    font-size: 70px;
    color: $dmode-body-text;
    margin: 0 0 15px;
  }

  @media (max-width: $campaign-stats-responsive-width) {
    .campaign-stats-row {
      max-width: 100vw;
    }
  }

  @media (min-width: 1366px) {
    .campaign-stats-row {
      flex-wrap: nowrap;
    }

    .campaign-stats-col {
      flex: 0 1 50%;
      width: 50%;
    }

    .campaign-stats-col-chart {
      order: 1;

      ssi-highchart {
        min-height: 400px;
        padding: 0;
        box-shadow: none;
      }

      ssi-highchart-controls {
        margin: -50px 0 10px;
      }
    }

    .campaign-stats-col-filters {
      order: 2;
      flex-direction: column;
      padding: 20px 0 40px 0;
    }

    .graph-filters {
      text-align: right;

      li,
      .graph-filter-title {
        text-align: right;
      }
    }
  }
}
