@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import './../../../../angularjs/common/sass/variables';
@import './../../../../angularjs/common/sass/mixins';

$ssi-outbox-post-list-responsive-width: 885px;

ssi-outbox-post-list {

  .scroll-y {
    border: 1px solid #eee;
  }
}

ssi-outbox-post-list {
  @media (max-width: $ssi-outbox-post-list-responsive-width) {
    .outbox-post-list-responsive {
      height: 95vh !important;
    }
  }
  .panel {
    box-shadow: $box-shadow-default;
    border-radius: 0;
    border-color: #eee;
  }

  .panel-body {
    padding: 5px;
    position: relative;
  }

  .media-left {
    padding: 10px;
  }

  .media-body {
    padding-top: 10px;
  }

  .media-content > * {
    white-space: pre-wrap;
  }

  .media-right {
    padding-right: 5px;

    a,
    img {
      width: 90px;
      height: 90px;
      box-shadow: $box-shadow-default;
      border-radius: 50%;
    }

    a {
      display: inline-block;
      text-align: center;
      @include align-vertical(90px);
    }

    a:hover {
      text-decoration: none;
    }

    img {
      object-fit: cover;
    }
  }

  .has-bottom-stats {
    .media-right {
      padding-right: 12px;
      padding-top: 12px;
    }
  }

  .media-heading {
    margin-bottom: 12px;
  }

  .media-heading a {
    padding-right: 5px;
  }

  .media-heading small {
    font-size: 12px;
    color: $text-dark;
  }

  .media-sub-heading {
    margin-top: 3px;
  }

  .children-separated *:not(:last-child):after {
    content: ' | ';
    padding: 0 5px;
  }

  .children-separated :last-child {
    padding-right: 5px;
  }

  .outbox-post-btns {
    margin-bottom: 10px;
    .btn {
      margin-right: 3px;
    }

    a:hover {
      text-decoration: none;
    }

    .outbox-post-stat {
      margin-right: 7px;
    }
  }

  .outbox-menu {
    position: absolute;
    right: $section-margin-width;
    top: $section-margin-width;
    z-index: 1;

    .outbox-menu-items {
      display: none;
    }
  }

  .outbox-menu-items {
    background-color: #ffffff;
    padding: 7px;
    min-width: 35px;
    border-radius: 20px;
    text-align: center;
    box-shadow: $box-shadow-default;

    a {
      padding: 0 7px;
    }

    a:hover {
      text-decoration: none;
    }
  }

  .outbox-menu:not(.no-hover):hover {
    .outbox-menu-trigger {
      display: none;
    }
    .outbox-menu-items {
      display: inline-block;
    }
  }

  .outbox-menu-trigger {
    width: 33px;
    @include align-vertical(33px);
    box-shadow: $box-shadow-default;
    border-radius: 50%;
  }

  .no-media .media-body {
    padding-right: 10px;
  }

  .media-nav-arrow.left {
    left: -10px !important;
  }

  .media-nav-arrow.right {
    right: -10px !important;
  }

  .outbox-post-stats-container {
    margin-left: 62px;
    padding-bottom: $section-margin-width;
  }

  .outbox-post-advert-icon {
    position: relative;
    left: 3px;
    top: -15px;
  }

  .unvalidated {
    color: #526bb4;
    margin-left: 5px;
  }

  // outbox-post and outbox-post-stats styles
  .outbox-post-stat {
    font-size: 10px;
    font-weight: normal;
    display: inline-block;
    position: relative;
    top: 2px;
    margin-right: 25px;
    color: #bbc2c8;

    i {
      font-size: 14px;
    }

    .outbox-post-stat-count {
      position: relative;
      top: -4px;
    }
  }

  a.outbox-post-stat {
    color: #bbced8;
  }

  a.outbox-post-stat:hover {
    text-decoration: none;
  }
}

.ad-budget-tooltip {
  padding: 3px 8px;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
  font-weight: normal;
  font-size: 11px;
}
