ssi-stat-bar-tile {
  font-size: 1rem;
}
ssi-stat-bar-tile .sbt {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 1.2em;
  box-shadow: 0 0.5em 1em rgba(131, 142, 171, 0.35);
  height: 15em;
  width: 100%;
  padding: 3.6em 3.2em 4.3em 3.2em;
  margin: 0 0 2em;
}
@media print {
  ssi-stat-bar-tile .sbt {
    border: 1px solid black;
  }
}
ssi-stat-bar-tile .sbt p {
  margin-bottom: 0;
}
ssi-stat-bar-tile .sbt-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
ssi-stat-bar-tile .sbt-header-right {
  display: flex;
}
ssi-stat-bar-tile .sbt-title {
  color: #838eab;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 900;
  text-rendering: geometricPrecision;
  font-size: 1em;
  margin: 0 0 0 -0.6em;
}
ssi-stat-bar-tile .sbt-value {
  color: #101525;
  font-weight: 900;
  text-rendering: geometricPrecision;
  font-size: 2.8em;
}
ssi-stat-bar-tile .sbt-icon {
  color: #838eab;
  font-size: 3.6em;
  margin: 0 0 0 1.3rem;
}
ssi-stat-bar-tile .sbt-progress {
  position: relative;
  background-color: #f4f4fa !important;
  border-radius: 10em;
  height: 0.4em;
  width: 100%;
  -webkit-print-color-adjust: exact;
}
ssi-stat-bar-tile .sbt-progress span {
  position: absolute;
  background-color: #425dec;
  display: inline-block;
  width: 0;
  height: 100%;
  border-radius: 10em;
  max-width: 100%;
  min-width: 0;
  -webkit-print-color-adjust: exact;
}
@media print {
  ssi-stat-bar-tile .sbt-progress span {
    background-color: black !important;
  }
}