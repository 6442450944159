ssi-navbar {
  text-transform: capitalize;
}
ssi-navbar .navbar-inverse {
  background-color: #35353f;
  color: #80858e;
}
ssi-navbar .navbar-brand {
  padding: 18px 15px;
}
ssi-navbar .navbar-brand:hover circle, ssi-navbar .navbar-brand:focus circle {
  stroke: #fff;
}
ssi-navbar .navbar-brand:hover path, ssi-navbar .navbar-brand:focus path {
  fill: #fff;
}
ssi-navbar .navbar-brand:active circle {
  stroke: #14bae3;
}
ssi-navbar .navbar-brand:active path {
  fill: #14bae3;
}
ssi-navbar .navbar .nav > li > a,
ssi-navbar .navbar .nav > li > button {
  color: inherit;
  padding: 23px 15px 25px;
}
ssi-navbar .navbar .nav > .active > a,
ssi-navbar .navbar .nav > .active > a:hover,
ssi-navbar .navbar .nav > .active > a:focus,
ssi-navbar .navbar .nav > .show > a,
ssi-navbar .navbar .nav > .show > a:hover,
ssi-navbar .navbar .nav > .show > a:focus {
  background-color: #35353f;
  color: #14bae3;
}
ssi-navbar .navbar .nav .compose-post {
  padding: 2px;
}
ssi-navbar .navbar .nav .compose-post a {
  border-radius: 10px;
  background-color: #40424e;
  color: #14bae3;
  font-weight: bold;
  font-size: 11px;
  padding: 0;
  margin: 16px;
  width: fit-content;
}
ssi-navbar .navbar .nav .compose-post span {
  margin: 0 14px 0 2px;
  text-transform: uppercase;
}
ssi-navbar .navbar .nav .compose-post i:first-child {
  padding: 10px 2px 10px 15px;
}
ssi-navbar .navbar .nav .compose-post i.toggle-publisher {
  border-radius: 50px;
  background-color: #4b4c56;
  padding: 7px;
  margin: 3px;
}
ssi-navbar .navbar .nav .resource-guide-item {
  margin-left: 15px;
  width: 50px;
}
ssi-navbar .navbar .navbar-right {
  display: flex;
  align-items: center;
}
ssi-navbar .navbar .navbar-right > li:last-child {
  padding-right: 15px;
}
ssi-navbar .navbar .navbar-right > li > a {
  padding: 23px 10px;
}
ssi-navbar .navbar .navbar-right > li > button {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 20px 10px;
}
ssi-navbar .navbar .navbar-right > li > button:focus, ssi-navbar .navbar .navbar-right > li > button:hover {
  background-color: #35353f;
  color: #fff;
}
ssi-navbar .navbar .navbar-right > li > button i {
  font-size: 24px;
}
ssi-navbar .navbar .navbar-right > li > button.navbar-live-chat-toggle.active {
  color: #49aee6 !important;
}
ssi-navbar .navbar .navbar-right-action-icon {
  font-size: 20px;
}
ssi-navbar .navbar-right-notification {
  color: #14bae3;
}
ssi-navbar .nav-item-icon {
  font-size: 14px;
  vertical-align: middle;
  padding-right: 5px;
}
ssi-navbar .nav-item-label {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 14px;
  vertical-align: middle;
}
ssi-navbar .nav-item-caret {
  position: absolute;
  right: -2px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 12px;
}
ssi-navbar .navbar-header {
  height: navbar-height;
  line-height: auto;
  max-width: 100vw;
}
ssi-navbar .navbar-header .navbar-toggle {
  float: none;
  margin: 0;
  position: absolute;
  font-size: 1.5em;
  right: 6px;
  top: 6px;
}
ssi-navbar .navbar-header .navbar-toggle.resource-guide-active {
  right: 66px;
}
ssi-navbar .navbar-support .label {
  border-radius: 15px;
  font-size: 1em;
  padding: 2.5px 10px;
  cursor: pointer;
  font-weight: normal;
}
ssi-navbar .navbar-support .label .fa {
  margin-right: 2.5px;
}
ssi-navbar .navbar-support .label span {
  font-size: 0.85em;
}
ssi-navbar .navbar-support a {
  padding-right: 0;
  padding-left: 5px;
}
@media (max-width: 1150px) {
  ssi-navbar .navbar-toggle {
    display: block;
  }
  ssi-navbar .navbar-header {
    float: none;
  }
  ssi-navbar .navbar-collapse {
    padding: 0 15px !important;
    margin: 0 -15px !important;
    max-width: 100vw;
  }
  ssi-navbar .navbar-collapse.collapse {
    display: none !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: auto !important;
  }
  ssi-navbar .navbar-collapse.collapse .navbar-nav {
    float: none !important;
    margin: 7.5px -15px;
  }
  ssi-navbar .navbar-collapse.collapse .navbar-nav > li {
    float: none;
    max-width: 95vw;
  }
  ssi-navbar .navbar-collapse.collapse .navbar-nav .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  ssi-navbar .navbar-collapse.collapse.show {
    display: block !important;
  }
}
ssi-navbar .workflows-selector .nav-item-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px;
  display: inline-block;
  overflow: hidden;
}

@media only screen and (max-width: 1048px) {
  .navbar-nav .show .dropdown-menu > li > a {
    color: #9d9d9d;
    font-size: 1.2em;
  }
  .navbar-nav .show .dropdown-menu > li > a:hover {
    color: #fff;
    background-color: transparent;
  }
  .navbar-nav .show .dropdown-menu > .active {
    background-color: #090909 !important;
  }
  .navbar-nav .show .dropdown-menu > .active a {
    color: #fff;
  }
  .navbar .nav li.compose-post a {
    margin: 16px 0;
  }
}