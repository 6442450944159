@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-enlarge-image-modal {
  .modal-header {
    .ssi-arrow-down-new {
      display: none;
    }
  }

  .opn-modal-gallery {
    color: red;

    .modal-header {
      padding: 20px;
      border-bottom: 1px solid #c3c9d8;

      .ssi-arrow-down-new {
        display: block;
      }

      .fa-angle-left,
      .fa-angle-right {
        display: none;
      }
    }

    .btn-default {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      width: 36px;
      background-color: #f4f4fa;
      color: #838eab;
      border-width: 0;
      border-radius: 50%;

      i {
        font-size: 16px;
        line-height: 1;

        &.arrow-down-new-left {
          transform: rotate(90deg);
          font-size: 12px;
        }

        &.arrow-down-new-right {
          transform: rotate(270deg);
          font-size: 12px;
        }
      }
    }

    img {
      border-radius: 6px;
    }

    .eim-zoom,
    .eim-count {
      display: none;
    }
  }

  .eim-alt-text {
    margin: 0;

    p {
      font-size: 14px;
      color: #101525;
      font-weight: 700;
      line-height: 1.4;
      margin: 20px 20px 10px;

      &:empty {
        margin: 0;
      }
    }
  }
}
