ssi-widget-rss-feeds {
  height: 100%;
  width: 100%;
}
ssi-widget-rss-feeds .dashboard-widget {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 10px;
}
ssi-widget-rss-feeds .dashboard-widget label.field-title {
  font-family: "Lato", "Arial", sans-serif;
  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  padding-left: 20px;
  color: #43537f;
}
ssi-widget-rss-feeds .dashboard-widget ssi-dropdown-select-2 {
  width: 100%;
}
ssi-widget-rss-feeds .dashboard-widget ssi-dropdown-select-2 .dd-head {
  width: 100%;
}
ssi-widget-rss-feeds .dashboard-widget ssi-dropdown-select-2 .dd-body {
  width: 100%;
}
ssi-widget-rss-feeds .dashboard-widget ssi-dropdown-select-2 .select-accounts-label {
  display: flex;
  align-items: center;
}
ssi-widget-rss-feeds .dashboard-widget .article-grid {
  height: 85%;
  overflow: auto;
  margin-top: 7px;
}
ssi-widget-rss-feeds .dashboard-widget .article-grid.view-1x1, ssi-widget-rss-feeds .dashboard-widget .article-grid.view-2x1, ssi-widget-rss-feeds .dashboard-widget .article-grid.view-3x1, ssi-widget-rss-feeds .dashboard-widget .article-grid.view-4x1 {
  height: 60%;
}
ssi-widget-rss-feeds .dashboard-widget .article-grid.view-3x1, ssi-widget-rss-feeds .dashboard-widget .article-grid.view-3x2, ssi-widget-rss-feeds .dashboard-widget .article-grid.view-3x3, ssi-widget-rss-feeds .dashboard-widget .article-grid.view-3x4, ssi-widget-rss-feeds .dashboard-widget .article-grid.view-4x1, ssi-widget-rss-feeds .dashboard-widget .article-grid.view-4x2, ssi-widget-rss-feeds .dashboard-widget .article-grid.view-4x3, ssi-widget-rss-feeds .dashboard-widget .article-grid.view-4x4 {
  display: grid;
  grid-template-columns: 50% 50%;
}
ssi-widget-rss-feeds .dashboard-widget .message-info {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-align: center;
}
ssi-widget-rss-feeds .dashboard-widget .message-info.view-1x1, ssi-widget-rss-feeds .dashboard-widget .message-info.view-2x1, ssi-widget-rss-feeds .dashboard-widget .message-info.view-3x1, ssi-widget-rss-feeds .dashboard-widget .message-info.view-4x1 {
  justify-content: start;
  padding-top: 10px;
}
ssi-widget-rss-feeds .dashboard-widget .message-info i {
  color: #b4bbce;
  font-size: 37.5px;
}
ssi-widget-rss-feeds .dashboard-widget .message-info h5 {
  font-family: "Lato", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
  color: #43537f;
}
ssi-widget-rss-feeds .dashboard-widget .message-info p {
  font-family: "Lato", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  max-width: 222px;
  color: #43537f;
}
ssi-widget-rss-feeds .dashboard-widget .message-info p.view-1x1, ssi-widget-rss-feeds .dashboard-widget .message-info p.view-2x1, ssi-widget-rss-feeds .dashboard-widget .message-info p.view-3x1, ssi-widget-rss-feeds .dashboard-widget .message-info p.view-4x1 {
  max-width: 312px;
}
ssi-widget-rss-feeds .dashboard-widget ssi-rss-feed-article .post-body {
  height: 160px;
}