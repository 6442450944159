ssi-monitoring-streams {
  font-size: 1rem;
}
ssi-monitoring-streams .monitoring-page {
  position: relative;
  background-color: #f8f9fd;
  min-height: calc(100vh - 68px);
  overflow-x: hidden;
}
ssi-monitoring-streams .monitoring-page.monitoring-page-reporting {
  background-color: #f0f7f7;
}
ssi-monitoring-streams .monitoring-header {
  position: fixed;
  z-index: 99;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 3em;
  background-color: #fff;
  box-shadow: 0 0.5em 1em rgba(131, 142, 171, 0.2);
  height: 9.4em;
  overflow: hidden;
}
ssi-monitoring-streams .monitoring-header button {
  border-radius: 10px;
}
ssi-monitoring-streams .monitoring-header-left {
  padding: 0 1em;
  flex: 1 0 auto;
}
ssi-monitoring-streams .monitoring-header-title {
  font-weight: 900;
  text-rendering: geometricPrecision;
  text-transform: uppercase;
  font-size: 1.4em;
  line-height: 1;
  letter-spacing: 0.1rem;
  color: #101525;
  margin: 0;
}
ssi-monitoring-streams .monitoring-body {
  padding-top: 9.4em;
}
ssi-monitoring-streams .monitoring-container {
  max-width: 140em;
  padding: 0 2em;
  margin: 4em auto;
}
ssi-monitoring-streams .monitoring-message {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 1.2em;
  padding: 2em;
  margin: 2em auto;
  color: #43537f;
}
@media (min-width: 768px) {
  ssi-monitoring-streams .monitoring-message {
    padding: 4em 7em;
  }
}
ssi-monitoring-streams .monitoring-message h2 {
  font-size: 2em;
  margin: 0 0 0.6em;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-monitoring-streams .monitoring-message p {
  line-height: 2rem;
  font-weight: 700;
  margin: 0;
  text-rendering: geometricPrecision;
}
ssi-monitoring-streams .monitoring-message-left {
  flex: 0 1 60em;
  max-width: 60em;
  margin: 0.6em 0;
}
ssi-monitoring-streams .monitoring-message-right {
  align-self: flex-end;
}
ssi-monitoring-streams .monitoring-message-right img {
  display: none;
  width: 31em;
  height: 17.5em;
  margin: 0 0 -3em 2em;
  object-fit: cover;
  object-position: 0 0;
}
@media (min-width: 768px) {
  ssi-monitoring-streams .monitoring-message-right img {
    display: block;
  }
}
@media (min-width: 992px) {
  ssi-monitoring-streams .monitoring-message-right img {
    margin: -1.5em 5em -4em 2em;
  }
}
ssi-monitoring-streams .monitoring-actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 5em 0 2.6em;
  width: 100%;
}
ssi-monitoring-streams .monitoring-actions > * {
  flex: 0 1 auto;
  margin: 0 0 1.6em;
}
ssi-monitoring-streams .monitoring-actions .monitoring-actions-center {
  flex: 1 0 0;
  text-align: center;
}
ssi-monitoring-streams .monitoring-actions .monitoring-actions-right {
  text-align: right;
}
ssi-monitoring-streams .monitoring-actions-search .ssi {
  color: #43537f;
  font-size: 1.2em;
  margin: 0 0.6rem 0 0;
}
ssi-monitoring-streams .monitoring-actions-search input {
  color: #43537f;
  font-size: 1.4em;
  font-weight: 700;
  text-rendering: geometricPrecision;
  border-width: 0;
  outline: 0;
  background-color: transparent;
  min-width: 190px;
}
ssi-monitoring-streams .monitoring-actions-search input::placeholder {
  color: #838eab;
}
ssi-monitoring-streams .monitoring-actions-filter {
  position: relative;
  display: inline-block;
  color: #43537f;
  font-size: 1em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 900;
  text-rendering: geometricPrecision;
  padding: 0.3em 1em 0.6em;
  margin: 0 0.6em;
  cursor: pointer;
}
ssi-monitoring-streams .monitoring-actions-filter.monitoring-actions-filter-active:after {
  content: "";
  position: absolute;
  bottom: -0.4em;
  left: 0;
  right: 0;
  margin: auto;
  display: inline-block;
  height: 0.4em;
  width: 5em;
  border-radius: 10em;
  background-color: #12aca4;
}
ssi-monitoring-streams .monitoring-actions-sort {
  margin-top: 0.4em;
  text-align: right;
  color: #43537f;
  font-size: 1em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 900;
  text-rendering: geometricPrecision;
  cursor: pointer;
}
ssi-monitoring-streams .monitoring-actions-sort i {
  font-size: 1.4em;
  color: #838eab;
}
ssi-monitoring-streams ssi-dropdown-select-2 {
  display: inline-block;
  min-width: 160px;
}
ssi-monitoring-streams .monitoring-streams-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1em 2.4em;
}
ssi-monitoring-streams .monitoring-streams-grid-item {
  flex: 1 0 0%;
  width: 100%;
  margin: 0 1em;
}
@supports (display: grid) {
  ssi-monitoring-streams .monitoring-streams-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25.6em, 1fr));
    grid-gap: 2em;
    margin: 0;
  }
  ssi-monitoring-streams .monitoring-streams-grid .monitoring-streams-grid-item {
    margin: 0;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  ssi-monitoring-streams .monitoring-streams-grid {
    display: flex;
    margin: 0 -1em 2.4em;
  }
  ssi-monitoring-streams .monitoring-streams-grid .monitoring-streams-grid-item {
    margin: 0 1em;
  }
}
ssi-monitoring-streams .insights-streams-tile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0.1em solid #12aca4;
  background-color: #fff;
  border-radius: 1.2em;
  height: 36em;
}
ssi-monitoring-streams .insights-streams-tile-actionable {
  cursor: pointer;
}
ssi-monitoring-streams .insights-streams-tile-actionable:hover, ssi-monitoring-streams .insights-streams-tile-actionable:focus {
  border-color: #14bae3;
}
ssi-monitoring-streams .insights-streams-tile h3 {
  font-size: 1.4em;
  margin: 0.6rem 0 2rem;
  color: #43537f;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-monitoring-streams .insights-streams-tile p {
  font-size: 1em;
  margin: -1.2em 0 2.6em;
  color: #43537f;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-monitoring-streams .insights-streams-tile button {
  color: #fff;
  background-color: #12aca4;
  border-width: 0;
  border-radius: 1rem;
  font-size: 1.4em;
  font-weight: 900;
  text-rendering: geometricPrecision;
  line-height: 1;
  padding: 1.1rem 2.1rem;
}
ssi-monitoring-streams .insights-streams-tile button i {
  font-size: 1.2rem;
  margin: 0 0.5rem 0 0;
}
ssi-monitoring-streams .insights-streams-tile .insights-streams-tile-add {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 1rem;
  height: 5rem;
  width: 5rem;
  padding: 0;
  color: #fff;
  border-radius: 50%;
}
ssi-monitoring-streams .insights-streams-tile .insights-streams-tile-add i {
  font-size: 1.2rem;
  margin: 0;
  color: inherit;
}
ssi-monitoring-streams .insights-streams-tile.insights-streams-tile-inbox {
  border-color: #89a4ea;
}
ssi-monitoring-streams .insights-streams-tile.insights-streams-tile-inbox button {
  background-color: #14bae3;
}
ssi-monitoring-streams .monitoring-streams-grid-item-reporting .stream-card {
  border-color: #12aca4;
}
ssi-monitoring-streams .monitoring-streams-grid-item-reporting .stream-card:hover {
  background-color: #e7f4f4;
}
ssi-monitoring-streams .monitoring-new-enhanced-body {
  border: 0.1em solid #12aca4;
  color: #43537f;
  background-color: #fff;
  border-radius: 1.2em;
  text-align: center;
  padding: 12em;
}
ssi-monitoring-streams .monitoring-new-enhanced-body h2 {
  font-size: 2em;
  font-weight: 900;
  text-rendering: geometricPrecision;
  margin: 0;
  line-height: 2;
}
ssi-monitoring-streams .monitoring-new-enhanced-body p {
  font-size: 1.4em;
  font-weight: 700;
  text-rendering: geometricPrecision;
  margin: 0;
}
ssi-monitoring-streams .monitoring-new-enhanced-body orlo-button.monitoring-new-enhanced-body-action {
  display: inline-block;
  margin: 24px 0 0;
  height: 3.6em;
}
ssi-monitoring-streams .monitoring-new-enhanced-body orlo-button.monitoring-new-enhanced-body-action button {
  font-size: 14px;
  line-height: 1;
  padding: 9px 11px 9px 14px;
  border-radius: 10px;
}
ssi-monitoring-streams .monitoring-new-enhanced-body orlo-button.monitoring-new-enhanced-body-action .leading-icon {
  font-size: 12px;
  line-height: 12px;
  margin: 0 3px 0 0;
}
ssi-monitoring-streams .monitoring-new-enhanced-body orlo-button.monitoring-new-enhanced-body-action .leading-icon + span {
  vertical-align: middle;
}
ssi-monitoring-streams .monitoring-new-twitter-body {
  margin: 7.2em 0 2em;
  text-align: center;
  min-width: 25.6em;
}
ssi-monitoring-streams .monitoring-new-twitter-body img {
  height: 10em;
  margin: 0 0 1.4em;
  pointer-events: none;
}
ssi-monitoring-streams .monitoring-new-twitter-body h2 {
  font-size: 2em;
  color: #43537f;
  font-weight: 900;
  text-rendering: geometricPrecision;
  margin: 0;
  line-height: 2;
}
ssi-monitoring-streams .monitoring-new-twitter-body p {
  font-size: 1.4em;
  color: #838eab;
  font-weight: 700;
  text-rendering: geometricPrecision;
  margin: 0;
}
ssi-monitoring-streams .monitoring-new-twitter-action {
  display: inline-flex;
  margin: 2em 0 0;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #14bae3;
  color: #fff;
  height: 4.6em;
  width: 4.6em;
  cursor: pointer;
}
ssi-monitoring-streams .monitoring-new-twitter-action:hover, ssi-monitoring-streams .monitoring-new-twitter-action:focus {
  background-color: #13aed5;
}
ssi-monitoring-streams ssi-paginator {
  font-size: 1.4em;
}
ssi-monitoring-streams ssi-paginator .flx-paginator {
  margin: 4em 0 0;
}
ssi-monitoring-streams ssi-paginator .flx-paginator button.navigate-btn,
ssi-monitoring-streams ssi-paginator .flx-paginator button.page-link,
ssi-monitoring-streams ssi-paginator .flx-paginator button.page-link.current {
  background-color: transparent;
}
ssi-monitoring-streams .listening-container {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 1.2em;
  padding: 2em;
  margin: 2em auto;
  min-height: 300px;
}
ssi-monitoring-streams .listening-container.blank-view {
  justify-content: center;
  padding: 10em 2em;
}
ssi-monitoring-streams .listening-container .confirm-action-tooltip-window {
  border-radius: 12px;
}
ssi-monitoring-streams .listening-container .confirm-action-tooltip-window .tooltip-actions {
  width: 400px;
}
ssi-monitoring-streams .table-container {
  width: 100%;
  position: relative;
}
ssi-monitoring-streams .table-container > button {
  position: absolute;
  top: 0;
  right: 0;
  appearance: none;
  outline: none;
  text-decoration: underline;
  border: none;
  font-weight: 900;
  font-size: 10px;
  color: #43537f;
  background-color: transparent;
  text-transform: uppercase;
  letter-spacing: 1px;
}
ssi-monitoring-streams .table-container > button i {
  margin-left: 7px;
}
ssi-monitoring-streams .table-container .cell {
  background-color: transparent !important;
}
ssi-monitoring-streams .table-container .table-cell {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 5px 0 10px;
  color: #43537f;
  font-size: 12px;
  font-weight: 900;
  line-height: 14px;
  border-top: 2px solid #e0e7f4;
}
ssi-monitoring-streams .table-container .table-cell.row-odd {
  background-color: #f1f7f7;
}
ssi-monitoring-streams .table-container .table-cell.light {
  font-weight: 700;
}
ssi-monitoring-streams .table-container .actions-cell {
  justify-content: flex-end;
}
ssi-monitoring-streams .table-container .actions-cell > button {
  border: 1px solid #838eab;
  padding: 10px;
  border-radius: 10px;
  outline: none;
  appearance: none;
}
ssi-monitoring-streams .table-container .actions-cell > button:first-child {
  margin-right: 5px;
  color: #838eab;
  background-color: #fff;
  border: 1px solid #838eab;
}
ssi-monitoring-streams .table-container .actions-cell > button:last-child {
  color: #43537f;
  background-color: #fff1ed;
  border: 1px solid #e34916;
}
ssi-monitoring-streams .table-container .actions-cell > button:last-child i {
  color: #e34916;
}
ssi-monitoring-streams .hashtag-container {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 1.2em;
  padding: 2em;
  margin: 2em auto;
  min-height: 300px;
}
ssi-monitoring-streams .hashtag-container.blank-view {
  justify-content: center;
  padding: 10em 2em;
}
ssi-monitoring-streams .hashtag-container .call-to-action span i {
  background-color: #0d827c;
  font-size: 14px;
}
ssi-monitoring-streams .call-to-action {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 220px;
}
ssi-monitoring-streams .call-to-action > i {
  font-size: 48px;
  color: #b4bbce;
  margin-bottom: 10px;
}
ssi-monitoring-streams .call-to-action h2 {
  color: #43537f;
  font-weight: 900;
  font-size: 20px;
}
ssi-monitoring-streams .call-to-action p {
  font-size: 14px;
  font-weight: bold;
  color: #43537f;
  text-align: center;
}
ssi-monitoring-streams .call-to-action span {
  cursor: pointer;
  margin-top: 30px;
}
ssi-monitoring-streams .call-to-action span i {
  color: #fff;
  padding: 18px;
  border-radius: 50%;
  background-color: #4eaaa4;
}
ssi-monitoring-streams .call-to-action a {
  color: #fff;
  font-weight: 900;
  font-size: 14px;
  background-color: #14bae3;
  padding: 8px 20px;
  border-radius: 10px;
  margin-top: 20px;
}
ssi-monitoring-streams .call-to-action a:hover {
  text-decoration: none;
}