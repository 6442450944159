.form-grid {
  display: flex;
  flex-wrap: wrap;
  padding: 5rem 8rem;
  margin: 0 auto;
}
.form-grid.align-left {
  margin: 0;
}
.form-grid .form-submit-fixed {
  position: absolute;
  top: -16px;
  width: 190px;
  right: 0;
  text-align: center;
}
.form-grid .form-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 3rem;
}
@media (max-width: 767px) {
  .form-grid .form-row {
    display: block;
  }
}
.form-grid .form-field {
  width: 100%;
}
.form-grid .form-field:first-child:not(:only-child) {
  margin-right: 2rem;
}
.form-grid .form-field-label {
  display: block;
  padding: 0 20px;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1px;
  margin-bottom: 1rem;
  color: #838eab;
  font-weight: bold;
}
.form-grid .form-field.half {
  width: 50%;
}
.form-grid .form-field.half:only-child {
  padding-right: 10px;
}
@media (max-width: 767px) {
  .form-grid .form-field.half {
    width: 100%;
    padding-right: 0 !important;
  }
}
@media (max-width: 767px) {
  .form-grid .form-field {
    margin-bottom: 10px;
  }
}
.form-grid textarea {
  width: 100%;
  background-color: #f0f3f9;
  border-radius: 10px;
  padding: 20px;
  border-color: #fff;
  resize: none;
}
.form-grid textarea:focus {
  border-color: #14bae3;
  outline-color: #14bae3;
}

ssi-edit-widget h2 {
  font-weight: 900;
  color: #838eab;
  margin-bottom: 2em;
}
ssi-edit-widget p {
  color: #838eab;
  margin-bottom: 2em;
}
ssi-edit-widget form .form-actions {
  position: absolute;
  right: 45px;
  top: 7px;
}
ssi-edit-widget form .form-actions button {
  margin-right: 1em;
}
ssi-edit-widget form .form-segment {
  background: #fff;
  border: 2.5em solid #fff;
  border-radius: 25px;
  margin-bottom: 2em;
}
ssi-edit-widget form .form-segment.full-height {
  height: 100%;
  margin: 0;
}
ssi-edit-widget form .form-segment.margin-right {
  margin-right: 2em;
}
ssi-edit-widget form .form-grid {
  padding: 0 4em;
}
ssi-edit-widget form .form-group {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 4em;
}
ssi-edit-widget form .form-group orlo-text-input .text-input span {
  float: none !important;
}
ssi-edit-widget form .form-group.full-width-fields orlo-text-input {
  width: 100%;
}
ssi-edit-widget form .form-group.full-width-fields orlo-text-input label {
  width: inherit !important;
  display: inline-block !important;
}
ssi-edit-widget form .form-group.slim {
  padding: 0 2em;
}
ssi-edit-widget form .form-group .orlo-select {
  margin-right: 2em;
}
ssi-edit-widget form .form-group .orlo-select.wide {
  min-width: 300px;
}
ssi-edit-widget form .form-group .orlo-select i {
  top: 48px;
}
ssi-edit-widget form .form-group .orlo-textarea {
  flex: 1;
}
ssi-edit-widget form .form-group input {
  font-weight: 700 !important;
  color: #43537f;
}
ssi-edit-widget form .form-group input:disabled {
  opacity: 0.5;
}
ssi-edit-widget form .form-group input[type=text] {
  min-width: 400px;
}
ssi-edit-widget form .form-group input[type=number] {
  max-width: 100px;
}
ssi-edit-widget form .form-group input[type=number]::-webkit-inner-spin-button,
ssi-edit-widget form .form-group input[type=number] ::-webkit-outer-spin-button {
  opacity: 1;
}
ssi-edit-widget form .form-group .control-label,
ssi-edit-widget form .form-group .field-label {
  width: 100%;
  padding: 0 3em;
  display: block;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 900;
  line-height: 2.5em;
  color: #838eab;
  letter-spacing: 1px;
  box-sizing: border-box;
  margin-bottom: 5px;
}
ssi-edit-widget form .form-group .control-label.tooltip-attached,
ssi-edit-widget form .form-group .field-label.tooltip-attached {
  width: auto;
  display: inline-block;
}
ssi-edit-widget form .form-group .form-upload {
  flex: 1;
  text-align: center;
}
ssi-edit-widget form .form-group .form-upload img {
  display: block;
  margin: 2em auto;
  max-width: 400px;
  max-height: 170px;
  width: auto;
  height: auto;
}
ssi-edit-widget form .form-group .form-toggle {
  flex: 1;
  margin-right: 2rem;
  max-width: 400px;
}
ssi-edit-widget form .form-group .form-toggle.compact {
  max-width: 300px;
}
ssi-edit-widget form .box-color {
  margin-left: 3em;
  color: #14bae3 !important;
}
ssi-edit-widget form .plus {
  padding: 12px 14px;
}
ssi-edit-widget form .flex-grid {
  margin-bottom: 2em;
}
ssi-edit-widget .delete-widget-tooltip {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  max-width: 270px;
  height: 100%;
  box-shadow: 0 0 3px 3px #eff0f5;
}
ssi-edit-widget .delete-widget-tooltip .buttons-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
ssi-edit-widget .delete-widget-tooltip .buttons-container button {
  text-transform: uppercase;
  padding: 0 5px;
  background-color: transparent;
}
ssi-edit-widget .delete-widget-tooltip .buttons-container button span {
  font-weight: bold;
}
ssi-edit-widget .delete-widget-tooltip h5 {
  color: #838eab;
  white-space: normal;
  text-transform: none;
}
ssi-edit-widget .new-chat-questions {
  max-width: 100%;
}
ssi-edit-widget .new-chat-questions .orlo-select {
  width: 130px;
  margin: 0 !important;
}
ssi-edit-widget .new-chat-questions .form-toggle {
  margin-right: 0 !important;
}
ssi-edit-widget .new-chat-questions .form-toggle.no-label {
  margin-top: 2em;
}
ssi-edit-widget .new-chat-questions .form-field {
  margin-right: 0 !important;
  flex-grow: 1;
  padding: 0 0.4em;
}
ssi-edit-widget .new-chat-questions .input-container {
  width: 290px;
}
ssi-edit-widget .new-chat-questions input[type=text] {
  min-width: auto !important;
}

.return-nav {
  position: absolute;
  top: -16px;
  width: 150px;
  left: 35px;
}