@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-account-build {
  font-size: 1rem;

  .account-build-container {
    display: flex;
    flex-direction: column;

    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100px;
      padding: 0 60px;
      background-color: $lgrey-background;
      box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.25);
      z-index: 1;

      h3 {
        font-size: 14px;
        color: $lmode-title-text;
        font-weight: 900;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
      h2 {
        color: $lmode-title-text;
        font-weight: 900;
        font-size: 20px;
      }
    }

    .body {
      height: calc(100vh - 168px);
      padding: 0 20px 20px 20px;
      background-color: $lgrey-background;
      overflow: auto;

      .body-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        min-height: calc(100vh - 188px);
        padding-bottom: 60px;
        background-color: #fff;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;

        form {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .form-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-top: 15px;

            &.extra-margin-top {
              margin-top: 50px;
            }

            .form-field {
              display: flex;
              flex-direction: column;
              flex-basis: 100%;

              &.half {
                flex-basis: 48%;
              }

              > label {
                color: $lmode-body-text;
                font-size: 10px;
                font-weight: 900;
                letter-spacing: 1px;
                text-transform: uppercase;
                margin-left: 20px;
                margin-bottom: 15px;

                .ssi {
                  font-size: 13px;
                }
              }

              .input-wrapper {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                &.select-all-accounts {
                  justify-content: flex-end;
                }

                .inline-select-item {
                  display: flex;
                  align-items: center;
                  color: $lmode-body-text;
                  font-size: 12px;
                  font-weight: 900;
                  padding: 10px;
                  border-radius: 10px;
                  background-color: $grey-background;
                  margin-right: 10px;
                  cursor: pointer;
                  user-select: none;
                  white-space: nowrap;

                  i {
                    margin-right: 10px;
                  }

                  &.selected {
                    color: $primary-active;
                    border: 1px solid $primary-active;
                  }

                  &:hover {
                    color: #fff;
                    background-color: $primary-active;
                    box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.35);
                  }
                  &:last-child {
                    margin-right: 0;
                  }
                }

                ssi-social-network-icon {
                  i {
                    font-size: 12px;
                  }
                }

                .accounts-selector {
                  width: 100%;
                  button.dd-head {
                    width: 100%;
                  }
                }

                .select-accounts-placeholder {
                  color: $lmode-helper-text;
                  font-size: 14px;
                  font-weight: 700;
                }

                .select-accounts-label {
                  color: $lmode-title-text;
                  font-size: 14px;
                  font-weight: 900;
                  display: flex;
                  align-items: center;

                  ssi-social-network-icon {
                    margin-right: 8px;
                  }
                }

                .date-picker-input {
                  width: 100%;
                  height: 46px;
                  border: none;
                  outline: none;
                  border-radius: 100px;
                  display: flex;
                  align-items: center;
                  color: $lmode-title-text;
                  font-size: 14px;
                  font-weight: 900;
                  background-color: $grey-background;
                  padding: 10px 30px 10px 20px;

                  &::placeholder {
                    color: $lmode-helper-text;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 17px;
                  }
                }
              }
            }
          }

          .submit-button {
            border: none;
            outline: none;
            border-radius: 100px;
            display: flex;
            justify-content: center;
            color: #fff;
            font-size: 14px;
            font-weight: 900;
            background-color: $primary-active;
            padding: 12px 20px;
            margin-top: 30px;

            &:hover {
              color: #fff;
            }

            &:disabled {
              background-color: $dmode-body-text;
            }
          }
        }
      }
    }
  }
}
