ssi-dropdown-select-2 .dd-container {
  position: relative;
}
ssi-dropdown-select-2 .dd-container .dd-head {
  display: flex;
  align-items: center;
  position: relative;
  outline: none;
  height: 46px;
  border: 1px solid #c3c9d8;
  border-radius: 10px;
  background-color: #fff;
  color: #101525;
  font-size: 14px;
  font-weight: 700;
  padding: 0 30px 0 20px;
}
ssi-dropdown-select-2 .dd-container .dd-head span {
  margin-right: 20px;
}
ssi-dropdown-select-2 .dd-container .dd-head span.placeholder {
  color: #6a759a;
}
ssi-dropdown-select-2 .dd-container .dd-head .button-icon {
  position: absolute;
  color: #14bae3;
  font-weight: 900;
  font-size: 12px;
  right: 20px;
}
ssi-dropdown-select-2 .dd-container .dd-head.variant-inbox {
  height: auto;
  border: none;
  border-radius: 20px;
  background-color: #f8f9fd;
  color: #14bae3;
  font-size: 10px;
  font-weight: 900;
  padding: 6px 8px;
}
ssi-dropdown-select-2 .dd-container .dd-head.variant-inbox span.placeholder {
  color: #14bae3;
}
ssi-dropdown-select-2 .dd-container .dd-head.variant-inbox .button-icon {
  font-size: 8px;
  right: 10px;
}
ssi-dropdown-select-2 .dd-container .dd-head.variant-analytics {
  height: auto;
  border: none;
  border-radius: 20px;
  background-color: #f8f9fd;
  color: #6a759a;
  font-size: 14px;
  font-weight: 900;
  padding: 10px 30px 10px 20px;
}
ssi-dropdown-select-2 .dd-container .dd-head.variant-analytics span.placeholder {
  color: #6a759a;
}
ssi-dropdown-select-2 .dd-container .dd-head.variant-analytics .button-icon {
  font-size: 12px;
  right: 20px;
}
ssi-dropdown-select-2 .dd-container .dd-head:disabled {
  color: #838eab;
  cursor: not-allowed;
}
ssi-dropdown-select-2 .dd-container .dd-body {
  position: absolute;
  z-index: 1000;
  min-width: 260px;
  padding: 20px 10px 20px 20px;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-color: #fff;
  overflow: hidden;
}
ssi-dropdown-select-2 .dd-container .dd-body.no-padding-top {
  padding-top: 0;
}
ssi-dropdown-select-2 .dd-container .dd-body .body-header-container {
  width: 100%;
  padding: 0 20px 0 5px;
  position: relative;
}
ssi-dropdown-select-2 .dd-container .dd-body .body-header-container hr {
  margin: 0 -50px;
  background-color: #c3c9d8;
  height: 1px;
}
ssi-dropdown-select-2 .dd-container .dd-body .filter-container {
  position: relative;
  width: 100%;
  padding: 0 20px 0 5px;
}
ssi-dropdown-select-2 .dd-container .dd-body .filter-container input {
  width: 100%;
  height: 40px;
  border: 0;
  outline: 0;
  padding-right: 20px;
  color: #101525;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  font-family: "Lato", "Arial", sans-serif;
}
ssi-dropdown-select-2 .dd-container .dd-body .filter-container input::placeholder {
  font-size: 15px;
  font-weight: 700;
  font-style: normal;
  color: #6a759a;
}
ssi-dropdown-select-2 .dd-container .dd-body .filter-container i {
  position: absolute;
  color: #6a759a;
  top: 16px;
  right: 28px;
  font-size: 12px;
  font-weight: 700;
}
ssi-dropdown-select-2 .dd-container .dd-body .filter-container i.active {
  color: #14bae3;
}
ssi-dropdown-select-2 .dd-container .dd-body .filter-container hr {
  margin: 0 -50px;
  background-color: #c3c9d8;
  height: 1px;
}
ssi-dropdown-select-2 .dd-container .dd-body .toggle-all-container {
  width: 100%;
  padding: 20px 26px 0 10px;
}
ssi-dropdown-select-2 .dd-container .dd-body .toggle-all-container.filter-visible {
  display: flex;
  justify-content: flex-end;
}
ssi-dropdown-select-2 .dd-container .dd-body > ul {
  overflow: auto;
  list-style: none;
  max-height: 200px;
  margin: 0;
  padding: 0;
  margin-top: 10px;
  scrollbar-gutter: stable;
}
ssi-dropdown-select-2 .dd-container .dd-body > ul.overflow-scroll {
  overflow-y: scroll;
}
ssi-dropdown-select-2 .dd-container .dd-body > ul.padding-right {
  padding-right: 17px;
}
ssi-dropdown-select-2 .dd-container .dd-body > ul > li {
  margin: 5px 0;
  min-height: 36px;
}
ssi-dropdown-select-2 .dd-container .dd-body > ul > li.selected .icon-container i {
  color: #14bae3;
  font-weight: 700;
}
ssi-dropdown-select-2 .dd-container .dd-body > ul > li.selected > .main-content span {
  color: #14bae3;
}
ssi-dropdown-select-2 .dd-container .dd-body > ul > li.group-separator:not(:first-child) {
  margin-top: 20px;
}
ssi-dropdown-select-2 .dd-container .dd-body > ul > li.group-separator .main-content > span {
  font-size: 10px;
  letter-spacing: 0.5px;
  color: #43537f;
  text-transform: uppercase;
}
ssi-dropdown-select-2 .dd-container .dd-body > ul > li.group-separator .main-content ssi-checkbox-2 {
  margin-left: auto;
  margin-right: 10px;
}
ssi-dropdown-select-2 .dd-container .dd-body > ul > li .main-content {
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  color: #43537f;
  font-size: 14px;
  font-weight: 900;
  border-radius: 10px;
  min-height: 30px;
  border: none;
}
ssi-dropdown-select-2 .dd-container .dd-body > ul > li .main-content:hover {
  background-color: #f8f9fd;
}
ssi-dropdown-select-2 .dd-container .dd-body > ul > li .main-content .icon-container {
  margin-left: 2px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
ssi-dropdown-select-2 .dd-container .dd-body > ul > li .main-content .icon-container img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
ssi-dropdown-select-2 .dd-container .dd-body > ul > li .main-content span {
  display: block;
  padding: 0 10px;
  border-radius: 15px;
  max-width: 100%;
  word-break: break-all;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
ssi-dropdown-select-2 .dd-container .dd-body > ul > li .main-content .tick-container {
  position: relative;
  margin-right: 10px;
  margin-left: auto;
  height: 16px;
  width: 16px;
  border-radius: 2px;
  border: 1px solid #838eab;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
ssi-dropdown-select-2 .dd-container .dd-body > ul > li .main-content .tick-container i {
  font-size: 10px;
  font-weight: 500;
  color: #fff;
}
ssi-dropdown-select-2 .dd-container .dd-body > ul > li .main-content .tick-container.checked {
  border: 1px solid #14bae3;
  background-color: #14bae3;
}
ssi-dropdown-select-2 .dd-container .dd-body > ul > li .main-content .tick-container.radio-btn {
  height: 18px;
  width: 18px;
  border-radius: 50%;
}
ssi-dropdown-select-2 .dd-container .dd-body > ul > li .main-content .tick-container.radio-btn i {
  display: none;
}
ssi-dropdown-select-2 .dd-container .dd-body > ul > li .main-content .tick-container.radio-btn.checked {
  background-color: #fff;
}
ssi-dropdown-select-2 .dd-container .dd-body > ul > li .main-content .tick-container.radio-btn.checked i {
  padding: 1px;
  display: block;
  color: #14bae3;
  border-radius: 50%;
  background-color: #14bae3;
}
ssi-dropdown-select-2 .dd-container .dd-body > ul > li .expanded-content-container {
  padding-left: 30px;
}
ssi-dropdown-select-2 .dd-container .dd-body > ul > li .expanded-content-container .filter-container {
  margin-top: 10px;
}
ssi-dropdown-select-2 .dd-container .dd-body > ul > li .expanded-content-container > ul {
  padding-bottom: 20px;
}
ssi-dropdown-select-2 .dd-container .body-footer-container {
  margin-top: 10px;
}
ssi-dropdown-select-2 .dd-container.size-sm .dd-body {
  min-width: 160px;
  padding: 5px 5px 20px 10px;
}
ssi-dropdown-select-2 .dd-container.size-sm .dd-body.no-padding-top {
  padding-top: 0;
}
ssi-dropdown-select-2 .dd-container.size-sm .dd-body .filter-container input {
  height: 36px;
}
ssi-dropdown-select-2 .dd-container.size-sm .dd-body .filter-container input::placeholder {
  font-size: 12px;
}
ssi-dropdown-select-2 .dd-container.size-sm .dd-body .filter-container i {
  top: 14px;
}
ssi-dropdown-select-2 .dd-container.size-sm .dd-body > ul li {
  min-height: 26px;
}
ssi-dropdown-select-2 .dd-container.size-sm .dd-body > ul li span {
  font-weight: 700;
}
ssi-dropdown-select-2 .dd-container.size-lg .dd-head {
  min-height: 46px;
}
ssi-dropdown-select-2 .dd-container.size-lg .dd-body {
  min-width: 420px;
  padding: 20px 20px 30px 30px;
}
ssi-dropdown-select-2 .dd-container.size-lg .dd-body.no-padding-top {
  padding-top: 0;
}
ssi-dropdown-select-2 .dd-container.size-lg .dd-body .filter-container input {
  height: 46px;
}
ssi-dropdown-select-2 .dd-container.size-lg .dd-body .filter-container i {
  top: 18px;
}
ssi-dropdown-select-2 .dd-container.size-lg .dd-body > ul {
  max-height: 200px;
}
ssi-dropdown-select-2 .dd-container.size-lg .dd-body > ul li {
  min-height: 36px;
  font-size: 14px;
}
ssi-dropdown-select-2 .dd-container.size-fit .dd-body {
  min-width: auto;
}
@media print {
  ssi-dropdown-select-2 .button-icon {
    display: none !important;
  }
}