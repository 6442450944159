ssi-workflows {
  font-size: 1rem;
}
ssi-workflows .alert,
ssi-workflows .page-header,
ssi-workflows .panel {
  font-size: 1.4em;
}
ssi-workflows .dropdown-menu {
  width: 300px;
}
ssi-workflows .list-group-item-text {
  height: 70px;
}