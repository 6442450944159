ssi-validate-reply-box .post-box {
  position: relative;
  border-width: 0;
  box-shadow: 0px 5px 10px 0px rgba(131, 142, 171, 0.2);
  overflow: hidden;
  margin-bottom: 20px;
  border-radius: 0;
}
@media (max-width: 885px) {
  ssi-validate-reply-box .post-box {
    padding-right: 0px;
  }
}
ssi-validate-reply-box .post-box-targeting-open {
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}
ssi-validate-reply-box .post-box-wrap {
  position: relative;
  padding: 20px 20px 20px 25px;
}
ssi-validate-reply-box .post-box-header {
  margin-bottom: 10px;
}
ssi-validate-reply-box .post-box-header .btn {
  margin: 0 5px 5px 0;
}
ssi-validate-reply-box .post-box-panel-body {
  padding: 0;
}
ssi-validate-reply-box .post-box-footer {
  padding: 15px 25px;
  border-radius: 0;
  background-color: #ebf0f2;
  border-top: 0;
}
ssi-validate-reply-box .post-box-footer.post-box-footer-selected {
  background-color: #494f5b;
}
ssi-validate-reply-box .post-box-validation {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
  opacity: 0.7;
  color: white;
  text-align: center;
  font-size: 24px;
  padding: 80px 0;
}
ssi-validate-reply-box .post-box-validation a {
  font-size: 16px;
}
ssi-validate-reply-box .post-box-validation.approved {
  background-color: cadetblue;
}
ssi-validate-reply-box .post-box-validation.disapproved {
  background-color: coral;
}
ssi-validate-reply-box .post-box-validation.boosted {
  background-color: black;
}