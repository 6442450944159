@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../common/styles/colors.scss';

ssi-finalise-step {
  .row-content {
    display: flex;
    align-items: flex-end;
    width: 100%;
    justify-content: center;
    margin: 0 0 40px;

    .row-content {
      margin: 30px 0 0;
      justify-content: space-between;
    }

    .form-field {
      width: 100%;
      max-width: 600px;
    }
  }

  ssi-rounded-box-check {
    .rounded-box-check-container {
      padding-left: 18px;
      padding-right: 18px;
    }
  }

  .step-content {
    margin: 70px 0;
  }

  hr {
    height: 2px;
    background-color: #e7e9ef;
    display: block;
    border: 0;
    border-radius: 100px;
    margin: 30px 0 30px 0;
  }

  .language-filtering {
    h2 {
      font-size: 20px;
      font-weight: 900;
      color: $lmode-body-text;
    }

    p {
      font-size: 14px;
      font-weight: bold;
      color: $lmode-body-text;
    }

    .dropdown-container {
      margin-top: 20px;
      .select-language-placeholder {
        color: #838eab;
        font-weight: bold;
      }
    }

    label {
      color: $lmode-body-text;
      font-size: 10px;
      font-weight: 900;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-left: 20px;
      margin-bottom: 15px;
    }

    .input-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .languages-selector {
        width: 100%;
        button {
          width: 100%;
          max-width: 500px;
          margin-right: auto;
        }
      }
    }
  }

  .toggle-source {
    > * {
      margin-right: 10px;
    }
  }

  orlo-button {
    button {
      opacity: 1 !important;
      border-radius: 10px !important;
    }
  }

  ssi-toggle-switch {
    flex-basis: 320px;

    .toggle-switch-container {
      border-radius: 10px;
      border: 1px solid #c3c9d8;
      background-color: transparent;
      padding: 12px 10px 12px 20px;

      span {
        color: #6a759a;
      }

      .switch {
        height: 20px;
        width: 36px;
        margin-bottom: 0;

        .slider {
          &:before {
            height: 12px;
            width: 12px;
            left: 4px;
            bottom: 4px;
          }
        }
      }

      &.active {
        border-color: $primary-active;
        span {
          color: $lmode-body-text;
        }

        .switch {
          .slider {
            &::before {
              background-color: #fff;
            }
          }
        }
      }
    }

    .field-label {
      padding: 0;
    }
  }
}
