@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-analytics ssi-marketing ssi-report ssi-top-times-to-post {
  .top-times-to-post-container {
    .section-head {
      h2 .ssi.ssi-help {
        margin: 0 0 0 3px;
        font-size: 14px;
        vertical-align: text-top;
      }
    }

    .section-body {
      padding: 30px 30px 60px 30px;
      background-color: #fff;
      border-radius: 10px;
      position: relative;
      display: flex;
      flex-direction: column;

      @media print {
        page-break-inside: avoid;
      }

      @media print {
        page-break-inside: avoid;
      }

      .top-row {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 30px;

        .best-hours-container {
          display: flex;
          border-radius: 5px;
          border: 1px solid #d41d68;
          padding: 20px;

          .left {
            margin-right: 80px;
            span {
              color: $lmode-helper-text;
              font-size: 10px;
              font-weight: 900;
              letter-spacing: 1px;
              text-transform: uppercase;
            }
          }

          .middle {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            span {
              color: $lmode-helper-text;
              font-size: 14px;
              font-weight: 700;
            }
            .hours {
              span {
                color: $lmode-title-text;
                font-size: 20px;
                font-weight: 900;
              }
            }
          }

          .right {
            display: flex;
            align-items: center;
            margin-left: 20px;
            i {
              font-size: 30px;
              color: #ffeee9;
            }
          }
        }
      }

      ssi-highchart {
        width: 100%;
        height: 340px;

        highcharts-chart {
          .highcharts-container,
          .highcharts-root {
            overflow: visible !important;
          }

          .highcharts-axis-title {
            font-size: 10px;
            font-weight: 900;
            letter-spacing: 1px;
            text-transform: uppercase;
            white-space: nowrap;
          }

          .highcharts-axis-labels {
            text {
              font-weight: 900;
            }
          }

          .highcharts-xaxis-labels {
            transform: translate(0, 1em);
          }
        }
      }

      .chart-legend {
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        margin-top: 40px;

        .legend-item {
          display: flex;
          align-items: center;
          margin-right: 60px;

          &:first-child {
            margin-right: 30px;
          }

          span {
            color: $lmode-body-text;
            font-size: 10px;
            font-weight: 900;
            letter-spacing: 0.4px;
          }

          .circle {
            height: 18px;
            width: 18px;
            border-radius: 50%;
            background-color: #d41d68;
            margin-right: 10px;
            -webkit-print-color-adjust: exact;
          }

          &.great {
            .circle {
              //   opacity: 1;
              background-color: #d41d68 !important;
            }
          }
          &.good {
            .circle {
              //   opacity: 0.8;
              background-color: #f40064 !important;
            }
          }
          &.okay {
            .circle {
              //   opacity: 0.6;
              background-color: #ff5da0 !important;
            }
          }
          &.not-great {
            .circle {
              //   opacity: 0.4;
              background-color: #fbc5db !important;
            }
          }
          &.not-good {
            .circle {
              //   opacity: 0.2;
              background-color: #ffeee9 !important;
            }
          }
        }
      }
    }
  }
}
