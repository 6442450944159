ssi-post-errors {
  display: block;
}
ssi-post-errors ul,
ssi-post-errors li {
  padding: 0;
  margin: 0;
  list-style: none;
}
ssi-post-errors .post-error {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #fff1ed;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
}
ssi-post-errors .post-error i {
  color: #e34916;
  font-size: 14px;
  padding-right: 8px;
  flex-basis: 22px;
}
ssi-post-errors .post-error p {
  position: relative;
  flex-basis: 95%;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #43537f;
  margin: 0;
  margin-right: auto;
}
ssi-post-errors .post-error p span,
ssi-post-errors .post-error p a {
  font-weight: 900;
}
ssi-post-errors .post-error p span span,
ssi-post-errors .post-error p a span {
  font-weight: 700;
}
ssi-post-errors .post-error .error-message {
  display: flex;
  align-items: baseline;
}
ssi-post-errors .post-error a {
  display: block;
  font-weight: 900;
  font-size: 14px;
  padding-left: 22px;
  white-space: nowrap;
  text-align: right;
  text-decoration: underline;
  color: #43537f;
}
ssi-post-errors .duplicates-text-account-list {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #43537f;
  padding-left: 22px;
}
ssi-post-errors .duplicates-text-account-list i {
  color: initial;
}