ssi-engagement ssi-sentiment-change {
  font-size: 1rem;
}
ssi-engagement ssi-sentiment-change .section-chart-wrap {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 1.2em 1em 1.2em 2em;
  box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
  border-radius: 1.2em;
  width: 100%;
  margin: 0 0 2em;
}
ssi-engagement ssi-sentiment-change .section-chart-wrap ssi-highchart {
  flex: 1 0 auto;
  padding: 0 0.6em 0 1em;
  margin: 0em 0 0;
}
ssi-engagement ssi-sentiment-change .section-grid {
  margin-bottom: -2em;
}
ssi-engagement ssi-sentiment-change .section-chart-meta {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: 0 1 17.2em;
  margin: 0;
  padding: 0;
}
ssi-engagement ssi-sentiment-change .section-chart-meta-item {
  display: flex;
  flex: 1 1 auto;
  height: 16.5em;
  width: 17.2em;
  border-radius: 1.2em;
  box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.35);
  margin: 1em 0;
  padding: 1.2em 1.2em 2em;
}
ssi-engagement ssi-sentiment-change .total-tile {
  display: flex;
  flex-direction: column;
  color: #838eab;
  letter-spacing: 0.1em;
  justify-content: center;
  align-items: center;
  padding-top: 3em;
}
@media print {
  ssi-engagement ssi-sentiment-change .total-tile {
    border: 1px solid black;
  }
}
ssi-engagement ssi-sentiment-change .total-tile-icon {
  font-size: 3.6em;
  color: #b4bbce;
}
ssi-engagement ssi-sentiment-change .total-tile-icon.ssi-total-conversations-stat {
  font-size: 4.2em;
}
ssi-engagement ssi-sentiment-change .total-tile-meta {
  position: relative;
  font-size: 1em;
  font-weight: 900;
  text-rendering: geometricPrecision;
  margin: 1.6em 0 0.8em;
  padding-bottom: 1.6em;
  text-transform: uppercase;
}
ssi-engagement ssi-sentiment-change .total-tile-meta:after {
  content: "";
  position: absolute;
  left: 0;
  margin: auto;
  right: 0;
  bottom: 0;
  display: inline-block;
  width: 4em;
  height: 0.4em;
  background-color: #d6dff7;
  border-radius: 10em;
}
ssi-engagement ssi-sentiment-change .total-tile-amount {
  letter-spacing: normal;
  margin: 0;
  color: #101525;
  font-weight: 900;
  text-rendering: geometricPrecision;
  font-size: 2em;
}
ssi-engagement ssi-sentiment-change .comparison-tile {
  position: relative;
  background-color: #fff;
  padding: 6em 3em 2.5em;
  box-shadow: 0 0.5em 1em rgba(131, 142, 171, 0.25);
  border-radius: 1.2em;
  margin-bottom: 2em;
}
@media print {
  ssi-engagement ssi-sentiment-change .comparison-tile {
    border: 1px solid black;
  }
}
ssi-engagement ssi-sentiment-change .comparison-tile-icons {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 3em;
  right: 3em;
  width: 13em;
}
ssi-engagement ssi-sentiment-change .comparison-tile-icon {
  display: inline-block;
  height: 3.6em;
  width: 3.6em;
  color: #657fc8;
  background-color: rgba(214, 223, 247, 0.7);
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  line-height: 3.5em;
}
ssi-engagement ssi-sentiment-change .comparison-tile-icon[class*=negative] {
  background-color: #fff1ed;
  color: #f88c68;
}
ssi-engagement ssi-sentiment-change .comparison-tile-icon[class*=positive] {
  background-color: #dff9ff;
  color: #14bae3;
}
ssi-engagement ssi-sentiment-change .comparison-tile-icon:before {
  font-size: 2.4em;
}
ssi-engagement ssi-sentiment-change .comparison-tile-break {
  font-size: 1.4em;
  transform: rotate(270deg);
  color: #838eab;
}
ssi-engagement ssi-sentiment-change .comparison-tile-meta {
  color: #101525;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-engagement ssi-sentiment-change .comparison-tile-meta p {
  font-weight: inherit;
  margin: 0;
}
ssi-engagement ssi-sentiment-change .comparison-tile-meta p:first-child {
  position: relative;
  padding-bottom: 1.3rem;
  margin-bottom: 0.8rem;
}
ssi-engagement ssi-sentiment-change .comparison-tile-meta p:first-child:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  display: inline-block;
  width: 5rem;
  height: 0.4rem;
  border-radius: 10rem;
  background-color: #d6dff7;
}
ssi-engagement ssi-sentiment-change .comparison-tile-meta p:first-child[class*=negative]:after {
  background-color: #f88c68;
}
ssi-engagement ssi-sentiment-change .comparison-tile-meta p:first-child[class*=positive]:after {
  background-color: #89d6ea;
}
ssi-engagement ssi-sentiment-change .comparison-tile-meta-amount {
  font-size: 2.8em;
}
ssi-engagement ssi-sentiment-change .comparison-tile-meta-copy {
  font-size: 1.2em;
}