@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-widget-rss-feeds {
  height: 100%;
  width: 100%;

  .dashboard-widget {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 10px;

    label.field-title {
      font-family: 'Lato', 'Arial', sans-serif;
      font-weight: 900;
      font-size: 10px;
      line-height: 12px;
      padding-left: 20px;
      color: #43537f;
    }

    ssi-dropdown-select-2 {
      width: 100%;

      .dd-head {
        width: 100%;
      }
      .dd-body {
        width: 100%;
      }
      .select-accounts-label {
        display: flex;
        align-items: center;
      }
    }

    .article-grid {
      height: 85%;
      overflow: auto;
      margin-top: 7px;

      &.view-1x1,
      &.view-2x1,
      &.view-3x1,
      &.view-4x1 {
        height: 60%;
      }

      &.view-3x1,
      &.view-3x2,
      &.view-3x3,
      &.view-3x4,
      &.view-4x1,
      &.view-4x2,
      &.view-4x3,
      &.view-4x4 {
        display: grid;
        grid-template-columns: 50% 50%;
      }
    }

    .message-info {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      text-align: center;

      &.view-1x1,
      &.view-2x1,
      &.view-3x1,
      &.view-4x1 {
        justify-content: start;
        padding-top: 10px;
      }

      i {
        color: #b4bbce;
        font-size: 37.5px;
      }

      h5 {
        font-family: 'Lato', 'Arial', sans-serif;
        font-size: 20px;
        font-weight: 900;
        line-height: 24px;
        color: #43537f;
      }

      p {
        font-family: 'Lato', 'Arial', sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        max-width: 222px;
        color: #43537f;

        &.view-1x1,
        &.view-2x1,
        &.view-3x1,
        &.view-4x1 {
          max-width: 312px;
        }
      }
    }

    ssi-rss-feed-article {
      .post-body {
        height: 160px;
      }
    }
  }
}
