@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../angularjs/common/sass/variables';
@import '~/styles/colors';

$unit: 2.5px;

ssi-live-chat-message-note {
  background-color: #7d90a0;
  border-radius: ($unit * 8);
  border-top-right-radius: 0;
  color: $text-light;
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  font-size: 0.85em;
  margin: ($unit * 4) 0;
  padding: ($unit * 8) ($unit * 8) ($unit * 4) ($unit * 8);

  * {
    display: flex;
  }

  span {
    & > span {
      & + span {
        margin-left: ($unit * 1);
      }
    }
  }

  .footer-information,
  .footer-options {
    & > li {
      button {
        align-items: center;
        background-color: transparent;
        border: none;
        border-radius: ($unit * 8);
        font-weight: 500;
        justify-content: center;
        padding: ($unit * 2) ($unit * 4);

        &.add-button {
          background-color: $text-light;
          color: $primary-active;

          &:hover,
          &.active {
            background-color: $primary-active;
            color: $text-light;
          }
        }

        &.delete-button {
          align-items: center;
          background: transparent;
          border: none;
          border-radius: 10px;
          color: #e4a598;
          font-style: italic;
          font-weight: 600;
          justify-content: space-between;
          padding: 0;

          & > .button-icon {
            font-size: 0.85em;
            padding-right: 0.2em;
          }
        }

        &.view-more-button {
          background-color: $primary-active;
          color: $text-light;

          &:hover,
          &.active {
            background-color: $text-light;
            color: $primary-active;
          }
        }

        & > .button-icon {
          padding-right: ($unit * 2);
        }
      }
    }
  }

  ul {
    flex-flow: row nowrap;
    list-style: none;
    margin: 0;
    padding: 0;

    & > li {
      align-items: center;
      flex-shrink: 0;
      justify-content: space-between;
      margin: 0;
      padding: 0;
    }
  }

  p {
    display: inline;
    flex-flow: row wrap;
    font-size: 1.1em;
    line-height: 1.6em;
    margin: 0 0 ($unit * 4) 0;

    &.self-penned {
      &:before {
        color: $primary-active;
        content: 'You:';
        font-style: italic;
        font-weight: 400;
        padding-right: 0.5em;
      }
    }

    * {
      display: inline;
      padding: 0 0.2em;
    }

    a {
      color: #fff;
      font-style: italic;
      font-weight: 400;

      &:before {
        content: '@';
      }
    }
  }

  .notes-preview,
  .form-inner {
    border-radius: ($unit * 8);
    border-top-right-radius: 0;
    flex-flow: column nowrap;
    padding: ($unit * 8) ($unit * 8) ($unit * 4) ($unit * 8);
  }

  .footer {
    align-items: center;
    font-size: 0.85em;
    font-style: italic;
    justify-content: space-between;

    .footer-options {
      margin: 0;
      padding: 0;

      & > li {
        &:not(:first-child) {
          margin-left: ($unit * 2);
        }
      }
    }

    .footer-information {
      flex-grow: 1;
      font-style: italic;
      font-weight: 500;
      justify-content: flex-end;

      & > li {
        &:first-child {
          &:after {
            content: '|';
            padding: 0 ($unit * 2);
          }
        }
      }

      .information-icon {
        color: #edbb38;
        font-size: 2em;
        margin-left: ($unit * 2);
      }
    }
  }

  .you {
    color: $primary-blue;
    font-style: italic;
    font-weight: 600;
    text-transform: capitalize;
  }
}
