@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-cl-context-menu {
  .cl-context-menu-container {
    .body {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      > * {
        margin: 10px;
        margin-bottom: 30px;
        flex: 0 0 260px;

        a {
          font-size: 10px;
        }
        p {
          font-size: 10px;
          margin: 0;
          &:last-of-type {
            margin-bottom: 20px;
          }
        }
      }

      .menu-body-content {
        height: 100px;
        width: 260px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0 1.8em 4em rgba(131, 142, 171, 0.35);
        border-radius: 10px;
        padding: 20px;
      }
    }
  }
}
