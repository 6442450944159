ssi-checkbox-2 .checkbox-2-container {
  position: relative;
  display: flex;
  align-items: center;
}
ssi-checkbox-2 .checkbox-2-container.reversed {
  justify-content: space-between;
  flex-direction: row-reverse;
}
ssi-checkbox-2 .checkbox-2-container.reversed label {
  margin: 0;
  margin-right: 14px;
}
ssi-checkbox-2 .checkbox-2-container.square .checkbox {
  height: 16px;
  width: 16px;
  border-radius: 2px;
}
ssi-checkbox-2 .checkbox-2-container.square i {
  top: 3px;
  left: 3px;
}
ssi-checkbox-2 .checkbox-2-container.square.reversed i {
  right: 3px;
  left: inherit;
}
ssi-checkbox-2 .checkbox-2-container input {
  visibility: hidden;
}
ssi-checkbox-2 .checkbox-2-container input:disabled {
  cursor: not-allowed;
}
ssi-checkbox-2 .checkbox-2-container .checkbox {
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #838eab;
  border-radius: 50%;
  cursor: pointer;
}
ssi-checkbox-2 .checkbox-2-container .checkbox.checked {
  background-color: #14bae3;
  border: none;
}
ssi-checkbox-2 .checkbox-2-container .checkbox.disabled {
  cursor: not-allowed;
}
ssi-checkbox-2 .checkbox-2-container i {
  position: absolute;
  top: 3px;
  left: 5px;
  color: #fff;
  font-size: 10px;
  font-weight: 500;
  cursor: pointer;
}
ssi-checkbox-2 .checkbox-2-container i.disabled {
  cursor: not-allowed;
}
ssi-checkbox-2 .checkbox-2-container label {
  margin: 0;
  margin-left: 10px;
  white-space: nowrap;
  color: #43537f;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  cursor: pointer;
}
ssi-checkbox-2 .checkbox-2-container label.checked {
  color: #14bae3;
}
ssi-checkbox-2 .checkbox-2-container label.disabled {
  cursor: not-allowed;
}