@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-analytics ssi-marketing ssi-report ssi-post-engagement {
  .post-engagement-container {
    .section-body {
      padding: 70px 30px 70px 30px;
      background-color: #fff;
      border-radius: 10px;
      position: relative;
      display: flex;

      @media print {
        page-break-inside: avoid;
      }

      ssi-highchart {
        width: 100%;
        height: 365px;

        highcharts-chart {
          .highcharts-container,
          .highcharts-root {
            overflow: visible !important;
          }
          .highcharts-axis-title {
            font-size: 10px;
            font-weight: 900;
            letter-spacing: 1px;
            text-transform: uppercase;
            white-space: nowrap;
          }

          .highcharts-axis-labels {
            text {
              font-weight: 900;
            }
          }

          .highcharts-xaxis-labels {
            transform: translate(0, 1em);
          }
        }
      }

      .cards-container {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;

        height: 280px;
        width: 260px;
        margin-left: 20px;

        .card {
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          background-color: #fff;
          border-radius: 12px;
          box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.35);

          &:not(:first-child) {
            margin-top: 20px;
          }

          > * {
            &:not(:first-child) {
              margin-top: 10px;
            }
          }

          .title {
            color: $lmode-helper-text;
            font-size: 10px;
            font-weight: 900;
            letter-spacing: 1px;
            text-transform: uppercase;
          }
          .break-line {
            height: 4px;
            width: 40px;
            border-radius: 100px;
            background-color: $lblue-background;
          }
          .count {
            color: $lmode-title-text;
            font-size: 20px;
            font-weight: 900;
          }
        }
      }
    }
  }
}
