ssi-tooltip-window[data-popper-placement^=top] .tooltip-arrow {
  top: 100%;
  border-width: 10px 10px 3px 2px;
  border-color: #3f4142 transparent transparent;
}

ssi-tooltip-window[data-popper-placement^=bottom] .tooltip-arrow {
  top: -10px;
  border-width: 10px 2px 3px 10px;
  border-color: transparent transparent transparent #3f4142;
}

ssi-tooltip-window.tooltip-bg-secondary {
  background-color: #2e3031;
}

ssi-tooltip-window[data-popper-placement^=top].tooltip-bg-secondary .tooltip-arrow {
  top: 100%;
  border-width: 10px 10px 3px 2px;
  border-color: #2e3031 transparent transparent;
}

ssi-tooltip-window[data-popper-placement^=bottom].tooltip-bg-secondary .tooltip-arrow {
  top: -10px;
  border-width: 10px 2px 3px 10px;
  border-color: transparent transparent transparent #2e3031;
}

ssi-tooltip-window {
  background-color: #3f4142;
  color: #fff;
  max-width: 350px;
  display: flex;
  position: absolute;
  border-radius: 3px;
  z-index: 1070;
}
ssi-tooltip-window .tooltip-icon {
  color: #3787b3;
  font-size: 30px;
  background-color: #2e3031;
  align-items: center;
  justify-content: center;
  height: auto;
  border-radius: 3px;
  padding: 10px;
  display: flex;
  flex: 0 1 auto;
}
ssi-tooltip-window .tooltip-icon + .tooltip-body {
  flex: 1;
  width: auto;
}
ssi-tooltip-window .tooltip-icon.tooltip-icon-padding {
  padding: 20px;
  font-size: 45px;
}
ssi-tooltip-window .tooltip-body {
  flex: 0 1 auto;
  width: 100%;
  padding: 10px;
  font-style: italic;
  display: flex;
  flex-direction: column;
}
ssi-tooltip-window .tooltip-body-title {
  font-size: 16px;
  margin-bottom: 5px;
  display: flex;
}
ssi-tooltip-window .tooltip-body-contents {
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  flex: 0 1 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
ssi-tooltip-window .tooltip-arrow {
  content: "";
  position: absolute;
  border-style: solid;
}
ssi-tooltip-window .trailing-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 16px;
  padding: 5px;
  border-radius: 50%;
  color: #838eab;
  background-color: rgba(138, 149, 176, 0.17);
}

.tooltip-chunky {
  background: #101525;
  min-width: 50px;
  max-width: 335px;
  border-radius: 25px;
  filter: drop-shadow(0 5px 10px rgba(16, 21, 37, 0.35));
}
.tooltip-chunky.top:not([data-popper-placement=bottom]), .tooltip-chunky.bottom[data-popper-placement=top] {
  margin-bottom: 10px;
}
.tooltip-chunky.top:not([data-popper-placement=bottom]) .tooltip-arrow, .tooltip-chunky.bottom[data-popper-placement=top] .tooltip-arrow {
  top: 100%;
  height: 10px;
  width: 10px;
  border: none;
}
.tooltip-chunky.top:not([data-popper-placement=bottom]) .tooltip-arrow:before, .tooltip-chunky.bottom[data-popper-placement=top] .tooltip-arrow:before {
  content: "";
  display: block;
  margin-left: 2.5px;
  top: 100%;
  border-style: solid;
  border-width: 10px 10px 0 0;
  border-color: #101525 transparent transparent transparent;
  transform: skew(-6deg);
}
.tooltip-chunky.bottom:not([data-popper-placement=top]), .tooltip-chunky.top[data-popper-placement=bottom] {
  margin-top: 10px;
  margin-left: 2.5px;
}
.tooltip-chunky.bottom:not([data-popper-placement=top]) .tooltip-arrow, .tooltip-chunky.top[data-popper-placement=bottom] .tooltip-arrow {
  top: -10px;
  height: 10px;
  width: 10px;
  border: none;
}
.tooltip-chunky.bottom:not([data-popper-placement=top]) .tooltip-arrow:before, .tooltip-chunky.top[data-popper-placement=bottom] .tooltip-arrow:before {
  content: "";
  display: block;
  margin-left: 2.5px;
  top: -10px;
  border-style: solid;
  border-width: 10px 0 0 10px;
  border-color: transparent transparent transparent #101525;
  transform: skew(6deg);
}
.tooltip-chunky .tooltip-body {
  padding: 9px 15px;
  transform: skew(0);
  word-break: break-word;
}
.tooltip-chunky ul {
  padding-left: 0;
}
.tooltip-chunky li {
  list-style: none;
}
.tooltip-chunky .tooltip-body-contents {
  color: #fff;
  line-height: 20px;
  font-weight: 700;
  font-style: normal;
}

.tooltip-chunky-basic {
  background: #101525;
  min-width: 150px;
  border-radius: 15px;
  filter: drop-shadow(0 5px 10px rgba(16, 21, 37, 0.35));
}
.tooltip-chunky-basic.top:not([data-popper-placement=bottom]), .tooltip-chunky-basic.bottom[data-popper-placement=top] {
  margin-bottom: 10px;
}
.tooltip-chunky-basic.top:not([data-popper-placement=bottom]) .tooltip-arrow, .tooltip-chunky-basic.bottom[data-popper-placement=top] .tooltip-arrow {
  top: 100%;
  height: 10px;
  width: 10px;
  border: none;
}
.tooltip-chunky-basic.top:not([data-popper-placement=bottom]) .tooltip-arrow:before, .tooltip-chunky-basic.bottom[data-popper-placement=top] .tooltip-arrow:before {
  content: "";
  display: block;
  margin-left: 2.5px;
  top: 100%;
  border-style: solid;
  border-width: 10px 10px 0 0;
  border-color: #101525 transparent transparent transparent;
  transform: skew(-6deg);
}
.tooltip-chunky-basic.bottom:not([data-popper-placement=top]), .tooltip-chunky-basic.top[data-popper-placement=bottom] {
  margin-top: 10px;
  margin-left: 2.5px;
}
.tooltip-chunky-basic.bottom:not([data-popper-placement=top]) .tooltip-arrow, .tooltip-chunky-basic.top[data-popper-placement=bottom] .tooltip-arrow {
  top: -10px;
  height: 10px;
  width: 10px;
  border: none;
}
.tooltip-chunky-basic.bottom:not([data-popper-placement=top]) .tooltip-arrow:before, .tooltip-chunky-basic.top[data-popper-placement=bottom] .tooltip-arrow:before {
  content: "";
  display: block;
  margin-left: 2.5px;
  top: -10px;
  border-style: solid;
  border-width: 10px 0 0 10px;
  border-color: transparent transparent transparent #101525;
  transform: skew(6deg);
}

.tooltip-chunky-big, .tooltip-chunky-big-white {
  background: #101525;
  width: 335px;
  border-radius: 15px;
  filter: drop-shadow(0 5px 10px rgba(16, 21, 37, 0.35));
}
.tooltip-chunky-big .tooltip-body-title, .tooltip-chunky-big-white .tooltip-body-title {
  font-size: 18px;
  color: #fff;
  font-weight: 900;
  line-height: 22px;
  font-style: normal;
}
.tooltip-chunky-big.top:not([data-popper-placement=bottom]), .top.tooltip-chunky-big-white:not([data-popper-placement=bottom]), .tooltip-chunky-big.bottom[data-popper-placement=top], .bottom[data-popper-placement=top].tooltip-chunky-big-white {
  margin-bottom: 10px;
}
.tooltip-chunky-big.top:not([data-popper-placement=bottom]) .tooltip-arrow, .top.tooltip-chunky-big-white:not([data-popper-placement=bottom]) .tooltip-arrow, .tooltip-chunky-big.bottom[data-popper-placement=top] .tooltip-arrow, .bottom[data-popper-placement=top].tooltip-chunky-big-white .tooltip-arrow {
  top: 100%;
  height: 10px;
  width: 10px;
  border: none;
}
.tooltip-chunky-big.top:not([data-popper-placement=bottom]) .tooltip-arrow:before, .top.tooltip-chunky-big-white:not([data-popper-placement=bottom]) .tooltip-arrow:before, .tooltip-chunky-big.bottom[data-popper-placement=top] .tooltip-arrow:before, .bottom[data-popper-placement=top].tooltip-chunky-big-white .tooltip-arrow:before {
  content: "";
  display: block;
  margin-left: 2.5px;
  top: 100%;
  border-style: solid;
  border-width: 10px 10px 0 0;
  border-color: #101525 transparent transparent transparent;
  transform: skew(-6deg);
}
.tooltip-chunky-big.bottom:not([data-popper-placement=top]), .bottom.tooltip-chunky-big-white:not([data-popper-placement=top]), .tooltip-chunky-big.top[data-popper-placement=bottom], .top[data-popper-placement=bottom].tooltip-chunky-big-white {
  margin-top: 10px;
  margin-left: 2.5px;
}
.tooltip-chunky-big.bottom:not([data-popper-placement=top]) .tooltip-arrow, .bottom.tooltip-chunky-big-white:not([data-popper-placement=top]) .tooltip-arrow, .tooltip-chunky-big.top[data-popper-placement=bottom] .tooltip-arrow, .top[data-popper-placement=bottom].tooltip-chunky-big-white .tooltip-arrow {
  top: -10px;
  height: 10px;
  width: 10px;
  border: none;
}
.tooltip-chunky-big.bottom:not([data-popper-placement=top]) .tooltip-arrow:before, .bottom.tooltip-chunky-big-white:not([data-popper-placement=top]) .tooltip-arrow:before, .tooltip-chunky-big.top[data-popper-placement=bottom] .tooltip-arrow:before, .top[data-popper-placement=bottom].tooltip-chunky-big-white .tooltip-arrow:before {
  content: "";
  display: block;
  margin-left: 2.5px;
  top: -10px;
  border-style: solid;
  border-width: 10px 0 0 10px;
  border-color: transparent transparent transparent #101525;
  transform: skew(6deg);
}
.tooltip-chunky-big .tooltip-body, .tooltip-chunky-big-white .tooltip-body {
  padding: 25px;
  transform: skew(0);
}
.tooltip-chunky-big .tooltip-body-contents, .tooltip-chunky-big-white .tooltip-body-contents {
  color: #fff;
  line-height: 20px;
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  white-space: normal;
}

.tooltip-chunky-big-white .tooltip-body-contents {
  color: #fff;
}

.tooltip-link-highlight {
  display: flex;
  background: #fff;
  color: #43537f;
  min-height: 46px;
  max-width: 155px;
  border-radius: 10px;
  padding: 0 20px;
  box-shadow: 0px 5px 10px 0px rgba(131, 142, 171, 0.3490196078);
}

.dark {
  background-color: #35353f;
}
.dark .tooltip-body {
  background-color: transparent;
  padding: 25px;
  transform: none;
  font-style: normal;
  line-height: 20px;
}
.dark .tooltip-body .tooltip-body-contents {
  color: #fff;
  font-size: 14px;
  font-weight: normal;
}
.dark .tooltip-icon {
  color: #838eab;
  padding: 25px 3px 35px 20px;
  background-color: transparent;
  font-size: 19px;
}
.dark .tooltip-icon + .tooltip-body {
  padding: 25px 25px 25px 10px;
}
.dark.rounded {
  border-radius: 15px;
}
.dark.grey {
  background-color: #f8f9fd;
  color: #8a95b0;
  border-radius: 20px;
  box-shadow: 0 3px 12px 3px rgba(231, 232, 238, 0.65);
  padding: 10px;
}
.dark.grey .tooltip-body {
  border-radius: 15px;
}
.dark.grey .tooltip-icon {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.dark-new {
  width: 300px;
  background-color: #101525;
  border-radius: 15px;
}
.dark-new .tooltip-body {
  background-color: transparent;
  padding: 25px !important;
  transform: none;
  font-style: normal;
  line-height: 20px;
  text-align: left;
}
.dark-new .tooltip-body .tooltip-body-contents {
  color: #fff;
  font-size: 14px;
  font-weight: normal;
}
.dark-new .tooltip-body .tooltip-body-title {
  font-size: 20px;
  font-weight: 900;
  margin-bottom: 10px;
  text-align: left;
}
.dark-new .tooltip-icon {
  position: absolute;
  right: 12px;
  top: 12px;
  font-size: 14px !important;
  color: #838eab;
  padding: 5px !important;
  background-color: rgba(138, 149, 176, 0.17);
  border-radius: 50%;
}
.dark-new .tooltip-icon + .tooltip-body {
  padding: 25px 25px 25px 10px;
}
.dark-new.rounded {
  border-radius: 15px;
}
.dark-new.top:not([data-popper-placement=bottom]), .dark-new.bottom[data-popper-placement=top] {
  margin-bottom: 10px;
}
.dark-new.top:not([data-popper-placement=bottom]) .tooltip-arrow, .dark-new.bottom[data-popper-placement=top] .tooltip-arrow {
  top: 100%;
  height: 10px;
  width: 10px;
  border: none;
}
.dark-new.top:not([data-popper-placement=bottom]) .tooltip-arrow:before, .dark-new.bottom[data-popper-placement=top] .tooltip-arrow:before {
  content: "";
  display: block;
  margin-left: 2.5px;
  top: 100%;
  border-style: solid;
  border-width: 10px 10px 0 0;
  border-color: #101525 transparent transparent transparent;
  transform: skew(-6deg);
}
.dark-new.bottom:not([data-popper-placement=top]), .dark-new.top[data-popper-placement=bottom] {
  margin-top: 10px;
  margin-left: 2.5px;
}
.dark-new.bottom:not([data-popper-placement=top]) .tooltip-arrow, .dark-new.top[data-popper-placement=bottom] .tooltip-arrow {
  top: -10px;
  height: 10px;
  width: 10px;
  border: none;
}
.dark-new.bottom:not([data-popper-placement=top]) .tooltip-arrow:before, .dark-new.top[data-popper-placement=bottom] .tooltip-arrow:before {
  content: "";
  display: block;
  margin-left: 2.5px;
  top: -10px;
  border-style: solid;
  border-width: 10px 0 0 10px;
  border-color: transparent transparent transparent #101525;
  transform: skew(6deg);
}
.dark-new .tooltip-arrow {
  border-color: #101525 transparent transparent !important;
}

ssi-tooltip-window.top {
  margin-bottom: 10px;
}
ssi-tooltip-window.top .tooltip-arrow {
  top: 100%;
  border-width: 10px 10px 0 10px;
  border-color: #3f4142 transparent transparent transparent;
}

ssi-tooltip-window.bottom {
  margin-top: 10px;
}
ssi-tooltip-window.bottom .tooltip-arrow {
  top: -10px;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #3f4142 transparent;
}

ssi-tooltip-window.tooltip-bg-secondary {
  background-color: #2e3031;
}

ssi-tooltip-window.top.tooltip-bg-secondary {
  margin-bottom: 10px;
}
ssi-tooltip-window.top.tooltip-bg-secondary .tooltip-arrow {
  top: 100%;
  border-width: 10px 10px 0 10px;
  border-color: #2e3031 transparent transparent transparent;
}

ssi-tooltip-window.bottom.tooltip-bg-secondary {
  margin-top: 10px;
}
ssi-tooltip-window.bottom.tooltip-bg-secondary .tooltip-arrow {
  top: -10px;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #2e3031 transparent;
}