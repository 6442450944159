ssi-widget-inbox-message {
  height: 100%;
  width: 100%;
}
ssi-widget-inbox-message .dashboard-widget {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 1px;
}
ssi-widget-inbox-message .dashboard-widget ssi-live-chat-tally-tile {
  z-index: 1;
}
ssi-widget-inbox-message .dashboard-widget ssi-inbox-query-result-list.live-chat-tally-visible .inbox-query-result-list {
  height: calc(100% - 50px);
}