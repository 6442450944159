.btn-style-1 {
  font-size: 12px;
  font-weight: 900;
  border-radius: 20px;
  padding: 10px 17px;
  border-width: 1px;
  border-style: solid;
}

.btn-style-1 + .btn-style-1 {
  margin-left: 5px;
}

.btn-style-1.btn-default {
  color: #14bae3;
  padding: 12px 17px;
  border-width: 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.btn-style-1.btn-primary {
  color: #5096aa;
  background-color: #eef5f7;
  border-color: #fff;
}
.btn-style-1.btn-primary:hover {
  background-color: #cbe1e7;
}
.btn-style-1.btn-primary:active {
  background-color: #a9ccd6;
}

.btn-style-1.btn-secondary {
  color: #fff;
  background-color: #565d62;
  border-width: 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}
.btn-style-1.btn-secondary:hover {
  color: #fff;
  background-color: #3e4347;
}
.btn-style-1.btn-secondary:active {
  background-color: #26292c;
}

.btn-style-1.btn-warning {
  color: #fff;
  background-color: #debf50;
  border-color: #ecd273;
}
.btn-style-1.btn-warning:hover {
  color: #fff;
  background-color: #d3ae28;
}
.btn-style-1.btn-warning:active {
  background-color: #a88a20;
}

.btn-style-1.btn-danger {
  color: #ff4d11;
  background-color: #fff6f3;
  border-color: #fff;
}
.btn-style-1.btn-danger:hover {
  color: #fff;
  background-color: #ffd0c0;
}
.btn-style-1.btn-danger:active {
  background-color: #dea79b;
}

.btn-style-1 > .btn-style-1-icon:first-child {
  margin-right: 3px;
}

.btn-style-1 > .btn-style-1-icon:last-child {
  margin-left: 3px;
}

.label-style-1 {
  display: inline-block;
  margin: 5px;
  background-color: #454c56;
  border-width: 2px;
  border-style: solid;
  border-radius: 20px;
  padding: 8px 18px;
  font-size: 12px;
  vertical-align: middle;
}
.label-style-1 p {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
}

.label-style-1-close {
  color: #727c82;
  font-size: 20px;
  margin-left: 10px;
  vertical-align: middle;
  cursor: pointer;
}
.label-style-1-close:hover, .label-style-1-close:focus {
  opacity: 0.6;
}

.label-style-1-yellow {
  border-color: #debf50;
}

.label-style-1-pink {
  border-color: #e9a898;
}

.validate-post-boost {
  position: relative;
  background-color: #40454f;
  font-size: 12px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  overflow: hidden;
  z-index: 2;
  margin-right: -50px;
}

.boost-header {
  position: relative;
  background-color: #505760;
  color: #fff;
  text-align: center;
  padding: 25px 20px;
}

.boost-summary {
  margin-bottom: 0;
}
.boost-summary dt,
.boost-summary dd {
  display: inline-block;
  font-weight: 500;
}
.boost-summary dd {
  margin-left: 3px;
}
.boost-summary dd:after {
  content: "|";
  color: #fff;
  margin: 0 8px;
}
.boost-summary dd:last-child:after {
  content: normal;
}

.boost-summary-action {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  display: inline-block;
  color: #14bae3;
  border-radius: 50%;
  background-color: #40454f;
  font-size: 14px;
  height: 42px;
  width: 42px;
  line-height: 42px;
  cursor: pointer;
  right: 20px;
}

.boost-content {
  display: flex;
  font-size: 14px;
  color: #727c82;
  padding: 25px 20px;
  text-align: center;
}

.boost-content-title {
  font-weight: 500;
  font-style: italic;
  border-bottom: 1px solid #727c82;
  padding: 0 10px 15px;
  margin-bottom: 15px;
}

.boost-content-action {
  margin: 15px 10px 0 10px;
}
.boost-content-action p {
  display: inline-block;
  color: #3bb8fe;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}
.boost-content-action p:hover, .boost-content-action p:focus {
  text-decoration: underline;
}
.boost-content-action p:active {
  color: #0187d2;
}

.boost-content-left,
.boost-content-right {
  flex: 1 1 50%;
}

.boost-content-left .label-style-1 {
  margin: 5px 5px 5px 0;
}

.boost-content-right .label-style-1 {
  margin: 5px 0 5px 5px;
}

.boost-content-left {
  border-right: 1px solid #727c82;
}

.boost-footer {
  background-color: #3a3f49;
  padding: 25px 20px;
  text-align: center;
}

.boost-footer-body {
  color: #fff;
  margin-bottom: 25px;
}

.boost-footer-title {
  font-size: 16px;
}