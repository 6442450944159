@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-analytics ssi-disapprovals ssi-report ssi-breakdown {
  .breakdown-container {
    .table-container {
      padding: 30px 30px 60px 30px;
      background-color: #fff;
      border-radius: 10px;

      .options-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
      }

      .head-cell {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;
        padding: 0 5px 0 10px;
      }

      .body-cell {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;
        padding: 0 5px 0 10px;
        border-bottom: 2px solid #e0e7f4;

        &.row-even {
          background-color: $grey-background;
        }

        &.account {
          color: $lmode-body-text;
          font-size: 12px;
          font-weight: 900;
          line-height: 14px;

          span {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            margin-left: 10px;

            @media print {
              white-space: normal;
            }
          }
        }

        &.stat {
          color: $lmode-title-text;
          font-size: 14px;
          font-weight: 900;

          @media print {
            display: flex;
            flex-wrap: wrap;

            ssi-stat-change {
              margin-left: 0;
            }
          }

          ssi-stat-change {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
