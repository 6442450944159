ssi-campaign ssi-posts-breakdown .progress-bars-reach-totals {
  display: flex;
}
ssi-campaign ssi-posts-breakdown .progress-bars-reach-totals .posts-by-network {
  flex: 1 1 75%;
  padding: 40px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.35);
}
ssi-campaign ssi-posts-breakdown .progress-bars-reach-totals .posts-by-network h4 {
  margin-bottom: 50px;
}
ssi-campaign ssi-posts-breakdown .progress-bars-reach-totals .posts-by-network ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
ssi-campaign ssi-posts-breakdown .progress-bars-reach-totals .posts-by-network li {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
ssi-campaign ssi-posts-breakdown .progress-bars-reach-totals .posts-by-network li span {
  min-width: 100px;
  margin: 0;
  font-weight: 900;
  color: #838eab;
}
ssi-campaign ssi-posts-breakdown .progress-bars-reach-totals .posts-by-network li span + span {
  min-width: 70px;
  color: #101525;
}
@media (max-width: 767px) {
  ssi-campaign ssi-posts-breakdown .progress-bars-reach-totals .posts-by-network {
    width: 100%;
  }
}
ssi-campaign ssi-posts-breakdown .progress-bars-reach-totals .network-reach-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 25%;
  border-radius: 12px;
  margin-left: 20px;
}
@media (max-width: 767px) {
  ssi-campaign ssi-posts-breakdown .progress-bars-reach-totals .network-reach-container {
    width: 100%;
    margin: 15px 0 0 0;
  }
}
ssi-campaign ssi-posts-breakdown .progress-bars-reach-totals .account-fact-total {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  flex: 1 1 25%;
  margin-bottom: 20px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.35);
}
ssi-campaign ssi-posts-breakdown .progress-bars-reach-totals .account-fact-total:last-child {
  margin-bottom: 0;
}
ssi-campaign ssi-posts-breakdown .progress-bars-reach-totals .account-fact-total .fact-details {
  display: flex;
  align-items: baseline;
}
ssi-campaign ssi-posts-breakdown .progress-bars-reach-totals .account-fact-total .fact-details i {
  color: #fff;
  margin-right: 7px;
  font-size: 16px;
  border-radius: 50%;
  padding: 10px;
}
ssi-campaign ssi-posts-breakdown .progress-bars-reach-totals .account-fact-total .fact-details i.fa-facebook {
  padding: 10px 12px;
}
ssi-campaign ssi-posts-breakdown .progress-bars-reach-totals .account-fact-total .fact-details span {
  display: block;
  font-weight: 900;
  font-size: 16px;
  color: #43537f;
}
ssi-campaign ssi-posts-breakdown .progress-bars-reach-totals .account-fact-total .fact-details span:last-child {
  letter-spacing: 1px;
  font-size: 10px;
  color: #838eab;
  text-transform: uppercase;
}
ssi-campaign ssi-posts-breakdown .progress-bars-reach-totals .account-fact-total ssi-big-number {
  display: flex;
  justify-content: flex-end;
  font-size: 28px;
  font-weight: 900;
  color: #101525;
  margin-top: auto;
}
@media (max-width: 767px) {
  ssi-campaign ssi-posts-breakdown .progress-bars-reach-totals {
    flex-wrap: wrap;
  }
}
ssi-campaign ssi-posts-breakdown .progress-bars-reach-totals.wrapped {
  flex-wrap: wrap;
}
ssi-campaign ssi-posts-breakdown .progress-bars-reach-totals.wrapped .posts-by-network,
ssi-campaign ssi-posts-breakdown .progress-bars-reach-totals.wrapped .network-reach-container {
  flex-basis: 100%;
}
ssi-campaign ssi-posts-breakdown .progress-bars-reach-totals.wrapped .network-reach-container {
  margin-left: 0;
  margin-top: 20px;
}
@media (min-width: 1025px) {
  ssi-campaign ssi-posts-breakdown .progress-bars-reach-totals.wrapped .network-reach-container {
    flex-direction: row;
    margin-top: 20px;
  }
  ssi-campaign ssi-posts-breakdown .progress-bars-reach-totals.wrapped .network-reach-container .account-fact-total {
    margin: 0 0 0 20px;
  }
  ssi-campaign ssi-posts-breakdown .progress-bars-reach-totals.wrapped .network-reach-container .account-fact-total:first-child {
    margin-left: 0;
  }
}
ssi-campaign ssi-posts-breakdown .reactions-inbound {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
@media print {
  ssi-campaign ssi-posts-breakdown .reactions-inbound {
    display: block;
  }
}
ssi-campaign ssi-posts-breakdown .reactions-inbound .single-facts {
  display: flex;
  flex-wrap: wrap;
  width: calc(50% - 10px);
  flex: 1 1 auto;
}
@media (max-width: 1199px) {
  ssi-campaign ssi-posts-breakdown .reactions-inbound .single-facts {
    width: 100%;
  }
}
ssi-campaign ssi-posts-breakdown .reactions-inbound .single-fact {
  width: calc(50% - 20px);
  margin: 10px;
  text-align: center;
}
ssi-campaign ssi-posts-breakdown .reactions-inbound .single-fact:first-child, ssi-campaign ssi-posts-breakdown .reactions-inbound .single-fact:first-child + * {
  margin-top: 0;
}
ssi-campaign ssi-posts-breakdown .reactions-inbound .single-fact:nth-child(odd) {
  margin-left: 0;
}
ssi-campaign ssi-posts-breakdown .reactions-inbound .single-fact:nth-child(even) {
  margin-right: 0;
}
ssi-campaign ssi-posts-breakdown .reactions-inbound .single-fact:nth-child(3n), ssi-campaign ssi-posts-breakdown .reactions-inbound .single-fact:nth-child(4n) {
  margin-bottom: 0;
}
@media (max-width: 767px) {
  ssi-campaign ssi-posts-breakdown .reactions-inbound .single-fact:nth-child(3n), ssi-campaign ssi-posts-breakdown .reactions-inbound .single-fact:nth-child(4n) {
    margin-bottom: 10px;
  }
}
@media (max-width: 1199px) {
  ssi-campaign ssi-posts-breakdown .reactions-inbound .single-fact {
    width: calc(50% - 10px);
  }
}
@media (max-width: 767px) {
  ssi-campaign ssi-posts-breakdown .reactions-inbound .single-fact {
    width: 100%;
    margin: 10px 0;
  }
  ssi-campaign ssi-posts-breakdown .reactions-inbound .single-fact:first-child + * {
    margin: 10px 0;
  }
}
ssi-campaign ssi-posts-breakdown .reactions-inbound .inbound-comments-pie-chart {
  height: 470px;
  width: calc(50% - 10px);
  padding: 40px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.35);
}
@media print {
  ssi-campaign ssi-posts-breakdown .reactions-inbound .inbound-comments-pie-chart {
    display: block;
    border: 1px solid black;
    page-break-inside: avoid;
  }
}
@media (max-width: 1199px) {
  ssi-campaign ssi-posts-breakdown .reactions-inbound .inbound-comments-pie-chart {
    width: 100%;
    margin-top: 20px;
  }
}
@media (max-width: 1199px) {
  ssi-campaign ssi-posts-breakdown .reactions-inbound {
    flex-wrap: wrap;
  }
}
ssi-campaign ssi-posts-breakdown ssi-fact-overview .fact-overview {
  padding: 30px 15px;
  margin: 0;
}
ssi-campaign ssi-posts-breakdown ssi-fact-overview i {
  padding: 10px 10px 0 10px;
}