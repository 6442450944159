@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

.settings-chat-bot {
  font-size: 1rem;

  table {
    thead p {
      font-weight: 700;
    }

    p {
      font-size: 1.4em;
    }
  }
}
