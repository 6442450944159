@media (min-width: 885px) {
  ssi-publisher-schedule-action-buttons .publisher-action-buttons-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  ssi-publisher-schedule-action-buttons .publisher-action-buttons-container .action-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #14bae3;
    color: #fff;
    border-radius: 50%;
    height: 46px;
    width: 46px;
    margin-right: 25px;
    border: 0;
    font-size: 14px;
    font-weight: normal;
  }
  ssi-publisher-schedule-action-buttons .publisher-action-buttons-container .action-button:last-child {
    margin-right: 0;
  }
  ssi-publisher-schedule-action-buttons .publisher-action-buttons-container .action-button:focus {
    outline: revert;
  }
  ssi-publisher-schedule-action-buttons .publisher-action-buttons-container .action-button:hover, ssi-publisher-schedule-action-buttons .publisher-action-buttons-container .action-button:focus {
    color: #fff;
  }
  ssi-publisher-schedule-action-buttons .publisher-action-buttons-container .action-button.disabled {
    background-color: transparent;
    color: #b4bbce;
    cursor: not-allowed;
  }
  ssi-publisher-schedule-action-buttons .publisher-action-buttons-container .action-button.disabled:hover, ssi-publisher-schedule-action-buttons .publisher-action-buttons-container .action-button.disabled:focus {
    background-color: #f0f3f9;
  }
  ssi-publisher-schedule-action-buttons .publisher-action-buttons-container .action-button.disabled i {
    font-size: 28px;
    color: inherit;
  }
  ssi-publisher-schedule-action-buttons .publisher-action-buttons-container .action-button i {
    font-size: 28px;
    color: inherit;
  }
  ssi-publisher-schedule-action-buttons .publisher-action-buttons-container .action-button i.ssi-send-icon {
    font-size: 24px;
  }
  ssi-publisher-schedule-action-buttons .publisher-action-buttons-container .action-button:disabled, ssi-publisher-schedule-action-buttons .publisher-action-buttons-container .action-button:disabled:hover {
    background-color: #f7f8fc;
    border-color: #f7f8fc;
    color: #838eab;
  }
}
@media (max-width: 885px) {
  ssi-publisher-schedule-action-buttons .publisher-action-buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    margin-top: 20px;
  }
  ssi-publisher-schedule-action-buttons .publisher-action-buttons-container .action-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-basis: 50%;
    height: 154px;
    border-radius: 20px;
    border: 0;
    background: #14bae3;
    color: #fff;
  }
  ssi-publisher-schedule-action-buttons .publisher-action-buttons-container .action-button i {
    font-size: 28px;
    margin-bottom: 10px;
  }
  ssi-publisher-schedule-action-buttons .publisher-action-buttons-container .action-button span {
    font-size: 14px;
    font-weight: 900;
  }
  ssi-publisher-schedule-action-buttons .publisher-action-buttons-container .action-button.preview-btn {
    margin-right: 10px;
    margin-left: 5px;
    background: #f3fcfe;
    border: 1px solid #14bae3;
    color: #14bae3;
  }
  ssi-publisher-schedule-action-buttons .publisher-action-buttons-container .action-button.preview-btn:disabled, ssi-publisher-schedule-action-buttons .publisher-action-buttons-container .action-button.preview-btn.disabled {
    color: #b4bbce;
    background-color: #f7f8fc;
    border: 0;
  }
  ssi-publisher-schedule-action-buttons .publisher-action-buttons-container .action-button.publish-btn {
    margin-right: 5px;
  }
  ssi-publisher-schedule-action-buttons .publisher-action-buttons-container .action-button.publish-btn:disabled, ssi-publisher-schedule-action-buttons .publisher-action-buttons-container .action-button.publish-btn.disabled {
    color: #b4bbce;
    background-color: #f7f8fc;
    border: 0;
  }
}