ssi-inbox-activity-thread {
  background-color: #f8f9fd;
  display: flex;
  flex-direction: column;
  min-height: 0;
  height: 100%;
}
ssi-inbox-activity-thread .inbox-split-conversation-dialog {
  background: #d8e1e5;
  border-top: dashed 2px #fff;
  border-bottom: dashed 2px #fff;
  display: flex;
  justify-content: center;
  padding: 20px;
}
ssi-inbox-activity-thread .inbox-split-conversation-dialog * {
  display: flex;
}
ssi-inbox-activity-thread .inbox-split-conversation-dialog > ul {
  flex-flow: row nowrap;
  list-style: none;
  margin: 0;
  padding: 0;
}
ssi-inbox-activity-thread .inbox-split-conversation-dialog > ul > li {
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
  font-size: 0.85em;
  font-style: italic;
  font-weight: 500;
  margin: 0 3px;
  padding: 3px 12px;
}
ssi-inbox-activity-thread .inbox-split-conversation-dialog > ul > li.inbox-split-conversation-confirm {
  background-color: #43ade8;
}
ssi-inbox-activity-thread .inbox-split-conversation-dialog > ul > li.inbox-split-conversation-undo {
  background-color: #7d90a0;
}
ssi-inbox-activity-thread .scroll-container {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
ssi-inbox-activity-thread .scroll-container.scroll-container-reply {
  padding-bottom: 85px;
  margin-bottom: 338px;
}
ssi-inbox-activity-thread .scroll-container.scroll-container-reply.scroll-container-reply-compressed {
  padding-bottom: 55px;
  margin-bottom: 88px;
}
ssi-inbox-activity-thread .scroll-container.scroll-container-reply.scroll-container-reply-expanded {
  padding-bottom: 55px;
  margin-bottom: 618px;
}
ssi-inbox-activity-thread .scroll-container.scroll-container-reply.scroll-container-reply-hidden {
  padding-bottom: 55px;
  margin-bottom: 98px;
}
ssi-inbox-activity-thread .scroll-container.scroll-container-reply.scroll-container-reply-none {
  margin-bottom: 0;
  padding-bottom: 143px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  ssi-inbox-activity-thread .scroll-container.scroll-container-reply:after {
    content: "";
    display: block;
    height: 85px;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  ssi-inbox-activity-thread .scroll-container.scroll-container-reply.scroll-container-reply-compressed:after, ssi-inbox-activity-thread .scroll-container.scroll-container-reply.scroll-container-reply-expanded:after, ssi-inbox-activity-thread .scroll-container.scroll-container-reply.scroll-container-reply-hidden:after {
    content: "";
    display: block;
    height: 55px;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  ssi-inbox-activity-thread .scroll-container.scroll-container-reply.scroll-container-reply-none:after {
    content: "";
    display: block;
    height: 143px;
  }
}
ssi-inbox-activity-thread .scroll-container > *:first-child {
  margin-top: 16px;
}
ssi-inbox-activity-thread .scroll-container .conversation-on-hold-indicator-container {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 60px;
}
ssi-inbox-activity-thread .scroll-container .conversation-on-hold-indicator-container .line {
  flex: 1 1 auto;
  height: 2px;
  border-radius: 10px;
  background-color: #e7edfd;
}
ssi-inbox-activity-thread .scroll-container .conversation-on-hold-indicator-container .conversation-on-hold-indicator {
  flex: 0 0 auto;
  border-radius: 10px;
  background-color: #e7edfd;
  height: 36px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  color: #101525;
  font-size: 12px;
  font-weight: 900;
}
ssi-inbox-activity-thread .scroll-container .conversation-on-hold-indicator-container .conversation-on-hold-indicator i {
  margin-right: 10px;
}
ssi-inbox-activity-thread .inbox-activity-thread-expander.border-top {
  border-top: solid 2px #e7ebee;
  padding-top: 5px;
}
ssi-inbox-activity-thread .hide-activity-top .activity-top {
  display: none;
}
ssi-inbox-activity-thread .inbox-activity-thread-children {
  margin-left: 50px;
}
ssi-inbox-activity-thread .inbox-activity-thread-children .activity-reply {
  padding-left: 0;
}
ssi-inbox-activity-thread .inbox-activity-thread-indent-0 .inbox-activity-thread-children {
  margin-left: 0;
}
ssi-inbox-activity-thread .inbox-activity-thread-history.expanded .thread-expand-icon,
ssi-inbox-activity-thread .inbox-activity-thread-newer-activities.expanded .thread-expand-icon {
  position: absolute;
  top: -50px;
}
ssi-inbox-activity-thread .inbox-activity-thread-history.expanded + .inbox-activity-reply-container .thread-expand-icon {
  position: absolute;
  top: -50px;
}
ssi-inbox-activity-thread .inbox-activity-thread-indent-1 {
  margin-left: 0;
}
ssi-inbox-activity-thread .inbox-activity-thread-indent-1 .inbox-activity-reply-container ssi-activity,
ssi-inbox-activity-thread .inbox-activity-thread-indent-1 .inbox-activity-thread-container ssi-activity {
  margin-top: 0;
}
ssi-inbox-activity-thread .inbox-activity-thread-indent-2 {
  margin-left: 50px;
}
ssi-inbox-activity-thread .inbox-activity-thread-indent-2 .inbox-activity-thread-children {
  margin-left: 0;
}
ssi-inbox-activity-thread .thread-expand-icon {
  color: #7d90a0;
  font-size: 1.6em;
  margin-left: 130px;
  margin-top: 10px;
}
ssi-inbox-activity-thread .inbox-activity-thread-expander {
  display: flex;
  margin: 10px 0 0 14px;
  padding: 0;
}
ssi-inbox-activity-thread .inbox-activity-thread-expander a {
  font-weight: normal;
  font-size: 12px;
}
ssi-inbox-activity-thread .inbox-activity-thread-expander a span {
  display: inline-block;
  background: #fff;
  border-radius: 100px;
  color: #14bae3;
  font-weight: 900;
  font-size: 12px;
  line-height: 14px;
  padding: 13px 24px;
  min-width: 120px;
  text-rendering: geometricPrecision;
}
ssi-inbox-activity-thread .inbox-activity-thread-expander a:hover {
  text-decoration: none;
}
ssi-inbox-activity-thread .inbox-activity-thread-expander.warning i {
  color: #e2b928;
}
ssi-inbox-activity-thread .inbox-activity-thread-expander.royal i {
  color: #a12378;
}
ssi-inbox-activity-thread .inbox-activity-thread-expander.info {
  margin-left: -28px;
}
ssi-inbox-activity-thread .inbox-activity-thread-expander.info i {
  color: #5bc0de;
}
@keyframes flash-animation {
  from {
    background: #edf1f3;
  }
  to {
    background: inherit;
  }
}
ssi-inbox-activity-thread .inbox-activity-flash-on-entry {
  display: block;
  animation-name: flash-animation;
  animation-duration: 2s;
}
ssi-inbox-activity-thread .inbox-activity-thread-footer {
  height: 50px;
  overflow: hidden;
  background-color: #edf1f3;
  padding: 15px;
  z-index: 4;
  box-shadow: 0 -3px 10px rgba(0, 0, 0, 0.175);
}
ssi-inbox-activity-thread .inbox-activity-thread-footer .btn {
  border: 0;
}
ssi-inbox-activity-thread .inbox-activity-thread-footer > ul {
  display: flex;
  flex-flow: row nowrap;
  font-size: 12px;
  list-style: none;
  margin: 0;
  padding: 0;
}
ssi-inbox-activity-thread .inbox-activity-thread-footer > ul > li {
  padding: 0 10px 0 0;
}
ssi-inbox-activity-thread .inbox-activity-thread-footer > ul > li > button {
  background-color: #fff;
  border: none;
  border-radius: 10px;
  color: #43ade8;
  font-weight: 400;
}