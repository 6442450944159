@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../angularjs/common/sass/variables';
@import '../../../../../../angularjs/common/sass/mixins';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';
@import '../../publisher.variables.scss';
@import '../../publisher.mixins.scss';
@import '../../../../../../angularjs/common/sass/include-media';
@import '~/styles/colors';

$ssi-publisher-responsive-width: 885px;
$ssi-publisher-actions-responsive-margin: 10px;

ssi-publisher-actions-and-media {
  .publisher-actions {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: $lmgrey-background;
  }

  .publisher-actions-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    @media (max-width: $ssi-publisher-responsive-width) {
      padding: 5px;
      position: absolute;
      margin-top: 5px;
      width: 100vw;
    }
  }

  .publisher-actions-row {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    user-select: none;
    margin: -5px;
    position: relative;

    @include media('<#{$ssi-publisher-responsive-width}') {
      padding: 0 10px;
    }

    &.loading-cover-visible {
      margin: 0;
    }

    .loading-cover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $lmgrey-background;
      border-radius: 25px;
      border: 6px solid #fff;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      i {
        color: $primary-active;
      }

      span {
        margin-top: 10px;
        color: $lmode-helper-text;
        font-size: 14px;
        font-weight: 700;
      }
    }

    @include media('<#{$ssi-publisher-responsive-width}') {
      align-content: flex-start;
    }
  }

  .publisher-close-compose {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .pro-tip {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 12px;
      color: $primary-active;
      i {
        margin-right: 5px;
        font-size: 16px;
      }
    }

    ssi-toggle-switch-2 {
      margin: 0 1em 0 0;
    }

    @include media('<#{$ssi-publisher-responsive-width}') {
      display: none;
    }
  }

  @mixin publisher-action-color($bg-color, $text-color) {
    .publisher-action-inner {
      background-color: $bg-color;
      color: $text-color;
      transition: background-color 400ms;
      border-radius: 15px;
    }

    .publisher-action-inner:hover {
      background-color: shade($bg-color, 3%);
    }
  }

  .publisher-action {
    flex-basis: calc(100% / 3);
    padding: 5px;
    appearance: none;
    outline: none;
    border: none;
    background-color: transparent;

    @include publisher-action-color(
      $publisher-action-bg,
      $publisher-action-color
    );

    i {
      color: #838eab;
    }
    .publisher-action-title {
      color: #43537f;
    }

    &.half-width {
      flex-basis: 50%;
    }
    &.active {
      i {
        color: #fff;
      }
      .publisher-action-title {
        color: #fff;
      }
    }
    &:focus {
      outline: revert;
    }
    &:hover {
      cursor: pointer;
    }
    &:disabled {
      i {
        color: #b4bbce;
      }
      .publisher-action-title {
        color: #b4bbce;
      }
    }

    @include media('<#{$ssi-publisher-responsive-width}') {
      flex-basis: calc(100% / 2);
      height: 154px;
    }
  }
  .publisher-action-inner {
    @include align-vertical(100%);
    text-align: center;
    i,
    ssi-icon {
      padding: 9px 0;
      height: 45px;
      color: $dmode-body-text;
    }
  }
  // yeah, I know right, I wish all the icons were uniform sizes
  .publisher-action.publisher-action-preview {
    i {
      font-size: 40px;
    }
  }
  .publisher-action-schedule {
    i {
      font-size: 28px;
    }
  }
  .publisher-action-album,
  .publisher-action-targeting,
  .publisher-action-content-approval,
  .publisher-action-publish,
  .publisher-action-draft,
  .publisher-action-add-image,
  .publisher-action-add-video {
    i {
      font-size: 31px;
    }
  }
  .publisher-action-inner.spacer-top {
    height: calc(100% - #{$section-margin-width});
  }
  .publisher-action[disabled] {
    @include publisher-action-color(
      $publisher-action-disabled-bg,
      darken($publisher-action-disabled-color, 11%)
    );
  }
  .publisher-action.active {
    @include publisher-action-color($primary-active, $text-light);
  }
  .publisher-action-title {
    margin-top: $section-margin-width;
    margin-top: 8px;
    @media (max-width: $ssi-publisher-responsive-width) {
      margin-top: 4px;
      margin-bottom: 10px;
    }
    font-size: 14px;
    font-weight: 900;
    color: #43537f;
  }
  .publisher-media {
    background-color: white;
    flex: 1;
    margin-top: $publisher-padding;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    height: 290px; // fix height hack required for firefox + Edge // TODO - find a better way of doing this
    overflow: hidden;
    @media (max-width: $ssi-publisher-responsive-width) {
      padding: 0 10px 70px 10px;
    }

    video {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    $publisher-media-text-color: $lmode-helper-text;

    .publisher-media-actions,
    ssi-media-nav {
      margin-top: 10px;

      .media-nav-img {
        border-radius: 3px 3px 15px 15px;
      }
      .edit-image {
        margin: 0 40px 0 10px;
      }
      .add-subtitles {
        padding: 7px 9px;
        border-radius: 15px;
        display: flex;
        align-items: flex-end;
        i {
          font-size: 16px;
          margin-right: 5px;
        }
        &:hover {
          background-color: $lmgrey-background;
        }
      }
      .remove-image {
        background-color: $primary-red;
        color: $secondary-red;
        padding: 7px;
        font-size: 18px;
        border-radius: 50%;
        display: flex;
        span {
          display: none;
          margin-left: 5px;
          font-family: $font-family-sans-serif;
          font-size: 12px;
          font-weight: bold;
        }
        &:hover {
          border-radius: 15px;
          span {
            display: flex;
            align-items: center;
          }
        }
      }
    }
    .publisher-media-actions {
      display: flex;
      align-items: center;
      padding: 0 10px;

      a {
        &:last-child {
          margin-left: auto;
        }

        i {
          margin-right: 3px;
        }
      }

      .ig-specific-media-label {
        color: #838eab;
        font-size: 10px;
        font-weight: 900;
        letter-spacing: 1px;
        text-transform: uppercase;
        padding: 5px 10px;
        background-color: #f4f4fa;
        border-radius: 5px;
      }

      ssi-checkbox-2 {
        margin-left: 10px;
      }
    }
    ssi-media-nav {
      flex: 1;
      padding-top: 0;
    }
    a {
      color: $publisher-media-text-color;
      font-size: 12px;
      font-weight: bold;
      &.alt-text {
        background-color: $lmgrey-background;
        border-radius: 15px;
        padding: 7px 10px;
        color: $primary-active;
      }
    }
    a:hover {
      text-decoration: none;
      color: shade($publisher-media-text-color, 10%);
    }
  }
}
ssi-publisher-actions-and-media.media-visible {
  // don't apply special styles for mobiles when media attached
  @include media('>#{$ssi-publisher-responsive-width}') {
    align-content: flex-start;

    .publisher-title-container,
    .publisher-action-title {
      display: none;
    }
    .publisher-actions {
      flex-direction: row-reverse;
      flex: 0;
      flex-basis: $publisher-action-sm-height;
    }

    .publisher-actions-row {
      justify-content: flex-start;
      margin: 0 10px 0 -25px;
      .publisher-action {
        flex-basis: calc(100% / 6);
        margin-left: 25px;
        border-radius: 50%;
        max-width: 45px;
        padding: 0;
        cursor: pointer;
        &.active,
        &.save-active {
          background-color: $primary-active;
        }
        @include media('<portal') {
          margin-left: 10px;
        }
      }
    }
    .pro-tip {
      display: none;
    }
    .publisher-actions {
      background-color: #fff;
      border-radius: 15px;
      padding: 14px;
      .publisher-action {
        .publisher-action-inner {
          background-color: transparent;
          border-radius: 50%;
          i {
            font-size: 24px;
            color: $lmode-helper-text;
            &.ssi-eye {
              font-size: 28px;
            }
          }
        }

        &:focus {
          outline: revert;
        }

        &.active {
          i {
            color: #fff;
          }
        }

        &.disabled {
          opacity: 0.4;
        }
      }
    }
    .publisher-close-compose {
      margin-bottom: 0;
    }
    .publisher-close-btn {
      @media (max-width: $ssi-publisher-responsive-width) {
        padding: 8px;
      }
      margin-bottom: 0;
    }
  }
}
