@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-assign-message {
  .content-container {
    background-color: #fff;
  }

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 1 10%;
    padding: 40px 40px 20px 40px;

    h3 {
      color: $lmode-body-text;
      margin: 0 auto 0 10px;
      font-weight: 900;
      font-size: 19px;
    }

    i:first-child {
      color: $lmode-helper-text;
      font-size: 22px;
    }

    i.close-icon {
      padding: 12px;
      background-color: $lmode-border-color;
      border-radius: 50px;
      color: $lmode-helper-text;
      font-size: 12px;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .search-field {
    display: flex;
    margin: 20px 60px 0 50px;
    padding-bottom: 5px;
    border-bottom: 2px $lmode-border-color solid;
    color: $lmode-helper-text;

    &.active {
      border-color: $primary-active;
    }

    input {
      height: 30px;
      border: 0;
      font-size: 16px;
      color: $lmode-title-text;
      box-shadow: none;
      background-color: transparent;
      width: 100%;
      outline: none;
      font-weight: 500;
      &::placeholder {
        color: $lmode-helper-text;
        font-weight: bold;
        font-size: 14px;
      }
    }

    i {
      font-size: 20px;
    }
  }

  hr {
    border: 1px solid $lmode-border-color;
    margin: 0;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 30px 20px 30px;
    flex: 0 1 10%;
    overflow: hidden;
    border-top: 2px solid $lmode-border-color;

    .assign-to-me {
      margin-right: auto;
      background: none;
      border: 0;
      border-radius: 15px;
      box-shadow: 0px 0px 10px 0px rgba(202, 202, 202, 0.75);
      padding: 5px 15px;
      font-weight: bold;
      color: $lmode-body-text;
      outline: none;
      i {
        margin-right: 10px;
      }

      &:hover {
        color: $primary-active;
      }
      &:disabled {
        color: $lmode-body-text;
        cursor: not-allowed;
      }
    }

    orlo-button {
      span {
        font-size: 12px !important;
        letter-spacing: 1px;
      }
      button {
        padding: 10px 10px;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    margin: 20px 30px 20px 40px;
    min-height: 300px;
    max-height: 300px;
    overflow: auto;
    scrollbar-color: $primary-active;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 10px;
      background-color: $lmode-border-color;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: $primary-active;
      box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        display: flex;
        align-items: baseline;
        color: $lmode-body-text;
        font-size: 14px;
        font-weight: bold;
        padding: 10px;
        margin-bottom: 5px;

        i {
          color: $lmode-helper-text;
        }

        .tick {
          display: none;
        }

        &:hover,
        &.selected {
          color: $primary-active;
          background-color: $grey-background;
          border-radius: 25px;
          margin-right: 30px;
          cursor: default;
          &.selected {
            i.tick {
              position: relative;
              display: inline-block;
              margin-left: auto;
              &:after {
                display: block;
                content: '';
                border-radius: 50%;
                background-color: gray;
              }
            }
          }
        }

        .more-info {
          color: #8a95b0;
          display: inline-flex;
          align-items: center;
          margin: 0 5px 0 10px;
          font-size: 11px;
          font-weight: normal;
          text-transform: uppercase;

          svg {
            margin: 0 5px;
          }
        }
      }
    }

    i {
      margin-right: 10px;
    }

    hr {
      margin: 15px 30px 15px 45px;
    }
  }

  .assignment-type {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 20px 50px;
    margin: 0;

    li {
      display: flex;
      align-items: center;
      position: relative;

      &::before {
        display: block;
        background-color: $lmgrey-background;
        content: '';
        border-radius: 50px;
        width: 16px;
        height: 16px;
      }

      i {
        display: none;
      }

      &.selected {
        label {
          color: $primary-active;
        }
        &::before {
          color: $primary-active;
          background-color: #d0f1f9;
        }
        i {
          display: block;
          position: absolute;
          left: 2px;
          bottom: 0;
          top: 0;
          margin: auto;
          height: 16px;
          font-size: 18px;
          color: $primary-active;
        }
      }
      &:first-child {
        margin-right: 30px;
      }
    }

    input {
      margin: 0;
      -webkit-appearance: none;
    }

    label {
      color: $lmode-body-text;
      text-transform: uppercase;
      font-size: 11px;
      letter-spacing: 1px;
      font-weight: bold;
      margin-left: 5px;
      margin: 0 0 0 5px;
    }
  }

  .assign-no-results {
    margin: auto;
    i {
      color: $dmode-body-text;
      font-size: 40px;
      margin-bottom: 30px;
    }
    h3 {
      color: $lmode-helper-text;
      font-size: 14px;
      font-weight: bold;
    }
    h6 {
      color: $lmode-helper-text;
      margin: 10px 10%;
    }
  }
}
