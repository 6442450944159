ssi-draft {
  font-size: 1rem;
}
ssi-draft .draft-container {
  margin: 0 0 1em;
}
ssi-draft .draft {
  background-color: #fff;
  border-radius: 1em;
  border: 0.1em solid #c3c9d8;
}
ssi-draft .draft.active-delete {
  border-color: #f88c68;
}
ssi-draft .draft-row {
  display: flex;
  padding: 0 2em 0 2.4em;
}
ssi-draft .draft-header {
  border-bottom: 0.1em solid;
  border-color: inherit;
  color: #43537f;
  min-height: 6.1em;
  flex-wrap: wrap;
}
ssi-draft .draft-title-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.6rem 0 -0.3em;
}
ssi-draft .draft-title-icon + .draft-title-icon {
  margin-left: -2rem;
}
ssi-draft .draft-title {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
}
ssi-draft .draft-title h2 {
  color: #101525;
  font-size: 1.6em;
  margin: 0 0.4em 0 0;
  font-weight: 900;
  text-rendering: geometricPrecision;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
ssi-draft .draft-title-pills {
  padding: 0;
  margin: 0;
}
ssi-draft .draft-title-pills li {
  display: inline-block;
  background-color: #f4f4fa;
  border-radius: 10em;
  padding: 0.4em 0.6em;
  margin: 0 0.6em 0 0;
}
ssi-draft .draft-title-pills li + li {
  margin-left: -0.6em;
}
ssi-draft .draft-title-pills li p {
  color: #43537f;
  line-height: 1;
  font-size: 1.2em;
  font-weight: 900;
  text-rendering: geometricPrecision;
  margin: 0;
}
ssi-draft .draft-meta {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  border-color: inherit;
}
ssi-draft .draft-meta-list {
  margin: 0;
  padding: 0;
}
ssi-draft .draft-meta-list li {
  display: inline-block;
  white-space: nowrap;
}
ssi-draft .draft-meta-list li:not(:last-child):after {
  content: "|";
  display: inline-block;
  margin: 0 0.6em;
  font-size: 1.2rem;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-draft .draft-meta-list p {
  display: inline-block;
  font-size: 1.2em;
  font-weight: 900;
  text-rendering: geometricPrecision;
  margin: 0;
}
ssi-draft .draft-meta-author {
  display: inline-flex;
  align-items: center;
  padding: 0.4em 0.6em;
  border: 1px solid;
  border-color: inherit;
  border-radius: 0.4em;
  margin: 0 0 0 1em;
}
ssi-draft .draft-meta-author i {
  color: #838eab;
  margin: 0 0.6rem 0 0;
  font-size: 1.1em;
}
ssi-draft .draft-meta-author p {
  color: #43537f;
  font-size: 1em;
  margin: 0;
  line-height: 1;
  font-weight: 900;
  text-rendering: geometricPrecision;
  text-transform: uppercase;
}
ssi-draft .draft-body {
  padding-top: 0.8em;
  padding-bottom: 0.8em;
  display: flex;
  align-items: start;
  justify-content: space-between;
}
ssi-draft .draft-body p {
  color: #101525;
  font-size: 1.4em;
  line-height: 2.4rem;
  margin: 0.5em 0 0.6em;
}
ssi-draft .draft-gallery {
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  margin: 1em 0 1em 5.7em;
}
ssi-draft .draft-gallery-multiple img:first-child {
  margin-top: -0.7em;
  margin-left: -0.3em;
}
ssi-draft .draft-gallery img,
ssi-draft .draft-gallery video {
  display: block;
  border-radius: 1em;
  overflow: hidden;
  background-color: #eee;
  height: 5.7em;
  object-fit: contain;
}
ssi-draft .draft-gallery img + img,
ssi-draft .draft-gallery video + img {
  position: absolute;
  top: 0.6em;
  left: 0.3em;
  box-shadow: 0 0.5em 1.5em rgba(116, 118, 132, 0.25);
}
ssi-draft .draft-gallery video {
  width: 17.3em;
  height: 10.7em;
}
ssi-draft .draft-gallery-badge {
  background-color: #fff;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -1.3em;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 2em;
  width: 2em;
  color: #14bae3;
  font-weight: 900;
  text-rendering: geometricPrecision;
  font-style: italic;
  border-radius: 50%;
  box-shadow: 0 0.5em 1.5em rgba(116, 118, 132, 0.25);
}
ssi-draft .draft-footer {
  border-top: 0.1em solid;
  border-color: inherit;
  color: #43537f;
  padding-top: 1em;
  padding-bottom: 1em;
  min-height: 6em;
}
ssi-draft .draft-actions {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
}
ssi-draft .draft-actions-left {
  flex: 1 1 auto;
  margin: 0 0 0 -1.3em;
  padding: 0;
}
ssi-draft .draft-actions-left .draft-actions-item {
  display: inline-flex;
  align-items: center;
  padding: 0.8em 0.3em;
  margin: 0 1em;
  cursor: pointer;
}
ssi-draft .draft-actions-left .draft-actions-item i {
  font-size: 1.4em;
  margin: 0 0.8rem 0 0;
}
ssi-draft .draft-actions-left .draft-actions-item p {
  font-size: 1.4em;
  font-weight: 900;
  text-rendering: geometricPrecision;
  margin: 0;
}
ssi-draft ssi-tooltip-window {
  border-radius: 15px;
  z-index: 2;
  background-color: transparent;
}
ssi-draft .draft-actions-right {
  margin-bottom: 0;
}
ssi-draft .draft-actions-right i {
  font-size: 14px;
  color: #e34916;
  display: flex;
  align-items: center;
  background-color: #fff1ed;
  border-radius: 50px;
  padding: 6px;
}
ssi-draft .delete-draft-tooltip {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  max-width: 310px;
  height: 100%;
  box-shadow: 0 1.8em 3.5em rgba(131, 142, 171, 0.35);
}
ssi-draft .delete-draft-tooltip .buttons-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
ssi-draft .delete-draft-tooltip .buttons-container button {
  text-transform: uppercase;
  padding: 0 5px;
  background-color: transparent;
}
ssi-draft .delete-draft-tooltip .buttons-container button span {
  font-weight: 900;
  letter-spacing: 1px;
}
ssi-draft .delete-draft-tooltip h5 {
  color: #838eab;
  white-space: normal;
  text-transform: none;
  letter-spacing: 0;
  line-height: 20px;
  font-weight: bold;
}