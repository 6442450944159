@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../angularjs/common/sass/include-media';

$unit: 2.5px;

$modal-height: 40vh;
$modal-width: 40vw;
$modal-offset-from-left: calc((100vw - $modal-width) / 2);
$modal-offset-from-top: calc((100vh - $modal-height) / 2);

ssi-live-chat-modal {
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-shrink: 0;
  height: 100vh;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: -68px;
  width: 100vw;
  z-index: 99999999;

  * {
    display: flex;
  }

  & > * {
    flex-shrink: 0;
  }

  .modal-container {
    align-items: center;
    background-color: #424348;
    border-radius: $unit * 10;
    color: #fff;
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    flex-shrink: 0;
    // height: $modal-height;
    justify-content: space-between;
    left: $modal-offset-from-left;
    overflow-x: hidden;
    overflow-y: auto;
    padding: calc($modal-height / 10) calc($modal-width / 10);
    position: fixed;
    top: $modal-offset-from-top;
    width: $modal-width;

    .modal-close-icon {
      float: right;
      margin-right: $unit * 8;
      margin-top: $unit * 6;
      // color: $publisher-social-network-preview-modal-color;
      position: relative;
      z-index: 1;
    }

    .modal-body {
      flex-flow: column nowrap;
      flex-grow: 1;
      flex-shrink: 0;
      font-weight: 600;
      padding: ($unit * 4) ($unit * 4);
      text-align: center;
    }

    .modal-footer {
      border-top: none;
      flex-flow: row nowrap;
      flex-shrink: 0;
      justify-content: space-evenly;
      margin: 0;
      width: 100%;

      & > li {
        flex-shrink: 0;
        width: 50%;

        &:nth-of-type(1) {
          margin-right: ($unit * 4);
        }

        &:nth-child(2) {
          margin-left: ($unit * 4);
        }

        button {
          align-items: center;
          border: none;
          border-radius: ($unit * 8);
          display: flex;
          font-weight: 600;
          justify-content: center;
          outline: none;
          padding: ($unit * 4) ($unit * 8);
          width: 100%;

          &:focus,
          &:hover {
            color: #fff;
          }

          &.btn-secondary {
            background-color: #53575d;
          }

          &.btn-primary {
            background-color: #57bdb7;
          }

          .modal-button-icon {
            font-size: 1.4em;

            & + .modal-button-text {
              margin-left: ($unit * 2);
            }
          }

          .modal-button-text {
            display: inline;
            font-size: 1.2em;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        @include media('<tablet') {
          width: 100%;
          margin: 0 !important;
        }
      }

      @include media('<tablet') {
        flex-wrap: wrap;
      }
    }

    .modal-header {
      align-items: center;
      border-bottom: none;
      flex-flow: column nowrap;
      flex-shrink: 0;
      font-weight: 400;

      &-icon {
        font-size: 3.2em;
      }

      &-text {
        font-size: 1.6em;
        text-align: center;
      }
    }

    @include media('<tablet') {
      left: 50%;
      transform: translate(-50%, 0);
      width: 80vw;
    }
  }
}
