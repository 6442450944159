ssi-analytics ssi-survey ssi-report ssi-filters .score-and-filters-section {
  padding: 30px 30px 60px 30px;
  background-color: #fff;
  border-radius: 10px;
}
ssi-analytics ssi-survey ssi-report ssi-filters .score-and-filters-section .score-and-filters-section-head {
  padding: 0 20px 0 0;
}
ssi-analytics ssi-survey ssi-report ssi-filters .score-and-filters-section .score-and-filters-section-head .title {
  display: flex;
  align-items: center;
}
ssi-analytics ssi-survey ssi-report ssi-filters .score-and-filters-section .score-and-filters-section-head .title i {
  font-size: 20px;
  color: #838eab;
}
ssi-analytics ssi-survey ssi-report ssi-filters .score-and-filters-section .score-and-filters-section-head .title h2 {
  color: #43537f;
  font-size: 20px;
  font-weight: 900;
  line-height: 40px;
  margin: 0;
  margin-left: 10px;
}
ssi-analytics ssi-survey ssi-report ssi-filters .score-and-filters-section .score-and-filters-section-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
ssi-analytics ssi-survey ssi-report ssi-filters .score-and-filters-section .score-and-filters-section-body .score-card {
  height: 185px;
  width: 285px;
  background-color: #fff;
  box-shadow: 0 5px 20px 0 rgba(131, 142, 171, 0.2);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
ssi-analytics ssi-survey ssi-report ssi-filters .score-and-filters-section .score-and-filters-section-body .score-card .score {
  text-align: center;
  font-weight: 900;
  font-size: 14px;
  color: #101525;
  position: relative;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
ssi-analytics ssi-survey ssi-report ssi-filters .score-and-filters-section .score-and-filters-section-body .score-card .score .rank {
  position: relative;
  margin: 0 0 2em 0;
}
ssi-analytics ssi-survey ssi-report ssi-filters .score-and-filters-section .score-and-filters-section-body .score-card .score .rank div {
  margin: 0 auto;
  width: 46px;
  height: 46px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  background-color: #fbf3e2;
}
ssi-analytics ssi-survey ssi-report ssi-filters .score-and-filters-section .score-and-filters-section-body .score-card .score .rank i {
  position: absolute;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 4em;
  color: #f0b427;
}
ssi-analytics ssi-survey ssi-report ssi-filters .score-and-filters-section .score-and-filters-section-body .score-card .score .value {
  margin-left: 30px;
}
ssi-analytics ssi-survey ssi-report ssi-filters .score-and-filters-section .score-and-filters-section-body .score-card .score .value .number {
  font-size: 20px;
  color: #101525;
  font-size: 28px;
  font-weight: 900;
  text-align: left;
}
ssi-analytics ssi-survey ssi-report ssi-filters .score-and-filters-section .score-and-filters-section-body .score-card .score .value .subtitle {
  color: #838eab;
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 1.2px;
}
ssi-analytics ssi-survey ssi-report ssi-filters .score-and-filters-section .score-and-filters-section-body .filters-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}
ssi-analytics ssi-survey ssi-report ssi-filters .score-and-filters-section .score-and-filters-section-foot {
  display: flex;
  align-items: center;
  padding-top: 60px;
}
ssi-analytics ssi-survey ssi-report ssi-filters .score-and-filters-section .score-and-filters-section-foot .buttons {
  margin-left: auto;
  margin-right: 60px;
  display: flex;
  align-items: center;
}
ssi-analytics ssi-survey ssi-report ssi-filters .score-and-filters-section .score-and-filters-section-foot .buttons .submit-button {
  margin-left: 10px;
}
ssi-analytics ssi-survey ssi-report ssi-filters .score-and-filters-section .score-and-filters-section-foot .buttons .clear-button i {
  font-size: 8px;
  color: #f88c68;
  margin-right: 8px;
}
ssi-analytics ssi-survey ssi-report ssi-filters .form-field-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 330px;
  margin-top: 40px;
  margin-left: 40px;
}
ssi-analytics ssi-survey ssi-report ssi-filters .form-field-group .form-field {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}
ssi-analytics ssi-survey ssi-report ssi-filters .form-field-group .form-field.half {
  flex-basis: 48%;
}
ssi-analytics ssi-survey ssi-report ssi-filters .form-field-group .form-field > label {
  color: #43537f;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-left: 20px;
  margin-bottom: 15px;
  min-height: 14px;
}
ssi-analytics ssi-survey ssi-report ssi-filters .form-field-group .form-field .input-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
ssi-analytics ssi-survey ssi-report ssi-filters .form-field-group .form-field .input-wrapper ssi-dropdown-select-2 {
  width: 100%;
}
ssi-analytics ssi-survey ssi-report ssi-filters .form-field-group .form-field .input-wrapper ssi-dropdown-select-2 button.dd-head {
  width: 100%;
}
ssi-analytics ssi-survey ssi-report ssi-filters .form-field-group .form-field .input-wrapper .select-placeholder {
  color: #838eab;
  font-size: 14px;
  font-weight: 700;
}
ssi-analytics ssi-survey ssi-report ssi-filters .form-field-group .form-field .input-wrapper .select-label {
  color: #101525;
  font-size: 14px;
  font-weight: 900;
}
ssi-analytics ssi-survey ssi-report ssi-filters .form-field-group .form-field .input-wrapper .text-input {
  width: 100%;
  height: 46px;
  border: none;
  outline: none;
  border-radius: 100px;
  display: flex;
  align-items: center;
  color: #101525;
  font-size: 14px;
  font-weight: 900;
  background-color: #f8f9fd;
  padding: 10px 30px 10px 20px;
}
ssi-analytics ssi-survey ssi-report ssi-filters .form-field-group .form-field .input-wrapper .text-input::placeholder {
  color: #838eab;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}
@media print {
  ssi-analytics ssi-survey ssi-report ssi-filters .submit-button {
    display: none !important;
  }
}