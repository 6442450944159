@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-fact-overview {
  .fact-overview {
    position: relative;
    display: flex;
    padding: 15px;
    background-color: #fff;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.35);

    &.extra-padding {
      padding: 30px 15px;
      padding-bottom: 50px;
    }

    @media print {
      border: 1px solid black;
      page-break-inside: avoid;
    }

    .divider {
      height: 4px;
      width: 40px;
      flex: none; // don't allow schrinking of this divider
      background-color: $lblue-background;
      margin-top: 7px;
      border-radius: 100px;
    }

    .stat-icon,
    .svg-icon-wrap {
      padding: 15px;
      border-radius: 50px;
      margin: 10px 0;
    }

    i {
      &.ssi-engagements-ads {
        font-weight: 700;
        // font-size: 28px;
      }
    }

    & > span {
      text-transform: uppercase;
      text-align: center;
      font-size: 10px;
      color: $lmode-helper-text;
      font-weight: 900;
      letter-spacing: 1px;
      white-space: nowrap;
    }

    h5 {
      display: flex;
      color: $lmode-title-text;
      font-weight: 900;
      font-size: 20px;
    }

    .stat-change-container {
      position: absolute;
      bottom: 0;

      ssi-stat-change {
        .stat-change {
          min-width: 120px;
          font-size: 16px;
        }
      }
    }
  }
}
