ssi-report-build-from form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 500px;
  padding: 80px 0;
}
ssi-report-build-from form .form-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
}
ssi-report-build-from form .form-row.extra-margin-top {
  margin-top: 50px;
}
ssi-report-build-from form .form-row .form-field {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}
ssi-report-build-from form .form-row .form-field.half {
  flex-basis: 48%;
}
ssi-report-build-from form .form-row .form-field > label {
  color: #43537f;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-left: 20px;
  margin-bottom: 15px;
}
ssi-report-build-from form .form-row .form-field .input-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
ssi-report-build-from form .form-row .form-field .input-wrapper.select-all-accounts {
  justify-content: flex-end;
}
ssi-report-build-from form .form-row .form-field .input-wrapper .inline-select-item {
  display: flex;
  align-items: center;
  color: #43537f;
  font-size: 12px;
  font-weight: 900;
  padding: 10px;
  border-radius: 10px;
  background-color: #f8f9fd;
  margin-right: 10px;
  cursor: pointer;
  user-select: none;
}
ssi-report-build-from form .form-row .form-field .input-wrapper .inline-select-item i {
  margin-right: 10px;
}
ssi-report-build-from form .form-row .form-field .input-wrapper .inline-select-item.selected {
  color: #14bae3;
  border: 1px solid #14bae3;
}
ssi-report-build-from form .form-row .form-field .input-wrapper .inline-select-item:hover {
  color: #fff;
  background-color: #14bae3;
  box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.35);
}
ssi-report-build-from form .form-row .form-field .input-wrapper ssi-social-network-icon i {
  font-size: 12px;
}
ssi-report-build-from form .form-row .form-field .input-wrapper .accounts-selector {
  width: 100%;
}
ssi-report-build-from form .form-row .form-field .input-wrapper .accounts-selector button.dd-head {
  width: 100%;
}
ssi-report-build-from form .form-row .form-field .input-wrapper .select-accounts-placeholder {
  color: #838eab;
  font-size: 14px;
  font-weight: 700;
}
ssi-report-build-from form .form-row .form-field .input-wrapper .select-accounts-label {
  color: #101525;
  font-size: 14px;
  font-weight: 900;
  display: flex;
  align-items: center;
}
ssi-report-build-from form .form-row .form-field .input-wrapper .select-accounts-label ssi-social-network-icon {
  margin-right: 8px;
}
ssi-report-build-from form .form-row .form-field .input-wrapper .date-picker-input {
  width: 100%;
  height: 46px;
  border: none;
  outline: none;
  border-radius: 100px;
  display: flex;
  align-items: center;
  color: #101525;
  font-size: 14px;
  font-weight: 900;
  background-color: #f8f9fd;
  padding: 10px 30px 10px 20px;
}
ssi-report-build-from form .form-row .form-field .input-wrapper .date-picker-input::placeholder {
  color: #838eab;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}
ssi-report-build-from form .form-row .form-field .input-wrapper .tag-selector {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
ssi-report-build-from form .form-row .form-field .input-wrapper .tag-selector .dd-container,
ssi-report-build-from form .form-row .form-field .input-wrapper .tag-selector button.dd-head {
  width: 100%;
  height: auto;
}
ssi-report-build-from form .form-row .form-field .input-wrapper .tag-selector button.dd-head {
  border-radius: 12px;
}
ssi-report-build-from form .form-row .form-field .input-wrapper .tag-selector .select-tags-placeholder {
  color: #838eab;
  font-size: 14px;
  font-weight: 700;
}
ssi-report-build-from form .form-row .form-field .input-wrapper .tag-selector .select-tag-labels {
  text-align: left;
  margin: 0 0 -10px -10px;
  color: #fff;
}
ssi-report-build-from form .form-row .form-field .input-wrapper .tag-selector .select-tag-labels span {
  padding: 6px 8px;
  font-size: 10px;
  margin: 0 4px 8px 0;
  background-color: #14bae3;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  text-rendering: geometricPrecision;
  word-break: break-all;
}
ssi-report-build-from form .form-row .form-field .input-wrapper .tag-selector .select-tag-labels span:hover {
  background-color: #f88c68;
}
ssi-report-build-from form .form-row .form-field .input-wrapper .tag-selector .select-tag-labels .ssi {
  margin: 0 0 0 7px;
  font-size: 8px;
}
ssi-report-build-from form .submit-button {
  margin-top: 30px;
  margin-bottom: 30px;
}