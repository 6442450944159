ssi-inbox-dashboard {
  display: flex;
  background-color: #f8f9fd;
  padding: 12px;
  height: calc(100vh - 68px);
}
ssi-inbox-dashboard .inbox-dashboard-inner {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 12px;
}
ssi-inbox-dashboard .inbox-dashboard-inner .dashboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
ssi-inbox-dashboard .inbox-loader {
  position: absolute;
  z-index: 4;
  margin-left: -47px;
  margin-top: 10px;
}
ssi-inbox-dashboard .inbox-clock {
  margin: 20px;
}
@media only screen and (max-height: 850px) {
  ssi-inbox-dashboard .inbox-clock {
    display: none;
  }
}
ssi-inbox-dashboard h2 {
  color: #101525;
  font-size: 20px;
  font-weight: 900;
}
ssi-inbox-dashboard .social-status {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
ssi-inbox-dashboard .social-status label {
  margin-right: 10px;
  margin-top: 4px;
  color: #101525;
  font-size: 14px;
  font-weight: 900;
}
ssi-inbox-dashboard .social-status .description {
  display: block;
  width: 100%;
  margin-top: 10px;
  color: #838eab;
  font-size: 12px;
  font-weight: 900;
  text-align: center;
}
ssi-inbox-dashboard .stats-and-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
ssi-inbox-dashboard .stats-and-avatar .stat {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16.5px;
  border-radius: 10px;
  background-color: #f2f4fc;
}
ssi-inbox-dashboard .stats-and-avatar .stat i {
  color: #838eab;
  margin-right: 5px;
}
ssi-inbox-dashboard .stats-and-avatar .stat span {
  color: #000000;
  font-size: 12px;
  font-weight: 900;
}
ssi-inbox-dashboard .stats-and-avatar .avatar {
  margin-left: 30px;
  margin-right: 30px;
  border-radius: 50%;
  width: 100px;
  height: 100px;
}
ssi-inbox-dashboard .social-push-mode-dashboard {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: auto;
}
ssi-inbox-dashboard .social-push-mode-dashboard .header-container {
  display: flex;
  width: 100%;
  text-align: center;
  flex-wrap: wrap;
}
ssi-inbox-dashboard .social-push-mode-dashboard .header-container h1,
ssi-inbox-dashboard .social-push-mode-dashboard .header-container h2 {
  width: 100%;
}
ssi-inbox-dashboard .social-push-mode-dashboard ul,
ssi-inbox-dashboard .social-push-mode-dashboard li {
  margin: 0;
  padding: 0;
  list-style: none;
}
ssi-inbox-dashboard .social-push-mode-dashboard .total-stats-list {
  display: flex;
}
ssi-inbox-dashboard .social-push-mode-dashboard .total-stats-list i {
  align-items: center;
  background-color: #f6f8f9;
  border-radius: 50%;
  font-size: 2.5em;
  justify-content: center;
  padding: 20px;
}
ssi-inbox-dashboard .social-push-mode-dashboard .single-stat {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 160px;
  width: 130px;
}
ssi-inbox-dashboard .social-push-mode-dashboard .single-stat span {
  text-align: center;
  font-size: 0.8em;
  font-weight: 600;
  margin: 0;
  padding: 0;
  text-transform: capitalize;
}
ssi-inbox-dashboard .social-push-mode-dashboard .single-stat span.value {
  font-size: 1.6em;
  font-style: italic;
  font-weight: 300;
}
ssi-inbox-dashboard .agents-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 130px);
  grid-gap: 20px;
  /* boring properties */
  list-style: none;
  padding: 20px;
  width: 100%;
  margin: 0 auto;
}
ssi-inbox-dashboard .agents-list > li {
  display: flex;
  justify-content: flex-start;
  padding: 0;
  flex-flow: column nowrap;
  margin: 20px 0;
  min-height: 160px;
  width: 130px;
}
ssi-inbox-dashboard .agent-container {
  display: flex;
  align-items: center;
}
ssi-inbox-dashboard .agent-container img {
  border-radius: 50%;
  border-style: solid;
  border-width: 4px;
  height: 60px;
  width: 60px;
}
ssi-inbox-dashboard .agent-container .agent-details {
  position: relative;
  display: flex;
  align-items: center;
  color: #187ab1;
  flex-flow: column nowrap;
}
ssi-inbox-dashboard .agent-container .agent-details .name {
  font-weight: 700;
  text-transform: capitalize;
  text-align: center;
}
ssi-inbox-dashboard .agent-container .agent-details .resolutions {
  display: flex;
  margin-bottom: 5px;
}
ssi-inbox-dashboard .agent-container .agent-details .resolutions dt {
  font-weight: 400;
  margin: 0;
  padding: 0;
}
ssi-inbox-dashboard .agent-container .agent-details .resolutions dd {
  padding-left: 5px;
}
ssi-inbox-dashboard .agent-container .status-button {
  border: none;
  outline: revert;
  padding: 0;
  white-space: nowrap;
  display: flex;
  align-items: center;
  border-radius: 20px;
  justify-content: space-around;
  font-size: 1.1em;
  font-weight: 500;
  margin: 5px 0;
  padding: 2px 20px;
  text-transform: capitalize;
  width: 90px;
}
ssi-inbox-dashboard .agent-container .status-button.disabled, ssi-inbox-dashboard .agent-container .status-button:disabled {
  background: transparent;
}
ssi-inbox-dashboard .agent-container .status-button:not(:disabled) {
  color: #fff;
}
ssi-inbox-dashboard .agent-container .status-icon {
  font-size: 0.85em;
  padding-left: 5px;
}
ssi-inbox-dashboard .agent-container .dropdown-menu {
  border: none;
  border-radius: 20px;
  margin: 0;
  padding: 0;
  width: 100%;
}
ssi-inbox-dashboard .agent-container .dropdown-menu button {
  background: transparent;
  border: none;
  outline: none;
  appearance: none;
}
ssi-inbox-dashboard .agent-container .dropdown-menu li {
  color: #43545d;
  padding: 10px;
}
ssi-inbox-dashboard .agent-container .dropdown-menu li:first-child {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
ssi-inbox-dashboard .agent-container .dropdown-menu li:last-child {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
ssi-inbox-dashboard .agent-container .dropdown-menu li:hover {
  background-color: #14bae3;
  color: #fff;
}
ssi-inbox-dashboard .agent-container.ONLINE .agent-details > button:not(:disabled) {
  background-color: #91cac7;
  color: #fff;
}
ssi-inbox-dashboard .agent-container.ONLINE img {
  border-color: #91cac7;
}
ssi-inbox-dashboard .agent-container.BUSY .agent-details > button:not(:disabled) {
  background-color: #607987;
}
ssi-inbox-dashboard .agent-container.BUSY img {
  border-color: #a6b7c0;
}
ssi-inbox-dashboard .agent-container.OFFLINE .agent-details > button:not(:disabled) {
  background-color: #f39780;
}
ssi-inbox-dashboard .agent-container.OFFLINE img {
  border-color: #f39780;
}