@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-datetime-modal {
  .header {
    width: 400px;
    text-align: center;
    margin: 0 auto 2em auto;
    img {
      width: 12%;
      height: 12%;
      margin: 1.6em 0;
    }
    button {
      margin-top: 1.6em;
    }
    h2 {
      color: #5471d7;
      font-size: 1.4em;
      font-weight: 600;
      margin: 0 0 1.2em 0;
    }
    p {
      margin: 1em 0;
      font-size: 12px;
      color: #a6b7c1;
      font-weight: 500;
    }
  }
  .exit {
    position: absolute;
    right: 15px;
    top: 15px;
    background-color: #eff2f8;
    border-radius: 100%;
    cursor: pointer;
    i {
      display: inline-block;
      padding: 10px;
      color: $lmode-helper-text;
    }
  }
  .datetime {
    position: relative;
    padding: 2em 0;
  }

  .flatpickr-months {
    padding-top: 0;
  }

  .flatpickr-next-month,
  .flatpickr-prev-month {
    margin-top: 0 !important;
  }

  .flatpickr-day {
    &.selected {
      background-color: $primary-active;
      border-color: $primary-active;
    }
  }

  .flatpickr-calendar {
    margin: 0 auto;
    box-shadow: none;
    margin-bottom: 2em;
    .flatpickr-time {
      border: none !important;
    }
  }
  .flatpickr-innerContainer {
    padding: 0;
  }
  input {
    display: none;
  }
  .footer {
    text-align: center;
    * {
      margin: 1em auto;
      display: block;
    }
  }
}
