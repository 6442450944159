@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-password-input {
  .password-input {
    position: relative;
  }

  .show-hide {
    position: absolute;
    right: 20px;
    top: 6px;
    font-size: 24px;
    line-height: 22px;
    vertical-align: middle;
    cursor: pointer;
  }

  .show-hide.ssi-eyeclosed {
    font-size: 12px;
    line-height: 30px;
  }
}
