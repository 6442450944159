@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-no-results {
  .no-results {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 140px;

    i {
      font-size: 50px;
      color: $dmode-body-text;
      margin-bottom: 30px;
    }

    ssi-spinner {
      margin-bottom: 30px;
    }

    .title {
      color: $lmode-body-text;
      font-size: 20px;
      font-weight: 900;
    }

    .desc {
      color: $lmode-helper-text;
      font-size: 14px;
      font-weight: 700;
      max-width: 280px;
    }

    &.size-sm {
      padding: 20px;

      i {
        font-size: 30px;
        margin-bottom: 15px;
      }

      ssi-spinner {
        margin-bottom: 15px;
      }

      .title {
        font-size: 14px;
      }

      .desc {
        font-size: 12px;
        max-width: 200px;
      }
    }
  }
}
