@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-ktd-grid-item {
  // This display none is used as a 'hack' to hide this element until 'someone' calls the function setStyles().
  display: none;
  position: absolute;
  z-index: 1;
  overflow: hidden;

  div {
    // // position: absolute;
    // // user-select: none;
    // // z-index: 10;

    &.grid-item-resize-icon-container {
      visibility: hidden;
      position: absolute;
      cursor: se-resize;
      // width: 20px;
      // height: 20px;
      bottom: 9px;
      right: 10px;
      color: #14bae3;

      // &::after {
      //   content: '';
      //   position: absolute;
      //   right: 3px;
      //   bottom: 3px;
      //   width: 5px;
      //   height: 5px;
      //   border-right: 2px solid;
      //   border-bottom: 2px solid;
      // }

      .grid-item-resize-icon {
        color: #14bae3;
        font-size: 14px;
        // font-weight: bold;
      }
    }
  }

  &:hover {
    .grid-item-resize-icon-container {
      visibility: visible;
    }
  }
}
