ssi-media-nav {
  display: flex;
  position: relative;
  height: 100%;
  overflow: hidden;
}
ssi-media-nav .media-nav-img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 180px;
}
ssi-media-nav img {
  object-fit: cover;
  width: 100%;
}
ssi-media-nav .media-nav-arrow {
  color: #14bae3;
  background-color: white;
  position: absolute;
  border-radius: 50%;
  font-size: 35px;
  border: 0;
  box-shadow: 0 4px 32px -5px rgba(119, 124, 128, 0.56);
  z-index: 1;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  top: calc(50% - (30px + 8px) / 2);
}
ssi-media-nav .media-nav-arrow.left {
  left: -8px;
}
ssi-media-nav .media-nav-arrow.right {
  right: -8px;
}
ssi-media-nav .media-nav-arrow:disabled {
  color: #555555;
  cursor: not-allowed;
}
ssi-media-nav .media-nav-arrow:not(:disabled):hover {
  color: shade(#14bae3, 15%);
  cursor: pointer;
}
ssi-media-nav video {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
ssi-media-nav .nav-btn-dark .media-nav-arrow {
  background-color: #2e3031;
}
ssi-media-nav.nav-btn-sm .media-nav-arrow {
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  top: calc(50% - (30px + 8px) / 2);
  font-size: 28px;
}
ssi-media-nav.nav-btn-sm .media-nav-arrow.left {
  left: -5px;
}
ssi-media-nav.nav-btn-sm .media-nav-arrow.right {
  right: -5px;
}
ssi-media-nav.redesign {
  height: auto;
}
ssi-media-nav.redesign .media-nav-arrow {
  border-radius: 5px;
  height: 26px;
  width: 26px;
  background-color: #fff;
}
ssi-media-nav.redesign .media-nav-arrow.left {
  top: calc(50% - 20px);
  left: 5px;
}
ssi-media-nav.redesign .media-nav-arrow.right {
  top: calc(50% - 20px);
  right: 5px;
}