ssi-live-chat-team-dashboard {
  height: calc(100vh - 68px);
  margin: 0 auto;
  color: #43537f;
  max-width: 960px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px 20px;
}
ssi-live-chat-team-dashboard > header {
  align-self: center;
  margin-bottom: 20px;
  text-align: center;
}
ssi-live-chat-team-dashboard > header h1 {
  font-weight: 300;
  justify-content: center;
  margin: 5px 0;
  text-transform: capitalize;
}
ssi-live-chat-team-dashboard > header h2 {
  font-size: 1em;
  font-weight: 600;
  justify-content: center;
  margin: 5px 0;
  text-transform: capitalize;
}