@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-ad-stats ssi-spend-conversion {
  font-size: 1rem;

  .spend-graphs-section {
    width: 100%;
  }

  .section-chart-wrap {
    position: relative;
    background-color: #fff;
    padding: 7em 4em 4em;
    margin: 0 0 2em;
    box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
    border-radius: 1.2em;
    width: 100%;

    .section-chart-context-menu-icon {
      display: inline-block;
      position: absolute;
      top: 2.6rem;
      right: 1.7rem;
      font-size: 2.6rem;
      color: $lmode-helper-text;
      pointer-events: none;

      @media print {
        display: none;
      }
    }

    highcharts-chart {
      padding: 0 2em 0 2em;
    }

    highcharts-chart,
    .highcharts-container,
    .highcharts-root {
      overflow: visible !important;
    }

    .highcharts-axis-title {
      font-weight: 900;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }

    .highcharts-axis-labels {
      text {
        font-weight: 900;
      }
    }

    .highcharts-xaxis-labels {
      transform: translate(0, 1em);
    }

    .highcharts-grid-line {
      transform: translate(0, -20px);

      &:nth-child(2n) {
        display: none;
      }
    }
  }

  .highcharts-legend-item {
    margin: 0 3em !important;
  }
}
