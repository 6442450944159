@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../../angularjs/common/sass/variables';
@import '../../styles/variables';
@import '~/styles/colors';

$border-radius: 12px;

ssi-drilldown-modal {
  font-size: 1rem;

  .drilldown-modal-content-container {
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: $border-radius;
    height: 100%;
  }

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    padding: 20px;
    border-bottom: 1px solid #c3c9d8;

    .title {
      font-size: 2em;
      font-weight: 900;
      color: $lmode-title-text;
      white-space: nowrap;
      flex-basis: 20%;
      margin-bottom: 0;
    }

    button {
      border: 1px solid $lmode-title-text;
      border-radius: 10px;
      background-color: #fff;
      padding: 0.5em 1.5em;
      appearance: none;
      font-size: 1.4em;
      font-weight: 900;
      color: $lmode-body-text;

      &.primary {
        color: #fff;
        background-color: $primary-active;
        border-color: $primary-active !important;
      }

      &:focus {
        outline: none;
        border-color: $lmode-helper-text;
      }

      & ~ button {
        margin-left: 5px;
      }
    }

    .buttons {
      display: flex;

      button {
        margin-left: 5px;
      }
    }
  }

  .tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $lgrey-background;

    .tab-name {
      position: relative;
      font-size: 1em;
      font-weight: 900;
      width: 100%;
      padding: 2em 0;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $lmode-title-text;
      cursor: pointer;
      border: 1px solid #c3c9d8;
      background-color: $lgrey-background;
      border-top: none;

      &:first-child {
        border-top-right-radius: 10px;
        border-right: 0;
      }

      &:last-child {
        border-top-left-radius: 10px;
      }

      &.active {
        color: $lmode-title-text;
        background-color: #fff;
        border-bottom: none;

        &:first-child {
          border-top-right-radius: 10px;
          border-right: 1px solid #c3c9d8;

          & ~ div {
            border-left: 0;
          }
        }

        &:last-child {
          border-top-left-radius: 10px;

          & + div {
            border-right: 0;
          }
        }
      }

      &:after {
        transform: scaleX(0);
        visibility: hidden;
        top: 45px;
        content: '';
        display: block;
        position: absolute;
        height: 3px;
        background-color: $primary-active;
        height: 2px;
        width: 50px;
        left: 0;
        right: 0;
        margin: auto;
        transition: all 0.3s ease-in-out 0s;
      }

      &:hover,
      &.active {
        &::after {
          transform: scaleX(1);
          visibility: visible;
        }
      }
    }
  }

  .drilldown-content {
    display: flex;
    height: 100%;
    background-color: #fff;
    overflow: hidden;

    .interaction-area,
    .posts-area,
    .annotation-area {
      background-color: #fff;
      width: 100%;
    }

    .interaction-area,
    .annotation-area {
      padding: 2em;
      overflow: auto;
    }

    .posts-area {
      overflow: auto;

      ssi-insights-posts {
        .posts-container {
          height: 100%;
          background-color: inherit;
        }
      }

      &.dotted {
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='#F8F9FD' rx='12' ry='12' stroke='#c3c9d8' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 12px;
        box-shadow: inset 0 18px 40px 0 rgba(131, 142, 171, 0.1);
      }
    }

    .datapoint-date {
      text-align: left;
      color: $lmode-body-text;
      font-size: 1.4em;
      font-weight: bold;
    }

    .paginator-wrapper {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      margin: 20px 0 60px 0;
    }
  }

  .sentiments-area {
    text-align: left;
    margin-top: 1em;

    .sentiment-block {
      display: inline-flex;
      align-items: center;
      margin-right: 30px;
    }

    .sentiment-color {
      display: inline-block;
      height: 4px;
      width: 20px;
      border-radius: 100px;
    }

    .sentiment-label {
      margin-left: 7px;
      color: $lmode-body-text;
      font-weight: 900;
      font-size: 10px;
    }
  }

  .themes-container {
    height: 100%;
  }

  .themes-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .message-info {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    text-align: center;

    i {
      color: #b4bbce;
      font-size: 4em;
    }

    h5 {
      font-size: 2em;
      font-weight: 900;
      color: $lmode-title-text;
    }

    p {
      font-size: 1.4em;
      max-width: 220px;
      font-weight: bold;
      color: $lmode-title-text;
    }
  }

  .themes-content {
    width: 100%;
    margin-top: 2em;

    ul,
    li {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li {
      position: relative;
      display: flex;
      align-items: baseline;
      justify-content: flex-start;
      padding: 37.5px;
      background-color: #f8f9fd;
      color: $lmode-title-text;
      border-radius: 10px;
      border: 1px solid #c3c9d8;
      margin-bottom: 7px;

      &:hover {
        cursor: pointer;
      }
    }

    .theme-index {
      position: absolute;
      top: 0;
      left: 15px;
      padding: 38px 20px 15px 20px;
      background-color: $lgrey-background;
      border: 1px solid #c3c9d8;
      border-top-color: transparent;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      color: $lmode-title-text;
      text-align: center;
      font-size: 20px;
      font-weight: 900;
    }

    .theme-name {
      color: $lmode-title-text;
      font-size: 14px;
      font-weight: 900;
      margin-left: 50px;
      text-align: left;
    }

    ssi-big-number {
      font-size: 20px;
      font-weight: 900;
      margin-left: auto;
    }

    .theme-results {
      color: $lmode-body-text;
      font-size: 10px;
      font-weight: 900;
      line-height: 8px;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-left: 5px;
    }
  }

  .report-action-bar-actions {
    width: 100%;
    margin-top: 10px;
    height: auto;
    min-height: 50px;
    border-top: 1px solid #c3c9d8;
    border-bottom: 1px solid #c3c9d8;
    padding: 0 2em;
  }

  .report-action-bar-actions-left {
    display: inline-flex;
    align-items: center;
    flex: 1 1 auto;
    margin: 1em 0;
  }

  .report-action-bar-actions-right {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1 1 auto;
    margin: 1em 0 1em 2em;
  }

  .report-actions-bar-bulk-actions {
    display: flex;
    border-radius: 1em;
    padding: 1em;
    height: 4rem;
    margin-top: 1em;
    flex: 1 1 auto;
  }

  .report-action-bar-selected-posts-container {
    display: flex;
    align-items: center;

    span {
      font-family: Lato, sans-serif;
      font-weight: 900;
      font-size: 14px;
    }
  }

  .report-bulk-actions-list {
    display: flex;
    flex: 0 1 auto;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100%;

    li {
      display: flex;
      flex-direction: row;
    }
  }

  .report-bulk-actions-list-item {
    padding: 0 1em 0 2rem;
    margin: 0.2rem 0;
    cursor: pointer;
    color: #43537f;

    .ssi {
      padding: 0 1em;
      font-size: 14px;
      align-self: center;
    }

    span {
      font-family: Lato, sans-serif;
      font-weight: 900;
      font-size: 14px;
    }

    .delete-icon {
      color: #cc3300;
    }
  }

  .report-action-bar-actions {
    width: 100%;
    height: auto;
    min-height: $view-report-action-bar-actions-height;
    border-bottom: 1px solid #c3c9d8;
    padding: 0 2em;
  }

  .report-action-bar-actions-left {
    display: inline-flex;
    align-items: center;
    flex: 1 1 auto;
    margin: 1em 0;
  }

  .report-action-bar-actions-right {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1 1 auto;
    margin: 1em 0 1em 2em;
  }

  .report-actions-bar-bulk-actions {
    display: flex;
    background-color: #dbe2f7;
    border-radius: 1em;
    padding: 1em;
    height: 4rem;
    margin-top: 1em;
    flex: 1 1 auto;
  }

  .report-action-bar-selected-posts-container {
    display: flex;
    align-items: center;
    color: #43537f;

    span {
      font-family: Lato, sans-serif;
      font-weight: 900;
      font-size: 14px;
    }
  }

  .report-bulk-actions-list {
    display: flex;
    flex: 0 1 auto;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100%;

    li {
      display: flex;
      flex-direction: row;
    }
  }

  .report-bulk-actions-list-item {
    padding: 0 1em 0 2rem;
    margin: 0.2rem 0;
    cursor: pointer;

    .ssi {
      padding: 0 1em;
      font-size: 14px;
      align-self: center;
    }

    span {
      font-family: Lato, sans-serif;
      font-weight: 900;
      font-size: 14px;
    }

    .delete-icon {
      color: #cc3300;
    }
  }

  .button-disabled {
    cursor: not-allowed;
  }

  .label-disabled {
    display: none;
  }

  .dwm-form {
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 1em 1em 1em;
    overflow: hidden;
    height: 0;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.26s ease;
    &.active {
      height: auto;
      transform: scaleY(1);
    }
  }

  .dwm-form-half {
    flex: 1 0 100%;

    @media (min-width: 768px) {
      flex: 1 0 calc(50% - 16px); // 16 is col gutter
      min-width: 30em;
      margin: 0 0.8em;
    }
  }

  .dwm-form-full {
    flex: 1 1 100%;
    margin: 1em 0.8em;
  }

  .dwm-label {
    padding: 0 0 0 3em;
    display: block;
    text-transform: uppercase;
    font-size: 1em;
    font-weight: 900;
    letter-spacing: 0.1em;
    margin: 0 0 1.8em;
    color: #6a759a;
    text-rendering: geometricPrecision;
    text-align: left;
  }

  .dwm-body-row-input {
    position: relative;
    margin: 0;
  }

  .dwm-input {
    font-size: 1.4em;
    width: 100%;
    border-width: 0;
    height: 4.6rem;
    border-radius: 1rem;
    color: #101525;
    font-weight: 700;
    text-rendering: geometricPrecision;
    text-indent: 3rem;
    padding: 0 3rem 0 0;
    background-color: #fff;
    border: 1px solid #c3c9d8;

    &:focus {
      outline: 1px solid $primary-active;
    }

    &:disabled {
      background-color: #f4f4fa;
      border: none;
    }

    &::placeholder {
      color: #6a759a;
      text-rendering: geometricPrecision;
    }
  }

  .dwm-textarea {
    padding: 1em 0 0 0;
    height: auto;
  }

  .dwm-body-row-input-meta {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    height: 1em;
    line-height: 1;
    right: 1.8em;
    color: $lmode-helper-text;
    font-size: 1em;
    font-weight: 900;
    text-rendering: geometricPrecision;

    &-active {
      color: $primary-active;
    }
  }

  .dwm-inline-submit {
    position: absolute;
    display: flex;
    line-height: 1.2em;
    color: #43537f;
    font-weight: 900;
    text-transform: uppercase;
    margin-left: 2em;
    letter-spacing: 0.1em;
    bottom: 15px;
    right: 20px;
    div {
      cursor: pointer;
      margin: 0 0 0 0.8em;
    }
  }

  .annotations-new {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .annotations-new-container {
      color: #43537f;
      max-width: 350px;
      i {
        color: #b5bbcf;
        font-size: 4em;
        margin-bottom: 0.2em;
      }
      h2 {
        font-size: 2em;
        margin: 0.6em 0;
        font-weight: 900;
        text-rendering: geometricPrecision;
      }
      p {
        line-height: 2rem;
        font-weight: 700;
        margin: 0 0 2em 0;
        text-rendering: geometricPrecision;
      }
      button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 1rem;
        height: 4rem;
        width: 4rem;
        padding: 0;
        color: #fff;
        border-radius: 50%;
        border-width: 0;
        background-color: #14bae3;
        i {
          font-size: 1.2rem;
          margin: 0;
          color: inherit;
        }
      }
    }
  }

  .annotations-header {
    display: flex;
    padding: 0 2em;
    margin: 0 2em;
    align-items: center;
    div {
      display: flex;
      text-transform: uppercase;
      font-size: 1em;
      font-weight: 900;
      letter-spacing: 0.1em;
      margin: 0 0 1em;
      color: #6a759a;
      text-rendering: geometricPrecision;
      text-align: left;
      &:nth-child(1) {
        flex-grow: 1;
        flex-basis: 15%;
        text-align: left;
      }
      &:nth-child(2) {
        flex-grow: 1;
        flex-basis: 15%;
      }
      &:nth-child(3) {
        flex-grow: 3;
        flex-basis: 50%;
      }
      &:nth-child(4) {
        flex-grow: 1;
        justify-content: flex-end;
        flex-basis: 20%;
      }
    }
  }

  .annotations-row {
    display: flex;
    padding: 1em 1.4em;
    margin: 0 1.4em;
    border-top: solid 1px #c3c9d8;
    font-size: 1.4em;
    align-items: center;
    font-weight: 700;
    color: #6a759a;
    &:nth-child(even) {
      background-color: #f4f4fa;
    }
    div {
      display: flex;
      &:nth-child(1) {
        flex-grow: 1;
        flex-basis: 15%;
        color: $primary-active;
        text-decoration: underline;
        text-align: left;
      }
      &:nth-child(2) {
        flex-grow: 1;
        flex-basis: 15%;
        color: $lmode-title-text;
      }
      &:nth-child(3) {
        flex-grow: 3;
        flex-basis: 50%;
      }
      &:nth-child(4) {
        flex-grow: 1;
        flex-basis: 20%;
        justify-content: flex-end;
      }
    }
  }

  .annotations-add-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary-active;
    color: white;
    border-width: 0;
    border-radius: 1em;
    height: 2.8em;
    line-height: 2.8em;
    white-space: nowrap;
    font-size: 1.4em;
    padding: 0 1.4em;
    font-weight: 700;
    & > *:first-child {
      display: inline-block;
      margin: 0 0.6rem 0 0;
    }

    // span {
    //   color: $lmode-body-text;
    //   font-size: 1.4rem;
    //   font-weight: 900;
    //   text-rendering: geometricPrecision;
    // }

    .ssi {
      font-size: 1.4rem;
    }

    ssi-icon path {
      fill: $lmode-body-text;
    }

    // &:hover,
    // &:focus {
    //   background-color: $ldgrey-background;
    // }
  }

  .annotations-action-button {
    display: inline-block;
    margin: 0 0 0 0.2em;

    border-radius: 0.8em;
    height: 2.6em;
    width: 2.6em;
    color: $lmode-body-text;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: solid 1px;
    &.edit {
      background-color: white;
      border-color: $lmode-helper-text;
    }

    &.delete {
      color: $secondary-red;
      background-color: $primary-red;
      border-color: $secondary-red;
    }

    &-active button {
      color: #fff;
      background-color: $primary-active;
    }

    i {
      font-size: 0.9em;
      color: inherit;
    }
  }
}
