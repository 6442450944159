ssi-analytics ssi-top-urls {
  font-size: 1rem;
}
ssi-analytics ssi-top-urls .section-tu-wrap {
  background: #fff;
  border-radius: 1.2em;
  box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
  padding: 5.8em 2.6em 2.8em;
}
ssi-analytics ssi-top-urls .tu-list {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
ssi-analytics ssi-top-urls .tu-list-head {
  flex-direction: row;
  justify-content: space-between;
}
ssi-analytics ssi-top-urls .tu-list-head .tu-list-item {
  display: flex;
  padding: 0 3.5em 1.6em;
}
ssi-analytics ssi-top-urls .tu-list-head p {
  flex: 1 0 auto;
  display: inline-block;
  color: #43537f;
  font-weight: 900;
  text-rendering: geometricPrecision;
  letter-spacing: 0.1em;
  font-size: 1em;
  text-transform: uppercase;
  margin: 0;
}
ssi-analytics ssi-top-urls .tu-list-head p:last-child {
  flex: 0 0 10em;
}
ssi-analytics ssi-top-urls .tu-list-body .tu-list-item {
  display: flex;
  align-items: center;
  padding: 0 1.9em;
}
ssi-analytics ssi-top-urls .tu-list-body p {
  flex: 1 1 auto;
  color: #101525;
  margin: 0;
  font-weight: 700;
  text-rendering: geometricPrecision;
  padding-right: 1em;
  overflow: hidden;
  display: inline-block;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}
ssi-analytics ssi-top-urls .tu-list-body p:first-child {
  flex: 0 0 3.2rem;
  font-weight: 900;
  padding-right: 0;
}
ssi-analytics ssi-top-urls .tu-list-body p:last-child {
  flex: 0 0 11.7rem;
  font-weight: 900;
  padding-right: 0;
}
ssi-analytics ssi-top-urls .tu-list-item {
  display: inline-block;
  width: calc(50% - 36px);
}
ssi-analytics ssi-top-urls .tu-list-body-item {
  height: 6.8em;
  border-top: 0.2rem solid #fff;
  border-bottom: 0.2rem solid #fff;
}
ssi-analytics ssi-top-urls .tu-list-body-item:nth-child(2n) {
  border-color: #e0e7f4;
  background-color: #f8f9fd;
}