ssi-engagement ssi-network-engagement {
  font-size: 1rem;
}
ssi-engagement ssi-network-engagement .section-card {
  padding: 3.7em 4.1em 2.5em;
}
@media print {
  ssi-engagement ssi-network-engagement .section-card {
    border: 1px solid black;
  }
}
ssi-engagement ssi-network-engagement .chart-bar {
  display: block;
  margin: 4em 1em 0 0;
  padding: 0 0 0;
}
ssi-engagement ssi-network-engagement .chart-bar-row {
  display: flex;
  height: 100%;
  align-items: center;
  margin: 2.2em 0;
}
ssi-engagement ssi-network-engagement .chart-bar-row-key {
  flex: 0 0 8.4em;
  margin: 0;
  color: #838eab;
  font-size: 1.4em;
  font-weight: 900;
  text-rendering: geometricPrecision;
  text-transform: capitalize;
}
ssi-engagement ssi-network-engagement .chart-bar-row-value {
  flex: 0 0 4.6em;
  margin: 0;
  color: #101525;
  font-size: 1.4em;
  font-weight: 900;
  text-rendering: geometricPrecision;
  padding: 0 1em;
}
ssi-engagement ssi-network-engagement .chart-bar-row-progress {
  flex: 1 1 auto;
  height: 0.4em;
}
ssi-engagement ssi-network-engagement .chart-bar-row-progress span {
  display: block;
  background: black;
  border-radius: 10em;
  height: 100%;
}
ssi-engagement ssi-network-engagement .chart-bar-row:nth-child(5n-4) .chart-bar-row-progress span {
  background-color: #425dec;
}
ssi-engagement ssi-network-engagement .chart-bar-row:nth-child(5n-3) .chart-bar-row-progress span {
  background-color: #14bae3;
}
ssi-engagement ssi-network-engagement .chart-bar-row:nth-child(5n-2) .chart-bar-row-progress span {
  background-color: #f40064;
}
ssi-engagement ssi-network-engagement .chart-bar-row:nth-child(5n-1) .chart-bar-row-progress span {
  background-color: #89a4ea;
}
ssi-engagement ssi-network-engagement .chart-bar-row:nth-child(5n) .chart-bar-row-progress span {
  background-color: #fb6340;
}
ssi-engagement ssi-network-engagement ssi-highchart {
  display: inline-block;
  margin: -0.5em 0 0 -2.5em;
}
ssi-engagement ssi-network-engagement .chart-wrap {
  display: flex;
}
ssi-engagement ssi-network-engagement .chart-legend {
  display: inline-flex;
  align-items: center;
  justify-content: stretch;
  flex-wrap: wrap;
  padding: 0;
  margin: 1em 0 2em 3.5em;
}
ssi-engagement ssi-network-engagement .chart-legend li {
  display: block;
  flex: 1 0 100%;
}
ssi-engagement ssi-network-engagement .stat-graph-chart-legend {
  display: inline-block;
  width: 1.2em;
  height: 1.2em;
  border-radius: 50%;
  margin: 0 1.2em 0 0;
}
ssi-engagement ssi-network-engagement .stat-graph-chart-name,
ssi-engagement ssi-network-engagement .stat-graph-chart-amount {
  display: inline-block;
  font-size: 1.4em;
  color: #101525;
  font-weight: 900;
  text-rendering: geometricPrecision;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: text-bottom;
}
ssi-engagement ssi-network-engagement .stat-graph-chart-amount {
  position: relative;
  display: inline-block;
  width: 3.8rem;
  margin: 0 0.8em 0 0;
  padding: 0 0.2em 0 0;
  min-height: 1.4rem;
}
ssi-engagement ssi-network-engagement .stat-graph-chart-amount:after {
  content: "|";
  position: absolute;
  right: 0;
  top: 0;
  margin: 0 auto 0 0;
}