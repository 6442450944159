@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-cl-dropdown-select-e {
  .cl-dropdown-select-e-container {
    .campaign-dd-and-button-container {
      display: flex;
      align-items: center;
      width: 100%;

      ssi-dropdown-select-2 {
        width: 100%;

        .dd-head {
          width: 100%;
          padding: 0 35px 0 5px;

          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        .dd-body {
          width: 600px;
          > ul {
            max-height: 465px !important;
          }
        }
      }

      &.no-selected-options {
        ssi-dropdown-select-2 {
          .dd-head {
            padding: 0 30px 0 20px;
          }
        }
      }

      .create-campaign-button {
        height: 46px;
        border: 1px solid #c3c9d8;
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      .select-placeholder {
        font-size: 14px;
        font-weight: 700;
        color: #6a759a;
        text-wrap: nowrap;
      }

      .selected-options-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .chips-container {
          display: flex;
          align-items: center;
          overflow: hidden;

          .chip {
            height: 36px;
            background-color: #f4f4fa;
            border-radius: 8px;
            padding: 0 10px;
            white-space: nowrap;
            margin-right: 5px;

            display: flex;
            align-items: center;

            span {
              font-size: 14px;
              color: #101525;
              font-weight: 900;
              margin: 0;
              margin-left: 10px;
            }

            i {
              font-size: 8px;
              color: #43537f;
              font-weight: 500;
              margin-left: 10px;
              cursor: pointer;
            }
          }
        }
      }

      .chevron.expand-campaign-icon {
        font-size: 12px;
        font-weight: 900;
        color: $lmode-title-text;

        transform: rotate(-90deg);

        &.down {
          transform: rotate(0deg);
        }
      }

      ssi-list-select2 {
        .filter-container {
          padding-left: 32px;
        }
      }
    }
  }
}
