@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-dropdown-select-2 {
  .dd-container {
    position: relative;

    .dd-head {
      display: flex;
      // justify-content: center;
      align-items: center;
      position: relative;
      outline: none;

      height: 46px; // TODO: lg, md, sm?
      border: 1px solid #c3c9d8;
      border-radius: 10px;
      background-color: #fff;
      color: #101525;
      font-size: 14px;
      font-weight: 700;
      padding: 0 30px 0 20px;

      span {
        margin-right: 20px;
        &.placeholder {
          color: #6a759a;
        }
      }

      .button-icon {
        position: absolute;
        color: $primary-active;
        font-weight: 900;

        font-size: 12px;
        right: 20px;
      }

      // used in 3 places in total - should probably be removed and replaced with headButton tempplate
      &.variant-inbox {
        height: auto;
        border: none;
        border-radius: 20px;
        background-color: $grey-background;
        color: $primary-active;
        font-size: 10px;
        font-weight: 900;
        padding: 6px 8px; // 26px height

        span {
          &.placeholder {
            color: $primary-active;
          }
        }

        .button-icon {
          font-size: 8px;
          right: 10px;
        }
      }

      &.variant-analytics {
        height: auto;
        border: none;
        border-radius: 20px;
        background-color: $grey-background;
        color: #6a759a;
        font-size: 14px;
        font-weight: 900;
        padding: 10px 30px 10px 20px; // 40px height

        span {
          &.placeholder {
            color: #6a759a;
          }
        }

        .button-icon {
          font-size: 12px;
          right: 20px;
        }
      }

      &:disabled {
        color: $lmode-helper-text;
        cursor: not-allowed;
      }
    }

    .dd-body {
      position: absolute;
      // top: 0;
      // left: 0;
      // bottom: 0;
      // right: 0;
      z-index: 1000;
      min-width: 260px;
      padding: 20px 10px 20px 20px;
      border-radius: 10px;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      background-color: #fff;
      overflow: hidden;

      &.no-padding-top {
        padding-top: 0;
      }

      .body-header-container {
        width: 100%;
        padding: 0 20px 0 5px;
        position: relative;

        hr {
          margin: 0 -50px;
          background-color: #c3c9d8;
          height: 1px;
        }
      }

      .filter-container {
        position: relative;
        width: 100%;
        padding: 0 20px 0 5px;

        input {
          // border-bottom: 2px solid $lmode-border-color;
          width: 100%;
          height: 40px;
          border: 0;
          outline: 0;
          padding-right: 20px;
          color: $lmode-title-text;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          font-family: 'Lato', 'Arial', sans-serif;

          // &:focus {
          //   border-bottom: 2px solid $primary-active;
          // }

          &::placeholder {
            font-size: 15px;
            font-weight: 700;
            font-style: normal;
            color: #6a759a;
          }
        }

        i {
          position: absolute;
          color: #6a759a;
          top: 16px;
          right: 28px;
          font-size: 12px;
          font-weight: 700;

          &.active {
            color: $primary-active;
          }
        }

        hr {
          margin: 0 -50px;
          background-color: #c3c9d8;
          height: 1px;
        }
      }

      .toggle-all-container {
        width: 100%;
        padding: 20px 26px 0 10px;
        &.filter-visible {
          display: flex;
          justify-content: flex-end;
        }
      }

      > ul {
        overflow: auto;
        list-style: none;
        max-height: 200px;
        margin: 0;
        padding: 0;
        margin-top: 10px;
        scrollbar-gutter: stable;

        &.overflow-scroll {
          overflow-y: scroll;
        }

        &.padding-right {
          padding-right: 17px;
        }

        > li {
          margin: 5px 0;
          min-height: 36px;

          &.selected {
            // background-color: $grey-background;
            // span {
            //   color: $primary-active;
            // }

            .icon-container {
              i {
                color: $primary-active;
                font-weight: 700;
              }
            }
            > .main-content {
              span {
                color: $primary-active;
              }
            }
          }

          &.group-separator {
            &:not(:first-child) {
              margin-top: 20px;
            }
            .main-content {
              > span {
                font-size: 10px;
                letter-spacing: 0.5px;
                color: #43537f;
                text-transform: uppercase;
              }
              ssi-checkbox-2 {
                margin-left: auto;
                margin-right: 10px;
              }
            }
          }

          .main-content {
            max-width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            cursor: pointer;
            color: $lmode-body-text;
            font-size: 14px;
            font-weight: 900;
            border-radius: 10px;
            min-height: 30px;
            border: none;

            &:hover {
              background-color: $grey-background;
            }

            .icon-container {
              margin-left: 2px;
              width: 24px;
              height: 24px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
            }

            span {
              display: block;
              padding: 0 10px;
              border-radius: 15px;
              max-width: 100%;
              word-break: break-all;

              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }

            .tick-container {
              position: relative;
              margin-right: 10px;
              margin-left: auto;
              height: 16px;
              width: 16px;
              border-radius: 2px;
              border: 1px solid #838eab;
              background-color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;

              i {
                font-size: 10px;
                font-weight: 500;
                color: #fff;
              }

              &.checked {
                border: 1px solid $primary-active;
                background-color: $primary-active;
              }

              &.radio-btn {
                height: 18px;
                width: 18px;
                border-radius: 50%;

                i {
                  display: none;
                }

                &.checked {
                  background-color: #fff;

                  i {
                    // just make a circle of it xD
                    padding: 1px;
                    display: block;
                    color: $primary-active;
                    border-radius: 50%;
                    background-color: $primary-active;
                  }
                }
              }
            }
          }

          .expanded-content-container {
            padding-left: 30px;

            .filter-container {
              margin-top: 10px;
            }

            > ul {
              // margin: 0;
              padding-bottom: 20px;
            }
          }
        }
      }
    }

    .body-footer-container {
      margin-top: 10px;
    }

    &.size-sm {
      .dd-body {
        min-width: 160px;
        padding: 5px 5px 20px 10px;

        &.no-padding-top {
          padding-top: 0;
        }

        .filter-container {
          input {
            height: 36px;
            &::placeholder {
              font-size: 12px;
            }
          }
          i {
            top: 14px;
          }
        }

        > ul {
          li {
            // font-size: 12px;
            min-height: 26px;
            span {
              font-weight: 700;
            }
          }
        }
      }
    }

    &.size-lg {
      .dd-head {
        min-height: 46px;
      }

      .dd-body {
        min-width: 420px;
        padding: 20px 20px 30px 30px;

        &.no-padding-top {
          padding-top: 0;
        }

        .filter-container {
          input {
            height: 46px;
          }
          i {
            top: 18px;
          }
        }

        > ul {
          max-height: 200px;
          li {
            min-height: 36px;
            font-size: 14px;
          }
        }
      }
    }

    &.size-fit {
      .dd-body {
        min-width: auto;
      }
    }
  }

  @media print {
    .button-icon {
      display: none !important;
    }
  }
}
