@import "./src/apps/angularjs/common/sass/global-variables.scss";
$unit: 2.5px;

ssi-live-chat {
  display: flex;
  flex-flow: column nowrap;
  flex-shrink: 0;
  flex-grow: 0;

  * {
    display: flex;
  }

  &.expanded {
    flex-grow: 1;
  }
}
