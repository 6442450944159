@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../angularjs/common/sass/include-media';
@import '../../../../../common/styles/colors.scss';
@import '../../../../../../angularjs/common/sass/mixins';

$margin-between-boxes: 20px;
$border-radius: 12px;

ssi-add-widget {
  .add-insights-widget-content-container {
    font-size: 1rem;

    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: $border-radius;
    height: 100%;

    .heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: left;
      padding: 20px 20px 50px 50px;

      .title {
        font-size: 2em;
        font-weight: 900;
        color: $lmode-body-text;
        white-space: nowrap;
        flex-basis: 20%;
        padding-top: 35px;
        margin-bottom: 0;
      }

      i.close-icon {
        background-color: #f2f4fc;
        color: #838eab;
        border-radius: 50px;
        padding: 13px;
        margin-bottom: auto;
        &:hover {
          cursor: pointer;
        }
      }

      .heading-right {
        flex-basis: 80%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .search-field {
      display: flex;
      align-items: center;
      text-align: left;
      margin-right: auto;
      padding-top: 40px;

      i {
        color: $lmode-body-text;
        font-size: 1.2em;
        margin: 0 0.6rem 0 0;
      }
      input {
        color: $lmode-body-text;
        font-size: 1.4em;
        font-weight: 700;
        text-rendering: geometricPrecision;
        border-width: 0;
        outline: 0;
        padding: 0;
        &::placeholder {
          color: #838eab;
        }
      }
    }

    .content {
      display: flex;
      height: 100%;
      justify-content: space-between;
      flex: 1 1 100%;
      overflow: auto;
    }

    .filters-pane {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-basis: 20%;
      min-width: 200px;
      border-right: 1px solid #d1dbf4;
      border-top: 1px solid #d1dbf4;
      border-top-right-radius: 12px;
      ul {
        list-style: none;
        margin: 0;
        padding: 10px 40px 10px 50px;
        margin-top: 30px;
        overflow: auto;

        li {
          position: relative;
          display: flex;
          justify-content: space-between;
          font-size: 1.4em;
          color: $lmode-body-text;
          font-weight: 900;
          text-align: left;
          margin-bottom: $margin-between-boxes;
          white-space: nowrap;
          flex-wrap: wrap;
          &:hover {
            cursor: pointer;
          }
          &.active {
            color: $primary-active;
            &::before {
              position: absolute;
              left: -13px;
              content: '';
              display: block;
              height: 100%;
              width: 4px;
              border-radius: 100px;
              background-color: $primary-active;
            }
          }
        }
      }

      button {
        white-space: nowrap;
        i {
          margin-right: 5px;
        }
      }

      .action-button {
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid #d1dbf4;
        padding: 40px;

        orlo-button {
          button {
            border-radius: 10px;
          }
        }
      }
    }

    .filters-pane,
    .items-list-container {
      overflow: hidden;
    }

    .items-list-container {
      flex-basis: 80%;
      padding: 0 30px;
    }

    .items-list {
      display: flex;
      flex-wrap: wrap;
      overflow: auto;
      height: 100%;
      align-content: flex-start;
    }

    .item-box {
      position: relative;
      width: 100%;
      margin: 0 20px 20px 0;
      border-radius: $border-radius;
      flex: 1 1 240px;

      .thumbnail-action {
        border-radius: $border-radius;
      }

      i {
        font-size: 1.2em;
      }

      .item-thumb {
        border: 1px solid #d1dbf4;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        width: 100%;
        height: 16em;
        background-color: $grey-background;
        border-radius: $border-radius;
        margin-bottom: 20px;

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 130px;
          color: #a3b8ee;
          opacity: 0.2;
        }

        &:hover {
          border-style: dashed;
          border-color: $primary-active;
        }
      }
      button {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 60px;
        width: 50px;
        height: 50px;
        background-color: $primary-active;
        padding: 16px;
        border: 0;
        border-radius: 50%;
        color: #fff;
      }

      .title,
      .description {
        text-align: left;
        width: 100%;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: middle;
        text-align: left;
        margin-bottom: 0.5em;
        padding-left: 10px;
      }

      .title {
        color: $lmode-title-text;
        font-weight: 900;
        font-size: 1.6em;
      }

      .description {
        color: $lmode-body-text;
        font-weight: bold;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;
      }
    }

    @supports (display: grid) {
      .insights-reports-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(25em, 1fr));
        grid-gap: 2.1em;
        margin: 0;

        .insights-reports-grid-item {
          margin: 0;
        }

        @include media('>1800px') {
          grid-template-columns: repeat(auto-fill, minmax(30em, 1fr));
        }
      }
    }

    @include ie-only {
      .insights-reports-grid {
        display: flex;
        margin: 0 -1em 2.4em;

        .insights-reports-grid-item {
          margin: 0 1em;
        }
      }
    }
  }
}
