@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import "../../../../../../library/sass/live-chat/colours.scss";

$unit: 2.5px;

$list-item-margin: $unit * 8;

ssi-live-chat-activity-tree {
  display: flex;
  flex-flow: column nowrap;
  flex-shrink: 0;

  * {
    display: flex;
  }

  & > ul,
  & > ul > li > ul {
    flex-flow: column nowrap;
    list-style: none;
    margin: 0;
    padding: 0;

    & > li {
      flex-flow: column nowrap;
      flex-shrink: 0;
      margin: 0;
      position: relative;
      
      &.message {
        width: 70%;

        &.agent,
        &.robot {
          align-self: flex-end;
          margin-right: 10%;
        }

        &.visitor {
          align-self: flex-start;
          margin-left: 10%;
        }

      }
    }
  }
}
