@import "./src/apps/angularjs/common/sass/global-variables.scss";
.modal-chat-bot {
  .panel-heading {
    p {
      margin: 0;
    }
  }

  .list-group-item {
    li {
      list-style-type: square;
    }
  }

  .list-group-item-subheading {
    margin: -10px -15px 15px -15px;
    height: 38px;
    padding: 10px 15px 10px 20px;
    border-bottom: 1px solid #ddd;
    background-color: #f9f9f9;

    &:before {
      content: '•';
      display: inline-block;
      margin: 0 10px 0 0;
    }
  }

  .form-field {
    margin: 10px 0;

    select {
      width: 100%;
      padding: 6px 12px;
      height: 34px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  }
}
