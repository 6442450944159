@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

$action-pad-responsive-width: 480px;
$silenced-button-color: #494d5e;

// Hide when inside a modal
.modal-body {
  .back-button {
    display: none !important;
  }
  ssi-inbox-activity {
    @media (max-width: $action-pad-responsive-width) {
      height: calc(85vh - 68px);
    }
  }
}

ssi-action-pad {
  background-color: $grey-background;

  .action-pad-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 17px;
    margin: 10px 10px 3px;
    border-radius: 12px;
    background-color: #e7edfd;
    height: 70px;

    &.action-pad-container-hidden > * {
      display: none;
    }

    .action-trigger-view-profile {
      display: none;

      &.unavailable {
        cursor: not-allowed;
      }
    }

    .action-trigger-toggle-profile {
      display: block;
    }

    @media (max-width: 1350px) {
      .action-trigger-view-profile {
        display: block;
      }

      .action-trigger-toggle-profile {
        display: none;
      }
    }

    &.silenced {
      background-color: $dmode-background;

      .assignment,
      .action-pad-actions-assign {
        & > button {
          background-color: $silenced-button-color;

          & > span,
          & > i.ssi {
            color: #fff;
          }
        }
      }

      .action-pad-button,
      .inbox-priority-select-container {
        background-color: $silenced-button-color;

        i {
          color: #fff;
        }
      }

      .action-triggers {
        background-color: $silenced-button-color;

        i {
          color: #fff;
        }
      }

      orlo-button {
        button {
          background-color: $silenced-button-color !important;
        }

        &.unsilence {
          margin-left: 5px;
          i {
            color: $secondary-red;
          }
        }
      }

      .assignment-action {
        background-color: $silenced-button-color;
      }
    }
  }

  .unsilence {
    margin-left: 6px;

    button {
      border-radius: 10px;
      white-space: nowrap;
    }
  }

  .action-pad-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-width: 0;
    border-radius: 10px;
    height: 36px;
    min-width: 36px;

    i {
      font-size: 18px;
      color: #838eab;

      &.ssi-more-actions {
        font-size: 14px;
      }
    }
  }

  .back-button {
    font-size: 14px;
    padding: 11px;
    color: #43537f;
    border: 1px solid #668aea;
    border-radius: 10px;
    background-color: #fff;
    font-weight: 900;
    text-rendering: geometricPrecision;

    &.action-pad-button {
      i {
        color: #838eab;
        font-size: 13px;
      }

      span {
        margin-left: 8px;
      }
    }

    .ssi-line-arrow-small-down {
      transform: rotate(90deg);
    }
  }

  .ap-menu-button {
    margin: 0 0 0 6px;
  }

  .action-pad-container-left {
    display: flex;
    height: 36px;

    & > button:not(:last-child),
    & > orlo-button:not(:last-child) {
      margin-right: 6px;
    }
  }

  .assignment-action {
    background-color: #668aea;
    color: #fff;
    display: flex;
    padding: 11px;
    border: none;
    border-radius: 10px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 900;
    outline: 0;
    text-rendering: geometricPrecision;
    cursor: pointer;

    .ssi {
      font-size: 14px;
      margin: 0;
    }

    & > span {
      margin: 0 0 0 9px;
    }

    .assignment-action-meta-wrap {
      height: 36px;
      white-space: nowrap;
      margin: -13px -18px;
      padding: 13px 18px;
    }

    .assignment-action-meta {
      display: inline-block;
      max-width: 65px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: middle;

      & + .assignment-action-meta:before {
        content: '|';
        margin: 0 2px 0 0;
      }

      &-timestamp {
        max-width: none;
      }
    }
  }

  .action-pad-container-right {
    display: flex;
    align-items: center;
    position: relative;

    &:last-child {
      margin-right: 0;
    }
  }

  .action-pad-actions-assign {
    button {
      padding: 11px;
      font-size: 12px;
      min-width: 90px;
      line-height: 13px;
      border-radius: 10px;
      white-space: nowrap;
      margin-left: 6px;

      .ssi {
        color: #838eab;
        font-size: 14px;
        color: $lmode-helper-text;

        & + span {
          display: inline-block;
          line-height: 14px;
          vertical-align: text-top;
          font-weight: 900;
          text-rendering: geometricPrecision;
          max-width: 100px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #43537f;
        }
      }
    }
  }

  .ap-menu-dropdown {
    background-color: #fff;
    min-width: 231px;
    border-radius: 15px;
    box-shadow: 0px 5px 10px rgba(131, 142, 171, 0.35);
    margin: -36px 0 0;
    border-width: 0;
    padding: 28px 10px 28px 36px;

    & > li {
      display: block;
      padding: 10px 0;
      font-size: 14px;
      line-height: 15px;
      margin: 0 0 4px 0;
      cursor: pointer;

      &:hover {
        .ap-menu-label,
        .on-hold-label,
        .resolve-label {
          text-decoration: underline;
        }
      }

      .ap-menu-label,
      .on-hold-label,
      .resolve-label {
        margin-left: 10px;
        color: #43537f;
        font-weight: 900;
        text-rendering: geometricPrecision;
        font-size: inherit;
      }

      & > i {
        color: #838eab;
      }
    }
  }

  .action-triggers {
    position: relative;
    padding: 8px 2px 8px 20px;
    border-radius: 10px;
    background-color: #fff;
    margin: 0 0 0 6px;
    display: flex;
    align-items: center;

    .action-trigger {
      color: $lmode-helper-text;
      margin-right: 18px;
      cursor: pointer;

      &-active {
        color: $primary-active;
      }

      &:hover {
        color: $primary-active;
      }

      &.ssi-resolve-convo {
        font-size: 16px;
        top: 2px;
        position: relative;
      }
      &.no-pointer {
        cursor: default;
      }
    }

    ssi-conversation-hold {
      display: flex;
      align-items: center;

      .dd-container {
        display: flex;
        align-items: center;
      }
    }
  }

  .action-triggers-dropdown {
    top: 45px;
    left: 50%;
    transform: translateX(-50%);
  }

  .action-trigger-view-profile,
  .action-trigger-toggle-profile {
    font-size: 20px;
    color: $lmode-helper-text;
    margin-left: 6px;
    cursor: pointer;
  }

  .tags-select-container {
    position: relative;

    .selected-tags-count {
      position: absolute;
      top: -10px;
      left: 10px;

      span {
        font-size: 10px;
        color: #43537f;
        font-weight: 900;
      }
    }

    .no-tags-state {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      padding: 0 10px;
      // height: 160px;

      i {
        margin-top: 20px;
        color: #b4bbce;
        font-size: 30px;
      }

      .title {
        margin-top: 15px;
        color: $lmode-body-text;
        font-size: 16px;
        font-weight: 900;
      }

      .desc {
        margin-bottom: 20px;
        color: $lmode-body-text;
        font-size: 14px;
        font-weight: 700;
        padding: 0 24px;
        text-align: center;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        width: 100%;
        i {
          margin: 0;
          margin-left: 10px;
          font-size: 12px;
          color: #fff;
        }
        span {
          white-space: nowrap;
        }
      }
    }

    .dd-body {
      max-width: 200px;
    }
  }

  .ssi-socialprofile {
    // fix misaligned font icon
    text-indent: 12%;
  }
}
