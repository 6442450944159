.manage-ifttt-applet {
  height: calc(100vh - 68px);
  background: url("./img/ifttt-bg-left.png") no-repeat fixed left, url("./img/ifttt-bg-right.png") no-repeat fixed right;
  padding: 0 15px;
}
.manage-ifttt-applet .manage-ifttt-applet-header-left {
  position: absolute;
  padding: 30px 20px;
}
.manage-ifttt-applet .manage-ifttt-applet-stage {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.manage-ifttt-applet .manage-ifttt-applet-stage .manage-ifttt-applet-stage-header-img {
  width: 100px;
  height: 100px;
}
.manage-ifttt-applet .manage-ifttt-applet-stage .manage-ifttt-applet-stage-header-title {
  font-size: 32px;
  margin: 0 0 15px;
  font-weight: 500;
}
.manage-ifttt-applet .manage-ifttt-applet-stage .manage-ifttt-applet-stage-header-sub-title {
  font-size: 16px;
  font-weight: 500;
}
.manage-ifttt-applet .manage-ifttt-applet-stage .manage-ifttt-applet-stage-header-sub-title em {
  font-weight: 700;
  font-style: italic;
}
.manage-ifttt-applet .manage-ifttt-applet-stage .manage-ifttt-applet-stage-body {
  display: flex;
  justify-content: center;
  margin-top: 65px;
}
.manage-ifttt-applet .manage-ifttt-applet-stage .manage-ifttt-applet-stage-body-inner {
  width: 300px;
}
.manage-ifttt-applet .btn-stage-next {
  width: 200px;
}
.manage-ifttt-applet input.form-control-minimal {
  font-style: italic;
  border-bottom: solid 3px #eef0f2;
  color: #393c3f;
}
.manage-ifttt-applet input.form-control-minimal::placeholder {
  color: #c9d8e0;
}
.manage-ifttt-applet .btn-trigger {
  background-color: #40434a;
  color: #fff;
  min-width: 125px;
  border-radius: 20px;
  border-width: 0;
  padding: 8px;
  font-size: 15px;
}

.manage-ifttt-applet.triggers-and-actions {
  height: auto;
  background: white;
}