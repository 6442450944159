ssi-publisher-secondary-view-nav {
  padding: 10px;
}
ssi-publisher-secondary-view-nav .publisher-secondary-nav-web {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 20px;
  padding: 13px 25px;
}
@media (max-width: 885px) {
  ssi-publisher-secondary-view-nav .publisher-secondary-nav-web {
    display: none;
  }
}
ssi-publisher-secondary-view-nav .publisher-secondary-nav-web .cancel-btn {
  background-color: #f88c68;
  color: #fff;
  padding: 0 30px;
  font-weight: 900;
}
@media (min-width: 885px) {
  ssi-publisher-secondary-view-nav .publisher-secondary-nav-mobile {
    display: none;
  }
}
ssi-publisher-secondary-view-nav .publisher-secondary-nav-mobile .cancel-btn {
  background-color: #f88c68;
  color: #fff;
  width: 100%;
}
ssi-publisher-secondary-view-nav .publisher-secondary-nav-mobile .edit-post-btn {
  position: relative;
  background-color: #fff;
  color: #43537f;
  width: 100%;
  margin-top: 10px;
}
ssi-publisher-secondary-view-nav .publisher-secondary-nav-mobile .edit-post-btn i {
  font-size: 12px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 5px;
  transform: rotate(90deg);
}