.orlo-button {
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  color: #fff;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 14px;
  font-weight: bold;
}
.orlo-button:focus {
  outline: 0;
}
.orlo-button.primary {
  background-color: #14bae3;
  margin-right: 2.5%;
}
.orlo-button.secondary {
  background-color: #434552;
}
.orlo-button.text {
  background: transparent;
  text-transform: uppercase;
  color: #43537f;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 0.8em;
}
.orlo-button:disabled {
  background-color: #cfd3de;
}

.orlo-table {
  margin: 0;
  border-radius: 25px;
  background-color: #fff;
  border: none !important;
  color: #43537f;
}
.orlo-table.striped tr:nth-child(even) {
  background-color: #fff;
}
.orlo-table.striped tr:nth-child(odd) {
  background-color: #f0f3f9;
}
.orlo-table tr {
  border-top: 2px solid #f0f3f9;
}
.orlo-table tr:first-child {
  border-top: none;
}
.orlo-table tr.has-delete:hover {
  box-shadow: 0px 0px 10px 5px #f3f3f3;
  border-radius: 10px;
  border-top-color: transparent;
}
.orlo-table tr.has-delete:hover + tr {
  border-top-color: transparent;
}
.orlo-table tr.has-delete a {
  position: relative;
  margin-right: 30px;
  margin-left: 15px;
}
.orlo-table tr.has-delete a i,
.orlo-table tr.has-delete a .icon-wrap {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 14px;
  color: #d4d7e2;
  line-height: 0;
}
.orlo-table tr.has-delete a i:hover, .orlo-table tr.has-delete a i.visible,
.orlo-table tr.has-delete a .icon-wrap:hover,
.orlo-table tr.has-delete a .icon-wrap.visible {
  padding: 19px;
  border-radius: 50px;
  background-color: #faeeeb;
  color: #e29e8c;
  top: -8px;
  left: -8px;
}
.orlo-table th {
  padding: 0 25px 25px 25px !important;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 900;
  line-height: 2.5em;
  color: #838eab;
  letter-spacing: 1px;
  box-sizing: border-box;
  border: none !important;
}
.orlo-table td {
  padding: 15px !important;
  border: none !important;
  word-wrap: break-word;
}
.orlo-table td.small {
  font-size: 0.85em;
}
.orlo-table td.centered {
  text-align: center;
}
.orlo-table tbody td:first-child {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.9em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 300px;
}
.orlo-table thead tr {
  background-color: #fff !important;
  border: none;
}
.orlo-table thead th {
  padding: 1em 15px !important;
  background-color: #f0f3f9;
  color: #43537f;
}
.orlo-table thead th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.orlo-table thead th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.orlo-text-input {
  width: 100%;
  margin: 0 0 0.4em 0;
  padding: 0.6em 1.6em;
  border-radius: 50px;
  background-color: #f0f3f9;
  border: 3px solid #f0f3f9;
  font-size: 16px;
  font-weight: 900;
  box-sizing: border-box;
}
.orlo-text-input:focus {
  border-color: #14bae3;
  outline: 0;
}
.orlo-text-input::placeholder {
  color: #838eab;
  font-style: italic;
  font-weight: 400;
}
.orlo-text-input.invalid {
  border-color: #fff1ed;
  background-color: #fff1ed;
  color: #f88c68;
}
.orlo-text-input.invalid::placeholder {
  color: #f88c68;
}
.orlo-text-input.no-right-radius {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.orlo-text-input.no-left-radius {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.orlo-input-submit {
  text-align: center;
  white-space: nowrap;
  background-color: #caceda;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border: none;
  padding: 1.4rem 1.6rem;
  margin-bottom: 0.5em;
  color: #fff;
  font-weight: bold;
}
.orlo-input-submit:hover {
  cursor: pointer;
}
.orlo-input-submit.active {
  background-color: #14bae3;
}
.orlo-input-submit i {
  margin-right: 0.5rem;
}

.orlo-select {
  margin: 0 0 0.4em 0;
  position: relative;
}
.orlo-select i {
  position: absolute;
  top: 45px;
  right: 20px;
  font-size: 20px;
  color: #14bae3;
}
.orlo-select select {
  width: 100%;
  height: 46px;
  padding: 0.6em 1.6em;
  border-radius: 50px;
  background-color: #f0f3f9;
  border: 3px solid #f0f3f9;
  font-size: 16px;
  font-weight: 700;
  box-sizing: border-box;
  box-shadow: none;
  appearance: none;
  color: #838eab;
  cursor: pointer;
}
.orlo-select select:focus {
  border-color: #14bae3;
  outline: 0;
}
.orlo-select select::placeholder {
  color: #838eab;
  font-style: italic;
  font-weight: 400;
}
.orlo-select select::-ms-expand {
  display: none;
}
.orlo-select select.invalid {
  border-color: #fff1ed;
  background-color: #fff1ed;
  color: #f88c68;
}
.orlo-select select.invalid::placeholder {
  color: #f88c68;
}
.orlo-select select[disabled] + i {
  color: #ced3df;
}

.orlo-modal .modal-content {
  border-radius: 25px;
  background-color: white;
  border: none;
  margin: auto;
}
.orlo-modal-1000 .modal-dialog {
  width: 1000px;
  max-width: 98%;
  margin-top: -5%;
}
.orlo-modal-1200 .modal-dialog {
  width: 1200px;
  max-width: 98%;
  margin-top: -5%;
}
.orlo-modal.position-top-20 {
  top: 20px;
}
.orlo-modal.position-top-20 .modal-dialog {
  margin-top: 0;
}

.orlo-modal-backdrop {
  background-color: rgb(16, 21, 37);
  opacity: 0.7 !important;
}

.orlo-modal-dark .modal-content {
  border-radius: 25px;
  background-color: #35353f;
  border: none;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #eff2f8 #14bae3;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 6px;
}

.custom-scrollbar-2::-webkit-scrollbar {
  width: 4px;
}

.custom-scrollbar-2 {
  scrollbar-width: thin;
  scrollbar-color: #ecf0fb #14bae3;
}

.custom-scrollbar-2::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 1px;
}

.orlo-textarea {
  width: 100%;
}
.orlo-textarea label {
  width: 100%;
  padding: 0 3em;
  display: block;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 900;
  line-height: 2.5em;
  color: #43537f;
  letter-spacing: 1px;
  box-sizing: border-box;
  margin-bottom: 5px;
}
.orlo-textarea textarea {
  width: 100%;
  margin: 0 0 0.4em 0;
  padding: 1.6em;
  border-radius: 10px;
  background-color: #f8f9fd;
  border: 3px solid transparent;
  font-size: 16px;
  font-weight: 700;
  box-sizing: border-box;
  color: #101525;
}
.orlo-textarea textarea:focus {
  border-color: #14bae3;
  outline: 0;
}
.orlo-textarea textarea::placeholder {
  color: #838eab;
  font-style: italic;
  font-weight: 400;
}
.orlo-textarea textarea.invalid {
  border-color: #fff1ed;
  background-color: #fff1ed;
  color: #f88c68;
}
.orlo-textarea textarea.invalid::placeholder {
  color: #f88c68;
}

.orlo-tickbox {
  display: flex;
  align-items: center;
  position: relative;
}
.orlo-tickbox input[type=checkbox] {
  position: absolute;
  opacity: 0;
  margin: 0;
  cursor: pointer;
}
.orlo-tickbox label {
  width: 100%;
  padding: 0 1em;
  display: block;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 900;
  line-height: 2.5em;
  color: #43537f;
  letter-spacing: 1px;
  box-sizing: border-box;
  margin-bottom: 5px;
}
.orlo-tickbox::before {
  display: block;
  margin-top: -6px;
  background-color: #f0f3f9;
  content: "";
  border-radius: 50px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.orlo-tickbox i {
  display: none;
}
.orlo-tickbox.selected label {
  color: #14bae3;
}
.orlo-tickbox.selected::before {
  color: #14bae3;
  background-color: #d0f1f9;
}
.orlo-tickbox.selected i {
  display: block;
  position: absolute;
  left: 2px;
  top: 2px;
  bottom: 0;
  font-size: 18px;
  color: #14bae3;
}
.orlo-tickbox:first-child {
  margin-right: 30px;
}

.cdk-overlay-container {
  z-index: 1060;
}

cdk-overlay-pane {
  width: 100%;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #eff2f8 #14bae3;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #eff2f8;
  border-radius: 6px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #14bae3;
  border-radius: 6px;
  border: 3px solid #14bae3;
}

.a11y-label {
  position: absolute;
  text-indent: -99999px;
  color: #000;
}

@media print {
  body > button {
    display: none !important;
  }
}
ssi-widget-top-interests-industries {
  font-size: 1rem;
}
ssi-widget-top-interests-industries .no-results-state {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}
ssi-widget-top-interests-industries .no-results-state i {
  color: #b4bbce;
  font-size: 40px;
}
ssi-widget-top-interests-industries .no-results-state h4 {
  margin-top: 10px;
  color: #43537f;
  font-weight: 900;
  font-size: 20px;
}
ssi-widget-top-interests-industries .no-results-state p {
  color: #43537f;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}
ssi-widget-top-interests-industries .groups-container {
  display: flex;
  height: 100%;
  align-items: center;
  overflow: auto;
}
ssi-widget-top-interests-industries .groups-container.view-1x1, ssi-widget-top-interests-industries .groups-container.view-1x2, ssi-widget-top-interests-industries .groups-container.view-1x3, ssi-widget-top-interests-industries .groups-container.view-2x2, ssi-widget-top-interests-industries .groups-container.view-2x3, ssi-widget-top-interests-industries .groups-container.view-2x4, ssi-widget-top-interests-industries .groups-container.view-3x2, ssi-widget-top-interests-industries .groups-container.view-3x3, ssi-widget-top-interests-industries .groups-container.view-3x4, ssi-widget-top-interests-industries .groups-container.view-4x3, ssi-widget-top-interests-industries .groups-container.view-4x4 {
  flex-wrap: wrap;
}
ssi-widget-top-interests-industries .groups-container.view-1x1 .single-group, ssi-widget-top-interests-industries .groups-container.view-1x2 .single-group, ssi-widget-top-interests-industries .groups-container.view-1x3 .single-group, ssi-widget-top-interests-industries .groups-container.view-2x2 .single-group, ssi-widget-top-interests-industries .groups-container.view-2x3 .single-group, ssi-widget-top-interests-industries .groups-container.view-2x4 .single-group, ssi-widget-top-interests-industries .groups-container.view-3x2 .single-group, ssi-widget-top-interests-industries .groups-container.view-3x3 .single-group, ssi-widget-top-interests-industries .groups-container.view-3x4 .single-group, ssi-widget-top-interests-industries .groups-container.view-4x3 .single-group, ssi-widget-top-interests-industries .groups-container.view-4x4 .single-group {
  flex-basis: 50%;
  max-width: initial;
}
ssi-widget-top-interests-industries .groups-container.view-1x1 .single-group:before, ssi-widget-top-interests-industries .groups-container.view-1x2 .single-group:before, ssi-widget-top-interests-industries .groups-container.view-1x3 .single-group:before, ssi-widget-top-interests-industries .groups-container.view-2x2 .single-group:before, ssi-widget-top-interests-industries .groups-container.view-2x3 .single-group:before, ssi-widget-top-interests-industries .groups-container.view-2x4 .single-group:before, ssi-widget-top-interests-industries .groups-container.view-3x2 .single-group:before, ssi-widget-top-interests-industries .groups-container.view-3x3 .single-group:before, ssi-widget-top-interests-industries .groups-container.view-3x4 .single-group:before, ssi-widget-top-interests-industries .groups-container.view-4x3 .single-group:before, ssi-widget-top-interests-industries .groups-container.view-4x4 .single-group:before {
  display: none;
}
ssi-widget-top-interests-industries .groups-container.view-1x1, ssi-widget-top-interests-industries .groups-container.view-1x2, ssi-widget-top-interests-industries .groups-container.view-1x3, ssi-widget-top-interests-industries .groups-container.view-1x4 {
  flex-wrap: wrap;
}
ssi-widget-top-interests-industries .groups-container.view-1x1 .single-group, ssi-widget-top-interests-industries .groups-container.view-1x2 .single-group, ssi-widget-top-interests-industries .groups-container.view-1x3 .single-group, ssi-widget-top-interests-industries .groups-container.view-1x4 .single-group {
  flex-basis: 100%;
  max-width: initial;
}
ssi-widget-top-interests-industries .groups-container.view-1x1, ssi-widget-top-interests-industries .groups-container.view-2x1, ssi-widget-top-interests-industries .groups-container.view-3x1, ssi-widget-top-interests-industries .groups-container.view-4x1 {
  align-items: flex-start;
}
ssi-widget-top-interests-industries .groups-container.view-4x1 .single-group:before, ssi-widget-top-interests-industries .groups-container.view-4x2 .single-group:before {
  display: none;
}
@media (max-width: 1399px) {
  ssi-widget-top-interests-industries .groups-container.view-2x1 .single-group, ssi-widget-top-interests-industries .groups-container.view-2x2 .single-group, ssi-widget-top-interests-industries .groups-container.view-2x3 .single-group, ssi-widget-top-interests-industries .groups-container.view-2x4 .single-group {
    flex-basis: 100%;
    max-width: initial;
  }
}
ssi-widget-top-interests-industries .groups-container.view-4x2, ssi-widget-top-interests-industries .groups-container.view-2x4, ssi-widget-top-interests-industries .groups-container.view-3x4, ssi-widget-top-interests-industries .groups-container.view-4x4 {
  overflow: hidden;
}
ssi-widget-top-interests-industries .groups-container.centered {
  justify-content: center;
}
ssi-widget-top-interests-industries .single-group {
  position: relative;
  width: 400px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  flex-basis: 25%;
  max-width: 25%;
}
ssi-widget-top-interests-industries .single-group h5 {
  color: #838eab;
  font-size: 10px;
  font-weight: 900;
  line-height: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 0;
}
ssi-widget-top-interests-industries .single-group .top-word {
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #f0b427;
  background: rgba(240, 180, 39, 0.2);
  height: fit-content;
  padding: 2px 8px;
}
ssi-widget-top-interests-industries .single-group .top-word i {
  position: relative;
  color: #f0b427;
  font-size: 20px;
  margin-right: 7px;
}
ssi-widget-top-interests-industries .single-group .top-word i span {
  position: absolute;
  top: 2px;
  left: 7px;
  font-family: Lato;
  font-size: 10px;
}
ssi-widget-top-interests-industries .single-group .top-word span {
  color: #101525;
  font-size: 12px;
  font-weight: 900;
}
ssi-widget-top-interests-industries .single-group .top-word:hover {
  cursor: pointer;
}
ssi-widget-top-interests-industries .single-group svg .progress {
  stroke: #f0b427;
}
ssi-widget-top-interests-industries .single-group:nth-child(2) .top-word {
  border: 1px solid #98a7d1;
  background: #e0e5f1;
}
ssi-widget-top-interests-industries .single-group:nth-child(2) .top-word i {
  color: #98a7d1;
}
ssi-widget-top-interests-industries .single-group:nth-child(2) .geo-demo-facts {
  border-color: #98a7d1;
}
ssi-widget-top-interests-industries .single-group:nth-child(2) svg .progress {
  stroke: #98a7d1;
}
ssi-widget-top-interests-industries .single-group:nth-child(3) .top-word {
  border: 1px solid #f88c68;
  background: #fee8e1;
}
ssi-widget-top-interests-industries .single-group:nth-child(3) .top-word i {
  color: #f88c68;
}
ssi-widget-top-interests-industries .single-group:nth-child(3) .geo-demo-facts {
  border-color: #f88c68;
}
ssi-widget-top-interests-industries .single-group:nth-child(3) svg .progress {
  stroke: #f88c68;
}
ssi-widget-top-interests-industries .single-group:nth-child(4) .top-word {
  border: 1px solid #d43082;
  background: #fbeaf3;
}
ssi-widget-top-interests-industries .single-group:nth-child(4) .top-word i {
  color: #d43082;
}
ssi-widget-top-interests-industries .single-group:nth-child(4) .geo-demo-facts {
  border-color: #d43082;
}
ssi-widget-top-interests-industries .single-group:nth-child(4) svg .progress {
  stroke: #d43082;
}
ssi-widget-top-interests-industries .single-group:after, ssi-widget-top-interests-industries .single-group.before {
  content: "";
  display: block;
  position: absolute;
  height: 100%;
  width: 1px;
  right: 1px;
  background-color: #e2e2ee;
}
ssi-widget-top-interests-industries .single-group:before {
  content: "";
  display: block;
  position: absolute;
  height: 1px;
  width: 100%;
  right: 1px;
  bottom: 0;
  background-color: #e2e2ee;
}
ssi-widget-top-interests-industries .single-group:last-child::after {
  display: none;
}
ssi-widget-top-interests-industries .fact {
  margin-right: 10px;
}
ssi-widget-top-interests-industries .fact i {
  font-size: 18px;
  color: #939cb6;
}
ssi-widget-top-interests-industries .fact-name {
  color: #838eab;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
}
ssi-widget-top-interests-industries .fact-value {
  color: #000;
  font-size: 12px;
  font-weight: 900;
  line-height: 12px;
  text-transform: capitalize;
  letter-spacing: 0;
  padding-top: 7px;
}
ssi-widget-top-interests-industries .main-facts {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
}
ssi-widget-top-interests-industries .main-facts span.value {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: #000;
  text-align: center;
  font-weight: 900;
  text-transform: uppercase;
}
ssi-widget-top-interests-industries .main-facts div {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 10px;
}
ssi-widget-top-interests-industries .main-facts div span {
  font-size: 2em;
  font-weight: 500;
}
ssi-widget-top-interests-industries .main-facts svg {
  height: 56px;
  width: 56px;
  transform: rotate(-90deg);
}
ssi-widget-top-interests-industries .geo-demo-facts {
  display: flex;
  border: 1px solid #f0b427;
  border-radius: 10px;
  background-color: #f9f9fc;
  padding: 14px 12px;
  justify-content: space-between;
  align-items: baseline;
  overflow: hidden;
}
ssi-widget-top-interests-industries .geo-demo-facts img {
  height: 18px;
  width: 18px;
}
ssi-widget-top-interests-industries .geo-demo-facts:hover {
  cursor: pointer;
}
ssi-widget-top-interests-industries .avatar {
  width: 30px;
  height: auto;
  border-radius: 50%;
}
ssi-widget-top-interests-industries .bio-keywords {
  height: 100px;
  padding: 12px 0;
  border-bottom: 1px solid #e2e2ee;
}
ssi-widget-top-interests-industries .bio-keywords .keywords {
  display: flex;
  flex-wrap: wrap;
}
ssi-widget-top-interests-industries .bio-keywords .keywords span {
  color: #101525;
  font-size: 12px;
  font-weight: 900;
  border-radius: 5px;
  background: #f4f4fa;
  padding: 6px 8px;
  margin-right: 2px;
  margin-bottom: 2px;
}
ssi-widget-top-interests-industries .bio-keywords .keywords span:hover {
  cursor: pointer;
}
ssi-widget-top-interests-industries .top-emotions {
  height: 100px;
  padding: 12px 0;
  border-bottom: 1px solid #e2e2ee;
}
ssi-widget-top-interests-industries .emotions-list {
  display: flex;
  flex-wrap: wrap;
}
ssi-widget-top-interests-industries .chip-item {
  display: flex;
  border-radius: 4px;
  margin-right: 2px;
  margin-bottom: 2px;
}
ssi-widget-top-interests-industries .chip-item i {
  font-size: 14px;
  padding: 3px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
ssi-widget-top-interests-industries .chip-item span {
  display: flex;
  align-items: center;
  padding: 0 10px;
  color: #43537f;
  font-size: 12px;
  font-weight: 900;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
ssi-widget-top-interests-industries .chip-item:hover {
  cursor: pointer;
}
ssi-widget-top-interests-industries .top-influencer {
  padding: 10px 0;
}
ssi-widget-top-interests-industries .top-influencer h5 {
  display: flex;
  justify-content: space-between;
}
ssi-widget-top-interests-industries .top-influencer .social-profile-dets {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
ssi-widget-top-interests-industries .top-influencer .social-profile-dets .followers-count {
  color: #000;
  text-align: right;
  font-size: 14px;
  font-weight: 900;
  margin-left: auto;
}
ssi-widget-top-interests-industries .top-influencer .view-more {
  color: #14bae3;
  text-align: right;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  text-decoration-line: underline;
  text-transform: uppercase;
  cursor: pointer;
}
ssi-widget-top-interests-industries .top-influencer .view-more i {
  color: #fff;
  font-size: 8px;
  height: 10px;
  width: 10px;
  padding: 1px;
  margin-left: auto;
  margin-right: 5px;
  border-radius: 50%;
  background-color: #14bae3;
}
ssi-widget-top-interests-industries .top-influencer img {
  margin-right: 3px;
}
ssi-widget-top-interests-industries .top-influencer .linked-name {
  color: #14bae3;
  font-size: 12px;
  font-weight: 900;
  text-decoration-line: underline;
  white-space: nowrap;
  cursor: pointer;
}
ssi-widget-top-interests-industries .top-influencer .handle {
  color: #000;
  font-weight: 400;
  font-size: 12px;
}