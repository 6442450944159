@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-attachments {
  .composer-attachments {
    margin-top: 20px;

    h3 {
      font-size: 10px;
      font-weight: 900;
      line-height: 12px;
      letter-spacing: 0.1em;
      text-align: left;
      color: #43537f;
      text-transform: uppercase;
      padding: 0 0 10px 20px;
    }

    .post-attachments-container {
      display: flex;
      margin-top: 10px;
      border-radius: 15px;
      padding: 10px;
      background-color: #f8f9fd;
      border: 1px solid #c3c9d8;
    }

    .post-attachment {
      width: 200px;
      height: 200px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      overflow: hidden;
      border-radius: 10px;
      border: 2px dashed #c3c9d8;
      background-color: #d9dfe9;

      img {
        max-height: 100%;
        max-width: 100%;
        cursor: pointer;
      }

      video {
        max-height: 100%;
        max-width: 100%;
      }

      &.add-new {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
      }

      &.upload-progress {
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        background-color: white;
        border: 2px dashed #14bae3;
      }

      &.hovered {
        border: 2px dashed #14bae3;
      }

      &.hidden {
        display: none;
      }

      // &.loading-cover {
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   width: 100%;
      //   height: 100%;
      //   background-color: $lmgrey-background;
      //   border-radius: 10px;
      //   border: 6px solid #fff;

      //   display: flex;
      //   flex-direction: column;
      //   align-items: center;
      //   justify-content: center;

      //   i {
      //     color: $primary-active;
      //   }

      //   span {
      //     margin-top: 10px;
      //     color: $lmode-helper-text;
      //     font-size: 14px;
      //     font-weight: 700;
      //     padding: 0 30px;
      //     text-align: center;
      //   }
      // }
    }

    .post-attachment-type {
      position: absolute;
      top: 0px;
      left: 12px;
      font-size: 24px;
      color: white;
    }
    .post-attachment-upload-progress-type {
      font-size: 24px;
      color: #838eab;
    }

    .post-attachment-cancel {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      color: #43537f;
      font-size: 10px;
    }

    .post-attachment-name {
      color: #43537f;
      font-size: 12px;
      font-weight: 900;
    }

    .post-attachment-size {
      color: #43537f;
      font-size: 12px;
      font-weight: 700;
    }

    .post-attachment-progress-bar {
      margin-top: 20px;
      width: 100%;
    }

    .post-attachment-progress-spin {
      margin-top: 10px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .spinner {
        color: $primary-active;
      }
      .resizing-video-text {
        margin-top: 5px;
        color: $lmode-helper-text;
        font-size: 14px;
        font-weight: 700;
        padding: 0 20px;
        text-align: center;
      }
    }

    .post-attachment-progress-text {
      color: #43537f;
      font-size: 12px;
      font-weight: 900;
    }

    .post-attachment-options-menu {
      position: absolute;
      top: 0px;
      right: 0px;

      .post-attachment-options-menu-trigger {
        width: 46px;
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 10px;
        cursor: pointer;

        i {
          font-size: 17px;
        }

        &:hover {
          background-color: #e4faff;
          border: 1px solid #14bae3;
        }
      }

      .post-attachment-options-list {
        .remove-icon {
          color: #ff4d11;
        }
      }
    }

    // .play-button {
    //   position: absolute;
    //   top: 40%;
    //   left: 40%;
    //   color: white;
    //   font-size: 40px;
    // }

    .post-attachment-action {
      position: absolute;

      width: 46px;
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      border-radius: 10px;
      cursor: pointer;

      i {
        font-size: 17px;
      }

      &.bottom-left {
        bottom: 0px;
        left: 0px;
      }

      &.top-left {
        top: 0px;
        left: 0px;
      }

      &.top-right {
        top: 0px;
        right: 0px;
      }

      &.action-remove {
        i {
          color: #ff4d11;
        }
        &:hover {
          background-color: #ffede7;
          border: 1px solid #ff4d11;
        }
      }

      &.action-edit {
        &:hover {
          background-color: #e4faff;
          border: 1px solid #14bae3;
        }
      }
    }
  }
}
