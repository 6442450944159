ssi-analytics ssi-marketing ssi-report ssi-follower-count .follower-count-container .section-body {
  display: flex;
  flex-direction: column;
}
@media print {
  ssi-analytics ssi-marketing ssi-report ssi-follower-count .follower-count-container .section-body {
    display: block;
  }
}
ssi-analytics ssi-marketing ssi-report ssi-follower-count .follower-count-container .section-body .card {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.35);
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 160px;
}
ssi-analytics ssi-marketing ssi-report ssi-follower-count .follower-count-container .section-body .card:not(:last-child) {
  margin-right: 20px;
}
ssi-analytics ssi-marketing ssi-report ssi-follower-count .follower-count-container .section-body .card .half {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
ssi-analytics ssi-marketing ssi-report ssi-follower-count .follower-count-container .section-body .followers-by-gender {
  display: flex;
  flex-wrap: wrap;
}
ssi-analytics ssi-marketing ssi-report ssi-follower-count .follower-count-container .section-body .followers-by-gender .card {
  padding: 40px;
}
ssi-analytics ssi-marketing ssi-report ssi-follower-count .follower-count-container .section-body .followers-by-gender .card .half.top .title {
  color: #838eab;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  line-height: 12px;
  text-transform: uppercase;
}
ssi-analytics ssi-marketing ssi-report ssi-follower-count .follower-count-container .section-body .followers-by-gender .card .half.top .percentage {
  color: #101525;
  font-size: 28px;
  font-weight: 900;
}
ssi-analytics ssi-marketing ssi-report ssi-follower-count .follower-count-container .section-body .followers-by-gender .card .half.top .percentage i {
  color: #838eab;
  font-size: 32px;
  margin-left: 10px;
}
ssi-analytics ssi-marketing ssi-report ssi-follower-count .follower-count-container .section-body .followers-by-network {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
@media print {
  ssi-analytics ssi-marketing ssi-report ssi-follower-count .follower-count-container .section-body .followers-by-network {
    display: block;
  }
}
ssi-analytics ssi-marketing ssi-report ssi-follower-count .follower-count-container .section-body .followers-by-network .card {
  padding: 15px;
}
@media print {
  ssi-analytics ssi-marketing ssi-report ssi-follower-count .follower-count-container .section-body .followers-by-network .card {
    page-break-inside: avoid;
    display: inline-block;
    width: auto;
  }
}
ssi-analytics ssi-marketing ssi-report ssi-follower-count .follower-count-container .section-body .followers-by-network .card .half.top .network-info {
  display: flex;
}
ssi-analytics ssi-marketing ssi-report ssi-follower-count .follower-count-container .section-body .followers-by-network .card .half.top .network-info .network-icon {
  margin-right: 10px;
}
ssi-analytics ssi-marketing ssi-report ssi-follower-count .follower-count-container .section-body .followers-by-network .card .half.top .network-info .network-name {
  color: #43537f;
  font-size: 16px;
  font-weight: 900;
}
ssi-analytics ssi-marketing ssi-report ssi-follower-count .follower-count-container .section-body .followers-by-network .card .half.top .network-info .accounts-count {
  color: #838eab;
  font-size: 14px;
  font-weight: 500;
}
ssi-analytics ssi-marketing ssi-report ssi-follower-count .follower-count-container .section-body .followers-by-network .card .half.bottom {
  padding-left: 10px;
}
ssi-analytics ssi-marketing ssi-report ssi-follower-count .follower-count-container .section-body .followers-by-network .card .half.bottom .followers-current-count {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto;
}
ssi-analytics ssi-marketing ssi-report ssi-follower-count .follower-count-container .section-body .followers-by-network .card .half.bottom .followers-current-count > *:not(:first-child) {
  margin-top: 5px;
}
ssi-analytics ssi-marketing ssi-report ssi-follower-count .follower-count-container .section-body .followers-by-network .card .half.bottom .followers-current-count .count {
  color: #101525;
  font-size: 20px;
  font-weight: 900;
}
ssi-analytics ssi-marketing ssi-report ssi-follower-count .follower-count-container .section-body .followers-by-network .card .half.bottom .followers-current-count .break-line {
  height: 4px;
  width: 60px;
  border-radius: 100px;
  background-color: #d6dff7;
}
ssi-analytics ssi-marketing ssi-report ssi-follower-count .follower-count-container .section-body .followers-by-network .card .half.bottom .followers-current-count > span {
  color: #838eab;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
}