@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

body.modal-open {
  overflow: auto !important;
}

ssi-snackbar {
  font-size: 1rem;
  display: block;
  width: 100%;
}

.snackbar-window {
  display: flex !important;
  pointer-events: none;

  &.d-block {
    display: flex !important;
  }

  &-bottom {
    align-items: flex-end;
  }

  &-top {
    align-items: flex-start;
  }

  .modal-dialog {
    width: auto;
    min-width: 350px;
    max-width: 95%;
  }

  .modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background-color: $primary-active;
    padding: 12px 24px;
    box-shadow: 0 3px 5px -1px rgba(131, 142, 171, 0.4),
      0 6px 10px 0 rgba(131, 142, 171, 0.4),
      0 1px 18px 0 rgba(131, 142, 171, 0.4);

    p {
      color: #fff;
      font-size: 18px;
      font-weight: 700;
      margin: 0;
      text-rendering: geometricPrecision;
    }
  }
}

.snackbar-style-1 {
  .modal-content {
    justify-content: space-between;
    pointer-events: all;
    background-color: #f4f5f5;
    padding: 0;
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    border-radius: 0.6rem;
    box-shadow: 0 0.4rem 3.2rem -0.5rem rgb(119 124 128 / 56%);

    .snackbar-container {
      display: flex;
      align-items: center;
      padding: 2.5em;
      min-width: 59.8em;
      box-shadow: 0 0.5em 1em 0 rgb(131 142 171 / 35%);
    }

    i {
      font-size: 1.4em;
      margin-right: 1rem;
      color: #b2c614;

      &.warning-style-02 {
        color: $secondary-red;
      }
    }

    p {
      font-size: 1.4em;
      line-height: 1.75rem;
      color: $lmode-body-text;
      font-weight: 700;
      margin-right: auto;
      margin-bottom: 0;
      text-rendering: geometricPrecision;
    }

    button {
      appearance: none;
      border: none;
      background: none;
      padding: 0;
      color: $lmode-helper-text;
      outline: none;

      i {
        color: #838eab;
        font-size: 1em;
      }
    }
  }
}
