@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../../angularjs/common/sass/variables';
@import '../../styles/variables';
@import '~/styles/colors';

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

ssi-widget-trustometer-gauge {
  .sentiment-chart-container {
    width: 100%;
    height: 100%;
    overflow: auto;
    position: relative;

    .gauge-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 15px;

      .gauge-background {
        width: 100%;
        height: auto;
      }

      svg,
      .score-container {
        max-width: 300px;
        width: 100%;
      }
    }

    .score-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 5px;
      padding: 3px 7px;
      margin-top: 10px;
      height: 48px;

      .score {
        color: #101525;
        font-size: 30px;
        font-weight: 900;
        margin-right: auto;
      }

      .trustometer-label {
        text-transform: uppercase;
        text-align: right;
        font-size: 10px;
        line-height: 10px;
        font-weight: 900;
        margin-right: 5px;
      }

      i {
        font-size: 30px;
        color: #f05230;
      }

      &.no-data {
        padding: 3px 45px;
        .trustometer-label {
          text-align: center;
          line-height: 12px;
        }
      }
    }
  }
  .themes-container {
    padding: 0 15px 15px 15px;
    .themes-group {
      h5 {
        color: $lmode-helper-text;
        font-size: 10px;
        font-weight: 900;
        text-transform: uppercase;
      }
      &:last-child {
        border-top: 1px solid $lmode-helper-text;
        margin-top: 10px;
      }
    }

    .themes-list {
      display: flex;
      flex-wrap: wrap;
      span {
        color: $lmode-title-text;
        font-size: 12px;
        font-weight: 900;
        padding: 5px;
        border-radius: 5px;
        margin-right: 2px;
        margin-bottom: 2px;
        background: #0dcdc2;
        white-space: nowrap;
        cursor: pointer;
        // text-overflow: ellipsis;
        // max-width: 250px;
        // overflow: hidden;
      }

      &.distrusting {
        .theme {
          color: #fff;
          background: #f05230;
        }

        .loading-themes-loader {
          span {
            background-image: linear-gradient(
              to right,
              #f05230 8%,
              #dddddd 18%,
              #f05230 33%
            );
          }
        }
      }

      p {
        color: $lmode-body-text;
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
      }
    }

    .loading-themes-loader {
      height: 70px;
      width: 100%;

      span {
        opacity: 0.1;
        width: 130px;
        height: 26px;
        display: inline-flex;
        animation-duration: 1.5s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        background: linear-gradient(
          to right,
          #0dcdc2 8%,
          #dddddd 18%,
          #0dcdc2 33%
        );
        background-size: 800px 104px;
        position: relative;

        &:nth-child(1) {
          width: 50px;
        }
        &:nth-child(2) {
          width: 120px;
        }
        &:nth-child(3) {
          width: 60px;
        }
        &:nth-child(4) {
          width: 100px;
        }
        &:nth-child(5) {
          width: 90px;
        }
      }
    }

    .no-themes-results {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 30px;

      i {
        font-size: 40px;
        color: $dmode-body-text;
        margin-bottom: 20px;
      }
      h4 {
        color: $lmode-body-text;
        font-weight: 900;
        font-size: 20px;
      }
      p {
        color: $lmode-body-text;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
}
