@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';
@import '../../../../angularjs/common/sass/mixins';

$avatar-size: 4.6em;
$message-padding-gutter: 3em;
$message-avatar-gap: 0.4em;
$message-border-radius: 15px;
$meta-indent: $avatar-size + $message-avatar-gap + $message-padding-gutter;
$message-max-width: calc(100% - #{$avatar-size + $message-avatar-gap});
$message-items-max-width: calc(
  100% - #{$avatar-size + $message-avatar-gap - $message-padding-gutter * 2}
);
$message-gallery-max-width: calc(
  100% - #{($avatar-size + $message-avatar-gap) - (
      $meta-indent + $message-padding-gutter
    )}
);
$message-end-gap: 10em;
$message-total-gap: $message-avatar-gap + $message-end-gap;
$message-negative-width: $avatar-size + $message-total-gap +
  $message-padding-gutter * 2;
$gallery-size: 9em;
$gallery-indent: $avatar-size + $message-avatar-gap;
$gallery-item-radius: 1.2em;
$gallery-item-action-size: 4.6em;
$meta-margin-top-bottom: 0.9em;

ssi-email-to-modal {
  .email-to {
    background-color: rgba($grey-background, 0.2);
    border-radius: 25px;
  }
  .header {
    width: 80%;
    padding: 2em 4em;
    position: relative;
    img {
      width: 12%;
      height: 12%;
      margin: 1.6em 0;
    }
    button {
      margin-top: 1.6em;
    }
    h2 {
      color: $lmode-body-text;
      font-size: 1.4em;
      font-weight: 800;
    }
    p {
      margin: 0;
      font-size: 14px;
      color: $lmode-helper-text;
      font-weight: 500;
    }
    i {
      position: absolute;
      font-size: 1.4em;
      color: $dmode-body-text;
      left: 30px;
      top: 28px;
    }
  }
  .exit {
    position: absolute;
    right: 15px;
    top: 15px;
    background-color: #eff2f8;
    border-radius: 100%;
    cursor: pointer;
    i {
      display: inline-block;
      padding: 10px;
      color: $lmode-helper-text;
    }
  }
  .scroll-container {
    margin: 0 4em;
    padding: 0 0 2em 0;
    max-height: 280px;
    overflow-y: scroll;
    background: linear-gradient(white 30%, hsla(0, 0%, 100%, 0)),
      linear-gradient(hsla(0, 0%, 100%, 0) 10px, white 70%) bottom,
      radial-gradient(at top, rgba(0, 0, 0, 0.1), transparent 70%),
      radial-gradient(at bottom, rgba(0, 0, 0, 0.1), transparent 70%) bottom;
    background-repeat: no-repeat;
    background-size: 100% 20px, 100% 20px, 100% 7px, 100% 7px;
    background-attachment: local, local, scroll, scroll;
  }
  .message-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 2em 0 0;
    font-size: 1rem;
    cursor: pointer;

    &.message-box-self-penned {
      flex-direction: row-reverse;
      padding-right: 3em;
      .message-box-message-wrap {
        flex-direction: row-reverse;
      }
      .message-box-message {
        margin: 0 0.4em 0 0;
        background-color: #e7eefd;
      }
    }
    &.selected {
      .message-box-message {
        border-color: $primary-active;
      }
    }
  }
  .message-box-container {
    align-items: flex-start;
    flex: 0 0 auto;
    max-width: 100%;
  }

  .message-box-message-wrap {
    display: flex;
  }

  .message-box-avatar-wrap {
    display: inline-flex;
    flex: 0 0 $avatar-size;
    align-items: center;
    justify-content: center;
    height: $avatar-size;
    width: $avatar-size;
    background-color: #9db0db;
    background-size: cover;
    // background-image: url('./img/avatar-refresh-512px.png');
    color: #fff;
    border-radius: 50%;
    overflow: hidden;

    &.message-box-avatar-actionable {
      cursor: pointer;

      &:hover {
        background-image: none;

        .message-box-avatar {
          display: none;
        }

        .message-box-avatar-actionable-button {
          display: flex;
        }
      }
    }

    .message-box-avatar-actionable-button {
      display: none;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      color: #fff;
      background-color: $primary-active;

      &.message-box-avatar-actionable-button-show {
        display: flex;
      }

      i {
        font-size: 2.4em;
      }
    }
  }

  .message-box-avatar {
    width: 100%;
    height: 100%;
  }

  .message-box-message {
    display: flex;
    flex: 0 1 auto;
    flex-wrap: wrap;
    flex-flow: column;
    margin: 0 $message-end-gap 0 $message-avatar-gap;
    background-color: #fff;
    border-radius: $message-border-radius;
    padding: 1em 2em;
    box-shadow: 0 3px 10px 0 rgba(131, 142, 171, 0.25);
    font-size: 1.3rem;
    font-weight: 700;
    color: $lmode-title-text;
    border: 2px solid transparent;

    @include ie-only {
      width: $message-max-width;
    }
  }

  .send-to-container {
    padding: 2em 4em;
    background-color: white;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
    border-top: 2px solid $lmode-border-color;
    input,
    textarea {
      font-size: 14px !important;
      font-weight: 500 !important;
      color: $lmode-title-text !important;
    }

    .inline-label {
      display: inline;
      padding: 0;
    }
    .buttons {
      text-align: right;
      button {
        padding-right: 0;
        &:disabled {
          color: $dmode-body-text !important;
        }
        span {
          font-size: 10px;
          font-weight: 900 !important;
          letter-spacing: 1px;
        }
      }
    }
    .recent-email-list {
      display: flex;
      flex-direction: row-reverse;
      .recent-email {
        background-color: $grey-background;
        padding: 0.4em 1em;
        margin: 0 0.4em;
        font-size: 0.8em;
        font-weight: 900;
        border-radius: 15px;
        color: $lmode-body-text;
        cursor: pointer;
        i {
          padding-right: 4px;
          color: $lmode-helper-text;
        }
      }
    }
  }
  .footer {
    text-align: center;
    * {
      margin: 1em auto;
      display: block;
    }
  }
}
