@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../angularjs/common/sass/variables';

ssi-insights ssi-reports {
  .insights-reports-container {
    position: relative;
    position: relative;
    min-height: calc(100vh - #{$navbar-height});
    background-color: #f4f4fa;
  }
}
