ssi-campaign-modal .header {
  width: 400px;
  text-align: center;
  margin: 0 auto 2em auto;
}
ssi-campaign-modal .header img {
  width: 12%;
  height: 12%;
  margin: 1.6em 0;
}
ssi-campaign-modal .header button {
  margin-top: 1.6em;
}
ssi-campaign-modal .header h2 {
  color: #5471d7;
  font-size: 1.4em;
  font-weight: 600;
  margin: 0 0 1.2em 0;
}
ssi-campaign-modal .header p {
  margin: 1em 0;
  font-size: 12px;
  color: #a6b7c1;
  font-weight: 500;
}
ssi-campaign-modal .exit {
  position: absolute;
  right: 15px;
  top: 15px;
  background-color: #eff2f8;
  border-radius: 100%;
  cursor: pointer;
}
ssi-campaign-modal .exit i {
  display: inline-block;
  padding: 10px;
  color: #838eab;
}
ssi-campaign-modal .dropdown-select-menu {
  position: relative !important;
  border-radius: 25px;
}
ssi-campaign-modal .dropdown-select-container {
  margin-bottom: 1em;
}
ssi-campaign-modal .dropdown-select-container .dropdown-select-menu-item {
  visibility: visible;
  background-color: transparent;
  border-width: 0;
  text-align: left;
}
ssi-campaign-modal .dropdown-select-container button {
  visibility: hidden;
}
ssi-campaign-modal .campaigns {
  padding: 2em 4em;
  position: relative;
}
ssi-campaign-modal input {
  display: none;
}
ssi-campaign-modal .footer {
  text-align: center;
}
ssi-campaign-modal .footer * {
  margin: 1em auto;
  display: block;
}
ssi-campaign-modal .publisher-landing-dropdown-item-indent {
  margin-left: 20px;
  display: inline-block;
}
ssi-campaign-modal .publisher-landing-dropdown-item-indent i {
  width: 18px;
}