ssi-triggers-sentiment-selector {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px;
}
ssi-triggers-sentiment-selector .sentiment-option {
  background-color: #dae0e4;
  padding: 10px;
  border-radius: 30px;
  color: white;
  margin: 5px;
}
ssi-triggers-sentiment-selector .sentiment-option.active {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
ssi-triggers-sentiment-selector .sentiment-option:hover {
  background-color: #cbd4d9;
}