body {
  font-size: 14px;
  font-weight: 300;
  color: $text-color;
}

a {
  color: $brand-primary;
  cursor: pointer;
}

h1 {
  font-size: 30px;
  font-weight: 300;
}

h2 {
  font-size: 25px;
  font-weight: 300;
}

h3 {
  font-size: 20px;
  font-weight: 300;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-weight: 300;
}

h3 {
  font-weight: 300;
  font-size: 21px;
  line-height: 28px;
}

h4 {
  font-weight: 300;
}

p.lead {
  font-size: 18px;
}

.pre-text {
  white-space: pre-line;
}

.no-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.wrap-hover:hover {
  white-space: normal;
}

h1 a,
h2 a,
h3 a,
h4 a {
  color: #262626;
}

a.text-muted:hover,
a.text-muted:focus {
  color: $text-muted;
}

.text-wrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

.text-light {
  color: white !important;
}

.font-weight-normal,
label {
  font-weight: normal;
}

.text-royal {
  color: $brand-royal !important;
}

.text-success {
  color: $brand-success;
}

.text-italic {
  font-style: italic;
}
