ssi-fact-overview .fact-overview {
  position: relative;
  display: flex;
  padding: 15px;
  background-color: #fff;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.35);
}
ssi-fact-overview .fact-overview.extra-padding {
  padding: 30px 15px;
  padding-bottom: 50px;
}
@media print {
  ssi-fact-overview .fact-overview {
    border: 1px solid black;
    page-break-inside: avoid;
  }
}
ssi-fact-overview .fact-overview .divider {
  height: 4px;
  width: 40px;
  flex: none;
  background-color: #d6dff7;
  margin-top: 7px;
  border-radius: 100px;
}
ssi-fact-overview .fact-overview .stat-icon,
ssi-fact-overview .fact-overview .svg-icon-wrap {
  padding: 15px;
  border-radius: 50px;
  margin: 10px 0;
}
ssi-fact-overview .fact-overview i.ssi-engagements-ads {
  font-weight: 700;
}
ssi-fact-overview .fact-overview > span {
  text-transform: uppercase;
  text-align: center;
  font-size: 10px;
  color: #838eab;
  font-weight: 900;
  letter-spacing: 1px;
  white-space: nowrap;
}
ssi-fact-overview .fact-overview h5 {
  display: flex;
  color: #101525;
  font-weight: 900;
  font-size: 20px;
}
ssi-fact-overview .fact-overview .stat-change-container {
  position: absolute;
  bottom: 0;
}
ssi-fact-overview .fact-overview .stat-change-container ssi-stat-change .stat-change {
  min-width: 120px;
  font-size: 16px;
}