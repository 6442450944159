ssi-social-push-mode-menu .status-menu {
  width: 90px;
}
ssi-social-push-mode-menu label {
  margin-right: 5px;
  margin-top: 3px;
  color: #838eab;
  font-size: 14px;
  font-weight: 900;
}
ssi-social-push-mode-menu ul {
  position: absolute;
  top: 0;
  height: 26px;
  width: 90px;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  list-style: none;
  padding: 0;
  border: 1px solid transparent;
  border-color: transparent;
  flex-direction: column;
}
ssi-social-push-mode-menu ul.active {
  display: block;
  height: auto;
  border-left: 1px solid #cacaca;
  border-right: 1px solid #cacaca;
  border-bottom: 1px solid #cacaca;
}
ssi-social-push-mode-menu ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 26px;
  padding: 0 10px;
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 900;
}
ssi-social-push-mode-menu ul li i .ssi {
  font-size: 10px;
  padding-top: 2px;
}
ssi-social-push-mode-menu ul li:hover {
  color: #14bae3;
  cursor: pointer;
}
ssi-social-push-mode-menu ul li.initial {
  color: #fff;
  font-weight: 900;
}
ssi-social-push-mode-menu ul li.offline {
  background-color: #43537f;
}
ssi-social-push-mode-menu ul li.online {
  background-color: #14bae3;
}
ssi-social-push-mode-menu ul li.busy {
  background-color: #f4490f;
}