ssi-youtube-post-options-select .youtube-post-options-select-container ssi-dropdown-select-2 .dd-head {
  height: 36px;
}
ssi-youtube-post-options-select .youtube-post-options-select-container ssi-dropdown-select-2 .dd-head .button-icon {
  color: #43537f;
}
ssi-youtube-post-options-select .youtube-post-options-select-container ssi-dropdown-select-2 .dd-body ul li i.ssi-unlisted {
  font-size: 16px;
}
ssi-youtube-post-options-select .youtube-post-options-select-container .custom-head-button-label {
  color: #43537f;
}
ssi-youtube-post-options-select .youtube-post-options-select-container .custom-head-button-label i {
  margin-right: 5px;
  font-size: 14px;
}
ssi-youtube-post-options-select .youtube-post-options-select-container .custom-head-button-label span {
  font-size: 12px;
  font-weight: 900;
}
ssi-youtube-post-options-select .youtube-post-options-select-container .menu-header {
  padding: 20px 0;
  width: 280px;
  color: #43537f;
  font-size: 14px;
}
ssi-youtube-post-options-select .youtube-post-options-select-container .menu-header strong {
  font-weight: 900;
}
ssi-youtube-post-options-select .youtube-post-options-select-container .menu-header strong i {
  margin-right: 5px;
}
ssi-youtube-post-options-select .youtube-post-options-select-container .menu-header div {
  font-weight: 700;
  margin-top: 3px;
}