ssi-time-picker .schedule-action {
  background-color: transparent;
  border-width: 0;
}
ssi-time-picker .schedule-action i {
  color: #14bae3;
  font-size: 12px;
}
ssi-time-picker label {
  color: #43537f;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-left: 30px;
  margin-bottom: 15px;
  display: inline-block;
  max-width: 100%;
  line-height: 14px;
}
ssi-time-picker input,
ssi-time-picker select {
  font-family: "Lato";
  border: 1px solid #c3c9d8;
  border-radius: 10px;
  padding: 14px;
  font-size: 14px;
  font-weight: 700;
  line-height: 12px;
  text-align: left;
  color: #6a759a;
  appearance: none;
  height: 46px;
}
ssi-time-picker input.active,
ssi-time-picker select.active {
  color: #101525;
}
ssi-time-picker input:focus-within,
ssi-time-picker select:focus-within {
  outline: none;
}
ssi-time-picker input::placeholder,
ssi-time-picker select::placeholder {
  color: #6a759a;
}
ssi-time-picker input:disabled,
ssi-time-picker select:disabled {
  border-color: #c3c9d8;
  opacity: 1;
}
ssi-time-picker input {
  background-color: #fff;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj4KICA8cGF0aCBkPSJNMTQ4IDI4OGgtNDBjLTYuNiAwLTEyLTUuNC0xMi0xMnYtNDBjMC02LjYgNS40LTEyIDEyLTEyaDQwYzYuNiAwIDEyIDUuNCAxMiAxMnY0MGMwIDYuNi01LjQgMTItMTIgMTJ6bTEwOC0xMnYtNDBjMC02LjYtNS40LTEyLTEyLTEyaC00MGMtNi42IDAtMTIgNS40LTEyIDEydjQwYzAgNi42IDUuNCAxMiAxMiAxMmg0MGM2LjYgMCAxMi01LjQgMTItMTJ6bTk2IDB2LTQwYzAtNi42LTUuNC0xMi0xMi0xMmgtNDBjLTYuNiAwLTEyIDUuNC0xMiAxMnY0MGMwIDYuNiA1LjQgMTIgMTIgMTJoNDBjNi42IDAgMTItNS40IDEyLTEyem0tOTYgOTZ2LTQwYzAtNi42LTUuNC0xMi0xMi0xMmgtNDBjLTYuNiAwLTEyIDUuNC0xMiAxMnY0MGMwIDYuNiA1LjQgMTIgMTIgMTJoNDBjNi42IDAgMTItNS40IDEyLTEyem0tOTYgMHYtNDBjMC02LjYtNS40LTEyLTEyLTEyaC00MGMtNi42IDAtMTIgNS40LTEyIDEydjQwYzAgNi42IDUuNCAxMiAxMiAxMmg0MGM2LjYgMCAxMi01LjQgMTItMTJ6bTE5MiAwdi00MGMwLTYuNi01LjQtMTItMTItMTJoLTQwYy02LjYgMC0xMiA1LjQtMTIgMTJ2NDBjMCA2LjYgNS40IDEyIDEyIDEyaDQwYzYuNiAwIDEyLTUuNCAxMi0xMnptOTYtMjYwdjM1MmMwIDI2LjUtMjEuNSA0OC00OCA0OEg0OGMtMjYuNSAwLTQ4LTIxLjUtNDgtNDhWMTEyYzAtMjYuNSAyMS41LTQ4IDQ4LTQ4aDQ4VjEyYzAtNi42IDUuNC0xMiAxMi0xMmg0MGM2LjYgMCAxMiA1LjQgMTIgMTJ2NTJoMTI4VjEyYzAtNi42IDUuNC0xMiAxMi0xMmg0MGM2LjYgMCAxMiA1LjQgMTIgMTJ2NTJoNDhjMjYuNSAwIDQ4IDIxLjUgNDggNDh6bS00OCAzNDZWMTYwSDQ4djI5OGMwIDMuMyAyLjcgNiA2IDZoMzQwYzMuMyAwIDYtMi43IDYtNnoiIGZpbGw9IiMxNGJhZTMiLz4KPC9zdmc+");
  background-repeat: no-repeat;
  background-position-x: 195px;
  background-position-y: 14px;
  background-size: 14px;
  padding: 16px 30px;
}
ssi-time-picker input:hover {
  cursor: pointer;
}
ssi-time-picker input::placeholder {
  color: #6a759a;
}
ssi-time-picker input:disabled {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIiBmaWxsPSIjQjRCQkNFIj4KICA8cGF0aCBkPSJNMTQ4IDI4OGgtNDBjLTYuNiAwLTEyLTUuNC0xMi0xMnYtNDBjMC02LjYgNS40LTEyIDEyLTEyaDQwYzYuNiAwIDEyIDUuNCAxMiAxMnY0MGMwIDYuNi01LjQgMTItMTIgMTJ6bTEwOC0xMnYtNDBjMC02LjYtNS40LTEyLTEyLTEyaC00MGMtNi42IDAtMTIgNS40LTEyIDEydjQwYzAgNi42IDUuNCAxMiAxMiAxMmg0MGM2LjYgMCAxMi01LjQgMTItMTJ6bTk2IDB2LTQwYzAtNi42LTUuNC0xMi0xMi0xMmgtNDBjLTYuNiAwLTEyIDUuNC0xMiAxMnY0MGMwIDYuNiA1LjQgMTIgMTIgMTJoNDBjNi42IDAgMTItNS40IDEyLTEyem0tOTYgOTZ2LTQwYzAtNi42LTUuNC0xMi0xMi0xMmgtNDBjLTYuNiAwLTEyIDUuNC0xMiAxMnY0MGMwIDYuNiA1LjQgMTIgMTIgMTJoNDBjNi42IDAgMTItNS40IDEyLTEyem0tOTYgMHYtNDBjMC02LjYtNS40LTEyLTEyLTEyaC00MGMtNi42IDAtMTIgNS40LTEyIDEydjQwYzAgNi42IDUuNCAxMiAxMiAxMmg0MGM2LjYgMCAxMi01LjQgMTItMTJ6bTE5MiAwdi00MGMwLTYuNi01LjQtMTItMTItMTJoLTQwYy02LjYgMC0xMiA1LjQtMTIgMTJ2NDBjMCA2LjYgNS40IDEyIDEyIDEyaDQwYzYuNiAwIDEyLTUuNCAxMi0xMnptOTYtMjYwdjM1MmMwIDI2LjUtMjEuNSA0OC00OCA0OEg0OGMtMjYuNSAwLTQ4LTIxLjUtNDgtNDhWMTEyYzAtMjYuNSAyMS41LTQ4IDQ4LTQ4aDQ4VjEyYzAtNi42IDUuNC0xMiAxMi0xMmg0MGM2LjYgMCAxMiA1LjQgMTIgMTJ2NTJoMTI4VjEyYzAtNi42IDUuNC0xMiAxMi0xMmg0MGM2LjYgMCAxMiA1LjQgMTIgMTJ2NTJoNDhjMjYuNSAwIDQ4IDIxLjUgNDggNDh6bS00OCAzNDZWMTYwSDQ4djI5OGMwIDMuMyAyLjcgNiA2IDZoMzQwYzMuMyAwIDYtMi43IDYtNnoiLz4KPC9zdmc+");
  color: #6a759a;
}
ssi-time-picker select {
  cursor: pointer;
  background-color: #fff;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMzAuMDIxIDMwLjAyMSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzAuMDIxIDMwLjAyMTsiIHhtbDpzcGFjZT0icHJlc2VydmUiIGZpbGw9IiMxNEJBRTMiPgo8Zz4KCTxwYXRoIGQ9Ik0yOC42MTEsMTMuMzg1bC0xMS4wMTEsOS4zNTJjLTAuNzQ1LDAuNjMzLTEuNjY3LDAuOTQ5LTIuNTg5LDAuOTQ5Yy0wLjkyMSwwLTEuODQyLTAuMzE2LTIuNTg5LTAuOTQ5TDEuNDExLDEzLjM4NSAgIGMtMS42ODQtMS40My0xLjg5LTMuOTU0LTAuNDYtNS42MzhjMS40MzEtMS42ODQsMy45NTUtMS44OSw1LjYzOS0wLjQ1OWw4LjQyMSw3LjE1MWw4LjQyLTcuMTUxICAgYzEuNjg2LTEuNDMsNC4yMDktMS4yMjQsNS42MzksMC40NTlDMzAuNSw5LjQzMSwzMC4yOTQsMTEuOTU1LDI4LjYxMSwxMy4zODV6Ii8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+");
  background-repeat: no-repeat;
  background-position-x: 65px;
  background-position-y: 17px;
  background-size: 12px;
  padding: 15px 42px 15px 28px;
}
ssi-time-picker select:disabled {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMzAuMDIxIDMwLjAyMSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzAuMDIxIDMwLjAyMTsiIHhtbDpzcGFjZT0icHJlc2VydmUiIGZpbGw9IiNCNEJCQ0UiPgo8Zz4KCTxwYXRoIGQ9Ik0yOC42MTEsMTMuMzg1bC0xMS4wMTEsOS4zNTJjLTAuNzQ1LDAuNjMzLTEuNjY3LDAuOTQ5LTIuNTg5LDAuOTQ5Yy0wLjkyMSwwLTEuODQyLTAuMzE2LTIuNTg5LTAuOTQ5TDEuNDExLDEzLjM4NSAgIGMtMS42ODQtMS40My0xLjg5LTMuOTU0LTAuNDYtNS42MzhjMS40MzEtMS42ODQsMy45NTUtMS44OSw1LjYzOS0wLjQ1OWw4LjQyMSw3LjE1MWw4LjQyLTcuMTUxICAgYzEuNjg2LTEuNDMsNC4yMDktMS4yMjQsNS42MzksMC40NTlDMzAuNSw5LjQzMSwzMC4yOTQsMTEuOTU1LDI4LjYxMSwxMy4zODV6Ii8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+");
}
ssi-time-picker .meridian-toggle {
  display: flex;
  margin-left: 5px;
  border-radius: 10px;
}
ssi-time-picker .meridian-toggle button {
  appearance: none;
  outline: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  padding: 15px 13px;
  color: #6a759a;
  border: 1px solid #c3c9d8;
  background-color: #fff;
}
ssi-time-picker .meridian-toggle button:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-right: none;
}
ssi-time-picker .meridian-toggle button:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-left: none;
}
ssi-time-picker .meridian-toggle button.active {
  background-color: #14bae3;
  color: #fff;
}
ssi-time-picker .meridian-toggle button.active:disabled {
  background-color: #b4bbce;
}
ssi-time-picker .meridian-toggle button:not(.active) {
  cursor: pointer;
}
ssi-time-picker .meridian-toggle button:disabled {
  cursor: not-allowed;
}
ssi-time-picker .time-picker-wrap {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
ssi-time-picker .time-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  justify-items: flex-start;
}
ssi-time-picker .time-container.push-right {
  margin: 0 18px 0 0;
}
ssi-time-picker .time-container {
  position: relative;
  display: flex;
}
ssi-time-picker .time-container .select-container {
  display: flex;
  align-items: center;
}
ssi-time-picker .time-container .semicolon {
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: center;
  margin: 0 3px;
  color: #838eab;
}
ssi-time-picker .show-times-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background-color: transparent;
  border-width: 0;
  color: #14bae3;
  margin: 10px 0;
}
ssi-time-picker .show-times-button span {
  display: inline-block;
  margin: 0 8px;
  font-size: 14px;
  white-space: nowrap;
  font-weight: 900;
  text-rendering: geometricPrecision;
  text-decoration: underline;
  text-underline-offset: 3px;
}

.time-menu-container {
  background-color: #fff;
  width: 295px;
  padding: 20px;
  color: #43537f;
  border-radius: 15px;
  box-shadow: 0 5px 10px 0 rgba(16, 21, 37, 0.35);
}
.time-menu-container p {
  color: inherit;
  margin: 0;
}
.time-menu-container .time-menu-header {
  display: flex;
  align-items: center;
  margin: 0 0 5px;
}
.time-menu-container .time-menu-header i {
  font-size: 12px;
  color: inherit;
}
.time-menu-container .time-menu-header p {
  margin: 0 0 0 4px;
  font-size: 14px;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
.time-menu-container .time-menu-body p {
  line-height: 20px;
}
.time-menu-container .time-menu-body ul {
  border-top: 1px solid #c3c9d8;
  padding: 25px 0 0;
  margin: 16px -20px 0;
  max-height: 226px;
  overflow: auto;
}
.time-menu-container .time-menu-body ul + p {
  margin-top: 6px;
}
.time-menu-container .time-menu-body li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 16px 4px;
  padding: 0 16px;
  height: 36px;
  border-radius: 10px;
  cursor: pointer;
}
.time-menu-container .time-menu-body li:hover {
  background-color: #f8f9fd;
}
.time-menu-container .time-menu-body li p {
  font-size: 14px;
  font-weight: 900;
  text-rendering: geometricPrecision;
}