.report-header {
  position: fixed;
  display: flex;
  width: 100%;
  height: 94px;
  background-color: #f4f4fa;
  padding: 2em 45px 2em 228px;
  color: #101525;
  box-shadow: 0px 0px 10px 5px rgba(131, 142, 171, 0.2);
  z-index: 98;
}
.report-header h1 {
  margin: 0 0 0 40px;
  font-size: 1em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 900;
  line-height: 2.6em;
}
.report-header h2 {
  margin: 0;
  font-size: 1.4em;
  font-weight: 900;
  line-height: 1.8em;
}
.report-header .report-name {
  margin-left: auto;
  display: flex;
}
.report-header .keyword-search-input {
  display: inline-block;
  align-self: flex-start;
  background-color: white;
  border-radius: 10px;
  padding: 0.6em 0.8em;
  margin-left: 0.6em;
  border: 1px solid #c3c9d8;
  cursor: pointer;
  white-space: nowrap;
}
.report-header .keyword-search-input input {
  color: #101525;
  height: 38px;
  margin: -11px 9px -11px 0;
  min-width: 170px;
  font-weight: 700;
  border-width: 0;
  outline-width: 0;
  background-color: transparent;
}
.report-header .keyword-search-input input::placeholder {
  color: #6a759a;
}
.report-header .keyword-search-input i {
  font-weight: 700;
  color: #43537f;
}
.report-header .keyword-search-input:hover {
  border-color: #14bae3;
}
.report-header .keyword-search-input:hover i {
  border-color: #14bae3;
}
.report-header .report-date-range {
  display: inline-block;
  border-radius: 10px;
  background-color: #ebebf4;
  align-self: flex-start;
  font-weight: 700;
  padding: 0.7em 0.4em;
  margin-right: 1em;
}
.report-header .report-date-selector .control-selector {
  background-color: white;
  border-radius: 10px;
  padding: 0.6em 0.8em;
  margin-left: 0.6em;
  color: #43537f;
  border: 1px solid #c3c9d8;
  cursor: pointer;
}
.report-header .report-date-selector .control-selector:hover {
  border-color: #14bae3;
}
.report-header .report-date-selector ssi-highchart-controls {
  margin: 0;
}
.report-header .report-date-selector ssi-highchart-controls .highchart-controls-actions,
.report-header .report-date-selector ssi-highchart-controls .highchart-controls-date-wrap {
  margin: 0;
  line-height: inherit;
}
.report-header .report-date-selector ssi-highchart-controls .highchart-controls-actions .highchart-controls-action,
.report-header .report-date-selector ssi-highchart-controls .highchart-controls-actions .highchart-controls-date-picker,
.report-header .report-date-selector ssi-highchart-controls .highchart-controls-date-wrap .highchart-controls-action,
.report-header .report-date-selector ssi-highchart-controls .highchart-controls-date-wrap .highchart-controls-date-picker {
  padding: 1em 0.6em;
  margin-left: 0.6em;
  background-color: white;
  border: none;
  color: #43537f;
  border-radius: 10px;
  font-weight: 700;
  border: 1px solid white;
}
.report-header .report-date-selector ssi-highchart-controls .highchart-controls-actions .highchart-controls-action:hover,
.report-header .report-date-selector ssi-highchart-controls .highchart-controls-actions .highchart-controls-date-picker:hover,
.report-header .report-date-selector ssi-highchart-controls .highchart-controls-date-wrap .highchart-controls-action:hover,
.report-header .report-date-selector ssi-highchart-controls .highchart-controls-date-wrap .highchart-controls-date-picker:hover {
  border-color: #14bae3;
}
.report-header .report-date-selector ssi-highchart-controls .highchart-controls-actions .highchart-controls-action.highchart-controls-action-active,
.report-header .report-date-selector ssi-highchart-controls .highchart-controls-actions .highchart-controls-date-picker.highchart-controls-action-active,
.report-header .report-date-selector ssi-highchart-controls .highchart-controls-date-wrap .highchart-controls-action.highchart-controls-action-active,
.report-header .report-date-selector ssi-highchart-controls .highchart-controls-date-wrap .highchart-controls-date-picker.highchart-controls-action-active {
  border-color: #14bae3;
  box-shadow: 0px 5px 10px 0px rgba(131, 142, 171, 0.35);
}
.report-header .report-date-selector ssi-highchart-controls .highchart-controls-actions .highchart-controls-date-toggle i,
.report-header .report-date-selector ssi-highchart-controls .highchart-controls-date-wrap .highchart-controls-date-toggle i {
  background-color: white;
  border-radius: 10px;
  padding: 0.72em 1em;
  color: #43537f;
  line-height: inherit;
}
.report-header .report-date-selector ssi-highchart-controls .highchart-controls-actions .highchart-controls-date-picker,
.report-header .report-date-selector ssi-highchart-controls .highchart-controls-date-wrap .highchart-controls-date-picker {
  padding: 0;
  max-width: auto;
  min-width: auto;
}
.report-header .report-date-selector ssi-highchart-controls .highchart-controls-actions .highchart-controls-date-picker input,
.report-header .report-date-selector ssi-highchart-controls .highchart-controls-date-wrap .highchart-controls-date-picker input {
  max-width: 120px;
  padding: 0.72em 0.6em;
  font-style: normal;
  color: #43537f;
  font-weight: 700;
  border-radius: 10px;
}
.report-header .report-date-selector ssi-highchart-controls .highchart-controls-actions .highchart-controls-date-picker input ::placeholder,
.report-header .report-date-selector ssi-highchart-controls .highchart-controls-date-wrap .highchart-controls-date-picker input ::placeholder {
  color: #43537f;
}
.report-header .report-date-selector ssi-highchart-controls .highchart-controls-actions .highchart-controls-date-picker input:focus,
.report-header .report-date-selector ssi-highchart-controls .highchart-controls-date-wrap .highchart-controls-date-picker input:focus {
  border-radius: 10px;
}
.report-header .report-date-selector ssi-highchart-controls .highchart-controls-actions .highchart-controls-date-picker i,
.report-header .report-date-selector ssi-highchart-controls .highchart-controls-date-wrap .highchart-controls-date-picker i {
  font-size: 0.8em;
  margin: 1.25em 0;
  font-weight: 900;
  right: 7px;
}