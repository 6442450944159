ssi-widget-positive-negative-themes {
  display: flex;
  justify-content: center;
  font-size: 1rem;
  overflow: hidden;
  padding: 20px;
}
ssi-widget-positive-negative-themes .insights-widget {
  overflow: auto;
  height: 100%;
}
ssi-widget-positive-negative-themes .theme {
  display: inline-flex;
  align-items: center;
  margin-bottom: 2px;
  margin-right: 2px;
  border-radius: 5px;
}
ssi-widget-positive-negative-themes .theme span,
ssi-widget-positive-negative-themes .theme ssi-big-number {
  color: #101525;
  font-size: 12px;
  font-weight: 900;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  padding: 6px;
}
ssi-widget-positive-negative-themes .theme:hover {
  cursor: pointer;
}
ssi-widget-positive-negative-themes .theme.positive {
  background-color: #0dcdc2;
}
ssi-widget-positive-negative-themes .theme.negative {
  color: #fff;
  background-color: #f05230;
}
ssi-widget-positive-negative-themes .no-results-state {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}
ssi-widget-positive-negative-themes .no-results-state i {
  color: #b4bbce;
  font-size: 40px;
}
ssi-widget-positive-negative-themes .no-results-state h4 {
  margin-top: 10px;
  color: #43537f;
  font-weight: 900;
  font-size: 20px;
}
ssi-widget-positive-negative-themes .no-results-state p {
  color: #43537f;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}