ssi-email-to-modal .email-to {
  background-color: rgba(248, 249, 253, 0.2);
  border-radius: 25px;
}
ssi-email-to-modal .header {
  width: 80%;
  padding: 2em 4em;
  position: relative;
}
ssi-email-to-modal .header img {
  width: 12%;
  height: 12%;
  margin: 1.6em 0;
}
ssi-email-to-modal .header button {
  margin-top: 1.6em;
}
ssi-email-to-modal .header h2 {
  color: #43537f;
  font-size: 1.4em;
  font-weight: 800;
}
ssi-email-to-modal .header p {
  margin: 0;
  font-size: 14px;
  color: #838eab;
  font-weight: 500;
}
ssi-email-to-modal .header i {
  position: absolute;
  font-size: 1.4em;
  color: #b4bbce;
  left: 30px;
  top: 28px;
}
ssi-email-to-modal .exit {
  position: absolute;
  right: 15px;
  top: 15px;
  background-color: #eff2f8;
  border-radius: 100%;
  cursor: pointer;
}
ssi-email-to-modal .exit i {
  display: inline-block;
  padding: 10px;
  color: #838eab;
}
ssi-email-to-modal .scroll-container {
  margin: 0 4em;
  padding: 0 0 2em 0;
  max-height: 280px;
  overflow-y: scroll;
  background: linear-gradient(white 30%, hsla(0deg, 0%, 100%, 0)), linear-gradient(hsla(0deg, 0%, 100%, 0) 10px, white 70%) bottom, radial-gradient(at top, rgba(0, 0, 0, 0.1), transparent 70%), radial-gradient(at bottom, rgba(0, 0, 0, 0.1), transparent 70%) bottom;
  background-repeat: no-repeat;
  background-size: 100% 20px, 100% 20px, 100% 7px, 100% 7px;
  background-attachment: local, local, scroll, scroll;
}
ssi-email-to-modal .message-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 2em 0 0;
  font-size: 1rem;
  cursor: pointer;
}
ssi-email-to-modal .message-box.message-box-self-penned {
  flex-direction: row-reverse;
  padding-right: 3em;
}
ssi-email-to-modal .message-box.message-box-self-penned .message-box-message-wrap {
  flex-direction: row-reverse;
}
ssi-email-to-modal .message-box.message-box-self-penned .message-box-message {
  margin: 0 0.4em 0 0;
  background-color: #e7eefd;
}
ssi-email-to-modal .message-box.selected .message-box-message {
  border-color: #14bae3;
}
ssi-email-to-modal .message-box-container {
  align-items: flex-start;
  flex: 0 0 auto;
  max-width: 100%;
}
ssi-email-to-modal .message-box-message-wrap {
  display: flex;
}
ssi-email-to-modal .message-box-avatar-wrap {
  display: inline-flex;
  flex: 0 0 4.6em;
  align-items: center;
  justify-content: center;
  height: 4.6em;
  width: 4.6em;
  background-color: #9db0db;
  background-size: cover;
  color: #fff;
  border-radius: 50%;
  overflow: hidden;
}
ssi-email-to-modal .message-box-avatar-wrap.message-box-avatar-actionable {
  cursor: pointer;
}
ssi-email-to-modal .message-box-avatar-wrap.message-box-avatar-actionable:hover {
  background-image: none;
}
ssi-email-to-modal .message-box-avatar-wrap.message-box-avatar-actionable:hover .message-box-avatar {
  display: none;
}
ssi-email-to-modal .message-box-avatar-wrap.message-box-avatar-actionable:hover .message-box-avatar-actionable-button {
  display: flex;
}
ssi-email-to-modal .message-box-avatar-wrap .message-box-avatar-actionable-button {
  display: none;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: #fff;
  background-color: #14bae3;
}
ssi-email-to-modal .message-box-avatar-wrap .message-box-avatar-actionable-button.message-box-avatar-actionable-button-show {
  display: flex;
}
ssi-email-to-modal .message-box-avatar-wrap .message-box-avatar-actionable-button i {
  font-size: 2.4em;
}
ssi-email-to-modal .message-box-avatar {
  width: 100%;
  height: 100%;
}
ssi-email-to-modal .message-box-message {
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  flex-flow: column;
  margin: 0 10em 0 0.4em;
  background-color: #fff;
  border-radius: 15px;
  padding: 1em 2em;
  box-shadow: 0 3px 10px 0 rgba(131, 142, 171, 0.25);
  font-size: 1.3rem;
  font-weight: 700;
  color: #101525;
  border: 2px solid transparent;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  ssi-email-to-modal .message-box-message {
    width: calc(100% - 5em);
  }
}
ssi-email-to-modal .send-to-container {
  padding: 2em 4em;
  background-color: white;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
  border-top: 2px solid #ecf0fb;
}
ssi-email-to-modal .send-to-container input,
ssi-email-to-modal .send-to-container textarea {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #101525 !important;
}
ssi-email-to-modal .send-to-container .inline-label {
  display: inline;
  padding: 0;
}
ssi-email-to-modal .send-to-container .buttons {
  text-align: right;
}
ssi-email-to-modal .send-to-container .buttons button {
  padding-right: 0;
}
ssi-email-to-modal .send-to-container .buttons button:disabled {
  color: #b4bbce !important;
}
ssi-email-to-modal .send-to-container .buttons button span {
  font-size: 10px;
  font-weight: 900 !important;
  letter-spacing: 1px;
}
ssi-email-to-modal .send-to-container .recent-email-list {
  display: flex;
  flex-direction: row-reverse;
}
ssi-email-to-modal .send-to-container .recent-email-list .recent-email {
  background-color: #f8f9fd;
  padding: 0.4em 1em;
  margin: 0 0.4em;
  font-size: 0.8em;
  font-weight: 900;
  border-radius: 15px;
  color: #43537f;
  cursor: pointer;
}
ssi-email-to-modal .send-to-container .recent-email-list .recent-email i {
  padding-right: 4px;
  color: #838eab;
}
ssi-email-to-modal .footer {
  text-align: center;
}
ssi-email-to-modal .footer * {
  margin: 1em auto;
  display: block;
}