ssi-cl-dropdown-select-f .cl-dropdown-select-f-container .accounts-select-container {
  position: relative;
  width: 40px;
}
ssi-cl-dropdown-select-f .cl-dropdown-select-f-container .accounts-select-container .accounts-selector-btn {
  width: 40px;
  height: 40px;
  background-color: #707f8b;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
ssi-cl-dropdown-select-f .cl-dropdown-select-f-container .accounts-select-container .accounts-selector-btn .ssi-tags {
  color: #fff;
  font-size: 28px;
  font-weight: 500;
}
ssi-cl-dropdown-select-f .cl-dropdown-select-f-container .accounts-select-container .accounts-selector-btn:hover {
  background-color: #59656f;
}
ssi-cl-dropdown-select-f .cl-dropdown-select-f-container .accounts-select-container .selected-accounts-count {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 18px;
  height: 18px;
  border: 1px solid #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #707f8b;
}
ssi-cl-dropdown-select-f .cl-dropdown-select-f-container .accounts-select-container .selected-accounts-count span {
  font-size: 10px;
  color: #fff;
  font-weight: 700;
}
ssi-cl-dropdown-select-f .cl-dropdown-select-f-container .accounts-select-container .no-accounts-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 0 10px;
}
ssi-cl-dropdown-select-f .cl-dropdown-select-f-container .accounts-select-container .no-accounts-state i {
  margin-top: 20px;
  color: #b4bbce;
  font-size: 30px;
}
ssi-cl-dropdown-select-f .cl-dropdown-select-f-container .accounts-select-container .no-accounts-state .title {
  margin-top: 15px;
  color: #43537f;
  font-size: 16px;
  font-weight: 900;
}
ssi-cl-dropdown-select-f .cl-dropdown-select-f-container .accounts-select-container .no-accounts-state .desc {
  margin-bottom: 20px;
  color: #43537f;
  font-size: 14px;
  font-weight: 700;
  padding: 0 24px;
  text-align: center;
}
ssi-cl-dropdown-select-f .cl-dropdown-select-f-container .accounts-select-container .no-accounts-state button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  width: 100%;
}
ssi-cl-dropdown-select-f .cl-dropdown-select-f-container .accounts-select-container .no-accounts-state button i {
  margin: 0;
  margin-left: 10px;
  font-size: 12px;
  color: #fff;
}
ssi-cl-dropdown-select-f .cl-dropdown-select-f-container .accounts-select-container .no-accounts-state button span {
  white-space: nowrap;
}