ssi-analytics ssi-marketing ssi-report ssi-post-engagement .post-engagement-container .section-body {
  padding: 70px 30px 70px 30px;
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  display: flex;
}
@media print {
  ssi-analytics ssi-marketing ssi-report ssi-post-engagement .post-engagement-container .section-body {
    page-break-inside: avoid;
  }
}
ssi-analytics ssi-marketing ssi-report ssi-post-engagement .post-engagement-container .section-body ssi-highchart {
  width: 100%;
  height: 365px;
}
ssi-analytics ssi-marketing ssi-report ssi-post-engagement .post-engagement-container .section-body ssi-highchart highcharts-chart .highcharts-container,
ssi-analytics ssi-marketing ssi-report ssi-post-engagement .post-engagement-container .section-body ssi-highchart highcharts-chart .highcharts-root {
  overflow: visible !important;
}
ssi-analytics ssi-marketing ssi-report ssi-post-engagement .post-engagement-container .section-body ssi-highchart highcharts-chart .highcharts-axis-title {
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: nowrap;
}
ssi-analytics ssi-marketing ssi-report ssi-post-engagement .post-engagement-container .section-body ssi-highchart highcharts-chart .highcharts-axis-labels text {
  font-weight: 900;
}
ssi-analytics ssi-marketing ssi-report ssi-post-engagement .post-engagement-container .section-body ssi-highchart highcharts-chart .highcharts-xaxis-labels {
  transform: translate(0, 1em);
}
ssi-analytics ssi-marketing ssi-report ssi-post-engagement .post-engagement-container .section-body .cards-container {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  height: 280px;
  width: 260px;
  margin-left: 20px;
}
ssi-analytics ssi-marketing ssi-report ssi-post-engagement .post-engagement-container .section-body .cards-container .card {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.35);
}
ssi-analytics ssi-marketing ssi-report ssi-post-engagement .post-engagement-container .section-body .cards-container .card:not(:first-child) {
  margin-top: 20px;
}
ssi-analytics ssi-marketing ssi-report ssi-post-engagement .post-engagement-container .section-body .cards-container .card > *:not(:first-child) {
  margin-top: 10px;
}
ssi-analytics ssi-marketing ssi-report ssi-post-engagement .post-engagement-container .section-body .cards-container .card .title {
  color: #838eab;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
}
ssi-analytics ssi-marketing ssi-report ssi-post-engagement .post-engagement-container .section-body .cards-container .card .break-line {
  height: 4px;
  width: 40px;
  border-radius: 100px;
  background-color: #d6dff7;
}
ssi-analytics ssi-marketing ssi-report ssi-post-engagement .post-engagement-container .section-body .cards-container .card .count {
  color: #101525;
  font-size: 20px;
  font-weight: 900;
}