ssi-widget-marketing-posts {
  height: 100%;
  width: 100%;
}
ssi-widget-marketing-posts .dashboard-widget {
  position: relative;
  height: 100%;
  width: 100%;
}
ssi-widget-marketing-posts .dashboard-widget .form-field {
  padding: 10px;
  border-bottom: 1px solid #c3c9d8;
  font-weight: 900;
}
ssi-widget-marketing-posts .dashboard-widget .form-field .label-clear-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
}
ssi-widget-marketing-posts .dashboard-widget .form-field label.field-title {
  font-size: 10px;
  line-height: 12px;
  padding-left: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #43537f;
  font-weight: 900;
}
ssi-widget-marketing-posts .dashboard-widget .form-field span.right {
  flex: 1 1 auto;
  padding-right: 20px;
  font-size: 14px;
  color: #14bae3;
  white-space: nowrap;
}
ssi-widget-marketing-posts .dashboard-widget .form-field span.right button.last-month-only-btn {
  border: 0;
  background-color: #ffffff;
  text-decoration: underline;
}
ssi-widget-marketing-posts .dashboard-widget .form-field span.right .italic {
  font-style: italic;
  font-size: 15px;
}
ssi-widget-marketing-posts .dashboard-widget ssi-dropdown-select-2 {
  width: 100%;
}
ssi-widget-marketing-posts .dashboard-widget ssi-dropdown-select-2 ssi-social-network-icon {
  margin-right: -8px;
}
ssi-widget-marketing-posts .dashboard-widget ssi-dropdown-select-2 .dd-head {
  width: 100%;
}
ssi-widget-marketing-posts .dashboard-widget ssi-dropdown-select-2 .dd-body {
  width: 100%;
}
ssi-widget-marketing-posts .dashboard-widget ssi-dropdown-select-2 .select-accounts-label {
  display: flex;
  align-items: center;
}
ssi-widget-marketing-posts .dashboard-widget ssi-dropdown-select-2 .select-accounts-label span {
  padding-left: 12px;
  color: #101525;
  font-weight: 900;
}
ssi-widget-marketing-posts .dashboard-widget .message-info {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-align: center;
}
ssi-widget-marketing-posts .dashboard-widget .message-info i {
  color: #b4bbce;
  font-size: 37.5px;
}
ssi-widget-marketing-posts .dashboard-widget .message-info h5 {
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
  color: #43537f;
}
ssi-widget-marketing-posts .dashboard-widget .message-info p {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  max-width: 222px;
  color: #43537f;
}
ssi-widget-marketing-posts .dashboard-widget ssi-outbox-post-list .panel {
  margin: 5px 10px 20px 10px;
}