ssi-inline-note,
ssi-inline-add-note {
  font-size: 1rem;
  display: block;
  width: 100%;
}
ssi-inline-note .inline-note-container,
ssi-inline-add-note .inline-note-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin: 0.4em 0 0;
  width: 100%;
}
ssi-inline-note .inline-note-container-public .inline-note,
ssi-inline-add-note .inline-note-container-public .inline-note {
  margin: 0 0 0 0.4em;
}
ssi-inline-note .inline-note-left,
ssi-inline-add-note .inline-note-left {
  position: relative;
  display: inline-flex;
  flex: 0 0 4.6em;
  flex-direction: column;
}
ssi-inline-note .inline-notes-avatar-wrap,
ssi-inline-add-note .inline-notes-avatar-wrap {
  display: flex;
  flex: 0 0 4.6em;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 4.6em;
  width: 4.6em;
  background-color: #89a4ea;
  border-radius: 50%;
}
ssi-inline-note .inline-notes-avatar-wrap.inline-notes-avatar-wrap-pointer,
ssi-inline-add-note .inline-notes-avatar-wrap.inline-notes-avatar-wrap-pointer {
  cursor: pointer;
}
ssi-inline-note .inline-notes-avatar-icon,
ssi-inline-add-note .inline-notes-avatar-icon {
  font-size: 2em;
  color: #fff;
}
ssi-inline-note .inline-notes-avatar-badge,
ssi-inline-add-note .inline-notes-avatar-badge {
  position: absolute;
  top: -0.1em;
  right: -0.3em;
  background-color: #668aea;
  height: 1.6em;
  width: 1.6em;
  color: #fff;
  font-size: 1em;
  box-shadow: 0 1em 3.5em rgba(231, 232, 238, 0.65);
  border-radius: 1.6em;
  text-align: center;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-inline-note .inline-notes-avatar-guide,
ssi-inline-add-note .inline-notes-avatar-guide {
  position: absolute;
  top: 5em;
  left: 0;
  right: 0;
  margin: auto;
  display: block;
  height: calc(100% - 5em);
  width: 0.2em;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAABQBAMAAADMwMmsAAAAFVBMVEVHcEzAzvKJpeq+zu+Jo+rBz/KJpOolcxqSAAAABXRSTlMA1+ovYfU0u0QAAAAlSURBVAjXY3BSYVBLAiEgAxcwFGYQDWQIDSUNAbUANeICA2QvAC2bG6Gl/VMHAAAAAElFTkSuQmCC");
  background-size: 0.2em 4em;
  background-repeat: repeat;
}
ssi-inline-note .inline-note-right,
ssi-inline-add-note .inline-note-right {
  display: flex;
  align-items: flex-start;
  flex: 1 0 auto;
  max-width: 100%;
}
ssi-inline-note .inline-note-wrap,
ssi-inline-add-note .inline-note-wrap {
  position: relative;
  display: inline-flex;
  flex: 0 1 auto;
  flex-direction: column;
  width: 100%;
  max-width: 41em;
  margin: 0 0 0.6em;
}
ssi-inline-note .inline-note,
ssi-inline-add-note .inline-note {
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  flex-flow: column;
  overflow: hidden;
  margin: 0 0 0 1.4em;
  background-color: #668aea;
  border: 0.2em solid #668aea;
  border-radius: 1em;
}
ssi-inline-note .inline-add-note,
ssi-inline-add-note .inline-add-note {
  overflow: visible;
  border: 0;
}
ssi-inline-note .inline-add-note ssi-add-note,
ssi-inline-add-note .inline-add-note ssi-add-note {
  background: #fff;
  border: 0.2em solid #668aea;
  border-radius: 1em;
}
ssi-inline-note .inline-add-note ssi-add-note .add-note-input-wrap,
ssi-inline-add-note .inline-add-note ssi-add-note .add-note-input-wrap {
  margin: 0;
}
ssi-inline-note .inline-add-note ssi-add-note .add-note-input-label,
ssi-inline-note .inline-add-note ssi-add-note .notes-tag,
ssi-inline-add-note .inline-add-note ssi-add-note .add-note-input-label,
ssi-inline-add-note .inline-add-note ssi-add-note .notes-tag {
  height: 11rem;
  padding: 1.9rem 1.6rem 0 2.1rem;
  margin: 0;
  line-height: 2.4rem;
  font-size: 1.4em;
}
ssi-inline-note .inline-add-note ssi-add-note .notes-tag,
ssi-inline-add-note .inline-add-note ssi-add-note .notes-tag {
  width: auto;
  height: 9.1rem;
  padding: 0;
  margin: 1.9rem 1.6rem 0 2.1rem;
  line-height: 2.2rem;
  font-size: 1.4em;
}
ssi-inline-note .inline-add-note ssi-add-note .add-note-input,
ssi-inline-add-note .inline-add-note ssi-add-note .add-note-input {
  font-size: inherit;
  line-height: inherit;
  color: #101525;
}
ssi-inline-note .inline-add-note ssi-add-note .add-note-input::placeholder,
ssi-inline-add-note .inline-add-note ssi-add-note .add-note-input::placeholder {
  color: #838eab;
}
ssi-inline-note .inline-add-note ssi-add-note .add-note-actions,
ssi-inline-add-note .inline-add-note ssi-add-note .add-note-actions {
  margin: 1.3em 1.4em 1.3em 0;
}
ssi-inline-note .inline-note-body,
ssi-inline-add-note .inline-note-body {
  padding: 1.9em 1.6em 0.8em 2.1em;
  line-height: 2.4em;
}
ssi-inline-note .inline-note-body a,
ssi-inline-add-note .inline-note-body a {
  color: #fff;
  text-decoration: underline;
}
ssi-inline-note .inline-note-body p,
ssi-inline-add-note .inline-note-body p {
  font-size: 1.4em;
  line-height: inherit;
  color: #fff;
  font-weight: 700;
  text-rendering: geometricPrecision;
  word-break: break-word;
  hyphens: auto;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  ssi-inline-note .inline-note-body p,
ssi-inline-add-note .inline-note-body p {
    word-break: break-all;
  }
}
ssi-inline-note .inline-note-footer,
ssi-inline-add-note .inline-note-footer {
  background-color: #e7edfd;
  padding: 0.8em 1.1em 1.1em 1.9em;
}
ssi-inline-note .inline-note-footer-add,
ssi-inline-add-note .inline-note-footer-add {
  display: flex;
  align-items: center;
  float: right;
  color: #43537f;
  cursor: pointer;
}
ssi-inline-note .inline-note-footer-add p,
ssi-inline-add-note .inline-note-footer-add p {
  display: inline-block;
  font-size: 1.2em;
  font-weight: 900;
  margin: 0;
  text-rendering: geometricPrecision;
}
ssi-inline-note .inline-note-footer-delete,
ssi-inline-add-note .inline-note-footer-delete {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  color: #43537f;
  height: 2em;
  width: 1.3em;
  cursor: pointer;
  transition: all 100ms ease;
  margin: 0 1em 0 0;
}
ssi-inline-note .inline-note-footer-delete p,
ssi-inline-add-note .inline-note-footer-delete p {
  display: none;
  margin: 0;
  color: #fff;
  font-size: 1.2em;
  font-weight: 900;
  white-space: nowrap;
  text-rendering: geometricPrecision;
}
ssi-inline-note .inline-note-footer-delete .ssi,
ssi-inline-add-note .inline-note-footer-delete .ssi {
  font-size: 1.2em;
}
ssi-inline-note .inline-note-footer-delete:hover, ssi-inline-note .inline-note-footer-delete:focus,
ssi-inline-add-note .inline-note-footer-delete:hover,
ssi-inline-add-note .inline-note-footer-delete:focus {
  color: #fff;
  background-color: #f88c68;
  border-radius: 10em;
  width: 9.6em;
  padding: 0 0.8em;
  margin: 0 1em 0 -0.8em;
}
ssi-inline-note .inline-note-footer-delete:hover p, ssi-inline-note .inline-note-footer-delete:focus p,
ssi-inline-add-note .inline-note-footer-delete:hover p,
ssi-inline-add-note .inline-note-footer-delete:focus p {
  display: inline-block;
}
ssi-inline-note .inline-note-footer-delete:hover ~ .inline-note-footer-add p, ssi-inline-note .inline-note-footer-delete:focus ~ .inline-note-footer-add p,
ssi-inline-add-note .inline-note-footer-delete:hover ~ .inline-note-footer-add p,
ssi-inline-add-note .inline-note-footer-delete:focus ~ .inline-note-footer-add p {
  display: none;
}
ssi-inline-note .inline-note-footer-meta,
ssi-inline-add-note .inline-note-footer-meta {
  display: inline-block;
  color: #43537f;
  padding: 0;
  margin: 0;
}
ssi-inline-note .inline-note-footer-meta li,
ssi-inline-add-note .inline-note-footer-meta li {
  display: inline-flex;
  align-items: center;
  height: 2em;
}
ssi-inline-note .inline-note-footer-meta li:after,
ssi-inline-add-note .inline-note-footer-meta li:after {
  content: "|";
  font-weight: 900;
  display: inline-block;
  margin: 0 0.3em;
}
ssi-inline-note .inline-note-footer-meta li:last-child:after,
ssi-inline-add-note .inline-note-footer-meta li:last-child:after {
  content: normal;
}
ssi-inline-note .inline-note-footer-meta p,
ssi-inline-add-note .inline-note-footer-meta p {
  display: inline-block;
  font-size: 1em;
  font-weight: 900;
  margin: 0;
  text-rendering: geometricPrecision;
  overflow-x: hidden;
}
ssi-inline-note .inline-note-footer-meta .inline-note-footer-concat p,
ssi-inline-add-note .inline-note-footer-meta .inline-note-footer-concat p {
  max-width: 13em;
  white-space: nowrap;
  text-overflow: ellipsis;
}
ssi-inline-note .inline-note-footer-add-icon,
ssi-inline-add-note .inline-note-footer-add-icon {
  display: inline-flex;
  flex: 0 0 auto;
  height: 2em;
  width: 2em;
  margin: 0 0 0 0.5em;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
}
ssi-inline-note .inline-note-footer-add-icon .ssi,
ssi-inline-add-note .inline-note-footer-add-icon .ssi {
  font-size: 0.8em;
}
ssi-inline-note .inline-note-container-inverse,
ssi-inline-add-note .inline-note-container-inverse {
  flex-direction: row-reverse;
}
ssi-inline-note .inline-note-container-inverse .inline-note-right,
ssi-inline-add-note .inline-note-container-inverse .inline-note-right {
  justify-content: flex-end;
}
ssi-inline-note .inline-note-container-inverse .inline-note,
ssi-inline-add-note .inline-note-container-inverse .inline-note {
  margin: 0 1.4em 0 0;
}