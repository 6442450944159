@charset "UTF-8";
ssi-inbox-sidebar .wide-version,
ssi-inbox-sidebar .slim-version {
  height: 100%;
}
ssi-inbox-sidebar .wide-version {
  display: flex;
}
ssi-inbox-sidebar .slim-version {
  display: none;
}
ssi-inbox-sidebar .wide-version {
  width: 160px;
  min-width: 160px;
  flex: 1;
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
  display: flex;
  flex-direction: column;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-results-count {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0px;
  text-transform: uppercase;
  color: #596065;
  background-color: #f8f8f8;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-inner {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #3f3f49;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-inner a {
  font-size: 1em;
  font-weight: 600;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-inner a span {
  font-size: 1em;
  font-weight: 600;
}
ssi-inbox-sidebar .wide-version a {
  color: white;
}
ssi-inbox-sidebar .wide-version a:hover,
ssi-inbox-sidebar .wide-version a:focus {
  text-decoration: none;
  color: white;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item.border-top {
  border-top: solid 1px rgba(255, 255, 255, 0.24);
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item {
  padding: 14px 28px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item i {
  font-size: 12px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages {
  padding: 20px 10px;
  position: relative;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages.margin-bottom {
  margin-bottom: 10px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages-assigned {
  justify-content: flex-end;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages-filtered {
  border-right: solid 2px #545767;
  justify-content: flex-end;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages-total {
  border-bottom: solid 2px #545767;
  border-right: solid 2px #545767;
  justify-content: flex-start;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages-unread {
  border-bottom: solid 2px #545767;
  justify-content: flex-start;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages ul {
  display: flex;
  flex-flow: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages ul li {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 55px;
  padding: 0;
  width: 50%;
  color: #838eab;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages ul li.active .count {
  color: #14bae3;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages ul li.active .label {
  color: #fff;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages ul li .count {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 2px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages ul li .label {
  color: inherit;
  font-size: 1em;
  font-weight: 600;
  padding: 0;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages button {
  background: #545767;
  border: solid 6px #3f4142;
  border-radius: 50%;
  color: #14bae3;
  left: 58px;
  outline: none;
  padding: 6px;
  position: absolute;
  top: 47px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages button .refresh-icon {
  font-size: 2.4em;
}
ssi-inbox-sidebar .wide-version a.inbox-sidebar-item {
  background-color: #545767;
  border-radius: 28px;
  margin-left: 14px;
  margin-right: 14px;
  padding: 7px 14px;
}
ssi-inbox-sidebar .wide-version a.inbox-sidebar-item.inbox-sidebar-item-bg-primary {
  background-color: #14bae3;
}
ssi-inbox-sidebar .wide-version a.inbox-sidebar-item.inbox-sidebar-item-bg-primary:hover {
  background-color: #25c4ec;
}
ssi-inbox-sidebar .wide-version a.inbox-sidebar-item.inbox-sidebar-item-btn-list-filters {
  background-color: #14bae3;
  color: #fff;
}
ssi-inbox-sidebar .wide-version a.inbox-sidebar-item.inbox-sidebar-item-btn-list-filters-clear {
  background-color: #f88c68;
}
ssi-inbox-sidebar .wide-version a.inbox-sidebar-item.inbox-sidebar-item-conversations-button {
  background-color: #545767;
  box-shadow: #303030 0 0 16px 0px;
  color: #838eab;
}
ssi-inbox-sidebar .wide-version a.inbox-sidebar-item.inbox-sidebar-item-conversations-button:hover {
  color: #fff;
}
ssi-inbox-sidebar .wide-version a.inbox-sidebar-item.inbox-sidebar-item-conversations-button.active > .button-icon {
  color: #14bae3;
}
ssi-inbox-sidebar .wide-version a.inbox-sidebar-item.inbox-sidebar-item-conversations-button.active > .button-text {
  color: #fff;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-filters-list,
ssi-inbox-sidebar .wide-version .inbox-sidebar-active-preset {
  margin: 0 10px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-filters-list > ul,
ssi-inbox-sidebar .wide-version .inbox-sidebar-active-preset > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-filters-list > ul > li,
ssi-inbox-sidebar .wide-version .inbox-sidebar-active-preset > ul > li {
  align-items: center;
  background-color: #545767;
  border-radius: 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  list-style: none;
  margin: 10px 0;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-filters-list > ul > li .btn-edit,
ssi-inbox-sidebar .wide-version .inbox-sidebar-active-preset > ul > li .btn-edit {
  background-color: #14bae3;
  border-radius: 20px;
  padding: 5px;
  color: #fff;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-filters-list > ul > li .filter-label,
ssi-inbox-sidebar .wide-version .inbox-sidebar-filters-list > ul > li .preset-label,
ssi-inbox-sidebar .wide-version .inbox-sidebar-active-preset > ul > li .filter-label,
ssi-inbox-sidebar .wide-version .inbox-sidebar-active-preset > ul > li .preset-label {
  color: #fff;
  overflow: hidden;
  padding: 0 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 98px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-filters-list > ul > li .btn-remove,
ssi-inbox-sidebar .wide-version .inbox-sidebar-active-preset > ul > li .btn-remove {
  color: #f0d9d1;
  margin-right: 8px;
  padding: 5px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-filters-list > ul > li .btn-remove .ssi,
ssi-inbox-sidebar .wide-version .inbox-sidebar-filters-list > ul > li .btn-remove .fa,
ssi-inbox-sidebar .wide-version .inbox-sidebar-active-preset > ul > li .btn-remove .ssi,
ssi-inbox-sidebar .wide-version .inbox-sidebar-active-preset > ul > li .btn-remove .fa {
  font-size: 14px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-active-preset > ul > li {
  background-color: #14bae3;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-active-preset > ul > li .btn-remove {
  color: #fff;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-custom-presets-toggle {
  position: relative;
  top: 12px;
  z-index: 1;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list {
  background-color: #35353f;
  padding: 28px 0 20px 0;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list > ul {
  margin: 0 20px;
  padding: 0;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list > ul > li {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  padding: 0;
  font-size: 12px;
  color: #838eab;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list > ul > li.selected .btn-edit,
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list > ul > li.selected span {
  color: #fff;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list > ul > li.selected .preset-label .ssi,
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list > ul > li.selected .preset-label .fa {
  color: #14bae3;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list > ul > li .preset-label {
  align-items: center;
  display: flex;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list > ul > li .preset-label > span {
  overflow: hidden;
  padding-left: 5px;
  padding-right: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 96px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list > ul > li .preset-label .ssi,
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list > ul > li .preset-label .fa {
  margin-right: 2px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list > ul > li a {
  color: #838eab;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list > ul > li a i {
  color: #545767;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-presets-list {
  margin-top: 30px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-presets-list > ul {
  margin: 20px;
  padding: 0;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-presets-list > ul > li {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  padding: 0;
  color: #838eab;
  font-size: 12px;
  font-weight: 600;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-presets-list > ul > li.selected {
  color: #fff;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-presets-list > ul > li.selected .fa {
  color: #14bae3;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-presets-list > ul > li .ssi,
ssi-inbox-sidebar .wide-version .inbox-sidebar-presets-list > ul > li .fa {
  color: #545767;
  margin-right: 2px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-spacing-top {
  margin-top: 3px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-spacing-bottom {
  margin-bottom: 3px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bg-light {
  background-color: #545767;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bg-dark {
  background-color: #363434;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bg-primary {
  background-color: #14bae3;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bg-light-hover:hover {
  background-color: #5f6375;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bg-dark-hover:hover {
  background-color: #292727;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bg-primary-hover:hover {
  background-color: #25c4ec;
}
ssi-inbox-sidebar .wide-version a.inbox-sidebar-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-top {
  flex: 1;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-top .bulk-actions-menu {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  list-style: none;
  margin: 20px 0 0 0;
  padding: 0;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-top .bulk-actions-menu * {
  display: flex;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-top .bulk-actions-menu > li {
  flex-shrink: 0;
  flex-grow: 1;
  margin: 5px 0;
  padding: 0 10px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-top .bulk-actions-menu > li > * {
  flex-grow: 1;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-top .bulk-actions-menu > li > button, ssi-inbox-sidebar .wide-version .inbox-sidebar-top .bulk-actions-menu > li > dl > dt {
  align-items: center;
  border-radius: 20px;
  justify-content: space-between;
  color: #fff;
  font-weight: 600;
  padding: 5px 13px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-top .bulk-actions-menu > li > button {
  border: none;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-top .bulk-actions-menu > li > dl {
  flex-flow: column-reverse nowrap;
  flex-grow: 1;
  margin: 0;
  padding: 0;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-top .bulk-actions-menu > li > dl > dt {
  margin: 0;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-top .bulk-actions-menu > li > dl > dd {
  color: #fff;
  display: block;
  line-height: 1.8em;
  margin: 0;
  padding: 0 10px 12px 10px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-top .bulk-actions-menu > li > dl > dd > * {
  display: inline;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-icon-left {
  padding-left: 5px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-filter-remove {
  position: relative;
  top: -1px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-filters li:not(:last-child) {
  margin-bottom: 10px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-padding-sides-sm {
  padding-left: 10px;
  padding-right: 8px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-text {
  display: inline-block;
  width: calc(100% - 40px);
  word-wrap: break-word;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-active {
  background-color: #14bae3;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-deselect {
  background-color: #f88c68;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-deselect:hover {
  background-color: #f77950;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-deselect span {
  flex-grow: 1;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-assign {
  background-color: #f0b427;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-assign:hover {
  background-color: #edab10;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-sentiment {
  background-color: #d41d68;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-sentiment:hover {
  background-color: #be1a5d;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-tag {
  background-color: #668aea;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-tag:hover {
  background-color: #5079e7;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-spam {
  background-color: #545767;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-spam:hover {
  opacity: 0.8;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-tip dt {
  background-color: #545767;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-tip i {
  color: #14bae3;
}
ssi-inbox-sidebar .wide-version i.ssi-users-plus {
  font-size: 16px;
}
ssi-inbox-sidebar .wide-version i.ssi-sentiment-menu-icon {
  font-size: 13px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-add-filter.active {
  background-color: #14bae3;
  color: #fff;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-btn {
  display: block;
  background-color: #425dec !important;
  box-shadow: #303030 0 0 6px 0px;
}
ssi-inbox-sidebar .wide-version .social-push-btn {
  margin-bottom: 20px;
  background-color: #94aef5 !important;
}
ssi-inbox-sidebar .wide-version .social-push-btn.exit {
  background-color: #f88c68 !important;
}
ssi-inbox-sidebar .wide-version .social-push-btn.exit i {
  transform: rotate(90deg);
}
ssi-inbox-sidebar .slim-version {
  flex: 1;
  flex-direction: column;
  width: 75px;
  min-width: 75px;
  font-size: 9px;
  font-weight: normal;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0px;
  background-color: #f8f8f8;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #3f3f49;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  color: white;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-item.useful-things {
  margin-bottom: 10px;
}
@media only screen and (max-height: 820px) {
  ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-standard-tools .inbox-sidebar-slim-item,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-tools .inbox-sidebar-slim-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
  }
}
@media only screen and (max-height: 740px) {
  ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-standard-tools .inbox-sidebar-slim-item,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-tools .inbox-sidebar-slim-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-size: 16px;
  }
}
@media only screen and (max-height: 550px) {
  ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-standard-tools .inbox-sidebar-slim-item-icon:last-child,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-tools .inbox-sidebar-slim-item-icon:last-child {
    margin-bottom: 10px;
  }
  ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-standard-tools .inbox-sidebar-slim-item-icon.useful-things,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-tools .inbox-sidebar-slim-item-icon.useful-things {
    display: none;
  }
}
@media only screen and (max-height: 500px) {
  ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-top {
    flex: 0;
  }
  ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-standard-tools .inbox-sidebar-slim-item-icon,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-tools .inbox-sidebar-slim-item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
  }
  ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-standard-tools .inbox-sidebar-slim-item-icon:last-child,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-tools .inbox-sidebar-slim-item-icon:last-child {
    margin-bottom: 10px;
  }
  ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-standard-tools .inbox-sidebar-slim-item-icon.useful-things,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-tools .inbox-sidebar-slim-item-icon.useful-things {
    display: none;
  }
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-item-clickable {
  cursor: pointer;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages {
  margin: 20px 5px 5px 5px;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages button,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages dl,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages dt,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages dd,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages ul,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages li,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages span {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin: 0;
  padding: 0;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages span {
  text-transform: capitalize;
  font-size: 1em;
  font-weight: 600;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages ul {
  list-style: none;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages .refresh-button-container {
  padding: 5px;
  margin: 5px;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages .refresh-button {
  background-color: #545767;
  border: none;
  border-radius: 50%;
  color: #14bae3;
  font-size: 2.4em;
  font-style: normal;
  outline: none;
  padding: 5px;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages .inbox-sidebar-slim-messages-total {
  color: #fff;
  margin: 5px;
  padding: 5px;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages .inbox-sidebar-slim-messages-total .total-value {
  font-size: 1.6em;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages .statistics-container {
  margin-top: 5px;
  color: #838eab;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages .statistics-container .active .statistic-value {
  color: #14bae3;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages .statistics-container .active .statistic-label {
  color: #fff;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages .statistics-container .statistic {
  margin: 5px 0;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages .statistics-container .statistic-value {
  font-size: 1.6em;
}
@media only screen and (max-height: 700px) {
  ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages .statistics-container {
    display: none;
  }
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom {
  overflow-y: auto;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom ul > * {
  margin: 0;
  padding: 0;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom .inbox-sidebar-slim-bulk-tools > .inbox-sidebar-slim-item:hover {
  color: #fff;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom .inbox-sidebar-slim-bulk-tools > .inbox-sidebar-slim-item.bulk-tool-assign .slim-item-icon {
  background-color: #9f2977;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom .inbox-sidebar-slim-bulk-tools > .inbox-sidebar-slim-item.bulk-tool-count {
  flex-flow: column nowrap;
  height: auto;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom .inbox-sidebar-slim-bulk-tools > .inbox-sidebar-slim-item.bulk-tool-count .count-label {
  font-size: 1em;
  margin-top: 5px;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom .inbox-sidebar-slim-bulk-tools > .inbox-sidebar-slim-item.bulk-tool-count .count-value {
  align-items: center;
  color: #14bae3;
  display: flex;
  font-size: 1.6em;
  font-weight: 500;
  justify-content: center;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom .inbox-sidebar-slim-bulk-tools > .inbox-sidebar-slim-item.bulk-tool-deselect {
  font-size: 1.5em;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom .inbox-sidebar-slim-bulk-tools > .inbox-sidebar-slim-item.bulk-tool-sentiment .slim-item-icon {
  background-color: #8ed0d8;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom .inbox-sidebar-slim-bulk-tools > .inbox-sidebar-slim-item.bulk-tool-tag .slim-item-icon {
  background-color: #6e9bb6;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom .inbox-sidebar-slim-bulk-tools > .inbox-sidebar-slim-item .slim-item-icon, ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom .inbox-sidebar-slim-bulk-tools > .inbox-sidebar-slim-item.bulk-tool-count .count-value {
  background-color: #454748;
  border-radius: 50%;
  height: 48px;
  position: relative;
  width: 48px;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom .inbox-sidebar-slim-bulk-tools > .inbox-sidebar-slim-item .slim-item-icon:before, ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom .inbox-sidebar-slim-bulk-tools > .inbox-sidebar-slim-item.bulk-tool-count .count-value:before {
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 24px;
  left: 12px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 12px;
  width: 24px;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-top {
  flex: 1;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-inner,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item {
  width: 100%;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-inner {
  padding: 8px;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-icon {
  font-size: 20px;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-bg-light {
  background-color: #545767;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-clickable.inbox-sidebar-slim-item-bg-light:hover {
  background-color: #5f6375;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-bg-dark {
  background-color: #363434;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-clickable.inbox-sidebar-slim-item-bg-dark:hover {
  background-color: #292727;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-count .count {
  font-size: 13px;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-count .count.active {
  color: #43ade8;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-icon-count i {
  color: #14bae3;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-icon-count .count {
  font-size: 17px;
  font-style: italic;
  position: relative;
  left: -3px;
  bottom: -2px;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-icon-count.active i {
  color: white;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item.active {
  color: #14bae3;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-bulk-assign {
  color: #f0b427;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-clickable.inbox-sidebar-slim-item-bulk-assign:hover {
  color: #edab10;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-bulk-sentiment {
  color: #d41d68;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-clickable.inbox-sidebar-slim-item-bulk-sentiment:hover {
  color: #be1a5d;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-bulk-tag {
  color: #668aea;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-clickable.inbox-sidebar-slim-item-bulk-tag:hover {
  color: #5079e7;
}
ssi-inbox-sidebar .slim-version .social-push-btn.exit i {
  font-size: 14px;
  padding: 6px 7px;
  background-color: #f88c68 !important;
  border-radius: 50%;
  transform: rotate(90deg);
}
@media (min-width: 885px) {
  ssi-inbox-sidebar .wide-version {
    display: none;
  }
  ssi-inbox-sidebar .slim-version {
    display: flex;
  }
}
@media (min-width: 1350px) {
  ssi-inbox-sidebar .wide-version {
    display: flex;
  }
  ssi-inbox-sidebar .slim-version {
    display: none;
  }
}
@media (max-width: 885px) {
  ssi-inbox-sidebar .hidden-on-small-screens {
    display: none;
  }
}
ssi-inbox-sidebar .must-hide {
  display: none;
}
ssi-inbox-sidebar .must-show {
  display: flex;
}
@media (max-width: 885px) {
  ssi-inbox-sidebar .hidden-on-small-screens {
    display: none;
  }
}

ssi-inbox-query-result-list h1,
ssi-inbox-query-result-list-push h1 {
  width: 100%;
}

.inbox-query-result-list .scrollable-content {
  padding-top: 10px;
}

.inbox-query-result-list {
  height: 100%;
  overflow-y: auto;
  background-color: #f8f9fd;
}
.inbox-query-result-list .inbox-query-result-list-divider {
  background-color: #f8f9fd;
  height: 25px;
  font-size: 8px;
  text-transform: uppercase;
  font-weight: normal;
}
.inbox-query-result-list .inbox-query-result-list-divider .divider-label {
  font-size: 10px;
  font-weight: bold;
  text-transform: capitalize;
  width: 70px;
  text-align: center;
  color: #838eab;
  line-height: 17px;
  margin-left: 30px;
}
.inbox-query-result-list .inbox-query-result-list-divider.conversation-mode {
  background-color: #e7edfd;
}
.inbox-query-result-list .inbox-query-result-list-item-toolbar-visible {
  background-color: #4f555a;
}
.inbox-query-result-list .inbox-query-result-list-item-source {
  margin: 0 0 0 3px;
  text-align: right;
  vertical-align: middle;
}
.inbox-query-result-list .inbox-query-result-list-item-source-label {
  display: inline-block;
  margin: 0 2px 0 0;
  color: #101525;
  font-size: 10px;
  line-height: 12px;
  vertical-align: top;
  font-weight: 700;
}
.inbox-query-result-list .inbox-query-result-list-item-source-icon {
  display: inline-block;
  background-size: 100% 100%;
  height: 12px;
  width: 12px;
  background-position: center;
}
.inbox-query-result-list .inbox-query-result-list-item {
  background-color: #fff;
  color: #c8d1d8;
  margin-bottom: 8px;
  display: flex;
  position: relative;
}
.inbox-query-result-list .inbox-query-result-list-item.expanded .inbox-query-result-list-item-content {
  white-space: normal;
}
.inbox-query-result-list .inbox-query-result-list-item.expanded .inbox-query-result-list-item-conversation-statistics {
  display: flex;
}
.inbox-query-result-list .inbox-query-result-list-item:hover .inbox-query-result-list-item-toolbar {
  background-color: #fff;
  box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.35);
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-media-container {
  display: flex;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-media {
  position: relative;
  flex: 1;
  height: 105px;
  margin: 0 10px 10px 10px;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-media > div {
  border-radius: 30px;
  display: flex;
  position: relative;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-media img {
  border-radius: 10px;
  height: 105px;
  width: 100%;
  object-fit: cover;
  cursor: pointer;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-media .inbox-query-result-list-item-media-overlay {
  border-radius: 10px;
  position: absolute;
  top: 0;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-media .inbox-query-result-list-item-media-icon {
  border: solid 2.5px #fff;
  border-radius: 50%;
  bottom: calc(50% - (36px / 2));
  display: flex;
  font-style: normal;
  position: absolute;
  text-align: center;
}
@media only screen and (max-width: 1350px) {
  .inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-media .inbox-query-result-list-item-media-icon {
    width: 48px;
  }
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-media .inbox-query-result-list-item-media-icon.ssi {
  background-color: #fff;
  font-size: 1.5em;
  left: 9px;
  padding: 5px;
  z-index: 2;
  color: #838eab;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-media .inbox-query-result-list-item-media-icon.additional {
  background-color: #43ade8;
  color: #fff;
  font-size: 0.8em;
  font-weight: 600;
  left: 38px;
  padding: 8px;
  text-align: center;
  z-index: 1;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-media .inbox-query-result-list-item-media-icon.additional i {
  color: #fff;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-media:not(:last-child) {
  border-right: solid 4px #c8d1d8;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-media-overlay {
  height: 105px;
  width: 100%;
  cursor: pointer;
  position: relative;
  top: -105px;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-icon,
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-status,
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-toolbar {
  flex-grow: 0;
  flex-shrink: 0;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-icon {
  flex-basis: 55px;
  color: #838eab;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
}
@media only screen and (max-width: 1350px) {
  .inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-icon {
    flex-basis: 55px;
  }
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-icon i {
  font-size: 17px;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-icon i.fa-youtube-play {
  font-size: 23px;
  color: #282828;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-icon.spinner i {
  color: #d8dee3;
  font-size: 2.4em;
  font-weight: 500;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-icon.shadow-on-hover:hover {
  background-color: #545767;
  color: #838eab;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-status {
  border: none;
  flex-basis: 15px;
  display: block;
}
@media only screen and (max-width: 1350px) {
  .inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-status {
    flex-basis: 15px;
  }
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-status.active, .inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-status.active:hover {
  background-color: #d8dee3;
  cursor: initial;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: normal;
  color: #43537f;
  line-height: 17px;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-avatar-container {
  flex-shrink: 0;
  width: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
}
@media only screen and (max-width: 1350px) {
  .inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-avatar-container {
    width: 48px;
  }
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-avatar-container .inbox-query-result-list-item-avatar-wrapper {
  position: relative;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-avatar-container .inbox-query-result-list-item-avatar-wrapper .priority-badge {
  position: absolute;
  top: -12px;
  left: 8px;
  z-index: 3;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-avatar-container .inbox-query-result-list-item-avatar-wrapper.P1 {
  border-radius: 50%;
  border: 2px solid #f4490f;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
@media only screen and (max-width: 1350px) {
  .inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-avatar {
    width: 24px;
    height: 24px;
  }
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-avatar-container.public-conversation .inbox-query-result-list-item-avatar-wrapper .priority-badge {
  z-index: 3;
  top: -2px;
  left: -2px;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-avatar-container.public-conversation .inbox-query-result-list-item-avatar:not(.multi-users-img) {
  position: relative;
  z-index: 2;
  top: 10px;
  left: -10px;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-avatar.multi-users-img {
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 5px;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-avatar-text-media-container {
  flex: 1;
  min-width: 0;
  position: relative;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-text-container {
  padding: 12px 15px 12px 0;
  font-size: 11px;
  line-height: 16px;
  color: #596065;
  width: calc(100% - (85px + 45px));
}
@media only screen and (max-width: 1350px) {
  .inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-text-container {
    width: calc(
          100% - 48px * 2.2
        );
  }
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-text-container.mid-action-conversation {
  align-items: center;
  color: #a6b7c0;
  display: flex;
  justify-content: center;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-text-container.mid-action-conversation p {
  align-items: center;
  display: flex;
  flex-flow: column wrap;
  font-size: 1.2em;
  line-height: 1.6em;
  justify-content: center;
  margin: 0;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-text-container.mid-action-conversation p * {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-text-container.mid-action-conversation p strong {
  font-weight: 600;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-text-container.mid-action-conversation p em {
  font-weight: 400;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-avatar-text-container {
  min-height: 105px;
  display: flex;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-from-to {
  align-items: center;
  border-bottom: solid 2px #ecf0fb;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-bottom: 10px;
  color: #101525;
  font-size: 12px;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-ad {
  flex: 0 0 20px;
  margin: 0 0 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  height: 20px;
  width: 20px;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-ad i {
  line-height: 12px;
  font-size: 10px;
  color: #43537f;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-name,
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-username {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 50%;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-name .verified,
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-username .verified {
  display: inline-block;
  position: relative;
  color: #1da1f2;
  font-size: 12px;
  z-index: 1;
  margin: 0 0 0 2px;
  vertical-align: initial;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-name .verified:after,
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-username .verified:after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 70%;
  width: 70%;
  background-color: #fff;
  border-radius: 50%;
  z-index: -1;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-name {
  font-size: 12px;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-username {
  font-size: 10px;
  float: right;
  padding-left: 10px;
  text-align: right;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-badge {
  position: relative;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  float: right;
  margin: -2px 0 0 5px;
  color: #43537f;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-badge i {
  position: absolute;
  display: block;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 10px;
  width: 13px;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-info {
  font-size: 10px;
  margin-top: 6px;
  color: #43537f;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-info .left > span:nth-child(n+2):before {
  content: "|";
  font-weight: 200;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-info span {
  font-weight: bold;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-created-at {
  margin-right: 5px;
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-created-at::after {
  margin-left: 5px;
  content: "|";
}
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-name,
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-visibility,
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-username,
.inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-created-at {
  font-weight: bold;
}
.inbox-query-result-list .inbox-query-result-list-item-unread .inbox-query-result-list-item-icon {
  color: #14bae3 !important;
}
.inbox-query-result-list .inbox-query-result-list-item-unread .inbox-query-result-list-item-status {
  background-color: #14bae3;
}
.inbox-query-result-list .inbox-query-result-list-item-unread .inbox-query-result-list-item-status:hover {
  background-color: #2788bf;
}
.inbox-query-result-list .inbox-query-result-list-item-unread .inbox-query-result-list-item-visibility i {
  color: #14bae3;
}
.inbox-query-result-list .inbox-query-result-list-item-actioned .inbox-query-result-list-item-status {
  background-color: #acb543;
}
.inbox-query-result-list .inbox-query-result-list-item-actioned .inbox-query-result-list-item-status:hover {
  background-color: #719239;
}
.inbox-query-result-list .inbox-query-result-list-item-unactioned .inbox-query-result-list-item-status {
  background-color: #d1dbf4;
}
.inbox-query-result-list .inbox-query-result-list-item-unactioned .inbox-query-result-list-item-status:hover {
  background-color: #c1b7b4;
}
.inbox-query-result-list .inbox-query-result-list-item-unactioned .inbox-query-result-list-item-from-to {
  border-color: #fff;
}
.inbox-query-result-list .inbox-query-result-list-item-resolved .inbox-query-result-list-item-status {
  background-color: #fff;
}
.inbox-query-result-list .inbox-query-result-list-item-resolved .inbox-query-result-list-item-icon {
  color: #c8d1d8 !important;
}
.inbox-query-result-list .inbox-query-result-list-item-resolved .inbox-query-result-list-item-status:hover {
  background-color: #fff;
}
.inbox-query-result-list .inbox-query-result-list-item-onhold .inbox-query-result-list-item-status {
  background-color: #d41d68;
}
.inbox-query-result-list .inbox-query-result-list-item-onhold .inbox-query-result-list-item-icon {
  color: #c8d1d8 !important;
}
.inbox-query-result-list .inbox-query-result-list-item-onhold .inbox-query-result-list-item-status:hover {
  background-color: #be1a5d;
}
.inbox-query-result-list .inbox-query-result-list-item-selected {
  background-color: #ecf0fd;
}
.inbox-query-result-list .inbox-query-result-list-item-selected .inbox-query-result-list-item-icon {
  background-color: #ecf0fd;
}
.inbox-query-result-list .inbox-query-result-list-item-selected .inbox-query-result-list-item-icon,
.inbox-query-result-list .inbox-query-result-list-item-selected .inbox-query-result-list-item-text-container {
  color: #596065;
}
.inbox-query-result-list .inbox-query-result-list-item-toolbar,
.inbox-query-result-list .inbox-query-result-list-item-toolbar * {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}
.inbox-query-result-list .inbox-query-result-list-item-conversation-statistics {
  display: none;
  flex-flow: row nowrap;
  justify-content: space-between;
  list-style: none;
  margin: 20px 0;
  padding: 0;
}
.inbox-query-result-list .inbox-query-result-list-item-conversation-statistics * {
  display: flex;
}
.inbox-query-result-list .inbox-query-result-list-item-conversation-statistics .conversation-statistic {
  align-items: center;
  border-radius: 10px;
  color: #fff;
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 0.85em;
  font-weight: 600;
  justify-content: center;
  margin: 0 5px;
  padding: 2px 12px;
}
.inbox-query-result-list .inbox-query-result-list-item-conversation-statistics .conversation-statistic.actioned {
  background-color: #acb543;
}
.inbox-query-result-list .inbox-query-result-list-item-conversation-statistics .conversation-statistic.unactioned {
  background-color: #d1dbf4;
}
.inbox-query-result-list .inbox-query-result-list-item-conversation-statistics .conversation-statistic.unread {
  background-color: #14bae3;
}
.inbox-query-result-list .inbox-query-result-list-item-conversation-statistics .conversation-statistic .statistic-value {
  padding-right: 0.3em;
}
.inbox-query-result-list .inbox-query-result-list-item-toolbar {
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  flex-flow: column nowrap;
  justify-content: space-around;
  list-style: none;
  margin: 0;
  max-height: 104px;
  padding: 3px;
  margin: 8px;
}
.inbox-query-result-list .inbox-query-result-list-item-toolbar-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  padding: 0;
  font-size: 12px;
  height: 34px;
  margin: 0;
  color: #fff;
  visibility: hidden;
  width: 34px;
}
.inbox-query-result-list .inbox-query-result-list-item-toolbar-item i {
  font-size: 14px;
}
.inbox-query-result-list .inbox-query-result-list-item-toolbar-item i.assign {
  color: #838eab;
}
.inbox-query-result-list .inbox-query-result-list-item-toolbar-item i.assigned {
  color: #14bae3;
}
.inbox-query-result-list .inbox-query-result-list-item:hover .inbox-query-result-list-item-toolbar-item,
.inbox-query-result-list .inbox-query-result-list-item-toolbar-visible .inbox-query-result-list-item-toolbar-item {
  visibility: visible;
}
.inbox-query-result-list .inbox-query-result-list-item-toolbar-item.influence {
  position: relative;
}
.inbox-query-result-list .inbox-query-result-list-item-toolbar-item.influence i {
  color: #14bae3;
  font-size: 26px;
  position: relative;
}
.inbox-query-result-list .inbox-query-result-list-item-toolbar-item.influence .inbox-query-result-list-item-toolbar-item-text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 12px;
  font-weight: normal;
}
.inbox-query-result-list .inbox-query-result-list-item-toolbar-item.assigned-to {
  cursor: pointer;
}
.inbox-query-result-list .inbox-query-result-list-item-toolbar-item.assigned-to.assigned-to-active {
  color: #14bae3;
}
.inbox-query-result-list .inbox-query-result-list-item-toolbar-item.§ed-to {
  border-radius: 0;
}
.inbox-query-result-list .inbox-query-result-list-item-toolbar-item.sentiment {
  border-radius: 50%;
  cursor: pointer;
  height: 34px;
  width: 34px;
}
.inbox-query-result-list .inbox-query-result-list-item-conversation-assign.active {
  display: flex;
}
.inbox-query-result-list .inbox-query-result-list-item-assign-to {
  display: flex;
  position: absolute;
  right: 42px;
  top: 42px;
}
.inbox-query-result-list .inbox-query-result-list-item-assign-to .conversation-assignment-preview {
  align-items: center;
  background-color: #14bae3;
  border-radius: 20px;
  color: #fff;
  display: flex !important;
  flex-grow: 1;
  font-size: 0.85em;
  font-weight: 500;
  justify-content: space-between;
  padding: 6px 20px;
}
.inbox-query-result-list .inbox-query-result-list-item-assign-to .conversation-assignment-preview .preview-icon {
  font-size: 1.2em;
}
.inbox-query-result-list .inbox-query-result-list-item-assign-to .conversation-assignment-preview .preview-name {
  flex-grow: 1;
  padding-left: 0.5em;
}
.inbox-query-result-list .inbox-query-result-list-item-assign-to .conversation-assignment-preview .remove-button {
  color: #fff;
  font-size: 1em;
  left: auto;
  position: relative;
  top: auto;
}
.inbox-query-result-list .inbox-query-result-list-item-assign-to .remove-button {
  align-items: center;
  background: transparent;
  border-radius: 50%;
  color: #14bae3;
  font-size: 0.85em;
  height: 18px;
  justify-content: center;
  left: 8px;
  padding: 0;
  position: absolute;
  top: 9px;
  width: 18px;
  z-index: 1;
}
.inbox-query-result-list .inbox-query-result-list-item-assign-to .input-container {
  background-color: #fff;
  border: solid 2px #c9d5db;
  border-radius: 20px;
  display: flex;
  position: relative;
}
.inbox-query-result-list .inbox-query-result-list-item-assign-to .input-container input {
  background-color: transparent;
  border: none;
  border-radius: 0;
  box-shadow: none;
  font-style: italic;
  font-weight: 400;
  height: 32px;
  padding: 5px 20px 5px 24px;
  width: 226px;
}
.inbox-query-result-list .inbox-query-result-list-item-assign-to .input-container .dropdown-menu {
  border: none;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  display: block;
  height: 200px;
  left: 16px !important;
  overflow-y: auto;
  padding: 10px 20px 20px 20px;
  top: 32px !important;
  width: 200px;
}
.inbox-query-result-list .inbox-query-result-list-item-assign-to button {
  border: none;
  height: 36px;
  margin: 0;
  padding: 0;
}
.inbox-query-result-list .inbox-query-result-list-item-assign-to button.action-assign {
  background-color: #43ade8;
  border-radius: 20px;
  margin-left: -36px;
  padding: 2px 20px;
}
.inbox-query-result-list .inbox-query-result-list-item-assign-to input,
.inbox-query-result-list .inbox-query-result-list-item-assign-to .btn {
  font-size: 12px;
}
.inbox-query-result-list .inbox-query-result-list-item-change-sentiment {
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  flex-flow: row nowrap;
  padding: 3px;
  position: absolute;
  right: 20px;
  top: 83px;
}
.inbox-query-result-list .inbox-query-result-list-item-change-sentiment * {
  align-items: center;
  display: flex;
}
.inbox-query-result-list .inbox-query-result-list-item-change-sentiment > .sentiment {
  border-radius: 50%;
  color: white;
  cursor: pointer;
  margin: 0 2px;
}
.inbox-query-result-list .inbox-query-result-list-item-change-sentiment > .sentiment > i {
  height: 14px;
  justify-content: center;
  width: 14px;
}
.inbox-query-result-list .inbox-query-result-list-item.inbox-query-result-list-item-multi-selected .inbox-query-result-list-item-icon {
  background-color: #545767;
}
.inbox-query-result-list.on-hold-list {
  max-height: 30%;
}

.inbox-no-results {
  display: flex;
  align-items: center;
  margin: auto;
  flex-direction: column;
}
.inbox-no-results i {
  color: #838eab;
  font-size: 50px;
  margin-bottom: 10px;
}
.inbox-no-results h3 {
  color: #43537f;
  font-size: 20px;
  font-weight: 900;
}
.inbox-no-results h6 {
  color: #43537f;
  font-size: 14px;
  font-weight: 700;
  margin-top: 0;
  text-align: center;
  margin: auto;
  padding: 0 20px;
  max-width: 230px;
}

ssi-inbox-sidebar .wide-version,
ssi-inbox-sidebar .slim-version {
  height: 100%;
}
ssi-inbox-sidebar .wide-version {
  display: flex;
}
ssi-inbox-sidebar .slim-version {
  display: none;
}
ssi-inbox-sidebar .wide-version {
  width: 160px;
  min-width: 160px;
  flex: 1;
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
  display: flex;
  flex-direction: column;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-results-count {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0px;
  text-transform: uppercase;
  color: #596065;
  background-color: #f8f8f8;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-inner {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #3f3f49;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-inner a {
  font-size: 1em;
  font-weight: 600;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-inner a span {
  font-size: 1em;
  font-weight: 600;
}
ssi-inbox-sidebar .wide-version a {
  color: white;
}
ssi-inbox-sidebar .wide-version a:hover,
ssi-inbox-sidebar .wide-version a:focus {
  text-decoration: none;
  color: white;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item.border-top {
  border-top: solid 1px rgba(255, 255, 255, 0.24);
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item {
  padding: 14px 28px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item i {
  font-size: 12px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages {
  padding: 20px 10px;
  position: relative;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages.margin-bottom {
  margin-bottom: 10px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages-assigned {
  justify-content: flex-end;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages-filtered {
  border-right: solid 2px #545767;
  justify-content: flex-end;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages-total {
  border-bottom: solid 2px #545767;
  border-right: solid 2px #545767;
  justify-content: flex-start;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages-unread {
  border-bottom: solid 2px #545767;
  justify-content: flex-start;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages ul {
  display: flex;
  flex-flow: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages ul li {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 55px;
  padding: 0;
  width: 50%;
  color: #838eab;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages ul li.active .count {
  color: #14bae3;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages ul li.active .label {
  color: #fff;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages ul li .count {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 2px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages ul li .label {
  color: inherit;
  font-size: 1em;
  font-weight: 600;
  padding: 0;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages button {
  background: #545767;
  border: solid 6px #3f4142;
  border-radius: 50%;
  color: #14bae3;
  left: 58px;
  outline: none;
  padding: 6px;
  position: absolute;
  top: 47px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-messages button .refresh-icon {
  font-size: 2.4em;
}
ssi-inbox-sidebar .wide-version a.inbox-sidebar-item {
  background-color: #545767;
  border-radius: 28px;
  margin-left: 14px;
  margin-right: 14px;
  padding: 7px 14px;
}
ssi-inbox-sidebar .wide-version a.inbox-sidebar-item.inbox-sidebar-item-bg-primary {
  background-color: #14bae3;
}
ssi-inbox-sidebar .wide-version a.inbox-sidebar-item.inbox-sidebar-item-bg-primary:hover {
  background-color: #25c4ec;
}
ssi-inbox-sidebar .wide-version a.inbox-sidebar-item.inbox-sidebar-item-btn-list-filters {
  background-color: #14bae3;
  color: #fff;
}
ssi-inbox-sidebar .wide-version a.inbox-sidebar-item.inbox-sidebar-item-btn-list-filters-clear {
  background-color: #f88c68;
}
ssi-inbox-sidebar .wide-version a.inbox-sidebar-item.inbox-sidebar-item-conversations-button {
  background-color: #545767;
  box-shadow: #303030 0 0 16px 0px;
  color: #838eab;
}
ssi-inbox-sidebar .wide-version a.inbox-sidebar-item.inbox-sidebar-item-conversations-button:hover {
  color: #fff;
}
ssi-inbox-sidebar .wide-version a.inbox-sidebar-item.inbox-sidebar-item-conversations-button.active > .button-icon {
  color: #14bae3;
}
ssi-inbox-sidebar .wide-version a.inbox-sidebar-item.inbox-sidebar-item-conversations-button.active > .button-text {
  color: #fff;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-filters-list,
ssi-inbox-sidebar .wide-version .inbox-sidebar-active-preset {
  margin: 0 10px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-filters-list > ul,
ssi-inbox-sidebar .wide-version .inbox-sidebar-active-preset > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-filters-list > ul > li,
ssi-inbox-sidebar .wide-version .inbox-sidebar-active-preset > ul > li {
  align-items: center;
  background-color: #545767;
  border-radius: 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  list-style: none;
  margin: 10px 0;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-filters-list > ul > li .btn-edit,
ssi-inbox-sidebar .wide-version .inbox-sidebar-active-preset > ul > li .btn-edit {
  background-color: #14bae3;
  border-radius: 20px;
  padding: 5px;
  color: #fff;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-filters-list > ul > li .filter-label,
ssi-inbox-sidebar .wide-version .inbox-sidebar-filters-list > ul > li .preset-label,
ssi-inbox-sidebar .wide-version .inbox-sidebar-active-preset > ul > li .filter-label,
ssi-inbox-sidebar .wide-version .inbox-sidebar-active-preset > ul > li .preset-label {
  color: #fff;
  overflow: hidden;
  padding: 0 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 98px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-filters-list > ul > li .btn-remove,
ssi-inbox-sidebar .wide-version .inbox-sidebar-active-preset > ul > li .btn-remove {
  color: #f0d9d1;
  margin-right: 8px;
  padding: 5px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-filters-list > ul > li .btn-remove .ssi,
ssi-inbox-sidebar .wide-version .inbox-sidebar-filters-list > ul > li .btn-remove .fa,
ssi-inbox-sidebar .wide-version .inbox-sidebar-active-preset > ul > li .btn-remove .ssi,
ssi-inbox-sidebar .wide-version .inbox-sidebar-active-preset > ul > li .btn-remove .fa {
  font-size: 14px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-active-preset > ul > li {
  background-color: #14bae3;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-active-preset > ul > li .btn-remove {
  color: #fff;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-custom-presets-toggle {
  position: relative;
  top: 12px;
  z-index: 1;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list {
  background-color: #35353f;
  padding: 28px 0 20px 0;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list > ul {
  margin: 0 20px;
  padding: 0;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list > ul > li {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  padding: 0;
  font-size: 12px;
  color: #838eab;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list > ul > li.selected .btn-edit,
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list > ul > li.selected span {
  color: #fff;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list > ul > li.selected .preset-label .ssi,
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list > ul > li.selected .preset-label .fa {
  color: #14bae3;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list > ul > li .preset-label {
  align-items: center;
  display: flex;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list > ul > li .preset-label > span {
  overflow: hidden;
  padding-left: 5px;
  padding-right: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 96px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list > ul > li .preset-label .ssi,
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list > ul > li .preset-label .fa {
  margin-right: 2px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list > ul > li a {
  color: #838eab;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-custom-presets-list > ul > li a i {
  color: #545767;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-presets-list {
  margin-top: 30px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-presets-list > ul {
  margin: 20px;
  padding: 0;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-presets-list > ul > li {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  padding: 0;
  color: #838eab;
  font-size: 12px;
  font-weight: 600;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-presets-list > ul > li.selected {
  color: #fff;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-presets-list > ul > li.selected .fa {
  color: #14bae3;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-presets-list > ul > li .ssi,
ssi-inbox-sidebar .wide-version .inbox-sidebar-presets-list > ul > li .fa {
  color: #545767;
  margin-right: 2px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-spacing-top {
  margin-top: 3px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-spacing-bottom {
  margin-bottom: 3px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bg-light {
  background-color: #545767;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bg-dark {
  background-color: #363434;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bg-primary {
  background-color: #14bae3;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bg-light-hover:hover {
  background-color: #5f6375;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bg-dark-hover:hover {
  background-color: #292727;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bg-primary-hover:hover {
  background-color: #25c4ec;
}
ssi-inbox-sidebar .wide-version a.inbox-sidebar-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-top {
  flex: 1;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-top .bulk-actions-menu {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  list-style: none;
  margin: 20px 0 0 0;
  padding: 0;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-top .bulk-actions-menu * {
  display: flex;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-top .bulk-actions-menu > li {
  flex-shrink: 0;
  flex-grow: 1;
  margin: 5px 0;
  padding: 0 10px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-top .bulk-actions-menu > li > * {
  flex-grow: 1;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-top .bulk-actions-menu > li > button, ssi-inbox-sidebar .wide-version .inbox-sidebar-top .bulk-actions-menu > li > dl > dt {
  align-items: center;
  border-radius: 20px;
  justify-content: space-between;
  color: #fff;
  font-weight: 600;
  padding: 5px 13px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-top .bulk-actions-menu > li > button {
  border: none;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-top .bulk-actions-menu > li > dl {
  flex-flow: column-reverse nowrap;
  flex-grow: 1;
  margin: 0;
  padding: 0;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-top .bulk-actions-menu > li > dl > dt {
  margin: 0;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-top .bulk-actions-menu > li > dl > dd {
  color: #fff;
  display: block;
  line-height: 1.8em;
  margin: 0;
  padding: 0 10px 12px 10px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-top .bulk-actions-menu > li > dl > dd > * {
  display: inline;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-icon-left {
  padding-left: 5px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-filter-remove {
  position: relative;
  top: -1px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-filters li:not(:last-child) {
  margin-bottom: 10px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-padding-sides-sm {
  padding-left: 10px;
  padding-right: 8px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-text {
  display: inline-block;
  width: calc(100% - 40px);
  word-wrap: break-word;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-active {
  background-color: #14bae3;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-deselect {
  background-color: #f88c68;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-deselect:hover {
  background-color: #f77950;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-deselect span {
  flex-grow: 1;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-assign {
  background-color: #f0b427;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-assign:hover {
  background-color: #edab10;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-sentiment {
  background-color: #d41d68;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-sentiment:hover {
  background-color: #be1a5d;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-tag {
  background-color: #668aea;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-tag:hover {
  background-color: #5079e7;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-spam {
  background-color: #545767;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-spam:hover {
  opacity: 0.8;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-tip dt {
  background-color: #545767;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-bulk-tip i {
  color: #14bae3;
}
ssi-inbox-sidebar .wide-version i.ssi-users-plus {
  font-size: 16px;
}
ssi-inbox-sidebar .wide-version i.ssi-sentiment-menu-icon {
  font-size: 13px;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-item-add-filter.active {
  background-color: #14bae3;
  color: #fff;
}
ssi-inbox-sidebar .wide-version .inbox-sidebar-btn {
  display: block;
  background-color: #425dec !important;
  box-shadow: #303030 0 0 6px 0px;
}
ssi-inbox-sidebar .wide-version .social-push-btn {
  margin-bottom: 20px;
  background-color: #94aef5 !important;
}
ssi-inbox-sidebar .wide-version .social-push-btn.exit {
  background-color: #f88c68 !important;
}
ssi-inbox-sidebar .wide-version .social-push-btn.exit i {
  transform: rotate(90deg);
}
ssi-inbox-sidebar .slim-version {
  flex: 1;
  flex-direction: column;
  width: 75px;
  min-width: 75px;
  font-size: 9px;
  font-weight: normal;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0px;
  background-color: #f8f8f8;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #3f3f49;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  color: white;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-item.useful-things {
  margin-bottom: 10px;
}
@media only screen and (max-height: 820px) {
  ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-standard-tools .inbox-sidebar-slim-item,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-tools .inbox-sidebar-slim-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
  }
}
@media only screen and (max-height: 740px) {
  ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-standard-tools .inbox-sidebar-slim-item,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-tools .inbox-sidebar-slim-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-size: 16px;
  }
}
@media only screen and (max-height: 550px) {
  ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-standard-tools .inbox-sidebar-slim-item-icon:last-child,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-tools .inbox-sidebar-slim-item-icon:last-child {
    margin-bottom: 10px;
  }
  ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-standard-tools .inbox-sidebar-slim-item-icon.useful-things,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-tools .inbox-sidebar-slim-item-icon.useful-things {
    display: none;
  }
}
@media only screen and (max-height: 500px) {
  ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-top {
    flex: 0;
  }
  ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-standard-tools .inbox-sidebar-slim-item-icon,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-tools .inbox-sidebar-slim-item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
  }
  ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-standard-tools .inbox-sidebar-slim-item-icon:last-child,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-tools .inbox-sidebar-slim-item-icon:last-child {
    margin-bottom: 10px;
  }
  ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-standard-tools .inbox-sidebar-slim-item-icon.useful-things,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-tools .inbox-sidebar-slim-item-icon.useful-things {
    display: none;
  }
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-item-clickable {
  cursor: pointer;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages {
  margin: 20px 5px 5px 5px;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages button,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages dl,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages dt,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages dd,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages ul,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages li,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages span {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin: 0;
  padding: 0;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages span {
  text-transform: capitalize;
  font-size: 1em;
  font-weight: 600;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages ul {
  list-style: none;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages .refresh-button-container {
  padding: 5px;
  margin: 5px;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages .refresh-button {
  background-color: #545767;
  border: none;
  border-radius: 50%;
  color: #14bae3;
  font-size: 2.4em;
  font-style: normal;
  outline: none;
  padding: 5px;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages .inbox-sidebar-slim-messages-total {
  color: #fff;
  margin: 5px;
  padding: 5px;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages .inbox-sidebar-slim-messages-total .total-value {
  font-size: 1.6em;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages .statistics-container {
  margin-top: 5px;
  color: #838eab;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages .statistics-container .active .statistic-value {
  color: #14bae3;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages .statistics-container .active .statistic-label {
  color: #fff;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages .statistics-container .statistic {
  margin: 5px 0;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages .statistics-container .statistic-value {
  font-size: 1.6em;
}
@media only screen and (max-height: 700px) {
  ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-messages .statistics-container {
    display: none;
  }
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom {
  overflow-y: auto;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom ul > * {
  margin: 0;
  padding: 0;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom .inbox-sidebar-slim-bulk-tools > .inbox-sidebar-slim-item:hover {
  color: #fff;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom .inbox-sidebar-slim-bulk-tools > .inbox-sidebar-slim-item.bulk-tool-assign .slim-item-icon {
  background-color: #9f2977;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom .inbox-sidebar-slim-bulk-tools > .inbox-sidebar-slim-item.bulk-tool-count {
  flex-flow: column nowrap;
  height: auto;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom .inbox-sidebar-slim-bulk-tools > .inbox-sidebar-slim-item.bulk-tool-count .count-label {
  font-size: 1em;
  margin-top: 5px;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom .inbox-sidebar-slim-bulk-tools > .inbox-sidebar-slim-item.bulk-tool-count .count-value {
  align-items: center;
  color: #14bae3;
  display: flex;
  font-size: 1.6em;
  font-weight: 500;
  justify-content: center;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom .inbox-sidebar-slim-bulk-tools > .inbox-sidebar-slim-item.bulk-tool-deselect {
  font-size: 1.5em;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom .inbox-sidebar-slim-bulk-tools > .inbox-sidebar-slim-item.bulk-tool-sentiment .slim-item-icon {
  background-color: #8ed0d8;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom .inbox-sidebar-slim-bulk-tools > .inbox-sidebar-slim-item.bulk-tool-tag .slim-item-icon {
  background-color: #6e9bb6;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom .inbox-sidebar-slim-bulk-tools > .inbox-sidebar-slim-item .slim-item-icon, ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom .inbox-sidebar-slim-bulk-tools > .inbox-sidebar-slim-item.bulk-tool-count .count-value {
  background-color: #454748;
  border-radius: 50%;
  height: 48px;
  position: relative;
  width: 48px;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom .inbox-sidebar-slim-bulk-tools > .inbox-sidebar-slim-item .slim-item-icon:before, ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-body .inbox-sidebar-slim-bottom .inbox-sidebar-slim-bulk-tools > .inbox-sidebar-slim-item.bulk-tool-count .count-value:before {
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 24px;
  left: 12px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 12px;
  width: 24px;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-top {
  flex: 1;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-inner,
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item {
  width: 100%;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-inner {
  padding: 8px;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-icon {
  font-size: 20px;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-bg-light {
  background-color: #545767;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-clickable.inbox-sidebar-slim-item-bg-light:hover {
  background-color: #5f6375;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-bg-dark {
  background-color: #363434;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-clickable.inbox-sidebar-slim-item-bg-dark:hover {
  background-color: #292727;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-count .count {
  font-size: 13px;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-count .count.active {
  color: #43ade8;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-icon-count i {
  color: #14bae3;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-icon-count .count {
  font-size: 17px;
  font-style: italic;
  position: relative;
  left: -3px;
  bottom: -2px;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-icon-count.active i {
  color: white;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item.active {
  color: #14bae3;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-bulk-assign {
  color: #f0b427;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-clickable.inbox-sidebar-slim-item-bulk-assign:hover {
  color: #edab10;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-bulk-sentiment {
  color: #d41d68;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-clickable.inbox-sidebar-slim-item-bulk-sentiment:hover {
  color: #be1a5d;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-bulk-tag {
  color: #668aea;
}
ssi-inbox-sidebar .slim-version .inbox-sidebar-slim-item-clickable.inbox-sidebar-slim-item-bulk-tag:hover {
  color: #5079e7;
}
ssi-inbox-sidebar .slim-version .social-push-btn.exit i {
  font-size: 14px;
  padding: 6px 7px;
  background-color: #f88c68 !important;
  border-radius: 50%;
  transform: rotate(90deg);
}
@media (min-width: 885px) {
  ssi-inbox-sidebar .wide-version {
    display: none;
  }
  ssi-inbox-sidebar .slim-version {
    display: flex;
  }
}
@media (min-width: 1350px) {
  ssi-inbox-sidebar .wide-version {
    display: flex;
  }
  ssi-inbox-sidebar .slim-version {
    display: none;
  }
}
@media (max-width: 885px) {
  ssi-inbox-sidebar .hidden-on-small-screens {
    display: none;
  }
}
ssi-inbox-sidebar .must-hide {
  display: none;
}
ssi-inbox-sidebar .must-show {
  display: flex;
}
@media (max-width: 885px) {
  ssi-inbox-sidebar .hidden-on-small-screens {
    display: none;
  }
}

ssi-inbox-search-results,
ssi-inbox-search-results-push {
  display: flex;
  flex-flow: column;
  flex: 1;
  transition: all 0.2s ease-out;
  background-color: #f8f9fd;
}
@media (max-width: 885px) {
  ssi-inbox-search-results,
ssi-inbox-search-results-push {
    width: 100vw;
  }
}
@media (min-width: 885px) {
  ssi-inbox-search-results,
ssi-inbox-search-results-push {
    width: 460px;
    min-width: 460px;
    max-width: 460px;
  }
}
ssi-inbox-search-results .sidebar-toggle,
ssi-inbox-search-results-push .sidebar-toggle {
  float: none;
  border: none;
  margin: 0;
  background: #333;
  margin: 0;
}
@media (min-width: 885px) {
  ssi-inbox-search-results .sidebar-toggle,
ssi-inbox-search-results-push .sidebar-toggle {
    display: none;
  }
}
ssi-inbox-search-results.pinned-crm,
ssi-inbox-search-results-push.pinned-crm {
  min-width: 390px;
  max-width: 390px;
}
ssi-inbox-search-results .inbox-search-results-options-filters,
ssi-inbox-search-results-push .inbox-search-results-options-filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  background-color: #fff;
  margin: 5px 0;
}
ssi-inbox-search-results .inbox-search-results-options-filters .inbox-clear-filters,
ssi-inbox-search-results-push .inbox-search-results-options-filters .inbox-clear-filters {
  background-color: #fff1ed;
  border: none;
  border-radius: 25px;
  padding: 11px 20px;
  color: #f88c68;
  font-weight: bold;
  font-size: 12px;
}
ssi-inbox-search-results .inbox-search-results-options-filters .inbox-clear-filters i,
ssi-inbox-search-results-push .inbox-search-results-options-filters .inbox-clear-filters i {
  margin-left: 10px;
  font-size: 10px;
}
ssi-inbox-search-results .inbox-search-results-options-filters .clear-filters-copy span,
ssi-inbox-search-results-push .inbox-search-results-options-filters .clear-filters-copy span {
  color: #838eab;
  font-size: 10px;
  font-weight: bold;
}
ssi-inbox-search-results.conversation-detail,
ssi-inbox-search-results-push.conversation-detail {
  background-color: #ecf0fb;
}
ssi-inbox-search-results.conversation-detail ssi-inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-icon,
ssi-inbox-search-results.conversation-detail ssi-inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-avatar-container,
ssi-inbox-search-results.conversation-detail ssi-inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-text-container,
ssi-inbox-search-results-push.conversation-detail ssi-inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-icon,
ssi-inbox-search-results-push.conversation-detail ssi-inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-avatar-container,
ssi-inbox-search-results-push.conversation-detail ssi-inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-text-container {
  background-color: transparent;
}
ssi-inbox-search-results.conversation-detail ssi-inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-text-container,
ssi-inbox-search-results-push.conversation-detail ssi-inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-text-container {
  color: #43537f;
}
ssi-inbox-search-results.conversation-detail ssi-inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-info,
ssi-inbox-search-results-push.conversation-detail ssi-inbox-query-result-list .inbox-query-result-list-item .inbox-query-result-list-item-info {
  color: #43537f !important;
}
ssi-inbox-search-results.conversation-detail .inbox-search-results-options,
ssi-inbox-search-results.conversation-detail ssi-live-chat,
ssi-inbox-search-results-push.conversation-detail .inbox-search-results-options,
ssi-inbox-search-results-push.conversation-detail ssi-live-chat {
  background-color: #e7edfd;
}
ssi-inbox-search-results.conversation-detail .inbox-query-result-list,
ssi-inbox-search-results-push.conversation-detail .inbox-query-result-list {
  background-color: #e7edfd;
}
ssi-inbox-search-results .inbox-search-results-stats,
ssi-inbox-search-results-push .inbox-search-results-stats {
  height: 0px;
  width: calc(100% - 30px);
}
ssi-inbox-search-results .inbox-search-results-options,
ssi-inbox-search-results-push .inbox-search-results-options {
  background-color: #f8f9fd;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  padding: 12px 0 12px 12px;
  border-bottom: 2px solid #ecf0fb;
}
@media (min-width: 885px) {
  ssi-inbox-search-results .inbox-search-results-options.social-push-mode,
ssi-inbox-search-results-push .inbox-search-results-options.social-push-mode {
    display: none;
  }
}
ssi-inbox-search-results .inbox-search-results-options > ul,
ssi-inbox-search-results-push .inbox-search-results-options > ul {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  width: 100%;
  list-style: none;
}
ssi-inbox-search-results .inbox-search-results-options > ul .social-push-item > button,
ssi-inbox-search-results-push .inbox-search-results-options > ul .social-push-item > button {
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: row;
  align-items: center;
  text-align: center;
  background-color: #fff;
  border: solid 1px #b4c8ff;
  border-radius: 24px;
  color: #43537f;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  padding: 11px 26px;
  outline: none;
}
ssi-inbox-search-results .inbox-search-results-options > ul .social-push-item > button:hover,
ssi-inbox-search-results-push .inbox-search-results-options > ul .social-push-item > button:hover {
  background-color: #89a4ea;
  color: #fff;
}
ssi-inbox-search-results .inbox-search-results-options > ul .social-push-item > button:hover button,
ssi-inbox-search-results-push .inbox-search-results-options > ul .social-push-item > button:hover button {
  color: #fff;
}
ssi-inbox-search-results .inbox-search-results-options > ul .social-push-item > button > i,
ssi-inbox-search-results-push .inbox-search-results-options > ul .social-push-item > button > i {
  font-size: 12px;
}
ssi-inbox-search-results .inbox-search-results-options > ul .inbox-search-results-btn-sort,
ssi-inbox-search-results-push .inbox-search-results-options > ul .inbox-search-results-btn-sort {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: 0;
}
ssi-inbox-search-results .inbox-search-results-options > ul .inbox-search-results-btn-sort button,
ssi-inbox-search-results-push .inbox-search-results-options > ul .inbox-search-results-btn-sort button {
  display: inline-block;
  font-size: 12px;
  line-height: 26px;
  font-weight: 900;
  color: #43537f;
  padding: 0 12px;
  border-radius: 100px;
  border: none;
  background: none;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  outline: none;
}
ssi-inbox-search-results .inbox-search-results-options > ul .inbox-search-results-btn-sort .dropdown-menu,
ssi-inbox-search-results-push .inbox-search-results-options > ul .inbox-search-results-btn-sort .dropdown-menu {
  position: absolute;
  width: 260px;
  z-index: 5;
  border: none;
  top: 0;
  left: -45px;
  padding: 25px 15px 32px 21px;
  background: #fff;
  border-radius: 12px;
}
ssi-inbox-search-results .inbox-search-results-options > ul .inbox-search-results-btn-sort .dropdown-menu > li,
ssi-inbox-search-results-push .inbox-search-results-options > ul .inbox-search-results-btn-sort .dropdown-menu > li {
  color: #43537f;
  font-style: normal;
  font-weight: 400;
  margin: 8px 0;
  padding: 0;
}
ssi-inbox-search-results .inbox-search-results-options > ul .inbox-search-results-btn-sort .dropdown-menu > li.selected > button,
ssi-inbox-search-results-push .inbox-search-results-options > ul .inbox-search-results-btn-sort .dropdown-menu > li.selected > button {
  color: #14bae3;
  background-color: #f8f9fd;
}
ssi-inbox-search-results .inbox-search-results-options > ul .inbox-search-results-btn-sort .dropdown-menu > li.selected > button > i,
ssi-inbox-search-results-push .inbox-search-results-options > ul .inbox-search-results-btn-sort .dropdown-menu > li.selected > button > i {
  font-weight: bold;
  margin-left: 8px;
}
ssi-inbox-search-results .inbox-search-results-options > ul .inbox-search-results-btn-sort .dropdown-menu > li:hover button,
ssi-inbox-search-results-push .inbox-search-results-options > ul .inbox-search-results-btn-sort .dropdown-menu > li:hover button {
  background-color: #f8f9fd;
}
ssi-inbox-search-results .inbox-search-results-options > ul .inbox-search-results-btn-sort .dropdown-menu > li > button,
ssi-inbox-search-results-push .inbox-search-results-options > ul .inbox-search-results-btn-sort .dropdown-menu > li > button {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  color: #43537f;
  align-items: center;
  background: transparent;
  border: none;
  font-size: 12px;
  width: 100%;
  text-transform: initial;
  letter-spacing: initial;
}
ssi-inbox-search-results .inbox-search-results-options > ul .inbox-search-results-btn-sort i,
ssi-inbox-search-results-push .inbox-search-results-options > ul .inbox-search-results-btn-sort i {
  margin-left: 5px;
  font-size: 14px;
}
ssi-inbox-search-results .inbox-search-results-options > ul .inbox-search-results-btn-action-all:hover,
ssi-inbox-search-results-push .inbox-search-results-options > ul .inbox-search-results-btn-action-all:hover {
  background-color: #acb543;
}
ssi-inbox-search-results .query-status-container,
ssi-inbox-search-results-push .query-status-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  color: #43537f;
  text-transform: uppercase;
}
ssi-inbox-search-results .query-status-container i,
ssi-inbox-search-results-push .query-status-container i {
  font-size: 7px;
  padding: 4px;
  border-radius: 50%;
  background-color: #43537f;
  color: #fff;
  margin-right: 5px;
}
ssi-inbox-search-results .query-status-container i.active,
ssi-inbox-search-results-push .query-status-container i.active {
  background-color: #b2c614;
}
ssi-inbox-search-results .query-status-container i.on-hold,
ssi-inbox-search-results-push .query-status-container i.on-hold {
  background-color: #d41d68;
}
ssi-inbox-search-results .query-status-container i.no-results,
ssi-inbox-search-results-push .query-status-container i.no-results {
  background-color: #838eab;
  color: #fff;
}
ssi-inbox-search-results ssi-live-chat,
ssi-inbox-search-results-push ssi-live-chat {
  background-color: #f8f9fd;
  margin-top: -2px;
  z-index: 1;
}
ssi-inbox-search-results ssi-live-chat ssi-live-chat-tally-tile > div,
ssi-inbox-search-results-push ssi-live-chat ssi-live-chat-tally-tile > div {
  padding: 12px 35px;
  box-shadow: 0 5px 5px rgba(131, 142, 171, 0.2);
  border-radius: 12px;
}
ssi-inbox-search-results ssi-live-chat ssi-live-chat-network-state .social-status,
ssi-inbox-search-results-push ssi-live-chat ssi-live-chat-network-state .social-status {
  margin-right: 20px;
}
ssi-inbox-search-results ssi-live-chat ssi-live-chat-network-state .social-status ul,
ssi-inbox-search-results ssi-live-chat ssi-live-chat-network-state .social-status .status-menu,
ssi-inbox-search-results-push ssi-live-chat ssi-live-chat-network-state .social-status ul,
ssi-inbox-search-results-push ssi-live-chat ssi-live-chat-network-state .social-status .status-menu {
  width: 77px;
}
ssi-inbox-search-results ssi-live-chat ssi-live-chat-conversation-statistics,
ssi-inbox-search-results-push ssi-live-chat ssi-live-chat-conversation-statistics {
  height: 24px;
}
ssi-inbox-search-results .inbox-search-results-statistic,
ssi-inbox-search-results-push .inbox-search-results-statistic {
  width: 100%;
  border-radius: 5px;
  border: 1px solid;
  padding: 8px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: normal;
}
ssi-inbox-search-results .inbox-search-results-statistic:not(:last-child),
ssi-inbox-search-results-push .inbox-search-results-statistic:not(:last-child) {
  margin-right: 8px;
}
ssi-inbox-search-results .inbox-search-results-statistic-primary,
ssi-inbox-search-results-push .inbox-search-results-statistic-primary {
  border-color: #43ade8;
  color: #43ade8;
}
ssi-inbox-search-results .inbox-search-results-statistic-success,
ssi-inbox-search-results-push .inbox-search-results-statistic-success {
  border-color: #acb638;
  color: #acb638;
}
ssi-inbox-search-results .spacer-bottom.conversation-mode,
ssi-inbox-search-results-push .spacer-bottom.conversation-mode {
  background-color: red;
}
ssi-inbox-search-results ssi-inbox-query-result-list,
ssi-inbox-search-results-push ssi-inbox-query-result-list {
  display: flex;
  flex: 1;
  flex-direction: column;
}
ssi-inbox-search-results .inbox-query-result-list,
ssi-inbox-search-results-push .inbox-query-result-list {
  flex: 1;
  flex-basis: 0;
}
ssi-inbox-search-results .inbox-search-results-conversation-bar,
ssi-inbox-search-results-push .inbox-search-results-conversation-bar {
  background-color: #fff;
  display: flex;
  flex-shrink: 0;
  height: 51px;
  padding: 12px 35px;
  box-shadow: 0 5px 5px rgba(131, 142, 171, 0.2);
  border-radius: 12px;
  z-index: 1;
}
ssi-inbox-search-results .inbox-search-results-conversation-bar > *,
ssi-inbox-search-results-push .inbox-search-results-conversation-bar > * {
  align-items: center;
  display: flex;
  padding-left: 0;
  padding-right: 0;
  width: auto;
}
ssi-inbox-search-results .inbox-search-results-conversation-bar > *.col-xs-7,
ssi-inbox-search-results-push .inbox-search-results-conversation-bar > *.col-xs-7 {
  flex-grow: 1;
  justify-content: space-around;
  list-style: none;
  margin: 0;
  padding: 0;
}
ssi-inbox-search-results .inbox-search-results-conversation-bar > *.col-xs-7 li,
ssi-inbox-search-results-push .inbox-search-results-conversation-bar > *.col-xs-7 li {
  margin: 0;
  padding: 0;
}
ssi-inbox-search-results .inbox-search-results-conversation-bar .btn-exit-conversation,
ssi-inbox-search-results-push .inbox-search-results-conversation-bar .btn-exit-conversation {
  background-color: #f8f9fd;
  color: #43537f;
  font-weight: bold;
  border-color: #a4baf3;
  border-radius: 30px;
  font-size: 12px;
  padding: 4px 12px;
}
ssi-inbox-search-results .inbox-search-results-conversation-bar .btn-exit-conversation i,
ssi-inbox-search-results-push .inbox-search-results-conversation-bar .btn-exit-conversation i {
  margin-right: 2px;
  font-size: 14px;
  font-weight: 900;
}
ssi-inbox-search-results .inbox-search-results-conversation-bar .inbox-search-results-conversation-total,
ssi-inbox-search-results-push .inbox-search-results-conversation-bar .inbox-search-results-conversation-total {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  color: #838eab;
  font-weight: normal;
  font-size: 14px;
}
ssi-inbox-search-results .inbox-search-results-conversation-bar .inbox-search-results-conversation-total-separator,
ssi-inbox-search-results-push .inbox-search-results-conversation-bar .inbox-search-results-conversation-total-separator {
  width: 2px;
  height: 20px;
  border-radius: 3px;
  margin: 0 4px;
}
ssi-inbox-search-results .inbox-search-results-conversation-bar .inbox-search-results-conversation-total.unread .inbox-search-results-conversation-total-separator,
ssi-inbox-search-results-push .inbox-search-results-conversation-bar .inbox-search-results-conversation-total.unread .inbox-search-results-conversation-total-separator {
  background-color: #14bae3;
}
ssi-inbox-search-results .inbox-search-results-conversation-bar .inbox-search-results-conversation-total.unactioned .inbox-search-results-conversation-total-separator,
ssi-inbox-search-results-push .inbox-search-results-conversation-bar .inbox-search-results-conversation-total.unactioned .inbox-search-results-conversation-total-separator {
  background-color: #d1dbf4;
}
ssi-inbox-search-results .inbox-search-results-conversation-bar .inbox-search-results-conversation-total.actioned .inbox-search-results-conversation-total-separator,
ssi-inbox-search-results-push .inbox-search-results-conversation-bar .inbox-search-results-conversation-total.actioned .inbox-search-results-conversation-total-separator {
  background-color: #acb543;
}
ssi-inbox-search-results .bottom-box-shadow,
ssi-inbox-search-results-push .bottom-box-shadow {
  box-shadow: 0 5px 5px rgba(131, 142, 171, 0.2);
}
ssi-inbox-search-results .push-mode-list-status-container,
ssi-inbox-search-results-push .push-mode-list-status-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  color: #43537f;
  padding: 12px 0;
  border-top: 2px solid #ecf0fb;
  border-bottom: 2px solid #ecf0fb;
  text-transform: uppercase;
}
ssi-inbox-search-results .push-mode-list-status-container i,
ssi-inbox-search-results-push .push-mode-list-status-container i {
  font-size: 7px;
  padding: 4px;
  border-radius: 50%;
  background-color: #43537f;
  color: #fff;
  margin-right: 5px;
}
ssi-inbox-search-results .push-mode-list-status-container i.active,
ssi-inbox-search-results-push .push-mode-list-status-container i.active {
  background-color: #b2c614;
}
ssi-inbox-search-results .push-mode-list-status-container i.on-hold,
ssi-inbox-search-results-push .push-mode-list-status-container i.on-hold {
  background-color: #d41d68;
}
ssi-inbox-search-results .push-mode-list-status-container.on-hold-only,
ssi-inbox-search-results-push .push-mode-list-status-container.on-hold-only {
  border-top: none;
}
ssi-inbox-search-results .upper-list,
ssi-inbox-search-results .lower-list,
ssi-inbox-search-results-push .upper-list,
ssi-inbox-search-results-push .lower-list {
  display: flex;
  flex-direction: column;
}
ssi-inbox-search-results .upper-list.full-height,
ssi-inbox-search-results .lower-list.full-height,
ssi-inbox-search-results-push .upper-list.full-height,
ssi-inbox-search-results-push .lower-list.full-height {
  height: 100%;
}
ssi-inbox-search-results .upper-list.truncated,
ssi-inbox-search-results .lower-list.truncated,
ssi-inbox-search-results-push .upper-list.truncated,
ssi-inbox-search-results-push .lower-list.truncated {
  height: auto;
}
ssi-inbox-search-results .upper-list,
ssi-inbox-search-results-push .upper-list {
  height: 60%;
}
ssi-inbox-search-results .lower-list,
ssi-inbox-search-results-push .lower-list {
  height: 40%;
  min-height: 290px;
}