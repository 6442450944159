@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-tags-select-create {
  .tags-select-create-container {
    ssi-dropdown-select-2 {
      width: 100%;

      .dd-head {
        width: 100%;
        padding: 0 35px 0 5px;
      }

      .dd-body {
        width: 100%;
        ul {
          max-height: 400px !important;
        }
      }
    }

    &.no-selected-options {
      width: 100%;
      ssi-dropdown-select-2 {
        .dd-head {
          padding: 0 30px 0 30px;
        }
      }
    }

    .select-placeholder {
      font-size: 14px;
      font-weight: 700;
      color: #6a759a;
      text-wrap: nowrap;
    }

    .selected-options-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .chips-container {
        display: flex;
        align-items: center;
        overflow: hidden;

        .chip {
          height: 36px;
          background-color: $lgrey-background;
          border-radius: 8px;
          padding: 0 10px;
          white-space: nowrap;
          margin-right: 5px;

          display: flex;
          align-items: center;

          span {
            font-size: 14px;
            color: #101525;
            font-weight: 900;
            margin: 0;
            margin-left: 10px;
          }

          i {
            font-size: 8px;
            color: $lmode-body-text;
            font-weight: 500;
            margin-left: 10px;
            cursor: pointer;

            &:first-child {
              font-size: 19px;
              color: $lmode-helper-text;
            }
          }
        }
      }
    }

    .empty-state {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      padding-right: 10px;
      // height: 160px;

      i {
        margin-top: 20px;
        color: #b4bbce;
        font-size: 30px;
      }

      .title {
        margin-top: 15px;
        color: $lmode-body-text;
        font-size: 16px;
        font-weight: 900;
      }

      .desc {
        margin-bottom: 20px;
        color: $lmode-body-text;
        font-size: 14px;
        font-weight: 700;
        padding: 0 24px;
        text-align: center;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        width: calc(100% + 20px);

        i {
          margin: 0 -8px 0 12px;
          font-size: 12px;
          font-weight: 900;
          color: #fff;
        }

        span {
          white-space: nowrap;
        }
      }
    }
  }
}
