ssi-inbox-search-results.conversation-detail .list-select-option > a {
  background-color: transparent;
}

.list-select {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  list-style: none;
  margin: 0;
  padding: 0;
}
.list-select .list-select-option {
  align-items: center;
  display: flex;
  flex-grow: 1;
  margin-bottom: 6px;
}
.list-select .list-select-option a {
  background-color: #3f3f49;
  display: inline-block;
  padding: 4px 10px;
  font-size: 11px;
  color: #fff;
  font-weight: normal;
  flex: 1;
}
.list-select .list-select-option a:hover {
  text-decoration: none;
  background-color: #4b4b57;
}
.list-select .list-select-option .list-select-option-icon-outer {
  margin-left: 8px;
  font-size: 13px;
  color: #c9d8e0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
}
.list-select .list-select-option .list-select-option-icon-inner {
  float: right;
}
.list-select .list-select-option .icon-selected {
  color: #14bae3;
}
.list-select .list-select-option.active a {
  background-color: #14bae3;
}
.list-select .list-select-option.active a:hover {
  background-color: #25c4ec;
}
.list-select * > * {
  display: flex;
}

ssi-list-select.options-white .list-select-option a {
  background-color: white !important;
  color: #555555 !important;
}

ssi-list-select.options-light .list-select-option a {
  background-color: #f8f8f8 !important;
  color: #14bae3 !important;
}

.list-select.query-builder-width .list-select-option a,
ssi-list-select.query-builder-width .list-select-option a {
  flex-basis: 75%;
  flex-grow: 0;
  flex-shrink: 0;
}

ssi-list-select.options-light .list-select-option.active a,
ssi-list-select.options-light .list-select-option a:hover,
ssi-list-select.options-white .list-select-option.active a,
ssi-list-select.options-white .list-select-option a:hover {
  background-color: #14bae3 !important;
  color: white !important;
}

ssi-list-select.options-lg .list-select-option a {
  font-size: 15px;
}
ssi-list-select.options-lg .list-select-option-icon-outer i {
  font-size: 18px;
}

ssi-list-select.option-active-success .list-select-option.active a,
ssi-list-select.option-active-success .list-select-option a:hover {
  background-color: #acb638 !important;
  color: white !important;
}
ssi-list-select.option-active-success .icon-selected {
  color: #acb638 !important;
}

ssi-list-select.option-active-royal .list-select-option.active a,
ssi-list-select.option-active-royal .list-select-option a:hover {
  background-color: #a12378 !important;
  color: white !important;
}
ssi-list-select.option-active-royal .icon-selected {
  color: #a12378 !important;
}

ssi-list-select.option-circles-hidden .list-select-option-icon-outer {
  display: none !important;
}