ssi-live-chat-push-mode-agent-encouragement {
  align-items: center;
  flex-grow: 1;
  justify-content: center;
}
ssi-live-chat-push-mode-agent-encouragement .encouragement {
  align-items: center;
  flex-flow: column nowrap;
  flex-shrink: 0;
  text-align: center;
}
ssi-live-chat-push-mode-agent-encouragement .encouragement .encouragement-icon {
  color: #A4B5BE;
  font-size: 15vh;
}
ssi-live-chat-push-mode-agent-encouragement .encouragement .encouragement-message {
  color: #A4B5BE;
  font-size: 1.4em;
  font-weight: 600;
}
ssi-live-chat-push-mode-agent-encouragement .encouragement .encouragement-message > * {
  padding: 0 2.5px;
}