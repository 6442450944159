ssi-dashboard ssi-create {
  font-size: 1rem;
}
ssi-dashboard ssi-create .dashboard-create-container {
  width: 100%;
  position: relative;
  background-color: #f4f4fa;
  min-height: calc(100vh - 68px);
  overflow-x: hidden;
}
ssi-dashboard ssi-create .dashboard-create-container.no-widgets {
  display: flex;
  align-items: center;
  justify-content: center;
}
ssi-dashboard ssi-create .dashboard-create-container.no-widgets .create-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
ssi-dashboard ssi-create .dashboard-create-container.no-widgets .create-card h2 {
  font-size: 2em;
  line-height: 1;
  margin: 0 0 1.2rem;
  color: #43537f;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-dashboard ssi-create .dashboard-create-container.no-widgets .create-card p {
  color: #43537f;
  font-weight: 700;
  text-rendering: geometricPrecision;
}
ssi-dashboard ssi-create .dashboard-create-container.no-widgets .create-card-icon {
  font-size: 4em;
  line-height: 1;
  color: #b4bbce;
  margin: 0 0 3rem;
}
ssi-dashboard ssi-create .dashboard-create-container.no-widgets .create-card-button {
  margin: 1em 0 0;
}
ssi-dashboard ssi-create .dashboard-create-container.no-widgets .create-card-button .ssi {
  font-size: 0.7em;
}
ssi-dashboard ssi-create .dashboard-create-container.has-widgets .nav-bar {
  height: 60px;
  width: 100%;
  border-bottom: 1px solid black;
  display: flex;
  align-items: center;
  padding: 10px;
}
ssi-dashboard ssi-create .dashboard-create-container.has-widgets .dashboard-content .actions-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding: 20px 20px 10px 20px;
}
ssi-dashboard ssi-create .dashboard-create-container.has-widgets .dashboard-content .actions-bar .left {
  display: flex;
  align-items: center;
}
ssi-dashboard ssi-create .dashboard-create-container.has-widgets .dashboard-content .actions-bar .right {
  display: flex;
  align-items: center;
}
ssi-dashboard ssi-create .dashboard-create-container.has-widgets .dashboard-content .actions-bar .right .save-btn,
ssi-dashboard ssi-create .dashboard-create-container.has-widgets .dashboard-content .actions-bar .right .save-as-template-btn {
  margin-left: 10px;
}
ssi-dashboard ssi-create .dashboard-create-container .dashboard-templates-menu .templates-button {
  min-width: 300px;
  display: flex;
  justify-content: flex-start;
}
ssi-dashboard ssi-create .dashboard-create-container .dashboard-templates-menu .templates-button .templates-select-arrow-icon {
  margin-left: auto;
  margin-right: 0;
  color: #14bae3;
  font-weight: 900;
}
ssi-dashboard ssi-create .dashboard-create-container .dashboard-templates-menu .menu-body .templates-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
ssi-dashboard ssi-create .dashboard-create-container .dashboard-templates-menu .menu-body .templates-list-item .delete-icon {
  color: #f88c68;
}