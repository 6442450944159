ssi-theme-detector-create-edit .theme-detector-create-edit-container .section-head {
  padding: 0;
  margin-left: 20px;
}
ssi-theme-detector-create-edit .theme-detector-create-edit-container .section-head .title {
  display: flex;
  align-items: center;
}
ssi-theme-detector-create-edit .theme-detector-create-edit-container .section-head .title h2 {
  color: #43537f;
  font-size: 20px;
  font-weight: 900;
  line-height: 40px;
  margin: 0;
}
ssi-theme-detector-create-edit .theme-detector-create-edit-container .section-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
ssi-theme-detector-create-edit .theme-detector-create-edit-container .section-body form.create-edit-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}
ssi-theme-detector-create-edit .theme-detector-create-edit-container .section-body form.create-edit-form .form-field-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 274px;
  margin-top: 40px;
  margin-left: 40px;
}
ssi-theme-detector-create-edit .theme-detector-create-edit-container .section-body form.create-edit-form .form-field-group.double-length {
  width: 590px;
}
ssi-theme-detector-create-edit .theme-detector-create-edit-container .section-body form.create-edit-form .form-field-group .form-field {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}
ssi-theme-detector-create-edit .theme-detector-create-edit-container .section-body form.create-edit-form .form-field-group .form-field.half {
  flex-basis: 48%;
}
ssi-theme-detector-create-edit .theme-detector-create-edit-container .section-body form.create-edit-form .form-field-group .form-field > label {
  color: #43537f;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-left: 20px;
  margin-bottom: 15px;
  min-height: 14px;
}
ssi-theme-detector-create-edit .theme-detector-create-edit-container .section-body form.create-edit-form .form-field-group .form-field .input-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
ssi-theme-detector-create-edit .theme-detector-create-edit-container .section-body form.create-edit-form .form-field-group .form-field .input-wrapper ssi-dropdown-select-2 {
  width: 100%;
}
ssi-theme-detector-create-edit .theme-detector-create-edit-container .section-body form.create-edit-form .form-field-group .form-field .input-wrapper ssi-dropdown-select-2 button.dd-head {
  width: 100%;
  border-radius: 10px;
}
ssi-theme-detector-create-edit .theme-detector-create-edit-container .section-body form.create-edit-form .form-field-group .form-field .input-wrapper .select-placeholder {
  color: #838eab;
  font-size: 14px;
  font-weight: 700;
}
ssi-theme-detector-create-edit .theme-detector-create-edit-container .section-body form.create-edit-form .form-field-group .form-field .input-wrapper .select-label {
  color: #101525;
  font-size: 14px;
  font-weight: 900;
  display: flex;
  align-items: center;
  max-width: 200px;
  overflow: hidden;
}
ssi-theme-detector-create-edit .theme-detector-create-edit-container .section-body form.create-edit-form .form-field-group .form-field .input-wrapper .select-label span {
  white-space: nowrap;
}
ssi-theme-detector-create-edit .theme-detector-create-edit-container .section-body form.create-edit-form .form-field-group .form-field .input-wrapper .select-label ssi-social-network-icon {
  margin-right: 8px;
}
ssi-theme-detector-create-edit .theme-detector-create-edit-container .section-body form.create-edit-form .form-field-group .form-field .input-wrapper .text-input {
  width: 100%;
  height: 46px;
  border: none;
  outline: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  color: #43537f;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 900;
  background-color: #f8f9fd;
  padding: 10px 30px 10px 20px;
}
ssi-theme-detector-create-edit .theme-detector-create-edit-container .section-body form.create-edit-form .form-field-group .form-field .input-wrapper .text-input::placeholder {
  color: #838eab;
  font-size: 13px;
  font-weight: 700;
}
@media print {
  ssi-theme-detector-create-edit .theme-detector-create-edit-container .section-body form.create-edit-form .submit-button {
    display: none !important;
  }
}
ssi-theme-detector-create-edit .theme-detector-create-edit-container .section-foot {
  display: flex;
  align-items: center;
  padding-top: 40px;
}
ssi-theme-detector-create-edit .theme-detector-create-edit-container .section-foot .buttons {
  width: 100%;
  margin-left: 40px;
  margin-right: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
ssi-theme-detector-create-edit .theme-detector-create-edit-container .section-foot .buttons .delete-button {
  margin-right: auto;
}
ssi-theme-detector-create-edit .theme-detector-create-edit-container .section-foot .buttons .delete-button i {
  font-weight: 500;
}
ssi-theme-detector-create-edit .theme-detector-create-edit-container .section-foot .buttons .submit-button {
  margin-left: 10px;
}