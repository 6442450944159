ssi-policy {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  height: 100vh;
  overflow: scroll;
}
ssi-policy .container {
  margin-top: -68px;
}
ssi-policy .policy-title {
  color: #fff;
}
ssi-policy .well {
  max-height: 450px;
}