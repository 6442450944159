ssi-report-action-bar {
  font-size: 1rem;
}
ssi-report-action-bar .report-action-bar {
  margin: 0 0 1em;
  color: #43537f;
}
ssi-report-action-bar .report-action-bar-container {
  display: flex;
  height: 100%;
  padding: 0 2em;
  margin: 0 auto;
}
ssi-report-action-bar .report-action-bar-container-wrap {
  flex-wrap: wrap;
}
ssi-report-action-bar .report-action-bar-header {
  height: 60px;
  background-color: #fff;
  border-bottom: 0.1em solid #838eab;
}
ssi-report-action-bar .report-action-bar-header-left {
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 1 auto;
  margin: 0 2em;
  overflow: hidden;
}
ssi-report-action-bar .report-action-bar-header-right {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  margin: 0 0 0 auto;
}
ssi-report-action-bar .report-header-name {
  color: #101525;
  font-size: 1.8em;
  margin: 0;
  margin-right: 2em;
  font-weight: 900;
  text-rendering: geometricPrecision;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
ssi-report-action-bar .report-action-bar-action-wrap {
  margin: 0 2em 0 -5rem;
  height: 1.6em;
  align-self: center;
}
ssi-report-action-bar .report-action-bar-action {
  font-size: 1.2em;
  border-width: 0;
  outline-width: 0;
  background-color: transparent;
  appearance: none;
}
ssi-report-action-bar .report-action-bar-dropdown-wrap {
  font-size: 1rem;
  border-width: 0;
  background-color: #fff;
  padding: 1.3em 1.8em 3.8em 1.8em;
  margin: 0.8em 0 0 -2.6em;
  background-color: #fff;
  border-radius: 1.5em;
  box-shadow: 0 1.8em 4em rgba(131, 142, 171, 0.35);
  min-width: 34.2em;
  border-width: 0;
}
ssi-report-action-bar .report-action-bar-dropdown {
  margin: 0;
  padding: 0;
}
ssi-report-action-bar .report-action-bar-dropdown li {
  display: block;
  padding: 1em 1.8em;
}
ssi-report-action-bar .report-action-bar-dropdown p {
  margin: 0;
}
ssi-report-action-bar .report-action-bar-dropdown-heading p {
  color: #838eab;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  font-weight: 900;
  text-transform: uppercase;
  margin: 1.5rem 0 0.5rem;
}
ssi-report-action-bar .report-action-bar-dropdown-item {
  border-radius: 1.4em;
  margin: 0 0 0.5em;
  cursor: pointer;
}
ssi-report-action-bar .report-action-bar-dropdown-item:hover, ssi-report-action-bar .report-action-bar-dropdown-item:focus {
  background-color: #f8f9fd;
}
ssi-report-action-bar .report-action-bar-dropdown-item p {
  color: #43537f;
  font-weight: 900;
}
ssi-report-action-bar .report-action-bar-dropdown-footer {
  margin: 1.5em -1.8em 0;
  border-top: 0.1em solid #c3c9d8;
  padding: 3em 1.8em 0;
}
ssi-report-action-bar .report-action-bar-dropdown-footer-item {
  display: block;
  cursor: pointer;
}
ssi-report-action-bar .report-action-bar-dropdown-footer-item .ssi {
  font-size: 0.8em;
  margin: 0 0.6rem 0 0.2rem;
}
ssi-report-action-bar .report-action-bar-dropdown-footer-item p {
  display: flex;
  align-items: center;
  margin: 0;
  color: #14bae3;
  font-weight: 900;
}
ssi-report-action-bar .report-warning {
  padding: 1em;
  width: 100%;
  background-color: #fff;
  font-size: 1.4em;
  font-weight: 900;
  color: #43537f;
}
ssi-report-action-bar .report-warning .report-warning-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2em;
  width: 100%;
  border-radius: 10px;
  background-color: #fff1ed;
}
ssi-report-action-bar .report-warning .report-warning-inner div {
  padding-right: 20px;
}
ssi-report-action-bar .report-warning .ssi {
  padding: 0 10px;
  color: #f88c68;
  cursor: pointer;
}
ssi-report-action-bar .report-warning .ssi-delete-close {
  position: absolute;
  right: 2.5em;
  cursor: pointer;
}
ssi-report-action-bar .report-action-bar-actions {
  width: 100%;
  height: auto;
  min-height: 60px;
  background-color: #f4f4fa;
  border-bottom: 0.1em solid #838eab;
}
ssi-report-action-bar .report-action-bar-actions-left {
  display: inline-flex;
  align-items: center;
  flex: 1 1 auto;
  margin: 1em 0;
}
ssi-report-action-bar .report-action-bar-actions-right {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1 1 auto;
  margin: 1em 0 1em 0;
}
ssi-report-action-bar .posts-selected {
  flex: 0 1 auto;
}
ssi-report-action-bar .report-action-bar-button {
  background-color: #14bae3;
  padding: 0 2.2em;
  height: 3.6em;
  border-radius: 1em;
  border-width: 0;
}
ssi-report-action-bar .report-action-bar-button span {
  color: #fff;
  font-size: 1.4em;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-report-action-bar .report-actions {
  display: flex;
  flex: 1 0 100%;
  justify-content: flex-end;
  width: 100%;
}
ssi-report-action-bar .report-actions-list {
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 auto;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 100%;
}
ssi-report-action-bar .report-actions-list li {
  display: inline-block;
}
ssi-report-action-bar .report-actions-list-action {
  margin: 0;
}
ssi-report-action-bar .report-actions-list-action button {
  position: relative;
  font-size: 1.4em;
  height: 3.6rem;
  padding: 0.6rem 2rem 0.6rem 1.6rem;
  color: #43537f;
  background-color: transparent;
  border-width: 0;
  font-weight: 900;
  text-rendering: geometricPrecision;
  white-space: nowrap;
  border-radius: 1rem;
}
ssi-report-action-bar .report-actions-list-action button[disabled] {
  cursor: not-allowed;
  opacity: 0.6;
}
ssi-report-action-bar .report-actions-list-action button:hover {
  background-color: #e5eaf6;
}
ssi-report-action-bar .report-actions-list-action button .ssi {
  font-size: 1.2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.2rem 0 0;
  height: 2rem;
  width: 2rem;
}
ssi-report-action-bar .report-actions-list-action button .ssi.ssi-more-actions {
  font-size: 1.3rem;
}
ssi-report-action-bar .report-actions-list-action button .ssi.ssi-date-preset {
  font-size: 1.4rem;
}
ssi-report-action-bar .report-actions-list-action .dropdown-menu {
  font-size: 1em;
  padding: 2.8em 1.5em 3em 2em;
  margin: 0.8em 0 0 50%;
  background-color: #fff;
  border-radius: 1.5em;
  box-shadow: 0 1.8em 4em rgba(131, 142, 171, 0.35);
  min-width: 22.2em;
  border-width: 0;
  transform: translateX(-50%);
}
ssi-report-action-bar .report-actions-list-action .dropdown-menu-md {
  min-width: 24.4em;
}
ssi-report-action-bar .report-actions-list-action .dropdown-menu-break-wrap {
  display: block;
}
ssi-report-action-bar .report-actions-list-action .dropdown-menu-break {
  margin: 1.8em -1.5em 2em -2.2em;
  border-color: #c3c9d8;
}
ssi-report-action-bar .report-actions-list-action .dropdown-menu-item {
  display: flex;
  align-items: center;
  height: 3.6rem;
  padding: 0 1em 0 2rem;
  border-radius: 1rem;
  margin: 0.2rem 0;
  cursor: pointer;
}
ssi-report-action-bar .report-actions-list-action .dropdown-menu-item-radio-list-inline > .ssi:first-child {
  margin-left: -1.6rem;
}
ssi-report-action-bar .report-actions-list-action .dropdown-menu-item span {
  font-size: 1.4rem;
  font-weight: 900;
  text-rendering: geometricPrecision;
  color: #43537f;
}
ssi-report-action-bar .report-actions-list-action .dropdown-menu-item:hover {
  background-color: #f8f9fd;
}
ssi-report-action-bar .report-actions-list-action .report-actions-list-action-badge {
  position: absolute;
  display: block;
  height: 1.2rem;
  width: 1.2rem;
  margin-top: -0.4rem;
  margin-left: -0.4rem;
  border-radius: 50%;
  font-size: 0.8rem;
  background-color: #14bae3;
  color: #fff;
  overflow: hidden;
}
ssi-report-action-bar .report-actions-list-action .report-actions-list-action-badge + .ssi {
  font-size: 0.8rem;
  margin: 0 0.6rem 0 0;
  border-radius: 50%;
  background-color: rgba(20, 186, 227, 0.2);
}
ssi-report-action-bar .report-actions-bar-bulk-actions {
  display: flex;
  background-color: #dbe2f7;
  border-radius: 1em;
  padding: 0.7em;
  height: 4rem;
  margin-top: 1em;
  flex: 1 1 auto;
}
ssi-report-action-bar .report-bulk-actions-list-item {
  padding: 0 1em 0 2rem;
  margin: 0.2rem 0;
  cursor: pointer;
}
ssi-report-action-bar .report-bulk-actions-list-item .ssi {
  padding: 0 1em;
  font-size: 14px;
  align-self: center;
}
ssi-report-action-bar .report-bulk-actions-list-item span {
  font-family: Lato, sans-serif;
  font-weight: 900;
  font-size: 14px;
}
ssi-report-action-bar .report-bulk-actions-list-item .delete-icon {
  color: #cc3300;
}
ssi-report-action-bar .report-action-bar-selected-posts-container {
  display: flex;
  align-items: center;
}
ssi-report-action-bar .report-action-bar-selected-posts-container span {
  font-family: Lato, sans-serif;
  font-weight: 900;
  font-size: 14px;
}
ssi-report-action-bar .report-bulk-actions-list {
  display: flex;
  flex: 0 1 auto;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 100%;
}
ssi-report-action-bar .report-bulk-actions-list li {
  display: flex;
  flex-direction: row;
}
ssi-report-action-bar .dropdown-menu-item-icon {
  color: #43537f;
  font-size: 1.4rem;
  margin: 0 1.5rem 0 0;
  color: #838eab;
}
ssi-report-action-bar .dropdown-menu-item-icon-small {
  margin: 0 0.6rem 0 0;
  font-size: 1rem;
  color: #43537f;
}
ssi-report-action-bar .dropdown-menu-item-icon-red {
  color: #f88c68;
}
ssi-report-action-bar .report-actions-toggles {
  display: flex;
  align-items: center;
  margin: 0 0 0 1em;
  padding: 0;
  list-style: none;
}
ssi-report-action-bar .report-actions-toggle {
  display: flex;
}
ssi-report-action-bar .report-actions-toggle label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  width: 5.5rem;
  background-color: #dbe2f7;
  color: #43537f;
  cursor: pointer;
  margin: 0;
  z-index: 2;
}
ssi-report-action-bar .report-actions-toggle label:last-child {
  border-bottom-right-radius: 1em;
  border-top-right-radius: 1em;
}
ssi-report-action-bar .report-actions-toggle label .ssi {
  font-size: 1.6em;
}
ssi-report-action-bar .report-actions-toggle label .ssi.ssi-post-results {
  font-size: 1.8rem;
}
ssi-report-action-bar .report-actions-toggle input {
  display: none;
}
ssi-report-action-bar .report-actions-toggle input:first-child + label {
  border-bottom-left-radius: 1em;
  border-top-left-radius: 1em;
}
ssi-report-action-bar .report-actions-toggle input:checked + label {
  background-color: #14bae3;
  color: #fff;
  z-index: 1;
  box-shadow: 0 0.5em 1em rgba(131, 142, 171, 0.2);
}
ssi-report-action-bar .report-actions-button {
  display: inline-block;
  margin: 0 0 0 1em;
}
ssi-report-action-bar .report-actions-button button {
  border-radius: 1em;
  height: 4em;
  width: 4em;
  background-color: #dbe2f7;
  color: #43537f;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-width: 0;
}
ssi-report-action-bar .report-actions-button-active button {
  color: #fff;
  background-color: #14bae3;
}
ssi-report-action-bar .report-actions-button i {
  font-size: 1.8em;
  color: inherit;
}
ssi-report-action-bar .filters-toggle-btn {
  margin-left: 10px;
}
ssi-report-action-bar .label-disabled {
  display: none;
}

.tooltip-insights {
  padding: 30px 18px 20px 30px;
}
.tooltip-insights .tooltip-insights-title {
  display: block;
  color: #fff;
  line-height: 20px;
  font-size: 20px;
  margin: 0 0 10px;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
.tooltip-insights .tooltip-insights-body {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-rendering: geometricPrecision;
  font-weight: 700;
  margin: 0;
  color: #b4bbce;
}
.tooltip-insights .button-disabled {
  cursor: not-allowed !important;
}