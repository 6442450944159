ssi-engagement ssi-average-response-time {
  font-size: 1rem;
}
ssi-engagement ssi-average-response-time .engagement-times-section ssi-highchart {
  width: 100%;
  height: 34em;
}
ssi-engagement ssi-average-response-time .engagement-times-section ssi-highchart highcharts-chart .highcharts-container,
ssi-engagement ssi-average-response-time .engagement-times-section ssi-highchart highcharts-chart .highcharts-root {
  overflow: visible !important;
}
ssi-engagement ssi-average-response-time .engagement-times-section ssi-highchart highcharts-chart .highcharts-axis-title {
  font-size: 1em;
  font-weight: 900;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  white-space: nowrap;
}
ssi-engagement ssi-average-response-time .engagement-times-section ssi-highchart highcharts-chart .highcharts-axis-labels text {
  font-weight: 900;
}
ssi-engagement ssi-average-response-time .engagement-times-section ssi-highchart highcharts-chart .highcharts-xaxis-labels {
  transform: translate(0, 1em);
}
ssi-engagement ssi-average-response-time .section-chart-wrap {
  padding: 3em 3em 6em 3em;
  background-color: #fff;
  border-radius: 1.2em;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
  margin: 0 0 2em;
}
@media print {
  ssi-engagement ssi-average-response-time .section-chart-wrap {
    page-break-inside: avoid;
  }
}
ssi-engagement ssi-average-response-time .top-row {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 3em;
}
ssi-engagement ssi-average-response-time .top-row .best-hours-container {
  display: flex;
  border-radius: 0.5em;
  border: 0.1em solid #f88c68;
  padding: 2em;
}
ssi-engagement ssi-average-response-time .top-row .best-hours-container .left {
  margin-right: 8em;
}
ssi-engagement ssi-average-response-time .top-row .best-hours-container .left span {
  color: #838eab;
  font-size: 1em;
  font-weight: 900;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-rendering: geometricPrecision;
}
ssi-engagement ssi-average-response-time .top-row .best-hours-container .middle {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
ssi-engagement ssi-average-response-time .top-row .best-hours-container .middle span {
  color: #838eab;
  font-size: 1.4em;
  font-weight: 700;
  text-rendering: geometricPrecision;
}
ssi-engagement ssi-average-response-time .top-row .best-hours-container .middle .hours span {
  color: #101525;
  font-size: 2em;
  font-weight: 900;
}
ssi-engagement ssi-average-response-time .top-row .best-hours-container .right {
  display: flex;
  align-items: center;
  margin-left: 2em;
}
ssi-engagement ssi-average-response-time .top-row .best-hours-container .right i {
  font-size: 3.2em;
  color: #ffe8df;
}
ssi-engagement ssi-average-response-time .chart-legend {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  margin-top: 3em;
}
ssi-engagement ssi-average-response-time .chart-legend .legend-item {
  display: flex;
  align-items: center;
  margin-right: 6em;
}
ssi-engagement ssi-average-response-time .chart-legend .legend-item:first-child {
  margin-right: 3em;
}
ssi-engagement ssi-average-response-time .chart-legend .legend-item span {
  color: #43537f;
  font-size: 1em;
  font-weight: 900;
  letter-spacing: 0.1em;
}
ssi-engagement ssi-average-response-time .chart-legend .legend-item .circle {
  height: 1.8em;
  width: 1.8em;
  border-radius: 50%;
  background-color: #000;
  margin-right: 1em;
  -webkit-print-color-adjust: exact;
}
ssi-engagement ssi-average-response-time .chart-legend .legend-item.great .circle {
  background-color: #f88c68 !important;
}
ssi-engagement ssi-average-response-time .chart-legend .legend-item.good .circle {
  background-color: #ffbfa9 !important;
}
ssi-engagement ssi-average-response-time .chart-legend .legend-item.okay .circle {
  background-color: #fee9e2 !important;
}
ssi-engagement ssi-average-response-time .chart-legend .legend-item.not-great .circle {
  background-color: #b2f0ff !important;
}
ssi-engagement ssi-average-response-time .chart-legend .legend-item.not-good .circle {
  background-color: #2bc1e6 !important;
}
ssi-engagement ssi-average-response-time .art-tile {
  border-radius: 1.2em;
  background-color: #fff;
  box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
  padding: 2.6em 2.6em 2.4em 3em;
  margin: 0 0 2em;
  color: #43537f;
}
ssi-engagement ssi-average-response-time .art-tile h2,
ssi-engagement ssi-average-response-time .art-tile h3,
ssi-engagement ssi-average-response-time .art-tile p {
  text-rendering: geometricPrecision;
}
ssi-engagement ssi-average-response-time .art-tile-large {
  padding: 4.5em 9em 5em 7em;
}
ssi-engagement ssi-average-response-time .art-tile-banner {
  position: relative;
}
ssi-engagement ssi-average-response-time .art-tile-banner > div {
  padding-right: 35em;
}
ssi-engagement ssi-average-response-time .art-tile-banner h2 {
  font-size: 2em;
  font-weight: 900;
  margin: 0 0 0.8em;
}
ssi-engagement ssi-average-response-time .art-tile-banner p {
  font-weight: 700;
  line-height: 1.4em;
  margin: 0 0 0.2em;
}
ssi-engagement ssi-average-response-time .art-tile-banner img {
  position: absolute;
  bottom: 0;
  right: 8.7em;
  width: 31.5em;
  max-height: 17.7em;
}
ssi-engagement ssi-average-response-time .art-tile-copy {
  position: relative;
  padding: 2.6em 8em 2.6em 3em;
}
ssi-engagement ssi-average-response-time .art-tile-copy h3 {
  color: #101525;
  font-size: 1.4em;
  margin: 0 0 0.4em;
  font-weight: 900;
}
ssi-engagement ssi-average-response-time .art-tile-copy p {
  color: #838eab;
  margin: 0;
  line-height: 1.4em;
  font-weight: 700;
}
ssi-engagement ssi-average-response-time .art-tile-copy-icon {
  position: absolute;
  top: 2.6em;
  right: 2.6em;
  height: 3.6em;
  width: 3.6em;
  background-color: #f4f4fa;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
ssi-engagement ssi-average-response-time .art-tile-copy-icon i {
  font-size: 1.8em;
  color: #43537f;
}
ssi-engagement ssi-average-response-time .art-tile-copy-icon:hover, ssi-engagement ssi-average-response-time .art-tile-copy-icon:focus {
  background-color: #14bae3;
  cursor: pointer;
}
ssi-engagement ssi-average-response-time .art-tile-copy-icon:hover i, ssi-engagement ssi-average-response-time .art-tile-copy-icon:focus i {
  color: #fff;
}