@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-paginator {
  * {
    box-sizing: border-box;
  }

  //height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  // justify-content: space-between;
  justify-content: center;

  .flx-paginator {
    flex: 0 0 auto;
    margin: 0.5rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .dots {
      opacity: 0.5;
      user-select: none;
      font-size: 14px;
    }

    button {
      flex: 0 0 auto;
      height: 1.8rem;
      min-width: 1.8rem;
      background: transparent;
      border: 0;
      cursor: pointer;

      i {
        color: #40337f;
      }

      &.page-link {
        display: flex;
        align-items: center;
        font-weight: 900;
        font-size: 14px;
        color: $lmode-body-text;
        // background-color: $grey-background;
        margin: 0 6px;
        position: relative;

        &::before {
          content: '';
          transform: scaleX(0);
          visibility: hidden;
          position: absolute;
          top: 22px;
          height: 3px;
          width: 98%;
          left: 1%;
          background-color: $primary-active;
          border-radius: 10px;
          transition: all 0.3s ease-in-out 0s;
        }

        &.active {
          font-weight: 900;
          color: $primary-active;
          // background-color: rgba(20, 186, 227, 0.1);
        }

        &.active,
        &:hover {
          &:before {
            transform: scaleX(1);
            visibility: visible;
          }
        }

        &:disabled {
          // opacity: 0.5;
          cursor: default;
        }
      }

      &.navigate-btn {
        display: flex;
        align-items: center;
        // background-color: $grey-background;
        color: $primary-active;
        text-align: center;
        border: none;
        border-radius: 3px;
        margin: 0;

        &:disabled {
          opacity: 0.3;
          cursor: not-allowed;
        }

        &.left {
          margin-right: 1em;
        }

        &.right {
          margin-left: 1rem;
        }

        i {
          color: $primary-active;
          font-weight: 900;
          font-size: 12px;
        }
      }
    }
  }

  .flx-paginator-settings {
    flex: 0 0 auto;
    margin: 0.5rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    select {
      height: 1.8rem;
      border: 1px solid #40337f;
      border-radius: 3px;
      margin-right: 1rem;
      background: transparent;
      cursor: pointer;
    }

    .span {
      flex: 0 0 auto;
      height: 1.8rem;
      margin: 0 0.2rem 0 0.2rem;
      border: 0;
    }
  }

  // .chevron-container {
  //   position: relative;
  //   height: 1.3rem;
  //   width: 1.3rem;
  //   background-color: transparent;

  //   .chevron {
  //     position: absolute;
  //     width: 100%;
  //     height: 100%;
  //     opacity: 1;
  //     user-select: none;
  //     cursor: pointer;
  //     top: 0;
  //     left: 0;

  //     &:before {
  //       height: 0.5em;
  //       width: 0.5em;
  //       border-width: 0.25em 0.25em 0 0;
  //       border-style: solid;
  //       content: '';
  //       display: inline-block;
  //       position: relative;
  //       top: 0.4em;
  //       left: 0;
  //       vertical-align: top;
  //       transform: rotate(45deg);
  //     }

  //     &.chevron-left {
  //       &:before {
  //         transform: rotate(225deg);
  //       }
  //     }
  //   }
  // }
}
