ssi-message-footer {
  font-size: 1rem;
}
ssi-message-footer .message-box-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1em 0 0;
}
ssi-message-footer .message-box-footer-actions {
  margin: 0;
  min-height: 1em;
  padding: 0 0 0 2em;
}
ssi-message-footer .message-box-footer-actions > li {
  display: inline-block;
}
ssi-message-footer .message-box-footer-actions ~ ssi-message-actions-inline {
  margin-bottom: -1em;
}
ssi-message-footer .message-box-footer-action {
  position: relative;
  color: #14bae3;
  margin: 0 0.6em 0 0;
}
ssi-message-footer .message-box-footer-action > p, ssi-message-footer .message-box-footer-action > span, ssi-message-footer .message-box-footer-action > i {
  margin: 0;
  text-transform: uppercase;
  font-size: 1em;
  font-weight: 900;
  letter-spacing: 0.1em;
  text-rendering: geometricPrecision;
  cursor: pointer;
}
ssi-message-footer .message-box-footer-action:hover, ssi-message-footer .message-box-footer-action:active {
  color: #1094b4;
}
ssi-message-footer .message-box-footer-action-inaction > p:not(.mouse-pointer), ssi-message-footer .message-box-footer-action-inaction > span:not(.mouse-pointer), ssi-message-footer .message-box-footer-action-inaction > i:not(.mouse-pointer) {
  cursor: auto;
}
ssi-message-footer .message-box-footer-action-inaction:hover, ssi-message-footer .message-box-footer-action-inaction:active {
  color: #14bae3;
}
ssi-message-footer .message-box-footer-action.message-box-footer-action-button {
  display: inline-flex;
  align-items: center;
  background-color: #668aea;
  padding: 0.5em 1em;
  border-radius: 2em;
  color: #fff;
  letter-spacing: 0;
  text-transform: none;
  cursor: pointer;
}
ssi-message-footer .message-box-footer-action.message-box-footer-action-button > p, ssi-message-footer .message-box-footer-action.message-box-footer-action-button > span, ssi-message-footer .message-box-footer-action.message-box-footer-action-button > i {
  letter-spacing: inherit;
  text-transform: inherit;
}
ssi-message-footer .message-box-footer-action.message-box-footer-action-button > p::selection, ssi-message-footer .message-box-footer-action.message-box-footer-action-button > span::selection, ssi-message-footer .message-box-footer-action.message-box-footer-action-button > i::selection {
  background-color: transparent;
}
ssi-message-footer .message-box-footer-action.message-box-footer-action-button > p {
  font-size: 1.2em;
  margin: 0 0.4em 0 0;
}
ssi-message-footer .message-box-footer-action.message-box-footer-action-button > i {
  font-size: 0.7em;
  font-weight: 900;
}
ssi-message-footer .message-box-footer-message-actions {
  align-self: flex-end;
  margin-bottom: -1em;
}