ssi-publisher-social-network-preview-modal .modal-close-icon {
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  color: #a3b5c0;
  position: relative;
  background-color: #eff2f8;
  padding: 8px;
  border-radius: 50px;
  border: 0;
  z-index: 1;
}
ssi-publisher-social-network-preview-modal .modal-close-icon i {
  color: #838eab;
}
ssi-publisher-social-network-preview-modal .modal-header {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px 10px 30px 40px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
ssi-publisher-social-network-preview-modal .modal-body {
  padding: 20px 10px;
  text-align: center;
  background-color: #fff;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: inset 0 -17px 14px -14px rgba(131, 142, 171, 0.35), inset 0 17px 14px -14px rgba(131, 142, 171, 0.35);
}
ssi-publisher-social-network-preview-modal .heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 30px;
}
ssi-publisher-social-network-preview-modal .content-left {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
}
ssi-publisher-social-network-preview-modal .content-left h3,
ssi-publisher-social-network-preview-modal .content-left h5 {
  width: 100%;
  margin: 5px 0;
}
ssi-publisher-social-network-preview-modal .content-left h3 {
  color: #43537f;
  font-weight: 900;
  font-size: 20px;
}
ssi-publisher-social-network-preview-modal .content-left h5 {
  color: #515b77;
  font-weight: 700;
}
ssi-publisher-social-network-preview-modal .dropdown .dropdown-toggle {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #eff2f8;
  border-radius: 10px;
  border: none;
  padding: 12px;
  color: #14bae3;
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
}
ssi-publisher-social-network-preview-modal .dropdown .dropdown-toggle span {
  width: 100%;
  margin: 0 2px;
}
ssi-publisher-social-network-preview-modal .dropdown .dropdown-menu {
  border-radius: 10px;
  padding: 10px;
}
ssi-publisher-social-network-preview-modal .dropdown .dropdown-menu a {
  color: #43537f;
  background: none;
  font-weight: bold;
  border-radius: 10px;
  margin-bottom: 5px;
}
ssi-publisher-social-network-preview-modal .dropdown .dropdown-menu a:hover {
  background-color: #f8f9fd;
}
ssi-publisher-social-network-preview-modal .dropdown .dropdown-menu a.active {
  color: #fff;
}
ssi-publisher-social-network-preview-modal ul {
  display: flex;
  margin: 0;
  padding: 0;
}
ssi-publisher-social-network-preview-modal .tab-nav {
  list-style: none;
  position: relative;
  height: 30px;
  text-transform: uppercase;
  text-decoration: none;
  margin: 0 25px 0 0;
  font-weight: 900;
  font-size: 10px;
  letter-spacing: 1px;
  color: #838eab;
}
ssi-publisher-social-network-preview-modal .tab-nav:focus {
  outline: revert;
}
ssi-publisher-social-network-preview-modal .tab-nav:before {
  visibility: hidden;
  transform: scaleX(0);
  visibility: visible;
  content: "";
  position: absolute;
  top: 22px;
  height: 4px;
  width: 100%;
  background-color: #89a4ea;
  transition: all 0.3s ease-in-out 0s;
}
ssi-publisher-social-network-preview-modal .tab-nav.active, ssi-publisher-social-network-preview-modal .tab-nav:hover {
  position: relative;
  color: #101525;
  cursor: pointer;
}
ssi-publisher-social-network-preview-modal .tab-nav.active:before, ssi-publisher-social-network-preview-modal .tab-nav:hover:before {
  visibility: visible;
  transform: scaleX(1);
}
ssi-publisher-social-network-preview-modal .social-network-preview-header {
  color: #a3b5c0;
  margin-bottom: 30px;
}
ssi-publisher-social-network-preview-modal .social-network-preview-body {
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  text-align: left;
}
ssi-publisher-social-network-preview-modal .generic-message {
  color: #838eab;
  text-align: center;
  margin: 50px 0;
}
ssi-publisher-social-network-preview-modal .generic-message i {
  font-size: 30px;
  margin-bottom: 5px;
}
ssi-publisher-social-network-preview-modal .social-network-preview-nav,
ssi-publisher-social-network-preview-modal .social-network-preview-footer {
  width: 100%;
}
ssi-publisher-social-network-preview-modal .social-network-preview-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
ssi-publisher-social-network-preview-modal .social-network-preview-footer :first-child {
  margin-right: 4px;
}
ssi-publisher-social-network-preview-modal .social-network-preview-footer :nth-child(2) {
  margin-left: 4px;
}
ssi-publisher-social-network-preview-modal .social-network-preview-window {
  margin: 20px 0;
}