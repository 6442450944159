ssi-create-edit-report .content-wrapper {
  padding: 60px 0 0;
  max-width: 1294px !important;
}
ssi-create-edit-report .content-wrapper .form-group {
  padding: 0;
}
ssi-create-edit-report .heading-container {
  position: fixed;
  top: 68px;
  width: 100%;
  height: 94px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f4f4fa;
  padding: 24px 45px;
  box-shadow: 0 5px 10px rgba(131, 142, 171, 0.25);
  z-index: 1;
}
ssi-create-edit-report .heading-container orlo-button {
  margin: 0 0 0 20px;
  height: 46px;
}
ssi-create-edit-report .heading-container orlo-button .ssi-line-arrow-small-down {
  transform: rotate(90deg);
  margin-right: 5px;
}
ssi-create-edit-report .heading-container orlo-button button {
  height: inherit;
}
ssi-create-edit-report .heading-container h1 {
  line-height: 1;
  margin: 10px 0;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-create-edit-report .content-form {
  padding: 60px 70px 90px;
  margin: 94px 15px 0;
  background-color: #f4f4fa;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  position: relative;
}
ssi-create-edit-report .delete {
  position: absolute;
  right: 2em;
  top: 2em;
  background-color: white;
  padding: 1em;
  border-radius: 100%;
  color: #838eab;
  line-height: 0;
  cursor: pointer;
}
ssi-create-edit-report orlo-text-input label {
  margin: 8px 0;
  padding: 0;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #43537f;
  font-size: 10px;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-create-edit-report orlo-text-input input {
  height: 46px;
  color: #101525 !important;
  background-color: #fff !important;
  font-size: 14px !important;
  text-rendering: geometricPrecision;
  color: #838eab;
}
ssi-create-edit-report orlo-text-input input:not(:focus) {
  border-color: #fff !important;
}
ssi-create-edit-report orlo-text-input input.invalid {
  border-color: #f88c68 !important;
}
ssi-create-edit-report orlo-text-input input::placeholder {
  color: #838eab;
  font-weight: 700 !important;
}
ssi-create-edit-report orlo-text-input input:focus {
  font-weight: 600 !important;
}
ssi-create-edit-report orlo-text-input span {
  text-align: left !important;
}
ssi-create-edit-report .input-container {
  position: relative;
}
ssi-create-edit-report .input-container:first-child {
  margin-right: 30px !important;
  flex: 1 1 40% !important;
}
ssi-create-edit-report .input-container:last-child {
  flex: 1 1 50% !important;
}
ssi-create-edit-report .input-counter {
  position: absolute;
  bottom: 0;
  right: 8px;
  color: #43537f;
  font-size: 10px;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-create-edit-report .input-counter.error {
  color: #f88c68;
}
ssi-create-edit-report .form-section {
  margin: 0 0 60px;
}
ssi-create-edit-report .section-title {
  margin: 18px 0 18px 28px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #43537f;
  font-size: 10px;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-create-edit-report .profile-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}
ssi-create-edit-report .profile-grid-item {
  flex: 0 1 25%;
  width: 25%;
}
ssi-create-edit-report .profile {
  display: flex;
  flex: 1 0 auto;
  background-color: #fff;
  align-items: center;
  width: calc(100% - 5px * 2);
  border-radius: 100px;
  padding: 3px;
  border: 2px solid #fff;
  margin: 0 5px 12px;
  color: #43537f;
}
ssi-create-edit-report .profile.focus {
  background-color: #213ed9;
  border-color: #213ed9;
}
ssi-create-edit-report .profile.focus .profile-avatar {
  background: #425dec !important;
}
ssi-create-edit-report .profile.focus .profile-avatar .ssi-target {
  color: white;
  opacity: 1;
}
ssi-create-edit-report .profile-included {
  border-color: #14bae3;
  background-color: #14bae3;
}
ssi-create-edit-report .profile-included .profile-meta {
  color: #fff;
  pointer-events: none;
}
ssi-create-edit-report .profile-included .profile-action {
  background-color: #fff;
  transform: rotate(45deg);
}
ssi-create-edit-report .profile-selected {
  border-color: #14bae3;
}
ssi-create-edit-report .profile-selected .profile-action {
  background-color: #14bae3;
  color: #fff;
  font-size: 14px;
  cursor: auto;
}
ssi-create-edit-report .profile-empty {
  text-align: center;
}
ssi-create-edit-report .profile-empty p {
  width: 100%;
  margin: 0 10% 0 0;
  color: #838eab;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-rendering: geometricPrecision;
}
ssi-create-edit-report .profile-meta {
  flex: 1 1 auto;
  margin: 0 14px;
  overflow: hidden;
}
ssi-create-edit-report .profile-meta p {
  margin: 0;
  font-weight: 900;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-rendering: geometricPrecision;
}
ssi-create-edit-report .profile-meta ul {
  margin: 0;
  padding: 0;
}
ssi-create-edit-report .profile-meta li {
  display: inline-block;
  margin: 0 10px 0 0;
  font-size: 12px;
}
ssi-create-edit-report .profile-avatar,
ssi-create-edit-report .profile-action {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 46px;
  width: 46px;
  height: 46px;
  background-color: #f4f4fa;
  border-radius: 50%;
}
ssi-create-edit-report .profile-avatar {
  background-position: 50%;
  cursor: pointer;
}
ssi-create-edit-report .profile-avatar .ssi {
  color: #838eab;
  font-size: 18px;
  line-height: 1;
}
ssi-create-edit-report .profile-avatar .ssi-target {
  opacity: 0;
}
ssi-create-edit-report .profile-action {
  color: #14bae3;
  background-color: #f4f4fa;
  font-size: 12px;
  cursor: pointer;
}
ssi-create-edit-report .limit-info {
  width: 100%;
  margin: 15px 5px -30px 15px;
}
ssi-create-edit-report .limit-info h4 {
  max-width: 400px;
  color: #43537f;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  text-rendering: geometricPrecision;
}