@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-audit-event {
  $iconWidth: 26px;
  $iconHeight: 26px;

  .audit-event {
    margin-top: 5px;
    padding-bottom: 5px;
    display: flex;

    .date-box {
      margin-right: 15px;
      padding-top: 1px;
      width: 40px;

      span {
        color: #43537f;
        font-size: 10px;
        font-weight: 900;
        letter-spacing: 1px;
        text-transform: uppercase;
        white-space: nowrap;
      }
    }

    .icon-box {
      margin-right: 15px;

      .icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        margin-bottom: 4px;

        i.action-icon {
          color: #fff;
          border-radius: 50%;
          padding: 6px;
          font-size: $iconWidth - 12;
          max-height: $iconHeight;
        }

        .trigger-label {
          color: #43537f;
          font-size: 10px;
          font-weight: 900;
          letter-spacing: 1px;
          text-transform: uppercase;
          margin: 0 8px;
        }
      }

      .delimiter {
        position: relative;
        height: 25px;
        border-left: 2px dotted #c1cff2;
        margin-left: calc(($iconWidth / 2) - 1px);
        margin-top: 1px;
        height: calc(100% - $iconHeight);
      }
    }

    .result-box {
      padding-top: 2px;
      min-height: 54px;

      .message {
        text-align: start;

        span {
          color: #43537f;
          font-size: 14px;
          font-weight: 700;
          line-height: 14px;

          strong {
            font-weight: 900;
          }

          &.time {
            white-space: nowrap;
          }
        }
      }

      .params {
        margin-top: 5px;
        overflow: auto;
        margin-bottom: 12px;

        .tag {
          border-radius: 100px;
          padding: 5px 10px;
          margin-right: 5px;
          margin-bottom: 5px;
          width: fit-content;

          &.added-tag {
            background-color: #ecf0fb;
          }

          &.removed-tag {
            background-color: #fff1ed;
          }

          color: #43537f;
          font-size: 10px;
          font-weight: 900;
          line-height: 12px;
        }

        .note {
          display: inline;
          color: #43537f;
          font-size: 14px;
          font-weight: 700;
        }

        .note-action {
          color: $primary-active;
          text-transform: uppercase;
          font-size: 10px;
          font-weight: 900;
          letter-spacing: 1px;
          cursor: pointer;

          &:hover,
          &:active {
            color: darken($primary-active, 10%);
          }
        }
      }
    }

    // Inbox events
    &.verb-viewed {
      i.action-icon {
        background-color: #d41d68;
      }
    }

    &.verb-statusChange {
      &.status-unread {
        i.action-icon {
          background-color: #14bae3;
        }
      }
      &.status-actioned {
        .icon-container {
          background-color: rgba(178, 198, 20, 0.2);
          i.action-icon {
            background-color: #b2c614;
          }
        }
      }
      &.status-unactioned {
        i.action-icon {
          background-color: #aeb6cc;
        }
      }
    }
    &.verb-assigned {
      .icon-container {
        background-color: rgba(240, 180, 39, 0.2);
        i.action-icon {
          background-color: #f0b427;
        }
      }
    }
    &.verb-unassigned {
      i.action-icon {
        background-color: #ff4000;
      }
    }
    &.verb-sentimentChanged {
      i.action-icon {
      }
    }
    &.verb-addedTags {
      .icon-container {
        background-color: rgba(41, 180, 173, 0.2);
        i.action-icon {
          background-color: #12aca4;
        }
      }
    }
    &.verb-removedTags {
      i.action-icon {
        background-color: #ff4000;
      }
    }
    &.verb-addedNote {
      .icon-container {
        background-color: #c6d6ff;
        i.action-icon {
          background-color: #749afd;
        }
      }
    }
    &.verb-deletedNote {
      i.action-icon {
        background-color: #ff4000;
      }
    }
    &.verb-socialLiked {
      i.action-icon {
        color: #d41d68 !important;
        background-color: #ecf0fb;
      }
    }
    &.verb-socialUnliked {
      i.action-icon {
        background-color: #838eab;
      }
    }
    &.verb-socialHide {
      i.action-icon {
        color: #35353f !important;
        background-color: #dfdfe3;
      }
    }
    &.verb-socialUnHide {
      i.action-icon {
        color: #14bae3 !important;
        background-color: #eef1fb;
      }
    }
    &.verb-emailed {
      i.action-icon {
        background-color: #4843b2;
      }
    }
    &.verb-blocked {
      i.action-icon {
        background-color: #ff0808;
      }
    }
    &.verb-unblocked {
      i.action-icon {
        background-color: #b2c614;
      }
    }
    &.verb-redacted {
      i.action-icon {
        color: #35353f !important;
        background-color: #dfdfe3;
      }
    }
    &.verb-sensitiveMediaViewed {
      .icon-container {
        background-color: rgba(178, 198, 20, 0.2);
        i.action-icon {
          background-color: #b2c614;
        }
      }
    }
    &.verb-sensitiveMediaHidden {
      i.action-icon {
        background-color: #aeb6cc;
      }
    }

    // Common (Inbox and Outbox) events
    &.verb-deleted {
      i.action-icon {
        background-color: #ff4000;
      }
    }

    // Outbox events
    &.verb-created {
      i.action-icon {
        background-color: #b2c614;
      }
    }
    &.verb-published {
      i.action-icon {
        background-color: #14bae3;
      }
    }
    &.verb-updated {
      i.action-icon {
        background-color: #f0b427;
      }

      &.re-scheduled {
        i.action-icon {
          background-color: #b2c614;
        }
      }
    }
    &.verb-accepted {
      i.action-icon {
        background-color: #65c9e1;
      }
    }
    &.verb-rejected {
      i.action-icon {
        background-color: #f88c68;
      }
    }
    &.verb-publish-failed {
      i.action-icon {
        background-color: #ff4000;
      }
    }
    &.verb-resolved {
      i.action-icon {
        background-color: #b2c614;
      }
    }
    &.verb-unresolved {
      i.action-icon {
        background-color: #f88c68;
      }
    }
  }

  .capitalized {
    text-transform: capitalize;
  }
  .no-wrap {
    white-space: nowrap;
  }
}
