ssi-outbox-message {
  font-size: 1rem;
}
ssi-outbox-message .outbox-message {
  position: relative;
  border-radius: 1em;
  background-color: #fff;
  overflow: hidden;
  padding: 4.2em 3em 3em;
}
ssi-outbox-message .outbox-message-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3em;
}
ssi-outbox-message .outbox-message-tab {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #eff2fd;
  border-top-left-radius: 1em;
  border-bottom-right-radius: 1em;
  height: inherit;
  min-width: 11.3em;
}
ssi-outbox-message .outbox-message-tab:hover {
  text-decoration: none;
}
ssi-outbox-message .outbox-message-tab p {
  color: #43537f;
  font-weight: 900;
  text-rendering: geometricPrecision;
  padding: 0 1em;
  margin: 0;
  font-size: 1.2em;
  text-transform: lowercase;
}
ssi-outbox-message .outbox-message-tab p:first-letter {
  text-transform: uppercase;
}
ssi-outbox-message .outbox-message-body {
  display: flex;
}
ssi-outbox-message .outbox-message-thumb {
  flex: 0 0 6.9em;
  padding: 1.5em 0 1em;
}
ssi-outbox-message .outbox-message-thumb img {
  display: inline-block;
  background-color: #fff;
  border-radius: 50%;
  height: 4.6em;
  width: 4.6em;
  box-shadow: 0 0.5em 1em rgba(131, 142, 171, 0.25);
}
@media (max-width: 767px) {
  ssi-outbox-message .outbox-message-thumb {
    display: none;
  }
}
ssi-outbox-message .outbox-message-thumb-mobile {
  display: none;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0.5em 1em rgba(131, 142, 171, 0.25);
  margin-right: 0.5em;
}
@media (max-width: 767px) {
  ssi-outbox-message .outbox-message-thumb-mobile {
    display: inline-block;
    height: 2em;
    width: 2em;
  }
}
ssi-outbox-message .outbox-message-content {
  flex: 1 1 auto;
}
ssi-outbox-message .outbox-message-content-meta ul {
  margin: 0 -0.5em;
  padding: 0;
}
ssi-outbox-message .outbox-message-content-meta li {
  display: inline-block;
  margin: 0 0.5em 0.5em;
}
@media (max-width: 767px) {
  ssi-outbox-message .outbox-message-content-meta li:first-child {
    display: block;
  }
}
ssi-outbox-message .outbox-message-content-meta p {
  display: inline-block;
  margin-bottom: 0;
  color: #43537f;
  font-size: 1.2em;
  line-height: 1;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-outbox-message .outbox-message-content-meta i {
  display: inline-block;
  color: #43537f;
  margin: 0 0.2em 0 0;
  line-height: 1;
  vertical-align: text-top;
}
ssi-outbox-message .outbox-message-content-meta-primary p {
  color: #14bae3;
  font-size: 1.6em;
  font-weight: 700;
  vertical-align: bottom;
  margin-bottom: 0.1em;
}
ssi-outbox-message .outbox-message-content-meta-primary .outbox-message-content-meta-primary-icon {
  display: inline-block;
  height: 2em;
  width: 2em;
  line-height: 2em;
  text-align: center;
  background-color: #f8f9fd;
  border-radius: 50%;
  margin: 0 0 0.1em 0.2em;
}
ssi-outbox-message .outbox-message-content-meta-primary .outbox-message-content-meta-primary-icon i {
  color: #14bae3;
  font-size: 1.4em;
  width: inherit;
}
ssi-outbox-message .outbox-message-content-meta-pill {
  background-color: #f8f9fd;
  border-radius: 10em;
  padding: 0.4em 0.6em;
}
ssi-outbox-message .outbox-message-content-meta-split p:last-child::before {
  content: "|";
  margin: 0 0.5em;
}
ssi-outbox-message .outbox-message-content-body {
  margin: 0.5em 0 2em;
  line-height: 2.4em;
}
ssi-outbox-message .outbox-message-content-body p {
  color: #101525;
  font-weight: 700;
  text-rendering: geometricPrecision;
  line-height: inherit;
  margin: 0;
}
ssi-outbox-message .outbox-message-content-stats {
  display: flex;
  align-items: center;
}
ssi-outbox-message .outbox-message-content-stats ul {
  padding: 0;
  margin: 0 0.5em;
}
@media (max-width: 767px) {
  ssi-outbox-message .outbox-message-content-stats ul {
    width: 100%;
    margin-top: 1em;
  }
}
ssi-outbox-message .outbox-message-content-stats li {
  display: inline-block;
  padding: 0 1.5em;
  margin: 0.5em 0;
}
@media (max-width: 767px) {
  ssi-outbox-message .outbox-message-content-stats li:first-child {
    padding-left: 0;
  }
}
ssi-outbox-message .outbox-message-content-stats i {
  display: inline-block;
  color: #838eab;
  margin: 0 0.3em 0 0;
  font-size: 1.2em;
  line-height: 1.2rem;
  vertical-align: text-bottom;
}
ssi-outbox-message .outbox-message-content-stats i.ssi-total-engagement, ssi-outbox-message .outbox-message-content-stats i.ssi-eye {
  font-size: 1.4em;
}
ssi-outbox-message .outbox-message-content-stats i.ssi-reach, ssi-outbox-message .outbox-message-content-stats i.ssi-correct-retweet {
  font-size: 1.6em;
}
ssi-outbox-message .outbox-message-content-stats p {
  display: inline-block;
  color: #101525;
  font-size: 1.2em;
  line-height: 1;
  margin: 0;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
@media (max-width: 767px) {
  ssi-outbox-message .outbox-message-content-stats {
    flex-wrap: wrap;
  }
}
ssi-outbox-message .outbox-message-content-label {
  display: inline-block;
  background-color: #ccc;
  border-radius: 0.4em;
  padding: 0.3em 1em;
}
ssi-outbox-message .outbox-message-content-label-live {
  background-color: #b2c614;
}
ssi-outbox-message .outbox-message-content-label-scheduled {
  background-color: #89a4ea;
}
ssi-outbox-message .outbox-message-content-label-approval {
  background-color: #91d4e1;
}
ssi-outbox-message .outbox-message-content-label p {
  color: #fff;
  font-weight: 900;
  font-size: 1em;
  letter-spacing: 0.1em;
  margin: 0;
  text-rendering: geometricPrecision;
  text-transform: uppercase;
}
ssi-outbox-message .outbox-message-gallery {
  text-align: right;
  flex: 0 0 15.6em;
  padding-right: 1.4em;
}
@media (max-width: 767px) {
  ssi-outbox-message .outbox-message-gallery {
    display: none;
  }
}
ssi-outbox-message .outbox-message-gallery-mobile {
  display: none;
}
@media (max-width: 767px) {
  ssi-outbox-message .outbox-message-gallery-mobile {
    display: block;
    padding-bottom: 2em;
  }
}
ssi-outbox-message .outbox-message-gallery-wrap {
  display: inline-block;
  height: 7.2em;
  width: 10.4em;
}
ssi-outbox-message .outbox-message-gallery-wrap img {
  border-radius: 1em;
  box-shadow: 0 0.5em 0.5em rgba(131, 142, 171, 0.35);
  height: 100%;
  width: 100%;
  object-fit: cover;
}
ssi-outbox-message .outbox-message-gallery-wrap-multiple {
  position: relative;
}
ssi-outbox-message .outbox-message-gallery-wrap-multiple img:first-child {
  position: absolute;
  left: -0.7em;
}
ssi-outbox-message .outbox-message-gallery-wrap-multiple img:nth-child(2) {
  position: absolute;
  top: 0.8em;
  left: 0;
}
ssi-outbox-message .outbox-message-gallery-wrap-multiple-badge {
  position: absolute;
  top: 0.7em;
  bottom: 0;
  right: -1.3em;
  margin: auto;
  display: inline-block;
  background-color: #fff;
  color: #14bae3;
  font-size: 1em;
  font-weight: 900;
  text-rendering: geometricPrecision;
  height: 2.6em;
  width: 2.6em;
  border-radius: 50%;
  box-shadow: 0 0.5em 1.5em rgba(116, 118, 132, 0.25);
  text-align: center;
  line-height: 2.6em;
  z-index: 1;
}