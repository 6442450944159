@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-inbox-priority-select {
  .inbox-priority-select-container {
    display: flex;
    align-items: center;
    padding: 0 8px;
    height: 36px;
    border-radius: 10px;
    background-color: #fff;

    > * {
      padding: 0 3px;
      cursor: pointer;
    }

    &.single-value {
      background: #fff;
      border-radius: 10px;
      padding: 8px;
      cursor: pointer;
    }
  }

  ul {
    .inbox-priority-dot {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      margin-right: 10px;
    }

    > span {
      display: block;
      margin-left: 12px;
      font-size: 1.4rem;
      font-weight: 900;
      text-rendering: geometricPrecision;
      color: #43537f;
      margin-bottom: 10px;
      i {
        margin-right: 8px;
      }
    }

    li {
      display: flex;
      justify-content: flex-start;
      .tick {
        display: none;
      }
      &.active {
        background-color: #f8f9fd;
        i.tick {
          display: block;
          color: #14bae3;
          margin-left: auto;
        }
      }
    }
  }

  .inbox-priority-container {
    .inbox-priority-badge {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        color: #fff;
        font-size: 10px;
        font-weight: 900;
      }
    }
  }

  .P1 {
    background-color: #f4490f;
  }
  .P2 {
    background-color: #f0b427;
  }
  .P3 {
    background-color: #425dec;
  }
  .P4 {
    background-color: #89a4ea;
  }
  .P5 {
    background-color: #12aca4;
  }
}
