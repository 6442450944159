@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-audit {
  .audit-container {
    background-color: #fff;
    position: relative;
    min-height: 500px;
    border-radius: 15px;

    i.close-icon {
      padding: 14px;
      background-color: #f4f4fa;
      border-radius: 50%;
      color: #838eab;
      font-size: 7px;
      font-weight: 900;
      position: absolute;
      top: 15px;
      right: 15px;
      z-index: 1;
      &:hover {
        cursor: pointer;
        color: #14bae3;
      }
    }

    .head {
      position: relative;

      .head-row {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        min-height: 80px;
        padding: 25px 50px;

        &.actions-row {
          position: absolute;
          min-height: 100px;
          background-color: #fff;
        }
      }

      h3 {
        color: #43537f;
        font-size: 20px;
        font-weight: 900;
        margin-right: 10px;
        margin-bottom: 0;
        user-select: none;
      }

      .head-action {
        margin-left: 30px;
        cursor: pointer;
        user-select: none;

        i {
          color: #14bae3;
          font-size: 12px;
          font-weight: 900;

          &.filter-icon {
            font-size: 14px;
          }
        }
        span {
          margin-left: 5px;
          color: #43537f;
          font-size: 10px;
          font-weight: 900;
          letter-spacing: 1px;
          text-transform: uppercase;
        }
      }
    }

    .body {
      padding: 25px 50px;
      height: 100%;
      height: 520px;
      overflow: auto;
      display: flex;
      flex-direction: column;

      .audit-events {
      }

      .load-more-btn-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: auto;

        .load-more-btn {
          border: none;
          outline: none;
          border-radius: 100px;
          display: flex;
          justify-content: center;
          color: #fff;
          font-size: 14px;
          font-weight: 900;
          text-transform: capitalize;
          background-color: #14bae3;
          padding: 12px 20px;
          margin-top: 30px;
          margin-bottom: 30px;

          &:hover {
            color: #fff;
          }

          &:disabled {
            background-color: #b4bbce;
          }
        }
      }
    }
  }
}
