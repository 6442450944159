@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';
@import '../../../../../../../../angularjs/common/sass/include-media';

ssi-widget-profile-search {
  height: 100%;
  width: 100%;

  .dashboard-widget {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    @include media('>=600px') {
      ssi-menu {
        display: none;
      }
      .post-details {
        &.time-visibility {
          display: flex;
          &.placed-below {
            display: none;
            flex-direction: row-reverse;
            justify-content: flex-end;
            padding: 10px 0 0 10px;
          }
        }
      }
    }

    // only special-handling when responsiveWidth is >1200
    // since the grid component only assigns widget sizes when its that wide
    @include media('>1200px') {
      &.view-1x2 {
        .desktop-menu {
          .menu-list-item {
            &:last-child,
            &:nth-child(3) {
              display: none;
            }
          }
        }

        ssi-menu {
          display: block;
        }

        .post-details {
          &.time-visibility {
            display: none;
            &.placed-below {
              display: flex;
              flex-direction: row-reverse;
              justify-content: flex-end;
              padding: 10px 0 0 10px;
            }
          }
        }
      }

      &.view-2x2 {
        ssi-menu {
          display: none;
        }
      }
    }

    .article-grid {
      height: 100%;
      overflow: auto;
      margin-top: 7px;
    }

    .form-field {
      padding: 10px 20px;
      font-weight: 900;

      .label-clear-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 5px;
      }

      label {
        &.field-title {
          font-size: 10px;
          line-height: 12px;
          padding-left: 20px;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: $lmode-body-text;
          font-weight: 900;
        }
      }

      &:last-child {
        padding-top: 0;
      }
    }

    ssi-dropdown-select-2 {
      width: 100%;

      .dd-head {
        width: 100%;
      }
      .dd-body {
        width: 100%;
      }
      .select-accounts-label {
        display: flex;
        align-items: center;

        span {
          padding-left: 7px;
        }
      }
    }

    .message-info {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      text-align: center;
      padding-top: 20px;
      margin-top: 20px;

      &.view-1x1,
      &.view-2x1,
      &.view-3x1,
      &.view-4x1 {
        justify-content: start;
        padding-top: 10px;
      }

      i {
        color: #b4bbce;
        font-size: 37.5px;
      }

      h5 {
        font-size: 20px;
        font-weight: 900;
        line-height: 24px;
        color: $lmode-body-text;
      }

      p {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        max-width: 222px;
        color: $lmode-body-text;

        &.view-1x1,
        &.view-2x1,
        &.view-3x1,
        &.view-4x1 {
          max-width: 312px;
        }
      }

      ssi-spinner {
        margin-bottom: 10px;
      }
    }
  }

  .form-container {
    padding-top: 20px;
    &.box-shadowed {
      box-shadow: 0px 9px 7px 0px #c3c9d8;
    }
  }

  .input-with-submit {
    display: flex;
    width: 100%;
    input {
      border: 1px solid #c3c9d8;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      padding: 10px;
      outline: none;
      width: 100%;
      padding-left: 20px;

      &::placeholder {
        color: #6a759a;
        font-size: 14px;
        font-weight: 700;
        line-height: 14px;
      }
    }

    .search-submit {
      display: inline-flex;
      background-color: $primary-active;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      white-space: nowrap;

      i {
        margin-right: 5px;
      }

      button {
        font-weight: 700;
        color: $lmode-helper-text;
        background-color: $lgrey-background;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border: 1px solid #c3c9d8;
        border-left: none;
        padding: 10px;
        appearance: none;
        outline: none;
      }
    }

    &.active {
      input {
        border-color: $primary-active;
        color: $lmode-title-text;
        font-size: 14px;
        font-weight: 900;
      }
      button {
        color: #fff;
        background-color: $primary-active;
        border-color: $primary-active;
      }
    }

    .tags-input-tags {
      flex: 0 0 auto;
      margin: 0;
      padding: 0.2em;
    }

    .tags-input-tag {
      font-size: 1.2em;
      position: relative;
      display: inline-flex;
      align-items: center;
      padding: 0 3rem 0 1.1rem;
      margin: 0 0.2rem 0 0;
      flex: 0 1 auto;
      height: 40px;
      background-color: $lgrey-background;
      border: 0.1rem solid #c3c9d8;
      border-radius: 0.8rem;

      span {
        font-size: 1.2rem;
        line-height: 1.2rem;
        min-height: 1rem;
        color: $lmode-title-text;
        font-weight: 900;
        text-rendering: geometricPrecision;
      }
    }

    .tags-input-tag-action {
      position: absolute;
      display: block;
      right: 0.6rem;
      top: 0;
      bottom: 0;
      margin: auto;
      padding: 0 0.5rem;
      border-width: 0;
      background-color: transparent;
      line-height: 0;
    }
  }

  .profiles-list {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    height: 100%;
    padding: 20px;

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(215px, 1fr));
    grid-gap: 15px;
    margin: 0;
  }

  .load-more-button {
    margin-top: auto;
    margin-bottom: 10px;
    appearance: none;
    border: none;
    padding: 0;
    color: #fff;
    border-radius: 10px;
    background-color: $primary-active;
    padding: 10px;
    font-size: 14px;
    font-weight: 900;
    line-height: 14px;
    grid-column: 1/-1;
    max-width: fit-content;
    justify-self: center;
    align-self: center;

    i {
      color: #fff;
      margin-right: 5px;
    }
  }

  .profile-container {
    flex: 1 1 215px;
    height: 280px;
    border: 1px solid #c3c9d8;
    border-radius: 10px;
    padding: 20px;

    .profile-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 100%;

      img {
        border: 4px solid #e8ecf9;
        border-radius: 50%;
        height: 62px;
        width: 62px;
      }
      .name {
        display: block;
        color: $primary-active;
        text-decoration: underline;
        font-size: 14px;
        font-weight: 900;
        line-height: 16.8px;
        text-align: center;
        margin-top: 5px;
      }

      .username,
      .description,
      .location {
        font-size: 12px;
        line-height: 14.4px;
        font-weight: 700;
        margin-top: 5px;
        text-align: center;
      }

      .description {
        color: $lmode-body-text;
        line-height: 18px;
        margin: 5px 0;
        max-height: 70px;
        overflow: auto;
      }

      .location {
        display: block;
        font-style: italic;
        line-height: 12px;
        margin: 10px 0 5px 0;

        i {
          display: block;
          font-size: 20px;
          color: $lmode-body-text;
          margin-bottom: 5px;
        }
      }

      button {
        margin-top: auto;
        appearance: none;
        border: none;
        padding: 0;
        color: #fff;
        border-radius: 10px;
        background-color: $primary-active;
        padding: 10px;
        font-size: 14px;
        font-weight: 900;
        line-height: 14px;

        i {
          color: #fff;
          margin-right: 5px;
        }
      }
    }
  }

  ssi-stream-post {
    display: block;
    padding: 10px;

    @include media('<600px') {
      .desktop-menu {
        .menu-list-item {
          &:last-child {
            display: none;
          }
        }
      }
      .post-details {
        &.time-visibility {
          display: none;
          &.placed-below {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
            padding: 10px 0 0 10px;
          }
        }
      }
    }

    @include media('<450px') {
      .desktop-menu {
        .menu-list-item {
          &:last-child,
          &:nth-child(3) {
            display: none;
          }
        }
      }
    }
  }

  ssi-stream-post .repost-menu .menu-list {
    max-height: 230px;
    overflow: auto;
  }

  .scrollable-content {
    display: flex;
    flex-direction: column;
  }
}
