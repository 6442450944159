@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';
@import '../../../../angularjs/common/sass/variables';

ssi-conversation-resolve {
  .conversation-resolve-container {
    display: inline-block;

    .resolve-btn {
      i {
      }
    }
  }

  i {
    &.resolve-icon-standalone {
      font-weight: 700;
      cursor: pointer;
      &.resolved {
        cursor: default;
      }
    }
  }

  .resolved-description {
    font-size: 12px;
    color: $lmode-helper-text;
    font-weight: 700;
    padding-left: 5px;

    i {
      font-weight: 700;
      padding-right: 0.2em;
    }
  }
}
