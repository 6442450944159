ssi-modal-list {
  font-size: 1rem;
}
ssi-modal-list .drilldown-modal-content-container {
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 12px;
  height: 100%;
}
ssi-modal-list .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: 2em 2em 2.1em 3em;
  border-bottom: 1px solid #c3c9d8;
}
ssi-modal-list .heading .title {
  color: #43537f;
  text-transform: capitalize;
  margin: 0.2em 0 0;
  padding: 0;
  line-height: 1;
  font-size: 2em;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-modal-list .heading button {
  display: inline-block;
  border-radius: 50%;
  background-color: #f4f4fa;
  border-width: 0;
  height: 3.6em;
  width: 3.6em;
  outline-width: 0;
}
ssi-modal-list .heading button .ssi {
  font-size: 1.2em;
  color: #838eab;
}
ssi-modal-list .heading button .ssi-addsmall {
  transform: rotate(45deg);
}
ssi-modal-list .drilldown-content {
  padding: 4em 3em 5em 2.5em;
}