@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-tags-input {
  font-size: 1rem;
  width: 100%;

  $tags-input-width: 470px;
  $dropdown-wrap-width: 115px;
  $tags-height: 34px;

  .tags-input {
    display: flex;
    width: 100%;
    height: 4em;

    ssi-dropdown-select-2 {
      .search-type-btn {
        border: 0.1em solid #c3c9d8;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding-right: 10px;
        background-color: #fff;
        span {
          color: #43537f;
          font-weight: 900;
        }
        i {
          font-weight: 500;
          color: $primary-active;
          margin-left: 26px;
          font-size: 14px;
        }
      }
      &.compact {
        width: 87px;
        .search-type-btn {
          span {
            width: 42px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          i {
            margin-left: 4px;
          }
        }
      }
    }

    .pro-tip-btn {
      width: 40px;
      height: 40px;
      margin-left: 5px;
      background-color: #fff;
      border: 1px solid $primary-active;
      i {
        color: $primary-active;
        margin: 0;
        font-size: 16px;
        font-weight: normal;
      }
    }
  }

  .tags-input-dropdown-wrap {
    position: relative;
    flex: 0 1 auto;
    min-width: $dropdown-wrap-width;

    .dropdown {
      height: 100%;
      width: 100%;
    }

    .dropdown-toggle {
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      padding: 0 3.8em 0 1.8em;
      border: 0.1em solid #c3c9d8;
      border-top-left-radius: 1em;
      border-bottom-left-radius: 1em;
      background-color: #fff;
      z-index: 1;
      cursor: pointer;

      &[aria-expanded='true'] {
        border-color: $primary-active;
      }

      p {
        font-size: 1.4em;
        margin: 0;
        color: $lmode-body-text;
        font-weight: 900;
        text-rendering: geometricPrecision;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .ssi {
        font-size: 1.2em;
        position: absolute;
        color: $primary-active;
        top: 0;
        bottom: 0;
        right: 1.7rem;
        margin: auto;
        height: 1.1rem;
      }
    }

    .dropdown-menu {
      font-size: 1em;
      padding: 2.8em 1.5em 3em 2em;
      margin: 0.4em 0 0;
      background-color: #fff;
      border-radius: 1.5em;
      box-shadow: 0 1.8em 4em rgba(131, 142, 171, 0.35);
      min-width: 22.2em;
      border-width: 0;
    }
  }

  .tags-input-input-wrap {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    border: 0.1em solid #c3c9d8;
    margin-left: -0.1em;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
    background-color: #fff;
    padding: 0;
    width: auto;
    padding: 0 3.7em 0 0;

    &-focused {
      border-color: $primary-active;
      z-index: 2;

      .tags-input-input-container-icon {
        color: $primary-active;
      }
    }
  }

  .tags-input-input-container {
    display: flex;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;

    &.compact {
      ul {
        padding: 0.2em !important;
        width: auto !important;
      }
      span {
        max-width: 85px;
        overflow: hidden;
        margin-right: 15px;
        text-overflow: ellipsis;
      }
    }

    &.custom-scrollbar {
      &::-webkit-scrollbar-thumb {
        visibility: hidden;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
        // visibility: hidden;
      }

      &:hover {
        // align-self: stretch;

        &::-webkit-scrollbar-track,
        &::-webkit-scrollbar-thumb {
          visibility: visible;
        }
      }
    }

    &::-webkit-scrollbar {
      height: 10px;
    }
  }

  .tags-input-input-container-icon {
    font-size: 1.2em;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 1.2rem;
    right: 1.7rem;
    color: $lmode-body-text;
    cursor: pointer;
  }

  .tags-input-tags {
    flex: 0 0 auto;
    margin: 0;
    padding: 0.2em;
  }

  .tags-input-tag {
    font-size: 1.2em;
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 0 3rem 0 1.1rem;
    margin: 0 0.2rem 0 0;
    flex: 0 1 auto;
    height: $tags-height;
    background-color: $lgrey-background;
    border: 0.1rem solid #c3c9d8;
    border-radius: 0.8rem;

    span {
      font-size: 1.2rem;
      line-height: 1.2rem;
      min-height: 1rem;
      color: $lmode-title-text;
      font-weight: 900;
      text-rendering: geometricPrecision;
    }
  }

  .tags-input-tag-action {
    position: absolute;
    display: block;
    right: 0.6rem;
    top: 0;
    bottom: 0;
    margin: auto;
    padding: 0 0.5rem;
    border-width: 0;
    background-color: transparent;
    line-height: 0;

    &:hover,
    &:focus {
      .ssi {
        color: $primary-active;
      }
    }

    .ssi {
      font-size: 0.8rem;
      color: $lmode-body-text;
    }
  }

  .tags-input-input {
    flex: 1 1 auto;
    height: $tags-height;
    color: $lmode-title-text;
    font-weight: 700;
    font-size: 1.4em;
    text-rendering: geometricPrecision;
    margin: 0.2rem;
    border-width: 0;
    min-width: 22rem;
    outline: 0;
    text-indent: 0.7rem;

    &.compact {
      min-width: 0;
      width: 100%;
    }

    &:disabled {
      background-color: #fff;
      color: transparent;

      &::placeholder {
        color: transparent;
      }
    }

    &::placeholder {
      color: #6a759a;
    }
  }
}

.insights-advanced-search-pro-tip-modal {
  // global - used also in the filters component
  background-color: rgba(53, 53, 63, 0.85);

  .modal-dialog {
    width: calc(100% - 15px);
    max-width: 1025px;
  }

  .modal-content {
    padding: 20px;
    box-shadow: 0 5px 20px rgba(131, 142, 171, 0.2);
    border-radius: 12px;
    background-color: #fff;
    color: #43537f;
  }

  .modal-close-button {
    display: inline-block;
    border-radius: 50%;
    border-width: 0;
    background-color: #f4f4fa;
    height: 36px;
    width: 36px;
    color: #838eab;
    line-height: 0;
    float: right;

    i {
      font-size: 10px;
      transform: rotate(45deg);
    }

    &:hover {
      i {
        color: $primary-active;
      }
    }
  }

  .pro-modal-header {
    margin: 0 10px 30px 10px;

    h2 {
      font-size: 20px;
      margin: 30px 0 20px;
      font-weight: 900;
      text-rendering: geometricPrecision;
    }

    p {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      text-rendering: geometricPrecision;
    }

    a {
      font-weight: 900;
      color: #14bae3;
      text-decoration: underline;
    }
  }

  .pro-modal-body {
    background-color: #f8f9fd;
    border-radius: 10px;
    padding: 30px 30px 0;

    h3 {
      font-size: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-weight: 900;
      text-rendering: geometricPrecision;
      margin: 7px 0 13px;
    }

    img {
      display: none;
      flex: 1 0 200px;
      align-self: flex-end;
      height: 300px;
      width: 200px;
      margin: -35px -15px 0 18px;
      object-fit: cover;
      object-position: top;
      transform: scaleX(-1);

      @media (min-width: 768px) {
        display: block;
      }
    }
  }

  .pro-modal-body-content {
    display: flex;
  }

  .pro-modal-body-legend {
    display: flex;
    flex-wrap: wrap;

    dt {
      flex: 0 1 187px;
      width: 187px;
      text-align: left;
      padding: 0 20px 0 0;

      .pro-modal-body-key {
        margin: 5px 0 0;
        text-wrap: nowrap;
      }
    }

    dd {
      flex: 1 1 calc(100% - 187px);
      width: calc(100% - 187px);
      color: #43537f;
      font-weight: 700;
      text-rendering: geometricPrecision;
      line-height: 20px;
      margin: 0 0 20px;

      strong {
        font-weight: 900;
      }

      &:after {
        content: '';
        display: block;
        flex-basis: 100%;
        height: 0;
      }
    }
  }

  .pro-modal-body-key {
    display: inline-block;
    min-width: 50px;
    text-align: center;
    padding: 6px 9px 7px;
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    text-rendering: geometricPrecision;
    font-weight: 900;
    background-color: #eee;

    &-blue {
      background-color: #425dec;
    }

    &-yellow {
      background-color: #f0b427;
    }

    &-orange {
      background-color: #f88c68;
    }

    &-white {
      background-color: #fff;
      color: #43537f;
    }

    &-green {
      background-color: #12aca4;
    }

    &-gray {
      background-color: #838eab;
    }
  }
}
