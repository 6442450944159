@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-engagement ssi-user-performance,
ssi-engagement ssi-tags-table,
ssi-engagement ssi-team-performance,
ssi-engagement ssi-response-time-sla {
  font-size: 1rem;

  ssi-treetable {
    overflow: visible;

    .content {
      background-color: #fff;
      border-radius: 1.2em;
      box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
      padding: 3em 2.6em 4.6em;
      margin: 0 0 2em;
    }

    .content
      .content-inner
      .head
      .head-inner
      .row
      .cell
      .cell-inner-cover.active,
    .content .content-inner .head .head-inner .row .cell {
      background-color: #fff;
      border: 0;
    }

    .content
      .content-inner
      .head
      .head-inner
      .row
      .cell
      .cell-inner
      .col-header
      > span {
      overflow: visible;
      white-space: normal;
      letter-spacing: 0.1em;
      color: $lmode-body-text;
      text-transform: uppercase;
      text-rendering: geometricPrecision;
    }

    .content .content-inner .head .head-inner .row .cell .cell-inner-cover,
    .content .content-inner .head .head-inner .row .cell:hover {
      box-shadow: none;
    }

    .body {
      margin: 2em 0 0;
    }

    .body-inner {
      .row {
        position: relative;

        &:nth-child(2n) {
          background-color: $grey-background;

          &:before,
          &:after {
            content: '';
            position: absolute;
            top: 0;
            width: 100%;
            height: 0.2em;
            border-radius: 10em;
            background-color: #e0e7f4;
            z-index: 1;
          }

          &:after {
            top: auto;
            bottom: 0;
          }
        }
      }

      .cell {
        display: flex;
        align-items: center;

        p {
          color: $lmode-title-text;
          font-weight: 900;
          text-rendering: geometricPrecision;
          font-size: 1.4em;
          padding: 0 0.6em 0 1em;
          margin: 0;
        }
      }

      .body-cell-primary {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        text-rendering: geometricPrecision;
        width: 500px;

        i {
          display: inline-flex;
          align-items: center;
          color: $lmode-helper-text;
          margin: 0 1em 0 0.7em;

          &:before {
            font-size: 1.2em;
          }
        }

        p {
          padding: 0;
          display: inline-block;
          color: $lmode-body-text;
          font-size: 1.2em;
          font-weight: 900;
          margin: 0;
        }
      }
    }
  }

  .user-performance-filters {
    label {
      letter-spacing: 0.1em;
      display: block;
      color: $lmode-body-text;
      font-weight: 900;
      text-transform: uppercase;
      text-rendering: geometricPrecision;
    }

    select {
      font-size: 1.4em;
      padding: 0.6em 1em;
      margin: 0 0 1em;
      border-radius: 1.2rem;
      border-width: 0;
    }
  }
}
