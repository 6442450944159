ssi-publisher-facebook-post-preview {
  display: block;
  padding: 12px;
  max-width: 474px;
  font-family: Helvetica, Arial, sans-serif;
  border: 1px solid #dddfe2;
  border-radius: 3px;
  background-color: #ffffff;
}
ssi-publisher-facebook-post-preview .facebook-post-header a,
ssi-publisher-facebook-post-preview .facebook-post-text a {
  color: #365899;
}
ssi-publisher-facebook-post-preview .facebook-post-text {
  word-break: break-word;
  padding: 0 0 5px 1px;
}
ssi-publisher-facebook-post-preview .facebook-post-header img {
  width: 40px;
  height: 40px;
  margin-right: 12px;
  border: solid 1px #eee;
}
ssi-publisher-facebook-post-preview .facebook-post-header a {
  color: #050505;
  font-size: 15px;
  font-weight: bold;
  padding-top: 2px;
  display: inline-block;
}
ssi-publisher-facebook-post-preview .facebook-post-header small {
  color: #65676b;
  position: relative;
  top: -2px;
  font-weight: normal;
  font-size: 13px;
}
ssi-publisher-facebook-post-preview .facebook-post-body {
  font-size: 15px;
  font-weight: normal;
  line-height: 1.38;
  word-wrap: break-word;
  margin-top: 6px;
  color: #050505;
}
ssi-publisher-facebook-post-preview .facebook-post-footer {
  display: flex;
  justify-content: space-around;
  color: #616770;
  font-size: 13px;
  font-weight: bold;
  line-height: 14px;
  margin-top: 10px;
}
ssi-publisher-facebook-post-preview .facebook-post-footer-item {
  margin-right: 20px;
  padding: 4px 4px 4px 0;
}
ssi-publisher-facebook-post-preview .facebook-post-image,
ssi-publisher-facebook-post-preview .facebook-post-video,
ssi-publisher-facebook-post-preview .facebook-post-link-preview {
  margin-top: 6px;
}
ssi-publisher-facebook-post-preview .facebook-post-image video,
ssi-publisher-facebook-post-preview .facebook-post-video video,
ssi-publisher-facebook-post-preview .facebook-post-link-preview video {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
ssi-publisher-facebook-post-preview .collage-container .facebook-post-image:last-child img {
  height: 250px;
}
ssi-publisher-facebook-post-preview .collage-container.collage-multiple {
  display: flex;
}
ssi-publisher-facebook-post-preview .collage-container .facebook-post-image {
  position: relative;
}
ssi-publisher-facebook-post-preview .collage-container .facebook-post-image.count-1 img {
  height: 100%;
  width: 100%;
}
ssi-publisher-facebook-post-preview .collage-container .facebook-post-image img {
  height: 200px;
  object-fit: cover;
}
ssi-publisher-facebook-post-preview .collage-container .facebook-post-image.count-2 img {
  width: 50%;
  border: 1px solid #fff;
}
ssi-publisher-facebook-post-preview .collage-container .facebook-post-image.count-3 img {
  width: 50%;
  height: 250px;
}
ssi-publisher-facebook-post-preview .collage-container .facebook-post-image.count-3 img {
  height: 250px;
  border: 1px solid #fff;
}
ssi-publisher-facebook-post-preview .collage-container .facebook-post-image.count-3 img:first-child {
  width: 100%;
  height: 275px;
}
ssi-publisher-facebook-post-preview .collage-container .facebook-post-image.count-4 img {
  width: 50%;
  border: 1px solid #fff;
}
ssi-publisher-facebook-post-preview .collage-container .facebook-post-image.count-5, ssi-publisher-facebook-post-preview .collage-container .facebook-post-image.more-than-two:not(.count-4):not(.count-3) {
  display: flex;
  flex-wrap: wrap;
}
ssi-publisher-facebook-post-preview .collage-container .facebook-post-image.count-5 img, ssi-publisher-facebook-post-preview .collage-container .facebook-post-image.more-than-two:not(.count-4):not(.count-3) img {
  width: 25%;
  flex-grow: 1;
  height: 165px;
  border: 1px solid #fff;
}
ssi-publisher-facebook-post-preview .collage-container .facebook-post-image.count-5 img:first-child, ssi-publisher-facebook-post-preview .collage-container .facebook-post-image.more-than-two:not(.count-4):not(.count-3) img:first-child {
  width: 100%;
  height: 333px;
}
ssi-publisher-facebook-post-preview .collage-container .facebook-post-image img.has-counter {
  filter: brightness(0.6);
}
ssi-publisher-facebook-post-preview .collage-container .facebook-post-image img.has-counter + .image-counter {
  color: #fff;
  font-size: 30px;
  position: absolute;
  bottom: 55px;
  right: 55px;
}
ssi-publisher-facebook-post-preview .facebook-post-link-preview,
ssi-publisher-facebook-post-preview .facebook-post-link-preview:hover {
  text-decoration: none;
  color: #1d2129;
}
ssi-publisher-facebook-post-preview .facebook-post-link-preview {
  border: 1px solid;
  border-color: #e9ebee #e9ebee #d1d1d1;
  display: block;
}
ssi-publisher-facebook-post-preview .facebook-post-link-preview img {
  border: 0;
}
ssi-publisher-facebook-post-preview .facebook-post-link-preview-footer {
  margin: 10px 12px;
  max-height: 100px;
  font-size: 12px;
}
ssi-publisher-facebook-post-preview .facebook-post-link-preview-title {
  font-family: Georgia, serif;
  letter-spacing: normal;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 5px;
  max-height: 110px;
  overflow: hidden;
  word-wrap: break-word;
}
ssi-publisher-facebook-post-preview .facebook-post-link-preview-description {
  line-height: 16px;
  max-height: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
ssi-publisher-facebook-post-preview .facebook-post-link-preview-hostname {
  padding-top: 9px;
  font-size: 11px;
  line-height: 11px;
  text-transform: uppercase;
  color: #90949c;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
ssi-publisher-facebook-post-preview .text-read-more {
  font-weight: 700;
  cursor: pointer;
}