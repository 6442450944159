@import '../../../../../angularjs/common/sass/variables';
@import '../../../../../angularjs/common/sass/mixins';

$inbox-activity-toolbar-header-height: 100px;
$inbox-activity-toolbar-item-bg-color: #b8ccd9;
$inbox-activity-toolbar-item-active-bg-color: #98b8c9;
$inbox-activity-toolbar-item-active-hover-bg-color: darken(
  $inbox-activity-toolbar-item-active-bg-color,
  5%
);

$inbox-crm-sidebar-width: 220px;
$inbox-crm-sidebar-padding-sides: 25px;
$inbox-crm-sidebar-stats-height: 221px;
$inbox-crm-sidebar-top-height: $inbox-activity-toolbar-header-height +
  $section-margin-width + $navbar-plus-page-heading-height;
$inbox-crm-sidebar-external-integration-header-active-bg: #5a5c5d;
$inbox-crm-sidebar-external-integration-header-bg: lighten(
  $inbox-crm-sidebar-external-integration-header-active-bg,
  30%
);
$inbox-crm-sidebar-external-integration-header-hover-bg: darken(
  $inbox-crm-sidebar-external-integration-header-bg,
  10%
);
$inbox-crm-sidebar-external-integration-header-active-hover-bg: darken(
  $inbox-crm-sidebar-external-integration-header-active-bg,
  10%
);
$inbox-crm-sidebar-header-border-width: 15px;
$inbox-crm-sidebar-header-active-height: 72px;
$inbox-crm-sidebar-external-integration-header-1st-bg: #951c79;
$inbox-crm-sidebar-external-integration-header-2nd-bg: #2dafc3;
$inbox-crm-sidebar-external-integration-header-3rd-bg: #e28a68;
$inbox-crm-sidebar-unlink-external-integration-person-text-color: #585958;
$inbox-crm-sidebar-unlink-external-integration-person-bg: #e6c032;
$inbox-crm-sidebar-unlink-external-integration-person-bg-hover: darken(
  $inbox-crm-sidebar-unlink-external-integration-person-bg,
  10%
);
$inbox-crm-sidebar-expand-external-integration-person-bg: #353535;
$inbox-crm-sidebar-expand-external-integration-person-bg-hover: darken(
  $inbox-crm-sidebar-expand-external-integration-person-bg,
  10%
);
$inbox-crm-sidebar-external-integration-person-header-action-dimensions: 40px;
$inbox-crm-sidebar-stats-colour: #a6b7c0;
$inbox-crm-sidebar-stats-placeholder-colour: #c9d8e0;
$inbox-crm-sidebar-stats-font-size: 10px;
$inbox-crm-sidebar-stats-icon-size: 14px;
$inbox-crm-sidebar-top-img-margin: 10px;
ssi-inbox-crm-sidebar {
  position: absolute;
  right: 0;
  z-index: 99999999;
  height: 100%;
  width: $inbox-crm-sidebar-width;
  font-size: 12px;
  background-color: #f8f9fd;
  padding: 15px 5px;
  .inbox-crm-sidebar-container {
    padding: 0;
    background-color: #fff;
    border-radius: 12px;
    height: 100%;
    overflow-y: auto;
    color: $inbox-crm-sidebar-stats-colour;
    display: flex;
    flex-direction: column;
    $inbox-crm-sidebar-notes-header-bg-color: #6599af;
    $inbox-crm-sidebar-notes-header-hover-bg-color: darken(
      $inbox-crm-sidebar-notes-header-bg-color,
      5%
    );
    $inbox-crm-sidebar-notes-header-border-color: #30728d;
    .inbox-crm-sidebar-img {
      align-self: center;
      border-radius: 50%;
      margin: 10px 0;
      padding-left: 0;
      padding-right: 0;
      width: $inbox-activity-toolbar-header-height -
        ($inbox-crm-sidebar-top-img-margin * 2);
    }
    .inbox-crm-sidebar-collapse {
      bottom: auto;
      display: flex;
      justify-content: flex-end;
      left: auto;
      padding: 10px 10px 0;
      position: relative;
      right: 0;
      top: 0;
      width: auto;
      button {
        background-color: transparent;
        border: none;
        color: #838eab;
        &:hover {
          color: darken($inbox-crm-sidebar-stats-colour, 5%);
        }
      }
    }
    .inbox-crm-sidebar-sentiment {
      position: absolute;
      bottom: 20px;
      right: 55px;
      padding: 15px;
      border-radius: 50%;
      @include box-shadow-bottom();
    }
    .inbox-crm-sidebar-profile-notes {
      display: flex;
      flex-flow: column nowrap;
      flex-shrink: 0;
      margin: 10px 10px;
      button {
        border: none;
        outline: none;
        border-radius: 20px;
        display: flex;
        flex-grow: 1;
        justify-content: center;
        color: #fff;
        font-size: 12px;
        font-weight: 900;
        background-color: #668aea;
        padding: 6px;
        margin-top: 30px;
      }
      & > ul {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        list-style: none;
        margin: 0;
        padding: 0;
        * {
          display: flex;
        }
      }
      .profile-note {
        display: flex;
        flex-flow: column nowrap;
        margin: 0;
        padding: 0;
        .profile-note-meta {
          margin: 10px 0;
          span {
            font-size: 10px;
            color: #43537f;
            font-weight: 900;
          }
        }
        .profile-note-content {
          display: inline;
          color: #43537f;
          font-size: 12px;
          font-weight: 700;
          margin-bottom: 20px;
          * {
            display: inline;
          }
        }
      }
    }
    .verified-wrap {
      position: relative;
      width: 80px;
      align-self: center;
    }
    .verified {
      display: block;
      position: absolute;
      top: 9px;
      right: -1px;
      color: #1da1f2;
      font-size: 20px;
      z-index: 1;

      &:after {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: 70%;
        width: 70%;
        background-color: #fff;
        border-radius: 50%;
        z-index: -1;
      }
    }
    .inbox-crm-sidebar-stats {
      padding-left: 20px;
      padding-right: 20px;
      &-profile {
        display: flex;
        margin: 10px 0;
        button {
          background-color: #fff;
          border: none;
          border-radius: 24px;
          display: flex;
          flex-grow: 1;
          font-weight: bold;
          padding: 3px 18px;
        }
      }
      .stats {
        display: flex;
        flex-direction: column;
        font-weight: 900;
        font-size: 10px;
        list-style: none;
        margin: 20px 0;
        padding: 0;
        color: #838eab;
        .stat-name {
          text-transform: uppercase;
          letter-spacing: 1px;
        }
        .stat-value {
          color: #101525;
        }
        & > li {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          margin: 6px 0;
          padding: 0;
        }
      }
      .inbox-crm-sidebar-label-value {
      }
      i {
        margin-right: 3px;
      }
      .list-unstyled > li {
        margin-bottom: 10px;
        line-height: 30px;
      }
      .btn {
        font-weight: normal;
        border-color: $inbox-crm-sidebar-stats-colour;
        position: relative;
        top: 8px;
      }
      .btn:hover {
        border-color: $inbox-activity-toolbar-item-active-bg-color;
        background-color: $inbox-activity-toolbar-item-active-bg-color;
        color: white;
      }
      ssi-crm-profile-sentiment-bar {
        display: block;
        margin-top: 20px;
      }
      .btn-group {
        width: 100%;
        display: flex;
      }
      .btn-group > .view-profile-btn {
        flex: 1;
      }
      .btn-group > .dropdown-menu {
        margin-top: 10px;
        text-transform: none;
      }
    }
    .progress {
      margin-top: $section-margin-width * 2;
    }
    .inbox-crm-sidebar-notes-header {
      background-color: $inbox-crm-sidebar-notes-header-bg-color;
      border-right: solid $inbox-crm-sidebar-header-border-width
        $inbox-crm-sidebar-notes-header-border-color;
    }
    .inbox-crm-sidebar-notes-header:hover {
      background-color: $inbox-crm-sidebar-notes-header-hover-bg-color;
    }
    .inbox-crm-sidebar-notes-header,
    .inbox-crm-sidebar-external-integration-header {
      color: white;
      text-transform: uppercase;
      font-weight: normal;
      font-size: 11px;
      @include align-vertical(auto);
      cursor: pointer;
      transition: height 0.5s ease;
      flex: 1;
      .inner {
        padding: 20px $inbox-crm-sidebar-padding-sides;
        width: 100%;
      }
      .total {
        font-size: 18px;
        position: relative;
        top: 2px;
      }
      .total:after {
        content: '|';
        margin: 0 3px;
      }
      i {
        float: right;
        font-size: 18px;
      }
    }
    .inbox-crm-sidebar-external-integration-person-header {
      background-color: $inbox-crm-sidebar-external-integration-header-active-bg;
      color: white;
      height: $inbox-crm-sidebar-header-active-height;
      @include align-vertical(72px);
      .inner {
        align-items: center;
        display: flex;
        flex: 1;
      }
    }
    .inbox-crm-sidebar-external-integration-person-header-left {
      flex: 1;
      margin-left: 20px;
      font-size: 12px;
      text-transform: uppercase;
      button {
        align-items: center;
        background: transparent;
        border: none;
        color: white;
        display: flex;
        justify-content: space-between;
        min-width: 48px;
        padding: 0;
        text-transform: uppercase;
      }
    }
    .inbox-crm-sidebar-unlink-external-integration-person,
    .inbox-crm-sidebar-expand-external-integration-person {
      width: $inbox-crm-sidebar-external-integration-person-header-action-dimensions;
      @include align-vertical(
        $inbox-crm-sidebar-external-integration-person-header-action-dimensions
      );
      border-radius: 50%;
      text-align: center;
      float: left;
      margin-right: $section-margin-width;
    }
    .inbox-crm-sidebar-unlink-external-integration-person {
      color: $inbox-crm-sidebar-unlink-external-integration-person-text-color;
      background-color: $inbox-crm-sidebar-unlink-external-integration-person-bg;
    }
    .inbox-crm-sidebar-unlink-external-integration-person:hover {
      background-color: $inbox-crm-sidebar-unlink-external-integration-person-bg-hover;
    }
    .inbox-crm-sidebar-expand-external-integration-person {
      color: white;
      background-color: $inbox-crm-sidebar-expand-external-integration-person-bg;
    }
    .inbox-crm-sidebar-expand-external-integration-person:hover {
      background-color: $inbox-crm-sidebar-expand-external-integration-person-bg-hover;
    }
    .inbox-crm-sidebar-profile-unavailable {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-left: 20px;
      padding-right: 20px;
      i {
        color: #b4bbce;
        font-size: 40px;
      }
      h5 {
        color: #43537f;
        font-size: 14px;
        font-weight: 900;
      }
      div {
        color: #838eab;
        font-size: 12px;
        font-weight: 700;
        text-align: center;
        padding: 0 10px;
      }
      button {
        margin-top: 30px;
        border: none;
        outline: none;
        border-radius: 20px;
        display: flex;
        width: 100%;
        justify-content: center;
        color: #fff;
        font-size: 12px;
        font-weight: 900;
        background-color: #668aea;
        padding: 6px;
      }
    }
    .inbox-crm-sidebar-bottom {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-left: 10px;
      padding-right: 10px;
      .modals {
        display: flex;
        flex-flow: column nowrap;
        flex-shrink: 0;
        list-style: none;
        margin-top: 20px;
        margin-bottom: 0;
        margin-left: 10px;
        margin-right: 10px;
        padding: 0;
        li {
          display: flex;
          flex-shrink: 0;
          margin: 3px 0;
          padding: 0;
          button {
            border: none;
            outline: none;
            border-radius: 20px;
            display: flex;
            flex-grow: 1;
            justify-content: center;
            color: #fff;
            font-size: 12px;
            font-weight: 900;
            background-color: #668aea;
            padding: 6px;
          }
        }
      }
    }
    .inbox-crm-sidebar-notes-active {
      .inbox-crm-sidebar-notes-header {
        height: $inbox-crm-sidebar-header-active-height;
        background-color: $inbox-crm-sidebar-notes-header-hover-bg-color;
        border: 0;
        flex: none;
      }
      .inbox-crm-sidebar-notes-header:hover {
        background-color: $inbox-crm-sidebar-notes-header-bg-color;
      }
      .inbox-crm-sidebar-tags,
      .inbox-crm-sidebar-external-integration-header {
        display: none;
      }
    }
    .inbox-crm-sidebar-external-integration-person-active {
      .inbox-crm-sidebar-notes-header,
      .inbox-crm-sidebar-external-integration-header {
        height: 0;
        margin: 0;
        flex: none;
        .inner {
          display: none;
        }
      }
      .inbox-crm-sidebar-tags {
        display: none;
      }
      .inbox-crm-sidebar-external-integration-person-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
      }
    }
    .inbox-crm-sidebar-external-integration-header {
      font-size: 16px;
      background-color: $inbox-crm-sidebar-external-integration-header-bg;
      margin-bottom: $section-margin-width;
      border-right: solid $inbox-crm-sidebar-header-border-width;
    }
    .inbox-crm-sidebar-external-integration-header:hover {
      background-color: $inbox-crm-sidebar-external-integration-header-hover-bg;
    }
    .inbox-crm-sidebar-external-integration-header.active {
      background-color: $inbox-crm-sidebar-external-integration-header-active-bg;
    }
    .inbox-crm-sidebar-external-integration-header.active:hover {
      background-color: $inbox-crm-sidebar-external-integration-header-active-hover-bg;
    }
    .inbox-crm-sidebar-external-integration-header:nth-of-type(2) {
      border-color: $inbox-crm-sidebar-external-integration-header-1st-bg;
    }
    .inbox-crm-sidebar-external-integration-header:nth-of-type(3) {
      border-color: $inbox-crm-sidebar-external-integration-header-2nd-bg;
    }
    .inbox-crm-sidebar-external-integration-header:nth-of-type(4) {
      border-color: $inbox-crm-sidebar-external-integration-header-3rd-bg;
    }
    .inbox-crm-sidebar-tags {
      margin-left: 10px;
      margin-right: 10px;
      .inbox-crm-sidebar-tags-icon {
        position: absolute;
        top: 10px;
        left: 25px;
        font-size: $inbox-crm-sidebar-stats-icon-size + 2;
        color: $inbox-crm-sidebar-stats-colour;
      }
      input {
        background-color: transparent;
        font-style: italic;
        &::placeholder {
          color: $inbox-crm-sidebar-stats-placeholder-colour;
          font-weight: 400;
        }
      }
    }
    .plugins {
      h4 {
        font-size: 14px;
      }
      li {
        margin: 5px 0;
      }
      button {
        width: 100%;
        background: #fff;
        border: none;
        border-radius: 20px;
        display: flex;
        flex-grow: 1;
        font-size: 1em;
        font-weight: 600;
        padding: 3px 20px;
        margin: 0 0 10px 0;
      }
    }
  }
}
