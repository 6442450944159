@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-widget-social-wall {
  height: 100%;
  width: 100%;

  .dashboard-widget {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 10px;
    overflow: hidden;

    .article-grid {
      height: 100%;
      overflow: auto;
      margin-top: 7px;
    }

    .form-field {
      padding: 10px;
      // border-bottom: 1px solid #c3c9d8;
      font-weight: 900;

      .label-clear-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 5px;
      }

      label {
        &.field-title {
          font-size: 10px;
          line-height: 12px;
          padding-left: 20px;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: $lmode-body-text;
          font-weight: 900;
        }
      }
    }

    ssi-dropdown-select-2 {
      width: 100%;

      .dd-head {
        width: 100%;
      }
      .dd-body {
        width: 100%;
      }
      .select-accounts-label {
        display: flex;
        align-items: center;
        span {
          padding-left: 12px;
          color: $lmode-title-text;
          font-weight: 900;
        }
      }
    }

    .message-info {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      text-align: center;

      &.view-1x1,
      &.view-2x1,
      &.view-3x1,
      &.view-4x1 {
        justify-content: start;
        padding-top: 10px;
      }

      i {
        color: #b4bbce;
        font-size: 37.5px;
      }

      h5 {
        font-family: 'Lato', 'Arial', sans-serif;
        font-size: 20px;
        font-weight: 900;
        line-height: 24px;
        color: #43537f;
      }

      p {
        font-family: 'Lato', 'Arial', sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        max-width: 222px;
        color: #43537f;

        &.view-1x1,
        &.view-2x1,
        &.view-3x1,
        &.view-4x1 {
          max-width: 312px;
        }
      }
    }
  }

  ssi-activity {
    display: block;
    margin-bottom: 10px;
    background-color: #f4f4fa;
    padding: 10px 15px 10px;
    border-radius: 10px;
    border: 1px solid #c3c9d8;
  }
}
