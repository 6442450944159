@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../../angularjs/common/sass/include-media';
@import '~/styles/colors';

$margin-between-boxes: 2em;
$box-radius: 12px;
$box-shadow: 0 5px 20px 0 rgba(131, 142, 171, 0.2);

$box-bg-color: #fff;

ssi-engagement ssi-top-engagers {
  font-size: 1rem;

  .verified {
    display: inline-block;
    color: #1DA1F2;
    font-size: 16px;
    margin: 0 0 0 2px;
  }

  .text {
    font-size: 1.4em;
    font-weight: 900;
    color: $primary-active;
    margin: 0 0.1em;
  }
  .number {
    font-size: 1.4em;
    font-weight: 900;
    color: $lmode-title-text;
    &.big {
      font-size: 2.8em;
      margin-left: auto;
    }
    span {
      font-size: 0.5em;
      margin-left: 0.3em;
      text-transform: lowercase;
    }
  }

  .network-icon {
    color: $primary-active;
    font-size: 1.5em;
    margin: 0 0.8em;
  }

  .top-engagers-container {
    display: flex;
    flex-wrap: wrap;
    gap: $margin-between-boxes;

    @media print {
      display: block;
    }
  }

  .inbound-count,
  .inbound-percentage {
    background-color: $box-bg-color;
    border-radius: $box-radius;
    box-shadow: $box-shadow;
    padding: 3em;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 1 calc(50% - #{$margin-between-boxes});

    @media print {
      display: block;
      margin: 10px 0 0;
      border: 1px solid black;
      page-break-inside: avoid;
    }

    h3 {
      color: $lmode-title-text;
      font-size: 2.8em;
      margin-bottom: 0;
      font-weight: 900;
    }
    h4 {
      width: 100%;
      color: $lmode-helper-text;
      font-size: 1.4em;
      line-height: 1.5em;
    }

    ssi-medal-score {
      height: 8em;
      margin-right: 2em;
    }
  }

  .inbound-percentage {
    i {
      font-size: 4.6em;
      color: $lmode-helper-text;
      margin-left: 1em;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      display: flex;
      align-items: center;
      margin-bottom: 1em;

      > span {
        margin-left: 2em;
        &:last-child {
          margin-left: 3em;
        }
      }
      i,
      span {
        color: $primary-active;
      }
    }
  }

  .avatar-img {
    width: 3.6em;
    height: auto;
    align-self: center;
    margin-left: 1em;
    border-radius: 50%;
  }

  > span {
    margin-left: 2em;
  }

  .top-engagers-tables {
    display: flex;
    align-items: flex-start;
    background-color: $box-bg-color;
    border-radius: $box-radius;
    box-shadow: $box-shadow;
    margin-top: $margin-between-boxes;
    padding: 5em 3em;
    flex-wrap: wrap;
    table {
      width: calc(50% - 3em);
      &:first-child {
        margin-right: 3em;
      }
      &:last-child {
        margin-left: 3em;
        @include media('<portal') {
          margin: 2em 3em 0 0;
        }
      }
      th {
        color: $lmode-body-text;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 900;
        font-size: 1em;
        white-space: nowrap;
      }
      tr {
        border-bottom: 2px solid #e0e7f4;
        td:not(:first-child) {
          padding: 1.5em 0;
        }
        td {
          &:first-child {
            padding-left: 1em;
          }
        }
        &:last-child {
          border-bottom: none;
        }
      }

      tbody {
        &::before {
          content: '-';
          display: block;
          line-height: 2em;
          color: transparent;
        }
      }

      @include media('<portal') {
        width: 100%;
      }
    }
  }
}
