@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../angularjs/common/sass/variables';
@import '../../../../../../angularjs/common/sass/mixins';
@import '../../../../../common/styles/colors.scss';

ssi-conversation-resolution-confirmation {
  align-items: center;
  background-color: white;
  color: $lmode-body-text;
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  justify-content: center;
  height: 100%;
  border-radius: 12px;

  * {
    display: flex;
  }

  .confirmation-message {
    align-items: center;
    flex-flow: column nowrap;
    font-weight: 500;
    justify-content: center;

    .message-icon {
      font-size: 4em;
      color: $dmode-body-text;
      margin-bottom: 0.15em;
    }

    .message-text {
      flex-flow: column nowrap;
      margin-bottom: 2em;
      h1 {
        font-size: 1.4em;
        font-weight: 800;
        justify-content: center;
        margin: 0;
      }
      h2 {
        font-size: 1em;
        font-weight: 600;
        justify-content: center;
        color: $lmode-helper-text;
      }
    }
    .dismiss-button {
      justify-content: center;
      background-color: $primary-active;
      min-width: 120px;
    }
  }
}
