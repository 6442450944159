ssi-widget-swot {
  font-size: 1rem;
}
ssi-widget-swot .insights-widget {
  position: relative;
  height: 100%;
  width: 100%;
}
ssi-widget-swot .insights-widget-swot {
  display: flex;
  align-items: center;
  justify-content: center;
}
ssi-widget-swot .insights-swot-wrapper {
  height: 80%;
  width: 93%;
}
ssi-widget-swot .insights-swot-wrapper .highcharts-button.highcharts-drillup-button rect {
  fill: #fff;
  stroke-width: 0;
  rx: 10;
  ry: 10;
}
ssi-widget-swot .insights-swot-wrapper .highcharts-button.highcharts-drillup-button text {
  color: #14bae3 !important;
  fill: #14bae3 !important;
  font-family: "Lato", "Arial", sans-serif;
  font-weight: 900 !important;
  text-rendering: geometricPrecision;
}
ssi-widget-swot .swot-tooltip {
  width: 330px;
  padding: 12px 8px 8px 12px;
}
ssi-widget-swot .swot-tooltip p {
  max-width: 100%;
  white-space: normal;
  margin: 0;
  font-weight: 700;
  line-height: 20px;
  text-rendering: geometricPrecision;
  font-size: 14px;
}
ssi-widget-swot .swot-tooltip p:first-child {
  font-size: 20px;
  margin: 0 0 8px;
  font-weight: 900;
}
ssi-widget-swot .insights-swot-loading {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: #43537F;
}
ssi-widget-swot .insights-swot-loading img {
  height: 4em;
  width: 4em;
  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
ssi-widget-swot .insights-swot-loading h2 {
  font-size: 2em;
  margin: 3rem 0 1rem;
  color: inherit;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-widget-swot .insights-swot-loading p {
  font-size: 1.4em;
  line-height: 2rem;
  max-width: 20rem;
  text-align: center;
  color: inherit;
  font-weight: 700;
  text-rendering: geometricPrecision;
}
@keyframes spin {
  from {
    transform: rotate3d(0, 0, 0, 0deg);
  }
  to {
    transform: rotate3d(0, 0, 1, 360deg);
  }
}