ssi-cl-dropdown-select-c .cl-dropdown-select-c-container ssi-dropdown-select-2 ssi-social-network-icon {
  margin-right: -8px;
}
ssi-cl-dropdown-select-c .cl-dropdown-select-c-container ssi-dropdown-select-2 .dd-body ul {
  max-height: 400px !important;
}
ssi-cl-dropdown-select-c .cl-dropdown-select-c-container ssi-dropdown-select-2 .select-placeholder {
  font-size: 14px;
  font-weight: 700;
  color: #6a759a;
}
ssi-cl-dropdown-select-c .cl-dropdown-select-c-container ssi-dropdown-select-2 .select-accounts-label {
  display: flex;
  align-items: center;
}
ssi-cl-dropdown-select-c .cl-dropdown-select-c-container ssi-dropdown-select-2 .select-accounts-label span {
  padding-left: 12px;
  color: #101525;
  font-weight: 900;
}