@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-insights-posts {
  .posts-container {
    overflow: auto;
    background-color: #f4f4fa;
    height: calc(100vh - 199px);
    display: flex;
    flex-direction: column;

    &.filters-bar-visible {
      height: calc(100vh - 249px);
    }

    .posts-wrapper {
      flex: 1 0 auto;
      padding: 6px 12px;
      display: flex;

      .column {
        flex: 1 1 calc(50% - 3px);
        margin: 6px;

        .post-wrapper {
          margin-bottom: 10px;
        }
      }
    }

    .no-posts-wrapper {
      flex: 1 1 100%;
      padding: 6px 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .paginator-wrapper {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      margin: 60px 0;

      &.hidden {
        display: none;
      }
    }
  }
}
