ssi-cl-dropdown-select .cl-dropdown-select-container .body {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
ssi-cl-dropdown-select .cl-dropdown-select-container .body > * {
  margin: 0 10px 30px 10px;
  flex: 0 0 600px;
}
ssi-cl-dropdown-select .cl-dropdown-select-container .body > * a {
  font-size: 10px;
}
ssi-cl-dropdown-select .cl-dropdown-select-container .body > * p {
  font-size: 10px;
  margin: 0;
}
ssi-cl-dropdown-select .cl-dropdown-select-container .body > * p:last-of-type {
  margin-bottom: 20px;
}