@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-query-builder-step {
  font-size: 1rem;

  .stream-builder-query {
    margin: 0 0.5em;
  }

  .query-guide-container {
    background-color: #ecf5f5;
    border: 2px solid #cceae8;
    padding: 0.8em;
    margin: 0 0 0.8em;
    border-radius: 1.2em;
    color: #43537f;
  }

  .query-guide-label {
    display: inline-block;
    background-color: #cceae8;
    border-radius: 0.4em;
    color: #12aca4;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin: 0 0 0.8em;
    padding: 0.8em 1em 0.7em;

    p {
      font-size: 1.2em;
      line-height: 1;
      font-weight: 900;
      text-rendering: geometricPrecision;
      margin: 0;
    }
  }

  .query-guide-sentence {
    color: #43537f;
    font-weight: 700;
    text-rendering: geometricPrecision;
    margin: 0.3em 0.6em 1em;

    i {
      font-weight: 900;
    }
  }

  .query-actionbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 1.2em;
    padding: 0.8em 1em;
    background-color: #f4f4fa;
    margin: 0 0 3em;
  }

  .query-actionbar-container-column {
    flex: 1 0 0;

    &-center {
      text-align: center;
    }

    &-right {
      text-align: right;
    }

    orlo-button {
      margin: 0 0.8em 0 0;
    }
  }

  .query-actionbar-button {
    button {
      padding: 9px 10px 10px;
      font-size: 12px;
      border-radius: 10px;
      font-weight: 900;
      text-rendering: geometricPrecision;

      .ssi {
        margin: 0 2px 0 0;
      }

      .ssi-addsmall {
        font-size: 10px;
      }

      .ssi-pro-tip {
        font-size: 16px;
        vertical-align: middle;
      }
    }

    &-border button {
      padding: 7px 10px 8px;
      border: 2px solid #cceae8;
      box-sizing: border-box;
    }
  }

  .actionbar-toggle {
    display: inline-flex;
    height: 4em;
    width: 11em;
  }

  .actionbar-toggle-item {
    flex: 1 0 0;
    height: 100%;

    &:first-child label {
      border-left: 0.2em solid #c3c9d8;
      border-top-left-radius: 1.2em;
      border-bottom-left-radius: 1.2em;
    }

    &:last-child label {
      border-right: 0.2em solid #c3c9d8;
      border-top-right-radius: 1.2em;
      border-bottom-right-radius: 1.2em;
    }

    label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      height: 100%;
      background-color: #fff;
      border-top: 0.2em solid #c3c9d8;
      border-bottom: 0.2em solid #c3c9d8;
      color: #838eab;
      font-weight: 900;
      text-rendering: geometricPrecision;
      cursor: pointer;

      span {
        font-size: 1.6em;
      }
    }

    input {
      position: absolute;
      z-index: -1;
      opacity: 0 !important;

      &:checked + label {
        background-color: black;
        color: #fff;
      }
    }

    &-blue input:checked + label {
      background-color: #425dec;
      border-color: #425dec;
    }

    &-yellow input:checked + label {
      background-color: #f0b427;
      border-color: #f0b427;
    }
  }

  .query-container-no-query {
    text-align: center;
    margin: 10em auto 3em;
    width: 36em;
    max-width: 100%;

    .ssi {
      color: #b4bbce;
      font-size: 5em;
    }

    h2 {
      font-size: 2em;
      color: #43537f;
      font-weight: 900;
      text-rendering: geometricPrecision;
      margin: 0.5em 0 0.3em;
    }

    p {
      color: #838eab;
      font-weight: 700;
      line-height: 1.5em;
      text-rendering: geometricPrecision;
    }
  }

  .query-container-builder {
    width: 73em;
    max-width: 100%;
    margin: 0 auto 3em;
  }

  ssi-query-builder-condition {
    & + .query-container-builder-group {
      margin-top: 3.5em;

      &:before {
        content: '';
        position: absolute;
        top: -3.9em;
        left: 4.8em;
        height: 2.2em;
        border: 0.1em solid #c3c9d8;
      }
    }
  }

  ssi-query-builder-condition.parent-child,
  ssi-query-builder-condition.parent {
    & + .query-container-builder-row {
      .query-container-builder-row-tree {
        &:before {
          content: '';
          position: absolute;
          display: inline-block;
          border: 0.1em solid #c3c9d8;
          height: calc(100% + 0.7em);
          left: 3.8em;
          top: -0.7em;
        }
      }
    }
  }

  .query-container-builder-row {
    display: flex;
    align-items: flex-start;
    margin: 0;
  }

  .query-container-builder-row-tree {
    width: 11em;
    flex: 0 0 11em;
    height: 7em;
  }

  .query-container-builder-group {
    position: relative;
    border: 0.2em solid #89a4ea;
    margin: 1em -1.2em 2.5em;
    padding: 1em 1em 1.4em;
    border-radius: 1em;
    background-color: #f8f9fd;

    .query-container-builder-row {
      height: 5.4em;

      &-single {
        .query-container-builder-row-tree-label {
          top: 1em;
        }

        .query-container-builder-row-tree-guides {
          // position: absolute;
          // width: 4em;
          // top: 2.3em;
          // right: 0.8em;
          // border: 0.1em solid #C3C9D8;
        }
      }

      &-parent,
      &-parent-child,
      &-child {
        .query-container-builder-row-tree-label {
          // position: absolute;
          top: 1em;
          bottom: auto;
          // right: 4.8em;
        }

        .query-container-builder-row-tree-guides {
          // position: absolute;
          // width: 6.4em;
          // top: 2.3em;
          // right: 0.8em;
          // border: 0.1em solid #C3C9D8;

          &:before {
            // content: '';
            // position: absolute;
            // left: -0.1em;
            // border: 0.1em solid #C3C9D8;
            // height: 2.4em;
          }
        }
      }

      &-parent-child {
        .query-container-builder-row-tree-guides {
          &:after {
            // content: '';
            // position: absolute;
            // left: -0.1em;
            // border: 0.1em solid #C3C9D8;
            height: 3em;
            top: -3em;
          }
        }
      }

      &-child {
        .query-container-builder-row-tree-label {
          display: inline-flex;
        }

        .query-container-builder-row-tree-guides {
          &:before {
            top: -3em;
          }
        }
      }
    }

    .query-container-builder-row-tree-label {
      background-color: #f8f9fd;
    }

    .query-container-builder-row-tree {
      position: relative;
      width: 11em;
      flex: 0 0 11em;
    }
  }

  .query-container-builder-group-join {
    position: relative;
    color: #838eab;
    font-weight: 900;
    text-rendering: geometricPrecision;
    text-transform: uppercase;
    z-index: 1;
    letter-spacing: 0.1em;
    margin: -2.5em 0 0;
    height: 5em;

    .query-container-builder-group-join-label {
      position: absolute;
      width: 4.2em;
      text-align: center;
      top: 1.3em;
      left: 1.2em;
      font-size: 1.2em;
      pointer-events: none;
    }

    .query-container-builder-group-join-guides {
      &:before,
      &:after {
        content: '';
        position: absolute;
        left: 3.8em;
        display: inline-block;
        border: 0.1em solid #c3c9d8;
        height: 1.2em;
      }

      &:before {
        top: 0;
      }

      &:after {
        bottom: 0;
      }
    }
  }

  .query-container-builder-row-condition-select-wrap {
    display: flex;
    justify-content: flex-end;
    margin: 0 0 1em;
  }

  .query-container-builder-row-condition-select {
    display: inline-block;
    flex: 0 1 0;
    position: relative;

    .ssi {
      position: absolute;
      pointer-events: none;
      color: #838eab;
      height: 1em;
      top: 0;
      bottom: 0;
      font-size: 1.2em;
      right: 2rem;
      margin: auto;
    }

    select {
      background-color: #f4f4fa;
      border: 0.2rem solid #c3c9d8;
      color: #43537f;
      font-weight: 900;
      text-rendering: geometricPrecision;
      font-size: 1.4em;
      padding: 1.1rem 4rem 1.2rem 2rem;
      border-radius: 1rem;
      appearance: none;
    }

    &:not(:first-child) {
      select {
        width: 160px;
      }
    }
  }

  .query-container-builder-group-add {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 1em;
    border: 0.2em solid #c3c9d8;
    background-color: #fff;
    height: 4.6em;
    width: 4.6em;

    .ssi {
      font-size: 1.2em;
      color: #43537f;
    }
  }

  .query-container-builder-group-delete {
    position: absolute;
    bottom: 1.2em;
    right: 2em;
    color: #43537f;
    padding: 0.5em;
    cursor: pointer;

    p {
      display: inline-block;
      margin: 0;
      font-size: 1.2em;
      font-weight: 900;
      text-rendering: geometricPrecision;
    }

    .ssi {
      vertical-align: text-top;
      margin: 0 0.2em 0 0;
    }
  }
}

.query-builder-pro-tip-modal {
  background-color: rgba(53, 53, 63, 0.85);

  .modal-dialog {
    width: calc(100% - 15px);
    max-width: 1025px;
  }

  .modal-content {
    padding: 20px;
    box-shadow: 0 5px 20px rgba(131, 142, 171, 0.2);
    border-radius: 12px;
    background-color: #fff;
    color: #43537f;
  }

  .modal-close-button {
    display: inline-block;
    border-radius: 50%;
    border-width: 0;
    background-color: #f4f4fa;
    height: 36px;
    width: 36px;
    color: #14bae3;
    line-height: 0;
    float: right;

    .ssi {
      font-size: 10px;
      transform: rotate(45deg);
    }
  }

  .pro-modal-header {
    margin: 0 60px 35px 50px;

    h2 {
      font-size: 20px;
      margin: 30px 0 14px;
      font-weight: 900;
      text-rendering: geometricPrecision;
    }

    p {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      text-rendering: geometricPrecision;
    }

    a {
      font-weight: 900;
      color: #14bae3;
      text-decoration: underline;
    }
  }

  .pro-modal-body {
    background-color: #f8f9fd;
    border-radius: 10px;
    padding: 30px 30px 0;

    h3 {
      font-size: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-weight: 900;
      text-rendering: geometricPrecision;
      margin: 7px 20px 13px;
    }

    img {
      display: none;
      flex: 1 0 234px;
      align-self: flex-end;
      height: 303px;
      width: 234px;
      margin: -35px -15px 0 80px;
      object-fit: cover;
      object-position: top;

      @media (min-width: 768px) {
        display: block;
      }
    }
  }

  .pro-modal-body-content {
    display: flex;
  }

  .pro-modal-body-legend {
    display: flex;
    flex-wrap: wrap;

    dt {
      flex: 0 1 97px;
      width: 97px;
      text-align: right;
      padding: 0 20px 0 0;

      .pro-modal-body-key {
        margin: 5px 0 0;
      }
    }

    dd {
      flex: 1 1 calc(100% - 97px);
      width: calc(100% - 97px);
      color: #43537f;
      font-weight: 700;
      text-rendering: geometricPrecision;
      line-height: 20px;
      margin: 0 0 20px;

      strong {
        font-weight: 900;
      }

      &:after {
        content: '';
        display: block;
        flex-basis: 100%;
        height: 0;
      }
    }
  }

  .pro-modal-body-key {
    display: inline-block;
    min-width: 50px;
    text-align: center;
    padding: 6px 9px 7px;
    border-radius: 4px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #fff;
    font-size: 12px;
    text-rendering: geometricPrecision;
    font-weight: 900;
    background-color: #eee;

    &-blue {
      background-color: #425dec;
    }

    &-yellow {
      background-color: #f0b427;
    }

    &-orange {
      background-color: #f88c68;
    }

    &-white {
      background-color: #fff;
      color: #43537f;
    }
  }
}
