@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-cl-post-scheduler {
  .cl-post-scheduler-container {
    .body {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      > * {
        margin: 10px;
        margin-bottom: 30px;
        flex: 0 0 260px;

        a {
          font-size: 10px;
        }
        p {
          font-size: 10px;
          margin: 0;
          &:last-of-type {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
