@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-bulk-upload {
  font-size: 1rem;

  .bu-divider {
    position: relative;
    text-align: center;
    margin: 0 0 2em;
    width: 100%;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      display: block;
      height: 0.1em;
      width: 40%;
      margin: auto;
      border-top: 0.1em solid #eee;
    }

    &:after {
      right: 0;
    }

    i {
      font-size: 4.2em;
    }
  }

  .panel {
    width: 100%;
  }

  .panel-heading {
    font-size: 1.4em;
  }

  .bu-action-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto 3em;
    padding: 0;
    max-width: 85%;

    & > li {
      display: block;
      flex: 1 0 auto;
      margin: 0 1.5em 1em;
    }
  }

  .bu-complete {
    font-size: 2.6em;
    font-weight: 700;

    small {
      display: block;
      font-size: 1.7rem;
      color: #777;
      font-weight: 300;
      margin: 1.4em 0;
    }
  }
}
