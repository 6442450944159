ssi-audit-event .audit-event {
  margin-top: 5px;
  padding-bottom: 5px;
  display: flex;
}
ssi-audit-event .audit-event .date-box {
  margin-right: 15px;
  padding-top: 1px;
  width: 40px;
}
ssi-audit-event .audit-event .date-box span {
  color: #43537f;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: nowrap;
}
ssi-audit-event .audit-event .icon-box {
  margin-right: 15px;
}
ssi-audit-event .audit-event .icon-box .icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  margin-bottom: 4px;
}
ssi-audit-event .audit-event .icon-box .icon-container i.action-icon {
  color: #fff;
  border-radius: 50%;
  padding: 6px;
  font-size: 14px;
  max-height: 26px;
}
ssi-audit-event .audit-event .icon-box .icon-container .trigger-label {
  color: #43537f;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0 8px;
}
ssi-audit-event .audit-event .icon-box .delimiter {
  position: relative;
  height: 25px;
  border-left: 2px dotted #c1cff2;
  margin-left: 12px;
  margin-top: 1px;
  height: calc(100% - 26px);
}
ssi-audit-event .audit-event .result-box {
  padding-top: 2px;
  min-height: 54px;
}
ssi-audit-event .audit-event .result-box .message {
  text-align: start;
}
ssi-audit-event .audit-event .result-box .message span {
  color: #43537f;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
}
ssi-audit-event .audit-event .result-box .message span strong {
  font-weight: 900;
}
ssi-audit-event .audit-event .result-box .message span.time {
  white-space: nowrap;
}
ssi-audit-event .audit-event .result-box .params {
  margin-top: 5px;
  overflow: auto;
  margin-bottom: 12px;
}
ssi-audit-event .audit-event .result-box .params .tag {
  border-radius: 100px;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  width: fit-content;
  color: #43537f;
  font-size: 10px;
  font-weight: 900;
  line-height: 12px;
}
ssi-audit-event .audit-event .result-box .params .tag.added-tag {
  background-color: #ecf0fb;
}
ssi-audit-event .audit-event .result-box .params .tag.removed-tag {
  background-color: #fff1ed;
}
ssi-audit-event .audit-event .result-box .params .note {
  display: inline;
  color: #43537f;
  font-size: 14px;
  font-weight: 700;
}
ssi-audit-event .audit-event .result-box .params .note-action {
  color: #14bae3;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  cursor: pointer;
}
ssi-audit-event .audit-event .result-box .params .note-action:hover, ssi-audit-event .audit-event .result-box .params .note-action:active {
  color: #1094b4;
}
ssi-audit-event .audit-event.verb-viewed i.action-icon {
  background-color: #d41d68;
}
ssi-audit-event .audit-event.verb-statusChange.status-unread i.action-icon {
  background-color: #14bae3;
}
ssi-audit-event .audit-event.verb-statusChange.status-actioned .icon-container {
  background-color: rgba(178, 198, 20, 0.2);
}
ssi-audit-event .audit-event.verb-statusChange.status-actioned .icon-container i.action-icon {
  background-color: #b2c614;
}
ssi-audit-event .audit-event.verb-statusChange.status-unactioned i.action-icon {
  background-color: #aeb6cc;
}
ssi-audit-event .audit-event.verb-assigned .icon-container {
  background-color: rgba(240, 180, 39, 0.2);
}
ssi-audit-event .audit-event.verb-assigned .icon-container i.action-icon {
  background-color: #f0b427;
}
ssi-audit-event .audit-event.verb-unassigned i.action-icon {
  background-color: #ff4000;
}
ssi-audit-event .audit-event.verb-addedTags .icon-container {
  background-color: rgba(41, 180, 173, 0.2);
}
ssi-audit-event .audit-event.verb-addedTags .icon-container i.action-icon {
  background-color: #12aca4;
}
ssi-audit-event .audit-event.verb-removedTags i.action-icon {
  background-color: #ff4000;
}
ssi-audit-event .audit-event.verb-addedNote .icon-container {
  background-color: #c6d6ff;
}
ssi-audit-event .audit-event.verb-addedNote .icon-container i.action-icon {
  background-color: #749afd;
}
ssi-audit-event .audit-event.verb-deletedNote i.action-icon {
  background-color: #ff4000;
}
ssi-audit-event .audit-event.verb-socialLiked i.action-icon {
  color: #d41d68 !important;
  background-color: #ecf0fb;
}
ssi-audit-event .audit-event.verb-socialUnliked i.action-icon {
  background-color: #838eab;
}
ssi-audit-event .audit-event.verb-socialHide i.action-icon {
  color: #35353f !important;
  background-color: #dfdfe3;
}
ssi-audit-event .audit-event.verb-socialUnHide i.action-icon {
  color: #14bae3 !important;
  background-color: #eef1fb;
}
ssi-audit-event .audit-event.verb-emailed i.action-icon {
  background-color: #4843b2;
}
ssi-audit-event .audit-event.verb-blocked i.action-icon {
  background-color: #ff0808;
}
ssi-audit-event .audit-event.verb-unblocked i.action-icon {
  background-color: #b2c614;
}
ssi-audit-event .audit-event.verb-redacted i.action-icon {
  color: #35353f !important;
  background-color: #dfdfe3;
}
ssi-audit-event .audit-event.verb-sensitiveMediaViewed .icon-container {
  background-color: rgba(178, 198, 20, 0.2);
}
ssi-audit-event .audit-event.verb-sensitiveMediaViewed .icon-container i.action-icon {
  background-color: #b2c614;
}
ssi-audit-event .audit-event.verb-sensitiveMediaHidden i.action-icon {
  background-color: #aeb6cc;
}
ssi-audit-event .audit-event.verb-deleted i.action-icon {
  background-color: #ff4000;
}
ssi-audit-event .audit-event.verb-created i.action-icon {
  background-color: #b2c614;
}
ssi-audit-event .audit-event.verb-published i.action-icon {
  background-color: #14bae3;
}
ssi-audit-event .audit-event.verb-updated i.action-icon {
  background-color: #f0b427;
}
ssi-audit-event .audit-event.verb-updated.re-scheduled i.action-icon {
  background-color: #b2c614;
}
ssi-audit-event .audit-event.verb-accepted i.action-icon {
  background-color: #65c9e1;
}
ssi-audit-event .audit-event.verb-rejected i.action-icon {
  background-color: #f88c68;
}
ssi-audit-event .audit-event.verb-publish-failed i.action-icon {
  background-color: #ff4000;
}
ssi-audit-event .audit-event.verb-resolved i.action-icon {
  background-color: #b2c614;
}
ssi-audit-event .audit-event.verb-unresolved i.action-icon {
  background-color: #f88c68;
}
ssi-audit-event .capitalized {
  text-transform: capitalize;
}
ssi-audit-event .no-wrap {
  white-space: nowrap;
}