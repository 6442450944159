@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

$header-height: 49px;
$footer-height: 107px;

ssi-dashboard-widget-modal {
  position: relative;
  font-size: 1rem;

  .dwm-close {
    position: absolute;
    right: 2em;
    top: 2em;
    display: inline-block;
    border-radius: 50%;
    background-color: $lgrey-background;
    border-width: 0;
    height: 3.6em;
    width: 3.6em;
    outline-width: 0;
    z-index: 1;

    .ssi {
      font-size: 1.2em;
      color: $lmode-helper-text;
    }

    .ssi-addsmall {
      transform: rotate(45deg);
    }
  }

  .dashboard-widget-modal {
    background-color: #fff;
    height: 100%;
    overflow: auto;
  }

  .dwm-container {
    display: flex;
    flex-wrap: wrap;
    padding: 2em 2em $footer-height;
    background-color: #fff;
    border-top-left-radius: 1.2em;
    border-top-right-radius: 1.2em;

    @media (min-width: 768px) {
      flex-wrap: nowrap;
      min-height: 100%;
    }
  }

  .dwm-aside {
    display: flex;
    flex-direction: column;
    flex: 0 0 100%;
    margin-left: 1.4em;
    padding: 0;

    @media (min-width: 768px) {
      flex: 0 0 24em;
      padding: 0 0 0 1.6em;
    }
  }

  .dwm-aside-header,
  .dwm-body-header {
    display: flex;
    align-items: flex-end;
    height: $header-height;
    margin-bottom: 4.5em;
    padding: 0 1.6em 0 0;

    h1 {
      font-size: 2em;
      font-weight: 900;
      color: $lmode-body-text;
      white-space: nowrap;
      margin: 0;
      line-height: 1;
      text-rendering: geometricPrecision;
    }

    a {
      display: block;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    p,
    i {
      display: inline-block;
      color: #43537f;
      font-size: 1.4em;
      font-weight: 900;
      line-height: 1;
      margin: 0;
    }

    i {
      transform: rotate(90deg);
      font-size: 1em;
      margin: 0 8px 0 0;
      vertical-align: text-top;
    }
  }

  .dwm-aside-body {
    flex: 1 0 auto;
    padding: 0 1.6em 2em 0;

    @media (min-width: 768px) {
      border-right: 1px solid #d1dbf4;
    }

    h2 {
      color: #43537f;
      font-weight: 900;
      font-size: 1.6em;
      line-height: 19px;
      margin: 0 0 7px;
      text-rendering: geometricPrecision;
    }

    p {
      color: #43537f;
      font-weight: 700;
      line-height: 20px;
      font-size: 1.4em;
      margin-bottom: 0;
      text-rendering: geometricPrecision;
    }
  }

  .dwm-aside-meta {
    background-color: #e8f8fc;
    border-radius: 1em;
    padding: 1.4em;
    margin: 2em 0 0 -1.4em;

    p {
      color: #058eaf;
      font-size: 1.4em;
      line-height: 20px;
    }
  }

  .thumbnail-action {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1.2em;
    width: 24em;
    height: 16em;
    margin: 0 0 1em;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='#14BAE3' stroke-width='3' stroke-dasharray='5%0c 10' stroke-dashoffset='100' stroke-linecap='square'/%3e%3c/svg%3e"),
      linear-gradient(0deg, #e8f9fd, #e8f9fd),
      linear-gradient(0deg, #f8f9fd, #f8f9fd);
  }

  .thumbnail-button {
    width: 46px;
    height: 46px;
    background-color: $primary-active;
    border-width: 0;
    border-radius: 50%;
    color: #fff;
    z-index: 1;
  }

  .item-thumb {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    border-radius: 1.2em;
    margin-bottom: 20px;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 138px;
      color: #c8f0f9;
    }
  }

  .dwm-body {
    flex: 1 1 auto;
  }

  .dwm-body-content {
    width: 100%;
    display: flex;
  }

  .widget-config {
    flex: 1 1 auto;
  }

  .dwm-form {
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 1em 1em 1em;
  }

  .dwm-form-half {
    flex: 1 0 100%;

    @media (min-width: 768px) {
      flex: 1 0 calc(50% - 16px); // 16 is col gutter
      min-width: 30em;
      margin: 0 0.8em;
    }
  }

  .dwm-filters {
    height: 100%;
    flex: 0 0 540px;
    position: relative;
    padding: 0 1em 1em 1em;
    margin-left: auto;

    label {
      padding-left: 30px;
    }
  }

  .dwm-body-row {
    padding: 0 3em;
    margin: 0 0 2em;
  }

  .dwm-label {
    display: block;
    text-transform: uppercase;
    font-size: 1em;
    font-weight: 900;
    letter-spacing: 0.1em;
    margin: 0 0 1.8em;
    color: #6a759a;
    text-rendering: geometricPrecision;
  }

  .dwm-body-row-input {
    position: relative;
    margin: 0 -3rem 0;
  }

  .dwm-input {
    font-size: 1.4em;
    width: 100%;
    border-width: 0;
    height: 4.6rem;
    border-radius: 1rem;
    color: #101525;
    font-weight: 700;
    text-rendering: geometricPrecision;
    text-indent: 3rem;
    padding: 0 3rem 0 0;
    background-color: #fff;
    border: 1px solid #c3c9d8;

    &:focus {
      outline: 1px solid $primary-active;
    }

    &:disabled {
      opacity: 0.4;
    }

    &::placeholder {
      color: #6a759a;
      text-rendering: geometricPrecision;
    }
  }

  .dwm-body-row-input-meta {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    height: 1em;
    line-height: 1;
    right: 1.8em;
    color: $lmode-helper-text;
    font-size: 1em;
    font-weight: 900;
    text-rendering: geometricPrecision;

    &-active {
      color: $primary-active;
    }
  }

  .dwm-input-toggle {
    display: flex;
    align-items: center;
    font-size: 1.4em;
    width: 100%;
    border-width: 0;
    height: 4.6rem;
    border-radius: 1rem;
    color: #101525;
    font-weight: 700;
    text-rendering: geometricPrecision;
    text-indent: 3rem;
    padding: 0 1.3rem 0 0;
    background-color: #f8f9fd;
    border: 1px solid #c3c9d8;

    &.toggled {
      background-color: #e4faff;
      border: 1px solid #14bae3;
    }

    .toggle-switch-2-container {
      width: 100%;
    }

    .toggle-switch-2-label {
      letter-spacing: 0;
      color: #43537f;
      &.active {
        color: #43537f;
      }
    }

    .switch {
      font-size: 0.8em;
      outline: none;
    }
  }

  .dwm-dropdown-selector {
    margin: 0 -3rem;

    .dd-container {
      width: 100%;
      .dd-head {
        width: 100%;
        padding: 0 30px;
      }
      .dd-body {
        width: 100%;
        ul {
          max-height: 268px;
        }
      }
    }
  }

  .dwm-footer {
    position: absolute;
    bottom: 1px;
    width: 100%;
    height: $footer-height;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 2.6em 2.5em 1.7em;
    border-top: 1px solid #d1dbf4;
    max-height: $footer-height;
    background-color: #fff;
    border-bottom-left-radius: 1.2em;
    border-bottom-right-radius: 1.2em;

    &-left {
      flex: 0 0 auto;
      padding: 0 0 0 0.5em;
    }

    &-right {
      flex: 0 1 auto;
      display: flex;
      align-items: center;

      .save-button {
        margin-left: 10px;
      }
    }
  }
}
