.reauth-accounts {
  color: #838eab;
}
.reauth-accounts h2 {
  color: #838eab;
}
.reauth-accounts h3 {
  margin-bottom: 3rem;
}
.reauth-accounts .container {
  position: relative;
  border-radius: 25px;
  padding: 1rem 3rem;
}
.reauth-accounts .rounded-container {
  width: 100%;
  padding: 3rem;
  background-color: #fff;
  border-radius: 25px;
  margin-top: 1.5rem;
}
.reauth-accounts .rounded-container.extra-padding {
  padding-left: 4rem;
}
.reauth-accounts .rounded-container.no-accounts {
  text-align: center;
  padding: 10rem;
}
.reauth-accounts .rounded-container.no-accounts i {
  color: #eff3fa;
  font-size: 9rem;
}
.reauth-accounts .rounded-container.no-accounts h3 {
  margin-bottom: 0;
  font-size: 20px;
}
.reauth-accounts .account-name {
  text-transform: uppercase;
}
.reauth-accounts .reauth-action {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}
.reauth-accounts .reauth-list {
  list-style: none;
  padding: 0 0 0 1rem;
}
.reauth-accounts .reauth-list li:not(:last-child) {
  border-bottom: 4px solid #f0f3f9;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}
.reauth-accounts img {
  margin-right: 1rem;
}
.reauth-accounts .added-by {
  font-style: italic;
}