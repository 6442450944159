@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-message-attachments {
  font-size: 1rem;

  .message-box-gallery-item-badge {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    right: -0.9em;
    margin: auto;
    height: 2em;
    width: 2em;
    background: #fff;
    box-shadow: 0 0.5em 1.5em rgba(116, 118, 132, 0.25);
    border-radius: 50%;

    p {
      margin: 0;
      font-size: 1em;
      color: $primary-active;
      font-weight: 900;
      text-rendering: geometricPrecision;
      font-style: italic;
    }
  }

  .message-box-gallery-item-multiple {
    position: relative;
    margin: 0 1.3em 0 0.3em;
  }

  .message-box-gallery-item-image-foreground {
    top: -0.3em;
    left: -0.3em;
  }

  .message-box-gallery-item-image-forefront {
    margin-top: 0.9em;
    margin-bottom: -0.3em;
    margin-left: -86px;
  }

  .message-box-gallery-item-image-tint {
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    background-color: rgba(53, 53, 63, 0.2);
    pointer-events: none;
  }
}
