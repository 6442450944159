@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../styles/variables';
@import '~/styles/colors';

ssi-report-filter-bar {
  font-size: 1rem;

  .report-filter-bar {
    // margin: 0 0 1em;
  }

  .report-filter-bar-container {
    display: flex;
    height: 100%;
    // max-width: 144em;
    padding: 0 2em;
    margin: 0 auto;
  }

  .report-filter-bar-row {
    position: relative;
    display: flex;
    width: 100%;
    padding: 0.7em;
    background-color: #dbe2f7;
    border-radius: 1em;

    &.report-filter-bar-row-expanded {
      box-shadow: 0 0.5em 1em rgba(131, 142, 171, 0.2);
    }
  }

  .report-filter-items {
    flex: 1 1 auto;
    display: block;
    padding: 0;
    margin: 0 0 -0.7em;
    height: 4.2em;
    line-height: 0;
    overflow: hidden;

    &.report-filter-items-expanded {
      height: auto;
      min-height: 8.6em;
    }
  }

  .report-filter-button {
    display: inline-flex;
    align-items: center;
    background-color: #fff;
    border-radius: 1em;
    height: 3.6em;
    padding: 0 1em;
    border: 1px solid #5c41ff;
    white-space: nowrap;
    line-height: 0;
    cursor: pointer;

    .report-filter-item-action {
      display: inline-block;
      font-size: 0.8em;
      padding: 0.4rem;
      color: #5c41ff;
      line-height: 0;
      cursor: pointer;

      &:hover,
      &:focus {
        color: $primary-active;
      }

      &:first-child {
        margin: 0 0.4rem 0 0;
      }
    }
  }

  .report-filter-item {
    margin: 0 0.7em 0.7em 0;
    vertical-align: bottom;

    &.report-filter-item-stream {
      background-color: #d0eeed;
      border-color: #12aca4;

      .report-filter-item-action {
        color: #12aca4;

        &:hover,
        &:focus {
          color: $primary-active;
        }
      }

      .ssi-orlo-insights {
        display: inline-block;
        font-size: 1.5em;
        color: #12aca4;
        margin: 0.2rem 1rem 0 0;
        line-height: 0;
      }
    }

    &.report-filter-item-disabled {
      border-color: #c9d3f1;
      background-color: #c9d3f1;
    }

    &.report-filter-item-warning {
      border-color: #f88c68;
      background-color: #fff1ed;
    }
  }

  .report-filter-item-label {
    font-size: 1.2em;
    display: inline-block;
    margin: 0 0.8rem 0 0;
    vertical-align: center;
    color: $lmode-title-text;
    font-weight: 900;
    text-rendering: geometricPrecision;
    line-height: 0;
  }

  .report-filter-actions {
    display: flex;
    flex: 0 0 auto;
  }

  .report-filter-action {
    border-color: #f88c68;
    background-color: #fff1ed;
    margin: 0 0 0 0.7em;

    .report-filter-item-action {
      color: #f88c68;
    }
  }

  .report-filter-action-text {
    display: inline-block;
    margin: 0 0.4rem 0 0;
    color: #43537f;
    font-size: 1.4em;
    font-weight: 900;
    text-rendering: geometricPrecision;
  }

  .report-filter-bar-toggle {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0.7em;
    right: 0.7em;
    color: #43537f;
    height: 3.6em;
    width: 3.6em;
    cursor: pointer;

    i {
      font-size: 1.2em;
      transform: rotate(180deg);
    }

    &:hover,
    &:focus {
      color: $primary-active;
    }
  }
}
