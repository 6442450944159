ssi-medal-score .medal-score {
  position: relative;
  height: 46px;
  width: 46px;
  border-radius: 5px;
  background-color: #f4f4fa;
}
ssi-medal-score .medal-score span {
  position: absolute;
  top: 25%;
  left: 40%;
  color: #101525;
  font-size: 14px;
  font-weight: 900;
  line-height: 17px;
}
ssi-medal-score .medal-score i {
  position: absolute;
  top: 25%;
  left: 15%;
  font-size: 32px;
}
ssi-medal-score .medal-score.big {
  background-color: transparent;
}
ssi-medal-score .medal-score.big span {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fbf3e2;
  border-radius: 50%;
  height: 46px;
  width: 46px;
  top: 10px;
  left: 9px;
}
ssi-medal-score .medal-score.big i {
  top: 30px;
  font-size: 50px;
}