ssi-note .note-container p {
  color: #101525;
  font-size: 14px;
  font-weight: 700;
}
ssi-note .note-container .note-meta-and-actions span {
  color: #43537f;
  font-size: 12px;
  font-weight: 900;
  margin-right: 5px;
}
ssi-note .note-container .note-meta-and-actions span.delete-icon-container {
  padding: 2px;
  background-color: #fff1ed;
  border-radius: 50%;
  margin-right: 10px;
}
ssi-note .note-container .note-meta-and-actions span.delete-icon-container:hover {
  cursor: pointer;
}
ssi-note .note-container .note-meta-and-actions span.delete-icon-container i {
  font-size: 10px;
  font-weight: 900;
  color: #e34916;
}