ssi-quote-retweet-modal {
  display: block;
  width: 550px;
  max-width: 100%;
}
ssi-quote-retweet-modal .quote-retweet {
  position: relative;
}
ssi-quote-retweet-modal .header {
  width: 100%;
  padding: 1.4em 4em;
  position: relative;
  color: white;
  border-bottom: 2px solid #444654;
}
ssi-quote-retweet-modal .header h2 {
  font-size: 1.4em;
  font-weight: 700;
  margin: 0;
}
ssi-quote-retweet-modal .header i {
  position: absolute;
  font-size: 2em;
  left: 24px;
  top: 22px;
  padding-right: 1em;
}
ssi-quote-retweet-modal .exit {
  position: absolute;
  right: 16px;
  top: 16px;
  background-color: #444654;
  border-radius: 100%;
  z-index: 999;
  cursor: pointer;
}
ssi-quote-retweet-modal .exit i {
  display: inline-block;
  padding: 10px;
  color: #b4bbce;
}
ssi-quote-retweet-modal .content {
  padding: 1em;
  border-bottom: 2px solid #444654;
}
ssi-quote-retweet-modal .content .orlo-button {
  padding: 0;
  color: #14bae3;
  font-size: 0.75em;
  line-height: 2.1em;
}
ssi-quote-retweet-modal .content .orlo-button i {
  color: #14bae3;
  margin-top: -10px;
  font-size: 0.8em;
  top: 0px;
}
ssi-quote-retweet-modal .content a.text {
  margin-left: 1em;
}
ssi-quote-retweet-modal .content .character-count {
  margin-right: 1em;
  font-weight: 700;
  font-size: 1em;
}
ssi-quote-retweet-modal .content .ssi-emoji {
  margin-right: 1em;
  color: white;
  font-size: 1.3em;
}
ssi-quote-retweet-modal .content .form-control {
  font-size: 1em;
}
ssi-quote-retweet-modal .footer {
  padding: 1em;
}
ssi-quote-retweet-modal .footer .text {
  font-size: 0.75em;
  padding-right: 0;
  letter-spacing: 1px;
  margin-top: 0.2em;
}
ssi-quote-retweet-modal .scheduler {
  position: relative;
}
ssi-quote-retweet-modal .scheduler-empty:after {
  content: "Schedule repost";
  color: white;
  font-size: 0.87em;
  font-weight: 700;
  position: absolute;
  top: 12px;
  left: 32px;
  pointer-events: none;
}
ssi-quote-retweet-modal .scheduler i {
  position: absolute;
  color: #b4bbce;
  top: 15px;
  font-size: 0.75em;
  left: 10px;
}
ssi-quote-retweet-modal .schedule-button {
  width: 180px;
  margin: 0.6em 0;
  display: inline-block;
  border-radius: 25px !important;
  background-color: #444654 !important;
  border: none !important;
  color: white !important;
  padding: 0.4em 1.2em 0.4em 2.6em;
  height: initial !important;
  font-size: 0.87em;
}
ssi-quote-retweet-modal .schedule-button::placeholder {
  color: transparent !important;
}
ssi-quote-retweet-modal .cancel-schedule {
  position: absolute;
  background: none;
  color: #fff;
  appearance: none;
  box-shadow: none;
  border: none;
  transform: translateX(-250%);
}
ssi-quote-retweet-modal .form-control {
  background-color: transparent;
  font-weight: 700;
  color: white;
}
ssi-quote-retweet-modal .form-control::placeholder {
  color: #838eab;
}
ssi-quote-retweet-modal textarea.form-control.form-control-minimal {
  resize: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 0;
}
ssi-quote-retweet-modal .quote-retweet-modal-form-control-footer {
  text-align: right;
}