ssi-create-edit-competitor .content-wrapper {
  padding: 60px 0 0;
  max-width: 1294px !important;
}
ssi-create-edit-competitor .content-wrapper .form-group {
  padding: 0;
}
ssi-create-edit-competitor .heading-container {
  position: fixed;
  top: 68px;
  width: 100%;
  height: 94px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f4f4fa;
  padding: 24px 45px;
  box-shadow: 0 5px 10px rgba(131, 142, 171, 0.25);
  z-index: 1;
}
ssi-create-edit-competitor .heading-container orlo-button {
  margin: 0 0 0 20px;
  height: 46px;
}
ssi-create-edit-competitor .heading-container orlo-button .ssi-line-arrow-small-down {
  transform: rotate(90deg);
  margin-right: 5px;
}
ssi-create-edit-competitor .heading-container orlo-button button {
  height: inherit;
}
ssi-create-edit-competitor .heading-container h1 {
  line-height: 1;
  margin: 10px 0;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-create-edit-competitor orlo-text-input label {
  margin: 8px 0;
  padding: 0;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #43537f;
  font-size: 10px;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-create-edit-competitor orlo-text-input input {
  height: 46px;
  color: #101525 !important;
  background-color: #fff !important;
  font-size: 14px !important;
  text-rendering: geometricPrecision;
  color: #838eab;
}
ssi-create-edit-competitor orlo-text-input input:not(:focus) {
  border-color: #fff !important;
}
ssi-create-edit-competitor orlo-text-input input.invalid {
  border-color: #f88c68 !important;
}
ssi-create-edit-competitor orlo-text-input input::placeholder {
  color: #838eab;
  font-weight: 700 !important;
}
ssi-create-edit-competitor orlo-text-input input:focus {
  font-weight: 600 !important;
}
ssi-create-edit-competitor orlo-text-input span {
  text-align: left !important;
}
ssi-create-edit-competitor .content-form {
  position: relative;
  padding: 60px 5% 90px;
  margin: 94px 15px 0;
  background-color: #f4f4fa;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
ssi-create-edit-competitor .content-form .delete {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 15px;
  color: #43537f;
  background-color: white;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1.2em;
  line-height: 1;
}
ssi-create-edit-competitor .input-container {
  position: relative;
}
ssi-create-edit-competitor .input-container:first-child {
  margin-right: 30px !important;
  flex: 1 1 40% !important;
  max-width: 400px;
}
ssi-create-edit-competitor .input-container:last-child {
  flex: 1 1 50% !important;
}
ssi-create-edit-competitor .input-container label {
  margin: 8px 0;
}
ssi-create-edit-competitor .input-counter {
  position: absolute;
  bottom: 0;
  right: 8px;
  color: #43537f;
  font-size: 10px;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-create-edit-competitor .input-counter.error {
  color: #f88c68;
}
ssi-create-edit-competitor .box-list .box {
  width: auto;
}
ssi-create-edit-competitor .box-list .box .channel {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
ssi-create-edit-competitor .box-list .box .name-details {
  color: #14bae3;
  width: 100%;
  margin: 0 2em;
  min-width: 120px;
}
ssi-create-edit-competitor .box-list .box .name-details label {
  text-align: center;
  max-width: 150px;
  margin: 0;
  font-weight: 900;
}
ssi-create-edit-competitor .box-list .box .name-details.selected {
  margin: 0;
}
ssi-create-edit-competitor .box-list .box .box-control {
  background-color: #14bae3;
}
ssi-create-edit-competitor .box-list .box .box-control i {
  color: white;
}
ssi-create-edit-competitor .box-list .box .box-control:hover {
  background-color: #14bae3;
}
ssi-create-edit-competitor .box-list .box.selected label {
  text-align: left;
}
ssi-create-edit-competitor .box-color {
  margin-left: 3em;
  padding: 15px 18px;
}
ssi-create-edit-competitor .search-box {
  border: 2px solid #14bae3;
  border-radius: 50px;
  width: 500px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
ssi-create-edit-competitor .search-box .search-handle {
  border: none;
  background-color: white;
  margin: 0;
  padding: 17px 2em;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-weight: 500;
}
ssi-create-edit-competitor .search-box .search-button {
  padding: 19px 2em;
  background-color: #14bae3;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
ssi-create-edit-competitor .search-box ssi-spinner {
  padding: 30px 10px 0 0;
}
ssi-create-edit-competitor .search-box .accounts-dropdown {
  width: 400px;
  position: absolute;
  top: 80px;
  background-color: white;
  border-radius: 25px;
  padding: 2em 2em 0 1em;
  box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.35);
  z-index: 99;
}
ssi-create-edit-competitor .search-box .accounts-dropdown .accounts-list {
  height: 300px;
  padding: 2em 2em;
  overflow: hidden;
  overflow-y: scroll;
}
ssi-create-edit-competitor .search-box .accounts-dropdown .accounts-list::-webkit-scrollbar {
  appearance: none;
  width: 7px;
}
ssi-create-edit-competitor .search-box .accounts-dropdown .accounts-list::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
ssi-create-edit-competitor .search-box .accounts-dropdown .accounts-list hr {
  border-top: 2px solid #f0f3f9;
}
ssi-create-edit-competitor .search-box .accounts-dropdown .accounts-list .media {
  font-size: 0.9em;
}
ssi-create-edit-competitor .search-box .accounts-dropdown .accounts-list .profile {
  width: 22px;
}
ssi-create-edit-competitor .search-box .accounts-dropdown .accounts-list .profile-info {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 240px;
}
ssi-create-edit-competitor .search-box .accounts-dropdown .accounts-list .text-muted {
  color: #43537f;
}
ssi-create-edit-competitor .search-box .accounts-dropdown .accounts-list .plus {
  height: 32px;
  padding: 0px 10px;
}
ssi-create-edit-competitor .search-box .accounts-dropdown .accounts-list .plus i {
  font-size: 0.8em;
  font-weight: 900;
}