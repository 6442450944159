ssi-stream-builder {
  font-size: 1rem;
}
ssi-stream-builder .stream-builder-container {
  padding: 4em;
  margin: 2em;
  background-color: white;
  border-radius: 15px;
  min-height: calc(100vh - (68px + 2em * 2));
}
ssi-stream-builder i.left {
  transform: rotate(-90deg);
  margin-right: 0.5em;
}
ssi-stream-builder i.right {
  transform: rotate(90deg);
  margin-left: 0.5em;
}
ssi-stream-builder .stream-builder-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2em;
  color: #838eab;
}
ssi-stream-builder .stream-builder-nav > * {
  flex: 1 0 0;
}
ssi-stream-builder .stream-builder-nav .stream-builder-nav-action,
ssi-stream-builder .stream-builder-nav a {
  color: #43537f;
  font-size: 1.2em;
  font-weight: 900;
}
ssi-stream-builder .stream-builder-nav .stream-builder-nav-action:hover,
ssi-stream-builder .stream-builder-nav a:hover {
  text-decoration: none;
  color: #0d827c;
}
ssi-stream-builder .stream-builder-nav .stream-builder-nav-action {
  margin: 0 0 0 2rem;
  cursor: pointer;
}
ssi-stream-builder .stream-builder-nav .stream-builder-nav-action span {
  font-size: 14px;
}
ssi-stream-builder .stream-builder-nav .stream-builder-nav-action i {
  font-size: 12px;
  vertical-align: text-top;
  margin: 0 7px 0 0;
}
ssi-stream-builder .stream-builder-nav .nav-list {
  display: flex;
  width: 465px;
  margin: 0 20px;
  flex: 0 0 465px;
}
ssi-stream-builder .stream-builder-nav .nav-list > * {
  flex: 1 0 auto;
}
ssi-stream-builder .stream-builder-nav .nav-list i {
  font-size: 0.9em;
  font-weight: 700;
  margin-left: 0.8em;
}
ssi-stream-builder .stream-builder-nav .nav-list h3 {
  font-size: 14px;
  font-weight: 900;
}
ssi-stream-builder .stream-builder-nav .line {
  width: 88px;
  height: 2px;
  margin: 1em;
  background-color: #b4bbce;
}
ssi-stream-builder .stream-builder-nav .line.active:not(.completed), ssi-stream-builder .stream-builder-nav .line.final {
  position: relative;
  background: linear-gradient(87.08deg, #12aca4 0%, #b4bbce 100%);
}
ssi-stream-builder .stream-builder-nav .line.active:not(.completed)::before, ssi-stream-builder .stream-builder-nav .line.final::before {
  content: "";
  display: block;
  position: absolute;
  top: -2px;
  right: 85px;
  background: #12aca4;
  border-radius: 50%;
  height: 6px;
  width: 6px;
}
ssi-stream-builder .stream-builder-nav .line.final::before {
  right: 0;
}
ssi-stream-builder .stream-builder-nav .line.completed {
  background: #12aca4;
  margin-left: 0.5em;
}
ssi-stream-builder .stream-builder-nav .active {
  color: #0d827c;
  border-color: #0d827c;
}
ssi-stream-builder .stream-builder-nav .next,
ssi-stream-builder .stream-builder-nav .back {
  white-space: nowrap;
}
ssi-stream-builder .stream-builder-nav .next a,
ssi-stream-builder .stream-builder-nav .back a {
  font-size: 14px;
}
ssi-stream-builder .stream-builder-nav .next.disabled orlo-button button,
ssi-stream-builder .stream-builder-nav .back.disabled orlo-button button {
  opacity: 0.6;
}
ssi-stream-builder .stream-builder-nav .next.disabled a,
ssi-stream-builder .stream-builder-nav .back.disabled a {
  cursor: not-allowed;
  opacity: 0.6;
}
ssi-stream-builder .stream-builder-nav .next.disabled a:hover, ssi-stream-builder .stream-builder-nav .next.disabled a:focus,
ssi-stream-builder .stream-builder-nav .back.disabled a:hover,
ssi-stream-builder .stream-builder-nav .back.disabled a:focus {
  color: #43537f;
}
ssi-stream-builder .stream-builder-nav .next {
  text-align: right;
}
ssi-stream-builder .stream-builder-nav button.next {
  background-color: transparent;
  border-width: 0;
}
ssi-stream-builder .stream-builder-nav orlo-button button {
  margin-top: -9px;
}