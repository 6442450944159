ssi-live-chat-modal {
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-shrink: 0;
  height: 100vh;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: -68px;
  width: 100vw;
  z-index: 99999999;
}
ssi-live-chat-modal * {
  display: flex;
}
ssi-live-chat-modal > * {
  flex-shrink: 0;
}
ssi-live-chat-modal .modal-container {
  align-items: center;
  background-color: #424348;
  border-radius: 25px;
  color: #fff;
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: space-between;
  left: 30vw;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 4vh 4vw;
  position: fixed;
  top: 30vh;
  width: 40vw;
}
ssi-live-chat-modal .modal-container .modal-close-icon {
  float: right;
  margin-right: 20px;
  margin-top: 15px;
  position: relative;
  z-index: 1;
}
ssi-live-chat-modal .modal-container .modal-body {
  flex-flow: column nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  font-weight: 600;
  padding: 10px 10px;
  text-align: center;
}
ssi-live-chat-modal .modal-container .modal-footer {
  border-top: none;
  flex-flow: row nowrap;
  flex-shrink: 0;
  justify-content: space-evenly;
  margin: 0;
  width: 100%;
}
ssi-live-chat-modal .modal-container .modal-footer > li {
  flex-shrink: 0;
  width: 50%;
}
ssi-live-chat-modal .modal-container .modal-footer > li:nth-of-type(1) {
  margin-right: 10px;
}
ssi-live-chat-modal .modal-container .modal-footer > li:nth-child(2) {
  margin-left: 10px;
}
ssi-live-chat-modal .modal-container .modal-footer > li button {
  align-items: center;
  border: none;
  border-radius: 20px;
  display: flex;
  font-weight: 600;
  justify-content: center;
  outline: none;
  padding: 10px 20px;
  width: 100%;
}
ssi-live-chat-modal .modal-container .modal-footer > li button:focus, ssi-live-chat-modal .modal-container .modal-footer > li button:hover {
  color: #fff;
}
ssi-live-chat-modal .modal-container .modal-footer > li button.btn-secondary {
  background-color: #53575d;
}
ssi-live-chat-modal .modal-container .modal-footer > li button.btn-primary {
  background-color: #57bdb7;
}
ssi-live-chat-modal .modal-container .modal-footer > li button .modal-button-icon {
  font-size: 1.4em;
}
ssi-live-chat-modal .modal-container .modal-footer > li button .modal-button-icon + .modal-button-text {
  margin-left: 5px;
}
ssi-live-chat-modal .modal-container .modal-footer > li button .modal-button-text {
  display: inline;
  font-size: 1.2em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (max-width: 767px) {
  ssi-live-chat-modal .modal-container .modal-footer > li {
    width: 100%;
    margin: 0 !important;
  }
}
@media (max-width: 767px) {
  ssi-live-chat-modal .modal-container .modal-footer {
    flex-wrap: wrap;
  }
}
ssi-live-chat-modal .modal-container .modal-header {
  align-items: center;
  border-bottom: none;
  flex-flow: column nowrap;
  flex-shrink: 0;
  font-weight: 400;
}
ssi-live-chat-modal .modal-container .modal-header-icon {
  font-size: 3.2em;
}
ssi-live-chat-modal .modal-container .modal-header-text {
  font-size: 1.6em;
  text-align: center;
}
@media (max-width: 767px) {
  ssi-live-chat-modal .modal-container {
    left: 50%;
    transform: translate(-50%, 0);
    width: 80vw;
  }
}