ssi-rounded-box-check {
  max-width: fit-content;
}
ssi-rounded-box-check .rounded-box-check-container {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 900;
  padding: 12px;
  background-color: #f4f4fa;
  color: #6a759a;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  max-width: fit-content;
  white-space: nowrap;
  border: 1px solid transparent;
}
ssi-rounded-box-check .rounded-box-check-container i {
  font-size: 12px;
}
ssi-rounded-box-check .rounded-box-check-container.bordered-initial {
  border-color: #c3c9d8;
}
ssi-rounded-box-check .rounded-box-check-container.selected {
  color: #43537f;
  border: 1px solid #14bae3;
  background-color: #e4faff;
}
ssi-rounded-box-check .rounded-box-check-container.selected i {
  color: #14bae3;
}
ssi-rounded-box-check i {
  margin-right: 10px;
}