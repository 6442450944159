@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-theme-detectors {
  background-color: #f4f4fa;

  .theme-detectors-container {
    padding: 60px 20px;
    max-width: 1400px;
    margin: auto;

    .section-container {
      padding: 30px 30px 60px;
      background-color: #fff;
      border-radius: 10px;
    }

    .section-delimiter {
      width: 100%;
      height: 2px;
      background-color: #fff;
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
}
