@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../../angularjs/common/sass/mixins';
@import '../../../../../../../angularjs/common/sass/variables';

.manage-ifttt-applet-streams {
  //   .manage-ifttt-applet-stage-body {
  //     text-align: left;
  //   }

  //   .scroll-vertical {
  //     max-height: 200px;
  //   }

  ssi-list-select2 {
    // .filter-container {
    //   padding-left: 32px;
    // }

    .toggle-all-container {
      padding-right: 28px !important;
    }

    ul {
      height: 200px;
      overflow: auto;
    }
  }
}
