@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';
@import '../../../../angularjs/common/sass/variables';

ssi-conversation-hold {
  .conversation-hold-container {
    display: inline-block;

    .hold-btn {
      white-space: nowrap;

      i {
        font-weight: 400;
      }
    }

    i {
      &.hold-icon-standalone {
        font-weight: 400;
      }
    }
  }
}
