@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../../../angularjs/common/sass/mixins';
@import '../../../../../../../../angularjs/common/sass/variables';
@import '~/styles/colors';

ssi-textarea-caret-tracker {
  $bar-icon-dimensions: 25px;
  $bar-bg-color-primary: #cedae1;
  $bar-bg-color-secondary: #fff;

  .bar-container {
    position: absolute;
    width: 100%;
    margin-top: 2px;
    right: 0;
    z-index: 3;
  }

  .striped-bar {
    background: repeating-linear-gradient(
      to right,
      $bar-bg-color-primary,
      $bar-bg-color-primary 10px,
      $bar-bg-color-secondary 10px,
      $bar-bg-color-secondary 35px
    );
    height: 2px;
    margin-top: -2px;
  }

  .bar-icon {
    @include align-vertical();
    right: calc(-1 * ($bar-icon-dimensions / 2));
    top: calc(-1 * ($bar-icon-dimensions / 2));
    position: absolute;
    background-color: $bar-bg-color-primary;
    width: $bar-icon-dimensions;
    height: $bar-icon-dimensions;
    text-align: center;
    border-radius: 50%;
    color: $bar-bg-color-secondary;
  }

  .bar-icon.active,
  .bar-icon:hover {
    background-color: $primary-active;
  }
}
