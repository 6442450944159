@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/variables';
@import '~/styles/colors';

ssi-insights-not-purchased {
  font-size: 1rem;

  .insights-np {
    background-color: #f4f4fa;
    height: calc(100vh - #{$navbar-height});
  }

  .insights-np-header {
    display: none;
    align-items: center;
    height: 9.4em;
    padding: 0 4em;
    margin: 0 auto;
    background-color: #fff;
    border-bottom: 0.1em solid #c3c9d8;

    @media (min-width: 768px) {
      display: flex;
    }

    h1 {
      color: #101525;
      font-size: 1.4em;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin: 0 2em 0 0;
      font-weight: 900;
      text-rendering: geometricPrecision;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .insights-np-body {
    padding: 1em;

    @media (min-width: 768px) {
      padding: 4.2em;
    }
  }

  .insights-np-body-grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -9px;
  }

  .insights-np-card {
    flex: 1 1 auto;
    overflow: hidden;
    border-radius: 1em;
    background-color: #fff;
    border: 1px solid #c3c9d8;
    padding: 3em;
    margin: 0 9px 10px;

    @media (min-width: 768px) {
      max-width: calc(50% - 18px);
      margin: 0 9px 18px;
    }

    h2 {
      color: #101525;
      font-weight: 900;
      text-rendering: geometricPrecision;
      font-size: 1.8em;
      line-height: 30px;
      margin: 0 0 16px;
    }

    p {
      color: #101525;
      font-size: 1.4em;
      line-height: 20px;
      font-weight: 700;
      text-rendering: geometricPrecision;
    }

    a {
      display: inline-flex;
      border-width: 0;
      align-items: center;
      justify-content: space-between;
      background-color: #0d827c;
      color: #fff;
      height: 4.6em;
      width: 14.8em;
      padding: 0 1.4em;
      border-radius: 1em;
      outline-width: 0;

      i {
        margin: 0 8px 0 0;
        font-size: 1.4em;
      }

      span {
        font-size: 1.4em;
        font-weight: 900;
        white-space: nowrap;
        text-rendering: geometricPrecision;
      }
    }

    .insights-np-left {
      flex: 1 1 0;

      @media (min-width: 482px) {
        max-width: 80%;
      }
    }

    .insights-np-right {
      margin: auto;

      @media (min-width: 768px) {
        margin: 0 0 0 auto;
      }
    }

    img {
      display: block;
      flex: 1 0 auto;
      margin: 30px -30px -30px;
      max-width: 364px;
      width: calc(100% + 60px);

      @media (min-width: 482px) {
        display: none;
      }

      @media (min-width: 768px) {
        width: 100%;
        display: block;
        margin: -30px -30px -30px 30px;
      }
    }

    &-main {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      border-color: #4eaaa4;
      margin: 0 0 10px;
      max-width: none;

      h2 {
        font-size: 2.4em;
      }

      p {
        margin: 0 0 22px;
      }

      @media (min-width: 768px) {
        margin: 0 0 18px;
        flex-wrap: nowrap;
      }
    }
  }

  .insights-np-card-icon {
    display: block;
    color: #0d827c;
    font-size: 2em;
    margin: 0 0 20px;
  }
}
