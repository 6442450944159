@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../angularjs/common/sass/variables';
@import '../../../../../angularjs/common/sass/mixins';
@import '~/styles/colors';

ssi-dashboard ssi-edit {
  font-size: 1rem;

  $appHeaderHeight: 68px;
  $actionBarHeight: 40px;

  .dashboard-edit-container {
    width: 100%;
    position: relative;
    // background-color: #f8f9fd;
    background-color: $lgrey-background;
    min-height: calc(100vh - #{$navbar-height});
    overflow-x: hidden;

    &.has-widgets {
      .nav-bar {
        height: 60px;
        width: 100%;
        border-bottom: 1px solid black;
        display: flex;
        align-items: center;
        padding: 10px;
      }

      .dashboard-content {
        .actions-bar {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 70px;
          padding: 20px 20px 10px 20px;

          .left {
            display: flex;
            align-items: center;
          }

          .right {
            display: flex;
            align-items: center;

            .save-btn,
            .save-as-template-btn {
              margin-left: 10px;
            }
          }
        }
      }
    }

    .dashboard-templates-menu {
      .templates-button {
        min-width: 300px;
        display: flex;
        justify-content: flex-start;
        .templates-select-arrow-icon {
          margin-left: auto;
          margin-right: 0;
          color: $primary-active;
          font-weight: 900;
        }
      }

      .menu-body {
        .templates-list-item {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .delete-icon {
            color: #f88c68;
          }
        }
      }
    }
  }

  .confirm-action-tooltip-window {
    background-color: transparent;
  }
}
