ssi-description-step .page-description {
  flex: 1 1 60%;
  display: flex;
  background-color: #f8f9fd;
  border-radius: 12px;
}
ssi-description-step .page-description .text {
  padding: 40px 20px 40px 50px;
}
ssi-description-step .page-description h2 {
  font-weight: 900;
  font-size: 20px;
  color: #43537f;
}
ssi-description-step .page-description p {
  font-weight: bold;
  color: #43537f;
  line-height: 20px;
}
ssi-description-step .page-description img {
  width: auto;
  height: 144px;
  margin-right: 40px;
  align-self: flex-end;
}
ssi-description-step .stream-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
}
ssi-description-step .input-container {
  position: relative;
  margin-left: 10px;
}
ssi-description-step .input-container input {
  color: #101525;
}
ssi-description-step .input-container input::placeholder {
  font-style: normal;
  font-size: 14px;
}
ssi-description-step .input-container label {
  text-transform: uppercase;
  color: #43537f;
  font-weight: 900;
  margin: 10px 0 10px 30px;
  letter-spacing: 1px;
}
ssi-description-step .input-container .input-counter {
  position: absolute;
  top: 90px;
  right: 8px;
  color: #838eab;
  font-size: 10px;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-description-step .input-container .input-counter.has-value {
  color: #14bae3;
}
ssi-description-step .input-container .input-counter.error {
  color: #f88c68;
}
ssi-description-step .input-container:first-child {
  margin-left: 0;
  width: calc(35% - 20px);
}
ssi-description-step .input-container:last-child {
  width: calc(65% - 20px);
}
@media (max-width: 767px) {
  ssi-description-step .description-step .input-container {
    width: 100%;
    margin: 0;
  }
}