ssi-marketing-index {
  display: block;
  background-color: #f6f8f9;
  margin-left: -15px;
  margin-right: -15px;
}
ssi-marketing-index .stat-number {
  display: block;
  font-size: 2.2em;
}
ssi-marketing-index .campaigns-list {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 885px) {
  ssi-marketing-index .campaigns-list {
    padding: 80px 120px;
  }
}
ssi-marketing-index .campaign {
  position: relative;
  flex-basis: calc(33% - (5px * 2));
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 25px 0;
  margin: 5px;
  text-align: center;
}
ssi-marketing-index .campaign-title {
  color: #a6b7c0;
  font-weight: normal;
  text-align: center;
  display: block;
  position: relative;
  z-index: 99;
}
ssi-marketing-index .campaign-icon {
  border: solid 3px #f3f6f7;
  color: #a6b7c0;
  font-size: 22px;
  border-radius: 50%;
  padding: 5px;
  display: inline-block;
  text-align: center;
  width: 50px;
  height: 50px;
  background-color: white;
}
ssi-marketing-index .campaign-icon.settings-trigger {
  cursor: pointer;
}
ssi-marketing-index .campaign-icon.campaign-icon-overlap-right {
  position: relative;
  right: -6px;
}
ssi-marketing-index .campaign-icon.campaign-icon-overlap-left {
  position: relative;
  left: -6px;
}
ssi-marketing-index .campaign-icon.active {
  background-color: #14bae3;
  color: #fff;
}
ssi-marketing-index .campaign-message {
  padding: 100px 0 400px;
  font-size: 22px;
  text-align: center;
}
ssi-marketing-index .campaign-stat {
  background-color: #f6f8f9;
  padding: 5px;
  color: #a6b7c0;
  font-weight: normal;
  margin: 0 3px;
  font-size: 13px;
  border-radius: 10px;
}
ssi-marketing-index .campaigns-completed-filter {
  text-align: center;
  margin: -12px;
}
ssi-marketing-index .campaigns-completed-filter .campaigns-select-wrapper {
  display: inline-block;
  padding: 6px 16px;
  background-color: #43ade8;
  border-radius: 20px;
}
ssi-marketing-index .campaigns-completed-filter .campaigns-select-wrapper select {
  border: none;
  background-color: #43ade8;
  color: white;
}

.reset-list-style {
  padding-left: 0;
  margin-bottom: 0;
}
.reset-list-style li {
  display: block;
}

.campaigns-stats {
  display: flex;
  width: 100%;
  flex: 1 1 auto;
}
@media (max-width: 885px) {
  .campaigns-stats {
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.campaign-stats-row {
  display: flex;
  flex: 1 1 auto;
  margin: 0 -15px;
}
@media (max-width: 885px) {
  .campaign-stats-row {
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.validate-posts-box-select-mode:hover {
  cursor: pointer;
}

.validation-filters-aside {
  display: inline-block;
  flex: 1 1 33%;
  width: 33%;
  background-color: #3a3f49;
  text-align: center;
  padding: 0 20px 40px 20px;
}
@media (max-width: 885px) {
  .validation-filters-aside {
    width: 100vw;
  }
}

.campain-stats-main {
  display: inline-block;
  flex: 1 1 66%;
  width: 66%;
  background-color: #f6f8f9;
  padding: 20px;
}
@media (max-width: 885px) {
  .campain-stats-main {
    width: 100vw;
  }
}

.no-validation-posts {
  color: #a6b7c0;
  font-weight: 500;
  max-width: 240px;
  margin: 15% auto;
  text-align: center;
}

.no-validation-posts-image {
  font-size: 90px;
  margin-bottom: 10px;
}

.no-validation-posts-title {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 600;
}

.no-validation-posts-meta {
  font-size: 14px;
}

.campaigns-selection {
  background-color: #494f5b;
  padding: 42px 35px;
  margin: 0 -20px 60px;
  color: #6f7983;
}
.campaigns-selection p {
  font-weight: 600;
  margin: 0;
}

.campaigns-selection-actions-wrap {
  display: flex;
  align-items: center;
}

.campaigns-info {
  flex: 0 1 auto;
  text-align: left;
}
.campaigns-info .campaigns-selection-action-refresh,
.campaigns-info p {
  display: inline-block;
  font-size: 14px;
}
.campaigns-info .campaigns-selection-action-refresh {
  background-color: #464d56;
  border-radius: 50%;
  height: 38px;
  width: 38px;
  line-height: 34px;
  text-align: center;
  border: 2px solid #454852;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 0 10px 0 0;
  color: #27bbe1;
  font-size: 18px;
}

.campaigns-selection-actions {
  text-align: right;
  margin: 0;
  padding: 0;
  flex: 1 1 auto;
  font-weight: 600;
}
.campaigns-selection-actions li {
  display: inline-block;
  white-space: nowrap;
}
.campaigns-selection-actions li p {
  display: inline-block;
}
.campaigns-selection-actions li:after {
  content: "|";
  display: inline-block;
  margin: 6px;
}
.campaigns-selection-actions li:last-child:after {
  content: normal;
  margin: 0;
}

.campaigns-selection-action {
  cursor: pointer;
}
.campaigns-selection-action:hover, .campaigns-selection-action:focus {
  color: #27bbe1;
}

.campaigns-bulk {
  max-width: 270px;
  margin: 0 auto;
  padding: 35px 0 40px;
  color: #67717a;
  font-weight: 600;
}
.campaigns-bulk .btn {
  width: 130px;
}

.campaigns-bulk-icon {
  font-size: 46px;
  margin-bottom: 25px;
}

.campaigns-bulk-title {
  color: #a6b7bf;
  margin-bottom: 25px;
}

.campaigns-bulk-meta {
  margin-bottom: 30px;
}