@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../../angularjs/common/sass/variables';
@import '../../styles/variables';
@import '~/styles/colors';

ssi-widget-chart-line {
  font-size: 1rem;

  .insights-widget {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .insights-chart-line {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .insights-chart-line-wrapper {
    width: calc(100% - 6em);
    height: 89%;
  }

  .no-results {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 30px;

    i {
      font-size: 40px;
      color: $dmode-body-text;
      margin-bottom: 20px;
    }
    h4 {
      color: $lmode-body-text;
      font-weight: 900;
      font-size: 20px;
    }
    p {
      color: $lmode-body-text;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
    }
  }
}
