ssi-ktd-grid-item {
  display: none;
  position: absolute;
  z-index: 1;
  overflow: hidden;
}
ssi-ktd-grid-item div.grid-item-resize-icon-container {
  visibility: hidden;
  position: absolute;
  cursor: se-resize;
  bottom: 9px;
  right: 10px;
  color: #14bae3;
}
ssi-ktd-grid-item div.grid-item-resize-icon-container .grid-item-resize-icon {
  color: #14bae3;
  font-size: 14px;
}
ssi-ktd-grid-item:hover .grid-item-resize-icon-container {
  visibility: visible;
}