@import '~/styles/colors';

.btn-style-1 {
  font-size: 12px;
  font-weight: 900;
  border-radius: 20px;
  padding: 10px 17px;
  border-width: 1px;
  border-style: solid;
}

.btn-style-1 + .btn-style-1 {
  margin-left: 5px;
}

.btn-style-1.btn-default {
  color: $primary-active;
  padding: 12px 17px;
  border-width: 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.btn-style-1.btn-primary {
  color: #5096aa;
  background-color: #eef5f7;
  border-color: #fff;

  &:hover {
    background-color: darken(#eef5f7, 10%);
  }

  &:active {
    background-color: darken(#eef5f7, 20%);
  }
}

.btn-style-1.btn-secondary {
  color: #fff;
  background-color: #565d62;
  border-width: 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

  &:hover {
    color: #fff;
    background-color: darken(#565d62, 10%);
  }

  &:active {
    background-color: darken(#565d62, 20%);
  }
}

.btn-style-1.btn-warning {
  color: #fff;
  background-color: #debf50;
  border-color: #ecd273;

  &:hover {
    color: #fff;
    background-color: darken(#debf50, 10%);
  }

  &:active {
    background-color: darken(#debf50, 20%);
  }
}

.btn-style-1.btn-danger {
  color: #ff4d11;
  background-color: #fff6f3;
  border-color: #fff;

  &:hover {
    color: #fff;
    background-color: darken(#fff6f3, 10%);
  }

  &:active {
    background-color: darken(#f7eae7, 20%);
  }
}

.btn-style-1 > .btn-style-1-icon:first-child {
  margin-right: 3px;
}

.btn-style-1 > .btn-style-1-icon:last-child {
  margin-left: 3px;
}

.label-style-1 {
  display: inline-block;
  margin: 5px;
  background-color: $dgrey-background;
  border-width: 2px;
  border-style: solid;
  border-radius: 20px;
  padding: 8px 18px;
  font-size: 12px;
  vertical-align: middle;

  p {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
  }
}

.label-style-1-close {
  color: #727c82;
  font-size: 20px;
  margin-left: 10px;
  vertical-align: middle;
  cursor: pointer;

  &:hover,
  &:focus {
    opacity: 0.6;
  }
}

.label-style-1-yellow {
  border-color: #debf50;
}

.label-style-1-pink {
  border-color: #e9a898;
}
