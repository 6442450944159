ssi-widget-chart-pie {
  font-size: 1rem;
}
ssi-widget-chart-pie .insights-widget {
  position: relative;
  height: 100%;
  width: 100%;
}
ssi-widget-chart-pie .insights-chart-pie {
  display: flex;
  align-items: center;
  justify-content: center;
}
ssi-widget-chart-pie .insights-chart-pie-total {
  font-size: 26px;
  font-weight: 900;
  color: #101525;
  text-rendering: geometricPrecision;
  margin: -7px 0 7px;
}
ssi-widget-chart-pie .insights-chart-pie-subtotal {
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  color: #838eab;
  text-transform: uppercase;
  text-rendering: geometricPrecision;
  margin: -5px 0 13px;
}
ssi-widget-chart-pie .insights-chart-pie-wrapper {
  height: min(23em, 90%);
  width: min(50em, 90%);
  margin-left: -6%;
}