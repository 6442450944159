ssi-ad-stats ssi-spend-engagement {
  font-size: 1rem;
}
ssi-ad-stats ssi-spend-engagement .spend-graphs-section {
  width: 100%;
}
ssi-ad-stats ssi-spend-engagement .section-chart-wrap {
  position: relative;
  background-color: #fff;
  padding: 7em 4em 4em;
  margin: 0 0 2em;
  box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
  border-radius: 1.2em;
  width: 100%;
}
ssi-ad-stats ssi-spend-engagement .section-chart-wrap .section-chart-context-menu-icon {
  display: inline-block;
  position: absolute;
  top: 2.6rem;
  right: 1.7rem;
  font-size: 2.6rem;
  color: #838eab;
  pointer-events: none;
}
@media print {
  ssi-ad-stats ssi-spend-engagement .section-chart-wrap .section-chart-context-menu-icon {
    display: none;
  }
}
ssi-ad-stats ssi-spend-engagement .section-chart-wrap highcharts-chart {
  padding: 0 2em 0 2em;
}
ssi-ad-stats ssi-spend-engagement .section-chart-wrap highcharts-chart,
ssi-ad-stats ssi-spend-engagement .section-chart-wrap .highcharts-container,
ssi-ad-stats ssi-spend-engagement .section-chart-wrap .highcharts-root {
  overflow: visible !important;
}
ssi-ad-stats ssi-spend-engagement .section-chart-wrap .highcharts-axis-title {
  font-weight: 900;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
ssi-ad-stats ssi-spend-engagement .section-chart-wrap .highcharts-axis-labels text {
  font-weight: 900;
}
ssi-ad-stats ssi-spend-engagement .section-chart-wrap .highcharts-xaxis-labels {
  transform: translate(0, 1em);
}
ssi-ad-stats ssi-spend-engagement .section-chart-wrap .highcharts-grid-line {
  transform: translate(0, -20px);
}
ssi-ad-stats ssi-spend-engagement .section-chart-wrap .highcharts-grid-line:nth-child(2n) {
  display: none;
}
ssi-ad-stats ssi-spend-engagement .highcharts-legend-item {
  margin: 0 3em !important;
}