@charset "UTF-8";
ssi-activity {
  display: flex;
  flex-direction: column;
  margin: 0 15px;
  padding: 0;
  /* stylelint-disable */
  /* stylelint-enable */
}
ssi-activity.self-penned .activity-body, ssi-activity.self-penned.active .activity-body {
  background-color: #fff;
}
ssi-activity.self-penned .activity-body .media .media-avatars, ssi-activity.self-penned.active .activity-body .media .media-avatars {
  left: auto;
  right: -20px;
}
ssi-activity .session-msg-remaining {
  display: flex;
  padding: 8px;
  background-color: #faeeeb;
  color: #e39e8a;
  border-radius: 10px;
}
ssi-activity .activity-text-read-more {
  color: #43ade8;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  cursor: pointer;
}
ssi-activity .activity-text-read-more:hover, ssi-activity .activity-text-read-more:active {
  color: #1b98dd;
}
ssi-activity .activity-media-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  list-style: none;
  margin: 20px 0 0 0;
  padding: 0;
}
ssi-activity .activity-media-container > li {
  margin: 0;
  padding: 0;
}
ssi-activity .activity-media {
  cursor: pointer;
  display: flex;
  position: relative;
}
ssi-activity .activity-media + li {
  margin-left: -60px;
}
ssi-activity .activity-media img {
  border-radius: 25px;
  width: 120px;
  height: 80px;
  object-fit: cover;
  border: solid 3px #dfe6e9;
}
ssi-activity .activity-media .activity-media-icon {
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  bottom: -6px;
  color: #43ade8;
  display: flex;
  font-size: 24px;
  justify-content: center;
  left: -22px;
  padding: 10px;
  position: absolute;
  text-align: center;
  width: auto;
}
ssi-activity .activity-media .activity-media-icon img {
  border-radius: 10px;
  height: 96px;
  width: auto;
}
ssi-activity .activity-split-conversation-btn {
  border: 0;
  background-color: #e1b4a6;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  color: white;
  font-size: 20px;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}
ssi-activity .activity-split-conversation {
  position: relative;
}
ssi-activity .activity-split-conversation:hover .activity-split-conversation-btn {
  display: block;
}
ssi-activity .activity-split-conversation:hover .media-object {
  visibility: hidden;
}
ssi-activity .media-object.border-info {
  border: solid 2px #5bc0de;
}
ssi-activity .media-object.border-warning {
  border: solid 2px #e2b928;
}
ssi-activity .media-object.border-royal {
  border: solid 2px #a12378;
}
ssi-activity .activity-top {
  height: 40px;
}
ssi-activity .activity-body {
  background-color: #e3eaed;
  border-radius: 20px !important;
  color: #596065;
}
ssi-activity .activity-body button {
  background: none;
  border: none;
  padding: 0;
}
ssi-activity .activity-body .media {
  margin: 0;
  padding: 15px 30px;
  position: relative;
}
ssi-activity .activity-body .media .media-avatars {
  left: -20px;
  position: absolute;
  top: 0;
}
ssi-activity .activity-body img.media-object {
  width: 38px;
  height: 38px;
}
ssi-activity .activity-body .activity-author-name {
  font-weight: normal;
  font-size: 17px;
}
ssi-activity .activity-body .activity-author-username {
  font-size: 14px;
}
ssi-activity .activity-body .media-heading,
ssi-activity .activity-body .media-body {
  font-weight: normal;
}
ssi-activity .activity-body .media-body {
  font-size: 14px;
}
ssi-activity .activity-body .media-body ssi-activity-text {
  line-height: 1.6em;
}
ssi-activity .activity-body .activity-created-at,
ssi-activity .activity-body .activity-response-time,
ssi-activity .activity-body .activity-deleted-by,
ssi-activity .activity-body .activity-visibility,
ssi-activity .activity-body .activity-outbox-author,
ssi-activity .activity-body .activity-deleted-by:before {
  color: #666666;
  padding-top: 10px;
  padding-bottom: 10px;
  font-style: italic;
  font-weight: 600;
}
ssi-activity .activity-body .activity-deleted-by {
  color: #e36635;
}
ssi-activity .activity-body .activity-deleted-by:before {
  content: "  |  ";
}
ssi-activity .activity-body .activity-links {
  display: flex;
}
ssi-activity .activity-body .activity-links-right {
  flex: 1;
  text-align: right;
}
ssi-activity .activity-body .activity-link {
  color: #43ade8;
  font-size: 11px;
  font-weight: normal;
}
ssi-activity .activity-body .activity-info {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  list-style: none;
  margin: 10px 0 0 0;
  padding: 0;
}
ssi-activity .activity-body .activity-info > li {
  margin: 0;
  padding: 0;
}
ssi-activity .activity-body .activity-info > li:not(:first-child):before {
  content: "|";
  padding: 0 0.2em;
}
ssi-activity .activity-body .activity-info-sm {
  font-size: 11px;
  font-weight: normal;
}
ssi-activity .activity-body .activity-links-left .activity-link {
  margin-right: 20px;
}
ssi-activity .activity-body .activity-links-right .activity-link {
  margin-left: 20px;
}
ssi-activity .activity-body .activity-links-right {
  text-align: right;
}
ssi-activity .activity-body .label {
  background-color: #dde4e8;
  color: #262626;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 9px;
  padding: 7px;
}
ssi-activity .activity-body .activity-outbox-stats {
  display: flex;
  font-size: 11px;
  justify-content: flex-end;
  margin-top: 12px;
}
ssi-activity .activity-body .activity-outbox-stats [class^=col-] {
  text-align: left;
}
ssi-activity .activity-footer {
  display: flex;
  flex-flow: row nowrap;
  font-size: 0.85em;
  justify-content: flex-end;
  list-style: none;
  margin: 10px 0 0 0;
  padding: 0;
  white-space: nowrap;
}
ssi-activity .activity-footer > li {
  align-items: center;
  display: flex;
  font-size: 0.85em;
  font-style: italic;
  font-weight: 500;
  margin: 0 5px;
  padding: 3px 10px;
}
ssi-activity .activity-footer .activity-link,
ssi-activity .activity-footer .activity-link > button {
  font-size: 0.85em;
}
ssi-activity .activity-footer .activity-link {
  background-color: #e3eaed;
  border: none;
  border-radius: 10px;
  color: #43ade8;
  cursor: pointer;
  font-size: 0.85em;
  font-style: italic;
  padding: 3px 10px;
}
ssi-activity .activity-footer .activity-link:hover, ssi-activity .activity-footer .activity-link.active {
  background-color: #43ade8;
  color: #fff;
}
ssi-activity .activity-footer .activity-link:hover a,
ssi-activity .activity-footer .activity-link:hover button, ssi-activity .activity-footer .activity-link.active a,
ssi-activity .activity-footer .activity-link.active button {
  color: #fff;
}
ssi-activity .activity-footer .activity-link.delete-button-container {
  background-color: #e9856f;
  color: #fff;
}
ssi-activity .activity-footer .activity-link.delete-button-container:hover {
  background-color: #df4d2d;
}
ssi-activity .activity-footer .activity-link > button {
  background-color: transparent;
  border: none;
  padding: 0;
}
ssi-activity .activity-footer .activity-link a {
  text-decoration: none;
}
ssi-activity .activity-footer .activity-link .link-icon {
  padding-right: 0.25em;
}
ssi-activity .activity-footer .activity-link .activity-notes-text-label {
  padding-right: 0.25em;
}
ssi-activity .notes-temp-trigger {
  background-color: #e3eaed;
  border: none;
  border-radius: 10px;
  color: #43ade8;
  cursor: pointer;
  font-size: 0.85em;
  font-style: italic;
  padding: 3px 10px;
}
ssi-activity .activity-reply-users {
  color: #bacbd6;
  font-style: italic;
}
ssi-activity .activity-reply-users .name {
  padding-left: 0.2em;
}
ssi-activity .activity-reply {
  margin: 40px 0;
  position: relative;
}
ssi-activity .activity-reply.social-wall {
  margin: 0;
  padding-top: 10px;
  padding-left: 50px;
}
ssi-activity .activity-reply .activity-viewers {
  font-size: 28px !important;
  color: #43ade8;
}
ssi-activity .activity-reply .activity-typers {
  font-style: italic;
  position: relative;
  top: -4px;
  left: 3px;
}
ssi-activity .activity-reply .activity-typers,
ssi-activity .activity-reply .activity-viewers {
  color: #43ade8;
}
ssi-activity .activity-reply .activity-action-buttons {
  margin: 10px 0;
  padding: 0 10px;
}
ssi-activity .activity-reply .activity-action-buttons .btn {
  font-size: 11px;
}
ssi-activity .activity-reply .activity-action-buttons > .btn,
ssi-activity .activity-reply .btn-group {
  margin-left: 5px;
}
ssi-activity .activity-reply .activity-action-buttons button {
  border: none;
  border-radius: 30px;
  padding: 3px 12px;
}
ssi-activity .activity-reply .activity-action-buttons button.dm-reply-button {
  background-color: #7d90a0;
  color: #fff;
}
ssi-activity .activity-reply.activty-reply-sticky {
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 30px);
  margin: 0 15px;
  padding: 3px 0 18px;
  background-color: #f8f9fd;
  z-index: 1;
}
ssi-activity .activity-reply.border-royal textarea {
  border-top: solid 3px #f9e3f2;
}
ssi-activity .activity-reply.border-royal textarea {
  border-left: solid 3px #f9e3f2;
  border-right: solid 3px #f9e3f2;
}
ssi-activity img.media-object,
ssi-activity .activity-avatar {
  border-radius: 50%;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
@media only screen and (max-width: 1350px) {
  ssi-activity .media-body {
    padding-right: 15px !important;
  }
}

ssi-activity.active .activity-body {
  background-color: #d8e1e5;
  border: solid 2px #fff;
}

ssi-activity.activity-nested-thread .activity-reply-body {
  margin-left: 0;
}