@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../angularjs/common/sass/mixins';
@import '../../../../../angularjs/common/sass/variables';
@import '../manage-ifttt-applet/service-selected/triggers-and-actions/triggers-and-actions.component.scss';
@import '~/styles/colors';

.ifttt-applet-collection-list {
  $ifttt-services-bg-light: #f8f8f8;
  padding: 15px !important;

  .ifttt-applet-collection-list-header {
    padding: 25px 25px 0;
    font-weight: normal;
  }

  h2 {
    margin-top: 0;
  }

  .ifttt-services-tabs {
    margin: 30px 0;
    display: flex;
    justify-content: flex-end;
  }

  .ifttt-services-tab {
    color: $primary-active;
    font-weight: normal;
    font-size: 18px;
    background-color: $ifttt-services-bg-light;
    border-radius: 15px;
    text-align: center;
    padding: 5px 20px;
    margin-left: $section-margin-width;
  }

  .ifttt-services-tab.active {
    background-color: #a0bdce;
    color: white;
  }

  .ifttt-applet-collection-list {
    border-collapse: separate;
    border-spacing: $section-margin-width;

    th {
      text-align: center;
      color: #c2cdd4;
      text-transform: uppercase;
      font-weight: normal;
      font-size: 12px;
    }

    td,
    th {
      background-color: $ifttt-services-bg-light;
      border: 0;
      vertical-align: middle;
    }

    td {
      padding: 3px 10px;
    }
  }

  .ifttt-applet-collection-list-column-name {
    width: 20%;
  }

  .ifttt-applet-collection-list-column-accounts {
    width: 15%;
  }

  .ifttt-applet-collection-list-column-triggers {
    width: 32%;
  }

  .ifttt-applet-collection-list-item-delete {
    text-align: center;
    width: 50px;
    a {
      color: $brand-danger;
      font-size: 18px;
    }

    a:hover {
      color: darken($brand-danger, 5%);
    }
  }

  .ifttt-applet-collection-list-item-name-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ifttt-applet-collection-list-item-name {
    flex: 1;
    padding: 0 15px;
    color: $primary-active;
    font-weight: normal;
  }

  .ifttt-applet-collection-list-item-label-account,
  .ifttt-applet-collection-list-item-label-trigger,
  .ifttt-applet-collection-list-item-label-action {
    border-radius: 15px;
    display: inline-block;
    padding: 4px 9px;
    font-weight: normal;
    font-style: italic;
    margin-right: 5px;
    font-size: 12px;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .ifttt-applet-collection-list-item-label-account {
    background-color: #a0bdce;
  }

  .ifttt-applet-collection-list-item-label-trigger {
    background-color: #ebcbe0;
  }

  .ifttt-applet-collection-list-item-label-action {
    background-color: $ifttt-actions-color-secondary;
  }

  .ifttt-applet-collection-list-item.disabled {
    .ifttt-applet-collection-list-item-name,
    .ifttt-applet-collection-list-item-edit,
    .ifttt-applet-collection-list-item-label-account,
    .ifttt-applet-collection-list-item-label-trigger,
    .ifttt-applet-collection-list-item-label-action {
      color: #c7c7cc;
    }

    .ifttt-applet-collection-list-item-label-account,
    .ifttt-applet-collection-list-item-label-trigger,
    .ifttt-applet-collection-list-item-label-action {
      background-color: #eceff0;
    }
  }

  .toggle {
    margin-top: 5px;
    margin-bottom: 0;
  }

  .toggle input:checked + label {
    background-color: $primary-active;
  }

  .ingredient-label {
    font-weight: bold;
  }
}

.trigger-broken-faq-link {
  color: $lmode-body-text;
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 1px;
  text-decoration: underline;
  text-transform: uppercase;
}
