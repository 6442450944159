.manage-ifttt-applet-triggers-and-actions .manage-ifttt-applet-triggers-and-actions-top {
  width: 100%;
  margin: auto;
  padding-top: 85px;
}
.manage-ifttt-applet-triggers-and-actions .manage-ifttt-applet-triggers-and-actions-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.manage-ifttt-applet-triggers-and-actions .applet-name-container {
  background-color: #f8f8f8;
  padding: 8px 10px;
  border-radius: 7px;
  text-align: center;
  color: #14bae3;
  margin-bottom: 8px;
  text-transform: uppercase;
  font-size: 11px;
  height: 30px;
}
.manage-ifttt-applet-triggers-and-actions .applet-name-container .applet-name {
  font-weight: bold;
  font-style: italic;
}
.manage-ifttt-applet-triggers-and-actions .applet-name-container i {
  float: right;
  margin-top: 2px;
}
.manage-ifttt-applet-triggers-and-actions .applet-name-container:hover {
  color: #23527c;
  cursor: pointer;
}
.manage-ifttt-applet-triggers-and-actions .manage-ifttt-applet-triggers-and-actions-top-inner {
  display: flex;
  align-items: center;
  justify-content: center;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-columns-container {
  display: flex;
  flex-direction: column;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-columns {
  display: flex;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column:not(:last-child) {
  margin-right: 8px;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-columns-nav-arrow {
  width: 50px;
  text-align: center;
  color: #14bae3;
  font-size: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  margin-top: 30px;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-columns-nav-arrow i:hover {
  cursor: pointer;
  color: #23527c;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column-body {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  background-color: #f8f8f8;
  padding: 0 15px;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column-body i {
  font-size: 40px;
  margin: 20px 5px;
  color: #c9d2d8;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column-body .applet-config-column-body-title,
.manage-ifttt-applet-triggers-and-actions .applet-config-column-body .applet-config-column-body-sub-title {
  font-style: italic;
  color: #c9d2d8;
  font-weight: normal;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column-body .applet-config-column-body-sub-title {
  font-size: 12px;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column-header,
.manage-ifttt-applet-triggers-and-actions .applet-config-column-footer {
  border-radius: 5px;
  padding: 8px 10px;
  text-align: center;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: normal;
  cursor: pointer;
  width: 230px;
  margin: auto;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column-header i,
.manage-ifttt-applet-triggers-and-actions .applet-config-column-footer i {
  float: right;
  margin-top: 3px;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column-header {
  color: white;
  margin-bottom: 8px;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column-footer {
  font-style: italic;
  color: white;
  margin-top: 8px;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.service-and-accounts .applet-config-column-header,
.manage-ifttt-applet-triggers-and-actions .applet-config-column.service-and-accounts .applet-config-column-footer.active {
  background-color: #a0bdce;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.service-and-accounts .applet-config-column-header:hover,
.manage-ifttt-applet-triggers-and-actions .applet-config-column.service-and-accounts .applet-config-column-footer.active:hover {
  background-color: #8fb1c5;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.service-and-accounts .applet-config-column-body {
  border-color: #c2d4df;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.service-and-accounts .applet-config-column-body i {
  color: #a0bdce;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.service-and-accounts .ingredient-label {
  color: #a0bdce;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.triggers .applet-config-column-header,
.manage-ifttt-applet-triggers-and-actions .applet-config-column.triggers .applet-config-column-footer.active {
  background-color: #a12378;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.triggers .applet-config-column-header:hover,
.manage-ifttt-applet-triggers-and-actions .applet-config-column.triggers .applet-config-column-footer.active:hover {
  background-color: #8c1e68;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.triggers .applet-config-column-body {
  border-color: #c7569e;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.triggers .applet-config-column-body i {
  color: #a12378;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.triggers .ingredient-label {
  color: #a12378;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.actions .applet-config-column-header,
.manage-ifttt-applet-triggers-and-actions .applet-config-column.actions .applet-config-column-footer.active {
  background-color: #acb638;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.actions .applet-config-column-header:hover,
.manage-ifttt-applet-triggers-and-actions .applet-config-column.actions .applet-config-column-footer.active:hover {
  background-color: #9aa332;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.actions .applet-config-column-body {
  border-color: #d8ddb0;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.actions .applet-config-column-body i {
  color: #acb638;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.actions .ingredient-label {
  color: #acb638;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column:not(.has-items) .applet-config-column-body i {
  color: #bac6cd;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.active .applet-config-column-header {
  pointer-events: none;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.active .applet-config-column-body {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.active .applet-config-column-body .applet-config-column-body-title,
.manage-ifttt-applet-triggers-and-actions .applet-config-column.active .applet-config-column-body .applet-config-column-body-sub-title {
  color: #14bae3;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.active .applet-config-column-footer.active {
  background-color: #14bae3;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.active .applet-config-column-footer.active:hover {
  background-color: shade(#14bae3, 5%);
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.has-items.active .applet-config-column-body {
  border-width: 3px;
  border-style: solid;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.future .applet-config-column-body i {
  color: #c9d2d8;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.future .applet-config-column-body:hover {
  cursor: pointer;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.future .applet-config-column-body:hover i {
  color: shade(#c9d2d8, 5%);
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column-footer.disabled {
  background-color: #f8f8f8;
  color: #c9d2d8;
  pointer-events: none;
}
.manage-ifttt-applet-triggers-and-actions .manage-ifttt-applet-triggers-and-actions-bottom-title {
  font-size: 26px;
}
.manage-ifttt-applet-triggers-and-actions .manage-ifttt-applet-triggers-and-actions-bottom-sub-title {
  font-weight: normal;
}
.manage-ifttt-applet-triggers-and-actions .manage-ifttt-applet-triggers-and-actions-bottom-inner {
  margin: 20px;
  width: 100%;
}
.manage-ifttt-applet-triggers-and-actions .manage-ifttt-applet-cards {
  display: flex;
  flex-wrap: wrap;
}
.manage-ifttt-applet-triggers-and-actions ssi-trigger-or-action-card {
  flex-basis: calc(20% - 8px);
  margin-right: 8px;
  margin-bottom: 8px;
}
.manage-ifttt-applet-triggers-and-actions ssi-trigger-or-action-card .btn-primary[disabled] {
  background-color: #c4cfd5;
  border-color: #c4cfd5;
}
.manage-ifttt-applet-triggers-and-actions ssi-trigger-or-action-card .card-form-elements-columns {
  display: flex;
  height: 100%;
  min-height: 0;
}
.manage-ifttt-applet-triggers-and-actions ssi-trigger-or-action-card .card-form-elements {
  display: flex;
  padding: 0 30px;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  width: 100%;
}
.manage-ifttt-applet-triggers-and-actions ssi-trigger-or-action-card .card-form-element {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 0;
}
.manage-ifttt-applet-triggers-and-actions ssi-trigger-or-action-card .card-form-element.flex-basis {
  flex-basis: 50px;
}
.manage-ifttt-applet-triggers-and-actions ssi-trigger-or-action-card .scroll-vertical {
  flex: 1;
}
.manage-ifttt-applet-triggers-and-actions ssi-trigger-or-action-card .ingredient-label {
  background-color: #acb638;
  border-radius: 8px;
  display: inline-block;
  padding: 0 4px;
  margin-bottom: 1px;
}
.manage-ifttt-applet-triggers-and-actions .card-form-element-input {
  background-color: #f0f2f3;
  border-radius: 0;
  border: 0;
  resize: none;
  box-shadow: none;
  text-align: left;
}
.manage-ifttt-applet-triggers-and-actions .card-form-element-input::placeholder {
  color: #c9d8e0;
}
.manage-ifttt-applet-triggers-and-actions textarea.card-form-element-input {
  flex: 1;
}
.manage-ifttt-applet-triggers-and-actions .card-form-element-input:focus {
  box-shadow: none;
  border: 0;
}
.manage-ifttt-applet-triggers-and-actions textarea[ssiTextInputElement].card-form-element-input {
  background: none;
}
.manage-ifttt-applet-triggers-and-actions .text-input-highlight-container .text-highlight-element {
  background-color: #f0f2f3;
}
.manage-ifttt-applet-triggers-and-actions .text-input-highlight-container .text-highlight-element-tag {
  background-color: #cedae1;
}
.manage-ifttt-applet-triggers-and-actions .ingredients-popover {
  border: 0;
  height: 343px;
  width: 300px;
  max-width: initial;
  border-radius: 0;
  padding: 20px;
}
.manage-ifttt-applet-triggers-and-actions .ingredients-popover .popover-title {
  display: none;
}
.manage-ifttt-applet-triggers-and-actions .ingredients-popover h4 {
  text-align: center;
  color: #cedae1;
  font-size: 15px;
  font-weight: normal;
  margin-bottom: 20px;
}
.manage-ifttt-applet-triggers-and-actions .ingredients-popover .scroll-vertical {
  max-height: 230px;
}
.manage-ifttt-applet-triggers-and-actions .popover.ingredients-popover::before {
  content: initial;
}
.manage-ifttt-applet-triggers-and-actions .popover.ingredients-popover::after {
  border: 0;
}
.manage-ifttt-applet-triggers-and-actions .exact-matches-checkbox-wrap {
  display: flex;
  align-items: center;
  padding: 5px 0 20px 0;
}
.manage-ifttt-applet-triggers-and-actions .exact-matches-checkbox-wrap a {
  color: #43537f;
}
.manage-ifttt-applet-triggers-and-actions .exact-matches-checkbox-wrap a:hover {
  color: #14bae3;
}
.manage-ifttt-applet-triggers-and-actions .exact-matches-checkbox-wrap a i {
  margin-left: 5px;
  cursor: pointer;
}

.ifttt-applet-collection-list {
  padding: 15px !important;
}
.ifttt-applet-collection-list .ifttt-applet-collection-list-header {
  padding: 25px 25px 0;
  font-weight: normal;
}
.ifttt-applet-collection-list h2 {
  margin-top: 0;
}
.ifttt-applet-collection-list .ifttt-services-tabs {
  margin: 30px 0;
  display: flex;
  justify-content: flex-end;
}
.ifttt-applet-collection-list .ifttt-services-tab {
  color: #14bae3;
  font-weight: normal;
  font-size: 18px;
  background-color: #f8f8f8;
  border-radius: 15px;
  text-align: center;
  padding: 5px 20px;
  margin-left: 8px;
}
.ifttt-applet-collection-list .ifttt-services-tab.active {
  background-color: #a0bdce;
  color: white;
}
.ifttt-applet-collection-list .ifttt-applet-collection-list {
  border-collapse: separate;
  border-spacing: 8px;
}
.ifttt-applet-collection-list .ifttt-applet-collection-list th {
  text-align: center;
  color: #c2cdd4;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 12px;
}
.ifttt-applet-collection-list .ifttt-applet-collection-list td,
.ifttt-applet-collection-list .ifttt-applet-collection-list th {
  background-color: #f8f8f8;
  border: 0;
  vertical-align: middle;
}
.ifttt-applet-collection-list .ifttt-applet-collection-list td {
  padding: 3px 10px;
}
.ifttt-applet-collection-list .ifttt-applet-collection-list-column-name {
  width: 20%;
}
.ifttt-applet-collection-list .ifttt-applet-collection-list-column-accounts {
  width: 15%;
}
.ifttt-applet-collection-list .ifttt-applet-collection-list-column-triggers {
  width: 32%;
}
.ifttt-applet-collection-list .ifttt-applet-collection-list-item-delete {
  text-align: center;
  width: 50px;
}
.ifttt-applet-collection-list .ifttt-applet-collection-list-item-delete a {
  color: #e36635;
  font-size: 18px;
}
.ifttt-applet-collection-list .ifttt-applet-collection-list-item-delete a:hover {
  color: #e0551f;
}
.ifttt-applet-collection-list .ifttt-applet-collection-list-item-name-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ifttt-applet-collection-list .ifttt-applet-collection-list-item-name {
  flex: 1;
  padding: 0 15px;
  color: #14bae3;
  font-weight: normal;
}
.ifttt-applet-collection-list .ifttt-applet-collection-list-item-label-account,
.ifttt-applet-collection-list .ifttt-applet-collection-list-item-label-trigger,
.ifttt-applet-collection-list .ifttt-applet-collection-list-item-label-action {
  border-radius: 15px;
  display: inline-block;
  padding: 4px 9px;
  font-weight: normal;
  font-style: italic;
  margin-right: 5px;
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 4px;
}
.ifttt-applet-collection-list .ifttt-applet-collection-list-item-label-account {
  background-color: #a0bdce;
}
.ifttt-applet-collection-list .ifttt-applet-collection-list-item-label-trigger {
  background-color: #ebcbe0;
}
.ifttt-applet-collection-list .ifttt-applet-collection-list-item-label-action {
  background-color: #d8ddb0;
}
.ifttt-applet-collection-list .ifttt-applet-collection-list-item.disabled .ifttt-applet-collection-list-item-name,
.ifttt-applet-collection-list .ifttt-applet-collection-list-item.disabled .ifttt-applet-collection-list-item-edit,
.ifttt-applet-collection-list .ifttt-applet-collection-list-item.disabled .ifttt-applet-collection-list-item-label-account,
.ifttt-applet-collection-list .ifttt-applet-collection-list-item.disabled .ifttt-applet-collection-list-item-label-trigger,
.ifttt-applet-collection-list .ifttt-applet-collection-list-item.disabled .ifttt-applet-collection-list-item-label-action {
  color: #c7c7cc;
}
.ifttt-applet-collection-list .ifttt-applet-collection-list-item.disabled .ifttt-applet-collection-list-item-label-account,
.ifttt-applet-collection-list .ifttt-applet-collection-list-item.disabled .ifttt-applet-collection-list-item-label-trigger,
.ifttt-applet-collection-list .ifttt-applet-collection-list-item.disabled .ifttt-applet-collection-list-item-label-action {
  background-color: #eceff0;
}
.ifttt-applet-collection-list .toggle {
  margin-top: 5px;
  margin-bottom: 0;
}
.ifttt-applet-collection-list .toggle input:checked + label {
  background-color: #14bae3;
}
.ifttt-applet-collection-list .ingredient-label {
  font-weight: bold;
}

.trigger-broken-faq-link {
  color: #43537f;
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 1px;
  text-decoration: underline;
  text-transform: uppercase;
}