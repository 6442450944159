ssi-finalise-step .row-content {
  display: flex;
  align-items: flex-end;
  width: 100%;
  justify-content: center;
  margin: 0 0 40px;
}
ssi-finalise-step .row-content .row-content {
  margin: 30px 0 0;
  justify-content: space-between;
}
ssi-finalise-step .row-content .form-field {
  width: 100%;
  max-width: 600px;
}
ssi-finalise-step ssi-rounded-box-check .rounded-box-check-container {
  padding-left: 18px;
  padding-right: 18px;
}
ssi-finalise-step .step-content {
  margin: 70px 0;
}
ssi-finalise-step hr {
  height: 2px;
  background-color: #e7e9ef;
  display: block;
  border: 0;
  border-radius: 100px;
  margin: 30px 0 30px 0;
}
ssi-finalise-step .language-filtering h2 {
  font-size: 20px;
  font-weight: 900;
  color: #43537f;
}
ssi-finalise-step .language-filtering p {
  font-size: 14px;
  font-weight: bold;
  color: #43537f;
}
ssi-finalise-step .language-filtering .dropdown-container {
  margin-top: 20px;
}
ssi-finalise-step .language-filtering .dropdown-container .select-language-placeholder {
  color: #838eab;
  font-weight: bold;
}
ssi-finalise-step .language-filtering label {
  color: #43537f;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-left: 20px;
  margin-bottom: 15px;
}
ssi-finalise-step .language-filtering .input-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
ssi-finalise-step .language-filtering .input-wrapper .languages-selector {
  width: 100%;
}
ssi-finalise-step .language-filtering .input-wrapper .languages-selector button {
  width: 100%;
  max-width: 500px;
  margin-right: auto;
}
ssi-finalise-step .toggle-source > * {
  margin-right: 10px;
}
ssi-finalise-step orlo-button button {
  opacity: 1 !important;
  border-radius: 10px !important;
}
ssi-finalise-step ssi-toggle-switch {
  flex-basis: 320px;
}
ssi-finalise-step ssi-toggle-switch .toggle-switch-container {
  border-radius: 10px;
  border: 1px solid #c3c9d8;
  background-color: transparent;
  padding: 12px 10px 12px 20px;
}
ssi-finalise-step ssi-toggle-switch .toggle-switch-container span {
  color: #6a759a;
}
ssi-finalise-step ssi-toggle-switch .toggle-switch-container .switch {
  height: 20px;
  width: 36px;
  margin-bottom: 0;
}
ssi-finalise-step ssi-toggle-switch .toggle-switch-container .switch .slider:before {
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
}
ssi-finalise-step ssi-toggle-switch .toggle-switch-container.active {
  border-color: #14bae3;
}
ssi-finalise-step ssi-toggle-switch .toggle-switch-container.active span {
  color: #43537f;
}
ssi-finalise-step ssi-toggle-switch .toggle-switch-container.active .switch .slider::before {
  background-color: #fff;
}
ssi-finalise-step ssi-toggle-switch .field-label {
  padding: 0;
}