ssi-live-chat-activity-tree {
  display: flex;
  flex-flow: column nowrap;
  flex-shrink: 0;
}
ssi-live-chat-activity-tree * {
  display: flex;
}
ssi-live-chat-activity-tree > ul, ssi-live-chat-activity-tree > ul > li > ul {
  flex-flow: column nowrap;
  list-style: none;
  margin: 0;
  padding: 0;
}
ssi-live-chat-activity-tree > ul > li, ssi-live-chat-activity-tree > ul > li > ul > li {
  flex-flow: column nowrap;
  flex-shrink: 0;
  margin: 0;
  position: relative;
}
ssi-live-chat-activity-tree > ul > li.message, ssi-live-chat-activity-tree > ul > li > ul > li.message {
  width: 70%;
}
ssi-live-chat-activity-tree > ul > li.message.agent, ssi-live-chat-activity-tree > ul > li.message.robot, ssi-live-chat-activity-tree > ul > li > ul > li.message.agent, ssi-live-chat-activity-tree > ul > li > ul > li.message.robot {
  align-self: flex-end;
  margin-right: 10%;
}
ssi-live-chat-activity-tree > ul > li.message.visitor, ssi-live-chat-activity-tree > ul > li > ul > li.message.visitor {
  align-self: flex-start;
  margin-left: 10%;
}