@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../../common/styles/colors.scss';
@import '../../../../../../../../angularjs/common/sass/variables';

.post-box-container {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid $dmode-body-text;
  border-radius: 10px;
  overflow: hidden;
  margin: 1px;
  cursor: pointer;
  width: 100%;

  &.post-box-container-modal-inner .content .text-content {
    padding-right: 75px;
  }

  .top-info {
    display: flex;
    align-items: center;
    height: 35px;
    font-weight: bold;
    font-size: 12px;
    padding: 2px 7px;
    color: $lmode-title-text;
  }

  .content {
    position: relative;
    min-height: 60px;
    padding: 7px 4px;
    border-top: 1px solid $dmode-body-text;
    border-bottom: 1px solid $dmode-body-text;
    max-height: 160px;

    .network-name {
      display: flex;
      align-items: center;

      h5 {
        margin: 0 0 5px 4px;
        font-weight: 900;
        font-size: 12px;
        color: $lmode-title-text;
      }
    }

    .text-content {
      padding: 3px 5px;
    }

    .post-video {
      width: 100%;
      padding-bottom: 60%;
      background-color: #e7ecfc;
      border-radius: 5px;
      position: relative;
      i {
        position: absolute;
        font-size: 2em;
        margin: 0 auto;
        width: 25px;
        display: block;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        color: $primary-active;
      }
    }

    .text {
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      color: $lmode-title-text;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }
  }

  .media-preview {
    margin: 7px 0;

    img {
      height: 82px;
      width: 100%;
      border-radius: 5px;
      object-fit: cover;
    }
  }

  .top-info,
  .bottom-actions {
    background-color: $grey-background;
  }

  .bottom-actions {
    width: 100%;
    background-color: $grey-background;
    height: 36px;
    overflow: hidden;

    .bottom-actions-wrap {
      position: relative;
      display: block;
      height: 100%;
      min-height: 36px;
      width: 100%;
      font-size: 0;
      padding: 0 2px;

      &-icons,
      &-labels {
        position: absolute;
        display: flex;
        height: 26px;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: -1;

        &-show {
          z-index: 1;
        }
      }
    }

    a,
    button {
      display: inline-block;
      appearance: none;
      border: none;
      background: none;
      border-radius: 5px;
      white-space: nowrap;
      line-height: 12px;
      font-size: 12px;
      font-weight: 900;
      padding: 7px;
      margin: 0 2px;

      span {
        line-height: 1;
      }

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    // i {
    //   margin-right: 3px;
    // }

    .live-post {
      color: #fff;
      background-color: #b2c614;
    }
    .approval-post {
      color: #fff;
      background-color: #75ddca;
    }
    .scheduled-post {
      color: #fff;
      background-color: #8da4e5;
    }
    .failed-post {
      color: #fff;
      background-color: #e34916;
    }
    .deleted-post {
      color: #e34916;
      background-color: #fff1ed;
    }
    .thread-post {
      color: #fff;
      background-color: $primary-active;
    }
    .create-thread-post {
      color: #fff;
      background-color: $primary-active;
    }
    .draft-post {
      color: #fff;
      background-color: $lmode-helper-text;
    }
  }

  &.failed-post {
    border-color: #e34916;

    .top-info,
    .bottom-actions {
      background-color: #f9dbd1;
    }

    .content {
      border-color: #e34916;
      background-color: #fcece7;
    }
  }

  &.deleted-post {
    border-color: #e34916;

    .content {
      border-color: #e34916;
    }
  }

  ssi-context-menu {
    .context-menu-container {
      border-radius: 10px;
    }
  }

  .post-box-gallery-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 43px;
    margin: auto;
    right: 4px;
    display: flex;
    align-items: center;
    flex: 0 0 auto;

    img {
      display: block;
      border-radius: 5px;
      overflow: hidden;
      background-color: #eee;
      width: auto;
      width: 60px;
      height: 43px;
      object-fit: cover;
      cursor: pointer;

      & + img {
        position: absolute;
        top: 6px;
        left: 3px;
        box-shadow: 0 5px 15px rgba(116, 118, 132, 0.25);
      }
    }

    &-multiple {
      margin-right: 4px;
      padding: 6px 0 0;

      img {
        z-index: 2;

        & + img {
          z-index: 1;
          margin-left: -8px;
        }
      }
    }
  }

  .post-box-gallery-badge-prev {
    background-color: #fff;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -6px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 18px;
    width: 18px;
    font-size: 10px;
    font-weight: 900;
    text-rendering: geometricPrecision;
    border-radius: 50%;
    box-shadow: 0 5px 15px rgba(116, 118, 132, 0.25);
    z-index: 3;
  }
}

.post-box-scroll {
  visibility: hidden;
  position: absolute;
  top: calc(-#{$navbar-height} - 100px);
}

.day-view {
  position: relative;
  border: 1px solid $lmode-helper-text;
  border-radius: 5px;
  width: fit-content;
  cursor: pointer;
  max-width: 100%;

  ssi-social-network-icon {
    height: 26px;
  }

  .content {
    display: flex;
    align-items: center;
    padding: 5px;

    i {
      margin-left: 5px;
    }
  }

  .time-info {
    margin-left: 7px;
    font-size: 10px;
    font-weight: 900;
    color: $lmode-title-text;
    line-height: 10px;
  }

  .text {
    width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 7px;
    font-weight: bold;
    font-size: 12px;
    color: $lmode-title-text;
  }

  &.live-post {
    border-color: #b2c614;
    background-color: rgba(178, 198, 20, 0.3);
  }
  &.approval-post {
    border-color: #75ddca;
    background-color: rgba(117, 221, 202, 0.3);
  }
  &.scheduled-post {
    border-color: #8da4e5;
    background-color: rgba(141, 164, 229, 0.3);
  }
  &.failed-post {
    background-color: rgba(227, 73, 22, 0.3);
  }
  &.deleted-post {
    border-color: #e34916;
    background-color: rgba(227, 73, 22, 0.3);
  }
  &.thread-post {
    background-color: $primary-active;
  }
  &.create-thread-post {
    background-color: $primary-active;
  }
  &.draft-post {
    border-color: $dmode-body-text;
    background-color: rgba(180, 187, 206, 0.3);
  }
}

.day-view-mobile {
  .content {
    display: flex;
    justify-content: space-between;
  }
}

.month-view {
  ssi-social-network-icon {
    height: 20px;
  }
  .content {
    display: flex;
    align-items: center;
    border: 1px solid $lmode-helper-text;
    border-radius: 5px;
    width: fit-content;
    padding: 3px 4px 3px 2px;
    &.failed-post {
      border-color: #e34916;
      background-color: rgba(227, 73, 22, 0.3);
    }
  }
  .post-counter {
    margin-left: 3px;
    font-weight: bold;
    font-size: 12px;
    color: $lmode-title-text;
    padding: 0 2px;
  }
}

.post-box-preview-window {
  background-color: #fff;
  border: 1px solid rgba(131, 142, 171, 0.35);
  border-radius: 15px;
}
