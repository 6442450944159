@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-add-custom-provider-modal {
  .form-row {
    display: flex;
  }
  .form-group {
    width: 100%;
  }
  .info-message {
    margin-top: 20px;
  }

  ul {
    margin: 10px 0;
    padding-left: 0;
    font-size: 17px;

    li {
      margin-left: 20px;
      margin-top: 10px;
      span {
        &:hover {
          cursor: pointer;
          font-weight: bold;
        }
      }
    }
  }

  .success-message,
  .error-message {
    margin-top: 10px;
    text-align: center;
    width: 100%;
  }
  .success-message {
    color: green;
  }
  .error-message {
    color: red;
  }
}
