.social-push-mode-modal-body {
  background-color: #424348;
  text-align: center;
  padding: 55px 50px 45px;
}
@media (max-width: 885px) {
  .social-push-mode-modal-body {
    min-width: 95vw;
    padding: 55px 50px 25px;
  }
}

.social-push-mode-modal-icon {
  display: block;
  font-size: 50px;
  color: #b4bbce;
  margin: 0 0 5px;
}

.social-push-mode-modal-heading {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.social-push-mode-modal-meta {
  color: #b4bbce;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  margin: 0 0 40px;
}

.social-push-mode-action {
  padding: 14px;
  margin: 0 5px;
  font-size: 14px;
  color: #fff;
  line-height: 17px;
  border-radius: 50px;
  border-width: 0;
  font-weight: 600;
  min-width: 165px;
  outline: none;
}
.social-push-mode-action:hover, .social-push-mode-action:active {
  filter: brightness(85%);
}
.social-push-mode-action.social-push-mode-action-primary {
  background-color: #52bdb8;
}
.social-push-mode-action.social-push-mode-action-secondary {
  background-color: #53575d;
}