@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

:host {
  margin-bottom: 3px;
}

ssi-label-tag {
  label {
    display: flex;
    align-self: center;
    align-items: center;
    background-color: $grey-background;
    color: $lmode-title-text;
    border-radius: 15px;
    font-size: 12px;
    font-weight: 900;
    overflow: hidden;
    margin: 0;
    padding: 4px 6px 4px 12px;

    .remove-btn {
      margin-left: 8px;
      font-size: 7px;
      font-weight: 900;
      color: $lmode-helper-text;
      border-radius: 50%;
      padding: 5px;
      &:hover {
        cursor: pointer;
        background-color: $primary-active;
        color: #fff;
      }
    }
  }
}
