@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-analytics ssi-marketing ssi-report ssi-top-posts {
  .top-posts-container {
    .section-body {
      padding: 30px 40px;
      background-color: #fff;
      border-radius: 10px;
      position: relative;
      display: flex;
      flex-direction: column;
    }
  }
}
