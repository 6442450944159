@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';
ssi-widget-kpi {
  font-size: 1rem;
  overflow: hidden;

  .insights-widget {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    grid-gap: 23px 0;
    height: 100%;
    overflow: auto;
  }

  .kpi {
    text-align: center;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    min-height: 203px;
    min-width: 160px;
    flex: 1 0 auto;
    width: 100%;

    .divider {
      height: 4px;
      width: 40px;
      flex: none;
      background-color: #d41d68;
      margin: 7px 0 7px 0;
      border-radius: 100px;
    }
    span {
      text-transform: uppercase;
      text-align: center;
      font-size: 10px;
      color: $lmode-title-text;
      font-weight: 900;
      letter-spacing: 1px;
    }

    ssi-big-number {
      display: block;
      color: $lmode-title-text;
      font-weight: 900;
      font-size: 30px;
    }
  }
}
