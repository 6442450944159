ssi-enable-social-chatbots h1 {
  font-weight: 900;
  color: #838eab;
  font-size: 1.25em;
}
ssi-enable-social-chatbots p {
  color: #838eab;
}
ssi-enable-social-chatbots .enable-social-chatbots {
  background-color: #f8f9fd;
  padding: 2em 4em;
}
ssi-enable-social-chatbots .container {
  position: relative;
  border-radius: 25px;
  padding: 2em 0;
}
ssi-enable-social-chatbots .segment {
  background: #fff;
  border: 2.5em solid #fff;
  border-radius: 25px;
  margin-bottom: 2em;
}
ssi-enable-social-chatbots .ssi-loading {
  margin-top: 10px;
  float: right;
}
ssi-enable-social-chatbots .account-list {
  padding: 0 0 2em 0;
  list-style: none;
}
ssi-enable-social-chatbots .account-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
  border-bottom: 2px solid #f0f3f9;
}
ssi-enable-social-chatbots .account-list li button {
  float: right;
}
ssi-enable-social-chatbots .account-list .account-name {
  padding: 2em 0;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 900;
  line-height: 2.5em;
  color: #838eab;
  letter-spacing: 1px;
  display: inline-block;
}
ssi-enable-social-chatbots .account-list .account-name i {
  margin-right: 1em;
  color: #43ade8;
}
ssi-enable-social-chatbots .orlo-select {
  display: inline-block;
}
ssi-enable-social-chatbots .orlo-select select {
  padding: 0.6em 2.6em 0.6em 1.6em;
}
ssi-enable-social-chatbots .orlo-select i {
  top: 15px;
}
ssi-enable-social-chatbots .return-nav {
  position: absolute;
  top: -16px;
  width: 150px;
  left: 35px;
}