.company-lockdown {
  background-color: d;
  padding: 2em 4em;
}
.company-lockdown .container {
  position: relative;
  background-color: #35353f;
  border-radius: 25px;
  padding: 0 0 6em 0;
}
.company-lockdown .form {
  text-align: center;
}
.company-lockdown .form .text-input {
  margin: 0 auto 1em auto;
}
.company-lockdown .form label {
  margin-bottom: 1em;
  color: #b4bbce;
}
.company-lockdown .header {
  padding: 3em 4em;
  margin-bottom: 5em;
  background-color: #25252d;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  color: #b4bbce;
}
.company-lockdown .header h2 {
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 1px;
}
.company-lockdown .info-list {
  margin: 0 4em;
}
.company-lockdown .info-list p {
  color: #f88c68;
  text-transform: uppercase;
}
.company-lockdown .info-list ul {
  list-style: none;
  padding: 0;
}
.company-lockdown .info-list ul li {
  color: #fff;
  margin-bottom: 1em;
}
.company-lockdown .info-list span {
  margin-left: 1em;
}
.company-lockdown .unlock {
  color: #f88c68;
  text-align: center;
}