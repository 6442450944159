ssi-insights ssi-widget-table .widget-table-container {
  width: 100%;
  height: 100%;
  padding: 30px 30px 20px 30px;
}
ssi-insights ssi-widget-table .widget-table-container .body-cell {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 0 5px 0 10px;
  border-bottom: 2px solid #e0e7f4;
  background-color: #f8f9fd;
}
ssi-insights ssi-widget-table .widget-table-container .body-cell.row-even {
  background-color: #fff;
}
ssi-insights ssi-widget-table .widget-table-container .body-cell.stat {
  color: #101525;
  font-size: 14px;
  font-weight: 900;
}
ssi-insights ssi-widget-table .widget-table-container .body-cell.stat span,
ssi-insights ssi-widget-table .widget-table-container .body-cell.stat ssi-big-number {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
@media print {
  ssi-insights ssi-widget-table .widget-table-container .body-cell.stat span,
ssi-insights ssi-widget-table .widget-table-container .body-cell.stat ssi-big-number {
    white-space: normal;
  }
}