@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-social-post {
  .social-post-dropdown {
    position: absolute;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);

    a {
      display: block;
      padding: 3px 20px;
      clear: both;
      font-weight: 400;
      line-height: 1.428571429;
      color: #333;
      white-space: nowrap;

      &:focus,
      &:hover {
        color: #262626;
        text-decoration: none;
        background-color: #f5f5f5;
      }
    }
  }
}
