ssi-dashboard ssi-edit {
  font-size: 1rem;
}
ssi-dashboard ssi-edit .dashboard-edit-container {
  width: 100%;
  position: relative;
  background-color: #f4f4fa;
  min-height: calc(100vh - 68px);
  overflow-x: hidden;
}
ssi-dashboard ssi-edit .dashboard-edit-container.has-widgets .nav-bar {
  height: 60px;
  width: 100%;
  border-bottom: 1px solid black;
  display: flex;
  align-items: center;
  padding: 10px;
}
ssi-dashboard ssi-edit .dashboard-edit-container.has-widgets .dashboard-content .actions-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding: 20px 20px 10px 20px;
}
ssi-dashboard ssi-edit .dashboard-edit-container.has-widgets .dashboard-content .actions-bar .left {
  display: flex;
  align-items: center;
}
ssi-dashboard ssi-edit .dashboard-edit-container.has-widgets .dashboard-content .actions-bar .right {
  display: flex;
  align-items: center;
}
ssi-dashboard ssi-edit .dashboard-edit-container.has-widgets .dashboard-content .actions-bar .right .save-btn,
ssi-dashboard ssi-edit .dashboard-edit-container.has-widgets .dashboard-content .actions-bar .right .save-as-template-btn {
  margin-left: 10px;
}
ssi-dashboard ssi-edit .dashboard-edit-container .dashboard-templates-menu .templates-button {
  min-width: 300px;
  display: flex;
  justify-content: flex-start;
}
ssi-dashboard ssi-edit .dashboard-edit-container .dashboard-templates-menu .templates-button .templates-select-arrow-icon {
  margin-left: auto;
  margin-right: 0;
  color: #14bae3;
  font-weight: 900;
}
ssi-dashboard ssi-edit .dashboard-edit-container .dashboard-templates-menu .menu-body .templates-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
ssi-dashboard ssi-edit .dashboard-edit-container .dashboard-templates-menu .menu-body .templates-list-item .delete-icon {
  color: #f88c68;
}
ssi-dashboard ssi-edit .confirm-action-tooltip-window {
  background-color: transparent;
}