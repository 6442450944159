ssi-textarea-caret-tracker .bar-container {
  position: absolute;
  width: 100%;
  margin-top: 2px;
  right: 0;
  z-index: 3;
}
ssi-textarea-caret-tracker .striped-bar {
  background: repeating-linear-gradient(to right, #cedae1, #cedae1 10px, #fff 10px, #fff 35px);
  height: 2px;
  margin-top: -2px;
}
ssi-textarea-caret-tracker .bar-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  right: -12.5px;
  top: -12.5px;
  position: absolute;
  background-color: #cedae1;
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
}
ssi-textarea-caret-tracker .bar-icon.active,
ssi-textarea-caret-tracker .bar-icon:hover {
  background-color: #14bae3;
}