ssi-conversation-resolve .conversation-resolve-container {
  display: inline-block;
}
ssi-conversation-resolve i.resolve-icon-standalone {
  font-weight: 700;
  cursor: pointer;
}
ssi-conversation-resolve i.resolve-icon-standalone.resolved {
  cursor: default;
}
ssi-conversation-resolve .resolved-description {
  font-size: 12px;
  color: #838eab;
  font-weight: 700;
  padding-left: 5px;
}
ssi-conversation-resolve .resolved-description i {
  font-weight: 700;
  padding-right: 0.2em;
}