@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-view-profile-modal {
  $green: #5fb65e;
  $orange: #edab55;
  $blue: #3979b3;

  mwl-gauge {
    display: block;
    margin-bottom: 20px;

    .gauge {
      transform: perspective(1px) translateZ(0);
      max-width: 140px;

      .dial {
        stroke: rgb(238, 238, 238);
        stroke-width: 20;
        fill: rgba(0, 0, 0, 0);
      }

      .value {
        stroke-width: 20;
        fill: rgba(0, 0, 0, 0);
      }

      .value-text {
        fill: #596065;
        font-family: sans-serif;
        font-weight: bold;
        font-size: 15px;
        transform: translate(0, -5px);
      }
    }
  }

  .mwl-gauge-label {
    position: absolute;
    bottom: calc(50%);
    left: 0;
    right: 0;
    color: #596065;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 20px;
    line-height: 0;

    &.mwl-gauge-label-large {
      font-size: 30px;
    }
  }

  ssi-gauge-chart {
    position: relative;
    display: block;
  }

  .gauge-chart-small {
    margin-top: 20px;

    mwl-gauge .gauge {
      max-width: 90px;
    }
  }

  .gauge-chart-green {
    mwl-gauge .gauge .value {
      stroke: $green;
    }

    .mwl-gauge-label {
      color: $green;
    }
  }

  .gauge-chart-orange {
    mwl-gauge .gauge .value {
      stroke: $orange;
    }

    .mwl-gauge-label {
      color: $orange;
    }
  }

  .gauge-chart-blue {
    mwl-gauge .gauge .value {
      stroke: $blue;
    }

    .mwl-gauge-label {
      color: $blue;
    }
  }

  .verified-wrap {
    position: relative;
  }

  .verified {
    display: block;
    position: absolute;
    top: -1px;
    right: -1px;
    color: #1DA1F2;
    font-size: 20px;
    z-index: 1;

    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      height: 70%;
      width: 70%;
      background-color: #fff;
      border-radius: 50%;
      z-index: -1;
    }
  }
}
