ssi-custom-widget {
  font-size: 1rem;
  display: block;
  height: 100%;
}
ssi-custom-widget .cw-container {
  display: flex;
  min-height: 100%;
  background-color: #f4f4fa;
  border-radius: 1.2em;
}
ssi-custom-widget .cw-side {
  position: relative;
  width: 30em;
  flex: 0 0 30em;
  border-right: 0.1em solid #838eab;
  min-height: 100%;
  color: #43537f;
  z-index: 3;
}
ssi-custom-widget .cw-side-tabs {
  display: flex;
  height: 60px;
  border-top-left-radius: 1.2em;
  overflow: hidden;
}
ssi-custom-widget .cw-filters {
  text-align: left;
  background-color: #fff;
  height: calc(100% - 60px);
  border-bottom-left-radius: 1.2em;
  opacity: 0;
  visibility: hidden;
  display: none;
}
ssi-custom-widget .cw-filters.active {
  opacity: 1;
  visibility: visible;
  display: block;
}
ssi-custom-widget .cw-filters ssi-global-filters .global-filters ul {
  padding: 0 10px;
}
ssi-custom-widget .cw-side-tab {
  position: relative;
  display: inline-flex;
  flex: 1 0 0;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  letter-spacing: 0.1em;
  border: 0 solid #838eab;
  border-bottom-width: 0.1em;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  cursor: pointer;
}
ssi-custom-widget .cw-side-tab p {
  font-size: 1em;
  margin: 0;
  line-height: 1;
  color: #838eab;
  text-transform: uppercase;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-custom-widget .cw-side-tab.cw-side-tab-active {
  background-color: #fff;
  border-top-width: 0.1em;
  border-left-width: 0.1em;
  border-right-width: 0.1em;
  border-bottom-width: 0;
  cursor: auto;
}
ssi-custom-widget .cw-side-tab.cw-side-tab-active:first-child {
  border-left-width: 0;
  border-top-left-radius: 0;
}
ssi-custom-widget .cw-side-tab.cw-side-tab-active:last-child {
  border-right-width: 0;
  border-top-right-radius: 0;
}
ssi-custom-widget .cw-side-tab.cw-side-tab-active p {
  color: #43537f;
  margin: -0.2em 0 0;
}
ssi-custom-widget .cw-side-tab.cw-side-tab-active p:after {
  content: "";
  position: absolute;
  margin: auto;
  bottom: 0.4em;
  left: 0;
  right: 0;
  display: block;
  width: 5em;
  height: 0.2em;
  background-color: #14bae3;
}
ssi-custom-widget .cw-side-form {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: calc(100% - 60px);
  padding: 4em 3em 2em;
  text-align: left;
  border-bottom-left-radius: 1.2em;
  opacity: 0;
  visibility: hidden;
  display: none;
}
ssi-custom-widget .cw-side-form.active {
  opacity: 1;
  visibility: visible;
  display: flex;
}
ssi-custom-widget .cw-side-form-section {
  position: relative;
  margin: 0 0 3.5em;
}
ssi-custom-widget .cw-side-form-section-action {
  border-top: 1px solid #838eab;
  text-align: center;
  margin: 0 -3em 3.5em;
  padding: 2em 1em 0;
}
ssi-custom-widget .cw-side-form-section-stretch {
  flex: 1 1 auto;
  margin-right: -2em;
  padding-right: 2em;
}
ssi-custom-widget .cw-side-form-section:last-child {
  margin-bottom: 0;
  border-bottom-left-radius: 1.2em;
}
ssi-custom-widget .cw-side-form-heading {
  font-size: 2em;
  padding: 0 0 2rem;
  margin: 0;
  line-height: 1;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-custom-widget .cw-side-form-subheading {
  color: #43537f;
  font-size: 1em;
  letter-spacing: 0.1rem;
  padding: 0.8rem 0 1.6rem;
  margin: 0;
  text-transform: uppercase;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-custom-widget .cw-grouped-radio {
  margin: 0 -0.6em 0 0;
  padding: 0;
  z-index: 1;
}
ssi-custom-widget .cw-grouped-radio-field {
  position: relative;
  display: inline-block;
  height: 5em;
  width: 5em;
  margin: 0 0.6em 0.6em 0;
}
ssi-custom-widget .cw-grouped-radio-field input {
  position: absolute;
  z-index: 0;
}
ssi-custom-widget .cw-grouped-radio-field input:checked + label {
  background-color: #14bae3;
  color: #fff;
  z-index: 1;
}
ssi-custom-widget .cw-grouped-radio-field input:focus-visible + label {
  outline: -webkit-focus-ring-color auto 1px;
  outline-color: -webkit-focus-ring-color;
  outline-style: auto;
  outline-width: 1px;
}
ssi-custom-widget .cw-grouped-radio-field label {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #43537f;
  height: 100%;
  width: 100%;
  background-color: #f4f4fa;
  border-radius: 1em;
  cursor: pointer;
}
ssi-custom-widget .cw-grouped-radio-field label i {
  font-size: 2em;
  line-height: 1;
}
ssi-custom-widget .cw-side-form-confirm {
  position: absolute;
  top: 3.5em;
  left: 0;
  background-color: #fff;
  padding: 3em 3em 2.7em;
  width: 30em;
  color: #43537f;
  border-radius: 1em;
  box-shadow: 0 0.5em 1em rgba(131, 142, 171, 0.35);
  z-index: 2;
}
ssi-custom-widget .cw-side-form-confirm p {
  font-weight: 700;
  font-size: 1.4em;
  line-height: 2rem;
  margin: 0 0 1.3rem;
  text-rendering: geometricPrecision;
}
ssi-custom-widget .cw-side-form-confirm-btn {
  font-size: 1.4em;
  color: inherit;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  font-weight: 900;
  z-index: 9;
  background-color: transparent;
  border-width: 0;
  text-rendering: geometricPrecision;
}
ssi-custom-widget .cw-side-form-confirm-btn-primary {
  color: #14bae3;
}
ssi-custom-widget .cw-content {
  flex: 1 1 auto;
}
ssi-custom-widget .cw-header {
  display: flex;
  height: 60px;
  background-color: #fff;
  border-bottom: 0.1em solid #838eab;
  padding: 1.2em;
  border-top-right-radius: 1.2em;
}
ssi-custom-widget .cw-header-form {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
}
ssi-custom-widget .cw-header-form-input {
  flex: 0 1 auto;
  display: flex;
  align-items: center;
}
ssi-custom-widget .cw-header-form-input input {
  flex: 1 1 auto;
  height: 100%;
  border-width: 0;
  font-size: 1.4em;
  color: #101525;
  font-weight: 900;
  text-rendering: geometricPrecision;
  outline: 0;
  margin: 0 0 0.2rem;
}
ssi-custom-widget .cw-header-form-input input::placeholder {
  color: #838eab;
  font-weight: 700;
}
ssi-custom-widget .cw-header-form-input.cw-header-form-input-name {
  min-width: 25em;
}
ssi-custom-widget .cw-header-form-input.cw-header-form-input-desc {
  flex: 1 0 auto;
}
ssi-custom-widget .cw-header-form-input-label {
  margin: 0;
}
ssi-custom-widget .cw-header-form-input-label i {
  font-size: 1.2em;
  color: #43537f;
  padding: 0 1.2rem;
}
ssi-custom-widget .cw-header-actions {
  flex: 0 0 auto;
  padding: 0 0.8em;
}
ssi-custom-widget .cw-header-action-button,
ssi-custom-widget .cw-side-action-button {
  display: inline-block;
  font-size: 1.4em;
  border: 0.1rem solid #838eab;
  color: #43537f;
  height: 3.6rem;
  padding: 0 1.5em;
  white-space: nowrap;
  border-radius: 1rem;
  font-weight: 900;
  text-rendering: geometricPrecision;
  background-color: #fff;
  margin: 0 0 0 0.2em;
}
ssi-custom-widget .cw-header-action-button.cw-header-action-button-primary, ssi-custom-widget .cw-header-action-button.cw-side-action-button-primary,
ssi-custom-widget .cw-side-action-button.cw-header-action-button-primary,
ssi-custom-widget .cw-side-action-button.cw-side-action-button-primary {
  background-color: #14bae3;
  color: #fff;
  border-color: #14bae3;
}
ssi-custom-widget .cw-header-action-button[disabled],
ssi-custom-widget .cw-side-action-button[disabled] {
  background-color: #f4f4fa;
  border-color: #f4f4fa;
  color: #838eab;
  cursor: not-allowed;
}
ssi-custom-widget .cw-body {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: calc(100% - 60px);
  margin: 0.9em 1em;
}
ssi-custom-widget .cw-chart-empty {
  width: 19em;
  align-self: center;
  margin: 4.7em 0 0;
}
ssi-custom-widget .cw-chart-empty .ssi {
  font-size: 4.4em;
  line-height: 1;
  color: #b4bbce;
  margin: 0 0 2.7rem;
}
ssi-custom-widget .cw-chart-empty p {
  color: #43537f;
  font-size: 1.4em;
  line-height: 2rem;
  font-weight: 700;
  text-rendering: geometricPrecision;
  margin: 0;
}
ssi-custom-widget .cw-chart-empty .cw-chart-empty-title {
  font-size: 2em;
  font-weight: 900;
  margin: 0 0 1rem;
}
ssi-custom-widget .cw-chart-container {
  height: 100%;
  width: 100%;
}
ssi-custom-widget .cw-chart-wrap {
  background-color: #fff;
  border: 0.1em solid #838eab;
  border-radius: 1em;
  padding: 3em 1em;
}
ssi-custom-widget .cw-chart-loading {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background-color: #f4f4fa;
  height: calc(100% - 2em);
  margin-top: -0.1em;
  width: 100%;
  border-bottom-left-radius: 1.2em;
}
@keyframes spin {
  from {
    transform: rotate3d(0, 0, 0, 0deg);
  }
  to {
    transform: rotate3d(0, 0, 1, 360deg);
  }
}
ssi-custom-widget .cw-chart-loading img {
  height: 4em;
  width: 4em;
  margin: 0 0 3em;
  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
ssi-custom-widget .cw-chart-loading p {
  color: #43537f;
  font-size: 1.4em;
  line-height: 2rem;
  font-weight: 700;
  text-rendering: geometricPrecision;
  margin: 0;
}