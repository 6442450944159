@import "./src/apps/angularjs/common/sass/global-variables.scss";
.modal-topic {
  .form-field {
    margin: 10px 0;

    input[type='checkbox'] + label {
      margin-right: 10px;
    }
  }

  .radio-group {
    margin-top: 5px;
  }
}
