@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-widget-chart-pie {
  font-size: 1rem;

  .insights-widget {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .insights-chart-pie {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .insights-chart-pie-total {
    font-size: 26px;
    font-weight: 900;
    color: #101525;
    text-rendering: geometricPrecision;
    margin: -7px 0 7px;
  }

  .insights-chart-pie-subtotal {
    font-size: 10px;
    font-weight: 900;
    letter-spacing: 1px;
    color: #838eab;
    text-transform: uppercase;
    text-rendering: geometricPrecision;
    margin: -5px 0 13px;
  }

  .insights-chart-pie-wrapper {
    height: calc(min(23em, 90%));
    width: calc(min(50em, 90%));
    margin-left: -6%;
  }
}
