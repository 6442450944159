@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-team-report {
  .team-report-container {
    width: 100%;
    height: 100%;

    $headerHeight: 94px;
    $sidebarWidth: 228px;

    .report-body {
      width: 100%;
      height: 100%;
      padding-top: $headerHeight;
      padding-left: $sidebarWidth + 40px;
      padding-right: 40px;
      min-height: calc(100vh - 68px);

      .section-container:first-child {
        padding-top: 40px;
      }

      .section-delimiter {
        width: 100%;
        height: 2px;
        background-color: #fff;
        margin: 30px 0;
      }

      .section-title {
        color: #101525;
        font-weight: 900;
        text-rendering: geometricPrecision;
        font-size: 20px;
        margin: 0 0 40px;
      }
    }

    .report-body-loading {
      width: 100%;
      height: 100%;
      height: calc(100vh - 68px);
      padding-top: $headerHeight;
      padding-left: $sidebarWidth;
    }

    .selected-date-ranges {
      border-radius: 10px;
      background-color: #ebebf4;
      padding: 10px;

      span {
        margin-right: 7px;
      }

      .current {
        color: $lmode-title-text;
        font-size: 14px;
        font-weight: 900;
      }
      .previous {
        color: $lmode-title-text;
        font-size: 12px;
        font-style: italic;
        font-weight: 700;
      }
    }

    .facts-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      grid-gap: 0 20px;
      margin: 0;

      @media print {
        display: block;

        & > * {
          display: inline-block;
          width: auto !important;
        }
      }

      & > * {
        width: 100%;

        .fact-overview {
          box-shadow: 0 0.5em 1em rgba(131, 142, 171, 0.35);
          border-radius: 12px;
          width: 100%;
          min-width: 170px;
          margin: 0 0 20px;
        }
      }
    }
  }

  ssi-treetable {
    font-size: 1rem;
    overflow: visible;

    .content {
      background-color: #fff;
      border-radius: 1.2em;
      box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
      padding: 3em 2.6em 4.6em;
      margin: 0 0 2em;
    }

    .content
      .content-inner
      .head
      .head-inner
      .row
      .cell
      .cell-inner-cover.active,
    .content .content-inner .head .head-inner .row .cell {
      background-color: #fff;
      border: 0;
    }

    .content
      .content-inner
      .head
      .head-inner
      .row
      .cell
      .cell-inner
      .col-header
      > span {
      overflow: visible;
      white-space: normal;
      letter-spacing: 0.1em;
      color: $lmode-body-text;
      text-transform: uppercase;
      text-rendering: geometricPrecision;
    }

    .content .content-inner .head .head-inner .row .cell .cell-inner-cover,
    .content .content-inner .head .head-inner .row .cell:hover {
      box-shadow: none;
    }

    .body {
      margin: 2em 0 0;
    }

    .body-inner {
      .row {
        position: relative;

        &:nth-child(2n) {
          background-color: $grey-background;

          &:before,
          &:after {
            content: '';
            position: absolute;
            top: 0;
            width: 100%;
            height: 0.2em;
            border-radius: 10em;
            background-color: #e0e7f4;
            z-index: 1;
          }

          &:after {
            top: auto;
            bottom: 0;
          }
        }
      }

      .cell {
        display: flex;
        align-items: center;

        p {
          color: $lmode-title-text;
          font-weight: 900;
          text-rendering: geometricPrecision;
          font-size: 1.4em;
          padding: 0 0.6em 0 1em;
          margin: 0;
        }
      }

      .body-cell-primary {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        text-rendering: geometricPrecision;
        width: 500px;

        i {
          display: inline-flex;
          align-items: center;
          color: $lmode-helper-text;
          margin: 0 1em 0 0.7em;

          &:before {
            font-size: 1.2em;
          }
        }

        p {
          padding: 0;
          display: inline-block;
          color: $lmode-body-text;
          font-size: 1.2em;
          font-weight: 900;
          margin: 0;
        }
      }
    }
  }
}
