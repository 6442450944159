body.modal-open {
  overflow: auto !important;
}

.notification-window {
  animation: wiggle 4s linear infinite, delay-animation 2000ms linear infinite;
}
.notification-window.notification-window-no-backdrop {
  pointer-events: none;
}
@keyframes wiggle {
  0%, 7% {
    transform: rotateX(0);
  }
  15% {
    transform: rotateX(-15deg);
  }
  20% {
    transform: rotateX(10deg);
  }
  25% {
    transform: rotateX(-10deg);
  }
  30% {
    transform: rotateX(6deg);
  }
  35% {
    transform: rotateX(-4deg);
  }
  40%, 100% {
    transform: rotateX(0);
  }
}

.notification-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  pointer-events: all;
  background-color: #101525;
}
.notification-container i {
  margin-right: 10px;
  color: #838eab;
}
.notification-container p {
  color: #ffffff;
  font-weight: bold;
  margin-right: auto;
  margin-bottom: 0;
}
.notification-container button {
  appearance: none;
  border: none;
  background: none;
  font-size: 10px;
  padding: 0;
  color: #838eab;
  margin-left: 20px;
}