ssi-live-chat-team {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
}
ssi-live-chat-team * {
  display: flex;
}
ssi-live-chat-team > ul {
  align-items: flex-start;
  flex-flow: row wrap;
  justify-content: flex-start;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  width: 650px;
}
ssi-live-chat-team > ul > li {
  flex-flow: column nowrap;
  justify-content: flex-start;
  margin: 20px 0;
  min-height: 160px;
  padding: 0;
  width: 130px;
}