@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-influencers-list {
  display: block;
  font-size: 1rem;
  width: 100%;

  .influencers-list {
    margin: 0 0 1em;
    padding: 0 0 0 1.4em;
    overflow: auto;
  }

  .influencers-list-labels {
    display: flex;
    width: 100%;
    margin: 0 0 1.5em;
    padding: 0;
  }

  .influencers-list-label {
    flex: 0 0 13em;
    display: block;

    &:first-child {
      flex: 1 0 auto;
      min-width: 35em;
      text-align: left;
    }

    &:last-child {
      flex: 0 0 14.4em;
    }

    &-center {
      text-align: center;
    }

    &-right {
      text-align: right;
    }

    p {
      display: inline-block;
      color: #43537f;
      font-weight: 900;
      font-size: 1em;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin: 0;
      text-rendering: geometricPrecision;
    }
  }

  .influencers-list-label-action {
    display: inline-block;
    margin: 0 0.4rem 0 0;
    font-size: 2em;
    line-height: 1rem;
    vertical-align: sub;
    background: none;
    border: none;
    padding: 0;
    color: #838eab;
    cursor: pointer;

    &-active {
      color: $primary-active;
    }
  }

  .influencers-list-data {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .influencers-list-data-item {
    display: flex;
    align-items: center;
    width: calc(100% + 1.4em);
    margin-left: -1.4em;
    padding: 0.7em 0 0.7em 1.4em;
    border-bottom: 0.1em solid #e2e2ee;

    &:last-child {
      border-bottom-width: 0;
    }
  }

  .influencers-list-data-col {
    flex: 0 0 13em;

    &:first-child {
      flex: 1 1 auto;
      min-width: 35em;
    }

    &:last-child {
      flex: 0 0 14.4em;
    }
  }

  .influencer-meta {
    overflow: hidden;
    margin: 0 0.4em 0 0;
  }

  .influencer-account {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }

  .influencer-badge {
    position: relative;
    display: inline-block;
    margin: 0.4em 0.8em 0 0;
    height: 2.1em;
    flex: 0 0 2.6em;
    width: 2.6em;
  }

  .influencer-badge-meta {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    margin: auto;
    color: #101525;
    font-size: 1em;
    line-height: 1.4em;
    font-weight: 900;
    text-rendering: geometricPrecision;
  }

  .influencer-badge-wreathe {
    &-gold {
      color: #f0b427;
    }
    &-silver {
      color: #98a7d1;
    }
    &-bronze {
      color: #f88c68;
    }
  }

  .influencer-account-avatar {
    display: inline-flex;
    flex: 0 0 3.6em;
    height: 3.6em;
    width: 3.6em;
    background: grey;
    border-radius: 50%;
    margin: 0 1em 0 0;
    overflow: hidden;
    align-content: center;

    img {
      width: 100%;
      min-height: 100%;
    }
  }

  .influencer-account-details {
    display: flex;
    flex: 0 1 auto;
  }

  .influencer-account-details-network {
    font-size: 1.2em;
    width: 1.2rem;
    margin: 0 1rem 0 0;
    flex: 0 1 auto;
  }

  .influencer-account-name {
    color: #101525;
    font-size: 1.4em;
    font-weight: 900;
    text-underline-offset: 0.4rem;
    margin: 0 0.3em 0 0;
    text-rendering: geometricPrecision;
    white-space: nowrap;
  }

  .influencer-account-alias {
    color: #000;
    font-size: 1.4em;
    font-weight: 400;
    margin: -0.1rem 0 0 0;
    white-space: nowrap;
  }

  .influencer-followers {
    p {
      font-size: 1.4em;
      color: #000;
      font-weight: 900;
      margin: 0;
      text-rendering: geometricPrecision;
    }
  }

  .influencer-button {
    background-color: $primary-active;
    border-width: 0;
    display: block;
    width: 100%;
    height: 2.6em;
    border-radius: 0.5em;

    span {
      font-size: 1.2em;
      font-weight: 900;
      color: #fff;
      text-rendering: geometricPrecision;
    }
  }

  .actionable {
    cursor: pointer;
  }
}
