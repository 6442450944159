ssi-message-link-preview {
  font-size: 1rem;
}
ssi-message-link-preview .message-link-preview {
  display: flex;
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
  background-color: #fcfdfe;
  overflow: hidden;
}
ssi-message-link-preview .message-link-preview.message-link-preview-self-penned {
  background-color: #d1dcf9;
}
ssi-message-link-preview .message-link-preview-thumb {
  flex: 0 0 10em;
  width: 10em;
  height: 10em;
  overflow: hidden;
  min-width: 5em;
  background-color: #838eab;
}
ssi-message-link-preview .message-link-preview-thumb img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
ssi-message-link-preview .message-link-preview-body {
  max-height: 9em;
  margin: 1em 2em;
  align-self: center;
  overflow: auto;
  max-width: 30em;
}
ssi-message-link-preview .message-link-preview-title {
  display: block;
  display: -webkit-box;
  font-weight: 900;
  text-decoration: underline;
  color: #101525;
  font-size: 1.2em;
  line-height: 1.6em;
  margin-bottom: 0.4em;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-rendering: geometricPrecision;
  overflow: hidden;
}
ssi-message-link-preview .message-link-preview-subtitle {
  display: inline-block;
  display: -webkit-box;
  color: #101525;
  font-weight: 600;
  font-size: 1.2em;
  text-rendering: geometricPrecision;
  margin: 0;
  -webkit-line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical;
  max-height: 1.4rem;
}