ssi-menu .menu-container {
  position: relative;
  display: inline-block;
}
ssi-menu .menu-container .menu-head {
  cursor: pointer;
}
ssi-menu .menu-container .menu-head .menu-trigger-icon {
  font-size: 14px;
  padding: 8px;
  border-radius: 50%;
}
ssi-menu .menu-container .menu-head .menu-trigger-icon:hover {
  background-color: #f4f4fa;
}
ssi-menu .menu-container .menu-body {
  position: absolute;
  z-index: 999;
  background-color: #fff;
  border-radius: 10px;
}