@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-crm-person-notes {
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin-left: 10px;
  // margin-right: 10px;
  margin-top: 10px;
  width: 100%;

  .close-button {
    width: 80%;
    max-width: 200px;
    position: relative;

    i {
      font-size: 10px;
      font-weight: 900;
      position: absolute;
      right: 10px;
    }
  }

  form {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .save-button {
      width: 80%;
      min-height: 30px;
      max-width: 200px;
      margin-top: 10px;
    }
  }
}
