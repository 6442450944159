.export-conversation {
  background-color: #3a3f49;
  color: white;
  padding: 30px 60px !important;
  border-radius: 25px;
}
.export-conversation.confirmation {
  text-align: center;
}
.export-conversation p {
  margin: 0 0 1em 0;
}
.export-conversation input[type=password],
.export-conversation input[type=text] {
  display: block;
  width: 300px;
  background: none;
  border: none;
  border-bottom: 2px solid #737481;
  padding: 5px;
}
.export-conversation label {
  margin: 0 0 0 10px;
  color: #27bae1;
}
.export-conversation h4 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}
.export-conversation .export-message {
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  display: block;
  float: right;
}
.export-conversation .export-message.invalid {
  color: #737481;
}
.export-conversation .export-message.valid {
  color: #27bae1;
}
.export-conversation .export-buttons {
  padding: 10px 0;
  text-align: center;
}
.export-conversation .export-buttons button {
  margin: 0 6px;
  border-radius: 16px;
  border: none;
}
.export-conversation .export-buttons .export-cancel {
  background-color: #4c525e;
  color: white;
}
.export-conversation .export-disclaimer {
  color: #737481;
  font-size: 12px;
  padding: 0 0 0 10px;
  border-left: 2px solid #27bae1;
  line-height: 1.5em;
  font-weight: 500;
}
.export-conversation .export-code-input {
  position: relative;
  display: inline-block;
  margin: 0 0 10px 0;
}
.export-conversation .export-input {
  margin: 0 0 10px 0;
}
.export-conversation .export-code-confirmation {
  border: 2px solid;
  display: inline-block;
  margin: 0 0 10px 0;
  padding: 5px;
  border-radius: 18px;
  border-color: #27bae1;
  font-size: 20px;
  padding: 2px 0;
  min-width: 104px;
}
.export-conversation .export-fireworks {
  font-size: 46px;
  margin-bottom: 16px;
}
.export-conversation .show-hide {
  position: absolute;
  right: 15px;
  top: 3px;
  font-size: 24px;
  line-height: 22px;
  vertical-align: middle;
  color: #27bae1;
  cursor: pointer;
}
.export-conversation .show-hide.ssi-eyeclosed {
  font-size: 12px;
  line-height: 30px;
}