@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../angularjs/common/sass/variables.scss';
@import '~/styles/colors';

$live-chat-away-status-colour: #a6b7c0;
$live-chat-offline-status-colour: #f39780;
$live-chat-online-status-colour: #91cac7;
$live-chat-online-text-colour: #49aee6;

$unit: 2.5px;

ssi-live-chat-network-state {
  align-items: center;
  color: $live-chat-away-status-colour;
  display: flex;
  flex-flow: column nowrap;
  flex-shrink: 0;
  font-size: 0.9em;
  position: relative;

  * {
    display: flex;
  }

  button {
    background: transparent;
    border: none;
    outline: revert;
    padding: 0;
    white-space: nowrap;

    &:focus {
      outline: revert !important;
    }

    &[disabled] {
      cursor: not-allowed;
      opacity: 0.65;
    }
  }

  ssi-live-chat-avatar {
    cursor: pointer;
    height: 60px;
    width: 60px;

    &.away,
    &.busy {
      .inner {
        & > img {
          border-color: $live-chat-away-status-colour;
        }

        & > .statistic {
          background-color: $live-chat-away-status-colour;
        }
      }
    }

    &.offline {
      .inner {
        & > img {
          border-color: $live-chat-offline-status-colour;
        }

        & > .statistic {
          background-color: $live-chat-offline-status-colour;
        }
      }
    }

    &.online {
      .inner {
        & > img {
          border-color: $live-chat-online-status-colour;
        }

        & > .statistic {
          background-color: $live-chat-online-status-colour;
        }
      }
    }

    .inner {
      & > img {
        border-style: solid;
        border-width: 4px;
      }

      & > .statistic {
        position: absolute;
        border-radius: 50%;
        color: #fff;
        padding: 6px 8px;
        font-style: italic;
        font-weight: 400;
        top: -6px;
        right: -6px;
        justify-content: center;
      }
    }
  }

  & > div {
    align-items: center;
    color: darken($live-chat-online-text-colour, 20%);
    flex-flow: column nowrap;

    & > .dropdown {
      position: inherit;
      width: 90px;

      &.show {
        & > * {
          display: inline;
        }
      }
    }

    & > .options {
      display: none;
    }

    & > button.status {
      color: #fff;
    }

    & > .status {
      align-items: center;
      border-radius: 20px;
      font-weight: 500;
      justify-content: space-around;
      padding: ($unit * 1) ($unit * 5);

      &.away,
      &.busy {
        background-color: darken($live-chat-away-status-colour, 25%);
      }

      &.offline {
        background-color: $live-chat-offline-status-colour;
        font-style: normal;
      }

      &.online {
        background-color: $live-chat-online-status-colour;
      }

      &.unstyled {
        background-color: transparent !important;
        margin: 0 !important;
      }

      .status-icon {
        font-size: 0.85em;
        padding-left: 5px;
      }
    }

    & > dl {
      margin: 0;
      padding: 0;

      dt,
      dd {
        font-weight: 400;
        margin: 0;
        padding: 0;
      }

      dd {
        padding-left: 5px;
      }
    }

    .dropdown-menu {
      border: none;
      border-radius: ($unit * 8);
      margin: 0;
      padding: 0;
      width: 100%;

      & > li {
        color: darken(#aabac2, 40%);
        padding: ($unit * 4);

        &:first-child {
          border-top-left-radius: ($unit * 8);
          border-top-right-radius: ($unit * 8);
        }

        &:last-child {
          border-bottom-left-radius: ($unit * 8);
          border-bottom-right-radius: ($unit * 8);
        }

        &:hover {
          background-color: $primary-active;
          color: $text-light;
        }

        & > button {
          span {
            font-style: italic;
          }

          strong {
            font-weight: bold;
            padding-left: ($unit * 1);
          }
        }
      }
    }

    & > .name {
      font-weight: 700;
      text-transform: capitalize;
      text-align: center;
    }

    & > .resolutions {
      margin-bottom: 5px;
    }

    & > .status {
      font-size: 1.1em;
      font-weight: 500;
      margin: 5px 0;
      padding: 2px 20px;
      text-transform: capitalize;

      &.offline {
        font-style: italic;
      }

      &.online {
        font-weight: 400;
      }
    }
  }

  .social-status {
    position: relative;
    display: flex;

    .status-menu {
      width: 90px;
    }

    label {
      margin-right: 5px;
      margin-top: 3px;
      color: $lmode-body-text;
      font-size: 14px;
      font-weight: 900;
    }

    ul {
      position: absolute;
      top: 0;
      z-index: 2;
      height: 25px;
      width: 90px;
      background-color: #fff;
      border-radius: 12px;
      overflow: hidden;
      list-style: none;
      padding: 0;
      color: #596065;

      flex-direction: column;

      &.active {
        display: block;
        height: auto;
        border-left: 1px solid #cacaca;
        border-right: 1px solid #cacaca;
        border-bottom: 1px solid #cacaca;
      }

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1 0 26px;
        height: 26px;
        padding: 0 10px;
        text-transform: capitalize;
        font-size: 12px;
        font-weight: 900;

        i .ssi {
          font-size: 10px;
          padding-top: 2px;
        }

        &:hover {
          color: $primary-active;
          cursor: pointer;
        }

        &.initial {
          color: #fff;
          font-weight: bold;
          line-height: 25px;
          flex: 1 0 25px;
        }

        &.offline {
          background-color: darken($dmode-body-text, 30%);
        }

        &.online {
          background-color: darken(#91d4e1, 37%);
        }

        &.busy {
          background-color: darken($secondary-red, 30%);
        }
      }
    }
  }
}
