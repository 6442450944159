@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-top-post-list {
  .top-post-list-container {
    .row {
      &.dropdown-select {
        margin-bottom: 40px;
      }

      .top-post-container {
        display: flex;
        align-items: center;

        .top-post {
          flex: 1 1 auto;
        }

        .top-post-stats {
          flex: 0 0 340px;
          display: flex;
          // align-items: center;
          justify-content: space-between;
          height: 174px;
          padding: 20px 30px;

          .top-post-stat-list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 0 10px;

            .stat {
              flex: 1 0 50%;
              width: 100px;
              padding: 5px 0;

              i {
                margin-right: 0.6em;
                font-size: 16px;

                &.ssi-eye {
                  font-size: 18px;
                }
                &.ssi-reach {
                  font-size: 18px;
                }
              }

              ssi-big-number {
                width: 45px;
                display: inline-block;
                font-size: 16px;
                color: $lmode-title-text;
                font-weight: 900;
              }
            }
          }

          .top-post-top-score {
            text-align: center;
            font-weight: 900;
            font-size: 14px;
            color: $lmode-title-text;
            position: relative;
            padding: 0 10px;
            #rank-0 {
              div {
                background-color: #fbf3e2;
              }
              i {
                color: #f0b427;
              }
            }
            #rank-1 {
              div {
                background-color: #eff2fd;
              }
              i {
                color: $dmode-body-text;
              }
            }
            #rank-2 {
              div {
                background-color: $primary-red;
              }
              i {
                color: $secondary-red;
              }
            }
            .rank {
              position: relative;
              margin: 0 0 2em 0;
              div {
                margin: 0 auto;
                width: 46px;
                height: 46px;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-content: center;
                flex-direction: column;
              }
              i {
                position: absolute;
                transform: translate(-50%, -50%);
                text-align: center;
                font-size: 4em;
                color: #eaedf4;
              }
            }
            .number {
              display: block;
              font-size: 20px;
              position: relative;
              z-index: 2;
              line-height: 1.4em;
            }
            .subtitle {
              text-transform: uppercase;
              font-size: 10px;
              position: relative;
              z-index: 2;
              color: $lmode-helper-text;
              letter-spacing: 1px;
              font-weight: 700;
            }
          }
        }
      }

      .delimiter {
        height: 2px;
        background-color: $lmode-border-color;
        border-radius: 10px;
        margin: 20px 15px;
      }
    }
  }
}
