@import './../../../../../angularjs/common/sass/variables';
@import './../../../../../angularjs/common/sass/mixins';
@import '~/styles/colors';

$inbox-activity-responsive-width: 885px;

$inbox-sidebar-item-bg-light-color: #545767;
$inbox-sidebar-item-bg-light-hover-color: lighten(
  $inbox-sidebar-item-bg-light-color,
  5%
);

$inbox-sidebar-item-bg-dark-color: #363434;
$inbox-sidebar-item-bg-dark-hover-color: darken(
  $inbox-sidebar-item-bg-dark-color,
  5%
);

$inbox-sidebar-item-bg-primary-color: $primary-active;
$inbox-sidebar-item-bg-primary-hover-color: lighten(
  $inbox-sidebar-item-bg-primary-color,
  5%
);

$inbox-sidebar-header-bg-color: #f8f8f8;

$inbox-sidebar-list-unselected-color: #838488;
$inbox-sidebar-list-selected-color: #fff;

$inbox-sidebar-bulk-assign-bg-color: #f0b427;
$inbox-sidebar-bulk-assign-bg-hover-color: darken(
  $inbox-sidebar-bulk-assign-bg-color,
  5%
);

$inbox-sidebar-bulk-deselect-bg-color: #f88c68;
$inbox-sidebar-bulk-deselect-bg-hover-color: darken(
  $inbox-sidebar-bulk-deselect-bg-color,
  5%
);

$inbox-sidebar-bulk-sentiment-bg-color: #d41d68;
$inbox-sidebar-bulk-sentiment-bg-hover-color: darken(
  $inbox-sidebar-bulk-sentiment-bg-color,
  5%
);

$inbox-sidebar-bulk-tag-bg-color: #668aea;
$inbox-sidebar-bulk-tag-bg-hover-color: darken(
  $inbox-sidebar-bulk-tag-bg-color,
  5%
);

$inbox-sidebar-item-padding-sides: 28px;
$inbox-sidebar-width: 160px;

// slim version variables
$inbox-sidebar-slim-width: 75px;
$inbox-sidebar-slim-item-height-xs: 32px;
$inbox-sidebar-slim-item-height-sm: 40px;
$inbox-sidebar-slim-item-height-md: 48px;
$inbox-sidebar-slim-item-height-lg: 56px;

$breakpoint-height-xxs: 500px;
$breakpoint-height-xs: 550px;
$breakpoint-height-sm: 700px;
$breakpoint-height-md: 740px;
$breakpoint-height-lg: 820px;
// end of slim version variables

ssi-inbox-sidebar {
  .wide-version,
  .slim-version {
    height: 100%;
  }

  .wide-version {
    display: flex;
  }

  .slim-version {
    display: none;
  }

  .wide-version {
    width: $inbox-sidebar-width;
    min-width: $inbox-sidebar-width;
    flex: 1;
    font-size: 12px;
    font-weight: normal;
    line-height: 14px;
    display: flex;
    flex-direction: column;

    .inbox-sidebar-results-count {
      @include align-vertical($page-heading-height);
      text-transform: uppercase;
      color: $text-dark;
      background-color: $inbox-sidebar-header-bg-color;
    }

    .inbox-sidebar-inner {
      display: flex;
      flex-direction: column;
      flex: 1;
      background-color: $bg-dark-secondary;
      a {
        font-size: 1em;
        font-weight: 600;
        span {
          font-size: 1em;
          font-weight: 600;
        }
      }
    }

    a {
      color: white;
    }

    a:hover,
    a:focus {
      text-decoration: none;
      color: white;
    }

    .inbox-sidebar-item.border-top {
      border-top: solid 1px $border-color-light;
    }

    .inbox-sidebar-item {
      padding: calc($inbox-sidebar-item-padding-sides / 2)
        $inbox-sidebar-item-padding-sides;

      i {
        font-size: 12px;
      }
    }

    .inbox-sidebar-messages {
      padding: 20px 10px;
      position: relative;

      &.margin-bottom {
        margin-bottom: 10px;
      }

      &-assigned {
        justify-content: flex-end;
      }

      &-filtered {
        border-right: solid 2px $inbox-sidebar-item-bg-light-color;
        justify-content: flex-end;
      }

      &-total {
        border-bottom: solid 2px $inbox-sidebar-item-bg-light-color;
        border-right: solid 2px $inbox-sidebar-item-bg-light-color;
        justify-content: flex-start;
      }

      &-unread {
        border-bottom: solid 2px $inbox-sidebar-item-bg-light-color;
        justify-content: flex-start;
      }

      ul {
        display: flex;
        flex-flow: wrap;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          align-items: center;
          display: flex;
          flex-direction: column;
          height: 55px;
          padding: 0;
          width: 50%;
          color: #838eab;

          &.active {
            .count {
              color: $primary-active;
            }

            .label {
              color: $text-light;
            }
          }

          .count {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 2px;
          }

          .label {
            color: inherit;
            font-size: 1em;
            font-weight: 600;
            padding: 0;
          }
        }
      }

      button {
        background: $inbox-sidebar-item-bg-light-color;
        border: solid 6px #3f4142;
        border-radius: 50%;
        color: $primary-active;
        left: 58px;
        outline: none;
        padding: 6px;
        position: absolute;
        top: 47px;

        .refresh-icon {
          font-size: 2.4em;
        }
      }
    }

    a.inbox-sidebar-item {
      background-color: $inbox-sidebar-item-bg-light-color;
      border-radius: $inbox-sidebar-item-padding-sides;
      margin-left: calc($inbox-sidebar-item-padding-sides / 2);
      margin-right: calc($inbox-sidebar-item-padding-sides / 2);
      padding: calc($inbox-sidebar-item-padding-sides / 4)
        calc($inbox-sidebar-item-padding-sides / 2);

      &.inbox-sidebar-item-bg-primary {
        background-color: $primary-active;

        &:hover {
          background-color: $inbox-sidebar-item-bg-primary-hover-color;
        }
      }

      &.inbox-sidebar-item-btn-list-filters {
        background-color: $primary-active;
        color: #fff;

        &-clear {
          background-color: #f88c68;
        }
      }

      &.inbox-sidebar-item-conversations-button {
        background-color: #545767;
        box-shadow: #303030 0 0 16px 0px;
        color: #838eab;

        &:hover {
          color: #fff;
        }

        &.active {
          & > .button-icon {
            color: $primary-active;
          }

          & > .button-text {
            color: #fff;
          }
        }
      }
    }

    .inbox-sidebar-filters-list,
    .inbox-sidebar-active-preset {
      margin: 0 10px;

      & > ul {
        list-style: none;
        margin: 0;
        padding: 0;

        & > li {
          align-items: center;
          background-color: #545767;
          border-radius: 20px;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          list-style: none;
          margin: 10px 0;

          .btn-edit {
            background-color: $primary-active;
            border-radius: 20px;
            padding: 5px;
            color: #fff;
          }

          .filter-label,
          .preset-label {
            color: #fff;
            overflow: hidden;
            padding: 0 5px;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 98px;
          }

          .btn-remove {
            color: #f0d9d1;
            margin-right: 8px;
            padding: 5px;

            .ssi,
            .fa {
              font-size: 14px;
            }
          }
        }
      }
    }

    .inbox-sidebar-active-preset {
      & > ul {
        & > li {
          background-color: $primary-active;

          .btn-remove {
            color: #fff;
          }
        }
      }
    }

    .inbox-sidebar-item-custom-presets-toggle {
      position: relative;
      top: 12px;
      z-index: 1;
    }

    .inbox-sidebar-custom-presets-list {
      background-color: #35353f;
      padding: 28px 0 20px 0;

      & > ul {
        margin: 0 20px;
        padding: 0;
        & > li {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          margin: 10px 0;
          padding: 0;
          font-size: 12px;
          color: #838eab;

          &.selected {
            .btn-edit,
            span {
              color: #fff;
            }

            .preset-label {
              .ssi,
              .fa {
                color: $primary-active;
              }
            }
          }

          .preset-label {
            align-items: center;
            display: flex;

            & > span {
              overflow: hidden;
              padding-left: 5px;
              padding-right: 5px;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 96px;
            }

            .ssi,
            .fa {
              margin-right: 2px;
            }
          }

          a {
            color: #838eab;
            i {
              color: #545767;
            }
          }
        }
      }
    }

    .inbox-sidebar-presets-list {
      margin-top: 30px;
      & > ul {
        margin: 20px;
        padding: 0;
        & > li {
          align-items: center;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          margin: 10px 0;
          padding: 0;
          color: #838eab;
          font-size: 12px;
          font-weight: 600;

          &.selected {
            color: #fff;
            .fa {
              color: $primary-active;
            }
          }

          .ssi,
          .fa {
            color: #545767;
            margin-right: 2px;
          }
        }
      }
    }

    $inbox-sidebar-item-spacing: 3px;

    .inbox-sidebar-item-spacing-top {
      margin-top: $inbox-sidebar-item-spacing;
    }

    .inbox-sidebar-item-spacing-bottom {
      margin-bottom: $inbox-sidebar-item-spacing;
    }

    .inbox-sidebar-item-bg-light {
      background-color: $inbox-sidebar-item-bg-light-color;
    }

    .inbox-sidebar-item-bg-dark {
      background-color: $inbox-sidebar-item-bg-dark-color;
    }

    .inbox-sidebar-item-bg-primary {
      background-color: $inbox-sidebar-item-bg-primary-color;
    }

    .inbox-sidebar-item-bg-light-hover:hover {
      background-color: $inbox-sidebar-item-bg-light-hover-color;
    }

    .inbox-sidebar-item-bg-dark-hover:hover {
      background-color: $inbox-sidebar-item-bg-dark-hover-color;
    }

    .inbox-sidebar-item-bg-primary-hover:hover {
      background-color: $inbox-sidebar-item-bg-primary-hover-color;
    }

    a.inbox-sidebar-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .inbox-sidebar-top {
      flex: 1;

      .bulk-actions-menu {
        display: flex;
        flex-flow: column nowrap;
        flex-grow: 1;
        list-style: none;
        margin: 20px 0 0 0;
        padding: 0;

        * {
          display: flex;
        }

        & > li {
          flex-shrink: 0;
          flex-grow: 1;
          margin: 5px 0;
          padding: 0 10px;

          & > * {
            flex-grow: 1;
          }

          & > button,
          & > dl > dt {
            align-items: center;
            border-radius: 20px;
            justify-content: space-between;
            color: #fff;
            font-weight: 600;
            padding: 5px 13px;
          }

          & > button {
            border: none;
          }

          & > dl {
            flex-flow: column-reverse nowrap;
            flex-grow: 1;
            margin: 0;
            padding: 0;

            & > dt {
              margin: 0;
            }

            & > dd {
              color: #fff;
              display: block;
              line-height: 1.8em;
              margin: 0;
              padding: 0 10px 12px 10px;

              & > * {
                display: inline;
              }
            }
          }
        }
      }
    }

    .inbox-sidebar-item-icon-left {
      padding-left: 5px;
    }

    .inbox-sidebar-item-filter-remove {
      position: relative;
      top: -1px;
    }

    .inbox-sidebar-item-filters li:not(:last-child) {
      margin-bottom: 10px;
    }

    .inbox-sidebar-item-padding-sides-sm {
      padding-left: 10px;
      padding-right: 8px;
    }

    .inbox-sidebar-item-text {
      display: inline-block;
      width: calc(100% - 40px);
      word-wrap: break-word;
    }

    .inbox-sidebar-item-active {
      background-color: $primary-active;
    }

    .inbox-sidebar-item-bulk-deselect {
      background-color: $inbox-sidebar-bulk-deselect-bg-color;

      &:hover {
        background-color: $inbox-sidebar-bulk-deselect-bg-hover-color;
      }

      span {
        flex-grow: 1;
      }
    }

    .inbox-sidebar-item-bulk-assign {
      background-color: $inbox-sidebar-bulk-assign-bg-color;
    }

    .inbox-sidebar-item-bulk-assign:hover {
      background-color: $inbox-sidebar-bulk-assign-bg-hover-color;
    }

    .inbox-sidebar-item-bulk-sentiment {
      background-color: $inbox-sidebar-bulk-sentiment-bg-color;
    }

    .inbox-sidebar-item-bulk-sentiment:hover {
      background-color: $inbox-sidebar-bulk-sentiment-bg-hover-color;
    }

    .inbox-sidebar-item-bulk-tag {
      background-color: $inbox-sidebar-bulk-tag-bg-color;
    }

    .inbox-sidebar-item-bulk-tag:hover {
      background-color: $inbox-sidebar-bulk-tag-bg-hover-color;
    }

    .inbox-sidebar-item-bulk-spam {
      background-color: #545767;
      &:hover {
        opacity: 0.8;
      }
    }

    .inbox-sidebar-item-bulk-tip {
      dt {
        background-color: #545767;
      }
      i {
        color: $primary-active;
      }
    }

    i.ssi-users-plus {
      font-size: 16px;
    }

    i.ssi-sentiment-menu-icon {
      font-size: 13px;
    }

    .inbox-sidebar-item-add-filter.active {
      background-color: $primary-active;
      color: #fff;
    }

    .inbox-sidebar-btn {
      display: block;
      background-color: #425dec !important;
      box-shadow: #303030 0 0 6px 0px;
    }

    .social-push-btn {
      margin-bottom: 20px;
      background-color: #94aef5 !important;
      &.exit {
        background-color: #f88c68 !important;
        i {
          transform: rotate(90deg);
        }
      }
    }
  }

  .slim-version {
    flex: 1;
    flex-direction: column;
    width: $inbox-sidebar-slim-width;
    min-width: $inbox-sidebar-slim-width;
    font-size: 9px;
    font-weight: normal;

    .inbox-sidebar-slim-header {
      @include align-vertical($page-heading-height);
      background-color: $inbox-sidebar-header-bg-color;
    }

    .inbox-sidebar-slim-body {
      display: flex;
      flex-direction: column;
      flex: 1;
      background-color: $bg-dark-secondary;

      .inbox-sidebar-slim-item {
        @include align-vertical($inbox-sidebar-slim-item-height-lg);
        color: white;
      }

      .inbox-sidebar-slim-item {
        &.useful-things {
          margin-bottom: 10px;
        }
      }

      @media only screen and (max-height: $breakpoint-height-lg) {
        .inbox-sidebar-slim-standard-tools,
        .inbox-sidebar-slim-tools {
          .inbox-sidebar-slim-item {
            @include align-vertical($inbox-sidebar-slim-item-height-md);
          }
        }
      }

      @media only screen and (max-height: $breakpoint-height-md) {
        .inbox-sidebar-slim-standard-tools,
        .inbox-sidebar-slim-tools {
          .inbox-sidebar-slim-item {
            @include align-vertical($inbox-sidebar-slim-item-height-sm);
            font-size: 16px;
          }
        }
      }

      @media only screen and (max-height: $breakpoint-height-xs) {
        .inbox-sidebar-slim-standard-tools,
        .inbox-sidebar-slim-tools {
          .inbox-sidebar-slim-item-icon {
            &:last-child {
              margin-bottom: 10px;
            }
            &.useful-things {
              display: none;
            }
          }
        }
      }

      @media only screen and (max-height: $breakpoint-height-xxs) {
        .inbox-sidebar-slim-top {
          flex: 0;
        }

        .inbox-sidebar-slim-standard-tools,
        .inbox-sidebar-slim-tools {
          .inbox-sidebar-slim-item-icon {
            @include align-vertical($inbox-sidebar-slim-item-height-xs);

            &:last-child {
              margin-bottom: 10px;
            }
            &.useful-things {
              display: none;
            }
          }
        }
      }

      .inbox-sidebar-slim-item-clickable {
        cursor: pointer;
      }

      // ------------------------

      .inbox-sidebar-slim-messages {
        $spacing-unit: 5px;
        margin: ($spacing-unit * 4) $spacing-unit $spacing-unit $spacing-unit;

        button,
        dl,
        dt,
        dd,
        ul,
        li,
        span {
          align-items: center;
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          margin: 0;
          padding: 0;
        }

        span {
          text-transform: capitalize;
          font-size: 1em;
          font-weight: 600;
        }

        ul {
          list-style: none;
        }

        .refresh-button-container {
          padding: $spacing-unit;
          margin: $spacing-unit;
        }

        .refresh-button {
          background-color: #545767;
          border: none;
          border-radius: 50%;
          color: $primary-active;
          font-size: 2.4em;
          font-style: normal;
          outline: none;
          padding: $spacing-unit;
        }

        .inbox-sidebar-slim-messages-total {
          color: $text-light;
          margin: $spacing-unit;
          padding: $spacing-unit;

          .total-label {
          }

          .total-value {
            font-size: 1.6em;
          }
        }

        .statistics-container {
          margin-top: $spacing-unit;
          color: #838eab;

          .active {
            .statistic-value {
              color: $primary-active;
            }
            .statistic-label {
              color: #fff;
            }
          }

          .statistic {
            margin: $spacing-unit 0;
          }

          .statistic-value {
            font-size: 1.6em;
          }

          @media only screen and (max-height: $breakpoint-height-sm) {
            display: none;
          }
        }
      }

      // ------------------------

      .inbox-sidebar-slim-bottom {
        overflow-y: auto;
        ul {
          list-style: none;
          margin: 0;
          padding: 0;

          & > * {
            margin: 0;
            padding: 0;
          }
        }

        .inbox-sidebar-slim-bulk-tools {
          & > .inbox-sidebar-slim-item {
            &:hover {
              color: $text-light;
            }

            &.bulk-tool-assign {
              .slim-item-icon {
                background-color: #9f2977;
              }
            }

            &.bulk-tool-count {
              flex-flow: column nowrap;
              height: auto;

              .count-label {
                font-size: 1em;
                margin-top: 5px;
              }

              .count-value {
                align-items: center;
                color: $primary-active;
                display: flex;
                font-size: 1.6em;
                font-weight: 500;
                justify-content: center;
              }
            }

            &.bulk-tool-deselect {
              font-size: 1.5em;
            }

            &.bulk-tool-sentiment {
              .slim-item-icon {
                background-color: #8ed0d8;
              }
            }

            &.bulk-tool-tag {
              .slim-item-icon {
                background-color: #6e9bb6;
              }
            }

            &.bulk-tool-tip {
            }

            .slim-item-icon,
            &.bulk-tool-count .count-value {
              background-color: #454748;
              border-radius: 50%;
              height: 48px;
              position: relative;
              width: 48px;

              &:before {
                align-content: center;
                align-items: center;
                display: flex;
                justify-content: center;
                height: 24px;
                left: 12px;
                margin: 0;
                padding: 0;
                position: absolute;
                top: 12px;
                width: 24px;
              }
            }
          }
        }

        &-standard-tools {
        }
      }
    }

    .inbox-sidebar-slim-top {
      flex: 1;
    }

    .inbox-sidebar-slim-item-inner,
    .inbox-sidebar-slim-item {
      width: 100%;
    }

    .inbox-sidebar-slim-item-inner {
      padding: $section-margin-width;
    }

    .inbox-sidebar-slim-item-icon {
      font-size: 20px;
    }

    .inbox-sidebar-slim-item-bg-light {
      background-color: $inbox-sidebar-item-bg-light-color;
    }

    .inbox-sidebar-slim-item-clickable.inbox-sidebar-slim-item-bg-light:hover {
      background-color: $inbox-sidebar-item-bg-light-hover-color;
    }

    .inbox-sidebar-slim-item-bg-dark {
      background-color: $inbox-sidebar-item-bg-dark-color;
    }

    .inbox-sidebar-slim-item-clickable.inbox-sidebar-slim-item-bg-dark:hover {
      background-color: $inbox-sidebar-item-bg-dark-hover-color;
    }

    .inbox-sidebar-slim-item-count {
      .count {
        font-size: 13px;
      }

      .count.active {
        color: $brand-primary;
      }
    }

    .inbox-sidebar-slim-item-icon-count {
      i {
        color: $primary-active;
      }

      .count {
        font-size: 17px;
        font-style: italic;
        position: relative;
        left: -3px;
        bottom: -2px;
      }
    }

    .inbox-sidebar-slim-item-icon-count.active {
      i {
        color: white;
      }
    }

    .inbox-sidebar-slim-item.active {
      color: $primary-active;
    }

    .inbox-sidebar-slim-item-bulk-assign {
      color: $inbox-sidebar-bulk-assign-bg-color;
    }

    .inbox-sidebar-slim-item-clickable.inbox-sidebar-slim-item-bulk-assign:hover {
      color: $inbox-sidebar-bulk-assign-bg-hover-color;
    }

    .inbox-sidebar-slim-item-bulk-sentiment {
      color: $inbox-sidebar-bulk-sentiment-bg-color;
    }

    .inbox-sidebar-slim-item-clickable.inbox-sidebar-slim-item-bulk-sentiment:hover {
      color: $inbox-sidebar-bulk-sentiment-bg-hover-color;
    }

    .inbox-sidebar-slim-item-bulk-tag {
      color: $inbox-sidebar-bulk-tag-bg-color;
    }

    .inbox-sidebar-slim-item-clickable.inbox-sidebar-slim-item-bulk-tag:hover {
      color: $inbox-sidebar-bulk-tag-bg-hover-color;
    }

    .social-push-btn {
      &.exit {
        i {
          font-size: 14px;
          padding: 6px 7px;
          background-color: #f88c68 !important;
          border-radius: 50%;
          transform: rotate(90deg);
        }
      }
    }
  }

  @media (min-width: $inbox-activity-responsive-width) {
    .wide-version {
      display: none;
    }

    .slim-version {
      display: flex;
    }
  }

  @media (min-width: $ssi-screen-width-sm) {
    .wide-version {
      display: flex;
    }

    .slim-version {
      display: none;
    }
  }

  @media (max-width: $inbox-activity-responsive-width) {
    .hidden-on-small-screens {
      display: none;
    }
  }

  .must-hide {
    display: none;
  }

  .must-show {
    display: flex;
  }

  @media (max-width: $inbox-activity-responsive-width) {
    .hidden-on-small-screens {
      display: none;
    }
  }
}
