ssi-icon-file {
  font-size: 1rem;
}
ssi-icon-file .icon-file {
  width: 4em;
  height: 4em;
}
ssi-icon-file .icon-file-meta {
  font-family: sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
ssi-icon-file .icon-file-background {
  fill: #E2574C;
}
ssi-icon-file .icon-file-lip {
  fill: #B53629;
}