@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import './../../../../angularjs/common/sass/variables.scss';
@import './../../../../angularjs/common/sass/mixins.scss';

$activity-separator-color: #e7eaec;
$activity-spacing-vertical: 40px;
$activity-body-color: $text-dark;
$activity-media-border-color: #fff;
$activity-media-border-width: 4px;
$activity-media-height: 200px;
$inbox-activity-thread-footer-content-height: 0px;
$inbox-activity-thread-footer-padding: 15px;
$inbox-activity-thread-footer-height: $inbox-activity-thread-footer-content-height +
  $inbox-activity-thread-footer-padding * 1;
$reply-box-sticky-bottom-padding: 18px;

ssi-activity {
  display: flex;
  flex-direction: column;
  margin: 0 15px;
  padding: 0;

  &.self-penned,
  &.self-penned.active {
    .activity-body {
      background-color: #fff;

      .media {
        .media-avatars {
          left: auto;
          right: -20px;
        }
      }
    }
  }

  .session-msg-remaining {
    display: flex;
    padding: 8px;
    background-color: #faeeeb;
    color: #e39e8a;
    border-radius: 10px;
  }

  .activity-text-read-more {
    color: $brand-primary;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 900;
    letter-spacing: 1px;
    cursor: pointer;

    &:hover,
    &:active {
      color: darken($brand-primary, 10%);
    }
  }

  .activity-media-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    list-style: none;
    margin: 20px 0 0 0;
    padding: 0;

    & > li {
      margin: 0;
      padding: 0;
    }
  }

  .activity-media {
    cursor: pointer;
    display: flex;
    position: relative;

    & + li {
      margin-left: -60px;
    }

    img {
      border-radius: 25px;
      width: 120px;
      height: 80px;
      object-fit: cover;
      border: solid 3px #dfe6e9;
    }

    .activity-media-icon {
      align-items: center;
      background-color: #fff;
      border-radius: 50%;
      bottom: -6px;
      color: $brand-primary;
      display: flex;
      font-size: 24px;
      justify-content: center;
      left: -22px;
      padding: 10px;
      position: absolute;
      text-align: center;
      width: auto;

      img {
        border-radius: 10px;
        height: 96px;
        width: auto;
      }
    }
  }

  .activity-split-conversation-btn {
    border: 0;
    background-color: #e1b4a6;
    width: $activity-avatar-img-dimensions;
    height: $activity-avatar-img-dimensions;
    border-radius: 50%;
    color: white;
    font-size: 20px;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
  }

  .activity-split-conversation {
    position: relative;
  }

  .activity-split-conversation:hover {
    .activity-split-conversation-btn {
      display: block;
    }

    .media-object {
      visibility: hidden;
    }
  }

  .media-object.border-info {
    border: solid 2px #5bc0de;
  }

  .media-object.border-warning {
    border: solid 2px $brand-warning;
  }

  .media-object.border-royal {
    border: solid 2px $brand-royal;
  }

  .activity-top {
    height: $activity-spacing-vertical;
  }

  .activity-body {
    background-color: #e3eaed;
    border-radius: 20px !important; // sigh
    color: $activity-body-color;

    button {
      background: none;
      border: none;
      padding: 0;
    }

    .media {
      margin: 0;
      padding: 15px 30px;
      position: relative;

      .media-avatars {
        left: -20px;
        position: absolute;
        top: 0;
      }
    }

    img.media-object {
      width: $activity-avatar-img-dimensions;
      height: $activity-avatar-img-dimensions;
    }

    .activity-author-name {
      font-weight: normal;
      font-size: 17px;
    }

    .activity-author-username {
      font-size: 14px;
    }

    .media-heading,
    .media-body {
      font-weight: normal;
    }

    .media-body {
      font-size: 14px;

      ssi-activity-text {
        line-height: 1.6em;
      }
    }

    .activity-created-at,
    .activity-response-time,
    .activity-deleted-by,
    .activity-visibility,
    .activity-outbox-author,
    .activity-deleted-by:before {
      color: lighten(black, 40%);
      padding-top: 10px;
      padding-bottom: 10px;
      font-style: italic;
      font-weight: 600;
    }

    .activity-deleted-by {
      color: $brand-danger;
    }

    .activity-deleted-by:before {
      content: '\00a0\00a0|\00a0\00a0';
    }

    .activity-links {
      display: flex;
    }

    .activity-links-right {
      flex: 1;
      text-align: right;
    }

    .activity-link {
      color: $brand-primary;
      font-size: 11px;
      font-weight: normal;
    }

    .activity-info {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
      list-style: none;
      margin: 10px 0 0 0;
      padding: 0;

      & > li {
        margin: 0;
        padding: 0;

        &:not(:first-child) {
          &:before {
            content: '|';
            padding: 0 0.2em;
          }
        }
      }
    }

    .activity-info-sm {
      font-size: 11px;
      font-weight: normal;
    }

    .activity-links-left .activity-link {
      margin-right: 20px;
    }

    .activity-links-right .activity-link {
      margin-left: 20px;
    }

    .activity-links-right {
      text-align: right;
    }

    .label {
      background-color: #dde4e8;
      color: lighten(black, 15%);
      font-weight: normal;
      text-transform: uppercase;
      font-size: 9px;
      padding: 7px;
    }

    .activity-outbox-stats {
      display: flex;
      font-size: 11px;
      justify-content: flex-end;
      margin-top: 12px;
      [class^='col-'] {
        text-align: left;
      }
    }
  }

  .activity-footer {
    display: flex;
    flex-flow: row nowrap;
    font-size: 0.85em;
    justify-content: flex-end;
    list-style: none;
    margin: 10px 0 0 0;
    padding: 0;
    white-space: nowrap;

    & > li {
      align-items: center;
      display: flex;
      font-size: 0.85em;
      font-style: italic;
      font-weight: 500;
      margin: 0 5px;
      padding: 3px 10px;
    }

    .activity-link,
    .activity-link > button {
      font-size: 0.85em;
    }

    .activity-link {
      background-color: #e3eaed;
      border: none;
      border-radius: 10px;
      color: $brand-primary;
      cursor: pointer;
      font-size: 0.85em;
      font-style: italic;
      padding: 3px 10px;

      &:hover,
      &.active {
        background-color: $brand-primary;
        color: $text-light;

        a,
        button {
          color: $text-light;
        }
      }

      &.delete-button-container {
        background-color: #e9856f;
        color: $text-light;

        &:hover {
          background-color: darken(#e9856f, 15%);
        }
      }

      & > button {
        background-color: transparent;
        border: none;
        padding: 0;
      }

      a {
        text-decoration: none;
      }

      .link-icon {
        padding-right: 0.25em;
      }

      .activity-notes-text-label {
        padding-right: 0.25em;
      }
    }
  }

  .notes-temp-trigger {
    background-color: #e3eaed;
    border: none;
    border-radius: 10px;
    color: $brand-primary;
    cursor: pointer;
    font-size: 0.85em;
    font-style: italic;
    padding: 3px 10px;
  }

  .activity-reply-users {
    color: #bacbd6;
    font-style: italic;

    .name {
      padding-left: 0.2em;
    }
  }

  .activity-reply {
    margin: 40px 0;
    position: relative;

    $textarea-actions-height: 27px;

    &.social-wall {
      margin: 0;
      padding-top: 10px;
      padding-left: 50px;
    }

    .activity-viewers {
      font-size: 28px !important;
      color: $brand-primary;
    }

    .activity-typers {
      font-style: italic;
      position: relative;
      top: -4px;
      left: 3px;
    }

    .activity-typers,
    .activity-viewers {
      color: $brand-primary;
    }

    $textarea-actions-color: $text-color-muted;

    .activity-action-buttons {
      margin: 10px 0;
      padding: 0 10px;

      .btn {
        font-size: 11px;
      }
    }

    .activity-action-buttons > .btn,
    .btn-group {
      margin-left: 5px;
    }

    .activity-action-buttons {
      button {
        border: none;
        border-radius: 30px;
        padding: 3px 12px;

        &.dm-reply-button {
          background-color: #7d90a0;
          color: $text-light;
        }
      }
    }

    &.activty-reply-sticky {
      position: absolute;
      bottom: 0;
      left: 0;
      width: calc(100% - #{$activity-separator-padding * 2});
      margin: 0 $activity-separator-padding;
      padding: 3px 0 $reply-box-sticky-bottom-padding;
      background-color: $inbox-activity-background-color;
      z-index: 1;

      // &:after {
      //   content: '';
      //   position: absolute;
      //   top: 20px;
      //   left: 0;
      //   display: block;
      //   height: calc(100% - 20px);
      //   width: calc(100% - #{$activity-separator-padding});
      //   background-color: $inbox-activity-background-color;
      //   z-index: -1;
      //   margin: auto;
      // }

      &.activty-reply-sticky-margin-conversation {
        // bottom: $inbox-activity-thread-footer-height;
      }
    }
  }

  .activity-reply.border-royal {
    $activity-reply-border: solid 3px lighten($brand-royal, 55%);

    textarea {
      border-top: $activity-reply-border;
    }

    textarea {
      border-left: $activity-reply-border;
      border-right: $activity-reply-border;
    }
  }

  img.media-object,
  .activity-avatar {
    border-radius: 50%;
    @include box-shadow-bottom();
  }

  /* stylelint-disable */
  @include responsiveWidth($ssi-screen-width-sm) {
    .media-body {
      padding-right: $activity-separator-padding !important;
    }
  }
  /* stylelint-enable */
}

ssi-activity.active {
  .activity-body {
    background-color: #d8e1e5;
    border: solid 2px $text-light;
  }
}

ssi-activity.activity-nested-thread {
  .activity-reply-body {
    margin-left: 0;
  }
}
