@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-analytics ssi-top-urls {
  font-size: 1rem;

  .section-tu-wrap {
    background: #fff;
    border-radius: 1.2em;
    box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
    padding: 5.8em 2.6em 2.8em;
  }

  .tu-list {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
  }

  .tu-list-head {
    flex-direction: row;
    justify-content: space-between;

    .tu-list-item {
      display: flex;
      padding: 0 3.5em 1.6em;
    }

    p {
      flex: 1 0 auto;
      display: inline-block;
      color: $lmode-body-text;
      font-weight: 900;
      text-rendering: geometricPrecision;
      letter-spacing: 0.1em;
      font-size: 1em;
      text-transform: uppercase;
      margin: 0;

      &:last-child {
        flex: 0 0 10em;
      }
    }
  }

  .tu-list-body {
    .tu-list-item {
      display: flex;
      align-items: center;
      padding: 0 1.9em;
    }

    p {
      flex: 1 1 auto;
      color: $lmode-title-text;
      margin: 0;
      font-weight: 700;
      text-rendering: geometricPrecision;
      padding-right: 1em;
      overflow: hidden;
      display: inline-block;
      text-align: left;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:first-child {
        flex: 0 0 3.2rem;
        font-weight: 900;
        padding-right: 0;
      }

      &:last-child {
        flex: 0 0 11.7rem;
        font-weight: 900;
        padding-right: 0;
      }
    }
  }

  .tu-list-item {
    display: inline-block;
    width: calc(50% - 36px);
  }

  .tu-list-body-item {
    height: 6.8em;
    border-top: 0.2rem solid #fff;
    border-bottom: 0.2rem solid #fff;

    &:nth-child(2n) {
      border-color: #e0e7f4;
      background-color: $grey-background;
    }
  }
}
