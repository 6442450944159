ssi-live-chat-conversation-statistics {
  display: flex;
  flex-basis: 72px;
  flex-grow: 1;
  height: 72px;
}
ssi-live-chat-conversation-statistics * {
  display: flex;
}
ssi-live-chat-conversation-statistics > div {
  flex-grow: 1;
}
ssi-live-chat-conversation-statistics > div .chat-statistics {
  flex-grow: 1;
  list-style: none;
  margin: 0;
  padding: 0;
}
ssi-live-chat-conversation-statistics > div .chat-statistics .widget-label {
  display: none;
}
ssi-live-chat-conversation-statistics > div .chat-statistics .unassigned .icon span.quantity-hide,
ssi-live-chat-conversation-statistics > div .chat-statistics .assigned .icon span.quantity-hide,
ssi-live-chat-conversation-statistics > div .chat-statistics .ongoing .icon span.quantity-hide {
  display: none !important;
}
ssi-live-chat-conversation-statistics > div .chat-statistics li {
  align-items: center;
  margin: 0;
  padding: 0;
}
ssi-live-chat-conversation-statistics > div .chat-statistics li.disabled {
  cursor: not-allowed;
  opacity: 0.65;
}
ssi-live-chat-conversation-statistics > div .chat-statistics li.empty {
  color: #43537f;
}
ssi-live-chat-conversation-statistics > div .chat-statistics li.empty .icon:after {
  background-color: #b4bbce;
}
ssi-live-chat-conversation-statistics > div.offline .chat-statistics span {
  color: #43537f;
}
ssi-live-chat-conversation-statistics > div.online .chat-statistics {
  color: #14bae3;
}
ssi-live-chat-conversation-statistics > div.online .chat-statistics .icon:after {
  background-color: #14bae3;
}