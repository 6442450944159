@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';
@import '~/styles/variables';

ssi-create-edit-competitor {
  $profile-grid-gutter: 5px;
  $header-height: 94px;

  .content-wrapper {
    padding: 60px 0 0;
    max-width: 1294px !important;

    .form-group {
      padding: 0;
    }
  }

  .heading-container {
    position: fixed;
    top: $navbar-height;
    width: 100%;
    height: $header-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $lgrey-background;
    padding: 24px 45px;
    box-shadow: 0 5px 10px rgba(131, 142, 171, 0.25);
    z-index: 1;

    orlo-button {
      margin: 0 0 0 20px;
      height: 46px;

      .ssi-line-arrow-small-down {
        transform: rotate(90deg);
        margin-right: 5px;
      }

      button {
        height: inherit;
      }
    }

    h1 {
      line-height: 1;
      margin: 10px 0;
      font-weight: 900;
      text-rendering: geometricPrecision;
    }
  }

  orlo-text-input {
    label {
      margin: 8px 0;
      padding: 0;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $lmode-body-text;
      font-size: 10px;
      font-weight: 900;
      text-rendering: geometricPrecision;
    }

    input {
      height: 46px;
      color: $lmode-title-text !important;
      background-color: #fff !important;
      font-size: 14px !important;
      text-rendering: geometricPrecision;
      color: $lmode-helper-text;

      &:not(:focus) {
        border-color: #fff !important;
      }

      &.invalid {
        border-color: $secondary-red !important;
      }

      &::placeholder {
        color: $lmode-helper-text;
        font-weight: 700 !important;
      }

      &:focus {
        font-weight: 600 !important;
      }
    }

    span {
      text-align: left !important;
    }
  }

  .content-form {
    position: relative;
    padding: 60px 5% 90px;
    margin: $header-height 15px 0;
    background-color: $lgrey-background;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    .delete {
      position: absolute;
      top: 20px;
      right: 20px;
      padding: 15px;
      color: $lmode-body-text;
      background-color: white;
      border-radius: 50px;
      cursor: pointer;
      font-size: 1.2em;
      line-height: 1;
    }
  }

  .input-container {
    position: relative;

    &:first-child {
      margin-right: 30px !important;
      flex: 1 1 40% !important;
      max-width: 400px;
    }

    &:last-child {
      flex: 1 1 50% !important;
    }

    label {
      margin: 8px 0;
    }
  }

  .input-counter {
    position: absolute;
    bottom: 0;
    right: 8px;
    color: $lmode-body-text;
    font-size: 10px;
    font-weight: 900;
    text-rendering: geometricPrecision;

    &.error {
      color: $secondary-red;
    }
  }

  .box-list {
    .box {
      width: auto;
      .channel {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .name-details {
        color: $primary-active;
        width: 100%;
        margin: 0 2em;
        min-width: 120px;
        label {
          text-align: center;
          max-width: 150px;
          margin: 0;
          font-weight: 900;
        }
        &.selected {
          margin: 0;
        }
      }

      .box-control {
        background-color: $primary-active;
        i {
          color: white;
        }
        &:hover {
          background-color: $primary-active;
        }
      }
      &.selected {
        label {
          text-align: left;
        }
      }
    }
  }

  .box-color {
    margin-left: 3em;
    padding: 15px 18px;
  }

  .search-box {
    border: 2px solid $primary-active;
    border-radius: 50px;
    width: 500px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .search-handle {
      border: none;
      background-color: white;
      margin: 0;
      padding: 17px 2em;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      font-weight: 500;
    }

    .search-button {
      padding: 19px 2em;
      background-color: $primary-active;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    ssi-spinner {
      padding: 30px 10px 0 0;
    }
    .accounts-dropdown {
      width: 400px;
      position: absolute;
      top: 80px;
      background-color: white;
      border-radius: 25px;
      padding: 2em 2em 0 1em;
      box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.35);
      z-index: 99;
      .accounts-list {
        height: 300px;
        padding: 2em 2em;
        overflow: hidden;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          appearance: none;
          width: 7px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: rgba(0, 0, 0, 0.5);
          box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }
        hr {
          border-top: 2px solid $lmgrey-background;
        }
        .media {
          font-size: 0.9em;
        }
        .profile {
          width: 22px;
        }
        .profile-info {
          overflow: hidden;
          text-overflow: ellipsis;
          width: 240px;
        }
        .text-muted {
          color: $lmode-body-text;
        }
        .plus {
          height: 32px;
          padding: 0px 10px;
          i {
            font-size: 0.8em;
            font-weight: 900;
          }
        }
      }
    }
  }
}
