@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';
@import '../../../../angularjs/common/sass/variables';

$height-animation-duration: 200ms;

$reply-box-responsive-width: 885px;

.reply-box {
  position: relative;
  background-color: #fff;
  border-radius: 12px;
  padding: $reply-box-padding;
  border: 2px solid transparent;
  display: flex;
  height: $reply-box-height-compressed;
  max-height: calc(
    100vh - #{$navbar-height + $reply-box-sticky-bottom-padding}
  );
  transition: height $height-animation-duration ease,
    border 100ms ease $height-animation-duration;

  .single-reply-warning {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background-color: $primary-red;
    border-radius: 10px;
    border: 1px solid $secondary-red;
    i {
      color: $secondary-red;
      margin-right: 7px;
    }
    p {
      color: $lmode-body-text;
      font-size: 12px;
      margin: 0;
    }
  }

  &.unfinished {
    border: 2px solid $primary-active;
    box-shadow: 0 5px 10px rgba(131, 142, 171, 0.35);
  }
  &.inactive {
    box-sizing: border-box;
    &:after {
      content: '';
      display: block;
      position: absolute;
      height: calc(100% + 4px);
      width: calc(100% + 4px);
      top: -2px;
      left: -2px;
      border: 1px solid #a3b8ee;
      pointer-events: none;
      border-radius: 12px;
    }
  }
  &.invalid {
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    align-items: center;
    border-radius: 0;
    border-top: 2px solid #eff1f4;
    height: $reply-box-height-hidden;
    // overflow: hidden;

    .session-invalid-actions {
      display: flex;
      align-items: center;
      button {
        margin: 0 5px;
        background-color: #fff;
        color: $primary-active;
      }
    }

    .session-invalid-message {
      display: flex;
      align-items: center;
      padding: 8px 13px;
      background-color: $dmode-background;
      color: #fff;
      font-size: 12px;
      font-weight: bold;
      border-radius: 10px;
      i {
        color: $lmode-helper-text;
        font-size: 18px;
        margin-right: 10px;
      }
    }

    .validity-message {
      a {
        color: $primary-active;
        text-decoration: underline;
      }
    }
  }
  button {
    border: none;
    border-radius: 50px;
    background-color: #f3f5fa;
    color: $primary-active;
  }
  .resolved {
    font-size: 12px;
    color: $lmode-helper-text;
    font-weight: 700;
    i {
      padding-right: 0.5em;
    }
  }
  .reply-box-above {
    position: absolute;
    left: 0;
    top: -45px;
    display: flex;
    width: 100%;
    pointer-events: none;
    > * {
      pointer-events: auto;
      margin-right: 0.8rem;
    }
    .viewing {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #cd5cdf;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      cursor: default;
      position: relative;

      &-conversation {
        background-color: #516cff;
      }

      i {
        font-size: 20px;
        color: #fff;
      }
      .count-badge {
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background-color: #9a03b3;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -2px;
        right: -2px;
        span {
          color: #fff;
          font-size: 10px;
          font-weight: 900;
        }
      }
    }
    .typing {
      display: flex;
      align-items: center;
      height: 40px;
      border-radius: 22px;
      background-color: #6377ad;
      cursor: default;
      .avatar {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        margin-left: 2px;
        margin-right: 1rem;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
        i {
          font-size: 16px;
          color: $lmode-helper-text;
        }
      }
      .text {
        margin-right: 0.5rem;
        span {
          color: #fff;
          font-size: 12px;
          font-style: italic;
          font-weight: 700;
        }
      }
      .dots {
        height: 20px;
        margin-right: 1.5rem;
      }
    }
    .countdown {
      &.end-right {
        margin-left: auto;
        margin-right: 2rem;
      }
    }
  }
  .collision-tooltip-content {
    padding: 2.5rem;
    .title {
      color: #fff;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 5px;
      margin-right: 50px;
    }
    .content {
      color: $dmode-body-text;
      font-size: 14px;
      font-weight: 500;
      &.alone {
        font-weight: 900;
      }
    }
    .icon-container {
      position: absolute;
      top: 10px;
      right: 10px;
      height: 26px;
      width: 26px;
      border-radius: 50%;
      background-color: rgba(138, 149, 176, 0.17);
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        color: #8a95b0;
        font-size: 16px;
      }
    }
  }
  .session-tooltip-content {
    padding: 2.5rem;
    .title {
      color: #fff;
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-bottom: 20px;
    }
    .content {
      color: $dmode-body-text;
      font-size: 14px;
      font-weight: 500;
    }
    .icon-container {
      position: absolute;
      top: 10px;
      right: 10px;
      height: 26px;
      width: 26px;
      border-radius: 50%;
      background-color: rgba(138, 149, 176, 0.17);
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        color: #8a95b0;
        font-size: 16px;
      }
    }
  }
  .textarea-emoji-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    .popover {
      border-radius: 25px;
      input {
        border-radius: 15px;
      }
    }
  }
  &.active {
    flex-direction: column;
    height: $reply-box-height-active;
    box-shadow: 0 5px 10px rgba(131, 142, 171, 0.35);
    .textarea-emoji-container {
      flex-direction: column;
    }
    textarea,
    .prediction-container {
      padding: 0 25px;
      margin-top: 20px;
      line-height: 24px;
    }
    textarea {
      transition: height 0.3s;
      &::placeholder {
        text-indent: 3px;
      }
    }
    .prediction-container {
      .prediction:after {
        margin-top: 0;
      }
    }
  }
  &.expanded {
    height: $reply-box-height-expanded;
    box-shadow: 0 5px 10px rgba(131, 142, 171, 0.35);
    textarea {
      height: 100%;
    }
  }
  textarea {
    color: $lmode-title-text;
    background-color: transparent;
    caret-color: $primary-active;
    border: 0 !important;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    resize: none;
    z-index: 1;
    flex: 1 1 auto;
    &::placeholder {
      color: $lmode-helper-text;
      font-weight: 700;
      text-rendering: geometricPrecision;
    }
  }
  .prediction-container {
    display: inline-flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    color: $lmode-helper-text;
    z-index: 0;
    .prediction {
      &:after {
        position: absolute;
        content: 'TAB';
        margin-top: 3px;
        margin-left: 7px;
        border-radius: 5px;
        color: $primary-active;
        border-radius: 5px;
        background-color: #f3f5fa;
        height: 24px;
        padding: 2px;
        width: 40px;
        font-size: 10px;
        text-align: center;
        letter-spacing: 1px;
        font-weight: 600;
        line-height: 20px;
      }
    }
  }
  textarea,
  .prediction-container {
    margin: $reply-box-textarea-vertical-margin 0;
    padding: 0 20px;
    line-height: 30px;
    font-size: 14px;
    font-weight: 500;
    height: 30px;
    white-space: pre-wrap;
    word-spacing: 0;
    word-wrap: break-word;
  }

  .reply-top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    height: $reply-box-top-height;
    margin: 5px 10px 25px;

    &.reply-top-hidden {
      visibility: hidden;
    }

    &.reply-top-desktop {
      flex-wrap: nowrap;
      margin: 15px 25px;

      .reply-top-left {
        flex: 0 1 auto;
        justify-content: flex-start;
        margin-bottom: 0;

        .reply-from {
          ssi-dropdown-select-2 {
            flex: 0 1 auto;
          }
        }
      }

      .reply-top-right {
        flex: 0 1 auto;
        justify-content: flex-start;

        .reply-to {
          flex: 0 1 auto;
          margin-left: 15px;

          ssi-dropdown-select-2 {
            flex: 0 1 auto;
          }
        }

        .expand-icon-container,
        .keyboard-shortcuts-container {
          display: block;
          margin-left: 15px;
        }
      }
    }

    .reply-top-left {
      flex: 1 0 100%;
      display: flex;
      margin-bottom: 10px;

      .reply-from {
        flex: 1 0 100%;
        justify-content: space-between;
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 900;
        color: $lmode-helper-text;

        > span {
          margin-right: 5px;
        }

        ssi-dropdown-select-2 {
          flex: 1 0 auto;
          max-width: 350px;

          button.dd-head {
            width: 100%;
          }
        }
      }
    }

    .reply-top-right {
      flex: 1 0 100%;
      display: flex;
      align-items: center;

      // > * {
      //   margin-left: 15px;
      // }

      .reply-to {
        flex: 1 0 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        font-weight: 900;
        color: $lmode-helper-text;

        > span {
          margin-right: 5px;
        }

        ssi-dropdown-select-2 {
          flex: 1 0 auto;
          max-width: 350px;

          button.dd-head {
            width: 100%;
          }
        }
      }

      .translate-reply {
        background-color: $grey-background;
        outline: none;
        height: 30px;
        padding: 6px 10px;
        font-weight: 900;
        font-size: 12px;
        &:disabled,
        &[disabled] {
          color: $lmode-helper-text;
        }
        span {
          margin: 0 3px;
        }
      }

      .keyboard-shortcuts-container {
        display: none;
        position: relative;

        .keyboard-icon {
          font-size: 18px;
          color: $lmode-helper-text;
          &:hover {
            cursor: pointer;
          }
          &.keyboard-icon-absolute {
            position: absolute;
            top: 0;
            left: 0;
          }
        }
        .keyboard-shortcuts-list {
          border-radius: 15px;
          border: 0;
          padding-bottom: 20px;
          bottom: auto;
          .shortcuts-heading {
            margin: 10px 10px 10px 0;
            padding: 5px 10px 10px 0;
            text-align: right;
            color: $lmode-helper-text;
            font-size: 10px;
            font-weight: bold;
            letter-spacing: 1px;
            text-transform: uppercase;
          }
          li {
            &:hover {
              background: none;
              cursor: auto;
            }
          }
          // overriding ngDropdown styles
          .dropdown-list-item {
            display: flex;
            justify-content: space-between;
            color: $lmode-body-text;
            font-size: 12px;
            font-weight: bold;
            .character-key {
              color: $lmode-helper-text;
              font-size: 11px;
              &:hover {
                cursor: auto;
              }
            }
          }
        }
        .character-key {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          text-transform: uppercase;
          background-color: #eef0f6;
          font-size: 14px;
          padding: 1px;
          border-radius: 5px;
          width: 23px;
          height: 23px;
          margin-left: 20px;
          &:hover {
            cursor: pointer;
          }
        }
      }
      .expand-icon-container {
        display: none;
        height: 18px;

        ssi-icon {
          font-size: 18px;
          color: $lmode-helper-text;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
  .attachments-bar {
    display: flex;
    margin: 0 15px;
  }

  .reply-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 1 auto;
    flex-wrap: wrap;

    .actions-right {
      display: flex;
      align-items: center;
      background-color: transparent;
      border: none;
      margin: 5px 0 0 auto;
      flex-flow: row nowrap;
      list-style: none;

      li,
      a {
        margin-left: 10px;
        &.send-reply {
          background-color: #eef0f6;
          border-radius: 50%;
          display: flex;
          width: 40px;
          height: 40px;
          @media (max-width: $reply-box-responsive-width) {
            width: 50px;
            height: 50px;
          }
          justify-content: center;
          align-items: center;
          i {
            color: $lmode-helper-text;
          }
          button {
            font-size: 20px;
            @media (max-width: $reply-box-responsive-width) {
              display: flex;
              justify-content: center;
              align-items: center;
              align-self: center;
            }
          }
        }

        &.active {
          color: $primary-active;
          font-weight: bold;
        }
      }

      .media-icon {
        width: 16px;
        height: auto;
        font-size: 16px;
      }
    }

    .actions-right {
      .chars-remaining {
        color: $primary-active;
        font-size: 12px;
        font-weight: 900;

        &.limit-exceeded {
          color: $secondary-red;
        }
      }

      padding: 0;
      button {
        border-radius: 50px;
        background-color: $primary-active;
        color: #fff;
        height: 40px;
        width: 40px;
        &:disabled,
        &[disabled] {
          background-color: #eef0f6;
          color: $lmode-helper-text;
        }
      }
      i {
        font-size: 20px;
        color: $lmode-helper-text;
        &.has-attachments {
          color: $primary-active;
          position: relative;
        }
        .attachments-count {
          position: absolute;
          top: -8px;
          right: -8px;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: $primary-active;
          color: #fff;
          font-size: 10px;
          font-weight: 900;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      i:hover {
        color: $primary-active;
        cursor: pointer;
      }
    }
    .actions-left {
      display: flex;
      align-items: center;

      > :not(:last-child) {
        margin-right: 10px;
      }

      button {
        background-color: #f2f4fc;
        color: $primary-active;

        &.dm-reply-link-added-button {
          i {
            font-size: 10px;
            margin-right: 0;
            margin-left: 6px;
          }
        }

        // &:hover {
        //   background-color: $primary-active;
        //   color: #fff;
        // }
      }

      .tags-input {
        position: relative;
        margin-left: 15px;
        font-size: 1rem;
        input {
          border: 0;
          border-bottom: 1px solid $dmode-body-text;
          color: $dmode-body-text;
          font-size: 12px;
          padding: 5px;
        }
        .overlay {
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: transparent;
          z-index: 40;
          overflow-y: auto;
          backface-visibility: hidden;
          pointer-events: auto;
        }
        .tags-menu {
          position: absolute;
          left: -15px;
          bottom: -5px;
          min-height: 160px;
          z-index: 50;
          display: inline-block;
          background: #fff;
          padding: 2.5em;
          border-radius: 2.5em;
          box-shadow: 0 0.1em 1.5em 0 rgba(231, 232, 238, 0.65);
          padding: 2.5em;
          margin: 0;
        }
      }
    }
  }

  &.thin-border {
    border: 1px solid #c3c9d8;
    box-shadow: none;
  }

  &.no-border {
    border: none;
    box-shadow: none;
  }

  .save-edit-reply {
    border-top: 1px solid #838eab;
    padding: 10px 10px 0;
    margin: 10px -10px 0 -10px;
    z-index: 2;

    .buttons-container {
      display: flex;
      justify-content: flex-end;
    }

    button {
      width: fit-content;
      background-color: #f3f4fc;
      color: $lmode-helper-text;
      font-size: 14px;
      font-weight: 900;
      padding: 12px 20px;
      border-radius: 10px;

      &:first-child {
        background-color: #fff;
        color: rgb(67, 83, 127);
        border: 1px solid $lmode-helper-text;
        margin-right: 5px;
      }

      &:last-child {
        color: #fff;
        background-color: $primary-active;

        &[disabled] {
          color: $lmode-helper-text;
          background-color: #f4f4fa;
          cursor: not-allowed;
        }
      }
    }
  }
}
