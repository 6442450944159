ssi-total-stat-card {
  font-size: 1rem;
}
ssi-total-stat-card .tsc {
  position: relative;
  display: inline-flex;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0 0.5em 1em rgba(131, 142, 171, 0.35);
  border-radius: 1.2em;
  width: 100%;
  min-width: 26.8em;
  margin: 0 0 2em;
}
@media print {
  ssi-total-stat-card .tsc {
    border: 1px solid black;
  }
}
ssi-total-stat-card .tsc-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 26.8em;
  text-align: center;
}
ssi-total-stat-card .tsc-icon-wrap {
  display: flex;
  align-items: flex-end;
  height: 6em;
  margin: 0 auto 1.5em;
}
ssi-total-stat-card .tsc-icon-wrap.tsc-icon-wrap-growth-label {
  margin: -2em auto 1.5em;
}
ssi-total-stat-card .tsc-icon-wrap.tsc-icon-wrap-background {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 5.6em;
  width: 5.6em;
  line-height: 1;
  border-radius: 50%;
  margin-bottom: 0.9em;
}
ssi-total-stat-card .tsc-icon {
  font-size: 3.6em;
  color: #838eab;
}
ssi-total-stat-card .tsc-label {
  letter-spacing: 0.1em;
  line-height: 1.2em;
}
ssi-total-stat-card .tsc-label p {
  color: #838eab;
  font-size: 1em;
  font-weight: 900;
  margin: 0 0 0.3em;
  text-transform: uppercase;
  text-rendering: geometricPrecision;
}
ssi-total-stat-card .tsc-value:before {
  content: "";
  display: block;
  margin: 0.7em auto;
  width: 4em;
  background-color: #d6dff7;
  height: 0.4em;
  border-radius: 10em;
}
ssi-total-stat-card .tsc-value p {
  color: #101525;
  font-weight: 900;
  margin: 0;
  font-size: 2em;
  text-rendering: geometricPrecision;
}
ssi-total-stat-card .tsc-growth-label-wrap {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15em;
  height: 3.6em;
  bottom: 0;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  background-color: #fff1ed;
  color: #f88c68;
}
ssi-total-stat-card .tsc-growth-label-wrap.tsc-growth-label-positive {
  background-color: #eaf9f8;
  color: #12aca4;
}
ssi-total-stat-card .tsc-growth-label-wrap p,
ssi-total-stat-card .tsc-growth-label-wrap i {
  display: inline-block;
  margin: 0 0.3em 0 0;
  text-rendering: geometricPrecision;
}
ssi-total-stat-card .tsc-growth-label-wrap p:last-child,
ssi-total-stat-card .tsc-growth-label-wrap i:last-child {
  margin: 0;
}
ssi-total-stat-card .tsc-growth-label-icon {
  font-size: 1.2em;
}
ssi-total-stat-card .tsc-growth-label {
  font-size: 1.4em;
  font-weight: 900;
}