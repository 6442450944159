ssi-date-time-picker label {
  color: #43537f;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-left: 30px;
  margin-bottom: 15px;
  display: inline-block;
  max-width: 100%;
}
ssi-date-time-picker input,
ssi-date-time-picker select {
  font-family: "Lato";
  border: 1px solid #c3c9d8;
  border-radius: 10px;
  padding: 14px;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  color: #6a759a;
  appearance: none;
  height: 46px;
}
ssi-date-time-picker input.active,
ssi-date-time-picker select.active {
  font-size: 14px;
  font-weight: 900;
  color: #101525;
}
ssi-date-time-picker input:focus-within,
ssi-date-time-picker select:focus-within {
  outline: none;
}
ssi-date-time-picker input::placeholder,
ssi-date-time-picker select::placeholder {
  color: #6a759a;
}
ssi-date-time-picker input:disabled,
ssi-date-time-picker select:disabled {
  border-color: #c3c9d8;
  opacity: 1;
  color: #6a759a;
}
ssi-date-time-picker input {
  background-color: #fff;
  background-size: 14px;
  padding: 16px 30px;
}
ssi-date-time-picker input:hover {
  cursor: pointer;
}
ssi-date-time-picker input::placeholder {
  color: #6a759a;
}
ssi-date-time-picker input:disabled {
  color: #6a759a;
}
ssi-date-time-picker select {
  cursor: pointer;
  background-color: #fff;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMzAuMDIxIDMwLjAyMSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzAuMDIxIDMwLjAyMTsiIHhtbDpzcGFjZT0icHJlc2VydmUiIGZpbGw9IiMxNEJBRTMiPgo8Zz4KCTxwYXRoIGQ9Ik0yOC42MTEsMTMuMzg1bC0xMS4wMTEsOS4zNTJjLTAuNzQ1LDAuNjMzLTEuNjY3LDAuOTQ5LTIuNTg5LDAuOTQ5Yy0wLjkyMSwwLTEuODQyLTAuMzE2LTIuNTg5LTAuOTQ5TDEuNDExLDEzLjM4NSAgIGMtMS42ODQtMS40My0xLjg5LTMuOTU0LTAuNDYtNS42MzhjMS40MzEtMS42ODQsMy45NTUtMS44OSw1LjYzOS0wLjQ1OWw4LjQyMSw3LjE1MWw4LjQyLTcuMTUxICAgYzEuNjg2LTEuNDMsNC4yMDktMS4yMjQsNS42MzksMC40NTlDMzAuNSw5LjQzMSwzMC4yOTQsMTEuOTU1LDI4LjYxMSwxMy4zODV6Ii8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+");
  background-repeat: no-repeat;
  background-position-x: 65px;
  background-position-y: 17px;
  background-size: 12px;
  padding: 15px 44px 16px 30px;
}
ssi-date-time-picker select:disabled {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMzAuMDIxIDMwLjAyMSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzAuMDIxIDMwLjAyMTsiIHhtbDpzcGFjZT0icHJlc2VydmUiIGZpbGw9IiNCNEJCQ0UiPgo8Zz4KCTxwYXRoIGQ9Ik0yOC42MTEsMTMuMzg1bC0xMS4wMTEsOS4zNTJjLTAuNzQ1LDAuNjMzLTEuNjY3LDAuOTQ5LTIuNTg5LDAuOTQ5Yy0wLjkyMSwwLTEuODQyLTAuMzE2LTIuNTg5LTAuOTQ5TDEuNDExLDEzLjM4NSAgIGMtMS42ODQtMS40My0xLjg5LTMuOTU0LTAuNDYtNS42MzhjMS40MzEtMS42ODQsMy45NTUtMS44OSw1LjYzOS0wLjQ1OWw4LjQyMSw3LjE1MWw4LjQyLTcuMTUxICAgYzEuNjg2LTEuNDMsNC4yMDktMS4yMjQsNS42MzksMC40NTlDMzAuNSw5LjQzMSwzMC4yOTQsMTEuOTU1LDI4LjYxMSwxMy4zODV6Ii8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+");
}
ssi-date-time-picker .meridian-toggle {
  display: flex;
  margin-left: 5px;
  border-radius: 10px;
}
ssi-date-time-picker .meridian-toggle button {
  appearance: none;
  outline: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  padding: 15px 13px;
  color: #6a759a;
  border: 1px solid #c3c9d8;
  background-color: #fff;
}
ssi-date-time-picker .meridian-toggle button:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-right: none;
}
ssi-date-time-picker .meridian-toggle button:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-left: none;
}
ssi-date-time-picker .meridian-toggle button.active {
  background-color: #14bae3;
  color: #fff;
}
ssi-date-time-picker .meridian-toggle button.active:disabled {
  background-color: #b4bbce;
}
ssi-date-time-picker .meridian-toggle button:not(.active) {
  cursor: pointer;
}
ssi-date-time-picker .meridian-toggle button:disabled {
  cursor: not-allowed;
}
ssi-date-time-picker .date-container,
ssi-date-time-picker .time-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  justify-items: flex-start;
}
ssi-date-time-picker .date-container {
  position: relative;
  width: 100%;
}
ssi-date-time-picker .date-container .calendar-icon {
  font-size: 16px;
  color: #14bae3;
  position: absolute;
  bottom: 15px;
  right: 15px;
}
ssi-date-time-picker .time-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
ssi-date-time-picker .time-container .select-container {
  display: flex;
  align-items: center;
}
ssi-date-time-picker .time-container .semicolon {
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: center;
  margin: 0 3px;
  color: #838eab;
}

.date-time-picker-wrap {
  display: flex;
}
.date-time-picker-wrap > div:not(:only-child) {
  margin-right: 7px;
}

.date-picker-header {
  padding: 40px 0 30px 0;
}
.date-picker-header h3 {
  margin: 0;
  font-size: 10px;
  line-height: 1;
  color: #43537f;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-rendering: geometricPrecision;
}

.date-picker-outer {
  padding: 0 34px 20px 34px;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid rgba(131, 142, 171, 0.35);
  border-radius: 15px;
  width: 340px;
}

.date-picker-inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-height: 300px;
}
.date-picker-inner .cal-month-view {
  font-size: 12px;
  color: #43537f;
  display: block;
  margin-bottom: 27px;
  font-weight: 700;
  text-rendering: geometricPrecision;
}
.date-picker-inner .cal-month-view .cal-header {
  color: #43537f;
  margin-bottom: 7px;
  font-weight: inherit;
}
.date-picker-inner .cal-month-view .cal-header:hover, .date-picker-inner .cal-month-view .cal-header:focus {
  background-color: transparent;
}
.date-picker-inner .cal-month-view .cal-header .cal-cell:hover, .date-picker-inner .cal-month-view .cal-header .cal-cell:focus {
  background-color: transparent;
}
.date-picker-inner .cal-month-view .cal-days {
  border: none;
}
.date-picker-inner .cal-month-view .cal-days .cal-day-cell,
.date-picker-inner .cal-month-view .cal-days .cal-cell-top {
  background-color: transparent;
  border: 0;
  min-height: initial;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .date-picker-inner .cal-month-view .cal-days .cal-day-cell,
.date-picker-inner .cal-month-view .cal-days .cal-cell-top {
    min-height: 35px;
  }
}
.date-picker-inner .cal-month-view .cal-days .cal-day-cell .cal-day-number,
.date-picker-inner .cal-month-view .cal-days .cal-day-number {
  display: block;
  float: none;
  text-align: center;
  margin: 5px auto;
  padding: 3px 0px;
  max-width: 26px;
  font-size: 12px;
  line-height: 18px;
  color: inherit;
  opacity: 1;
  font-weight: inherit;
  border-radius: 5px;
  border: 1px solid transparent;
  cursor: pointer;
  text-rendering: geometricPrecision;
}
.date-picker-inner .cal-month-view .cal-days .cal-cell {
  font-weight: 700;
  margin: 1px;
}
.date-picker-inner .cal-month-view .cal-days .cal-cell:first-child {
  margin-left: 0;
}
.date-picker-inner .cal-month-view .cal-days .cal-cell:last-child {
  margin-right: 0;
}
.date-picker-inner .cal-month-view .cal-days .cal-cell.cal-future:hover .cal-day-number, .date-picker-inner .cal-month-view .cal-days .cal-cell.cal-future:focus .cal-day-number {
  background-color: #eceef7;
}
.date-picker-inner .cal-month-view .cal-days .cal-cell.cal-today .cal-day-number {
  border: 1px solid #43537f;
  color: #43537f;
}
.date-picker-inner .cal-month-view .cal-days .cal-cell.cal-weekend .cal-day-number {
  color: #43537f;
}
.date-picker-inner .cal-month-view .cal-days .cal-cell.disabled .cal-day-number,
.date-picker-inner .cal-month-view .cal-days .cal-cell.cal-out-month .cal-day-number {
  opacity: 1;
  color: #b4bbce;
}
.date-picker-inner .cal-month-view .cal-days .cal-cell.disabled:hover .cal-day-number, .date-picker-inner .cal-month-view .cal-days .cal-cell.disabled:focus .cal-day-number,
.date-picker-inner .cal-month-view .cal-days .cal-cell.cal-out-month:hover .cal-day-number,
.date-picker-inner .cal-month-view .cal-days .cal-cell.cal-out-month:focus .cal-day-number {
  background-color: #f6f7fd;
}
.date-picker-inner .cal-month-view .cal-days .cal-cell.scheduled .cal-day-number {
  background-color: #8ed500;
  color: #fff;
}
.date-picker-inner .cal-month-view .cal-days .cal-cell.scheduled:hover .cal-day-number, .date-picker-inner .cal-month-view .cal-days .cal-cell.scheduled:focus .cal-day-number {
  background-color: #8ed500;
}
.date-picker-inner .cal-month-view .cal-days .cal-cell.active .cal-day-number {
  background-color: #14bae3;
  color: #fff;
}
.date-picker-inner .cal-month-view .cal-days .cal-cell.active:hover .cal-day-number, .date-picker-inner .cal-month-view .cal-days .cal-cell.active:focus .cal-day-number {
  background-color: #14bae3;
}
.date-picker-inner .cal-month-view .cal-days.cal-cell {
  border: 0;
}
.date-picker-inner .cal-month-view .cal-days.cal-cell:hover, .date-picker-inner .cal-month-view .cal-days.cal-cell:focus {
  background-color: transparent;
}
.date-picker-inner .cal-month-view .cal-days .cal-cell-row {
  border: none;
}
.date-picker-inner .cal-month-view .cal-days .cal-cell-row:hover {
  background-color: transparent;
}
.date-picker-inner .cal-month-view .cal-days .cal-cell-row .cal-cell:hover {
  background-color: transparent;
}

.date-picker-title-wrap {
  max-height: 28px;
  margin: 0 6px 8px;
}

.date-picker-title-nav button {
  border: 0;
  background-color: transparent;
}
.date-picker-title-nav button .point-right {
  transform: rotate(180deg);
}
.date-picker-title-nav i {
  font-size: 12px;
  color: #14bae3;
  line-height: 28px;
}

.date-picker-title {
  text-align: center;
}
.date-picker-title p {
  font-size: 14px;
  font-weight: 900;
  color: #43537f;
  margin: 0;
  line-height: 28px;
  text-rendering: geometricPrecision;
}