ssi-engagement ssi-top-engagers {
  font-size: 1rem;
}
ssi-engagement ssi-top-engagers .verified {
  display: inline-block;
  color: #1DA1F2;
  font-size: 16px;
  margin: 0 0 0 2px;
}
ssi-engagement ssi-top-engagers .text {
  font-size: 1.4em;
  font-weight: 900;
  color: #14bae3;
  margin: 0 0.1em;
}
ssi-engagement ssi-top-engagers .number {
  font-size: 1.4em;
  font-weight: 900;
  color: #101525;
}
ssi-engagement ssi-top-engagers .number.big {
  font-size: 2.8em;
  margin-left: auto;
}
ssi-engagement ssi-top-engagers .number span {
  font-size: 0.5em;
  margin-left: 0.3em;
  text-transform: lowercase;
}
ssi-engagement ssi-top-engagers .network-icon {
  color: #14bae3;
  font-size: 1.5em;
  margin: 0 0.8em;
}
ssi-engagement ssi-top-engagers .top-engagers-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
}
@media print {
  ssi-engagement ssi-top-engagers .top-engagers-container {
    display: block;
  }
}
ssi-engagement ssi-top-engagers .inbound-count,
ssi-engagement ssi-top-engagers .inbound-percentage {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 5px 20px 0 rgba(131, 142, 171, 0.2);
  padding: 3em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1 calc(50% - 2em);
}
@media print {
  ssi-engagement ssi-top-engagers .inbound-count,
ssi-engagement ssi-top-engagers .inbound-percentage {
    display: block;
    margin: 10px 0 0;
    border: 1px solid black;
    page-break-inside: avoid;
  }
}
ssi-engagement ssi-top-engagers .inbound-count h3,
ssi-engagement ssi-top-engagers .inbound-percentage h3 {
  color: #101525;
  font-size: 2.8em;
  margin-bottom: 0;
  font-weight: 900;
}
ssi-engagement ssi-top-engagers .inbound-count h4,
ssi-engagement ssi-top-engagers .inbound-percentage h4 {
  width: 100%;
  color: #838eab;
  font-size: 1.4em;
  line-height: 1.5em;
}
ssi-engagement ssi-top-engagers .inbound-count ssi-medal-score,
ssi-engagement ssi-top-engagers .inbound-percentage ssi-medal-score {
  height: 8em;
  margin-right: 2em;
}
ssi-engagement ssi-top-engagers .inbound-percentage i {
  font-size: 4.6em;
  color: #838eab;
  margin-left: 1em;
}
ssi-engagement ssi-top-engagers ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
ssi-engagement ssi-top-engagers ul li {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}
ssi-engagement ssi-top-engagers ul li > span {
  margin-left: 2em;
}
ssi-engagement ssi-top-engagers ul li > span:last-child {
  margin-left: 3em;
}
ssi-engagement ssi-top-engagers ul li i,
ssi-engagement ssi-top-engagers ul li span {
  color: #14bae3;
}
ssi-engagement ssi-top-engagers .avatar-img {
  width: 3.6em;
  height: auto;
  align-self: center;
  margin-left: 1em;
  border-radius: 50%;
}
ssi-engagement ssi-top-engagers > span {
  margin-left: 2em;
}
ssi-engagement ssi-top-engagers .top-engagers-tables {
  display: flex;
  align-items: flex-start;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 5px 20px 0 rgba(131, 142, 171, 0.2);
  margin-top: 2em;
  padding: 5em 3em;
  flex-wrap: wrap;
}
ssi-engagement ssi-top-engagers .top-engagers-tables table {
  width: calc(50% - 3em);
}
ssi-engagement ssi-top-engagers .top-engagers-tables table:first-child {
  margin-right: 3em;
}
ssi-engagement ssi-top-engagers .top-engagers-tables table:last-child {
  margin-left: 3em;
}
@media (max-width: 1199px) {
  ssi-engagement ssi-top-engagers .top-engagers-tables table:last-child {
    margin: 2em 3em 0 0;
  }
}
ssi-engagement ssi-top-engagers .top-engagers-tables table th {
  color: #43537f;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 900;
  font-size: 1em;
  white-space: nowrap;
}
ssi-engagement ssi-top-engagers .top-engagers-tables table tr {
  border-bottom: 2px solid #e0e7f4;
}
ssi-engagement ssi-top-engagers .top-engagers-tables table tr td:not(:first-child) {
  padding: 1.5em 0;
}
ssi-engagement ssi-top-engagers .top-engagers-tables table tr td:first-child {
  padding-left: 1em;
}
ssi-engagement ssi-top-engagers .top-engagers-tables table tr:last-child {
  border-bottom: none;
}
ssi-engagement ssi-top-engagers .top-engagers-tables table tbody::before {
  content: "-";
  display: block;
  line-height: 2em;
  color: transparent;
}
@media (max-width: 1199px) {
  ssi-engagement ssi-top-engagers .top-engagers-tables table {
    width: 100%;
  }
}