@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../library/sass/live-chat/colours.scss';
@import '../../../../../angularjs/common/sass/variables';
@import '~/styles/variables';

ssi-live-chat-assign-chat {
  background-color: #edf1f3;
  display: flex;
  flex-direction: column;
  min-height: 0;
  height: 100%;

  & > .live-chat-assign-chat-header {
    align-items: center;
    background-color: #d8e1e5;
    color: white;
    display: flex;
    flex-flow: row nowrap;
    flex-shrink: 0;
    font-size: 12px;
    height: 72px;
    justify-content: space-between;
    list-style: none;
    padding: 10px;

    & * {
      align-items: center;
      display: flex;
    }

    & > li {
      color: #fff;
      font-weight: 400;
      margin: 0 10px;
      padding: 10px;

      & > button {
        align-items: baseline;
        background-color: #fff;
        border: none;
        border-radius: 24px;
        color: #a6b7c0;
        font-style: italic;
        justify-content: space-between;
        padding: 8px 12px;

        & > .button-icon {
          margin-right: 8px;
        }

        & > .button-text {
          font-weight: 600;
        }
      }

      &.live-chat-assign-chat-header-title {
        i {
          font-size: 40px;
        }

        span {
          font-size: 18px;
        }
      }
    }
  }
}
