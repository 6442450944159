ssi-insights-reports-home {
  font-size: 1rem;
}
ssi-insights-reports-home .insights-page {
  position: relative;
  background-color: #fff;
  min-height: calc(100vh - 68px);
  overflow-x: hidden;
}
ssi-insights-reports-home .insights-container {
  max-width: 140em;
  padding: 0 2em;
  margin: 4em auto;
}
ssi-insights-reports-home .insights-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto 2.7em;
}
ssi-insights-reports-home .insights-row > * {
  flex: 1 0 100%;
}
ssi-insights-reports-home .insights-column {
  flex: 1 0 auto;
}
ssi-insights-reports-home .insights-column-third {
  flex: 1 0 33.3333333333%;
}
ssi-insights-reports-home .insights-column-two-thirds {
  flex: 1 0 66.6666666667%;
}
ssi-insights-reports-home .insights-reports-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1em 2.4em;
}
ssi-insights-reports-home .insights-reports-grid-item {
  flex: 1 0 0%;
  width: 100%;
  margin: 0 1em;
}
@supports (display: grid) {
  ssi-insights-reports-home .insights-reports-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25.7em, 1fr));
    grid-gap: 2.7em;
    margin: 0;
  }
  ssi-insights-reports-home .insights-reports-grid .insights-reports-grid-item {
    margin: 0;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  ssi-insights-reports-home .insights-reports-grid {
    display: flex;
    margin: 0 -1em 2.4em;
  }
  ssi-insights-reports-home .insights-reports-grid .insights-reports-grid-item {
    margin: 0 1em;
  }
}
ssi-insights-reports-home .insights-reports-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 4em 0 3em;
  height: 45.6em;
  color: #43537f;
  text-align: center;
  border-radius: 1.2em;
  border: 0.1em solid #d1dbf4;
  background-color: #f8f9fd;
}
ssi-insights-reports-home .insights-reports-empty h3,
ssi-insights-reports-home .insights-reports-empty p {
  max-width: 21rem;
  text-rendering: geometricPrecision;
}
ssi-insights-reports-home .insights-reports-empty h3 {
  font-size: 2em;
  margin: 0 0 0.6rem;
  font-weight: 900;
}
ssi-insights-reports-home .insights-reports-empty p {
  line-height: 2rem;
  margin: 0 0 2rem;
  font-weight: 700;
}
ssi-insights-reports-home .insights-reports-empty button {
  background-color: #14bae3;
  border-radius: 1em;
  padding: 0.8em 1.9em;
  border-width: 0;
  color: #fff;
}
ssi-insights-reports-home .insights-reports-empty button .ssi {
  font-size: 1.2em;
  margin: 0 0.7rem 0 0;
  vertical-align: text-bottom;
}
ssi-insights-reports-home .insights-reports-empty button span {
  font-size: 1.4em;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-insights-reports-home .insights-page-break {
  width: 200vw;
  margin: 4em -50vw;
  border-color: #d1dbf4;
}
ssi-insights-reports-home .insights-reports-banner {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 4.6em 5.5em 5em;
  background-color: #101525;
  color: #fff;
  border-radius: 1.2em;
}
ssi-insights-reports-home .insights-reports-banner-content {
  flex: 1 1 auto;
  max-width: 61em;
}
ssi-insights-reports-home .insights-reports-banner-hero {
  display: none;
  flex: 0 0 30.8em;
  width: 36.5em;
  height: 14.7em;
  margin: 0 0 -5em 1em;
}
@media (min-width: 992px) {
  ssi-insights-reports-home .insights-reports-banner-hero {
    display: block;
  }
}
ssi-insights-reports-home .insights-reports-banner h2 {
  font-size: 2em;
  margin-bottom: 1.4rem;
}
ssi-insights-reports-home .insights-reports-banner p {
  line-height: 2rem;
  margin: 0;
  font-weight: 700;
  text-rendering: geometricPrecision;
}
ssi-insights-reports-home .insights-report-card {
  cursor: pointer;
}
ssi-insights-reports-home .insights-report-card img {
  width: 100%;
  border-radius: 1.2em;
  margin: 0 0 2em;
}
ssi-insights-reports-home .insights-report-card-meta {
  max-width: 97%;
  margin: 0 0 0 auto;
}
ssi-insights-reports-home .insights-report-card-meta p {
  color: #43537f;
  line-height: 2rem;
  font-weight: 700;
  text-rendering: geometricPrecision;
}
ssi-insights-reports-home .insights-report-card-title {
  color: #101525;
  font-size: 2em;
  font-weight: 900;
  text-rendering: geometricPrecision;
  margin: 0 0 0.6rem;
}
ssi-insights-reports-home .insights-reports-controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0.6em 0 2em;
}
ssi-insights-reports-home .insights-reports-controls > * {
  flex: 0 0 auto;
  margin: 0 0 1.6em;
}
ssi-insights-reports-home .insights-reports-controls-filter .ssi {
  color: #43537f;
  font-size: 1.2em;
  margin: 0 0.6rem 0 0;
}
ssi-insights-reports-home .insights-reports-controls-filter input {
  color: #43537f;
  font-size: 1.4em;
  font-weight: 700;
  text-rendering: geometricPrecision;
  border-width: 0;
  outline: 0;
}
ssi-insights-reports-home .insights-reports-controls-filter input::placeholder {
  color: #838eab;
}
ssi-insights-reports-home .insights-reports-controls-type {
  text-align: center;
}
ssi-insights-reports-home .insights-reports-controls-type ul {
  margin: 0 0 0.4em;
  padding: 0;
}
ssi-insights-reports-home .insights-reports-controls-type li {
  position: relative;
  display: inline-block;
  color: #43537f;
  font-size: 1em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 900;
  text-rendering: geometricPrecision;
  padding: 0.3em 1em 0.8em;
  margin: 0 0.6em;
  cursor: pointer;
}
ssi-insights-reports-home .insights-reports-controls-type li.insights-reports-controls-type-active:after {
  content: "";
  position: absolute;
  bottom: -0.4em;
  left: 0;
  right: 0;
  margin: auto;
  display: inline-block;
  height: 0.4em;
  width: 5em;
  border-radius: 10em;
  background-color: #14bae3;
}
ssi-insights-reports-home .insights-reports-controls-sort {
  margin-top: 0.4em;
  text-align: right;
  color: #43537f;
  font-size: 1em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 900;
  text-rendering: geometricPrecision;
  cursor: pointer;
}
ssi-insights-reports-home .insights-reports-controls-sort i {
  font-size: 1.4em;
}
ssi-insights-reports-home .insights-reports-controls ssi-dropdown-select-2 {
  min-width: 160px;
}
ssi-insights-reports-home .insights-reports-tile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0.1em solid #d1dbf4;
  background-color: #f8f9fd;
  border-radius: 1.2em;
  height: 18em;
}
ssi-insights-reports-home .insights-reports-tile-actionable {
  cursor: pointer;
}
ssi-insights-reports-home .insights-reports-tile-actionable:hover, ssi-insights-reports-home .insights-reports-tile-actionable:focus {
  border-color: #14bae3;
}
ssi-insights-reports-home .insights-reports-tile h3 {
  font-size: 1.4em;
  text-align: center;
  margin: 0.6rem 0 2.2rem;
  color: #43537f;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-insights-reports-home .insights-reports-tile p {
  font-size: 1.4em;
  margin: -1.2em 0 2em;
  color: #43537f;
  font-weight: 700;
  text-rendering: geometricPrecision;
}
ssi-insights-reports-home .insights-reports-tile button {
  color: #43537f;
  background-color: #fff;
  border-width: 0;
  border-radius: 1rem;
  font-size: 1.4em;
  font-weight: 900;
  text-rendering: geometricPrecision;
  line-height: 1;
  padding: 1.1rem 2.1rem;
}
ssi-insights-reports-home .insights-reports-tile button:hover, ssi-insights-reports-home .insights-reports-tile button:focus {
  background-color: #14bae3;
  color: #fff;
}
ssi-insights-reports-home .insights-reports-tile button i {
  font-size: 1.2rem;
  margin: 0 0.5rem 0 0;
}
ssi-insights-reports-home .insights-reports-tile .insights-reports-tile-add {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: -0.6rem 0 0;
  height: 5rem;
  width: 5rem;
  padding: 0;
  color: #14bae3;
  border-radius: 50%;
}
ssi-insights-reports-home .insights-reports-tile .insights-reports-tile-add i {
  font-size: 1.2rem;
  margin: 0;
  color: inherit;
}
ssi-insights-reports-home ssi-paginator {
  margin: 4em 0 0;
}
ssi-insights-reports-home ssi-no-results {
  font-size: 1rem;
}
ssi-insights-reports-home ssi-no-results .ssi-no-results {
  font-size: 4rem;
}
ssi-insights-reports-home ssi-no-results .no-results {
  padding: 10rem;
}
ssi-insights-reports-home ssi-no-results .no-results .title {
  text-rendering: geometricPrecision;
}
ssi-insights-reports-home ssi-no-results .no-results .desc {
  width: 22.5rem;
  color: #43537f;
  line-height: 2rem;
  text-rendering: geometricPrecision;
}