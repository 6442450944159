ssi-rss-feed-article:nth-child(even) {
  margin-left: 10px;
}
ssi-rss-feed-article .feed-logo {
  max-height: 20px;
  vertical-align: text-top;
}
ssi-rss-feed-article .post-article {
  font-family: "Lato", "Arial", sans-serif;
  font-weight: 900;
  border: 1px solid #c3c9d8;
  border-radius: 10px;
}
ssi-rss-feed-article .post-article .post-heading {
  padding: 20px;
  border-bottom: 1px solid #c3c9d8;
}
ssi-rss-feed-article .post-article .post-heading .post-url {
  font-size: 16px;
  line-height: 14px;
  color: #14bae3;
}
ssi-rss-feed-article .post-article .post-heading .post-warning {
  font-size: 12px;
  line-height: 12px;
}
ssi-rss-feed-article .post-article .post-heading .post-warning span {
  background: rgba(214, 34, 34, 0.1);
}
ssi-rss-feed-article .post-article .post-heading .post-warning span i.ssi-warning-style-02 {
  color: #ffffff;
  background-color: #d62222;
  margin-right: 5px;
  padding: 3px;
}
ssi-rss-feed-article .post-article .post-heading .post-date {
  padding-top: 5px;
  font-size: 10px;
  line-height: 8px;
  color: #43537f;
}
ssi-rss-feed-article .post-article .post-body {
  padding: 20px;
}
ssi-rss-feed-article .post-article .post-body .post-content h3.post-title {
  font-size: 14px;
  line-height: 14px;
  color: #101525;
}
ssi-rss-feed-article .post-article .post-body .post-content p.post-description {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
}
ssi-rss-feed-article .post-article .post-body .post-content span.read-more-link {
  cursor: pointer;
  color: #14bae3;
  font-weight: 900;
  white-space: nowrap;
}
ssi-rss-feed-article .post-article .post-body .post-content span.read-more-link:hover {
  text-decoration: underline;
}
ssi-rss-feed-article .post-article .post-body .post-image img {
  width: 100%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}
ssi-rss-feed-article .post-article .post-footer {
  padding: 20px;
  border-top: 1px solid #c3c9d8;
}
ssi-rss-feed-article .post-article .post-footer a {
  font-size: 14px;
  color: #43537f;
}
ssi-rss-feed-article .post-article .post-footer a .ssi {
  padding-right: 5px;
}
ssi-rss-feed-article .post-article .post-footer a:hover {
  text-decoration: none;
}
ssi-rss-feed-article .post-article .post-footer a.post-create {
  padding-right: 30px;
}
ssi-rss-feed-article .post-article .post-footer a.post-create .italic {
  font-style: italic;
}
ssi-rss-feed-article .view-2x2 .post-heading,
ssi-rss-feed-article .view-2x3 .post-heading,
ssi-rss-feed-article .view-2x4 .post-heading,
ssi-rss-feed-article .view-4x2 .post-heading,
ssi-rss-feed-article .view-4x3 .post-heading,
ssi-rss-feed-article .view-2x4 .post-heading {
  display: grid;
  grid-template-columns: auto 259px 99px;
}
ssi-rss-feed-article .view-2x2 .post-body,
ssi-rss-feed-article .view-2x3 .post-body,
ssi-rss-feed-article .view-2x4 .post-body,
ssi-rss-feed-article .view-4x2 .post-body,
ssi-rss-feed-article .view-4x3 .post-body,
ssi-rss-feed-article .view-2x4 .post-body {
  display: grid;
  grid-template-columns: 75% 25%;
}