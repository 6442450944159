ssi-drilldown-modal {
  font-size: 1rem;
}
ssi-drilldown-modal .drilldown-modal-content-container {
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 12px;
  height: 100%;
}
ssi-drilldown-modal .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: 20px;
  border-bottom: 1px solid #c3c9d8;
}
ssi-drilldown-modal .heading .title {
  font-size: 2em;
  font-weight: 900;
  color: #101525;
  white-space: nowrap;
  flex-basis: 20%;
  margin-bottom: 0;
}
ssi-drilldown-modal .heading button {
  border: 1px solid #101525;
  border-radius: 10px;
  background-color: #fff;
  padding: 0.5em 1.5em;
  appearance: none;
  font-size: 1.4em;
  font-weight: 900;
  color: #43537f;
}
ssi-drilldown-modal .heading button.primary {
  color: #fff;
  background-color: #14bae3;
  border-color: #14bae3 !important;
}
ssi-drilldown-modal .heading button:focus {
  outline: none;
  border-color: #838eab;
}
ssi-drilldown-modal .heading button ~ button {
  margin-left: 5px;
}
ssi-drilldown-modal .heading .buttons {
  display: flex;
}
ssi-drilldown-modal .heading .buttons button {
  margin-left: 5px;
}
ssi-drilldown-modal .tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4fa;
}
ssi-drilldown-modal .tabs .tab-name {
  position: relative;
  font-size: 1em;
  font-weight: 900;
  width: 100%;
  padding: 2em 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #101525;
  cursor: pointer;
  border: 1px solid #c3c9d8;
  background-color: #f4f4fa;
  border-top: none;
}
ssi-drilldown-modal .tabs .tab-name:first-child {
  border-top-right-radius: 10px;
  border-right: 0;
}
ssi-drilldown-modal .tabs .tab-name:last-child {
  border-top-left-radius: 10px;
}
ssi-drilldown-modal .tabs .tab-name.active {
  color: #101525;
  background-color: #fff;
  border-bottom: none;
}
ssi-drilldown-modal .tabs .tab-name.active:first-child {
  border-top-right-radius: 10px;
  border-right: 1px solid #c3c9d8;
}
ssi-drilldown-modal .tabs .tab-name.active:first-child ~ div {
  border-left: 0;
}
ssi-drilldown-modal .tabs .tab-name.active:last-child {
  border-top-left-radius: 10px;
}
ssi-drilldown-modal .tabs .tab-name.active:last-child + div {
  border-right: 0;
}
ssi-drilldown-modal .tabs .tab-name:after {
  transform: scaleX(0);
  visibility: hidden;
  top: 45px;
  content: "";
  display: block;
  position: absolute;
  height: 3px;
  background-color: #14bae3;
  height: 2px;
  width: 50px;
  left: 0;
  right: 0;
  margin: auto;
  transition: all 0.3s ease-in-out 0s;
}
ssi-drilldown-modal .tabs .tab-name:hover::after, ssi-drilldown-modal .tabs .tab-name.active::after {
  transform: scaleX(1);
  visibility: visible;
}
ssi-drilldown-modal .drilldown-content {
  display: flex;
  height: 100%;
  background-color: #fff;
  overflow: hidden;
}
ssi-drilldown-modal .drilldown-content .interaction-area,
ssi-drilldown-modal .drilldown-content .posts-area,
ssi-drilldown-modal .drilldown-content .annotation-area {
  background-color: #fff;
  width: 100%;
}
ssi-drilldown-modal .drilldown-content .interaction-area,
ssi-drilldown-modal .drilldown-content .annotation-area {
  padding: 2em;
  overflow: auto;
}
ssi-drilldown-modal .drilldown-content .posts-area {
  overflow: auto;
}
ssi-drilldown-modal .drilldown-content .posts-area ssi-insights-posts .posts-container {
  height: 100%;
  background-color: inherit;
}
ssi-drilldown-modal .drilldown-content .posts-area.dotted {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='#F8F9FD' rx='12' ry='12' stroke='#c3c9d8' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 12px;
  box-shadow: inset 0 18px 40px 0 rgba(131, 142, 171, 0.1);
}
ssi-drilldown-modal .drilldown-content .datapoint-date {
  text-align: left;
  color: #43537f;
  font-size: 1.4em;
  font-weight: bold;
}
ssi-drilldown-modal .drilldown-content .paginator-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin: 20px 0 60px 0;
}
ssi-drilldown-modal .sentiments-area {
  text-align: left;
  margin-top: 1em;
}
ssi-drilldown-modal .sentiments-area .sentiment-block {
  display: inline-flex;
  align-items: center;
  margin-right: 30px;
}
ssi-drilldown-modal .sentiments-area .sentiment-color {
  display: inline-block;
  height: 4px;
  width: 20px;
  border-radius: 100px;
}
ssi-drilldown-modal .sentiments-area .sentiment-label {
  margin-left: 7px;
  color: #43537f;
  font-weight: 900;
  font-size: 10px;
}
ssi-drilldown-modal .themes-container {
  height: 100%;
}
ssi-drilldown-modal .themes-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
ssi-drilldown-modal .message-info {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-align: center;
}
ssi-drilldown-modal .message-info i {
  color: #b4bbce;
  font-size: 4em;
}
ssi-drilldown-modal .message-info h5 {
  font-size: 2em;
  font-weight: 900;
  color: #101525;
}
ssi-drilldown-modal .message-info p {
  font-size: 1.4em;
  max-width: 220px;
  font-weight: bold;
  color: #101525;
}
ssi-drilldown-modal .themes-content {
  width: 100%;
  margin-top: 2em;
}
ssi-drilldown-modal .themes-content ul,
ssi-drilldown-modal .themes-content li {
  list-style: none;
  margin: 0;
  padding: 0;
}
ssi-drilldown-modal .themes-content li {
  position: relative;
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  padding: 37.5px;
  background-color: #f8f9fd;
  color: #101525;
  border-radius: 10px;
  border: 1px solid #c3c9d8;
  margin-bottom: 7px;
}
ssi-drilldown-modal .themes-content li:hover {
  cursor: pointer;
}
ssi-drilldown-modal .themes-content .theme-index {
  position: absolute;
  top: 0;
  left: 15px;
  padding: 38px 20px 15px 20px;
  background-color: #f4f4fa;
  border: 1px solid #c3c9d8;
  border-top-color: transparent;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #101525;
  text-align: center;
  font-size: 20px;
  font-weight: 900;
}
ssi-drilldown-modal .themes-content .theme-name {
  color: #101525;
  font-size: 14px;
  font-weight: 900;
  margin-left: 50px;
  text-align: left;
}
ssi-drilldown-modal .themes-content ssi-big-number {
  font-size: 20px;
  font-weight: 900;
  margin-left: auto;
}
ssi-drilldown-modal .themes-content .theme-results {
  color: #43537f;
  font-size: 10px;
  font-weight: 900;
  line-height: 8px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-left: 5px;
}
ssi-drilldown-modal .report-action-bar-actions {
  width: 100%;
  margin-top: 10px;
  height: auto;
  min-height: 50px;
  border-top: 1px solid #c3c9d8;
  border-bottom: 1px solid #c3c9d8;
  padding: 0 2em;
}
ssi-drilldown-modal .report-action-bar-actions-left {
  display: inline-flex;
  align-items: center;
  flex: 1 1 auto;
  margin: 1em 0;
}
ssi-drilldown-modal .report-action-bar-actions-right {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1 1 auto;
  margin: 1em 0 1em 2em;
}
ssi-drilldown-modal .report-actions-bar-bulk-actions {
  display: flex;
  border-radius: 1em;
  padding: 1em;
  height: 4rem;
  margin-top: 1em;
  flex: 1 1 auto;
}
ssi-drilldown-modal .report-action-bar-selected-posts-container {
  display: flex;
  align-items: center;
}
ssi-drilldown-modal .report-action-bar-selected-posts-container span {
  font-family: Lato, sans-serif;
  font-weight: 900;
  font-size: 14px;
}
ssi-drilldown-modal .report-bulk-actions-list {
  display: flex;
  flex: 0 1 auto;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 100%;
}
ssi-drilldown-modal .report-bulk-actions-list li {
  display: flex;
  flex-direction: row;
}
ssi-drilldown-modal .report-bulk-actions-list-item {
  padding: 0 1em 0 2rem;
  margin: 0.2rem 0;
  cursor: pointer;
  color: #43537f;
}
ssi-drilldown-modal .report-bulk-actions-list-item .ssi {
  padding: 0 1em;
  font-size: 14px;
  align-self: center;
}
ssi-drilldown-modal .report-bulk-actions-list-item span {
  font-family: Lato, sans-serif;
  font-weight: 900;
  font-size: 14px;
}
ssi-drilldown-modal .report-bulk-actions-list-item .delete-icon {
  color: #cc3300;
}
ssi-drilldown-modal .report-action-bar-actions {
  width: 100%;
  height: auto;
  min-height: 60px;
  border-bottom: 1px solid #c3c9d8;
  padding: 0 2em;
}
ssi-drilldown-modal .report-action-bar-actions-left {
  display: inline-flex;
  align-items: center;
  flex: 1 1 auto;
  margin: 1em 0;
}
ssi-drilldown-modal .report-action-bar-actions-right {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1 1 auto;
  margin: 1em 0 1em 2em;
}
ssi-drilldown-modal .report-actions-bar-bulk-actions {
  display: flex;
  background-color: #dbe2f7;
  border-radius: 1em;
  padding: 1em;
  height: 4rem;
  margin-top: 1em;
  flex: 1 1 auto;
}
ssi-drilldown-modal .report-action-bar-selected-posts-container {
  display: flex;
  align-items: center;
  color: #43537f;
}
ssi-drilldown-modal .report-action-bar-selected-posts-container span {
  font-family: Lato, sans-serif;
  font-weight: 900;
  font-size: 14px;
}
ssi-drilldown-modal .report-bulk-actions-list {
  display: flex;
  flex: 0 1 auto;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 100%;
}
ssi-drilldown-modal .report-bulk-actions-list li {
  display: flex;
  flex-direction: row;
}
ssi-drilldown-modal .report-bulk-actions-list-item {
  padding: 0 1em 0 2rem;
  margin: 0.2rem 0;
  cursor: pointer;
}
ssi-drilldown-modal .report-bulk-actions-list-item .ssi {
  padding: 0 1em;
  font-size: 14px;
  align-self: center;
}
ssi-drilldown-modal .report-bulk-actions-list-item span {
  font-family: Lato, sans-serif;
  font-weight: 900;
  font-size: 14px;
}
ssi-drilldown-modal .report-bulk-actions-list-item .delete-icon {
  color: #cc3300;
}
ssi-drilldown-modal .button-disabled {
  cursor: not-allowed;
}
ssi-drilldown-modal .label-disabled {
  display: none;
}
ssi-drilldown-modal .dwm-form {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 1em 1em 1em;
  overflow: hidden;
  height: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.26s ease;
}
ssi-drilldown-modal .dwm-form.active {
  height: auto;
  transform: scaleY(1);
}
ssi-drilldown-modal .dwm-form-half {
  flex: 1 0 100%;
}
@media (min-width: 768px) {
  ssi-drilldown-modal .dwm-form-half {
    flex: 1 0 calc(50% - 16px);
    min-width: 30em;
    margin: 0 0.8em;
  }
}
ssi-drilldown-modal .dwm-form-full {
  flex: 1 1 100%;
  margin: 1em 0.8em;
}
ssi-drilldown-modal .dwm-label {
  padding: 0 0 0 3em;
  display: block;
  text-transform: uppercase;
  font-size: 1em;
  font-weight: 900;
  letter-spacing: 0.1em;
  margin: 0 0 1.8em;
  color: #6a759a;
  text-rendering: geometricPrecision;
  text-align: left;
}
ssi-drilldown-modal .dwm-body-row-input {
  position: relative;
  margin: 0;
}
ssi-drilldown-modal .dwm-input {
  font-size: 1.4em;
  width: 100%;
  border-width: 0;
  height: 4.6rem;
  border-radius: 1rem;
  color: #101525;
  font-weight: 700;
  text-rendering: geometricPrecision;
  text-indent: 3rem;
  padding: 0 3rem 0 0;
  background-color: #fff;
  border: 1px solid #c3c9d8;
}
ssi-drilldown-modal .dwm-input:focus {
  outline: 1px solid #14bae3;
}
ssi-drilldown-modal .dwm-input:disabled {
  background-color: #f4f4fa;
  border: none;
}
ssi-drilldown-modal .dwm-input::placeholder {
  color: #6a759a;
  text-rendering: geometricPrecision;
}
ssi-drilldown-modal .dwm-textarea {
  padding: 1em 0 0 0;
  height: auto;
}
ssi-drilldown-modal .dwm-body-row-input-meta {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  height: 1em;
  line-height: 1;
  right: 1.8em;
  color: #838eab;
  font-size: 1em;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-drilldown-modal .dwm-body-row-input-meta-active {
  color: #14bae3;
}
ssi-drilldown-modal .dwm-inline-submit {
  position: absolute;
  display: flex;
  line-height: 1.2em;
  color: #43537f;
  font-weight: 900;
  text-transform: uppercase;
  margin-left: 2em;
  letter-spacing: 0.1em;
  bottom: 15px;
  right: 20px;
}
ssi-drilldown-modal .dwm-inline-submit div {
  cursor: pointer;
  margin: 0 0 0 0.8em;
}
ssi-drilldown-modal .annotations-new {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
ssi-drilldown-modal .annotations-new .annotations-new-container {
  color: #43537f;
  max-width: 350px;
}
ssi-drilldown-modal .annotations-new .annotations-new-container i {
  color: #b5bbcf;
  font-size: 4em;
  margin-bottom: 0.2em;
}
ssi-drilldown-modal .annotations-new .annotations-new-container h2 {
  font-size: 2em;
  margin: 0.6em 0;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-drilldown-modal .annotations-new .annotations-new-container p {
  line-height: 2rem;
  font-weight: 700;
  margin: 0 0 2em 0;
  text-rendering: geometricPrecision;
}
ssi-drilldown-modal .annotations-new .annotations-new-container button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 1rem;
  height: 4rem;
  width: 4rem;
  padding: 0;
  color: #fff;
  border-radius: 50%;
  border-width: 0;
  background-color: #14bae3;
}
ssi-drilldown-modal .annotations-new .annotations-new-container button i {
  font-size: 1.2rem;
  margin: 0;
  color: inherit;
}
ssi-drilldown-modal .annotations-header {
  display: flex;
  padding: 0 2em;
  margin: 0 2em;
  align-items: center;
}
ssi-drilldown-modal .annotations-header div {
  display: flex;
  text-transform: uppercase;
  font-size: 1em;
  font-weight: 900;
  letter-spacing: 0.1em;
  margin: 0 0 1em;
  color: #6a759a;
  text-rendering: geometricPrecision;
  text-align: left;
}
ssi-drilldown-modal .annotations-header div:nth-child(1) {
  flex-grow: 1;
  flex-basis: 15%;
  text-align: left;
}
ssi-drilldown-modal .annotations-header div:nth-child(2) {
  flex-grow: 1;
  flex-basis: 15%;
}
ssi-drilldown-modal .annotations-header div:nth-child(3) {
  flex-grow: 3;
  flex-basis: 50%;
}
ssi-drilldown-modal .annotations-header div:nth-child(4) {
  flex-grow: 1;
  justify-content: flex-end;
  flex-basis: 20%;
}
ssi-drilldown-modal .annotations-row {
  display: flex;
  padding: 1em 1.4em;
  margin: 0 1.4em;
  border-top: solid 1px #c3c9d8;
  font-size: 1.4em;
  align-items: center;
  font-weight: 700;
  color: #6a759a;
}
ssi-drilldown-modal .annotations-row:nth-child(even) {
  background-color: #f4f4fa;
}
ssi-drilldown-modal .annotations-row div {
  display: flex;
}
ssi-drilldown-modal .annotations-row div:nth-child(1) {
  flex-grow: 1;
  flex-basis: 15%;
  color: #14bae3;
  text-decoration: underline;
  text-align: left;
}
ssi-drilldown-modal .annotations-row div:nth-child(2) {
  flex-grow: 1;
  flex-basis: 15%;
  color: #101525;
}
ssi-drilldown-modal .annotations-row div:nth-child(3) {
  flex-grow: 3;
  flex-basis: 50%;
}
ssi-drilldown-modal .annotations-row div:nth-child(4) {
  flex-grow: 1;
  flex-basis: 20%;
  justify-content: flex-end;
}
ssi-drilldown-modal .annotations-add-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #14bae3;
  color: white;
  border-width: 0;
  border-radius: 1em;
  height: 2.8em;
  line-height: 2.8em;
  white-space: nowrap;
  font-size: 1.4em;
  padding: 0 1.4em;
  font-weight: 700;
}
ssi-drilldown-modal .annotations-add-button > *:first-child {
  display: inline-block;
  margin: 0 0.6rem 0 0;
}
ssi-drilldown-modal .annotations-add-button .ssi {
  font-size: 1.4rem;
}
ssi-drilldown-modal .annotations-add-button ssi-icon path {
  fill: #43537f;
}
ssi-drilldown-modal .annotations-action-button {
  display: inline-block;
  margin: 0 0 0 0.2em;
  border-radius: 0.8em;
  height: 2.6em;
  width: 2.6em;
  color: #43537f;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: solid 1px;
}
ssi-drilldown-modal .annotations-action-button.edit {
  background-color: white;
  border-color: #838eab;
}
ssi-drilldown-modal .annotations-action-button.delete {
  color: #f88c68;
  background-color: #fff1ed;
  border-color: #f88c68;
}
ssi-drilldown-modal .annotations-action-button-active button {
  color: #fff;
  background-color: #14bae3;
}
ssi-drilldown-modal .annotations-action-button i {
  font-size: 0.9em;
  color: inherit;
}