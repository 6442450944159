ssi-advertising-edit-preset {
  background-color: #40454f;
  padding: 0 15px;
  margin-top: 30px;
}

.advertising-float-top {
  margin: -20px 0 40px;
}

.btn-top {
  display: inline-block;
  color: inherit;
  background-color: #40454f;
  border: 2px solid #454c56;
}

.advertising-section {
  display: flex;
  margin-bottom: 70px;
}
.advertising-section.advertising-section-wrap {
  flex-wrap: wrap;
}
.advertising-section.advertising-section-collapse {
  margin-bottom: 0;
}

.advertising-section-bullet-wrap {
  flex: 0 0 80px;
  width: 80px;
}
.advertising-section-bullet-wrap .advertising-section-bullet + .advertising-section-bullet:before {
  content: "";
  position: absolute;
  height: 12px;
  border: 1px dashed #5c616a;
  margin-top: -26px;
}

.advertising-section-bullet {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background-color: #454c56;
  border-radius: 50%;
  font-size: 18px;
  color: #fff;
  margin: 0 0 12px;
}

.advertising-preset-info {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.advertising-preset-info-name {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 12px;
}

.advertising-preset-info-copy {
  text-align: center;
  color: #87959e;
}
.advertising-preset-info-copy .advertising-label {
  color: inherit;
}
.advertising-preset-info-copy .ssi {
  font-size: 30px;
}

.advertising-preset-info-copy.active {
  color: #ffd51c;
}
.advertising-preset-info-copy.active .advertising-label {
  color: #fff;
}
.advertising-preset-info-copy.active .ssi {
  cursor: pointer;
}

.advertising-preset-wrap {
  display: flex;
  flex: 1 0 calc(100% - 80px);
  align-items: center;
  margin-bottom: 12px;
  flex-wrap: wrap;
}

.advertising-label {
  color: #fff;
  font-weight: 500;
  display: inline-block;
  width: 125px;
  overflow: hidden;
  margin: 0;
}

.advertising-label-hide {
  text-indent: -100%;
  overflow: hidden;
  white-space: nowrap;
}

.advertising-label.advertising-label-block {
  display: block;
  width: 100%;
  margin: 0 0 20px;
  text-align: center;
}

.preset-form-dropdown-group + .preset-form-dropdown-group:before {
  content: "";
  width: 100%;
}
.preset-form-dropdown-group > .preset-form-input + .preset-form-input,
.preset-form-dropdown-group > .preset-form-dropdown + .preset-form-dropdown {
  margin-left: 30px;
}
.preset-form-dropdown-group > .preset-form-input + .preset-form-input:before,
.preset-form-dropdown-group > .preset-form-dropdown + .preset-form-dropdown:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 35px;
  left: -35px;
  top: 18px;
  border-bottom: 3px solid #454c56;
}

.preset-form-dropdown {
  position: relative;
  display: inline-block;
  background-color: #454c56;
  color: #87959e;
  border-radius: 20px;
}
.preset-form-dropdown select {
  height: 40px;
  width: 250px;
  background-color: transparent;
  border-width: 0;
  appearance: none;
  padding: 0 40px 0 20px;
  font-weight: 500;
  outline: 0;
}
.preset-form-dropdown .preset-form-dropdown-icon {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  bottom: 0;
  right: 20px;
  color: #ffd51c;
  pointer-events: none;
}

.preset-form-input-group .input-group-addon {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: #454c56;
  color: #87959e;
  height: 40px;
  border: 0;
  padding: 0 0 0 20px;
}
.preset-form-input-group .preset-form-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 221px;
}

.preset-form-input,
.advertising-static {
  background-color: #454c56;
  color: #87959e;
  border-radius: 20px;
  padding: 0 20px;
  border-width: 0;
  height: 40px;
  width: 250px;
  font-weight: 500;
}

.preset-form-input {
  outline: 0;
}
.preset-form-input::placeholder {
  color: #87959e;
  opacity: 0.6;
}

.advertising-static {
  position: relative;
  display: inline-block;
  line-height: 40px;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: not-allowed;
}

.btn-advertising-preset {
  position: relative;
  border-radius: 20px;
  height: 40px;
  width: 250px;
  text-align: center;
  padding: 0 40px 0 20px;
  margin: 0 10px 0 0;
  border-width: 0;
}
.btn-advertising-preset.btn-primary {
  background-color: #454c56;
  color: #87959e;
}
.btn-advertising-preset.btn-primary:hover, .btn-advertising-preset.btn-primary:focus {
  background-color: #3a3f48;
  color: #87959e;
}
.btn-advertising-preset.btn-advertising-preset-bullet:after {
  content: "";
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  bottom: 0;
  right: 20px;
  margin: auto;
  display: block;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #fff;
}
.btn-advertising-preset.btn-advertising-preset-wide {
  width: 535px;
  text-align: left;
}

@media (min-width: 1200px) {
  .advertising-section {
    margin: 0 45px 70px;
  }
}