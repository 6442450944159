@import '../../../../../angularjs/common/sass/variables';
@import '../../../../../angularjs/common/sass/mixins';
@import '~/styles/colors';
@import '../../../auth/inbox/inbox-sidebar/inbox-sidebar.component.scss';

// from old angularjs activity.scss
$activity-media-border-width: 4px;
$inbox-activity-thread-footer-content-height: 0px;

$inbox-activity-thread-footer-padding: 15px;
$inbox-activity-thread-footer-height: $inbox-activity-thread-footer-content-height +
  $inbox-activity-thread-footer-padding * 1;
//

$unit: 2.5px;

$inbox-query-result-list-item-unread: $primary-active;
$inbox-query-result-list-item-actioned: #acb543;
$inbox-query-result-list-item-unactioned: #d1dbf4;
$inbox-query-result-list-item-resolved: #fff;
$inbox-query-result-list-item-selected: #ecf0fd;
$inbox-query-result-list-item-onhold: #d41d68;

$inbox-query-result-list-item-unread-hover: #2788bf;
$inbox-query-result-list-item-actioned-hover: #719239;
$inbox-query-result-list-item-unactioned-hover: #c1b7b4;
$inbox-query-result-list-item-resolved-hover: $inbox-query-result-list-item-resolved;
$inbox-query-result-list-item-selected-hover: darken(
  $inbox-query-result-list-item-selected,
  5%
);
$inbox-query-result-list-item-onhold-hover: darken(
  $inbox-query-result-list-item-onhold,
  5%
);

$inbox-query-result-list-item-unread-text: $inbox-query-result-list-item-unread;
$inbox-query-result-list-item-actioned-text: $inbox-query-result-list-item-actioned;
$inbox-query-result-list-item-unactioned-text: darken(
  $inbox-query-result-list-item-unactioned,
  20%
);
$inbox-query-result-list-item-resolved-text: $inbox-query-result-list-item-unactioned-text;
$inbox-query-result-list-item-selected-text: $text-dark;
$inbox-query-result-list-item-unread-social-icon-color: $inbox-query-result-list-item-unread;
$inbox-query-result-list-item-actioned-social-icon-color: $text-color-muted;
$inbox-query-result-list-item-unactioned-social-icon-color: $text-color-muted;
$inbox-query-result-list-item-resolved-social-icon-color: $text-color-muted;
$inbox-query-result-list-item-selected-social-icon-color: $text-color-muted;
$inbox-query-result-list-item-section-height: 105px;
$inbox-query-result-list-item-icon-width: 55px;
$inbox-query-result-list-item-status-width: 15px;
$inbox-query-result-list-item-avatar-width: 72px;
$inbox-query-result-list-item-icon-width-sm: 55px;
$inbox-query-result-list-item-status-width-sm: 15px;
$inbox-query-result-list-item-avatar-width-sm: 48px;
$inbox-query-result-list-item-toolbar-influence-bg: #e3ba4b;
$inbox-query-result-list-item-toolbar-assigned-to-bg: #4f555a;
$inbox-query-result-list-item-toolbar-assigned-to-bg-open: darken(#4f555a, 10%);
$inbox-query-result-list-item-toolbar-assigned-to-bg-active: $brand-royal;
$inbox-query-result-list-item-toolbar-assigned-to-bg-active-hover: darken(
  $inbox-query-result-list-item-toolbar-assigned-to-bg-active,
  5%
);
$inbox-query-result-list-item-toolbar-width: 30px;
$inbox-query-result-list-item-text-container-line-height: 16px;
$inbox-query-result-list-item-text-container-padding-vertical: 12px;
$inbox-query-result-list-item-text-container-padding-horizontal: 15px;
$inbox-query-result-list-item-conversation-message-bg: #fff;
ssi-inbox-query-result-list,
ssi-inbox-query-result-list-push {
  h1 {
    width: 100%;
  }
}

.inbox-query-result-list .scrollable-content {
  padding-top: 10px;
}

.inbox-query-result-list {
  height: 100%;
  overflow-y: auto;
  background-color: $grey-background;
  // .scrollable-content :first-child .inbox-query-result-list-divider {
  //   margin-top: $section-margin-width;
  // }
  .inbox-query-result-list-divider {
    $divider-height: 25px;
    $divider-inner-height: $divider-height - $section-margin-width;
    $divider-label-color: $lmode-helper-text;
    $divider-font-size: 8px;
    background-color: $grey-background;
    height: $divider-height;
    font-size: $divider-font-size;
    text-transform: uppercase;
    font-weight: normal;
    .divider-label {
      font-size: 10px;
      font-weight: bold;
      text-transform: capitalize;
      width: 70px;
      text-align: center;
      color: $divider-label-color;
      line-height: $divider-inner-height;
      margin-left: 30px;
    }
    &.conversation-mode {
      background-color: #e7edfd;
    }
  }
  .inbox-query-result-list-item-toolbar-visible {
    background-color: $inbox-query-result-list-item-toolbar-assigned-to-bg;
  }

  .inbox-query-result-list-item-source {
    margin: 0 0 0 3px;
    text-align: right;
    vertical-align: middle;
  }

  .inbox-query-result-list-item-source-label {
    display: inline-block;
    margin: 0 2px 0 0;
    color: $lmode-title-text;
    font-size: 10px;
    line-height: 12px;
    vertical-align: top;
    font-weight: 700;
  }

  .inbox-query-result-list-item-source-icon {
    display: inline-block;
    background-size: 100% 100%;
    height: 12px;
    width: 12px;
    background-position: center;
  }

  .inbox-query-result-list-item {
    background-color: #fff;
    color: $text-color-muted;
    margin-bottom: $section-margin-width;
    display: flex;
    position: relative;
    &.expanded {
      .inbox-query-result-list-item-content {
        white-space: normal;
      }
      .inbox-query-result-list-item-conversation-statistics {
        display: flex;
      }
    }
    &:hover {
      .inbox-query-result-list-item-toolbar {
        background-color: #fff;
        box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.35);
      }
    }
    .inbox-query-result-list-item-media-container {
      display: flex;
    }
    .inbox-query-result-list-item-media {
      position: relative;
      flex: 1;

      height: $inbox-query-result-list-item-section-height;
      margin: 0 10px 10px 10px;
      & > div {
        border-radius: 30px;
        display: flex;
        position: relative;
      }
      img {
        border-radius: 10px;
        height: $inbox-query-result-list-item-section-height;
        width: 100%;
        object-fit: cover;
        cursor: pointer;
      }
      .inbox-query-result-list-item-media-overlay {
        border-radius: 10px;
        position: absolute;
        top: 0;
      }
      $media-icon-height: 36px;
      .inbox-query-result-list-item-media-icon {
        @include responsiveWidth($ssi-screen-width-sm) {
          width: $inbox-query-result-list-item-avatar-width-sm;
        }
        border: solid $unit #fff;
        border-radius: 50%;
        bottom: calc(50% - (#{$media-icon-height} / 2));
        display: flex;
        font-style: normal;
        position: absolute;
        text-align: center;
        &.ssi {
          background-color: #fff;
          font-size: 1.5em;
          left: calc($media-icon-height / 4);
          padding: $unit * 2;
          z-index: 2;
          color: $lmode-helper-text;
        }
        &.additional {
          background-color: $primary-blue;
          color: #fff;
          font-size: 0.8em;
          font-weight: 600;
          left: 38px;
          padding: 8px;
          text-align: center;
          z-index: 1;
          i {
            color: #fff;
          }
        }
      }
    }
    .inbox-query-result-list-item-media:not(:last-child) {
      border-right: solid $activity-media-border-width
        $inbox-query-result-list-item-selected-social-icon-color;
    }
    .inbox-query-result-list-item-media-overlay {
      height: $inbox-query-result-list-item-section-height;
      width: 100%;
      cursor: pointer;
      position: relative;
      top: -$inbox-query-result-list-item-section-height;
    }
    .inbox-query-result-list-item-icon,
    .inbox-query-result-list-item-status,
    .inbox-query-result-list-item-toolbar {
      flex-grow: 0;
      flex-shrink: 0;
    }
    .inbox-query-result-list-item-icon {
      flex-basis: $inbox-query-result-list-item-icon-width;
      color: $lmode-helper-text;
      @include responsiveWidth($ssi-screen-width-sm) {
        flex-basis: $inbox-query-result-list-item-icon-width-sm;
      }
      text-align: center;
      @include align-vertical(auto);
      i {
        font-size: 17px;
        &.fa-youtube-play {
          font-size: 23px;
          color: #282828;
        }
      }
      &.spinner {
        i {
          color: #d8dee3;
          font-size: 2.4em;
          font-weight: 500;
        }
      }
      &.shadow-on-hover {
        &:hover {
          background-color: #545767;
          color: $lmode-helper-text;
        }
      }
    }
    .inbox-query-result-list-item-status {
      border: none;
      flex-basis: $inbox-query-result-list-item-status-width;
      display: block;
      @include responsiveWidth($ssi-screen-width-sm) {
        flex-basis: $inbox-query-result-list-item-status-width-sm;
      }
      &.active,
      &.active:hover {
        background-color: #d8dee3;
        cursor: initial;
      }
    }
    .inbox-query-result-list-item-content {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      font-weight: normal;
      color: $lmode-body-text;
      line-height: 17px;
    }
    .inbox-query-result-list-item-avatar-container {
      flex-shrink: 0;
      width: $inbox-query-result-list-item-avatar-width;
      @include responsiveWidth($ssi-screen-width-sm) {
        width: $inbox-query-result-list-item-avatar-width-sm;
      }
      @include align-vertical(auto);

      .inbox-query-result-list-item-avatar-wrapper {
        position: relative;

        .priority-badge {
          position: absolute;
          top: -12px;
          left: 8px;
          z-index: 3;
        }

        &.P1 {
          border-radius: 50%;
          border: 2px solid #f4490f;
        }
      }
    }

    .inbox-query-result-list-item-avatar {
      width: calc($inbox-query-result-list-item-avatar-width / 2);
      height: calc($inbox-query-result-list-item-avatar-width / 2);
      border-radius: 50%;
      @include responsiveWidth($ssi-screen-width-sm) {
        width: calc($inbox-query-result-list-item-avatar-width-sm / 2);
        height: calc($inbox-query-result-list-item-avatar-width-sm / 2);
      }
    }
    .inbox-query-result-list-item-avatar-container.public-conversation {
      .inbox-query-result-list-item-avatar-wrapper {
        .priority-badge {
          z-index: 3;
          top: -2px;
          left: -2px;
        }
      }

      .inbox-query-result-list-item-avatar:not(.multi-users-img) {
        position: relative;
        z-index: 2;
        top: 10px;
        left: -10px;
      }
    }
    .inbox-query-result-list-item-avatar.multi-users-img {
      position: absolute;
      z-index: 1;
      top: -5px;
      left: 5px;
    }
    .inbox-query-result-list-item-avatar-text-media-container {
      flex: 1;
      min-width: 0;
      position: relative;
    }
    .inbox-query-result-list-item-text-container {
      $offset: (
        ($inbox-query-result-list-item-icon-width-sm * 1) +
          $inbox-query-result-list-item-toolbar-width
      );
      $rightMargin: 45px;
      padding: $inbox-query-result-list-item-text-container-padding-vertical
        $inbox-query-result-list-item-text-container-padding-horizontal
        $inbox-query-result-list-item-text-container-padding-vertical 0;
      font-size: 11px;
      line-height: $inbox-query-result-list-item-text-container-line-height;
      color: $text-dark;
      width: calc(100% - (#{$offset} + #{$rightMargin}));
      @include responsiveWidth($ssi-screen-width-sm) {
        width: calc(
          100% - #{$inbox-query-result-list-item-avatar-width-sm} * 2.2
        );
      }
      &.mid-action-conversation {
        align-items: center;
        color: #a6b7c0;
        display: flex;
        justify-content: center;
        p {
          align-items: center;
          display: flex;
          flex-flow: column wrap;
          font-size: 1.2em;
          line-height: 1.6em;
          justify-content: center;
          margin: 0;
          * {
            align-items: center;
            display: flex;
            justify-content: center;
            width: 100%;
          }
          strong {
            font-weight: 600;
          }
          em {
            font-weight: 400;
          }
        }
      }
    }
    .inbox-query-result-list-item-avatar-text-container {
      min-height: $inbox-query-result-list-item-section-height;
      display: flex;
    }
    .inbox-query-result-list-item-from-to {
      align-items: center;
      border-bottom: solid 2px $lmode-border-color;
      display: flex;
      justify-content: space-between;
      margin-bottom: $inbox-query-result-list-item-text-container-padding-vertical -
        2;
      padding-bottom: $inbox-query-result-list-item-text-container-padding-vertical -
        2;
      color: $lmode-title-text;
      font-size: 12px;
    }

    .inbox-query-result-list-item-ad {
      flex: 0 0 20px;
      margin: 0 0 0 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      border-radius: 50%;
      height: 20px;
      width: 20px;

      i {
        line-height: 12px;
        font-size: 10px;
        color: #43537f;
      }
    }

    .inbox-query-result-list-item-name,
    .inbox-query-result-list-item-username {
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 50%;

      .verified {
        display: inline-block;
        position: relative;
        color: #1da1f2;
        font-size: 12px;
        z-index: 1;
        margin: 0 0 0 2px;
        vertical-align: initial;

        &:after {
          content: '';
          position: absolute;
          display: block;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          height: 70%;
          width: 70%;
          background-color: #fff;
          border-radius: 50%;
          z-index: -1;
        }
      }
    }
    .inbox-query-result-list-item-name {
      font-size: 12px;
    }
    .inbox-query-result-list-item-username {
      font-size: 10px;
      float: right;
      padding-left: 10px;
      text-align: right;
    }

    .inbox-query-result-list-item-badge {
      position: relative;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      // background-color: #ECF0FE;
      float: right;
      margin: -2px 0 0 5px;
      color: #43537f;

      i {
        position: absolute;
        display: block;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 10px;
        width: 13px;
      }
    }

    .inbox-query-result-list-item-info {
      font-size: 10px;
      margin-top: 6px;
      color: $lmode-body-text;
      .left > span {
        &:nth-child(n + 2):before {
          content: '|';
          font-weight: 200;
        }
      }
      span {
        font-weight: bold;
      }
    }
    $inbox-query-result-list-item-created-at-spacing: 5px;
    .inbox-query-result-list-item-created-at {
      margin-right: $inbox-query-result-list-item-created-at-spacing;
    }
    .inbox-query-result-list-item-created-at::after {
      margin-left: $inbox-query-result-list-item-created-at-spacing;
      content: '|';
    }

    .inbox-query-result-list-item-name,
    .inbox-query-result-list-item-visibility,
    .inbox-query-result-list-item-username,
    .inbox-query-result-list-item-created-at {
      font-weight: bold;
    }
  }
  .inbox-query-result-list-item-unread {
    .inbox-query-result-list-item-icon {
      color: $inbox-query-result-list-item-unread-social-icon-color !important;
    }
    .inbox-query-result-list-item-status {
      background-color: $inbox-query-result-list-item-unread;
      &:hover {
        background-color: $inbox-query-result-list-item-unread-hover;
      }
    }
    .inbox-query-result-list-item-visibility {
      i {
        color: $primary-active;
      }
    }
  }
  .inbox-query-result-list-item-actioned {
    .inbox-query-result-list-item-status {
      background-color: $inbox-query-result-list-item-actioned;
      &:hover {
        background-color: $inbox-query-result-list-item-actioned-hover;
      }
    }
  }
  .inbox-query-result-list-item-unactioned {
    .inbox-query-result-list-item-status {
      background-color: $inbox-query-result-list-item-unactioned;
    }
    .inbox-query-result-list-item-status:hover {
      background-color: $inbox-query-result-list-item-unactioned-hover;
    }
    .inbox-query-result-list-item-from-to {
      border-color: #fff;
    }
  }
  .inbox-query-result-list-item-resolved {
    .inbox-query-result-list-item-status {
      background-color: $inbox-query-result-list-item-resolved;
    }
    .inbox-query-result-list-item-icon {
      color: $inbox-query-result-list-item-resolved-social-icon-color !important;
    }
    .inbox-query-result-list-item-status:hover {
      background-color: $inbox-query-result-list-item-resolved-hover;
    }
  }
  .inbox-query-result-list-item-onhold {
    .inbox-query-result-list-item-status {
      background-color: $inbox-query-result-list-item-onhold;
    }
    .inbox-query-result-list-item-icon {
      color: $inbox-query-result-list-item-resolved-social-icon-color !important;
    }
    .inbox-query-result-list-item-status:hover {
      background-color: $inbox-query-result-list-item-onhold-hover;
    }
  }
  .inbox-query-result-list-item-selected {
    background-color: #ecf0fd;
    .inbox-query-result-list-item-icon {
      background-color: $inbox-query-result-list-item-selected;
    }

    .inbox-query-result-list-item-icon,
    .inbox-query-result-list-item-text-container {
      color: $inbox-query-result-list-item-selected-text;
    }
  }
  .inbox-query-result-list-item-toolbar,
  .inbox-query-result-list-item-toolbar * {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  .inbox-query-result-list-item-conversation-statistics {
    display: none;
    flex-flow: row nowrap;
    justify-content: space-between;
    list-style: none;
    margin: 20px 0;
    padding: 0;
    * {
      display: flex;
    }
    .conversation-statistic {
      align-items: center;
      border-radius: 10px;
      color: $text-light;
      flex-grow: 1;
      flex-shrink: 0;
      font-size: 0.85em;
      font-weight: 600;
      justify-content: center;
      margin: 0 5px;
      padding: 2px 12px;
      &.actioned {
        background-color: $inbox-query-result-list-item-actioned;
      }
      &.unactioned {
        background-color: $inbox-query-result-list-item-unactioned;
      }
      &.unread {
        background-color: $inbox-query-result-list-item-unread;
      }
      .statistic-value {
        padding-right: 0.3em;
      }
    }
  }
  .inbox-query-result-list-item-toolbar {
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    flex-flow: column nowrap;
    justify-content: space-around;
    list-style: none;
    margin: 0;
    max-height: 104px;
    padding: 3px;
    margin: 8px;
  }
  .inbox-query-result-list-item-toolbar-item {
    @include align-vertical(
      calc($inbox-query-result-list-item-section-height / 3)
    );
    padding: 0;
    font-size: 12px;
    height: 34px;
    margin: 0;
    color: #fff;
    visibility: hidden;
    width: 34px;
    i {
      font-size: 14px;
      &.assign {
        color: $lmode-helper-text;
      }
      &.assigned {
        color: $primary-active;
      }
    }
  }
  .inbox-query-result-list-item:hover
    .inbox-query-result-list-item-toolbar-item,
  .inbox-query-result-list-item-toolbar-visible
    .inbox-query-result-list-item-toolbar-item {
    visibility: visible;
  }
  .inbox-query-result-list-item-toolbar-item.influence {
    position: relative;
    i {
      color: $primary-active;
      font-size: 26px;
      position: relative;
    }
    .inbox-query-result-list-item-toolbar-item-text {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      font-size: 12px;
      font-weight: normal;
    }
  }
  .inbox-query-result-list-item-toolbar-item.assigned-to {
    cursor: pointer;
    &.assigned-to-active {
      color: $primary-active;
    }
  }
  .inbox-query-result-list-item-toolbar-item.§ed-to {
    border-radius: 0;
  }
  .inbox-query-result-list-item-toolbar-item.sentiment {
    border-radius: 50%;
    cursor: pointer;
    height: 34px;
    width: 34px;
  }

  $assign-bar-height: 36px;
  $assign-bar-border-width: 2px;
  .inbox-query-result-list-item-conversation-assign {
    &.active {
      display: flex;
    }
  }
  .inbox-query-result-list-item-assign-to {
    display: flex;
    position: absolute;
    right: 42px;
    top: 42px;
    // width: calc(100% - #{$inbox-query-result-list-item-avatar-width} * 1.5);
    // @include responsiveWidth($ssi-screen-width-sm) {
    //   width: calc(
    //     100% - #{$inbox-query-result-list-item-avatar-width-sm} * 1.5
    //   );
    // }
    .conversation-assignment-preview {
      align-items: center;
      background-color: $primary-active;
      border-radius: 20px;
      color: $text-light;
      display: flex !important;
      flex-grow: 1;
      font-size: 0.85em;
      font-weight: 500;
      justify-content: space-between;
      padding: 6px 20px;
      .preview-icon {
        font-size: 1.2em;
      }
      .preview-name {
        flex-grow: 1;
        padding-left: 0.5em;
      }
      .remove-button {
        color: $text-light;
        font-size: 1em;

        left: auto;
        position: relative;
        top: auto;
      }
    }
    .remove-button {
      align-items: center;
      background: transparent;
      border-radius: 50%;
      color: $primary-active;
      font-size: 0.85em;
      height: 18px;
      justify-content: center;
      left: 8px;
      padding: 0;
      position: absolute;
      top: 9px;
      width: 18px;
      z-index: 1;
    }
    .input-container {
      background-color: #fff;
      border: solid $assign-bar-border-width #c9d5db;
      border-radius: 20px;
      display: flex;
      position: relative;
      input {
        background-color: transparent;
        border: none;
        border-radius: 0;

        box-shadow: none;
        font-style: italic;
        font-weight: 400;
        height: $assign-bar-height - ($assign-bar-border-width * 2);
        padding: 5px 20px 5px 24px;
        width: 226px;
      }
      .dropdown-menu {
        border: none;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        display: block;
        height: 200px;
        left: 16px !important;
        overflow-y: auto;
        padding: 10px 20px 20px 20px;
        top: 32px !important;
        width: 200px;
      }
    }
    button {
      border: none;
      height: $assign-bar-height;
      margin: 0;
      padding: 0;
      &.action-assign {
        background-color: $primary-blue;
        border-radius: 20px;
        margin-left: -$assign-bar-height;
        padding: 2px 20px;
      }
    }
    input,
    .btn {
      font-size: 12px;
    }
  }
  .inbox-query-result-list-item-change-sentiment {
    align-items: center;
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    flex-flow: row nowrap;
    padding: 3px;
    position: absolute;
    right: 20px;
    top: 83px;
    * {
      align-items: center;
      display: flex;
    }
    & > .sentiment {
      border-radius: 50%;
      color: white;
      cursor: pointer;
      margin: 0 2px;
      & > i {
        height: $inbox-query-result-list-item-toolbar-width -
          ($section-margin-width * 2);
        justify-content: center;
        width: $inbox-query-result-list-item-toolbar-width -
          ($section-margin-width * 2);
      }
    }
  }
  .inbox-query-result-list-item.inbox-query-result-list-item-multi-selected {
    .inbox-query-result-list-item-icon {
      background-color: $inbox-sidebar-item-bg-light-color;
    }
  }

  &.on-hold-list {
    max-height: 30%;
  }
}

.inbox-no-results {
  display: flex;
  align-items: center;
  margin: auto;
  flex-direction: column;
  i {
    color: $lmode-helper-text;
    font-size: 50px;
    margin-bottom: 10px;
  }
  h3 {
    color: $lmode-body-text;
    font-size: 20px;
    font-weight: 900;
  }
  h6 {
    color: $lmode-body-text;
    font-size: 14px;
    font-weight: 700;
    margin-top: 0;
    text-align: center;
    margin: auto;
    padding: 0 20px;
    max-width: 230px;
  }
}
