ssi-content-calendar {
  overflow: hidden;
  width: 100%;
}
ssi-content-calendar .content-calendar-container {
  width: 100vw;
  padding: 34px 34px 0;
  background-color: #f4f4fa;
  overflow-y: auto;
  overflow-x: hidden;
}
ssi-content-calendar .content-calendar-container.month-view {
  height: calc(100vh - 68px);
}
ssi-content-calendar .content-calendar-container.content-calendar-container-mobile {
  padding: 0;
  background-color: #fff;
}
ssi-content-calendar .content-calendar-container.content-calendar-container-mobile .content-calendar-header {
  padding: 34px 0 0;
}
ssi-content-calendar .content-calendar-container.content-calendar-container-mobile .post-box-scroll {
  top: calc(-50px - 85px);
}
ssi-content-calendar .content-calendar-container .cal-hour-segment {
  position: relative;
}
ssi-content-calendar .content-calendar-container .cal-hour-segment .empty-cell {
  display: none;
}
ssi-content-calendar .content-calendar-container .cal-hour-segment .hidden-events-btn-container {
  position: absolute;
  bottom: 1px;
  left: 1px;
  padding: 5px;
}
ssi-content-calendar .content-calendar-container .cal-hour-segment .hidden-events-btn-container .show-more-btn {
  color: #14bae3;
  font-weight: 900;
  background-color: transparent;
  border: none;
}
ssi-content-calendar .content-calendar-container .cal-hour-segment .hidden-events-btn-container.mobile {
  left: 45px;
}
ssi-content-calendar .content-calendar-container .cal-hour-segment .hidden-events-btn-container.mobile .show-more-btn {
  font-size: 12px;
}
ssi-content-calendar .content-calendar-container .cal-hour-segment .hidden-events-btn-container.mobile .show-more-btn i {
  margin-left: 3px;
  font-size: 12px;
}
ssi-content-calendar .calendar-scroll-top {
  visibility: hidden;
  position: absolute;
  top: calc(-50px - 100px);
}
ssi-content-calendar .content-calendar-header {
  display: block;
  height: 94px;
  position: fixed;
  width: 100%;
  left: 0;
  padding: 34px 34px 0;
  margin: -34px 0 0;
  z-index: 999;
  background-color: #f4f4fa;
}
ssi-content-calendar .campaigns-and-events-box {
  display: flex;
  width: 100%;
  text-align: left;
  flex-wrap: wrap;
  padding: 1px;
}
ssi-content-calendar .campaigns-and-events-box .single-item {
  display: flex;
  align-items: center;
  color: #43537f;
  border: 1px solid #f4bf41;
  border-radius: 5px;
  padding: 4px 7px;
  background-color: #fef9f0;
  width: 100%;
  margin-top: 1px;
}
ssi-content-calendar .campaigns-and-events-box .single-item.spans-next-day {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
ssi-content-calendar .campaigns-and-events-box .single-item.spans-previous-day {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
ssi-content-calendar .campaigns-and-events-box .single-item span {
  font-size: 12px;
  font-weight: 900;
  opacity: 1;
  max-width: 160px;
  overflow: hidden;
  display: inline-block;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (max-width: 1499px) {
  ssi-content-calendar .campaigns-and-events-box .single-item span {
    max-width: 120px;
  }
}
@media (max-width: 1199px) {
  ssi-content-calendar .campaigns-and-events-box .single-item span {
    max-width: 95px;
  }
}
@media (max-width: 1023px) {
  ssi-content-calendar .campaigns-and-events-box .single-item span {
    max-width: 68px;
  }
}
@media (max-width: 849px) {
  ssi-content-calendar .campaigns-and-events-box .single-item span {
    max-width: 58px;
  }
}
ssi-content-calendar .campaigns-and-events-box .single-item i {
  color: #f4bf41;
  margin-right: 8px;
}
ssi-content-calendar .campaigns-and-events-box .single-item.custom-event {
  border-color: #3463f2;
  background-color: #eaeffe;
}
ssi-content-calendar .campaigns-and-events-box .single-item.custom-event i {
  color: #3463f2;
}
ssi-content-calendar .campaigns-and-events-box > button {
  color: #14bae3;
  font-weight: 900;
  background-color: transparent;
  border: none;
  margin: 5px 0;
}
ssi-content-calendar .campaigns-and-events-box > button i {
  font-size: 14px;
  margin-left: 3px;
}
ssi-content-calendar .campaigns-and-events-box > button.close-collapse i {
  transform: rotate(180deg);
  margin-right: 5px;
}
ssi-content-calendar .campaigns-and-events-box.above-table .single-item {
  width: auto;
  margin: 0 0 5px 5px;
}
@media (max-width: 768px) {
  ssi-content-calendar .campaigns-and-events-box {
    padding-top: 20px;
  }
}
ssi-content-calendar .content-calendar-header-scroll-margin {
  margin: calc(
        94px - 34px + 1px
      ) 0 0;
}
ssi-content-calendar ssi-calendar-day-view {
  font-size: 1rem;
  display: block;
}
ssi-content-calendar ssi-calendar-day-view.calendar-day-view-mobile {
  margin: 1.1em auto;
  width: calc(100vw - 2em);
}
ssi-content-calendar ssi-calendar-day-view.calendar-day-view-mobile .cal-week-view {
  border-width: 0;
}
ssi-content-calendar ssi-calendar-day-view.calendar-day-view-mobile .cal-week-view .cal-time-events {
  border-width: 0;
}
ssi-content-calendar ssi-calendar-day-view.calendar-day-view-mobile .cal-week-view .cal-hour:not(:last-child) .cal-hour-segment {
  border-bottom-color: #838eab;
}
ssi-content-calendar ssi-calendar-day-view.calendar-day-view-mobile .cal-week-view .cal-hour:last-child .cal-hour-segment:hover:before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  display: block;
  width: calc(100% - 4.6rem);
  border-bottom: 0.1em dashed #14bae3;
}
ssi-content-calendar ssi-calendar-day-view.calendar-day-view-mobile .cal-week-view .cal-hour:nth-child(3) .cal-hour-segment:hover:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: calc(100% - 4.6rem);
  border-bottom: 0.1em dashed #14bae3;
}
ssi-content-calendar ssi-calendar-day-view.calendar-day-view-mobile .cal-week-view .cal-hour-segment:hover {
  background-color: rgba(20, 186, 227, 0.1) !important;
  border-right: 0.1em dashed #14bae3;
}
ssi-content-calendar ssi-calendar-day-view.calendar-day-view-mobile .cal-week-view .cal-hour-segment:hover .cal-time .cell-add-icon-left {
  font-size: 0.857em;
  display: block;
  position: absolute;
  margin: auto;
  bottom: 3px;
  right: 5px;
  color: #14bae3;
  text-align: center;
  vertical-align: middle;
  line-height: 3.4rem;
  border: 0.1rem solid #14bae3;
  background-color: #fff;
  border-radius: 50%;
  height: 3.6rem;
  width: 3.6rem;
  display: inline-block;
  z-index: 2;
  cursor: pointer;
}
ssi-content-calendar ssi-calendar-day-view.calendar-day-view-mobile .cal-week-view .cal-hour-segment:hover .cal-time .cell-add-icon-left:after {
  content: "\f1db";
  font-family: Socialsignin;
  color: inherit;
}
ssi-content-calendar ssi-calendar-day-view.calendar-day-view-mobile .cal-week-view .cal-hour-segment:hover .cal-time {
  border-right: 0.1em dashed #14bae3;
}
ssi-content-calendar ssi-calendar-day-view.calendar-day-view-mobile .cal-week-view .cal-time {
  width: 4.6rem;
  line-height: 4.2em;
  padding: 0;
  height: 100%;
  background-color: #fff;
  text-align: left;
  color: #43537f;
  font-size: 1em;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-content-calendar ssi-calendar-day-view.calendar-day-view-mobile .cal-day-view .cal-current-time-marker {
  margin-left: 4.6rem;
  width: calc(100% - 4.6rem);
}
ssi-content-calendar ssi-calendar-day-view.calendar-day-view-mobile .cal-day-view .cal-events-container {
  margin-left: 4.6rem;
}
ssi-content-calendar ssi-calendar-day-view.calendar-day-view-mobile .cal-day-view,
ssi-content-calendar ssi-calendar-day-view.calendar-day-view-mobile .cal-hour,
ssi-content-calendar ssi-calendar-day-view.calendar-day-view-mobile .cal-day-view:nth-child(odd),
ssi-content-calendar ssi-calendar-day-view.calendar-day-view-mobile .cal-hour:nth-child(odd) {
  background-color: #fff;
}
ssi-content-calendar ssi-calendar-day-view.calendar-day-view-mobile .cal-time-date {
  display: none;
}
ssi-content-calendar ssi-calendar-day-view.calendar-day-view-mobile .post-box-container {
  width: 300px;
  max-width: 100%;
}
ssi-content-calendar ssi-calendar-day-view.calendar-day-view-mobile .post-box-container .text-content {
  padding-right: 75px;
}
ssi-content-calendar ssi-calendar-day-view.calendar-day-view {
  margin: 1em 0;
  border: 0.1em solid #b4bbce;
  overflow: hidden;
  border-radius: 1em;
}
ssi-content-calendar ssi-calendar-day-view.calendar-day-view .cal-week-view {
  border-width: 0;
}
ssi-content-calendar ssi-calendar-day-view.calendar-day-view .cal-week-view .cal-time-events {
  border-width: 0;
}
ssi-content-calendar ssi-calendar-day-view.calendar-day-view .cal-week-view .cal-hour {
  background-color: #fff;
}
ssi-content-calendar ssi-calendar-day-view.calendar-day-view .cal-week-view .cal-hour .cal-time {
  background-color: #fff;
}
ssi-content-calendar ssi-calendar-day-view.calendar-day-view .cal-week-view .cal-hour:nth-child(2n+1) {
  background-color: #f9f9fc;
}
ssi-content-calendar ssi-calendar-day-view.calendar-day-view .cal-week-view .cal-hour:nth-child(2n+1) .cal-time {
  background-color: #f9f9fc;
}
ssi-content-calendar ssi-calendar-day-view.calendar-day-view .cal-week-view .cal-hour .cal-hour-segment,
ssi-content-calendar ssi-calendar-day-view.calendar-day-view .cal-week-view .cal-hour .cal-hour-segment:last-child {
  border-bottom-color: #838eab;
}
ssi-content-calendar ssi-calendar-day-view.calendar-day-view .cal-week-view .cal-hour .cal-hour-segment:hover,
ssi-content-calendar ssi-calendar-day-view.calendar-day-view .cal-week-view .cal-hour .cal-hour-segment:last-child:hover {
  background-color: #e7f8fc !important;
}
ssi-content-calendar ssi-calendar-day-view.calendar-day-view .cal-week-view .cal-hour .cal-hour-segment:hover .cell-add-icon-left,
ssi-content-calendar ssi-calendar-day-view.calendar-day-view .cal-week-view .cal-hour .cal-hour-segment:last-child:hover .cell-add-icon-left {
  font-size: 1.2em;
  display: block;
  position: absolute;
  margin: auto;
  left: 0.4rem;
  top: 0;
  bottom: 0;
  color: #14bae3;
  text-align: center;
  vertical-align: middle;
  line-height: 2.4rem;
  border: 0.1rem solid #14bae3;
  background-color: #fff;
  border-radius: 50%;
  height: 2.6rem;
  width: 2.6rem;
  display: inline-block;
  z-index: 2;
  cursor: pointer;
}
ssi-content-calendar ssi-calendar-day-view.calendar-day-view .cal-week-view .cal-hour .cal-hour-segment:hover .cell-add-icon-left:after,
ssi-content-calendar ssi-calendar-day-view.calendar-day-view .cal-week-view .cal-hour .cal-hour-segment:last-child:hover .cell-add-icon-left:after {
  content: "\f1db";
  font-family: Socialsignin;
  color: inherit;
}
ssi-content-calendar ssi-calendar-day-view.calendar-day-view .cal-week-view .cal-hour .cal-hour-segment:hover .cal-time,
ssi-content-calendar ssi-calendar-day-view.calendar-day-view .cal-week-view .cal-hour .cal-hour-segment:last-child:hover .cal-time {
  position: relative;
  z-index: 3;
  border-right: 0.1em solid #14bae3;
  border-bottom: 0.1em;
}
ssi-content-calendar ssi-calendar-day-view.calendar-day-view .cal-week-view .cal-hour .cal-hour-segment:hover .cal-time .cell-add-icon-left,
ssi-content-calendar ssi-calendar-day-view.calendar-day-view .cal-week-view .cal-hour .cal-hour-segment:last-child:hover .cal-time .cell-add-icon-left {
  font-size: 1.2em;
  display: block;
  position: absolute;
  margin: auto;
  left: 5.6rem;
  top: 0;
  bottom: 0;
  color: #14bae3;
  text-align: center;
  vertical-align: middle;
  line-height: 2.4rem;
  border: 0.1rem solid #14bae3;
  background-color: #fff;
  border-radius: 50%;
  height: 2.6rem;
  width: 2.6rem;
  display: inline-block;
  z-index: 2;
  cursor: pointer;
}
ssi-content-calendar ssi-calendar-day-view.calendar-day-view .cal-week-view .cal-hour .cal-hour-segment:hover .cal-time .cell-add-icon-left:after,
ssi-content-calendar ssi-calendar-day-view.calendar-day-view .cal-week-view .cal-hour .cal-hour-segment:last-child:hover .cal-time .cell-add-icon-left:after {
  content: "\f1db";
  font-family: Socialsignin;
  color: inherit;
}
ssi-content-calendar ssi-calendar-day-view.calendar-day-view .cal-week-view .cal-time {
  display: flex;
  align-items: center;
  width: 5.3rem;
  padding: 0 0 0 1.1em;
  height: 100%;
  text-align: left;
  color: #43537f;
  font-size: 1em;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-content-calendar ssi-calendar-day-view.calendar-day-view .cal-day-view .cal-current-time-marker {
  margin-left: 4.6rem;
  width: calc(100% - 4.6rem);
}
ssi-content-calendar ssi-calendar-day-view.calendar-day-view .cal-day-view .cal-events-container {
  margin-left: 8.9rem;
}
ssi-content-calendar ssi-calendar-day-view ssi-calendar-week-view .cal-hour-segment:after {
  display: none !important;
}
ssi-content-calendar ssi-calendar-week-view .cal-week-view {
  width: 100%;
}
ssi-content-calendar ssi-calendar-week-view .cal-week-view .cal-hour-odd {
  background-color: #fff;
}
ssi-content-calendar ssi-calendar-week-view .cal-week-view .cal-day-headers {
  margin: 0;
  height: auto;
  border-color: #b4bbce;
}
ssi-content-calendar ssi-calendar-week-view .cal-week-view .cal-day-headers .cal-header {
  position: relative;
  padding: 0;
}
ssi-content-calendar ssi-calendar-week-view .cal-week-view .cal-day-headers .cal-header .week-head-cell-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}
ssi-content-calendar ssi-calendar-week-view .cal-week-view .cal-day-headers .cal-header .week-head-cell-top span {
  color: #838eab;
  font-size: 10px;
  font-weight: 900;
  text-transform: uppercase;
  opacity: 1;
}
ssi-content-calendar ssi-calendar-week-view .cal-week-view .cal-day-headers .cal-header .week-head-cell-top b {
  color: #43537f;
  font-size: 20px;
  font-weight: 600;
}
ssi-content-calendar ssi-calendar-week-view .cal-week-view .cal-day-headers .cal-header .week-head-cell-top b.today {
  color: #fff;
  z-index: 1;
}
ssi-content-calendar ssi-calendar-week-view .cal-week-view .cal-day-headers .cal-header .week-head-cell-top b.today::after {
  content: "";
  position: absolute;
  top: 0;
  right: 5px;
  height: 30px;
  width: 35px;
  background-color: #14bae3;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: all 0.3s ease-in-out 0s;
  z-index: -1;
}
ssi-content-calendar ssi-calendar-week-view .cal-week-view .cal-day-headers .cal-header .week-head-cell-bottom {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
}
ssi-content-calendar ssi-calendar-week-view .cal-week-view .cal-day-headers .cal-header .week-head-cell-bottom .posts-count-chip {
  margin-left: 3px;
  margin-bottom: 2px;
}
ssi-content-calendar ssi-calendar-week-view .cal-week-view .cal-day-headers .cal-header .week-head-cell-bottom .posts-count-chip span {
  padding: 1px 4px;
  border: 1px solid #b4bbce;
  border-radius: 2px;
  opacity: 1;
  font-size: 10px;
  font-weight: 900;
  color: #838eab;
}
ssi-content-calendar ssi-calendar-week-view .cal-week-view .cal-day-headers .cal-header .week-head-cell-bottom .posts-count-chip.has-posts span {
  background-color: #14bae3;
  border: none;
  color: #fff;
}
ssi-content-calendar ssi-calendar-week-view .cal-time-events {
  border-color: #b4bbce !important;
}
ssi-content-calendar ssi-calendar-week-view .cal-time-events .cal-time-label-column .cal-hour ssi-calendar-week-view-hour-segment .cal-hour-start {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 900;
  color: #43537f;
  border-bottom-color: #b4bbce;
}
ssi-content-calendar ssi-calendar-week-view .cal-time-events .cal-time-label-column .cal-hour ssi-calendar-week-view-hour-segment .cal-hour-start .cal-time {
  padding: 0;
}
ssi-content-calendar ssi-calendar-week-view .cal-time-events .cal-day-columns .cal-day-column {
  border-left-color: #b4bbce;
}
ssi-content-calendar ssi-calendar-week-view .cal-time-events .cal-day-columns .cal-day-column .cal-hour ssi-calendar-week-view-hour-segment .cal-hour-segment {
  border-bottom-color: #b4bbce;
}
ssi-content-calendar ssi-calendar-week-view .cal-time-events .cal-day-columns .cal-day-column .cal-hour ssi-calendar-week-view-hour-segment .cal-hour-segment:hover {
  outline: 1px solid #14bae3;
  outline-offset: -1px;
  background-color: rgba(20, 186, 227, 0.1);
}
ssi-content-calendar ssi-calendar-week-view .cal-time-events .cal-day-columns .cal-day-column .cal-hour ssi-calendar-week-view-hour-segment .cal-hour-segment:hover .cell-add-icon {
  font-size: 0.857em;
  display: block;
  position: absolute;
  margin: auto;
  bottom: 3px;
  right: 5px;
  color: #14bae3;
  text-align: center;
  vertical-align: middle;
  line-height: 3.4rem;
  border: 0.1rem solid #14bae3;
  background-color: #fff;
  border-radius: 50%;
  height: 3.6rem;
  width: 3.6rem;
  display: inline-block;
  z-index: 2;
  cursor: pointer;
}
ssi-content-calendar ssi-calendar-week-view .cal-time-events .cal-day-columns .cal-day-column .cal-hour ssi-calendar-week-view-hour-segment .cal-hour-segment:hover .cell-add-icon:after {
  content: "\f1db";
  font-family: Socialsignin;
  color: inherit;
}
ssi-content-calendar ssi-calendar-week-view .cal-time-events .cal-day-columns .cal-day-column .cal-hour-odd {
  background-color: #fff;
}
ssi-content-calendar ssi-calendar-month-view .cal-month-view {
  border-radius: 10px;
}
ssi-content-calendar ssi-calendar-month-view .cal-month-view .cal-days {
  border: 1px solid #b4bbce;
  border-radius: 10px;
}
ssi-content-calendar ssi-calendar-month-view .cal-month-view .cal-days .cal-cell-row {
  border-bottom: 1px solid #b4bbce;
}
ssi-content-calendar ssi-calendar-month-view .cal-month-view .cal-days .cal-cell-row.cal-cell-row-last {
  border-bottom: none;
}
ssi-content-calendar ssi-calendar-month-view .cal-month-view .cal-days .cal-cell-row ssi-calendar-month-cell {
  min-height: 120px;
}
ssi-content-calendar ssi-calendar-month-view .cal-month-view .cal-days .cal-cell-row ssi-calendar-month-cell.cal-cell {
  background-color: #fff;
}
ssi-content-calendar ssi-calendar-month-view .cal-month-view .cal-days .cal-cell-row ssi-calendar-month-cell.cal-cell.cal-out-month {
  background-color: #f8f9fd;
}
ssi-content-calendar ssi-calendar-month-view .cal-month-view .cal-days .cal-cell-row ssi-calendar-month-cell.cal-cell:hover {
  background-color: #fff;
}
ssi-content-calendar ssi-calendar-month-view .cal-month-view .cal-days .cal-cell-row ssi-calendar-month-cell.cal-day-cell.cal-today .day-cell-inner-wrapper {
  border: 1px solid #14bae3;
}
ssi-content-calendar ssi-calendar-month-view .cal-month-view .cal-days .cal-cell-row ssi-calendar-month-cell.cal-day-cell:not(:last-child) {
  border-right: 1px solid #b4bbce;
}
ssi-content-calendar ssi-calendar-month-view .cal-month-view .cal-days .cal-cell-row ssi-calendar-month-cell.cal-day-cell:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
ssi-content-calendar ssi-calendar-month-view .cal-month-view .cal-days .cal-cell-row ssi-calendar-month-cell.cal-day-cell:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
ssi-content-calendar ssi-calendar-month-view .cal-month-view .cal-days .cal-cell-row ssi-calendar-month-cell .day-cell-inner-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
}
ssi-content-calendar ssi-calendar-month-view .cal-month-view .cal-days .cal-cell-row ssi-calendar-month-cell .day-cell-inner-wrapper:hover {
  outline: 1px solid #14bae3;
  outline-offset: -1px;
  background-color: rgba(20, 186, 227, 0.1);
}
ssi-content-calendar ssi-calendar-month-view .cal-month-view .cal-days .cal-cell-row ssi-calendar-month-cell .day-cell-inner-wrapper:hover .cell-add-icon {
  font-size: 0.857em;
  display: block;
  position: absolute;
  margin: auto;
  bottom: 5px;
  right: 5px;
  color: #14bae3;
  text-align: center;
  vertical-align: middle;
  line-height: 3.4rem;
  border: 0.1rem solid #14bae3;
  background-color: #fff;
  border-radius: 50%;
  height: 3.6rem;
  width: 3.6rem;
  display: inline-block;
  z-index: 2;
  cursor: pointer;
}
ssi-content-calendar ssi-calendar-month-view .cal-month-view .cal-days .cal-cell-row ssi-calendar-month-cell .day-cell-inner-wrapper:hover .cell-add-icon:after {
  content: "\f1db";
  font-family: Socialsignin;
  color: inherit;
}
ssi-content-calendar ssi-calendar-month-view .cal-month-view .cal-days .cal-cell-row ssi-calendar-month-cell .day-cell-inner-wrapper .cell-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
}
ssi-content-calendar ssi-calendar-month-view .cal-month-view .cal-days .cal-cell-row ssi-calendar-month-cell .day-cell-inner-wrapper .cell-top .day-name-wrapper .day-name {
  color: #838eab;
  font-size: 10px;
  font-weight: 900;
  text-transform: uppercase;
}
ssi-content-calendar ssi-calendar-month-view .cal-month-view .cal-days .cal-cell-row ssi-calendar-month-cell .day-cell-inner-wrapper .cell-top .day-number-wrapper.today {
  background-color: #14bae3;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0 5px;
}
ssi-content-calendar ssi-calendar-month-view .cal-month-view .cal-days .cal-cell-row ssi-calendar-month-cell .day-cell-inner-wrapper .cell-top .day-number-wrapper.today .day-number {
  color: #fff;
}
ssi-content-calendar ssi-calendar-month-view .cal-month-view .cal-days .cal-cell-row ssi-calendar-month-cell .day-cell-inner-wrapper .cell-top .day-number-wrapper.out-of-month .day-number {
  color: #b4bbce;
}
ssi-content-calendar ssi-calendar-month-view .cal-month-view .cal-days .cal-cell-row ssi-calendar-month-cell .day-cell-inner-wrapper .cell-top .day-number-wrapper .day-number {
  color: #43537f;
  font-size: 20px;
  font-weight: 600;
  opacity: 1;
}
ssi-content-calendar ssi-calendar-month-view .cal-month-view .cal-days .cal-cell-row ssi-calendar-month-cell .day-cell-inner-wrapper .cal-events {
  padding: 5px 10px 5px 20px;
  margin: 0;
}
ssi-content-calendar ssi-calendar-month-view .cal-month-view .cal-days .cal-cell-row ssi-calendar-month-cell .day-cell-inner-wrapper .cal-events .post-chip {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #838eab;
  border-radius: 5px;
  width: 46px;
  height: 26px;
  margin-right: 2px;
  margin-bottom: 2px;
}
ssi-content-calendar ssi-calendar-month-view .cal-month-view .cal-days .cal-cell-row ssi-calendar-month-cell .day-cell-inner-wrapper .cal-events .post-chip .post-count {
  margin-left: 4px;
  color: #101525;
  font-size: 12px;
  font-weight: 700;
}
ssi-content-calendar ssi-calendar-month-view .cal-month-view .cal-days .cal-cell-row ssi-calendar-month-cell .day-cell-inner-wrapper .cell-bottom {
  display: flex;
  align-items: center;
  justify-content: start;
}
ssi-content-calendar ssi-calendar-month-view .cal-month-view .cal-days .cal-cell-row ssi-calendar-month-cell .day-cell-inner-wrapper .cell-bottom .posts-count-chip {
  margin-left: 3px;
}
ssi-content-calendar ssi-calendar-month-view .cal-month-view .cal-days .cal-cell-row ssi-calendar-month-cell .day-cell-inner-wrapper .cell-bottom .posts-count-chip span {
  padding: 1px 4px;
  border: 1px solid #b4bbce;
  border-radius: 2px;
  opacity: 1;
  font-size: 10px;
  font-weight: 900;
  color: #838eab;
}
ssi-content-calendar ssi-calendar-month-view .cal-month-view .cal-days .cal-cell-row ssi-calendar-month-cell .day-cell-inner-wrapper .cell-bottom .posts-count-chip.has-posts span {
  background-color: #14bae3;
  border: none;
  color: #fff;
}
ssi-content-calendar ssi-calendar-month-view .cal-month-view .cal-header .cal-cell {
  background-color: #f4f4fa;
}
ssi-content-calendar ssi-context-menu .context-menu-container {
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.35);
}
ssi-content-calendar ssi-context-menu .context-menu-container .posts-preview-menu {
  background-color: #fff;
  border: 1px solid rgba(131, 142, 171, 0.35);
  border-radius: 10px;
  padding: 2px 2px 2px 2px;
  width: 300px;
}
ssi-content-calendar ssi-context-menu .context-menu-container .posts-preview-menu ssi-post-box {
  margin-bottom: 2px;
}
ssi-content-calendar .cell-add-icon,
ssi-content-calendar .cell-add-icon-left {
  display: none;
}