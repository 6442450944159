ssi-sentiment-card {
  display: flex;
  justify-content: space-between;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.25);
  padding: 2em;
  min-width: 12em;
  page-break-inside: avoid;
}
@media print {
  ssi-sentiment-card {
    margin: 10px 0 0;
    border: 1px solid black;
  }
}
ssi-sentiment-card ssi-big-number.message-count {
  font-size: 2.8em;
  color: #101525;
  font-weight: 900;
}
ssi-sentiment-card span {
  display: block;
  text-transform: lowercase;
  font-size: 1.4em;
  font-weight: 900;
  line-height: 0;
  color: #101525;
}
ssi-sentiment-card ssi-stat-change {
  display: flex;
}
ssi-sentiment-card .sentiment-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
ssi-sentiment-card .sentiment-left .upper {
  margin-bottom: 3em;
}
ssi-sentiment-card .sentiment-right i {
  font-size: 2.4em;
  border-radius: 50%;
  padding: 0.25em;
}