@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../angularjs/common/sass/mixins';
@import '../../publisher.variables.scss';
@import '../../publisher.mixins.scss';
@import '~/styles/colors';

$ssi-publisher-schedule-tp-responsive-width-bp-1: 1200px;
$ssi-publisher-schedule-tp-responsive-width-bp-2: 885px;
$ssi-publisher-schedule-tp-mobile-width: 768px;

ssi-publisher-schedule-time-picker {
  @media (min-width: $ssi-publisher-schedule-tp-responsive-width-bp-2) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .publisher-schedule-time-picker-inner {
    padding: 0 50px 0 30px;
  }

  ssi-highchart {
    display: block;

    .highcharts-background {
      fill: transparent;
    }

    .highcharts-plot-border {
      fill: $lmgrey-background !important;
    }

    .highcharts-axis-labels text {
      font-weight: 700;
    }
  }

  @include responsiveWidth(1200px) {
    .publisher-schedule-time-picker-inner {
      padding: 0 5px;
    }

    ssi-highchart {
      margin: 0 15px;
    }
  }

  .highcharts-background {
    fill: $publisher-bg;
  }

  .highcharts-point,
  .highcharts-label text tspan:nth-child(1) {
    opacity: 0.2;
  }

  .highcharts-point-select {
    opacity: 1;
  }

  .publisher-schedule-time-picker-footer {
    @include align-vertical(80px);
    justify-content: flex-end;
    margin-top: 44px;
    margin-bottom: 10px;
    color: #8b8e9d;

    @media (max-width: $ssi-publisher-schedule-tp-responsive-width-bp-1) {
      flex-wrap: wrap;
      height: auto;
      .publisher-schedule-time-picker-footer-date-wrap {
        flex-grow: 1;
      }
    }
    @media (max-width: $ssi-publisher-schedule-tp-responsive-width-bp-2) {
      margin-top: 0;
    }
    @media (max-width: $ssi-publisher-schedule-tp-mobile-width) {
      justify-content: center;
      .publisher-schedule-time-picker-footer-date-wrap {
        flex-grow: 0;
        margin-bottom: 10px;
      }
      .publisher-schedule-time-picker-footer-timepicker-wrap {
        margin-bottom: 10px;
      }
      .publisher-schedule-btn-wrap {
        width: 90%;
        .schedule-btn {
          width: 100%;
          border-radius: 10px;
        }
      }
    }

    .publisher-schedule-time-picker-footer-date-wrap {
      display: flex;
      background-color: $grey-background;
      border-radius: 100px;
      padding: 15px 20px;

      button {
        border-width: 0;
        background-color: transparent;
        line-height: 14px;

        &:focus {
          outline: revert;
        }
      }

      i {
        color: $primary-active;
        font-size: 12px;
      }
    }

    .publisher-schedule-time-picker-footer-date {
      margin: 1px 20px;
      color: $lmode-body-text;
      text-align: center;
      font-size: 12px;
      font-weight: 700;
      line-height: 14px;
    }

    .publisher-schedule-time-picker-footer-timepicker-wrap {
      ngb-timepicker {
        display: block;
        margin: 0 8px 0 12px;

        .chevron {
          color: $primary-active;
          font-size: 12px;

          &:before {
            font-weight: 700;
          }
        }

        .ngb-tp-input-container {
          .btn {
            padding: 3px 12px;

            &:focus {
              outline: revert;
            }
          }
        }

        .ngb-tp-input {
          background-color: $grey-background;
          color: $lmode-body-text;
          font-size: 12px;
          font-weight: 700;
          height: 46px;
        }

        .ngb-tp-hour {
          position: relative;

          .ngb-tp-input {
            border-top-left-radius: 100px;
            border-bottom-left-radius: 100px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }

          &:after {
            content: ':';
            position: absolute;
            right: -7px;
            top: 0;
            bottom: 2px;
            margin: auto;
            height: 18px;
            color: $lmode-body-text;
            font-size: 18px;
            line-height: 18px;
            font-weight: 700;
          }
        }

        .ngb-tp-minute {
          .ngb-tp-input {
            border-radius: 0;
          }
        }

        .ngb-tp-meridian {
          margin-left: -5px;

          .btn {
            background-color: $lmgrey-background;
            border-top-right-radius: 100px;
            border-bottom-right-radius: 100px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            color: $lmode-body-text;
            font-size: 12px;
            text-transform: lowercase;
            height: 46px;
            padding: 0 20px 0 15px;
            font-weight: 700;

            &:focus {
              outline: revert;
            }
          }
        }
      }
    }

    .form-control {
      border: 0;
      box-shadow: none;
      width: 60px;
    }
  }
}
