.orlo-button {
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  color: #fff;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 14px;
  font-weight: bold;
}
.orlo-button:focus {
  outline: 0;
}
.orlo-button.primary {
  background-color: #14bae3;
  margin-right: 2.5%;
}
.orlo-button.secondary {
  background-color: #434552;
}
.orlo-button.text {
  background: transparent;
  text-transform: uppercase;
  color: #43537f;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 0.8em;
}
.orlo-button:disabled {
  background-color: #cfd3de;
}

.orlo-table {
  margin: 0;
  border-radius: 25px;
  background-color: #fff;
  border: none !important;
  color: #43537f;
}
.orlo-table.striped tr:nth-child(even) {
  background-color: #fff;
}
.orlo-table.striped tr:nth-child(odd) {
  background-color: #f0f3f9;
}
.orlo-table tr {
  border-top: 2px solid #f0f3f9;
}
.orlo-table tr:first-child {
  border-top: none;
}
.orlo-table tr.has-delete:hover {
  box-shadow: 0px 0px 10px 5px #f3f3f3;
  border-radius: 10px;
  border-top-color: transparent;
}
.orlo-table tr.has-delete:hover + tr {
  border-top-color: transparent;
}
.orlo-table tr.has-delete a {
  position: relative;
  margin-right: 30px;
  margin-left: 15px;
}
.orlo-table tr.has-delete a i,
.orlo-table tr.has-delete a .icon-wrap {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 14px;
  color: #d4d7e2;
  line-height: 0;
}
.orlo-table tr.has-delete a i:hover, .orlo-table tr.has-delete a i.visible,
.orlo-table tr.has-delete a .icon-wrap:hover,
.orlo-table tr.has-delete a .icon-wrap.visible {
  padding: 19px;
  border-radius: 50px;
  background-color: #faeeeb;
  color: #e29e8c;
  top: -8px;
  left: -8px;
}
.orlo-table th {
  padding: 0 25px 25px 25px !important;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 900;
  line-height: 2.5em;
  color: #838eab;
  letter-spacing: 1px;
  box-sizing: border-box;
  border: none !important;
}
.orlo-table td {
  padding: 15px !important;
  border: none !important;
  word-wrap: break-word;
}
.orlo-table td.small {
  font-size: 0.85em;
}
.orlo-table td.centered {
  text-align: center;
}
.orlo-table tbody td:first-child {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.9em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 300px;
}
.orlo-table thead tr {
  background-color: #fff !important;
  border: none;
}
.orlo-table thead th {
  padding: 1em 15px !important;
  background-color: #f0f3f9;
  color: #43537f;
}
.orlo-table thead th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.orlo-table thead th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.orlo-text-input {
  width: 100%;
  margin: 0 0 0.4em 0;
  padding: 0.6em 1.6em;
  border-radius: 50px;
  background-color: #f0f3f9;
  border: 3px solid #f0f3f9;
  font-size: 16px;
  font-weight: 900;
  box-sizing: border-box;
}
.orlo-text-input:focus {
  border-color: #14bae3;
  outline: 0;
}
.orlo-text-input::placeholder {
  color: #838eab;
  font-style: italic;
  font-weight: 400;
}
.orlo-text-input.invalid {
  border-color: #fff1ed;
  background-color: #fff1ed;
  color: #f88c68;
}
.orlo-text-input.invalid::placeholder {
  color: #f88c68;
}
.orlo-text-input.no-right-radius {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.orlo-text-input.no-left-radius {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.orlo-input-submit {
  text-align: center;
  white-space: nowrap;
  background-color: #caceda;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border: none;
  padding: 1.4rem 1.6rem;
  margin-bottom: 0.5em;
  color: #fff;
  font-weight: bold;
}
.orlo-input-submit:hover {
  cursor: pointer;
}
.orlo-input-submit.active {
  background-color: #14bae3;
}
.orlo-input-submit i {
  margin-right: 0.5rem;
}

.orlo-select {
  margin: 0 0 0.4em 0;
  position: relative;
}
.orlo-select i {
  position: absolute;
  top: 45px;
  right: 20px;
  font-size: 20px;
  color: #14bae3;
}
.orlo-select select {
  width: 100%;
  height: 46px;
  padding: 0.6em 1.6em;
  border-radius: 50px;
  background-color: #f0f3f9;
  border: 3px solid #f0f3f9;
  font-size: 16px;
  font-weight: 700;
  box-sizing: border-box;
  box-shadow: none;
  appearance: none;
  color: #838eab;
  cursor: pointer;
}
.orlo-select select:focus {
  border-color: #14bae3;
  outline: 0;
}
.orlo-select select::placeholder {
  color: #838eab;
  font-style: italic;
  font-weight: 400;
}
.orlo-select select::-ms-expand {
  display: none;
}
.orlo-select select.invalid {
  border-color: #fff1ed;
  background-color: #fff1ed;
  color: #f88c68;
}
.orlo-select select.invalid::placeholder {
  color: #f88c68;
}
.orlo-select select[disabled] + i {
  color: #ced3df;
}

.orlo-modal .modal-content {
  border-radius: 25px;
  background-color: white;
  border: none;
  margin: auto;
}
.orlo-modal-1000 .modal-dialog {
  width: 1000px;
  max-width: 98%;
  margin-top: -5%;
}
.orlo-modal-1200 .modal-dialog {
  width: 1200px;
  max-width: 98%;
  margin-top: -5%;
}
.orlo-modal.position-top-20 {
  top: 20px;
}
.orlo-modal.position-top-20 .modal-dialog {
  margin-top: 0;
}

.orlo-modal-backdrop {
  background-color: rgb(16, 21, 37);
  opacity: 0.7 !important;
}

.orlo-modal-dark .modal-content {
  border-radius: 25px;
  background-color: #35353f;
  border: none;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #eff2f8 #14bae3;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 6px;
}

.custom-scrollbar-2::-webkit-scrollbar {
  width: 4px;
}

.custom-scrollbar-2 {
  scrollbar-width: thin;
  scrollbar-color: #ecf0fb #14bae3;
}

.custom-scrollbar-2::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 1px;
}

.orlo-textarea {
  width: 100%;
}
.orlo-textarea label {
  width: 100%;
  padding: 0 3em;
  display: block;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 900;
  line-height: 2.5em;
  color: #43537f;
  letter-spacing: 1px;
  box-sizing: border-box;
  margin-bottom: 5px;
}
.orlo-textarea textarea {
  width: 100%;
  margin: 0 0 0.4em 0;
  padding: 1.6em;
  border-radius: 10px;
  background-color: #f8f9fd;
  border: 3px solid transparent;
  font-size: 16px;
  font-weight: 700;
  box-sizing: border-box;
  color: #101525;
}
.orlo-textarea textarea:focus {
  border-color: #14bae3;
  outline: 0;
}
.orlo-textarea textarea::placeholder {
  color: #838eab;
  font-style: italic;
  font-weight: 400;
}
.orlo-textarea textarea.invalid {
  border-color: #fff1ed;
  background-color: #fff1ed;
  color: #f88c68;
}
.orlo-textarea textarea.invalid::placeholder {
  color: #f88c68;
}

.orlo-tickbox {
  display: flex;
  align-items: center;
  position: relative;
}
.orlo-tickbox input[type=checkbox] {
  position: absolute;
  opacity: 0;
  margin: 0;
  cursor: pointer;
}
.orlo-tickbox label {
  width: 100%;
  padding: 0 1em;
  display: block;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 900;
  line-height: 2.5em;
  color: #43537f;
  letter-spacing: 1px;
  box-sizing: border-box;
  margin-bottom: 5px;
}
.orlo-tickbox::before {
  display: block;
  margin-top: -6px;
  background-color: #f0f3f9;
  content: "";
  border-radius: 50px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.orlo-tickbox i {
  display: none;
}
.orlo-tickbox.selected label {
  color: #14bae3;
}
.orlo-tickbox.selected::before {
  color: #14bae3;
  background-color: #d0f1f9;
}
.orlo-tickbox.selected i {
  display: block;
  position: absolute;
  left: 2px;
  top: 2px;
  bottom: 0;
  font-size: 18px;
  color: #14bae3;
}
.orlo-tickbox:first-child {
  margin-right: 30px;
}

.cdk-overlay-container {
  z-index: 1060;
}

cdk-overlay-pane {
  width: 100%;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #eff2f8 #14bae3;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #eff2f8;
  border-radius: 6px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #14bae3;
  border-radius: 6px;
  border: 3px solid #14bae3;
}

.a11y-label {
  position: absolute;
  text-indent: -99999px;
  color: #000;
}

@media print {
  body > button {
    display: none !important;
  }
}
ssi-widget-big {
  font-size: 1rem;
}
ssi-widget-big .sentiments-area {
  padding-left: 15px;
}
ssi-widget-big .sentiments-area .sentiment-block {
  display: inline-flex;
  align-items: center;
  margin-right: 30px;
}
ssi-widget-big .sentiments-area .sentiment-color {
  display: inline-block;
  height: 4px;
  width: 20px;
  border-radius: 100px;
}
ssi-widget-big .sentiments-area .sentiment-label {
  margin-left: 7px;
  color: #43537f;
  font-weight: 900;
  font-size: 10px;
}
ssi-widget-big .big-chart-section {
  width: 100%;
  height: 100%;
}
ssi-widget-big .big-chart-section ssi-highchart {
  width: 100%;
  height: 100%;
}
ssi-widget-big .section-chart-wrap {
  width: 100%;
  height: 100%;
  padding: 3em 3em 6em 3em;
  background-color: #fff;
  border-radius: 1.2em;
  position: relative;
  display: flex;
  flex-direction: column;
}
ssi-widget-big .top-row {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4em;
}
ssi-widget-big .table-container {
  overflow: auto;
  height: 100%;
}
ssi-widget-big .theme-map-table {
  width: 100%;
  border-collapse: separate;
}
ssi-widget-big .theme-map-table .loading-overlay.full-height {
  height: 300px;
}
ssi-widget-big .theme-map-table .dropdown-table-header {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
ssi-widget-big .theme-map-table ssi-dropdown-select-2 button.dd-head {
  white-space: nowrap;
  font-size: 14px;
  width: 100%;
  background-color: #fff;
  border-right-color: transparent;
  padding: 11px;
}
ssi-widget-big .theme-map-table ssi-dropdown-select-2 button.dd-head span {
  color: #43537f;
}
ssi-widget-big .theme-map-table .t-header-row th:first-child {
  width: 150px;
}
ssi-widget-big .theme-map-table .t-header {
  color: #101525;
  font-size: 10px;
  font-weight: 900;
  padding: 1em 0.8em;
  border: 1px solid #d9dde6;
  border-left: none;
}
ssi-widget-big .theme-map-table .t-header span {
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 1.5em;
}
ssi-widget-big .theme-map-table .t-header .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
ssi-widget-big .theme-map-table .t-header .content-action {
  appearance: none;
  border-width: 0;
  background-color: transparent;
  color: #43537f;
}
ssi-widget-big .theme-map-table .t-header .content-action:focus, ssi-widget-big .theme-map-table .t-header .content-action:hover {
  color: #f88c68;
}
ssi-widget-big .theme-map-table .t-header .content-action .small-delete {
  color: inherit;
  font-size: 0.8em;
}
ssi-widget-big .theme-map-table .t-header.action-header {
  display: flex;
  justify-content: flex-end;
  height: 100%;
  font-weight: 900;
  padding: 0.3em;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-left: none;
  background-color: transparent;
}
ssi-widget-big .theme-map-table .t-header.blank:not(.action-header) {
  background-color: #fff;
  border-right-color: transparent;
}
ssi-widget-big .theme-map-table .t-header.blank:not(.action-header):last-child {
  border-left-color: transparent;
}
ssi-widget-big .theme-map-table .t-row {
  height: 40px;
}
ssi-widget-big .theme-map-table .table-cell-data {
  border: 1px solid #d9dde6;
  border-top: none;
  border-left: none;
  text-align: center;
}
ssi-widget-big .theme-map-table .table-cell-data div {
  color: black;
  font-size: 12px;
  font-weight: 900;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
ssi-widget-big .theme-map-table .table-cell-data.has-data {
  border-radius: 3px;
}
ssi-widget-big .theme-map-table .t-breakdown {
  color: #101525;
  cursor: default;
  font-size: 10px;
  font-weight: 900;
  fill: #101525;
  padding: 0 1em;
  border: 1px solid #d9dde6;
  border-left: none;
}
ssi-widget-big .theme-map-table .t-breakdown:first-child {
  border-top: none;
}
ssi-widget-big .theme-map-table.full-height {
  height: 100%;
}
ssi-widget-big .theme-map-table .theme-search-button {
  display: flex;
  align-items: center;
  color: #14bae3;
  font-weight: 900;
  font-size: 12px;
  padding: 10px;
  appearance: none;
  border: none;
  background-color: rgba(20, 186, 227, 0.1);
  border-radius: 8px;
}
ssi-widget-big .theme-map-table .theme-search-button:disabled {
  filter: grayscale(60%);
  cursor: not-allowed;
  opacity: 0.8;
}
ssi-widget-big .theme-map-table .theme-search-button i {
  font-size: 10px;
  margin-right: 7px;
}
ssi-widget-big .theme-map-table-prompt {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
ssi-widget-big .theme-map-table-prompt .ssi {
  color: #b4bbce;
  font-size: 40px;
  margin: 0 0 20px;
}
ssi-widget-big .theme-map-table-prompt h2 {
  color: #43537f;
  font-size: 20px;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-widget-big .theme-map-table-prompt p {
  color: #43537f;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  text-rendering: geometricPrecision;
}
ssi-widget-big .theme-map-table-prompt-wrap {
  max-width: 295px;
  padding: 20px 0 0;
  text-align: center;
}

.add-theme-window {
  background-color: #fff;
  border: 1px solid rgba(131, 142, 171, 0.35);
  border-radius: 15px;
  padding: 25px;
}
.add-theme-window h3 {
  color: #43537f;
  font-size: 14px;
  font-weight: 900;
  margin-bottom: 15px;
}
.add-theme-window .form-field input {
  background-color: #f8f9fd;
  border-radius: 10px;
  font-size: 14px;
}
.add-theme-window .form-field input::placeholder {
  color: #838eab;
  font-style: normal;
}
.add-theme-window label {
  width: 100%;
  padding: 0 3em;
  display: block;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 900;
  line-height: 2.5em;
  color: #43537f;
  letter-spacing: 1px;
  box-sizing: border-box;
}
.add-theme-window label i {
  margin-left: 3px;
}
.add-theme-window .action-buttons {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.add-theme-window .action-button {
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 20px;
  border-radius: 10px;
  border: 1px solid #c3c9d8;
  background-color: #fff;
  color: #43537f;
  font-size: 14px;
  font-weight: 900;
  line-height: 14px;
  text-align: center;
  white-space: pre-wrap;
  margin-left: 10px;
}
.add-theme-window .action-button:last-child {
  color: #fff;
  background-color: #14bae3;
}
.add-theme-window .action-button:disabled {
  color: #838eab;
  background-color: #f4f4fa;
  border-color: transparent;
}
.add-theme-window ssi-dropdown-select-2 button.dd-head {
  padding: 13px 30px;
  color: #101525;
  font-size: 14px;
  width: 100%;
}