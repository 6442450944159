@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../../angularjs/common/sass/include-media';
@import '../../../../../../../angularjs/common/sass/mixins.scss';
@import '~/styles/colors';

ssi-engagement-summary {
  .engagement-summary-container {
    .section-body {
      width: 100%;

      .facts-row {
        display: flex;
        width: 100%;
        justify-content: space-between;
        overflow-x: auto;

        &:first-child {
          // margin-bottom: 5px;
        }

        @media print {
          display: block;

          & > * {
            display: inline-block;
            width: auto !important;
          }
        }

        > * {
          width: 100%;
          padding: 7px;
          margin-left: 10px;
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }

  ssi-fact-overview {
    .fact-overview {
      box-shadow: 0 0.5em 1em rgba(131, 142, 171, 0.35);
      margin-bottom: 10px;
    }
  }
}
