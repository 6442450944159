@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-workflows {
  font-size: 1rem;

  .alert,
  .page-header,
  .panel {
    font-size: 1.4em;
  }

  .dropdown-menu {
    width: 300px;
  }

  .list-group-item-text {
    height: 70px;
  }
}
