@import '~/styles/colors';

// global - common styles for analytics module

.g-text {
  color: #101525;
  font-size: 14px;
  font-weight: 900;

  &.active {
    color: $primary-active;
  }

  &.secondary {
    color: #6a759a;
  }
}

.g-stat-box {
  //   width: 100%;
  padding: 5px;
  background-color: #fff;
  box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.25);
  border-radius: 5px;
  border: 1px solid #d6dff7;
}

// TODO: move out from analytics.scss as it's globally used
.g-list {
  display: inline-block;
  font-size: 14px;
  margin: 0;
  padding: 15px 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 1.8em 4em rgba(131, 142, 171, 0.35);
  border-width: 0;

  min-width: 200px;

  &-md {
    min-width: 340px;
  }

  &-lg {
    min-width: 400px;
  }

  .g-list-item {
    display: flex;
    align-items: center;
    height: 36px;
    padding: 0 10px 0 15px;
    border-radius: 1rem;
    margin: 2px 0;
    cursor: pointer;
    font-size: 14px;
    white-space: nowrap;
    overflow-y: auto;

    &:hover {
      background-color: $grey-background;
    }

    i {
      color: $lmode-body-text;
      margin: 0 10px 0 0;
      color: $lmode-helper-text;
    }

    .g-list-item-icon {
      margin: 0 10px 0 0;
    }

    span {
      font-weight: 900;
      text-rendering: geometricPrecision;
      color: $lmode-body-text;
    }

    &.active {
      span {
        color: $primary-active;
      }
    }
  }
}

// TODO: move out from analytics.scss as it's globally used
.g-btn {
  border: none;
  outline: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
  background-color: $primary-active;
  color: #fff;
  height: 40px;
  padding: 0 18px;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  // &:disabled {
  //   opacity: 1;
  //   background-color: #f4f4fa;
  //   color: #838eab;
  // }

  i {
    // font-weight: 700;
    // font-size: 12px;
    margin-right: 8px;
  }

  &.border-radius-100 {
    border-radius: 100px;
  }

  &.text-only-button {
    color: $primary-active;
    background-color: transparent;
    border: none;
    padding: 0 10px;

    &.underlined {
      text-decoration: underline;
    }
  }

  &.round-icon-only-button {
    font-size: 18px;
    font-weight: normal;
    width: 40px;
    padding: 0;
    border-radius: 50%;
    i {
      margin: 0;
    }
  }

  &.rounded-square-icon-only-button {
    font-size: 18px;
    font-weight: normal;
    width: 40px;
    padding: 0;
    border-radius: 10px;
    i {
      margin: 0;
    }
  }

  &-sm {
    font-size: 12px;
    height: 30px;
    &.round-icon-only-button {
      font-size: 12px;
      width: 30px;
    }
  }

  &-md {
    height: 46px;
    &.round-icon-only-button {
      width: 46px;
    }
  }

  &-wide {
    width: auto;
  }

  &-lg {
    height: 50px;
    &.round-icon-only-button {
      width: 50px;
    }
  }

  &.bg-red {
    background-color: #f88c68;
  }

  &.bg-light-red {
    background-color: #ffede7;
  }

  &.btn-text-red {
    color: #cc3300;
    font-weight: 900;
  }

  &-secondary {
    background-color: #ecf0fb;
    color: #43537f;

    i {
      color: #838eab;
    }

    &.text-only-button {
      color: #43537f;
    }

    &.bg-white {
      background-color: #fff;
      border: 1px solid #838eab;
    }

    &.bg-black {
      color: #fff;
      background-color: #212635;
      border: 1px solid #838eab;
    }

    &.bg-red {
      background-color: #fff1ed;
      border: 1px solid #f88c68;
      i {
        color: #f88c68;
      }
    }

    &.delete-btn {
      color: #fff;
      background-color: #f88c68;
      i {
        color: #fff;
      }
    }
  }
}

// TODO: move out from analytics.scss as it's globally used
.g-input {
  border: 1px solid #c3c9d8;
  border-radius: 10px;
  padding-left: 30px;
  padding-right: 30px;
  color: $lmode-title-text;
  font-style: normal;
  font-weight: 500;
  outline: none;
  position: relative;
  width: 100%;
  font-size: 14px;
  height: 46px;

  &:focus {
    border: 2px solid $primary-active;
  }

  &.underlined {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #838eab;

    &:focus {
      border-bottom: 2px solid $primary-active;
    }
  }

  &.no-border {
    border: none;
    border-radius: 0;

    &:focus {
      border-bottom: 2px solid $primary-active;
    }
  }

  &::placeholder {
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    color: #6a759a;
  }

  &.bg-black {
    background-color: #212635;
    color: #fff;
    border: 1px solid #838eab;
  }

  &-sm {
    font-size: 12px;
    height: 40px;

    &::placeholder {
      font-size: 12px;
    }
  }

  &-md {
    font-size: 14px;
    height: 46px;

    &::placeholder {
      font-size: 14px;
    }
  }
}

.g-count-badge-container {
  position: relative;

  .g-count-badge {
    position: absolute;
    top: -4px;
    right: -4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary-active;
    border-radius: 50%;
    width: 16px;
    height: 16px;

    span {
      color: #fff;
      font-size: 10px;
      font-weight: 900;
    }
  }
}

.g-hide-if-mobile {
  @media (max-width: 768px) {
    display: none;
  }
}

.g-hide-if-small-screen {
  @media (max-width: 885px) {
    display: none;
  }
}
.g-hide-if-large-screen {
  @media (min-width: 885px) {
    display: none;
  }
}

.g-overflow-hidden {
  overflow: hidden;
}

.g-margin-top {
  margin-top: 10px !important;
  &-20 {
    margin-top: 20px !important;
  }
  &-30 {
    margin-top: 30px !important;
  }
  &-40 {
    margin-top: 40px !important;
  }
}
