ssi-datetime-modal .header {
  width: 400px;
  text-align: center;
  margin: 0 auto 2em auto;
}
ssi-datetime-modal .header img {
  width: 12%;
  height: 12%;
  margin: 1.6em 0;
}
ssi-datetime-modal .header button {
  margin-top: 1.6em;
}
ssi-datetime-modal .header h2 {
  color: #5471d7;
  font-size: 1.4em;
  font-weight: 600;
  margin: 0 0 1.2em 0;
}
ssi-datetime-modal .header p {
  margin: 1em 0;
  font-size: 12px;
  color: #a6b7c1;
  font-weight: 500;
}
ssi-datetime-modal .exit {
  position: absolute;
  right: 15px;
  top: 15px;
  background-color: #eff2f8;
  border-radius: 100%;
  cursor: pointer;
}
ssi-datetime-modal .exit i {
  display: inline-block;
  padding: 10px;
  color: #838eab;
}
ssi-datetime-modal .datetime {
  position: relative;
  padding: 2em 0;
}
ssi-datetime-modal .flatpickr-months {
  padding-top: 0;
}
ssi-datetime-modal .flatpickr-next-month,
ssi-datetime-modal .flatpickr-prev-month {
  margin-top: 0 !important;
}
ssi-datetime-modal .flatpickr-day.selected {
  background-color: #14bae3;
  border-color: #14bae3;
}
ssi-datetime-modal .flatpickr-calendar {
  margin: 0 auto;
  box-shadow: none;
  margin-bottom: 2em;
}
ssi-datetime-modal .flatpickr-calendar .flatpickr-time {
  border: none !important;
}
ssi-datetime-modal .flatpickr-innerContainer {
  padding: 0;
}
ssi-datetime-modal input {
  display: none;
}
ssi-datetime-modal .footer {
  text-align: center;
}
ssi-datetime-modal .footer * {
  margin: 1em auto;
  display: block;
}