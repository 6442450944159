@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../angularjs/common/sass/variables';
@import '../../../../../angularjs/common/sass/mixins';
@import '~/styles/colors';

ssi-compose-area {
  display: block;
  margin-bottom: 10px;
  height: auto;

  .composer-textarea-container {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #fff;
    z-index: 2;
    line-height: 19px;
    border: 1px solid #c3c9d8;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    // overflow: hidden;

    .composer-text-form-control {
      width: 100%;
      height: 100%;
      min-height: 250px;
      flex: 1;
      background-color: transparent;
      z-index: 2;
      position: relative;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      resize: none;
      border: 0;
      padding: 38px 36px;
      outline: none;
      line-height: 19px;
      color: $lmode-title-text;
      font-size: 14px;
      font-weight: 700;

      &::placeholder {
        color: #6a759a;
      }
    }

    .no-accounts-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 2;
      padding: 80px;
      text-align: center;
      color: $lmode-body-text;

      i {
        font-size: 37px;
        color: $dmode-body-text;
        margin-bottom: 15px;
      }

      h4 {
        font-size: 20px;
        font-weight: 900;
        line-height: 24px;
        text-align: center;
      }

      p {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        text-align: center;
        padding: 0 20px;
        max-width: 250px;
      }
    }

    &.disabled {
      background-color: #f0f3f9;
      border: none;
    }

    .text-highlight-element {
      pointer-events: none;
      background: transparent;
      z-index: 2;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      width: 100% !important;
    }

    .text-highlight-tag {
      text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
      color: $primary-active;
      text-decoration: underline;
    }

    &.active {
      border-color: $primary-active;
    }
  }

  .composer-textarea-footer {
    z-index: 5;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 5px;

    .composer-textarea-footer-right-icon {
      display: flex;
      align-items: center;
    }

    img {
      width: auto;
      height: 16px;
    }

    i {
      font-size: 16px;
      color: #6a759a;
    }

    .attachment-type-list {
      li {
        i {
          font-size: 14px;
          &.video-icon {
            font-size: 16px;
          }
        }
      }
    }

    .location-menu {
      .menu-body {
        position: absolute;
        width: 300px;
        border-radius: 15px;
        box-shadow: 0 5px 15px rgba(116, 118, 132, 0.25);
        overflow: hidden;
        padding: 15px;
        max-height: 320px;
        overflow-y: scroll;
        ul,
        li {
          list-style: none;
          padding: 0;
          margin: 0;
          font-size: 14px;
          font-weight: 900;
          line-height: 40px;
          text-align: left;
          color: $lmode-body-text;

          i {
            font-size: 14px;
            color: $lmode-body-text;
            margin-right: 7px;

            &.video-icon {
              font-size: 16px;
            }
          }
        }

        ul {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        li {
          padding: 2px 15px;
          border-radius: 15px;

          &:hover {
            cursor: pointer;
            background-color: $grey-background;
          }
        }
      }

      .location-search-container {
        position: relative;
        width: 100%;
        border: 1px solid #c3c9d8;
        border-radius: 10px;

        input {
          width: 100%;
          border: 0;
          border-radius: 10px;
          outline: 0;
          padding: 8px 20px;
          color: $lmode-title-text;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          font-family: 'Lato', 'Arial', sans-serif;

          // &:focus {
          //   border-bottom: 2px solid $primary-active;
          // }

          &::placeholder {
            font-size: 14px;
            font-weight: 700;
            font-style: normal;
            color: #6a759a;
          }
        }

        i {
          position: absolute;
          color: #6a759a;
          top: 13px;
          right: 12px;
          font-size: 12px;
          font-weight: 700;

          &.active {
            color: $primary-active;
          }
        }

        hr {
          margin: 0 -50px;
          background-color: #c3c9d8;
          height: 1px;
        }
        .location-selected {
          position: absolute;
        }
      }

      .location-default {
        padding: 40px 60px;
        text-align: center;
        min-height: 200px;
        i {
          font-size: 30px;
          margin-bottom: 10px;
        }
        h3,
        p {
          color: $lmode-body-text;
          font-weight: 700;
        }
      }

      .location-tags {
        min-height: 200px;
        p {
          padding: 60px;
          text-align: center;
        }
        span {
          display: inline-block;
          max-width: 215px;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .select-circle {
          margin: 10px 0 0 10px;
          padding: 2px;
          display: inline-block;
          width: 18px;
          height: 18px;
          border: 1px solid $lmode-helper-text;
          border-radius: 100%;
          position: relative;
          &.selected {
            border-color: $primary-active;
            &::after {
              position: absolute;
              content: '';
              margin: 1px;
              width: 10px;
              height: 10px;
              background-color: $primary-active;
              border-radius: 100%;
            }
          }
        }
        ul {
          li {
            display: flex;
            width: 100%;
            padding-top: 8px;
            cursor: pointer;
            &.selected {
              color: $primary-active;
            }
          }
        }
      }
      ssi-spinner {
        position: absolute;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: 60%;
      }
    }
  }

  .composer-video-title-container {
    background-color: #fff;
    font-family: 'Lato', sans-serif;
    display: flex;
    flex-direction: row;
    border-top: 1px solid #c3c9d8;
    border-left: 1px solid #c3c9d8;
    border-right: 1px solid #c3c9d8;

    span {
      display: flex;
      align-items: center;
      border: 0;
      padding: 0.4em 0.3em 0.4em 1.5em;
      color: #6a759a;
      background-color: #ffffff;
      z-index: 2;
      i {
        font-size: 22px;
      }
    }

    input {
      border: 0;
      font-family: Lato, sans-serif;
      font-weight: 700;
      color: #101525;
      z-index: 1;
    }

    input:focus {
      outline: none;
    }
  }

  .composer-video-title-container:focus-within {
    border: 1px solid #14bae3;
  }

  .composer-video-title-form-control {
    font-weight: normal;

    &::placeholder {
      color: #6a759a;
    }
  }

  ssi-emoji-form-control-trigger {
    display: flex;
    align-items: center;
  }

  .post-controls {
    display: flex;
    z-index: 2;
    align-items: center;
    padding: 5px;
    height: 46px;
    border: 1px solid #c3c9d8;
    background-color: #fff;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom: none;

    .network-post-controls-select {
      margin-right: 5px;
    }

    .toggle-label {
      height: 36px;
      padding: 0 8px 0 12px;
      margin-left: auto;

      p {
        font-size: 12px;
      }
    }

    ssi-checkbox {
      .checkbox-container {
        label {
          padding: 8px 20px;

          .leading-icon {
            font-size: 12px;
          }
          .check-icon {
            top: 12px;
          }
        }
        &:not(.active) {
          background-color: #fff;
        }
      }
    }

    &.active {
      border-color: $primary-active;
    }
  }

  .post-control-item {
    padding: 6px;
    .menu-head {
      .menu-trigger-icon {
        font-size: 16px;
        padding: 0;
      }
    }
    .location-selected {
      padding: 6px 10px;
      background-color: #f4f4fa;
      border-radius: 8px;
      span {
        font-weight: 900;
        color: #101525;
        padding: 0 10px 0 9px;
      }
      i {
        color: #43537f;
      }
      .ssi-delete-close {
        position: relative;
        top: -2px;
        font-size: 8px;
        cursor: pointer;
      }
    }
  }

  .post-controls-reply {
    margin-right: 4px;

    ssi-dropdown-select-2 {
      .dd-head {
        height: 36px;
        line-height: 36px;
        padding: 0 14px;

        .button-icon {
          color: #43537f;
          right: 14px;
        }
      }

      .dd-body {
        max-width: 300px;

        .body-header-container {
          padding: 0;
          text-rendering: geometricPrecision;
        }

        ul {
          margin-top: 20px;

          li {
            text-rendering: geometricPrecision;
          }
        }
      }
    }

    .custom-head-button-label {
      color: #43537f;

      i {
        margin-right: 5px;
        font-size: 14px;
      }

      span {
        font-size: 12px;
        font-weight: 900;
      }
    }

    .menu-header {
      padding: 20px 20px 20px 0;
      width: 280px;
      color: #43537f;
      font-size: 14px;

      strong {
        font-weight: 900;
        i {
          margin-right: 5px;
        }
      }

      div {
        font-weight: 700;
        margin-top: 3px;
      }
    }
  }

  .account-controls {
    display: flex;
    align-items: center;
    padding: 5px;
    border-top: 1px solid #c3c9d8;

    .acc-control-button {
      display: flex;
      align-items: center;
      outline: none;
      appearance: none;
      border: 1px solid #c3c9d8;
      background-color: #fff;
      padding: 10px;
      border-radius: 8px;
      margin-right: 5px;

      i {
        color: $primary-active;
      }

      span {
        margin-left: 5px;
        color: $lmode-body-text;
        font-size: 12px;
        font-weight: 900;
        line-height: 12px;
        text-align: left;
      }

      &.network-select {
        padding: 9px;
      }

      &.active {
        background-color: rgba(20, 186, 227, 0.1);
        border-color: $primary-active;
      }

      &.post-errored {
        background-color: #ff4d11;
        border-color: #ff4d11;

        i {
          color: #fff;
        }
      }
    }

    span {
      .acc-control-button {
        padding: 9px;
      }
    }

    &.active {
      border-color: $primary-active;
    }

    .network-accounts-list {
      max-height: 280px;
      overflow-y: auto;
    }
  }

  .composer-textarea-character-count {
    color: $primary-active;
    font-size: 12px;
    font-weight: 900;
    line-height: 14.4px;
  }

  .composer-mention-click-context-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 160px;
    padding: 8px;
    border-radius: 10px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);

    button {
      border: 0;
      outline: 0;
      padding: 8px 10px;
      border-radius: 20px;
      background: #ffffff;
      font-weight: 900;
      color: $lmode-body-text;
      cursor: pointer;
      &:hover {
        color: $primary-active;
        background-color: $grey-background;
      }
    }

    .vertical-line {
      height: 20px;
      width: 2px;
      background-color: rgba(180, 187, 206, 0.5);
      margin: 0 5px;
    }

    a {
      padding: 5px 10px;
      border-radius: 50%;
      color: $lmode-body-text;
      cursor: pointer;
      &:hover {
        color: $primary-active;
        background-color: $grey-background;
      }
    }
  }

  .ai-writing-tool-context-menu {
    position: absolute;
    background-color: #fff;
    bottom: -1.4em;
    right: 0;
    display: block;
    font-size: 1em;
    margin: 0 -0.4em 0 0;
    padding: 1.8em 0;
    border-radius: 1.5em;
    box-shadow: 0 1.8em 4em rgb(131 142 171 / 35%);
    border-width: 0;
    min-width: 18em;

    .menu-item {
      display: flex;
      align-items: center;
      height: 3.6rem;
      padding: 0 2em 0 1rem;
      border-radius: 1rem;
      margin: 0.2rem 2rem;
      cursor: pointer;

      i {
        color: $lmode-body-text;
        font-size: 1.4rem;
        margin: 0 1.5rem 0 0;
        color: $lmode-helper-text;
      }

      span {
        font-size: 1.4rem;
        font-weight: 900;
        text-rendering: geometricPrecision;
        color: $lmode-body-text;
      }

      &:hover {
        background-color: $grey-background;
      }
    }

    hr {
      border-top: 1px solid #c3c9d8;
    }

    a:hover {
      text-decoration: none;
    }

    ssi-icon {
      margin-right: 1.5rem;
    }
  }
}
