@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../angularjs/common/sass/variables';
@import '../../../../angularjs/common/sass/mixins';

$accent-blue: #5B7CEE;
$plugin-width: 280px;

ssi-plugin {
    display: block;
    width:$plugin-width;
    height: 100%;
    position: fixed;
    top:0;
    right:-$plugin-width;
    z-index: 9999;
    iframe{
        border:none;
    }
}

.plugin-background {
    visibility: hidden;
    position: absolute;
    width: 0;
    height: 0;
}

.plugin-container, .plugin, .plugin-window{
    position: relative;
    height:100%;
    width:100%;
    
}

.plugin {
    position: absolute;
    right: $plugin-width;
}

.plugin-container{
    right: 0;
    top:0px;
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.2);
    transition: all 0.2s ease-out;
    &.open{
        right:$plugin-width;
    }
    &.pinned{
        box-shadow:none;
        top:68px;
    }
}

.plugin-close{
    top: 22px;
    left: 30px;
}

.plugin-settings{
    top: 22px;
    left: 75px;
}

.plugin-button{
    position: absolute;
    padding:12px;
    z-index: 10000;
    border:none;
    border-radius:100%;
    background-color: $accent-blue;
    line-height: 0;
    color: $text-light;
}