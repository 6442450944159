@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-content-generator {
  font-size: 1rem;

  .alert {
    font-size: 1.4em;
  }

  label {
    font-size: 1.4em;
  }

  .cg-empty-text {
    text-align: center;
    font-size: 1.8em;
    line-height: 2;
    font-weight: 700;
  }

  .article-grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1em 2.4em;

    &-item {
      flex: 1 0 0%;
      width: 100%;
      margin: 0 1em;
    }
  }

  @supports (display: grid) {
    .article-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(26.8em, 1fr));
      grid-gap: 0 2em;
      margin: 0;

      &-thirds {
        grid-template-columns: repeat(auto-fit, minmax(36.4em, 1fr));
      }

      &-halves {
        grid-template-columns: repeat(auto-fit, minmax(55.6em, 1fr));
      }

      .section-grid-item {
        margin: 0;
      }
    }
  }
}
