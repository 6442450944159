@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-outbox-post-new-stat {
  font-size: 1rem;

  .opns {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 14em;
    background-color: #f8f9fd;
    height: 10.6em;
    border-radius: 1.2em;
    margin: 0 0 1em;
    padding: 0 1.3em;
  }

  .opns-header {
    color: #838eab;
    text-rendering: geometricPrecision;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    h5 {
      font-size: 1em;
      font-weight: 900;
      margin: 0.6em 0 0;
    }
  }

  .opns-divider {
    border-radius: 10em;
    width: 4.6em;
    height: 0.4em;
    background-color: #9fd700;
    border-width: 0;
    margin: 1em 0 0.8em;
  }

  .opns-stat {
    color: #101525;
    text-rendering: geometricPrecision;

    p {
      font-size: 2em;
      font-weight: 900;
      margin: 0;
    }
  }
}
