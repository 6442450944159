ssi-publisher-landing {
  display: flex;
  height: 100%;
}
@media (max-width: 885px) {
  ssi-publisher-landing {
    flex-direction: column;
    align-items: center;
  }
}
ssi-publisher-landing ssi-dropdown-select {
  z-index: 4;
}
ssi-publisher-landing ssi-dropdown-select.dd-active {
  z-index: 5;
}
ssi-publisher-landing .publisher-landing-left,
ssi-publisher-landing .publisher-landing-right {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
}
@media (max-width: 885px) {
  ssi-publisher-landing .publisher-landing-left,
ssi-publisher-landing .publisher-landing-right {
    align-items: center;
  }
}
ssi-publisher-landing .publisher-landing-left {
  z-index: 1 !important;
  background-color: transparent;
  padding: 0;
}
@media (max-width: 885px) {
  ssi-publisher-landing .publisher-landing-left {
    margin: 10px 0px;
    width: 100vw;
  }
}
@media (min-width: 885px) {
  ssi-publisher-landing .publisher-landing-left {
    margin: 40px 10px 20px 20px;
    flex: 1 1 52%;
  }
}
@media (max-width: 885px) {
  ssi-publisher-landing .publisher-landing-right {
    width: 100vw;
  }
}
@media (min-width: 885px) {
  ssi-publisher-landing .publisher-landing-right {
    margin: 40px 20px 20px 10px;
    flex: 1 1 48%;
  }
}
ssi-publisher-landing .publisher-textarea-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #fff;
  z-index: 0;
  line-height: 19px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
@media (max-width: 885px) {
  ssi-publisher-landing .publisher-textarea-container {
    width: 95vw;
  }
}
ssi-publisher-landing .publisher-textarea-container .publisher-link-tag-primary {
  color: #14bae3;
  text-decoration: underline;
}
ssi-publisher-landing .publisher-textarea-container .publisher-link-tag-primary.publisher-link-tag-hover {
  background-color: #f0f3f9;
  text-shadow: 0 0 transparent !important;
}
ssi-publisher-landing .publisher-textarea-container .publisher-link-tag-primary.publisher-link-tag-hover.publisher-link-tag-hover {
  background-color: #ecf0f8;
}
ssi-publisher-landing .publisher-textarea-container .publisher-link-tag-warning {
  color: #14bae3;
  text-decoration: underline;
}
ssi-publisher-landing .publisher-textarea-container .publisher-link-tag-warning.publisher-link-tag-hover {
  background-color: #f0f3f9;
  text-shadow: 0 0 transparent !important;
}
ssi-publisher-landing .publisher-textarea-container .publisher-link-tag-warning.publisher-link-tag-hover.publisher-link-tag-hover {
  background-color: #ecf0f8;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer {
  display: flex;
  background-color: #fff;
  padding: 20px;
  z-index: 5;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer.mobile-device {
  padding: 10px;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer.publisher-textarea-footer-addl-if-mobile {
  padding: 10px;
  border-top: 1px solid #c6cfe4;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-left {
  flex: 1;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-left .btn {
  border-radius: 10px;
  border: 0;
  line-height: 24px;
  margin-right: 3px;
  height: 42px;
  min-width: 42px;
  padding: 0;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-left .btn:focus {
  outline: revert;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-left .btn.btn-primary {
  background-color: #14bae3;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-left .btn.dropdown-toggle {
  padding: 0 9px;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-left .btn.dropdown-toggle .ssi {
  font-size: 14px;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-left .btn.dropdown-toggle .dropdown-toggle-meta {
  font-size: 11px;
  font-weight: 900;
  margin: 0 8px 0 0;
  text-rendering: geometricPrecision;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-left .btn.dropdown-toggle .caret {
  border-top: 5px dashed;
  border-top: 5px solid;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  margin: 0 2px 0 0;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-left .btn.dropdown-toggle + .dropdown-menu {
  min-width: 230px;
  padding: 20px 15px;
  border-radius: 10px;
  box-shadow: 0 0 18px rgba(131, 142, 171, 0.35);
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-left .btn.dropdown-toggle + .dropdown-menu .dropdown-item {
  width: 100%;
  overflow: hidden;
  word-wrap: none;
  text-overflow: ellipsis;
  color: #43537f;
  font-size: 14px;
  line-height: 36px;
  font-weight: 900;
  height: 36px;
  padding: 0 20px;
  border-radius: 200px;
  margin: 0 0 4px;
  text-rendering: geometricPrecision;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-left .btn.dropdown-toggle + .dropdown-menu .dropdown-item.active {
  background-color: #14bae3 !important;
  color: #fff;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-left .btn.dropdown-toggle + .dropdown-menu .dropdown-item:last-child {
  margin: 0;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-left .btn i {
  font-size: 16px;
  line-height: 30px;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-left .btn i.ssi-first-comment {
  font-size: 20px;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-left .btn-left-action {
  background-color: #f0f3f9;
  color: #14bae3;
  font-size: 12px;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-left .btn-left-action-active {
  background-color: #14bae3;
  color: #fff;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-left .btn-split-post {
  background-color: #f0f3f9;
  color: #14bae3;
  font-size: 12px;
  font-weight: 900;
  border-radius: 10px;
  width: auto;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-left .btn-split-post .ssi {
  margin: 0 6px 0 0;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-left .btn-split-post.btn-split-post-action {
  padding: 0 16px;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-left [ngbDropdown].show {
  display: inline-block !important;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-left .split-post-account-select-menu {
  overflow-y: auto;
  max-height: 230px;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-right {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  justify-content: flex-end;
  margin-top: 10px;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-right .publisher-textarea-character-count {
  font-weight: 700;
  font-style: italic;
  margin-right: 5px;
  font-size: 14px;
  color: #657396;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-right .publisher-textarea-character-count.active {
  color: #14bae3;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-right .publisher-textarea-character-count.invalid {
  color: #e36635;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-right .publisher-textarea-footer-right-icon {
  color: #c0cad2;
  opacity: 1 !important;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-right .publisher-textarea-footer-right-icon:focus {
  outline: revert;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-right .label {
  background-color: #c0cad2;
  line-height: 18px;
  border-radius: 15px;
  margin-top: 2px;
  font-style: italic;
  font-weight: 300;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-right .label i {
  font-size: 15px;
  position: relative;
  top: 2px;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-right .label.active,
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-right .label:hover {
  background-color: #14bae3;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-right .label,
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-right .publisher-textarea-footer-right-icon {
  margin: 0 5px;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-right .publisher-textarea-footer-right-icon {
  font-size: 20px;
  color: #838eab;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-right .publisher-textarea-footer-right-icon:hover {
  color: #14bae3;
  text-decoration: none;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-right .add-more {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ecf0fb;
  color: #14bae3;
  border-radius: 15px;
  padding: 5px 7px;
  font-size: 11px;
  font-weight: bold;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-right .add-more i {
  font-size: 15px;
  margin-left: 4px;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-right .add-more i:first-child {
  font-size: 11px;
  font-weight: bold;
  margin-right: 5px;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-right .ai-writing-tool-context-menu {
  position: absolute;
  background-color: #fff;
  bottom: 15px;
  right: 15px;
  display: block;
  font-size: 1em;
  margin: 0;
  padding: 1.8em 0;
  border-radius: 1.5em;
  box-shadow: 0 1.8em 4em rgba(131, 142, 171, 0.35);
  border-width: 0;
  min-width: 18em;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-right .ai-writing-tool-context-menu .menu-item {
  display: flex;
  align-items: center;
  height: 3.6rem;
  padding: 0 2em 0 1rem;
  border-radius: 1rem;
  margin: 0.2rem 2rem;
  cursor: pointer;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-right .ai-writing-tool-context-menu .menu-item i {
  color: #43537f;
  font-size: 1.4rem;
  margin: 0 1.5rem 0 0;
  color: #838eab;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-right .ai-writing-tool-context-menu .menu-item span {
  font-size: 1.4rem;
  font-weight: 900;
  text-rendering: geometricPrecision;
  color: #43537f;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-right .ai-writing-tool-context-menu .menu-item:hover {
  background-color: #f8f9fd;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-right .ai-writing-tool-context-menu hr {
  border-top: 1px solid #c3c9d8;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-right .ai-writing-tool-context-menu a:hover {
  text-decoration: none;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-right .ai-writing-tool-context-menu ssi-icon {
  margin-right: 1.5rem;
}
ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer-right .ai-writing-tool-icon {
  font-size: 18px;
  margin-left: 5px;
  background-color: #f2f4fc;
  border-radius: 10px;
  padding: 10px;
}
ssi-publisher-landing .publisher-textarea-container .no-accounts-wrap {
  display: none;
}
ssi-publisher-landing .publisher-textarea-container.disabled textarea,
ssi-publisher-landing .publisher-textarea-container.disabled .publisher-textarea-footer {
  background-color: #f7f8fc;
}
ssi-publisher-landing .publisher-textarea-container.disabled textarea {
  text-align: center;
  padding-top: 120px;
}
ssi-publisher-landing .publisher-textarea-container.disabled .no-accounts-wrap {
  display: block;
  position: absolute;
  z-index: 2;
  top: 35%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  color: #b4bbce;
}
ssi-publisher-landing .publisher-textarea-container.disabled .no-accounts-wrap p {
  color: #67759c;
  font-weight: 700;
  margin: 10px 0 20px;
}
ssi-publisher-landing .publisher-textarea-container.disabled .no-accounts-wrap .ssi {
  display: block;
  font-size: 40px;
  color: inherit;
}
@media (max-width: 885px) {
  ssi-publisher-landing .publisher-textarea-container.disabled .no-accounts-wrap {
    padding: 15px;
  }
}
ssi-publisher-landing .text-highlight-element {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100% !important;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  ssi-publisher-landing .text-highlight-element {
    width: 100% !important;
    height: 201px !important;
  }
}
ssi-publisher-landing .text-input-highlight-container .text-highlight-element {
  pointer-events: none;
  background: transparent;
  z-index: 2;
}
ssi-publisher-landing .text-input-highlight-container .text-highlight-element .text-highlight-tag {
  text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
}
ssi-publisher-landing .publisher-text-form-control {
  width: 100%;
  height: 100%;
  flex: 1;
  background-color: transparent;
  z-index: 2;
  padding-top: 12px;
  position: relative;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  resize: none;
  border: 0;
  padding: 38px 36px;
  outline: none;
  line-height: 19px;
}
ssi-publisher-landing .publisher-text-form-control,
ssi-publisher-landing .publisher-video-title-form-control {
  font-weight: normal;
  color: #101525;
}
@media (max-width: 885px) {
  ssi-publisher-landing .publisher-text-form-control,
ssi-publisher-landing .publisher-video-title-form-control {
    width: 95vw;
  }
}
ssi-publisher-landing .publisher-text-form-control::placeholder,
ssi-publisher-landing .publisher-video-title-form-control::placeholder {
  color: #838eab;
  font-weight: bold;
}
ssi-publisher-landing .publisher-video-title-form-control,
ssi-publisher-landing .publisher-video-title-form-control:focus {
  border-bottom: solid 3px #eef0f2;
}
ssi-publisher-landing .publisher-btns {
  margin-bottom: 8px;
  z-index: 1;
}
@media (max-width: 885px) {
  ssi-publisher-landing .publisher-btns {
    flex-direction: column;
    width: 95vw;
  }
}
ssi-publisher-landing .publisher-btns .btn {
  border-radius: 20px;
  padding: 13px;
  font-weight: 700;
}
ssi-publisher-landing .publisher-btns .btn:focus {
  outline: revert;
}
@media (max-width: 885px) {
  ssi-publisher-landing .publisher-btns .btn {
    padding: 10px 15px 10px 25px;
    text-align: left;
  }
}
ssi-publisher-landing .publisher-btns .btn i.pull-right {
  position: relative;
  top: 3px;
}
ssi-publisher-landing .publisher-btns .btn span i.fa-youtube-play {
  font-size: 23px;
  vertical-align: bottom;
}
ssi-publisher-landing .publisher-btns .dropdown-select-menu {
  border-radius: 20px;
  background-color: #14bae3;
}
@media (min-width: 885px) {
  ssi-publisher-landing .publisher-btns .accounts-dd {
    margin-right: 8px;
  }
}
ssi-publisher-landing .publisher-btns .accounts-dd .dropdown-select-menu-item {
  background-color: #14bae3;
  border: 0;
}
ssi-publisher-landing .publisher-btns .accounts-dd .dropdown-select-menu-item:hover {
  background-color: #11acd3;
}
@media (min-width: 885px) {
  ssi-publisher-landing .publisher-btns .campaigns-dd {
    margin-left: 8px;
  }
}
ssi-publisher-landing .publisher-btns .campaigns-dd .dropdown-select-menu-item {
  background-color: #57576a;
  border: 0;
}
ssi-publisher-landing .publisher-btns .tags-select-container {
  position: relative;
}
ssi-publisher-landing .publisher-btns .tags-select-container .tags-selector-btn {
  width: 48px;
  height: 48px;
  background-color: #707f8b;
  margin-left: 8px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
ssi-publisher-landing .publisher-btns .tags-select-container .tags-selector-btn .ssi-tags {
  color: #fff;
  font-size: 28px;
  font-weight: 500;
}
ssi-publisher-landing .publisher-btns .tags-select-container .tags-selector-btn:hover {
  background-color: #59656f;
}
ssi-publisher-landing .publisher-btns .tags-select-container .tags-selector-btn span {
  display: none;
}
@media (max-width: 884px) {
  ssi-publisher-landing .publisher-btns .tags-select-container .tags-selector-btn span {
    display: block;
    color: #fff;
    font-weight: 700;
  }
}
@media (max-width: 884px) {
  ssi-publisher-landing .publisher-btns .tags-select-container .tags-selector-btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 25px;
    margin: 0;
    height: 42px;
  }
}
ssi-publisher-landing .publisher-btns .tags-select-container .selected-tags-count {
  position: absolute;
  top: -4px;
  right: -4px;
  width: 18px;
  height: 18px;
  border: 1px solid #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #707f8b;
}
ssi-publisher-landing .publisher-btns .tags-select-container .selected-tags-count span {
  font-size: 10px;
  color: #fff;
  font-weight: 700;
}
ssi-publisher-landing .publisher-btns .tags-select-container .no-tags-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 0 10px;
}
ssi-publisher-landing .publisher-btns .tags-select-container .no-tags-state i {
  margin-top: 20px;
  color: #b4bbce;
  font-size: 30px;
}
ssi-publisher-landing .publisher-btns .tags-select-container .no-tags-state .title {
  margin-top: 15px;
  color: #43537f;
  font-size: 16px;
  font-weight: 900;
}
ssi-publisher-landing .publisher-btns .tags-select-container .no-tags-state .desc {
  margin-bottom: 20px;
  color: #43537f;
  font-size: 14px;
  font-weight: 700;
  padding: 0 24px;
  text-align: center;
}
ssi-publisher-landing .publisher-btns .tags-select-container .no-tags-state button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  width: 100%;
}
ssi-publisher-landing .publisher-btns .tags-select-container .no-tags-state button i {
  margin: 0;
  margin-left: 10px;
  font-size: 12px;
  color: #fff;
}
ssi-publisher-landing .publisher-btns .tags-select-container .no-tags-state button span {
  white-space: nowrap;
}
ssi-publisher-landing .publisher-btns .publisher-close-compose {
  display: none;
}
@media (max-width: 884px) {
  ssi-publisher-landing .publisher-btns .publisher-close-compose {
    display: flex;
  }
}
ssi-publisher-landing .publisher-btns .publisher-close-compose .publisher-close-btn {
  width: 100%;
  height: 42px;
  text-align: center;
  border-radius: 10px;
  margin: 2px;
}
ssi-publisher-landing .publisher-textarea-container-badge {
  position: absolute;
  background-color: #b2c614;
  padding: 4px 9px;
  top: 10px;
  right: 15px;
  z-index: 3;
  font-size: 10px;
  line-height: 12px;
  color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 900;
  text-rendering: geometricPrecision;
  border-radius: 100px;
}
ssi-publisher-landing .publisher-tooltip-window {
  width: 670px;
  background-color: #242a3e;
  padding: 20px;
  border-radius: 10px;
  max-width: none;
  z-index: 99999999;
}
@media (max-width: 885px) {
  ssi-publisher-landing .publisher-tooltip-window {
    max-width: 99vw;
  }
}
ssi-publisher-landing .publisher-tooltip-window,
ssi-publisher-landing .publisher-tooltip-window .form-control,
ssi-publisher-landing .publisher-tooltip-window .input-group-addon,
ssi-publisher-landing .publisher-tooltip-window .btn {
  font-size: 12px;
}
ssi-publisher-landing .publisher-tooltip-window .btn {
  font-weight: 700;
  min-width: 100px;
}
ssi-publisher-landing .publisher-tooltip-window .form-control-wrap {
  display: flex;
  align-items: center;
  background-color: #101525;
}
ssi-publisher-landing .publisher-tooltip-window .form-control {
  background-color: #101525;
  font-weight: normal;
  border-radius: 3px;
  color: #fff;
}
ssi-publisher-landing .publisher-tooltip-window .form-control::placeholder {
  color: #b4bbce;
  font-weight: 700;
  text-rendering: geometricPrecision;
}
ssi-publisher-landing .publisher-tooltip-window .btn-primary {
  background-color: #14bae3;
  border-color: #14bae3;
}
ssi-publisher-landing .publisher-tooltip-window .btn-primary:disabled {
  background-color: #303449;
  border-color: #303449;
}
ssi-publisher-landing .publisher-tooltip-window .input-group-addon,
ssi-publisher-landing .publisher-tooltip-window input.form-control,
ssi-publisher-landing .publisher-tooltip-window .btn {
  height: 30px;
}
ssi-publisher-landing .publisher-tooltip-window .text-danger {
  color: #e36635;
}
ssi-publisher-landing .info-wrap {
  border-right: 2px solid #101525;
  border-top: 2px solid #101525;
  border-bottom: 2px solid #101525;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  height: 30px;
  transition: border-color 0.15s ease-in-out;
}
ssi-publisher-landing .info {
  display: inline-block;
  height: 16px;
  width: 16px;
  line-height: 16px;
  vertical-align: middle;
  text-align: center;
  font-size: 12px;
  color: #838eab;
  border-radius: 100%;
}
ssi-publisher-landing .info.info-light:hover {
  background-color: #303449;
}
ssi-publisher-landing .info.info-dark:hover {
  background-color: #242a3e;
}
ssi-publisher-landing .publisher-link-config-tooltip-wrap {
  width: 100%;
}
@media (max-width: 885px) {
  ssi-publisher-landing .publisher-link-config-tooltip-wrap {
    max-width: 100vw;
  }
}
ssi-publisher-landing .publisher-link-config-tooltip-top {
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px;
}
@media (max-width: 885px) {
  ssi-publisher-landing .publisher-link-config-tooltip-top {
    flex-wrap: wrap;
  }
}
ssi-publisher-landing .publisher-link-config-togglers {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}
ssi-publisher-landing .publisher-link-config-toggler {
  display: inline-flex;
  align-items: center;
  color: #838eab;
  margin: 0 12px 0 0;
  cursor: pointer;
}
ssi-publisher-landing .publisher-link-config-toggler i {
  color: #838eab;
  font-size: 20px;
  line-height: 1;
}
ssi-publisher-landing .publisher-link-config-toggler.active i {
  color: #14bae3;
}
ssi-publisher-landing .publisher-link-config-toggler.disabled {
  cursor: not-allowed;
}
ssi-publisher-landing .publisher-link-config-prefix {
  text-rendering: geometricPrecision;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
ssi-publisher-landing .publisher-link-config-prefix .input-group {
  width: 360px;
}
@media (max-width: 885px) {
  ssi-publisher-landing .publisher-link-config-prefix .input-group {
    max-width: 100vw;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
ssi-publisher-landing .publisher-link-config-prefix .input-group .form-control-wrap .info {
  margin-right: 6px;
}
ssi-publisher-landing .publisher-link-config-prefix .input-group .form-control {
  border-left: 2px solid #101525;
  border-top: 2px solid #101525;
  border-bottom: 2px solid #101525;
}
ssi-publisher-landing .publisher-link-config-prefix .input-group .form-control:focus {
  border-left: 2px solid #14bae3;
  border-top: 2px solid #14bae3;
  border-bottom: 2px solid #14bae3;
}
ssi-publisher-landing .publisher-link-config-prefix .input-group .form-control:focus + .info-wrap {
  border-right: 2px solid #14bae3;
  border-top: 2px solid #14bae3;
  border-bottom: 2px solid #14bae3;
}
ssi-publisher-landing .publisher-link-config-prefix .input-group-addon {
  background-color: #303449;
  color: #fff;
  border: 0;
  font-weight: 700;
  line-height: 14px;
}
ssi-publisher-landing .publisher-link-config-prefix .input-group-addon .info {
  margin-right: -6px;
}
ssi-publisher-landing .publisher-link-config-prefix-alert {
  display: flex;
  align-items: center;
  background-color: #fff1ed;
  border-radius: 10px;
  padding: 0 15px;
  max-width: 465px;
  height: 38px;
}
ssi-publisher-landing .publisher-link-config-prefix-alert a {
  line-height: 10px;
}
ssi-publisher-landing .publisher-link-config-prefix-alert p {
  display: inline-block;
  font-weight: 700;
  font-size: 10px;
  line-height: 10px;
  color: #43537f;
  margin: -5px 0 0;
}
ssi-publisher-landing .publisher-link-config-prefix-alert .ssi {
  color: #f88c68;
  margin: 0 10px 0 0;
  line-height: 10px;
}
ssi-publisher-landing .publisher-link-config-prefix-alert.text-danger,
ssi-publisher-landing .publisher-link-config-prefix-alert.text-danger a {
  color: #e36635;
}
ssi-publisher-landing .publisher-link-config-prefix-alert.text-primary,
ssi-publisher-landing .publisher-link-config-prefix-alert.text-primary a {
  color: #14bae3;
}
ssi-publisher-landing .publisher-link-config-tooltip-link-preview-visible .publisher-link-config-togglers {
  height: auto;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
ssi-publisher-landing .publisher-link-config-tooltip-link-preview-visible .publisher-link-config-toggler {
  margin-bottom: 0;
  height: 30px;
}
ssi-publisher-landing .publisher-link-config-tooltip-link-preview-visible .publisher-link-config-toggler span {
  display: none;
}
ssi-publisher-landing .publisher-link-config-tooltip-link-preview-visible .publisher-link-config-prefix {
  margin-left: 0;
}
ssi-publisher-landing .publisher-link-config-tooltip-bottom {
  display: flex;
  width: 100%;
}
@media (max-width: 885px) {
  ssi-publisher-landing .publisher-link-config-tooltip-bottom {
    flex-direction: column;
  }
}
ssi-publisher-landing .publisher-link-config-tooltip-bottom .trigger-linkedin-image {
  width: 50%;
  max-width: 150px;
  margin-top: 10px;
  align-self: center;
}
ssi-publisher-landing .publisher-link-config-tooltip-bottom .loading {
  flex: 1 0 auto;
}
ssi-publisher-landing .publisher-link-config-tooltip-bottom-form {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  min-height: 155px;
}
ssi-publisher-landing .publisher-link-config-tooltip-bottom-form .form-control {
  padding: 14px;
  border-radius: 5px;
  line-height: 20px;
}
ssi-publisher-landing .publisher-link-config-tooltip-bottom-form-title {
  border: 2px solid #101525;
}
ssi-publisher-landing .publisher-link-config-tooltip-bottom-form-title.form-control {
  padding: 0px 12px;
}
ssi-publisher-landing .publisher-link-config-tooltip-bottom-form-title:focus {
  border: 2px solid #14bae3;
}
ssi-publisher-landing .publisher-link-config-tooltip-bottom-form-title-disabled {
  cursor: not-allowed;
  white-space: normal;
}
ssi-publisher-landing .publisher-link-config-tooltip-bottom-form-title-disabled.form-control {
  min-height: 30px;
  height: auto;
  line-height: 18px;
  padding: 6px 14px;
  background-color: #303449;
}
ssi-publisher-landing .publisher-link-config-tooltip-bottom-form-textarea {
  flex: 1 0 auto;
  margin: 4px 0 0;
  overflow-x: hidden;
  overflow-y: auto;
  cursor: not-allowed;
  white-space: normal;
}
ssi-publisher-landing .publisher-link-config-tooltip-bottom-form-textarea.form-control {
  background-color: #303449;
}
ssi-publisher-landing .publisher-linkedin-image-tooltip {
  width: 400px;
  height: 150px;
}
ssi-publisher-landing .publisher-landing-dropdown-item-indent {
  margin-left: 20px;
  display: inline-block;
}
ssi-publisher-landing .publisher-landing-dropdown-item-indent i {
  width: 18px;
}
ssi-publisher-landing .publisher-landing-dropdown-item-indent.underline {
  padding-bottom: 3px;
  border-bottom: solid 1px #8b939a;
}
ssi-publisher-landing .override-zindex {
  z-index: 4;
}
ssi-publisher-landing .zero-zindex {
  z-index: 0;
}
ssi-publisher-landing .media-nav-wrap {
  flex: 0 0 auto;
  height: 155px;
  width: 315px;
  margin: 0 0 0 4px;
  border-radius: 5px;
  overflow: hidden;
}
@media (max-width: 885px) {
  ssi-publisher-landing .media-nav-wrap {
    width: 88vw;
  }
}
ssi-publisher-landing .media-nav-wrap .media-nav-img {
  flex: 1 0 auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  height: 100%;
}
ssi-publisher-landing .publisher-close-btn {
  border: 0;
  padding: 13px 25px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  background-color: #f88c68;
  color: #fff;
  border-radius: 25px;
  font-weight: bold;
  margin: 0 0 8px auto;
}
ssi-publisher-landing .account-option {
  display: flex;
  justify-content: space-between;
}
ssi-publisher-landing .account-option .account-option-left {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
ssi-publisher-landing .account-option .account-option-left i {
  margin-right: 5px;
}
ssi-publisher-landing .account-option .account-option-left i.fa-youtube-play {
  font-size: 22px;
}
ssi-publisher-landing .account-option .account-option-right {
  flex: 0 0 25%;
  display: flex;
  justify-content: space-between;
}
ssi-publisher-landing .account-option .account-option-right div:last-of-type {
  min-width: 20px;
  margin-left: 5px;
}
ssi-publisher-landing .account-option .account-option-right .account-type {
  display: flex;
  align-items: center;
  min-width: 80px;
  padding: 2px 10px;
  font-size: 10px;
  letter-spacing: 1px;
  border-radius: 12px;
  background-color: #0c9bbf;
}
ssi-publisher-landing .account-option .account-option-right .account-type i {
  font-size: 10px;
  width: 14px;
}
ssi-publisher-landing .publisher-mention-click-context-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 160px;
  padding: 8px;
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}
ssi-publisher-landing .publisher-mention-click-context-menu button {
  border: 0;
  outline: 0;
  padding: 8px 10px;
  border-radius: 20px;
  background: #ffffff;
  font-weight: 900;
  color: #43537f;
  cursor: pointer;
}
ssi-publisher-landing .publisher-mention-click-context-menu button:hover {
  color: #14bae3;
  background-color: #f8f9fd;
}
ssi-publisher-landing .publisher-mention-click-context-menu .vertical-line {
  height: 20px;
  width: 2px;
  background-color: rgba(180, 187, 206, 0.5);
  margin: 0 5px;
}
ssi-publisher-landing .publisher-mention-click-context-menu a {
  padding: 5px 10px;
  border-radius: 50%;
  color: #43537f;
  cursor: pointer;
}
ssi-publisher-landing .publisher-mention-click-context-menu a:hover {
  color: #14bae3;
  background-color: #f8f9fd;
}
ssi-publisher-landing .post-info {
  border: 2px solid #838eab;
  border-radius: 100%;
  display: inline-block;
  margin-left: 10px;
}
ssi-publisher-landing .post-info i {
  padding: 5px;
}
ssi-publisher-landing .pt-a11y-label {
  position: absolute;
  text-indent: -9999px;
}
ssi-publisher-landing .video-visibility {
  display: inline-block;
  flex: 0 1 0;
  position: relative;
}
ssi-publisher-landing .video-visibility .ssi {
  position: absolute;
  pointer-events: none;
  color: #838eab;
  height: 1em;
  top: 0;
  bottom: 0;
  font-size: 1.2em;
  right: 2rem;
  margin: auto;
}
ssi-publisher-landing .video-visibility select {
  height: 42px;
  background-color: #f4f4fa;
  border: none;
  color: #43537f;
  font-weight: 900;
  text-rendering: geometricPrecision;
  padding: 8px 14px;
  border-radius: 1rem;
  vertical-align: middle;
  appearance: none;
  cursor: pointer;
}
ssi-publisher-landing .video-visibility select:focus {
  outline: none;
}
ssi-publisher-landing .video-visibility:not(:first-child) select {
  width: 160px;
}
ssi-publisher-landing ssi-menu .menu-container .menu-body {
  width: 370px;
  height: 330px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(116, 118, 132, 0.25);
  overflow: hidden;
}
ssi-publisher-landing ssi-menu hr {
  margin: 10px 0;
}
ssi-publisher-landing ssi-menu i.active {
  color: #14bae3;
}
ssi-publisher-landing ssi-menu .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 60px 10px 60px;
}
ssi-publisher-landing ssi-menu .heading span {
  color: #14bae3;
  font-weight: 900;
  font-size: 14px;
}
ssi-publisher-landing ssi-menu .heading i {
  color: #14bae3;
  font-size: 10px;
  margin-left: 5px;
  margin-right: auto;
}
ssi-publisher-landing .tags-input-input-container {
  padding: 10px 15px 5px 40px;
}
ssi-publisher-landing .tags-input-input-container label {
  width: 100%;
  position: relative;
}
ssi-publisher-landing .tags-input-input-container i {
  font-size: 0.6em;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 1.3rem;
  right: 1.2rem;
  color: #14bae3;
  cursor: pointer;
}
ssi-publisher-landing .tags-input-input-container input {
  width: 100%;
  border: 1px solid #14bae3;
  border-radius: 10px;
  padding: 8px 30px 8px 20px;
  color: #101525;
  background-color: #f8f9fd;
  font-size: 14px;
}
ssi-publisher-landing .tags-input-input-container input::placeholder {
  color: #838eab;
}
ssi-publisher-landing .tags-input-input-container input:focus, ssi-publisher-landing .tags-input-input-container input:active, ssi-publisher-landing .tags-input-input-container input:focus-visible {
  border-color: #14bae3;
  outline: none;
}
ssi-publisher-landing .tags-input-input-container input[type=text] {
  color: #101525;
}
ssi-publisher-landing ul,
ssi-publisher-landing li {
  list-style: none;
  margin: 0;
}
ssi-publisher-landing ul {
  overflow: auto;
  height: 190px;
  padding: 10px 60px;
}
ssi-publisher-landing ul p {
  color: #838eab;
}
ssi-publisher-landing li {
  position: relative;
  color: #43537f;
  margin-bottom: 15px;
  font-weight: 900;
  font-size: 14px;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
ssi-publisher-landing li span {
  max-width: 230px;
  overflow: hidden;
  display: inline-block;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}
ssi-publisher-landing li .select-icon {
  display: none;
  color: #14bae3;
  position: absolute;
  right: 0;
  font-weight: 900;
}
ssi-publisher-landing li.selected {
  color: #14bae3;
}
ssi-publisher-landing li.selected .select-icon {
  display: inline-flex;
}
ssi-publisher-landing li:hover {
  cursor: pointer;
  color: #14bae3;
}
@media (max-width: 884px) {
  ssi-publisher-landing ssi-dropdown-select,
ssi-publisher-landing ssi-dropdown-select-2 {
    margin: 2px;
  }
}