@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../styles/variables';
@import '~/styles/colors';

ssi-report-action-bar {
  font-size: 1rem;

  .report-action-bar {
    // height: $view-report-action-bar-height;
    margin: 0 0 1em;
    color: #43537f;
  }

  .report-action-bar-container {
    display: flex;
    height: 100%;
    // max-width: 144em;
    padding: 0 2em;
    margin: 0 auto;

    &-wrap {
      flex-wrap: wrap;
    }
  }

  .report-action-bar-header {
    height: $view-report-action-bar-header-height;
    background-color: #fff;
    border-bottom: 0.1em solid $lmode-helper-text;
  }

  .report-action-bar-header-left {
    position: relative;
    display: flex;
    align-items: center;
    flex: 0 1 auto;
    margin: 0 2em;
    overflow: hidden;
  }

  .report-action-bar-header-right {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    margin: 0 0 0 auto;
  }

  .report-header-name {
    // width: 100%;
    color: $lmode-title-text;
    font-size: 1.8em;
    margin: 0;
    margin-right: 2em;
    font-weight: 900;
    text-rendering: geometricPrecision;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .report-action-bar-action-wrap {
    margin: 0 2em 0 -5rem;
    height: 1.6em;
    align-self: center;
  }

  .report-action-bar-action {
    font-size: 1.2em;
    border-width: 0;
    outline-width: 0;
    background-color: transparent;
    appearance: none;
  }

  .report-action-bar-dropdown-wrap {
    font-size: 1rem;
    border-width: 0;
    background-color: #fff;
    padding: 1.3em 1.8em 3.8em 1.8em;
    margin: 0.8em 0 0 -2.6em;
    background-color: #fff;
    border-radius: 1.5em;
    box-shadow: 0 1.8em 4em rgba(131, 142, 171, 0.35);
    min-width: 34.2em;
    border-width: 0;
  }

  .report-action-bar-dropdown {
    margin: 0;
    padding: 0;

    li {
      display: block;
      padding: 1em 1.8em;
    }

    p {
      margin: 0;
    }
  }

  .report-action-bar-dropdown-heading p {
    color: #838eab;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    font-weight: 900;
    text-transform: uppercase;
    margin: 1.5rem 0 0.5rem;
  }

  .report-action-bar-dropdown-item {
    border-radius: 1.4em;
    margin: 0 0 0.5em;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: #f8f9fd;
    }

    p {
      color: #43537f;
      font-weight: 900;
    }
  }

  .report-action-bar-dropdown-footer {
    margin: 1.5em -1.8em 0;
    border-top: 0.1em solid #c3c9d8;
    padding: 3em 1.8em 0;
  }

  .report-action-bar-dropdown-footer-item {
    display: block;
    cursor: pointer;

    .ssi {
      font-size: 0.8em;
      margin: 0 0.6rem 0 0.2rem;
    }

    p {
      display: flex;
      align-items: center;
      margin: 0;
      color: $primary-active;
      font-weight: 900;
    }
  }

  .report-warning {
    padding: 1em;
    width: 100%;

    background-color: #fff;
    font-size: 1.4em;
    font-weight: 900;
    color: #43537f;

    .report-warning-inner {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 2em;
      width: 100%;
      border-radius: 10px;
      background-color: #fff1ed;
      div {
        padding-right: 20px;
      }
    }
    .ssi {
      padding: 0 10px;
      color: $secondary-red;
      cursor: pointer;
    }
    .ssi-delete-close {
      position: absolute;
      right: 2.5em;
      cursor: pointer;
    }
  }

  .report-action-bar-actions {
    width: 100%;
    height: auto;
    min-height: $view-report-action-bar-actions-height;
    background-color: $lgrey-background;
    border-bottom: 0.1em solid $lmode-helper-text;
  }

  .report-action-bar-actions-left {
    display: inline-flex;
    align-items: center;
    flex: 1 1 auto;
    margin: 1em 0;
  }

  .report-action-bar-actions-right {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1 1 auto;
    margin: 1em 0 1em 0;
  }

  .posts-selected {
    flex: 0 1 auto;
  }

  .report-action-bar-button {
    background-color: $primary-active;
    padding: 0 2.2em;
    height: 3.6em;
    border-radius: 1em;
    border-width: 0;

    span {
      color: #fff;
      font-size: 1.4em;
      font-weight: 900;
      text-rendering: geometricPrecision;
    }
  }

  .report-actions {
    display: flex;
    flex: 1 0 100%;
    justify-content: flex-end;
    width: 100%;
  }

  .report-actions-list {
    display: flex;
    flex-wrap: wrap;
    flex: 0 1 auto;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100%;

    li {
      display: inline-block;
    }
  }

  .report-actions-list-action {
    margin: 0;

    button {
      position: relative;
      font-size: 1.4em;
      height: 3.6rem;
      padding: 0.6rem 2rem 0.6rem 1.6rem;
      color: $lmode-body-text;
      background-color: transparent;
      border-width: 0;
      font-weight: 900;
      text-rendering: geometricPrecision;
      white-space: nowrap;
      border-radius: 1rem;

      &[disabled] {
        cursor: not-allowed;
        opacity: 0.6;
      }

      &:hover {
        background-color: #e5eaf6;
      }

      // &:active:not(.dropdown-toggle) {
      //   background-color: transparent;
      // }

      .ssi {
        font-size: 1.2rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin: 0 0.2rem 0 0;
        height: 2rem;
        width: 2rem;

        &.ssi-more-actions {
          font-size: 1.3rem;
        }

        &.ssi-date-preset {
          font-size: 1.4rem;
        }
      }
    }

    .dropdown-menu {
      font-size: 1em;
      padding: 2.8em 1.5em 3em 2em;
      margin: 0.8em 0 0 50%;
      background-color: #fff;
      border-radius: 1.5em;
      box-shadow: 0 1.8em 4em rgba(131, 142, 171, 0.35);
      min-width: 22.2em;
      border-width: 0;
      transform: translateX(-50%);

      &-md {
        min-width: 24.4em;
      }
    }

    .dropdown-menu-break-wrap {
      display: block;
    }

    .dropdown-menu-break {
      margin: 1.8em -1.5em 2em -2.2em;
      border-color: #c3c9d8;
    }

    .dropdown-menu-item {
      display: flex;
      align-items: center;
      height: 3.6rem;
      padding: 0 1em 0 2rem;
      border-radius: 1rem;
      margin: 0.2rem 0;
      cursor: pointer;

      &-radio-list-inline {
        & > .ssi:first-child {
          margin-left: -1.6rem;
        }
      }

      span {
        font-size: 1.4rem;
        font-weight: 900;
        text-rendering: geometricPrecision;
        color: $lmode-body-text;
      }

      &:hover {
        background-color: $grey-background;
      }
    }

    .report-actions-list-action-badge {
      position: absolute;
      display: block;
      height: 1.2rem;
      width: 1.2rem;
      margin-top: -0.4rem;
      margin-left: -0.4rem;
      border-radius: 50%;
      font-size: 0.8rem;
      background-color: $primary-active;
      color: #fff;
      overflow: hidden;

      & + .ssi {
        font-size: 0.8rem;
        margin: 0 0.6rem 0 0;
        // font-size: 0.8rem;
        border-radius: 50%;
        background-color: rgba(20, 186, 227, 0.2);
      }
    }
  }

  .report-actions-bar-bulk-actions {
    display: flex;
    background-color: #dbe2f7;
    border-radius: 1em;
    padding: 0.7em;
    height: 4rem;
    margin-top: 1em;
    flex: 1 1 auto;
  }

  .report-bulk-actions-list-item {
    padding: 0 1em 0 2rem;
    margin: 0.2rem 0;
    cursor: pointer;

    .ssi {
      padding: 0 1em;
      font-size: 14px;
      align-self: center;
    }

    span {
      font-family: Lato, sans-serif;
      font-weight: 900;
      font-size: 14px;
    }

    .delete-icon {
      color: #cc3300;
    }
  }

  .report-action-bar-selected-posts-container {
    display: flex;
    align-items: center;

    span {
      font-family: Lato, sans-serif;
      font-weight: 900;
      font-size: 14px;
    }
  }

  .report-bulk-actions-list {
    display: flex;
    flex: 0 1 auto;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100%;

    li {
      display: flex;
      flex-direction: row;
    }
  }

  .dropdown-menu-item-icon {
    color: $lmode-body-text;
    font-size: 1.4rem;
    margin: 0 1.5rem 0 0;
    color: $lmode-helper-text;

    &-small {
      margin: 0 0.6rem 0 0;
      font-size: 1rem;
      color: $lmode-body-text;
    }

    &-red {
      color: $secondary-red;
    }
  }

  .report-actions-toggles {
    display: flex;
    align-items: center;
    margin: 0 0 0 1em;
    padding: 0;
    list-style: none;
  }

  .report-actions-toggle {
    display: flex;

    label {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 4rem;
      width: 5.5rem;
      background-color: #dbe2f7;
      color: $lmode-body-text;
      cursor: pointer;
      margin: 0;
      z-index: 2;

      &:last-child {
        border-bottom-right-radius: 1em;
        border-top-right-radius: 1em;
      }

      .ssi {
        font-size: 1.6em;

        &.ssi-post-results {
          font-size: 1.8rem;
        }
      }
    }

    input {
      display: none;

      &:first-child + label {
        border-bottom-left-radius: 1em;
        border-top-left-radius: 1em;
      }

      &:checked + label {
        background-color: $primary-active;
        color: #fff;
        z-index: 1;
        box-shadow: 0 0.5em 1em rgba(131, 142, 171, 0.2);
      }
    }
  }

  .report-actions-button {
    display: inline-block;
    margin: 0 0 0 1em;

    button {
      border-radius: 1em;
      height: 4em;
      width: 4em;
      background-color: #dbe2f7;
      color: $lmode-body-text;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-width: 0;
    }

    &-active button {
      color: #fff;
      background-color: $primary-active;
    }

    i {
      font-size: 1.8em;
      color: inherit;
    }
  }

  .filters-toggle-btn {
    margin-left: 10px;
  }

  .label-disabled {
    display: none;
  }
}

.tooltip-insights {
  padding: 30px 18px 20px 30px;

  .tooltip-insights-title {
    display: block;
    color: #fff;
    line-height: 20px;
    font-size: 20px;
    margin: 0 0 10px;
    font-weight: 900;
    text-rendering: geometricPrecision;
  }

  .tooltip-insights-body {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-rendering: geometricPrecision;
    font-weight: 700;
    margin: 0;
    color: $dmode-body-text;
  }

  .button-disabled {
    cursor: not-allowed !important;
  }
}
