@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';
@import '../../../styles/variables';

ssi-insights-post {
  .insights-post-container {
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #c3c9d8;

    &.post-selected {
      border: 1px solid $primary-active;
      background-color: #f3fcfe;
    }

    &:hover {
      border-color: $primary-active;
    }

    .bar {
      display: flex;
      align-items: center;
    }

    .post-head {
      height: 60px;
      padding: 0 20px;
      margin: 0;
      border-bottom: 1px solid #c3c9d8;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .bar.left {
        > * {
          margin-right: 5px;
        }

        ssi-checkbox-2 {
          margin-right: 10px;
        }

        ssi-social-network-icon {
          margin-right: -5px;
          .social-network-icon-container {
            height: 26px;
          }
        }

        .author-avatar {
          height: 26px;
          width: 26px;
          border-radius: 50%;
        }

        a.author-name {
          color: $primary-active;
          font-size: 16px;
          font-weight: 700;
          &.inactive {
            color: $lmode-title-text;
            text-decoration: none;
          }
        }

        .author-username {
          color: $lmode-title-text;
          font-size: 12px;
          font-weight: 900;
          padding: 1px 5px;
          border-radius: 100px;
          background-color: #f4f4fa;
        }

        .rating {
          display: flex;
          align-items: center;
          margin-left: 7px;
          i {
            margin-left: 3px;
            font-size: 12px;
            color: #b4bedb;
            &.active {
              color: $primary-active;
            }
          }
        }
      }

      .bar.right {
        > * {
          margin-left: 10px;
        }

        .timestamp {
          color: $lmode-body-text;
          font-size: 12px;
          font-weight: 900;
        }

        .visibility {
          border-radius: 4px;
          border: 1px solid #c3c9d8;
          display: flex;
          align-items: center;
          padding: 2px 5px;

          i {
            color: $lmode-body-text;
            font-size: 10px;
            margin-right: 7px;
          }

          span {
            color: $lmode-body-text;
            font-size: 10px;
            font-weight: 900;
            text-transform: uppercase;
          }
        }

        i.close-modal-icon {
          padding: 14px;
          background-color: #f4f4fa;
          border-radius: 50%;
          color: #838eab;
          font-size: 8px;
          margin-left: 15px;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .post-body {
      min-height: 80px;
      padding: 20px;
      display: flex;
      //   align-items: center;
      justify-content: space-between;
      max-height: 400px;
      overflow: auto;

      .left {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        // justify-content: space-between;

        .content {
          margin: 0 15px 15px 0;
          text-align: left;

          span,
          p {
            color: $lmode-title-text;
            font-size: 14px;
            font-weight: 700;
            line-height: 22px;
          }

          span.read-more-link {
            cursor: pointer;
            color: $primary-active;
            font-weight: 900;
            white-space: nowrap;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        ssi-message-link-preview {
          margin: 0 15px 15px 0;

          .message-link-preview {
            border-radius: 10px;
            background-color: #f8f9fd;
            border: 1px solid #e7edfd;
          }
        }

        .sentiment-and-emotions {
          display: flex;
          margin-top: auto;
          margin-right: 15px;

          &.padding-bottom {
            padding-bottom: 10px;
          }

          .chip-item {
            display: flex;
            border-radius: 4px;
            margin-right: 5px;

            i {
              font-size: 14px;
              padding: 3px;
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            }

            span {
              display: flex;
              align-items: center;
              padding: 0 10px;
              color: $lmode-title-text;
              font-size: 12px;
              font-weight: 900;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
            }
          }
        }
      }

      .right {
        flex: 0 0 auto;

        .media-gallery {
          position: relative;
          margin-right: 15px;

          img,
          .video-icon-container {
            height: 80px;
            width: 120px;
            border-radius: 10px;
            box-shadow: 0 3px 5px 0 rgba(131, 142, 171, 0.35);

            &.stacked {
              position: absolute;
              // top: ngStyle
              // left: ngStyle
            }
          }

          .video-icon-container {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $primary-active;
            background-color: $grey-background;

            i {
              font-size: 60px;
            }
          }

          .play-icon {
            position: absolute;
            // top: ngStyle
            // left: ngStyle
            font-size: 30px;
            color: #fff;
          }

          .media-items-count {
            position: absolute;
            right: -20px;
            top: 45%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            border-radius: 50%;
            width: 25px;
            height: 25px;
            color: $primary-active;
            font-size: 10px;
            font-style: italic;
            font-weight: 900;
            line-height: 12px;
            box-shadow: 0 5px 15px 0 rgba(116, 118, 132, 0.25);
          }
        }
      }
    }

    .post-foot {
      height: 60px;
      padding: 0 10px 0 20px;
      border-top: 1px solid #c3c9d8;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        .stats {
          display: flex;
          align-items: baseline;

          .stat {
            display: flex;
            flex-direction: column;
            // min-width: 80px;
            margin-right: 30px;
            min-width: 0;

            .stat-value {
              color: $lmode-title-text;
              font-size: 12px;
              font-weight: 900;

              &.title {
                overflow: hidden;
                white-space: nowrap;
                max-width: 260px;
                text-overflow: ellipsis;
              }
            }

            .stat-label {
              color: #6a759a;
              font-size: 12px;
              font-weight: 900;

              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
      .right {
        > * {
          margin-left: 10px;
        }

        .tags {
          border-radius: 100px;
          background-color: #f4f4fa;
          padding: 4px 8px;
          display: flex;
          align-items: center;
          cursor: pointer;

          span {
            color: $lmode-title-text;
            font-size: 12px;
            font-weight: 900;
            margin: 0 7px;
          }

          i {
            padding-top: 2px;
            &.edit-icon {
              color: #43537f;
              font-size: 10px;
            }
          }
        }

        .actions-menu-container {
          position: relative;

          .menu-body {
            width: 220px;

            .g-list .g-list-item span,
            i {
              color: #43537f;
            }

            .delete-icon {
              color: #cc3300;
            }
          }

          ssi-menu {
            .menu-trigger-icon {
              font-size: 14px;
              color: $lmode-body-text;
            }

            .sentiment {
              i {
                width: 14px;
              }
            }
          }
        }
      }
    }
  }
}
