.post-box-side {
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  padding-top: 20px;
  text-align: center;
  background: #fff;
  height: 100%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
@media (max-width: 885px) {
  .post-box-side {
    position: relative;
    width: 100vw;
    height: 60px;
  }
}

.post-box-side-activator {
  color: #14bae3;
  font-size: 18px;
  vertical-align: middle;
}

.post-box-side-activator-badge {
  color: #14bae3;
  font-size: 10px;
  vertical-align: top;
}