@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-rss-feed-article {
  &:nth-child(even) {
    margin-left: 10px;
  }

  .feed-logo {
    max-height: 20px;
    vertical-align: text-top;
  }

  .post-article {
    font-family: 'Lato', 'Arial', sans-serif;
    font-weight: 900;
    border: 1px solid #c3c9d8;
    border-radius: 10px;

    // margin-bottom: 10px;

    .post-heading {
      padding: 20px;

      border-bottom: 1px solid #c3c9d8;

      .post-url {
        font-size: 16px;
        line-height: 14px;
        color: #14bae3;
      }

      .post-warning {
        font-size: 12px;
        line-height: 12px;

        span {
          background: rgba(214, 34, 34, 0.1);

          i.ssi-warning-style-02 {
            color: #ffffff;
            background-color: #d62222;
            margin-right: 5px;
            padding: 3px;
          }
        }
      }

      .post-date {
        padding-top: 5px;
        font-size: 10px;
        line-height: 8px;
        color: #43537f;
      }
    }

    .post-body {
      padding: 20px;

      .post-content {
        h3.post-title {
          font-size: 14px;
          line-height: 14px;
          color: #101525;
        }

        p.post-description {
          font-weight: 700;
          font-size: 14px;
          line-height: 22px;
        }

        span.read-more-link {
          cursor: pointer;
          color: $primary-active;
          font-weight: 900;
          white-space: nowrap;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .post-image {
        img {
          width: 100%;
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
        }
      }
    }

    .post-footer {
      padding: 20px;

      border-top: 1px solid #c3c9d8;

      a {
        font-size: 14px;
        color: #43537f;

        .ssi {
          padding-right: 5px;
        }

        &:hover {
          text-decoration: none;
        }

        &.post-create {
          padding-right: 30px;

          .italic {
            font-style: italic;
          }
        }

        &.pst-view {
        }
      }
    }
  }

  .view-2x2,
  .view-2x3,
  .view-2x4,
  .view-4x2,
  .view-4x3,
  .view-2x4 {
    .post-heading {
      display: grid;
      grid-template-columns: auto 259px 99px;
    }

    .post-body {
      display: grid;
      grid-template-columns: 75% 25%;
    }
  }
}
