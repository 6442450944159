ssi-validate-posts-box ssi-campaign-box ul {
  list-style: none;
  padding: 0;
}
ssi-validate-posts-box ssi-campaign-box ul li {
  margin: 0 0 1em 0;
}
ssi-validate-posts-box ssi-campaign-box button {
  border-radius: 10px;
}
ssi-validate-posts-box ssi-campaign-box button i {
  float: left;
  padding: 3px;
}
ssi-validate-posts-box ssi-campaign-box .settings-trigger {
  position: relative;
  z-index: 99;
}
ssi-validate-posts-box ssi-campaign-box .settings-trigger:hover {
  color: white;
  background-color: #43ade8;
}
ssi-validate-posts-box ssi-campaign-box .campaign-settings {
  width: 100%;
  position: absolute;
  top: 77px;
  bottom: 0;
  background-color: #dfe5e8;
}
ssi-validate-posts-box ssi-campaign-box .campaign-settings .campaign-icon {
  top: -24px;
  position: relative;
}
ssi-validate-posts-box ssi-campaign-box .campaign-setting-button {
  width: 200px;
  border-radius: 20px;
  text-align: right;
}
ssi-validate-posts-box ssi-campaign-box .campaign-children {
  padding: 1em;
}
ssi-validate-posts-box ssi-campaign-box .campaign-children p {
  font-size: 40px;
}
ssi-validate-posts-box ssi-campaign-box .campaign-select {
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  cursor: pointer;
}
ssi-validate-posts-box ssi-campaign-box .campaign-editor {
  position: absolute;
  left: 20px;
  right: 20px;
  top: 50%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
  transform: translateY(-50%);
  z-index: 99;
}
ssi-validate-posts-box ssi-campaign-box .campaign-editor select,
ssi-validate-posts-box ssi-campaign-box .campaign-editor input {
  width: 100%;
  background-color: #f6f8f9;
  border: none;
  border-radius: 20px;
  padding: 6px 14px;
}
ssi-validate-posts-box ssi-campaign-box .select-wrapper {
  width: 100%;
  display: inline-block;
  padding: 6px 16px 6px 6px;
  background-color: #f6f8f9;
  border-radius: 20px;
}
ssi-validate-posts-box .post-box {
  position: relative;
  border-width: 0;
  padding-right: 50px;
  box-shadow: 0px 5px 10px 0px rgba(131, 142, 171, 0.2);
  overflow: hidden;
  margin-bottom: 20px;
  border-radius: 0;
}
@media (max-width: 885px) {
  ssi-validate-posts-box .post-box {
    padding-right: 0px;
  }
}
ssi-validate-posts-box .post-box-targeting-open {
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}
ssi-validate-posts-box .post-box-wrap {
  position: relative;
  padding: 20px 130px 20px 25px;
}
ssi-validate-posts-box .post-box-header {
  margin-bottom: 10px;
}
ssi-validate-posts-box .post-box-header .btn {
  margin: 0 5px 5px 0;
}
ssi-validate-posts-box .post-box-panel-body {
  padding: 0;
}
ssi-validate-posts-box .post-box-footer {
  padding: 15px 25px;
  border-radius: 0;
  background-color: #ebf0f2;
  border-top: 0;
}
ssi-validate-posts-box .post-box-footer.post-box-footer-selected {
  background-color: #494f5b;
}
ssi-validate-posts-box .post-box-validation {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
  opacity: 0.7;
  color: white;
  text-align: center;
  font-size: 24px;
  padding: 80px 0;
}
ssi-validate-posts-box .post-box-validation a {
  font-size: 16px;
}
ssi-validate-posts-box .post-box-validation.approved {
  background-color: cadetblue;
}
ssi-validate-posts-box .post-box-validation.disapproved {
  background-color: coral;
}
ssi-validate-posts-box .post-box-validation.boosted {
  background-color: black;
}