@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-stat-graph {
  font-size: 1rem;

  $network-icon-gutter: 0.9em;

  .stat-graph-container {
    display: flex;
    flex-direction: column;
    height: 36.4em;
    width: 36.4em;
    background-color: #fff;
    box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
    border-radius: 1.2em;
    padding: 4em 3em;
    margin: 1em;
  }

  .stat-graph-header {
    display: flex;
    flex: 0 0 auto;
    margin: 0 1.2em 0.5em;
  }

  .stat-graph-header-heading-container {
    flex: 1 1 auto;
  }

  .stat-graph-header-heading {
    font-size: 2.8em;
    line-height: 1;
    color: $lmode-title-text;
    font-weight: 900;
    margin: 0;
  }

  .stat-graph-header-sub {
    font-size: 1em;
    line-height: 1;
    color: $lmode-helper-text;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin: 0.8em 0 0;
    text-rendering: geometricPrecision;
  }

  .stat-graph-header-meta {
    flex: 1 0 auto;
    margin: 0.1em (-$network-icon-gutter) 0;
    text-align: right;
  }

  .stat-graph-network {
    display: inline-block;
    margin: 0 $network-icon-gutter;

    .fa,
    .ssi {
      color: $lmode-helper-text;
      font-size: 1.8em;
    }
  }

  .stat-graph-header-break {
    flex: 0 0 auto;
    height: 0.4em;
    background-color: $lblue-background;
    border-radius: 1em;
    border-width: 0;
    margin: 2.5em 0.2em 3em;
  }

  .stat-graph-content {
    display: flex;
    flex: 1 0 auto;
    margin: 0 0 0.4em;
  }

  .stat-graph-chart-container {
    flex: 0 0 auto;

    .highcharts-container {
      margin: -1em 0 0 -1em;
    }
  }

  .stat-graph-chart-key {
    flex: 1 0 auto;
    list-style: none;
    margin: 3em 0 0;
    padding: 0 0 0 1em;

    li {
      display: flex;
      align-items: center;
      margin: 0 0 2em;
    }
  }

  .stat-graph-chart-legend {
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    border-radius: 50%;
    margin: 0 1.2em 0 0;
  }

  .stat-graph-chart-name,
  .stat-graph-chart-amount {
    display: inline-block;
    font-size: 1.4em;
    color: $lmode-title-text;
    font-weight: 900;
    text-rendering: geometricPrecision;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .stat-graph-chart-amount {
    position: relative;
    display: inline-block;
    width: 3.8rem;
    margin: 0 0.2em 0 0;
    padding: 0 0.2em 0 0;
    min-height: 1.4rem;

    &:after {
      content: '|';
      position: absolute;
      right: 0;
      top: 0;
      margin: 0 auto 0 0;
    }
  }

  .stat-graph-chart-name {
    text-transform: capitalize;
    margin: 0 0 0 0.4em;
  }

  /* Large Variation
   ========================================================================== */
  .stat-graph-container-large {
    flex-direction: row;
    height: 35em;
    width: 55.6em;
    padding: 2.6em;
    justify-content: space-between;

    .stat-graph-large-left {
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 4em;
    }

    .stat-graph-competitor-container {
      margin-bottom: 3em;

      p {
        margin: 0;
      }
    }

    .stat-graph-competitor {
      display: flex;
      align-items: center;
      color: $lmode-helper-text;
      font-size: 1em;
      letter-spacing: 0.1em;
      font-weight: 900;
      text-transform: uppercase;
      text-rendering: geometricPrecision;
    }

    .stat-graph-competitor-colour {
      display: inline-block;
      height: 2em;
      width: 2em;
      border-radius: 50%;
      margin: 0 1em 0 0;
    }

    .stat-graph-chart-container {
      margin-left: 7.2em;
    }

    .stat-graph-large-left-footer {
      padding: 0;
      margin: 0;
      margin: 0.1em (-$network-icon-gutter) 0;
    }

    // .stat-graph-large-right {
    //   min-width: 18.5em;
    // }

    .stat-graph-large-right-header {
      display: flex;
    }

    .stat-graph-large-right-header-title {
      margin: 5.4em 0 0;
      flex: 1 0 auto;
    }

    .stat-graph-large-right-header-badge {
      flex: 0 0 auto;
    }

    .stat-graph-badge {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: #fbf3e2;
      color: $lmode-title-text;
      height: 4.6em;
      width: 4.6em;
      border-radius: 50%;
      pointer-events: none;

      p {
        margin: 0;
        font-size: 1.4em;
        font-weight: 900;
      }

      .stat-graph-badge-wreathe {
        position: absolute;
        margin: 2.4em 0 0;
        color: #f0b427;

        path {
          transform: scale(1.15);
          transform-origin: center;
        }
      }
    }

    .stat-graph-chart-key {
      padding-left: 0;
    }
  }

  /* Large Thin Variation
  ========================================================================== */
  .stat-graph-container-large-thin {
    width: 36.4em;
    height: 46em;

    .stat-graph-large-left {
      justify-content: flex-start;
      margin-right: 1em;
    }

    .stat-graph-chart-container {
      margin-left: 0;
    }

    .stat-graph-competitor-container {
      margin: 0 0 4.4em;
    }

    .stat-graph-large-left-footer {
      margin: auto (-$network-icon-gutter) 0;
    }

    .stat-graph-large-right {
      flex: 0 1 auto;
      // min-width: 15.8em;
    }

    .stat-graph-large-right-header-title {
      margin: 14.5em 0 0;
    }

    highcharts-chart {
      padding: 10px;
    }
  }
}
