ssi-content-generator {
  font-size: 1rem;
}
ssi-content-generator .alert {
  font-size: 1.4em;
}
ssi-content-generator label {
  font-size: 1.4em;
}
ssi-content-generator .cg-empty-text {
  text-align: center;
  font-size: 1.8em;
  line-height: 2;
  font-weight: 700;
}
ssi-content-generator .article-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1em 2.4em;
}
ssi-content-generator .article-grid-item {
  flex: 1 0 0%;
  width: 100%;
  margin: 0 1em;
}
@supports (display: grid) {
  ssi-content-generator .article-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(26.8em, 1fr));
    grid-gap: 0 2em;
    margin: 0;
  }
  ssi-content-generator .article-grid-thirds {
    grid-template-columns: repeat(auto-fit, minmax(36.4em, 1fr));
  }
  ssi-content-generator .article-grid-halves {
    grid-template-columns: repeat(auto-fit, minmax(55.6em, 1fr));
  }
  ssi-content-generator .article-grid .section-grid-item {
    margin: 0;
  }
}