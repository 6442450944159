ssi-facebook-share-modal {
  display: block;
  background-color: #373c3f;
  padding: 40px;
  border-radius: 4px;
  width: 550px;
}
ssi-facebook-share-modal .btn-inverse {
  margin-top: 35px;
}
ssi-facebook-share-modal ssi-publisher-landing {
  flex-direction: column;
}
ssi-facebook-share-modal ssi-publisher-landing .publisher-textarea-container {
  border-radius: 5px;
  z-index: auto;
}
ssi-facebook-share-modal ssi-publisher-landing .publisher-textarea-container textarea {
  min-height: 160px;
  padding: 20px;
}
ssi-facebook-share-modal ssi-publisher-landing .publisher-textarea-container .publisher-textarea-footer {
  padding: 10px 20px;
}
ssi-facebook-share-modal ssi-publisher-landing .publisher-textarea-container .no-accounts-icon {
  display: none;
}
ssi-facebook-share-modal textarea.form-control.form-control-minimal {
  resize: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 0;
}
ssi-facebook-share-modal input.form-control.form-control-minimal {
  border-radius: 5px;
  background-color: white;
}
ssi-facebook-share-modal .form-control.form-control-minimal {
  font-size: 14px;
  padding: 15px;
}
ssi-facebook-share-modal .quote-retweet-modal-form-control-footer {
  background-color: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 10px 15px;
  text-align: right;
  font-size: 14px;
}
ssi-facebook-share-modal .quote-retweet-modal-form-control-footer i {
  font-size: 20px;
  color: #d2d8df;
  position: relative;
  top: 2px;
  left: -3px;
}
ssi-facebook-share-modal .quote-retweet-modal-form-control-footer i:hover {
  color: #b4beca;
}
ssi-facebook-share-modal .quote-retweet-footer-btns .btn {
  margin-left: 3px;
  margin-top: 3px;
}
ssi-facebook-share-modal .quote-retweet-footer-date-picker .btn {
  margin-left: 3px;
}