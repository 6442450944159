ssi-login .form-group {
  margin-bottom: 20px;
}
ssi-login .form-group-authentication {
  margin: 45px 0 12px;
}
ssi-login .btn-google {
  flex: 1 0 175px;
  background-color: #fff;
  border-color: #fff;
  position: relative;
  text-indent: -9px;
}
ssi-login .btn-google img {
  width: 18px;
  height: 18px;
  vertical-align: bottom;
  margin-right: 11px;
}
ssi-login .login-field .form-control-password {
  display: block;
}
ssi-login .login-field .form-control-password input,
ssi-login .login-field .form-control-password .form-control {
  padding-right: 60px;
}
ssi-login .login-field .form-control-password input[type=password],
ssi-login .login-field .form-control-password .form-control[type=password] {
  letter-spacing: 8px;
}
ssi-login .login-field .form-control-password.form-control-password-empty .show-hide {
  display: none;
}
ssi-login .login-field .form-control-password .show-hide {
  top: 19px;
  right: 30px;
  height: 22px;
  font-size: 24px;
}
ssi-login .login-field .form-control-password .show-hide.ssi-eyeclosed {
  font-size: 12px;
  right: 31px;
}
ssi-login .form-meta {
  text-align: right;
  margin: 6px 0 0;
  padding: 0 15px;
}
ssi-login .form-meta a {
  color: inherit;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  font-style: normal;
}
ssi-login .form-spacer {
  margin-bottom: 37px;
}