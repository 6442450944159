ssi-publisher-actions-and-media .publisher-actions {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #f0f3f9;
}
ssi-publisher-actions-and-media .publisher-actions-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
}
@media (max-width: 885px) {
  ssi-publisher-actions-and-media .publisher-actions-wrapper {
    padding: 5px;
    position: absolute;
    margin-top: 5px;
    width: 100vw;
  }
}
ssi-publisher-actions-and-media .publisher-actions-row {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  user-select: none;
  margin: -5px;
  position: relative;
}
@media (max-width: 884px) {
  ssi-publisher-actions-and-media .publisher-actions-row {
    padding: 0 10px;
  }
}
ssi-publisher-actions-and-media .publisher-actions-row.loading-cover-visible {
  margin: 0;
}
ssi-publisher-actions-and-media .publisher-actions-row .loading-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f0f3f9;
  border-radius: 25px;
  border: 6px solid #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
ssi-publisher-actions-and-media .publisher-actions-row .loading-cover i {
  color: #14bae3;
}
ssi-publisher-actions-and-media .publisher-actions-row .loading-cover span {
  margin-top: 10px;
  color: #838eab;
  font-size: 14px;
  font-weight: 700;
}
@media (max-width: 884px) {
  ssi-publisher-actions-and-media .publisher-actions-row {
    align-content: flex-start;
  }
}
ssi-publisher-actions-and-media .publisher-close-compose {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
ssi-publisher-actions-and-media .publisher-close-compose .pro-tip {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
  color: #14bae3;
}
ssi-publisher-actions-and-media .publisher-close-compose .pro-tip i {
  margin-right: 5px;
  font-size: 16px;
}
ssi-publisher-actions-and-media .publisher-close-compose ssi-toggle-switch-2 {
  margin: 0 1em 0 0;
}
@media (max-width: 884px) {
  ssi-publisher-actions-and-media .publisher-close-compose {
    display: none;
  }
}
ssi-publisher-actions-and-media .publisher-action {
  flex-basis: 33.3333333333%;
  padding: 5px;
  appearance: none;
  outline: none;
  border: none;
  background-color: transparent;
}
ssi-publisher-actions-and-media .publisher-action .publisher-action-inner {
  background-color: #fff;
  color: #838eab;
  transition: background-color 400ms;
  border-radius: 15px;
}
ssi-publisher-actions-and-media .publisher-action .publisher-action-inner:hover {
  background-color: shade(#fff, 3%);
}
ssi-publisher-actions-and-media .publisher-action i {
  color: #838eab;
}
ssi-publisher-actions-and-media .publisher-action .publisher-action-title {
  color: #43537f;
}
ssi-publisher-actions-and-media .publisher-action.half-width {
  flex-basis: 50%;
}
ssi-publisher-actions-and-media .publisher-action.active i {
  color: #fff;
}
ssi-publisher-actions-and-media .publisher-action.active .publisher-action-title {
  color: #fff;
}
ssi-publisher-actions-and-media .publisher-action:focus {
  outline: revert;
}
ssi-publisher-actions-and-media .publisher-action:hover {
  cursor: pointer;
}
ssi-publisher-actions-and-media .publisher-action:disabled i {
  color: #b4bbce;
}
ssi-publisher-actions-and-media .publisher-action:disabled .publisher-action-title {
  color: #b4bbce;
}
@media (max-width: 884px) {
  ssi-publisher-actions-and-media .publisher-action {
    flex-basis: 50%;
    height: 154px;
  }
}
ssi-publisher-actions-and-media .publisher-action-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}
ssi-publisher-actions-and-media .publisher-action-inner i,
ssi-publisher-actions-and-media .publisher-action-inner ssi-icon {
  padding: 9px 0;
  height: 45px;
  color: #b4bbce;
}
ssi-publisher-actions-and-media .publisher-action.publisher-action-preview i {
  font-size: 40px;
}
ssi-publisher-actions-and-media .publisher-action-schedule i {
  font-size: 28px;
}
ssi-publisher-actions-and-media .publisher-action-album i,
ssi-publisher-actions-and-media .publisher-action-targeting i,
ssi-publisher-actions-and-media .publisher-action-content-approval i,
ssi-publisher-actions-and-media .publisher-action-publish i,
ssi-publisher-actions-and-media .publisher-action-draft i,
ssi-publisher-actions-and-media .publisher-action-add-image i,
ssi-publisher-actions-and-media .publisher-action-add-video i {
  font-size: 31px;
}
ssi-publisher-actions-and-media .publisher-action-inner.spacer-top {
  height: calc(100% - 8px);
}
ssi-publisher-actions-and-media .publisher-action[disabled] .publisher-action-inner {
  background-color: #f7f8fc;
  color: #637093;
  transition: background-color 400ms;
  border-radius: 15px;
}
ssi-publisher-actions-and-media .publisher-action[disabled] .publisher-action-inner:hover {
  background-color: shade(#f7f8fc, 3%);
}
ssi-publisher-actions-and-media .publisher-action.active .publisher-action-inner {
  background-color: #14bae3;
  color: #fff;
  transition: background-color 400ms;
  border-radius: 15px;
}
ssi-publisher-actions-and-media .publisher-action.active .publisher-action-inner:hover {
  background-color: shade(#14bae3, 3%);
}
ssi-publisher-actions-and-media .publisher-action-title {
  margin-top: 8px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 900;
  color: #43537f;
}
@media (max-width: 885px) {
  ssi-publisher-actions-and-media .publisher-action-title {
    margin-top: 4px;
    margin-bottom: 10px;
  }
}
ssi-publisher-actions-and-media .publisher-media {
  background-color: white;
  flex: 1;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  height: 290px;
  overflow: hidden;
}
@media (max-width: 885px) {
  ssi-publisher-actions-and-media .publisher-media {
    padding: 0 10px 70px 10px;
  }
}
ssi-publisher-actions-and-media .publisher-media video {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
ssi-publisher-actions-and-media .publisher-media .publisher-media-actions,
ssi-publisher-actions-and-media .publisher-media ssi-media-nav {
  margin-top: 10px;
}
ssi-publisher-actions-and-media .publisher-media .publisher-media-actions .media-nav-img,
ssi-publisher-actions-and-media .publisher-media ssi-media-nav .media-nav-img {
  border-radius: 3px 3px 15px 15px;
}
ssi-publisher-actions-and-media .publisher-media .publisher-media-actions .edit-image,
ssi-publisher-actions-and-media .publisher-media ssi-media-nav .edit-image {
  margin: 0 40px 0 10px;
}
ssi-publisher-actions-and-media .publisher-media .publisher-media-actions .add-subtitles,
ssi-publisher-actions-and-media .publisher-media ssi-media-nav .add-subtitles {
  padding: 7px 9px;
  border-radius: 15px;
  display: flex;
  align-items: flex-end;
}
ssi-publisher-actions-and-media .publisher-media .publisher-media-actions .add-subtitles i,
ssi-publisher-actions-and-media .publisher-media ssi-media-nav .add-subtitles i {
  font-size: 16px;
  margin-right: 5px;
}
ssi-publisher-actions-and-media .publisher-media .publisher-media-actions .add-subtitles:hover,
ssi-publisher-actions-and-media .publisher-media ssi-media-nav .add-subtitles:hover {
  background-color: #f0f3f9;
}
ssi-publisher-actions-and-media .publisher-media .publisher-media-actions .remove-image,
ssi-publisher-actions-and-media .publisher-media ssi-media-nav .remove-image {
  background-color: #fff1ed;
  color: #f88c68;
  padding: 7px;
  font-size: 18px;
  border-radius: 50%;
  display: flex;
}
ssi-publisher-actions-and-media .publisher-media .publisher-media-actions .remove-image span,
ssi-publisher-actions-and-media .publisher-media ssi-media-nav .remove-image span {
  display: none;
  margin-left: 5px;
  font-family: "Lato", sans-serif;
  font-size: 12px;
  font-weight: bold;
}
ssi-publisher-actions-and-media .publisher-media .publisher-media-actions .remove-image:hover,
ssi-publisher-actions-and-media .publisher-media ssi-media-nav .remove-image:hover {
  border-radius: 15px;
}
ssi-publisher-actions-and-media .publisher-media .publisher-media-actions .remove-image:hover span,
ssi-publisher-actions-and-media .publisher-media ssi-media-nav .remove-image:hover span {
  display: flex;
  align-items: center;
}
ssi-publisher-actions-and-media .publisher-media .publisher-media-actions {
  display: flex;
  align-items: center;
  padding: 0 10px;
}
ssi-publisher-actions-and-media .publisher-media .publisher-media-actions a:last-child {
  margin-left: auto;
}
ssi-publisher-actions-and-media .publisher-media .publisher-media-actions a i {
  margin-right: 3px;
}
ssi-publisher-actions-and-media .publisher-media .publisher-media-actions .ig-specific-media-label {
  color: #838eab;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 5px 10px;
  background-color: #f4f4fa;
  border-radius: 5px;
}
ssi-publisher-actions-and-media .publisher-media .publisher-media-actions ssi-checkbox-2 {
  margin-left: 10px;
}
ssi-publisher-actions-and-media .publisher-media ssi-media-nav {
  flex: 1;
  padding-top: 0;
}
ssi-publisher-actions-and-media .publisher-media a {
  color: #838eab;
  font-size: 12px;
  font-weight: bold;
}
ssi-publisher-actions-and-media .publisher-media a.alt-text {
  background-color: #f0f3f9;
  border-radius: 15px;
  padding: 7px 10px;
  color: #14bae3;
}
ssi-publisher-actions-and-media .publisher-media a:hover {
  text-decoration: none;
  color: shade(#838eab, 10%);
}

@media (min-width: 886px) {
  ssi-publisher-actions-and-media.media-visible {
    align-content: flex-start;
  }
  ssi-publisher-actions-and-media.media-visible .publisher-title-container,
ssi-publisher-actions-and-media.media-visible .publisher-action-title {
    display: none;
  }
  ssi-publisher-actions-and-media.media-visible .publisher-actions {
    flex-direction: row-reverse;
    flex: 0;
    flex-basis: 60px;
  }
  ssi-publisher-actions-and-media.media-visible .publisher-actions-row {
    justify-content: flex-start;
    margin: 0 10px 0 -25px;
  }
  ssi-publisher-actions-and-media.media-visible .publisher-actions-row .publisher-action {
    flex-basis: 16.6666666667%;
    margin-left: 25px;
    border-radius: 50%;
    max-width: 45px;
    padding: 0;
    cursor: pointer;
  }
  ssi-publisher-actions-and-media.media-visible .publisher-actions-row .publisher-action.active, ssi-publisher-actions-and-media.media-visible .publisher-actions-row .publisher-action.save-active {
    background-color: #14bae3;
  }
}
@media (min-width: 886px) and (max-width: 1199px) {
  ssi-publisher-actions-and-media.media-visible .publisher-actions-row .publisher-action {
    margin-left: 10px;
  }
}
@media (min-width: 886px) {
  ssi-publisher-actions-and-media.media-visible .pro-tip {
    display: none;
  }
}
@media (min-width: 886px) {
  ssi-publisher-actions-and-media.media-visible .publisher-actions {
    background-color: #fff;
    border-radius: 15px;
    padding: 14px;
  }
  ssi-publisher-actions-and-media.media-visible .publisher-actions .publisher-action .publisher-action-inner {
    background-color: transparent;
    border-radius: 50%;
  }
  ssi-publisher-actions-and-media.media-visible .publisher-actions .publisher-action .publisher-action-inner i {
    font-size: 24px;
    color: #838eab;
  }
  ssi-publisher-actions-and-media.media-visible .publisher-actions .publisher-action .publisher-action-inner i.ssi-eye {
    font-size: 28px;
  }
  ssi-publisher-actions-and-media.media-visible .publisher-actions .publisher-action:focus {
    outline: revert;
  }
  ssi-publisher-actions-and-media.media-visible .publisher-actions .publisher-action.active i {
    color: #fff;
  }
  ssi-publisher-actions-and-media.media-visible .publisher-actions .publisher-action.disabled {
    opacity: 0.4;
  }
}
@media (min-width: 886px) {
  ssi-publisher-actions-and-media.media-visible .publisher-close-compose {
    margin-bottom: 0;
  }
}
@media (min-width: 886px) {
  ssi-publisher-actions-and-media.media-visible .publisher-close-btn {
    margin-bottom: 0;
  }
}
@media (min-width: 886px) and (max-width: 885px) {
  ssi-publisher-actions-and-media.media-visible .publisher-close-btn {
    padding: 8px;
  }
}