ssi-inbox-pinned-activities {
  margin-bottom: -20px;
  z-index: 1;
}

.unpin-button {
  z-index: 9999;
}

.pinned-activities-container {
  display: flex;
  flex: 1;
  background-color: #f6f7f8;
  color: #b4bbce;
  margin: 0;
  padding: 15px 15px 5px;
  list-style: none;
}
.pinned-activities-container li {
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 160px;
  max-width: 30%;
  flex: 1;
  padding: 5px 10px;
  border-radius: 12px;
  background-color: #fff;
}
.pinned-activities-container li.active {
  background-color: #e7edfd;
}
.pinned-activities-container li:last-child {
  margin-right: 0;
}
.pinned-activities-container li img {
  margin-right: 0.5rem;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.pinned-activities-container li span {
  font-size: 10px;
  font-weight: 900;
  color: #43537f;
}
.pinned-activities-container li .author-info {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 0.2rem;
  flex: 1;
}
.pinned-activities-container li .author-info:hover {
  cursor: pointer;
}
.pinned-activities-container li button {
  border: 0;
  border-radius: 50%;
  color: #fff;
  background-color: #668aea;
  padding: 6px;
  font-size: 7px;
  height: 20px;
  width: 20px;
}
.pinned-activities-container .additional-trigger {
  position: relative;
}
.pinned-activities-container .additional-trigger > i {
  z-index: 10;
  color: #838eab;
}
.pinned-activities-container .additional-trigger > i:hover {
  cursor: pointer;
}
.pinned-activities-container .additional-activities {
  position: absolute;
  z-index: 5;
  top: 0;
  right: 0;
  margin: 0;
  padding: 30px 10px 10px 10px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 0px 10px 0px rgba(202, 202, 202, 0.75);
}
.pinned-activities-container .additional-activities li {
  margin-right: 0;
  max-width: 100%;
  width: 100%;
}
.pinned-activities-container .additional-activities li:hover {
  color: #14bae3;
}
.pinned-activities-container .additional-activities li .author-info {
  margin-right: 3rem;
}

.pinned-activities-tooltip-content {
  padding: 2.5rem;
  width: 260px;
  font-weight: 900;
}
.pinned-activities-tooltip-content .main-info {
  display: flex;
}
.pinned-activities-tooltip-content .main-info span {
  margin-right: 0.4rem;
  color: #fff;
  font-size: 10px;
}
.pinned-activities-tooltip-content .main-info span.author-name {
  display: inline-block;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.pinned-activities-tooltip-content .message-preview {
  margin-top: 1rem;
  color: #b4bbce;
  font-size: 14px;
  word-wrap: break-word;
}