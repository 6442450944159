@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../angularjs/common/sass/variables';
@import '~/styles/colors';

$ssi-marketing-responsive-width: 885px;

ssi-marketing {
  $marketing-actions-menu-border-color: #edf1f3;
  $marketing-actions-menu-border-width: 3px;
  $marketing-menu-color: $lmode-body-text;
  $blue: $primary-active;
  $ad-blue: #70a3de;
  $index-bg-color: #f6f8f9;

  .container-app-page {
    &.container-marketing-no-margin {
      margin: 0;
      padding: 0;
      width: 100%;
    }
  }

  .bg-dark {
    background-color: #3a3f49;
    color: #a8b9c2;

    .marketing-floating-menu {
      background-color: #40454f;
      border: 2px solid #454b55;
    }

    ui-view {
      background-color: transparent;
    }

    .marketing-nav {
      li {
        a {
          color: #b4bbce;
          &:hover {
            color: darken(#b4bbce, 5%);
          }
        }
        &.active {
          a {
            color: #fff;
          }
        }
        &.compose-post {
          a {
            color: #14bae3;
          }
        }
      }
    }
  }

  .has-advertising {
    .marketing-nav {
      li {
        &.active {
          border-bottom-color: $primary-active;
        }
      }
    }

    .marketing-filter {
      .marketing-filter-button {
        background-color: $ad-blue;
        border-color: $ad-blue;

        &.marketing-filter-button-inverse {
          background-color: transparent;
        }
      }

      .marketing-actions-menu-separator {
        background-color: $ad-blue;
      }
    }

    .marketing-filter-selecter {
      border: 2px solid $ad-blue;
    }
  }

  .marketing-filter {
    position: relative;
    .marketing-actions-menu-separator {
      background-color: $blue;
    }
  }

  .marketing-filter-menu-wrap {
    position: relative;
    padding-bottom: 15px;
    @media (max-width: $ssi-marketing-responsive-width) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .marketing-filter-menu {
    justify-content: center;
    @media (max-width: $ssi-marketing-responsive-width) {
      overflow-x: scroll;
      max-width: 100vw;
    }
  }

  .marketing-filter-button {
    position: relative;
    display: inline-block;
    border-radius: 20px;
    background-color: $blue;
    border: 2px solid $blue;
    padding: 8px 20px;
    text-transform: capitalize;
    font-weight: 700;

    @media (max-width: $ssi-marketing-responsive-width) {
      width: 95vw;
    }

    &:hover,
    &:focus {
      background-color: darken($blue, 5%);
      border-color: darken($blue, 5%);
    }

    &.marketing-filter-button-inverse {
      background-color: #fff;
      color: $marketing-menu-color;

      &:active {
        background-color: #fff;
        border-color: $marketing-menu-color;
        color: $marketing-menu-color;
      }
    }

    &.marketing-filter-button-add {
      padding: 8px 15px;

      &:before {
        content: normal;
      }
    }
  }

  .marketing-filter-button-inverse ~ .marketing-filter-button {
    font-style: italic;
  }

  .marketing-filter-button-children {
    padding: 8px 50px 8px 20px;

    &:before {
      content: '\f0c4';
      position: absolute;
      top: 11px;
      right: 20px;
      height: 10px;
      font: normal normal normal 16px/1 Socialsignin;
      text-rendering: auto;
      font-smoothing: antialiased;
    }
  }

  .marketing-filter-selecter {
    position: absolute;
    top: 45px;
    left: 0;
    right: 0;
    width: 80%;
    padding: 25px;
    margin: 0 10%;
    background-color: white;
    border: 2px solid $blue;
    border-radius: 20px;
    z-index: 99;

    ul,
    input {
      width: 100%;
      max-height: 315px;
      overflow-y: scroll;
      margin: 0 0 1em;
      background-color: $index-bg-color;
      border: none;
      border-radius: 20px;
      padding: 2px 14px;
      color: $marketing-menu-color;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: $marketing-menu-color;
      }
    }

    ul {
      padding: 10px 14px;
      list-style: none;
      text-align: left;

      li {
        cursor: pointer;
      }
    }
  }

  .marketing-actions-menu-separator {
    content: '';
    background-color: $marketing-actions-menu-border-color;
    margin-top: 19px;
    height: $marketing-actions-menu-border-width;
    width: 20px;
  }

  .marketing-nav {
    display: flex;
    text-align: center;
    @media (min-width: $ssi-marketing-responsive-width) {
      justify-content: center;
    }
    @media (max-width: $ssi-marketing-responsive-width) {
      max-width: 100%;
      overflow-x: scroll;
    }

    li {
      margin: 0 15px;
      padding: 5px 5px 9px;
      @media (max-width: $ssi-marketing-responsive-width) {
        padding: 5px 5px 0px;
        min-width: 100px;
      }
      border-bottom-color: transparent;
      border-bottom-width: 3px;
      border-bottom-style: solid;

      &.active {
        border-bottom-color: $primary-active;
      }

      a {
        color: $marketing-menu-color;
        font-weight: 700;

        &:hover {
          text-decoration: none;
          color: darken($marketing-menu-color, 5%);
        }
      }
    }
  }

  .marketing-nav-icon {
    @media (min-width: $ssi-marketing-responsive-width) {
      display: inline-block;
    }
    color: $primary-active;
    font-size: 12px;
    margin: 0 3px;
    font-weight: 700;
  }

  .marketing-tooltip-menu {
    @media (min-width: $ssi-marketing-responsive-width) {
      position: absolute;
      top: 0;
      right: 15px;
      width: 190px;
    }
    @media (max-width: $ssi-marketing-responsive-width) {
      width: 95vw;
    }
  }

  .marketing-tooltip-trigger {
    position: relative;
    float: right;
    background-color: #f6f8f9;
    color: $marketing-menu-color;
    font-weight: 700;
    border-radius: 20px;
    font-size: 12px;
    padding: 10px 20px;
    cursor: pointer;
    @media (max-width: $ssi-marketing-responsive-width) {
      width: 95vw;
      text-align: center;
    }
    &:hover,
    &:focus {
      background-color: darken(#f6f8f9, 5%);
    }

    p {
      display: inline-block;
      margin: 0 10px 0 0;
      color: $marketing-menu-color;
      font-weight: 700;
    }

    .ssi {
      font-size: 19px;
    }
  }

  .marketing-tooltip-label {
    position: absolute;
    top: -15px;
    right: -8px;
    height: 30px;
    width: 30px;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    vertical-align: middle;
    font-weight: 500;
    background-color: $blue;
  }

  .marketing-floating-menu {
    width: 100%;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 20px;
    text-align: right;

    > li {
      margin: 0 0 10px;

      &:last-child {
        margin: 0;
      }
    }

    a:hover,
    a:focus {
      text-decoration: none;
    }
  }

  .marketing-floating-menu-label {
    margin-right: 10px;
    font-size: 12px;
    position: relative;
    font-weight: 500;
    color: $marketing-menu-color;

    &:hover,
    &:hover + .marketing-floating-menu-label-icon,
    &:active,
    &:active + .marketing-floating-menu-label-icon {
      color: $blue;
    }
  }

  .marketing-floating-menu-label-pre {
    &:after {
      content: '|';
      margin: 0 5px;
    }
  }

  .marketing-floating-menu-label-icon {
    color: $marketing-menu-color;
    font-size: 16px;
  }
}

ng-datepicker {
  .ngx-datepicker-container {
    .ngx-datepicker-input {
      width: 100% !important;
      border: none !important;
    }
  }
}

.container-flex {
  display: flex;
  flex-direction: column;
}

.campaign-page-wrap,
.campaign-page-wrap > * {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;

  @media (max-width: $ssi-marketing-responsive-width) {
    align-items: center;
    max-width: 100vw;
  }
}
