@import "./src/apps/angularjs/common/sass/global-variables.scss";
$default-primary-color: #E2574C;
$default-accent-color: #B53629;

ssi-icon-file {
    font-size: 1rem;
    
    .icon-file {
        width: 4em;
        height: 4em;
    }
    
    .icon-file-meta {
        font-family: sans-serif;
        font-weight: 700;
        text-transform: uppercase;
    }
    
    .icon-file-background {
        fill: $default-primary-color;
    }
    
    .icon-file-lip {
        fill: $default-accent-color;
    }
}
