@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-accordion {
  font-size: 1rem;

  .cl-accordion {
    margin: 0 0 0.9em;
    width: 100%;
  }

  .cl-accordion-item {
    overflow: hidden;
    border-radius: 1em;
    border: 1px solid #c3c9d8;
    background-color: #fff;
    transition: height 0.3s ease;
    will-change: transform;

    &.accordion-item-invalid {
      background-color: #ffede7;
      border-color: #cc3300;

      .accordion-button {
        border-color: #cc3300;
      }

      .accordion-button-icon {
        color: #cc3300;
      }
    }
  }

  .accordion-header {
    font-size: 1em;
    margin: 0;
    height: 46px;
  }

  .accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    background-color: inherit;
    border-width: 0;
    padding: 0.5em 3.5em 0.5em 0.5em;
    width: 100%;

    &.disabled {
      cursor: not-allowed;
    }

    &.accordion-button-open {
      border-bottom: 1px solid #c3c9d8;

      .accordion-button-icon {
        transform: rotate(180deg);
      }
    }

    .accordion-button-label {
      color: #43537f;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 1.4rem;
      text-indent: 2.5rem;
      margin: 0;
      padding: 0;
      line-height: 1;
      font-weight: 900;
      text-rendering: geometricPrecision;
    }
  }

  .accordion-button-icon {
    position: absolute;
    right: 1.8rem;
    top: 0;
    bottom: 0;
    margin: auto;
    color: $primary-active;
    pointer-events: none;
    line-height: 0.8rem;
    height: 0.8rem;

    &::before {
      display: block;
      line-height: 0.8rem;
      height: 0.8rem;
    }
  }

  .accordion-collapse {
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
    overflow: auto;

    &.accordion-open {
      overflow: visible;
    }
  }

  .accordion-body {
    padding: 3em;
  }
}
