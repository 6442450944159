@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../angularjs/common/sass/variables';
@import '~/styles/colors';

ssi-activity-snippets-dropdown {
  font-size: 1rem;

  .as-dropdown-container {
    position: absolute;
    z-index: 7;
    padding: 1.5em 2em 1.5em 1em;
    background: #fff;
    border-radius: 1.2em;
    box-shadow: 0 0.4em 3.2em -0.5em rgba(119, 124, 128, 0.56);
  }

  .as-label {
    font-size: 1.4em;
    padding: 0 1.5rem;
    font-style: italic;
    color: $lmode-helper-text;
    font-weight: normal;

    &::after {
      content: '/';
      display: block;
      position: absolute;
      top: 4.5rem;
    }
  }

  .as-filter-input {
    font-size: 1.4em;
    display: flex;
    margin: 0 2.5rem 1rem 1.5rem;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    border: none;
    border-bottom: 0.1rem solid $lmode-border-color;
    width: 90%;

    &:focus {
      outline: none;
    }
  }

  .as-list {
    align-items: flex-start;
    background-color: $text-light;
    border-radius: 1em;
    display: block;
    flex-flow: column nowrap;
    flex: 1 0 auto;
    height: 9em;
    width: 35em;
    justify-content: flex-start;
    list-style: none;
    margin: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0 0.5em 0 0;
  }

  .as-list-item {
    display: flex;
    align-items: center;
    border-radius: 10em;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: flex-start;
    margin: 0.5em 0.5em;
    padding: 0.5em 0.5em;

    .tick {
      display: none;
    }

    button {
      font-size: 1.2em;
      color: $lmode-body-text;
      font-weight: bold;
    }

    &:hover,
    &.selected {
      background-color: $grey-background;

      button {
        color: $primary-active;
      }
    }

    a,
    button {
      align-items: center;
      background: transparent;
      border: none;
      flex: 1 1 auto;
      justify-content: space-between;
      padding: 0;
      text-decoration: none;
      display: inline-block;
      width: 100%;
      max-width: 14rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
    }
  }

  .administer {
    margin-top: 1.5em;

    a {
      font-size: 1em;
      color: $primary-active;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 0.1em;
    }
  }
}
