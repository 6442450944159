.manage-ifttt-applet-triggers-and-actions .manage-ifttt-applet-triggers-and-actions-top {
  width: 100%;
  margin: auto;
  padding-top: 85px;
}
.manage-ifttt-applet-triggers-and-actions .manage-ifttt-applet-triggers-and-actions-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.manage-ifttt-applet-triggers-and-actions .applet-name-container {
  background-color: #f8f8f8;
  padding: 8px 10px;
  border-radius: 7px;
  text-align: center;
  color: #14bae3;
  margin-bottom: 8px;
  text-transform: uppercase;
  font-size: 11px;
  height: 30px;
}
.manage-ifttt-applet-triggers-and-actions .applet-name-container .applet-name {
  font-weight: bold;
  font-style: italic;
}
.manage-ifttt-applet-triggers-and-actions .applet-name-container i {
  float: right;
  margin-top: 2px;
}
.manage-ifttt-applet-triggers-and-actions .applet-name-container:hover {
  color: #23527c;
  cursor: pointer;
}
.manage-ifttt-applet-triggers-and-actions .manage-ifttt-applet-triggers-and-actions-top-inner {
  display: flex;
  align-items: center;
  justify-content: center;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-columns-container {
  display: flex;
  flex-direction: column;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-columns {
  display: flex;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column:not(:last-child) {
  margin-right: 8px;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-columns-nav-arrow {
  width: 50px;
  text-align: center;
  color: #14bae3;
  font-size: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  margin-top: 30px;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-columns-nav-arrow i:hover {
  cursor: pointer;
  color: #23527c;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column-body {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  background-color: #f8f8f8;
  padding: 0 15px;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column-body i {
  font-size: 40px;
  margin: 20px 5px;
  color: #c9d2d8;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column-body .applet-config-column-body-title,
.manage-ifttt-applet-triggers-and-actions .applet-config-column-body .applet-config-column-body-sub-title {
  font-style: italic;
  color: #c9d2d8;
  font-weight: normal;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column-body .applet-config-column-body-sub-title {
  font-size: 12px;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column-header,
.manage-ifttt-applet-triggers-and-actions .applet-config-column-footer {
  border-radius: 5px;
  padding: 8px 10px;
  text-align: center;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: normal;
  cursor: pointer;
  width: 230px;
  margin: auto;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column-header i,
.manage-ifttt-applet-triggers-and-actions .applet-config-column-footer i {
  float: right;
  margin-top: 3px;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column-header {
  color: white;
  margin-bottom: 8px;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column-footer {
  font-style: italic;
  color: white;
  margin-top: 8px;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.service-and-accounts .applet-config-column-header,
.manage-ifttt-applet-triggers-and-actions .applet-config-column.service-and-accounts .applet-config-column-footer.active {
  background-color: #a0bdce;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.service-and-accounts .applet-config-column-header:hover,
.manage-ifttt-applet-triggers-and-actions .applet-config-column.service-and-accounts .applet-config-column-footer.active:hover {
  background-color: #8fb1c5;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.service-and-accounts .applet-config-column-body {
  border-color: #c2d4df;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.service-and-accounts .applet-config-column-body i {
  color: #a0bdce;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.service-and-accounts .ingredient-label {
  color: #a0bdce;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.triggers .applet-config-column-header,
.manage-ifttt-applet-triggers-and-actions .applet-config-column.triggers .applet-config-column-footer.active {
  background-color: #a12378;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.triggers .applet-config-column-header:hover,
.manage-ifttt-applet-triggers-and-actions .applet-config-column.triggers .applet-config-column-footer.active:hover {
  background-color: #8c1e68;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.triggers .applet-config-column-body {
  border-color: #c7569e;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.triggers .applet-config-column-body i {
  color: #a12378;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.triggers .ingredient-label {
  color: #a12378;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.actions .applet-config-column-header,
.manage-ifttt-applet-triggers-and-actions .applet-config-column.actions .applet-config-column-footer.active {
  background-color: #acb638;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.actions .applet-config-column-header:hover,
.manage-ifttt-applet-triggers-and-actions .applet-config-column.actions .applet-config-column-footer.active:hover {
  background-color: #9aa332;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.actions .applet-config-column-body {
  border-color: #d8ddb0;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.actions .applet-config-column-body i {
  color: #acb638;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.actions .ingredient-label {
  color: #acb638;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column:not(.has-items) .applet-config-column-body i {
  color: #bac6cd;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.active .applet-config-column-header {
  pointer-events: none;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.active .applet-config-column-body {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.active .applet-config-column-body .applet-config-column-body-title,
.manage-ifttt-applet-triggers-and-actions .applet-config-column.active .applet-config-column-body .applet-config-column-body-sub-title {
  color: #14bae3;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.active .applet-config-column-footer.active {
  background-color: #14bae3;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.active .applet-config-column-footer.active:hover {
  background-color: shade(#14bae3, 5%);
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.has-items.active .applet-config-column-body {
  border-width: 3px;
  border-style: solid;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.future .applet-config-column-body i {
  color: #c9d2d8;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.future .applet-config-column-body:hover {
  cursor: pointer;
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column.future .applet-config-column-body:hover i {
  color: shade(#c9d2d8, 5%);
}
.manage-ifttt-applet-triggers-and-actions .applet-config-column-footer.disabled {
  background-color: #f8f8f8;
  color: #c9d2d8;
  pointer-events: none;
}
.manage-ifttt-applet-triggers-and-actions .manage-ifttt-applet-triggers-and-actions-bottom-title {
  font-size: 26px;
}
.manage-ifttt-applet-triggers-and-actions .manage-ifttt-applet-triggers-and-actions-bottom-sub-title {
  font-weight: normal;
}
.manage-ifttt-applet-triggers-and-actions .manage-ifttt-applet-triggers-and-actions-bottom-inner {
  margin: 20px;
  width: 100%;
}
.manage-ifttt-applet-triggers-and-actions .manage-ifttt-applet-cards {
  display: flex;
  flex-wrap: wrap;
}
.manage-ifttt-applet-triggers-and-actions ssi-trigger-or-action-card {
  flex-basis: calc(20% - 8px);
  margin-right: 8px;
  margin-bottom: 8px;
}
.manage-ifttt-applet-triggers-and-actions ssi-trigger-or-action-card .btn-primary[disabled] {
  background-color: #c4cfd5;
  border-color: #c4cfd5;
}
.manage-ifttt-applet-triggers-and-actions ssi-trigger-or-action-card .card-form-elements-columns {
  display: flex;
  height: 100%;
  min-height: 0;
}
.manage-ifttt-applet-triggers-and-actions ssi-trigger-or-action-card .card-form-elements {
  display: flex;
  padding: 0 30px;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  width: 100%;
}
.manage-ifttt-applet-triggers-and-actions ssi-trigger-or-action-card .card-form-element {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 0;
}
.manage-ifttt-applet-triggers-and-actions ssi-trigger-or-action-card .card-form-element.flex-basis {
  flex-basis: 50px;
}
.manage-ifttt-applet-triggers-and-actions ssi-trigger-or-action-card .scroll-vertical {
  flex: 1;
}
.manage-ifttt-applet-triggers-and-actions ssi-trigger-or-action-card .ingredient-label {
  background-color: #acb638;
  border-radius: 8px;
  display: inline-block;
  padding: 0 4px;
  margin-bottom: 1px;
}
.manage-ifttt-applet-triggers-and-actions .card-form-element-input {
  background-color: #f0f2f3;
  border-radius: 0;
  border: 0;
  resize: none;
  box-shadow: none;
  text-align: left;
}
.manage-ifttt-applet-triggers-and-actions .card-form-element-input::placeholder {
  color: #c9d8e0;
}
.manage-ifttt-applet-triggers-and-actions textarea.card-form-element-input {
  flex: 1;
}
.manage-ifttt-applet-triggers-and-actions .card-form-element-input:focus {
  box-shadow: none;
  border: 0;
}
.manage-ifttt-applet-triggers-and-actions textarea[ssiTextInputElement].card-form-element-input {
  background: none;
}
.manage-ifttt-applet-triggers-and-actions .text-input-highlight-container .text-highlight-element {
  background-color: #f0f2f3;
}
.manage-ifttt-applet-triggers-and-actions .text-input-highlight-container .text-highlight-element-tag {
  background-color: #cedae1;
}
.manage-ifttt-applet-triggers-and-actions .ingredients-popover {
  border: 0;
  height: 343px;
  width: 300px;
  max-width: initial;
  border-radius: 0;
  padding: 20px;
}
.manage-ifttt-applet-triggers-and-actions .ingredients-popover .popover-title {
  display: none;
}
.manage-ifttt-applet-triggers-and-actions .ingredients-popover h4 {
  text-align: center;
  color: #cedae1;
  font-size: 15px;
  font-weight: normal;
  margin-bottom: 20px;
}
.manage-ifttt-applet-triggers-and-actions .ingredients-popover .scroll-vertical {
  max-height: 230px;
}
.manage-ifttt-applet-triggers-and-actions .popover.ingredients-popover::before {
  content: initial;
}
.manage-ifttt-applet-triggers-and-actions .popover.ingredients-popover::after {
  border: 0;
}
.manage-ifttt-applet-triggers-and-actions .exact-matches-checkbox-wrap {
  display: flex;
  align-items: center;
  padding: 5px 0 20px 0;
}
.manage-ifttt-applet-triggers-and-actions .exact-matches-checkbox-wrap a {
  color: #43537f;
}
.manage-ifttt-applet-triggers-and-actions .exact-matches-checkbox-wrap a:hover {
  color: #14bae3;
}
.manage-ifttt-applet-triggers-and-actions .exact-matches-checkbox-wrap a i {
  margin-left: 5px;
  cursor: pointer;
}

.manage-ifttt-applet-summary {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.manage-ifttt-applet-summary .manage-ifttt-applet-summary-inner {
  width: 80%;
}
.manage-ifttt-applet-summary .manage-ifttt-applet-summary-title {
  text-align: center;
}
.manage-ifttt-applet-summary .manage-ifttt-applet-summary-title-separator {
  height: 8px;
  width: 75px;
  background-color: #14bae3;
  margin: 30px auto;
}
.manage-ifttt-applet-summary .manage-ifttt-applet-summary-sub-title {
  text-align: center;
  font-weight: normal;
  font-size: 18px;
  margin-bottom: 30px;
}
.manage-ifttt-applet-summary .manage-ifttt-applet-summary-columns {
  display: flex;
}
.manage-ifttt-applet-summary .manage-ifttt-applet-summary-save {
  text-align: center;
  margin-top: 80px;
}
.manage-ifttt-applet-summary .manage-ifttt-applet-summary-save .btn {
  width: 200px;
}
.manage-ifttt-applet-summary .manage-ifttt-applet-summary-column.accounts {
  padding: 20px;
  flex: 1;
  background-color: #f8f8f8;
  margin: 0 8px;
}
.manage-ifttt-applet-summary .manage-ifttt-applet-summary-column.accounts .manage-ifttt-applet-summary-column-item {
  color: #a0bdce;
}
.manage-ifttt-applet-summary .manage-ifttt-applet-summary-column.accounts .ingredient-label {
  background-color: #a0bdce;
  color: white;
  border-radius: 8px;
  display: inline-block;
  padding: 0 4px;
}
.manage-ifttt-applet-summary .manage-ifttt-applet-summary-column.triggers {
  padding: 20px;
  flex: 1;
  background-color: #f8f8f8;
  margin: 0 8px;
}
.manage-ifttt-applet-summary .manage-ifttt-applet-summary-column.triggers .manage-ifttt-applet-summary-column-item {
  color: #a12378;
}
.manage-ifttt-applet-summary .manage-ifttt-applet-summary-column.triggers .ingredient-label {
  background-color: #a12378;
  color: white;
  border-radius: 8px;
  display: inline-block;
  padding: 0 4px;
}
.manage-ifttt-applet-summary .manage-ifttt-applet-summary-column.actions {
  padding: 20px;
  flex: 1;
  background-color: #f8f8f8;
  margin: 0 8px;
}
.manage-ifttt-applet-summary .manage-ifttt-applet-summary-column.actions .manage-ifttt-applet-summary-column-item {
  color: #acb638;
}
.manage-ifttt-applet-summary .manage-ifttt-applet-summary-column.actions .ingredient-label {
  background-color: #acb638;
  color: white;
  border-radius: 8px;
  display: inline-block;
  padding: 0 4px;
}
.manage-ifttt-applet-summary .manage-ifttt-applet-summary-column-title {
  font-size: 18px;
  font-weight: normal;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  color: #14bae3;
}
.manage-ifttt-applet-summary .manage-ifttt-applet-summary-column-title i {
  font-size: 14px;
}
.manage-ifttt-applet-summary .manage-ifttt-applet-summary-column-item {
  padding: 10px 15px;
  font-weight: normal;
  margin-top: 8px;
  display: flex;
  background-color: white;
  border-radius: 20px;
}
.manage-ifttt-applet-summary .manage-ifttt-applet-summary-column-item-title-container {
  width: 100%;
}
.manage-ifttt-applet-summary .manage-ifttt-applet-summary-column-item-title {
  font-style: italic;
  font-weight: bold;
}
.manage-ifttt-applet-summary .manage-ifttt-applet-summary-column-item-title:first-letter {
  text-transform: capitalize;
}
.manage-ifttt-applet-summary .manage-ifttt-applet-summary-column-items {
  max-height: 25vh;
  overflow-y: auto;
}
.manage-ifttt-applet-summary .manage-ifttt-applet-summary-column-item-icon {
  margin-right: 12px;
  font-size: 18px;
}
.manage-ifttt-applet-summary .ingredient-label {
  margin-bottom: 2px;
}