ssi-toggle-switch-2 {
  font-size: 1rem;
}
ssi-toggle-switch-2 .toggle-switch-2-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
ssi-toggle-switch-2 .toggle-switch-2-label {
  color: #43537f;
  font-size: 1em;
  font-weight: 900;
  letter-spacing: 0.1rem;
  padding-left: 1.4rem;
  padding-right: 2rem;
}
ssi-toggle-switch-2 .toggle-switch-2-label.active {
  color: #14bae3;
}
ssi-toggle-switch-2 .switch {
  position: relative;
  display: inline-block;
  width: 3.4em;
  height: 2em;
  flex: 0 0 auto;
}
ssi-toggle-switch-2 .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
ssi-toggle-switch-2 .switch input:focus + span {
  outline: auto -webkit-focus-ring-color;
  transition: outline 0s;
}
ssi-toggle-switch-2 .switch input:checked + .slider {
  background-color: #14bae3;
}
ssi-toggle-switch-2 .switch input:checked + .slider:before {
  background-color: #fff;
  transform: translateX(1.6em);
}
ssi-toggle-switch-2 .switch input:checked:focus + .slider {
  box-shadow: 0 0 0.1em #14bae3;
}
ssi-toggle-switch-2 .switch input:focus {
  outline: none;
}
ssi-toggle-switch-2 .switch input:focus + .slider {
  outline: none;
}
ssi-toggle-switch-2 .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #b4bbce;
  transition: 0.4s;
}
ssi-toggle-switch-2 .slider:before {
  position: absolute;
  content: "";
  height: 1.2em;
  width: 1.2em;
  left: 0.3em;
  bottom: 0.4em;
  background-color: #fff;
  transition: 0.4s;
}
ssi-toggle-switch-2 .slider:focus-visible {
  outline: none;
}
ssi-toggle-switch-2 .slider.round {
  border-radius: 10em;
}
ssi-toggle-switch-2 .slider:before {
  border-radius: 50%;
}
ssi-toggle-switch-2 .slider.disabled {
  cursor: not-allowed;
}
ssi-toggle-switch-2 label {
  margin: 0;
}
ssi-toggle-switch-2 .ts2-a11y-label {
  position: absolute;
  text-indent: -9999px;
}
ssi-toggle-switch-2.wrapped .toggle-switch-2-container {
  display: flex;
  align-items: center;
  font-size: 14px;
  width: 100%;
  border-width: 0;
  height: 46px;
  color: #101525;
  font-weight: 700;
  text-rendering: geometricPrecision;
  padding: 0 12px 0 0;
  background-color: #f8f9fd;
  border: 1px solid #838eab;
  border-radius: 10px;
}
ssi-toggle-switch-2.wrapped.toggled .toggle-switch-2-container {
  background-color: #e4faff;
  border: 1px solid #838eab;
}
ssi-toggle-switch-2.wrapped .toggle-switch-2-container {
  width: 100%;
}
ssi-toggle-switch-2.wrapped .toggle-switch-2-label {
  letter-spacing: 0;
  color: #43537f;
}
ssi-toggle-switch-2.wrapped .toggle-switch-2-label.active {
  color: #43537f;
}
ssi-toggle-switch-2.wrapped input:checked + .slider:before {
  background-color: #fff;
  transform: translateX(1.6em);
}