ssi-ad-stats-build {
  font-size: 1rem;
}
ssi-ad-stats-build .ad-stats-build-container {
  display: flex;
  flex-direction: column;
}
ssi-ad-stats-build .ad-stats-build-container .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  padding: 0 60px;
  background-color: #f4f4fa;
  box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.25);
  z-index: 1;
}
ssi-ad-stats-build .ad-stats-build-container .head h3 {
  font-size: 14px;
  color: #101525;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
}
ssi-ad-stats-build .ad-stats-build-container .head h2 {
  color: #101525;
  font-weight: 900;
  font-size: 20px;
}
ssi-ad-stats-build .ad-stats-build-container .body {
  height: calc(100vh - 168px);
  padding: 0 20px 20px 20px;
  background-color: #f4f4fa;
  overflow: auto;
}
ssi-ad-stats-build .ad-stats-build-container .body .body-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 188px);
  padding-bottom: 60px;
  background-color: #fff;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}
ssi-ad-stats-build .ad-stats-build-container .body .body-inner form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
}
ssi-ad-stats-build .ad-stats-build-container .body .body-inner form .form-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
}
ssi-ad-stats-build .ad-stats-build-container .body .body-inner form .form-row.extra-margin-top {
  margin-top: 50px;
}
ssi-ad-stats-build .ad-stats-build-container .body .body-inner form .form-row .form-field {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}
ssi-ad-stats-build .ad-stats-build-container .body .body-inner form .form-row .form-field.half {
  flex-basis: 48%;
}
ssi-ad-stats-build .ad-stats-build-container .body .body-inner form .form-row .form-field > label {
  color: #43537f;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-left: 20px;
  margin-bottom: 15px;
}
ssi-ad-stats-build .ad-stats-build-container .body .body-inner form .form-row .form-field > label .ssi {
  font-size: 13px;
}
ssi-ad-stats-build .ad-stats-build-container .body .body-inner form .form-row .form-field .input-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
ssi-ad-stats-build .ad-stats-build-container .body .body-inner form .form-row .form-field .input-wrapper.select-all-accounts {
  justify-content: flex-end;
}
ssi-ad-stats-build .ad-stats-build-container .body .body-inner form .form-row .form-field .input-wrapper .inline-select-item {
  display: flex;
  align-items: center;
  color: #43537f;
  font-size: 12px;
  font-weight: 900;
  padding: 10px;
  border-radius: 10px;
  background-color: #f8f9fd;
  margin-right: 10px;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
}
ssi-ad-stats-build .ad-stats-build-container .body .body-inner form .form-row .form-field .input-wrapper .inline-select-item i {
  margin-right: 10px;
}
ssi-ad-stats-build .ad-stats-build-container .body .body-inner form .form-row .form-field .input-wrapper .inline-select-item.selected {
  color: #14bae3;
  border: 1px solid #14bae3;
}
ssi-ad-stats-build .ad-stats-build-container .body .body-inner form .form-row .form-field .input-wrapper .inline-select-item:hover {
  color: #fff;
  background-color: #14bae3;
  box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.35);
}
ssi-ad-stats-build .ad-stats-build-container .body .body-inner form .form-row .form-field .input-wrapper .inline-select-item:last-child {
  margin-right: 0;
}
ssi-ad-stats-build .ad-stats-build-container .body .body-inner form .form-row .form-field .input-wrapper ssi-social-network-icon i {
  font-size: 12px;
}
ssi-ad-stats-build .ad-stats-build-container .body .body-inner form .form-row .form-field .input-wrapper .accounts-selector {
  width: 100%;
}
ssi-ad-stats-build .ad-stats-build-container .body .body-inner form .form-row .form-field .input-wrapper .accounts-selector button.dd-head {
  width: 100%;
}
ssi-ad-stats-build .ad-stats-build-container .body .body-inner form .form-row .form-field .input-wrapper .select-accounts-placeholder {
  color: #838eab;
  font-size: 14px;
  font-weight: 700;
}
ssi-ad-stats-build .ad-stats-build-container .body .body-inner form .form-row .form-field .input-wrapper .select-accounts-label {
  color: #101525;
  font-size: 14px;
  font-weight: 900;
  display: flex;
  align-items: center;
}
ssi-ad-stats-build .ad-stats-build-container .body .body-inner form .form-row .form-field .input-wrapper .select-accounts-label ssi-social-network-icon {
  margin-right: 8px;
}
ssi-ad-stats-build .ad-stats-build-container .body .body-inner form .form-row .form-field .input-wrapper .date-picker-input {
  width: 100%;
  height: 46px;
  border: none;
  outline: none;
  border-radius: 100px;
  display: flex;
  align-items: center;
  color: #101525;
  font-size: 14px;
  font-weight: 900;
  background-color: #f8f9fd;
  padding: 10px 30px 10px 20px;
}
ssi-ad-stats-build .ad-stats-build-container .body .body-inner form .form-row .form-field .input-wrapper .date-picker-input::placeholder {
  color: #838eab;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}
ssi-ad-stats-build .ad-stats-build-container .body .body-inner form .submit-button {
  border: none;
  outline: none;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  font-weight: 900;
  background-color: #14bae3;
  padding: 12px 20px;
  margin-top: 30px;
}
ssi-ad-stats-build .ad-stats-build-container .body .body-inner form .submit-button:hover {
  color: #fff;
}
ssi-ad-stats-build .ad-stats-build-container .body .body-inner form .submit-button:disabled {
  background-color: #b4bbce;
}