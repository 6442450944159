@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-suggested-text-modal {
  .suggested-text-modal-container {
    background-color: #fff;
    position: relative;
    border-radius: 15px;

    i.close-icon {
      padding: 14px;
      background-color: #f4f4fa;
      border-radius: 50%;
      color: #838eab;
      font-size: 8px;
      font-weight: 900;
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 1;
      &:hover {
        cursor: pointer;
        color: #14bae3;
      }
    }

    .head {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 80px;
      padding: 10px 30px;
      border-bottom: 1px solid #c3c9d8;

      h3 {
        color: #43537f;
        font-size: 20px;
        font-weight: 900;
        margin: 0;
        user-select: none;
      }
    }

    .body {
      padding: 25px 25px;
      min-height: 20px;
      overflow: auto;

      p {
        color: #43537f;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        margin: 25px;

        &.highlighted {
          background-color: #f7f8fc;
          border-radius: 10px;
          color: #101525;
          font-weight: 900;
          margin: 0;
          padding: 25px 25px;

          &.primary {
            background-color: #e8f8fc;
            border: 1px dashed #14bae3;
          }
        }
      }

      h5 {
        padding: 5px 25px 5px 25px;
        color: #43537f;
        font-size: 10px;
        font-weight: 900;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }

    .foot {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 30px;
      width: 100%;
      min-height: 80px;
      border-top: 1px solid #c3c9d8;

      .left {
        display: flex;
        align-items: center;

        .addl-footer-content-container {
          button {
            letter-spacing: 1.4px;
            text-transform: uppercase;
          }
        }
      }

      .right {
        display: flex;
        align-items: center;

        button {
          margin-left: 10px;
        }
      }
    }
  }
}
