@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-stat-bar-tile {
  font-size: 1rem;

  .sbt {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 1.2em;
    box-shadow: 0 0.5em 1em rgba(131, 142, 171, 0.35);
    height: 15em;
    width: 100%;
    padding: 3.6em 3.2em 4.3em 3.2em;
    margin: 0 0 2em;

    @media print {
      border: 1px solid black;
    }

    p {
      margin-bottom: 0;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-right {
        display: flex;
      }
    }

    &-title {
      color: $lmode-helper-text;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      font-weight: 900;
      text-rendering: geometricPrecision;
      font-size: 1em;
      margin: 0 0 0 -0.6em;
    }

    &-value {
      color: $lmode-title-text;
      font-weight: 900;
      text-rendering: geometricPrecision;
      font-size: 2.8em;
    }

    &-icon {
      color: $lmode-helper-text;
      font-size: 3.6em;
      margin: 0 0 0 1.3rem;
    }

    &-progress {
      position: relative;
      background-color: $lgrey-background !important;
      border-radius: 10em;
      height: 0.4em;
      width: 100%;
      -webkit-print-color-adjust: exact;

      span {
        position: absolute;
        background-color: #425dec;
        display: inline-block;
        width: 0;
        height: 100%;
        border-radius: 10em;
        max-width: 100%;
        min-width: 0;
        -webkit-print-color-adjust: exact;

        @media print {
          background-color: black !important;
        }
      }
    }
  }
}
