@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../../angularjs/common/sass/include-media';
@import '~/styles/colors';

$margin-between-boxes: 20px;
$box-radius: 12px;
$box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.35);
$box-bg-color: #fff;

ssi-campaign ssi-posts-breakdown {
  .progress-bars-reach-totals {
    display: flex;

    .posts-by-network {
      flex: 1 1 75%;
      padding: 40px;
      border-radius: $box-radius;
      background-color: $box-bg-color;
      box-shadow: $box-shadow;

      h4 {
        margin-bottom: 50px;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }

      li {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        span {
          min-width: 100px;
          margin: 0;
          font-weight: 900;
          color: $lmode-helper-text;
          & + span {
            min-width: 70px;
            color: $lmode-title-text;
          }
        }
      }

      @include media('<tablet') {
        width: 100%;
      }
    }

    .network-reach-container {
      display: flex;
      flex-direction: column;
      flex: 1 1 25%;
      border-radius: $box-radius;
      margin-left: $margin-between-boxes;

      @include media('<tablet') {
        width: 100%;
        margin: 15px 0 0 0;
      }
    }

    .account-fact-total {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 15px;
      flex: 1 1 25%;
      margin-bottom: $margin-between-boxes;
      border-radius: $box-radius;
      background-color: $box-bg-color;
      box-shadow: $box-shadow;
      &:last-child {
        margin-bottom: 0;
      }

      .fact-details {
        display: flex;
        align-items: baseline;

        i {
          color: #fff;
          margin-right: 7px;
          font-size: 16px;
          border-radius: 50%;
          padding: 10px;
          &.fa-facebook {
            padding: 10px 12px;
          }
        }
        span {
          display: block;
          font-weight: 900;
          font-size: 16px;
          color: $lmode-body-text;
          &:last-child {
            letter-spacing: 1px;
            font-size: 10px;
            color: $lmode-helper-text;
            text-transform: uppercase;
          }
        }
      }

      ssi-big-number {
        display: flex;
        justify-content: flex-end;
        font-size: 28px;
        font-weight: 900;
        color: $lmode-title-text;
        margin-top: auto;
      }
    }

    @include media('<tablet') {
      flex-wrap: wrap;
    }
    // when there's no reach to show or equal number of reach/posts by network items
    &.wrapped {
      flex-wrap: wrap;

      .posts-by-network,
      .network-reach-container {
        flex-basis: 100%;
      }

      .network-reach-container {
        margin-left: 0;
        margin-top: $margin-between-boxes;
        @include media('>desktop') {
          flex-direction: row;
          margin-top: $margin-between-boxes;

          .account-fact-total {
            margin: 0 0 0 $margin-between-boxes;

            &:first-child {
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  .reactions-inbound {
    display: flex;
    justify-content: space-between;
    margin-top: $margin-between-boxes;

    @media print {
      display: block;
    }

    .single-facts {
      display: flex;
      flex-wrap: wrap;
      width: calc(50% - #{calc($margin-between-boxes / 2)});
      flex: 1 1 auto;

      @include media('<portal') {
        width: 100%;
      }
    }
    .single-fact {
      width: calc(50% - #{$margin-between-boxes});
      margin: calc($margin-between-boxes / 2);
      text-align: center;

      &:first-child,
      &:first-child + * {
        margin-top: 0;
      }
      &:nth-child(odd) {
        margin-left: 0;
      }
      &:nth-child(even) {
        margin-right: 0;
      }

      &:nth-child(3n),
      &:nth-child(4n) {
        margin-bottom: 0;

        @include media('<tablet') {
          margin-bottom: calc($margin-between-boxes / 2);
        }
      }

      @include media('<portal') {
        width: calc(50% - #{calc($margin-between-boxes / 2)});
      }

      @include media('<tablet') {
        width: 100%;
        margin: calc($margin-between-boxes / 2) 0;

        &:first-child + * {
          margin: calc($margin-between-boxes / 2) 0;
        }
      }
    }

    .inbound-comments-pie-chart {
      height: 470px; // highchart pie needs this
      width: calc(50% - #{calc($margin-between-boxes / 2)});
      padding: 40px;
      border-radius: $box-radius;
      background-color: $box-bg-color;
      box-shadow: $box-shadow;

      @media print {
        display: block;
        border: 1px solid black;
        page-break-inside: avoid;
      }

      @include media('<portal') {
        width: 100%;
        margin-top: $margin-between-boxes;
      }
    }

    @include media('<portal') {
      flex-wrap: wrap;
    }
  }

  ssi-fact-overview {
    .fact-overview {
      padding: 30px 15px;
      margin: 0;
    }
    i {
      padding: 10px 10px 0 10px;
    }
  }
}
