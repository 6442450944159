@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-analytics ssi-survey ssi-report {
  .survey-analytics-report-container {
    width: 100%;
    height: 100%;

    $headerHeight: 94px;
    $sidebarWidth: 228px;

    .report-body {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding-top: $headerHeight + 40px;
      padding-left: $sidebarWidth + 40px;
      padding-right: 40px;
      min-height: calc(100vh - 68px);

      @media print {
        display: block;
      }

      .account-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .account-list-item {
          display: flex;
          align-items: center;
          background-color: #ebebf4;
          border-radius: 10px;
          padding: 10px;
          margin: 5px;
          i {
            color: $lmode-helper-text;
          }
          span {
            color: $lmode-body-text;
            font-size: 12px;
            font-weight: 900;
            line-height: 14px;
            margin-left: 10px;
          }
        }
      }

      .section-delimiter {
        width: 100%;
        height: 2px;
        background-color: #fff;
        margin-top: 40px;
        margin-bottom: 40px;
      }

      .section-head,
      .section-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
        h2,
        h3 {
          color: $lmode-title-text;
          font-size: 20px;
          font-weight: 900;
        }
      }

      .facts-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        overflow-x: auto;

        @media print {
          display: block;

          & > * {
            display: inline-block;
            width: auto !important;
          }
        }

        > * {
          width: 100%;
          padding: 7px;
          margin-left: 10px;
          &:first-child {
            margin-left: 0;
          }
          .fact-overview {
            box-shadow: 0 0.5em 1em rgba(131, 142, 171, 0.35);
            margin-bottom: 10px;
          }
        }
      }
    }

    .report-body-loading {
      width: 100%;
      height: 100%;
      height: calc(100vh - 68px);
      padding-top: $headerHeight;
      padding-left: $sidebarWidth;
    }

    .selected-date-ranges {
      border-radius: 10px;
      background-color: #ebebf4;
      padding: 10px;

      span {
        margin-right: 7px;
      }

      .current {
        color: $lmode-title-text;
        font-size: 14px;
        font-weight: 900;
      }
      .previous {
        color: $lmode-title-text;
        font-size: 12px;
        font-style: italic;
        font-weight: 700;
      }
    }

    .margin-bottom-100 {
      margin-bottom: 100px;
    }
  }
}
