@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import './../../../../angularjs/common/sass/variables';
@import '~/styles/colors';

$tag-background-colour: $lmode-border-color;
$tag-text-colour: $lmode-body-text;

// $tag-button-text-hover-colour: darken($tag-text-colour, 5%);
$tag-button-text-hover-colour: $primary-active;

ssi-tags-input {
  & > form {
    & > input.form-control.form-control-minimal {
      border-bottom: 2px solid $lmode-border-color;
      padding-left: 5px;
      color: $lmode-title-text;
      font-style: normal;
      font-weight: 500;

      &:focus {
        border-bottom: 2px solid $primary-active;
      }

      &::placeholder {
        font-size: 12px;
        font-weight: 700;
        font-style: normal;
        color: $lmode-helper-text;
      }
    }
  }

  & > ul {
    display: flex;
    flex-flow: row wrap;
    list-style: none;
    margin: 10px 0 10px 0;
    padding: 0;

    & > .tag {
      align-items: center;
      background-color: $tag-background-colour;
      border-radius: 32px;
      color: $tag-text-colour;
      display: inline-block;
      font-size: 10px;
      font-weight: 900;
      margin: 0 6px 6px 0;
      padding: 5px 10px;

      & > span {
      }

      & > button {
        background-color: transparent;
        border: none;
        margin-left: 6px;
        padding: 0;
        color: $lmode-helper-text;

        &:hover {
          color: $tag-button-text-hover-colour;
        }
      }
    }
  }
}
