@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../angularjs/common/sass/variables';
@import '../../../../angularjs/common/sass/mixins';
@import '~/styles/colors';

ssi-post {
  display: block;
  height: 100%;
}

.post {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  padding: 25px 200px 15px 70px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  overflow: hidden;
  height: 100%;
  min-height: 150px;

  &.post-no-media {
    padding: 25px 65px 15px 70px;
    min-height: 0;
  }

  &.objective {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.025);
    margin-bottom: 0;

    .post-body {
      max-height: 34px;
      margin: 0 0 20px;
    }
  }
}

.post-side {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  left: 25px;
  width: 45px;
  height: 100%;
}

.post-soc {
  display: block;
  height: 32px;
  width: 32px;
  background-color: #666;
  border-radius: 50%;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
  }
}

.post-head {
  margin-bottom: 10px;
}

.post-head-soc {
  display: inline-block;
  color: $primary-active;
  margin: 0 15px 0 0;
}

.post-head-soc-icon {
  display: inline-block;
  font-size: 12px;
  line-height: 14px;
  vertical-align: bottom;
  font-weight: 500;
}

.post-head-soc-name {
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  vertical-align: bottom;
  margin: 0;
}

.post-head-info {
  display: inline-block;
  font-size: 11px;
  line-height: 11px;
  margin: 0;
  padding: 0;
  vertical-align: bottom;
  font-weight: 500;

  > li {
    display: inline-block;
    padding: 0 6px;
    border-right: 1px solid #a8b9c2;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      border-right-width: 0;
    }
  }

  .ssi {
    display: inline-block;
    color: $primary-active;
    position: relative;
    top: -1px;
    padding-right: 3px;
  }

  p {
    display: inline-block;
    margin: 0;
  }
}

.post-body {
  font-weight: 500;
  color: #000;
  font-size: 12px;
  line-height: 18px;
  max-height: none;
  overflow: hidden;

  p {
    white-space: pre-wrap;
  }
}

.post-status {
  display: flex;
  justify-content: space-between;
  padding: 0 15px 0 0;
  margin: 0;
  font-weight: 500;

  > li {
    display: inline-block;
  }
}

.post-status-icon {
  display: inline-block;
  font-size: 12px;
}

.post-status-label {
  display: inline-block;
  font-size: 9px;
  vertical-align: top;
  line-height: 16px;
}

.post-gallery {
  position: absolute;
  right: 40px;
  top: 10px;
  bottom: 8px;
  margin: auto;
  width: 135px;
  height: calc(100% - 14px);
  display: flex;
  align-items: center;
  max-height: 150px;
  cursor: pointer;
}

.post-gallery-frame {
  display: block;
  border: 4px solid #fff;
  height: 100%;
  width: 100%;
  background-size: cover;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.post-gallery-frame-stack {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  box-shadow: none;

  &:first-child {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  }

  & + .post-gallery-frame-stack {
    left: 15px;

    & + .post-gallery-frame-stack {
      left: 30px;
    }
  }
}

.post-menu {
  position: absolute;
  top: 0;
  right: 0;
  width: 45px;
  padding-top: 15px;
  text-align: center;
  background: #fff;
  height: 100%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.post-menu-items {
  position: absolute;
  top: 9px;
  right: 5px;
  display: flex;
  background-color: #ffffff;
  color: $primary-active;
  padding: 8px;
  min-width: 35px;
  border-radius: 20px;
  text-align: center;
  box-shadow: $box-shadow-default;

  > * {
    margin: 0 7px;
    cursor: pointer;
    line-height: 20px;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .text-danger {
    color: $secondary-red;
  }

  .useless-icon {
    font-size: 18px;
    cursor: default;
    margin: 0;
  }
}

.post-action {
  color: $primary-active;
  font-size: 18px;
  vertical-align: middle;
  cursor: pointer;
}
