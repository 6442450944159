@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-stream-card {
  font-size: 1rem;

  .stream-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2.8em 1.5em 4.5em;
    text-align: center;
    height: 36em;
    // width: 25.6em;
    margin: 0 auto;
    border: 0.1em solid #89a4ea;
    border-radius: 1.2em;
    background-color: #fff;
    color: #43537f;
    overflow: hidden;
  }

  .stream-card-header {
    p {
      font-size: 1em;
      color: #838eab;
      font-weight: 900;
      text-rendering: geometricPrecision;
      margin: 0;
    }
  }

  .stream-card-header-action {
    display: inline-block;
    height: 3.6em;
    width: 3.6em;
    border-radius: 50%;
    margin: 2.4em 0 0;
    border-width: 0;
    background-color: #f8f9fd;

    &:hover,
    &:focus {
      background-color: darken(#f8f9fd, 3%);
    }

    &:active {
      background-color: darken(#f8f9fd, 6%);
    }

    i {
      color: #838eab;
      font-size: 1.4em;
    }
  }

  .stream-card-body {
    display: flex;
    align-items: center;
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: space-between;
    color: #43537f;
    margin: 0.8em 0 0;

    h3 {
      font-size: 1.4em;
      font-weight: 900;
      text-rendering: geometricPrecision;
      margin: 0 0 0.4em;
    }

    p {
      font-size: 1em;
      line-height: 1.4em;
      font-weight: 700;
      text-rendering: geometricPrecision;
      margin: 0;
    }

    .print-query-comparison {
      display: inline-block;
      width: 100%;
      line-height: 2em;
      background-color: #fff;
      z-index: 2;

      &:before,
      &:after {
        content: '';
        z-index: 1;
        display: inline-block;
        vertical-align: middle;
        width: 6em;
        border-bottom: 0.1em solid #43537f;
        opacity: 0.6;
      }

      &:before {
        left: 0;
      }

      &:after {
        right: 0;
      }
    }
  }

  .stream-card-body-content {
    height: 10em;
    overflow: hidden;
    padding-bottom: 1em;
    
    p {
      word-break: break-all;
    }
  }

  .stream-sources {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 0 3em;
    padding: 0;
    height: 3em;

    &-break {
      flex: 1 0 auto;
      list-style: none;
    }
  }

  .stream-source {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3em;
    width: 3em;
    border-radius: 50%;
    margin: 0 -0.3em;

    i {
      font-size: 1.6em;
      color: #fff;
    }
  }

  .stream-source-more {
    width: 100%;
    padding: 0.3em 0 0;
    cursor: default;

    p {
      display: inline-block;
    }
  }

  orlo-button.stream-card-footer-action {
    button {
      padding: 7px 22px 10px;
    }

    .leading-icon {
      font-size: 0.8em;

      & + span {
        margin: 0 0 0 5px;
        vertical-align: middle;
      }
    }
  }
}
