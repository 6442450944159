@import "./src/apps/angularjs/common/sass/global-variables.scss";
$mainHeaderHeight: 68px;

ssi-insights ssi-reports ssi-view-report {
  .global-filters-sidebar {
    position: fixed;
    top: 0;
    right: 0;
    width: 500px;
    height: calc(100% - $mainHeaderHeight);
    margin-top: $mainHeaderHeight;
    background-color: #fff;
    box-shadow: 0 18px 40px 0 rgba(131, 142, 171, 0.35);
    z-index: 3;
  }
  .view-report-container {
    width: 100%;

    .header {
      height: 60px;
      background-color: #fff;
      display: flex;
      align-items: center;
      padding: 0 40px;

      h3 {
        padding: 0;
        margin: 0;
        color: #101525;
        font-size: 16px;
        font-weight: 900;
        line-height: 14px;
      }
    }

    .actions-bar {
      height: 60px;
      background-color: #f4f4fa;
      border-top: 1px solid #838eab;
      border-bottom: 1px solid #838eab;
      display: flex;
      align-items: center;
    }

    .content-wrapper {
      height: 100%;
      background-color: #f4f4fa;
    }
  }
}
