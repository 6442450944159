ssi-widget-monitoring-stream {
  height: 100%;
  width: 100%;
}
ssi-widget-monitoring-stream .posts-container {
  height: 100%;
  width: 100%;
  background-color: #fff;
}
ssi-widget-monitoring-stream .posts-container .posts-wrapper {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
ssi-widget-monitoring-stream .posts-container .posts-wrapper .scrollable-content {
  display: flex;
  flex-wrap: wrap;
}
ssi-widget-monitoring-stream .posts-container .posts-wrapper .scrollable-content .post-wrapper {
  flex-basis: 100%;
  margin: 10px 10px 0 10px;
}
ssi-widget-monitoring-stream .posts-container .posts-wrapper .scrollable-content .post-wrapper ssi-insights-post .insights-post-container .post-body {
  height: 140px;
}
ssi-widget-monitoring-stream .posts-container .no-posts-wrapper {
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}