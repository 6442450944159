ssi-emoji-autocomplete .emoji-dropdown-container {
  position: absolute;
  z-index: 1000;
}
ssi-emoji-autocomplete .emoji-dropdown-container .emoji-dropdown {
  min-width: 150px;
  font-size: 14px;
  list-style: none;
  background-color: #fff;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  border-radius: 12px;
  border: 0;
  padding: 10px;
}
ssi-emoji-autocomplete .emoji-dropdown-container .emoji-dropdown li {
  display: flex;
  align-items: center;
  border-radius: 12px;
  color: #aab9c3;
  margin: 1px;
  padding: 4px;
  cursor: pointer;
  font-weight: 700;
  color: #43537f;
}
ssi-emoji-autocomplete .emoji-dropdown-container .emoji-dropdown li:hover {
  background-color: #f8f9fd;
}
ssi-emoji-autocomplete .emoji-dropdown-container .emoji-dropdown li.active {
  background-color: #f8f9fd;
  color: #14bae3;
}