@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../../angularjs/common/sass/variables';
@import '../../styles/variables';
@import '~/styles/colors';

ssi-modal-list {
  font-size: 1rem;

  .drilldown-modal-content-container {
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 12px;
    height: 100%;
  }

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    padding: 2em 2em 2.1em 3em;
    border-bottom: 1px solid #c3c9d8;

    .title {
      color: #43537f;
      text-transform: capitalize;
      margin: 0.2em 0 0;
      padding: 0;
      line-height: 1;
      font-size: 2em;
      font-weight: 900;
      text-rendering: geometricPrecision;
    }

    button {
      display: inline-block;
      border-radius: 50%;
      background-color: $lgrey-background;
      border-width: 0;
      height: 3.6em;
      width: 3.6em;
      outline-width: 0;

      .ssi {
        font-size: 1.2em;
        color: $lmode-helper-text;
      }

      .ssi-addsmall {
        transform: rotate(45deg);
      }
    }
  }

  .drilldown-content {
    padding: 4em 3em 5em 2.5em;
  }
}
