ssi-top-post-list .top-post-list-container .row.dropdown-select {
  margin-bottom: 40px;
}
ssi-top-post-list .top-post-list-container .row .top-post-container {
  display: flex;
  align-items: center;
}
ssi-top-post-list .top-post-list-container .row .top-post-container .top-post {
  flex: 1 1 auto;
}
ssi-top-post-list .top-post-list-container .row .top-post-container .top-post-stats {
  flex: 0 0 340px;
  display: flex;
  justify-content: space-between;
  height: 174px;
  padding: 20px 30px;
}
ssi-top-post-list .top-post-list-container .row .top-post-container .top-post-stats .top-post-stat-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 10px;
}
ssi-top-post-list .top-post-list-container .row .top-post-container .top-post-stats .top-post-stat-list .stat {
  flex: 1 0 50%;
  width: 100px;
  padding: 5px 0;
}
ssi-top-post-list .top-post-list-container .row .top-post-container .top-post-stats .top-post-stat-list .stat i {
  margin-right: 0.6em;
  font-size: 16px;
}
ssi-top-post-list .top-post-list-container .row .top-post-container .top-post-stats .top-post-stat-list .stat i.ssi-eye {
  font-size: 18px;
}
ssi-top-post-list .top-post-list-container .row .top-post-container .top-post-stats .top-post-stat-list .stat i.ssi-reach {
  font-size: 18px;
}
ssi-top-post-list .top-post-list-container .row .top-post-container .top-post-stats .top-post-stat-list .stat ssi-big-number {
  width: 45px;
  display: inline-block;
  font-size: 16px;
  color: #101525;
  font-weight: 900;
}
ssi-top-post-list .top-post-list-container .row .top-post-container .top-post-stats .top-post-top-score {
  text-align: center;
  font-weight: 900;
  font-size: 14px;
  color: #101525;
  position: relative;
  padding: 0 10px;
}
ssi-top-post-list .top-post-list-container .row .top-post-container .top-post-stats .top-post-top-score #rank-0 div {
  background-color: #fbf3e2;
}
ssi-top-post-list .top-post-list-container .row .top-post-container .top-post-stats .top-post-top-score #rank-0 i {
  color: #f0b427;
}
ssi-top-post-list .top-post-list-container .row .top-post-container .top-post-stats .top-post-top-score #rank-1 div {
  background-color: #eff2fd;
}
ssi-top-post-list .top-post-list-container .row .top-post-container .top-post-stats .top-post-top-score #rank-1 i {
  color: #b4bbce;
}
ssi-top-post-list .top-post-list-container .row .top-post-container .top-post-stats .top-post-top-score #rank-2 div {
  background-color: #fff1ed;
}
ssi-top-post-list .top-post-list-container .row .top-post-container .top-post-stats .top-post-top-score #rank-2 i {
  color: #f88c68;
}
ssi-top-post-list .top-post-list-container .row .top-post-container .top-post-stats .top-post-top-score .rank {
  position: relative;
  margin: 0 0 2em 0;
}
ssi-top-post-list .top-post-list-container .row .top-post-container .top-post-stats .top-post-top-score .rank div {
  margin: 0 auto;
  width: 46px;
  height: 46px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}
ssi-top-post-list .top-post-list-container .row .top-post-container .top-post-stats .top-post-top-score .rank i {
  position: absolute;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 4em;
  color: #eaedf4;
}
ssi-top-post-list .top-post-list-container .row .top-post-container .top-post-stats .top-post-top-score .number {
  display: block;
  font-size: 20px;
  position: relative;
  z-index: 2;
  line-height: 1.4em;
}
ssi-top-post-list .top-post-list-container .row .top-post-container .top-post-stats .top-post-top-score .subtitle {
  text-transform: uppercase;
  font-size: 10px;
  position: relative;
  z-index: 2;
  color: #838eab;
  letter-spacing: 1px;
  font-weight: 700;
}
ssi-top-post-list .top-post-list-container .row .delimiter {
  height: 2px;
  background-color: #ecf0fb;
  border-radius: 10px;
  margin: 20px 15px;
}