@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-detected-themes {
  .detected-themes-container {
    .section-head {
      padding: 0;
      margin-left: 20px;
      .title {
        display: flex;
        align-items: center;
        // i {
        //   font-size: 20px;
        //   color: #838eab;
        //   margin-right: 10px;
        // }
        h2 {
          color: #43537f;
          font-size: 20px;
          font-weight: 900;
          line-height: 40px;
          margin: 0;
        }
      }
    }

    .section-body.table-container {
      padding: 30px 30px 60px 30px;
      background-color: #fff;
      border-radius: 10px;

      .head-cell {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;
        padding: 0 5px 0 10px;
      }

      .body-cell {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;
        padding: 0 5px 0 10px;
        border-bottom: 2px solid #e0e7f4;

        &.row-even {
          background-color: $grey-background;
        }

        span {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          color: #43537f;
          font-size: 12px;
          font-weight: 900;

          @media print {
            white-space: normal;
          }

          &.theme-name {
            font-size: 14px;
            &:hover {
              cursor: pointer;
              opacity: 0.8;
            }
          }
        }
      }

      ssi-paginator {
        padding-top: 40px;
      }

      ssi-no-results {
        .no-results {
          padding: 100px;
        }
      }
    }
  }
}
