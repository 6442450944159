ssi-detected-themes .detected-themes-container .section-head {
  padding: 0;
  margin-left: 20px;
}
ssi-detected-themes .detected-themes-container .section-head .title {
  display: flex;
  align-items: center;
}
ssi-detected-themes .detected-themes-container .section-head .title h2 {
  color: #43537f;
  font-size: 20px;
  font-weight: 900;
  line-height: 40px;
  margin: 0;
}
ssi-detected-themes .detected-themes-container .section-body.table-container {
  padding: 30px 30px 60px 30px;
  background-color: #fff;
  border-radius: 10px;
}
ssi-detected-themes .detected-themes-container .section-body.table-container .head-cell {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 0 5px 0 10px;
}
ssi-detected-themes .detected-themes-container .section-body.table-container .body-cell {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 0 5px 0 10px;
  border-bottom: 2px solid #e0e7f4;
}
ssi-detected-themes .detected-themes-container .section-body.table-container .body-cell.row-even {
  background-color: #f8f9fd;
}
ssi-detected-themes .detected-themes-container .section-body.table-container .body-cell span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #43537f;
  font-size: 12px;
  font-weight: 900;
}
@media print {
  ssi-detected-themes .detected-themes-container .section-body.table-container .body-cell span {
    white-space: normal;
  }
}
ssi-detected-themes .detected-themes-container .section-body.table-container .body-cell span.theme-name {
  font-size: 14px;
}
ssi-detected-themes .detected-themes-container .section-body.table-container .body-cell span.theme-name:hover {
  cursor: pointer;
  opacity: 0.8;
}
ssi-detected-themes .detected-themes-container .section-body.table-container ssi-paginator {
  padding-top: 40px;
}
ssi-detected-themes .detected-themes-container .section-body.table-container ssi-no-results .no-results {
  padding: 100px;
}