@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-confirm-action {
  .tooltip-actions {
    background-color: #fff;
    border-radius: 12px;
    padding: 20px;
    max-width: 280px;
    height: 100%;
    box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.35);

    .buttons-container {
      display: flex;
      justify-content: flex-end;

      button {
        span {
          letter-spacing: 1px;
          font-weight: 900;
          text-transform: uppercase;
        }
      }
    }

    h5 {
      text-align: left;
      color: $lmode-body-text;
      white-space: normal;
      text-transform: none;
      margin-bottom: 2rem;
      line-height: 20px;
    }
  }
}
