@import "./src/apps/angularjs/common/sass/global-variables.scss";
// @import '../../../../../angularjs/common/sass/mixins';
@import '~/styles/colors';

ssi-account-selector {
  .account-selector-container {
    ssi-dropdown-select-2 {
      width: 100%;

      .dd-head {
        width: 100%;
        padding: 0 35px 0 5px;
      }

      .dd-body {
        width: 100%;
        ul {
          max-height: 400px !important;
        }
      }
    }

    &.no-selected-options {
      width: 100%;
      ssi-dropdown-select-2 {
        .dd-head {
          padding: 0 30px 0 20px;
        }
      }
    }

    .select-placeholder {
      font-size: 14px;
      font-weight: 700;
      color: #6a759a;
      text-wrap: nowrap;
    }

    .selected-options-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .chips-container {
        display: flex;
        align-items: center;
        overflow: hidden;

        .chip {
          height: 36px;
          background-color: #f4f4fa;
          border-radius: 8px;
          padding: 0 10px;
          white-space: nowrap;
          margin-right: 5px;

          display: flex;
          align-items: center;

          span {
            font-size: 14px;
            color: #101525;
            font-weight: 900;
            margin: 0;
            margin-left: 10px;
          }

          i {
            font-size: 8px;
            color: #43537f;
            font-weight: 500;
            margin-left: 10px;
            cursor: pointer;

            &.disabled {
              cursor: not-allowed;
            }
          }
        }
      }
    }

    .menu-header {
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      span {
        margin-right: auto;
      }

      .collapse-icon {
        color: $primary-active;
        font-weight: 900;
        font-size: 12px;
        margin-left: 5px;
        margin-right: 8px;
        transform: rotate(180deg);
        cursor: pointer;
      }

      .no-accounts-selected-label {
        color: #6a759a;
        font-weight: 700;
        font-style: italic;
      }
    }
  }
}
