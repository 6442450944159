ssi-global-filters .global-filters {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
ssi-global-filters .global-filters.compact .filters-body {
  padding: 2em 1em 2em;
}
ssi-global-filters .global-filters ssi-tags-input {
  padding: 10px 10px;
}
ssi-global-filters .global-filters .filters-body {
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}
ssi-global-filters .global-filters .filters-title {
  color: #43537f;
  padding: 0 0 2rem 4rem;
  font-size: 1.4em;
  margin: 0;
  line-height: 1;
  font-weight: 900;
  text-rendering: geometricPrecision;
  padding: 2em 0 1em 2em;
}
ssi-global-filters .global-filters ul,
ssi-global-filters .global-filters li {
  list-style: none;
  margin: 0;
}
ssi-global-filters .global-filters ul {
  width: 100%;
  padding: 0 40px;
}
ssi-global-filters .global-filters li {
  border-bottom: 1px solid #c3c9d8;
  padding: 15px 10px;
  font-size: 14px;
  color: #43537f;
  font-weight: 900;
}
ssi-global-filters .global-filters li i {
  color: #838eab;
}
ssi-global-filters .global-filters li i.leading-icon {
  font-size: 14px;
  margin-right: 15px;
}
ssi-global-filters .global-filters li i.leading-icon.ssi-comment, ssi-global-filters .global-filters li i.leading-icon.ssi-image-small {
  font-size: 12px;
}
ssi-global-filters .global-filters li i.leading-icon.location {
  padding-right: 3px;
  font-size: 18px;
}
ssi-global-filters .global-filters h4 {
  text-transform: uppercase;
  color: #838eab;
  letter-spacing: 1px;
  font-size: 10px;
  margin: 0 0 2em 0;
}
ssi-global-filters .global-filters .filter-header {
  cursor: pointer;
  color: #43537f;
}
ssi-global-filters .global-filters .filter-header .filter-header-right {
  margin-top: -8px;
  float: right;
}
ssi-global-filters .global-filters .filter-header .filter-header-right .ssi-arrow-down-new {
  margin-top: 10px;
  color: #43537f;
}
ssi-global-filters .global-filters .filter-header .filter-header-right .ssi-arrow-down-new.flip {
  transform: rotate(180deg);
}
ssi-global-filters .global-filters .filter-header.filter-active span {
  width: 90px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
ssi-global-filters .global-filters .filter-header.filter-active .leading-icon {
  position: relative;
  top: -4px;
}
ssi-global-filters .global-filters .filter-options {
  margin: 2em 0 0 2.2em;
}
ssi-global-filters .global-filters .filter-options-label {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 11px;
  letter-spacing: 1px;
}
ssi-global-filters .global-filters .input-wrapper-half-label {
  margin: 0 10px 20px;
}
ssi-global-filters .global-filters .filter-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
}
ssi-global-filters .global-filters .filter-form .form-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: 100%;
  margin-top: 15px;
}
ssi-global-filters .global-filters .filter-form .form-row.extra-margin-top {
  margin-top: 50px;
}
ssi-global-filters .global-filters .filter-form .form-row.no-margin {
  margin: 0;
}
ssi-global-filters .global-filters .filter-form .form-row.column {
  flex-direction: column;
  align-items: flex-start;
}
ssi-global-filters .global-filters .filter-form .form-row.column .form-field {
  width: 100%;
  align-items: center;
}
ssi-global-filters .global-filters .filter-form .form-row.column .form-field > label {
  width: 120px;
  margin-bottom: 0;
}
ssi-global-filters .global-filters .filter-form .form-row .form-field {
  position: relative;
  display: flex;
  flex-basis: 100%;
  margin: 0 0 20px 0;
}
ssi-global-filters .global-filters .filter-form .form-row .form-field ssi-checkbox-2 {
  width: 100%;
}
ssi-global-filters .global-filters .filter-form .form-row .form-field.half {
  flex-basis: 48%;
}
ssi-global-filters .global-filters .filter-form .form-row .form-field.column {
  flex-direction: column;
  align-items: flex-start;
}
ssi-global-filters .global-filters .filter-form .form-row .form-field > label {
  color: #43537f;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-left: 20px;
  margin-bottom: 15px;
}
ssi-global-filters .global-filters .filter-form .form-row .form-field label.compact {
  width: auto;
  margin: 0;
}
ssi-global-filters .global-filters .filter-form .form-row .form-field .input-wrapper {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
ssi-global-filters .global-filters .filter-form .form-row .form-field .input-wrapper label {
  padding: 0 0 15px 20px;
  font-size: 10px;
  font-weight: 900;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-align: left;
  text-transform: uppercase;
}
ssi-global-filters .global-filters .filter-form .form-row .form-field .input-wrapper.select-all-accounts {
  justify-content: flex-end;
}
ssi-global-filters .global-filters .filter-form .form-row .form-field .input-wrapper .inline-select-item {
  display: flex;
  align-items: center;
  color: #43537f;
  font-size: 12px;
  font-weight: 900;
  padding: 10px;
  border-radius: 10px;
  background-color: #f8f9fd;
  margin: 0 10px 10px 0;
  cursor: pointer;
  user-select: none;
}
ssi-global-filters .global-filters .filter-form .form-row .form-field .input-wrapper .inline-select-item i {
  margin-right: 10px;
}
ssi-global-filters .global-filters .filter-form .form-row .form-field .input-wrapper .inline-select-item.selected {
  color: #14bae3;
  border: 1px solid #14bae3;
}
ssi-global-filters .global-filters .filter-form .form-row .form-field .input-wrapper .inline-select-item:hover {
  color: #fff;
  background-color: #14bae3;
  box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.35);
}
ssi-global-filters .global-filters .filter-form .form-row .form-field .input-wrapper ssi-social-network-icon i {
  font-size: 12px;
}
ssi-global-filters .global-filters .filter-form .form-row .form-field .input-wrapper .accounts-selector {
  width: 100%;
}
ssi-global-filters .global-filters .filter-form .form-row .form-field .input-wrapper .accounts-selector button.dd-head {
  width: 100%;
}
ssi-global-filters .global-filters .filter-form .form-row .form-field .input-wrapper .select-accounts-placeholder {
  color: #838eab;
  font-size: 14px;
  font-weight: 700;
}
ssi-global-filters .global-filters .filter-form .form-row .form-field .input-wrapper .select-accounts-label {
  color: #101525;
  font-size: 14px;
  font-weight: 900;
  display: flex;
  align-items: center;
}
ssi-global-filters .global-filters .filter-form .form-row .form-field .input-wrapper .select-accounts-label ssi-social-network-icon {
  margin-right: 8px;
}
ssi-global-filters .global-filters .filter-form .form-row .form-field .input-wrapper .date-picker-input {
  width: 100%;
  height: 46px;
  border: none;
  outline: none;
  border-radius: 100px;
  display: flex;
  align-items: center;
  color: #101525;
  font-size: 14px;
  font-weight: 900;
  background-color: #f8f9fd;
  padding: 10px 30px 10px 20px;
  appearance: none;
}
ssi-global-filters .global-filters .filter-form .form-row .form-field .input-wrapper .date-picker-input::placeholder {
  color: #838eab;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}
ssi-global-filters .global-filters .filter-form .form-row .form-field .input-wrapper .date-picker-input::-webkit-outer-spin-button, ssi-global-filters .global-filters .filter-form .form-row .form-field .input-wrapper .date-picker-input::-webkit-inner-spin-button {
  appearance: none;
}
ssi-global-filters .global-filters .filter-form .form-row .form-field .input-wrapper .tag-selector {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
ssi-global-filters .global-filters .filter-form .form-row .form-field .input-wrapper .tag-selector .dd-container,
ssi-global-filters .global-filters .filter-form .form-row .form-field .input-wrapper .tag-selector button.dd-head {
  width: 100%;
  height: auto;
}
ssi-global-filters .global-filters .filter-form .form-row .form-field .input-wrapper .tag-selector button.dd-head {
  border-radius: 12px;
}
ssi-global-filters .global-filters .filter-form .form-row .form-field .input-wrapper .tag-selector .select-tags-placeholder {
  color: #838eab;
  font-size: 14px;
  font-weight: 700;
}
ssi-global-filters .global-filters .filter-form .form-row .form-field .input-wrapper .tag-selector .select-tag-labels {
  text-align: left;
  margin: 0 0 -10px -10px;
  color: #fff;
}
ssi-global-filters .global-filters .filter-form .form-row .form-field .input-wrapper .tag-selector .select-tag-labels span {
  padding: 6px 8px;
  font-size: 10px;
  margin: 0 4px 8px 0;
  background-color: #14bae3;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  text-rendering: geometricPrecision;
  word-break: break-all;
}
ssi-global-filters .global-filters .filter-form .form-row .form-field .input-wrapper .tag-selector .select-tag-labels span:hover {
  background-color: #f88c68;
}
ssi-global-filters .global-filters .filter-form .form-row .form-field .input-wrapper .tag-selector .select-tag-labels .ssi {
  margin: 0 0 0 7px;
  font-size: 8px;
}
ssi-global-filters .global-filters .filter-form .form-row .form-field .input-wrapper ssi-dropdown-select-2 .dd-body {
  width: 100%;
}
ssi-global-filters .global-filters .filter-form .form-row .form-field .input-wrapper ssi-dropdown-select-2 .select-placeholder {
  color: #838eab;
}
ssi-global-filters .global-filters .filter-form .form-row .form-field .input-wrapper ssi-dropdown-select-2 .select-label {
  color: #000;
}
ssi-global-filters .global-filters .filter-form .form-row .form-field input[type=number] {
  border: 0.1em solid #ecf0fb;
  padding-left: 5px;
  color: #101525;
  font-style: normal;
  font-weight: 500;
  border-radius: 10px;
  padding: 0.6em 0.8em;
}
ssi-global-filters .global-filters .filter-form .form-row .form-field input[type=number]:focus {
  outline: none;
  border: 0.1em solid #14bae3;
}
ssi-global-filters .global-filters .filter-form .form-row .form-field input[type=number]::placeholder {
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  color: #838eab;
}
ssi-global-filters .global-filters .filter-form .form-row .form-field input[type=number]::-webkit-inner-spin-button,
ssi-global-filters .global-filters .filter-form .form-row .form-field input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
}
ssi-global-filters .global-filters .filters-footer {
  flex: 0 0 auto;
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
  padding: 20px 30px;
  border-top: 1px solid #c3c9d8;
  box-shadow: 0 18px 40px 0 rgba(131, 142, 171, 0.3490196078);
}
ssi-global-filters .global-filters .filters-footer button {
  margin-left: 10px;
}
ssi-global-filters .global-filters .filters-footer button.clear-btn {
  margin-right: auto;
}
ssi-global-filters .global-filters .divider {
  width: 100%;
  margin: 1em 0 2em 0;
  border-bottom: 1px solid #838eab;
}
ssi-global-filters .global-filters .scroll-wrapper {
  padding: 5px 20px 0 0;
  width: 100%;
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}
ssi-global-filters .global-filters .scroll-wrapper::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  background-color: #ecf0fb;
  border-radius: 5px;
}
ssi-global-filters .global-filters .scroll-wrapper::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #14bae3;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
ssi-global-filters .global-filters .cancel-filters {
  display: inline-block;
  margin-right: 5px;
  padding: 10px 12px;
  background-color: #fff1ed;
  border-radius: 10px;
  font-weight: 700;
  font-size: 12px;
}
ssi-global-filters .global-filters .cancel-filters i {
  color: #f88c68;
  font-size: 8px;
  margin: 0 5px;
}
ssi-global-filters .apply-btn {
  background-color: #f0f3f9;
  color: #43537f;
}
ssi-global-filters .apply-btn.active {
  background-color: #14bae3;
  color: white;
  border: none;
}
ssi-global-filters ssi-checkbox-2 .checkbox-2-container label {
  font-weight: 900;
  text-rendering: geometricPrecision;
  font-size: 14px;
  text-transform: none;
  letter-spacing: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
ssi-global-filters ssi-checkbox-2 .checkbox-2-container .checkbox {
  height: 16px;
  width: 16px;
}
ssi-global-filters ssi-checkbox-2 .checkbox-2-container .checkbox ~ i {
  top: 5px;
}
ssi-global-filters ssi-checkbox-2 .checkbox-2-container .checkbox.checked ~ label {
  color: #14bae3;
}
ssi-global-filters ssi-checkbox-2 .checkbox-2-container .checkbox.checked ~ i {
  color: #fff;
}
ssi-global-filters ssi-checkbox-2 .checkbox-2-container.reversed i.checked {
  right: 3px;
}
ssi-global-filters ssi-checkbox-2 .checkbox-2-container .disabled {
  opacity: 0.4;
}
ssi-global-filters ssi-social-network-icon {
  position: absolute;
  margin-left: -20px;
}
ssi-global-filters ssi-social-network-icon i {
  color: #838eab !important;
}
ssi-global-filters ssi-social-network-icon i.meta-icon {
  font-size: 10px;
  padding-bottom: 6px;
}
ssi-global-filters ssi-social-network-icon i.nextdoor-icon {
  font-size: 11px;
  padding-bottom: 6px;
}
ssi-global-filters .author-filters {
  flex: 0 0 auto;
  padding: 0.2em !important;
}
ssi-global-filters .author-filters label {
  width: 100%;
}
ssi-global-filters .author-filter {
  font-size: 1.2em;
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0 3rem 0 1.1rem;
  margin: 0 0.7rem 0.7rem 0 !important;
  flex: 0 1 auto;
  height: 34px;
  background-color: white;
  border: 0.1rem solid #c3c9d8;
  border-radius: 0.8rem;
}
ssi-global-filters .author-filter span {
  font-size: 1.2rem;
  line-height: 1.2rem;
  min-height: 1rem;
  color: #101525;
  font-weight: 900;
  text-rendering: geometricPrecision;
  padding-right: 2em;
}
ssi-global-filters .author-filter-action {
  position: absolute;
  display: block;
  right: 0.6rem;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 0 0.5rem;
  border-width: 0;
  background-color: transparent;
  line-height: 0;
}
ssi-global-filters .author-filter-action:hover .ssi, ssi-global-filters .author-filter-action:focus .ssi {
  color: #14bae3;
}
ssi-global-filters .author-filter-action .ssi {
  font-size: 0.8rem;
  color: #43537f;
}
ssi-global-filters .author-input label {
  width: 100% !important;
  margin-bottom: 15px !important;
}
ssi-global-filters .author-input input {
  border-radius: 1em;
  padding: 10px 30px 10px 20px;
  font-size: 14px;
  font-weight: 900;
}
ssi-global-filters .author-input input:focus {
  outline: none;
}
ssi-global-filters .author-input input::placeholder {
  font-style: normal !important;
}