ssi-dashboard-navbar {
  font-size: 1rem;
}
ssi-dashboard-navbar + .insights-page {
  min-height: calc(100vh - (68px + 60px));
}
ssi-dashboard-navbar .insights-navbar {
  display: flex;
  height: 60px;
  width: 100%;
  padding: 0 1.6em;
  background-color: #fff;
  border-bottom: 1px solid #838eab;
}
ssi-dashboard-navbar .insights-navbar-column {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
}
ssi-dashboard-navbar .insights-navbar-column-right {
  justify-content: flex-end;
  overflow: visible;
}
ssi-dashboard-navbar .insights-navbar-item {
  padding: 0 0.3em;
}
ssi-dashboard-navbar .insights-navbar-item .insights-navbar-button,
ssi-dashboard-navbar .insights-navbar-item button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f4fa;
  color: #43537f;
  border-width: 0;
  border-radius: 1em;
  height: 3.6em;
  line-height: 3.6em;
  min-width: 3.6em;
  white-space: nowrap;
}
ssi-dashboard-navbar .insights-navbar-item .insights-navbar-button:hover, ssi-dashboard-navbar .insights-navbar-item .insights-navbar-button:focus,
ssi-dashboard-navbar .insights-navbar-item button:hover,
ssi-dashboard-navbar .insights-navbar-item button:focus {
  background-color: #e7ecf8;
}
ssi-dashboard-navbar .insights-navbar-item .insights-navbar-button.delete-button,
ssi-dashboard-navbar .insights-navbar-item button.delete-button {
  background-color: #fff1ed;
  color: #f88c68;
}
ssi-dashboard-navbar .insights-navbar-item .insights-navbar-button .ssi,
ssi-dashboard-navbar .insights-navbar-item button .ssi {
  font-size: 1.4em;
}
ssi-dashboard-navbar .insights-navbar-item .insights-navbar-button ssi-icon path,
ssi-dashboard-navbar .insights-navbar-item button ssi-icon path {
  fill: #43537f;
}
ssi-dashboard-navbar .insights-navbar-item .insights-navbar-button-long {
  padding: 0 1.4em;
}
ssi-dashboard-navbar .insights-navbar-item .insights-navbar-button-long > *:first-child {
  display: inline-block;
  margin: 0 0.6rem 0 0;
}
ssi-dashboard-navbar .insights-navbar-item .insights-navbar-button-long span {
  color: #43537f;
  font-size: 1.4rem;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-dashboard-navbar .insights-navbar-item .insights-navbar-button-long .ssi {
  font-size: 1rem;
}
ssi-dashboard-navbar .insights-navbar-item .border {
  border: 1px solid #838eab;
  background-color: transparent;
  color: #14bae3;
}
ssi-dashboard-navbar .insights-navbar-item ssi-dropdown-select-2 {
  z-index: 999;
}
ssi-dashboard-navbar .insights-navbar-item ssi-dropdown-select-2 .dd-container .dd-head {
  background-color: transparent;
  color: #101525;
  font-size: 16px;
}
ssi-dashboard-navbar .insights-navbar-item ssi-dropdown-select-2 .dd-container .dd-head .button-icon {
  color: #101525;
}
ssi-dashboard-navbar .insights-navbar-name {
  padding: 0 1.9em 0 1.4em;
}
ssi-dashboard-navbar .insights-navbar-name.insights-navbar-button .ssi {
  font-size: 1.2rem;
  margin: 0 1rem 0 0;
}
ssi-dashboard-navbar .insights-navbar-name input {
  color: #101525;
  font-weight: 900;
  text-rendering: geometricPrecision;
  font-size: 1.4em;
  margin: 0 0 0 0.3em;
  line-height: 1rem;
  border: none;
  background: none;
  appearance: none;
  outline: none;
}
ssi-dashboard-navbar .insights-navbar-name input::placeholder {
  color: #838eab;
}
ssi-dashboard-navbar .insights-navbar-name {
  display: flex;
  align-items: center;
}
ssi-dashboard-navbar .insights-navbar-dropdown .insights-navbar-button:focus, ssi-dashboard-navbar .insights-navbar-dropdown .insights-navbar-button:hover {
  background-color: #f4f4fa;
}
ssi-dashboard-navbar .insights-navbar-dropdown .dropdown-menu {
  min-width: 24rem;
  padding: 2.4rem 1.9rem 2.3rem;
  margin: 0.7rem 0 0;
  right: 0;
  left: auto;
  border-radius: 1.2rem;
  border-width: 0;
  box-shadow: 0 1.8rem 4rem rgba(131, 142, 171, 0.35);
  overflow: hidden;
}
ssi-dashboard-navbar .insights-navbar-dropdown .dropdown-menu li {
  position: relative;
  display: flex;
  align-items: center;
  height: 3.6rem;
  margin: 0.3rem 0 0.4rem;
  padding: 0.3rem 1rem 0.3rem 1.7rem;
  cursor: pointer;
  border-radius: 1rem;
}
ssi-dashboard-navbar .insights-navbar-dropdown .dropdown-menu li.insights-navbar-item-break:hover, ssi-dashboard-navbar .insights-navbar-dropdown .dropdown-menu li.insights-navbar-item-break:focus {
  cursor: auto;
  background-color: transparent;
}
ssi-dashboard-navbar .insights-navbar-dropdown .dropdown-menu li hr {
  position: absolute;
  left: -2.5rem;
  right: -2.5rem;
  border-top-width: 0;
  border-bottom: 0.1rem solid #c3c9d8;
}
ssi-dashboard-navbar .insights-navbar-dropdown .dropdown-menu li .ssi {
  color: #838eab;
  display: inline-block;
  margin: 0 1.4rem 0 0;
}
ssi-dashboard-navbar .insights-navbar-dropdown .dropdown-menu li .ssi.insights-navbar-item-icon-red {
  color: #f88c68;
}
ssi-dashboard-navbar .insights-navbar-dropdown .dropdown-menu li p {
  margin: 0;
  color: #43537f;
  font-weight: 900;
  text-rendering: geometricPrecision;
}
ssi-dashboard-navbar .insights-navbar-dropdown .dropdown-menu li:hover, ssi-dashboard-navbar .insights-navbar-dropdown .dropdown-menu li:focus {
  background-color: #f8f9fd;
}