@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-insights ssi-widget-table {
  .widget-table-container {
    width: 100%;
    height: 100%;
    padding: 30px 30px 20px 30px;

    .body-cell {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      overflow: hidden;
      padding: 0 5px 0 10px;
      border-bottom: 2px solid #e0e7f4;
      background-color: $grey-background;

      &.row-even {
        background-color: #fff;
      }

      &.stat {
        color: $lmode-title-text;
        font-size: 14px;
        font-weight: 900;

        span,
        ssi-big-number {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          @media print {
            white-space: normal;
          }
        }
      }
    }
  }
}
