@media (min-width: 885px) {
  ssi-publisher-schedule-time-picker {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
ssi-publisher-schedule-time-picker .publisher-schedule-time-picker-inner {
  padding: 0 50px 0 30px;
}
ssi-publisher-schedule-time-picker ssi-highchart {
  display: block;
}
ssi-publisher-schedule-time-picker ssi-highchart .highcharts-background {
  fill: transparent;
}
ssi-publisher-schedule-time-picker ssi-highchart .highcharts-plot-border {
  fill: #f0f3f9 !important;
}
ssi-publisher-schedule-time-picker ssi-highchart .highcharts-axis-labels text {
  font-weight: 700;
}
@media only screen and (max-width: 1200px) {
  ssi-publisher-schedule-time-picker .publisher-schedule-time-picker-inner {
    padding: 0 5px;
  }
  ssi-publisher-schedule-time-picker ssi-highchart {
    margin: 0 15px;
  }
}
ssi-publisher-schedule-time-picker .highcharts-background {
  fill: #f0f3f9;
}
ssi-publisher-schedule-time-picker .highcharts-point,
ssi-publisher-schedule-time-picker .highcharts-label text tspan:nth-child(1) {
  opacity: 0.2;
}
ssi-publisher-schedule-time-picker .highcharts-point-select {
  opacity: 1;
}
ssi-publisher-schedule-time-picker .publisher-schedule-time-picker-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  justify-content: flex-end;
  margin-top: 44px;
  margin-bottom: 10px;
  color: #8b8e9d;
}
@media (max-width: 1200px) {
  ssi-publisher-schedule-time-picker .publisher-schedule-time-picker-footer {
    flex-wrap: wrap;
    height: auto;
  }
  ssi-publisher-schedule-time-picker .publisher-schedule-time-picker-footer .publisher-schedule-time-picker-footer-date-wrap {
    flex-grow: 1;
  }
}
@media (max-width: 885px) {
  ssi-publisher-schedule-time-picker .publisher-schedule-time-picker-footer {
    margin-top: 0;
  }
}
@media (max-width: 768px) {
  ssi-publisher-schedule-time-picker .publisher-schedule-time-picker-footer {
    justify-content: center;
  }
  ssi-publisher-schedule-time-picker .publisher-schedule-time-picker-footer .publisher-schedule-time-picker-footer-date-wrap {
    flex-grow: 0;
    margin-bottom: 10px;
  }
  ssi-publisher-schedule-time-picker .publisher-schedule-time-picker-footer .publisher-schedule-time-picker-footer-timepicker-wrap {
    margin-bottom: 10px;
  }
  ssi-publisher-schedule-time-picker .publisher-schedule-time-picker-footer .publisher-schedule-btn-wrap {
    width: 90%;
  }
  ssi-publisher-schedule-time-picker .publisher-schedule-time-picker-footer .publisher-schedule-btn-wrap .schedule-btn {
    width: 100%;
    border-radius: 10px;
  }
}
ssi-publisher-schedule-time-picker .publisher-schedule-time-picker-footer .publisher-schedule-time-picker-footer-date-wrap {
  display: flex;
  background-color: #f8f9fd;
  border-radius: 100px;
  padding: 15px 20px;
}
ssi-publisher-schedule-time-picker .publisher-schedule-time-picker-footer .publisher-schedule-time-picker-footer-date-wrap button {
  border-width: 0;
  background-color: transparent;
  line-height: 14px;
}
ssi-publisher-schedule-time-picker .publisher-schedule-time-picker-footer .publisher-schedule-time-picker-footer-date-wrap button:focus {
  outline: revert;
}
ssi-publisher-schedule-time-picker .publisher-schedule-time-picker-footer .publisher-schedule-time-picker-footer-date-wrap i {
  color: #14bae3;
  font-size: 12px;
}
ssi-publisher-schedule-time-picker .publisher-schedule-time-picker-footer .publisher-schedule-time-picker-footer-date {
  margin: 1px 20px;
  color: #43537f;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
}
ssi-publisher-schedule-time-picker .publisher-schedule-time-picker-footer .publisher-schedule-time-picker-footer-timepicker-wrap ngb-timepicker {
  display: block;
  margin: 0 8px 0 12px;
}
ssi-publisher-schedule-time-picker .publisher-schedule-time-picker-footer .publisher-schedule-time-picker-footer-timepicker-wrap ngb-timepicker .chevron {
  color: #14bae3;
  font-size: 12px;
}
ssi-publisher-schedule-time-picker .publisher-schedule-time-picker-footer .publisher-schedule-time-picker-footer-timepicker-wrap ngb-timepicker .chevron:before {
  font-weight: 700;
}
ssi-publisher-schedule-time-picker .publisher-schedule-time-picker-footer .publisher-schedule-time-picker-footer-timepicker-wrap ngb-timepicker .ngb-tp-input-container .btn {
  padding: 3px 12px;
}
ssi-publisher-schedule-time-picker .publisher-schedule-time-picker-footer .publisher-schedule-time-picker-footer-timepicker-wrap ngb-timepicker .ngb-tp-input-container .btn:focus {
  outline: revert;
}
ssi-publisher-schedule-time-picker .publisher-schedule-time-picker-footer .publisher-schedule-time-picker-footer-timepicker-wrap ngb-timepicker .ngb-tp-input {
  background-color: #f8f9fd;
  color: #43537f;
  font-size: 12px;
  font-weight: 700;
  height: 46px;
}
ssi-publisher-schedule-time-picker .publisher-schedule-time-picker-footer .publisher-schedule-time-picker-footer-timepicker-wrap ngb-timepicker .ngb-tp-hour {
  position: relative;
}
ssi-publisher-schedule-time-picker .publisher-schedule-time-picker-footer .publisher-schedule-time-picker-footer-timepicker-wrap ngb-timepicker .ngb-tp-hour .ngb-tp-input {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
ssi-publisher-schedule-time-picker .publisher-schedule-time-picker-footer .publisher-schedule-time-picker-footer-timepicker-wrap ngb-timepicker .ngb-tp-hour:after {
  content: ":";
  position: absolute;
  right: -7px;
  top: 0;
  bottom: 2px;
  margin: auto;
  height: 18px;
  color: #43537f;
  font-size: 18px;
  line-height: 18px;
  font-weight: 700;
}
ssi-publisher-schedule-time-picker .publisher-schedule-time-picker-footer .publisher-schedule-time-picker-footer-timepicker-wrap ngb-timepicker .ngb-tp-minute .ngb-tp-input {
  border-radius: 0;
}
ssi-publisher-schedule-time-picker .publisher-schedule-time-picker-footer .publisher-schedule-time-picker-footer-timepicker-wrap ngb-timepicker .ngb-tp-meridian {
  margin-left: -5px;
}
ssi-publisher-schedule-time-picker .publisher-schedule-time-picker-footer .publisher-schedule-time-picker-footer-timepicker-wrap ngb-timepicker .ngb-tp-meridian .btn {
  background-color: #f0f3f9;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  color: #43537f;
  font-size: 12px;
  text-transform: lowercase;
  height: 46px;
  padding: 0 20px 0 15px;
  font-weight: 700;
}
ssi-publisher-schedule-time-picker .publisher-schedule-time-picker-footer .publisher-schedule-time-picker-footer-timepicker-wrap ngb-timepicker .ngb-tp-meridian .btn:focus {
  outline: revert;
}
ssi-publisher-schedule-time-picker .publisher-schedule-time-picker-footer .form-control {
  border: 0;
  box-shadow: none;
  width: 60px;
}