ssi-analytics .network-filter {
  padding: 0;
}
ssi-analytics .network-filter li {
  position: relative;
  letter-spacing: 1px;
  margin: 0 2em 0 0;
  display: inline-block;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  color: #43537f;
  cursor: pointer;
}
ssi-analytics .network-filter li::before {
  content: "";
  transform: scaleX(0);
  visibility: hidden;
  position: absolute;
  top: 22px;
  height: 2px;
  width: 80%;
  left: 10%;
  background-color: #14bae3;
  transition: all 0.3s ease-in-out 0s;
}
ssi-analytics .network-filter li.active:before, ssi-analytics .network-filter li:hover:before {
  transform: scaleX(1);
  visibility: visible;
}
ssi-analytics .network-filter li:last-child {
  margin-right: 0;
}
ssi-analytics .section-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
ssi-analytics .section-heading h3 {
  color: #101525;
  font-size: 20px;
  font-weight: 900;
  margin: 0;
}
ssi-analytics .section-heading .ssi.ssi-help {
  margin: 0 0 0 3px;
  font-size: 14px;
  vertical-align: text-top;
}
ssi-analytics .loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: auto;
  background-color: #fff;
}
ssi-analytics .loading-container img {
  width: 200px;
  height: 200px;
}
ssi-analytics .loading-container h3 {
  font-size: 20px;
  font-weight: 900;
  color: #43537f;
}
ssi-analytics .loading-container p {
  font-weight: bold;
  color: #838eab;
}
@media print {
  ssi-analytics ssi-report-sidebar {
    display: none;
  }
  ssi-analytics ssi-report-header .report-header {
    position: relative;
    padding: 0;
  }
  ssi-analytics ssi-report-header .report-header h1 {
    margin: 0;
  }
  ssi-analytics ssi-report-header .report-header .report-date-selector {
    display: none;
  }
  ssi-analytics .report-body {
    width: 100% !important;
    padding: 0 !important;
  }
  ssi-analytics .section-tip-wrap {
    display: none;
  }
}