.dissaprove-posts p {
  margin: 0 0 1em 0;
}
.dissaprove-posts textarea {
  display: block;
  width: 100%;
  height: 200px;
  margin: 0 0 20px;
  border-radius: 12px;
  border: none;
  padding: 1em;
  font-weight: 500;
}
.dissaprove-posts label {
  margin: 0 0 1em 0;
}
.dissaprove-posts .tag-selector {
  margin-top: 1em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 2em 0;
}
.dissaprove-posts .tag-selector .dd-container,
.dissaprove-posts .tag-selector button.dd-head {
  width: 100%;
  height: auto;
}
.dissaprove-posts .tag-selector button.dd-head {
  border-radius: 12px;
  background-color: white;
}
.dissaprove-posts .tag-selector .select-tags-placeholder {
  color: #838eab;
  font-size: 14px;
  font-weight: 700;
}
.dissaprove-posts .tag-selector .select-tag-labels {
  text-align: left;
  margin: 0 0 -10px -10px;
  color: #fff;
}
.dissaprove-posts .tag-selector .select-tag-labels span {
  padding: 6px 8px;
  font-size: 10px;
  margin: 0 4px 8px 0;
  background-color: #14bae3;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  text-rendering: geometricPrecision;
  word-break: break-all;
}
.dissaprove-posts .tag-selector .select-tag-labels span:hover {
  background-color: #f88c68;
}
.dissaprove-posts .tag-selector .select-tag-labels .ssi {
  margin: 0 0 0 7px;
  font-size: 8px;
}
.dissaprove-posts .buttons-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}