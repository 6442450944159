ssi-cl-dropdown-select-d .cl-dropdown-select-d-container ssi-dropdown-select-2 {
  width: 100%;
}
ssi-cl-dropdown-select-d .cl-dropdown-select-d-container ssi-dropdown-select-2 .dd-head {
  width: 100%;
  padding: 0 35px 0 5px;
}
ssi-cl-dropdown-select-d .cl-dropdown-select-d-container ssi-dropdown-select-2 .dd-body {
  width: 100%;
}
ssi-cl-dropdown-select-d .cl-dropdown-select-d-container ssi-dropdown-select-2 .dd-body ul {
  max-height: 400px !important;
}
ssi-cl-dropdown-select-d .cl-dropdown-select-d-container.no-selected-options {
  width: 100%;
}
ssi-cl-dropdown-select-d .cl-dropdown-select-d-container.no-selected-options ssi-dropdown-select-2 .dd-head {
  padding: 0 30px 0 20px;
}
ssi-cl-dropdown-select-d .cl-dropdown-select-d-container .select-placeholder {
  font-size: 14px;
  font-weight: 700;
  color: #6a759a;
  text-wrap: nowrap;
}
ssi-cl-dropdown-select-d .cl-dropdown-select-d-container .selected-options-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
ssi-cl-dropdown-select-d .cl-dropdown-select-d-container .selected-options-container .chips-container {
  display: flex;
  align-items: center;
  overflow: hidden;
}
ssi-cl-dropdown-select-d .cl-dropdown-select-d-container .selected-options-container .chips-container .chip {
  height: 36px;
  background-color: #f4f4fa;
  border-radius: 8px;
  padding: 0 10px;
  white-space: nowrap;
  margin-right: 5px;
  display: flex;
  align-items: center;
}
ssi-cl-dropdown-select-d .cl-dropdown-select-d-container .selected-options-container .chips-container .chip span {
  font-size: 14px;
  color: #101525;
  font-weight: 900;
  margin: 0;
  margin-left: 10px;
}
ssi-cl-dropdown-select-d .cl-dropdown-select-d-container .selected-options-container .chips-container .chip i {
  font-size: 8px;
  color: #43537f;
  font-weight: 500;
  margin-left: 10px;
  cursor: pointer;
}
ssi-cl-dropdown-select-d .cl-dropdown-select-d-container .selected-options-container .chips-container .chip i.disabled {
  cursor: not-allowed;
}
ssi-cl-dropdown-select-d .cl-dropdown-select-d-container .menu-header {
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
ssi-cl-dropdown-select-d .cl-dropdown-select-d-container .menu-header span {
  margin-right: auto;
}
ssi-cl-dropdown-select-d .cl-dropdown-select-d-container .menu-header .collapse-icon {
  color: #14bae3;
  font-weight: 900;
  font-size: 12px;
  margin-left: 5px;
  margin-right: 8px;
  transform: rotate(180deg);
  cursor: pointer;
}
ssi-cl-dropdown-select-d .cl-dropdown-select-d-container .menu-header .no-accounts-selected-label {
  color: #6a759a;
  font-weight: 700;
  font-style: italic;
}