@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

.embed-nav {
  margin: 1em 2em 2em 2em;
  ul {
    width: 100%;
    display: inline-block;
    list-style: none;
    border-bottom: 4px solid $grey-background;
    li {
      position: relative;
      float: left;
      padding: 2em 0 2em;
      margin: 0 8em 0 0;
      margin-bottom: -4px;
      border-bottom: 4px solid transparent;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 0.8em;
      color: $lmode-helper-text;
      letter-spacing: 1px;
      i {
        font-size: 2em;
        position: absolute;
        left: -30px;
        top: 20px;
      }
      cursor: pointer;
      &.active {
        border-color: $primary-active;
        color: $primary-active;
      }
    }
  }
}

.embed-view {
  margin: 1em 4em;
  color: $lmode-body-text;
  p {
    margin-bottom: 2em;
  }
  textarea {
    width: 90%;
    min-height: 200px;
    padding: 2em 1em;
    border: 3px solid $primary-active;
    border-radius: 10px;
    display: block;
    margin: 0 auto;
    background-color: $grey-background;
    color: #000;
    font-weight: 500;
    &:focus {
      outline: none;
    }
  }
}

.return-nav {
  position: absolute;
  top: -16px;
  width: 150px;
  left: 35px;
}
