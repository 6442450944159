@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-detected-theme-results {
  font-size: 1rem;

  .detected-theme-results-container {
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 12px;
    height: 100%;
  }

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    padding: 20px;
    border-bottom: 1px solid #c3c9d8;

    .title {
      font-size: 2em;
      font-weight: 900;
      color: $lmode-body-text;
      white-space: nowrap;
      flex-basis: 20%;
      margin-bottom: 0;
    }
  }

  .body {
    display: flex;
    height: 100%;
    background-color: #fff;
    overflow: hidden;

    .posts-area {
      overflow: auto;
      background-color: #fff;
      width: 100%;

      ssi-insights-posts {
        .posts-container {
          height: 100%;
          background-color: inherit;
        }
      }

      &.dotted {
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='#F8F9FD' rx='12' ry='12' stroke='#c3c9d8' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 12px;
        box-shadow: inset 0 18px 40px 0 rgba(131, 142, 171, 0.1);
      }
    }

    // .datapoint-date {
    //   text-align: left;
    //   color: $lmode-body-text;
    //   font-size: 1.4em;
    //   font-weight: bold;
    // }

    .paginator-wrapper {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      margin: 20px 0 60px 0;
    }
  }

  .message-info {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    text-align: center;

    i {
      color: #b4bbce;
      font-size: 4em;
    }

    h5 {
      font-size: 2em;
      font-weight: 900;
      color: $lmode-body-text;
    }

    p {
      font-size: 1.4em;
      max-width: 220px;
      font-weight: bold;
      color: $lmode-body-text;
    }
  }
}
