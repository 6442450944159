@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';
ssi-widget-positive-negative-themes {
  display: flex;
  justify-content: center;
  font-size: 1rem;
  overflow: hidden;
  padding: 20px;

  .insights-widget {
    overflow: auto;
    height: 100%;
  }

  .theme {
    display: inline-flex;
    align-items: center;
    margin-bottom: 2px;
    margin-right: 2px;
    border-radius: 5px;

    span,
    ssi-big-number {
      color: $lmode-title-text;
      font-size: 12px;
      font-weight: 900;
      line-height: 14px;
      letter-spacing: 0em;
      text-align: left;
      padding: 6px;
    }

    &:hover {
      cursor: pointer;
    }

    &.positive {
      background-color: #0dcdc2;
    }

    &.negative {
      color: #fff;
      background-color: #f05230;
    }
  }

  .no-results-state {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;

    i {
      color: $dmode-body-text;
      font-size: 40px;
    }
    h4 {
      margin-top: 10px;
      color: $lmode-body-text;
      font-weight: 900;
      font-size: 20px;
    }

    p {
      color: $lmode-body-text;
      text-align: center;
      font-size: 14px;
      font-weight: 700;
    }
  }
}
