@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../../angularjs/common/sass/variables';
@import '../../../../../../angularjs/common/sass/mixins';
@import '~/styles/colors';

ssi-insights ssi-reports ssi-create {
  font-size: 1rem;
  * {
    box-sizing: border-box;
  }

  $appHeaderHeight: 68px;
  $actionBarHeight: 40px;

  .insights-reports-create-container {
    width: 100%;
    position: relative;
    // background-color: #f8f9fd;
    background-color: $lgrey-background;
    min-height: calc(100vh - #{$navbar-height});
    overflow-x: hidden;

    .create-report-empty {
      // max-width: 140em;
      padding: 0 2em;
      margin: 4em auto;

      max-width: 95em;
      margin-top: 19.4em;

      .insights-row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0 auto 2em;

        & > * {
          flex: 1 0 auto;
        }
      }

      .insights-column {
        display: flex;
      }

      .create-report-empty-card {
        flex: 1 0 auto;
        display: inline-block;
        text-align: center;
        margin: 0 auto 5.5em;
        padding: 0 2em;

        h2 {
          font-size: 2em;
          line-height: 1;
          margin: 0 0 1.2rem;
          color: $lmode-body-text;
          font-weight: 900;
          text-rendering: geometricPrecision;
        }

        p {
          color: $lmode-body-text;
          font-weight: 700;
          text-rendering: geometricPrecision;
        }

        &-icon {
          font-size: 4em;
          line-height: 1;
          color: $dmode-body-text;
          margin: 0 0 3rem;
        }

        &-button {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background-color: $primary-active;
          color: #fff;
          height: 4.6em;
          width: 4.6em;
          border-width: 0;
          border-radius: 50%;
          margin: 1em 0 0;

          .ssi {
            font-size: 1.2em;
          }
        }

        &-action {
          display: inline-block;
          margin: 1.2em auto 0;
          cursor: pointer;

          .ssi {
            font-size: 1.6em;
            color: $primary-active;
            line-height: 1.4rem;
            vertical-align: text-bottom;
          }

          p {
            display: inline-block;
            margin: 0 0 0 0.4em;
            color: $lmode-body-text;
            border-bottom: 1px solid $lmode-body-text;
            font-weight: 900;
            text-rendering: geometricPrecision;
          }
        }

        .create-report-empty-card-action {
          a {
            margin: 0 0 0 0.4em;
            color: $lmode-body-text;
            border-bottom: 1px solid $lmode-body-text;
            font-weight: 900;
            text-rendering: geometricPrecision;
            font-size: 14px;
            line-height: 1.25em;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }

    .report-content {
      .actions-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 70px;
        padding: 20px 20px 10px 20px;

        .left {
          ssi-dropdown-select-2 {
            button.dd-head {
              background-color: #fff;

              .head-button-label {
                > span {
                  color: $primary-active;
                }
              }
            }
          }
        }

        .right {
          display: flex;
          align-items: center;

          button {
            border: none;
            outline: none;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            color: $lmode-body-text;
            font-size: 14px;
            font-weight: 900;
            background-color: #e2e2ee;
            padding: 10px 20px;
            margin-left: 10px;

            &:hover {
              color: $primary-active;
            }

            &.save-btn {
              background-color: $primary-active;
              color: #fff;

              &:not([disabled]):hover {
                color: #fff;
              }
            }

            &:disabled {
              background-color: $dmode-body-text;
              color: $lmode-body-text;
            }

            &.cancel-btn {
              background-color: $lgrey-background;
              border: 1px solid $lmode-helper-text;
            }
          }
        }
      }
    }
  }
}
