@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-medal-score {
  .medal-score {
    position: relative;
    height: 46px;
    width: 46px;
    border-radius: 5px;
    background-color: $lgrey-background;

    span {
      position: absolute;
      top: 25%;
      left: 40%;
      color: $lmode-title-text;
      font-size: 14px;
      font-weight: 900;
      line-height: 17px;
    }

    i {
      position: absolute;
      top: 25%;
      left: 15%;
      font-size: 32px;
    }

    // variant with rounded bg and bigger wreath
    &.big {
      background-color: transparent;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fbf3e2;
        border-radius: 50%;
        height: 46px;
        width: 46px;
        top: 10px;
        left: 9px;
      }
      i {
        top: 30px;
        font-size: 50px;
      }
    }
  }
}
