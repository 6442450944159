ssi-outbox-typeahead {
  display: flex;
}
ssi-outbox-typeahead .input-hidden {
  display: none;
}
ssi-outbox-typeahead > * {
  display: flex;
}
ssi-outbox-typeahead .disabled,
ssi-outbox-typeahead [disabled] {
  cursor: not-allowed !important;
  opacity: 0.65 !important;
}
ssi-outbox-typeahead > .outbox-typeahead-container {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
}
ssi-outbox-typeahead > .outbox-typeahead-container .choices-container {
  background-color: #fff;
  overflow: hidden;
}
ssi-outbox-typeahead > .outbox-typeahead-container .choices-container input {
  border: none;
  flex-grow: 1;
  flex-shrink: 0;
  margin: 0;
  padding: 10px;
  width: 100%;
}
ssi-outbox-typeahead > .outbox-typeahead-container .suggestions-filter {
  border: none;
  outline: none;
}
ssi-outbox-typeahead > .outbox-typeahead-container .suggestions-filter::placeholder {
  color: #b8ccd9;
}
ssi-outbox-typeahead > .outbox-typeahead-container ul {
  background-color: #fff;
  flex-flow: column nowrap;
  flex-grow: 0;
  flex-shrink: 0;
  height: 50vh;
  left: 5px;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  top: calc(100% + 2.5px);
  width: 20vw;
}
ssi-outbox-typeahead > .outbox-typeahead-container ul.choices {
  display: flex;
  flex-flow: row wrap;
  flex-grow: 0;
  flex-shrink: 0;
  height: auto;
  left: auto;
  position: relative;
  top: auto;
}
ssi-outbox-typeahead > .outbox-typeahead-container ul.choices > .selected-option {
  background-color: #eee;
  border-radius: 15px;
  color: #43ade8;
  display: inline;
  flex-grow: 0;
  flex-shrink: 1;
  font-size: 0.9em;
  font-style: italic;
  margin: 5px;
  overflow: hidden;
  padding: 5px 25px 5px 10px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
}
ssi-outbox-typeahead > .outbox-typeahead-container ul.choices > .selected-option:after {
  color: #666;
  content: "X";
  font-style: normal;
  position: absolute;
  right: 10px;
}
ssi-outbox-typeahead > .outbox-typeahead-container ul.choices > .selected-option:hover {
  box-shadow: 0 2.5px 5px 1.25px #d4d1d1;
}
ssi-outbox-typeahead > .outbox-typeahead-container ul.choices > .selected-option:hover.disabled {
  box-shadow: none;
}
ssi-outbox-typeahead > .outbox-typeahead-container ul.choices > .selected-option.disabled:after {
  display: none !important;
}
ssi-outbox-typeahead > .outbox-typeahead-container ul.suggestions {
  width: 100%;
  z-index: 999;
}
ssi-outbox-typeahead > .outbox-typeahead-container ul.suggestions > li {
  color: #999;
  margin: 2.5px 5px 2.5px 0;
  padding: 5px 10px;
}
ssi-outbox-typeahead > .outbox-typeahead-container ul.suggestions > li.active {
  background-color: #43ade8;
  color: #fff;
}
ssi-outbox-typeahead > .outbox-typeahead-container ul.suggestions > li.selected, ssi-outbox-typeahead > .outbox-typeahead-container ul.suggestions > li:hover {
  background-color: #43ade8;
  color: #fff;
}
ssi-outbox-typeahead > .outbox-typeahead-container ul > li {
  align-items: center;
  color: #999;
  cursor: pointer;
  flex-shrink: 0;
  font-weight: 400;
  justify-content: space-between;
  padding: 5px 10px;
}