ssi-confirm-action .tooltip-actions {
  background-color: #fff;
  border-radius: 12px;
  padding: 20px;
  max-width: 280px;
  height: 100%;
  box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.35);
}
ssi-confirm-action .tooltip-actions .buttons-container {
  display: flex;
  justify-content: flex-end;
}
ssi-confirm-action .tooltip-actions .buttons-container button span {
  letter-spacing: 1px;
  font-weight: 900;
  text-transform: uppercase;
}
ssi-confirm-action .tooltip-actions h5 {
  text-align: left;
  color: #43537f;
  white-space: normal;
  text-transform: none;
  margin-bottom: 2rem;
  line-height: 20px;
}