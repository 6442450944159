@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-engagement ssi-sentiment-change {
  font-size: 1rem;

  .section-chart-wrap {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 1.2em 1em 1.2em 2em;
    box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
    border-radius: 1.2em;
    width: 100%;
    margin: 0 0 2em;

    ssi-highchart {
      flex: 1 0 auto;
      padding: 0 0.6em 0 1em;
      margin: 0em 0 0;
    }
  }

  .section-grid {
    margin-bottom: -2em;
  }

  .section-chart-meta {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 0 1 17.2em;
    margin: 0;
    padding: 0;

    &-item {
      display: flex;
      flex: 1 1 auto;
      height: 16.5em;
      width: 17.2em;
      border-radius: 1.2em;
      box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.35);
      margin: 1em 0;
      padding: 1.2em 1.2em 2em;
    }
  }

  .total-tile {
    display: flex;
    flex-direction: column;
    color: $lmode-helper-text;
    letter-spacing: 0.1em;
    justify-content: center;
    align-items: center;
    padding-top: 3em;

    @media print {
      border: 1px solid black;
    }

    &-icon {
      font-size: 3.6em;
      color: $dmode-body-text;

      &.ssi-total-conversations-stat {
        font-size: 4.2em;
      }
    }

    &-meta {
      position: relative;
      font-size: 1em;
      font-weight: 900;
      text-rendering: geometricPrecision;
      margin: 1.6em 0 0.8em;
      padding-bottom: 1.6em;
      text-transform: uppercase;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        margin: auto;
        right: 0;
        bottom: 0;
        display: inline-block;
        width: 4em;
        height: 0.4em;
        background-color: $lblue-background;
        border-radius: 10em;
      }
    }

    &-amount {
      letter-spacing: normal;
      margin: 0;
      color: $lmode-title-text;
      font-weight: 900;
      text-rendering: geometricPrecision;
      font-size: 2em;
    }
  }

  .comparison-tile {
    position: relative;
    background-color: #fff;
    padding: 6em 3em 2.5em;
    box-shadow: 0 0.5em 1em rgba(131, 142, 171, 0.25);
    border-radius: 1.2em;
    margin-bottom: 2em;

    @media print {
      border: 1px solid black;
    }

    &-icons {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: space-between;
      top: 3em;
      right: 3em;
      width: 13em;
    }

    &-icon {
      display: inline-block;
      height: 3.6em;
      width: 3.6em;
      color: #657fc8;
      background-color: rgba(214, 223, 247, 0.7);
      border-radius: 50%;
      text-align: center;
      vertical-align: middle;
      line-height: 3.5em;

      &[class*='negative'] {
        background-color: $primary-red;
        color: $secondary-red;
      }

      &[class*='positive'] {
        background-color: #dff9ff;
        color: $primary-active;
      }

      &:before {
        font-size: 2.4em;
      }
    }

    &-break {
      font-size: 1.4em;
      transform: rotate(270deg);
      color: $lmode-helper-text;
    }
  }

  .comparison-tile-meta {
    color: $lmode-title-text;
    font-weight: 900;
    text-rendering: geometricPrecision;

    p {
      font-weight: inherit;
      margin: 0;

      &:first-child {
        position: relative;
        padding-bottom: 1.3rem;
        margin-bottom: 0.8rem;

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          display: inline-block;
          width: 5rem;
          height: 0.4rem;
          border-radius: 10rem;
          background-color: $lblue-background;
        }

        &[class*='negative']:after {
          background-color: $secondary-red;
        }

        &[class*='positive']:after {
          background-color: #89d6ea;
        }
      }
    }

    &-amount {
      font-size: 2.8em;
    }

    &-copy {
      font-size: 1.2em;
    }
  }
}
