@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';
ssi-widget-theme-cloud {
  display: flex;
  justify-content: center;
  font-size: 1rem;
  overflow: hidden;
  padding: 20px;

  .insights-widget {
    overflow: auto;
    height: 100%;
  }

  .theme {
    display: inline-flex;
    margin-bottom: 7px;
    margin-right: 7px;

    span {
      font-size: 12px;
      font-weight: 900;
      line-height: 14px;
      letter-spacing: 0em;
      text-align: left;
      border: 1px solid #d5d5d5;
      border-radius: 5px;
      padding: 10px;

      &.positive {
        background-color: rgb(0, 198, 115, 0.2);
        color: #008a50;
      }

      &.negative {
        color: #ff4d11;
        background-color: rgb(255, 77, 17, 0.2);
      }
    }

    &.large {
      span {
        font-size: 20px;
        line-height: 20px;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  .no-results-state {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;

    i {
      color: $dmode-body-text;
      font-size: 40px;
    }
    h4 {
      margin-top: 10px;
      color: $lmode-body-text;
      font-weight: 900;
      font-size: 20px;
    }

    p {
      color: $lmode-body-text;
      text-align: center;
      font-size: 14px;
      font-weight: 700;
    }
  }
}
