ssi-advertising-index {
  flex: 1 1 auto;
  padding: 0 15px;
}

ssi-advertising-table {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.advert-graph-wrap {
  padding: 0;
}
.advert-graph-wrap ssi-highchart {
  display: block;
  min-height: 400px;
}
.advert-graph-wrap ssi-highchart .highcharts-background {
  fill: none;
}
.advert-graph-wrap ssi-highchart .highcharts-legend {
  opacity: 0;
}
.advert-graph-wrap ssi-highchart-controls {
  position: relative;
  display: flex;
  background-color: #3a3f49;
  margin: -40px 0 10px;
}
.advert-graph-wrap ssi-highchart-controls .highchart-controls-actions {
  text-align: center;
  font-size: 12px;
}
.advert-graph-wrap ssi-highchart-controls .highchart-controls-date-wrap {
  text-align: center;
}

.advert-table {
  position: relative;
  z-index: 1;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.advert-table-head {
  display: flex;
  padding: 35px 40px 35px 20px;
  margin: 0;
  background-color: #40454f;
  justify-content: space-between;
  color: #707b84;
}

.advert-table-head.active {
  color: inherit;
}
.advert-table-head.active .advert-table-head-icon {
  color: #f2ca27;
}

.advert-table-head-icon {
  font-size: 32px;
  vertical-align: middle;
}

.advert-table-head-label {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  text-align: right;
}
.advert-table-head-label p {
  margin-bottom: 0;
}

.advert-table-col {
  display: inline-flex;
  flex: 1 0 9%;
  max-width: 9%;
  align-items: center;
  justify-content: flex-end;
}
.advert-table-col:first-child {
  flex: 1 0 12%;
  max-width: 12%;
  min-width: 185px;
  justify-content: center;
  z-index: 3;
}

.advert-table-body-wrap {
  display: flex;
  flex-direction: column;
  background-color: #313138;
  flex: 1 1 auto;
  width: auto;
  margin: 0 -15px;
  padding: 0 15px;
  overflow: hidden;
}
.advert-table-body-wrap .advert-table-body > ul:nth-child(2n) {
  background-color: #35353f;
}
.advert-table-body-wrap .advert-table-body > ul:nth-child(2n+1) {
  background-color: #2d2d35;
}
.advert-table-body-wrap.advert-table-body-wrap-paused {
  background-color: #454c56;
}
.advert-table-body-wrap.advert-table-body-wrap-paused .advert-table-body > ul:nth-child(2n) {
  background-color: #4c535d;
}
.advert-table-body-wrap.advert-table-body-wrap-paused .advert-table-body > ul:nth-child(2n+1) {
  background-color: #565d66;
}
.advert-table-body-wrap.advert-table-body-wrap-completed {
  background-color: #e7e8f0;
}
.advert-table-body-wrap.advert-table-body-wrap-completed .advert-table-body > ul:nth-child(2n) {
  background-color: #fff;
}
.advert-table-body-wrap.advert-table-body-wrap-completed .advert-table-body > ul:nth-child(2n+1) {
  background-color: #f0f1f8;
}
.advert-table-body-wrap.advert-table-body-wrap-completed .advert-table-body .ad-stat {
  color: #a6b7c0;
}

.advert-table-body {
  padding: 0 0 50px;
}

.ad {
  display: flex;
  padding: 20px 40px 20px 20px;
  margin: 0 0 10px;
  min-height: 140px;
  justify-content: space-between;
}

.ad-no-media.advert-table-col {
  flex: 1 0 21%;
  max-width: 21%;
  min-width: calc(9% + 185px);
  padding-left: 20px;
}

.ad-stat {
  font-weight: 700;
  color: #fff;
}

.ad-thumbnail {
  width: 145px;
  height: 100px;
  border: 2px solid #fff;
  border-radius: 25px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  background-size: cover;
  background-color: #000;
  cursor: pointer;
}
.ad-thumbnail video {
  height: 100%;
}

.ad-thumbnail-wrap.ad-thumbnail-live .ad-thumbnail {
  border-color: #dbbb75;
}
.ad-thumbnail-wrap.ad-thumbnail-scheduled .ad-thumbnail {
  border-color: #8e52cc;
}
.ad-thumbnail-wrap.ad-thumbnail-paused .ad-thumbnail {
  border-color: #e8eff2;
}
.ad-thumbnail-wrap.ad-thumbnail-completed .ad-thumbnail {
  border-color: #e3eaed;
}

.ad-description {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 12px;
}

.ad-status {
  display: block;
  white-space: nowrap;
  margin: 0 0 10px;
  cursor: pointer;
}
.ad-status .ssi,
.ad-status .fa {
  font-size: 14px;
}
.ad-status p {
  display: inline-block;
  margin: 0 0 0 2px;
  font-weight: 700;
}
.ad-status.ad-status-live {
  color: #e2b928;
}
.ad-status.ad-status-scheduled {
  color: #8e52cc;
}
.ad-status.ad-status-paused {
  color: #14bae3;
}
.ad-status.ad-status-completed {
  color: #14bae3;
}

.ad-description-title {
  font-style: italic;
  font-weight: 500;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.ad-description-body {
  margin: 0;
  max-height: 50px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.ad-description-action {
  font-weight: 700;
  margin: 0;
}

.top.tooltip-ad-table {
  background: #2c2f34;
  max-width: 200px;
  border-radius: 20px;
}
.top.tooltip-ad-table .tooltip-body {
  padding: 25px;
}
.top.tooltip-ad-table .tooltip-body .tooltip-body-contents {
  font-weight: 500;
  line-height: 1.8;
}
.top.tooltip-ad-table .tooltip-arrow {
  border-width: 10px 30px 0 0;
}

.top.tooltip-ad-table[data-popper-placement^=top] {
  margin-bottom: 10px;
}
.top.tooltip-ad-table[data-popper-placement^=top] .tooltip-arrow {
  margin-left: 15px;
  border-color: #2c2f34 transparent transparent transparent;
}

.top.tooltip-ad-table[data-popper-placement^=bottom] {
  margin-top: 10px;
}
.top.tooltip-ad-table[data-popper-placement^=bottom] .tooltip-arrow {
  top: -10px;
  border-color: transparent #2c2f34 transparent transparent;
}

.advert-table-pagination {
  display: block;
  margin: 30px 0 0 0;
  padding: 0 0 50px;
  text-align: center;
}

.pagination-control,
.pagination-item {
  display: inline-block;
  width: 25px;
  height: 38px;
  margin: 0 5px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;
  vertical-align: middle;
  line-height: 35px;
  cursor: pointer;
}
.pagination-control:hover, .pagination-control:focus,
.pagination-item:hover,
.pagination-item:focus {
  opacity: 0.8;
}

.pagination-control {
  color: #14bae3;
}

.pagination-item {
  font-weight: 700;
}

.pagination-item.pagination-item-active {
  color: #14bae3;
  border-bottom-color: #14bae3;
  cursor: auto;
}
.pagination-item.pagination-item-active:hover, .pagination-item.pagination-item-active:focus {
  opacity: 1;
}

.adverts-none-wrap {
  flex: 1 0 auto;
  background-color: #505760;
}

.adverts-none {
  max-width: 280px;
  margin: 0 auto;
  padding: 50px 0 100px;
  color: #b3c5ce;
  text-align: center;
}

.adverts-none-icon {
  font-size: 68px;
  margin: 0 0 30px;
}

.adverts-none-title {
  font-size: 18px;
  margin: 0 0 20px;
  font-weight: 500;
}

.adverts-none-meta {
  margin: 0 0 40px;
  font-weight: 500;
}

.advert-table-load-more {
  padding: 1em 0 2em 0;
  text-align: center;
}
.advert-table-load-more button {
  border: 1px solid #43ade8;
  background-color: #2d2d35;
  color: #a8b9c2;
}

@media (min-width: 1200px) {
  .advert-table,
.advert-table-body,
.advert-graph-wrap,
.adverts-none-wrap {
    margin: 0 50px;
  }
}