.embed-nav {
  margin: 1em 2em 2em 2em;
}
.embed-nav ul {
  width: 100%;
  display: inline-block;
  list-style: none;
  border-bottom: 4px solid #f8f9fd;
}
.embed-nav ul li {
  position: relative;
  float: left;
  padding: 2em 0 2em;
  margin: 0 8em 0 0;
  margin-bottom: -4px;
  border-bottom: 4px solid transparent;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.8em;
  color: #838eab;
  letter-spacing: 1px;
  cursor: pointer;
}
.embed-nav ul li i {
  font-size: 2em;
  position: absolute;
  left: -30px;
  top: 20px;
}
.embed-nav ul li.active {
  border-color: #14bae3;
  color: #14bae3;
}

.embed-view {
  margin: 1em 4em;
  color: #43537f;
}
.embed-view p {
  margin-bottom: 2em;
}
.embed-view textarea {
  width: 90%;
  min-height: 200px;
  padding: 2em 1em;
  border: 3px solid #14bae3;
  border-radius: 10px;
  display: block;
  margin: 0 auto;
  background-color: #f8f9fd;
  color: #000;
  font-weight: 500;
}
.embed-view textarea:focus {
  outline: none;
}

.return-nav {
  position: absolute;
  top: -16px;
  width: 150px;
  left: 35px;
}