@import "~@angular/cdk/overlay-prebuilt.css";
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/* declare a font face for JoyPixels */
@font-face {
  font-family: JoyPixels;
  src: url("https://www.orlo.app/assets/fonts/joypixels.woff") format("woff"), url("https://www.orlo.app/assets/fonts/joypixels.woff2") format("woff2");
}
/* declare a font face for JoyPixels SVG font (Firefox only) */
@font-face {
  font-family: JoyPixelsSVG;
  src: url("https://www.orlo.app/assets/fonts/joypixels-svg.woff") format("woff"), url("https://www.orlo.app/assets/fonts/joypixels-svg.woff2") format("woff2");
}
@font-face {
  font-family: JoyPixels;
  src: url("https://www.orlo.app/assets/fonts/lato.woff2") format("woff2");
  /* using the unicode-range attribute to limit the reach of the JoyPixels web font */
  unicode-range: U+0000-00A8, U+00AF-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: JoyPixelsSVG;
  src: url("https://www.orlo.app/assets/fonts/lato.woff2") format("woff2");
  /* using the unicode-range attribute to limit the reach of the JoyPixels web font */
  unicode-range: U+0000-00A8, U+00AF-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* OR font family applied to class matching *joypixels-font */
[class*=joypixels],
textarea,
input {
  font-family: JoyPixels;
}

/* apply SVG font when browser is Mozilla Firefox */
@-moz-document url-prefix() {
  [class*=joypixels],
textarea,
input {
    font-family: JoyPixelsSVG;
  }
}
h1 {
  margin-top: 0;
  font-size: 1.875em;
  font-weight: bold;
  line-height: 2.25em;
  font-family: "Lato", "Arial", sans-serif;
}
h1.light {
  color: #101525;
}
h1.dark {
  color: #d4d9e6;
}

h2 {
  margin-top: 0;
  font-size: 1.25em;
  font-weight: bold;
  line-height: 1.5em;
  font-family: "Lato", "Arial", sans-serif;
}
h2.light {
  color: #101525;
}
h2.dark {
  color: #d4d9e6;
}

h3 {
  margin-top: 0;
  font-size: 1.2em;
  font-weight: bold;
  line-height: 1.5em;
  font-family: "Lato", "Arial", sans-serif;
}
h3.light {
  color: #101525;
}
h3.dark {
  color: #d4d9e6;
}

h4 {
  margin-top: 0;
  font-size: 1em;
  font-weight: bold;
  line-height: 1.2em;
  font-family: "Lato", "Arial", sans-serif;
}
h4.light {
  color: #101525;
}
h4.dark {
  color: #d4d9e6;
}

p {
  font-family: "Lato", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.25em;
}
p.light {
  color: #43537f;
}
p.dark {
  color: #b4bbce;
}

.orlo-button {
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  color: #fff;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 14px;
  font-weight: bold;
}
.orlo-button:focus {
  outline: 0;
}
.orlo-button.primary {
  background-color: #14bae3;
  margin-right: 2.5%;
}
.orlo-button.secondary {
  background-color: #434552;
}
.orlo-button.text {
  background: transparent;
  text-transform: uppercase;
  color: #43537f;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 0.8em;
}
.orlo-button:disabled {
  background-color: #cfd3de;
}

.orlo-table {
  margin: 0;
  border-radius: 25px;
  background-color: #fff;
  border: none !important;
  color: #43537f;
}
.orlo-table.striped tr:nth-child(even) {
  background-color: #fff;
}
.orlo-table.striped tr:nth-child(odd) {
  background-color: #f0f3f9;
}
.orlo-table tr {
  border-top: 2px solid #f0f3f9;
}
.orlo-table tr:first-child {
  border-top: none;
}
.orlo-table tr.has-delete:hover {
  box-shadow: 0px 0px 10px 5px #f3f3f3;
  border-radius: 10px;
  border-top-color: transparent;
}
.orlo-table tr.has-delete:hover + tr {
  border-top-color: transparent;
}
.orlo-table tr.has-delete a {
  position: relative;
  margin-right: 30px;
  margin-left: 15px;
}
.orlo-table tr.has-delete a i,
.orlo-table tr.has-delete a .icon-wrap {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 14px;
  color: #d4d7e2;
  line-height: 0;
}
.orlo-table tr.has-delete a i:hover, .orlo-table tr.has-delete a i.visible,
.orlo-table tr.has-delete a .icon-wrap:hover,
.orlo-table tr.has-delete a .icon-wrap.visible {
  padding: 19px;
  border-radius: 50px;
  background-color: #faeeeb;
  color: #e29e8c;
  top: -8px;
  left: -8px;
}
.orlo-table th {
  padding: 0 25px 25px 25px !important;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 900;
  line-height: 2.5em;
  color: #838eab;
  letter-spacing: 1px;
  box-sizing: border-box;
  border: none !important;
}
.orlo-table td {
  padding: 15px !important;
  border: none !important;
  word-wrap: break-word;
}
.orlo-table td.small {
  font-size: 0.85em;
}
.orlo-table td.centered {
  text-align: center;
}
.orlo-table tbody td:first-child {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.9em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 300px;
}
.orlo-table thead tr {
  background-color: #fff !important;
  border: none;
}
.orlo-table thead th {
  padding: 1em 15px !important;
  background-color: #f0f3f9;
  color: #43537f;
}
.orlo-table thead th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.orlo-table thead th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.orlo-text-input {
  width: 100%;
  margin: 0 0 0.4em 0;
  padding: 0.6em 1.6em;
  border-radius: 50px;
  background-color: #f0f3f9;
  border: 3px solid #f0f3f9;
  font-size: 16px;
  font-weight: 900;
  box-sizing: border-box;
}
.orlo-text-input:focus {
  border-color: #14bae3;
  outline: 0;
}
.orlo-text-input::placeholder {
  color: #838eab;
  font-style: italic;
  font-weight: 400;
}
.orlo-text-input.invalid {
  border-color: #fff1ed;
  background-color: #fff1ed;
  color: #f88c68;
}
.orlo-text-input.invalid::placeholder {
  color: #f88c68;
}
.orlo-text-input.no-right-radius {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.orlo-text-input.no-left-radius {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.orlo-input-submit {
  text-align: center;
  white-space: nowrap;
  background-color: #caceda;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border: none;
  padding: 1.4rem 1.6rem;
  margin-bottom: 0.5em;
  color: #fff;
  font-weight: bold;
}
.orlo-input-submit:hover {
  cursor: pointer;
}
.orlo-input-submit.active {
  background-color: #14bae3;
}
.orlo-input-submit i {
  margin-right: 0.5rem;
}

.orlo-select {
  margin: 0 0 0.4em 0;
  position: relative;
}
.orlo-select i {
  position: absolute;
  top: 45px;
  right: 20px;
  font-size: 20px;
  color: #14bae3;
}
.orlo-select select {
  width: 100%;
  height: 46px;
  padding: 0.6em 1.6em;
  border-radius: 50px;
  background-color: #f0f3f9;
  border: 3px solid #f0f3f9;
  font-size: 16px;
  font-weight: 700;
  box-sizing: border-box;
  box-shadow: none;
  appearance: none;
  color: #838eab;
  cursor: pointer;
}
.orlo-select select:focus {
  border-color: #14bae3;
  outline: 0;
}
.orlo-select select::placeholder {
  color: #838eab;
  font-style: italic;
  font-weight: 400;
}
.orlo-select select::-ms-expand {
  display: none;
}
.orlo-select select.invalid {
  border-color: #fff1ed;
  background-color: #fff1ed;
  color: #f88c68;
}
.orlo-select select.invalid::placeholder {
  color: #f88c68;
}
.orlo-select select[disabled] + i {
  color: #ced3df;
}

.orlo-modal .modal-content {
  border-radius: 25px;
  background-color: white;
  border: none;
  margin: auto;
}
.orlo-modal-1000 .modal-dialog {
  width: 1000px;
  max-width: 98%;
  margin-top: -5%;
}
.orlo-modal-1200 .modal-dialog {
  width: 1200px;
  max-width: 98%;
  margin-top: -5%;
}
.orlo-modal.position-top-20 {
  top: 20px;
}
.orlo-modal.position-top-20 .modal-dialog {
  margin-top: 0;
}

.orlo-modal-backdrop {
  background-color: rgb(16, 21, 37);
  opacity: 0.7 !important;
}

.orlo-modal-dark .modal-content {
  border-radius: 25px;
  background-color: #35353f;
  border: none;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #eff2f8 #14bae3;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 6px;
}

.custom-scrollbar-2::-webkit-scrollbar {
  width: 4px;
}

.custom-scrollbar-2 {
  scrollbar-width: thin;
  scrollbar-color: #ecf0fb #14bae3;
}

.custom-scrollbar-2::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 1px;
}

.orlo-textarea {
  width: 100%;
}
.orlo-textarea label {
  width: 100%;
  padding: 0 3em;
  display: block;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 900;
  line-height: 2.5em;
  color: #43537f;
  letter-spacing: 1px;
  box-sizing: border-box;
  margin-bottom: 5px;
}
.orlo-textarea textarea {
  width: 100%;
  margin: 0 0 0.4em 0;
  padding: 1.6em;
  border-radius: 10px;
  background-color: #f8f9fd;
  border: 3px solid transparent;
  font-size: 16px;
  font-weight: 700;
  box-sizing: border-box;
  color: #101525;
}
.orlo-textarea textarea:focus {
  border-color: #14bae3;
  outline: 0;
}
.orlo-textarea textarea::placeholder {
  color: #838eab;
  font-style: italic;
  font-weight: 400;
}
.orlo-textarea textarea.invalid {
  border-color: #fff1ed;
  background-color: #fff1ed;
  color: #f88c68;
}
.orlo-textarea textarea.invalid::placeholder {
  color: #f88c68;
}

.orlo-tickbox {
  display: flex;
  align-items: center;
  position: relative;
}
.orlo-tickbox input[type=checkbox] {
  position: absolute;
  opacity: 0;
  margin: 0;
  cursor: pointer;
}
.orlo-tickbox label {
  width: 100%;
  padding: 0 1em;
  display: block;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 900;
  line-height: 2.5em;
  color: #43537f;
  letter-spacing: 1px;
  box-sizing: border-box;
  margin-bottom: 5px;
}
.orlo-tickbox::before {
  display: block;
  margin-top: -6px;
  background-color: #f0f3f9;
  content: "";
  border-radius: 50px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.orlo-tickbox i {
  display: none;
}
.orlo-tickbox.selected label {
  color: #14bae3;
}
.orlo-tickbox.selected::before {
  color: #14bae3;
  background-color: #d0f1f9;
}
.orlo-tickbox.selected i {
  display: block;
  position: absolute;
  left: 2px;
  top: 2px;
  bottom: 0;
  font-size: 18px;
  color: #14bae3;
}
.orlo-tickbox:first-child {
  margin-right: 30px;
}

.cdk-overlay-container {
  z-index: 1060;
}

cdk-overlay-pane {
  width: 100%;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #eff2f8 #14bae3;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #eff2f8;
  border-radius: 6px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #14bae3;
  border-radius: 6px;
  border: 3px solid #14bae3;
}

.a11y-label {
  position: absolute;
  text-indent: -99999px;
  color: #000;
}

@media print {
  body > button {
    display: none !important;
  }
}
.flex-grid {
  display: flex;
  flex-wrap: wrap;
}
.flex-grid .col {
  flex: 1;
}
.flex-grid .col.full {
  flex-basis: 100%;
}
.flex-grid .col.one-third {
  flex-basis: 33.33%;
}
.flex-grid .col.two-thirds {
  flex-basis: 66.66%;
}
.flex-grid .col.one-half {
  flex-basis: 50%;
}
.flex-grid.thirds {
  justify-content: space-between;
}
.flex-grid.thirds .col {
  width: 32%;
}

.z-2000 {
  z-index: 2000 !important;
}

.z-2001 {
  z-index: 2001 !important;
}

ssi-competitor-report ssi-report-sidebar .ssi-line-arrow-small-down {
  transform: rotate(90deg);
}
ssi-competitor-report .report-filter {
  width: 100%;
  margin: 1em 0;
  text-align: right;
}
ssi-competitor-report .report-body {
  width: 100%;
  height: 100%;
  padding: 134px 40px 0 268px;
  background-color: #f4f4fa;
  min-height: calc(100vh - 68px);
}
ssi-competitor-report .report-body .highcharts-credits {
  display: none;
}
ssi-competitor-report .report-body .no-margin {
  margin-top: 0;
}
ssi-competitor-report .report-body hr {
  border-top: 2px solid #fff;
}
ssi-competitor-report .report-body-loading {
  width: 100%;
  height: 100%;
  height: calc(100vh - 68px);
  padding-top: 68px;
  padding-left: 268px;
}
ssi-competitor-report .section-container {
  padding: 2em 0;
}
ssi-competitor-report .section-content {
  padding: 2em 0;
  display: flex;
  flex-direction: column;
}
@media print {
  ssi-competitor-report .section-content {
    page-break-inside: avoid;
    padding: 0;
  }
}
ssi-competitor-report .section-content.section-content-row {
  flex-direction: row;
}
ssi-competitor-report .section-heading {
  padding: 1em 0;
}
ssi-competitor-report .guidance {
  padding: 4em;
  color: white;
  margin: 0 0 1.6em 0;
  border-radius: 10px;
  background: #425dec url("https://website-app.s3.eu-west-1.amazonaws.com/assets/v2/img/teammates-desk.png") 125% 0/50% no-repeat;
}
@media print {
  ssi-competitor-report .guidance {
    padding: 0;
  }
}
ssi-competitor-report .guidance h4 {
  font-size: 1.4em;
  font-weight: 900;
}
ssi-competitor-report .guidance p {
  max-width: 50%;
  line-height: 1.6em;
  font-weight: 700;
}
ssi-competitor-report .profile-comparisons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
ssi-competitor-report .profile-comparisons .profile {
  position: relative;
  display: flex;
  flex: 1;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
  margin: 0 1.6em 0 0;
  padding: 1.6em;
}
ssi-competitor-report .profile-comparisons .profile:last-child {
  margin: 0;
}
ssi-competitor-report .profile-comparisons #rank-0 div {
  background-color: #fbf3e2;
}
ssi-competitor-report .profile-comparisons #rank-0 i {
  color: #f0b427;
}
ssi-competitor-report .profile-comparisons #rank-1 div {
  background-color: #eff2fd;
}
ssi-competitor-report .profile-comparisons #rank-1 i {
  color: #b4bbce;
}
ssi-competitor-report .profile-comparisons #rank-2 div {
  background-color: #fff1ed;
}
ssi-competitor-report .profile-comparisons #rank-2 i {
  color: #f88c68;
}
ssi-competitor-report .profile-comparisons .rank {
  width: 60px;
  text-align: center;
  position: relative;
  margin: 0 0 2em 0;
}
ssi-competitor-report .profile-comparisons .rank div {
  margin: 0 auto;
  width: 46px;
  height: 46px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  font-weight: 900;
  color: #101525;
}
ssi-competitor-report .profile-comparisons .rank i {
  position: absolute;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 4em;
  color: #eaedf4;
}
ssi-competitor-report .profile-comparisons .profile-score {
  padding: 0.4em 1em;
}
ssi-competitor-report .profile-comparisons .profile-score .number {
  font-size: 2em;
  font-weight: 900;
  color: #101525;
}
ssi-competitor-report .profile-comparisons .profile-score .name {
  display: block;
  text-transform: uppercase;
  font-size: 0.7em;
  position: relative;
  color: #838eab;
  letter-spacing: 1px;
  font-weight: 700;
}
ssi-competitor-report .profile-comparisons .profile-color {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  right: 1em;
  top: 1em;
}
ssi-competitor-report .profile-comparisons .focus {
  background-color: #425dec;
}
ssi-competitor-report .profile-comparisons .focus .profile-score .name,
ssi-competitor-report .profile-comparisons .focus .profile-score .number {
  color: white;
}
ssi-competitor-report .profile-comparisons .focus .rank i {
  color: white !important;
}
ssi-competitor-report .profile-comparisons .focus .rank div {
  background-color: #5e75ef !important;
  color: white !important;
}
ssi-competitor-report ssi-stat-graph {
  flex: 1;
}
ssi-competitor-report ssi-stat-graph .stat-graph-container {
  height: 100%;
  width: 100%;
  margin: 0;
}
@media print {
  ssi-competitor-report ssi-stat-graph {
    border: 1px solid black;
    border-radius: 1.2em;
  }
}
ssi-competitor-report .stat-list {
  display: flex;
  flex-direction: column;
  flex: 1;
}
ssi-competitor-report .stat-list.stat-list-row {
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: white;
  padding: 1em 1em 1em 2em;
  border-radius: 10px;
}
ssi-competitor-report .stat-list .stat {
  background-color: #fff;
  border-radius: 10px;
  padding: 2.5em 2em;
  margin: 0 1em 1em 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
}
@media print {
  ssi-competitor-report .stat-list .stat {
    border: 1px solid black;
  }
}
ssi-competitor-report .stat-list .stat h4 {
  text-transform: uppercase;
  font-size: 0.8em;
  text-align: right;
  color: #b4bbce;
}
ssi-competitor-report .stat-list .stat.stat-grey {
  background-color: #f8f9fd;
}
ssi-competitor-report .stat-list .stat.stat-grey .stat-icon {
  margin-bottom: 1em;
}
ssi-competitor-report .stat-list .stat.no-pad-right {
  padding-right: 0;
  cursor: pointer;
}
ssi-competitor-report .stat-list .stat.no-marg-left {
  margin-left: 0;
}
ssi-competitor-report .stat-list .stat .stat-icon {
  font-size: 2.6em;
  padding: 0 0 0 0.6em;
  color: #838eab;
  font-weight: 500;
}
ssi-competitor-report .stat-list .stat .stat-icon.active {
  color: #14bae3;
}
ssi-competitor-report .stat-list .stat span {
  font-size: 2em;
  font-weight: 900;
  color: #101525;
  margin-left: 2em;
}
ssi-competitor-report .stat-list .stat .stat-title {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
  display: inline;
  color: #838eab;
  font-weight: 700;
  letter-spacing: 1px;
}
ssi-competitor-report .stat-list .stat:last-child {
  margin-bottom: 0;
}
ssi-competitor-report .stat-list .stat-group {
  padding: 0 20px 0 0;
  flex: 1;
}
ssi-competitor-report .stat-list .stat-group .stat-group-title {
  margin: 1em 0;
  display: flex;
}
ssi-competitor-report .stat-list .stat-group .stat-group-title p {
  font-size: 1.2em;
  color: #101525;
  font-weight: 700;
  margin-right: 10px;
}
ssi-competitor-report .stat-list .stat-group .stat-group-title i {
  margin-top: 4px;
}
ssi-competitor-report .stat-list .stat-group .stat-group-stats {
  position: relative;
  background-color: #f8f9fd;
  padding: 2em;
  border-radius: 10px;
  cursor: pointer;
}
ssi-competitor-report .stat-list .stat-group .stat-group-stats .stat-group-stats-selected {
  position: absolute;
  right: 1.6em;
  top: 1.6em;
  width: 20px;
  height: 20px;
  background-color: rgba(20, 186, 227, 0.1);
  border-radius: 100%;
  opacity: 0;
}
ssi-competitor-report .stat-list .stat-group .stat-group-stats .stat-group-stats-selected i {
  position: relative;
  left: 5px;
  font-size: 18px;
  color: #14bae3;
}
ssi-competitor-report .stat-list .stat-group .stat-group-stats.active {
  border: 2px solid #14bae3;
  box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
}
ssi-competitor-report .stat-list .stat-group .stat-group-stats.active .stat-group-stats-selected {
  opacity: 1;
}
ssi-competitor-report .stat-list .stat-group .stat-group-stats i {
  font-size: 2.6em;
  color: #838eab;
}
ssi-competitor-report .stat-list .stat-group .stat-competitor {
  margin: 1em 0;
}
ssi-competitor-report .stat-list .stat-group .stat-competitor span {
  letter-spacing: 1px;
  margin: 0 0 0.4em 0;
  display: inline-block;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  color: #838eab;
}
ssi-competitor-report .stat-list .stat-group .stat-competitor .stat-competitor-color {
  width: 50%;
  height: 4px;
  border-radius: 10px;
  margin: 0 0 0.2em 0;
}
ssi-competitor-report .stat-list .stat-group .stat-competitor ssi-big-number {
  font-size: 1.4em;
  color: #101525;
  font-weight: 900;
}
ssi-competitor-report .stat-list .stat-comparisons {
  background-color: white;
  box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
  border-radius: 10px;
  padding: 2em;
  flex-basis: 364px;
}
ssi-competitor-report .stat-list .stat-comparisons .stat-comparisons-title {
  font-size: 1.2em;
  color: #101525;
  font-weight: 700;
  margin: 0 0 1em 10px;
}
ssi-competitor-report .stat-list .stat-comparisons .count-bar {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 0 2em 10px;
}
ssi-competitor-report .stat-list .stat-comparisons .count-bar .progress-bar-wrapper {
  width: 100%;
  margin-right: 15px;
}
ssi-competitor-report .stat-list .stat-comparisons .count-bar .progress-bar-wrapper span {
  color: #838eab;
  font-size: 14px;
  font-weight: 900;
  line-height: 17px;
}
ssi-competitor-report .stat-list .stat-comparisons .count-bar .count {
  color: #101525;
  font-size: 14px;
  font-weight: 900;
  line-height: 22px;
}
ssi-competitor-report .stat-list .stat-comparisons .stat-comparisons-networks {
  flex: 1 0 auto;
  margin: 0.1em -0.8em 0;
  text-align: right;
  margin-bottom: 1em;
}
ssi-competitor-report .stat-list .stat-comparisons .stat-comparisons-networks li {
  display: inline-block;
  margin: 0 0.8em;
}
ssi-competitor-report .stat-list .stat-comparisons .stat-comparisons-networks li .ssi,
ssi-competitor-report .stat-list .stat-comparisons .stat-comparisons-networks li .fa {
  color: #838eab;
  font-size: 1.3em;
}
ssi-competitor-report .stat-list .stat-comparisons .stat-comparisons-average {
  margin-top: 3em;
  background-color: #f8f9fd;
  padding: 1em 2em;
  border-radius: 10px;
}
ssi-competitor-report .stat-list .stat-comparisons .stat-comparisons-average ssi-big-number {
  font-size: 1.6em;
  color: #101525;
  font-weight: 900;
  display: block;
}
ssi-competitor-report .stat-list .stat-comparisons .stat-comparisons-average span {
  display: block;
  letter-spacing: 1px;
  margin: 0 0 0.4em 0;
  display: inline-block;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  color: #838eab;
}
ssi-competitor-report .stat-list .stat-comparisons .stat-comparisons-average i {
  float: right;
  font-size: 2.6em;
  color: #838eab;
}
ssi-competitor-report .media-types {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
ssi-competitor-report .media-types .media-types-box {
  position: relative;
  flex: 1 1 calc(50% - 20px);
  margin-right: 20px;
  margin-bottom: 20px;
}
ssi-competitor-report .media-types .media-types-box:last-child {
  margin-right: 0;
}
ssi-competitor-report .media-types .media-types-box:nth-child(2):not(.competitors-3) {
  margin-right: 0;
}
ssi-competitor-report .media-types .media-types-box ssi-medal-score {
  position: absolute;
  right: 2em;
  top: 1em;
}
ssi-competitor-report .media-types .media-types-box.competitors-3 {
  flex: 1 1 calc(33.33% - 20px);
  max-width: calc(33.33% - 20px);
}
ssi-competitor-report .media-types .media-types-box.competitors-3:last-child {
  margin-right: 0;
}
ssi-competitor-report .competitor-legend {
  padding: 0 0 0 3em;
  position: relative;
  letter-spacing: 1px;
  margin: 0 0 6em 0;
  display: inline-block;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  color: #838eab;
}
ssi-competitor-report .competitor-legend div {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: -4px;
}
ssi-competitor-report .top-posts {
  display: flex;
  flex-direction: row;
  display: block;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
}
ssi-competitor-report .top-posts .top-post {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.6em 0;
  margin: 0 1.6em;
  border-bottom: 2px solid #ecf0fb;
}
@media print {
  ssi-competitor-report .top-posts .top-post {
    display: block;
    width: 100%;
    page-break-inside: avoid;
  }
}
ssi-competitor-report .top-posts .top-post ssi-top-post-stats {
  flex: 0 0 30%;
}
@media print {
  ssi-competitor-report .top-posts .top-post ssi-top-post-stats {
    display: inline-block;
    width: 30%;
  }
}
ssi-competitor-report .top-posts .top-post ssi-top-post-view {
  flex: 0 0 70%;
}
@media print {
  ssi-competitor-report .top-posts .top-post ssi-top-post-view {
    display: inline-block;
    width: 69%;
  }
}
ssi-competitor-report .top-posts :last-child {
  border: none;
}
ssi-competitor-report .post-breakdown {
  display: flex;
}
ssi-competitor-report .post-breakdown .post-breakdown-box {
  flex: 1;
  flex-basis: 50%;
  min-width: 50%;
  min-height: 400px;
}
ssi-competitor-report .post-breakdown .top-tags-box {
  margin: 0 1.6em 0 0 !important;
}
ssi-competitor-report .top-words,
ssi-competitor-report .top-tags {
  display: flex;
}
ssi-competitor-report .top-words .top-tags-box,
ssi-competitor-report .top-words .top-words-box,
ssi-competitor-report .top-tags .top-tags-box,
ssi-competitor-report .top-tags .top-words-box {
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
  padding: 1.6em;
  margin: 0 1.6em 0 0;
}
ssi-competitor-report .top-words .top-tags-box:last-child,
ssi-competitor-report .top-words .top-words-box:last-child,
ssi-competitor-report .top-tags .top-tags-box:last-child,
ssi-competitor-report .top-tags .top-words-box:last-child {
  margin: 0;
}
ssi-competitor-report .top-words .top-words-box-header,
ssi-competitor-report .top-words .top-tags-box-header,
ssi-competitor-report .top-tags .top-words-box-header,
ssi-competitor-report .top-tags .top-tags-box-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
}
ssi-competitor-report .top-words .top-words-box-header .stat-comparisons-networks,
ssi-competitor-report .top-words .top-tags-box-header .stat-comparisons-networks,
ssi-competitor-report .top-tags .top-words-box-header .stat-comparisons-networks,
ssi-competitor-report .top-tags .top-tags-box-header .stat-comparisons-networks {
  padding: 0 0.6em;
  display: inline-block;
}
ssi-competitor-report .top-words .top-words-box-header .stat-comparisons-networks i,
ssi-competitor-report .top-words .top-tags-box-header .stat-comparisons-networks i,
ssi-competitor-report .top-tags .top-words-box-header .stat-comparisons-networks i,
ssi-competitor-report .top-tags .top-tags-box-header .stat-comparisons-networks i {
  color: #838eab;
  font-size: 16px;
  margin-left: 10px;
}
ssi-competitor-report .top-words .top-words-box-header .competitor-legend,
ssi-competitor-report .top-words .top-tags-box-header .competitor-legend,
ssi-competitor-report .top-tags .top-words-box-header .competitor-legend,
ssi-competitor-report .top-tags .top-tags-box-header .competitor-legend {
  margin: 0;
}
ssi-competitor-report .top-words .top-words-box-header span,
ssi-competitor-report .top-words .top-tags-box-header span,
ssi-competitor-report .top-tags .top-words-box-header span,
ssi-competitor-report .top-tags .top-tags-box-header span {
  font-weight: 700;
  font-size: 1.2em;
  color: #101525;
}
ssi-competitor-report .top-words .tag-bar,
ssi-competitor-report .top-tags .tag-bar {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 20px;
}
ssi-competitor-report .top-words .tag-bar.top,
ssi-competitor-report .top-tags .tag-bar.top {
  padding: 5px 20px 5px 5px;
  margin-bottom: 10px;
}
ssi-competitor-report .top-words .tag-bar.top .tag-count,
ssi-competitor-report .top-tags .tag-bar.top .tag-count {
  font-size: 18px;
}
ssi-competitor-report .top-words .tag-bar ssi-medal-score,
ssi-competitor-report .top-tags .tag-bar ssi-medal-score {
  margin-right: 15px;
}
ssi-competitor-report .top-words .tag-bar .progress-bar-wrapper,
ssi-competitor-report .top-tags .tag-bar .progress-bar-wrapper {
  width: 100%;
  margin-right: 15px;
}
ssi-competitor-report .top-words .tag-bar .progress-bar-wrapper span,
ssi-competitor-report .top-tags .tag-bar .progress-bar-wrapper span {
  color: #101525;
  font-size: 14px;
  font-weight: 900;
  line-height: 17px;
}
ssi-competitor-report .top-words .tag-bar .tag-count,
ssi-competitor-report .top-tags .tag-bar .tag-count {
  color: #101525;
  font-size: 14px;
  font-weight: 900;
  line-height: 22px;
}
ssi-competitor-report .top-words {
  display: flex;
}
ssi-competitor-report .top-words highcharts-chart {
  height: inherit !important;
  margin: 0 0 2em 0;
}
ssi-competitor-report .top-words .top-words-box {
  flex: 1 1 auto;
}
ssi-competitor-report .follower-counts {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
ssi-competitor-report .follower-counts highcharts-chart {
  height: inherit !important;
  margin: 0 0 2em 0;
}
ssi-competitor-report .follower-counts .count-chart {
  position: relative;
  flex: 1;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
  padding: 1.6em;
  margin: 0 1.6em 0 0;
}
ssi-competitor-report .follower-counts .count-chart:last-child {
  margin: 0;
}
ssi-competitor-report .follower-counts .count-chart .current-count {
  box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
  padding: 0.6em;
  border-radius: 10px;
  border: 1px solid;
}
ssi-competitor-report .follower-counts .count-chart .current-count .stat-comparisons-networks {
  padding: 0 0.6em;
  display: inline-block;
}
ssi-competitor-report .follower-counts .count-chart .current-count .number {
  padding: 7px 0 7px 0;
  font-size: 1.4em;
  color: #101525;
  font-weight: 900;
  display: inline-block;
}
ssi-competitor-report .follower-counts .count-chart .current-count ssi-stat-change {
  float: right;
}
ssi-competitor-report .follower-counts .count-chart .current-count-single {
  padding: 1em 0 2em 0;
}
ssi-competitor-report .follower-counts .count-chart .current-count-single span {
  color: #101525;
  font-weight: 700;
  font-size: 1.2em;
}
ssi-competitor-report .follower-counts .count-chart .current-count-single .stat-comparisons-networks {
  padding: 0 0.6em;
  display: inline-block;
}
ssi-competitor-report .follower-counts .count-chart .current-count-single .stat-comparisons-networks i {
  color: #838eab;
  font-size: 1em;
}
ssi-competitor-report .follower-counts .count-chart .current-count-single ssi-stat-change {
  float: right;
  font-size: 1em;
}
ssi-competitor-report .follower-counts .count-chart ssi-medal-score {
  position: absolute;
  right: 2em;
  top: 1em;
}
ssi-competitor-report .date-selector {
  width: 80%;
  float: right;
  margin-top: 90px;
}
ssi-competitor-report .date-selector ssi-highchart-controls {
  margin: 0;
  transform: translateX(-12px);
}
ssi-competitor-report .date-selector .highchart-controls-actions,
ssi-competitor-report .date-selector .highchart-controls-date-wrap {
  margin-bottom: 0;
}
ssi-competitor-report .no-results {
  margin: 4em 0;
  padding: 0 10%;
  color: #43537f;
  text-align: center;
}
ssi-competitor-report .no-results h4 {
  font-size: 16px;
  color: #838eab;
}
ssi-competitor-report .no-results p {
  color: #838eab;
}
ssi-competitor-report .no-results i {
  font-size: 3em;
  margin: 0 0 0.4em 0;
  color: #b4bbce;
}
ssi-competitor-report .growth-section .section-heading {
  display: flex;
  justify-content: space-between;
}
ssi-competitor-report .growth-section .audience-stats {
  display: flex;
}
ssi-competitor-report .growth-section .audience-stats .stat-box {
  padding: 10px;
  flex: 1 1 auto;
  margin-right: 20px;
}
ssi-competitor-report .growth-section .audience-stats .stat-box:last-child {
  margin-right: 0;
}
ssi-competitor-report .growth-section .follower-growth {
  padding: 1.6em;
  background-color: white;
  border-radius: 10px;
}
ssi-competitor-report .growth-section .follower-growth.single {
  display: flex;
}
ssi-competitor-report .growth-section .growth-per-profile {
  padding: 2em 0;
}
ssi-competitor-report .growth-section .title-container {
  display: flex;
  align-items: center;
}
ssi-competitor-report .growth-section .title-container h4 {
  font-size: 16px;
  color: #101525;
}
ssi-competitor-report .growth-section .title-container i {
  color: #838eab;
  margin-left: 5px;
  font-size: 16px;
  padding-bottom: 7px;
}
ssi-competitor-report .growth-section .single-profile-growth {
  background-color: #fff;
  width: 31%;
  display: flex;
  flex-direction: column;
  padding: 70px 1.6em;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}
ssi-competitor-report .growth-section .single-profile-growth .box-stat {
  padding: 15px 20px 20px 20px;
  background-color: #fff;
  box-shadow: 0 5px 10px 0 rgba(131, 142, 171, 0.25);
  border-radius: 5px;
  border: 1px solid #d6dff7;
}
ssi-competitor-report .growth-section .single-profile-growth span {
  display: block;
  font-weight: 900;
  font-size: 28px;
  color: #101525;
  text-align: right;
  margin-bottom: 10px;
}
ssi-competitor-report .growth-section .single-profile-growth .background-graphic {
  color: #eaf9f8;
  font-size: 120px;
  margin-top: 5px;
}
ssi-competitor-report .growth-section .single-profile-growth h4 {
  color: #838eab;
  letter-spacing: 1px;
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 0;
}
ssi-competitor-report .growth-section .single-profile-growth ssi-stat-change .stat-change {
  margin-left: auto;
  max-width: fit-content;
}
ssi-competitor-report .growth-section.single-brand ssi-highchart {
  width: 70% !important;
}
ssi-competitor-report .growth-section.single-brand highcharts-chart {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 12px;
}
ssi-competitor-report .growth-section.single-brand .section-content {
  display: flex;
}
ssi-competitor-report .growth-section .growth-stat-box {
  display: flex;
  justify-content: space-between;
}
ssi-competitor-report .growth-section .growth-stat-box .growth-stats {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex: 0;
}
ssi-competitor-report .growth-section .growth-stat-box .growth-stats span {
  color: #101525;
  font-size: 28px;
  font-weight: 900;
  margin-bottom: 10px;
}
ssi-competitor-report .growth-section .growth-stat-box .legend-dot {
  display: inline-flex;
  background-color: gray;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 10px;
}
ssi-competitor-report .growth-section .growth-stat-box .growth-label-container i {
  font-size: 50px;
}
ssi-competitor-report .growth-section .growth-stat-box .growth-label-container .stat-label {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
ssi-competitor-report .growth-section .growth-stat-box .growth-label-container .stat-label span {
  font-size: 10px;
  color: #838eab;
  text-transform: uppercase;
  letter-spacing: 1px;
}
ssi-competitor-report .growth-section .growth-stat-box .growth-label-container ssi-medal-score {
  margin-bottom: 60px;
  display: block;
}
ssi-competitor-report .growth-section .highcharts-plot-band {
  color: #f4f4f4;
}