.btn-style-1 {
  font-size: 12px;
  font-weight: 900;
  border-radius: 20px;
  padding: 10px 17px;
  border-width: 1px;
  border-style: solid;
}

.btn-style-1 + .btn-style-1 {
  margin-left: 5px;
}

.btn-style-1.btn-default {
  color: #14bae3;
  padding: 12px 17px;
  border-width: 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.btn-style-1.btn-primary {
  color: #5096aa;
  background-color: #eef5f7;
  border-color: #fff;
}
.btn-style-1.btn-primary:hover {
  background-color: #cbe1e7;
}
.btn-style-1.btn-primary:active {
  background-color: #a9ccd6;
}

.btn-style-1.btn-secondary {
  color: #fff;
  background-color: #565d62;
  border-width: 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}
.btn-style-1.btn-secondary:hover {
  color: #fff;
  background-color: #3e4347;
}
.btn-style-1.btn-secondary:active {
  background-color: #26292c;
}

.btn-style-1.btn-warning {
  color: #fff;
  background-color: #debf50;
  border-color: #ecd273;
}
.btn-style-1.btn-warning:hover {
  color: #fff;
  background-color: #d3ae28;
}
.btn-style-1.btn-warning:active {
  background-color: #a88a20;
}

.btn-style-1.btn-danger {
  color: #ff4d11;
  background-color: #fff6f3;
  border-color: #fff;
}
.btn-style-1.btn-danger:hover {
  color: #fff;
  background-color: #ffd0c0;
}
.btn-style-1.btn-danger:active {
  background-color: #dea79b;
}

.btn-style-1 > .btn-style-1-icon:first-child {
  margin-right: 3px;
}

.btn-style-1 > .btn-style-1-icon:last-child {
  margin-left: 3px;
}

.label-style-1 {
  display: inline-block;
  margin: 5px;
  background-color: #454c56;
  border-width: 2px;
  border-style: solid;
  border-radius: 20px;
  padding: 8px 18px;
  font-size: 12px;
  vertical-align: middle;
}
.label-style-1 p {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
}

.label-style-1-close {
  color: #727c82;
  font-size: 20px;
  margin-left: 10px;
  vertical-align: middle;
  cursor: pointer;
}
.label-style-1-close:hover, .label-style-1-close:focus {
  opacity: 0.6;
}

.label-style-1-yellow {
  border-color: #debf50;
}

.label-style-1-pink {
  border-color: #e9a898;
}

.btn-style-1-icon {
  font-size: 14px;
}

.btn-style-1-badge {
  display: inline-block;
  margin: 0 4px 0 0;
  background-color: #14bae3;
  height: 11px;
  width: 11px;
  border-radius: 50%;
  line-height: 11px;
}
.btn-style-1-badge i {
  color: #fff;
  font-size: 5px;
  line-height: 11px;
  vertical-align: middle;
}