@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-quote-retweet-modal {
  $form-control-border-radius: 5px;
  $form-control-icon-color: #d2d8df;

  display: block;
  width: 550px;
  max-width: 100%;

  .quote-retweet {
    position: relative;
  }

  .header {
    width: 100%;
    padding: 1.4em 4em;
    position: relative;
    color: white;
    border-bottom: 2px solid $dmode-disabled;

    h2 {
      font-size: 1.4em;
      font-weight: 700;
      margin: 0;
    }

    i {
      position: absolute;
      font-size: 2em;
      left: 24px;
      top: 22px;
      padding-right: 1em;
    }
  }

  .exit {
    position: absolute;
    right: 16px;
    top: 16px;
    background-color: $dmode-disabled;
    border-radius: 100%;
    z-index: 999;
    cursor: pointer;

    i {
      display: inline-block;
      padding: 10px;
      color: $dmode-body-text;
    }
  }

  .content {
    padding: 1em;
    border-bottom: 2px solid $dmode-disabled;

    .orlo-button {
      padding: 0;
      color: $primary-active;
      font-size: 0.75em;
      line-height: 2.1em;

      i {
        color: $primary-active;
        margin-top: -10px;
        font-size: 0.8em;
        top: 0px;
      }
    }

    a {
      &.text {
        margin-left: 1em;
      }
    }

    .character-count {
      margin-right: 1em;
      font-weight: 700;
      font-size: 1em;
    }

    .ssi-emoji {
      margin-right: 1em;
      color: white;
      font-size: 1.3em;
    }

    .form-control {
      font-size: 1em;
    }
  }

  .footer {
    padding: 1em;

    .text {
      font-size: 0.75em;
      padding-right: 0;
      letter-spacing: 1px;
      margin-top: 0.2em;
    }
  }

  .scheduler {
    position: relative;

    &-empty:after {
      content: 'Schedule repost';
      color: white;
      font-size: 0.87em;
      font-weight: 700;
      position: absolute;
      top: 12px;
      left: 32px;
      pointer-events: none;
    }

    i {
      position: absolute;
      color: $dmode-body-text;
      top: 15px;
      font-size: 0.75em;
      left: 10px;
    }
  }

  .schedule-button {
    width: 180px;
    margin: 0.6em 0;
    display: inline-block;
    border-radius: 25px !important;
    background-color: $dmode-disabled !important;
    border: none !important;
    color: white !important;
    padding: 0.4em 1.2em 0.4em 2.6em;
    height: initial !important;
    font-size: 0.87em;

    &::placeholder {
      color: transparent !important;
    }
  }

  .cancel-schedule {
    position: absolute;
    background: none;
    color: #fff;
    appearance: none;
    box-shadow: none;
    border: none;
    transform: translateX(-250%);
  }

  .form-control {
    background-color: transparent;
    font-weight: 700;
    color: white;
    &::placeholder {
      color: $lmode-helper-text;
    }
  }

  textarea.form-control.form-control-minimal {
    resize: none;
    border-top-left-radius: $form-control-border-radius;
    border-top-right-radius: $form-control-border-radius;
    border-bottom: 0;
  }

  .quote-retweet-modal-form-control-footer {
    text-align: right;
  }
}
