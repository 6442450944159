@import '~/styles/colors';

$publisher-bg: $lmgrey-background;
$publisher-height: 435px;
$publisher-sides-margin: 30px;
$publisher-padding: 10px;
$publisher-header-height: 110px;
$publisher-primary-bg: $primary-active;
$publisher-secondary-bg: $brand-inverse;
$publisher-primary-text: #fff;
$publisher-secondary-text: $publisher-primary-text;
$publisher-box-shadow: 0 0 18px rgba(131, 142, 171, 0.35);
$publisher-font-color: #393c3f;
$publisher-input-color: $lmode-title-text;
$publisher-input-placeholder-color: $lmode-helper-text;

$publisher-secondary-view-left-width: 350px;
$publisher-secondary-view-margin-top: 50px;

$publisher-schedule-day-scheduled-color: $brand-success;
$publisher-schedule-day-disabled-color: #dadde3;

$publisher-action-bg: #fff;
$publisher-action-color: $lmode-helper-text;
$publisher-action-disabled-bg: #f7f8fc;
$publisher-action-disabled-color: $publisher-action-color;
$publisher-action-sm-height: 60px;
$publisher-action-schedule-active-bg: #5e7c8c;
$publisher-action-content-approval-active-bg: #407fb0;
