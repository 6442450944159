ssi-clock .clock {
  margin-bottom: 60px;
}
ssi-clock .clock .clock-bg {
  margin: auto;
  width: 120px;
  height: 120px;
}
ssi-clock .clock .clock-hour-hand,
ssi-clock .clock .clock-minute-hand {
  margin: auto;
  transform-origin: center bottom;
}
ssi-clock .clock .clock-hour-hand {
  width: 5px;
  height: 25px;
  background-color: #101525;
  margin-top: -85px;
  border-radius: 2.5px;
}
ssi-clock .clock .clock-minute-hand {
  width: 1px;
  height: 35px;
  background-color: #101525;
  margin-top: -35px;
  border-radius: 0.5px;
}