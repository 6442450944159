ssi-advertising-nav {
  width: 100%;
  margin: 30px auto 70px;
  max-width: 1290px;
}

.advertising-nav {
  position: relative;
  display: flex;
  flex: 0 1 auto;
  justify-content: space-between;
  align-items: flex-start;
  height: 75px;
  margin: 0;
}
.advertising-nav:before {
  content: "";
  position: absolute;
  top: 37px;
  width: 100%;
  border-bottom: 2px solid #4a525b;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.advertising-nav-link {
  position: relative;
  display: inline-block;
  flex: 0 1 330px;
  width: 330px;
  background-color: #2d2d35;
  border: 2px solid #515962;
  border-radius: 40px;
  z-index: 3;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  font-weight: 500;
  cursor: pointer;
}
.advertising-nav-link:hover .advertising-nav-meta, .advertising-nav-link:focus .advertising-nav-meta {
  display: block;
}
.advertising-nav-link > a,
.advertising-nav-link > span {
  display: block;
  color: inherit;
  padding: 13px 30px;
}
.advertising-nav-link > a:hover, .advertising-nav-link > a:focus,
.advertising-nav-link > span:hover,
.advertising-nav-link > span:focus {
  text-decoration: none;
}
.advertising-nav-link .ssi {
  display: block;
  font-size: 20px;
  color: #f2ca27;
  width: 100%;
  margin-bottom: 5px;
  font-weight: 400;
}

.advertising-nav-meta {
  display: none;
  margin: 25px 0;
  line-height: 1.8;
}

.advertising-nav-link.advertising-nav-link-highlight {
  border-color: #f2ca27;
}

.advertising-nav-link.active {
  background-color: #40454f;
}