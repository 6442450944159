ssi-dropdown-select,
ssi-dropdown-select-2 {
  position: relative;
  display: block;
}
ssi-dropdown-select .btn:focus,
ssi-dropdown-select-2 .btn:focus {
  outline: 0;
}
ssi-dropdown-select .btn,
ssi-dropdown-select-2 .btn {
  border-radius: 10px !important;
}
ssi-dropdown-select .dropdown-select-menu,
ssi-dropdown-select-2 .dropdown-select-menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 375px;
  padding-top: 10px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
@media (max-width: 885px) {
  ssi-dropdown-select .dropdown-select-menu.width-extended,
ssi-dropdown-select-2 .dropdown-select-menu.width-extended {
    width: 95vw;
  }
}
@media (min-width: 885px) {
  ssi-dropdown-select .dropdown-select-menu.width-extended,
ssi-dropdown-select-2 .dropdown-select-menu.width-extended {
    width: 50vw;
  }
}
ssi-dropdown-select .dropdown-select-menu-items,
ssi-dropdown-select-2 .dropdown-select-menu-items {
  flex: 1 1 auto;
  padding: 16px 36px;
}
ssi-dropdown-select .dropdown-select-menu-item,
ssi-dropdown-select-2 .dropdown-select-menu-item {
  cursor: pointer;
  display: block;
  width: 100%;
  border-radius: 20px;
  padding: 8px 5px 8px 20px;
  margin: 3px 0;
  font-weight: normal;
}
ssi-dropdown-select .dropdown-select-menu-item:focus,
ssi-dropdown-select-2 .dropdown-select-menu-item:focus {
  outline: revert;
}
ssi-dropdown-select .dropdown-select-menu-item i,
ssi-dropdown-select-2 .dropdown-select-menu-item i {
  width: 20px;
  font-size: 14px;
}
ssi-dropdown-select .dropdown-search-container,
ssi-dropdown-select-2 .dropdown-search-container {
  padding: 10px 60px 5px 60px;
  position: relative;
}
ssi-dropdown-select .dropdown-search-container .form-control,
ssi-dropdown-select-2 .dropdown-search-container .form-control {
  background-color: transparent;
  padding-right: 30px;
  box-shadow: none;
  height: 40px;
  font-weight: bold;
  font-size: 14px;
  border: 0;
  border-radius: 0;
  border-bottom: 2px solid #fff;
}
ssi-dropdown-select .dropdown-search-container .form-control:focus,
ssi-dropdown-select-2 .dropdown-search-container .form-control:focus {
  outline: none;
}
ssi-dropdown-select .dropdown-search-container .form-control::placeholder,
ssi-dropdown-select-2 .dropdown-search-container .form-control::placeholder {
  opacity: 0.7;
  font-weight: 400;
}
ssi-dropdown-select .dropdown-search-container i,
ssi-dropdown-select-2 .dropdown-search-container i {
  position: absolute;
  top: 20px;
  right: 70px;
  font-size: 18px;
}
ssi-dropdown-select .dropdown-toggle-all,
ssi-dropdown-select-2 .dropdown-toggle-all {
  text-align: right;
  padding: 0 60px;
  font-weight: 600;
}
ssi-dropdown-select .dropdown-toggle-all a,
ssi-dropdown-select-2 .dropdown-toggle-all a {
  margin-left: 10px;
}
ssi-dropdown-select .dropdown-toggle-all a:focus,
ssi-dropdown-select-2 .dropdown-toggle-all a:focus {
  outline: revert;
}
ssi-dropdown-select .dropdown-select-container.primary .dropdown-select-menu,
ssi-dropdown-select-2 .dropdown-select-container.primary .dropdown-select-menu {
  background-color: #14bae3;
  color: #fff;
}
ssi-dropdown-select .dropdown-select-container.primary .dropdown-select-menu-item.active,
ssi-dropdown-select .dropdown-select-container.primary .dropdown-select-menu-item:hover,
ssi-dropdown-select-2 .dropdown-select-container.primary .dropdown-select-menu-item.active,
ssi-dropdown-select-2 .dropdown-select-container.primary .dropdown-select-menu-item:hover {
  background-color: #11acd3;
}
ssi-dropdown-select .dropdown-select-container.primary .dropdown-search-container .form-control,
ssi-dropdown-select .dropdown-select-container.primary .dropdown-search-container .form-control:focus,
ssi-dropdown-select-2 .dropdown-select-container.primary .dropdown-search-container .form-control,
ssi-dropdown-select-2 .dropdown-select-container.primary .dropdown-search-container .form-control:focus {
  color: #fff;
}
ssi-dropdown-select .dropdown-select-container.primary .dropdown-search-container .form-control::placeholder,
ssi-dropdown-select-2 .dropdown-select-container.primary .dropdown-search-container .form-control::placeholder {
  color: #fff;
}
ssi-dropdown-select .dropdown-select-container.primary .dropdown-toggle-all a,
ssi-dropdown-select-2 .dropdown-select-container.primary .dropdown-toggle-all a {
  color: #fff;
}
ssi-dropdown-select .dropdown-select-container.inverse .dropdown-select-menu,
ssi-dropdown-select-2 .dropdown-select-container.inverse .dropdown-select-menu {
  background-color: #707f8b;
  color: #fff;
}
ssi-dropdown-select .dropdown-select-container.inverse .dropdown-select-menu-item.active,
ssi-dropdown-select .dropdown-select-container.inverse .dropdown-select-menu-item:hover,
ssi-dropdown-select-2 .dropdown-select-container.inverse .dropdown-select-menu-item.active,
ssi-dropdown-select-2 .dropdown-select-container.inverse .dropdown-select-menu-item:hover {
  background-color: #56606b;
}
ssi-dropdown-select .dropdown-select-container.inverse .dropdown-search-container .form-control,
ssi-dropdown-select .dropdown-select-container.inverse .dropdown-search-container .form-control:focus,
ssi-dropdown-select-2 .dropdown-select-container.inverse .dropdown-search-container .form-control,
ssi-dropdown-select-2 .dropdown-select-container.inverse .dropdown-search-container .form-control:focus {
  color: #fff;
}
ssi-dropdown-select .dropdown-select-container.inverse .dropdown-search-container .form-control::placeholder,
ssi-dropdown-select-2 .dropdown-select-container.inverse .dropdown-search-container .form-control::placeholder {
  color: #fff;
}
ssi-dropdown-select .dropdown-select-container.inverse .dropdown-toggle-all a,
ssi-dropdown-select-2 .dropdown-select-container.inverse .dropdown-toggle-all a {
  color: #fff;
}