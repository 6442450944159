@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '~/styles/colors';

ssi-ad-stats-report {
  .advertising-analytics-report-container {
    width: 100%;
    height: 100%;

    $headerHeight: 94px;
    $sidebarWidth: 228px;

    .report-body {
      width: 100%;
      height: 100%;
      padding-top: $headerHeight;
      padding-left: $sidebarWidth + 40px;
      padding-right: 40px;
      min-height: calc(100vh - 68px);

      .section-delimiter {
        width: 100%;
        height: 2px;
        background-color: #fff;
        margin: 30px 0;
      }

      .section-title {
        color: #101525;
        font-weight: 900;
        text-rendering: geometricPrecision;
        font-size: 20px;
        margin: 0 0 40px;
      }
    }

    .report-body-loading {
      width: 100%;
      height: 100%;
      height: calc(100vh - 68px);
      padding-top: $headerHeight;
      padding-left: $sidebarWidth;
    }

    .selected-date-ranges {
      border-radius: 10px;
      background-color: #ebebf4;
      padding: 10px;

      span {
        margin-right: 7px;
      }

      .current {
        color: $lmode-title-text;
        font-size: 14px;
        font-weight: 900;
      }
      .previous {
        color: $lmode-title-text;
        font-size: 12px;
        font-style: italic;
        font-weight: 700;
      }
    }

    .facts-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      grid-gap: 0 20px;
      margin: 0;

      @media print {
        display: block;

        & > * {
          display: inline-block;
          width: auto !important;
        }
      }

      & > * {
        width: 100%;

        .fact-overview {
          box-shadow: 0 0.5em 1em rgba(131, 142, 171, 0.35);
          border-radius: 12px;
          width: 100%;
          min-width: 170px;
          margin: 0 0 20px;
        }
      }
    }
  }
}
