@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../angularjs/common/sass/variables';

ssi-policy {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  height: 100vh;
  overflow: scroll;

  .container {
    margin-top: -$navbar-height;
  }

  .policy-title {
    color: #fff;
  }

  .well {
    max-height: 450px;
  }
}
