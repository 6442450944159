@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-inbox-priority-indicator {
  .inbox-priority-container {
    .inbox-priority-dot {
      height: 10px;
      width: 10px;
      border-radius: 50%;
    }

    .inbox-priority-badge {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      &.S {
        height: 20px;
        width: 20px;
      }

      &.M {
        height: 26px;
        width: 26px;
      }

      span {
        color: #fff;
        font-size: 10px;
        font-weight: 900;
      }
    }

    .inbox-priority-text {
      height: 36px;
      border-radius: 10px;
      background-color: rgba(137, 164, 234, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 15px;

      span {
        color: #43537f;
        font-size: 10px;
        font-weight: 900;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-left: 5px;
      }
    }

    .P1 {
      background-color: #f4490f;
    }
    .P2 {
      background-color: #f0b427;
    }
    .P3 {
      background-color: #425dec;
    }
    .P4 {
      background-color: #89a4ea;
    }
    .P5 {
      background-color: #12aca4;
    }

    &.single-value {
      background: #fff;
      border-radius: 10px;
      padding: 8px;
    }
  }
}
