@import "./src/apps/angularjs/common/sass/global-variables.scss";
ssi-inbox-activity-modal {
  .inbox-activity-modal {
    position: relative;
    padding: 10px;
    height: 100%;

    .close-icon {
      position: fixed;
      z-index: 999999999;
      top: -8px;
      right: -8px;

      @media (min-width: 768px) {
        top: -18px;
        right: -18px;
      }

      color: #838eab;
      font-size: 10px;
      background-color: #fff;
      &:hover {
        opacity: 1;
        color: #14bae3;
      }
    }

    ssi-inbox-activity {
      height: 100%;
    }
  }
}
