ssi-analytics ssi-disapprovals ssi-report .disapprovals-analytics-report-container {
  width: 100%;
  height: 100%;
}
ssi-analytics ssi-disapprovals ssi-report .disapprovals-analytics-report-container .report-body {
  width: 100%;
  height: 100%;
  padding-top: 134px;
  padding-left: 268px;
  padding-right: 40px;
  min-height: calc(100vh - 68px);
}
ssi-analytics ssi-disapprovals ssi-report .disapprovals-analytics-report-container .report-body .account-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
ssi-analytics ssi-disapprovals ssi-report .disapprovals-analytics-report-container .report-body .account-list .account-list-item {
  display: flex;
  align-items: center;
  background-color: #ebebf4;
  border-radius: 10px;
  padding: 10px;
  margin: 5px;
}
ssi-analytics ssi-disapprovals ssi-report .disapprovals-analytics-report-container .report-body .account-list .account-list-item i {
  color: #838eab;
}
ssi-analytics ssi-disapprovals ssi-report .disapprovals-analytics-report-container .report-body .account-list .account-list-item span {
  color: #43537f;
  font-size: 12px;
  font-weight: 900;
  line-height: 14px;
  margin-left: 10px;
}
ssi-analytics ssi-disapprovals ssi-report .disapprovals-analytics-report-container .report-body .section-delimiter {
  width: 100%;
  height: 2px;
  background-color: #fff;
  margin-top: 40px;
  margin-bottom: 40px;
}
ssi-analytics ssi-disapprovals ssi-report .disapprovals-analytics-report-container .report-body .section-head,
ssi-analytics ssi-disapprovals ssi-report .disapprovals-analytics-report-container .report-body .section-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
ssi-analytics ssi-disapprovals ssi-report .disapprovals-analytics-report-container .report-body .section-head h2,
ssi-analytics ssi-disapprovals ssi-report .disapprovals-analytics-report-container .report-body .section-head h3,
ssi-analytics ssi-disapprovals ssi-report .disapprovals-analytics-report-container .report-body .section-heading h2,
ssi-analytics ssi-disapprovals ssi-report .disapprovals-analytics-report-container .report-body .section-heading h3 {
  color: #101525;
  font-size: 20px;
  font-weight: 900;
}
ssi-analytics ssi-disapprovals ssi-report .disapprovals-analytics-report-container .report-body-loading {
  width: 100%;
  height: 100%;
  height: calc(100vh - 68px);
  padding-top: 94px;
  padding-left: 228px;
}
ssi-analytics ssi-disapprovals ssi-report .disapprovals-analytics-report-container .selected-date-ranges {
  border-radius: 10px;
  background-color: #ebebf4;
  padding: 10px;
}
ssi-analytics ssi-disapprovals ssi-report .disapprovals-analytics-report-container .selected-date-ranges span {
  margin-right: 7px;
}
ssi-analytics ssi-disapprovals ssi-report .disapprovals-analytics-report-container .selected-date-ranges .current {
  color: #101525;
  font-size: 14px;
  font-weight: 900;
}
ssi-analytics ssi-disapprovals ssi-report .disapprovals-analytics-report-container .selected-date-ranges .previous {
  color: #101525;
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
}
ssi-analytics ssi-disapprovals ssi-report .disapprovals-analytics-report-container .section-title {
  color: #101525;
  font-weight: 900;
  text-rendering: geometricPrecision;
  font-size: 20px;
  margin: 0 0 40px;
}
ssi-analytics ssi-disapprovals ssi-report .facts-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 0 20px;
  margin: 0;
}
@media print {
  ssi-analytics ssi-disapprovals ssi-report .facts-container {
    display: block;
  }
  ssi-analytics ssi-disapprovals ssi-report .facts-container > * {
    display: inline-block;
    width: auto !important;
  }
}
ssi-analytics ssi-disapprovals ssi-report .facts-container > * {
  width: 100%;
}
ssi-analytics ssi-disapprovals ssi-report .facts-container > * .fact-overview {
  border-radius: 12px;
  padding: 12px;
  width: 100%;
  min-width: 170px;
  margin: 0 0 20px;
}
ssi-analytics ssi-disapprovals ssi-report .summaries-per-account-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 0 20px;
}
ssi-analytics ssi-disapprovals ssi-report article {
  font-size: 1rem;
}
ssi-analytics ssi-disapprovals ssi-report article.social-network {
  display: inline-flex;
  background-color: #fff;
  box-shadow: 0 0.5em 2em rgba(131, 142, 171, 0.2);
  border-radius: 1.2em;
  padding: 1.2em;
  width: 100%;
  min-width: 170px;
  margin: 0 0 2em;
}
@media print {
  ssi-analytics ssi-disapprovals ssi-report article.social-network {
    border: 1px solid black;
  }
}
ssi-analytics ssi-disapprovals ssi-report article .social-network-left {
  flex: 0 0 4.6em;
  padding-right: 1em;
}
ssi-analytics ssi-disapprovals ssi-report article .social-network-thumb {
  height: 4.6em;
  width: 4.6em;
  overflow: hidden;
}
ssi-analytics ssi-disapprovals ssi-report article .social-network-thumb i {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  color: #fff;
  line-height: 1;
  background-color: #89a4ea;
  font-size: 1.8em;
}
ssi-analytics ssi-disapprovals ssi-report article .social-network-thumb i.ssi-sms:before {
  margin-top: 0.4em;
}
ssi-analytics ssi-disapprovals ssi-report article .social-network-thumb i.fa-twitter, ssi-analytics ssi-disapprovals ssi-report article .social-network-thumb i.ssi-x-logo {
  font-size: 2em;
  background-color: #1da1f2;
}
ssi-analytics ssi-disapprovals ssi-report article .social-network-thumb i.fa-facebook {
  font-size: 1.7em;
  background-color: #1877f2;
}
ssi-analytics ssi-disapprovals ssi-report article .social-network-thumb i.fa-linkedin {
  font-size: 1.8em;
  background-color: #2867b2;
}
ssi-analytics ssi-disapprovals ssi-report article .social-network-thumb i.fa-youtube-play {
  font-size: 2em;
  background-color: #f00;
}
ssi-analytics ssi-disapprovals ssi-report article .social-network-thumb i.fa-instagram {
  font-size: 2em;
  background: linear-gradient(180deg, #635fcc, #d43082 37%, #fec86b 100%);
}
ssi-analytics ssi-disapprovals ssi-report article .social-network-thumb i.ssi-whatsapp {
  font-size: 1.7em;
  background-color: #56cd63;
}
ssi-analytics ssi-disapprovals ssi-report article .social-network-right {
  flex: 1 0 auto;
  text-rendering: geometricPrecision;
}
ssi-analytics ssi-disapprovals ssi-report article .social-network-title {
  font-weight: 900;
  margin: 0.7rem 0 0;
  font-size: 1.6em;
  color: #46547c;
}
ssi-analytics ssi-disapprovals ssi-report article .social-network-meta {
  font-weight: 700;
  margin: 0.1em 0 1em;
  color: #838eab;
}
ssi-analytics ssi-disapprovals ssi-report article .social-network-stats {
  display: flex;
  justify-content: flex-end;
  padding: 0;
  margin: 2.6em 0.6em 0.5em 0;
}
ssi-analytics ssi-disapprovals ssi-report article .social-network-stat {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 0 2.2em 0 0;
}
ssi-analytics ssi-disapprovals ssi-report article .social-network-stat:last-child {
  margin-right: 0;
}
ssi-analytics ssi-disapprovals ssi-report article .social-network-stat:nth-child(2n) .social-network-stat-label:before {
  background-color: #14bae3;
}
ssi-analytics ssi-disapprovals ssi-report article .social-network-stat-data {
  color: #101525;
  font-size: 2em;
  margin: 0;
  font-weight: 900;
}
ssi-analytics ssi-disapprovals ssi-report article .social-network-stat-label {
  color: #838eab;
  font-weight: 900;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 1em;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
ssi-analytics ssi-disapprovals ssi-report article .social-network-stat-label:before {
  right: 0;
  content: "";
  display: block;
  width: 6em;
  height: 0.4em;
  border-radius: 10em;
  background-color: #f88c68;
  margin: 0.6em 0 0.8em;
}