ssi-widget-inbox-conversations {
  height: 100%;
  width: 100%;
}
ssi-widget-inbox-conversations .dashboard-widget {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 1px;
}
ssi-widget-inbox-conversations .dashboard-widget ssi-inbox-query-result-list.live-chat-tally-visible .inbox-query-result-list {
  height: calc(100% - 50px);
}