ssi-publisher {
  color: #393c3f;
}
ssi-publisher h1,
ssi-publisher h2,
ssi-publisher h3 {
  color: #393c3f;
}
ssi-publisher .publisher-height-spacer {
  height: 435px;
}
ssi-publisher .publisher-body-container {
  overflow: visible !important;
  position: fixed;
  top: 68px;
  z-index: 1029;
}
@media (max-width: 885px) {
  ssi-publisher .publisher-body-container {
    width: 100vw;
    height: 100vh;
    overflow: auto !important;
    background-color: #f0f3f9;
  }
}
@media (min-width: 885px) {
  ssi-publisher .publisher-body-container {
    height: 435px;
    width: 100%;
  }
}
ssi-publisher .publisher-body {
  background-color: #f0f3f9;
  height: 100%;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}
@media (max-width: 885px) {
  ssi-publisher .publisher-body {
    margin: 0;
  }
}
@media (min-width: 885px) {
  ssi-publisher .publisher-body {
    margin: 0 30px;
    box-shadow: 0 0 18px rgba(131, 142, 171, 0.35);
  }
}
ssi-publisher .publisher-secondary-view {
  display: flex;
  height: 100%;
}
@media (max-width: 885px) {
  ssi-publisher .publisher-secondary-view {
    flex-direction: column;
  }
}
ssi-publisher .publisher-secondary-view-left {
  width: 350px;
  padding-top: 50px;
  box-shadow: 0 0 18px rgba(131, 142, 171, 0.35);
  height: 100%;
  display: flex;
  flex-direction: column;
}
ssi-publisher .publisher-secondary-view-left-body {
  padding: 0 10px;
  flex: 1;
}
ssi-publisher .publisher-secondary-view-right {
  flex: 1;
}
ssi-publisher .publisher-btn-light {
  color: #14bae3;
  background-color: #fff;
  border-color: #fff;
}
ssi-publisher .publisher-btn-light:focus, ssi-publisher .publisher-btn-light.focus {
  color: #14bae3;
  background-color: #e6e6e6;
  border-color: #bfbfbf;
}
ssi-publisher .publisher-btn-light:hover {
  color: #14bae3;
  background-color: #e6e6e6;
  border-color: #e0e0e0;
}
ssi-publisher .publisher-btn-light:active, ssi-publisher .publisher-btn-light.active, .open > ssi-publisher .publisher-btn-light.dropdown-toggle {
  color: #14bae3;
  background-color: #e6e6e6;
  background-image: none;
  border-color: #e0e0e0;
}
ssi-publisher .publisher-btn-light:active:hover, ssi-publisher .publisher-btn-light:active:focus, ssi-publisher .publisher-btn-light:active.focus, ssi-publisher .publisher-btn-light.active:hover, ssi-publisher .publisher-btn-light.active:focus, ssi-publisher .publisher-btn-light.active.focus, .open > ssi-publisher .publisher-btn-light.dropdown-toggle:hover, .open > ssi-publisher .publisher-btn-light.dropdown-toggle:focus, .open > ssi-publisher .publisher-btn-light.dropdown-toggle.focus {
  color: #14bae3;
  background-color: #d4d4d4;
  border-color: #bfbfbf;
}
ssi-publisher .publisher-btn-light.disabled:hover, ssi-publisher .publisher-btn-light.disabled:focus, ssi-publisher .publisher-btn-light.disabled.focus, ssi-publisher .publisher-btn-light[disabled]:hover, ssi-publisher .publisher-btn-light[disabled]:focus, ssi-publisher .publisher-btn-light[disabled].focus, fieldset[disabled] ssi-publisher .publisher-btn-light:hover, fieldset[disabled] ssi-publisher .publisher-btn-light:focus, fieldset[disabled] ssi-publisher .publisher-btn-light.focus {
  background-color: #fff;
  border-color: #fff;
}
ssi-publisher .publisher-btn-light .badge {
  color: #fff;
  background-color: #14bae3;
}
ssi-publisher .publisher-btn-gray {
  color: #8d919f;
  background-color: #e1e3ea;
  border-color: #e1e3ea;
}
ssi-publisher .publisher-btn-gray:focus, ssi-publisher .publisher-btn-gray.focus {
  color: #8d919f;
  background-color: #c3c7d5;
  border-color: #969db6;
}
ssi-publisher .publisher-btn-gray:hover {
  color: #8d919f;
  background-color: #c3c7d5;
  border-color: #bdc1d1;
}
ssi-publisher .publisher-btn-gray:active, ssi-publisher .publisher-btn-gray.active, .open > ssi-publisher .publisher-btn-gray.dropdown-toggle {
  color: #8d919f;
  background-color: #c3c7d5;
  background-image: none;
  border-color: #bdc1d1;
}
ssi-publisher .publisher-btn-gray:active:hover, ssi-publisher .publisher-btn-gray:active:focus, ssi-publisher .publisher-btn-gray:active.focus, ssi-publisher .publisher-btn-gray.active:hover, ssi-publisher .publisher-btn-gray.active:focus, ssi-publisher .publisher-btn-gray.active.focus, .open > ssi-publisher .publisher-btn-gray.dropdown-toggle:hover, .open > ssi-publisher .publisher-btn-gray.dropdown-toggle:focus, .open > ssi-publisher .publisher-btn-gray.dropdown-toggle.focus {
  color: #8d919f;
  background-color: #aeb3c6;
  border-color: #969db6;
}
ssi-publisher .publisher-btn-gray.disabled:hover, ssi-publisher .publisher-btn-gray.disabled:focus, ssi-publisher .publisher-btn-gray.disabled.focus, ssi-publisher .publisher-btn-gray[disabled]:hover, ssi-publisher .publisher-btn-gray[disabled]:focus, ssi-publisher .publisher-btn-gray[disabled].focus, fieldset[disabled] ssi-publisher .publisher-btn-gray:hover, fieldset[disabled] ssi-publisher .publisher-btn-gray:focus, fieldset[disabled] ssi-publisher .publisher-btn-gray.focus {
  background-color: #e1e3ea;
  border-color: #e1e3ea;
}
ssi-publisher .publisher-btn-gray .badge {
  color: #e1e3ea;
  background-color: #8d919f;
}
ssi-publisher .publisher-text {
  font-size: 14px;
  font-weight: normal;
}