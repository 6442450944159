@import "./src/apps/angularjs/common/sass/global-variables.scss";
@import '../../../../../angularjs/common/sass/variables';
@import '~/styles/colors';

$action-blue: #27bbe1;
$campaign-stats-responsive-width: 885px;

ssi-marketing-index {
  $campaign-title-color: #a6b7c0;
  $campaign-icon-dimensions: 50px;
  $campaign-icon-overlap: 6px;
  $index-bg-color: #f6f8f9;
  $blue: $primary-blue;
  $orange: #eac95c;

  display: block;
  background-color: $index-bg-color;
  margin-left: -15px;
  margin-right: -15px;

  .stat-number {
    display: block;
    font-size: 2.2em;
  }

  .campaigns-list {
    display: flex;
    flex-wrap: wrap;
    @media (min-width: $campaign-stats-responsive-width) {
      padding: 80px 120px;
    }
  }

  .campaign {
    position: relative;
    $campaign-margin: 5px;
    flex-basis: calc(33% - (#{$campaign-margin} * 2));
    background-color: white;
    box-shadow: $box-shadow-1;
    padding: 25px 0;
    margin: $campaign-margin;
    text-align: center;
  }

  .campaign-title {
    color: $campaign-title-color;
    font-weight: normal;
    text-align: center;
    display: block;
    position: relative;
    z-index: 99;
  }

  .campaign-icon {
    border: solid 3px #f3f6f7;
    color: $campaign-title-color;
    font-size: 22px;
    border-radius: 50%;
    padding: 5px;
    display: inline-block;
    text-align: center;
    width: $campaign-icon-dimensions;
    height: $campaign-icon-dimensions;
    background-color: white;

    &.settings-trigger {
      cursor: pointer;
    }

    &.campaign-icon-overlap-right {
      position: relative;
      right: -$campaign-icon-overlap;
    }

    &.campaign-icon-overlap-left {
      position: relative;
      left: -$campaign-icon-overlap;
    }

    &.active {
      background-color: $primary-active;
      color: #fff;
    }
  }

  .campaign-message {
    padding: 100px 0 400px;
    font-size: 22px;
    text-align: center;
  }

  .campaign-stat {
    background-color: $index-bg-color;
    padding: 5px;
    color: $campaign-title-color;
    font-weight: normal;
    margin: 0 3px;
    font-size: 13px;
    border-radius: 10px;
  }

  .campaigns-completed-filter {
    text-align: center;
    margin: -12px;
    .campaigns-select-wrapper {
      display: inline-block;
      padding: 6px 16px;
      background-color: $blue;
      border-radius: 20px;
      select {
        border: none;
        background-color: $blue;
        color: white;
      }
    }
  }
}

.reset-list-style {
  padding-left: 0;
  margin-bottom: 0;

  li {
    display: block;
  }
}

.campaigns-stats {
  display: flex;
  width: 100%;
  flex: 1 1 auto;
  @media (max-width: $campaign-stats-responsive-width) {
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.campaign-stats-row {
  display: flex;
  flex: 1 1 auto;
  margin: 0 -15px;
  @media (max-width: $campaign-stats-responsive-width) {
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.validate-posts-box-select-mode:hover {
  cursor: pointer;
}

.validation-filters-aside {
  display: inline-block;
  flex: 1 1 33%;
  width: 33%;
  background-color: #3a3f49;
  text-align: center;
  padding: 0 20px 40px 20px;
  @media (max-width: $campaign-stats-responsive-width) {
    width: 100vw;
  }
}

.campain-stats-main {
  display: inline-block;
  flex: 1 1 66%;
  width: 66%;
  @media (max-width: $campaign-stats-responsive-width) {
    width: 100vw;
  }
  background-color: #f5f7f8;
  padding: 20px;
}

.no-validation-posts {
  color: #a6b7c0;
  font-weight: 500;
  max-width: 240px;
  margin: 15% auto;
  text-align: center;
}

.no-validation-posts-image {
  font-size: 90px;
  margin-bottom: 10px;
}

.no-validation-posts-title {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 600;
}

.no-validation-posts-meta {
  font-size: 14px;
}

.campaigns-selection {
  background-color: #494f5b;
  padding: 42px 35px;
  margin: 0 -20px 60px;
  color: #6f7983;

  p {
    font-weight: 600;
    margin: 0;
  }
}

.campaigns-selection-actions-wrap {
  display: flex;
  align-items: center;
}

.campaigns-info {
  flex: 0 1 auto;
  text-align: left;

  .campaigns-selection-action-refresh,
  p {
    display: inline-block;
    font-size: 14px;
  }

  .campaigns-selection-action-refresh {
    background-color: #464d56;
    border-radius: 50%;
    height: 38px;
    width: 38px;
    line-height: 34px;
    text-align: center;
    border: 2px solid #454852;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 0 10px 0 0;
    color: $action-blue;
    font-size: 18px;
  }
}

.campaigns-selection-actions {
  text-align: right;
  margin: 0;
  padding: 0;
  flex: 1 1 auto;
  font-weight: 600;

  li {
    display: inline-block;
    white-space: nowrap;

    p {
      display: inline-block;
    }

    &:after {
      content: '|';
      display: inline-block;
      margin: 6px;
    }

    &:last-child {
      &:after {
        content: normal;
        margin: 0;
      }
    }
  }
}

.campaigns-selection-action {
  cursor: pointer;

  &:hover,
  &:focus {
    color: $action-blue;
  }
}

.campaigns-bulk {
  max-width: 270px;
  margin: 0 auto;
  padding: 35px 0 40px;
  color: #67717a;
  font-weight: 600;

  .btn {
    width: 130px;
  }
}

.campaigns-bulk-icon {
  font-size: 46px;
  margin-bottom: 25px;
}

.campaigns-bulk-title {
  color: #a6b7bf;
  margin-bottom: 25px;
}

.campaigns-bulk-meta {
  margin-bottom: 30px;
}
