ssi-ktd-grid {
  display: block;
  position: relative;
  width: 100%;
}
ssi-ktd-grid ssi-ktd-grid-item.ktd-grid-item-dragging {
  z-index: 1000;
}
ssi-ktd-grid ssi-ktd-grid-item.no-transitions {
  transition: none !important;
}
ssi-ktd-grid .ktd-grid-item-placeholder {
  position: absolute;
  background-color: rgba(20, 186, 227, 0.1);
  border: 2px dashed #14bae3;
  border-radius: 10px;
  padding: 10px;
  z-index: 0;
  transition-property: transform;
  transition: all 150ms ease;
}